import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react";
import { SegmentChangeEventDetail, RefresherEventDetail } from "@ionic/core";
import { useRouteMatch } from "react-router-dom";
import cn from "classnames";

import AppliedFiltersList from "../AppliedFiltersList";
import { NavBar, NavBarBackButton } from "../NavBar";
import { FullContentLoadingView } from "../LoadingView";
import CLContent from "../CLContent";
import Header from "./Header";
import Features from "./Features";
import FilterButton from "../FilterButton";
import BackToTopButton from "../BackToTopButton";
import FloatingButtons from "../FloatingButtons";
import {
  TarBarHeightContext,
  TabBarSpacePlaceholder,
} from "../navigation/TabBar";
import ProductList from "../ProductList";
import ShoppingCartButton from "../ShoppingCartButton";

import {
  getResources,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { ProductOverview } from "../../models/ProductOverview";
import {
  ProductFilterInfo,
  hasFilters,
  removeSingleLevelAndMultiLevelFilterAttribute,
  removeRangeFilterAttribute,
  FilterAttribute,
  RangeFilterAttribute,
  defaultProductFilterInfo,
  SortField,
  SortOrder,
  SortFieldOption,
  makeGraphQLFilterValue,
  SortFields,
} from "../../models/filter";
import { MerchantPreview, doesMerchantHasCMSPage } from "../../models/Merchant";
import { PaginationInfo } from "../../repository/State";

import { useFetchMerchantPreview } from "../../repository/MerchantRepository";
import {
  useFetchNormalProductOverviewsByMerchantId,
  useFetchFeaturedProductOverviewsByMerchantId,
} from "../../repository/ProductRepository";
import { useFetchProductLabelsByProductIdPaginated } from "../../repository/ProductLabelRepository";
import { useFetchSortFields } from "../../repository/FilterRepository";

import { useSticky } from "../../utils/sticky";
import { useIonContentScrollEl } from "../../utils/ionContentScrollEl";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useHandleBackToTopClick from "../../utils/handleBackToTopClick";
import { useIsContentScrolledToTop } from "../../utils/IonContent";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { LocalizedText } from "../../i18n/Localization";
import {
  getPathForProductDetailPage,
  RootTab,
  getPathForMerchantDetails,
  useCurrentTab,
  getPathForShoppingCart,
  presentFilterModal,
} from "../../navigation/routes";
import { usePresentLocalizedAlertForRequestStateError } from "../../hook/ResourcesRequestHook";
import { useKeepUpdatingRef } from "../../hook/utils";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";

import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import { withProviders } from "../Provider";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";

import { PresentationContext } from "../../our-navigation";

import floatingButtonStyles from "../FloatingButtons/FloatingButton.module.scss";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { WishlistContext } from "../WishlistProvider";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import ProductListEmptyView from "./ProductListEmptyView";
import CLLink from "../navigation/CLLink";

import styles from "./styles.module.scss";

const SingleMerchantPage: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const merchantID = match.params.id;

  const { requestState } = useFetchMerchantPreview(merchantID);
  const merchantPreview = getResources(requestState);

  const [fetchSortFieldsRequestState, fetchSortFields] = useFetchSortFields();

  const fetchSortFields_ = useCallback(() => {
    fetchSortFields("", { vendor_id: { eq: `${merchantID}` } }).catch(() => {});
  }, [fetchSortFields, merchantID]);

  const sortFields = useMemo<SortFields | null>(() => {
    if (
      fetchSortFieldsRequestState.type === "initial" ||
      isRequestLoading(fetchSortFieldsRequestState)
    ) {
      return null;
    }
    const resources = getResources(fetchSortFieldsRequestState);
    if (resources) {
      return resources;
    }
    return { defaultSortField: null, sortFieldOptions: [] };
  }, [fetchSortFieldsRequestState]);

  useEffect(() => {
    fetchSortFields_();
  }, [fetchSortFields_]);

  return (
    <div className={cn("ion-page", styles.container)}>
      {isRequestLoading(requestState) || !sortFields ? (
        <>
          <NavBar headerLeft={<NavBarBackButton />} />
          <CLContent>
            <FullContentLoadingView />
          </CLContent>
        </>
      ) : merchantPreview && sortFields ? (
        <ResolvedSingleMerchantPage
          merchantPreview={merchantPreview}
          defaultSortField={sortFields.defaultSortField}
          sortRows={sortFields.sortFieldOptions}
        />
      ) : null}
    </div>
  );
};

enum Segment {
  featured = "featured",
  products = "products",
}

function makeSegment(rawValue: string): Segment | null {
  if (rawValue === Segment.featured) {
    return Segment.featured;
  } else if (rawValue === Segment.products) {
    return Segment.products;
  }
  return null;
}

type ViewState =
  | ViewStateLoading
  | ViewStateDisplayFeaturedProducts
  | ViewStateDisplayNormalProducts
  | ViewStateCMSPage;

interface ViewStateLoading {
  type: "loading";
}
const ViewStateLoading: ViewStateLoading = {
  type: "loading",
};

interface ViewStateDisplayFeaturedProducts {
  type: "featured-products";
  products: ProductOverview[];
  paginationInfo: PaginationInfo<ProductOverview>;
}
function ViewStateDisplayFeaturedProducts(
  products: ProductOverview[],
  paginationInfo: PaginationInfo<ProductOverview>
): ViewStateDisplayFeaturedProducts {
  return {
    type: "featured-products",
    products,
    paginationInfo,
  };
}

interface ViewStateDisplayNormalProducts {
  type: "products";
  products: ProductOverview[];
  paginationInfo: PaginationInfo<ProductOverview>;
  productFilterInfo: ProductFilterInfo | null;
}
function ViewStateDisplayNormalProducts(
  products: ProductOverview[],
  paginationInfo: PaginationInfo<ProductOverview>,
  productFilterInfo: ProductFilterInfo | null
): ViewStateDisplayNormalProducts {
  return {
    type: "products",
    products,
    paginationInfo,
    productFilterInfo,
  };
}

interface ViewStateCMSPage {
  type: "cms-page";
  cmsPageID: number;
  canonicalUrl: string | null;
  relativeUrl: string | null;
  paginationInfo: PaginationInfo<ProductOverview>;
}
function ViewStateCMSPage(
  cmsPageID: number,
  canonicalUrl: string | null,
  relativeUrl: string | null,
  paginationInfo: PaginationInfo<ProductOverview>
): ViewStateCMSPage {
  return {
    type: "cms-page",
    cmsPageID,
    canonicalUrl,
    relativeUrl,
    paginationInfo,
  };
}

interface ResolvedSingleMerchantPageProps {
  merchantPreview: MerchantPreview;
  defaultSortField: SortField | null;
  sortRows: SortFieldOption[];
}

const ResolvedSingleMerchantPage: React.FC<
  ResolvedSingleMerchantPageProps
  // eslint-disable-next-line complexity
> = props => {
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const isTabBarHidden = useScrollToHideTabBar(
    ionContentRef,
    ionLifeCycleContext
  );

  const tabBarHeight = useContext(TarBarHeightContext);

  const { merchantPreview, defaultSortField, sortRows } = props;

  const [selectedSegment, setSelectedSegment] = useState<Segment>(
    Segment.featured
  );
  useEffect(() => {
    switch (selectedSegment) {
      case Segment.featured:
        pageView({ page: "Merchant" });
        break;
      case Segment.products:
        pageView({ page: "Merchant All Product" });
        break;
      default:
        break;
    }
  }, [selectedSegment]);
  const handleIonSegmentChange = useCallback(
    (e: CustomEvent<SegmentChangeEventDetail>) => {
      const { value } = e.detail;
      if (value != null) {
        const segment = makeSegment(value);
        if (segment) {
          if (ionContentRef.current) {
            ionContentRef.current.scrollToTop(100);
          }
          actionEvent(
            selectedSegment === Segment.featured
              ? "Merchant"
              : "Merchant_All Product Page",
            "Click",
            "All Products"
          );
          setSelectedSegment(segment);
        }
      }
    },
    [selectedSegment]
  );

  const scrollEl = useIonContentScrollEl(ionContentRef);
  const stickyElRef = useRef<HTMLDivElement>(null);
  useSticky(scrollEl, stickyElRef.current);

  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );

  const [normalProductsFilterInfo, setNormalProductFilterInfo] = useState<
    ProductFilterInfo
  >(
    defaultProductFilterInfo({
      sortField: defaultSortField,
      sortOrder: SortOrder.descending,
    })
  );

  const {
    requestState: normalProductsRequestState,
    fetchNext: _loadMoreNormalProducts,
    refresh: _refreshNormalProducts,
    paginationInfo: normalProductsPaginationInfo,
  } = useFetchNormalProductOverviewsByMerchantId(
    merchantPreview.entityId,
    normalProductsFilterInfo
  );

  const isLoadMoreNormalProductsLoading = useMemo(() => {
    if (
      normalProductsPaginationInfo != null &&
      normalProductsPaginationInfo.hasMore &&
      isRequestLoading(normalProductsRequestState)
    ) {
      return true;
    }
    return false;
  }, [normalProductsPaginationInfo, normalProductsRequestState]);

  const [
    normalProductLabelsByProductId,
    fetchNormalProductLabelsByProductIds,
    clearNormalProductLabelByProductId,
  ] = useFetchProductLabelsByProductIdPaginated();

  const fetchNormalProductLabelsByProductIdsRef = useKeepUpdatingRef(
    fetchNormalProductLabelsByProductIds
  );

  const refreshNormalProducts = useCallback(async () => {
    const result = await _refreshNormalProducts();
    if (result) {
      clearNormalProductLabelByProductId();
      const { productOverviews } = result;
      const productIds = productOverviews.map(p => p.id);
      fetchNormalProductLabelsByProductIdsRef.current(productIds);
    }
    return result;
  }, [
    _refreshNormalProducts,
    fetchNormalProductLabelsByProductIdsRef,
    clearNormalProductLabelByProductId,
  ]);

  const loadMoreNormalProducts = useCallback(async () => {
    const result = await _loadMoreNormalProducts();
    if (result) {
      const { productOverviews } = result;
      const productIds = productOverviews.map(p => p.id);
      fetchNormalProductLabelsByProductIds(productIds);
    }
    return result;
  }, [_loadMoreNormalProducts, fetchNormalProductLabelsByProductIds]);

  const refreshNormalProductsRef = useKeepUpdatingRef(refreshNormalProducts);

  useEffect(() => {
    if (normalProductsFilterInfo) {
      clearNormalProductLabelByProductId();
      (async () => {
        try {
          await refreshNormalProductsRef.current();
        } catch {
        } finally {
          hideLoadingModal();
        }
      })();
    }
  }, [
    normalProductsFilterInfo,
    refreshNormalProductsRef,
    clearNormalProductLabelByProductId,
    hideLoadingModal,
  ]);

  usePresentLocalizedAlertForRequestStateError(normalProductsRequestState);

  const {
    requestState: featuredProductsRequestState,
    fetchNext: _loadMoreFeaturedProducts,
    refresh: _refreshFeaturedProducts,
    paginationInfo: featuredProductsPaginationInfo,
  } = useFetchFeaturedProductOverviewsByMerchantId(merchantPreview.id);

  const isLoadMoreFeaturedProductsLoading = useMemo(() => {
    if (
      featuredProductsPaginationInfo != null &&
      featuredProductsPaginationInfo.hasMore &&
      isRequestLoading(featuredProductsRequestState)
    ) {
      return true;
    }
    return false;
  }, [featuredProductsPaginationInfo, featuredProductsRequestState]);

  const [
    featuredProductLabelsByProductId,
    fetchFeaturedProductLabelsByProductIds,
    clearFeaturedProductLabelByProductId,
  ] = useFetchProductLabelsByProductIdPaginated();

  const fetchFeaturedProductLabelsByProductIdsRef = useKeepUpdatingRef(
    fetchFeaturedProductLabelsByProductIds
  );

  const refreshFeaturedProducts = useCallback(async () => {
    const result = await _refreshFeaturedProducts();
    if (result) {
      clearFeaturedProductLabelByProductId();
      const { productOverviews } = result;
      const productIds = productOverviews.map(p => p.id);
      fetchFeaturedProductLabelsByProductIdsRef.current(productIds);
    }
    return result;
  }, [
    _refreshFeaturedProducts,
    fetchFeaturedProductLabelsByProductIdsRef,
    clearFeaturedProductLabelByProductId,
  ]);

  const loadMoreFeaturedProducts = useCallback(async () => {
    const result = await _loadMoreFeaturedProducts();
    if (result) {
      const { productOverviews } = result;
      const productIds = productOverviews.map(p => p.id);
      fetchFeaturedProductLabelsByProductIds(productIds);
    }
    return result;
  }, [_loadMoreFeaturedProducts, fetchFeaturedProductLabelsByProductIds]);

  const refreshFeaturedProductsRef = useKeepUpdatingRef(
    refreshFeaturedProducts
  );

  useEffect(() => {
    (async () => {
      try {
        await refreshFeaturedProductsRef.current();
      } catch {
      } finally {
        hideLoadingModal();
      }
    })();
  }, [refreshFeaturedProductsRef, hideLoadingModal]);

  usePresentLocalizedAlertForRequestStateError(featuredProductsRequestState);

  const tab = useCurrentTab();
  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(tab, productOverview.sku);
    },
    [tab]
  );

  const handleProductOverviewClick = useCallback(
    (productOverview: ProductOverview) => {
      actionEvent("Merchant_All Product Page", "Click", productOverview.name);
    },
    []
  );

  const presentAddToCartModal = usePresentAddToCartModal();
  const handleAddToCart = useCallback(
    (productOverview: ProductOverview) => {
      actionEvent(
        "Merchant_All Product Page",
        "Click",
        `${productOverview.name}_Add To Cart`
      );
      presentAddToCartModal(productOverview.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );
  const { toggleProductFromWishlist } = useContext(WishlistContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      actionEvent(
        "Merchant_All Product Page",
        "Click",
        `${productOverview.name}_Likelist`
      );
      toggleProductFromWishlist(productOverview.sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );

  const onRefreshProductList = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      if (selectedSegment === Segment.products) {
        clearNormalProductLabelByProductId();
        try {
          await refreshNormalProducts();
        } catch {
        } finally {
          e.detail.complete();
        }
      } else {
        try {
          await refreshFeaturedProducts();
        } catch {
        } finally {
          e.detail.complete();
        }
      }
    },
    [
      selectedSegment,
      refreshNormalProducts,
      refreshFeaturedProducts,
      clearNormalProductLabelByProductId,
    ]
  );

  const onProductEndReached = useCallback(() => {
    if (selectedSegment === Segment.products) {
      loadMoreNormalProducts();
    } else {
      loadMoreFeaturedProducts();
    }
  }, [selectedSegment, loadMoreNormalProducts, loadMoreFeaturedProducts]);

  const scrollToTop = useCallback(() => {
    const ionContent = ionContentRef.current;
    if (ionContent) {
      ionContent.scrollToPoint(undefined, 0);
    }
  }, []);

  const onClickClearAllButton = useCallback(() => {
    showLoadingModal();
    setNormalProductFilterInfo(prev =>
      defaultProductFilterInfo(prev.sortAttribute)
    );
    scrollToTop();
    clearNormalProductLabelByProductId();
  }, [
    showLoadingModal,
    setNormalProductFilterInfo,
    clearNormalProductLabelByProductId,
    scrollToTop,
  ]);

  const onClickClearFilterButton = useCallback(
    (appliedFilter: FilterAttribute) => {
      showLoadingModal();
      const clearFilter = (p: ProductFilterInfo) =>
        removeSingleLevelAndMultiLevelFilterAttribute(p, appliedFilter);
      setNormalProductFilterInfo(clearFilter);
      scrollToTop();
      clearNormalProductLabelByProductId();
    },
    [
      showLoadingModal,
      setNormalProductFilterInfo,
      clearNormalProductLabelByProductId,
      scrollToTop,
    ]
  );

  const onClickClearRangeFilterButton = useCallback(
    (appliedRangeFilter: RangeFilterAttribute) => {
      showLoadingModal();
      const clearFilter = (p: ProductFilterInfo) =>
        removeRangeFilterAttribute(p, appliedRangeFilter);
      setNormalProductFilterInfo(clearFilter);
      scrollToTop();
    },
    [showLoadingModal, scrollToTop]
  );

  const onClickClearPredefinedCPFilterButton = useCallback(() => {
    showLoadingModal();
    setNormalProductFilterInfo(p => ({ ...p, predefinedCPFilter: null }));
    scrollToTop();
  }, [showLoadingModal, scrollToTop]);

  const onApplyFilters = useCallback(
    (productFilterInfo: ProductFilterInfo) => {
      showLoadingModal();
      setNormalProductFilterInfo({ ...productFilterInfo });
      scrollToTop();
      clearNormalProductLabelByProductId();
    },
    [
      showLoadingModal,
      setNormalProductFilterInfo,
      clearNormalProductLabelByProductId,
      scrollToTop,
    ]
  );

  const { present } = useContext(PresentationContext);
  const handleFilterClick = useCallback(() => {
    actionEvent("Merchant_All Product Page", "Click", "Filter");
    if (merchantPreview) {
      const merchantEntityIdFilter = makeGraphQLFilterValue(
        merchantPreview.entityId,
        "FilterEqualTypeInput"
      );
      presentFilterModal(present, {
        initialProductFilterInfo: normalProductsFilterInfo,
        onApplyFilters,
        sortRows,
        initialGraphQLFilter: merchantEntityIdFilter
          ? { entity_id: merchantEntityIdFilter }
          : {},
      });
    }
  }, [
    present,
    sortRows,
    normalProductsFilterInfo,
    onApplyFilters,
    merchantPreview,
  ]);

  const onClickShoppingCartButton = useCallback(() => {
    actionEvent(
      selectedSegment === Segment.featured
        ? "Merchant"
        : "Merchant_All Product Page",
      "Click",
      "Shopping Cart"
    );
    present(getPathForShoppingCart());
  }, [present, selectedSegment]);

  const handleBackToTopClick = useHandleBackToTopClick(ionContentRef, 200);

  const renderHeaderAndSegmentControl = useCallback(
    (_merchantPreview: MerchantPreview, shouldShowSegment: boolean) => (
      <HeaderAndSegmentControl
        key="header-and-segment-control"
        tab={tab}
        merchantPreview={_merchantPreview}
        selectedSegment={selectedSegment}
        handleIonSegmentChange={handleIonSegmentChange}
        stickyElRef={stickyElRef}
        shouldShowSegment={shouldShowSegment}
      />
    ),
    [tab, selectedSegment, handleIonSegmentChange]
  );

  const viewState = useMemo<ViewState>(() => {
    if (!normalProductsPaginationInfo) {
      return ViewStateLoading;
    }
    if (
      !doesMerchantHasCMSPage(merchantPreview) &&
      featuredProductsPaginationInfo == null
    ) {
      return ViewStateLoading;
    }

    if (selectedSegment === Segment.featured) {
      if (doesMerchantHasCMSPage(merchantPreview)) {
        return ViewStateCMSPage(
          merchantPreview.cmsPage.id,
          merchantPreview.cmsPage.canonicalUrl,
          merchantPreview.cmsPage.relativeUrl,
          normalProductsPaginationInfo
        );
      }
      if (featuredProductsPaginationInfo == null) {
        return ViewStateLoading;
      }
      return ViewStateDisplayFeaturedProducts(
        featuredProductsPaginationInfo.items,
        featuredProductsPaginationInfo
      );
    }

    return ViewStateDisplayNormalProducts(
      normalProductsPaginationInfo.items,
      normalProductsPaginationInfo,
      normalProductsFilterInfo
    );
  }, [
    selectedSegment,
    merchantPreview,
    normalProductsPaginationInfo,
    normalProductsFilterInfo,
    featuredProductsPaginationInfo,
  ]);

  const {
    isContentScrolledToTop,
    handleIonScroll,
  } = useIsContentScrolledToTop();

  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  const shouldHaveFeaturedProductSegment = useMemo(() => {
    if (doesMerchantHasCMSPage(merchantPreview)) {
      return true;
    }
    if (!featuredProductsPaginationInfo) {
      return false;
    }
    return featuredProductsPaginationInfo.items.length > 0;
  }, [featuredProductsPaginationInfo, merchantPreview]);

  const shouldShowSegment = useMemo(() => {
    return viewState.type === "loading"
      ? false
      : shouldHaveFeaturedProductSegment;
  }, [viewState, shouldHaveFeaturedProductSegment]);

  const initialSelectedSegmentSetRef = useRef(false);
  useEffect(() => {
    if (viewState.type !== "loading" && !initialSelectedSegmentSetRef.current) {
      setSelectedSegment(
        shouldHaveFeaturedProductSegment ? Segment.featured : Segment.products
      );
      initialSelectedSegmentSetRef.current = true;
    }
  }, [viewState, shouldHaveFeaturedProductSegment]);

  return (
    <div className={cn("ion-page", styles.container)}>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={merchantPreview.name}
        headerRight={
          <ShoppingCartButton
            onClick={onClickShoppingCartButton}
            count={shoppingCartItemCount}
          />
        }
      />
      {viewState.type === "products" &&
      viewState.productFilterInfo != null &&
      hasFilters(viewState.productFilterInfo) ? (
        <div className={styles.appliedFiltersListContainer}>
          <AppliedFiltersList
            productFilterInfo={viewState.productFilterInfo}
            onClickClearAllButton={onClickClearAllButton}
            onClickClearButton={onClickClearFilterButton}
            onClickClearRangeButton={onClickClearRangeFilterButton}
            onClickClearPredefinedCPFilterButton={
              onClickClearPredefinedCPFilterButton
            }
          />
        </div>
      ) : null}
      <CLContent
        className={styles.ionContent}
        ref={ionContentRef}
        onIonScroll={handleIonScroll}
      >
        <ProductList
          ionContentRef={ionContentRef}
          productOverviews={
            viewState.type === "products" ||
            viewState.type === "featured-products"
              ? viewState.paginationInfo.items
              : []
          }
          isLoadMoreLoading={
            viewState.type === "loading"
              ? true
              : viewState.type === "products"
              ? isLoadMoreNormalProductsLoading
              : viewState.type === "featured-products"
              ? isLoadMoreFeaturedProductsLoading
              : false
          }
          productLabelsByProductId={
            viewState.type === "products"
              ? normalProductLabelsByProductId
              : viewState.type === "featured-products"
              ? featuredProductLabelsByProductId
              : undefined
          }
          hrefForProduct={hrefForProduct}
          onProductOverviewClick={handleProductOverviewClick}
          onAddToCart={handleAddToCart}
          onClickLikeButton={onClickLikeButton}
          header={
            <>
              {renderHeaderAndSegmentControl(
                merchantPreview,
                shouldShowSegment
              )}
              {viewState.type !== "loading" &&
                selectedSegment === Segment.products && (
                  <h1 className={styles.productListHeader}>
                    <LocalizedText messageID="single_merchant.products" />
                  </h1>
                )}
            </>
          }
          footer={
            <>
              {viewState.type === "cms-page" && (
                <div>
                  <Features
                    cmsPageID={viewState.cmsPageID}
                    canonicalUrl={viewState.canonicalUrl}
                    relativeUrl={viewState.relativeUrl}
                  />
                </div>
              )}
              {viewState.type === "products" &&
                viewState.paginationInfo.items.length === 0 && (
                  <ProductListEmptyView messageID="single_category_page.no_products.message" />
                )}
              {viewState.type === "featured-products" &&
                viewState.paginationInfo.items.length === 0 && (
                  <ProductListEmptyView messageID="single_merchant.featured.no_products.message" />
                )}
            </>
          }
          onRefresh={
            viewState.type === "featured-products" ||
            viewState.type === "products"
              ? onRefreshProductList
              : undefined
          }
          onEndReach={
            viewState.type === "featured-products" ||
            viewState.type === "products"
              ? onProductEndReached
              : undefined
          }
        />
        <TabBarSpacePlaceholder />
      </CLContent>
      {viewState.type === "products" &&
      viewState.paginationInfo.items.length > 0 ? (
        <FloatingButtons offset={isTabBarHidden ? 0 : tabBarHeight}>
          <FilterButton onClick={handleFilterClick} />
          <BackToTopButton
            onClick={handleBackToTopClick}
            className={cn(floatingButtonStyles.hidable, {
              [floatingButtonStyles.hidden]: isContentScrolledToTop,
            })}
          />
        </FloatingButtons>
      ) : null}
    </div>
  );
};

export default withProviders(SingleMerchantPage, LoadingModalProvider);

const HeaderAndSegmentControl: React.FC<{
  tab: RootTab;
  merchantPreview: MerchantPreview;
  selectedSegment: Segment;
  handleIonSegmentChange: (e: CustomEvent<SegmentChangeEventDetail>) => void;
  stickyElRef: React.RefObject<HTMLDivElement>;
  shouldShowSegment: boolean;
}> = props => {
  const {
    tab,
    merchantPreview,
    selectedSegment,
    handleIonSegmentChange,
    stickyElRef,
    shouldShowSegment,
  } = props;
  return (
    <>
      <CLLink
        to={getPathForMerchantDetails(tab, merchantPreview.id)}
        className="no-style-link"
      >
        <Header merchantPreview={merchantPreview} />
      </CLLink>
      {shouldShowSegment && (
        <div className={styles.segmentWrapper} ref={stickyElRef}>
          <IonSegment
            onIonChange={handleIonSegmentChange}
            value={selectedSegment}
            mode="ios"
          >
            <IonSegmentButton
              value="featured"
              className={styles.segmentButton}
              mode="ios"
            >
              <IonLabel className={styles.segmentButtonText}>
                <LocalizedText messageID="single_merchant.featured" />
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="products"
              className={styles.segmentButton}
              mode="ios"
            >
              <IonLabel className={styles.segmentButtonText}>
                <LocalizedText messageID="single_merchant.products" />
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
      )}
    </>
  );
};
