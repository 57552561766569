import React, { useCallback } from "react";
import cn from "classnames";

import { PrimaryButton } from "../Button";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./NoProducts.module.scss";
import { MessageID } from "../../i18n/translations/type";

interface Props {
  className: string;
  onBackClick: () => void;
  messageID: MessageID;
  backTextMessageID: MessageID;
}

const NoProducts: React.FC<Props> = (props: Props) => {
  const { className, onBackClick, messageID, backTextMessageID } = props;

  const handleBackClick = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.icon} />
      <p className={styles.message}>
        <LocalizedText messageID={messageID} />
      </p>
      <PrimaryButton
        className={styles.button}
        block={true}
        onClick={handleBackClick}
      >
        <LocalizedText messageID={backTextMessageID} />
      </PrimaryButton>
    </div>
  );
};

export default NoProducts;
