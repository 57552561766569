import React, { useMemo, CSSProperties } from "react";
import { IonIcon } from "@ionic/react";
import classnames from "classnames";
import numeral from "numeral";

import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import {
  ClubMemberCustomer,
  ClubTier,
  getCustomerClubPoints,
  getCustomerClubTier,
} from "../../models/Customer";
import Config from "../../Config";

interface AccountLinkTheClubSectionProps {
  className?: string;
  style?: CSSProperties;
  onClickTheClubButton: () => void;
}

export const AccountLinkTheClubSection: React.FC<
  AccountLinkTheClubSectionProps
> = props => {
  const { className, onClickTheClubButton, style } = props;
  return (
    <div className={classnames(styles.theClubCard, className)} style={style}>
      <div className={styles.theClubCardUpperSection}>
        <IonIcon icon="club-point" className={styles.clubPointIcon} />
        <div className={styles.theClubDesc}>
          <LocalizedText messageID="page.account.enjoy_redeeming_rewards" />
        </div>
      </div>
      <div className={styles.theClubCardLowerSection}>
        <div className={styles.theClubArrow} />
        <div className={styles.theClubButton} onClick={onClickTheClubButton} />
      </div>
    </div>
  );
};

interface AccountClubPointSectionProps {
  className?: string;
  style?: CSSProperties;
  customer: ClubMemberCustomer;
  onClickRedeemButton: () => void;
}

export const AccountClubPointSection: React.FC<
  AccountClubPointSectionProps
> = props => {
  const { className, customer, onClickRedeemButton, style } = props;

  const tierClass = useMemo(() => {
    const tierClassMapping: { [key in ClubTier]: string } = {
      blue: "blueCard",
      silver: "silverCard",
      gold: "goldCard",
      platinum: "platinumCard",
      black: "blackCard",
    };
    return tierClassMapping[getCustomerClubTier(customer)];
  }, [customer]);

  return (
    <div
      className={classnames(styles.clubPointCard, className, styles[tierClass])}
      style={style}
    >
      <div className={styles.clubPointCardUpperSection}>
        <div className={styles.clubPoint}>
          <h6 className={styles.clubPointTitle}>
            <LocalizedText messageID="page.account.club_points_earned.label" />
          </h6>
          <p className={styles.clubPointValue}>
            <IonIcon icon="club-point" className={styles.clubPointIcon} />{" "}
            <LocalizedText
              messageID="page.account.club_points_earned"
              messageArgs={{
                clubPointsStr: numeral(getCustomerClubPoints(customer)).format(
                  "0,0"
                ),
              }}
            />
          </p>
        </div>
        <div className={styles.tier}>
          <div className={styles.theClubIcon} />
          <p className={styles.tierText}>
            <LocalizedText
              messageID="page.account.club_tier"
              messageArgs={{ tier: getCustomerClubTier(customer) }}
            />
          </p>
        </div>
      </div>
      {Config.SHOW_CLUBPOINT_JOURNEY && (
        <div className={styles.clubPointCardLowerSection}>
          <div className={styles.giftContainer}>
            <div className={styles.giftOval} />
            <div className={styles.giftName}>TODO: Gift name</div>
            <div className={styles.giftPoints}>TODO: Gift pts</div>
          </div>
          <div className={styles.redeemButton} onClick={onClickRedeemButton}>
            <LocalizedText messageID="redeem" />
          </div>
        </div>
      )}
    </div>
  );
};
