import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import classnames from "classnames";

import { HorizontalProductListData } from "../../models/horizontalProductList";
import { ProductOverview } from "../../models/ProductOverview";
import { IndexMap } from "../../utils/type";

import MountNotifier from "../MountNotifier";

import ProductBlock from "./ProductBlock";

import styles from "./styles.module.scss";
import Pager from "./Pager";

interface Props {
  horizontalProductListData: HorizontalProductListData;
  detailButton?: React.ReactNode;
  itemPerPage: number;

  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
  onDataLoadedOnce?: () => void;
}

const HorizontalPaginatedProductList: React.FC<Props> = props => {
  const {
    horizontalProductListData: {
      title,
      backgroundUrl,
      productOverviews,
      productLabelsByProductId,
      bundleByProductId,
    },
    detailButton,
    itemPerPage,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
  } = props;

  const backgroundStyle = useMemo(
    () =>
      backgroundUrl
        ? {
            backgroundImage: `url(${backgroundUrl})`,
          }
        : undefined,
    [backgroundUrl]
  );

  const totalPages = useMemo(() => {
    return Math.ceil(productOverviews.length / itemPerPage);
  }, [productOverviews, itemPerPage]);

  const productBlockData = useMemo<
    { key: string; product: ProductOverview }[][] | null
  >(() => {
    let currentPage = 0;
    const data: { key: string; product: ProductOverview }[][] = [];
    const productCountBySKU: IndexMap<string, number> = {};
    for (let i = 0; i < productOverviews.length; ++i) {
      const product = productOverviews[i];
      const count = productCountBySKU[product.sku] || 0;
      productCountBySKU[product.sku] = count + 1;
      if (data.length <= currentPage) {
        data.push([]);
      }
      data[currentPage].push({
        key: `${product.sku}-${count}`,
        product,
      });
      if (itemPerPage) {
        currentPage += i + 1 >= itemPerPage * data.length ? 1 : 0;
      }
    }
    return data;
  }, [productOverviews, itemPerPage]);

  const ionSlidesRef = useRef<HTMLIonSlidesElement>(null);

  const [_ionSlidesPage, setIonSlidesPage] = useState(0);

  const canSlidesNext = useMemo(() => {
    return _ionSlidesPage < totalPages - 1;
  }, [_ionSlidesPage, totalPages]);

  const handleNextPageClick = useCallback(() => {
    setIonSlidesPage(ionSlidesPage =>
      ionSlidesPage >= totalPages ? ionSlidesPage : ionSlidesPage + 1
    );
  }, [totalPages]);

  const canSlidePrev = useMemo(() => {
    return _ionSlidesPage > 0;
  }, [_ionSlidesPage]);

  const handlePrevPageClick = useCallback(() => {
    setIonSlidesPage(ionSlidesPage =>
      ionSlidesPage <= 0 ? 0 : ionSlidesPage - 1
    );
  }, []);

  useEffect(() => {
    if (ionSlidesRef.current) {
      ionSlidesRef.current.slideTo(_ionSlidesPage);
    }
  }, [_ionSlidesPage]);

  const handleIonSlideDidChange = useCallback(async () => {
    if (ionSlidesRef.current) {
      setIonSlidesPage(await ionSlidesRef.current.getActiveIndex());
    }
  }, []);

  const slidesElRef = useRef<HTMLDivElement>(null);

  const [ionSlidesMounted, setIonSlidesMounted] = useState(false);

  const handleIonSlidesMount = useCallback(() => setIonSlidesMounted(true), []);

  const [slidesHeight, setSlidesHeight] = useState(0);

  useEffect(() => {
    if (slidesElRef.current && productBlockData) {
      const slidesEl = slidesElRef.current;

      setSlidesHeight(slidesEl.getBoundingClientRect().height);

      const handleResize = () => {
        setSlidesHeight(slidesEl.getBoundingClientRect().height);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    return undefined;
  }, [productBlockData, ionSlidesMounted]);

  return (
    <div className={styles.container}>
      <div className={styles.header} style={backgroundStyle}>
        <>
          <p
            className={classnames(styles.title, {
              [styles.hasBackground]: backgroundStyle !== undefined,
            })}
          >
            {title}
          </p>
          {detailButton && <div>{detailButton}</div>}
        </>
      </div>
      <div className={styles.spectrum} />
      <div className={styles.slidesContainer} style={{ height: slidesHeight }}>
        <div className={styles.slides} ref={slidesElRef}>
          {productBlockData != null ? (
            <MountNotifier onMount={handleIonSlidesMount}>
              <IonSlides
                ref={ionSlidesRef}
                onIonSlideDidChange={handleIonSlideDidChange}
              >
                {productBlockData.map((productBlockPage, page) => {
                  return (
                    <IonSlide key={page}>
                      <div className={styles.slideContent}>
                        {productBlockPage.map((productBlock, i) => {
                          const bundle =
                            bundleByProductId[productBlock.product.id];
                          const hasBundle =
                            bundle && bundle.items != null
                              ? bundle.items.length > 0
                              : false;
                          return (
                            <div
                              className={styles.productBlockContainer}
                              key={productBlock.key}
                            >
                              <ProductBlock
                                showOnlyInView={false}
                                product={productBlock.product}
                                productLabels={
                                  productLabelsByProductId
                                    ? productLabelsByProductId[
                                        productBlock.product.id
                                      ]
                                    : undefined
                                }
                                hasBundle={hasBundle}
                                rank={page * itemPerPage + i + 1}
                                hrefForProduct={hrefForProduct}
                                onAddToCart={onAddToCart}
                                onClickLikeButton={onClickLikeButton}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </IonSlide>
                  );
                })}
              </IonSlides>
            </MountNotifier>
          ) : null}
        </div>
      </div>
      <div className={styles.pager}>
        <Pager
          currentPage={_ionSlidesPage + 1}
          totalPages={totalPages}
          onNextClick={handleNextPageClick}
          onPrevClick={handlePrevPageClick}
          nextDisabled={!canSlidesNext}
          prevDisabled={!canSlidePrev}
        />
      </div>
    </div>
  );
};

export default HorizontalPaginatedProductList;
