import { useCallback, useEffect, useState } from "react";
import { CMS_PAGE_TYPE, ResolvedCMSPageContent } from "../../models/cmsBlock";
import { isRequestLoading } from "../../models/ResourcesRequestState";
import {
  useFetchCMSPageContent,
  useGetCMSPageContent,
} from "../../repository/CMSPageContentRepository";

interface ViewModel {
  cmsPageContent: ResolvedCMSPageContent | null;
  refresh: () => Promise<unknown>;

  isFullPageLoading: boolean;
}

function useViewModel(pageType: CMS_PAGE_TYPE): ViewModel {
  const getCMSPageContent = useGetCMSPageContent();

  const [
    cmsPageContent,
    setCMSPageContent,
  ] = useState<ResolvedCMSPageContent | null>(null);

  useEffect(() => {
    setCMSPageContent(getCMSPageContent(pageType));
  }, [pageType, getCMSPageContent]);

  const [
    fetchCMSPageRequestState,
    ,
    refreshCMSPageContent,
  ] = useFetchCMSPageContent();

  const isFullPageLoading =
    !cmsPageContent &&
    (fetchCMSPageRequestState.type === "initial" ||
      isRequestLoading(fetchCMSPageRequestState));

  const refresh = useCallback(async () => {
    setCMSPageContent(await refreshCMSPageContent(pageType));
  }, [refreshCMSPageContent, pageType]);

  return {
    cmsPageContent: cmsPageContent,
    refresh,
    isFullPageLoading,
  };
}

export default useViewModel;
