import { Plugins } from "@capacitor/core";
import { Crop } from "@ionic-native/crop";
import { isAndroid } from "../utils/Platform";

export async function crop(imageUrl: string): Promise<string> {
  if (isAndroid()) {
    const { path } = await Plugins.ImageCropper.crop({ imageUrl });
    return path;
  }
  return Crop.crop(imageUrl, { quality: 100 });
}
