declare module "@capacitor/core" {
  interface PluginRegistry {
    MigrateToV2Plugin: MigrateToV2Plugin;
  }
}

export enum V2Locale {
  en = "en",
  zhHant = "zh-Hant",
}

export interface MigrateToV2Plugin {
  setToken(options: { token: string | null }): Promise<void>;
  setLocale(options: { locale: V2Locale | null }): Promise<void>;
}
