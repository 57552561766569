import {
  ApolloClient,
  InMemoryCache,
  gql,
  FetchPolicy,
  NormalizedCacheObject,
  createHttpLink,
  from,
  ApolloLink,
  Observable,
  FetchResult,
  OperationVariables,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { PersistedData } from "apollo-cache-persist/types";
import { CachePersistor } from "apollo-cache-persist";
import * as Sentry from "sentry-cordova";
import Storage from "../storage";

import { makeCLGraphQLFetch } from "./fetch";
import { makeCLGraphQLFetch as makeCLGraphQLHashedQueryFetch } from "./hashedQueryFetch";

import { fetchMaintenanceStatus, restAPIClient } from "./RESTful";

import Config from "../Config";

import { TokenStore } from "./TokenStore";

import {
  LiveEvent,
  LiveEventGraphQLAttributes,
  LiveEventSchema,
} from "../models/AppConfig";
import {
  StoreConfig,
  StoreConfigGraphQLAttributes,
} from "../models/StoreConfig";
import {
  ProductOverviewBaseClubTierQuotaGraphQLAttributes,
  ProductOverviewVariantProductCubTierQuotaGraphQLAttributes,
  ProductEnableAgeDeclarationGraphQLAttributes,
  VariantProductEnableAgeDeclarationGraphQLAttributes,
  keyGraphQLAttributes as productKeyGraphQLAttributes,
  ProductThirdPartyProductShowPriceGraphQLAttributes,
  ProductThirdPartyProductShowPriceType,
  getIndexedProduct,
  RemoteProductOverviewBaseClubTierQuota,
  ProductEnableAgeDeclaration,
  VariantProductClubTierQuota,
  VariantProductEnableAgeDeclaration,
  ProductInstalmentType,
  ProductInstalmentGraphQLAttributes,
  ProductVariantProduct,
  getVariantGraphQLAttributes,
  ProductIsPreOrderGraphQLAttributes,
  ProductIsPreOrder,
  VariantProductIsPreOrder,
  VariantProductIsPreOrderGraphQLAttributes,
  RemoteProductEstimatedDeliveryDate,
  ProductEstimatedDeliveryDateGraphQLAttributes,
  RemoteVariantProductEstimatedDeliveryDate,
  VariantProductEstimatedDeliveryDateGraphQLAttributes,
  patchProductFromIndexedMap,
} from "../models/product";
import {
  Product,
  ProductBaseGraphQLAttributes,
  ProductDetailsAdditionalGraphQLAttributes,
  ProductDetailsRelatedProductsGraphQLAttributes,
  assembleProduct,
  RemoteProductBase,
  RemoteProductAdditional,
  ProductRelatedProducts,
} from "../models/ProductDetails";
import {
  ProductOverview,
  ProductOverviewGraphQLAttributes,
} from "../models/ProductOverview";
import {
  RemoteProductReview,
  ProductReview,
  ProductReviewSchema,
  ProductReviewGraphQLAttributes,
  RemoteCustomerProductReview,
  CustomerProductReview,
  CustomerProductReviewsSchema,
  CustomerProductReviewGraphqlAttributes,
  RatingCode,
  RatingCodeGraphQLAttributes,
  RatingVote,
} from "../models/ProductReview";
import {
  RawRemoteCategoryTree,
  CategoryTreeGraphQLAttributes,
} from "../models/category";
import {
  SimpleProductCartItemInput,
  serializeSimpleProductCartItemInput,
  CartGraphQLAttributes,
  ConfigurableProductCartItemInput,
  isConfigurableProductCartItemInput,
  serializeConfigurableProductCartItemInput,
  Cart,
} from "../models/cart";
import {
  MerchantID,
  EntityID as MerchantEntityID,
  MerchantPreview,
  MerchantPreviewGraphQLAttributes,
  Merchant,
  MerchantGraphQLAttributes,
} from "../models/Merchant";

import { Locale, getStoreViewCodeForLocale } from "../i18n/locale";
import {
  Customer,
  CustomerGraphQLAttributes,
  CustomerAddressGraphQLAttributes,
  RemoteAddress,
} from "../models/Customer";
import {
  CMSStaticBlockContent,
  CMSStaticBlockContentGraphQLAttributes,
  CMSPageContent,
  HTMLBasedCMSPageContent,
} from "../models/cmsBlock";
import {
  RemoteDistrict,
  RemoteCountry,
  CountryGraphQLAttributes,
  DistrictGraphQLAttributes,
} from "../models/CountryRegion";
import {
  SearchTerm,
  SearchAutoSuggestion,
  SearchAutoSuggestionGraphQLAttributes,
} from "../models/Search";
import { PageInfo, PageInfoGraphQLAttributes } from "../models/PageInfo";
import { EntityUrl, EntityUrlGraphQLAttributes } from "../models/EntityUrl";
import {
  FilterInputField,
  ProductFilterInfo,
  mapSortAttributeToGraphQLVariable,
  makeGraphQLFilter,
  GraphQLFilter,
  SortFieldOption,
  SortField,
  getApplicableProductFilterInfo,
  SortInputField,
  SortFields,
} from "../models/filter";
import {
  WishlistItem,
  WishlistItemGraphQLAttribtues,
} from "../models/Wishlist";
import {
  extractCMSBlocksFromContentForApp,
  extractCMSBlocksFromContentForAppWithWaitingToFillHTML,
} from "../utils/CMSBlockExtractor";
import {
  Aggregation,
  AggregationGraphQLAttributes,
} from "../models/LayeredNavigation";
import {
  OS,
  Platform,
  Token,
  Isdn,
  PNSResponse,
  NotificationEnableState,
} from "../models/OPNSPushNotification";
import { OppCard, OppCardGraphQLAttributes } from "../models/OppCard";
import {
  CustomerSubscriptionId,
  RemoteCustomerSubscription,
  CustomerSubscription,
  transformRemoteCustomerSubscriptionToCustomerSubscription,
  CustomerSubscriptionGraphQLAttributes,
} from "../models/CustomerSubscription";
import { fromMaybe, IndexMap, mapNullable } from "../utils/type";

import {
  networkEventEmitter,
  NetworkEventMaintenance,
} from "../utils/SimpleEventEmitter";
import {
  ServerVirtualWaitingRoomConfig,
  ServerVirtualWaitingRoomConfigGraphQLAttributes,
} from "../models/VirtualWaitingRoom";
import typePolicies from "./typePolicies";
import possibleTypes from "./possibleTypes.json";
import { profileAsyncAction } from "../utils/performance";
import { CacheSession } from "../utils/PerformanceRecordStore/sessions";

export type GraphQLFn<T> = (
  client: ApolloClient<any>,
  locale: Locale,
  ...args: any
) => Promise<T>;

export type GraphQLFnParams<F extends GraphQLFn<any>> = F extends (
  client: ApolloClient<any>,
  locale: Locale,
  ...args: infer A
) => Promise<any>
  ? A
  : never;

const httpLink = createHttpLink({
  uri: Config.GRAPHQL_ENDPOINT,
  fetch: Config.ENABLE_HASHED_QUERY
    ? makeCLGraphQLHashedQueryFetch()
    : makeCLGraphQLFetch({ useGETForQueries: true }),
});
const authContext = setContext((_, { headers }) => {
  if (TokenStore.accessToken == null) {
    return { headers };
  }

  const token = TokenStore.accessToken;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

/* eslint-disable complexity */
const errorLink = onError(error => {
  const { operation } = error;
  const { operationName } = operation;
  const { response } = error;
  const { graphQLErrors } = error;
  const errorMessage = parseGraphQLError(error);

  // Let sentry capture more info in breadcrumbs
  console.info(
    `GraphQL error: ${errorMessage} (operationName: ${operationName})`
  );

  if (error.graphQLErrors) {
    const internalServerErrors = error.graphQLErrors.filter(
      e => e.message === "Internal server error"
    );
    if (internalServerErrors.length > 0) {
      Sentry.withScope(scope => {
        scope.setExtra("response", error.response);
        for (const internalServerError of internalServerErrors) {
          Sentry.captureException(new Error(internalServerError.message));
        }
      });
    }
  }

  // remove query cart out of stock error
  if (
    graphQLErrors &&
    operationName === "QueryCart" &&
    response &&
    response.data &&
    response.data.cart &&
    response.data.cart.items.includes(null)
  ) {
    response.data.cart.items = response.data.cart.items.filter(
      (item: any) => !!item
    );
    (response as any).errors = null;
  }
  if (
    graphQLErrors &&
    operationName === "UpdateCartItemQuantity" &&
    response &&
    response.data &&
    response.data.updateCartItems &&
    response.data.updateCartItems.cart &&
    response.data.updateCartItems.cart.items.includes(null)
  ) {
    response.data.updateCartItems.cart.items = response.data.updateCartItems.cart.items.filter(
      (item: any) => !!item
    );
    (response as any).errors = null;
  }
  if (
    graphQLErrors &&
    operationName === "RemoveCartItem" &&
    response &&
    response.data &&
    response.data.removeItemFromCart &&
    response.data.removeItemFromCart.cart &&
    response.data.removeItemFromCart.cart.items.includes(null)
  ) {
    response.data.removeItemFromCart.cart.items = response.data.removeItemFromCart.cart.items.filter(
      (item: any) => !!item
    );
    (response as any).errors = null;
  }
  if (
    graphQLErrors &&
    operationName === "SetClubPointOnCart" &&
    response &&
    response.data &&
    response.data.setClubPointOnCart &&
    response.data.setClubPointOnCart.cart &&
    response.data.setClubPointOnCart.cart.items.includes(null)
  ) {
    response.data.setClubPointOnCart.cart.items = response.data.setClubPointOnCart.cart.items.filter(
      (item: any) => !!item
    );
    (response as any).errors = null;
  }
  if (graphQLErrors && operationName === "AddProductsToCarts") {
    if (
      response &&
      response.data &&
      response.data.addSimpleProductsToCart &&
      response.data.addSimpleProductsToCart.cart &&
      response.data.addSimpleProductsToCart.cart.items.includes(null)
    ) {
      response.data.addSimpleProductsToCart.cart.items = response.data.addSimpleProductsToCart.cart.items.filter(
        (item: any) => !!item
      );
      (response as any).errors = null;
    }
    if (
      response &&
      response.data &&
      response.data.addConfigurableProductsToCart &&
      response.data.addConfigurableProductsToCart.cart &&
      response.data.addConfigurableProductsToCart.cart.items.includes(null)
    ) {
      response.data.addConfigurableProductsToCart.cart.items = response.data.addConfigurableProductsToCart.cart.items.filter(
        (item: any) => !!item
      );
      (response as any).errors = null;
    }
  }
});
/* eslint-enable complexity */

/**
 * Use apollo link to handle errors raised by network or api server
 * Specific errors are checked and see if they are caused by maintenance mode
 * by checking maintenance mode flag.
 *
 * If maintenance mode is checked by this link, a maintenance event will be emitted.
 * The app should act according to emission of maintenance event.
 */
const maintenanceLink = new ApolloLink((operation, forward) => {
  return new Observable<FetchResult>(observer => {
    if (!forward) {
      observer.complete();
      return;
    }
    let sub: ReturnType<Observable<FetchResult>["subscribe"]> | null = null;
    try {
      sub = forward(operation).subscribe({
        next: result => {
          // Check for api server error. There maybe some error messages from server
          // we should care to check the maintenance mode.
          if (result.errors) {
            const errorMessage = parseGraphQLError({
              graphQLErrors: result.errors,
            });
            if (errorMessage) {
              const errorMessagesForMaintenanceCheck: string[] = [];
              if (errorMessagesForMaintenanceCheck.length === 0) {
                observer.next(result);
                return;
              }
              const errorMessagesForMaintenanceCheckRegExp = new RegExp(
                errorMessagesForMaintenanceCheck.join("|")
              );
              if (errorMessagesForMaintenanceCheckRegExp.exec(errorMessage)) {
                fetchMaintenanceStatus(restAPIClient).then(isMaintenance => {
                  if (!isMaintenance) {
                    observer.next(result);
                  } else {
                    networkEventEmitter.publish(NetworkEventMaintenance());
                    observer.complete();
                  }
                });
                return;
              }
            }
          }
          observer.next(result);
        },
        error: networkError => {
          // Check for network error. Mainly server inaccessable.
          if (networkError) {
            const errorMessage = networkError.message;
            if (errorMessage) {
              const errorMessagesForMaintenanceCheck: string[] = [
                "Failed to fetch",
                // ios
                "(.+)is not allowed by Access-Control-Allow-Origin.",
              ];
              if (errorMessagesForMaintenanceCheck.length === 0) {
                observer.error(networkError);
                return;
              }
              const errorMessagesForMaintenanceCheckRegExp = new RegExp(
                errorMessagesForMaintenanceCheck.join("|")
              );
              if (errorMessagesForMaintenanceCheckRegExp.exec(errorMessage)) {
                fetchMaintenanceStatus(restAPIClient).then(isMaintenance => {
                  if (!isMaintenance) {
                    observer.error(networkError);
                  } else {
                    networkEventEmitter.publish(NetworkEventMaintenance());
                    observer.complete();
                  }
                });
                return;
              }
            }
          }
          observer.error(networkError);
        },
        complete: () => {
          observer.complete();
        },
      });
    } catch (e) {
      observer.error(e);
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  });
});

const cache = new InMemoryCache({
  typePolicies: typePolicies,
  possibleTypes,
});

export const cachePersistor = new CachePersistor({
  cache,
  storage: {
    getItem: async (key: string): Promise<NormalizedCacheObject> => {
      return profileAsyncAction(
        CacheSession(),
        "Load Cache from Storage",
        async () => {
          const result = await Storage.getItem<NormalizedCacheObject>(key);
          if (result == null) {
            return {};
          }
          return result;
        }
      );
    },
    setItem: async (
      key: string,
      data: PersistedData<NormalizedCacheObject>
    ): Promise<void> => {
      profileAsyncAction(CacheSession(), "Save Cache to Storage", async () => {
        try {
          await Storage.setItem(key, data);
        } catch {
          await Storage.remove(key);
        }
      });
    },
    removeItem: async (key: string): Promise<void> => {
      await Storage.remove(key);
    },
  },
  trigger: "write",
  debounce: 5000,
  serialize: false,
  maxSize: 4000000,
});

// This is the internal apolloClient
//
// Please use getApolloClient to ensure this instance is initialized properly
export const apolloClient = new ApolloClient({
  link: from([errorLink, maintenanceLink, authContext, httpLink]),
  cache,
});

// see: https://www.apollographql.com/docs/react/features/error-handling/#error-policies
//
// return null if not able to parse the error
export function parseGraphQLError(error: any): string | null {
  if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0) {
    if (error.graphQLErrors.length > 1) {
      console.warn("parseGraphQLError, graphQLErrors.length > 1");
    }

    const { message, debugMessage } = error.graphQLErrors[0];

    if (message === "Internal server error") {
      return debugMessage || message;
    }

    return message;
  }

  if (error.networkError != null) {
    return error.networkError.message;
  }

  console.warn(
    "parseGraphQLError, neither graphQLErrors nor networkError found",
    error
  );
  return null;
}

export async function fetchStoreConfig(
  client: ApolloClient<any>,
  locale: Locale
): Promise<StoreConfig> {
  const result = await client.query({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      {
        storeConfig {
          ${StoreConfigGraphQLAttributes}
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return result.data.storeConfig;
}

export async function fetchLiveEvent(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<LiveEvent | null> {
  const result = await client.query<{
    appConfig: {
      liveEvent: LiveEvent;
    };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      {
        appConfig {
          ${LiveEventGraphQLAttributes}
        }
      }
    `,
    fetchPolicy,
  });
  const liveEvent = LiveEventSchema.validateSync(
    result.data.appConfig.liveEvent
  );
  return liveEvent;
}

export async function fetchVirtualWaitingRoomConfig(
  client: ApolloClient<any>
): Promise<ServerVirtualWaitingRoomConfig | null> {
  try {
    const result = await client.query<{
      appConfig: ServerVirtualWaitingRoomConfig;
    }>({
      query: gql`
    query fetchVirtualWaitingRoomConfig {
      appConfig {
        ${ServerVirtualWaitingRoomConfigGraphQLAttributes}
      }
    }`,
    });
    if (result.data && result.data.appConfig) {
      return result.data.appConfig;
    }
    return null;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function fetchProductSKUByUrlKey(
  client: ApolloClient<any>,
  urlKey: string
): Promise<string | null> {
  const result = await client.query<
    {
      products: { items: [{ sku: string }] } | undefined;
    },
    {
      urlKey: string;
    }
  >({
    query: gql`
      query QueryProductSKUbyUrlKey($urlKey: String!) {
        products(filter: { url_key: { eq: $urlKey } }) {
          items {
            sku
          }
        }
      }
    `,
    variables: {
      urlKey,
    },
  });

  if (!result.data.products) {
    return null;
  }

  const item = result.data.products.items[0];
  if (!item) {
    return null;
  }

  return item.sku;
}

export function getProduct(
  client: ApolloClient<any>,
  sku: string
): Product | null {
  const query = <P>(graphQLAttributes: string) =>
    client.readQuery<{
      products: { items: P[] } | undefined;
    }>({
      query: gql`
      query QueryProductBySKU($sku: String!) {
        products(filter: { sku: { eq: $sku } }) {
          items {
            ${graphQLAttributes}
          }
        }
      }
    `,
      variables: {
        sku,
      },
    });

  const _getProduct = <P>(
    obj: { products: { items: P[] } | undefined } | null
  ) => {
    return obj && obj.products && obj.products.items.length > 0
      ? obj.products.items[0]
      : null;
  };

  const baseData = query<RemoteProductBase>(ProductBaseGraphQLAttributes);
  const additionalData = query<RemoteProductAdditional>(
    ProductDetailsAdditionalGraphQLAttributes
  );
  // FEATURE_MERGE: club_tier_quota present in product interface => [0]
  const clubTierQuotaData = query<RemoteProductOverviewBaseClubTierQuota>(
    ProductOverviewBaseClubTierQuotaGraphQLAttributes
  );
  // FEATURE_MERGE: enable_age_declaration present in product interface => [0]
  const enableAgeDeclarationData = query<ProductEnableAgeDeclaration>(
    ProductEnableAgeDeclarationGraphQLAttributes
  );
  // FEATURE_MERGE: club_tier_quota present in product.variants.product interface => [0]
  const variantProductClubTierQuotaData = query<VariantProductClubTierQuota>(
    ProductOverviewVariantProductCubTierQuotaGraphQLAttributes
  );
  // FEATURE_MERGE: enable_age_declaration present in product.variants.product interface => [0]
  const variantEnableAgeDeclarationData = query<
    VariantProductEnableAgeDeclaration
  >(VariantProductEnableAgeDeclarationGraphQLAttributes);
  const thirdPartyProductShowPriceData = query<
    ProductThirdPartyProductShowPriceType
  >(ProductThirdPartyProductShowPriceGraphQLAttributes);
  const instalmentData = query<ProductInstalmentType>(
    ProductInstalmentGraphQLAttributes
  );
  const variantInstalmentData = query<
    ProductVariantProduct<ProductInstalmentType>
  >(getVariantGraphQLAttributes(ProductInstalmentGraphQLAttributes));
  const isPreOrderData = query<ProductIsPreOrder>(
    ProductIsPreOrderGraphQLAttributes
  );
  const variantIsPreOrderData = query<VariantProductIsPreOrder>(
    VariantProductIsPreOrderGraphQLAttributes
  );
  const estimatedDeliveryDateData = query<RemoteProductEstimatedDeliveryDate>(
    ProductEstimatedDeliveryDateGraphQLAttributes
  );
  const variantEstimatedDeliveryDateData = query<
    RemoteVariantProductEstimatedDeliveryDate
  >(VariantProductEstimatedDeliveryDateGraphQLAttributes);

  const base = _getProduct(baseData);
  const additional = _getProduct(additionalData);
  const clubTierQuota = _getProduct(clubTierQuotaData);
  const enableAgeDeclaration = _getProduct(enableAgeDeclarationData);
  const variantProductClubTierQuota = _getProduct(
    variantProductClubTierQuotaData
  );
  const variantEnableAgeDeclaration = _getProduct(
    variantEnableAgeDeclarationData
  );
  const thirdPartyProductShowPrice = _getProduct(
    thirdPartyProductShowPriceData
  );
  const instalment = _getProduct(instalmentData);
  const variantInstalment = _getProduct(variantInstalmentData);
  const isPreOrder = _getProduct(isPreOrderData);
  const variantIsPreOrder = _getProduct(variantIsPreOrderData);
  const estiamtedDeliveryDate = _getProduct(estimatedDeliveryDateData);
  const variantEstimatedDeliveryDate = _getProduct(
    variantEstimatedDeliveryDateData
  );

  if (!base || !additional) {
    return null;
  }

  return assembleProduct(
    base,
    additional,
    clubTierQuota,
    enableAgeDeclaration,
    variantProductClubTierQuota,
    variantEnableAgeDeclaration,
    thirdPartyProductShowPrice,
    instalment,
    variantInstalment,
    isPreOrder,
    variantIsPreOrder,
    estiamtedDeliveryDate,
    variantEstimatedDeliveryDate
  );
}

export async function fetchProduct(
  client: ApolloClient<any>,
  sku: string,
  locale: Locale,
  // Do not use cache version of this api
  // because there are still objects returned even
  // some fields are missing (unexpected undefined)
  // and default partialRefetch (false) is not working
  fetchPolicy: Exclude<FetchPolicy, "cache-only" | "cache-first">
): Promise<Product | null> {
  const query = <P>(
    graphQLAttributes: string
  ): Promise<{
    data: {
      products: { items: P[] } | undefined;
    } | null;
  }> =>
    client.query<{
      products:
        | {
            items: P[];
          }
        | undefined;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryProductBySKU($sku: String!) {
          products(filter: { sku: { eq: $sku } }) {
            items {
              ${graphQLAttributes}
            }
          }
        }
      `,
      variables: {
        sku,
      },
      fetchPolicy,
    });

  const _getProduct = <P>(
    obj: { products: { items: P[] } | undefined } | null
  ) => {
    return obj && obj.products && obj.products.items.length > 0
      ? obj.products.items[0]
      : null;
  };

  const [
    [
      baseData,
      additionalData,
      clubTierQuotaData,
      enableAgeDeclarationData,
      variantProductClubTierQuotaData,
      variantEnableAgeDeclarationData,
      thirdPartyProductShowPriceData,
      instalmentData,
      variantInstalmentData,
    ],
    [
      isPreOrderData,
      variantIsPreOrderData,
      estimatedDeliveryDateData,
      variantEstimatedDeliveryDateData,
    ],
  ] = await Promise.all([
    Promise.all([
      query<RemoteProductBase>(ProductBaseGraphQLAttributes),
      query<RemoteProductAdditional>(ProductDetailsAdditionalGraphQLAttributes),
      // FEATURE_MERGE: club_tier_quota present in product interface => [0]
      query<RemoteProductOverviewBaseClubTierQuota>(
        ProductOverviewBaseClubTierQuotaGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
      // FEATURE_MERGE: enable_age_declaration present in product interface => [0]
      query<ProductEnableAgeDeclaration>(
        ProductEnableAgeDeclarationGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
      // FEATURE_MERGE: club_tier_quota present in product.variants.product interface => [0]
      query<VariantProductClubTierQuota>(
        ProductOverviewVariantProductCubTierQuotaGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
      // FEATURE_MERGE: enable_age_declaration present in product.variants.product interface => [0]
      query<VariantProductEnableAgeDeclaration>(
        VariantProductEnableAgeDeclarationGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
      query<ProductThirdPartyProductShowPriceType>(
        ProductThirdPartyProductShowPriceGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
      query<ProductInstalmentType>(ProductInstalmentGraphQLAttributes).catch(
        () => ({ data: { products: { items: [] } } })
      ),
      query<ProductVariantProduct<ProductInstalmentType>>(
        getVariantGraphQLAttributes(ProductInstalmentGraphQLAttributes)
      ).catch(() => ({ data: { products: { items: [] } } })),
    ]),
    Promise.all([
      query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes).catch(
        () => ({ data: { products: { items: [] } } })
      ),
      query<VariantProductIsPreOrder>(
        VariantProductIsPreOrderGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
      query<RemoteProductEstimatedDeliveryDate>(
        ProductEstimatedDeliveryDateGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
      query<RemoteVariantProductEstimatedDeliveryDate>(
        VariantProductEstimatedDeliveryDateGraphQLAttributes
      ).catch(() => ({ data: { products: { items: [] } } })),
    ]),
  ]);

  const base = _getProduct(baseData.data);
  const additional = _getProduct(additionalData.data);
  const clubTierQuota = _getProduct(clubTierQuotaData.data);
  const enableAgeDeclaration = _getProduct(enableAgeDeclarationData.data);
  const variantProductClubTierQuota = _getProduct(
    variantProductClubTierQuotaData.data
  );
  const variantEnableAgeDeclaration = _getProduct(
    variantEnableAgeDeclarationData.data
  );
  const thirdPartyProductShowPrice = _getProduct(
    thirdPartyProductShowPriceData.data
  );
  const instalment = _getProduct(instalmentData.data);
  const variantInstalment = _getProduct(variantInstalmentData.data);
  const isPreOrder = _getProduct(isPreOrderData.data);
  const variantIsPreOrder = _getProduct(variantIsPreOrderData.data);
  const estiamtedDeliveryDate = _getProduct(estimatedDeliveryDateData.data);
  const variantEstimatedDeliveryDate = _getProduct(
    variantEstimatedDeliveryDateData.data
  );

  if (!base || !additional) {
    return null;
  }

  return assembleProduct(
    base,
    additional,
    clubTierQuota,
    enableAgeDeclaration,
    variantProductClubTierQuota,
    variantEnableAgeDeclaration,
    thirdPartyProductShowPrice,
    instalment,
    variantInstalment,
    isPreOrder,
    variantIsPreOrder,
    estiamtedDeliveryDate,
    variantEstimatedDeliveryDate
  );
}

export function getProductRelatedProducts(
  client: ApolloClient<unknown>,
  sku: string
): ProductOverview[] {
  const res = client.readQuery<
    {
      products: { items: ProductRelatedProducts[] } | undefined;
    },
    { sku: string }
  >({
    query: gql`
      query QueryProductBySKU($sku: String!) {
        products(filter: { sku: { eq: $sku } }) {
          items {
            ${ProductDetailsRelatedProductsGraphQLAttributes}
          }
        }
      }`,

    variables: { sku },
  });
  return fromMaybe(
    [],
    res && res.products && res.products.items && res.products.items.length > 0
      ? res.products.items[0].relatedProducts
      : null
  );
}

export async function fetchProductRelatedProducts(
  client: ApolloClient<any>,
  sku: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductOverview[]> {
  const res = await client.query<
    {
      products: { items: ProductRelatedProducts[] } | undefined;
    },
    { sku: string }
  >({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
        query QueryProductBySKU($sku: String!) {
          products(filter: { sku: { eq: $sku } }) {
            items {
              ${ProductDetailsRelatedProductsGraphQLAttributes}
            }
          }
        }
      `,
    variables: {
      sku,
    },
    fetchPolicy,
  });
  return fromMaybe(
    [],
    res &&
      res.data.products &&
      res.data.products.items &&
      res.data.products.items.length > 0
      ? res.data.products.items[0].relatedProducts
      : null
  );
}

export function getProductReviews(
  client: ApolloClient<any>,
  sku: string
): ProductReview[] {
  const result = client.readQuery<{
    products: { items: { review: RemoteProductReview[] }[] };
  }>({
    query: gql`
      query QueryProductReviews($sku: String!) {
        products(filter: { sku: { eq: $sku } }) {
          items {
            ${productKeyGraphQLAttributes}
            review {
              ${ProductReviewGraphQLAttributes}
            }
          }
        }
      }
    `,
    variables: {
      sku,
    },
  });

  if (!result || result.products.items.length === 0) {
    return [];
  }

  const [product] = result.products.items;

  if (product.review == null) {
    return [];
  }

  const productReviews = ProductReviewSchema.validateSync(product.review);

  return productReviews;
}

export async function fetchProductReviews(
  client: ApolloClient<any>,
  sku: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductReview[]> {
  const result = await client.query<{
    products: { items: { review: RemoteProductReview[] }[] };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryProductReviews($sku: String!) {
        products(filter: { sku: { eq: $sku } }) {
          items {
            ${productKeyGraphQLAttributes}
            review {
              ${ProductReviewGraphQLAttributes}
            }
          }
        }
      }
    `,
    variables: {
      sku,
    },
    fetchPolicy,
  });

  if (result.data.products == null || result.data.products.items.length === 0) {
    return [];
  }

  const [product] = result.data.products.items;

  if (product.review == null) {
    return [];
  }

  const productReviews = ProductReviewSchema.validateSync(product.review);

  return productReviews;
}

export async function fetchCustomerProductReviews(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CustomerProductReview[]> {
  const result = await client.query<{
    customer: { productReviews: RemoteCustomerProductReview[] };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryCustomerProductReviews {
        customer {
          id
          productReviews: product_reviews {
            ${CustomerProductReviewGraphqlAttributes}
          }
        }
      }
    `,
    fetchPolicy,
  });
  if (
    result.data.customer == null ||
    result.data.customer.productReviews == null ||
    result.data.customer.productReviews.length === 0
  ) {
    return [];
  }

  const { productReviews } = result.data.customer;

  return CustomerProductReviewsSchema.validateSync(productReviews);
}

export async function fetchRatingCodes(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RatingCode[]> {
  const result = await client.query<{
    ratingCode: RatingCode[];
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryRatingCodes {
        ratingCode {
          ${RatingCodeGraphQLAttributes}
        }
      }
    `,
    fetchPolicy,
  });

  if (result.data.ratingCode == null) {
    return [];
  }

  return result.data.ratingCode;
}

export async function addProductReview(
  client: ApolloClient<any>,
  ratingVote: RatingVote[],
  productId: number,
  name: string,
  detail: string,
  locale: Locale
) {
  const requestTemplate = <V extends OperationVariables>(
    mutation: string,
    variables: V
  ) =>
    client.mutate<
      {
        addProductReview: { reviewId: number };
      },
      V
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql(mutation),
      variables,
      fetchPolicy: "no-cache",
    });

  const request = () =>
    requestTemplate<{
      ratingVote: { option_id: number; rating_id: number }[];
      productId: number;
      nickname: string;
      detail: string;
    }>(
      `mutation AddProductReview(
        $ratingVote: [RatingVoteInput]!
        $productId: Int!
        $nickname: String!
        $detail: String!
      ) {
        addProductReview(
          input: {
            rating_vote: $ratingVote
            product_id: $productId
            nickname: $nickname
            detail: $detail
          }
        ) {
          reviewId: review_id
        }
      }`,
      {
        ratingVote: ratingVote.map(({ optionId, ratingId }) => ({
          option_id: optionId,
          rating_id: ratingId,
        })),
        productId,
        nickname: name,
        detail,
      }
    );

  const requestFallback = () =>
    requestTemplate<{
      ratingVote: { option_id: number; rating_id: number }[];
      productId: number;
      detail: string;
    }>(
      `mutation AddProductReview(
        $ratingVote: [RatingVoteInput]!
        $productId: Int!
        $detail: String!
      ) {
        addProductReview(
          input: {
            rating_vote: $ratingVote
            product_id: $productId
            detail: $detail
          }
        ) {
          reviewId: review_id
        }
      }`,
      {
        ratingVote: ratingVote.map(({ optionId, ratingId }) => ({
          option_id: optionId,
          rating_id: ratingId,
        })),
        productId,
        detail,
      }
    );

  const result = Config.ENABLE_SET_NAME_ON_RODUCT_REVIEW
    ? // Handle when feature flag is enabled but api is not supported
      await request().catch(requestFallback)
    : // Handle when feature is not enabled but api is changed
      await requestFallback().catch(request);

  if (result.data == null) {
    throw new Error();
  }

  return result.data.addProductReview.reviewId;
}

export function getProductOverviewsBySKUs(
  client: ApolloClient<any>,
  skus: string[]
): ProductOverview[] | null {
  const result = client.readQuery<{ products: { items: ProductOverview[] } }>({
    query: gql`
      query QueryProductOverviewsBySKUs($skus: [String], $pageSize: Int!) {
        products(filter: { sku: { in: $skus } }, pageSize: $pageSize) {
          items {
            ${ProductOverviewGraphQLAttributes}
            ${ProductThirdPartyProductShowPriceGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      skus,
      pageSize: skus.length,
    },
  });

  if (!result || result.products == null) {
    return null;
  }

  // The result from this query is not in the order of input skus
  // so make it in order
  const productOverviews: ProductOverview[] = [];

  for (let i = 0; i < skus.length; i++) {
    const sku = skus[i];
    const productOverview = result.products.items.filter(p => p.sku === sku)[0];
    if (!productOverview) {
      console.warn(`Missing product of sku ${sku} from QueryProductBySKU`);
      continue;
    }
    productOverviews.push(productOverview);
  }

  return productOverviews;
}

export async function fetchProductOverviewsBySKUs(
  client: ApolloClient<any>,
  skus: string[],
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductOverview[] | null> {
  const query = async <T>(graphQLAttributes: string) =>
    client.query<{ products: { items: T[] } }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryProductOverviewsBySKUs($skus: [String], $pageSize: Int!) {
        products(filter: { sku: { in: $skus } }, pageSize: $pageSize) {
          items {
            ${graphQLAttributes}
          }
        }
      }
    `,
      variables: {
        skus,
        pageSize: skus.length,
      },
      fetchPolicy,
    });

  const [
    result,
    thirdPartyProductShowPriceResult,
    isPreOrderResult,
  ] = await Promise.all([
    query<ProductOverview>(ProductOverviewGraphQLAttributes),
    query<ProductThirdPartyProductShowPriceType>(
      ProductThirdPartyProductShowPriceGraphQLAttributes
    ).catch(() => ({ data: { products: { items: [] } } })),
    query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes).catch(() => ({
      data: { products: { items: [] } },
    })),
  ]);

  if (result.data.products == null) {
    return null;
  }

  const skuThirdPartyProductShowPriceMap =
    thirdPartyProductShowPriceResult &&
    thirdPartyProductShowPriceResult.data &&
    thirdPartyProductShowPriceResult.data.products &&
    thirdPartyProductShowPriceResult.data.products.items
      ? getIndexedProduct(thirdPartyProductShowPriceResult.data.products.items)
      : {};

  const isPreOrderMap =
    getIndexedProduct(isPreOrderResult.data.products.items) || {};

  // The result from this query is not in the order of input skus
  // so make it in order
  const productOverviews: ProductOverview[] = [];

  for (let i = 0; i < skus.length; i++) {
    const sku = skus[i];
    const productOverview = result.data.products.items.filter(
      p => p.sku === sku
    )[0];
    if (!productOverview) {
      console.warn(`Missing product of sku ${sku} from QueryProductBySKU`);
      continue;
    }
    productOverviews.push(
      patchProductFromIndexedMap(
        patchProductFromIndexedMap(
          productOverview,
          skuThirdPartyProductShowPriceMap
        ),
        isPreOrderMap
      )
    );
  }

  return productOverviews;
}

export async function loginWithEmail(
  client: ApolloClient<any>,
  email: string,
  password: string
): Promise<string> {
  const result = await client.mutate<{
    generateCustomerToken: { token: string };
  }>({
    mutation: gql`
      mutation Login($email: String!, $password: String!) {
        generateCustomerToken(email: $email, password: $password) {
          token
        }
      }
    `,
    variables: {
      email,
      password,
    },
    fetchPolicy: "no-cache",
  });

  if (result.data == null) {
    throw new Error();
  }
  return result.data.generateCustomerToken.token;
}

export function getCategoryList(
  client: ApolloClient<any>,
  categoryId: number
): RawRemoteCategoryTree | null {
  const result = client.readQuery<
    { clCategoryList: RawRemoteCategoryTree },
    { categoryId: string }
  >({
    query: gql`
      query CLCategoryList($categoryId: String) {
        clCategoryList(categoryId: $categoryId)
      }
    `,
    variables: {
      categoryId: `${categoryId}`,
    },
  });

  if (!result || result.clCategoryList == null) {
    return null;
  }
  return result.clCategoryList;
}

export function storeCategoryList(
  client: ApolloClient<unknown>,
  categoryId: number,
  remoteCategoryTree: RawRemoteCategoryTree
) {
  client.writeQuery<
    { clCategoryList: RawRemoteCategoryTree },
    { categoryId: string }
  >({
    query: gql`
      query CLCategoryList($categoryId: String) {
        clCategoryList(categoryId: $categoryId)
      }
    `,
    variables: {
      categoryId: `${categoryId}`,
    },
    data: { clCategoryList: remoteCategoryTree },
  });
}

export async function fetchCategoryList(
  client: ApolloClient<any>,
  categoryId: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RawRemoteCategoryTree | null> {
  const result = await client.query<{ categoryList: RawRemoteCategoryTree[] }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryCategoryList($categoryId: String) {
        categoryList(filters: { ids: { eq: $categoryId } }) {
          ${CategoryTreeGraphQLAttributes}
        }
      }
    `,
    variables: {
      categoryId: `${categoryId}`,
    },
    fetchPolicy,
  });

  if (
    result.data.categoryList == null ||
    result.data.categoryList.length === 0
  ) {
    return null;
  }
  return result.data.categoryList[0];
}

export async function signupWithEmail(
  client: ApolloClient<any>,
  locale: Locale,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  isSubscribeToNewsletter: boolean
): Promise<number> {
  try {
    const result = await client.mutate<{
      createCustomer: {
        customer: { id: number };
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation Signup(
          $firstName: String!
          $lastName: String!
          $email: String!
          $password: String!
          $isSubscribeToNewsletter: Boolean!
        ) {
          createCustomer(
            input: {
              firstname: $firstName
              lastname: $lastName
              email: $email
              password: $password
              is_subscribed: $isSubscribeToNewsletter
            }
          ) {
            customer {
              id
            }
          }
        }
      `,
      variables: {
        firstName,
        lastName,
        email,
        password,
        isSubscribeToNewsletter,
      },
      fetchPolicy: "no-cache",
    });

    if (result.data == null) {
      throw new Error();
    }

    return result.data.createCustomer.customer.id;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export function getCategoryDescriptionFromCategoryListByCategoryId(
  client: ApolloClient<any>,
  categoryId: number
): string | null {
  const result = client.readQuery<{
    categoryList: { description: string | null }[];
  }>({
    query: gql`
      query QueryDescriptionByCategoryId($categoryId: String) {
        categoryList(filters: { ids: { eq: $categoryId } }) {
          id
          description
        }
      }
    `,
    variables: {
      categoryId: `${categoryId}`,
    },
  });
  if (
    !result ||
    result.categoryList == null ||
    result.categoryList.length === 0
  ) {
    return null;
  }
  return result.categoryList[0].description;
}

export async function fetchCategoryDescriptionFromCategoryListByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<string | null> {
  const result = await client.query<{
    categoryList: { description: string | null }[];
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryDescriptionByCategoryId($categoryId: String) {
        categoryList(filters: { ids: { eq: $categoryId } }) {
          id
          description
        }
      }
    `,
    variables: {
      categoryId: `${categoryId}`,
    },
    fetchPolicy,
  });
  if (
    result.data.categoryList == null ||
    result.data.categoryList.length === 0
  ) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }
  return result.data.categoryList[0].description;
}

export function getProductOverviewsFromCategoryListByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  page: number,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
): {
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null {
  const { sortAttribute } = productFilterInfo;
  const sortInput =
    mapSortAttributeToGraphQLVariable(sortAttribute) || undefined;
  const query = <T>(graphQLAttributes: string) =>
    client.readQuery<{
      products: {
        items: T[];
        pageInfo: { totalPages: number; pageSize: number };
      };
    }>({
      query: gql`
      query QueryProductsByCategoryId(
        $page: Int,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput,
      ) {
        products(
          pageSize: 20,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          filter: $filter
        ) {
          items {
            ${graphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
      variables: {
        page,
        sort: sortInput,
        filter: {
          category_id: { eq: `${categoryId}` },
          ...(productFilterInfo
            ? makeGraphQLFilter(
                getApplicableProductFilterInfo(productFilterInfo),
                productAttributeFilterInputMap
              )
            : {}),
        },
      },
    });

  const [result, thirdPartyProductShowPriceResult, isPreOrderResult] = [
    query<ProductOverview>(ProductOverviewGraphQLAttributes),
    query<ProductThirdPartyProductShowPriceType>(
      ProductThirdPartyProductShowPriceGraphQLAttributes
    ),
    query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes),
  ];

  if (!result || result.products == null) {
    return null;
  }

  const skuThirdPartyProductShowPriceMap =
    thirdPartyProductShowPriceResult &&
    thirdPartyProductShowPriceResult.products &&
    thirdPartyProductShowPriceResult.products.items
      ? getIndexedProduct(thirdPartyProductShowPriceResult.products.items)
      : {};

  const isPreOrderMap = fromMaybe(
    {},
    mapNullable(isPreOrderResult, r => getIndexedProduct(r.products.items))
  );

  const { products: _products } = result;

  return {
    productOverviews: _products.items.map(item =>
      patchProductFromIndexedMap(
        patchProductFromIndexedMap(item, skuThirdPartyProductShowPriceMap),
        isPreOrderMap
      )
    ),
    hasMore: _products.pageInfo.totalPages > page,
    pageSize: _products.pageInfo.pageSize,
  };
}

export async function fetchProductOverviewsFromCategoryListByCategoryId(
  client: ApolloClient<any>,
  categoryId: number,
  page: number,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const { sortAttribute } = productFilterInfo;
  const sortInput =
    mapSortAttributeToGraphQLVariable(sortAttribute) || undefined;
  const query = <T>(graphQLAttributes: string) =>
    client.query<{
      products: {
        items: T[];
        pageInfo: { totalPages: number; pageSize: number };
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryProductsByCategoryId(
        $page: Int,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput,
      ) {
        products(
          pageSize: 20,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          filter: $filter
        ) {
          items {
            ${graphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
      variables: {
        page,
        sort: sortInput,
        filter: {
          category_id: { eq: `${categoryId}` },
          ...(productFilterInfo
            ? makeGraphQLFilter(
                getApplicableProductFilterInfo(productFilterInfo),
                productAttributeFilterInputMap
              )
            : {}),
        },
      },
      fetchPolicy,
    });

  const [
    result,
    thirdPartyProductShowPriceResult,
    isPreOrderResult,
  ] = await Promise.all([
    query<ProductOverview>(ProductOverviewGraphQLAttributes),
    query<ProductThirdPartyProductShowPriceType>(
      ProductThirdPartyProductShowPriceGraphQLAttributes
    ).catch(() => ({ data: { products: { items: [] } } })),
    query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes).catch(() => ({
      data: { products: { items: [] } },
    })),
  ]);

  if (result.data.products == null) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }

  const skuThirdPartyProductShowPriceMap =
    thirdPartyProductShowPriceResult &&
    thirdPartyProductShowPriceResult.data &&
    thirdPartyProductShowPriceResult.data.products &&
    thirdPartyProductShowPriceResult.data.products.items
      ? getIndexedProduct(thirdPartyProductShowPriceResult.data.products.items)
      : {};

  const isPreOrderMap = fromMaybe(
    {},
    getIndexedProduct(isPreOrderResult.data.products.items)
  );

  const { products: _products } = result.data;

  return {
    productOverviews: _products.items.map(item =>
      patchProductFromIndexedMap(
        patchProductFromIndexedMap(item, skuThirdPartyProductShowPriceMap),
        isPreOrderMap
      )
    ),
    hasMore: _products.pageInfo.totalPages > page,
    pageSize: _products.pageInfo.pageSize,
  };
}

export async function getMyCustomer(
  client: ApolloClient<any>,
  locale: Locale
): Promise<Customer | null> {
  const result = await client.query<{ customer: Customer | null }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query GetMyCustomer {
        customer {
          ${CustomerGraphQLAttributes}
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  return result.data.customer;
}

export async function updateMyCustomerInterestCategories(
  client: ApolloClient<any>,
  categoryIds: number[]
): Promise<Customer> {
  const categoryIdsInString = categoryIds.map(String);
  const result = await client.mutate<{
    updateCustomer: { customer: Customer | null };
  }>({
    mutation: gql`
    mutation updateCustomer($categoryIds: [String]!) {
      updateCustomer(
          input: {
            interest: $categoryIds,
            has_interests_set: false
          }
        ) {
          customer {
            ${CustomerGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      categoryIds: categoryIdsInString,
    },
    fetchPolicy: "no-cache",
  });

  if (result.data == null || result.data.updateCustomer.customer == null) {
    throw new Error();
  }

  return result.data.updateCustomer.customer;
}

export async function updateMyCustomerInfo(
  client: ApolloClient<any>,
  firstName: string,
  lastName: string,
  isSubscribeToNewsletter: boolean,
  updatedProfilePic?: string
): Promise<Customer> {
  if (updatedProfilePic) {
    await client.mutate<{
      updateCustomer: { customer: Customer | null };
    }>({
      mutation: gql`
        mutation updateCustomerProfilePicture($file: String!) {
          updateCustomerProfilePicture(file: $file)
        }
      `,
      variables: {
        file: updatedProfilePic,
      },
      fetchPolicy: "no-cache",
    });
  }
  const result = await client.mutate<{
    updateCustomer: { customer: Customer | null };
  }>({
    mutation: gql`
      mutation updateCustomer(
        $firstName: String!,
        $lastName: String!,
        $isSubscribeToNewsletter: Boolean!) {
        updateCustomer(
            input: {
              firstname: $firstName
              lastname: $lastName
              is_subscribed: $isSubscribeToNewsletter
            }
          ) {
            customer {
              ${CustomerGraphQLAttributes}
            }
          }
        }
      `,
    variables: {
      firstName,
      lastName,
      isSubscribeToNewsletter,
    },
    fetchPolicy: "no-cache",
  });

  if (result.data == null || result.data.updateCustomer.customer == null) {
    throw new Error();
  }

  return result.data.updateCustomer.customer;
}

export async function updateMyCustomerEmail(
  client: ApolloClient<any>,
  email: string,
  password: string,
  locale: Locale
): Promise<void> {
  try {
    const result = await client.mutate<{
      updateCustomerEmail: { reject_reason: string | null; success: boolean };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation updateCustomerEmail($email: String!, $password: String!) {
          updateCustomerEmail(new_email: $email, current_password: $password) {
            reject_reason
            success
          }
        }
      `,
      variables: {
        email,
        password,
      },
      fetchPolicy: "no-cache",
    });
    if (result.data == null) {
      throw new Error();
    }
    const { success, reject_reason } = result.data.updateCustomerEmail;
    if (!success) {
      throw new Error(reject_reason || undefined);
    }
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}

export async function resendChangeEmailConfirmation(
  client: ApolloClient<any>,
  locale: Locale
): Promise<void> {
  await client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation {
        resendCustomerEmailUpdateEmail
      }
    `,
    fetchPolicy: "no-cache",
  });
}

export async function cancelChangeEmailRequest(
  client: ApolloClient<any>,
  locale: Locale
): Promise<void> {
  await client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation {
        cancelCustomerEmailUpdate
      }
    `,
    fetchPolicy: "no-cache",
  });
}

export async function verifyChangeEmail(
  client: ApolloClient<any>,
  customerID: number,
  key: string,
  locale: Locale
): Promise<void> {
  const result = await client.mutate<{
    verifyCustomerEmailUpdate: {
      reject_reason: string | null;
      success: boolean;
    };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation VerifyCustomerEmailUpdate($customerID: Int!, $key: String!) {
        verifyCustomerEmailUpdate(customer_id: $customerID, key: $key) {
          reject_reason
          success
        }
      }
    `,
    variables: {
      customerID,
      key,
    },
    fetchPolicy: "no-cache",
  });
  if (result.data == null) {
    throw new Error();
  }
  const { success, reject_reason } = result.data.verifyCustomerEmailUpdate;
  if (!success) {
    throw reject_reason;
  }
}

export async function updateMyCustomerInfoAfterSSOSignup(
  client: ApolloClient<any>,
  isSubscribeToNewsletter: boolean
): Promise<Customer | null> {
  const result = await client.mutate<{
    updateCustomer: { customer: Customer | null };
  }>({
    mutation: gql`
    mutation updateCustomer($isSubscribeToNewsletter: Boolean!) {
      updateCustomer(
          input: {
            is_subscribed: $isSubscribeToNewsletter
          }
        ) {
          customer {
            ${CustomerGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      isSubscribeToNewsletter,
    },
    fetchPolicy: "no-cache",
  });
  if (result.data == null) {
    throw new Error();
  }
  return result.data.updateCustomer.customer;
}

export async function addProductToCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  cartItem: SimpleProductCartItemInput | ConfigurableProductCartItemInput
): Promise<Cart> {
  const mutationFunc = isConfigurableProductCartItemInput(cartItem)
    ? "addConfigurableProductsToCart"
    : "addSimpleProductsToCart";
  const cartItemType = isConfigurableProductCartItemInput(cartItem)
    ? "ConfigurableProductCartItemInput"
    : "SimpleProductCartItemInput";
  const serializedItem = isConfigurableProductCartItemInput(cartItem)
    ? serializeConfigurableProductCartItemInput(cartItem)
    : serializeSimpleProductCartItemInput(cartItem);
  const getCartFunc = isConfigurableProductCartItemInput(cartItem)
    ? (data: any) => data.addConfigurableProductsToCart.cart
    : (data: any) => data.addSimpleProductsToCart.cart;
  const result = await client.mutate({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation AddProductsToCarts(
        $cartId: String!
        $cartItem: ${cartItemType}!
      ) {
        ${mutationFunc}(
          input: { cart_id: $cartId, cart_items: [$cartItem] }
        ) {
          cart {
            ${CartGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      cartId,
      cartItem: serializedItem,
    },
    fetchPolicy: "no-cache",
  });
  return getCartFunc(result.data);
}

export async function setClubPointOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  clubpoint: number
): Promise<Cart> {
  try {
    const result = await client.mutate<{
      setClubPointOnCart: { cart: Cart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation SetClubPointOnCart($cartId: String!, $clubpoint: Int!) {
        setClubPointOnCart(input: { cart_id: $cartId, clubpoints: $clubpoint }) {
          cart {
            ${CartGraphQLAttributes}
          }
        }
      }
    `,
      variables: {
        cartId,
        clubpoint,
      },
    });
    if (result.data == null) {
      throw new Error();
    }
    return result.data.setClubPointOnCart.cart;
  } catch (e) {
    throw parseGraphQLError(e);
  }
}

export async function fetchCMSPageContentByIdentifier(
  client: ApolloClient<any>,
  type:
    | { type: "cmsBlock"; identifier: string }
    | { type: "cmsPage"; identifier: number }
    | { type: "cmsPageStringId"; identifier: string },
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CMSPageContent | null> {
  const item: { contentForApp: string } | null = await (async () => {
    if (type.type === "cmsPage" || type.type === "cmsPageStringId") {
      const result = await client.query<
        {
          cmsPage: { contentForApp: string };
        },
        { identifier: number | string }
      >({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`
	  query QueryCMSPage($identifier: ${
      type.type === "cmsPage" ? "Int" : "String"
    }!) {
	    cmsPage(${type.type === "cmsPage" ? "id" : "identifier"}: $identifier) {
              id: identifier
              contentForApp: content_for_app
            }
          }
        `,
        variables: {
          identifier: type.identifier,
        },
        fetchPolicy,
      });
      if (result.data == null) {
        return null;
      }
      return result.data.cmsPage || null;
    }
    const result = await client.query<
      {
        cmsBlocks: { items: [{ contentForApp: string }] };
      },
      { identifiers: [string] }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryCMSBlocks($identifiers: [String!]!) {
          cmsBlocks(identifiers: $identifiers) {
            items {
              contentForApp: content_for_app
            }
          }
        }
      `,
      variables: {
        identifiers: [type.identifier],
      },
      fetchPolicy,
    });
    if (result.data == null) {
      return null;
    }
    return result.data.cmsBlocks != null
      ? result.data.cmsBlocks.items[0]
      : null;
  })();

  if (item == null) {
    return null;
  }
  const cmsBlocks = extractCMSBlocksFromContentForApp(item.contentForApp);
  return {
    items: cmsBlocks,
  };
}

export function getHTMLBasedCMSPageContentByIdentifier(
  client: ApolloClient<any>,
  type:
    | { type: "cmsBlock"; identifier: string }
    | { type: "cmsPage"; identifier: number }
    | { type: "cmsPageStringId"; identifier: string }
): HTMLBasedCMSPageContent | null {
  const item: { contentForApp: string } | null = (() => {
    if (type.type === "cmsPage" || type.type === "cmsPageStringId") {
      const result = client.readQuery<
        {
          cmsPage: { contentForApp: string };
        },
        { identifier: number | string }
      >({
        query: gql`
          query QueryCMSPage($identifier: Int) {
            cmsPage(id: $identifier) {
              id: identifier
              contentForApp: content_for_app
            }
          }
        `,
        variables: {
          identifier: type.identifier,
        },
      });
      if (!result) {
        return null;
      }
      return result.cmsPage || null;
    }
    const result = client.readQuery<
      {
        cmsBlocks: { items: [{ contentForApp: string }] };
      },
      { identifiers: [string] }
    >({
      query: gql`
        query QueryCMSBlocks($identifiers: [String!]!) {
          cmsBlocks(identifiers: $identifiers) {
            items {
              contentForApp: content_for_app
            }
          }
        }
      `,
      variables: {
        identifiers: [type.identifier],
      },
    });
    if (!result) {
      return null;
    }
    return result.cmsBlocks != null ? result.cmsBlocks.items[0] : null;
  })();

  if (item == null) {
    return null;
  }
  const htmlBasedCMSPageContent = extractCMSBlocksFromContentForAppWithWaitingToFillHTML(
    item.contentForApp
  );
  return htmlBasedCMSPageContent;
}

export async function fetchHTMLBasedCMSPageContentByIdentifier(
  client: ApolloClient<any>,
  type:
    | { type: "cmsBlock"; identifier: string }
    | { type: "cmsPage"; identifier: number }
    | { type: "cmsPageStringId"; identifier: string },
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<HTMLBasedCMSPageContent | null> {
  const item: { contentForApp: string } | null = await (async () => {
    if (type.type === "cmsPage" || type.type === "cmsPageStringId") {
      const result = await client.query<
        {
          cmsPage: { contentForApp: string };
        },
        { identifier: number | string }
      >({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`
          query QueryCMSPage($identifier: Int) {
            cmsPage(id: $identifier) {
              id: identifier
              contentForApp: content_for_app
            }
          }
        `,
        variables: {
          identifier: type.identifier,
        },
        fetchPolicy,
      });
      if (result.data == null) {
        return null;
      }
      return result.data.cmsPage || null;
    }
    const result = await client.query<
      {
        cmsBlocks: { items: [{ contentForApp: string }] };
      },
      { identifiers: [string] }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryCMSBlocks($identifiers: [String!]!) {
          cmsBlocks(identifiers: $identifiers) {
            items {
              contentForApp: content_for_app
            }
          }
        }
      `,
      variables: {
        identifiers: [type.identifier],
      },
      fetchPolicy,
    });
    if (result.data == null) {
      return null;
    }
    return result.data.cmsBlocks != null
      ? result.data.cmsBlocks.items[0]
      : null;
  })();

  if (item == null) {
    return null;
  }
  const htmlBasedCMSPageContent = extractCMSBlocksFromContentForAppWithWaitingToFillHTML(
    item.contentForApp
  );
  return htmlBasedCMSPageContent;
}

export function getCMSStaticBlocksByIds(
  client: ApolloClient<any>,
  ids: string[]
): CMSStaticBlockContent[] {
  const result = client.readQuery<{
    cmsBlocks: { items: (CMSStaticBlockContent | null)[] | null } | null;
  }>({
    query: gql`
      query CMSStaticBlocks($identifiers: [String!]!) {
        cmsBlocks(identifiers: $identifiers) {
          items {
            ${CMSStaticBlockContentGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      identifiers: ids,
    },
  });

  const cmsBlocks: CMSStaticBlockContent[] = [];
  if (result && result.cmsBlocks && result.cmsBlocks.items) {
    for (const cmsBlock of result.cmsBlocks.items) {
      if (cmsBlock) {
        cmsBlocks.push(cmsBlock);
      }
    }
  }

  return cmsBlocks;
}

export async function fetchStaticCMSBlocksByIds(
  client: ApolloClient<any>,
  ids: string[],
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CMSStaticBlockContent[]> {
  const result = await client.query<{
    cmsBlocks: { items: (CMSStaticBlockContent | null)[] | null } | null;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query CMSStaticBlocks($identifiers: [String!]!) {
        cmsBlocks(identifiers: $identifiers) {
          items {
            ${CMSStaticBlockContentGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      identifiers: ids,
    },
    fetchPolicy,
    // Do not throw error because the missing identifier will fail
    // to update apollo cache and the cache will be retained in each
    // request
    errorPolicy: "all",
  });

  const cmsBlocks: CMSStaticBlockContent[] = [];
  if (result.data.cmsBlocks && result.data.cmsBlocks.items) {
    for (const cmsBlock of result.data.cmsBlocks.items) {
      if (cmsBlock) {
        cmsBlocks.push(cmsBlock);
      }
    }
  }

  // Write back filtered version to cache
  client.cache.writeQuery<{
    cmsBlocks: {
      items:
        | (CMSStaticBlockContent & { __typename: "CmsBlock" } | null)[]
        | null;
      __typename: "CmsBlocks";
    } | null;
  }>({
    query: gql`
      query CMSStaticBlocks($identifiers: [String!]!) {
        cmsBlocks(identifiers: $identifiers) {
          items {
            ${CMSStaticBlockContentGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      identifiers: ids,
    },
    data: {
      cmsBlocks: {
        items: cmsBlocks.map(cmsBlock => ({
          ...cmsBlock,
          __typename: "CmsBlock",
        })),
        __typename: "CmsBlocks",
      },
    },
  });

  return cmsBlocks;
}

export async function fetchMerchantDirectory(
  client: ApolloClient<any>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  merchantPreviews: MerchantPreview[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const result = await client.query<{
    merchant: {
      items: MerchantPreview[];
      pageInfo: { totalPages: number; pageSize: number };
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryMerchants($page: Int) {
        merchant(pageSize: 20, currentPage: $page) {
          items {
            ${MerchantPreviewGraphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
    variables: {
      page,
    },
    fetchPolicy,
  });

  if (!result.data.merchant || !result.data.merchant.items) {
    throw Error("Something went wrong");
  }
  return {
    merchantPreviews: result.data.merchant.items,
    hasMore: result.data.merchant.pageInfo.totalPages > page,
    pageSize: result.data.merchant.pageInfo.pageSize,
  };
}

export async function fetchMerchantPreview(
  client: ApolloClient<any>,
  merchantID: MerchantID,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<MerchantPreview | null> {
  const result = await client.query<{ merchant: { items: MerchantPreview[] } }>(
    {
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
        query QueryMerchant($id: String) {
          merchant(filter: { vendor_id: { eq: $id } }) {
            items {
              ${MerchantPreviewGraphQLAttributes}
            }
          }
        }
      `,
      variables: {
        id: `${merchantID}`,
      },
      fetchPolicy,
    }
  );

  if (!result.data.merchant || !result.data.merchant.items) {
    throw Error("Something went wrong");
  }
  return result.data.merchant.items[0];
}

export function getMerchantPreview(
  client: ApolloClient<any>,
  id: MerchantID
): MerchantPreview | null {
  const cachedId = client.cache.identify({
    __typename: "Merchant",
    id,
  });
  return client.readFragment<MerchantPreview>({
    id: cachedId,
    fragment: gql`
      fragment X on Merchant {
        ${MerchantPreviewGraphQLAttributes}
      }
    `,
  });
}

export async function fetchMerchant(
  client: ApolloClient<any>,
  merchantID: MerchantID,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Merchant | null> {
  const result = await client.query<{ merchant: { items: Merchant[] } }>({
    context: { headers: { store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryMerchant($id: String) {
        merchant(filter: { vendor_id: { eq: $id } }) {
          items {
            ${MerchantGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      id: `${merchantID}`,
    },
    fetchPolicy,
  });

  if (!result.data.merchant || !result.data.merchant.items) {
    throw Error("Something went wrong");
  }
  return result.data.merchant.items[0];
}

export async function fetchNormalProductOverviewsByMerchantId(
  client: ApolloClient<any>,
  entityID: MerchantEntityID,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ productOverviews: ProductOverview[]; pageInfo: PageInfo } | null> {
  const sortInput =
    mapSortAttributeToGraphQLVariable(productFilterInfo.sortAttribute) ||
    undefined;
  const _products = await (async () => {
    const getGraphQL = (
      itemGraphQLAttribute: string,
      includePageInfo: boolean
    ) => {
      return `
      query QueryNormalProductsByMerchantID(
        $page: Int,
        ${sortInput ? "$sort: ProductAttributeSortInput" : ""}
        ${
          productFilterInfo != null
            ? "$filter: ProductAttributeFilterInput"
            : ""
        },
      ) {
        products(
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          ${productFilterInfo != null ? "filter: $filter" : ""},
        ) {
          items {
            ${itemGraphQLAttribute}
          }
          ${
            includePageInfo
              ? `pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }`
              : ""
          }
        }
      }
    `;
    };

    const query = <T>(graphQLAttributes: string) =>
      client.query<{
        products: { items: T[]; pageInfo: PageInfo };
      }>({
        context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
        query: gql`
          ${getGraphQL(graphQLAttributes, true)}
        `,
        variables: {
          page,
          sort: sortInput,
          filter: {
            vendor_id: { eq: `${entityID}` },
            ...(productFilterInfo
              ? makeGraphQLFilter(
                  getApplicableProductFilterInfo(productFilterInfo),
                  productAttributeFilterInputMap
                )
              : {}),
          },
        },
        fetchPolicy,
      });

    const result = await query<ProductOverview>(
      ProductOverviewGraphQLAttributes
    );

    const { products } = result.data;
    return products;
  })();

  if (!_products) {
    return null;
  }

  return {
    productOverviews: _products.items,
    pageInfo: _products.pageInfo,
  };
}
export async function fetchFeaturedProductOverviewsByMerchantId(
  client: ApolloClient<any>,
  merchantID: MerchantID,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ productOverviews: ProductOverview[]; pageInfo: PageInfo } | null> {
  const _products = await (async () => {
    const getGraphQL = (
      itemGraphQLAttribute: string,
      includePageInfo: boolean
    ) => {
      return `
      query QueryFeaturedProductsByMerchantID(
        $id: String,
        $page: Int,
      ) {
        merchant(filter: {vendor_id: { eq: $id }}) {
          items {
            id: vendor_id
            products: featured_products(
              currentPage: $page,
            ) {
              items {
                ${itemGraphQLAttribute}
              }
              ${
                includePageInfo
                  ? `pageInfo: page_info {
                ${PageInfoGraphQLAttributes}
              }`
                  : ""
              }
            }
          }
        }
      }
    `;
    };

    const query = <T>(graphQLAttributes: string) =>
      client.query<{
        merchant: {
          items: {
            products: { items: T[]; pageInfo: PageInfo };
          }[];
        };
      }>({
        context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
        query: gql`
          ${getGraphQL(graphQLAttributes, true)}
        `,
        variables: {
          id: `${merchantID}`,
          page,
        },
        fetchPolicy,
      });

    const result = await query<ProductOverview>(
      ProductOverviewGraphQLAttributes
    );

    const merchant =
      result.data.merchant &&
      result.data.merchant.items &&
      result.data.merchant.items[0];
    if (!merchant) {
      return null;
    }

    const { products } = merchant;
    return products;
  })();

  if (!_products) {
    return null;
  }

  return {
    productOverviews: _products.items,
    pageInfo: _products.pageInfo,
  };
}

export async function fetchCountries(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RemoteCountry[] | null> {
  const result = await client.query<{
    countries: RemoteCountry[];
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query FetchCountries {
        countries {
          ${CountryGraphQLAttributes}
        }
      }
    `,
    fetchPolicy,
  });

  if (!result.data.countries) {
    return null;
  }

  const countries: RemoteCountry[] = [];
  for (const c of result.data.countries) {
    countries.push(c);
  }
  return countries;
}

export async function fetchDistricts(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<RemoteDistrict[] | null> {
  const result = await client.query<{ city: { items: RemoteDistrict[] } }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query FetchDistricts {
        city {
    items {
      ${DistrictGraphQLAttributes}
    }
  }
      }
    `,
    fetchPolicy,
  });

  if (!result.data.city || !result.data.city.items) {
    return null;
  }

  const districts: RemoteDistrict[] = [];
  for (const item of result.data.city.items) {
    districts.push(item);
  }

  return districts;
}

export async function fetchHotSearches(
  client: ApolloClient<any>,
  locale: Locale
) {
  const result = await client.query<{
    searches: {
      popularSearches: SearchAutoSuggestion[];
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query QueryHotSearches {
        searches {
          popularSearches: popular_searches {
            ${SearchAutoSuggestionGraphQLAttributes}
          }
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return result.data.searches.popularSearches;
}

export async function fetchSearchSuggestion(
  client: ApolloClient<any>,
  searchTerm: SearchTerm,
  locale: Locale
): Promise<{
  brand: SearchAutoSuggestion[];
  popularSearches: SearchAutoSuggestion[];
  products: { items: { sku: string; name: string }[]; totalCount: number };
  filteredListing: SearchAutoSuggestion[];
}> {
  const searchQuery = async <T>(graphQLAttributes: string) => {
    return client.query<{ searches: T }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
        query QuerySearchSuggestion($search: String!) {
          searches(search: $search) {
            ${graphQLAttributes}
          }
        }
      `,
      variables: {
        search: searchTerm,
      },
      fetchPolicy: "network-only",
    });
  };
  const searchSuggestionPromise = searchQuery<{
    category: SearchAutoSuggestion[];
    popularSearches: SearchAutoSuggestion[];
  }>(
    `category {
      ${SearchAutoSuggestionGraphQLAttributes}
    }
    popularSearches: popular_searches {
      ${SearchAutoSuggestionGraphQLAttributes}
    }`
  );

  const brandSuggestionPromise = searchQuery<{ brand: SearchAutoSuggestion[] }>(
    `brand {
      ${SearchAutoSuggestionGraphQLAttributes}
    }`
  );

  const productSuggestionPromise = client.query<{
    products: { items: Pick<Product, "sku" | "name">[]; totalCount: number };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query suggestProduct($search: String!, $limit: Int!) {
        products(search: $search, pageSize: $limit) {
          items {
            sku
            name
          }
          totalCount: total_count
        }
      }
    `,
    variables: {
      search: searchTerm,
      limit: Config.SEARCH_PRODUCT_SUGGESTION_LIMIT,
    },
  });

  const result = await Promise.all([
    searchSuggestionPromise,
    brandSuggestionPromise.catch(() => ({ data: { searches: { brand: [] } } })),
    productSuggestionPromise,
  ]);

  const { popularSearches, category } = result[0].data.searches;

  return {
    popularSearches: popularSearches,
    filteredListing: category,
    brand: result[1].data.searches.brand,
    products: result[2].data.products,
  };
}

export async function fetchProductAttributeFilterInputFields(
  client: ApolloClient<any>,
  fetchPolicy: FetchPolicy
): Promise<FilterInputField[]> {
  const result = await client.query<{
    __type: { inputFields: FilterInputField[] };
  }>({
    query: gql`
      query FetchProductAttributeFilterInputFields {
        __type(name: "ProductAttributeFilterInput") {
          inputFields {
            name
            type {
              name
            }
          }
        }
      }
    `,
    fetchPolicy,
  });
  if (
    result.data.__type &&
    result.data.__type.inputFields &&
    result.data.__type.inputFields.length
  ) {
    return result.data.__type.inputFields;
  }
  return [];
}

export async function fetchProductAttributeSortInputFields(
  client: ApolloClient<any>,
  fetchPolicy: FetchPolicy
): Promise<SortInputField[]> {
  const result = await client.query<{
    __type: { inputFields: SortInputField[] };
  }>({
    query: gql`
      query FetchProductAttributeSortInputFields {
        __type(name: "ProductAttributeSortInput") {
          inputFields {
            name
          }
        }
      }
    `,
    fetchPolicy,
  });
  if (
    result.data.__type &&
    result.data.__type.inputFields &&
    result.data.__type.inputFields.length
  ) {
    return result.data.__type.inputFields;
  }
  return [];
}

export function getSearchedProductOverviews(
  client: ApolloClient<any>,
  searchTerm: SearchTerm,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  page: number
): {
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null {
  const sortInput =
    mapSortAttributeToGraphQLVariable(productFilterInfo.sortAttribute) ||
    undefined;
  const getGraphQL = (
    itemGraphQLAttribute: string,
    includePageInfo: boolean
  ) => {
    return productFilterInfo == null
      ? `
      query SearchProducts($search: String!, $page: Int!) {
        products(search: $search, currentPage: $page) {
          items {
            ${itemGraphQLAttribute}
          }
          ${
            includePageInfo
              ? `pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }`
              : ""
          }
        }
      }
    `
      : `
      query SearchProducts(
        $search: String!,
        $page: Int!,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput
      ) {
        products(
          search: $search,
          filter: $filter,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
        ) {
          items {
            ${itemGraphQLAttribute}
          }
          ${
            includePageInfo
              ? `pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }`
              : ""
          }
        }
      }
    `;
  };

  const query = <T>(graphQLAttributes: string) =>
    client.readQuery<{
      products: {
        items: T[];
        pageInfo: PageInfo;
      };
    }>({
      query: gql(getGraphQL(graphQLAttributes, true)),
      variables: {
        search: searchTerm,
        page,
        sort: sortInput,
        filter: makeGraphQLFilter(
          getApplicableProductFilterInfo(productFilterInfo),
          productAttributeFilterInputMap
        ),
      },
    });
  const [result, thirdPartyProductShowPriceResult, isPreOrderResult] = [
    query<ProductOverview>(ProductOverviewGraphQLAttributes),
    query<ProductThirdPartyProductShowPriceType>(
      ProductThirdPartyProductShowPriceGraphQLAttributes
    ),
    query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes),
  ];

  if (!result) {
    return null;
  }

  const skuThirdPartyProductShowPriceMap =
    thirdPartyProductShowPriceResult &&
    thirdPartyProductShowPriceResult.products &&
    thirdPartyProductShowPriceResult.products.items
      ? getIndexedProduct(thirdPartyProductShowPriceResult.products.items)
      : {};

  const isPreOrderMap = fromMaybe(
    {},
    mapNullable(isPreOrderResult, r => getIndexedProduct(r.products.items))
  );

  return {
    productOverviews: result.products.items.map(item =>
      patchProductFromIndexedMap(
        patchProductFromIndexedMap(item, skuThirdPartyProductShowPriceMap),
        isPreOrderMap
      )
    ),
    hasMore: result.products.pageInfo.totalPages > page,
    pageSize: result.products.pageInfo.pageSize,
  };
}

export async function searchProductOverviews(
  client: ApolloClient<any>,
  searchTerm: SearchTerm,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  page: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const sortInput =
    mapSortAttributeToGraphQLVariable(productFilterInfo.sortAttribute) ||
    undefined;
  const getGraphQL = (
    itemGraphQLAttribute: string,
    includePageInfo: boolean
  ) => {
    return productFilterInfo == null
      ? `
      query SearchProducts($search: String!, $page: Int!) {
        products(search: $search, currentPage: $page) {
          items {
            ${itemGraphQLAttribute}
          }
          ${
            includePageInfo
              ? `pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }`
              : ""
          }
        }
      }
    `
      : `
      query SearchProducts(
        $search: String!,
        $page: Int!,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput
      ) {
        products(
          search: $search,
          filter: $filter,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
        ) {
          items {
            ${itemGraphQLAttribute}
          }
          ${
            includePageInfo
              ? `pageInfo: page_info {
            ${PageInfoGraphQLAttributes}
          }`
              : ""
          }
        }
      }
    `;
  };

  const query = <T>(graphQLAttributes: string) =>
    client.query<{
      products: {
        items: T[];
        pageInfo: PageInfo;
      };
    }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql(getGraphQL(graphQLAttributes, true)),
      variables: {
        search: searchTerm,
        page,
        sort: sortInput,
        filter: makeGraphQLFilter(
          getApplicableProductFilterInfo(productFilterInfo),
          productAttributeFilterInputMap
        ),
      },
      fetchPolicy,
    });
  const [
    result,
    thirdPartyProductShowPriceResult,
    isPreOrderResult,
  ] = await Promise.all([
    query<ProductOverview>(ProductOverviewGraphQLAttributes),
    query<ProductThirdPartyProductShowPriceType>(
      ProductThirdPartyProductShowPriceGraphQLAttributes
    ).catch(() => ({ data: { products: { items: [] } } })),
    query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes).catch(() => ({
      data: { products: { items: [] } },
    })),
  ]);

  if (result.data.products == null) {
    return null;
  }

  const skuThirdPartyProductShowPriceMap =
    thirdPartyProductShowPriceResult &&
    thirdPartyProductShowPriceResult.data &&
    thirdPartyProductShowPriceResult.data.products &&
    thirdPartyProductShowPriceResult.data.products.items
      ? getIndexedProduct(thirdPartyProductShowPriceResult.data.products.items)
      : {};

  const isPreOrderMap = fromMaybe(
    {},
    getIndexedProduct(isPreOrderResult.data.products.items)
  );

  return {
    productOverviews: result.data.products.items.map(item =>
      patchProductFromIndexedMap(
        patchProductFromIndexedMap(item, skuThirdPartyProductShowPriceMap),
        isPreOrderMap
      )
    ),
    hasMore: result.data.products.pageInfo.totalPages > page,
    pageSize: result.data.products.pageInfo.pageSize,
  };
}

export function getSortFields(
  client: ApolloClient<any>,
  search: string,
  filter: GraphQLFilter
): SortFields | null {
  const result = client.readQuery<{
    products: {
      sortFields: {
        defaultSortField: SortField | null;
        sortFieldOptions: (SortFieldOption | null)[] | null;
      };
    };
  }>({
    query: gql`
      query GetSortFields(
        $search: String!
        $filter: ProductAttributeFilterInput
      ) {
        products(search: $search, filter: $filter) {
          sortFields: sort_fields {
            defaultSortField: default
            sortFieldOptions: options {
              label
              value
            }
          }
        }
      }
    `,
    variables: {
      search,
      filter,
    },
  });

  if (!result) {
    return null;
  }

  const { sortFieldOptions } = result.products.sortFields;

  const validSortFieldOptions: SortFieldOption[] = [];

  if (sortFieldOptions != null) {
    for (const sortFieldOption of sortFieldOptions) {
      if (sortFieldOption) {
        validSortFieldOptions.push(sortFieldOption);
      }
    }
  }

  const res = {
    ...result.products.sortFields,
    sortFieldOptions: validSortFieldOptions,
  };

  return res;
}

export async function fetchSortFields(
  client: ApolloClient<any>,
  search: string,
  filter: GraphQLFilter,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<SortFields | null> {
  const result = await client.query<{
    products: {
      sortFields: {
        defaultSortField: SortField | null;
        sortFieldOptions: (SortFieldOption | null)[] | null;
      };
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql`
      query GetSortFields(
        $search: String!
        $filter: ProductAttributeFilterInput
      ) {
        products(search: $search, filter: $filter) {
          sortFields: sort_fields {
            defaultSortField: default
            sortFieldOptions: options {
              label
              value
            }
          }
        }
      }
    `,
    variables: {
      search,
      filter,
    },
    fetchPolicy,
  });

  if (!result.data.products || !result.data.products.sortFields) {
    return null;
  }

  const { sortFieldOptions } = result.data.products.sortFields;

  const validSortFieldOptions: SortFieldOption[] = [];

  if (sortFieldOptions != null) {
    for (const sortFieldOption of sortFieldOptions) {
      if (sortFieldOption) {
        validSortFieldOptions.push(sortFieldOption);
      }
    }
  }

  const res = {
    ...result.data.products.sortFields,
    sortFieldOptions: validSortFieldOptions,
  };

  return res;
}

export function getAggregation(
  client: ApolloClient<any>,
  search: string,
  filter: GraphQLFilter
): Aggregation[] | null {
  const graphQL = `
query GetAggregation($search: String!, $filter: ProductAttributeFilterInput) {
  products(search: $search, filter: $filter) {
    ${AggregationGraphQLAttributes}
  }
}
  `;

  const result = client.readQuery<{
    products: {
      aggregations?: Aggregation[];
    };
  }>({
    query: gql(graphQL),
    variables: {
      search,
      filter,
    },
  });

  if (!result) {
    return null;
  }

  const aggregations = result.products.aggregations
    ? result.products.aggregations
    : [];

  return aggregations;
}

export async function fetchAggregation(
  client: ApolloClient<any>,
  search: string,
  filter: GraphQLFilter,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Aggregation[] | null> {
  const graphQL = `
query GetAggregation($search: String!, $filter: ProductAttributeFilterInput) {
  products(search: $search, filter: $filter) {
    ${AggregationGraphQLAttributes}
  }
}
  `;

  const result = await client.query<{
    products: {
      aggregations?: Aggregation[];
    };
  }>({
    context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
    query: gql(graphQL),
    variables: {
      search,
      filter,
    },
    fetchPolicy,
  });

  if (result.data.products == null) {
    return null;
  }

  const aggregations = result.data.products.aggregations
    ? result.data.products.aggregations
    : [];

  return aggregations;
}

export async function fetchCustomerAddresses(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  addresses: RemoteAddress[];
  defaultBilling: number | null;
  defaultShipping: number | null;
} | null> {
  try {
    const result = await client.query<{
      customer: {
        addresses: RemoteAddress[];
        defaultBilling: string | null;
        defaultShipping: string | null;
      };
    }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
      query QueryCustomerAddresses {
        customer {
          id
          addresses {
            ${CustomerAddressGraphQLAttributes}
          }
	  defaultBilling: default_billing
	  defaultShipping: default_shipping
        }
      }
    `,
      fetchPolicy,
    });

    if (!result.data.customer || !result.data.customer.addresses) {
      return null;
    }

    const { addresses, defaultBilling, defaultShipping } = result.data.customer;
    return {
      addresses,
      defaultBilling: defaultBilling ? parseInt(defaultBilling, 10) : null,
      defaultShipping: defaultShipping ? parseInt(defaultShipping, 10) : null,
    };
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function resolveUrl(
  client: ApolloClient<any>,
  locale: Locale,
  urlString: string
): Promise<EntityUrl | null> {
  const url = new URL(urlString);
  try {
    const result = await client.query<{ urlResolver: EntityUrl }>({
      context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
      query: gql`
      query resolveUrl($urlPath: String!) {
        urlResolver(url: $urlPath) {
          ${EntityUrlGraphQLAttributes}
        }
      }
    `,
      variables: {
        urlPath: url.pathname,
      },
      fetchPolicy: "network-only",
    });
    return result.data.urlResolver;
  } catch (e) {
    throw parseGraphQLError(e);
  }
}

export async function fetchCustomerWishlist(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<WishlistItem[]> {
  try {
    const query = <T>(graphQLAttributes: string) =>
      client.query<{ customer: { wishlist: { items: T[] } } }>({
        context: { headers: { Store: getStoreViewCodeForLocale(locale) } },
        query: gql`
          query fetchWishlist {
            customer {
              id
              wishlist {
                items {
                  ${graphQLAttributes}
                }
              }
            }
          }
        `,
        fetchPolicy,
      });
    const result = await query<WishlistItem>(
      WishlistItemGraphQLAttribtues(ProductOverviewGraphQLAttributes)
    );
    if (result.data.customer == null || result.data.customer.wishlist == null) {
      return [];
    }

    return result.data.customer.wishlist.items;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function activateCustomer(
  client: ApolloClient<any>,
  customerId: number,
  confirmationKey: string
): Promise<string> {
  const result = await client.mutate<
    {
      activateCustomer: {
        customer_access_token: string;
      };
    },
    {
      customerId: number;
      confirmationKey: string;
    }
  >({
    mutation: gql`
      mutation ActivateCustomer($customerId: Int!, $confirmationKey: String!) {
        activateCustomer(
          customer_id: $customerId
          confirmation_key: $confirmationKey
        ) {
          customer_access_token
        }
      }
    `,
    variables: {
      customerId,
      confirmationKey,
    },
    fetchPolicy: "no-cache",
  });
  if (result.data == null) {
    throw new Error();
  }
  return result.data.activateCustomer.customer_access_token;
}

export async function changePassword(
  client: ApolloClient<any>,
  currentPassword: string,
  newPassword: string
): Promise<void> {
  try {
    await client.mutate({
      mutation: gql`
        mutation ChangePassword(
          $currentPassword: String!
          $newPassword: String!
        ) {
          changeCustomerPassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
          ) {
            id
          }
        }
      `,
      variables: {
        currentPassword,
        newPassword,
      },
      fetchPolicy: "no-cache",
    });
  } catch (e) {
    if (e.message === "GraphQL error: Invalid login or password.") {
      throw Error("invalid-current-password");
    }
    throw e;
  }
}

export async function changeEmailOnLogin(
  client: ApolloClient<any>,
  email: string
): Promise<{ success: boolean; reject_reason?: string }> {
  const result = await client.mutate<{
    updateCustomerEmailOnLogin: { success: boolean; reject_reason?: string };
  }>({
    mutation: gql`
      mutation ChangeEmail($email: String!) {
        updateCustomerEmailOnLogin(new_email: $email) {
          success
          reject_reason
        }
      }
    `,
    variables: {
      email,
    },
    fetchPolicy: "no-cache",
  });
  if (result.data == null) {
    throw new Error();
  }
  return result.data.updateCustomerEmailOnLogin;
}

export async function registerDeviceToken(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
): Promise<PNSResponse> {
  const result = await client.mutate<{
    registerDeviceToken: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation RegisterDeviceToken(
        $os: String!
        $platform: String!
        $token: String!
        $isdn: String!
      ) {
        registerDeviceToken(
          os: $os
          token: $token
          platform: $platform
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      os,
      platform,
      token,
      isdn,
    },
  });

  if (result.data == null) {
    throw new Error();
  }

  return result.data.registerDeviceToken;
}

export async function deleteDeviceToken(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
): Promise<PNSResponse> {
  const result = await client.mutate<{
    deleteDeviceToken: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation DeleteDeviceToken(
        $os: String!
        $platform: String!
        $token: String!
        $isdn: String!
      ) {
        deleteDeviceToken(
          os: $os
          token: $token
          platform: $platform
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      os,
      platform,
      token,
      isdn,
    },
  });

  if (result.data == null) {
    throw new Error();
  }

  return result.data.deleteDeviceToken;
}

export async function changeDeviceToken(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  platform: Platform,
  oldToken: Token,
  newToken: Token,
  isdn: Isdn
): Promise<PNSResponse> {
  const result = await client.mutate<{
    changeDeviceToken: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation ChangeDeviceToken(
        $os: String!
        $platform: String!
        $oldToken: String!
        $newToken: String!
        $isdn: String!
      ) {
        changeDeviceToken(
          os: $os
          oldToken: $oldToken
          newToken: $newToken
          platform: $platform
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      os,
      platform,
      oldToken,
      newToken,
      isdn,
    },
  });

  if (result.data == null) {
    throw new Error();
  }

  return result.data.changeDeviceToken;
}

export async function messageMarkRead(
  client: ApolloClient<any>,
  locale: Locale,
  token: Token,
  isdn: Isdn,
  messageId: string
): Promise<PNSResponse> {
  const result = await client.mutate<{
    messageMarkRead: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation MessageMarkRead(
        $token: String!
        $messageId: String!
        $isdn: String!
      ) {
        messageMarkRead(
          accessToken: $token
          msgIds: [$messageId]
          isdn: $isdn
        ) {
          code
          description
        }
      }
    `,
    variables: {
      token,
      messageId,
      isdn,
    },
  });

  if (result.data == null) {
    throw new Error();
  }

  return result.data.messageMarkRead;
}

export async function setOrderNotification(
  client: ApolloClient<any>,
  locale: Locale,
  isEnabled: NotificationEnableState
): Promise<PNSResponse> {
  console.info(
    `[ClubLike-OPNS] Attempt to ${
      isEnabled ? "enable" : "disable"
    } order notification`
  );
  const result = await client.mutate<{
    setOrderNotification: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation SetOrderNotification($isEnabled: Int!) {
        setOrderNotification(isEnabled: $isEnabled) {
          code
          description
        }
      }
    `,
    variables: {
      isEnabled,
    },
  });
  console.info(
    `[ClubLike-OPNS] Order notification is ${
      isEnabled ? "enabled" : "disabled"
    }`
  );

  if (result.data == null) {
    throw new Error();
  }

  return result.data.setOrderNotification;
}

export async function setPromotionNotification(
  client: ApolloClient<any>,
  locale: Locale,
  isEnabled: NotificationEnableState
): Promise<PNSResponse> {
  console.info(
    `[ClubLike-OPNS] Attempt to ${
      isEnabled ? "enable" : "disable"
    } promotion notification`
  );
  const result = await client.mutate<{
    setPromotionNotification: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation SetPromotionNotification($isEnabled: Int!) {
        setPromotionNotification(isEnabled: $isEnabled) {
          code
          description
        }
      }
    `,
    variables: {
      isEnabled,
    },
  });
  console.info(
    `[ClubLike-OPNS] Promotion notification is ${
      isEnabled ? "enabled" : "disabled"
    }`
  );

  if (result.data == null) {
    throw new Error();
  }

  return result.data.setPromotionNotification;
}

export async function getOppCards(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<OppCard[]> {
  try {
    const result = await client.query<{
      getCustomerOppCard: { cardList: OppCard[] };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query GetCustomerOppCard {
          getCustomerOppCard {
            cardList: card_list {
              ${OppCardGraphQLAttributes}
            }
          }
        }
      `,
      fetchPolicy,
    });
    return result.data.getCustomerOppCard.cardList;
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}

export async function deleteOppCard(
  client: ApolloClient<any>,
  cardId: string
): Promise<boolean> {
  try {
    const result = await client.mutate<{ deleteOppCard: boolean }>({
      mutation: gql`
        mutation DeleteOppCard($cardId: String!) {
          deleteOppCard(card_id: $cardId)
        }
      `,
      variables: { cardId },
      fetchPolicy: "no-cache",
    });

    if (result.data == null) {
      throw new Error();
    }

    return result.data.deleteOppCard;
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}

export async function getCustomerSubscriptions(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CustomerSubscription[]> {
  try {
    const result = await client.query<{
      getCustomerSubscription: [RemoteCustomerSubscription];
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query GetCustomerSubscriptions {
          getCustomerSubscription {
            ${CustomerSubscriptionGraphQLAttributes}
          }
        }
      `,
      fetchPolicy,
    });

    if (!result.data.getCustomerSubscription) {
      return [];
    }

    return result.data.getCustomerSubscription.map(
      transformRemoteCustomerSubscriptionToCustomerSubscription
    );
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}

export async function getCustomerSubscription(
  client: ApolloClient<any>,
  locale: Locale,
  subscriptionId: CustomerSubscriptionId,
  fetchPolicy: FetchPolicy
): Promise<CustomerSubscription> {
  try {
    const result = await client.query<{
      getCustomerSubscription: [RemoteCustomerSubscription];
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query GetCustomerSubscriptions {
          getCustomerSubscription {
            ${CustomerSubscriptionGraphQLAttributes}
          }
        }
      `,
      fetchPolicy,
    });

    if (!result.data.getCustomerSubscription) {
      throw new Error("Not found");
    }

    const filtered = result.data.getCustomerSubscription.filter(
      (c: RemoteCustomerSubscription) => {
        return c.subscription
          ? c.subscription.subscriptionId === subscriptionId
          : false;
      }
    );

    if (filtered.length === 0) {
      throw new Error("Not found");
    }

    return transformRemoteCustomerSubscriptionToCustomerSubscription(
      filtered[0]
    );
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}

export async function cancelSubscription(
  client: ApolloClient<any>,
  subscriptionPayment: string,
  subscriptionId: CustomerSubscriptionId
): Promise<boolean> {
  try {
    const result = await client.mutate<{
      cancelSubscription: boolean;
    }>({
      mutation: gql`
        mutation CancelSubscription(
          $subscriptionPayment: String!
          $subscriptionId: String!
        ) {
          cancelSubscription(
            subscription_payment: $subscriptionPayment
            subscription_id: $subscriptionId
          )
        }
      `,
      variables: {
        subscriptionPayment,
        subscriptionId,
      },
      fetchPolicy: "no-cache",
    });

    if (result.data == null) {
      throw new Error();
    }

    return result.data.cancelSubscription;
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}
