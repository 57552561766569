import { useEffect, useCallback, RefObject } from "react";
import {
  appEventEmitter,
  AppEventOnClickViewMore,
} from "../utils/SimpleEventEmitter";

export default function(
  htmlContentContainerRef: RefObject<HTMLElement>,
  changes: any[],
  onLinkPublish?: (url: string) => void
) {
  const onClickAnchor = useCallback(
    (e: MouseEvent) => {
      const a = e.currentTarget as HTMLAnchorElement | null;
      if (!a) {
        return;
      }
      const url = a.getAttribute("href");
      if (url == null) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      if (url !== "") {
        if (onLinkPublish) {
          onLinkPublish(url);
        }
        appEventEmitter.publish(AppEventOnClickViewMore(url));
      }
    },
    [onLinkPublish]
  );

  useEffect(() => {
    if (htmlContentContainerRef.current == null) {
      return;
    }
    const anchors = htmlContentContainerRef.current.querySelectorAll("a");
    anchors.forEach(a => {
      a.onclick = onClickAnchor;
    });
    return () => {
      anchors.forEach(a => (a.onclick = null));
    };
  }, [htmlContentContainerRef, onClickAnchor, ...changes]); // eslint-disable-line react-hooks/exhaustive-deps
}
