import cn from "classnames";
import React, { useCallback } from "react";
import styles from "./SocialLoginButtons.module.scss";

interface Props {
  onFacebookLoginClick: () => void;
  onGoogleLoginClick: () => void;
}

const SocialLoginButtons: React.FC<Props> = props => {
  const { onFacebookLoginClick, onGoogleLoginClick } = props;
  const handleFacebookLoginClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onFacebookLoginClick();
    },
    [onFacebookLoginClick]
  );
  const handleGoogleLoginClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onGoogleLoginClick();
    },
    [onGoogleLoginClick]
  );
  return (
    <div className={styles.container}>
      <button
        className={cn(styles.button, styles.facebook)}
        onClick={handleFacebookLoginClick}
      >
        Facebook
      </button>
      <button
        className={cn(styles.button, styles.google)}
        onClick={handleGoogleLoginClick}
      >
        Google
      </button>
    </div>
  );
};

export default SocialLoginButtons;
