import { WebPlugin } from "@capacitor/core";

import { InboxMessagePlugin } from "./definitions";
import { InboxMessage } from "./models";

export class InboxMessagePluginWeb extends WebPlugin
  implements InboxMessagePlugin {
  constructor() {
    super({
      name: "InboxMessagePlugin",
      platforms: ["web"],
    });
  }

  async loadMessages(): Promise<{ messages: InboxMessage[] }> {
    throw new Error("Not Implemented");
  }

  async markAsRead(): Promise<{ message: InboxMessage }> {
    throw new Error("Not Implemented");
  }

  async clearAllMessages(): Promise<void> {
    throw new Error("Not Implemented");
  }
}
