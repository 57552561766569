import React from "react";

import { RequestCheckDataHander, SuccessHandler, FailedHandler } from "./types";

interface QRScannerContext {
  present: () => void;
}

export const QRScannerContext = React.createContext<QRScannerContext>({
  present: () => {},
});

interface QRScannerModalContext {
  presentModal: (
    onRequestCheckData: RequestCheckDataHander,
    onSuccess: SuccessHandler,
    onFailed: FailedHandler
  ) => void;
}

export const QRScannerModalContext = React.createContext<QRScannerModalContext>(
  null as any
);
