import React, { useMemo } from "react";

import { LoadingView } from "../../LoadingView";
import HTMLView from "../../HTMLView";

import { useFetchCMSStaticBlockContents } from "../../../repository/CMSPageContentRepository";

import {
  CMSStaticBlock,
  CMSStaticBlockContent,
} from "../../../models/cmsBlock";
import { getResources } from "../../../models/ResourcesRequestState";

import styles from "./styles.module.scss";

import { filterNullOrUndefined } from "../../../utils/array";

interface Props {
  cmsStaticBlock: CMSStaticBlock;
  onDeepLinkPublish?: (url: string) => void;
}

const CMSHtmlView: React.FC<Props> = React.memo(props => {
  const { cmsStaticBlock, onDeepLinkPublish } = props;

  const identifiers = useMemo(
    () =>
      filterNullOrUndefined(
        (cmsStaticBlock.items || []).map(i => i.identifier)
      ),
    [cmsStaticBlock.items]
  );
  const cmsStaticBlockContentsRequestState = useFetchCMSStaticBlockContents(
    identifiers
  );
  const cmsStaticBlockContents = getResources(
    cmsStaticBlockContentsRequestState
  );

  if (cmsStaticBlockContents == null) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingView />
      </div>
    );
  }
  if (cmsStaticBlockContents.length <= 0) {
    return null;
  }
  return (
    <div>
      <ResolvedCMSHtmlView
        cmsStaticBlockContents={cmsStaticBlockContents}
        onDeepLinkPublish={onDeepLinkPublish}
      />
    </div>
  );
});

export default CMSHtmlView;

interface ResolvedProps {
  cmsStaticBlockContents: CMSStaticBlockContent[];
  onDeepLinkPublish?: (url: string) => void;
}

export const ResolvedCMSHtmlView: React.FC<ResolvedProps> = props => {
  const { cmsStaticBlockContents, onDeepLinkPublish } = props;
  return (
    <div>
      {cmsStaticBlockContents.map(content =>
        content.contentForApp ? (
          <HTMLView
            key={content.identifier}
            htmlContent={content.contentForApp}
            onDeepLinkPublish={onDeepLinkPublish}
          />
        ) : null
      )}
    </div>
  );
};
