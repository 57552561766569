import { ApolloClient, FetchPolicy, gql } from "@apollo/client";

import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";
import {
  FilterInputField,
  getApplicableProductFilterInfo,
  makeGraphQLFilter,
  mapSortAttributeToGraphQLVariable,
  ProductFilterInfo,
} from "../../models/filter";
import {
  ProductOverview,
  ProductOverviewGraphQLAttributes,
} from "../../models/ProductOverview";
import { fromMaybe, IndexMap } from "../../utils/type";
import { parseGraphQLError } from "../../api/GraphQL";

import { Brand } from "./models";
import {
  getIndexedProduct,
  patchProductFromIndexedMap,
  ProductIsPreOrder,
  ProductIsPreOrderGraphQLAttributes,
  ProductThirdPartyProductShowPriceGraphQLAttributes,
  ProductThirdPartyProductShowPriceType,
} from "../../models/product";

export function getProductOverviews(
  client: ApolloClient<any>,
  brandId: number,
  page: number,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
): {
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null {
  const { sortAttribute } = productFilterInfo;
  const sortInput =
    mapSortAttributeToGraphQLVariable(sortAttribute) || undefined;
  const result = client.readQuery<{
    products: {
      items: ProductOverview[];
      pageInfo: { totalPages: number; pageSize: number };
    };
  }>({
    query: gql`
      query QueryProductsByBrandId(
        $page: Int,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput,
      ) {
        products(
          pageSize: 20,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          filter: $filter
        ) {
          items {
            ${ProductOverviewGraphQLAttributes}
            ${ProductThirdPartyProductShowPriceGraphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
    variables: {
      page,
      sort: sortInput,
      filter: {
        brand: { eq: `${brandId}` },
        ...(productFilterInfo
          ? makeGraphQLFilter(
              getApplicableProductFilterInfo(productFilterInfo),
              productAttributeFilterInputMap
            )
          : {}),
      },
    },
  });

  if (!result) {
    return null;
  }

  const { products: _products } = result;

  return {
    productOverviews: _products.items,
    hasMore: _products.pageInfo.totalPages > page,
    pageSize: _products.pageInfo.pageSize,
  };
}

export async function fetchProductOverviews(
  client: ApolloClient<any>,
  brandId: number,
  page: number,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{
  productOverviews: ProductOverview[];
  hasMore: boolean;
  pageSize: number;
} | null> {
  const { sortAttribute } = productFilterInfo;
  const sortInput =
    mapSortAttributeToGraphQLVariable(sortAttribute) || undefined;
  const query = <T>(graphQLAttributes: string) =>
    client.query<{
      products: {
        items: T[];
        pageInfo: { totalPages: number; pageSize: number };
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryProductsByBrandId(
        $page: Int,
        ${sortInput ? "$sort: ProductAttributeSortInput," : ""}
        $filter: ProductAttributeFilterInput,
      ) {
        products(
          pageSize: 20,
          currentPage: $page,
          ${sortInput ? "sort: $sort," : ""}
          filter: $filter
        ) {
          items {
            ${graphQLAttributes}
          }
          pageInfo: page_info {
            totalPages: total_pages
            pageSize: page_size
          }
        }
      }
    `,
      variables: {
        page,
        sort: sortInput,
        filter: {
          brand: { eq: `${brandId}` },
          ...(productFilterInfo
            ? makeGraphQLFilter(
                getApplicableProductFilterInfo(productFilterInfo),
                productAttributeFilterInputMap
              )
            : {}),
        },
      },
      fetchPolicy,
    });

  const [
    result,
    thirdPartyProductShowPriceResult,
    isPreOrderResult,
  ] = await Promise.all([
    query<ProductOverview>(ProductOverviewGraphQLAttributes),
    query<ProductThirdPartyProductShowPriceType>(
      ProductThirdPartyProductShowPriceGraphQLAttributes
    ).catch(() => ({ data: { products: { items: [] } } })),
    query<ProductIsPreOrder>(ProductIsPreOrderGraphQLAttributes).catch(() => ({
      data: { products: { items: [] } },
    })),
  ]);

  if (result.data.products == null) {
    if (fetchPolicy === "cache-only") {
      return null;
    }
    throw Error("Something went wrong");
  }

  const { products: _products } = result.data;

  const skuThirdPartyProductShowPriceMap =
    thirdPartyProductShowPriceResult &&
    thirdPartyProductShowPriceResult.data &&
    thirdPartyProductShowPriceResult.data.products &&
    thirdPartyProductShowPriceResult.data.products.items
      ? getIndexedProduct(thirdPartyProductShowPriceResult.data.products.items)
      : {};

  const isPreOrderMap = fromMaybe(
    {},
    getIndexedProduct(isPreOrderResult.data.products.items)
  );

  return {
    productOverviews: _products.items.map(item =>
      patchProductFromIndexedMap(
        patchProductFromIndexedMap(item, skuThirdPartyProductShowPriceMap),
        isPreOrderMap
      )
    ),
    hasMore: _products.pageInfo.totalPages > page,
    pageSize: _products.pageInfo.pageSize,
  };
}

const BrandGraphQLAttributes = `
label
id: brandId
`;

const BrandTopCmsIdGraphQLAttributes = `
id: brandId
topCmsId
`;

export function getBrand(
  client: ApolloClient<any>,
  name: string
): Brand | null {
  const query = <T>(graphQLAttributes: string) =>
    client.readQuery<{ amBrand: T }>({
      query: gql`
        query QueryBrand(
          $name: String!
        ) {
          amBrand(label: $name) {
            ${graphQLAttributes}
          }
        }
      `,
      variables: {
        name,
      },
    });

  const [baseBrandResult, brandTopCmsIdResult] = [
    query<Pick<Brand, "id" | "label">>(BrandGraphQLAttributes),
    query<Pick<Brand, "id" | "topCmsId">>(BrandTopCmsIdGraphQLAttributes),
  ];

  if (!baseBrandResult) {
    return null;
  }

  return {
    ...baseBrandResult.amBrand,
    ...(brandTopCmsIdResult ? brandTopCmsIdResult.amBrand : {}),
  };
}

export async function fetchBrand(
  client: ApolloClient<any>,
  name: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Brand> {
  try {
    const query = async <T>(graphQLAttributes: string) => {
      return client.query<{ amBrand: T }>({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`
          query QueryBrand(
            $name: String!
          ) {
            amBrand(label: $name) {
              ${graphQLAttributes}
            }
          }
        `,
        variables: {
          name,
        },
        fetchPolicy,
      });
    };

    const [baseBrandResult, brandTopCmsIdResult] = await Promise.all([
      query<Pick<Brand, "id" | "label">>(BrandGraphQLAttributes),
      query<Pick<Brand, "id" | "topCmsId">>(
        BrandTopCmsIdGraphQLAttributes
      ).catch(() => ({ data: { amBrand: { topCmsId: undefined } } })),
    ]);

    return {
      ...baseBrandResult.data.amBrand,
      topCmsId: brandTopCmsIdResult.data.amBrand.topCmsId,
    };
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}
