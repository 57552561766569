import React, { useCallback } from "react";
import cn from "classnames";

import styles from "./Checkbox.module.scss";

export type CheckState = "unchecked" | "checked" | "blocked";

interface CheckboxProps {
  className?: string;
  checkState: CheckState;
  dimmed?: boolean;
  onCheckedChange?: (value: boolean) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = React.memo(props => {
  const {
    className,
    checkState,
    dimmed,
    onCheckedChange,
    children,
    disabled,
  } = props;
  const onCheckboxClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (onCheckedChange) {
      if (checkState === "checked") {
        onCheckedChange(false);
      } else if (checkState === "unchecked") {
        onCheckedChange(true);
      }
    }
  }, [checkState, onCheckedChange, disabled]);

  return (
    <div className={styles.container} onClick={onCheckboxClick}>
      <div className={styles.indicatorColumn}>
        <div
          className={cn(styles.checkbox, className, {
            [styles.checked]: checkState === "checked",
            [styles.blocked]: checkState === "blocked",
            [styles.dimmed]: dimmed,
          })}
        />
      </div>
      {children && <div className={styles.descriptionColumn}>{children}</div>}
    </div>
  );
});

// Cannot use React.memo here because compiler error
// Property 'children' does not exist on type 'IntrinsicAttributes & CheckboxProps'
export default Checkbox;
