import React, { useCallback } from "react";
import cn from "classnames";
import { LocalizedText } from "../../i18n/Localization";

import styles from "./VerifyTheClubAccountBanner.module.scss";

interface Props {
  onVerifyClick: () => void;
  className?: string;
}

const VerifyTheClubAccountBanner: React.FC<Props> = props => {
  const { onVerifyClick, className } = props;

  const handleVerifyClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onVerifyClick();
    },
    [onVerifyClick]
  );

  return (
    <div className={cn(styles.verifyTheClubAccountBanner, className)}>
      <div className={styles.message}>
        <LocalizedText messageID="verify_the_club.description" />
      </div>
      <button className={styles.button} onClick={handleVerifyClick}>
        <LocalizedText messageID="verify_the_club.verify_now" />
      </button>
    </div>
  );
};

export default VerifyTheClubAccountBanner;
