import React, { useCallback } from "react";

import { CustomerSubscription } from "../../models/CustomerSubscription";
import { formatMoney } from "../../models/Price";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./Item.module.scss";

interface Props {
  customerSubscription: CustomerSubscription;
  onDetailClick: (customerSubscription: CustomerSubscription) => void;
}

const Item: React.FC<Props> = props => {
  const { customerSubscription, onDetailClick } = props;

  const {
    status,
    subscription,
    subscriptionName,
    nextBillingAmount,
  } = customerSubscription;

  const handleDetailsClick = useCallback(() => {
    onDetailClick(customerSubscription);
  }, [onDetailClick, customerSubscription]);

  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemInfo}>
        <div className={styles.infoColumn}>
          <InfoItem titleID="my_subscriptions.subscription_info.subscription_id">
            {subscription && subscription.subscriptionId ? (
              <># {subscription.subscriptionId}</>
            ) : (
              "-"
            )}
          </InfoItem>
          <InfoItem titleID="my_subscriptions.subscription_info.next_billing_amount">
            {nextBillingAmount ? formatMoney(nextBillingAmount) : "-"}
          </InfoItem>
        </div>
        <div className={styles.infoColumn}>
          <InfoItem titleID="my_subscriptions.subscription_info.subscription_name">
            {subscriptionName || "-"}
          </InfoItem>
          <InfoItem titleID="my_subscriptions.subscription_info.status">
            {status || "-"}
          </InfoItem>
        </div>
      </div>
      <div className={styles.detailsButton} onClick={handleDetailsClick}>
        <LocalizedText messageID="my_subscriptions.subscription_info.details" />
      </div>
    </div>
  );
};

export default Item;

interface InfoItemProps {
  titleID: MessageID;
  children: React.ReactNode;
}

const InfoItem: React.FC<InfoItemProps> = props => {
  const { titleID, children } = props;
  return (
    <div className={styles.infoItem}>
      <div className={styles.infoItemTitle}>
        <LocalizedText messageID={titleID} />
      </div>
      <div className={styles.infoItemContent}>{children}</div>
    </div>
  );
};
