import React, { useCallback } from "react";
import cn from "classnames";

import styles from "./LikeButton.module.scss";

interface Props {
  like: boolean;
  onClick: () => any;
  disabled?: boolean;
}

const LikeButton: React.FC<Props> = (props: Props) => {
  const { like, disabled, onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) {
        onClick();
      }
    },
    [disabled, onClick]
  );

  return (
    <button
      className={cn(styles.button, {
        [styles.liked]: like,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      onClick={handleClick}
    />
  );
};

export default LikeButton;
