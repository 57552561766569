import React from "react";
import Config from "../../Config";

interface Props {
  id: string;
  onRender: React.ProfilerOnRenderCallback;
}

const RerenderLogger: React.FC<Props> = props => {
  return Config.INFO_ENABLE_PERFORMANCE_LOGGING ? (
    <React.Profiler id={props.id} onRender={props.onRender}>
      {props.children}
    </React.Profiler>
  ) : (
    <>{props.children}</>
  );
};

export default RerenderLogger;
