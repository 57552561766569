import React from "react";
import styles from "./ShipmentBlock.module.scss";

interface Props {
  title: React.ReactNode;
  fieldName: React.ReactNode;
  value: React.ReactNode;
  accessory: React.ReactNode;
}

export const ShipmentBlock: React.FC<Props> = props => {
  const { title, fieldName, value, accessory } = props;
  return (
    <div className={styles.shipmentBlock}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.field}>{fieldName}</div>
      <div className={styles.value}>{value}</div>
      <div className={styles.accessory}>{accessory}</div>
    </div>
  );
};
