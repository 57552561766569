import { useCallback, useContext } from "react";
import {
  AddToCartFinalResult,
  AddToCartModalContext,
} from "../components/AddToCartModalProvider";
import { FormState } from "../components/ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import { OrderItem } from "../models/Order";
import { IndexMap } from "../utils/type";

export function usePresentAddToCartModal() {
  const addToCartModalContext = useContext(AddToCartModalContext);

  return useCallback(
    (
      productSku: string,
      formInitialState: FormState,
      options?: {
        campaignId?: number;
        onAddToCartFinalResult?: (
          addToCartFinalResult: AddToCartFinalResult | undefined
        ) => void;
      }
    ) => {
      addToCartModalContext
        .presentAddToCartModal(productSku, formInitialState, options)
        .then(options ? options.onAddToCartFinalResult : undefined);
    },
    [addToCartModalContext]
  );
}

export function usePresentAddToCartModalWithOrderItem() {
  const addToCartModalContext = useContext(AddToCartModalContext);

  return useCallback(
    (
      orderItem: OrderItem,
      options?: {
        onAddToCartFinalResult?: (
          addToCartFinalResult: AddToCartFinalResult | undefined
        ) => void;
      }
    ) => {
      addToCartModalContext
        .presentAddToCartModalWithOrderItem(orderItem)
        .then(options ? options.onAddToCartFinalResult : undefined);
    },
    [addToCartModalContext]
  );
}

export function usePresentAddToCartMultipleModal() {
  const addToCartModalContext = useContext(AddToCartModalContext);

  return useCallback(
    (
      productSkus: string[],
      skuFormStateMap: IndexMap<string, FormState>,
      options?: {
        onAddToCartFinalResults?: (
          addToCartFinalResults: AddToCartFinalResult[]
        ) => void;
      }
    ) => {
      addToCartModalContext
        .presentAddToCartMultipleModal(productSkus, skuFormStateMap)
        .then(options ? options.onAddToCartFinalResults : undefined);
    },
    [addToCartModalContext]
  );
}
