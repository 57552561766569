import { useCallback, useContext, useMemo } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import {
  fetchProductAttributeFilterInputFields,
  fetchProductAttributeSortInputFields,
} from "../api/GraphQL";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import {
  FilterInputField,
  SortFieldOption,
  SortFields,
  SortInputField,
} from "../models/filter";

import { useFetchResources_v2 } from "./Hooks";
import ProductAttributeFilterContext from "../contexts/ProductAttributeFilterContext";
import ProductAttributeSortContext from "../contexts/ProductAttributeSortContext";

export function useFetchProductAttributeFilterFields(): [
  ResourcesRequestState<FilterInputField[]>,
  () => Promise<FilterInputField[]>
] {
  const client = useApolloClient();
  const {
    productAttributeFilterInputFields,
    updateProductAttributeFilterFields,
  } = useContext(ProductAttributeFilterContext);

  const [requestState, { call }] = useFetchResources_v2<
    FilterInputField[],
    () => Promise<FilterInputField[]>
  >({
    memoryCacheProvider: () =>
      Promise.resolve(productAttributeFilterInputFields),
    localCacheProvider: async () => {
      const _productAttributeFilterInputFields = await fetchProductAttributeFilterInputFields(
        client,
        "cache-only"
      );
      updateProductAttributeFilterFields(_productAttributeFilterInputFields);
      return _productAttributeFilterInputFields;
    },
    remoteResourcesProvider: async () => {
      const _productAttributeFilterInputFields = await fetchProductAttributeFilterInputFields(
        client,
        "network-only"
      );
      updateProductAttributeFilterFields(_productAttributeFilterInputFields);
      return _productAttributeFilterInputFields;
    },
  });

  return [requestState, call];
}

export function useFetchProductAttributeSortFields(): [
  ResourcesRequestState<SortInputField[]>,
  () => Promise<SortInputField[]>
] {
  const client = useApolloClient();
  const {
    productAttributeSortInputFields,
    updateProductAttributeSortFields,
  } = useContext(ProductAttributeSortContext);

  const [requestState, { call }] = useFetchResources_v2<
    SortInputField[],
    () => Promise<SortInputField[]>
  >({
    memoryCacheProvider: () => Promise.resolve(productAttributeSortInputFields),
    localCacheProvider: async () => {
      const productAttributeFilterSortFields = await fetchProductAttributeSortInputFields(
        client,
        "cache-only"
      );
      updateProductAttributeSortFields(productAttributeFilterSortFields);
      return productAttributeFilterSortFields;
    },
    remoteResourcesProvider: async () => {
      const productAttributeFilterSortFields = await fetchProductAttributeSortInputFields(
        client,
        "network-only"
      );
      updateProductAttributeSortFields(productAttributeFilterSortFields);
      return productAttributeFilterSortFields;
    },
  });

  return [requestState, call];
}

export function useValidSortFieldOptions(
  availableSortFieldOptions: SortFieldOption[]
): SortFieldOption[] {
  const { productAttributeSortFields } = useContext(
    ProductAttributeSortContext
  );
  return useMemo(
    () =>
      availableSortFieldOptions.filter(
        s => productAttributeSortFields.indexOf(s.value) > -1
      ),
    [availableSortFieldOptions, productAttributeSortFields]
  );
}

export function useGetValidSortFields(): (
  sortFields: SortFields | null
) => SortFields | null {
  const { productAttributeSortFields } = useContext(
    ProductAttributeSortContext
  );

  return useCallback(
    (sortFields: SortFields | null) => {
      if (sortFields == null) {
        return null;
      }
      return {
        defaultSortField:
          sortFields.defaultSortField &&
          productAttributeSortFields.indexOf(sortFields.defaultSortField) > -1
            ? sortFields.defaultSortField
            : null,
        sortFieldOptions: sortFields.sortFieldOptions.filter(
          ({ value }) => productAttributeSortFields.indexOf(value) > -1
        ),
      };
    },
    [productAttributeSortFields]
  );
}
