import React, { useMemo } from "react";

import CLLink from "../../navigation/CLLink";
import ViewMoreButton from "../CMSBlockList/ViewMoreButton.lazy";

import { CMSBlogBlock, Blog } from "../../../models/cmsBlock";

import {
  RootTab,
  getPathForArticleDetail,
  getPathForArticleTab,
} from "../../../navigation/routes";

import parse from "../../../utils/HTMLReactParser";

import styles from "./styles.module.scss";

interface Props {
  currentTab: RootTab;
  cmsBlogBlock: CMSBlogBlock;
}

const CMSBlog: React.FC<Props> = props => {
  const { currentTab, cmsBlogBlock } = props;
  return (
    <div className={styles.cmsBlogWidget}>
      <div className={styles.header}>
        <h1 className={styles.widgetTitle}>{cmsBlogBlock.title}</h1>
        <ViewMoreButton link={getPathForArticleTab()} />
      </div>
      <div className={styles.blogList}>
        {cmsBlogBlock.items
          ? cmsBlogBlock.items.map((blog, i) => (
              <div key={i} className={styles.blogListItem}>
                {blog.postId ? (
                  <CLLink to={getPathForArticleDetail(currentTab, blog.postId)}>
                    <BlogItem blog={blog} />
                  </CLLink>
                ) : null}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default CMSBlog;

const BlogItem: React.FC<{
  blog: Blog;
}> = React.memo(props => {
  const { blog } = props;
  const blogContentReactElement = useMemo(
    () => parse(blog.shortContent || ""),
    [blog]
  );
  return (
    <div className={styles.blogCard}>
      <div className={styles.blogContentContainer}>
        <h4 className={styles.blogTitle}>{blog.name}</h4>
        <div className={styles.blogShortContent}>{blogContentReactElement}</div>
      </div>
    </div>
  );
});
