import React, { useCallback } from "react";

function useHandleBackToTopClick(
  ionContentRef: React.RefObject<HTMLIonContentElement>,
  duration?: number
) {
  return useCallback(() => {
    if (!ionContentRef.current) {
      return;
    }
    ionContentRef.current.scrollToTop(duration);
  }, [ionContentRef, duration]);
}

export default useHandleBackToTopClick;
