import { useMemo } from "react";

export default function fillSrcUrlScheme(
  htmlString: string,
  scheme: string = "https"
): string {
  const x = document.createElement("div");
  x.innerHTML = htmlString;
  const srcs = x.querySelectorAll("[src]");
  srcs.forEach(node => {
    const src = node.getAttribute("src");
    if (src && src.startsWith("//")) {
      node.setAttribute("src", `${scheme}:${src}`);
    }
  });
  return x.innerHTML;
}

export function useFillSrcUrlScheme(
  htmlString: string,
  scheme: string = "https"
) {
  return useMemo(() => fillSrcUrlScheme(htmlString, scheme), [
    htmlString,
    scheme,
  ]);
}
