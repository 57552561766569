import React, { useEffect, useRef } from "react";
import { IonButton, IonBadge, IonIcon } from "@ionic/react";
import { LottieElement } from "../Lottie";
import Lottie from "../Lottie.lazy";
import styles from "./styles.module.scss";
import Config from "../../Config";

interface Props {
  onClick: () => void;
  count: number;
}

const NavShoppingCartButton: React.FC<Props> = (props: Props) => {
  const { count } = props;
  const lottieElementRef = useRef<LottieElement | null>(null);
  useEffect(() => {
    let token: NodeJS.Timeout | null = null;
    if (count > 0) {
      token = setInterval(() => {
        if (lottieElementRef.current) {
          lottieElementRef.current.play();
        }
      }, Config.SHOPPING_CART_ICON_ANIMATION_INTERVAL_IN_MS);
    }
    return () => {
      if (token) {
        clearInterval(token);
      }
    };
  }, [count]);
  return (
    <IonButton className={styles.button} onClick={props.onClick}>
      {count > 0 ? (
        <Lottie
          ref={lottieElementRef}
          className={styles.shoppingCartAnimation}
          play={true}
          autoplay={true}
          loop={false}
          animationData={require("../../resources/animation/shopping-cart.json")}
        />
      ) : (
        <IonIcon name="shopping-cart" className={styles.shoppingCartIcon} />
      )}
      {count ? (
        <IonBadge className={styles.countBadge}>{count}</IonBadge>
      ) : null}
    </IonButton>
  );
};

export default React.memo(NavShoppingCartButton);
