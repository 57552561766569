import { ModelKeys } from "./product";
import { ProductLabel } from "./ProductLabel";
import { ProductOverview } from "./ProductOverview";
import { ProductSaleBundle } from "./ProductSaleBundle";

export interface HorizontalProductListData {
  title: string;
  backgroundUrl: string | null;
  detailButtonText: string | null;
  detailUrl: string | null;
  productOverviews: ProductOverview[];
  productLabelsByProductId: {
    [productId in number]: ProductLabel[];
  };
  bundleByProductId: {
    [productId in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
}

export function HorizontalProductListData(
  title: string,
  productOverviews: ProductOverview[],
  productLabelsByProductId: { [productId in number]: ProductLabel[] },
  backgroundUrl: string | null,
  detailButtonText: string | null,
  detailUrl: string | null,
  bundleByProductId: {
    [productId in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  }
): HorizontalProductListData {
  return {
    title,
    productOverviews,
    productLabelsByProductId,
    backgroundUrl,
    detailButtonText,
    detailUrl,
    bundleByProductId,
  };
}
