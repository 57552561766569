import { Product } from "./ProductDetails";
import { ProductOverviewVariant } from "./ProductOverview";

export enum DiscountType {
  FLAT = 0,
  RATE = 1,
}

export enum ApplyCondition {
  AnyBundleProductIsChosen = 0,
  AllBundleProductsAreChosen = 1,
}

export interface ProductSaleBundle<T> {
  mainProduct: T | null;
  items: ProductSaleBundleItem<T>[] | null;
}

export interface ProductSaleBundleItemBase {
  applyForParent: boolean;
  applyCondition: ApplyCondition;
  discountType: DiscountType;
  discountAmount: number;
}

export type ProductSaleBundleItem<T> = ProductSaleBundleItemBase & {
  blockDescription?: string;
  blockTitle?: string;
  items: ProductSaleBundleItemItem<T>[];
};

export interface ProductSaleBundleItemItem<T> {
  discountAmount: number | null;
  product: T;
  qty: number;
}

export function getProductSaleBundleMainProductGraphQLAttributes(
  productGraphQLAttribute: string
): string {
  return `mainProduct: main_product {
  ${productGraphQLAttribute}
  }`;
}

export const ProductSaleBundleItemBaseGraphQLAttributes = `
items {
  applyForParent: apply_for_parent
  applyCondition: apply_condition
  discountType: discount_type
  discountAmount: discount_amount
}
`;

export function getProductSaleBundleItemsGraphQLAttributes(
  productGraphQLAttribute: string
): string {
  return `items {
  applyForParent: apply_for_parent
  applyCondition: apply_condition
  discountType: discount_type
  discountAmount: discount_amount
  blockDescription: block_description
  blockTitle: block_title
  items {
    discountAmount: discount_amount
    qty
    product {
      ${productGraphQLAttribute}
    }
  }
  }`;
}

export type ProductSaleBundleProduct = Product;

export function getUnitDiscount(
  discountType: DiscountType,
  discountAmount: number,
  price: number
): number {
  switch (discountType) {
    case DiscountType.FLAT:
      return discountAmount > price ? price : discountAmount;
    case DiscountType.RATE:
      return (discountAmount * price) / 100;
    default:
      return 0;
  }
}

export function calculateDiscount(
  discountType: DiscountType,
  discountAmount: number,
  quantity: number,
  price: number
): number {
  return getUnitDiscount(discountType, discountAmount, price) * quantity;
}

export function calculateTotalDiscount<
  T extends { discountAmount: number; qty: number; price: number }
>(discountType: DiscountType, items: T[]): number {
  let discount = 0;
  for (const item of items) {
    discount += calculateDiscount(
      discountType,
      item.discountAmount,
      item.qty,
      item.price
    );
  }
  return discount;
}

export function calculateTotalPrice<
  T extends { discountAmount: number; qty: number; price: number }
>(discountType: DiscountType, items: T[]): number {
  let totalPrice = 0;
  for (const item of items) {
    totalPrice +=
      item.qty * item.price -
      calculateDiscount(
        discountType,
        item.discountAmount,
        item.qty,
        item.price
      );
  }
  return totalPrice;
}

export function areProductIdsQuantitySatisfyingBundle<
  T extends {
    id: number;
    variants?: ProductOverviewVariant[] | null;
  }
>(
  bundle: ProductSaleBundleItem<T>,
  productIdQuantityMap: { [key in number]: number }
): "BUNDLE_PRODUCTS_NOT_SATISFIED" | "SATISIFIED" {
  // Products in bundle
  for (const item of bundle.items) {
    if (!isProductSatisfy(item, productIdQuantityMap)) {
      return "BUNDLE_PRODUCTS_NOT_SATISFIED";
    }
  }

  return "SATISIFIED";
}

function isProductSatisfy<
  T extends {
    qty: number;
    product: {
      id: number;
      variants?: ProductOverviewVariant[] | null;
    };
  }
>(
  { qty, product: { id, variants } }: T,
  productIdQuantityMap: { [key in number]: number }
) {
  if (productIdQuantityMap[id] && productIdQuantityMap[id] >= qty) {
    return true;
  }

  if (!variants || !variants.length) {
    return false;
  }

  // Quantity of variant matching
  for (const variant of variants) {
    if (
      productIdQuantityMap[variant.product.id] &&
      productIdQuantityMap[variant.product.id] >= qty
    ) {
      return true;
    }
  }

  return false;
}

export function extractItemProducts<P>(
  items: ProductSaleBundleItem<P>[] | null
) {
  let ps: P[] = [];
  if (!items) {
    return ps;
  }
  for (const item of items) {
    ps = [...ps, ...item.items.map(i => i.product)];
  }
  return ps;
}
