import { EffectCallback, useEffect, useRef } from "react";

export function useEffectOnce(effectCallback: EffectCallback) {
  const executedRef = useRef(false);

  useEffect(() => {
    if (!executedRef.current) {
      executedRef.current = true;
      const res = effectCallback();
      return res;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
