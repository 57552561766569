import { ApolloClient, gql } from "@apollo/client";
import { getStoreViewCodeForLocale, Locale } from "../i18n/locale";
import {
  CMSPageContent,
  parseCMSPageContentFromJson,
} from "../models/cmsBlock";
import { RESTAPIClient } from "./RESTful";

interface HomePageContentQueryType {
  homePageContent: CMSPageContent;
}

const homePageContentQuery = gql`
  query {
    homePageContent
  }
`;

export function getHomePageContent(
  client: ApolloClient<unknown>
): CMSPageContent | null {
  const res = client.readQuery<HomePageContentQueryType>({
    query: homePageContentQuery,
  });
  if (res) {
    return parseCMSPageContentFromJson(res.homePageContent);
  }
  return null;
}

export async function fetchHomePageContent(
  client: RESTAPIClient,
  apolloClient: ApolloClient<unknown>,
  locale: Locale
): Promise<CMSPageContent | null> {
  const response = await client.get<any>(
    `/cmsIndexApi/index/index/?store_view=${getStoreViewCodeForLocale(locale)}`
  );
  const homePageContent = parseCMSPageContentFromJson(response);
  if (homePageContent != null) {
    apolloClient.writeQuery<HomePageContentQueryType>({
      query: homePageContentQuery,
      data: { homePageContent },
    });
  }
  return homePageContent;
}
