import { AppAvailability } from "@ionic-native/app-availability";

// WTF: This AppAvailability.check() will only return true if available,
// or throw error if not available...
// Thus it must be wrapped with a try catch...
// I don't understand why the design is like this...
export async function checkAppAvailability(
  appString: string
): Promise<boolean> {
  try {
    return await AppAvailability.check(appString);
  } catch {
    return false;
  }
}
