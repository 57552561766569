import { useState, useEffect, useRef } from "react";
import { Plugins, AppState } from "@capacitor/core";

const { App } = Plugins;

export default function useRefreshOnAppActive(fn: () => void) {
  const [appActive, setAppActive] = useState(true);
  const prevAppActiveRef = useRef(true);

  useEffect(() => {
    const handler = App.addListener("appStateChange", (state: AppState) => {
      setAppActive(state.isActive);
    });
    return () => {
      if (handler) {
        handler.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (!prevAppActiveRef.current && appActive) {
      fn();
    }
    prevAppActiveRef.current = appActive;
  }, [appActive, fn]);
}
