import { AppState, Plugins } from "@capacitor/core";
import { AsiaPayParameters } from "./AsiaPay";
import {
  pay,
  queryTransactionResult,
  AsiaPayError,
} from "../CLPlugins/AsiaPay";

import { timeout } from "./promise";

const { App } = Plugins;

type PayMethod = "ALIPAYHK" | "GOOGLE";

export function getPayMethod(
  paymentMethod: "alipayhk" | "googlepay"
): PayMethod {
  switch (paymentMethod) {
    case "alipayhk":
      return "ALIPAYHK";
    case "googlepay":
      return "GOOGLE";
  }
}

export function presentAsiaPay(
  asiaPayParameters: AsiaPayParameters,
  payMethod: PayMethod
): Promise<void> {
  const { amount, currCode, orderRef, merchantId, lang } = asiaPayParameters;

  return new Promise((resolve, reject) => {
    if (!amount || !currCode || !orderRef || !merchantId || !lang) {
      reject(new Error("Missing parameters"));
      return;
    }
    let isActive = true;
    let isInactiveOnce = false;
    let completed = false;
    let checkTransactionStatusInProgress = false;

    // Define idle time for each retry stage in ms
    const retryTimes = [5000, 10000];
    let currentRetryStage = -1;

    const resetRetryStage = () => {
      currentRetryStage = -1;
    };

    // This function is not pure
    const getRetryTime = () => {
      if (currentRetryStage >= retryTimes.length - 1) {
        return null;
      }
      currentRetryStage += 1;
      return retryTimes[currentRetryStage];
    };

    const checkTransactionStatusIfNotInProgress = () => {
      if (checkTransactionStatusInProgress) {
        return;
      }
      checkTransactionStatusInProgress = true;
      checkTransactionStatus();
    };

    const checkTransactionStatus = async () => {
      if (completed) {
        return;
      }
      try {
        const transactionResults = await queryTransactionResult();
        console.log(
          "kenchannnn checkTransactionStatus",
          JSON.stringify({
            transactionResults,
          })
        );
        const transactionResultsOfThisOrder = transactionResults.orders.filter(
          r => r.ref === orderRef
        );
        if (transactionResultsOfThisOrder.length > 0) {
          const [transactionResultOfThisOrder] = transactionResultsOfThisOrder;
          if (transactionResultOfThisOrder.successcode === "0") {
            handleSuccess();
            return;
          }
          if (
            transactionResultOfThisOrder.successcode === "1" &&
            transactionResultOfThisOrder.prc &&
            transactionResultOfThisOrder.prc !== "null" &&
            transactionResultOfThisOrder.src &&
            transactionResultOfThisOrder.src !== "null"
          ) {
            handleFailed(
              new AsiaPayError(
                transactionResultOfThisOrder.errMsg || "",
                transactionResultOfThisOrder.prc,
                transactionResultOfThisOrder.src
              )
            );
            return;
          }
        }
      } catch (e) {
        console.log("kenchannnn checkTransactionStatus", { e });
      } finally {
        if (isActive) {
          const retryTime = getRetryTime();
          if (retryTime) {
            await timeout(retryTime);
            checkTransactionStatus();
          } else {
            handleFailed(new Error("Retry timeout"));
          }
        } else {
          checkTransactionStatusInProgress = false;
        }
      }
    };

    // Handle app resume from alipay app
    const appStateChangeHandler = App.addListener(
      "appStateChange",
      (state: AppState) => {
        console.log("kenchannnn", "appStateChange", state.isActive);
        isActive = state.isActive;
        if (!state.isActive) {
          isInactiveOnce = true;
        }
        if (state.isActive && isInactiveOnce) {
          resetRetryStage();
          checkTransactionStatusIfNotInProgress();
        }
      }
    );

    const handleSuccess = () => {
      completed = true;
      appStateChangeHandler.remove();
      resolve();
    };

    const handleFailed = (err: any) => {
      completed = true;
      appStateChangeHandler.remove();
      reject(err);
    };

    pay(
      "DIRECT",
      amount,
      currCode,
      "NORMAL_PAYMENT",
      orderRef,
      payMethod,
      lang,
      merchantId,
      "",
      ""
    ).then(
      () => {
        handleSuccess();
      },
      err => {
        console.log("kenchannnn err", err.message);
        console.log("kenchannnn err", err.prc);
        console.log("kenchannnn err", err.src);
        if (
          err.message ===
          "Processing is in progress, payment result is unknown (it may have been paid successfully), please check the payment status of the order in the merchant order list"
        ) {
          checkTransactionStatusIfNotInProgress();
        } else {
          handleFailed(err);
        }
      }
    );
  });
}
