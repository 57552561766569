import { IonRefresher } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { timeout } from "../utils/promise";

// Fix typescript error
// eslint-disable-next-line no-undef
type HTMLIonRefresherElement_ = HTMLIonRefresherElement;

// This component help updating `disabled` props after closeDuration
// to correctly reflect the `disabled` state.
const CLIonRefresher: React.FC<
  React.ComponentProps<typeof IonRefresher>
> = props => {
  const { disabled, children } = props;
  const ionRefresherRef = useRef<HTMLIonRefresherElement_>(null);

  useEffect(() => {
    if (ionRefresherRef.current) {
      timeout(parseTimeString(ionRefresherRef.current.closeDuration)).then(
        () => {
          if (ionRefresherRef.current) {
            const ionRefresher = ionRefresherRef.current;
            ionRefresher.disabled = disabled || false;
          }
        }
      );
    }
  }, [disabled]);

  return (
    <IonRefresher {...props} ref={ionRefresherRef}>
      {children}
    </IonRefresher>
  );
};

export default CLIonRefresher;

function parseTimeString(str: string) {
  return parseFloat(str) * (/\ds$/.test(str) ? 1000 : 1);
}
