import React from "react";

import Config from "../../Config";

export enum Setting {
  orders = "orders",
  bingoList = "bingoList",
  subscriptions = "subscriptions",
  productReviews = "productReviews",
  addressBook = "addressBook",
  myCards = "myCards",
  displayLanguage = "displayLanguage",
  notifications = "notifications",
  changePassword = "changePassword",
  logout = "logout",
  divider = "divider",
  footerCMSLinks = "footerCMSLinks",
  redemption = "redemption",
  externalRedemptionOrder = "externalRedemptionOrder",
  performanceRecords = "performanceRecords",
}

export function getSettings(
  isLoggedIn: boolean,
  shouldShowBingoList: boolean,
  hasRedemptionTab: boolean,
  hasExternalRedemptionOrder: boolean
): Setting[] {
  const loggedInSettings = [
    Setting.orders,
    ...(shouldShowBingoList ? [Setting.bingoList] : []),
    ...(hasExternalRedemptionOrder ? [Setting.externalRedemptionOrder] : []),
    ...(Config.ENABLE_SUBSCRIPTION ? [Setting.subscriptions] : []),
    Setting.productReviews,
    Setting.divider,
    ...(hasRedemptionTab ? [Setting.redemption, Setting.divider] : []),
    Setting.addressBook,
    Setting.myCards,
    Setting.displayLanguage,
    Setting.notifications,
    ...(Config.ENABLE_THE_CLUB_SSO_MVP1 &&
    Config.ENABLE_THE_CLUB_SSO_MVP1
      .THE_CLUB_EDIT_SHOULD_GO_TO_THE_CLUB_MEMBER_PROFILE
      ? []
      : [Setting.changePassword]),
    Setting.divider,
    Setting.footerCMSLinks,
    Setting.divider,
    Setting.logout,
    ...(Config.INFO_ENABLE_PERFORMANCE_LOGGING
      ? [Setting.divider, Setting.performanceRecords]
      : []),
  ];
  const guestSettings = [
    ...(hasExternalRedemptionOrder
      ? [Setting.externalRedemptionOrder, Setting.divider]
      : []),
    ...(hasRedemptionTab ? [Setting.redemption, Setting.divider] : []),
    Setting.displayLanguage,
    Setting.notifications,
    Setting.divider,
    Setting.footerCMSLinks,
    ...(Config.INFO_ENABLE_PERFORMANCE_LOGGING
      ? [Setting.divider, Setting.performanceRecords]
      : []),
  ];
  return isLoggedIn ? loggedInSettings : guestSettings;
}

export interface SettingDisplayInfo {
  title: React.ReactNode;
  iconPath: string;
  showRightArrow: boolean;
  count?: number;
}
