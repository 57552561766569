export function resizeImage(
  base64String: string,
  height: number,
  width: number
): Promise<string | null> {
  return new Promise(resolve => {
    const img = new Image();
    img.src = base64String;
    img.onload = () => {
      const canvas = document.createElement("canvas");

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        resolve(null);
        return;
      }
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL("image/jpeg"));
    };
  });
}

export function getBase64ImageFromURL(url: string): Promise<string> {
  return new Promise(resolve => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    if (!img.complete) {
      img.onload = () => {
        resolve(getBase64Image(img));
      };
      img.onerror = e => {
        throw e;
      };
    } else {
      resolve(getBase64Image(img));
    }
  });
}

function getBase64Image(img: HTMLImageElement) {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw Error("cannot-create-canvas");
  }
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL();
}
