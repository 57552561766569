import React from "react";
import { RepositoryContext, useRepositoryStateReducer } from "./State";

const RepositoryProvider: React.FC = props => {
  const repositoryState = useRepositoryStateReducer();
  return (
    <RepositoryContext.Provider value={repositoryState}>
      {props.children}
    </RepositoryContext.Provider>
  );
};

export default RepositoryProvider;
