import { InAppBrowserObject } from "@ionic-native/in-app-browser";
import Config from "../Config";

export type Subscription = ReturnType<
  ReturnType<InAppBrowserObject["on"]>["subscribe"]
>;

interface InAppBrowserMessageCallbackParams<MessageType extends Object> {
  data: Partial<MessageType>;
}

interface InAppBrowserHandleAnchorTargetBlankMessageType {
  openUrlInBlank: string;
}

// This function will be plugged to the in app browser.
// Since the variable may be renamed during bundling,
// please make sure the variables are provided from iab env through parameters.
function handleAnchorTargetBlank(
  window: Window,
  document: Document,
  JSON: JSON
) {
  const elements = document.querySelectorAll('a[target="_blank"]');
  const makeMessage = (
    url: string
  ): InAppBrowserHandleAnchorTargetBlankMessageType => {
    return {
      openUrlInBlank: url,
    };
  };
  const handler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget) {
      (window as any).webkit.messageHandlers.cordova_iab.postMessage(
        JSON.stringify(makeMessage((e.currentTarget as HTMLAnchorElement).href))
      );
    }
  };
  elements.forEach(el => {
    el.addEventListener("click", handler);
  });
}

export function executeHandleAnchorTargetBlank(
  onUrlHandle: (url: string) => void,
  browser: InAppBrowserObject
): () => void {
  const subscriptions: Subscription[] = [];

  const loadstop = browser.on("loadstop");
  if (loadstop) {
    subscriptions.push(
      loadstop.subscribe(() => {
        browser.executeScript({
          code: `(${handleAnchorTargetBlank.toString()})(window, document, JSON);`,
        });
      })
    );
  }

  const message = browser.on("message");
  if (message) {
    subscriptions.push(
      message.subscribe(
        (
          params: InAppBrowserMessageCallbackParams<
            InAppBrowserHandleAnchorTargetBlankMessageType
          >
        ) => {
          if (params.data.openUrlInBlank) {
            onUrlHandle(params.data.openUrlInBlank);
          }
        }
      )
    );
  }

  return () => {
    subscriptions.forEach(subscription => subscription.unsubscribe());
  };
}

const androidHttpIntentPrefixRegex = /^http:\/\/intent:\/\//;
const dynamicLinkRegex =
  Config.DYNAMICLINK_URLS && Config.DYNAMICLINK_URLS.length > 0
    ? new RegExp(
        Config.DYNAMICLINK_URLS.map(s => `^(http|https)://${s}`).join("|")
      )
    : null;

export function handleDynamicLink(
  onUrlHandle: (url: string) => void,
  browser: InAppBrowserObject
): () => void {
  const subscriptions: Subscription[] = [];

  const loadstart = browser.on("loadstart");
  if (loadstart) {
    subscriptions.push(
      loadstart.subscribe(e => {
        let { url: urlString } = e;
        if (androidHttpIntentPrefixRegex.exec(e.url)) {
          urlString = urlString.replace(
            androidHttpIntentPrefixRegex,
            "https://"
          );
        }
        if (dynamicLinkRegex && dynamicLinkRegex.exec(urlString)) {
          try {
            const url = new URL(urlString);
            const link = url.searchParams.get("link");
            if (link) {
              onUrlHandle(link);
            }
          } catch {}
        }
      })
    );
  }

  return () =>
    subscriptions.forEach(subscription => subscription.unsubscribe());
}

const deepLinkRegex =
  Config.DEEPLINK_URLS && Config.DEEPLINK_URLS.length > 0
    ? new RegExp(
        Config.DEEPLINK_URLS.map(s => `^(http|https)://${s}`).join("|")
      )
    : null;

export function handleDeepLink(
  onUrlHandle: (url: string) => void,
  browser: InAppBrowserObject
): () => void {
  const subscriptions: Subscription[] = [];

  const loadstart = browser.on("loadstart");
  if (loadstart) {
    subscriptions.push(
      loadstart.subscribe(e => {
        const { url: urlString } = e;
        if (deepLinkRegex && deepLinkRegex.exec(urlString)) {
          onUrlHandle(urlString);
        }
      })
    );
  }

  return () =>
    subscriptions.forEach(subscription => subscription.unsubscribe());
}
