import { IndexMap } from "../utils/type";

export type CountryID = string;
export type CountryCode = string;

export interface Country {
  id: CountryID;
  name: string;
  code: CountryCode;
}

export type RegionID = number;
export type RegionCode = string;

export interface RemoteRegion {
  id: RegionID | null;
  code: RegionCode | null;
  name: string | null;
}

export interface Region {
  id: RegionID;
  code: RegionCode;
  name: string;
}

export type DistrictID = number;
export type DistrictName = string;

export interface RemoteDistrict {
  id: DistrictID | null;
  regionId: RegionID | null;
  name: DistrictName | null;
}

export interface District {
  id: DistrictID;
  regionId: RegionID;
  name: DistrictName;
}

export interface RemoteCountry {
  id: CountryID | null;
  name: string | null;
  code: CountryCode | null;
  regions: RemoteRegion[] | null;
}

export interface CountryRegions {
  countries: Country[];
  regionsByCountryID: IndexMap<CountryID, Region[]>;
}

export const CountryGraphQLAttributes = `
  id
  code: two_letter_abbreviation
  name: full_name_locale
  regions: available_regions {
    id
    code
    name
  }
`;

export const DistrictGraphQLAttributes = `
  id
  regionId: region_id
  name
`;

export function transformRemoteCountries(
  remoteCountries: RemoteCountry[]
): CountryRegions {
  const res: CountryRegions = { countries: [], regionsByCountryID: {} };
  for (const rCountry of remoteCountries) {
    const { id, code, name, regions } = rCountry;
    if (id && code && name) {
      res.countries.push({ id, code, name: changeCountryNameIfNeeded(name) });
      if (regions) {
        const validRegions: Region[] = [];
        for (const region of regions) {
          const { id: rid, code: rcode, name: rname } = region;
          if (rid && rcode && rname) {
            validRegions.push({ id: rid, code: rcode, name: rname });
          }
        }
        res.regionsByCountryID[id] = validRegions;
      }
    }
  }
  return res;
}

export function transformRemoteDistricts(
  remoteDistricts: RemoteDistrict[]
): District[] {
  const districts: District[] = [];
  for (const remoteDistrict of remoteDistricts) {
    const { id, name, regionId } = remoteDistrict;
    if (id && name && regionId) {
      districts.push({ id, name, regionId });
    }
  }
  return districts;
}

function changeCountryNameIfNeeded(name: string): string {
  const changes: { [key: string]: string } = {
    [`中華人民共和國香港特別行政區`]: "香港",
    "Hong Kong SAR China": "Hong Kong",
  };

  return changes[name] ? changes[name] : name;
}
