import { ApolloClient, gql } from "@apollo/client";

import {
  ShippingMethod,
  PartialCart,
  CartAddressInput,
  DeliveryEndpointType,
  DeliveryEndpointParameters,
  DeliveryTimeSlotValue,
} from "./models";
import { OppCardOption } from "./Context";

import { MoneyGraphQLAttributes } from "../../models/Price";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";
import { TheClubOTPParameters } from "../../utils/TheClubOTP";
import { NumericBoolean } from "../../utils/type";
import {
  CartDiscountBreakdownGraphQLAttributes,
  CartDiscountsGraphQLAttributes,
  SelectedConfigurableOptionGraphQL,
} from "../../models/cart";
import { ProductConfigurableOptionGraphQLAttributes } from "../../models/product";
import { parseGraphQLError } from "../../api/GraphQL";
import { runMutations } from "../../api/GraphQLMutation";
import { MutationDefinitionWithCartId } from "./mutation";

const cartAddressGraphQLAttributes = `
firstname
lastname
company
telephone
city
street
region {
  code
}
country {
  code
}
`;

const shippingMethodGraphQLAttributes = `
carrierCode: carrier_code
methodCode: method_code
`;

const shippingCartAddressGraphQLAttributes = `
${cartAddressGraphQLAttributes}
availableShippingMethods: available_shipping_methods {
  ${shippingMethodGraphQLAttributes}
}
`;

const ProductVariantGraphQLAttributes = `
product {
  id
  sku
  type: type_id
  deliveryMethod: delivery_method
  minClubPoint: min_clubpoints
  name
}
attributes {
  label
  value: value_index
  code
}
`;

const ProductGraphQLAttribute = `
id
sku
type: type_id
deliveryMethod: delivery_method
minClubPoint: min_clubpoints
name
...on ConfigurableProduct {
  configurableOptions: configurable_options {
    ${ProductConfigurableOptionGraphQLAttributes}
  }
  variants {
    ${ProductVariantGraphQLAttributes}
  }
}
`;

export function getCartGraphQL(
  appliedCouponField: "appliedCoupon" | "appliedCoupons",
  discountField: "discounts" | "discountBreakdown"
) {
  return `
id
items {
  id
  product {
    ${ProductGraphQLAttribute}
  }
  ...on ConfigurableCartItem {
    configurableOptions: configurable_options {
      ${SelectedConfigurableOptionGraphQL}
    }
  }
  quantity
}
clubpointsToBeEarned: clubpoints_to_be_earned
clubPointToBeUsed: clubpoints_to_be_used
clubPointRequired: clubpoints_required
shippingAddresses: shipping_addresses {
  ${shippingCartAddressGraphQLAttributes}
}
billingAddress: billing_address {
  ${cartAddressGraphQLAttributes}
}
availableDeliveryEndpoints: available_delivery_endpoints {
  label
  type
}
availableDeliveryTimeSlots: available_delivery_timeslots {
  label
  value
  valueText: value_text
}
selectedDeliveryTimeSlot: selected_delivery_timeslot
availablePaymentMethods: available_payment_methods {
  code
  title
}
selectedPaymentMethod: selected_payment_method {
  code
}
availableO2oStores: available_o2o_stores {
  country {
    id
    code: two_letter_abbreviation
  }
  region {
    code
    id
    name
  }
  city
  storeType: store_type
  lockerProvider: locker_provider
  name
  street
  building
  block
  floor
  flat
  code
  openingHours: opening_hours
}
${
  appliedCouponField === "appliedCoupons"
    ? `
appliedCoupons: applied_coupons {
  code
}
`
    : appliedCouponField === "appliedCoupon"
    ? `
appliedCoupon: applied_coupon {
  code
}
`
    : ""
}
prices {
  grandTotal: grand_total { ${MoneyGraphQLAttributes} }
  subtotalExcludingTax: subtotal_excluding_tax { ${MoneyGraphQLAttributes} }
  subtotalIncludingTax: subtotal_including_tax { ${MoneyGraphQLAttributes} }
  subtotalWithDiscountExcludingTax: subtotal_with_discount_excluding_tax { ${MoneyGraphQLAttributes} }
  discountAmount: discount_amount { ${MoneyGraphQLAttributes} }
  initialSubscriptionFee: initial_subscription_fee { ${MoneyGraphQLAttributes} }
${
  discountField === "discounts"
    ? `
  discounts { ${CartDiscountsGraphQLAttributes} }
`
    : discountField === "discountBreakdown"
    ? `
  discountBreakdown: discount_breakdown { ${CartDiscountBreakdownGraphQLAttributes} }
`
    : ""
}
  shippingAmount: shipping_amount { ${MoneyGraphQLAttributes} }
}
`;
}

const TheClubOTPParameterGraphQLAttributes = `
additionalRemarks
callbackUri
cancelUri
channel
clientId
clubMemberId
clubPointAmount
idSignature
language
orderReferenceNo
packageCode
signature
`;

export async function fetchCart(
  client: ApolloClient<any>,
  locale: Locale,
  id: string
): Promise<PartialCart> {
  try {
    const result = await client.query<{ cart: PartialCart }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryCart($cart_id: String!) {
        cart(cart_id: $cart_id) {
          ${getCartGraphQL("appliedCoupons", "discounts")}
        }
      }
    `,
      variables: {
        cart_id: id,
      },
      fetchPolicy: "no-cache",
    });

    return result.data.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function updateDeliveryEndpointOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  deliveryEndpointType: DeliveryEndpointType,
  deliveryEndpointParameters?: DeliveryEndpointParameters
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setDeliveryEndpointOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation UpdateDeliveryEndpointOnCart(
        $cart_id: String!,
        $delivery_endpoint_type: String!,
        $o2o_store_code: String
      ) {
        setDeliveryEndpointOnCart(input: { cart_id: $cart_id, delivery_endpoint_type: $delivery_endpoint_type, o2o_store_code: $o2o_store_code}) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cart_id: cartId,
        delivery_endpoint_type: deliveryEndpointType,
        o2o_store_code: deliveryEndpointParameters
          ? deliveryEndpointParameters.o2oStoreCode
          : undefined,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setDeliveryEndpointOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function updateDeliveryTimeSlotOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  deliveryTimeSlot: DeliveryTimeSlotValue | null
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setDeliveryTimeSlotOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation UpdateDeliveryTimeSlotOnCart(
        $cartId: String!,
        $deliveryTimeSlot: String
      ) {
        setDeliveryTimeSlotOnCart(input: {
          cart_id: $cartId,
          delivery_time_slot: $deliveryTimeSlot
        }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
        deliveryTimeSlot,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setDeliveryTimeSlotOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function removeCouponFromCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      removeCouponFromCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation RemoveCouponFromCart($cartId: String!) {
        removeCouponFromCart(input: { cart_id: $cartId }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.removeCouponFromCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function applyCouponToCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  couponCode: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      applyCouponToCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation ApplyCouponToCart($cartId: String!, $couponCode: String!) {
        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
        couponCode,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.applyCouponToCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function setShippingAddressOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  address: CartAddressInput
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setShippingAddressesOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation SetShippingAddressOnCart($cartId: String!, $address: CartAddressInput!) {
          setShippingAddressesOnCart(input: { cart_id: $cartId, shipping_addresses: [{ address: $address }] }) {
            cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
            }
          }
        }
      `,
      variables: {
        cartId,
        address: {
          city: address.city,
          company: address.company,
          country_code: address.countryCode,
          firstname: address.firstname,
          lastname: address.lastname,
          region: address.region,
          save_in_address_book: address.saveInAddressBook,
          street: address.street,
          telephone: address.telephone,
        },
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setShippingAddressesOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function setShippingMethodOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  shippingMethod: ShippingMethod
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setShippingMethodsOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation SetShippingMethodsOnCart($cartId: String!, $shippingMethod: ShippingMethodInput!) {
        setShippingMethodsOnCart(input: { cart_id: $cartId, shipping_methods: [$shippingMethod] }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
        shippingMethod: {
          carrier_code: shippingMethod.carrierCode,
          method_code: shippingMethod.methodCode,
        },
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setShippingMethodsOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function setBillingAddressOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  address: CartAddressInput | null
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setBillingAddressOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation SetBillingAddressOnCart($cartId: String!, $address: CartAddressInput) {
          setBillingAddressOnCart(input: { cart_id: $cartId, billing_address: { address: $address } }) {
            cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
            }
          }
        }
      `,
      variables: {
        cartId,
        address:
          address == null
            ? null
            : {
                city: address.city,
                company: address.company,
                country_code: address.countryCode,
                firstname: address.firstname,
                lastname: address.lastname,
                region: address.region,
                save_in_address_book: address.saveInAddressBook,
                street: address.street,
                telephone: address.telephone,
              },
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setBillingAddressOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function setPaymentMethodOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  paymentMethodCode: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setPaymentMethodOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation SetPaymentMethodOnCart($cartId: String!, $paymentMethodCode: String!) {
        setPaymentMethodOnCart(input: { cart_id: $cartId, payment_method: { code: $paymentMethodCode } }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
        paymentMethodCode,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setPaymentMethodOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function placeOrder(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string
): Promise<{ orderId: string }> {
  try {
    const result = await client.mutate<{
      placeOrder: { order: { orderId: string } };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation PlaceOrder($cartId: String!) {
          placeOrder(input: { cart_id: $cartId }) {
            order {
              orderId: order_id
            }
          }
        }
      `,
      variables: {
        cartId,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.placeOrder.order;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function setClubPointOnCart(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  clubPoint: number
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setClubPointOnCart: { cart: PartialCart };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
    mutation SetClubPointOnCart($cartId: String!, $clubPoint: Int!) {
      setClubPointOnCart(input: { cart_id: $cartId, clubpoints: $clubPoint }) {
        cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
        }
      }
    }
  `,
      variables: {
        cartId,
        clubPoint,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setClubPointOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function setEmailAddressOnCart(
  client: ApolloClient<unknown>,
  locale: Locale,
  cartId: string,
  emailAddress: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<
      {
        setEmailAddressOnCart: { cart: PartialCart };
      },
      { cartId: string; emailAddress: string }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
mutation SetEmailAddressOnCart(
  $cartId: String!
  $emailAddress: String!
) {
  setEmailAddressOnCart(input: {
    cart_id: $cartId
    email_address: $emailAddress
  }) {
    cart {
      ${getCartGraphQL("appliedCoupons", "discounts")}
    }
  }
}`,
      variables: {
        cartId,
        emailAddress,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.setEmailAddressOnCart.cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function queryTheClubOTPParameters(
  client: ApolloClient<any>,
  locale: Locale,
  orderId: string
): Promise<{ action: string; fields: TheClubOTPParameters }> {
  try {
    const result = await client.query<{
      theClubOtpParameters: {
        action: string;
        fields: TheClubOTPParameters;
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryTheClubOTPParameters($orderId: String!) {
        theClubOtpParameters(order_id: $orderId) {
          action
	  fields {
	    ${TheClubOTPParameterGraphQLAttributes}
	  }
        }
      }
    `,
      variables: {
        orderId,
      },
    });
    return result.data.theClubOtpParameters;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function confirmOrderBurntPoint(
  client: ApolloClient<any>,
  locale: Locale,
  orderId: string
): Promise<boolean> {
  try {
    const result = await client.mutate<{
      confirmOrderBurntPoint: boolean;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation ConfirmOrderBurntPoint($orderId: String!) {
          confirmOrderBurntPoint(order_id: $orderId)
        }
      `,
      variables: {
        orderId,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.confirmOrderBurntPoint;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function queryAsiapayClientPostParameters(
  client: ApolloClient<any>,
  locale: Locale,
  orderId: string
): Promise<{
  action: string;
  fields: string[];
  values: string[];
}> {
  try {
    const result = await client.query<{
      asiapayClientPostParameters: {
        action: string;
        fields: string[];
        values: string[];
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryAsiapayClientPostParameters($orderId: String!) {
          asiapayClientPostParameters(order_id: $orderId) {
            action
            fields
            values
          }
        }
      `,
      variables: {
        orderId,
      },
    });
    return result.data.asiapayClientPostParameters;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function queryOppClientPostParameters(
  client: ApolloClient<any>,
  locale: Locale,
  orderId: string,
  oppCardOption: OppCardOption
): Promise<{
  action: string;
}> {
  const additionalData: {
    card_token?: string;
    is_save_card?: NumericBoolean;
  } = (() => {
    switch (oppCardOption.type) {
      case "existing":
        return { card_token: oppCardOption.oppCard.token };
      case "new":
        return { is_save_card: oppCardOption.saveCard };
      default:
        return {};
    }
  })();

  try {
    const result = await client.query<{
      oppClientPostParameters: {
        action: string;
      };
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryOppClientPostParameters(
          $orderId: String!
          $additionalData: additionalDataInput!
        ) {
          oppClientPostParameters(
            order_id: $orderId
            additional_data: $additionalData
          ) {
            action
          }
        }
      `,
      variables: {
        orderId,
        additionalData,
      },
    });
    return result.data.oppClientPostParameters;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function mPosPayPrepareOrderForQrCodeScan(
  client: ApolloClient<any>,
  locale: Locale,
  incrementID: string
): Promise<boolean> {
  try {
    const result = await client.mutate<{
      mPosPayPrepareOrderForQrCodeScan: boolean;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation mPosPayPrepareOrderForQrCodeScan($incrementID: String!) {
          mPosPayPrepareOrderForQrCodeScan(increment_id: $incrementID)
        }
      `,
      variables: {
        incrementID,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.mPosPayPrepareOrderForQrCodeScan;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

enum AuthorizeApplePayPaymentDataSuccessCode {
  Succeeded = 0,
}

export async function authorizeApplePayPaymentData(
  client: ApolloClient<unknown>,
  orderId: number,
  paymentData: string
): Promise<void> {
  try {
    const result = await client.mutate<
      {
        authorizeApplePayToken: {
          successCode: AuthorizeApplePayPaymentDataSuccessCode;
          errMsg: string | null;
        };
      },
      { orderId: number; paymentData: string }
    >({
      mutation: gql`
        mutation AuthorizeApplePayPaymentData(
          $orderId: Int!
          $paymentData: String!
        ) {
          authorizeApplePayToken(
            order_id: $orderId
            payment_data: $paymentData
          ) {
            successCode: success_code
            errMsg: err_msg

            amt
            auth_id
            cur
            holder
            ord
            pay_ref
            prc
            ref
            src
            tx_time
          }
        }
      `,
      variables: {
        orderId,
        paymentData,
      },
    });
    if (!result.data) {
      throw new Error();
    }

    if (
      result.data.authorizeApplePayToken.successCode !==
      AuthorizeApplePayPaymentDataSuccessCode.Succeeded
    ) {
      const { errMsg } = result.data.authorizeApplePayToken;
      throw new Error(errMsg || "Authorization failed for unknown reason");
    }
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export function runCartMutations(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  ...mutationDefinitions: MutationDefinitionWithCartId[]
) {
  return runMutations<{ cart: PartialCart }>(
    client,
    {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    "CartMutations",
    ...mutationDefinitions.map(m => m(cartId))
  );
}
