import React, { useCallback } from "react";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./Section.module.scss";

interface Props {
  num: number;
  titleID: MessageID;
  onEditClick?: () => any;
}

const Section: React.FC<Props> = props => {
  const { num, titleID, onEditClick, children } = props;

  const handleEditClick = useCallback(() => {
    if (onEditClick) {
      onEditClick();
    }
  }, [onEditClick]);

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.numContainer}>
          <span className={styles.num}>{num}</span>
        </div>
        <div className={styles.title}>
          <LocalizedText messageID={titleID} />
        </div>
        {onEditClick && (
          <div className={styles.editColumn}>
            <button onClick={handleEditClick} className={styles.editButton}>
              <LocalizedText messageID="checkout.confirmation.edit_button" />
            </button>
          </div>
        )}
      </div>
      <div className={styles.sectionContent}>{children}</div>
    </div>
  );
};

export default Section;
