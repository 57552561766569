import { IonButton } from "@ionic/react";
import QRCode from "qrcode.react";
import React, { useCallback, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Sentry from "sentry-cordova";
import { useGraphQLFn } from "../../hook/graphql";
import { LocalizedText } from "../../i18n/Localization";
import { navigateToCheckoutOrderedPage } from "../../navigation/routes";
import { OurNavContext } from "../../our-navigation";
import { purchaseEvent } from "../../utils/GTM";
import { PrimaryButton } from "../Button";
import { fetchOrder } from "../CheckoutConfirmationPage/api";
import CLContent from "../CLContent";
import {
  LoadingModalContextProps,
  withLoadingModal,
} from "../LoadingModalProvider";
import { NavBar } from "../NavBar";
import { usePollOrderStatus } from "./api";
import styles from "./styles.module.scss";

type Props = RouteComponentProps<any> & LoadingModalContextProps;

const MPOSPayPaymentPage: React.FC<Props> = props => {
  const {
    match,
    loadingModalContext: { show: showLoading, hide: hideLoading },
  } = props;
  const orderID = match.params.orderID;
  const { navigate } = useContext(OurNavContext);

  const { startPolling, stopPolling, orderStatus } = usePollOrderStatus();

  useEffect(() => {
    startPolling(orderID);
    return stopPolling;
  }, [orderID, startPolling, stopPolling]);

  const fetchOrder_ = useGraphQLFn(fetchOrder);

  useEffect(() => {
    (async () => {
      if (orderStatus === "complete") {
        try {
          const order = await fetchOrder_(orderID);
          purchaseEvent(order);
          navigateToCheckoutOrderedPage(navigate, { order });
        } catch {
          Sentry.captureException(
            new Error("Cannot get order. No purchase event logged.")
          );
        }
      }
    })();
  }, [orderStatus, navigate, orderID, fetchOrder_]);

  const onRefreshClick = useCallback(() => {
    console.log("TODO: onRefreshClick");
  }, []);

  const onDismissClick = useCallback(() => {
    showLoading();
    fetchOrder_(orderID)
      .then(order => {
        navigateToCheckoutOrderedPage(navigate, { order });
      })
      .catch(() => {})
      .finally(() => hideLoading());
  }, [navigate, orderID, fetchOrder_, showLoading, hideLoading]);

  return (
    <>
      <NavBar
        headerLeft={<IonButton />}
        headerTitle={
          <LocalizedText messageID="checkout.payment_qr_code.title" />
        }
      />
      <CLContent className={styles.ionContent}>
        <div className={styles.content}>
          <div className={styles.orderID}>
            <LocalizedText
              messageID="checkout.payment_qr_code.your_order_id_is"
              messageArgs={{ orderID }}
            />
          </div>
          <QRCode className={styles.qrcode} value={orderID} />
          <div className={styles.refreshButton}>
            <PrimaryButton onClick={onRefreshClick}>
              <LocalizedText messageID="checkout.payment_qr_code.refresh" />
            </PrimaryButton>
          </div>
          <PrimaryButton onClick={onDismissClick}>
            (DEV) success without waiting for complete
          </PrimaryButton>
        </div>
      </CLContent>
    </>
  );
};

export default withLoadingModal(MPOSPayPaymentPage);
