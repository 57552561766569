import React, { useMemo, useCallback, useContext } from "react";

import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { WishlistItem } from "../models/Wishlist";

import { PaginationInfo } from "../repository/State";
import { useIsLoggedIn } from "../repository/AuthRepository";
import {
  useFetchWishlist,
  useAddProductToWishlist,
  useMemoryProductLikedBySKUMap,
  useRemoveItemFromWishlist,
} from "../repository/WishlistRepository";

import { useKeepUpdatingRef } from "../hook/utils";
import { withProviders } from "./Provider";
import LoadingModalProvider, {
  LoadingModalContext,
} from "./LoadingModalProvider";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";

interface WishlistContextValue {
  requestState: ResourcesRequestState<WishlistItem[] | null>;
  paginationInfo: PaginationInfo<WishlistItem> | null;
  fetchNext: () => Promise<WishlistItem[] | null>;
  refresh: () => Promise<WishlistItem[] | null>;

  toggleProductFromWishlist: (
    sku: string,
    onNeedUserLogin: () => void
  ) => Promise<void>;

  addToWishlist: (sku: string, onNeedUserLogin: () => void) => Promise<boolean>;
}

export const WishlistContext = React.createContext<WishlistContextValue>(
  null as any
);

const WishlistProvider: React.FC = props => {
  const {
    requestState,
    paginationInfo,
    fetchNext,
    refresh,
  } = useFetchWishlist();

  const addProductToWishlist = useAddProductToWishlist();
  const removeItemFromWishlist = useRemoveItemFromWishlist();

  const isLoggedIn = useIsLoggedIn();
  const isLoggedInRef = useKeepUpdatingRef(isLoggedIn);

  const memoryProductLikedBySKUMap = useMemoryProductLikedBySKUMap();
  const memoryProductLikedBySKUMapRef = useKeepUpdatingRef(
    memoryProductLikedBySKUMap
  );

  const { show: showLoading, hide: hideLoading } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const toggleProductFromWishlist = useCallback(
    async (sku: string, onNeedUserLogin: () => void) => {
      if (!isLoggedInRef.current) {
        onNeedUserLogin();
        return;
      }
      const isProductLiked =
        memoryProductLikedBySKUMapRef.current[sku] === true;
      try {
        showLoading();
        if (isProductLiked) {
          await removeItemFromWishlist(isLoggedInRef.current, sku);
        } else {
          await addProductToWishlist(isLoggedInRef.current, sku);
        }
      } catch {
        if (isProductLiked) {
          presentLocalizedAlert({
            headerId: "alert.error.title",
            messageId: "wishlist.remove_from_wishlist.error",
            buttons: [{ textMessageID: "alert.button.ok" }],
          });
        } else {
          presentLocalizedAlert({
            headerId: "alert.error.title",
            messageId: "wishlist.add_to_wishlist.error",
            buttons: [{ textMessageID: "alert.button.ok" }],
          });
        }
      } finally {
        hideLoading();
      }
    },
    [
      isLoggedInRef,
      memoryProductLikedBySKUMapRef,
      addProductToWishlist,
      removeItemFromWishlist,
      showLoading,
      hideLoading,
      presentLocalizedAlert,
    ]
  );

  const addToWishlist = useCallback(
    async (sku: string, onNeedUserLogin: () => void) => {
      if (!isLoggedInRef.current) {
        onNeedUserLogin();
        return false;
      }
      const isProductLiked =
        memoryProductLikedBySKUMapRef.current[sku] === true;
      if (isProductLiked) {
        return true;
      }
      showLoading();
      try {
        await addProductToWishlist(isLoggedInRef.current, sku);
        return true;
      } catch {
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: "wishlist.add_to_wishlist.error",
          buttons: [{ textMessageID: "alert.button.ok" }],
        });
        return false;
      } finally {
        hideLoading();
      }
    },
    [
      isLoggedInRef,
      memoryProductLikedBySKUMapRef,
      addProductToWishlist,
      showLoading,
      hideLoading,
      presentLocalizedAlert,
    ]
  );

  const contextValue = useMemo<WishlistContextValue>(
    () => ({
      requestState,
      paginationInfo,
      fetchNext,
      refresh,

      toggleProductFromWishlist,
      addToWishlist,
    }),
    [
      requestState,
      paginationInfo,
      fetchNext,
      refresh,
      toggleProductFromWishlist,
      addToWishlist,
    ]
  );
  return (
    <WishlistContext.Provider value={contextValue}>
      {props.children}
    </WishlistContext.Provider>
  );
};

export default withProviders(WishlistProvider, LoadingModalProvider);
