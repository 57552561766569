import React, { useMemo, useState } from "react";

import TheClubPreferredLoginModal from "./TheClubPreferredLoginModal";
import { useModalState } from "../hook/modal";
import { Customer } from "../models/Customer";
import { pageView, singleSignOnImpressionEvent } from "../utils/GTM";

interface LoginSignupModalContext {
  presentLoginModal: (
    onDidLogin?: ((customer: Customer) => void) | null
  ) => void;
}

export const LoginSignupModalContext = React.createContext<
  LoginSignupModalContext
>(null as any);

export const LoginSignupModalProvider: React.FC = props => {
  const [
    isTheClubPreferredLoginModalOpen,
    presentTheClubPreferredLoginModal,
    dismissTheClubPreferredLoginModal,
  ] = useModalState();

  const [_onDidLogin, setOnDidLoginCallback] = useState<
    ((customer: Customer) => void) | null
  >(null);

  const contextValue = useMemo(
    () => ({
      presentLoginModal: (
        onDidLogin: ((customer: Customer) => void) | null = null
      ) => {
        setOnDidLoginCallback(() => onDidLogin);
        presentTheClubPreferredLoginModal();
        singleSignOnImpressionEvent();
        pageView({ page: "User Login" });
      },
    }),
    [presentTheClubPreferredLoginModal]
  );

  return (
    <>
      <LoginSignupModalContext.Provider value={contextValue}>
        {props.children}
      </LoginSignupModalContext.Provider>
      <TheClubPreferredLoginModal
        isModalOpen={isTheClubPreferredLoginModalOpen}
        onRequestDismiss={dismissTheClubPreferredLoginModal}
        onDidLogin={_onDidLogin}
      />
    </>
  );
};
