import React, { useCallback, useEffect, useMemo } from "react";
import classnames from "classnames";
import styles from "./PurchasedItemList.module.scss";
import commonStyles from "./styles.module.scss";
import { PartialOrderItem } from "./model";
import { getLastShipmentStage, ShipmentStatus } from "../../models/Order";
import Item from "./Item";

interface PurhcasedItemListProps {
  currencyCode: string;
  items: PartialOrderItem[];
  onClickReorder: (item: PartialOrderItem) => void;
  onClickViewDelivery: (shipmentStatus: ShipmentStatus) => void;
  onClickReview: (item: PartialOrderItem) => void;
  onClickRedeem: (item: PartialOrderItem) => void;
  shipmentStatusList: ShipmentStatus[] | null;
  onQRCodeLinkClicked: (url: string) => void;
  onClickViewInsurance: (item: PartialOrderItem) => void;
  onClickRedemptionLetterUrl: (url: string) => void;
}

const PurhcasedItemList: React.FC<PurhcasedItemListProps> = props => {
  const {
    currencyCode,
    items,
    onClickReorder,
    onClickViewDelivery,
    onClickReview,
    onClickRedeem,
    shipmentStatusList,
    onQRCodeLinkClicked,
    onClickViewInsurance,
    onClickRedemptionLetterUrl,
  } = props;

  const itemsByDeliveryMethod = useMemo(
    () =>
      items.reduce<{ [typeID: number]: PartialOrderItem[] }>((acc, item) => {
        if (acc[item.shippingTypeID] == null) {
          acc[item.shippingTypeID] = [];
        }

        acc[item.shippingTypeID] = [...acc[item.shippingTypeID], item];
        return acc;
      }, {}),
    [items]
  );

  const entries = React.useMemo(() => Object.entries(itemsByDeliveryMethod), [
    itemsByDeliveryMethod,
  ]);

  const shipmentStatusByProductId = useMemo(() => {
    const res: { [key in number]: ShipmentStatus | null } = {};
    if (!shipmentStatusList) {
      return res;
    }
    for (const shipmentStatus of shipmentStatusList) {
      if (!shipmentStatus) {
        continue;
      }
      const { productIds } = shipmentStatus;
      if (!productIds || !shipmentStatus.stages) {
        continue;
      }
      for (const productId of productIds) {
        res[productId] = shipmentStatus;
      }
    }
    return res;
  }, [shipmentStatusList]);

  const hasProductIdSpecificShipmentStatus = useMemo(
    () => Object.keys(shipmentStatusByProductId).length > 0,
    [shipmentStatusByProductId]
  );

  return (
    <div>
      {entries.map(entry => (
        <div key={entry[0]} className={styles.itemListByDelivery}>
          <div className={styles.itemList}>
            {entry[1].map(item => (
              <PurchasedItemInfo
                key={item.sku}
                className={styles.itemInfo}
                currencyCode={currencyCode}
                item={item}
                onClickReorder={onClickReorder}
                onClickViewDelivery={onClickViewDelivery}
                onClickReview={onClickReview}
                onClickRedeem={onClickRedeem}
                shipmentStatus={
                  hasProductIdSpecificShipmentStatus
                    ? shipmentStatusByProductId[item.productID]
                    : null
                }
                onQRCodeLinkClicked={onQRCodeLinkClicked}
                onClickViewInsurance={onClickViewInsurance}
                onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

interface PurchasedItemInfoProps {
  className?: string;
  currencyCode: string;
  item: PartialOrderItem;
  onClickReorder: (item: PartialOrderItem) => void;
  onClickViewDelivery: (shipmentStatus: ShipmentStatus) => void;
  onClickReview: (item: PartialOrderItem) => void;
  onClickRedeem: (item: PartialOrderItem) => void;
  shipmentStatus: ShipmentStatus | null;
  onQRCodeLinkClicked: (url: string) => void;
  onClickViewInsurance: (item: PartialOrderItem) => void;
  onClickRedemptionLetterUrl: (url: string) => void;
}

const PurchasedItemInfo: React.FC<PurchasedItemInfoProps> = props => {
  const {
    className,
    currencyCode,
    item,
    onClickReorder,
    onClickViewDelivery,
    onClickReview,
    onClickRedeem,
    shipmentStatus,
    onQRCodeLinkClicked,
    onClickViewInsurance,
    onClickRedemptionLetterUrl,
  } = props;

  const shipmentStage = useMemo(() => {
    if (!shipmentStatus || !shipmentStatus.stages) {
      return null;
    }
    return getLastShipmentStage(shipmentStatus.stages);
  }, [shipmentStatus]);

  useEffect(() => console.log({ shipmentStatus, shipmentStage }), [
    shipmentStatus,
    shipmentStage,
  ]);

  const onClickViewDeliveryItem = useCallback(() => {
    if (!shipmentStatus) {
      return;
    }
    onClickViewDelivery(shipmentStatus);
  }, [onClickViewDelivery, shipmentStatus]);

  return (
    <div
      className={classnames(
        className,
        styles.itemInfoContainer,
        commonStyles.orderDetailSectionContainer
      )}
    >
      <DeliveryMethodBanner
        name={item.shippingLabel}
        shipmentStatus={shipmentStage ? shipmentStage.label : null}
        onShipmentStatusClick={onClickViewDeliveryItem}
      />
      <Item
        currencyCode={currencyCode}
        item={item}
        onClickReorder={onClickReorder}
        onClickReview={onClickReview}
        onClickRedeem={onClickRedeem}
        onQRCodeLinkClicked={onQRCodeLinkClicked}
        onClickViewInsurance={onClickViewInsurance}
        onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
      />
    </div>
  );
};

export default PurhcasedItemList;

interface DeliveryMethodBannerProps {
  name: string;
  shipmentStatus: string | null;
  onShipmentStatusClick: () => void;
}

const DeliveryMethodBanner: React.FC<DeliveryMethodBannerProps> = props => {
  const { name, shipmentStatus, onShipmentStatusClick } = props;

  const handleShipmentStatusClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.stopPropagation();
      e.preventDefault();
      onShipmentStatusClick();
    },
    [onShipmentStatusClick]
  );

  return (
    <div className={styles.deliveryMethodBanner}>
      <div className={styles.deliveryMethodBanner__name}>{name}</div>
      {shipmentStatus ? (
        <button
          className={styles.deliveryMethodBanner__shipmentStatus}
          onClick={handleShipmentStatusClick}
        >
          {shipmentStatus}
        </button>
      ) : null}
    </div>
  );
};
