import React, { useMemo } from "react";

import CLModal from "../CLModal/lazy";
import CLContent from "../CLContent";
import { LocalizedText } from "../../i18n/Localization";

import { MessageID } from "../../i18n/translations/type";

import { stringLiteral } from "../../utils/type";

import styles from "./styles.module.scss";
import Lottie from "../Lottie.lazy";

import verificationEmailSentImg from "../../resources/img-verification-email-sent.svg";

type Graphic =
  | {
      type: "image";
      imageSrc: string;
      imageAlt: string;
    }
  | {
      type: "animation";
      animationData: any;
      play: boolean;
    };

interface Props {
  isOpen: boolean;
  graphic: Graphic;
  titleMessageID: MessageID;
  onRequestDismiss: () => void;
  onClickGotoShopButton: () => void;
}
const Modal: React.FC<Props> = React.memo(props => {
  const {
    isOpen,
    onRequestDismiss,
    onClickGotoShopButton,

    graphic,
    titleMessageID,
  } = props;
  return (
    <CLModal
      className={styles.modal}
      isOpen={isOpen}
      onRequestDismiss={onRequestDismiss}
    >
      <CLContent>
        <div className={styles.modalContent}>
          <button onClick={onRequestDismiss} className={styles.crossButton} />
          {graphic.type === "image" ? (
            <img
              className={styles.graphic}
              src={graphic.imageSrc}
              alt={graphic.imageAlt}
            />
          ) : (
            <Lottie
              className={styles.graphic}
              autoplay={true}
              loop={true}
              play={graphic.play}
              animationData={graphic.animationData}
            />
          )}
          <h1 className={styles.title}>
            <LocalizedText messageID={titleMessageID} />
          </h1>
          <button className={styles.shopButton} onClick={onClickGotoShopButton}>
            <LocalizedText messageID="page.change_email.modal.button.shop" />
          </button>
        </div>
      </CLContent>
    </CLModal>
  );
});

export const VerificationEmailSentModal: React.FC<{
  isOpen: boolean;
  onRequestDismiss: () => void;
  onClickGotoShopButton: () => void;
}> = React.memo(props => {
  const graphic = useMemo(
    () => ({
      type: stringLiteral("image"),
      imageSrc: verificationEmailSentImg,
      imageAlt: "Verfication email sent",
    }),
    []
  );
  return (
    <Modal
      {...props}
      graphic={graphic}
      titleMessageID="page.change_email.modal.weve_sent_you_an_verification_email"
    />
  );
});
VerificationEmailSentModal.displayName = "VerificationEmailSentModal";

export const YourEmailUpdatedModal: React.FC<{
  isOpen: boolean;
  onRequestDismiss: () => void;
  onClickGotoShopButton: () => void;
}> = React.memo(props => {
  const { isOpen } = props;
  const graphic = useMemo(
    () => ({
      type: stringLiteral("animation"),
      animationData: require("../../resources/animation/signup-to-shop.json"),
      play: isOpen,
    }),
    [isOpen]
  );
  return (
    <Modal
      {...props}
      graphic={graphic}
      titleMessageID="page.change_email.modal.your_email_updated"
    />
  );
});
YourEmailUpdatedModal.displayName = "YourEmailUpdatedModal";
