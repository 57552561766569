import React from "react";

class RerenderBlocker extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return this.props.children;
  }
}

export default RerenderBlocker;
