import React from "react";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./Block.module.scss";

interface Props {
  children: React.ReactNode;
  titleMessageID?: MessageID;
}

const Block = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { titleMessageID, children } = props;

  return (
    <div className={styles.block} ref={ref}>
      {titleMessageID ? (
        <h3 className={styles.title}>
          <LocalizedText messageID={titleMessageID} />
        </h3>
      ) : null}
      {children}
    </div>
  );
});

export default Block;
