import React, { useCallback } from "react";

import { NumericBoolean } from "../../utils/type";
import { OppCard } from "../../models/OppCard";
import { LocalizedText } from "../../i18n/Localization";

import Checkbox from "../Checkbox";

import { OppCardOption } from "./Context";
import SelectBlock from "./SelectBlock";

import styles from "./OppCardSelectList.module.scss";

import logoMasterImg from "../../resources/logo-master.svg";
import logoVisaImg from "../../resources/logo-visa.svg";

interface Props {
  oppCards: OppCard[];
  selectedOption: OppCardOption | null;
  onChange: (oppCardOption: OppCardOption) => void;
}

export const OppCardSelectListView: React.FC<Props> = props => {
  const { oppCards, selectedOption, onChange } = props;

  const handleOppCardSelect = useCallback(
    (oppCard: OppCard) => {
      onChange({
        type: "existing",
        oppCard,
      });
    },
    [onChange]
  );

  const handleNewCardSelect = useCallback(() => {
    onChange({
      type: "new",
      saveCard: NumericBoolean.YES,
    });
  }, [onChange]);

  const handleSaveCardCheckedChange = useCallback(
    (saveCard: boolean) => {
      onChange({
        type: "new",
        saveCard: saveCard ? NumericBoolean.YES : NumericBoolean.NO,
      });
    },
    [onChange]
  );

  return (
    <>
      <div className={styles.option}>
        <SelectBlock
          header={
            <div className={styles.optionHeader}>
              <LocalizedText messageID="checkout.filling_info.payment_option.opp.new_card" />
            </div>
          }
          selected={selectedOption ? selectedOption.type === "new" : false}
          value="new"
          onSelect={handleNewCardSelect}
          collapsed={selectedOption ? selectedOption.type !== "new" : true}
          hideIndicator={oppCards.length === 0}
        >
          {selectedOption && selectedOption.type === "new" ? (
            <Checkbox
              checked={selectedOption.saveCard === NumericBoolean.YES}
              onCheckedChange={handleSaveCardCheckedChange}
            >
              <div className={styles.checkboxText}>
                <LocalizedText messageID="checkout.filling_info.payment_option.opp.save_card" />
              </div>
            </Checkbox>
          ) : null}
        </SelectBlock>
      </div>
      {oppCards.map((oppCard, i) => {
        return (
          <div className={styles.option} key={i}>
            <OppCardOptionRow
              oppCard={oppCard}
              onSelect={handleOppCardSelect}
              selected={
                selectedOption
                  ? selectedOption.type === "existing" &&
                    selectedOption.oppCard.token === oppCard.token
                  : false
              }
            />
          </div>
        );
      })}
    </>
  );
};

interface OppCardOptionRowProps {
  oppCard: OppCard;
  selected: boolean;
  onSelect: (oppCard: OppCard) => void;
}

const OppCardOptionRow: React.FC<OppCardOptionRowProps> = props => {
  const { oppCard, selected, onSelect } = props;

  const handleSelect = useCallback(() => {
    onSelect(oppCard);
  }, [oppCard, onSelect]);

  return (
    <SelectBlock
      header={
        <div className={styles.optionHeader}>
          <OppCardRow oppCard={oppCard} />
        </div>
      }
      selected={selected}
      value={oppCard.token}
      onSelect={handleSelect}
      indicatorAlignment="center"
    />
  );
};

interface OppCardRowProps {
  oppCard: OppCard;
}

export const OppCardRow: React.FC<OppCardRowProps> = props => {
  const { oppCard } = props;
  return (
    <div className={styles.oppCardRow}>
      {(() => {
        switch (oppCard.brand) {
          case "VISA":
            return (
              <img className={styles.brandIcon} alt="visa" src={logoVisaImg} />
            );
          case "MASTERCARD":
            return (
              <img
                className={styles.brandIcon}
                alt="master"
                src={logoMasterImg}
              />
            );
          default:
            return null;
        }
      })()}
      <div className={styles.cardNumber}>{oppCard.number}</div>
    </div>
  );
};
