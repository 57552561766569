import { useApolloClient } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import {
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { profileAsyncAction, profileSyncAction } from "../../utils/performance";
import { BrandSearchPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { getBrands } from "./api";
import { useFetchBrandList } from "./repository";

function useViewModel() {
  const client = useApolloClient();

  const _brands = useMemo(
    () =>
      profileSyncAction(
        BrandSearchPageSession(),
        "Load Brand List from Cache",
        () => getBrands(client)
      ),
    [client]
  );

  const [brands, setBrands] = useState(_brands);

  const [fetchBrandsRequestState, , refreshBrands] = useFetchBrandList(
    BrandSearchPageSession()
  );

  const isFullPageLoading = useMemo(
    () =>
      !brands &&
      (isRequestLoading(fetchBrandsRequestState) ||
        fetchBrandsRequestState.type === "initial"),
    [brands, fetchBrandsRequestState]
  );

  const error = useMemo(() => getRequestStateError(fetchBrandsRequestState), [
    fetchBrandsRequestState,
  ]);

  const refresh = useCallback(async () => {
    const __brands = await profileAsyncAction(
      BrandSearchPageSession(),
      "Load Brand List from Network",
      () => refreshBrands()
    );
    setBrands(__brands);
  }, [refreshBrands]);

  return {
    brands,
    refresh,

    isFullPageLoading,
    error,
  };
}

export default useViewModel;
