import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import Config from "../Config";
import { getStoreViewCodeForLocale, Locale } from "../i18n/locale";
import {
  ProductLabel,
  ProductLabelGraphQLAttributes,
  ProductLabelMode,
} from "../models/ProductLabel";

export function getProductLabelsByProductIds(
  client: ApolloClient<any>,
  productIds: number[],
  mode: ProductLabelMode
): { productId: number; productLabels: ProductLabel[] }[] {
  const result = client.readQuery<{
    amLabelProvider: { items: ProductLabel[] }[];
  }>({
    query: gql`
      query QueryProductLabels(
        $productIds: [Int!]!
        $mode: AmLabelMode
      ) {
        amLabelProvider(
          productIds: $productIds
          mode: $mode
        ) {
          items {
            ${ProductLabelGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      productIds,
      mode,
    },
  });

  const data: { [key in string]: ProductLabel[] } = {};

  if (!result) {
    return [];
  }

  for (const ls of result.amLabelProvider) {
    for (const item of ls.items) {
      const { productId } = item;
      const productLabels: ProductLabel[] = data[`${productId}`] || [];
      data[`${productId}`] = [
        ...productLabels,
        // Fix image url with site url
        {
          ...item,
          image: item.image ? `${Config.SITE_URL}/${item.image}` : null,
        },
      ];
    }
  }

  const res: { productId: number; productLabels: ProductLabel[] }[] = [];

  for (const key of Object.keys(data)) {
    if (data[key]) {
      const [{ productId }] = data[key];
      res.push({ productId, productLabels: data[key] });
    }
  }

  return res;
}

export async function fetchProductLabelsByProductIds(
  client: ApolloClient<any>,
  productIds: number[],
  mode: ProductLabelMode,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<{ productId: number; productLabels: ProductLabel[] }[]> {
  const result = await client.query<{
    amLabelProvider: { items: ProductLabel[] }[];
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryProductLabels(
        $productIds: [Int!]!
        $mode: AmLabelMode
      ) {
        amLabelProvider(
          productIds: $productIds
          mode: $mode
        ) {
          items {
            ${ProductLabelGraphQLAttributes}
          }
        }
      }
    `,
    variables: {
      productIds,
      mode,
    },
    fetchPolicy,
  });

  const data: { [key in string]: ProductLabel[] } = {};

  for (const ls of result.data.amLabelProvider) {
    for (const item of ls.items) {
      const { productId } = item;
      const productLabels: ProductLabel[] = data[`${productId}`] || [];
      data[`${productId}`] = [
        ...productLabels,
        // Fix image url with site url
        {
          ...item,
          image: item.image ? `${Config.SITE_URL}/${item.image}` : null,
        },
      ];
    }
  }

  const res: { productId: number; productLabels: ProductLabel[] }[] = [];

  for (const key of Object.keys(data)) {
    if (data[key]) {
      const [{ productId }] = data[key];
      res.push({ productId, productLabels: data[key] });
    }
  }

  return res;
}
