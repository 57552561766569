import { EntityUrlRedirect, EntityUrlType } from "../models/EntityUrl";

const findMatchedRedirectMapping = (
  urlRedirectConfig: EntityUrlRedirect[] | null,
  type: EntityUrlType,
  id: string
) => {
  const matched =
    urlRedirectConfig == null
      ? null
      : urlRedirectConfig.filter(
          mapping => mapping.type === type && `${mapping.id}` === id
        );

  if (matched != null && matched.length > 0) {
    return matched[0];
  }

  return null;
};

export default findMatchedRedirectMapping;
