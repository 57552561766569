import {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  RefObject,
} from "react";

export function useDebounce<T>(value: T, delay?: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}

function isSame<A extends any[], B extends any[]>(a: A, b: B) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function useDebounceByCompletion<Args extends any[]>(
  fn: (...args: Args) => Promise<any>
): [(...args: Args) => Promise<any>, RefObject<boolean>] {
  const inProgressRef = useRef<boolean>(false);
  const nextValueRef = useRef<Args | null>(null);

  const debounced = useCallback(
    async (...args: Args) => {
      if (inProgressRef.current) {
        nextValueRef.current = args;
        return;
      }
      inProgressRef.current = true;
      try {
        await fn(...args);
      } catch {}
      inProgressRef.current = false;
      if (nextValueRef.current != null) {
        if (isSame(nextValueRef.current, args)) {
          return;
        }
        const nextValue = nextValueRef.current;
        nextValueRef.current = null;
        debounced(...nextValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn]
  );

  return [debounced, inProgressRef];
}

export function useChain(...fns: (() => void)[]) {
  return useCallback(() => {
    fns.forEach(fn => fn());
  }, [...fns]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useKeepUpdatingRef<T>(value: T): MutableRefObject<T> {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}
