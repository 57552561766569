import React, { useEffect, useMemo } from "react";
import cn from "classnames";

import { ProductOverview } from "../../models/ProductOverview";
import { getResources } from "../../models/ResourcesRequestState";
import { ModelKeys } from "../../models/product";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { useFetchProductLabelsByProductId } from "../../repository/ProductLabelRepository";

import useInViewPolyfill from "../../hook/useInViewPolyfill";

import { ResolvedHorizontalProductListProducts } from "./index";

import styles from "./styles.module.scss";

const PRODUCTLIST_CELL_HEIGHT = 335;

interface ProductOverviewHorizontalProductListProps {
  title: string;
  backgroundUrl?: string | null;
  productOverviews: ProductOverview[];
  bundleByProductId: { [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys> };
  detailButton?: React.ReactNode;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
}

export const ProductOverviewHorizontalProductList: React.FC<
  ProductOverviewHorizontalProductListProps
> = props => {
  const {
    title,
    backgroundUrl,
    productOverviews,
    bundleByProductId,
    detailButton,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
  } = props;

  const backgroundStyle = useMemo(
    () =>
      backgroundUrl
        ? {
            backgroundImage: `url(${backgroundUrl})`,
          }
        : undefined,
    [backgroundUrl]
  );

  const productIds = useMemo(() => {
    if (!productOverviews) {
      return [];
    }
    return productOverviews.map(p => p.id);
  }, [productOverviews]);

  const [
    productLabelRequestState,
    requestProductLabelsByProductId,
  ] = useFetchProductLabelsByProductId();

  useEffect(() => {
    if (productIds.length === 0) {
      return;
    }

    requestProductLabelsByProductId(productIds, "category").catch(() => {});
  }, [productIds, requestProductLabelsByProductId]);

  const productLabelsByProductId = getResources(productLabelRequestState);

  const [ref, inView] = useInViewPolyfill();

  const productListStyle = useMemo(
    () => ({
      height: PRODUCTLIST_CELL_HEIGHT + 40,
    }),
    []
  );

  return (
    <div className={styles.container}>
      {/*
        Cannot apply position:relative in top level,
        because the static block use float
      */}
      <div className={styles.relativeContainer}>
        <div ref={ref} className={styles.visibleArea} />
        <div className={styles.header} style={backgroundStyle}>
          <>
            <p
              className={cn(styles.title, {
                [styles.hasBackground]: backgroundStyle !== undefined,
              })}
            >
              {title}
            </p>
            {detailButton && (
              <div className={styles.detailButton}>{detailButton}</div>
            )}
          </>
        </div>
        <div className={styles.products} style={productListStyle}>
          {!inView ? null : (
            <ResolvedHorizontalProductListProducts
              productOverviews={productOverviews}
              productLabelsByProductId={productLabelsByProductId}
              bundleByProductId={bundleByProductId}
              onAddToCart={onAddToCart}
              hrefForProduct={hrefForProduct}
              onClickLikeButton={onClickLikeButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductOverviewHorizontalProductList;
