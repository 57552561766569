import { Plugins } from "@capacitor/core";
import { BrowserTab } from "@ionic-native/browser-tab";
import { useCallback } from "react";
import { useGetTheClubOneTimeAuthenticatedUrl } from "../useCase/TheClubSSOUseCase";
import { isPDF } from "../utils/File";
import { makeGoogleDocsViewUrl } from "../utils/GoogleDocs";
import { isAndroid, isCapacitor } from "../utils/Platform";

const { Browser } = Plugins;

async function checkBrowserTabIsAvailable(): Promise<boolean> {
  try {
    return await BrowserTab.isAvailable();
  } catch {
    return false;
  }
}

const useOpenUrlWithBrowser = () => {
  const getTheClubOneTimeAuthenticatedUrl = useGetTheClubOneTimeAuthenticatedUrl();
  return useCallback(
    async (url: string) => {
      const presentUrl = await getTheClubOneTimeAuthenticatedUrl(url);
      return presentBrowser(presentUrl);
    },
    [getTheClubOneTimeAuthenticatedUrl]
  );
};

export default useOpenUrlWithBrowser;

export async function presentBrowser(url: string) {
  const browserTabIsAvailable = await checkBrowserTabIsAvailable();
  if (!isCapacitor()) {
    window.open(url);
  } else if (isAndroid() && browserTabIsAvailable) {
    BrowserTab.openUrl(isPDF(url) ? makeGoogleDocsViewUrl(url) : url);
  } else {
    Browser.open({ url });
  }
}
