import React from "react";
import { LocalizedText } from "../../i18n/Localization";
import { formatPriceValue } from "../../models/Price";
import styles from "./InstalmentPriceView.module.scss";

interface Props {
  instalmentAmountPerMonth: number;
}

const InstalmentPriceView: React.FC<Props> = props => {
  const { instalmentAmountPerMonth } = props;
  return (
    <div>
      <p className={styles.instalmentAsLowAsText}>
        <LocalizedText messageID="page.product_detail.instalment.as_low_as" />
      </p>
      <p>
        <span className={styles.instalmentPrice}>
          {formatPriceValue(instalmentAmountPerMonth)}
        </span>
        <span className={styles.instalmentPriceSuffix}>
          <LocalizedText messageID="page.product_detail.instalment.price_suffix" />
        </span>
      </p>
    </div>
  );
};

export default InstalmentPriceView;
