export type MerchantID = string;
export type EntityID = number;

export interface MerchantPreview {
  id: MerchantID;
  entityId: EntityID;
  logo: string | null;
  name: string;
  displayOrder: number | null;
  cmsPage: {
    id: number;
    canonicalUrl: string | null;
    relativeUrl: string | null;
  } | null;
}

export interface MerchantPreviewWithCMSPage extends MerchantPreview {
  cmsPage: {
    id: number;
    canonicalUrl: string | null;
    relativeUrl: string | null;
  };
}

export function doesMerchantHasCMSPage(
  merchant: MerchantPreview
): merchant is MerchantPreviewWithCMSPage {
  return (
    merchant.cmsPage != null &&
    (merchant.cmsPage.id !== 0 ||
      merchant.cmsPage.canonicalUrl != null ||
      merchant.cmsPage.relativeUrl != null)
  );
}

export const MerchantPreviewGraphQLAttributes = `
  id: vendor_id
  entityId: entity_id
  logo
  name: store_name
  displayOrder: display_order
  cmsPage: cms_page {
    id
    canonicalUrl: canonical_url
    relativeUrl: relative_url
  }
`;

export interface Merchant extends MerchantPreview {
  id: MerchantID;
  logo: string | null;
  name: string;
  displayOrder: number | null;
  about: string | null;
  contactNumber: string | null;
  openingHours: string | null;
  returnPolicy: string | null;
  shippingPolicy: string | null;
  shortDesc: string | null;
}

export const MerchantGraphQLAttributes = `
  ${MerchantPreviewGraphQLAttributes}
  about
  contactNumber: contact_number
  openingHours: opening_hours
  returnPolicy: return_policy
  shippingPolicy: shipping_policy
  shortDesc: short_desc
`;

const INVISIBLE_DISPLAY_ORDER = 9999;

export function isMerchantVisible(merchantPreview: MerchantPreview) {
  if (!merchantPreview.displayOrder) {
    return true;
  }
  return merchantPreview.displayOrder < INVISIBLE_DISPLAY_ORDER;
}
