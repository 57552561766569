import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouteMatch } from "react-router-dom";
import {
  useAddAppBannerToUrl,
  useAddInAppToUrl,
  useAddStoreToUrl,
} from "../../hook/Url";
import { decodeIFramePageParamUrl } from "../../navigation/routes";
import { useGetTheClubOneTimeAuthenticatedUrl } from "../../useCase/TheClubSSOUseCase";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { pageView } from "../../utils/GTM";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import { NavBar, NavBarBackButton } from "../NavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import NoInternetConnectionView from "../NoInternetConnectionView";
import styles from "./styles.module.scss";

export const IFramePage: React.FC = () => {
  const routeMatch = useRouteMatch<{ urlEncoded?: string }>();

  const url = useMemo(
    () =>
      routeMatch.params.urlEncoded
        ? decodeIFramePageParamUrl(routeMatch.params.urlEncoded)
        : "",
    [routeMatch]
  );

  return (
    <IFramePageComponent
      title=""
      src={url}
      errorMessage={null}
      includeTabBarSpace={true}
    />
  );
};

interface IFramePageComponentProps {
  title: string;
  src: string | null;
  errorMessage: string | null;
  includeTabBarSpace: boolean;
}

export const IFramePageComponent: React.FC<
  IFramePageComponentProps
> = props => {
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const { isOnline } = useContext(NetworkStatusContext);

  const addInAppToUrl = useAddInAppToUrl();
  const addStoreToUrl = useAddStoreToUrl();
  const addAppBannerToUrl = useAddAppBannerToUrl();

  const { src, title, errorMessage, includeTabBarSpace } = props;

  const getTheClubOneTimeAuthenticatedUrl = useGetTheClubOneTimeAuthenticatedUrl();
  const [maybeAuthenticatedUrl, setMaybeAuthenticatedUrl] = useState<
    string | null
  >(null);

  const url = useMemo(
    () => (src ? addAppBannerToUrl(addStoreToUrl(addInAppToUrl(src))) : null),
    [src, addAppBannerToUrl, addStoreToUrl, addInAppToUrl]
  );

  useEffect(() => {
    (async () => {
      if (url) {
        setMaybeAuthenticatedUrl(await getTheClubOneTimeAuthenticatedUrl(url));
      }
    })();
  }, [url, getTheClubOneTimeAuthenticatedUrl]);

  const viewEnter = useCallback(() => {
    pageView({ page: title });
  }, [title]);

  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const iframeStyle = useMemo(() => {
    if (!isOnline) {
      return {
        height: "calc(100% - 48px)",
      };
    }
    return {};
  }, [isOnline]);

  return (
    <>
      <NavBar headerLeft={<NavBarBackButton />} headerTitle={title} />
      <CLContent className={styles.ionContent}>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          {errorMessage != null ? (
            <div className={styles.errorContainer}>
              <div className={styles.errorIcon} />
              <div className={styles.errorMessage}> {errorMessage}</div>
            </div>
          ) : maybeAuthenticatedUrl == null ? (
            <FullContentLoadingView />
          ) : (
            <div className={styles.iframeContainer}>
              <iframe
                className={styles.iframe}
                title={title}
                src={maybeAuthenticatedUrl}
                style={iframeStyle}
              />
              {
                // FIXME
                // Since it is impossible to forward swipe event from
                // iframe, I placed a transparect view here to steal back to
                // swipe event
              }
              <div className={styles.fixme_fixCannotSwipeBack} />
              {includeTabBarSpace ? <TabBarSpacePlaceholder /> : null}
            </div>
          )}
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};
