import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
  ProfilerOnRenderCallback,
} from "react";
import cn from "classnames";
import { useLocation, useRouteMatch } from "react-router-dom";
import { RefresherEventDetail } from "@ionic/core";
import { IonLifeCycleContextInterface } from "@ionic/react";

import { FullContentLoadingView } from "../LoadingView";
import ShoppingCartButton from "../ShoppingCartButton";
import ProductList from "../ProductList";
import HTMLBasedCMSBlocks from "../CMSBlocks/HTMLBasedCMSBlocks";
import NoProducts from "./NoProducts";
import FilterButton from "../FilterButton";
import BackToTopButton from "../BackToTopButton";
import FloatingButtons from "../FloatingButtons";
import {
  TarBarHeightContext,
  TabBarSpacePlaceholder,
} from "../navigation/TabBar";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import { ProductOverview } from "../../models/ProductOverview";
import {
  useFetchCategory,
  useCategoryTreeMap,
  useFetchCategoryDescription,
} from "../../repository/CategoryRepository";
import { hasPage, CategoryTree } from "../../models/category";

import {
  ProfileSessionContext,
  ProfileSessionProvider,
} from "../../contexts/ProfileSessionContext";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useHandleBackToTopClick from "../../utils/handleBackToTopClick";
import { useIsContentScrolledToTop } from "../../utils/IonContent";
import { pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import { parseQueryParameters } from "../../utils/Url";
import parse from "../../utils/HTMLReactParser";
import {
  addPerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";
import { CategoryPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { useIonContentScrollEl } from "../../utils/ionContentScrollEl";
import { useSticky } from "../../utils/sticky";

import CLContent from "../CLContent";
import { NavBar, NavBarBackButton } from "../NavBar";
import AppliedFiltersList from "../AppliedFiltersList";
import RerenderLogger from "../Performance/RerenderLogger";

import {
  RootTab,
  getPathForShoppingCart,
  getPathForProductDetailPage,
  useCurrentTab,
  presentFilterModal,
} from "../../navigation/routes";
import { PresentationContext, OurNavContext } from "../../our-navigation";

import styles from "./styles.module.scss";
import floatingButtonStyles from "../FloatingButtons/FloatingButton.module.scss";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import { withProviders } from "../Provider";
import {
  ProductFilterInfo,
  hasFilters,
  FilterAttribute,
  RangeFilterAttribute,
  makeGraphQLFilterValue,
} from "../../models/filter";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { WishlistContext } from "../WishlistProvider";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";
import ErrorView from "../ErrorView";
import { useIntl } from "../../i18n/Localization";
import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";
import { usePresentLocalizedAlertForRequestStateError } from "../../hook/ResourcesRequestHook";
import useViewEnterForAsyncContent from "../../hook/useViewEnterForAsyncContent";
import { useKeepUpdatingRef } from "../../hook/utils";
import { MessageID } from "../../i18n/translations/type";
import useViewModel from "./ViewModel";
import { useEffectOnce } from "../../hook/useEffectOnce";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";

const SingleCategoryPageImpl: React.FC = () => {
  const match = useRouteMatch<{ categoryId: string }>();
  const location = useLocation();
  const categoryId = parseInt(match.params.categoryId, 10);
  const profileSession = CategoryPageSession(categoryId);

  const [search] = useState(location.search);

  const currentTab = useCurrentTab();

  const queryParams = useMemo(() => {
    const res = parseQueryParameters(search);
    const cat = res.cat;
    if (cat != null) {
      res.category_id = cat;
    }
    return res;
  }, [search]);

  const categoryTreeMap = useCategoryTreeMap();
  const categoryTree = categoryTreeMap[categoryId];

  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  const { present } = useContext(PresentationContext);
  const onClickShoppingCart = useCallback(() => {
    present(getPathForShoppingCart());
  }, [present]);

  const [viewDidEnter, setViewDidEnter] = useState(false);

  const viewEnter = useCallback(() => {
    if (!categoryTree) {
      return;
    }
    pageView({ page: "Subcategory", categoryName: categoryTree.name });
    setViewDidEnter(true);
  }, [categoryTree]);

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useViewEnterForAsyncContent(categoryTree, ionLifeCycleContext, viewEnter);

  const handleViewDidEnter = useCallback(() => {
    addPerformanceRecord(profileSession, "Category Page did enter");
  }, [profileSession]);

  ionLifeCycleContext.onIonViewDidEnter(handleViewDidEnter);

  const handleRerender: ProfilerOnRenderCallback = useCallback(
    (_id, phrase, actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          profileSession,
          `Category Page Mounted`,
          startTime
        );
        return;
      }
      addPerformanceRecord(
        profileSession,
        RERENDER_EVENT,
        startTime,
        startTime + actualDuration
      );
    },
    [profileSession]
  );

  const {
    isContentScrolledToTop,
    handleIonScroll,
  } = useIsContentScrolledToTop();

  const contentRef = useRef<HTMLIonContentElement>(null);

  return (
    <RerenderLogger id={`${categoryId}`} onRender={handleRerender}>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={categoryTree && categoryTree.name}
        headerRight={
          <ShoppingCartButton
            onClick={onClickShoppingCart}
            count={shoppingCartItemCount}
          />
        }
      />
      <CLContent
        className={styles.ionContent}
        ref={contentRef}
        onIonScroll={handleIonScroll}
      >
        <SingleCategory
          categoryId={categoryId}
          viewDidEnter={viewDidEnter}
          queryParams={queryParams}
          currentTab={currentTab}
          ionLifeCycleContext={ionLifeCycleContext}
          noProductsBackAction="BACK"
          contentRef={contentRef}
          isContentScrolledToTop={isContentScrolledToTop}
        />
      </CLContent>
    </RerenderLogger>
  );
};

const SingleCategoryPage: React.FC = () => {
  const match = useRouteMatch<{ categoryId: string }>();
  const categoryId = useMemo(() => parseInt(match.params.categoryId, 10), [
    match,
  ]);

  return (
    <ProfileSessionProvider profileSession={CategoryPageSession(categoryId)}>
      <SingleCategoryPageImpl />
    </ProfileSessionProvider>
  );
};

interface SingleCategoryProps {
  currentTab: RootTab;
  categoryId: number;
  viewDidEnter: boolean;
  queryParams: { [key in string]: string };
  ionLifeCycleContext: IonLifeCycleContextInterface;
  noProductsBackAction: "BACK" | "CLEAR_FILTER";
  contentRef: React.RefObject<HTMLIonContentElement>;
  isContentScrolledToTop: boolean;
}

const _SingleCategory: React.FC<SingleCategoryProps> = props => {
  const {
    currentTab,
    categoryId,
    queryParams,
    ionLifeCycleContext,
    noProductsBackAction,
    contentRef,
    isContentScrolledToTop,
    viewDidEnter,
  } = props;

  const categoryTreeMap = useCategoryTreeMap();
  const categoryTree = categoryTreeMap[categoryId];

  return (
    <>
      {categoryTree ? (
        <SingleCategoryPageDisplay
          currentTab={currentTab}
          viewDidEnter={viewDidEnter}
          categoryTree={categoryTree}
          queryParams={queryParams}
          ionLifeCycleContext={ionLifeCycleContext}
          noProductsBackAction={noProductsBackAction}
          contentRef={contentRef}
          isContentScrolledToTop={isContentScrolledToTop}
        />
      ) : (
        <SingleCategoryPageMissing categoryId={categoryId} />
      )}
    </>
  );
};

export const SingleCategory = withProviders(
  _SingleCategory,
  LoadingModalProvider
);

interface DisplayProps {
  categoryTree: CategoryTree;
  viewDidEnter: boolean;
  queryParams: { [key in string]: string };
  currentTab: RootTab;
  ionLifeCycleContext: IonLifeCycleContextInterface;
  noProductsBackAction: "BACK" | "CLEAR_FILTER";
  contentRef: React.RefObject<HTMLIonContentElement>;
  isContentScrolledToTop: boolean;
}

const SingleCategoryPageDisplay: React.FC<DisplayProps> = props => {
  const {
    categoryTree,
    queryParams,
    currentTab,
    ionLifeCycleContext,
    noProductsBackAction,
    contentRef,
    isContentScrolledToTop,
    viewDidEnter,
  } = props;

  const {
    hasDescription,
    categoryPageContent: categoryPageCMSContent,

    sortFields,
    productFilterInfo,
    clearAllFilter,
    clearFilter,
    clearRangeFilter,
    applyProductFilterInfo,
    clearPredefinedCPFilter,

    productOverviews,
    productLabelsByProductIdMap,
    bundleByProductIdMap,
    fetchProductOverviewsByCategoryIdRequestState,
    fetchNextProductOverviews,
    isProductListLoadingMore,

    isProductListLoading,
    isFullPageLoading,
    refresh: refreshViewModel,
  } = useViewModel(categoryTree, queryParams);

  const refreshViewModelRef = useKeepUpdatingRef(refreshViewModel);

  useEffect(() => {
    if (viewDidEnter) {
      refreshViewModelRef.current();
    }
  }, [viewDidEnter, refreshViewModelRef]);

  const { isOnline } = useContext(NetworkStatusContext);
  const isTabBarHidden = useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const { goBack } = useContext(OurNavContext);
  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );

  const tabBarHeight = useContext(TarBarHeightContext);

  const [
    ,
    fetchCategoryDescription,
    refreshCategoryDescription,
  ] = useFetchCategoryDescription(categoryTree.id);

  const resetProductFilterInfo = useCallback(() => {
    clearAllFilter();
  }, [clearAllFilter]);

  const fetchNext = useCallback(async () => {
    await fetchNextProductOverviews();
  }, [fetchNextProductOverviews]);

  const refresh = useCallback(async () => {
    const promises: Promise<unknown>[] = [
      refreshCategoryDescription().catch(() => {}),
      refreshViewModel().catch(() => {}),
    ];
    await Promise.all(promises);
  }, [refreshCategoryDescription, refreshViewModel]);

  usePresentLocalizedAlertForRequestStateError(
    fetchProductOverviewsByCategoryIdRequestState
  );

  useEffect(() => {
    if (!categoryTree.description) {
      fetchCategoryDescription();
    }
  }, [categoryTree, fetchCategoryDescription]);

  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(currentTab, productOverview.sku);
    },
    [currentTab]
  );

  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = React.useCallback(
    (product: ProductOverview) => {
      presentAddToCartModal(product.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );
  const { toggleProductFromWishlist } = useContext(WishlistContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      toggleProductFromWishlist(productOverview.sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh]
  );

  const handleLoadMoreProductOverviews = useCallback(async () => {
    try {
      await fetchNext();
    } catch {}
  }, [fetchNext]);

  const scrollToTop = useCallback(() => {
    const ionContent = contentRef.current;
    if (ionContent) {
      ionContent.scrollToPoint(undefined, 0);
    }
  }, [contentRef]);

  const onApplyFilters = useCallback(
    async (_productFilterInfo: ProductFilterInfo) => {
      scrollToTop();
      showLoadingModal();
      try {
        await applyProductFilterInfo(_productFilterInfo);
      } finally {
        hideLoadingModal();
      }
    },
    [showLoadingModal, hideLoadingModal, applyProductFilterInfo, scrollToTop]
  );

  const { present } = useContext(PresentationContext);
  const onClickFilter = useCallback(() => {
    const categoryIdFilter = makeGraphQLFilterValue(
      categoryTree.id,
      "FilterEqualTypeInput"
    );
    const sortRows = sortFields ? sortFields.sortFieldOptions : [];
    presentFilterModal(present, {
      initialProductFilterInfo: productFilterInfo,
      onApplyFilters,
      sortRows,
      rootCategoryId: categoryTree.id,
      initialGraphQLFilter: categoryIdFilter
        ? {
            category_id: categoryIdFilter,
          }
        : {},
    });
  }, [present, productFilterInfo, onApplyFilters, categoryTree, sortFields]);

  const onClickBackToTop = useHandleBackToTopClick(contentRef, 200);

  const retry = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);

  const descriptionContainerRef = useRef<HTMLDivElement>(null);
  const descriptionHTMLString = useFillSrcUrlScheme(
    useExtractScriptsAndStyles(
      descriptionContainerRef,
      categoryTree.description || ""
    )
  );

  useRegisterPublishDeepLinkOnHTMLContent(descriptionContainerRef, [
    descriptionHTMLString,
  ]);

  const descriptionReactElement = useMemo(() => parse(descriptionHTMLString), [
    descriptionHTMLString,
  ]);

  const onClickClearAllButton = useCallback(async () => {
    scrollToTop();
    showLoadingModal();
    try {
      await clearAllFilter();
    } finally {
      hideLoadingModal();
    }
  }, [showLoadingModal, hideLoadingModal, clearAllFilter, scrollToTop]);

  const onClickClearFilterButton = useCallback(
    async (appliedFilter: FilterAttribute) => {
      scrollToTop();
      showLoadingModal();
      try {
        await clearFilter(appliedFilter);
      } finally {
        hideLoadingModal();
      }
    },
    [showLoadingModal, hideLoadingModal, clearFilter, scrollToTop]
  );

  const onClickClearRangeFilterButton = useCallback(
    async (appliedRangeFilter: RangeFilterAttribute) => {
      scrollToTop();
      showLoadingModal();
      try {
        await clearRangeFilter(appliedRangeFilter);
      } finally {
        hideLoadingModal();
      }
    },
    [showLoadingModal, hideLoadingModal, clearRangeFilter, scrollToTop]
  );

  const onClickClearPredefinedCPFilterButton = useCallback(async () => {
    scrollToTop();
    showLoadingModal();
    try {
      await clearPredefinedCPFilter();
    } finally {
      hideLoadingModal();
    }
  }, [
    showLoadingModal,
    hideLoadingModal,
    clearPredefinedCPFilter,
    scrollToTop,
  ]);

  const handleNoProductsBackClick = useCallback(() => {
    switch (noProductsBackAction) {
      case "BACK": {
        goBack();
        break;
      }
      case "CLEAR_FILTER": {
        resetProductFilterInfo();
        break;
      }
      default:
        break;
    }
  }, [noProductsBackAction, goBack, resetProductFilterInfo]);

  const noProductsMessageID = useMemo<MessageID>(() => {
    switch (noProductsBackAction) {
      case "BACK": {
        return "single_category_page.no_products.message";
      }
      case "CLEAR_FILTER": {
        return "single_category_page.no_products.clear_filter_message";
      }
      default: {
        return "single_category_page.no_products.message";
      }
    }
  }, [noProductsBackAction]);

  const backTextMessageID = useMemo<MessageID>(() => {
    switch (noProductsBackAction) {
      case "BACK": {
        return "single_category_page.back";
      }
      case "CLEAR_FILTER": {
        return "single_category_page.clear_filter";
      }
      default: {
        return "single_category_page.back";
      }
    }
  }, [noProductsBackAction]);

  const scrollEl = useIonContentScrollEl(contentRef);
  const stickyElRef = useRef<HTMLDivElement>(null);
  useSticky(scrollEl, stickyElRef.current);

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, _actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          CategoryPageSession(categoryTree.id),
          "Content Mount",
          startTime
        );
      }
    },
    [categoryTree]
  );

  const profileSessionContext = useContext(ProfileSessionContext);
  useEffectOnce(() => {
    if (profileSessionContext) {
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Contents (CategoryPage) Shown"
      );
    }
  });

  return (
    <RerenderLogger id="Single Category Page Content" onRender={handleRender}>
      {hasFilters(productFilterInfo) && (
        <div className={styles.appliedFiltersListContainer} ref={stickyElRef}>
          <AppliedFiltersList
            productFilterInfo={productFilterInfo}
            onClickClearAllButton={onClickClearAllButton}
            onClickClearButton={onClickClearFilterButton}
            onClickClearRangeButton={onClickClearRangeFilterButton}
            onClickClearPredefinedCPFilterButton={
              onClickClearPredefinedCPFilterButton
            }
          />
        </div>
      )}
      <NoInternetConnectionView
        isOnline={isOnline}
        hasData={productOverviews.length > 0}
        onRetry={retry}
      >
        <ProductList
          ionContentRef={contentRef}
          productOverviews={productOverviews}
          isLoadMoreLoading={
            !isFullPageLoading &&
            (isProductListLoading || isProductListLoadingMore)
          }
          productLabelsByProductId={productLabelsByProductIdMap}
          bundleByProductId={bundleByProductIdMap}
          header={
            <>
              {!hasFilters(productFilterInfo) && hasDescription && (
                <div
                  className={styles.description}
                  ref={descriptionContainerRef}
                >
                  {descriptionReactElement}
                </div>
              )}
              {!hasFilters(productFilterInfo) &&
                hasPage(categoryTree) &&
                categoryPageCMSContent && (
                  <HTMLBasedCMSBlocks
                    waitingToFillHTML={categoryPageCMSContent.waitingToFillHTML}
                    resolvedMatchedCMSBlocks={
                      categoryPageCMSContent.resolvedMatchedCMSBlocks
                    }
                    currentTab={currentTab}
                    onAddToCart={openPurchaseProductModal}
                    hrefForProduct={hrefForProduct}
                    onClickLikeButton={onClickLikeButton}
                  />
                )}
            </>
          }
          footer={
            <>
              {isFullPageLoading ? (
                <FullContentLoadingView />
              ) : (
                !isProductListLoading &&
                productOverviews.length === 0 && (
                  <NoProducts
                    className={cn({
                      [styles.noProductsFullScreen]: !hasPage(categoryTree),
                      [styles.noProductsHasPage]: hasPage(categoryTree),
                    })}
                    messageID={noProductsMessageID}
                    onBackClick={handleNoProductsBackClick}
                    backTextMessageID={backTextMessageID}
                  />
                )
              )}
            </>
          }
          hrefForProduct={hrefForProduct}
          onAddToCart={openPurchaseProductModal}
          onClickLikeButton={onClickLikeButton}
          onRefresh={productOverviews.length === 0 ? undefined : handleRefresh}
          onEndReach={
            productOverviews.length === 0
              ? undefined
              : handleLoadMoreProductOverviews
          }
        />
        <TabBarSpacePlaceholder />
      </NoInternetConnectionView>
      <FloatingButtons
        offset={isTabBarHidden ? 0 : tabBarHeight}
        className={styles.floatingButtons}
      >
        {productOverviews.length > 0 && (
          <FilterButton onClick={onClickFilter} />
        )}
        {hasPage(categoryTree) || productOverviews.length > 0 ? (
          <BackToTopButton
            onClick={onClickBackToTop}
            className={cn(floatingButtonStyles.hidable, {
              [floatingButtonStyles.hidden]: isContentScrolledToTop,
            })}
          />
        ) : null}
      </FloatingButtons>
    </RerenderLogger>
  );
};

interface MissingProps {
  categoryId: number;
}

const SingleCategoryPageMissing: React.FC<MissingProps> = props => {
  const { categoryId } = props;

  const { translate } = useIntl();

  const requestState = useFetchCategory(
    categoryId,
    CategoryPageSession(categoryId)
  );

  return (
    <CLContent className={styles.ionContent}>
      {requestState.type === "initial" || requestState.type === "loading" ? (
        <FullContentLoadingView />
      ) : (
        <div className={styles.errorView}>
          <ErrorView errorMessage={translate("category.not_found")} />
        </div>
      )}
    </CLContent>
  );
};

export default SingleCategoryPage;
