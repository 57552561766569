import { useEffect, useMemo } from "react";
import { Customer, canCustomerSetClubPointOnCart } from "../models/Customer";
import {
  SelectedConfigurableOption,
  SelectedCustomizableOption,
} from "../models/cart";
import {
  ProductConfigurableOption,
  sortConfigurableOptions,
} from "../models/product";
import { useKeepUpdatingRef } from "../hook/utils";

// Reset club point on cart automatically when cart club point is larger than customer club point.
// If customer not present, no action is performend.
// If customer club point is smaller than min club point required by cart, no action is performed.
export function useResetShoppingCartClubPointToBeUsed<T>(
  resetMem: (clubPoint: number) => void,
  resetRemote: (clubPoint: number) => Promise<T>,
  customer: Customer | null,
  minClubPointUsed: number,
  clubPointToBeUsed: number
) {
  const shouldReset = useMemo(() => {
    if (!customer) {
      return false;
    }
    if (!canCustomerSetClubPointOnCart(customer, minClubPointUsed)) {
      return false;
    }
    return minClubPointUsed > clubPointToBeUsed;
  }, [customer, clubPointToBeUsed, minClubPointUsed]);

  const resetMemRef = useKeepUpdatingRef(resetMem);
  const resetRemoteRef = useKeepUpdatingRef(resetRemote);

  useEffect(() => {
    if (shouldReset) {
      resetMemRef.current(0);
      resetRemoteRef.current(0).catch(() => {});
    }
  }, [shouldReset, resetMemRef, resetRemoteRef]);
}

export function getOrderedItemOptions(
  itemConfigurableOptions: SelectedConfigurableOption[],
  productConfigurableOptions: ProductConfigurableOption[]
): SelectedConfigurableOption[] {
  if (!itemConfigurableOptions.length) {
    return itemConfigurableOptions;
  }
  const sortedConfigurableOptions = sortConfigurableOptions(
    productConfigurableOptions
  );
  const itemOptionValueIdOrderMap: { [key in number]: number } = {};
  for (let i = 0; i < sortedConfigurableOptions.length; i++) {
    const { values } = sortedConfigurableOptions[i];
    for (const { value } of values) {
      itemOptionValueIdOrderMap[value] = i;
    }
  }
  const taggedRes: {
    order: number;
    configurableOption: SelectedConfigurableOption;
  }[] = [];
  for (const configurableOption of itemConfigurableOptions) {
    const { valueId } = configurableOption;
    const order = itemOptionValueIdOrderMap[valueId];
    if (order == null) {
      continue;
    }
    taggedRes.push({ order, configurableOption });
  }
  const res = [...taggedRes]
    .sort((a, b) => a.order - b.order)
    .map(({ configurableOption }) => configurableOption);
  return res;
}

export function getOrderedCustomizableOptions(
  customizableOptions: SelectedCustomizableOption[]
) {
  return [...customizableOptions].sort((a, b) => a.sortOrder - b.sortOrder);
}
