import classnames from "classnames";
import React, { useCallback } from "react";
import { ShipmentStatus } from "../../models/Order";
import Item from "./Item";
import styles from "./ItemGroup.module.scss";
import { PartialOrderItem } from "./model";
import ShipmentStatusBar from "./ShipmentStatusBar";
import commonStyles from "./styles.module.scss";

interface Props {
  className?: string;
  currencyCode: string;
  header: React.ReactNode;
  items: PartialOrderItem[];
  onClickReorder: (item: PartialOrderItem) => void;
  onClickViewDelivery: (shipmentStatus: ShipmentStatus) => void;
  onClickReview: (item: PartialOrderItem) => void;
  onClickRedeem: (item: PartialOrderItem) => void;
  shipmentStatus: ShipmentStatus | null;
  onQRCodeLinkClicked: (url: string) => void;
  onClickViewInsurance: (item: PartialOrderItem) => void;
  onClickRedemptionLetterUrl: (url: string) => void;
}

const ItemGroup: React.FC<Props> = props => {
  const {
    className,
    currencyCode,
    header,
    items,
    onClickReorder,
    onClickViewDelivery,
    onClickReview,
    onClickRedeem,
    shipmentStatus,
    onQRCodeLinkClicked,
    onClickViewInsurance,
    onClickRedemptionLetterUrl,
  } = props;

  const onClickViewDeliveryItem = useCallback(() => {
    if (!shipmentStatus) {
      return;
    }
    onClickViewDelivery(shipmentStatus);
  }, [onClickViewDelivery, shipmentStatus]);

  return (
    <div
      className={classnames(
        className,
        styles.itemInfoContainer,
        commonStyles.orderDetailSectionContainer
      )}
    >
      <div className={styles.header}>{header}</div>
      {shipmentStatus ? (
        <div className={styles.shipmentStatusBarContainer}>
          <ShipmentStatusBar
            shipmentStatus={shipmentStatus}
            onClick={onClickViewDeliveryItem}
          />
        </div>
      ) : null}
      {items.map((item, i) => (
        <Item
          key={i}
          currencyCode={currencyCode}
          item={item}
          onClickReorder={onClickReorder}
          onClickReview={onClickReview}
          onClickRedeem={onClickRedeem}
          onQRCodeLinkClicked={onQRCodeLinkClicked}
          onClickViewInsurance={onClickViewInsurance}
          onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
        />
      ))}
    </div>
  );
};

export default ItemGroup;
