import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";

import { ParentNavContext, PresentationContext } from "../../our-navigation";
import { LocalizedText } from "../../i18n/Localization";
import { makeMockClubPointJourneyPoints } from "../../models/ClubPoint";
import {
  RootTab,
  getPathForAccountTab,
  getPathForMyOrders,
  getPathForHomePage,
  CheckoutOrderedLocationState,
  getPathForOrderDetail,
} from "../../navigation/routes";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { CheckoutSession } from "../../utils/PerformanceRecordStore/sessions";

import Lottie from "../Lottie.lazy";
import ModalHeader from "../ModalHeader";
import CLContent from "../CLContent";
import ClubPointJourney from "../ClubPointJourney";
import CheckoutSteps from "../CheckoutSteps";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import CheckoutOrderedPageComponent from "./CheckoutOrderedPageComponent";

import styles from "./styles.module.scss";

import Config from "../../Config";

function viewEnter() {
  pageView({ page: "Payment Confirm" });
}

const CheckoutOrderedPage: React.FC = () => {
  useEffect(
    () =>
      addPerformanceRecord(CheckoutSession(), "Checkout Thank you page shown"),
    []
  );
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const presentationContext = useContext(PresentationContext);
  const { isOnline } = useContext(NetworkStatusContext);

  const parentNavContext = useContext(ParentNavContext);
  const { location } = useHistory<CheckoutOrderedLocationState | undefined>();
  const orderRef = useRef(location.state ? location.state.order : null);

  const onRequestDismiss = useCallback(() => {
    presentationContext.dismiss();
  }, [presentationContext]);

  const handleMyTransactionClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      actionEvent("Payment Confirm Page", "Click", "My Transaction");
      presentationContext.dismiss(() => {
        parentNavContext.navigate(getPathForAccountTab());
        // Do a hack here to fix cannot navigate twice
        setTimeout(() => {
          parentNavContext.navigate(getPathForMyOrders(RootTab.account));
        }, 500);
      });
    },
    [presentationContext, parentNavContext]
  );

  const handleMyOrderClick = useCallback(() => {
    const order = orderRef.current;
    if (order) {
      presentationContext.dismiss(() => {
        parentNavContext.navigate(getPathForAccountTab());
        setTimeout(() => {
          parentNavContext.navigate(
            getPathForOrderDetail(RootTab.account, `${order.entityID}`)
          );
        }, 500);
      });
    }
  }, [presentationContext, parentNavContext]);

  const handleContinueShoppingClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      actionEvent("Payment Confirm Page", "Click", "Continue Shopping");
      presentationContext.dismiss(() => {
        parentNavContext.navigate(getPathForHomePage());
      });
    },
    [presentationContext, parentNavContext]
  );

  const clubPointJourneyPoints = useMemo(() => {
    return makeMockClubPointJourneyPoints();
  }, []);

  return (
    <>
      <ModalHeader
        messageID="checkout.ordered.title"
        disabled={false}
        onModalDismiss={onRequestDismiss}
        hasDismissButton={true}
      />
      <CLContent className={styles.ionContent}>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          <div className={styles.content}>
            <div className={styles.checkoutStepsWrapper}>
              <CheckoutSteps currentStep="ordered" />
            </div>
            <div className={styles.block}>
              {Config.ENABLE_PRE_ORDER_AND_EDD && orderRef.current ? (
                <CheckoutOrderedPageComponent
                  order={orderRef.current}
                  onMyOrderClick={handleMyOrderClick}
                />
              ) : (
                <>
                  <h2 className={styles.blockTitle}>
                    <LocalizedText messageID="checkout.ordered.title" />
                  </h2>
                  <h3 className={styles.blockSubTitle}>
                    <LocalizedText messageID="checkout.ordered.order_id" />
                  </h3>
                  <div className={styles.orderId}>
                    {orderRef.current ? orderRef.current.incrementID : ""}
                  </div>
                  <Lottie
                    className={styles.animation}
                    play={true}
                    loop={true}
                    autoplay={true}
                    animationData={require("../../resources/animation/empty-cart-or-wishlist.json")}
                  />
                  <h2 className={styles.messageHeader}>
                    <LocalizedText messageID="checkout.ordered.message.title" />
                  </h2>
                </>
              )}
              <div className={styles.blockButtons}>
                <button
                  onClick={handleMyTransactionClick}
                  className={styles.blockButton}
                >
                  <LocalizedText messageID="checkout.ordered.button.my_transaction" />
                </button>
                <button
                  onClick={handleContinueShoppingClick}
                  className={styles.blockButton}
                >
                  <LocalizedText messageID="checkout.ordered.button.continue_shopping" />
                </button>
              </div>
            </div>
            {Config.SHOW_CLUBPOINT_JOURNEY && (
              <div className={styles.clubPointJourneyBlock}>
                <div className={styles.clubPointJourneyBorder}>
                  <ClubPointJourney
                    clubPointJourneyPoints={clubPointJourneyPoints}
                    currentPoint={1800}
                  />
                </div>
              </div>
            )}
          </div>
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default CheckoutOrderedPage;
