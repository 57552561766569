import React, { useCallback, useContext, useState } from "react";
import cn from "classnames";
import Lottie from "../Lottie.lazy";

import { NavBar } from "../NavBar";
import { LocalizedText } from "../../i18n/Localization";
import styles from "./styles.module.scss";
import CLContent from "../CLContent";
import { PrimaryHollowButton } from "../Button";
import { getPathForHomePage } from "../../navigation/routes";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { useLoginWithTheClub } from "../../useCase/AuthUseCase";
import { useLinkSocialAccountRequest } from "../../repository/AuthRepository";
import { OurNavContext } from "../../our-navigation";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

function viewEnter() {
  pageView({ page: "Signup Link The Club" });
}

const SignupClubPointPage: React.FC = () => {
  const { replace } = useContext(OurNavContext);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const [isLoading, setIsLoading] = useState(false);

  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const loginWithTheClub = useLoginWithTheClub();
  const linkSocialAccount = useLinkSocialAccountRequest();
  const onLinkButtonClick = useCallback(async () => {
    actionEvent("Signup Link The Club", "Click", "Link To The Club Account");
    try {
      setIsLoading(true);
      const ssoResponse = await loginWithTheClub();
      const linkResult = await linkSocialAccount(
        ssoResponse.token,
        ssoResponse.provider
      );
      if (!linkResult.success) {
        presentLocalizedAlert({
          headerId: "page.edit_profile.connect_account_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      } else {
        replace(getPathForHomePage());
      }
    } catch {
      presentLocalizedAlert({
        headerId: "page.edit_profile.connect_account_error",
        buttons: [{ textMessageID: "try_again" }],
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    loginWithTheClub,
    replace,
    setIsLoading,
    linkSocialAccount,
    presentLocalizedAlert,
  ]);
  const onSkipClick = useCallback(() => {
    actionEvent("Signup Link The Club", "Click", "Skip For Now");
    replace(getPathForHomePage());
  }, [replace]);

  return (
    <>
      <NavBar
        headerTitle={
          <LocalizedText messageID="page.signup_clubpoint.clubpoint" />
        }
      />
      <CLContent>
        <div className={styles.content}>
          <Lottie
            className={styles.animation}
            play={true}
            loop={true}
            autoplay={true}
            animationData={require("../../resources/animation/earn-clubpoint.json")}
          />
          <div className={styles.title}>
            <LocalizedText messageID="page.signup_clubpoint.earn_clubpoints" />
          </div>
          <div>
            <PrimaryHollowButton
              disabled={isLoading}
              onClick={onLinkButtonClick}
              className={styles.linkButton}
            >
              <LocalizedText messageID="page.signup_clubpoint.link_button" />
            </PrimaryHollowButton>
            <div
              className={cn(styles.skipButton, isLoading && styles.disabled)}
              onClick={onSkipClick}
            >
              <LocalizedText messageID="skip_for_now" />
            </div>
          </div>
        </div>
      </CLContent>
    </>
  );
};

export default SignupClubPointPage;
