import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { format } from "date-fns";
import React, { useCallback, useMemo, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import { Campaign } from "../../models/Campaign";
import { getLowestFinalPrice } from "../../models/product";
import { ProductOverview } from "../../models/ProductOverview";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";
import {
  getPathForCampaignProductDetailsPage,
  getPathForProductDetailPage,
  RootTab,
  useCurrentTab,
} from "../../navigation/routes";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import {
  addToCartBingoListEvent,
  addToCartBingoListFailEvent,
} from "../../utils/GTM";
import parse from "../../utils/HTMLReactParser";
import { useViewDidEnterOnce } from "../../utils/View";
import { AddToCartFinalResult } from "../AddToCartModalProvider";
import CLContent from "../CLContent";
import { FullContentErrorView } from "../ErrorView";
import { FullContentLoadingView } from "../LoadingView";
import { NavBar, NavBarBackButton } from "../NavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import ProductBlock from "./ProductBlock";
import styles from "./styles.module.scss";
import { useViewModel } from "./viewModel";

const CampaignDetailsPage: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const currentTab = useCurrentTab();

  const campaignId = useMemo(() => parseInt(match.params.id, 10), [match]);

  const viewModel = useViewModel(campaignId);

  const ionLifeCycleContext = useCLIonLifeCycleContext();

  const handleViewEnter = useCallback(() => {
    viewModel.refresh().catch(() => {});
  }, [viewModel]);

  useViewDidEnterOnce(ionLifeCycleContext, handleViewEnter);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await viewModel.refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [viewModel]
  );

  const viewState = useMemo<
    SimpleViewState<{ campaign: Campaign }, Error>
  >(() => {
    if (viewModel.campaign) {
      return SimpleViewStateDisplay({ campaign: viewModel.campaign });
    }
    if (viewModel.fetchCampaignError) {
      return SimpleViewStateError(viewModel.fetchCampaignError);
    }
    if (viewModel.isFullPageLoading) {
      return SimpleViewStateLoading;
    }
    return SimpleViewStateInitial;
  }, [viewModel]);

  const handleBingoListAddToCartGTMEvent = useCallback(
    (result: AddToCartFinalResult) => {
      if (result.type === "success") {
        const { product, configuredProduct } = result;
        const { id, name, priceRange } = configuredProduct
          ? configuredProduct
          : product;
        addToCartBingoListEvent(
          `${id}`,
          name,
          getLowestFinalPrice({ priceRange })
        );
      }
      if (result.type === "failed") {
        if (result.reason === "PRODUCT_NOT_FOUND") {
          const { sku } = result;
          addToCartBingoListFailEvent("", `sku=${sku}`, "Product Not Found");
        } else if (result.reason === "OUT_OF_STOCK") {
          const { product, configuredProduct } = result;
          const p = configuredProduct ? configuredProduct : product;
          if (p) {
            const { id, name } = p;
            addToCartBingoListFailEvent(`${id}`, name, "Out of stock");
          }
        } else if (result.reason === "ERROR") {
          const { product, configuredProduct } = result;
          const p = configuredProduct ? configuredProduct : product;
          if (p) {
            const { id, name } = p;
            const errorMessage =
              result.error && result.error.message
                ? result.error.message
                : null;
            if (errorMessage) {
              addToCartBingoListFailEvent(`${id}`, name, errorMessage);
            }
          }
        }
      }
    },
    []
  );

  const presentAddToCartModal = usePresentAddToCartModal();

  const handleCampaignProductAddToCart = useCallback(
    (productOverview: ProductOverview) => {
      presentAddToCartModal(productOverview.sku, getFormInitialState(), {
        campaignId,
        onAddToCartFinalResult: result =>
          result ? handleBingoListAddToCartGTMEvent(result) : undefined,
      });
    },
    [presentAddToCartModal, campaignId, handleBingoListAddToCartGTMEvent]
  );
  const handleFixedProductAddToCart = useCallback(
    (productOverview: ProductOverview) => {
      presentAddToCartModal(productOverview.sku, getFormInitialState(), {
        onAddToCartFinalResult: result =>
          result ? handleBingoListAddToCartGTMEvent(result) : undefined,
      });
    },
    [presentAddToCartModal, handleBingoListAddToCartGTMEvent]
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={viewModel.campaign ? viewModel.campaign.title : ""}
      />
      <CLContent className={styles.content}>
        <IonRefresher onIonRefresh={handleRefresh} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>
        {viewState.type === "loading" || viewState.type === "initial" ? (
          <FullContentLoadingView />
        ) : viewState.type === "display" ? (
          <>
            <Banner campaign={viewState.data.campaign} />
            <ContentBody
              currentTab={currentTab}
              campaignId={campaignId}
              campaign={viewState.data.campaign}
              campaignProductOverviews={viewModel.campaignProductOverviews}
              fixedProductOverviews={viewModel.fixedProductOverviews}
              onCampaignProductAddToCart={handleCampaignProductAddToCart}
              onFixedProductAddToCart={handleFixedProductAddToCart}
            />
          </>
        ) : (
          <FullContentErrorView errorMessage={viewState.error.message} />
        )}
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

export default CampaignDetailsPage;

interface HeaderProps {
  campaign: Campaign;
}

const Banner: React.FC<HeaderProps> = props => {
  const { campaign } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const banner = useMemo(
    () => (campaign.banner ? parse(campaign.banner) : null),
    [campaign]
  );

  return <div ref={containerRef}>{banner}</div>;
};

interface ContentBodyProps {
  currentTab: RootTab;
  campaignId: number;
  campaign: Campaign;
  campaignProductOverviews: ProductOverview[];
  fixedProductOverviews: ProductOverview[];
  onCampaignProductAddToCart: (productOverview: ProductOverview) => void;
  onFixedProductAddToCart: (productOverview: ProductOverview) => void;
}

const ContentBody: React.FC<ContentBodyProps> = props => {
  const {
    currentTab,
    campaignId,
    campaign,
    campaignProductOverviews,
    fixedProductOverviews,
    onCampaignProductAddToCart,
    onFixedProductAddToCart,
  } = props;
  const { translate } = useIntl();

  const containerRef = useRef<HTMLDivElement>(null);

  const description = useMemo(
    () => (campaign.description ? parse(campaign.description) : null),
    [campaign]
  );
  useExtractScriptsAndStyles(containerRef, campaign.description || "");

  const tc = useMemo(() => (campaign.tc ? parse(campaign.tc) : null), [
    campaign,
  ]);
  useExtractScriptsAndStyles(containerRef, campaign.tc || "");

  const hrefForCampaignProduct = useCallback(
    ({ sku }) =>
      getPathForCampaignProductDetailsPage(currentTab, campaignId, sku),
    [campaignId, currentTab]
  );

  const hrefForFixedProduct = useCallback(
    ({ sku }) => getPathForProductDetailPage(currentTab, sku),
    [currentTab]
  );

  return (
    <div ref={containerRef} className={styles.contentBody}>
      {campaign.title ? (
        <h2 className={styles.title}>{campaign.title}</h2>
      ) : null}
      {description ? (
        <div className={styles.description}>{description}</div>
      ) : null}
      {campaign.end ? (
        <h3 className={styles.lastRedemptionDate}>
          <LocalizedText
            messageID="page.club_member_campaign_details.last_redemption_date.message"
            messageArgs={{
              dateStr: format(
                campaign.end,
                translate(
                  "page.club_member_campaign_details.last_redemption_date.date_format"
                )
              ),
            }}
          />
        </h3>
      ) : null}
      {campaignProductOverviews.length > 0 ? (
        <ProductList
          productOverviews={campaignProductOverviews}
          hrefForProduct={hrefForCampaignProduct}
          onAddToCart={onCampaignProductAddToCart}
        />
      ) : null}
      {fixedProductOverviews.length > 0 ? (
        <>
          <h3 className={styles.moreOptionsHeader}>
            <LocalizedText messageID="page.club_member_campaign_details.more_options.title" />
          </h3>
          <ProductList
            productOverviews={fixedProductOverviews}
            hrefForProduct={hrefForFixedProduct}
            onAddToCart={onFixedProductAddToCart}
          />
        </>
      ) : null}
      {tc ? (
        <>
          <h3 className={styles.tcHeader}>
            <LocalizedText messageID="page.club_member_campaign_details.tc.title" />
          </h3>
          <div className={styles.tc}>{tc}</div>
        </>
      ) : null}
    </div>
  );
};

interface ProductListProps {
  productOverviews: ProductOverview[];
  hrefForProduct: (productOverview: ProductOverview) => string;
  onAddToCart: (productOverview: ProductOverview) => any;
}

const ProductList: React.FC<ProductListProps> = props => {
  const { productOverviews, hrefForProduct, onAddToCart } = props;

  return (
    <ul className={styles.productList}>
      {productOverviews.map(productOverview => (
        <li key={productOverview.sku} className={styles.productListItem}>
          <ProductBlock
            product={productOverview}
            showOnlyInView={false}
            hrefForProduct={hrefForProduct}
            onAddToCart={onAddToCart}
          />
        </li>
      ))}
    </ul>
  );
};
