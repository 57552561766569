import React, { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports
import { IonHeader } from "@ionic/react";
import CLContent from "../CLContent";
import { NavBar, NavBarBackButton } from "../NavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import performanceRecords, {
  PerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";

const PerformanceRecordsPage: React.FC = () => {
  const grouppedRecords: PerformanceRecord[][] = useMemo(() => {
    const m: { [key in string]: PerformanceRecord[] } = {};
    for (const performanceRecord of performanceRecords) {
      const session = performanceRecord.session;
      if (!m[session]) {
        m[session] = [];
      }
      m[session].push(performanceRecord);
    }

    for (const key of Object.keys(m)) {
      m[key].sort((a, b) => a.startTime - b.startTime);
    }

    const firstItems: PerformanceRecord[] = [];
    for (const key of Object.keys(m)) {
      firstItems.push(m[key][0]);
    }

    firstItems.sort((a, b) => a.startTime - b.startTime);
    const order = firstItems.map(r => r.session.toString());
    return order.map(session => {
      return m[session];
    });
  }, []);

  return (
    <>
      <IonHeader>
        <NavBar
          headerTitle={<span>Performance Records</span>}
          headerLeft={<NavBarBackButton />}
        ></NavBar>
      </IonHeader>
      <CLContent>
        <table>
          {grouppedRecords.map((records, i) => {
            const session = records[0].session.toString();
            return <Item session={session} records={records} key={i} />;
          })}
        </table>
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

export default PerformanceRecordsPage;

interface ItemProps {
  session: string;
  records: PerformanceRecord[];
}

const Item: React.FC<ItemProps> = props => {
  const { session, records } = props;

  const rerenderCount = useMemo(() => {
    return records.filter(x => x.event === RERENDER_EVENT).length;
  }, [records]);

  return (
    <div>
      <h5>{session}</h5>
      <small>Rerender Count: {rerenderCount}</small>
      <ol>
        {records.map((record, j) => {
          const { event, startTime, endTime } = record;
          return (
            <small key={j}>
              <li>
                <div>
                  <b>{event}</b>
                </div>
                Start @{startTime.toFixed(0)}ms
                {endTime ? (
                  <>
                    ms (~
                    {(endTime - startTime).toFixed(3)}ms)
                  </>
                ) : null}
              </li>
            </small>
          );
        })}
      </ol>
    </div>
  );
};
