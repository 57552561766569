import moment from "moment-timezone";
import Config from "../Config";
import { Override } from "../utils/type";

export interface Campaign {
  id: string | null;
  banner: string | null;
  description: string | null;
  start: Date | null;
  end: Date | null;
  image: string | null;
  qty: string | null;
  status: string | null;
  tc: string | null;
  title: string | null;
  venderId: string | null;
}

export type RemoteCampaign = Override<
  Campaign,
  {
    start: string | null;
    end: string | null;
  }
>;

export function transformRemoteCampaignToCampaign(
  remote: RemoteCampaign
): Campaign {
  const { start: _start, end: _end } = remote;

  const parseDateStr = (maybeDateStr: string): Date | null => {
    // TODO: Pending format confirm
    const m = moment.tz(maybeDateStr, "YYYY-MM-DD HH:mm:ss", Config.TIMEZONE);
    if (m.isValid()) {
      return m.toDate();
    }
    return null;
  };

  return {
    ...remote,
    start: _start ? parseDateStr(_start) : null,
    end: _end ? parseDateStr(_end) : null,
  };
}
