import React from "react";
import { LocalizedText } from "../../i18n/Localization";
import { ProductData, getProductSKU } from "./type";

import styles from "./styles.module.scss";

interface Props {
  productData: ProductData;
}
const ProductSKULabel: React.FC<Props> = React.memo(props => {
  const { productData } = props;
  return (
    <div className={styles.skuText}>
      <LocalizedText
        messageID="page.product_detail.sku"
        messageArgs={{ PRODUCT_SKU: getProductSKU(productData) }}
      />
    </div>
  );
});

export default ProductSKULabel;
