import React from "react";
import { LocalizedText } from "../../i18n/Localization";

import styles from "./FreeDeliveryBadge.module.scss";

const FreeDeliveryBadge: React.FC = () => {
  return (
    <div className={styles.badge}>
      <LocalizedText messageID="product.badge.free_delivery" />
    </div>
  );
};

export default FreeDeliveryBadge;
