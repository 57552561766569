import { max } from "date-fns";
import {
  ProductDeliveryMethod,
  getPriceOfCustomizableOptionPriceType,
  ProductType,
  CLClubPoint,
  PriceRange,
  ProductConfigurableOption,
  ProductConfigurableOptionGraphQLAttributes,
  ProductConfigurableAttributeOption,
  ProductStockStatus,
  ProductImage,
  ProductImageGraphQLAttributes,
  ModelKeys,
  isMerchantProductDeliveryMethod,
  keyGraphQLAttributes,
  ProductEstimatedDeliveryDate,
  isEDDIgnoredByDeliveryMethod,
} from "./product";
import { MerchantPreview, MerchantPreviewGraphQLAttributes } from "./Merchant";
import { Money, MoneyGraphQLAttributes } from "./Price";
import {
  ApplyCondition,
  ProductSaleBundle,
  ProductSaleBundleItem,
} from "./ProductSaleBundle";
import { resolveConfiguredProductBySelectedConfiguration } from "./ProductConfigurationDependency";
import { IndexMap, NumericBoolean, Override } from "../utils/type";
import {
  getOrderedItemOptions,
  getOrderedCustomizableOptions,
} from "../utils/ShoppingCart";
import { filterNullOrUndefined } from "../utils/array";

export enum ShippingGroup {
  NotApplication = "not_applicable",
  Free = "free",
  Charged = "charged",
  Unknown = "unknown",
}

export type Product = ModelKeys & {
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  merchant: [MerchantPreview | null];
  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodLabel: string | null;
  stockStatus: ProductStockStatus;

  configurableOptions?: ProductConfigurableOption[] | null;
  variants?: ProductVariant[] | null;

  isFreeShipping?: boolean;
} & ProductEstimatedDeliveryDate;

export interface ProductVariant {
  product: ConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}

export type ConfiguredProduct = ModelKeys & {
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  merchant: [MerchantPreview | null];
  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodLabel: string | null;
  stockStatus: ProductStockStatus;

  isFreeShipping?: boolean;
} & ProductEstimatedDeliveryDate;

export const ProductVariantGrapQLAttributes = `
${keyGraphQLAttributes}
name
type: type_id
thumbnail {
  ${ProductImageGraphQLAttributes}
}
clubPoint: clubpoints
minClubPoint: min_clubpoints
extraClubpoints: extra_clubpoints
clClubPoint: cl_clubpoints
priceRange: price_range {
  minimumPrice: minimum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
  maximumPrice: maximum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
}
merchant {
  ${MerchantPreviewGraphQLAttributes}
}
deliveryMethod: delivery_method
stockStatus: stock_status
`;

export const ProductGraphQLAttributes = `
${keyGraphQLAttributes}
name
type: type_id
thumbnail {
  ${ProductImageGraphQLAttributes}
}
clubPoint: clubpoints
minClubPoint: min_clubpoints
extraClubpoints: extra_clubpoints
clClubPoint: cl_clubpoints
priceRange: price_range {
  minimumPrice: minimum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
  maximumPrice: maximum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
}
merchant {
  ${MerchantPreviewGraphQLAttributes}
}
deliveryMethod: delivery_method
stockStatus: stock_status

... on ConfigurableProduct {
  configurableOptions: configurable_options {
    ${ProductConfigurableOptionGraphQLAttributes}
  }
  variants {
    product{
      ${ProductVariantGrapQLAttributes}
    }
    attributes {
      label
      value: value_index
      code
    }
  }
}
`;

export interface CartDiscountBreakdown {
  ruleName: string;
  ruleAmount: string;
}

export const CartDiscountBreakdownGraphQLAttributes = `
  ruleAmount: rule_amount
  ruleName: rule_name
`;

export interface CartDiscount {
  label: string;
  amount: Money;
}

export const CartDiscountsGraphQLAttributes = `
  amount {
    ${MoneyGraphQLAttributes}
  }
  label
`;

export type CartDiscountTypes =
  | {
      type: "discounts";
      discounts: CartDiscount[];
    }
  | {
      type: "discountBreakdown";
      discountBreakdown: CartDiscountBreakdown[];
    };

export interface CartPrices {
  // TODO (Steven-Chan): includes applied_taxes
  grandTotal: Money;
  subtotalExcludingTax: Money;
  subtotalIncludingTax: Money;
  subtotalWithDiscountExcludingTax: Money;
  discountAmount: Money;
  shippingAmount: Money;
  discountBreakdown?: CartDiscountBreakdown[];
  discounts?: CartDiscount[];
  initialSubscriptionFee?: Money;
}

export const CartPricesGraphQLAttributes = `
  grandTotal: grand_total { ${MoneyGraphQLAttributes} }
  subtotalExcludingTax: subtotal_excluding_tax { ${MoneyGraphQLAttributes} }
  subtotalIncludingTax: subtotal_including_tax { ${MoneyGraphQLAttributes} }
  subtotalWithDiscountExcludingTax: subtotal_with_discount_excluding_tax { ${MoneyGraphQLAttributes} }
  discountBreakdown: discount_breakdown { ${CartDiscountBreakdownGraphQLAttributes} }
`;

export interface InstalmentMsg {
  isShowMsg: boolean | null;
  messageTitle: string | null;
  messageDesc: string | null;
}

export const CartInstallmentGraphQLAttributes = `
id
instalmentMsg: installment_msg {
  isShowMsg: is_show_msg
  messageTitle: message_title
  messageDesc: message_desc
}
`;

export interface Cart {
  id: string;
  items: CartItem[];
  prices: CartPrices;
  clubPointToBeEarned: number;
  clubPointToBeUsed: number;
  clubPointRequired?: number | null;
  instalmentMsg?: InstalmentMsg | null;
}

type CartItemWithDeliveryMethod = Override<
  Pick<CartItem, "product">,
  {
    product: Pick<Product, "deliveryMethod">;
  }
>;

export function getCartItemsByDeliveryMethod<
  T extends { product: P } & CartItemWithDeliveryMethod,
  P extends ModelKeys = ModelKeys
>(
  maybeBundledCartItems: MaybeBundledCartItem<T, P>[]
): [
  { [blockId in ProductDeliveryMethod]: MaybeBundledCartItem<T, P>[] },
  MaybeBundledCartItem<T, P>[]
] {
  const resWithDeliveryMethod: {
    [blockId in ProductDeliveryMethod]: MaybeBundledCartItem<T, P>[];
  } = {
    warehouse: [],
    evoucher: [],
    consolidate: [],
    "merchant*": [],
  };
  const resWithoutDeliveryMethod: MaybeBundledCartItem<T, P>[] = [];
  for (const item of maybeBundledCartItems) {
    const {
      cartItem: { product },
    } = item;
    const { deliveryMethod } = product;
    if (deliveryMethod == null) {
      resWithoutDeliveryMethod.push(item);
    } else {
      const d: ProductDeliveryMethod = isMerchantProductDeliveryMethod(
        deliveryMethod
      )
        ? "merchant*"
        : deliveryMethod;
      if (resWithDeliveryMethod[d] == null) {
        resWithDeliveryMethod[d] = [];
      }
      resWithDeliveryMethod[d].push(item);
    }
  }
  return [resWithDeliveryMethod, resWithoutDeliveryMethod];
}

type CartItemWithDeliveryMethodLabel = Override<
  Pick<CartItem, "product">,
  {
    product: Pick<Product, "deliveryMethodLabel">;
  }
>;

export function getCartItemsByDeliveryMethodLabel<
  T extends { product: P } & CartItemWithDeliveryMethodLabel,
  P extends ModelKeys = ModelKeys
>(
  cartItems: MaybeBundledCartItem<T, P>[]
): [
  { [key in string]: MaybeBundledCartItem<T, P>[] },
  MaybeBundledCartItem<T, P>[]
] {
  const resWithDeliveryMethodLabel: {
    [key in string]: MaybeBundledCartItem<T, P>[];
  } = {};
  const resWithoutDeliveryMethodLabel: MaybeBundledCartItem<T, P>[] = [];

  for (const item of cartItems) {
    const { deliveryMethodLabel } = item.cartItem.product;
    if (deliveryMethodLabel == null) {
      resWithoutDeliveryMethodLabel.push(item);
    } else {
      if (resWithDeliveryMethodLabel[deliveryMethodLabel] == null) {
        resWithDeliveryMethodLabel[deliveryMethodLabel] = [];
      }
      resWithDeliveryMethodLabel[deliveryMethodLabel].push(item);
    }
  }

  return [resWithDeliveryMethodLabel, resWithoutDeliveryMethodLabel];
}

interface ShippingGroups<C> {
  notChargedDelivery: C[];
  notCharged: C[];
  clDelivery: C[];
  merchantDelivery: C[];
  unknown: C[];
}

function getCartItemsByShippingGroupForFreeShipping<
  _Product extends {
    deliveryMethod: ProductDeliveryMethod | null;
  } & ModelKeys,
  _CartItem extends {
    product: _Product;
    shippingGroup?: ShippingGroup | null;
  },
  C extends MaybeBundledCartItem<_CartItem>
>(cartItems: C[]): ShippingGroups<C> {
  const notChargedDelivery: C[] = [];
  const notCharged: C[] = [];
  const clDelivery: C[] = [];
  const merchantDelivery: C[] = [];
  const unknown: C[] = [];

  for (const cartItem of cartItems) {
    const {
      cartItem: {
        shippingGroup,
        product: { deliveryMethod },
      },
    } = cartItem;
    if (
      shippingGroup === ShippingGroup.NotApplication &&
      deliveryMethod === "evoucher"
    ) {
      notCharged.push(cartItem);
    } else if (
      deliveryMethod &&
      isMerchantProductDeliveryMethod(deliveryMethod)
    ) {
      merchantDelivery.push(cartItem);
    } else if (
      shippingGroup === ShippingGroup.Charged ||
      shippingGroup === ShippingGroup.Free ||
      deliveryMethod === "consolidate" ||
      deliveryMethod === "warehouse"
    ) {
      clDelivery.push(cartItem);
    } else {
      unknown.push(cartItem);
    }
  }
  return {
    notChargedDelivery,
    notCharged,
    clDelivery,
    merchantDelivery,
    unknown,
  };
}

function getCartItemsByShippingGroupForChargedShipping<
  _Product extends {
    deliveryMethod: ProductDeliveryMethod | null;
  } & ModelKeys,
  _CartItem extends {
    product: _Product;
    shippingGroup?: ShippingGroup | null;
  },
  C extends MaybeBundledCartItem<_CartItem>
>(cartItems: C[]): ShippingGroups<C> {
  const notChargedDelivery: C[] = [];
  const notCharged: C[] = [];
  const clDelivery: C[] = [];
  const merchantDelivery: C[] = [];
  const unknown: C[] = [];

  for (const cartItem of cartItems) {
    const {
      cartItem: {
        shippingGroup,
        product: { deliveryMethod },
      },
    } = cartItem;
    if (
      shippingGroup === ShippingGroup.NotApplication &&
      deliveryMethod === "evoucher"
    ) {
      notCharged.push(cartItem);
    } else if (
      shippingGroup === ShippingGroup.NotApplication &&
      deliveryMethod === "warehouse"
    ) {
      notChargedDelivery.push(cartItem);
    } else if (
      deliveryMethod &&
      isMerchantProductDeliveryMethod(deliveryMethod)
    ) {
      merchantDelivery.push(cartItem);
    } else if (
      shippingGroup === ShippingGroup.Charged ||
      shippingGroup === ShippingGroup.Free ||
      deliveryMethod === "consolidate"
    ) {
      clDelivery.push(cartItem);
    } else {
      unknown.push(cartItem);
    }
  }
  return {
    notChargedDelivery,
    notCharged,
    clDelivery,
    merchantDelivery,
    unknown,
  };
}

export function getCartItemForDeliveryMethodLabelDisplay<
  _Product extends {
    deliveryMethod: ProductDeliveryMethod | null;
    deliveryMethodBlockIdentifier: string | null;
  } & ModelKeys,
  _CartItem extends {
    product: _Product;
    shippingGroup?: ShippingGroup | null;
  },
  C extends MaybeBundledCartItem<_CartItem>
>(cartItems: C[]): C | undefined {
  const priorities: ProductDeliveryMethod[] = ["consolidate", "warehouse"];
  for (const p of priorities) {
    const c = cartItems.find(
      ({
        cartItem: {
          product: { deliveryMethod },
        },
      }) => deliveryMethod === p
    );
    if (c) {
      return c;
    }
  }
  return cartItems[0];
}

export function getCartItemsByShippingGroup<
  _Product extends {
    deliveryMethod: ProductDeliveryMethod | null;
  } & ModelKeys,
  _CartItem extends {
    product: _Product;
    shippingGroup?: ShippingGroup | null;
  },
  C extends MaybeBundledCartItem<_CartItem>
>(items: C[], shouldBeFreeShipping: boolean): ShippingGroups<C> {
  if (shouldBeFreeShipping) {
    return getCartItemsByShippingGroupForFreeShipping(items);
  }
  return getCartItemsByShippingGroupForChargedShipping(items);
}

type CartItemWithMerchant = Override<
  Pick<CartItem, "product">,
  {
    product: Override<
      Pick<CartItem["product"], "merchant">,
      {
        merchant: ({ name: string } | null)[];
      }
    >;
  }
>;

export function getCartItemByMerchant<
  T extends { product: P } & CartItemWithMerchant,
  P extends ModelKeys = ModelKeys
>(
  cartItems: MaybeBundledCartItem<T, P>[]
): [
  { [key in string]: MaybeBundledCartItem<T, P>[] },
  MaybeBundledCartItem<T, P>[]
] {
  const merchantItemsMap: {
    [key in string]: MaybeBundledCartItem<T, P>[];
  } = {};
  const itemsWithoutMerchant: MaybeBundledCartItem<T, P>[] = [];
  for (const item of cartItems) {
    if (item.cartItem.product.merchant.length > 0) {
      const [merchant] = item.cartItem.product.merchant;
      if (merchant) {
        const { name } = merchant;
        if (!merchantItemsMap[name]) {
          merchantItemsMap[name] = [];
        }
        merchantItemsMap[name].push(item);
      } else {
        itemsWithoutMerchant.push(item);
      }
    } else {
      itemsWithoutMerchant.push(item);
    }
  }
  return [merchantItemsMap, itemsWithoutMerchant];
}

export const SelectedCustomizableOptionGraphQL = `
  isRequired: is_required
  label
  sortOrder: sort_order
  values {
    label
    price {
      type
      units
      value
    }
    value
  }
`;

export interface CartItemSelectedOptionValuePrice {
  type: "FIXED" | "PERCENT" | "DYNAMIC";
  units: string;
  value: number;
}

export interface SelectedCustomizableOptionValue {
  // FIXME
  // Server returns same id for option value which leads to we cannot
  // dispaly multiple value
  // Hide id for now to hotfix the issue
  // id: number;
  label: string;
  price: CartItemSelectedOptionValuePrice;
  value: string;
}

export interface SelectedCustomizableOption {
  id: number;
  isRequired: boolean;
  label: string;
  sortOrder: number;
  values: SelectedCustomizableOptionValue[];
}

function isSelectedCustomizableOption(
  maybeSelectedCustomizableOption: SelectedCustomizableOption | null
): maybeSelectedCustomizableOption is SelectedCustomizableOption {
  return maybeSelectedCustomizableOption != null;
}

export const SelectedConfigurableOptionGraphQL = `
  optionLabel: option_label
  valueId: value_id
  valueLabel: value_label
`;

export interface SelectedConfigurableOption {
  id: number;
  optionLabel: string;
  valueId: number;
  valueLabel: string;
}

export const CartItemPriceGraphQLAttributes = `
prices {
  discounts { ${CartDiscountsGraphQLAttributes} }
  price { ${MoneyGraphQLAttributes} }
  rowTotal: row_total { ${MoneyGraphQLAttributes} }
  totalItemDiscount: total_item_discount { ${MoneyGraphQLAttributes} }
}
`;

export interface CartItemPrices {
  discounts: CartDiscount[] | null;
  price: Money;
  rowTotal: Money;
  totalItemDiscount: Money;
}

// TODO (Steven-Chan): update this
export const CartGraphQLAttributes = `
  id
  email
  clubPointRequired: clubpoints_required
  items {
    id
    product {
      ${ProductGraphQLAttributes}
    }
    quantity
    ...on VirtualCartItem {
      isFreeGift: is_free_gift
      customizableOptionsForVirtualCartItem: customizable_options {
        ${SelectedCustomizableOptionGraphQL}
      }
    }
    ...on SimpleCartItem {
      isFreeGift: is_free_gift
      customizableOptionsForSimpleCartItem: customizable_options {
        ${SelectedCustomizableOptionGraphQL}
      }
    }
    ...on ConfigurableCartItem {
      configurableOptions: configurable_options {
        ${SelectedConfigurableOptionGraphQL}
      }
      customizableOptionsForConfigurableCartItem: customizable_options {
        ${SelectedCustomizableOptionGraphQL}
      }
    }
  }
  prices {
    ${CartPricesGraphQLAttributes}
  }
`;

export interface CartItem {
  id: string;
  product: Product;
  isFreeGift?: boolean;
  quantity: number;
  configurableOptions?: SelectedConfigurableOption[];
  customizableOptionsForVirtualCartItem?: (SelectedCustomizableOption | null)[];
  customizableOptionsForSimpleCartItem?: (SelectedCustomizableOption | null)[];
  customizableOptionsForConfigurableCartItem?: (SelectedCustomizableOption | null)[];
  shippingGroup?: ShippingGroup | null;
  prices?: CartItemPrices | null;
}

export interface RecurringOption {
  label: string | null;
  value: string | null;
}

export interface CartItemInput {
  sku: string;
  quantity: number;
  subscribe: SubscribeEnum;
  disclaimer?: DisclaimerInput;
  clubprotection?: ClubProtectionInput;
  age_declaration?: AgeDeclarationInput;
  campaign_id?: number;
}

export interface DisclaimerInput {
  agreement?: 0 | 1;
  service?: ReeService;
}

export interface CustomizableOptionInput {
  id: number;
  valueString: string;
}

export interface SimpleProductCartItemInput {
  data: CartItemInput;
  customizableOptions?: CustomizableOptionInput[];
}

export function serializeSimpleProductCartItemInput(
  input: SimpleProductCartItemInput
) {
  return {
    data: input.data,
    customizable_options: input.customizableOptions
      ? input.customizableOptions.map(o => ({
          id: o.id,
          value_string: o.valueString,
        }))
      : undefined,
  };
}

export interface ConfigurableProductCartItemInput
  extends SimpleProductCartItemInput {
  parentSku: string;
  variantSku: string;
}

export function serializeConfigurableProductCartItemInput(
  input: ConfigurableProductCartItemInput
) {
  return {
    parent_sku: input.parentSku,
    variant_sku: input.variantSku,
    data: input.data,
    customizable_options: input.customizableOptions
      ? input.customizableOptions.map(o => ({
          id: o.id,
          value_string: o.valueString,
        }))
      : undefined,
  };
}

export function isConfigurableProductCartItemInput(
  input: SimpleProductCartItemInput
): input is ConfigurableProductCartItemInput {
  return typeof (input as any).parentSku === "string";
}

export type ReeService = "FREE_RECYCLE" | "NO_RECYCLE" | "DECIDE_LATER";

export enum ReeServiceInt {
  FreeRecycle = 0,
  NoRecycle = 1,
  DecideLater = 2,
}

export function getReeService(service: ReeServiceInt): ReeService {
  switch (service) {
    case ReeServiceInt.FreeRecycle:
      return "FREE_RECYCLE";
    case ReeServiceInt.NoRecycle:
      return "NO_RECYCLE";
    case ReeServiceInt.DecideLater:
      return "DECIDE_LATER";
    default:
      throw new Error("Unknown REE Service");
  }
}

export enum SubscribeEnum {
  subscribe = "subscribe",
  notSubscribe = "not_subscribe",
}

export enum ClubProtectionEnum {
  Yes = "YES",
  No = "NO",
}

export interface ClubProtectionInput {
  agreement_1: NumericBoolean;
  agreement_2: NumericBoolean;
  service: ClubProtectionEnum;
}

export interface AgeDeclarationInput {
  agreement: NumericBoolean;
}

export function getCartItemSelectedOptions(
  item: Override<
    Pick<
      CartItem,
      | "product"
      | "configurableOptions"
      | "customizableOptionsForVirtualCartItem"
      | "customizableOptionsForSimpleCartItem"
      | "customizableOptionsForConfigurableCartItem"
    >,
    {
      product: Pick<CartItem["product"], "configurableOptions">;
    }
  >
): { title: string; displayValue: string }[] {
  const {
    product,
    configurableOptions: _configurableOptions,
    customizableOptionsForVirtualCartItem: _customizableOptionsForVirtualCartItem,
    customizableOptionsForSimpleCartItem: _customizableOptionsForSimpleCartItem,
    customizableOptionsForConfigurableCartItem: _customizableOptionsForConfigurableCartItem,
  } = item;
  const configurableOptions =
    _configurableOptions && product.configurableOptions
      ? getOrderedItemOptions(_configurableOptions, product.configurableOptions)
      : [];
  const customizableOptionsForVirtualCartItem = _customizableOptionsForVirtualCartItem
    ? getOrderedCustomizableOptions(
        _customizableOptionsForVirtualCartItem.filter(
          isSelectedCustomizableOption
        )
      )
    : [];
  const customizableOptionsForSimpleCartItem = _customizableOptionsForSimpleCartItem
    ? getOrderedCustomizableOptions(
        _customizableOptionsForSimpleCartItem.filter(
          isSelectedCustomizableOption
        )
      )
    : [];
  const customizableOptionsForConfigurableCartItem = _customizableOptionsForConfigurableCartItem
    ? getOrderedCustomizableOptions(
        _customizableOptionsForConfigurableCartItem.filter(
          isSelectedCustomizableOption
        )
      )
    : [];
  const options: { title: string; displayValue: string }[] = [];
  options.push(
    ...configurableOptions.map(o => ({
      title: o.optionLabel,
      displayValue: o.valueLabel,
    }))
  );
  const selectedCustomizableOptions: SelectedCustomizableOption[] = [];
  selectedCustomizableOptions.push(...customizableOptionsForVirtualCartItem);
  selectedCustomizableOptions.push(...customizableOptionsForSimpleCartItem);
  selectedCustomizableOptions.push(
    ...customizableOptionsForConfigurableCartItem
  );
  options.push(
    ...selectedCustomizableOptions.map(o => ({
      title: o.label,
      displayValue: o.values.map(v => v.label || v.value).join(", "),
    }))
  );
  return options;
}

export function getCartItemSelectedOptionsAdditionalCost(
  originalPrice: number,
  cartItem: Pick<
    CartItem,
    | "customizableOptionsForVirtualCartItem"
    | "customizableOptionsForSimpleCartItem"
    | "customizableOptionsForConfigurableCartItem"
  >
): number {
  const selectedCustomizableOptions: SelectedCustomizableOption[] = [];
  if (cartItem.customizableOptionsForVirtualCartItem != null) {
    selectedCustomizableOptions.push(
      ...cartItem.customizableOptionsForVirtualCartItem.filter(
        isSelectedCustomizableOption
      )
    );
  }
  if (cartItem.customizableOptionsForSimpleCartItem != null) {
    selectedCustomizableOptions.push(
      ...cartItem.customizableOptionsForSimpleCartItem.filter(
        isSelectedCustomizableOption
      )
    );
  }
  if (cartItem.customizableOptionsForConfigurableCartItem != null) {
    selectedCustomizableOptions.push(
      ...cartItem.customizableOptionsForConfigurableCartItem.filter(
        isSelectedCustomizableOption
      )
    );
  }
  let additionalCost = 0;
  for (const option of selectedCustomizableOptions) {
    for (const _value of option.values) {
      const { type, value } = _value.price;
      additionalCost += getPriceOfCustomizableOptionPriceType(
        originalPrice,
        type,
        value
      );
    }
  }
  return additionalCost;
}

export function getDiscountBreakdown(prices: CartPrices): CartDiscountTypes {
  if (prices.discountBreakdown != null) {
    return {
      type: "discountBreakdown",
      discountBreakdown: prices.discountBreakdown,
    };
  } else if (prices.discounts != null) {
    return {
      type: "discounts",
      discounts: prices.discounts,
    };
  }
  return {
    type: "discounts",
    discounts: [],
  };
}

export function getMinClubPointUsed(
  cart: Pick<Cart, "clubPointRequired">
): number {
  return cart.clubPointRequired || 0;
}

export function extractProductIdsFromCart<
  C extends { items: { product: { id: number } }[] }
>(cart: C) {
  const { items } = cart;
  const ids: number[] = [];
  for (const item of items) {
    ids.push(item.product.id);
  }
  return ids;
}

export function classifyBundleOfCartItems<
  I extends { product: P; quantity: number },
  P extends ModelKeys = ModelKeys
>(
  cartItems: I[],
  bundles: ProductSaleBundle<P>[]
): [
  {
    bundle: ProductSaleBundle<P>;
    bundleItem: ProductSaleBundleItem<P>;
    items: I[];
  }[],
  I[]
] {
  const skus = cartItems.map(i => i.product.sku);
  const quantities = cartItems.map(i => i.quantity);

  const classifiedCartItems: {
    bundle: ProductSaleBundle<P>;
    bundleItem: ProductSaleBundleItem<P>;
    items: I[];
  }[] = [];

  for (const bundle of bundles) {
    if (bundle.mainProduct == null || bundle.items == null) {
      continue;
    }

    const { sku: mainProductSku } = bundle.mainProduct;
    const i = skus.findIndex(
      (sku, index) =>
        sku === mainProductSku && quantities[index] && quantities[index] >= 1
    );

    // If main product is not found in cart items, go to the next bundle
    if (i === -1) {
      continue;
    }

    for (const bundleItem of bundle.items) {
      // Max loop for bundle product count to consume from all products to only
      // 2 product
      for (
        let loopCount = 0;
        loopCount < bundleItem.items.length;
        loopCount++
      ) {
        const bundledQuantity = getBundledQuantity(
          bundle.mainProduct,
          bundleItem,
          getSkuQuantityMap(skus, quantities)
        );
        if (bundledQuantity === 0) {
          break;
        }

        const items: I[] = [];
        let remainingBundledQuantity = bundledQuantity;
        for (let _i = 0; _i < skus.length; _i++) {
          if (skus[_i] === mainProductSku) {
            const quantity = quantities[_i];
            const cartItem = cartItems[_i];
            if (quantity && cartItem) {
              const reduction =
                quantity > remainingBundledQuantity
                  ? remainingBundledQuantity
                  : quantity;
              remainingBundledQuantity = remainingBundledQuantity - reduction;
              quantities[_i] = quantity - reduction;
              items.push({ ...cartItem, quantity: reduction });
            }
            if (remainingBundledQuantity <= 0) {
              break;
            }
          }
        }

        for (const item of bundleItem.items) {
          const {
            product: { sku: itemSku },
            qty,
          } = item;
          const itemIndex = skus.findIndex(
            (sku, index) =>
              sku === itemSku && quantities[index] && quantities[index] >= qty
          );
          const quantity = quantities[itemIndex];
          const cartItem = cartItems[itemIndex];
          if (quantity && cartItem) {
            quantities[itemIndex] = quantity - qty * bundledQuantity;
            items.push({ ...cartItem, quantity: qty * bundledQuantity });
          }
        }

        classifiedCartItems.push({ bundle, bundleItem, items });
      }
    }
  }

  const unclassifiedCartItems: I[] = cartItems
    .map((cartItem, i) => ({
      ...cartItem,
      quantity: quantities[i] != null ? quantities[i] : cartItem.quantity,
    }))
    .filter(cartItem => cartItem.quantity > 0);

  return [classifiedCartItems, unclassifiedCartItems];
}

function getBundledQuantity<P extends ModelKeys = ModelKeys>(
  mainProduct: P,
  bundleItem: ProductSaleBundleItem<P>,
  skuQuantityMap: IndexMap<string, number>
): number {
  let n = 0;

  const selectedMainProductQuantity = skuQuantityMap[mainProduct.sku];

  if (selectedMainProductQuantity) {
    n = selectedMainProductQuantity;
  }

  if (n === 0) {
    return 0;
  }

  const { applyCondition, items } = bundleItem;

  let itemBundledQuantity: number | null = null;

  for (const item of items) {
    const selectedItemQuantity = skuQuantityMap[item.product.sku];
    if (!selectedItemQuantity || selectedItemQuantity < item.qty) {
      if (applyCondition === ApplyCondition.AllBundleProductsAreChosen) {
        return 0;
      }
      continue;
    }

    const q = Math.floor(selectedItemQuantity / item.qty);

    itemBundledQuantity =
      itemBundledQuantity == null ? q : Math.min(itemBundledQuantity, q);
  }

  return itemBundledQuantity == null ? 0 : Math.min(n, itemBundledQuantity);
}

function getSkuQuantityMap(
  skus: string[],
  quantities: number[]
): IndexMap<string, number> {
  const res: IndexMap<string, number> = {};
  for (let i = 0; i < skus.length; i++) {
    const sku = skus[i];
    const quantity = quantities[i];
    if (sku != null && quantity != null) {
      res[sku] = (res[sku] || 0) + quantity;
    }
  }
  return res;
}

interface NonBundledCartItem<
  I extends { product: P },
  P extends ModelKeys = ModelKeys
> {
  type: "nonBundled";
  cartItem: I;
}

export function NonBundledCartItem<
  I extends { product: P },
  P extends ModelKeys = ModelKeys
>(cartItem: I): NonBundledCartItem<I, P> {
  return {
    type: "nonBundled",
    cartItem,
  };
}

interface BundledCartItem<
  I extends { product: P },
  P extends ModelKeys = ModelKeys
> {
  type: "bundled";
  bundle: ProductSaleBundle<P>;
  bundleItem: ProductSaleBundleItem<P>;
  cartItem: I;
}

export function BundledCartItem<
  I extends { product: P },
  P extends ModelKeys = ModelKeys
>(
  bundle: ProductSaleBundle<P>,
  bundleItem: ProductSaleBundleItem<P>,
  cartItem: I
): BundledCartItem<I, P> {
  return {
    type: "bundled",
    bundle,
    bundleItem,
    cartItem,
  };
}

export type MaybeBundledCartItem<
  I extends { product: P },
  P extends ModelKeys = ModelKeys
> = NonBundledCartItem<I, P> | BundledCartItem<I, P>;

export function resolveConfiguredCartItemProduct<
  ProductType extends {
    variants?: ProductVariantType[] | null;
  },
  ConfiguredProductType,
  ProductVariantType extends {
    attributes: ProductConfigurableAttributeOption[];
    product: ConfiguredProductType;
  },
  CartItemType extends {
    product: ProductType;
    configurableOptions?: SelectedConfigurableOption[] | null;
  }
>(item: CartItemType): ProductType | ConfiguredProductType | null {
  const { configurableOptions } = item;
  if (!configurableOptions) {
    return item.product;
  }
  const { variants } = item.product;
  if (!variants) {
    return item.product;
  }
  return resolveConfiguredProductBySelectedConfiguration<
    ConfiguredProductType,
    ProductVariantType
  >(variants, configurableOptions.map(x => x.valueId));
}

export function getMaxEstmiatedDeliveryDateOfCartItems<
  ProductType extends {
    variants?: ProductVariantType[] | null;
    deliveryMethod: ProductDeliveryMethod | null;
  } & ProductEstimatedDeliveryDate,
  ConfiguredProductType extends ProductEstimatedDeliveryDate & {
    deliveryMethod: ProductDeliveryMethod | null;
  },
  ProductVariantType extends {
    attributes: ProductConfigurableAttributeOption[];
    product: ConfiguredProductType;
  },
  CartItemType extends {
    product: ProductType;
    configurableOptions?: SelectedConfigurableOption[] | null;
  }
>(items: CartItemType[]): Date | null {
  const ps = filterNullOrUndefined(
    items.map(i =>
      resolveConfiguredCartItemProduct<
        ProductType,
        ConfiguredProductType,
        ProductVariantType,
        CartItemType
      >(i)
    )
  );
  const dates = filterNullOrUndefined(
    ps.map(p =>
      p.deliveryMethod && !isEDDIgnoredByDeliveryMethod(p.deliveryMethod)
        ? p.estimatedDeliveryDate
        : null
    )
  );
  if (dates.length > 0) {
    return max(...dates);
  }
  return null;
}
