import React, { useContext } from "react";

import {
  Address,
  formatContactName,
  formatAddress,
} from "../../models/Customer";
import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./styles.module.scss";

interface Props {
  address: Address;
}

const AddressCard: React.FC<Props> = props => {
  const { address } = props;

  const { countryByID, regionByID, districtByID } = useContext(
    CountryRegionDistrictContext
  );

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.titleColumn}>
          <LocalizedText messageID="address_card.name.title" />
        </div>
        <div className={styles.valueColumn}>{formatContactName(address)}</div>
      </div>
      <div className={styles.rowSpacer} />
      {address.company && (
        <div className={styles.row}>
          <div className={styles.titleColumn}>
            <LocalizedText messageID="address_card.company.title" />
          </div>
          <div className={styles.valueColumn}>{address.company}</div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.titleColumn}>
          <LocalizedText messageID="address_card.telephone.title" />
        </div>
        <div className={styles.valueColumn}>{address.telephone}</div>
      </div>
      <div className={styles.rowSpacer} />
      <div className={styles.row}>
        <div className={styles.titleColumn}>
          <LocalizedText messageID="address_card.address.title" />
        </div>
        <div className={styles.valueColumn}>
          {formatAddress(address, countryByID, regionByID, districtByID)}
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
