import { InAppBrowser } from "@ionic-native/in-app-browser";
import { SocialSharing } from "@ionic-native/social-sharing";
import { Plugins } from "@capacitor/core";
import { useEffect, useState } from "react";

import { checkAppAvailability } from "./CheckAppAvailability";
import * as Platform from "./Platform";

const { Device } = Plugins;

export type ShareChannel =
  | "wechat"
  | "whatsapp"
  | "facebook"
  | "twitter"
  | "general";

const wechatApp = Platform.select({
  ios: "weixin://",
  android: "com.tencent.mm",
  default: undefined,
});
const facebookApp = Platform.select({
  ios: "fb://",
  android: "com.facebook.katana",
  default: undefined,
});
const twitterApp = Platform.select({
  ios: "twitter://",
  android: "com.twitter.android",
  default: undefined,
});
const whatsappApp = Platform.select({
  ios: "whatsapp://",
  android: "com.whatsapp",
  default: undefined,
});

const canShareWithWechat = async () =>
  wechatApp ? checkAppAvailability(wechatApp) : false;
const canShareWithFacebook = async () =>
  facebookApp ? checkAppAvailability(facebookApp) : false;
const canShareWithTwitter = async () =>
  twitterApp ? checkAppAvailability(twitterApp) : false;
const canShareWithWhatsapp = async () =>
  whatsappApp ? checkAppAvailability(whatsappApp) : false;

export async function socialShare(
  shareChannel: ShareChannel,
  title: string | undefined,
  message: string,
  messengerMessage: string,
  link: string
) {
  const deviceInfo = await Device.getInfo();
  switch (shareChannel) {
    case "wechat":
      if (await canShareWithWechat()) {
        InAppBrowser.create(
          `weixin://dl/moments?content=${encodeURI(messengerMessage)}`,
          "_system"
        );
      } else {
        await SocialSharing.share(message, undefined, undefined, link);
      }
      break;
    case "facebook":
      if (await canShareWithFacebook()) {
        await SocialSharing.shareViaFacebook("", undefined, link);
        break;
      }
      await SocialSharing.share(message, undefined, undefined, link);
      break;
    case "twitter":
      if (deviceInfo.platform === "ios") {
        if (await checkAppAvailability("twitter://")) {
          InAppBrowser.create(
            `twitter://post?message=${encodeURI(messengerMessage)}`,
            "_system"
          );
        } else {
          await SocialSharing.share(message, undefined, undefined, link);
        }
      } else {
        await SocialSharing.shareViaTwitter(messengerMessage);
      }
      break;
    case "whatsapp":
      if (deviceInfo.platform === "ios") {
        if (await checkAppAvailability("whatsapp://")) {
          InAppBrowser.create(
            `whatsapp://send?text=${encodeURI(messengerMessage)}`,
            "_system"
          );
        } else {
          await SocialSharing.share(message, undefined, undefined, link);
        }
      } else {
        await SocialSharing.shareViaWhatsApp(messengerMessage);
      }
      break;
    default:
      await SocialSharing.share(message, title, undefined, link);
  }
}

export function useCanShareWithFacebook() {
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    (async () => {
      setCanShare(await canShareWithFacebook());
    })();
  }, []);

  return canShare;
}

export function useCanShareWithTwitter() {
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    (async () => {
      setCanShare(await canShareWithTwitter());
    })();
  }, []);

  return canShare;
}

export function useCanShareWithWhatsapp() {
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    (async () => {
      setCanShare(await canShareWithWhatsapp());
    })();
  }, []);

  return canShare;
}
