import React, { useContext, useMemo } from "react";

import { IndexMap } from "../../utils/type";
import {
  CountryCode,
  RegionCode,
  DistrictName,
  Country,
  Region,
  District,
} from "../../models/CountryRegion";
import { Address, formatAddress } from "../../models/Customer";

import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";

import { CartAddress } from "../Checkout/models";

import { FormFieldRow, FormField } from "./FormField";

function transformCartAddressToAddress(
  cartAddress: CartAddress,
  countryByCountryCode: IndexMap<CountryCode, Country>,
  regionByRegionCode: IndexMap<RegionCode, Region>,
  districtByDistrictName: IndexMap<DistrictName, District>
): Address {
  const country = cartAddress.country.code
    ? countryByCountryCode[cartAddress.country.code]
    : null;
  const region = cartAddress.region.code
    ? regionByRegionCode[cartAddress.region.code]
    : null;
  const district = cartAddress.city
    ? districtByDistrictName[cartAddress.city]
    : null;
  const [street, building, block, flatOrFloor, roomNumber] = cartAddress.street;
  return {
    firstName: cartAddress.firstname || "",
    lastName: cartAddress.lastname || "",
    company: cartAddress.company || "",
    telephone: cartAddress.telephone || "",
    roomNumber: roomNumber || "",
    flatOrFloor: flatOrFloor || "",
    block: block || "",
    building: building || "",
    street: street || "",
    district: district ? district.id : null,
    area: region ? region.id : null,
    region: country ? country.id : null,
    isDefaultBilling: false,
    isDefaultShipping: false,
  };
}

interface Props {
  cartAddress: CartAddress;
}

const AddressForm: React.FC<Props> = props => {
  const { cartAddress } = props;
  const {
    countryByCountryCode,
    countryByID,
    regionByRegionCode,
    regionByID,
    districtByDistrictName,
    districtByID,
  } = useContext(CountryRegionDistrictContext);

  const address = useMemo(
    () =>
      transformCartAddressToAddress(
        cartAddress,
        countryByCountryCode,
        regionByRegionCode,
        districtByDistrictName
      ),
    [
      cartAddress,
      countryByCountryCode,
      regionByRegionCode,
      districtByDistrictName,
    ]
  );

  return (
    <div>
      {address.lastName || address.firstName ? (
        <FormFieldRow>
          {address.lastName ? (
            <FormField titleID="address_form.last_name">
              {address.lastName}
            </FormField>
          ) : null}
          {address.firstName ? (
            <FormField titleID="address_form.first_name">
              {address.firstName}
            </FormField>
          ) : null}
        </FormFieldRow>
      ) : null}
      {address.company ? (
        <FormFieldRow>
          <FormField titleID="address_form.company">
            {address.company}
          </FormField>
        </FormFieldRow>
      ) : null}
      {address.telephone ? (
        <FormFieldRow>
          <FormField titleID="address_form.telephone">
            {address.telephone}
          </FormField>
        </FormFieldRow>
      ) : null}
      <FormFieldRow>
        <FormField titleID="address_form.address">
          {formatAddress(address, countryByID, regionByID, districtByID)}
        </FormField>
      </FormFieldRow>
    </div>
  );
};

export default AddressForm;
