import * as yup from "yup";

export interface PromotionBanner {
  entityId: string;
  bannerImage: string;
  bannerAlt: string | null;
  bannerLink: string | null;
}

export const promotionBannerSchema: yup.Schema<PromotionBanner> = yup
  .object<PromotionBanner>({
    entityId: yup.string().required(),
    bannerImage: yup.string().required(),
    bannerAlt: yup.string().nullable(),
    bannerLink: yup.string().nullable(),
  })
  .camelCase();
