import React, { ReactNode } from "react";
import { NavDirection, RouterDirection } from "@ionic/core";
import { Omit } from "./utils";

export type OurNavDirection = NavDirection | "present" | "dismiss";

export interface ViewItem<RouteData = any> {
  id: string;
  key: string;
  element: React.ReactElement<any>;
  ref?: React.RefObject<HTMLElement>;
  routeData: RouteData;
  prevId?: string;
  mount: boolean;
  show: boolean;
  preventHide: boolean;
}

export interface ViewStack<ViewItemRouteData = any> {
  stackId: string;
  routerOutlet: HTMLIonRouterOutletElement;
  activeId?: string;
  views: ViewItem<ViewItemRouteData>[];
  isPresentation: boolean;
  closePath?: string;
  isActive: boolean;
}

export interface ViewStacks {
  [key: string]: ViewStack;
}

export interface NavContextState {
  viewStacks: ViewStacks;
  activeViewStackId?: string;

  hideView: (viewId: string) => void;
  setupIonRouter: (
    id: string,
    children: ReactNode,
    routerOutlet: HTMLIonRouterOutletElement,
    isPresentation: boolean,
    closePath: string | undefined
  ) => void;
  removeViewStack: (stack: string) => void;
  renderChild: (item: ViewItem) => void;
  navigate: (path: string, state?: any, direction?: RouterDirection) => void;
  replace: (href: string) => void;
  goBack: (defaultHref?: string, state?: any) => void;
  transitionView: (
    enteringEl: HTMLElement,
    leavingEl: HTMLElement,
    ionRouterOuter: HTMLIonRouterOutletElement,
    direction: OurNavDirection
  ) => void;
  waitForTransition: () => Promise<void>;
  getActivePathForTab: (tab: string) => string | null;
  getActiveTab: () => string | null;
}

export type OurNavContextState = NavContextState;

export const OurNavContext = React.createContext<OurNavContextState>(
  null as any
);

export interface OurNavContextProps {
  navContext: OurNavContextState;
}

export function withNav<P extends OurNavContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof OurNavContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof OurNavContextProps>> = (
    props: Omit<P, keyof OurNavContextProps>
  ) => (
    <OurNavContext.Consumer>
      {context => <Component {...(props as any)} navContext={context} />}
    </OurNavContext.Consumer>
  );

  return Wrapped;
}
