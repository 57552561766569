import { useApolloClient } from "@apollo/client";
import { useMemo } from "react";
import { useIntl } from "../i18n/Localization";
import { ClubMemberCampaign } from "../models/ClubMemberCampaign";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../repository/Hooks";
import {
  fetchClubMemberCampaign,
  fetchClubMemberCampaignCount,
  getClubMemberCampaign,
  getClubMemberCampaignCount,
} from "../api/ClubMemberCampaign";

export function useGetBingoList() {
  const client = useApolloClient();

  return useMemo(() => getClubMemberCampaign(client), [client]);
}

export function useFetchBingoList(): [
  ResourcesRequestState<ClubMemberCampaign>,
  () => Promise<ClubMemberCampaign>,
  () => Promise<ClubMemberCampaign>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    ClubMemberCampaign,
    () => Promise<ClubMemberCampaign>
  >({
    memoryCacheProvider: () => Promise.resolve(getClubMemberCampaign(client)),
    localCacheProvider: () =>
      fetchClubMemberCampaign(client, locale, "cache-only"),
    remoteResourcesProvider: () =>
      fetchClubMemberCampaign(client, locale, "network-only"),
  });

  return [requestState, fetch, refresh];
}

export function useFetchBingoListCount(): [
  ResourcesRequestState<number>,
  () => Promise<number>,
  () => Promise<number>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    number,
    () => Promise<number>
  >({
    memoryCacheProvider: () =>
      Promise.resolve(getClubMemberCampaignCount(client)),
    localCacheProvider: () =>
      fetchClubMemberCampaignCount(client, locale, "cache-only"),
    remoteResourcesProvider: () =>
      fetchClubMemberCampaignCount(client, locale, "network-only"),
  });

  return [requestState, fetch, refresh];
}
