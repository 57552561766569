import React, { useEffect, useRef, useCallback } from "react";
import { IonLifeCycleContextInterface } from "@ionic/react";
import { ScrollDetail } from "@ionic/core";
import { getScrollElement } from "../utils/ionicPatch";

export type ScrollDirection = "up" | "down";

export default function useContentScrollDirection(
  contentRef: React.RefObject<HTMLIonContentElement>,
  onScroll: (direction: ScrollDirection) => void,
  onViewLeave: () => void,
  ionLifeCycleContext: IonLifeCycleContextInterface
) {
  const scrollElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const content = contentRef.current;
    if (!content) {
      return;
    }
    (async () => {
      const scrollEl = await getScrollElement(content);
      scrollElementRef.current = scrollEl;
    })();
  }, [contentRef]);

  const lastScrollPositionRef = useRef<number | null>(null);

  const onIonScroll = useCallback(
    (e: any) => {
      const { currentY } = e.detail as ScrollDetail;
      const content = contentRef.current;
      const scrollElement = scrollElementRef.current;
      if (!content || !scrollElement) {
        return;
      }
      const { scrollHeight } = scrollElement;
      let lastScrollPosition = lastScrollPositionRef.current;
      if (lastScrollPosition === null) {
        lastScrollPosition = currentY;
        lastScrollPositionRef.current = currentY;
      }
      const deltaY = currentY - lastScrollPosition;
      if (deltaY === 0) {
        // Do nothing when scrolling but not scrolling
        return;
      } else if (
        // Overscroll on top
        currentY <= 0 ||
        // Scrolling up and not overscroll on buttom
        (deltaY < 0 &&
          (scrollHeight && currentY + content.clientHeight < scrollHeight)) ||
        // Prevent hiding when the content is small.
        // Note the scrollHeight is always larger than content.clientHeight by 1
        (scrollHeight && scrollHeight <= content.clientHeight + 1)
      ) {
        onScroll("up");
      } else {
        onScroll("down");
      }
      lastScrollPositionRef.current = currentY;
    },
    [contentRef, onScroll]
  );

  const onIonScrollEnd = useCallback(() => {
    lastScrollPositionRef.current = null;
  }, []);

  const onIonViewWillLeave = useCallback(() => onViewLeave(), [onViewLeave]);

  useEffect(() => {
    const content = contentRef.current;
    if (!content) {
      return;
    }
    const scrollEvents = content.scrollEvents;
    content.scrollEvents = true;
    content.addEventListener("ionScroll", onIonScroll);
    content.addEventListener("ionScrollEnd", onIonScrollEnd);
    return () => {
      content.scrollEvents = scrollEvents;
      content.removeEventListener("ionScroll", onIonScroll);
      content.removeEventListener("ionScrollEnd", onIonScrollEnd);
    };
  }, [contentRef, onIonScroll, onIonScrollEnd]);

  ionLifeCycleContext.onIonViewWillLeave(onIonViewWillLeave);
}
