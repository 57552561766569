import React, { useCallback } from "react";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./SelectSavedAddressButton.module.scss";

interface Props {
  onClick: () => void;
}

const SelectSavedAddressButton: React.FC<Props> = props => {
  const { onClick } = props;

  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <button className={styles.button} onClick={handleClick}>
      <LocalizedText messageID="checkout.filling_info.select_a_saved_address" />
    </button>
  );
};

export default SelectSavedAddressButton;
