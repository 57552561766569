import { Override } from "../utils/type";

export const OTHER_BRANDS = "OTHERS";

export interface BrandSlider {
  autoplay: boolean | null;
  autoplayDelay: number | null;
  buttonsShow: boolean | null;
  imageHeight: number | null;
  imageWidth: number | null;
  sliderHeaderColor: string | null;
  sliderTitle: string | null;
  sliderTitleColor: string | null;
  items: BrandSliderItem[] | null;
  itemsNumber: number | null;
}

export type RemoteBrandSlider = Override<
  BrandSlider,
  {
    items: RemoteBrandSliderItem[];
  }
>;

export interface BrandSliderItem {
  alt: string | null;
  img: string | null;
  label: string | null;
  position: number | null;
  url: string | null;
  id: string | null;
}

export type RemoteBrandSliderItem = Override<
  BrandSliderItem,
  {
    position: string | null;
  }
>;

export function transformRemoteBrandSliderToBrandSlider(
  r: RemoteBrandSlider
): BrandSlider {
  return {
    ...r,
    items: r.items
      ? r.items.map(i => {
          return {
            ...i,
            position: i.position ? parseInt(i.position, 10) : null,
          };
        })
      : null,
  };
}

export interface Brand {
  label: string;
  url: string;
  id: string | null;
  topCmsId?: string | null;
}

export function groupBrands<K extends string>(
  brands: Brand[],
  groupFn: (brand: Brand) => K | null
): [{ [key in K]: Brand[] }, Brand[]] {
  const m: { [key in K]: Brand[] } = Object.create({});
  const others: Brand[] = [];
  for (const brand of brands) {
    const k = groupFn(brand);
    if (k) {
      if (m[k] != null) {
        m[k].push(brand);
      } else {
        m[k] = [brand];
      }
    } else {
      others.push(brand);
    }
  }
  return [m, others];
}

export function groupByFirstLetterOrOthers(brand: Brand): string | null {
  const { label } = brand;
  if (/[a-zA-Z]/.test(label[0])) {
    return label[0].toUpperCase();
  }
  return null;
}

export function sortBrandKeys<K extends string>(b1: K, b2: K): 1 | 0 | -1 {
  if (b1.length > 1) {
    return 1;
  }
  if (b1.length === 0) {
    return -1;
  }
  return b1 > b2 ? 1 : b1 === b2 ? 0 : -1;
}
