import cn from "classnames";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./Accordion.module.scss";

interface AccordionProps {
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = props => {
  const { header, body, footer } = props;

  const [collapsed, setCollapsed] = useState(true);
  const [actualBodyHeight, setActualBodyHeight] = useState(0);
  const actualBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (actualBodyRef.current) {
      setActualBodyHeight(actualBodyRef.current.clientHeight);
    }
  }, [body]);

  const animationStyle = useMemo<CSSProperties>(
    () => ({
      height: collapsed ? "0px" : `${actualBodyHeight}px`,
    }),
    [collapsed, actualBodyHeight]
  );

  const handleToggleClick = useCallback((e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    e.stopPropagation();

    setCollapsed(prev => !prev);
  }, []);

  return (
    <div className={styles.accordion}>
      <div className={styles.header}>{header}</div>
      <div className={styles.body}>
        <div className={styles.actualBody} ref={actualBodyRef}>
          {body}
        </div>
        <div className={styles.animation} style={animationStyle} />
      </div>
      <button
        onClick={handleToggleClick}
        className={cn(styles.footer, {
          [styles.collapsed]: collapsed,
        })}
      >
        {footer}
      </button>
    </div>
  );
};

export default Accordion;
