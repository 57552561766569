import React from "react";
import cn from "classnames";
import { MerchantPreview } from "../../models/Merchant";
import { useImageStyle } from "../../utils/imageStyle";

import styles from "./styles.module.scss";

interface Props {
  merchant: MerchantPreview;
  className: string;
}

const MerchantBlock: React.FC<Props> = React.memo(props => {
  const {
    merchant: { logo, name },
    className,
  } = props;

  const logoStyle = useImageStyle(
    require("../../resources/merchant-placeholder.svg"),
    logo
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.logo} style={logoStyle} />
      <div className={styles.separator} />
      <div className={styles.name}>{name}</div>
    </div>
  );
});

export default MerchantBlock;
