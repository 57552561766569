import React, { useMemo, useState } from "react";

import { useModalState } from "../hook/modal";
import { TryClubpointModalProps } from "./TryClubpointModal";
import TryClubpointModal from "./TryClubpointModal/lazy";

interface TryClubpointModalContext {
  present: (props: TryClubpointModalProps) => void;
}

export const TryClubpointModalContext = React.createContext<
  TryClubpointModalContext
>(null as any);

export const TryClubpointModalProvider: React.FC = props => {
  const [isOpen, present, dismiss] = useModalState();
  const [modalProps, setModalProps] = useState<TryClubpointModalProps | null>(
    null
  );

  const contextValue = useMemo(
    () => ({
      present: (_props: TryClubpointModalProps) => {
        setModalProps(_props);
        present();
      },
    }),
    [setModalProps, present]
  );

  return (
    <>
      <TryClubpointModalContext.Provider value={contextValue}>
        {props.children}
      </TryClubpointModalContext.Provider>
      {modalProps && (
        <TryClubpointModal
          key={`${modalProps.basePrice.value}${modalProps.minValue}`}
          isOpen={isOpen}
          onRequestDismiss={dismiss}
          {...modalProps}
        />
      )}
    </>
  );
};
