import { Animation } from "@ionic/core";

const DURATION = 500;
const EASING = "cubic-bezier(0.36,0.66,0.04,1)";
const OPACITY = "opacity";
const TRANSFORM = "transform";
const TRANSLATEX = "translateX";
const CENTER = "0%";

export const shadow = <T extends Element>(el: T): ShadowRoot | T => {
  return el.shadowRoot || el;
};

export const iosTransitionAnimation = (
  AnimationC: Animation,
  navEl: HTMLElement,
  opts: any
): Promise<Animation> => {
  const isRTL = (navEl.ownerDocument as any).dir === "rtl";
  const OFF_RIGHT = isRTL ? "-99.5%" : "99.5%";
  const OFF_LEFT = isRTL ? "33%" : "-33%";

  const enteringEl = opts.enteringEl;
  const leavingEl = opts.leavingEl;

  const rootTransition = new AnimationC();
  rootTransition
    .addElement(enteringEl)
    .duration(opts.duration || DURATION)
    .easing(opts.easing || EASING)
    .beforeRemoveClass("ion-page-invisible");

  if (leavingEl && navEl) {
    const navDecor = new AnimationC();
    navDecor.addElement(navEl);

    rootTransition.add(navDecor);
  }

  const backDirection = opts.direction === "back";
  // setting up enter view
  const contentEl = enteringEl.querySelector(":scope > ion-content");
  const headerEls = enteringEl.querySelectorAll(
    ":scope > ion-header > *:not(ion-toolbar), :scope > ion-footer > *"
  );
  const enteringToolBarEls = enteringEl.querySelectorAll(
    ":scope > ion-header > ion-toolbar"
  );
  const enteringContent = new AnimationC();

  if (!contentEl && enteringToolBarEls.length === 0 && headerEls.length === 0) {
    enteringContent.addElement(
      enteringEl.querySelector(
        ":scope > .ion-page, :scope > ion-nav, :scope > ion-tabs"
      )
    );
  } else {
    enteringContent.addElement(contentEl);
    enteringContent.addElement(headerEls);
  }

  rootTransition.add(enteringContent);

  if (backDirection) {
    enteringContent.fromTo(TRANSLATEX, OFF_LEFT, CENTER, true);
  } else {
    // entering content, forward direction
    enteringContent.fromTo(TRANSLATEX, OFF_RIGHT, CENTER, true);
  }

  enteringToolBarEls.forEach((enteringToolBarEl: any) => {
    const enteringToolBar = new AnimationC();
    enteringToolBar.addElement(enteringToolBarEl);
    rootTransition.add(enteringToolBar);

    const enteringTitle = new AnimationC();
    enteringTitle.addElement(enteringToolBarEl.querySelector("ion-title"));

    const enteringToolBarButtons = new AnimationC();
    enteringToolBarButtons.addElement(
      enteringToolBarEl.querySelectorAll("ion-buttons,[menuToggle]")
    );

    const enteringToolBarItems = new AnimationC();
    enteringToolBarItems.addElement(
      enteringToolBarEl.querySelectorAll(
        ":scope > *:not(ion-title):not(ion-buttons):not([menuToggle])"
      )
    );

    const enteringToolBarBg = new AnimationC();
    enteringToolBarBg.addElement(
      shadow(enteringToolBarEl).querySelector(".toolbar-background")
    );

    const enteringBackButton = new AnimationC();
    const backButtonEl = enteringToolBarEl.querySelector("ion-back-button");

    if (backButtonEl) {
      enteringBackButton.addElement(backButtonEl);
    }

    enteringToolBar
      .add(enteringTitle)
      .add(enteringToolBarButtons)
      .add(enteringToolBarItems)
      .add(enteringToolBarBg)
      .add(enteringBackButton);

    enteringTitle.fromTo(OPACITY, 0.01, 1, true);
    enteringToolBarButtons.fromTo(OPACITY, 0.01, 1, true);
    enteringToolBarItems.fromTo(OPACITY, 0.01, 1, true);

    if (backDirection) {
      enteringTitle.fromTo(TRANSLATEX, OFF_LEFT, CENTER, true);

      enteringToolBarItems.fromTo(TRANSLATEX, OFF_LEFT, CENTER, true);

      // back direction, entering page has a back button
      enteringBackButton.fromTo(OPACITY, 0.01, 1, true);
    } else {
      // entering toolbar, forward direction
      enteringTitle.fromTo(TRANSLATEX, OFF_RIGHT, CENTER, true);

      enteringToolBarItems.fromTo(TRANSLATEX, OFF_RIGHT, CENTER, true);

      enteringToolBarBg
        .beforeClearStyles([OPACITY])
        .fromTo(OPACITY, 0.01, 1, true);

      // forward direction, entering page has a back button
      enteringBackButton.fromTo(OPACITY, 0.01, 1, true);

      if (backButtonEl) {
        const enteringBackBtnText = new AnimationC();
        enteringBackBtnText
          .addElement(shadow(backButtonEl).querySelector(".button-text"))
          .fromTo(TRANSLATEX, isRTL ? "-100px" : "100px", "0px");

        enteringToolBar.add(enteringBackBtnText);
      }
    }
  });

  // setup leaving view
  if (leavingEl) {
    const leavingContent = new AnimationC();

    const _contentEl = leavingEl.querySelector(":scope > ion-content");
    const _headerEls = leavingEl.querySelectorAll(
      ":scope > ion-header > *:not(ion-toolbar), :scope > ion-footer > *"
    );
    const leavingToolBarEls = leavingEl.querySelectorAll(
      ":scope > ion-header > ion-toolbar"
    );

    if (
      !_contentEl &&
      leavingToolBarEls.length === 0 &&
      _headerEls.length === 0
    ) {
      leavingContent.addElement(
        leavingEl.querySelector(
          ":scope > .ion-page, :scope > ion-nav, :scope > ion-tabs"
        )
      );
    } else {
      leavingContent.addElement(_contentEl);
      leavingContent.addElement(_headerEls);
    }

    rootTransition.add(leavingContent);

    if (backDirection) {
      // leaving content, back direction
      leavingContent.fromTo(TRANSLATEX, CENTER, isRTL ? "-100%" : "100%");
    } else {
      // leaving content, forward direction
      leavingContent.fromTo(TRANSLATEX, CENTER, OFF_LEFT, true);
    }

    leavingToolBarEls.forEach((leavingToolBarEl: any) => {
      const leavingToolBar = new AnimationC();
      leavingToolBar.addElement(leavingToolBarEl);

      const leavingTitle = new AnimationC();
      leavingTitle.addElement(leavingToolBarEl.querySelector("ion-title"));

      const leavingToolBarButtons = new AnimationC();
      leavingToolBarButtons.addElement(
        leavingToolBarEl.querySelectorAll("ion-buttons,[menuToggle]")
      );

      const leavingToolBarItems = new AnimationC();
      const leavingToolBarItemEls = leavingToolBarEl.querySelectorAll(
        ":scope > *:not(ion-title):not(ion-buttons):not([menuToggle])"
      );
      if (leavingToolBarItemEls.length > 0) {
        leavingToolBarItems.addElement(leavingToolBarItemEls);
      }

      const leavingToolBarBg = new AnimationC();
      leavingToolBarBg.addElement(
        shadow(leavingToolBarEl).querySelector(".toolbar-background")
      );

      const leavingBackButton = new AnimationC();
      const backButtonEl = leavingToolBarEl.querySelector("ion-back-button");
      if (backButtonEl) {
        leavingBackButton.addElement(backButtonEl);
      }

      leavingToolBar
        .add(leavingTitle)
        .add(leavingToolBarButtons)
        .add(leavingToolBarItems)
        .add(leavingBackButton)
        .add(leavingToolBarBg);

      rootTransition.add(leavingToolBar);

      // fade out leaving toolbar items
      leavingBackButton.fromTo(OPACITY, 0.99, 0);
      leavingTitle.fromTo(OPACITY, 0.99, 0);
      leavingToolBarButtons.fromTo(OPACITY, 0.99, 0, 0);
      leavingToolBarItems.fromTo(OPACITY, 0.99, 0);

      if (backDirection) {
        // leaving toolbar, back direction
        leavingTitle.fromTo(TRANSLATEX, CENTER, isRTL ? "-100%" : "100%");
        leavingToolBarItems.fromTo(
          TRANSLATEX,
          CENTER,
          isRTL ? "-100%" : "100%"
        );

        // leaving toolbar, back direction, and there's no entering toolbar
        // should just slide out, no fading out
        leavingToolBarBg.beforeClearStyles([OPACITY]).fromTo(OPACITY, 1, 0.01);

        if (backButtonEl) {
          const leavingBackBtnText = new AnimationC();
          leavingBackBtnText.addElement(
            shadow(backButtonEl).querySelector(".button-text")
          );
          leavingBackBtnText.fromTo(
            TRANSLATEX,
            CENTER,
            (isRTL ? -124 : 124) + "px"
          );
          leavingToolBar.add(leavingBackBtnText);
        }
      } else {
        // leaving toolbar, forward direction
        leavingTitle
          .fromTo(TRANSLATEX, CENTER, OFF_LEFT)
          .afterClearStyles([TRANSFORM]);
        leavingToolBarItems
          .fromTo(TRANSLATEX, CENTER, OFF_LEFT)
          .afterClearStyles([TRANSFORM, OPACITY]);

        leavingBackButton.afterClearStyles([OPACITY]);
        leavingTitle.afterClearStyles([OPACITY]);
        leavingToolBarButtons.afterClearStyles([OPACITY]);
      }
    });
  }
  // Return the rootTransition promise
  return Promise.resolve(rootTransition);
};
