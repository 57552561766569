import produce from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { FilterInputField } from "../models/filter";
import { IndexMap } from "../utils/type";

interface ContextState {
  productAttributeFilterInputFields: FilterInputField[];
  productAttributeFilterFields: string[];
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>;
}

const initialContextState: ContextState = {
  productAttributeFilterInputFields: [],
  productAttributeFilterFields: [],
  productAttributeFilterInputMap: {},
};

type Context = ContextState & {
  updateProductAttributeFilterFields: (
    productAttributeFilterInputFields: FilterInputField[]
  ) => void;
};

const initialContextValue: Context = {
  ...initialContextState,
  updateProductAttributeFilterFields: () => {},
};

const ProductAttributeFilterContext = React.createContext<Context>(
  initialContextValue
);

export const ProductAttributeFilterContextProvider: React.FC = props => {
  const [state, setState] = useState(initialContextState);

  const updateProductAttributeFilterFields = useCallback(
    (productAttributeFilterInputFields: FilterInputField[]) => {
      setState(prevState =>
        produce(prevState, draft => {
          draft.productAttributeFilterInputFields = productAttributeFilterInputFields;
          draft.productAttributeFilterFields = productAttributeFilterInputFields.map(
            ({ name }) => name
          );
          draft.productAttributeFilterInputMap = {};
          for (const productAttributeFilterInput of productAttributeFilterInputFields) {
            const { name } = productAttributeFilterInput;
            draft.productAttributeFilterInputMap[
              name
            ] = productAttributeFilterInput;
          }
        })
      );
    },
    []
  );

  const contextValue = useMemo(
    () => ({ ...state, updateProductAttributeFilterFields }),
    [state, updateProductAttributeFilterFields]
  );

  return (
    <ProductAttributeFilterContext.Provider value={contextValue}>
      {props.children}
    </ProductAttributeFilterContext.Provider>
  );
};

export default ProductAttributeFilterContext;
