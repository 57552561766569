import React, { useCallback, useContext } from "react";
import {
  // eslint-disable-next-line
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
} from "@ionic/react";
import cn from "classnames";

import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { CheckoutSession } from "../../utils/PerformanceRecordStore/sessions";

import { NavBarMessageButton } from "../NavBar";
import ShoppingCartButton from "../ShoppingCartButton";
import { QRScannerContext } from "../QRScanner/context";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";
import { PushNotificationContext } from "../PushNotificationProvider";

import styles from "./styles.module.scss";
import {
  getPathForShoppingCart,
  getPathForSearchSuggestion,
  useCurrentTab,
  getPathForPushNotificationMessages,
} from "../../navigation/routes";
import { PresentationContext, OurNavContext } from "../../our-navigation";

import Config from "../../Config";

interface Props {
  title?: React.ReactNode;
  titleClassName?: string;
}

const MainTabPageNavBar: React.FC<Props> = props => {
  const { title, titleClassName } = props;
  const presentationContext = useContext(PresentationContext);
  const { present: _presentQRScanner } = useContext(QRScannerContext);
  const { unreadMessageCount } = useContext(PushNotificationContext);
  const presentQRScanner = useCallback(() => {
    _presentQRScanner();
  }, [_presentQRScanner]);
  const onClickShoppingCart = useCallback(() => {
    addPerformanceRecord(CheckoutSession(), "Shopping cart button click");
    presentationContext.present(getPathForShoppingCart());
  }, [presentationContext]);

  const onClickMessage = useCallback(() => {
    presentationContext.present(getPathForPushNotificationMessages());
  }, [presentationContext]);

  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  const { navigate } = useContext(OurNavContext);
  const tab = useCurrentTab();

  const handleSearchClick = useCallback(() => {
    navigate(getPathForSearchSuggestion(tab));
  }, [tab, navigate]);

  return (
    <>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="secondary">
            <IonButton
              className={styles.searchButton}
              onClick={handleSearchClick}
            >
              <div className={styles.searchIcon} />
            </IonButton>
            <IonButton className={styles.scanButton} onClick={presentQRScanner}>
              <div className={styles.scanIcon} />
            </IonButton>
          </IonButtons>
          <IonTitle
            className={cn(title ? titleClassName : undefined, {
              [styles.navBarTitle]: !!title,
            })}
          >
            {title ? title : <div className={styles.logo} />}
          </IonTitle>
          <IonButtons slot="primary">
            {Config.SHOW_ENVELOPE_ICON ? (
              <NavBarMessageButton
                onClick={onClickMessage}
                count={unreadMessageCount}
              />
            ) : null}
            <ShoppingCartButton
              onClick={onClickShoppingCart}
              count={shoppingCartItemCount}
            />
          </IonButtons>
        </IonToolbar>
        {props.children ? (
          <IonToolbar mode="ios" className={styles["padding-0"]}>
            {props.children}
          </IonToolbar>
        ) : null}
      </IonHeader>
    </>
  );
};

export default MainTabPageNavBar;
