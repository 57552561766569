import produce from "immer";
import React, { useCallback, useMemo, useState } from "react";
import {
  Article,
  ArticleAuthor,
  ArticleCategory,
  ArticleTag,
} from "../models/Article";
import { IndexMap } from "../utils/type";

interface ContextState {
  articleById: IndexMap<string, Article>;
  categoryById: IndexMap<string, ArticleCategory>;
  authorById: IndexMap<string, ArticleAuthor>;
  tagById: IndexMap<string, ArticleTag>;
}

const initialContextState: ContextState = {
  articleById: {},
  categoryById: {},
  authorById: {},
  tagById: {},
};

interface ContextAction {
  updateArticleCategories: (categories: ArticleCategory[]) => void;
  updateArticle: (article: Article) => void;
  updateArticleAuthor: (authorId: string, author: ArticleAuthor) => void;
  UpdateArticleTags: (tags: ArticleTag[]) => void;
}

const initialContextAction: ContextAction = {
  updateArticleCategories: () => {},
  updateArticle: () => {},
  updateArticleAuthor: () => {},
  UpdateArticleTags: () => {},
};

type Context = ContextState & ContextAction;

const initialContextValue = { ...initialContextState, ...initialContextAction };

const ArticleContext = React.createContext<Context>(initialContextValue);

export default ArticleContext;

export const ArticleContextProvider: React.FC = props => {
  const [state, setState] = useState(initialContextState);

  const updateArticleCategories = useCallback(
    (categories: ArticleCategory[]) => {
      setState(prevState =>
        produce(prevState, draft => {
          for (const category of categories) {
            draft.categoryById[category.categoryId] = category;
          }
        })
      );
    },
    []
  );

  const updateArticle = useCallback((article: Article) => {
    setState(prevState =>
      produce(prevState, draft => {
        draft.articleById[article.postId] = article;
      })
    );
  }, []);

  const updateArticleAuthor = useCallback(
    (authorId: string, author: ArticleAuthor) => {
      setState(prevState =>
        produce(prevState, draft => {
          draft.authorById[authorId] = author;
        })
      );
    },
    []
  );

  const UpdateArticleTags = useCallback((tags: ArticleTag[]) => {
    setState(prevState =>
      produce(prevState, draft => {
        for (const tag of tags) {
          draft.tagById[tag.tagId] = tag;
        }
      })
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      updateArticleCategories,
      updateArticle,
      updateArticleAuthor,
      UpdateArticleTags,
    }),
    [
      state,
      updateArticleCategories,
      updateArticle,
      updateArticleAuthor,
      UpdateArticleTags,
    ]
  );

  return (
    <ArticleContext.Provider value={contextValue}>
      {props.children}
    </ArticleContext.Provider>
  );
};
