import React from "react";
import { IonIcon } from "@ionic/react";

import styles from "./ExtraClubPointBadge.module.scss";

interface Props {
  point: number;
}
const ExtraClubPointBadge: React.FC<Props> = props => {
  const { point } = props;
  if (point <= 0) {
    return null;
  }
  return (
    <div className={styles.badge}>
      <IonIcon className={styles.clubpointIcon} name="club-point" /> {point}x
    </div>
  );
};

export default ExtraClubPointBadge;
