import React, { useCallback } from "react";
import { IonItemDivider, IonItemGroup, IonLabel } from "@ionic/react";

import { Brand, OTHER_BRANDS } from "../../models/Brand";
import { LocalizedText } from "../../i18n/Localization";

import styles from "./List.module.scss";

interface Props {
  keys: string[];
  groupedBrands: { [x in string]: Brand[] };
  otherBrands: Brand[];
  onBrandClick: (brand: Brand) => void;
}

const List: React.FC<Props> = props => {
  const { keys, groupedBrands, otherBrands, onBrandClick } = props;
  return (
    <div className={styles.brandList}>
      {keys.map(key => {
        const brands = key === OTHER_BRANDS ? otherBrands : groupedBrands[key];
        return (
          <IonItemGroup key={key}>
            <IonItemDivider sticky={true}>
              <IonLabel>
                {key === OTHER_BRANDS ? (
                  <LocalizedText messageID="page.brand_index.filter.others" />
                ) : (
                  <span className={styles.groupLabel}>{key}</span>
                )}
              </IonLabel>
            </IonItemDivider>
            {brands.map(brand => {
              return (
                <BrandListItem
                  brand={brand}
                  key={brand.label}
                  onClick={onBrandClick}
                />
              );
            })}
          </IonItemGroup>
        );
      })}
    </div>
  );
};

export default List;

interface BrandListItemProps {
  brand: Brand;
  onClick: (e: Brand) => void;
}

const BrandListItem: React.FC<BrandListItemProps> = props => {
  const { brand, onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(brand);
    },
    [onClick, brand]
  );

  return (
    <>
      <div className={styles.item}>
        <button onClick={handleClick} className={styles.itemContent}>
          <span className={styles.itemLabel}>{brand.label}</span>
        </button>
      </div>
    </>
  );
};
