import React, { useMemo } from "react";
import cn from "classnames";
import numeral from "numeral";
import { IonIcon } from "@ionic/react";

import { LocalizedText } from "../../i18n/Localization";

import { formatMoney } from "../../models/Price";
import { ProductOverview } from "../../models/ProductOverview";

import {
  getCrossedMoney,
  getListedMoney,
  getPriceRangeTextMessageID,
  getManufacturerSuggestedRetailPriceMoney,
} from "./ProductPriceViewUtils";

import styles from "./styles.module.scss";

interface Props {
  productOfMinValue: ProductOverview;
  productOfMaxValue: ProductOverview;
}

const ProductPriceView: React.FC<Props> = React.memo(props => {
  const { productOfMinValue, productOfMaxValue } = props;

  const manufacturerSuggestedRetailPriceMoney = useMemo(
    () => getManufacturerSuggestedRetailPriceMoney(productOfMinValue),
    [productOfMinValue]
  );
  const crossedMoney = useMemo(
    () => getCrossedMoney(productOfMinValue, productOfMaxValue),
    [productOfMinValue, productOfMaxValue]
  );
  const priceRangeTextMessageID = useMemo(
    () => getPriceRangeTextMessageID(productOfMinValue, productOfMaxValue),
    [productOfMinValue, productOfMaxValue]
  );
  const listedMoney = useMemo(
    () => getListedMoney(productOfMinValue, productOfMaxValue),
    [productOfMinValue, productOfMaxValue]
  );

  const isSpecialPrice = useMemo(
    () => !!crossedMoney || manufacturerSuggestedRetailPriceMoney,
    [crossedMoney, manufacturerSuggestedRetailPriceMoney]
  );

  return (
    <div>
      {manufacturerSuggestedRetailPriceMoney ? (
        <p className={styles.manufacturerSuggestedRetailPrice}>
          {formatMoney(manufacturerSuggestedRetailPriceMoney)}
        </p>
      ) : null}
      {crossedMoney ? (
        <p>
          {crossedMoney.type === "single" ? (
            <s className={styles.productOriginalPrice}>
              {formatMoney(crossedMoney.money)}
            </s>
          ) : crossedMoney.type === "range" ? (
            <>
              <s className={styles.productOriginalPrice}>
                {formatMoney(crossedMoney.minMoney)} -
                {formatMoney(crossedMoney.maxMoney)}
              </s>
            </>
          ) : null}
        </p>
      ) : null}
      {priceRangeTextMessageID ? (
        <p className={styles.productPriceAsLowAs}>
          <LocalizedText messageID={priceRangeTextMessageID} />
        </p>
      ) : null}
      <div
        className={cn(styles.productPriceText, {
          [styles.withSpecialPrice]: isSpecialPrice,
        })}
      >
        {listedMoney ? (
          <p>
            {listedMoney.type === "single" ? (
              <p className={styles.priceRow}>
                {formatMoney(listedMoney.money)}
              </p>
            ) : listedMoney.type === "singleMinCP" ? (
              <p className={styles.priceRow}>
                <IonIcon name="club-point" className={styles.clubPointIcon} />
                {numeral(listedMoney.minClubPoint).format("0,0")} +{" "}
                {formatMoney(listedMoney.money)}
              </p>
            ) : listedMoney.type === "range" ? (
              <p className={styles.priceRow}>
                {formatMoney(listedMoney.minMoney)} -{" "}
                {formatMoney(listedMoney.maxMoney)}
              </p>
            ) : listedMoney.type === "cpOnly" ? (
              <p className={styles.priceRow}>
                <IonIcon name="club-point" className={styles.clubPointIcon} />
                {numeral(listedMoney.clubPoint).format("0,0")}
              </p>
            ) : null}
          </p>
        ) : null}
      </div>
    </div>
  );
});

export default ProductPriceView;
