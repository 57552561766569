import React, { useMemo, useCallback } from "react";
import SearchSuggestionList, {
  SearchSuggestionListItemItem,
} from "./SearchSuggestionList";
import { SearchTerm } from "../../models/Search";
import { stringLiteral } from "../../utils/type";

interface Props {
  recentlySearches: SearchTerm[];
  onClickItem: (searchTerm: SearchTerm) => void;
}

const RecentlySearchesList: React.FC<Props> = props => {
  const { recentlySearches, onClickItem } = props;
  const listItems = useMemo(
    () => ({
      type: stringLiteral("search-terms"),
      searchTerms: recentlySearches,
    }),
    [recentlySearches]
  );
  const onClickItem_ = useCallback(
    (item: SearchSuggestionListItemItem) => {
      if (item.type === "search-term") {
        onClickItem(item.searchTerm);
      }
    },
    [onClickItem]
  );
  return (
    <SearchSuggestionList
      displayMode="row"
      headerTitleID="page.search_suggestion.list.recently_searches.title"
      items={listItems}
      onClickItem={onClickItem_}
    />
  );
};

export default RecentlySearchesList;
