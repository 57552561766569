import React, { useMemo, useCallback, useContext, useRef } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { LocalizedText } from "../../i18n/Localization";

import {
  isRequestLoading,
  isRequestError,
} from "../../models/ResourcesRequestState";
import { CustomerSubscription } from "../../models/CustomerSubscription";
import {
  SimpleViewState,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
  SimpleViewStateDisplay,
  SimpleViewStateError,
} from "../../models/SimpleViewState";
import { useGetCustomerSubscriptions } from "../../repository/CustomerSubscriptionRepository";
import { RepositoryContext } from "../../repository/State";

import { OurNavContext } from "../../our-navigation";
import {
  getPathForSubscriptionDetails,
  useCurrentTab,
} from "../../navigation/routes";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { FullContentLoadingView } from "../LoadingView";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import Item from "./Item";

import styles from "./styles.module.scss";

const MySubscriptionsPage: React.FC = () => {
  const currentTab = useCurrentTab();

  const contentRef = useRef<HTMLIonContentElement>(null);
  const { isOnline } = useContext(NetworkStatusContext);
  const { navigate } = useContext(OurNavContext);
  const {
    state: { customerSubscriptions },
  } = useContext(RepositoryContext);

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const [requestState, fetch, refresh] = useGetCustomerSubscriptions();

  const viewDidEnterOnceRef = useRef(false);
  const onViewDidEnter = useCallback(() => {
    if (!viewDidEnterOnceRef.current) {
      fetch().catch(() => {});
      viewDidEnterOnceRef.current = true;
    }
  }, [fetch]);
  ionLifeCycleContext.onIonViewDidEnter(onViewDidEnter);

  const viewState = useMemo<SimpleViewState<CustomerSubscription[]>>(() => {
    if (customerSubscriptions == null) {
      if (isRequestLoading(requestState)) {
        return SimpleViewStateLoading;
      }
      if (isRequestError(requestState)) {
        return SimpleViewStateError(requestState.error);
      }
      return SimpleViewStateInitial;
    }
    if (customerSubscriptions.length === 0) {
      if (isRequestError(requestState)) {
        return SimpleViewStateError(requestState.error);
      }
      if (
        requestState.type === "loaded" &&
        requestState.source === "local-cache"
      ) {
        return SimpleViewStateLoading;
      }
    }
    return SimpleViewStateDisplay(customerSubscriptions);
  }, [requestState, customerSubscriptions]);

  const onRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh]
  );

  const retry = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);

  const handleDetailClick = useCallback(
    (customerSubscription: CustomerSubscription) => {
      const { subscription } = customerSubscription;
      if (subscription && subscription.subscriptionId) {
        navigate(
          getPathForSubscriptionDetails(currentTab, subscription.subscriptionId)
        );
      }
    },
    [currentTab, navigate]
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="page.my_subscriptions.title" />}
      />
      <CLContent ref={contentRef} className={styles.ionContent}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={
            customerSubscriptions ? customerSubscriptions.length > 0 : false
          }
          onRetry={retry}
        >
          {viewState.type === "initial" || viewState.type === "loading" ? (
            <FullContentLoadingView />
          ) : (
            <List
              onRefresh={onRefresh}
              viewState={viewState}
              onDetailClick={handleDetailClick}
            />
          )}
        </NoInternetConnectionView>
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

export default MySubscriptionsPage;

interface ListProps {
  onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
  onDetailClick: (customerSubscription: CustomerSubscription) => void;
  viewState: Extract<
    SimpleViewState<CustomerSubscription[]>,
    { type: "error" } | { type: "display" }
  >;
}

const List: React.FC<ListProps> = props => {
  const { onRefresh, viewState, onDetailClick } = props;

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {viewState.type === "error" && <ErrorView />}
      {viewState.type === "display" ? (
        viewState.data.length === 0 ? (
          <EmptyView />
        ) : (
          <>
            {viewState.data.map((customerSubscription, i) => (
              <Item
                key={i}
                customerSubscription={customerSubscription}
                onDetailClick={onDetailClick}
              />
            ))}
          </>
        )
      ) : null}
    </>
  );
};

const EmptyView: React.FC = () => {
  return (
    <div className={styles.emptyMessage}>
      <LocalizedText messageID="my_subscriptions.empty" />
    </div>
  );
};

const ErrorView: React.FC = () => {
  return (
    <div className={styles.errorMessage}>
      <LocalizedText messageID="error.unknown" />
    </div>
  );
};
