import {
  CameraPhoto,
  CameraResultType,
  CameraSource,
  Plugins,
} from "@capacitor/core";
import { actionSheetController, RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import isError from "lodash/isError";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { crop } from "../../CLPlugins/ImageCropper";
import {
  check,
  openSettings,
  Permission,
  request,
  requestable,
} from "../../CLPlugins/Permission";
import Config from "../../Config";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import {
  getCustomerClubMemberId,
  getCustomerProfilePicUrl,
  isCustomerLinkedToTheClub,
} from "../../models/Customer";
import { OurNavContext } from "../../our-navigation";
import {
  useCustomer,
  useGetMyCustomerRequest,
  useRefreshCustomer,
  useUpdateMyCustomerInfoRequest,
} from "../../repository/AuthRepository";
import { resizeImage } from "../../utils/ResizeHelper";
import { usePresentTheClubMemberProfileBrowser } from "../../utils/TheClubMemberProfile";
import { mapNullable } from "../../utils/type";
import Checkbox from "../Checkbox";
import CLContent from "../CLContent";
import ProfilePictureContainer from "../EditProfilePage/ProfilePictureContainer";
import { LoadingModalContext } from "../LoadingModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { NavBar, NavBarBackButton } from "../NavBar";
import styles from "./styles.module.scss";

const { Camera, Filesystem } = Plugins;

const AccountInformationPage: React.FC = () => {
  const { withLoadingModalAsync } = useContext(LoadingModalContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { goBack } = useContext(OurNavContext);

  const { translate } = useIntl();
  const _customer = useCustomer();
  const initialCustomer = useRef(_customer);

  const fetchCustomer = useGetMyCustomerRequest();
  const refreshCustomer = useRefreshCustomer();

  const [firstName, setFirstName] = useState(
    mapNullable(initialCustomer.current, c => c.firstname) || ""
  );
  const [lastName, setLastName] = useState(
    mapNullable(initialCustomer.current, c => c.lastname) || ""
  );
  const [email, setEmail] = useState(
    mapNullable(initialCustomer.current, c => c.email) || ""
  );
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(
    mapNullable(initialCustomer.current, getCustomerProfilePicUrl)
  );
  const [memberId, setMemberId] = useState<string | null>(
    mapNullable(initialCustomer.current, getCustomerClubMemberId)
  );
  const [isSubscribed, setIsSubscribed] = useState(
    mapNullable(initialCustomer.current, c => c.isSubscribed) || false
  );

  useEffect(() => {
    if (initialCustomer.current != null) {
      return;
    }
    withLoadingModalAsync(async () => {
      try {
        const customer = await fetchCustomer();
        if (!customer || !isCustomerLinkedToTheClub(customer)) {
          throw new Error("no-customer");
        }
        setProfilePicUrl(getCustomerProfilePicUrl(customer));
        setFirstName(customer.firstname);
        setLastName(customer.lastname);
        setEmail(customer.email);
        setMemberId(customer.clubMember[0].clubMemberID);
        setIsSubscribed(customer.isSubscribed);
      } catch {
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: "page.edit_profile.alert.cannot_fetch_customer",
          buttons: [
            {
              textMessageID: "alert.button.ok",
              handler: () => {
                goBack();
              },
            },
          ],
        });
      }
    });
  }, [withLoadingModalAsync, fetchCustomer, presentLocalizedAlert, goBack]);

  const presentFromPhotoBlockedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.from_photo_blocked",
      buttons: [
        { textMessageID: "alert.button.ok" },
        {
          textMessageID: "go_to_settings",
          handler: () => {
            openSettings();
          },
        },
      ],
    });
  }, [presentLocalizedAlert]);

  const presentFromPhotoDeniedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.from_photo_denied",
      buttons: [{ textMessageID: "alert.button.ok" }],
    });
  }, [presentLocalizedAlert]);

  const presentTakePictureBlockedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.take_picture_blocked",
      buttons: [
        { textMessageID: "alert.button.ok" },
        {
          textMessageID: "go_to_settings",
          handler: () => {
            openSettings();
          },
        },
      ],
    });
  }, [presentLocalizedAlert]);

  const presentTakePictureDeniedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.take_picture_denied",
      buttons: [{ textMessageID: "alert.button.ok" }],
    });
  }, [presentLocalizedAlert]);

  const getPhoto = useCallback((): Promise<CameraPhoto> => {
    return new Promise(resolve => {
      actionSheetController
        .create({
          buttons: [
            {
              text: translate(
                "page.edit_profile.action_sheet.camera.from_photos"
              ),
              handler: () => {
                (async () => {
                  let { result: permissionResult } = await check({
                    permission: Permission.PHOTO_LIBRARY,
                  });
                  if (permissionResult === "blocked") {
                    presentFromPhotoBlockedAlert();
                    return;
                  }
                  if (requestable(permissionResult)) {
                    const { result: requestResult } = await request({
                      permission: Permission.PHOTO_LIBRARY,
                    });
                    permissionResult = requestResult;
                  }
                  if (permissionResult !== "granted") {
                    presentFromPhotoDeniedAlert();
                    return;
                  }
                  try {
                    await Camera.getPhoto({
                      quality: 100,
                      resultType: CameraResultType.Uri,
                      source: CameraSource.Photos,
                    }).then(resolve);
                  } catch {}
                })();
              },
            },
            {
              text: translate(
                "page.edit_profile.action_sheet.camera.take_picture"
              ),
              handler: () => {
                (async () => {
                  let { result: permissionResult } = await check({
                    permission: Permission.CAMERA,
                  });
                  if (permissionResult === "blocked") {
                    presentTakePictureBlockedAlert();
                    return;
                  }
                  if (requestable(permissionResult)) {
                    const { result: requestResult } = await request({
                      permission: Permission.CAMERA,
                    });
                    permissionResult = requestResult;
                  }
                  if (permissionResult !== "granted") {
                    presentTakePictureDeniedAlert();
                    return;
                  }
                  try {
                    await Camera.getPhoto({
                      quality: 100,
                      resultType: CameraResultType.Uri,
                      source: CameraSource.Camera,
                    }).then(resolve);
                  } catch {}
                })();
              },
            },
            {
              text: translate("page.edit_profile.action_sheet.camera.cancel"),
              role: "cancel",
            },
          ],
        })
        .then(actionSheet => actionSheet.present());
    });
  }, [
    translate,
    presentFromPhotoBlockedAlert,
    presentFromPhotoDeniedAlert,
    presentTakePictureBlockedAlert,
    presentTakePictureDeniedAlert,
  ]);

  const updateMyCustomerInfoRequest = useUpdateMyCustomerInfoRequest();
  const updateProfilePic = useCallback(
    (newProfilePicUrl: string) => {
      withLoadingModalAsync(async () => {
        try {
          await updateMyCustomerInfoRequest(
            firstName,
            lastName,
            isSubscribed,
            newProfilePicUrl
          );
          setProfilePicUrl(newProfilePicUrl);
        } catch {
          presentLocalizedAlert({
            headerId: "page.edit_profile.update_profile_error",
            buttons: [{ textMessageID: "try_again" }],
          });
        }
      });
    },
    [
      withLoadingModalAsync,
      updateMyCustomerInfoRequest,
      firstName,
      lastName,
      isSubscribed,
      presentLocalizedAlert,
    ]
  );

  const onPickImageClick = useCallback(async () => {
    try {
      const image = await getPhoto();
      const imageUrl = image.path;
      if (imageUrl) {
        const croppedUrl = await crop(imageUrl);
        const data = await Filesystem.readFile({
          path: croppedUrl,
        });
        const resizedData = await resizeImage(
          "data:image/jpeg;base64," + data.data,
          Config.IMAGE_HEIGHT,
          Config.IMAGE_WIDTH
        );
        if (resizedData) {
          updateProfilePic(resizedData);
        }
      }
    } catch (e) {
      if (
        isError(e) &&
        e.message !== "User cancelled photos app" &&
        e.message !== "User cancelled"
      ) {
        presentLocalizedAlert({
          headerId: "page.edit_profile.update_profile_picture_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      }
    }
  }, [updateProfilePic, presentLocalizedAlert, getPhoto]);

  const handleSubscribeNewsletterChange = useCallback(
    (newIsSubscribe: boolean) => {
      withLoadingModalAsync(async () => {
        try {
          await updateMyCustomerInfoRequest(
            firstName,
            lastName,
            newIsSubscribe
          );
          setIsSubscribed(newIsSubscribe);
        } catch {
          presentLocalizedAlert({
            headerId: "page.edit_profile.update_profile_error",
            buttons: [{ textMessageID: "try_again" }],
          });
        }
      });
    },
    [
      withLoadingModalAsync,
      updateMyCustomerInfoRequest,
      firstName,
      lastName,
      presentLocalizedAlert,
    ]
  );

  const presentTheClubMemberProfile = usePresentTheClubMemberProfileBrowser();

  const editProfile = useCallback(
    () =>
      withLoadingModalAsync(async () => {
        if (presentTheClubMemberProfile) {
          await presentTheClubMemberProfile();
          await refreshCustomer.refreshCustomer();
        }
      }),
    [withLoadingModalAsync, presentTheClubMemberProfile, refreshCustomer]
  );

  const handleEditClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      editProfile();
    },
    [editProfile]
  );

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      await refreshCustomer.refreshCustomer();
      e.detail.complete();
    },
    [refreshCustomer]
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="page.account_information.title" />
        }
      />
      <CLContent className={styles.content}>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <Section>
          <ProfilePictureContainer
            className={styles.profilePicture}
            profilePicUrl={profilePicUrl}
            onPickImageClick={onPickImageClick}
            showEditText={false}
          />
          <div className={styles.fields}>
            <Field
              label={translate("page.account_information.name.label")}
              value={`${firstName} ${lastName}`}
            />
            <Field
              label={translate("page.account_information.email.label")}
              value={email}
            />
            <Field
              label={translate("page.account_information.member_id.label")}
              value={memberId || ""}
            />
          </div>
          <button className={styles.editButton} onClick={handleEditClick}>
            <LocalizedText messageID="page.account_information.edit_button_text" />
          </button>
        </Section>
        <Section
          header={translate("page.account_information.newsletter.header")}
        >
          <Checkbox
            checked={isSubscribed}
            onCheckedChange={handleSubscribeNewsletterChange}
          >
            <p className={styles.checkboxText}>
              <LocalizedText messageID="page.account_information.newsletter.checkbox_text" />
            </p>
          </Checkbox>
        </Section>
      </CLContent>
    </>
  );
};

export default AccountInformationPage;

interface SectionProps {
  header?: string;
}

const Section: React.FC<SectionProps> = props => {
  const { header, children } = props;

  return (
    <section className={styles.section}>
      {header ? <h2 className={styles.sectionHeader}>{header}</h2> : null}
      {children ? <div className={styles.sectionBody}>{children}</div> : null}
    </section>
  );
};

interface FieldProps {
  label: string;
  value: string;
}

const Field: React.FC<FieldProps> = props => {
  const { label, value } = props;
  return (
    <div className={styles.field}>
      <p className={styles.fieldLabel}>{label}</p>
      <p className={styles.fieldValue}>{value}</p>
    </div>
  );
};
