import { useApolloClient } from "@apollo/client";
import { useMemo } from "react";
import {
  fetchCampaign,
  fetchCampaignProducts,
  fetchFixedProducts,
  getCampaign,
} from "../api/Campaign";
import { useIntl } from "../i18n/Localization";
import { Campaign } from "../models/Campaign";
import { ProductOverview } from "../models/ProductOverview";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { useFetchResources_v2 } from "./Hooks";

export function useGetCampaign(campaignId: number) {
  const client = useApolloClient();

  return useMemo(() => getCampaign(client, campaignId), [client, campaignId]);
}

export function useFetchCampaign(
  campaignId: number
): [
  ResourcesRequestState<Campaign>,
  () => Promise<Campaign>,
  () => Promise<Campaign>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Campaign,
    () => Promise<Campaign>
  >({
    localCacheProvider: () =>
      fetchCampaign(client, campaignId, locale, "cache-only"),
    remoteResourcesProvider: () =>
      fetchCampaign(client, campaignId, locale, "network-only"),
  });

  return [requestState, fetch, refresh];
}

export function useFetchCampaignProduct(
  campaignId: number
): [
  ResourcesRequestState<ProductOverview[]>,
  () => Promise<ProductOverview[]>,
  () => Promise<ProductOverview[]>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    ProductOverview[],
    () => Promise<ProductOverview[]>
  >({
    localCacheProvider: () =>
      fetchCampaignProducts(client, campaignId, locale, "cache-only"),
    remoteResourcesProvider: () =>
      fetchCampaignProducts(client, campaignId, locale, "network-only"),
  });

  return [requestState, fetch, refresh];
}

export function useFetchFixedProduct(
  campaignId: number
): [
  ResourcesRequestState<ProductOverview[]>,
  () => Promise<ProductOverview[]>,
  () => Promise<ProductOverview[]>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    ProductOverview[],
    () => Promise<ProductOverview[]>
  >({
    localCacheProvider: () =>
      fetchFixedProducts(client, campaignId, locale, "cache-only"),
    remoteResourcesProvider: () =>
      fetchFixedProducts(client, campaignId, locale, "network-only"),
  });

  return [requestState, fetch, refresh];
}
