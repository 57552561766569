import React from "react";
import { IonButton } from "@ionic/react";

import { MessageID } from "../../i18n/translations/type";
import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { NavBar, NavBarCrossButton } from "../NavBar";

interface Props {
  messageID: MessageID;
  className?: string;
  onModalDismiss?: () => void;
  disabled: boolean;
  hasDismissButton?: boolean;
  hasDoneButton?: boolean;
  doneButtonID?: MessageID;
  onModalDone?: () => void;
  doneDisabled?: boolean;
}

const ModalHeader: React.FC<Props> = props => {
  const {
    messageID,
    onModalDismiss,
    disabled,
    className,
    hasDismissButton = true,
    hasDoneButton,
    doneButtonID = "done",
    onModalDone,
    doneDisabled,
  } = props;
  return (
    <NavBar
      toolbarClassName={className}
      headerLeft={
        hasDismissButton ? (
          <NavBarCrossButton onClick={onModalDismiss} disabled={disabled} />
        ) : (
          <IonButton disabled={true} />
        )
      }
      headerTitle={<LocalizedText messageID={messageID} />}
      headerRight={
        hasDoneButton ? (
          <IonButton onClick={onModalDone} disabled={doneDisabled}>
            <span className={styles.doneButton}>
              <LocalizedText messageID={doneButtonID} />
            </span>
          </IonButton>
        ) : (
          <IonButton disabled={true} />
        )
      }
    />
  );
};

export default ModalHeader;
