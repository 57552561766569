import moment from "moment-timezone";
import Config from "../Config";
import { filterNullOrUndefined } from "../utils/array";
import { IndexMap, Override } from "../utils/type";

export interface CampaignForClubMember {
  id: string | null;
  title: string | null;
  image: string | null;
  end: Date | null;
  expired: boolean | null;
}

export type RemoteCampaignForClubMember = Override<
  CampaignForClubMember,
  {
    end: string | null;
  }
>;

export interface CampaignMember {
  campaignId: string | null;
  quota: number | null;
  usedQuota: number | null;
}

export type RemoteCampaignMember = Override<
  CampaignMember,
  {
    quota: string | null;
    usedQuota: string | null;
  }
>;

export interface CampaignOrderItem {
  campaignId: string | null;
  createdAt: Date | null;
}

export type RemoteCampaignOrderItem = Override<
  CampaignOrderItem,
  {
    createdAt: string | null;
  }
>;

export interface RemoteClubMemberCampaign {
  campaigns: (RemoteCampaignForClubMember | null)[] | null;
  campaignMembers: (RemoteCampaignMember | null)[] | null;
  campaignOrderItems: (RemoteCampaignOrderItem | null)[] | null;
}

export interface ClubMemberCampaign {
  campaigns: CampaignForClubMember[];
  campaignMembers: CampaignMember[];
  campaignOrderItems: CampaignOrderItem[];
}

export function transformRemoteCampaignForClubMemberToCampaignForClubMember(
  remote: RemoteCampaignForClubMember
): CampaignForClubMember {
  const { end: _end } = remote;

  const parseDateStr = (maybeDateStr: string): Date | null => {
    // TODO: Pending format confirm
    const m = moment.tz(maybeDateStr, "YYYY-MM-DD HH:mm:ss", Config.TIMEZONE);
    if (m.isValid()) {
      return m.toDate();
    }
    return null;
  };

  return {
    ...remote,
    end: _end ? parseDateStr(_end) : null,
  };
}

export function transformRemoteCampaignMemberToCampaignMember(
  remote: RemoteCampaignMember
): CampaignMember {
  const { quota: _quota, usedQuota: _usedQuota } = remote;
  return {
    ...remote,
    quota: _quota ? parseInt(_quota, 10) : null,
    usedQuota: _usedQuota ? parseInt(_usedQuota, 10) : null,
  };
}

export function transformRemoteCampaignOrderitemToCampaignOrderItem(
  remote: RemoteCampaignOrderItem
): CampaignOrderItem {
  const { createdAt: _createdAt } = remote;

  const parseDateStr = (maybeDateStr: string): Date | null => {
    // TODO: Pending format confirm
    const m = moment.tz(maybeDateStr, "YYYY-MM-DD HH:mm:ss", Config.TIMEZONE);
    if (m.isValid()) {
      return m.toDate();
    }
    return null;
  };

  return {
    ...remote,
    createdAt: _createdAt ? parseDateStr(_createdAt) : null,
  };
}

export function transformRemoteClubMemberCampaign(
  remote: RemoteClubMemberCampaign
): ClubMemberCampaign {
  return {
    campaigns: remote.campaigns
      ? filterNullOrUndefined(remote.campaigns).map(
          transformRemoteCampaignForClubMemberToCampaignForClubMember
        )
      : [],
    campaignMembers: remote.campaignMembers
      ? filterNullOrUndefined(remote.campaignMembers).map(
          transformRemoteCampaignMemberToCampaignMember
        )
      : [],
    campaignOrderItems: remote.campaignOrderItems
      ? filterNullOrUndefined(remote.campaignOrderItems).map(
          transformRemoteCampaignOrderitemToCampaignOrderItem
        )
      : [],
  };
}

// Get the campaign_id where clubMemberCampaign.campaigns[].expired = false,
// then sum the unused quota in clubMemberCampaign.campaignMembers.
// Unused quota = quota - used_quota.
export function getAvailableCount<
  C extends Pick<CampaignForClubMember, "id" | "expired">,
  M extends Pick<CampaignMember, "campaignId" | "quota" | "usedQuota">
>(campaigns: C[], campaignMembers: M[]) {
  return getAvailableCampaigns(campaigns, campaignMembers).length;
}

function spanObject<T>(obj: T, span: number): T[] {
  const res: T[] = [];
  for (let i = 0; i < span; i++) {
    res.push({ ...obj });
  }
  return res;
}

export function getAvailableCampaigns<
  C extends Pick<CampaignForClubMember, "id" | "expired">,
  M extends Pick<CampaignMember, "campaignId" | "quota" | "usedQuota">
>(campaigns: C[], campaignMembers: M[]): C[] {
  const campaignIdMemberMap: IndexMap<string, M> = {};
  for (const member of campaignMembers) {
    if (member.campaignId) {
      campaignIdMemberMap[member.campaignId] = member;
    }
  }

  let res: C[] = [];
  for (const campaign of campaigns) {
    if (campaign.id && !campaign.expired) {
      const campaignMember = campaignIdMemberMap[campaign.id];
      if (campaignMember) {
        const span = campaignMember.quota
          ? campaignMember.usedQuota
            ? campaignMember.quota - campaignMember.usedQuota
            : campaignMember.quota
          : 0;
        res = [...res, ...spanObject(campaign, span)];
      }
    }
  }
  return res;
}

interface ExpiredCampaign<C> {
  type: "expired";
  campaign: C;
}

interface RedeemedCampaign<C, O> {
  type: "redeemed";
  campaign: C;
  orderedItem: O;
}

export type UnavailableCampaign<C, O> =
  | ExpiredCampaign<C>
  | RedeemedCampaign<C, O>;

export function getUnavailableCampaigns<
  C extends CampaignForClubMember,
  M extends CampaignMember,
  O extends CampaignOrderItem
>(
  campaigns: C[],
  campaignMembers: M[],
  campaignOrderItems: O[]
): UnavailableCampaign<C, O>[] {
  const campaignIdMemberMap: IndexMap<string, M> = {};
  for (const member of campaignMembers) {
    if (member.campaignId) {
      campaignIdMemberMap[member.campaignId] = member;
    }
  }

  const campaignIdOrderItemsMap: IndexMap<string, O[]> = {};
  for (const orderItem of campaignOrderItems) {
    if (orderItem.campaignId) {
      const orderItems = campaignIdOrderItemsMap[orderItem.campaignId] || [];
      if (!campaignIdOrderItemsMap[orderItem.campaignId]) {
        campaignIdOrderItemsMap[orderItem.campaignId] = orderItems;
      }
      orderItems.push(orderItem);
    }
  }

  let res: UnavailableCampaign<C, O>[] = [];
  for (const campaign of campaigns) {
    if (campaign.id) {
      const campaignMember = campaignIdMemberMap[campaign.id];
      if (campaignMember) {
        // Count Redeemed First
        const orderedItems = campaignIdOrderItemsMap[campaign.id];
        if (orderedItems) {
          for (const orderedItem of orderedItems) {
            res.push({
              type: "redeemed",
              campaign,
              orderedItem,
            });
          }
        }
        if (campaign.expired) {
          const expiredSpan = campaignMember.quota
            ? campaignMember.usedQuota
              ? campaignMember.quota - campaignMember.usedQuota
              : campaignMember.quota
            : 0;
          res = [
            ...res,
            ...spanObject(campaign, expiredSpan).map<ExpiredCampaign<C>>(c => ({
              type: "expired",
              campaign: c,
            })),
          ];
        }
      }
    }
  }
  return res;
}

// Count

export interface CampaignForClubMemberForCount {
  id: string | null;
  expired: boolean | null;
}

export interface RemoteClubMemberCampaignForCount {
  campaigns: (CampaignForClubMemberForCount | null)[] | null;
  campaignMembers: (RemoteCampaignMember | null)[] | null;
}
