import * as yup from "yup";

export interface ServerVirtualWaitingRoomConfig {
  enableVirtualWaitingRoom: boolean;
  virtualWaitingRoomId: string | null;
}

export const virtualWaitingRoomIdRegExp = new RegExp("(.*)_(.*)");

export const ServerVirtualWaitingRoomConfigGraphQLAttributes = `
enableVirtualWaitingRoom: enable_virtual_waiting_room
virtualWaitingRoomId: virtual_waiting_room_id
`;

export interface ClientMsg {
  default?: string;
  en?: string;
  zh?: string;
}

export interface RequestVirtualWaitingRoomTokenResponseSuccess {
  success: true;
  tokenid: string;
  isActive?: boolean;
  nextPollingDelay: number;
  ttl: number;
}

export interface RequestVirtualWaitingRoomTokenResponseFailed {
  success: false;
  clientMsg?: ClientMsg | null;
}

export type RequestVirtualWaitingRoomTokenResponse =
  | RequestVirtualWaitingRoomTokenResponseSuccess
  | RequestVirtualWaitingRoomTokenResponseFailed;

export interface CheckinResponseSuccess {
  success: true;
  tokenid: string;
  nextPollingDelay: number;
  waitingPos?: number;
  waitingTotal?: number;
  isQueueFull?: boolean;
  isOutOfStock?: boolean;
  ttl: number;
  estimatedWaitingTime?: number;
  entryURL: string | null;
  targetURL: string | null;
  clientMsg?: ClientMsg | null;
  isActive?: boolean;
}

export interface CheckinResponseFailed {
  success: false;
  isQueueFull: boolean;
  isOutOfStock: boolean;
  clientMsg?: ClientMsg | null;
}

export type CheckinResponse = CheckinResponseSuccess | CheckinResponseFailed;

export const ClientMsgSchema = yup.object<ClientMsg>({
  default: yup.string(),
  zh: yup.string(),
  en: yup.string(),
});

export const RequestVirtualWaitingRoomTokenResponseSuccessSchema = yup.object<
  RequestVirtualWaitingRoomTokenResponseSuccess
>({
  success: yup.mixed().required(),
  tokenid: yup.string().required(),
  isActive: yup.boolean(),
  nextPollingDelay: yup.number().required(),
  ttl: yup.number().required(),
});

export const RequestVirtualWaitingRoomTokenResponseFailedSchema = yup.object<
  RequestVirtualWaitingRoomTokenResponseFailed
>({
  success: yup.mixed().required(),
  clientMsg: ClientMsgSchema,
});

export const CheckinResponseSuccessSchema = yup.object<CheckinResponseSuccess>({
  success: yup.mixed().required(),
  tokenid: yup.string().required(),
  nextPollingDelay: yup.number().required(),
  waitingPos: yup.number(),
  waitingTotal: yup.number(),
  isQueueFull: yup.boolean(),
  isOutOfStock: yup.boolean(),
  ttl: yup.number().required(),
  estimatedWaitingTime: yup.number(),
  entryURL: yup.string().nullable(),
  targetURL: yup.string().nullable(),
  clientMsg: ClientMsgSchema,
  isActive: yup.boolean(),
});

export const CheckinResponseFailedSchema = yup.object<CheckinResponseFailed>({
  success: yup.mixed().required(),
  isQueueFull: yup.boolean().required(),
  isOutOfStock: yup.boolean().required(),
  clientMsg: ClientMsgSchema,
});
