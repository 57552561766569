import React from "react";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./FormField.module.scss";

export const FormFieldRow: React.FC = React.memo(props => {
  return <div className={styles.row}>{props.children}</div>;
});

interface Props {
  titleID?: MessageID;
  title?: string;
}

export const FormField: React.FC<Props> = React.memo(props => {
  const { title, titleID, children } = props;
  return (
    <div className={styles.field}>
      <div className={styles.fieldTitle}>
        {titleID ? <LocalizedText messageID={titleID} /> : title}
      </div>
      <div className={styles.fieldContent}>{children}</div>
    </div>
  );
});
