import { Money, areMoneyEqual } from "../../models/Price";
import { ProductOverview } from "../../models/ProductOverview";
import { MessageID } from "../../i18n/translations/type";

export function getCrossedMoney(
  productOfMinValue: Pick<ProductOverview, "minClubPoint" | "priceRange">,
  productOfMaxValue: Pick<ProductOverview, "minClubPoint" | "priceRange">
):
  | { type: "single"; money: Money }
  | { type: "range"; minMoney: Money; maxMoney: Money }
  | null {
  const { minClubPoint } = productOfMinValue;
  if (minClubPoint > 0) {
    return null;
  }
  const { priceRange: minPriceRange } = productOfMinValue;
  const { priceRange: maxPriceRange } = productOfMaxValue;
  const firstPriceRange = minPriceRange ? minPriceRange : maxPriceRange;
  const secondPriceRange =
    firstPriceRange && firstPriceRange !== maxPriceRange ? maxPriceRange : null;
  if (firstPriceRange && secondPriceRange) {
    const {
      minimumPrice: {
        regularPrice: minRegularPrice,
        finalPrice: minFinalPrice,
      },
    } = firstPriceRange;
    const {
      maximumPrice: {
        regularPrice: maxRegularPrice,
        finalPrice: maxFinalPrice,
      },
    } = secondPriceRange;
    if (
      areMoneyEqual(minRegularPrice, minFinalPrice) &&
      areMoneyEqual(maxRegularPrice, maxFinalPrice)
    ) {
      return null;
    }
    if (areMoneyEqual(minRegularPrice, maxRegularPrice)) {
      return { type: "single", money: minRegularPrice };
    }
    return {
      type: "range",
      minMoney: minRegularPrice,
      maxMoney: maxRegularPrice,
    };
  }
  if (firstPriceRange) {
    const {
      minimumPrice: {
        regularPrice: minRegularPrice,
        finalPrice: minFinalPrice,
      },
      maximumPrice: {
        regularPrice: maxRegularPrice,
        finalPrice: maxFinalPrice,
      },
    } = firstPriceRange;
    if (
      areMoneyEqual(minRegularPrice, minFinalPrice) &&
      areMoneyEqual(maxRegularPrice, maxFinalPrice)
    ) {
      return null;
    }
    if (areMoneyEqual(minRegularPrice, maxRegularPrice)) {
      return { type: "single", money: minRegularPrice };
    }
    return {
      type: "range",
      minMoney: minRegularPrice,
      maxMoney: maxRegularPrice,
    };
  }
  return null;
}

export function getListedMoney(
  productOfMinValue: Pick<ProductOverview, "minClubPoint" | "priceRange">,
  productOfMaxValue: Pick<ProductOverview, "minClubPoint" | "priceRange">
):
  | { type: "single"; money: Money }
  | { type: "singleMinCP"; money: Money; minClubPoint: number }
  | { type: "range"; minMoney: Money; maxMoney: Money }
  | { type: "cpOnly"; clubPoint: number }
  | null {
  const { priceRange: minPriceRange, minClubPoint } = productOfMinValue;
  const { priceRange: maxPriceRange } = productOfMaxValue;
  if (minClubPoint > 0 && minPriceRange) {
    const {
      minimumPrice: { finalPrice: minFinalPrice },
    } = minPriceRange;
    if (minFinalPrice.value) {
      return { type: "singleMinCP", money: minFinalPrice, minClubPoint };
    }
    return { type: "cpOnly", clubPoint: minClubPoint };
  }
  const firstPriceRange = minPriceRange ? minPriceRange : maxPriceRange;
  const secondPriceRange =
    firstPriceRange && firstPriceRange !== maxPriceRange ? maxPriceRange : null;
  if (firstPriceRange && secondPriceRange) {
    const {
      minimumPrice: { finalPrice: minFinalPrice },
    } = firstPriceRange;
    const {
      maximumPrice: { finalPrice: maxFinalPrice },
    } = secondPriceRange;
    if (areMoneyEqual(minFinalPrice, maxFinalPrice)) {
      return { type: "single", money: minFinalPrice };
    }
    return {
      type: "range",
      minMoney: minFinalPrice,
      maxMoney: maxFinalPrice,
    };
  }
  if (firstPriceRange) {
    const {
      minimumPrice: { finalPrice: minFinalPrice },
      maximumPrice: { finalPrice: maxFinalPrice },
    } = firstPriceRange;
    if (areMoneyEqual(minFinalPrice, maxFinalPrice)) {
      return { type: "single", money: minFinalPrice };
    }
    return {
      type: "range",
      minMoney: minFinalPrice,
      maxMoney: maxFinalPrice,
    };
  }
  return null;
}

export function getFullRedemptionClubPoint(
  productOfMinValue: Pick<ProductOverview, "minClubPoint" | "priceRange">,
  clubPointConversionRate: number
): number | null {
  const { priceRange: minPriceRange, minClubPoint } = productOfMinValue;
  if (minClubPoint > 0 && minPriceRange) {
    const {
      minimumPrice: { finalPrice: minFinalPrice },
    } = minPriceRange;
    if (minFinalPrice.value) {
      return minClubPoint + minFinalPrice.value * clubPointConversionRate;
    }
  }
  return null;
}

export function getPriceRangeTextMessageID(
  productOfMinValue: Pick<ProductOverview, "minClubPoint" | "priceRange">,
  productOfMaxValue: Pick<ProductOverview, "minClubPoint" | "priceRange">
): MessageID | null {
  const { priceRange: minPriceRange, minClubPoint } = productOfMinValue;
  const { priceRange: maxPriceRange } = productOfMaxValue;
  if (minClubPoint > 0 && productOfMinValue !== productOfMaxValue) {
    return "product.as_low_as";
  }
  const firstPriceRange = minPriceRange ? minPriceRange : maxPriceRange;
  const secondPriceRange =
    firstPriceRange && firstPriceRange !== maxPriceRange ? maxPriceRange : null;
  if (firstPriceRange && secondPriceRange) {
    const {
      minimumPrice: { finalPrice: minFinalPrice },
    } = firstPriceRange;
    const {
      maximumPrice: { finalPrice: maxFinalPrice },
    } = secondPriceRange;
    if (!areMoneyEqual(minFinalPrice, maxFinalPrice)) {
      return "product.from";
    }
    return "product.as_low_as";
  }
  if (firstPriceRange) {
    const {
      minimumPrice: { finalPrice: minFinalPrice },
      maximumPrice: { finalPrice: maxFinalPrice },
    } = firstPriceRange;
    if (!areMoneyEqual(minFinalPrice, maxFinalPrice)) {
      return "product.from";
    }
  }
  return null;
}

export function getManufacturerSuggestedRetailPriceMoney(
  productOfMinValue: Pick<
    ProductOverview,
    "minClubPoint" | "priceRange" | "manufacturerSuggestedRetailPrice"
  >
): Money | null {
  const {
    priceRange: minPriceRange,
    minClubPoint,
    manufacturerSuggestedRetailPrice,
  } = productOfMinValue;
  if (minClubPoint > 0 && minPriceRange && manufacturerSuggestedRetailPrice) {
    const manufacturerSuggestedRetailPriceNumber:
      | number
      | null = manufacturerSuggestedRetailPrice
      ? parseFloat(manufacturerSuggestedRetailPrice)
      : null;
    const manufacturerSuggestedRetailPriceMoney: Money | null =
      manufacturerSuggestedRetailPriceNumber &&
      !isNaN(manufacturerSuggestedRetailPriceNumber)
        ? {
            currency: "HKD",
            value: manufacturerSuggestedRetailPriceNumber,
          }
        : null;
    return manufacturerSuggestedRetailPriceMoney;
  }
  return null;
}
