import React from "react";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./LinkSocialAccountWidget.module.scss";

interface LinkSocialAccountToggleSwitchProps {
  className?: string;
  linked: boolean;
  onToggleClick: () => void;
  disabled: boolean;
}

const LinkSocialAccountToggleSwitch: React.FC<
  LinkSocialAccountToggleSwitchProps
> = props => {
  const { className, linked, onToggleClick, disabled } = props;
  return (
    <div
      className={cn(
        styles.toggleSwitch,
        className,
        linked && styles.linked,
        disabled && styles.disabled
      )}
      onClick={onToggleClick}
    >
      <div className={styles.linkText}>
        <LocalizedText messageID="link_switch.link" />
      </div>
      <div className={styles.slider} />
      <div className={styles.unlinkText}>
        <LocalizedText messageID="link_switch.unlink" />
      </div>
    </div>
  );
};

interface LinkSocialAccountWidgetProps {
  type: "club" | "google" | "facebook";
  className?: string;
  email: string | null;
  onToggleClick: () => void;
  disabled: boolean;
  additionalInfo?: React.ReactNode | null;
}

const LinkSocialAccountWidget: React.FC<
  LinkSocialAccountWidgetProps
> = props => {
  const {
    className,
    type,
    email,
    onToggleClick,
    disabled,
    additionalInfo,
  } = props;
  return (
    <div className={cn(styles.widgetContainer, className)}>
      <div className={styles.socialAccountInfo}>
        <div
          className={cn(
            styles.socialImage,
            type === "club" ? styles.theClub : null,
            type === "facebook" && styles.facebook,
            type === "google" && styles.google
          )}
        />
        <div className={styles.email}>{email}</div>
        {additionalInfo ? (
          <div className={styles.additionalInfo}>{additionalInfo}</div>
        ) : null}
      </div>
      <div className={styles.switchContainer}>
        <LinkSocialAccountToggleSwitch
          linked={email != null}
          disabled={disabled}
          onToggleClick={onToggleClick}
        />
      </div>
    </div>
  );
};

export default LinkSocialAccountWidget;
