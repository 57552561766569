import React from "react";
import cn from "classnames";

import { PrimaryButton } from "../Button";
import styles from "./styles.module.scss";

interface Props {
  className?: string;
  onClick: () => void;
}

const FilterButton: React.FC<Props> = props => {
  const { className, onClick } = props;
  return (
    <PrimaryButton className={cn(styles.button, className)} onClick={onClick}>
      <div className={styles.icon} />
    </PrimaryButton>
  );
};

export default FilterButton;
