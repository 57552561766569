import * as yup from "yup";
import moment from "moment";
import localforage from "localforage";
import { Plugins } from "@capacitor/core";
import { Locale, stringToLocale } from "./i18n/locale";
import { StoreConfig, StoreConfigSchema } from "./models/StoreConfig";
import { SearchTerm, SearchTermSchema } from "./models/Search";
import { ArticleCategory, ArticleCategorySchema } from "./models/Article";
import {
  AppConfig,
  AppConfigSchema,
  UrlRedirectSchema,
} from "./models/AppConfig";
import { IndexMap } from "./utils/type";
import { EntityUrlRedirect } from "./models/EntityUrl";
import { Customer } from "./models/Customer";

const { Storage: DeprecatingStorage } = Plugins;

interface Storage {
  getItem<T>(key: string): Promise<T | null>;
  setItem<T>(key: string, value: T): Promise<T>;
  remove(key: string): Promise<void>;
}

class StorageImpl implements Storage {
  private backend = (() => {
    localforage.config({
      driver: [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ],
    });
    return localforage;
  })();

  public async getItem<T>(key: string): Promise<T | null> {
    return localforage.getItem<T>(key);
  }

  public setItem<T>(key: string, value: T) {
    return this.backend.setItem(key, value);
  }

  public remove(key: string) {
    return this.backend.removeItem(key);
  }
}

const Storage: Storage = new StorageImpl();

export default Storage;

export enum StorageKey {
  displayLanguage = "display_language",
  appConfig = "appConfig",
  customer = "customer",
  loginSignupMethod = "loginSignupMethod",
  urlRedirect = "urlRedirect",
  storeConfig = "storeConfig",
  cartID = "cartID",
  categoryTree = "categoryTree",
  accessToken = "accessToken",
  recentSearch = "recentSearch",
  articleCategories = "articleCategories",
  pushNotificationEnabled = "pushNotificationEnabled",
  pnDeviceToken = "pnDeviceToken",
  pushNotificationMessagesPageLastRead = "pushNotificationMessagesPageLastRead",
}

async function setItem(key: StorageKey, value: string) {
  await Storage.setItem(key, value);
}

async function getItem(key: StorageKey): Promise<string | null> {
  return Storage.getItem<string>(key);
}

async function clearItem(key: StorageKey): Promise<void> {
  return Storage.remove(key);
}

export async function setDisplayLanguage(locale: Locale) {
  await Promise.all([
    setItem(StorageKey.displayLanguage, locale),
    DeprecatingStorage.set({ key: StorageKey.displayLanguage, value: locale }),
  ]);
}

export async function getDisplayLanguage(): Promise<Locale | null> {
  const localeValue = await DeprecatingStorage.get({
    key: StorageKey.displayLanguage,
  });
  if (localeValue.value != null) {
    return stringToLocale(localeValue.value);
  }
  return null;
}

function getItemKey(
  locale: Locale,
  storageKey: StorageKey,
  params: IndexMap<string, any> = {}
): StorageKey {
  let key = `${locale}_${storageKey}`;
  if (Object.keys(params).length) {
    const paramPart = Object.keys(params)
      .sort()
      .map(_key => `${_key}:${params[_key]}`)
      .join("_");
    key = `${key}_${paramPart}`;
  }
  return key as StorageKey;
}

export async function setCartID(cartID: string | null) {
  if (cartID != null) {
    await setItem(StorageKey.cartID, cartID);
  } else {
    await clearItem(StorageKey.cartID);
  }
}

export async function getCartID(): Promise<string | null> {
  return getItem(StorageKey.cartID);
}

export async function setStoreConfig(storeConfig: StoreConfig, locale: Locale) {
  return setItem(
    getItemKey(locale, StorageKey.storeConfig),
    JSON.stringify(storeConfig)
  );
}

export async function getStoreConfig(locale: Locale) {
  const value = await getItem(getItemKey(locale, StorageKey.storeConfig));
  if (value == null) {
    return null;
  }

  try {
    return StoreConfigSchema.validateSync(JSON.parse(value));
  } catch {
    clearItem(getItemKey(locale, StorageKey.storeConfig));
    return null;
  }
}

export async function storeAppConfig(
  appConfig: AppConfig,
  locale: Locale
): Promise<void> {
  return setItem(
    getItemKey(locale, StorageKey.appConfig),
    JSON.stringify(appConfig)
  );
}

export async function getAppConfig(locale: Locale): Promise<AppConfig | null> {
  const value = await getItem(getItemKey(locale, StorageKey.appConfig));
  if (value == null) {
    return null;
  }

  try {
    return AppConfigSchema.validateSync(JSON.parse(value));
  } catch {
    clearItem(getItemKey(locale, StorageKey.appConfig));
    return null;
  }
}

export async function storeUrlRedirect(
  urlRedirect: EntityUrlRedirect[],
  locale: Locale
): Promise<void> {
  return setItem(
    getItemKey(locale, StorageKey.urlRedirect),
    JSON.stringify(urlRedirect)
  );
}

export async function getUrlRedirect(
  locale: Locale
): Promise<EntityUrlRedirect[] | null> {
  const value = await getItem(getItemKey(locale, StorageKey.urlRedirect));
  if (value == null) {
    return null;
  }

  try {
    return UrlRedirectSchema.validateSync(JSON.parse(value));
  } catch {
    clearItem(getItemKey(locale, StorageKey.urlRedirect));
    return null;
  }
}

export async function getAccessToken(): Promise<string | null> {
  const { value: accessToken } = await DeprecatingStorage.get({
    key: StorageKey.accessToken,
  });
  return accessToken;
}

export async function setAccessTokenToStorage(accessToken: string) {
  return Promise.all([
    setItem(StorageKey.accessToken, accessToken),
    DeprecatingStorage.set({ key: StorageKey.accessToken, value: accessToken }),
  ]);
}

export async function clearAccessToken() {
  return Promise.all([
    clearItem(StorageKey.accessToken),
    DeprecatingStorage.remove({ key: StorageKey.accessToken }),
  ]);
}

export async function storeRecentlySearches(recentSearches: SearchTerm[]) {
  return Promise.all([
    setItem(StorageKey.recentSearch, JSON.stringify(recentSearches)),
    DeprecatingStorage.set({
      key: StorageKey.recentSearch,
      value: JSON.stringify(recentSearches),
    }),
  ]);
}

export async function getRecentlySearches(): Promise<SearchTerm[]> {
  const { value } = await DeprecatingStorage.get({
    key: StorageKey.recentSearch,
  });
  if (value == null) {
    return [];
  }
  try {
    const searches = JSON.parse(value);
    return await yup
      .array()
      .of(SearchTermSchema)
      .validate(searches);
  } catch {
    return [];
  }
}

export async function storeAllArticleCategories(
  articleCategories: ArticleCategory[],
  locale: Locale
) {
  return setItem(
    getItemKey(locale, StorageKey.articleCategories),
    JSON.stringify(articleCategories)
  );
}

export async function getAllArticleCategories(
  locale: Locale
): Promise<ArticleCategory[] | null> {
  const value = await getItem(getItemKey(locale, StorageKey.articleCategories));
  if (value == null) {
    return null;
  }
  try {
    const rawCategories = JSON.parse(value);
    return await yup
      .array()
      .of(ArticleCategorySchema)
      .validate(rawCategories);
  } catch {
    return null;
  }
}

export async function setCustomer(customer: Customer) {
  return Promise.all([
    setItem(StorageKey.customer, JSON.stringify(customer)),
    DeprecatingStorage.set({
      key: StorageKey.customer,
      value: JSON.stringify(customer),
    }),
  ]);
}

export async function getCustomer(): Promise<Customer | null> {
  const { value: customerString } = await DeprecatingStorage.get({
    key: StorageKey.customer,
  });
  if (!customerString) {
    return null;
  }
  try {
    const customer = JSON.parse(customerString);
    return customer;
  } catch {
    return null;
  }
}

export async function clearCustomer() {
  return Promise.all([
    clearItem(StorageKey.customer),
    DeprecatingStorage.remove({
      key: StorageKey.customer,
    }),
  ]);
}

export async function setLoginSignupMethod(
  loginSignupMethod: LoginSignUpMethod
) {
  return setItem(StorageKey.loginSignupMethod, loginSignupMethod);
}

export async function getLoginSignupMethod(): Promise<LoginSignUpMethod | null> {
  const value = await getItem(StorageKey.loginSignupMethod);
  if (!value) {
    return null;
  }
  switch (value) {
    case "The Club":
      return "The Club";
    case "Email":
      return "Email";
    case "Facebook":
      return "Facebook";
    case "Google":
      return "Google";
    default:
      return null;
  }
}

export async function hasEnablePushNotificationEntry(): Promise<boolean> {
  return !!(await DeprecatingStorage.get({
    key: StorageKey.pushNotificationEnabled,
  })).value;
}

export async function setEnablePushNotification(enable: boolean) {
  return Promise.all([
    setItem(StorageKey.pushNotificationEnabled, `${enable}`),
    DeprecatingStorage.set({
      key: StorageKey.pushNotificationEnabled,
      value: `${enable}`,
    }),
  ]);
}

export async function getEnablePushNotification(): Promise<boolean> {
  return (
    (await DeprecatingStorage.get({ key: StorageKey.pushNotificationEnabled }))
      .value === "true"
  );
}

export async function deleteEnablePushNotification() {
  return Promise.all([
    clearItem(StorageKey.pushNotificationEnabled),
    DeprecatingStorage.remove({ key: StorageKey.pushNotificationEnabled }),
  ]);
}

export async function setPNDeviceToken(deviceToken: string) {
  return Promise.all([
    setItem(StorageKey.pnDeviceToken, deviceToken),
    DeprecatingStorage.set({
      key: StorageKey.pnDeviceToken,
      value: deviceToken,
    }),
  ]);
}

export async function getPNDeviceToken(): Promise<string | null> {
  const { value: pnDeviceToken } = await DeprecatingStorage.get({
    key: StorageKey.pnDeviceToken,
  });
  if (!pnDeviceToken) {
    return null;
  }
  return pnDeviceToken;
}

export async function deletePNDeviceToken() {
  return Promise.all([
    clearItem(StorageKey.pnDeviceToken),
    DeprecatingStorage.remove({ key: StorageKey.pnDeviceToken }),
  ]);
}

export async function getPushNotificationMessagesPageLastRead(): Promise<Date | null> {
  const { value: dateString } = await DeprecatingStorage.get({
    key: StorageKey.pushNotificationMessagesPageLastRead,
  });
  if (!dateString) {
    return null;
  }
  return moment(dateString).toDate();
}

export async function setPushNotificationMessagesPageLastRead(date: Date) {
  return Promise.all([
    setItem(
      StorageKey.pushNotificationMessagesPageLastRead,
      moment(date).format()
    ),
    DeprecatingStorage.set({
      key: StorageKey.pushNotificationMessagesPageLastRead,
      value: moment(date).format(),
    }),
  ]);
}

export async function deletePushNotificationMessagesPageLastRead() {
  return Promise.all([
    clearItem(StorageKey.pushNotificationMessagesPageLastRead),
    DeprecatingStorage.remove({
      key: StorageKey.pushNotificationMessagesPageLastRead,
    }),
  ]);
}
