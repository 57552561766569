import React, { useMemo, useCallback, useState } from "react";
import { IonImg } from "@ionic/react";
import cn from "classnames";

import { ProductReview } from "../../models/ProductReview";
import { LocalizedDateTime } from "../../i18n/Localization";
import { Locale } from "../../i18n/locale";

import ProductRatingScore from "../ProductDetailPage/ProductRatingScore.lazy";

import styles from "./ProductReviewCell.module.scss";

import placeholderImg from "../../resources/account-profile-ph.svg";

interface Props {
  productReview: ProductReview;
}

const dateFormat = {
  [Locale.en]: "D MMM YYYY",
  [Locale.zhHant]: "D/M/YYYY",
};

const ProductReviewCell: React.FC<Props> = React.memo(props => {
  const { productReview } = props;
  const {
    createdAt,
    customerProfilePicture,
    detail,
    nickname,
    rating,
  } = productReview;

  const [profilePicture, setProfilePicture] = useState(
    customerProfilePicture || placeholderImg
  );
  const [
    profilePictureIsPlaceholder,
    setProfilePictureIsPlaceholder,
  ] = useState(false);
  const handleImageLoadError = useCallback(() => {
    setProfilePicture(placeholderImg);
    setProfilePictureIsPlaceholder(true);
  }, []);

  const theRating = useMemo(() => {
    if (rating && rating.length > 0) {
      return rating[0];
    }
    return null;
  }, [rating]);

  return (
    <div className={styles.container}>
      <div className={styles.reviewHeader}>
        <IonImg
          className={cn(styles.profileImage, {
            [styles.profileImageIsPlaceholder]: profilePictureIsPlaceholder,
          })}
          src={profilePicture}
          onIonError={handleImageLoadError}
          alt=""
        />
        <div className={styles.reviewerInfo}>
          {nickname ? <p className={styles.reviewerName}>{nickname}</p> : null}
          <div className={styles.ratingDateRow}>
            <div className={styles.rating}>
              <ProductRatingScore
                size="small"
                overallReviewScore={theRating ? theRating.percent : 0}
              />
            </div>
            {createdAt ? (
              <div className={styles.postDate}>
                <LocalizedDateTime datetime={createdAt} format={dateFormat} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {detail ? <p className={styles.detail}>{detail}</p> : null}
    </div>
  );
});

export default ProductReviewCell;
