import * as yup from "yup";

export type SearchTerm = string;

export const SearchTermSchema = yup.string();

export interface SearchAutoSuggestion {
  name: string;
  url: string;
  redirectUrl: string | null;
}

export const SearchAutoSuggestionGraphQLAttributes = `
  name
  url
  redirectUrl: redirect_url
`;
