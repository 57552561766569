import React from "react";
import { LocalizedText, MessageArgs } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";
import styles from "./styles.module.scss";

interface Props {
  messageID: MessageID;
  messageArgs?: MessageArgs;
}

const EmptyView: React.FC<Props> = props => {
  const { messageID, messageArgs, children } = props;
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <LocalizedText messageID={messageID} messageArgs={messageArgs} />
      </h1>
      {children ? <div>{children}</div> : null}
    </div>
  );
};

export default EmptyView;

export const FullContentEmptyView: React.FC<Props> = props => {
  return (
    <div className={styles.fullContentContainer}>
      <EmptyView {...props} />
    </div>
  );
};
