import { TypePolicies, TypePolicy } from "@apollo/client";

import possibleTypes from "./possibleTypes.json";

const ProductsTypePolicy: TypePolicy = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

const productInterfaceTypePolicies: TypePolicies = {
  ProductInterface: {
    keyFields: ["sku"],
  },
  ...possibleTypes.ProductInterface.reduce<TypePolicies>((prev, type) => {
    return {
      ...prev,
      [type]: {
        keyFields: ["sku"],
        fields: {
          variants: {
            merge(
              existing: { product: { __ref: string } }[] | undefined,
              incoming: { product: { __ref: string } }[],
              { mergeObjects }
            ) {
              if (!existing) {
                return incoming;
              }
              const existingSkuMap: {
                [key in string]: { product: { __ref: string } };
              } = {};
              for (const item of existing) {
                const {
                  product: { __ref },
                } = item;
                existingSkuMap[__ref] = item;
              }
              const res: { product: { __ref: string } }[] = [];
              for (const item of incoming) {
                const {
                  product: { __ref },
                } = item;
                res.push(mergeObjects(existingSkuMap[__ref], item));
              }
              return res;
            },
          },
        },
      },
    };
  }, {}),
};

const appConfigTypePolicies: TypePolicies = {
  AppConfig: {
    merge() {
      // Do not cache app config in memory since it is locale dependent and already stored in storage manually
      return undefined;
    },
  },
};

const CountryTypePolicy: TypePolicy = {
  keyFields: false,
};

const RegionTypePolicy: TypePolicy = {
  keyFields: false,
};

const CityTypePolicy: TypePolicy = {
  keyFields: false,
};

const CampaignOutputPolicy: TypePolicy = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

const AmMostviewedBundlePacksTypePolicy: TypePolicy = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

const FooterCmsLinkGroupTypePolicy: TypePolicy = {
  keyFields: ["text"],
};

const CampaignMemberTypePolicy: TypePolicy = {
  keyFields: ["campaign_id"],
};

const CampaignOrderItemTypePolicy: TypePolicy = {
  keyFields: false,
};

const ClubMemberCampaignOutputTypePolicy: TypePolicy = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

const typePolicies: TypePolicies = {
  Products: ProductsTypePolicy,
  ...productInterfaceTypePolicies,
  ...appConfigTypePolicies,
  SalesOrder: {
    keyFields: ["entity_id"],
    fields: {
      items: {
        merge(
          _existing: { simple_sku: string | null; sku: string }[] | undefined,
          incoming: { simple_sku: string | null; sku: string }[],
          { mergeObjects }
        ) {
          const existing = _existing || [];
          const skus = incoming.map(i => i.simple_sku || i.sku);
          const incomingSkuMap: {
            [sku in string]: { simple_sku: string | null; sku: string };
          } = {};
          for (const item of incoming) {
            const _sku = item.simple_sku || item.sku;
            incomingSkuMap[_sku] = item;
          }
          for (const item of existing) {
            const { simple_sku, sku } = item;
            const _sku = simple_sku || sku;
            if (incomingSkuMap[_sku]) {
              incomingSkuMap[_sku] = mergeObjects(item, incomingSkuMap[_sku]);
            }
          }
          return skus.map(sku => incomingSkuMap[sku]);
        },
      },
    },
  },
  Country: CountryTypePolicy,
  Region: RegionTypePolicy,
  City: CityTypePolicy,
  CampaignOutput: CampaignOutputPolicy,
  AmMostviewedBundlePacks: AmMostviewedBundlePacksTypePolicy,
  FooterCmsLinkGroup: FooterCmsLinkGroupTypePolicy,
  CampaignMember: CampaignMemberTypePolicy,
  CampaignOrderItem: CampaignOrderItemTypePolicy,
  ClubMemberCampaignOutput: ClubMemberCampaignOutputTypePolicy,
};

export default typePolicies;
