import Config from "../Config";
import { isValidDate } from "./Date";

export function enableSmartProtectionByTier() {
  if (
    isValidDate(Config.ENABLE_CLUB_PROTECTION_BY_TIER_AFTER) &&
    Config.ENABLE_CLUB_PROTECTION_BY_TIER_AFTER <= new Date()
  ) {
    return true;
  }
  return false;
}
