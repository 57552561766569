import { useContext, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { ResourcesRequestState } from "../models/ResourcesRequestState";
import {
  CustomerSubscription,
  CustomerSubscriptionId,
} from "../models/CustomerSubscription";
import { useIntl } from "../i18n/Localization";
import {
  getCustomerSubscriptions,
  getCustomerSubscription,
  cancelSubscription,
} from "../api/GraphQL";

import { RepositoryContext } from "./State";
import { useFetchResources_v2 } from "./Hooks";

export function useGetCustomerSubscriptions(): [
  ResourcesRequestState<CustomerSubscription[]>,
  () => Promise<CustomerSubscription[]>,
  () => Promise<CustomerSubscription[]>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch } = useContext(RepositoryContext);
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    CustomerSubscription[],
    () => Promise<CustomerSubscription[]>
  >({
    localCacheProvider: async () => {
      const customerSubscriptions = await getCustomerSubscriptions(
        client,
        locale,
        "cache-only"
      );
      dispatch({
        type: "UpdateCustomerSubscriptions",
        customerSubscriptions,
      });
      return customerSubscriptions;
    },
    remoteResourcesProvider: async () => {
      const customerSubscriptions = await getCustomerSubscriptions(
        client,
        locale,
        "network-only"
      );
      dispatch({
        type: "UpdateCustomerSubscriptions",
        customerSubscriptions,
      });
      return customerSubscriptions;
    },
  });
  return [requestState, fetch, refresh];
}

export function useGetCustomerSubscriptionFromMemory(
  subscriptionId: CustomerSubscriptionId
): () => CustomerSubscription | null {
  const {
    state: { customerSubscriptionMap },
  } = useContext(RepositoryContext);
  return useCallback(() => {
    return customerSubscriptionMap[subscriptionId] || null;
  }, [customerSubscriptionMap, subscriptionId]);
}

export function useGetCustomerSubscription(
  subscriptionId: CustomerSubscriptionId
): [
  ResourcesRequestState<CustomerSubscription>,
  () => Promise<CustomerSubscription>,
  () => Promise<CustomerSubscription>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch } = useContext(RepositoryContext);
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    CustomerSubscription,
    () => Promise<CustomerSubscription>
  >({
    localCacheProvider: async () => {
      const customerSubscription = await getCustomerSubscription(
        client,
        locale,
        subscriptionId,
        "cache-only"
      );
      dispatch({
        type: "UpdateCustomerSubscription",
        customerSubscription,
      });
      return customerSubscription;
    },
    remoteResourcesProvider: async () => {
      const customerSubscription = await getCustomerSubscription(
        client,
        locale,
        subscriptionId,
        "network-only"
      );
      dispatch({
        type: "UpdateCustomerSubscription",
        customerSubscription,
      });
      return customerSubscription;
    },
  });
  return [requestState, fetch, refresh];
}

export function useCancelSubscription(): [
  ResourcesRequestState<boolean>,
  (
    subscriptionPayment: string,
    subscriptionId: CustomerSubscriptionId
  ) => Promise<boolean>
] {
  const client = useApolloClient();
  const [requestState, { call }] = useFetchResources_v2<
    boolean,
    (
      subscriptionPayment: string,
      subscriptionId: CustomerSubscriptionId
    ) => Promise<boolean>
  >({
    remoteResourcesProvider: (
      subscriptionPayment: string,
      subscriptionId: CustomerSubscriptionId
    ) => cancelSubscription(client, subscriptionPayment, subscriptionId),
  });
  return [requestState, call];
}
