import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";

import Config from "../Config";
import { RatingCode, RatingOption, RatingVote } from "../models/ProductReview";
import {
  getResources,
  isRequestLoading,
} from "../models/ResourcesRequestState";
import {
  useFetchRatingCodes,
  useAddProductReview,
  useAddProductReviewWithName,
} from "../repository/ProductReviewRepository";
import { parseGraphQLError } from "../api/GraphQL";
import { fromMaybe } from "../utils/type";

import LoadingModalProvider, {
  LoadingModalContext,
} from "./LoadingModalProvider";
import { withProviders } from "./Provider";
import AddProductReviewModal from "./AddProductReviewModal/lazy";
import AddProductReviewModalV2 from "./AddProductReviewModal/v2";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";

interface PreparationStateInitial {
  type: "initial";
}
const PreparationStateInitial: PreparationStateInitial = {
  type: "initial",
};

interface PreparationStatePreparing {
  type: "preparing";
}
const PreparationStatePreparing: PreparationStatePreparing = {
  type: "preparing",
};

interface PreparationStatePrepared {
  type: "prepared";
  ratingCodes: RatingCode[];
}
function PreparationStatePrepared(
  ratingCodes: RatingCode[]
): PreparationStatePrepared {
  return {
    type: "prepared",
    ratingCodes,
  };
}

type PreparationState =
  | PreparationStateInitial
  | PreparationStatePreparing
  | PreparationStatePrepared;

interface AddProductReviewModalContext {
  show: (productId: number) => void;
}

export const AddProductReviewModalContext = React.createContext<
  AddProductReviewModalContext
>(null as any);

const AddProductReviewModalProvider: React.FC = props => {
  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const [isOpen, setIsOpen] = useState(false);

  const [_productId, setProductId] = useState<number | null>(null);

  const [
    fetchRatingCodesRequestState,
    fetchRatingCodes,
  ] = useFetchRatingCodes();

  const handleRequestDismiss = useCallback(() => {
    setIsOpen(false);
  }, []);

  const [
    addProductReviewRequestState,
    addProductReview,
  ] = useAddProductReview();

  const [
    addProductReviewWithNameRequestState,
    addProductReviewWithName,
    addProductReviewWithNameValidationError,
    resetAddProductReviewWithNameValidationError,
  ] = useAddProductReviewWithName();

  const presentLocalizedAlertForMaybeGraphQLError = useCallback(
    async (error: any) => {
      return new Promise<void>(resolve => {
        const errorMessage = parseGraphQLError(error);
        if (errorMessage) {
          presentLocalizedAlert({
            message: errorMessage,
            buttons: [
              {
                textMessageID: "alert.button.ok",
                handler: () => {
                  resolve();
                },
              },
            ],
          });
        } else {
          if (!Config.ENABLE_SET_NAME_ON_RODUCT_REVIEW) {
            presentLocalizedAlert({
              messageId: "error.unknown",
              buttons: [
                {
                  textMessageID: "alert.button.ok",
                  handler: () => {
                    resolve();
                  },
                },
              ],
            });
          }
        }
      });
    },
    [presentLocalizedAlert]
  );

  const preparationState = useMemo<PreparationState>(() => {
    const ratingCodes = getResources(fetchRatingCodesRequestState);
    if (!ratingCodes) {
      if (isRequestLoading(fetchRatingCodesRequestState)) {
        return PreparationStatePreparing;
      }
      return PreparationStateInitial;
    }
    return PreparationStatePrepared(ratingCodes);
  }, [fetchRatingCodesRequestState]);

  const ratingCodes = useMemo<RatingCode[]>(() => {
    const _ratingCodes = getResources(fetchRatingCodesRequestState);
    if (!_ratingCodes) {
      return [];
    }
    return _ratingCodes;
  }, [fetchRatingCodesRequestState]);

  const isModalOpen = useMemo(() => {
    return preparationState.type === "prepared" && isOpen;
  }, [preparationState, isOpen]);

  useEffect(() => {
    if (preparationState.type === "preparing") {
      showLoadingModal();
    } else {
      hideLoadingModal();
    }
  }, [preparationState, showLoadingModal, hideLoadingModal]);

  const submit = useCallback(
    async (
      selectedOptions: { [key in number]: RatingOption | null },
      name: string,
      detail: string
    ) => {
      if (_productId == null) {
        return;
      }
      const ratingIds = Object.keys(selectedOptions);
      const ratingVotes: RatingVote[] = [];
      for (const ratingId_ of ratingIds) {
        const ratingId = parseInt(`${ratingId_}`, 10);
        const selectedOption = selectedOptions[ratingId];
        if (selectedOption != null && selectedOption.optionId != null) {
          ratingVotes.push({ ratingId, optionId: selectedOption.optionId });
        }
      }
      try {
        if (Config.ENABLE_SET_NAME_ON_RODUCT_REVIEW) {
          await addProductReviewWithName(ratingVotes, _productId, name, detail);
        } else {
          await addProductReview(ratingVotes, _productId, detail);
        }
      } catch (e) {
        await presentLocalizedAlertForMaybeGraphQLError(e);
        return;
      }
      presentLocalizedAlert({
        headerId: "add_product_review.success.title",
        buttons: [
          {
            textMessageID: "alert.button.ok",
            handler: () => {
              setIsOpen(false);
            },
          },
        ],
        onDidDismiss: () => {
          setIsOpen(false);
        },
      });
    },
    [
      addProductReviewWithName,
      addProductReview,
      _productId,
      presentLocalizedAlertForMaybeGraphQLError,
      presentLocalizedAlert,
    ]
  );

  const handleAddProductReviewSubmit = useCallback(
    (
      selectedOptions: { [key in number]: RatingOption | null },
      detail: string
    ) => submit(selectedOptions, "", detail),
    [submit]
  );
  const handleAddProductReviewSubmitWithName = submit;

  const showWithoutName = useCallback(
    async (productId: number) => {
      setProductId(productId);
      setIsOpen(true);
      const _ratingCodes = getResources(fetchRatingCodesRequestState);
      if (!_ratingCodes) {
        try {
          await fetchRatingCodes();
        } catch (e) {
          await presentLocalizedAlertForMaybeGraphQLError(e);
          setIsOpen(false);
        }
      }
    },
    [
      fetchRatingCodes,
      fetchRatingCodesRequestState,
      presentLocalizedAlertForMaybeGraphQLError,
    ]
  );

  const showWithName = useCallback(
    async (productId: number) => {
      setProductId(productId);
      setIsOpen(true);
      resetAddProductReviewWithNameValidationError();
      const _ratingCodes = getResources(fetchRatingCodesRequestState);
      if (!_ratingCodes) {
        try {
          await fetchRatingCodes();
        } catch (e) {
          await presentLocalizedAlertForMaybeGraphQLError(e);
          setIsOpen(false);
        }
      }
    },
    [
      resetAddProductReviewWithNameValidationError,
      fetchRatingCodes,
      fetchRatingCodesRequestState,
      presentLocalizedAlertForMaybeGraphQLError,
    ]
  );

  const show = useCallback(
    (productId: number) => {
      if (Config.ENABLE_SET_NAME_ON_RODUCT_REVIEW) {
        showWithName(productId);
      } else {
        showWithoutName(productId);
      }
    },
    [showWithoutName, showWithName]
  );

  const value = useMemo(
    () => ({
      show,
    }),
    [show]
  );

  return (
    <>
      <AddProductReviewModalContext.Provider value={value}>
        {props.children}
      </AddProductReviewModalContext.Provider>
      {Config.ENABLE_SET_NAME_ON_RODUCT_REVIEW ? (
        <AddProductReviewModalV2
          isModalOpen={isModalOpen}
          onRequestDismiss={handleRequestDismiss}
          ratingCodes={ratingCodes}
          onSubmit={handleAddProductReviewSubmitWithName}
          isSubmitDisabled={isRequestLoading(
            addProductReviewWithNameRequestState
          )}
          isSubmitLoading={isRequestLoading(
            addProductReviewWithNameRequestState
          )}
          ratingStarErrorMessage={
            fromMaybe({}, addProductReviewWithNameValidationError).ratingVote ||
            null
          }
          nameErrorMessage={
            fromMaybe({}, addProductReviewWithNameValidationError).name || null
          }
          detailErrorMessage={
            fromMaybe({}, addProductReviewWithNameValidationError).detail ||
            null
          }
        />
      ) : (
        <AddProductReviewModal
          isModalOpen={isModalOpen}
          onRequestDismiss={handleRequestDismiss}
          ratingCodes={ratingCodes}
          onSubmit={handleAddProductReviewSubmit}
          isSubmitDisabled={isRequestLoading(addProductReviewRequestState)}
          isSubmitLoading={isRequestLoading(addProductReviewRequestState)}
        />
      )}
    </>
  );
};

export default withProviders(
  AddProductReviewModalProvider,
  LoadingModalProvider
);
