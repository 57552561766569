import React from "react";

import { PrimaryButton } from "../Button";
import { LocalizedText } from "../../i18n/Localization";
import Lottie from "../Lottie.lazy";

import { getPathForHomePage } from "../../navigation/routes";

import styles from "./WishlistEmptyView.module.scss";

interface Props {
  playAnimation: boolean;
}

const WishlistEmptyView: React.FC<Props> = props => {
  const { playAnimation } = props;
  return (
    <div>
      <Lottie
        className={styles.animation}
        play={playAnimation}
        loop={true}
        autoplay={true}
        animationData={require("../../resources/animation/empty-cart-or-wishlist.json")}
      />
      <h1 className={styles.title}>
        <LocalizedText messageID="page.likes.wish_list.empty.title" />
      </h1>
      <p className={styles.description}>
        <LocalizedText messageID="page.likes.wish_list.empty.description" />
      </p>
      <PrimaryButton
        className={styles.letsGoShoppingButton}
        link={getPathForHomePage()}
      >
        <LocalizedText messageID="page.likes.wish_list.empty.button.lets_go_shopping" />
      </PrimaryButton>
    </div>
  );
};

export default WishlistEmptyView;
