import React, { useCallback } from "react";
import LocalizedText from "../../i18n/LocalizedText";
import { enableSmartProtectionByTier } from "../../utils/SmartProtection";

import styles from "./ClubProtectBanner.module.scss";

interface Props {
  onClick?: () => void;
}

const ClubProtectBanner: React.FC<Props> = props => {
  const { onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (onClick) {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <button onClick={handleClick} className={styles.container}>
      <p className={styles.text}>
        <span className={styles.mainText}>
          {enableSmartProtectionByTier() ? (
            <LocalizedText messageID="page.product_detail.club_protect_banner.only_tiers.main_text" />
          ) : (
            <LocalizedText messageID="page.product_detail.club_protect_banner.main_text" />
          )}
        </span>
        {onClick ? (
          <span className={styles.detailsText}>
            <LocalizedText messageID="page.product_detail.club_protect_banner.details" />
          </span>
        ) : null}
      </p>
    </button>
  );
};

export default ClubProtectBanner;
