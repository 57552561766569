import React, { useCallback } from "react";

import { ProductReview } from "../../models/ProductReview";
import { LocalizedText } from "../../i18n/Localization";

import { PrimaryHollowButton, BlackHollowButton } from "../Button";
import ProductReviewCell from "../ProductReviews/ProductReviewCell";

import styles from "./ProductReview.module.scss";

interface Props {
  productReviews: ProductReview[];
  isLoggedIn: boolean;
  onAddReviewClick: () => void;
  onViewAllReviewsClick: () => void;
  onLoginClick: () => void;
}

const ProductReviewComponent: React.FC<Props> = props => {
  const {
    productReviews,
    onAddReviewClick,
    onViewAllReviewsClick,
    onLoginClick,
    isLoggedIn,
  } = props;

  return (
    <div className={styles.productReviews}>
      {productReviews.length === 0 ? (
        <EmptyProductReview
          isLoggedIn={isLoggedIn}
          onAddReviewClick={onAddReviewClick}
          onLoginClick={onLoginClick}
        />
      ) : (
        <SingleProductReview
          productReview={productReviews[0]}
          isLoggedIn={isLoggedIn}
          reviewCount={productReviews.length}
          onAddReviewClick={onAddReviewClick}
          onViewAllReviewsClick={onViewAllReviewsClick}
          onLoginClick={onLoginClick}
        />
      )}
    </div>
  );
};

interface EmptyProductReviewProps {
  isLoggedIn: boolean;
  onAddReviewClick: () => void;
  onLoginClick: () => void;
}

const EmptyProductReview: React.FC<EmptyProductReviewProps> = props => {
  const { isLoggedIn, onAddReviewClick, onLoginClick } = props;

  const handleAddReviewClick = useCallback(() => {
    onAddReviewClick();
  }, [onAddReviewClick]);

  const handleLoginClick = useCallback(() => {
    onLoginClick();
  }, [onLoginClick]);

  return (
    <div className={styles.emptyProductReview}>
      <h2 className={styles.emptyMessage}>
        <LocalizedText messageID="page.product_detail.product_reviews.no_reviews" />
        {isLoggedIn ? (
          <BlackHollowButton
            className={styles.addReviewButton}
            onClick={handleAddReviewClick}
          >
            <LocalizedText messageID="page.product_detail.button.add_review" />
          </BlackHollowButton>
        ) : (
          <BlackHollowButton
            className={styles.addReviewButton}
            onClick={handleLoginClick}
          >
            <LocalizedText messageID="page.product_detail.button.login_and_add_review" />
          </BlackHollowButton>
        )}
      </h2>
    </div>
  );
};

interface SingleProductReviewProps {
  productReview: ProductReview;
  isLoggedIn: boolean;
  reviewCount: number;
  onAddReviewClick: () => void;
  onViewAllReviewsClick: () => void;
  onLoginClick: () => void;
}

const SingleProductReview: React.FC<SingleProductReviewProps> = props => {
  const {
    productReview,
    isLoggedIn,
    reviewCount,
    onAddReviewClick,
    onViewAllReviewsClick,
    onLoginClick,
  } = props;

  const handleAddReviewClick = useCallback(() => {
    onAddReviewClick();
  }, [onAddReviewClick]);

  const handleViewAllReviewsClick = useCallback(() => {
    onViewAllReviewsClick();
  }, [onViewAllReviewsClick]);

  const handleLoginClick = useCallback(() => {
    onLoginClick();
  }, [onLoginClick]);

  return (
    <div className={styles.singleProductReview}>
      <h2 className={styles.title}>
        <LocalizedText messageID="page.product_detail.product_reviews.title" />
      </h2>
      <ProductReviewCell productReview={productReview} />
      {isLoggedIn ? (
        <BlackHollowButton
          className={styles.addReviewButton}
          onClick={handleAddReviewClick}
        >
          <LocalizedText messageID="page.product_detail.button.add_review" />
        </BlackHollowButton>
      ) : (
        <BlackHollowButton
          className={styles.addReviewButton}
          onClick={handleLoginClick}
        >
          <LocalizedText messageID="page.product_detail.button.login_and_add_review" />
        </BlackHollowButton>
      )}
      <PrimaryHollowButton
        className={styles.seeAllReviewsButton}
        onClick={handleViewAllReviewsClick}
      >
        <LocalizedText
          messageID="page.product_detail.button.read_all_reviews"
          messageArgs={{
            numberOfCustomerReviews: reviewCount,
          }}
        />
      </PrimaryHollowButton>
    </div>
  );
};

export default ProductReviewComponent;
