import { IonPopover } from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import BingoListContext from "../../contexts/BingoListContext";
import { LocalizedText } from "../../i18n/Localization";
import {
  getPathForAccountTab,
  getPathForBingoList,
  RootTab,
} from "../../navigation/routes";
import { OurNavContext, PresentationContext } from "../../our-navigation";
import {
  bingolistTooltipsClickEvent,
  bingolistTooltipsImpressionEvent,
} from "../../utils/GTM";
import { IonPopoverTarget } from "../../utils/IonPopover";
import { timeout } from "../../utils/promise";
import { PrimaryButton } from "../Button";

import styles from "./styles.module.scss";

interface Props {
  targetElement: HTMLElement | null;
}

export const BingoListNotificationPopover: React.FC<Props> = props => {
  const { targetElement } = props;

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const eventRef = useRef<IonPopoverTarget>({ target: null });

  useEffect(() => {
    eventRef.current.target = targetElement;
  }, [targetElement]);

  const [shownOnce, setShownOnce] = useState(false);

  const { navigate } = useContext(OurNavContext);
  const { dismiss: dismissPresentation } = useContext(PresentationContext);
  const bingoListContext = useContext(BingoListContext);

  const tryShowOnce = useCallback(() => {
    if (
      bingoListContext.bingoListCount != null &&
      bingoListContext.bingoListCount > 0 &&
      !shownOnce
    ) {
      setShownOnce(true);
      setShowPopover(true);
      bingolistTooltipsImpressionEvent();
    }
  }, [shownOnce, bingoListContext.bingoListCount]);

  useEffect(() => {
    tryShowOnce();
  }, [tryShowOnce]);

  const handleDismiss = useCallback(() => {
    setShowPopover(false);
  }, []);

  const handleGoToBingoListClick = useCallback(
    async (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      bingolistTooltipsClickEvent();
      handleDismiss();
      dismissPresentation();
      navigate(getPathForAccountTab());
      await timeout(500);
      navigate(getPathForBingoList(RootTab.account));
    },
    [handleDismiss, dismissPresentation, navigate]
  );

  return (
    <IonPopover
      mode="ios"
      cssClass={styles.container}
      isOpen={showPopover}
      event={eventRef.current}
      onDidDismiss={handleDismiss}
    >
      <div className={styles.bubble}>
        <p className={styles.text}>
          <LocalizedText
            messageID="bingo_list.notification_popover.message"
            messageArgs={{ count: bingoListContext.bingoListCount || 0 }}
          />
        </p>
        <PrimaryButton
          className={styles.button}
          onClick={handleGoToBingoListClick}
        >
          <LocalizedText messageID="bingo_list.notification_popover.go_to_bingo_list" />
        </PrimaryButton>
      </div>
    </IonPopover>
  );
};
