import { useCallback, useEffect, useMemo } from "react";
import { ApolloClient, FetchPolicy } from "@apollo/client";
import { useApolloClient } from "@apollo/react-hooks";

import { useFetchResources, useFetchResources_v2 } from "./Hooks";

import {
  CMS_PAGE_TYPE,
  HTML_BASED_CMS_PAGE_TYPE,
  CMSStaticBlockContent,
  CMSBlock,
  CMSStaticBlock,
  isCMSStaticBlock,
  HTMLBasedCMSPageContent,
  ResolvedHTMLBasedCMSPageContent,
  ResolvedCMSBlock,
  ResolvedMatchedCMSBlock,
  ResolvedCMSPageContent,
  CMSPageContent,
} from "../models/cmsBlock";
import { ResourcesRequestState } from "../models/ResourcesRequestState";

import { useIntl } from "../i18n/Localization";
import { restAPIClient } from "../api/RESTful";
import {
  fetchStaticCMSBlocksByIds,
  fetchHTMLBasedCMSPageContentByIdentifier,
  getHTMLBasedCMSPageContentByIdentifier,
  getCMSStaticBlocksByIds,
  fetchProductOverviewsBySKUs,
  getProductOverviewsBySKUs,
} from "../api/GraphQL";
import { fetchProductLabelsByProductIds } from "../api/ProductLabel";
import {
  fetchProductSaleBundlesForProductSKUOnly,
  getProductSaleBundlesForProductSKUOnly,
} from "../api/ProductSaleBundle";

import Config from "../Config";
import { useKeepUpdatingRef } from "../hook/utils";
import { Locale } from "../i18n/locale";
import { IndexMap, mapNullable } from "../utils/type";
import { extractCMSBlocksFromContentForApp } from "../utils/CMSBlockExtractor";
import { profileAsyncAction, profileSyncAction } from "../utils/performance";
import {
  InitializeHomePageSession,
  Session,
} from "../utils/PerformanceRecordStore/sessions";
import { filterNullOrUndefined, flatMap } from "../utils/array";
import {
  fetchHomePageContent,
  getHomePageContent,
} from "../api/HomePageContent";

function getResolvedCMSPageContent(
  client: ApolloClient<unknown>,
  cmsPageContent: CMSPageContent
): ResolvedCMSPageContent | null {
  const resolvedCMSBlocks = getCMSBlocks(client, cmsPageContent.items);
  if (resolvedCMSBlocks) {
    return { items: resolvedCMSBlocks };
  }
  return null;
}

async function fetchResolvedCMSPageContent(
  client: ApolloClient<unknown>,
  locale: Locale,
  cmsPageContent: CMSPageContent
): Promise<ResolvedCMSPageContent | null> {
  const resolvedCMSBlocks = await resolveCMSBlocks(
    client,
    locale,
    cmsPageContent.items,
    "network-only"
  );
  if (resolvedCMSBlocks) {
    return { items: resolvedCMSBlocks };
  }
  return null;
}

export function getCategoryPageContent(
  client: ApolloClient<any>,
  identifier: string
): ResolvedHTMLBasedCMSPageContent | null {
  const content = getHTMLBasedCMSPageContentByIdentifier(client, {
    type: "cmsBlock",
    identifier,
  });
  if (content == null) {
    return null;
  }

  return getResolvedHTMLBasedCMSPageContent(client, content);
}

export async function fetchCategoryPageContent(
  client: ApolloClient<any>,
  identifier: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ResolvedHTMLBasedCMSPageContent | null> {
  const content = await fetchHTMLBasedCMSPageContentByIdentifier(
    client,
    {
      type: "cmsBlock",
      identifier,
    },
    locale,
    fetchPolicy
  );
  if (content == null) {
    return null;
  }

  return resolveHTMLBasedCMSPageContent(client, locale, content, fetchPolicy);
}

export function getBrandTopCmsContent(
  client: ApolloClient<unknown>,
  topCmsId: string
): ResolvedHTMLBasedCMSPageContent | null {
  const content = getHTMLBasedCMSPageContentByIdentifier(client, {
    type: "cmsBlock",
    identifier: topCmsId,
  });
  if (content == null) {
    return null;
  }

  return getResolvedHTMLBasedCMSPageContent(client, content);
}

export async function fetchBrandTopCmsContent(
  client: ApolloClient<any>,
  topCmsId: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ResolvedHTMLBasedCMSPageContent | null> {
  const content = await fetchHTMLBasedCMSPageContentByIdentifier(
    client,
    {
      type: "cmsBlock",
      identifier: topCmsId,
    },
    locale,
    fetchPolicy
  );
  if (content == null) {
    return null;
  }

  return resolveHTMLBasedCMSPageContent(client, locale, content, fetchPolicy);
}

async function fetchMerchantPageContent(
  client: ApolloClient<any>,
  identifier: string,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ResolvedHTMLBasedCMSPageContent | null> {
  const content = await fetchHTMLBasedCMSPageContentByIdentifier(
    client,
    {
      type: "cmsPageStringId",
      identifier,
    },
    locale,
    fetchPolicy
  );
  if (content == null) {
    return null;
  }

  return resolveHTMLBasedCMSPageContent(client, locale, content, fetchPolicy);
}

async function fetchCMSPageContent(
  client: ApolloClient<any>,
  pageType:
    | { type: "cmsBlock"; identifier: string }
    | { type: "cmsPage"; identifier: number }
    | { type: "cmsPageStringId"; identifier: string },
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ResolvedHTMLBasedCMSPageContent | null> {
  const content = await fetchHTMLBasedCMSPageContentByIdentifier(
    client,
    pageType,
    locale,
    fetchPolicy
  );

  if (content == null) {
    return null;
  }

  return resolveHTMLBasedCMSPageContent(client, locale, content, fetchPolicy);
}

export function useGetCMSPageContent(): (
  pageType: CMS_PAGE_TYPE
) => ResolvedCMSPageContent | null {
  const client = useApolloClient();
  return useCallback(
    (pageType: CMS_PAGE_TYPE) => {
      if (pageType.type === "home") {
        const homePageContent = profileSyncAction(
          InitializeHomePageSession(),
          "- Load Home Page Content from Cache",
          () => getHomePageContent(client)
        );
        if (homePageContent) {
          return profileSyncAction(
            InitializeHomePageSession(),
            "-- Load Home Page CMS Blocks from Cache",
            () => getResolvedCMSPageContent(client, homePageContent)
          );
        }
      }
      return null;
    },
    [client]
  );
}

export function useFetchCMSPageContent(): [
  ResourcesRequestState<ResolvedCMSPageContent | null>,
  (pageType: CMS_PAGE_TYPE) => Promise<ResolvedCMSPageContent | null>,
  (pageType: CMS_PAGE_TYPE) => Promise<ResolvedCMSPageContent | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const getCMSPageContent = useGetCMSPageContent();

  const localeRef = useKeepUpdatingRef(locale);

  const [requestState, { call, refresh }] = useFetchResources_v2<
    ResolvedCMSPageContent | null,
    (pageType: CMS_PAGE_TYPE) => Promise<ResolvedCMSPageContent | null>
  >({
    localCacheProvider: async (pageType: CMS_PAGE_TYPE) => {
      return getCMSPageContent(pageType);
    },
    remoteResourcesProvider: async (pageType: CMS_PAGE_TYPE) => {
      const cmsContent = await (async () => {
        if (pageType.type === "home") {
          const content = await profileAsyncAction(
            InitializeHomePageSession(),
            "- Load Home Page Content from Network",
            () => fetchHomePageContent(restAPIClient, client, localeRef.current)
          );
          if (content) {
            return profileAsyncAction(
              InitializeHomePageSession(),
              "-- Load Home Page CMS Blocks from Network",
              () => fetchResolvedCMSPageContent(client, locale, content)
            );
          }
        }
        return null;
      })();

      return cmsContent;
    },
  });

  return [requestState, call, refresh];
}

export function useFetchCMSStaticBlockContents(
  ids: string[]
): ResourcesRequestState<CMSStaticBlockContent[] | null> {
  const client = useApolloClient();
  const { locale } = useIntl();
  const idsDeps = useMemo(() => ids.join(":"), [ids]);
  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () =>
        fetchStaticCMSBlocksByIds(client, ids, locale, "cache-only"),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () =>
        fetchStaticCMSBlocksByIds(client, ids, locale, "network-only"),
      didFetchFromRemote: () => {},
    },
    [locale, idsDeps]
  );
  useEffect(() => {
    startRequesting();
  }, [startRequesting]);
  return requestState;
}

export function useFetchHTMLBasedCMSPageContent(
  profileSession?: Session
): [
  ResourcesRequestState<ResolvedHTMLBasedCMSPageContent | null>,
  (
    pageType: HTML_BASED_CMS_PAGE_TYPE
  ) => Promise<ResolvedHTMLBasedCMSPageContent | null>,
  (
    pageType: HTML_BASED_CMS_PAGE_TYPE
  ) => Promise<ResolvedHTMLBasedCMSPageContent | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const localeRef = useKeepUpdatingRef(locale);

  const [requestState, { call, refresh }] = useFetchResources_v2<
    ResolvedHTMLBasedCMSPageContent | null,
    (
      pageType: HTML_BASED_CMS_PAGE_TYPE
    ) => Promise<ResolvedHTMLBasedCMSPageContent | null>
  >({
    localCacheProvider: async (pageType: HTML_BASED_CMS_PAGE_TYPE) => {
      const action = async () => {
        if (pageType.type === "category") {
          return fetchCategoryPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "cache-only"
          );
        } else if (pageType.type === "merchant") {
          return fetchMerchantPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "cache-only"
          );
        }
        return fetchCMSPageContent(
          client,
          pageType,
          localeRef.current,
          "cache-only"
        );
      };
      const htmlBasedCMSPageContent = profileSession
        ? await profileAsyncAction(
            profileSession,
            "Load CMS Page from Cache",
            action
          )
        : await action();
      return htmlBasedCMSPageContent;
    },
    remoteResourcesProvider: async (pageType: HTML_BASED_CMS_PAGE_TYPE) => {
      const action = async () => {
        if (pageType.type === "category") {
          return fetchCategoryPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "network-only"
          );
        } else if (pageType.type === "merchant") {
          return fetchMerchantPageContent(
            client,
            pageType.identifier,
            localeRef.current,
            "network-only"
          );
        }
        return fetchCMSPageContent(client, pageType, locale, "network-only");
      };
      const htmlBasedCMSPageContent = profileSession
        ? await profileAsyncAction(
            profileSession,
            "Load CMS Page from Network",
            action
          )
        : await action();

      return htmlBasedCMSPageContent;
    },
  });

  return [requestState, call, refresh];
}

export function getCMSBlocks(
  client: ApolloClient<any>,
  cmsBlocks: CMSBlock[]
): ResolvedCMSBlock[] | null {
  function resolveCMSBlock(cmsBlock: CMSBlock): ResolvedCMSBlock | null {
    switch (cmsBlock.type) {
      case "static_block":
        return {
          type: "static_block",
          items: getCMSStaticBlocksByIds(
            client,
            filterNullOrUndefined((cmsBlock.items || []).map(i => i.identifier))
          ),
        };
      case "banner_rotator":
      case "recent_blog":
      case "category_bricks":
        return cmsBlock;
      case "top_sellers":
      case "products_recommendation":
      case "popular_products": {
        const productOverviews = getProductOverviewsBySKUs(
          client,
          filterNullOrUndefined((cmsBlock.items || []).map(i => i.sku))
        );
        const productIds = productOverviews
          ? productOverviews.map(p => p.id)
          : [];
        const bundles =
          Config.ENABLE_BUNDLE_SALE &&
          Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
            ? getProductSaleBundlesForProductSKUOnly(client, productIds)
            : [];
        if (productOverviews) {
          return {
            ...cmsBlock,
            productOverviews,
            productLabelsByProductId: {},
            bundleByProductId: bundles.reduce(
              (prev, curr) =>
                mapNullable(curr.mainProduct, p => ({
                  ...prev,
                  [p.id]: curr,
                })) || prev,
              {}
            ),
          };
        }
        return null;
      }
    }
  }

  const resList = cmsBlocks.map(resolveCMSBlock);
  const res: ResolvedCMSBlock[] = [];
  for (const resItem of resList) {
    if (resItem) {
      res.push(resItem);
    }
  }

  return res;
}

export async function resolveCMSBlocks(
  client: ApolloClient<any>,
  locale: Locale,
  cmsBlocks: CMSBlock[],
  fetchPolicy: FetchPolicy
): Promise<ResolvedCMSBlock[] | null> {
  async function resolveCMSBlock(
    cmsBlock: CMSBlock
  ): Promise<ResolvedCMSBlock | null> {
    switch (cmsBlock.type) {
      case "static_block":
        try {
          return {
            type: "static_block",
            items: await fetchStaticCMSBlocksByIds(
              client,
              filterNullOrUndefined(
                (cmsBlock.items || []).map(i => i.identifier)
              ),
              locale,
              fetchPolicy
            ),
          };
        } catch {
          return null;
        }
      case "banner_rotator":
      case "recent_blog":
      case "category_bricks":
        return cmsBlock;
      case "top_sellers":
      case "products_recommendation":
      case "popular_products": {
        try {
          const productOverviews = await fetchProductOverviewsBySKUs(
            client,
            filterNullOrUndefined((cmsBlock.items || []).map(i => i.sku)),
            locale,
            fetchPolicy
          );
          if (productOverviews) {
            const productIds = productOverviews.map(
              productOverview => productOverview.id
            );
            const productLabelsByProductIds = await fetchProductLabelsByProductIds(
              client,
              productIds,
              "category",
              locale,
              fetchPolicy
            );
            const bundles =
              Config.ENABLE_BUNDLE_SALE &&
              Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
                ? await fetchProductSaleBundlesForProductSKUOnly(
                    client,
                    productIds,
                    locale,
                    fetchPolicy
                  )
                : [];
            return {
              ...cmsBlock,
              productOverviews,
              productLabelsByProductId: productLabelsByProductIds.reduce(
                (prev, curr) => ({
                  ...prev,
                  [curr.productId]: curr.productLabels,
                }),
                {}
              ),
              bundleByProductId: bundles.reduce(
                (prev, curr) =>
                  mapNullable(curr.mainProduct, p => ({
                    ...prev,
                    [p.id]: curr,
                  })) || prev,
                {}
              ),
            };
          }
          return null;
        } catch {
          return null;
        }
      }
    }
  }

  const resList = await Promise.all(cmsBlocks.map(resolveCMSBlock));
  const res: ResolvedCMSBlock[] = [];
  for (const resItem of resList) {
    if (resItem) {
      res.push(resItem);
    }
  }

  return res;
}

function getResolvedHTMLBasedCMSPageContent(
  client: ApolloClient<any>,
  htmlBasedCMSPageContent: HTMLBasedCMSPageContent
): ResolvedHTMLBasedCMSPageContent | null {
  const { matchedCMSBlocks } = htmlBasedCMSPageContent;

  // We will further resolve one more level of cms block
  const staticBlockIds = filterNullOrUndefined(
    flatMap(
      staticBlock => (staticBlock.items || []).map(i => i.identifier),
      matchedCMSBlocks
        .map(m => m.cmsBlocks)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .filter<CMSStaticBlock>(isCMSStaticBlock)
    )
  );

  const staticBlockContents =
    staticBlockIds.length > 0
      ? getCMSStaticBlocksByIds(client, staticBlockIds)
      : [];
  const staticBlockContentById: IndexMap<string, CMSStaticBlockContent> = {};
  for (const staticBlockContent of staticBlockContents) {
    staticBlockContentById[staticBlockContent.identifier] = staticBlockContent;
  }

  const resolvedMatchedCMSBlocks: (ResolvedMatchedCMSBlock | null)[] = [];

  for (const matchedCMSBlock of matchedCMSBlocks) {
    const replacedCMSBlocks: CMSBlock[] = [];
    for (const cmsBlock of matchedCMSBlock.cmsBlocks) {
      if (!isCMSStaticBlock(cmsBlock)) {
        replacedCMSBlocks.push(cmsBlock);
      } else {
        mapNullable(cmsBlock.items, items => {
          for (const staticBlockItem of items) {
            if (!staticBlockItem.identifier) continue;
            const staticBlockContent =
              staticBlockContentById[staticBlockItem.identifier];
            if (
              staticBlockContent != null &&
              staticBlockContent.contentForApp != null
            ) {
              replacedCMSBlocks.push(
                ...extractCMSBlocksFromContentForApp(
                  staticBlockContent.contentForApp
                )
              );
            } else {
              replacedCMSBlocks.push(cmsBlock);
            }
          }
        });
      }
    }

    const resolvedCMSBlocks = getCMSBlocks(client, replacedCMSBlocks);

    if (resolvedCMSBlocks) {
      resolvedMatchedCMSBlocks.push({
        matchId: matchedCMSBlock.matchId,
        resolvedCMSBlocks,
      });
    }
  }

  const res: ResolvedMatchedCMSBlock[] = [];

  for (const resolvedMatchedCMSBlock of resolvedMatchedCMSBlocks) {
    if (resolvedMatchedCMSBlock) {
      res.push(resolvedMatchedCMSBlock);
    }
  }

  return {
    waitingToFillHTML: htmlBasedCMSPageContent.waitingToFillHTML,
    resolvedMatchedCMSBlocks: res,
  };
}

async function resolveHTMLBasedCMSPageContent(
  client: ApolloClient<any>,
  locale: Locale,
  htmlBasedCMSPageContent: HTMLBasedCMSPageContent,
  fetchPolicy: FetchPolicy
): Promise<ResolvedHTMLBasedCMSPageContent | null> {
  const { matchedCMSBlocks } = htmlBasedCMSPageContent;

  // We will further resolve one more level of cms block
  const staticBlockIds = filterNullOrUndefined(
    flatMap(
      staticBlock => (staticBlock.items || []).map(i => i.identifier),
      matchedCMSBlocks
        .map(m => m.cmsBlocks)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .filter<CMSStaticBlock>(isCMSStaticBlock)
    )
  );

  const staticBlockContents =
    staticBlockIds.length > 0
      ? getCMSStaticBlocksByIds(client, staticBlockIds)
      : [];
  const staticBlockContentById: IndexMap<string, CMSStaticBlockContent> = {};
  for (const staticBlockContent of staticBlockContents) {
    staticBlockContentById[staticBlockContent.identifier] = staticBlockContent;
  }

  const resolveMatchedCMSBlockCalls: Promise<ResolvedMatchedCMSBlock | null>[] = [];

  for (const matchedCMSBlock of matchedCMSBlocks) {
    const replacedCMSBlocks: CMSBlock[] = [];
    for (const cmsBlock of matchedCMSBlock.cmsBlocks) {
      if (!isCMSStaticBlock(cmsBlock)) {
        replacedCMSBlocks.push(cmsBlock);
      } else {
        mapNullable(cmsBlock.items, items => {
          for (const staticBlockItem of items) {
            if (!staticBlockItem.identifier) {
              continue;
            }
            const staticBlockContent =
              staticBlockContentById[staticBlockItem.identifier];
            if (
              staticBlockContent != null &&
              staticBlockContent.contentForApp != null
            ) {
              replacedCMSBlocks.push(
                ...extractCMSBlocksFromContentForApp(
                  staticBlockContent.contentForApp
                )
              );
            } else {
              replacedCMSBlocks.push(cmsBlock);
            }
          }
        });
      }
    }

    resolveMatchedCMSBlockCalls.push(
      resolveCMSBlocks(client, locale, replacedCMSBlocks, fetchPolicy).then(
        resolvedCMSBlocks =>
          resolvedCMSBlocks
            ? {
                matchId: matchedCMSBlock.matchId,
                resolvedCMSBlocks,
              }
            : null
      )
    );
  }

  const resolvedMatchedCMSBlocks: (ResolvedMatchedCMSBlock | null)[] = await Promise.all(
    resolveMatchedCMSBlockCalls
  );

  const res: ResolvedMatchedCMSBlock[] = [];

  for (const resolvedMatchedCMSBlock of resolvedMatchedCMSBlocks) {
    if (resolvedMatchedCMSBlock) {
      res.push(resolvedMatchedCMSBlock);
    }
  }

  return {
    waitingToFillHTML: htmlBasedCMSPageContent.waitingToFillHTML,
    resolvedMatchedCMSBlocks: res,
  };
}
