import { Plugins } from "@capacitor/core";
import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react";

import { OurNavContext, PresentationContext } from "../our-navigation";
import { useFetchCategory } from "../repository/CategoryRepository";
import { useHistory } from "react-router-dom";
import {
  useGetMyCustomer,
  useIsLoggedIn,
  useRefreshCustomer,
  useCustomer,
} from "../repository/AuthRepository";
import {
  useFetchCountryRegion,
  useFetchDistricts,
} from "../repository/CountryRegionRepository";
import {
  RootTab,
  getPathForSelectInterestCategoryPage,
  getPathForHomePage,
} from "../navigation/routes";
import {
  Customer,
  isCustomerInterestSet,
  isCustomerLinkedToTheClub,
  isCustomerRequireEmailVerificationPrompt,
} from "../models/Customer";
import { getOS, dummyIsdn } from "../models/OPNSPushNotification";
import {
  SSOResponse,
  useGetTheClubSSOConfirmationStatus,
} from "../useCase/AuthUseCase";
import { WishlistContext } from "./WishlistProvider";
import { useCartResource } from "./ShoppingCartModal/api";
import VerifyEmailModal from "./VerifyEmailModal";
import { LoginSignupModalContext } from "./LoginSignupModalProvider";
import PushNotificationReceiver from "./PushNotificationReceiver";
import ACSPushNotificationReceiver from "./PushNotificationReceiver/ACS";
import useRefreshOnAppActive from "../utils/RefreshOnAppActive";
import { isiOS, isAndroid } from "../utils/Platform";
import { networkEventEmitter } from "../utils/SimpleEventEmitter";
import { InitializeHomePageSession } from "../utils/PerformanceRecordStore/sessions";
import {
  useFetchProductAttributeFilterFields,
  useFetchProductAttributeSortFields,
} from "../repository/ProductAttributeFilter";
import { useFetchLiveEvent } from "../repository/ConfigRepository";
import { useFetchBingoListCount } from "../repository/BingoListRepository";
import BingoListContext from "../contexts/BingoListContext";
import usePresentSessionExpiredAlert from "../hook/usePresentSessionExpiredAlert";
import { useKeepUpdatingRef } from "../hook/utils";
import { useEffectOnce } from "../hook/useEffectOnce";
import { useModalState } from "../hook/modal";
import LocationService from "./LocationService";
import {
  useGetUnreadMessageCount,
  useGetOrderNotification,
  useGetPromotionNotification,
} from "./PushNotificationProvider/repository";
import { PushNotificationContext } from "./PushNotificationProvider";
import SubscribeProductRestockAlertContext from "./SubscribeProductRestockAlert/context";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";
import TheClubSSOConfirmationFlow, {
  TheClubSSOCOnfirmationStatusResult,
} from "./TheClubSSOConfirmationModals/TheClubSSOConfirmationFlow";
import SocialSignupTermsAndConditionsModal from "./SocialSignupTermsAndConditionsModal";

import Config from "../Config";
import { getLoginSignupMethod } from "../storage";
import { useIntl } from "../i18n/Localization";

const { AdobeExperiencePlatform: thePlugin } = Plugins;

const usePush = Config.USE_PUSH && (isiOS() || isAndroid());

const enableTheClubSSOMVP1ConfirmationDialogs =
  Config.ENABLE_THE_CLUB_SSO_MVP1 &&
  Config.ENABLE_THE_CLUB_SSO_MVP1.THE_CLUB_SSO_CONFIRMATION_DIALOGS;

const OnAppLoad: React.FC = props => {
  const history = useHistory();
  useFetchCategory(Config.DEFAULT_CATEGORY_ID, InitializeHomePageSession());

  useFetchCountryRegion();
  useFetchDistricts();

  const { locale } = useIntl();

  const presentationContext = useContext(PresentationContext);
  const { replace } = useContext(OurNavContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const { reset: resetSubscribeProductRestockAlert } = useContext(
    SubscribeProductRestockAlertContext
  );

  const { startRequesting: startRequestingGetMyCustomer } = useGetMyCustomer();
  const customer = useCustomer();
  const isTheClubMember = customer
    ? isCustomerLinkedToTheClub(customer)
    : false;
  const [verifyEmailModalIsOpen, setVerifyEmailModalIsOpen] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const onVerifyEmailModalDismiss = useCallback(
    () => setVerifyEmailModalIsOpen(false),
    [setVerifyEmailModalIsOpen]
  );
  const { presentLocalizedAlertOnAsyncError } = useContext(
    LocalizedAlertContext
  );

  const getTheClubSSOConfirmationStatus = useGetTheClubSSOConfirmationStatus();
  const [
    theClubSSOConfirmationModalShown,
    showTheClubSSOConfirmationModal,
    hideTheClubSSOConfirmationModal,
  ] = useModalState();
  const [
    theClubSSOConfirmationStatusResult,
    setTheClubSSOConfirmationStatusResult,
  ] = useState<TheClubSSOCOnfirmationStatusResult | null>(null);
  const [ssoLoginResult, setSSOLoginResult] = useState<SSOResponse | undefined>(
    undefined
  );
  const [
    socialSignupTermsModalIsOpen,
    setSocialSignupTermsModalIsOpen,
  ] = useState(false);

  const handleLoginSuccess = useCallback(
    (_customer: Customer) => {
      if (isCustomerRequireEmailVerificationPrompt(_customer)) {
        setVerifyEmailModalIsOpen(true);
        setVerifyEmail(_customer.email);
        return;
      }
      if (
        !enableTheClubSSOMVP1ConfirmationDialogs &&
        !isCustomerInterestSet(_customer)
      ) {
        history.push(getPathForSelectInterestCategoryPage(RootTab.home));
      }
    },
    [history]
  );

  const isHandlingSSOConfirmation = useCallback(
    async (_customer: Customer, method: LoginSignUpMethod) => {
      const theClubSSOConfirmationStatus = await getTheClubSSOConfirmationStatus(
        _customer,
        method
      );
      if (theClubSSOConfirmationStatus.type !== "NoActionRequired") {
        showTheClubSSOConfirmationModal();
        setTheClubSSOConfirmationStatusResult(theClubSSOConfirmationStatus);
        return true;
      }
      return false;
    },
    [getTheClubSSOConfirmationStatus, showTheClubSSOConfirmationModal]
  );

  useEffectOnce(() => {
    (async () => {
      const _customer = await startRequestingGetMyCustomer().catch(() => null);
      if (_customer) {
        presentLocalizedAlertOnAsyncError(async () => {
          const isMember = isCustomerLinkedToTheClub(_customer);
          if (
            !(await isHandlingSSOConfirmation(
              _customer,
              (await getLoginSignupMethod()) ||
                (isMember ? "The Club" : "Email")
            ))
          ) {
            handleLoginSuccess(_customer);
          }
        });

        if (
          Config.ADOBE_EXPERIENCE_PLATFORM_APP_ID &&
          isCustomerLinkedToTheClub(_customer)
        ) {
          return thePlugin
            .collectPii({
              attributes: {
                cusMembershipID: _customer.clubMember[0].clubMemberID,
                systemLanguage: locale,
              },
            })
            .catch(console.error);
        }
      }
    })();
  });

  const onSocialSignupTermsModalRequestDismiss = useCallback(() => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
  }, []);

  const handleSocialSignupTermsModalSuccess = useCallback(async () => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
    const _customer = await startRequestingGetMyCustomer().catch(() => null);
    if (_customer) {
      const isMember = isCustomerLinkedToTheClub(_customer);
      const method: LoginSignUpMethod =
        (await getLoginSignupMethod()) || (isMember ? "The Club" : "Email");
      presentLocalizedAlertOnAsyncError(async () => {
        if (!(await isHandlingSSOConfirmation(_customer, method))) {
          handleLoginSuccess(_customer);
        }
      });
    }
  }, [
    startRequestingGetMyCustomer,
    presentLocalizedAlertOnAsyncError,
    isHandlingSSOConfirmation,
    handleLoginSuccess,
  ]);

  // Initialize cart item count
  useCartResource();

  const handleSessionExpiredAlertLoginAgain = useCallback(() => {
    presentLoginModal();
  }, [presentLoginModal]);

  const handleSessionExpiredAlertCancel = useCallback(() => {
    presentationContext.dismiss();
    replace(getPathForHomePage());
  }, [presentationContext, replace]);

  usePresentSessionExpiredAlert(
    handleSessionExpiredAlertLoginAgain,
    handleSessionExpiredAlertCancel
  );

  useEffect(() => {
    const { remove } = networkEventEmitter.subscribe(e => {
      if (e.type === "ResumedFromMaintenance") {
        replace(getPathForHomePage());
      }
    });
    return () => {
      remove();
    };
  }, [replace]);

  const isLoggedIn = useIsLoggedIn();
  const { refresh } = useContext(WishlistContext);
  useEffect(() => {
    if (isLoggedIn) {
      refresh().catch(() => {});
    }
  }, [isLoggedIn, refresh]);

  useEffect(() => {
    if (!isLoggedIn) {
      resetSubscribeProductRestockAlert();
    }
  }, [isLoggedIn, resetSubscribeProductRestockAlert]);

  const { refreshCustomer } = useRefreshCustomer();
  useRefreshOnAppActive(refreshCustomer);

  const {
    deviceToken,
    setUnreadMessageCount,
    setOrderNotification,
    setPromotionNotification,
    pushNotificationMessagesPageLastRead,
    pushNotificationMessagesPageLastReadInitialized,
  } = useContext(PushNotificationContext);

  const pushNotificationMessagesPageLastReadRef = useKeepUpdatingRef(
    pushNotificationMessagesPageLastRead
  );

  const getUnreadMessageCount = useGetUnreadMessageCount();

  useEffect(() => {
    const os = getOS();
    if (!deviceToken || !os) {
      return;
    }
    if (pushNotificationMessagesPageLastReadInitialized) {
      (async () => {
        const _pushNotificationMessagesPageLastRead =
          pushNotificationMessagesPageLastReadRef.current;
        const c = await getUnreadMessageCount(
          os,
          deviceToken,
          dummyIsdn,
          _pushNotificationMessagesPageLastRead
        );
        setUnreadMessageCount(c);
      })();
    }
  }, [
    deviceToken,
    isLoggedIn,
    getUnreadMessageCount,
    setUnreadMessageCount,
    pushNotificationMessagesPageLastReadRef,
    pushNotificationMessagesPageLastReadInitialized,
  ]);

  const [getOrderNotification] = useGetOrderNotification();
  const [getPromotionNotification] = useGetPromotionNotification();
  useEffect(() => {
    if (customer) {
      (async () => {
        const orderNotification = await getOrderNotification();
        if (orderNotification != null) {
          setOrderNotification(orderNotification);
        }
      })();
    }
  }, [customer, getOrderNotification, setOrderNotification]);

  useEffect(() => {
    if (customer) {
      (async () => {
        const promotionNotification = await getPromotionNotification();
        if (promotionNotification != null) {
          setPromotionNotification(promotionNotification);
        }
      })();
    }
  }, [customer, getPromotionNotification, setPromotionNotification]);

  const [, fetchLiveEvent, refreshLiveEvent] = useFetchLiveEvent();
  useRefreshOnAppActive(refreshLiveEvent);

  useEffect(() => {
    fetchLiveEvent();
  }, [fetchLiveEvent]);

  const [
    ,
    fetchProductAttributeFilterFields,
  ] = useFetchProductAttributeFilterFields();
  useEffect(() => {
    fetchProductAttributeFilterFields().catch(() => {});
  }, [fetchProductAttributeFilterFields]);

  const [
    ,
    fetchProductAttributeSortFields,
  ] = useFetchProductAttributeSortFields();
  useEffect(() => {
    fetchProductAttributeSortFields().catch(() => {});
  }, [fetchProductAttributeSortFields]);

  const [, fetchBingoListCount] = useFetchBingoListCount();
  const { updateBingoListCount } = useContext(BingoListContext);
  const fetchBingoListCountRef = useRef(fetchBingoListCount);
  const updateBingoListCountRef = useRef(updateBingoListCount);
  useEffect(() => {
    if (!Config.ENABLE_BINGO_LIST || !isTheClubMember) {
      return;
    }
    // Will set bingo list count to 0 anyway to prevent notification showing
    (async () => {
      try {
        const count = await fetchBingoListCountRef.current();
        updateBingoListCountRef.current(count);
      } catch {
        updateBingoListCountRef.current(0);
      }
    })();
  }, [isTheClubMember]);

  return (
    <>
      {props.children}
      {usePush ? (
        Config.ADOBE_EXPERIENCE_PLATFORM_APP_ID ? (
          <ACSPushNotificationReceiver />
        ) : (
          <PushNotificationReceiver />
        )
      ) : null}
      {isiOS() || isAndroid() ? <LocationService /> : null}
      <VerifyEmailModal
        isModalOpen={verifyEmailModalIsOpen}
        initialEmail={verifyEmail}
        onRequestDismiss={onVerifyEmailModalDismiss}
        onEmailVerified={onVerifyEmailModalDismiss}
      />
      <SocialSignupTermsAndConditionsModal
        isModalOpen={socialSignupTermsModalIsOpen}
        onRequestDismiss={onSocialSignupTermsModalRequestDismiss}
        onSuccess={handleSocialSignupTermsModalSuccess}
        provider={ssoLoginResult ? ssoLoginResult.provider : undefined}
        token={ssoLoginResult ? ssoLoginResult.token : undefined}
      />
      <TheClubSSOConfirmationFlow
        theClubSSOConfirmationModalShown={theClubSSOConfirmationModalShown}
        showTheClubSSOConfirmationModal={showTheClubSSOConfirmationModal}
        hideTheClubSSOConfirmationModal={hideTheClubSSOConfirmationModal}
        theClubSSOConfirmationStatusResult={theClubSSOConfirmationStatusResult}
        setTheClubSSOConfirmationStatusResult={
          setTheClubSSOConfirmationStatusResult
        }
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

export default OnAppLoad;
