import React, { useCallback, useState, useContext } from "react";
import { IonButton } from "@ionic/react";
import cn from "classnames";

import { NavBar } from "../NavBar";
import { LocalizedText } from "../../i18n/Localization";
import styles from "./styles.module.scss";
import CLContent from "../CLContent";
import { useStoreConfig } from "../../repository/ConfigRepository";
import { useMainCategoryCount } from "../../repository/CategoryRepository";
import CategorySelectItem from "../CategorySelectItem";
import { Category } from "../../models/category";
import CategoryList from "../CategoryList";
import {
  getPathForSignupClubPointPage,
  RootTab,
  getPathForHomePage,
} from "../../navigation/routes";
import {
  useUpdateMyCustomerInterestCategoriesRequest,
  useCustomer,
} from "../../repository/AuthRepository";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { getCustomerLinkedSSOEmail } from "../../models/Customer";
import { OurNavContext } from "../../our-navigation";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import Config from "../../Config";

function viewEnter() {
  pageView({ page: "Signup Select Interest" });
}

const SelectInterestCategoryPage: React.FC = () => {
  const { navigate } = useContext(OurNavContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const storeConfig = useStoreConfig();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const customer = useCustomer();

  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const onCategoryItemClicked = useCallback(
    category => {
      actionEvent("Signup Select Interest", "Click", category.name);
      const newSelectedCategoryIds = [...selectedCategoryIds];
      const index = selectedCategoryIds.findIndex(id => id === category.id);
      if (index === -1) {
        newSelectedCategoryIds.push(category.id);
      } else {
        newSelectedCategoryIds.splice(index, 1);
      }
      setSelectedCategoryIds(newSelectedCategoryIds);
    },
    [selectedCategoryIds, setSelectedCategoryIds]
  );

  const renderCategorySelectItem = useCallback(
    (category: Category): JSX.Element | null =>
      storeConfig ? (
        <CategorySelectItem
          isSelected={selectedCategoryIds.includes(category.id)}
          storeConfig={storeConfig}
          category={category}
          key={category.id}
          onItemClick={onCategoryItemClicked}
        />
      ) : null,
    [storeConfig, selectedCategoryIds, onCategoryItemClicked]
  );

  const updateMyCustomerInterestCategoriesRequest = useUpdateMyCustomerInterestCategoriesRequest();

  const onDoneButtonClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await updateMyCustomerInterestCategoriesRequest(selectedCategoryIds);
      if (customer && !getCustomerLinkedSSOEmail(customer, "the-club")) {
        navigate(getPathForSignupClubPointPage(RootTab.home));
      } else {
        navigate(getPathForHomePage());
      }
    } catch {
      presentLocalizedAlert({
        headerId: "page.select_category.fail",
        messageId: "page.select_category.fail_text",
        buttons: [{ textMessageID: "try_again" }],
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    navigate,
    selectedCategoryIds,
    setIsLoading,
    updateMyCustomerInterestCategoriesRequest,
    presentLocalizedAlert,
    customer,
  ]);

  const numberOfCategories = useMainCategoryCount(Config.DEFAULT_CATEGORY_ID);

  return (
    <>
      <NavBar
        headerTitle={
          <span className={styles.title}>
            <LocalizedText messageID="page.select_category.select_your_interest" />
          </span>
        }
        headerRight={
          selectedCategoryIds.length !== 0 ? (
            <IonButton
              className={styles.doneButton}
              disabled={isLoading}
              onClick={onDoneButtonClick}
            >
              <span
                className={cn({
                  [styles.bold]:
                    selectedCategoryIds.length === numberOfCategories,
                })}
              >
                {!isLoading ? (
                  <LocalizedText
                    messageID="page.select_category.done"
                    messageArgs={{
                      numOfSelectedCategories: selectedCategoryIds.length,
                    }}
                  />
                ) : (
                  <div className={styles.loading}></div>
                )}
              </span>
            </IonButton>
          ) : null
        }
      />
      <CLContent>
        <div className={styles.content}>
          <div className={styles.descriptionText}>
            <LocalizedText messageID="page.select_category.description" />
          </div>
          <CategoryList renderCategory={renderCategorySelectItem} />
        </div>
      </CLContent>
    </>
  );
};

export default SelectInterestCategoryPage;
