import React, { useMemo, useCallback } from "react";

import { MessageID } from "../../i18n/translations/type";
import { LocalizedText } from "../../i18n/Localization";

import { SearchTerm, SearchAutoSuggestion } from "../../models/Search";

import styles from "./SearchSuggestionList.module.scss";
import { stringLiteral } from "../../utils/type";

export type SearchSuggestionListItemItem =
  | { type: "search-term"; searchTerm: SearchTerm }
  | { type: "search-suggestion"; searchSuggestion: SearchAutoSuggestion }
  | { type: "product"; product: { sku: string; name: string } }
  | { type: "total-count"; totalCount: number }
  | {
      type: "filtered-listing";
      searchTerm: string;
      searchSuggestion: SearchAutoSuggestion;
    };

interface Props {
  displayMode: "tag" | "row";
  headerTitleID: MessageID;
  items:
    | { type: "search-terms"; searchTerms: SearchTerm[] }
    | { type: "search-suggestion"; searchSuggestions: SearchAutoSuggestion[] }
    | { type: "products"; products: { sku: string; name: string }[] }
    | {
        type: "filtered-listing";
        searchTerm: string;
        searchSuggestions: SearchAutoSuggestion[];
      };
  totalCount?: number;
  footer?: React.ReactNode;
  onClickItem: (item: SearchSuggestionListItemItem) => void;
}

function getCount(items: Props["items"]): number {
  if (items.type === "search-terms") {
    return items.searchTerms.length;
  } else if (items.type === "products") {
    return items.products.length;
  } else if (items.type === "search-suggestion") {
    return items.searchSuggestions.length;
  }
  return items.searchSuggestions.length;
}

const SearchSuggestionList: React.FC<Props> = React.memo(props => {
  const {
    displayMode,
    headerTitleID,
    items: propsItems,
    totalCount,
    onClickItem,
  } = props;

  const items = useMemo<SearchSuggestionListItemItem[]>(() => {
    const items_: SearchSuggestionListItemItem[] = [];
    if (propsItems.type === "search-terms") {
      items_.push(
        ...propsItems.searchTerms.map(searchTerm => ({
          type: stringLiteral("search-term"),
          searchTerm,
        }))
      );
    } else if (propsItems.type === "products") {
      items_.push(
        ...propsItems.products.map(product => ({
          type: stringLiteral("product"),
          product,
        }))
      );
    } else if (propsItems.type === "search-suggestion") {
      items_.push(
        ...propsItems.searchSuggestions.map(s => ({
          type: stringLiteral("search-suggestion"),
          searchSuggestion: s,
        }))
      );
    } else {
      items_.push(
        ...propsItems.searchSuggestions.map(searchSuggestion => ({
          type: stringLiteral("filtered-listing"),
          searchSuggestion,
          searchTerm: propsItems.searchTerm,
        }))
      );
    }
    if (totalCount != null) {
      items_.push({
        type: "total-count",
        totalCount,
      });
    }
    return items_;
  }, [propsItems, totalCount]);

  if (getCount(propsItems) === 0) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.headerTitle}>
        <LocalizedText messageID={headerTitleID} />
      </h1>
      <div
        className={
          displayMode === "row" ? styles.searchRowList : styles.searchTagList
        }
      >
        {items.map((item, i) => (
          <div key={i} className={styles.searchListItem}>
            <SearchSuggestionListItem item={item} onClick={onClickItem} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default SearchSuggestionList;

const SearchSuggestionListItem: React.FC<{
  item: SearchSuggestionListItemItem;
  onClick: (item: SearchSuggestionListItemItem) => void;
}> = props => {
  const { item, onClick } = props;
  const onClick_ = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(item);
    },
    [item, onClick]
  );
  return (
    <div className={styles.searchItemContainer} onClick={onClick_}>
      {item.type === "search-term" ? (
        item.searchTerm
      ) : item.type === "product" ? (
        item.product.name
      ) : item.type === "filtered-listing" ? (
        item.searchSuggestion.name
      ) : item.type === "search-suggestion" ? (
        item.searchSuggestion.name
      ) : (
        <LocalizedText
          messageID="page.search_suggestion.list.view_all"
          messageArgs={{
            TOTAL_COUNT: item.totalCount,
          }}
        />
      )}
    </div>
  );
};
