import React, { useContext, useEffect } from "react";
import ConfigContext from "../contexts/ConfigContext";
import useOpenUrlWithBrowser from "../hook/useOpenUrlWithBrowser";
import {
  LoadingModalContextProps,
  withLoadingModal,
} from "./LoadingModalProvider";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";

type Props = LoadingModalContextProps;

const AppUpdateObserver: React.FC<Props> = props => {
  const { needUpdate } = useContext(ConfigContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const openUrlWithBrowser = useOpenUrlWithBrowser();

  const {
    loadingModalContext: { withLoadingModalAsync },
  } = props;

  useEffect(() => {
    if (needUpdate === false) {
      return;
    }
    switch (needUpdate.type) {
      case "force": {
        const link = needUpdate.forceUpdateLink;

        presentLocalizedAlert({
          headerId: "app_update_dialog.force.title",
          messageId: "app_update_dialog.force.description",
          backdropDismiss: false,
          buttons: [
            {
              textMessageID: "app_update_dialog.update",
              handler: () => {
                withLoadingModalAsync(() => openUrlWithBrowser(link));
                return false;
              },
            },
          ],
        });
        break;
      }
      case "recommend": {
        const link = needUpdate.recommendUpdateLink;

        presentLocalizedAlert({
          headerId: "app_update_dialog.recommend.title",
          messageId: "app_update_dialog.recommend.description",
          backdropDismiss: false,
          buttons: [
            {
              textMessageID: "app_update_dialog.update",
              handler: () => {
                withLoadingModalAsync(() => openUrlWithBrowser(link));
                return false;
              },
            },
            {
              textMessageID: "app_update_dialog.no_thanks",
            },
          ],
        });
        break;
      }
      default:
        break;
    }
  }, [
    needUpdate,
    presentLocalizedAlert,
    openUrlWithBrowser,
    withLoadingModalAsync,
  ]);

  return <>{props.children}</>;
};

export default withLoadingModal(AppUpdateObserver);
