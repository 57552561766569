import React, { useContext, useCallback } from "react";

import { Locale, getLocaleDisplayText } from "../../../i18n/locale";
import { LocaleContext, LocalizedText } from "../../../i18n/Localization";
import { NavBar, NavBarBackButton } from "../../NavBar";
import CLContent from "../../CLContent";
import NoInternetConnectionView from "../../NoInternetConnectionView";
import { NetworkStatusContext } from "../../NetworkStatusProvider";
import styles from "./styles.module.scss";
import SelectingRow from "../../SelectingCategoryRow";
import { getPathForAccountTab } from "../../../navigation/routes";
import { RepositoryContext } from "../../../repository/State";
import { apolloClient, cachePersistor } from "../../../api/GraphQL";

const DisplayLanguagePage: React.FC = () => {
  const { isOnline } = useContext(NetworkStatusContext);

  const { changeLocaleWithLoadingAndDestroyHistory, locale } = useContext(
    LocaleContext
  );

  const { dispatch } = useContext(RepositoryContext);

  const changeLocale = useCallback(
    async (newLocale: Locale) => {
      await apolloClient.stop();
      await cachePersistor.purge();
      await apolloClient.resetStore();

      const didChange = await changeLocaleWithLoadingAndDestroyHistory(
        newLocale
      );

      if (didChange) {
        dispatch({ type: "ClearProduct" });

        console.log(
          "TODO: construct a correct history, with account page at the back."
        );
      }
    },
    [changeLocaleWithLoadingAndDestroyHistory, dispatch]
  );

  const onClickzhHantLocale = useCallback(() => {
    changeLocale(Locale.zhHant);
  }, [changeLocale]);
  const onClickEnLocale = useCallback(() => {
    changeLocale(Locale.en);
  }, [changeLocale]);
  if (locale == null) {
    return null;
  }
  return (
    <>
      <NavBar
        headerLeft={
          <NavBarBackButton backDefaultHref={getPathForAccountTab()} />
        }
        headerTitle={<LocalizedText messageID="page.display_language.title" />}
      />
      <CLContent>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          <div className={styles.container}>
            <SelectingRow
              className={styles.selectingRow}
              textClassName={styles.text}
              onClick={onClickEnLocale}
              displayText={getLocaleDisplayText(Locale.en)}
              isSelected={locale === Locale.en}
            />
            <SelectingRow
              className={styles.selectingRow}
              textClassName={styles.text}
              onClick={onClickzhHantLocale}
              displayText={getLocaleDisplayText(Locale.zhHant)}
              isSelected={locale === Locale.zhHant}
            />
          </div>
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default DisplayLanguagePage;
