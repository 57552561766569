import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import { getStoreViewCodeForLocale, Locale } from "../i18n/locale";
import {
  AppConfig,
  AppConfigEstimatedDeliveryDateDisclaimer,
  AppConfigEstimatedDeliveryDateGraphQLAttributes,
  AppConfigGraphQLAttributes,
  AppConfigSchema,
  assembleAppConfig,
  RemoteAppConfig,
} from "../models/AppConfig";

export async function fetchAppConfig(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<AppConfig | null> {
  const query = <T>(graphQLAttributes: string) =>
    client.query<{
      appConfig: T;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      {
        appConfig {
          ${graphQLAttributes}
        }
      }
    `,
      fetchPolicy,
    });

  const [
    baseResult,
    appConfigEstimatedDeliveryDateDisclaimerResult,
  ] = await Promise.all([
    query<RemoteAppConfig>(AppConfigGraphQLAttributes),
    query<AppConfigEstimatedDeliveryDateDisclaimer>(
      AppConfigEstimatedDeliveryDateGraphQLAttributes
    ).catch(() => ({ data: { appConfig: {} } })),
  ]);

  const result = assembleAppConfig(
    AppConfigSchema.validateSync(baseResult.data.appConfig),
    appConfigEstimatedDeliveryDateDisclaimerResult.data.appConfig
  );

  return result;
}
