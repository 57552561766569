import React, {
  useCallback,
  useRef,
  useContext,
  useEffect,
  useState,
} from "react";
import cn from "classnames";

import { BackAndroidExitApp } from "../BackAndroidHandler";
import CLContent from "../CLContent";
import CategoryButton from "../CategoryButton";
import CategoryList from "../CategoryList";
import MainTabPageNavBar from "../MainTabPageNavBar";
import BrandIndexButton from "../BrandIndexButton";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import {
  Category,
  CategoryTree,
  computeChildenCategories,
} from "../../models/category";
import {
  useStoreConfig,
  useUrlRedirectConfig,
} from "../../repository/ConfigRepository";
import { useCategoryTreeMap } from "../../repository/CategoryRepository";

import {
  getPathForSubCategoryListPage,
  getPathForSingleCategoryPage,
  useCheckIsScreenActive,
  getPathForBrandIndexPage,
  RootTab,
  useCurrentTab,
} from "../../navigation/routes";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { pageView, actionEvent } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

import styles from "./styles.module.scss";
import { useScrollToTopWhenClickTabBar } from "../../hook/scrollToTopWhenClickTabBar";
import findMatchedRedirectMapping from "../../utils/findMatchedRedirectMapping";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { CategoryPageSession } from "../../utils/PerformanceRecordStore/sessions";

import Config from "../../Config";

function viewEnter() {
  pageView({ page: "Category" });
}

const CategoryPage: React.FC = () => {
  const { isOnline } = useContext(NetworkStatusContext);

  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const storeConfig = useStoreConfig();

  const isScreenActive = useCheckIsScreenActive();
  const currentTab = useCurrentTab();

  const categoryTreeMap = useCategoryTreeMap();

  const handleCategoryButtonClick = useCallback((category: Category) => {
    actionEvent("Category", "Click", category.name);
    addPerformanceRecord(
      CategoryPageSession(category.id),
      "Click on a category entry point"
    );
  }, []);

  const urlRedirectConfig = useUrlRedirectConfig();

  const renderCategoryButton = useCallback(
    (category: CategoryTree): JSX.Element => {
      if (storeConfig == null) {
        return <div key={category.id} />;
      }

      const matched = findMatchedRedirectMapping(
        urlRedirectConfig,
        "CATEGORY",
        `${category.id}`
      );
      const link =
        matched != null
          ? matched.targetPath
          : computeChildenCategories(category, categoryTreeMap).length > 0
          ? getPathForSubCategoryListPage(currentTab, category.id)
          : getPathForSingleCategoryPage(currentTab, category.id);

      return (
        <div key={category.id} className={styles.listItem}>
          <CategoryButton
            storeConfig={storeConfig}
            category={category}
            key={category.id}
            leftLink={link}
            rightLink={link}
            onLeftAreaClick={handleCategoryButtonClick}
            onRightAreaClick={handleCategoryButtonClick}
          />
        </div>
      );
    },
    [
      storeConfig,
      currentTab,
      handleCategoryButtonClick,
      urlRedirectConfig,
      categoryTreeMap,
    ]
  );

  useScrollToTopWhenClickTabBar(RootTab.category, isScreenActive, contentRef);

  const [
    changedFromActiveToInactiveOnce,
    setChangedFromActiveToInactiveOnce,
  ] = useState(false);

  useEffect(() => {
    if (!isScreenActive && !changedFromActiveToInactiveOnce) {
      setChangedFromActiveToInactiveOnce(true);
    }
  }, [isScreenActive, changedFromActiveToInactiveOnce]);

  return (
    <>
      <BackAndroidExitApp pageIsActive={isScreenActive} />
      <MainTabPageNavBar />
      <CLContent ref={contentRef}>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          <div className={styles.content}>
            <div
              className={cn(
                styles.list,
                !changedFromActiveToInactiveOnce && styles.withAnimation
              )}
            >
              <CategoryList renderCategory={renderCategoryButton} />
              {Config.ENABLE_BRAND ? (
                <div className={styles.listItem}>
                  <BrandIndexButton
                    href={getPathForBrandIndexPage(RootTab.category)}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <TabBarSpacePlaceholder />
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default CategoryPage;
