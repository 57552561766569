import { ApolloClient, gql } from "@apollo/client";
import { parseGraphQLError } from "./GraphQL";

export async function fetchTheClubOneTimeToken(
  client: ApolloClient<unknown>,
  challenge: string
): Promise<string> {
  try {
    const result = await client.mutate<
      { generateTheClubOneTimeToken: { token: string } },
      { challenge: string }
    >({
      mutation: gql`
        mutation GetTheClubOneTimeToken($challenge: String!) {
          generateTheClubOneTimeToken(code_challenge: $challenge) {
            token
          }
        }
      `,
      variables: {
        challenge,
      },
    });
    if (result.data) {
      return result.data.generateTheClubOneTimeToken.token;
    }
    throw new Error("Unknown error");
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}
