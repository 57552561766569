import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
  useContext,
} from "react";
import { RefresherEventDetail } from "@ionic/core";
import cn from "classnames";

import { Props as ArticleListProps } from "./ArticleList";
import { BackAndroidExitApp } from "../BackAndroidHandler";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import { LocalizedText } from "../../i18n/Localization";
import MainTabPageNavBar from "../MainTabPageNavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import { ArticlePreview, ArticleCategory } from "../../models/Article";
import {
  getResources,
  isRequestLoading,
} from "../../models/ResourcesRequestState";

import {
  useFetchAllArticleCategories,
  useFetchArticleList,
} from "../../repository/ArticleRepository";
import { PaginationInfo } from "../../repository/State";

import {
  useCheckIsScreenActive,
  getPathForArticleDetail,
  RootTab,
} from "../../navigation/routes";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { pageView, actionEvent } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

import styles from "./RootArticleListPage.module.scss";
import { OurNavContext } from "../../our-navigation";
import { useScrollToTopWhenClickTabBar } from "../../hook/scrollToTopWhenClickTabBar";

type ViewState = ViewStateLoading | ViewStateDisplay;

interface ViewStateLoading {
  type: "loading";
}

function ViewStateLoading(): ViewStateLoading {
  return {
    type: "loading",
  };
}

interface ViewStateDisplay {
  type: "display";

  ArticleListComponent: React.ComponentType<ArticleListProps>;

  articleListPaginationInfo: PaginationInfo<ArticlePreview>;
  articleCategories: ArticleCategory[];
}

function ViewStateDisplay(
  ArticleListComponent: React.ComponentType<ArticleListProps>,
  articleListPaginationInfo: PaginationInfo<ArticlePreview>,
  articleCategories: ArticleCategory[]
): ViewStateDisplay {
  return {
    type: "display",
    ArticleListComponent,
    articleListPaginationInfo,
    articleCategories,
  };
}

function viewEnter() {
  pageView({ page: "Article" });
}

const RootArticleListPage: React.FC = () => {
  const { isOnline } = useContext(NetworkStatusContext);
  const [ArticleListComponents, setArticleListComponents] = useState<{
    ArticleListComponent: React.ComponentType<ArticleListProps>;
  } | null>(null);
  useEffect(() => {
    Promise.all([
      import("./ArticleList").then(({ default: ArticleList }) => ArticleList),
    ]).then(([ArticleListComponent]) => {
      setArticleListComponents({
        ArticleListComponent,
      });
    });
  }, []);

  const { navigate } = useContext(OurNavContext);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const isScreenActive = useCheckIsScreenActive();

  const {
    requestState: fetchAllArticleCategoriesRequestState,
    fetch: fetchAllArticleCategories,
    retry: retryAllArticleCategories,
  } = useFetchAllArticleCategories();
  const {
    requestState: fetchArticleListNextPageRequestState,
    paginationInfo: articleListPaginationInfo,
    fetchNext: fetchArticleListNextPage,
    refresh: refreshArticleList,
  } = useFetchArticleList();

  const retry = useCallback(() => {
    retryAllArticleCategories();
    refreshArticleList();
  }, [retryAllArticleCategories, refreshArticleList]);

  useEffect(() => {
    fetchAllArticleCategories();
    fetchArticleListNextPage();
  }, [fetchAllArticleCategories, fetchArticleListNextPage]);

  const [
    articleListRefreshEventDetail,
    setArticleListRefreshEventDetail,
  ] = useState<CustomEvent<RefresherEventDetail> | null>(null);
  const onArticleListRefresh = useCallback(
    (e: CustomEvent<RefresherEventDetail>) => {
      refreshArticleList();
      setArticleListRefreshEventDetail(e);
    },
    [refreshArticleList]
  );
  useEffect(() => {
    if (
      articleListRefreshEventDetail != null &&
      !isRequestLoading(fetchArticleListNextPageRequestState)
    ) {
      articleListRefreshEventDetail.detail.complete();
      setArticleListRefreshEventDetail(null);
    }
  }, [articleListRefreshEventDetail, fetchArticleListNextPageRequestState]);

  const onArticleListEndReach = useCallback(() => {
    fetchArticleListNextPage();
  }, [fetchArticleListNextPage]);

  const onClickLatestArticleCell = useCallback(
    (article: ArticlePreview, i: number) => {
      actionEvent(
        "Article Listing",
        "Click",
        `Latest Article P${i}_${article.title}`
      );
      navigate(getPathForArticleDetail(RootTab.articles, article.postId));
    },
    [navigate]
  );

  const viewState = useMemo<ViewState>(() => {
    if (
      fetchAllArticleCategoriesRequestState.type === "loading" ||
      fetchArticleListNextPageRequestState.type === "loading" ||
      ArticleListComponents == null
    ) {
      return ViewStateLoading();
    }
    const articleCategories = getResources(
      fetchAllArticleCategoriesRequestState
    );
    if (articleListPaginationInfo != null && articleCategories != null) {
      return ViewStateDisplay(
        ArticleListComponents.ArticleListComponent,
        articleListPaginationInfo,
        articleCategories
      );
    }
    return ViewStateLoading();
  }, [
    fetchAllArticleCategoriesRequestState,
    fetchArticleListNextPageRequestState,
    ArticleListComponents,
    articleListPaginationInfo,
  ]);

  useScrollToTopWhenClickTabBar(RootTab.articles, isScreenActive, contentRef);

  return (
    <div className={cn("ion-page", styles.container)}>
      <BackAndroidExitApp pageIsActive={isScreenActive} />
      <MainTabPageNavBar />
      <CLContent ref={contentRef} className={styles.content}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "display"}
          onRetry={retry}
        >
          {viewState.type === "loading" && <FullContentLoadingView />}
          {viewState.type === "display" && (
            <viewState.ArticleListComponent
              ionContentRef={contentRef}
              header={
                <>
                  <h1 className={styles.latestPostTitle}>
                    <LocalizedText messageID="page.article_list.latest_post.title" />
                  </h1>
                </>
              }
              paginationInfo={viewState.articleListPaginationInfo}
              onClickArticleCell={onClickLatestArticleCell}
              onRefresh={onArticleListRefresh}
              onEndReach={onArticleListEndReach}
            />
          )}
          <TabBarSpacePlaceholder />
        </NoInternetConnectionView>
      </CLContent>
    </div>
  );
};

export default RootArticleListPage;
