interface GraphQLRequest {
  operationName: string | null;
  variables: { [key in string]: any };
  query?: string;
  extensions?: { [key in string]: any };
}

const getRequestURILengthThreshold = 6000;

export function getCLGraphQLFetchParameters(
  input: RequestInfo,
  init?: RequestInit
): [RequestInfo, RequestInit | undefined] {
  if (!init) {
    return [input, init];
  }
  const { body } = init;
  if (!body || !isString(body)) {
    return [input, init];
  }
  let request: GraphQLRequest | null = null;
  try {
    const parsed = JSON.parse(body);
    const { operationName, variables, query, extensions } = parsed;
    request = {
      operationName: operationName || null,
      variables: variables || {},
      query,
      extensions,
    };
  } catch {
    console.warn("[CLGraphQLFetch]", "body cannot be parsed");
  }
  if (!request) {
    return [input, init];
  }
  if (!isString(input)) {
    return [input, init];
  }
  let chosenURI = input;
  if (request.query && !isMutation(request.query)) {
    const newURI = rewriteURIForGET(chosenURI, request);
    const length = newURI.length;
    if (length <= getRequestURILengthThreshold) {
      chosenURI = newURI;
      init.method = "GET";
      init.body = undefined;
      init.cache = "no-store";
    } else {
      console.warn(
        `[CLGraphQLFetch] Get request length (${length}) exceed ${getRequestURILengthThreshold}. Keep using POST.`,
        { operationName: request.operationName }
      );
    }
  }
  return [chosenURI, init];
}

export function makeCLGraphQLFetch(options: {
  useGETForQueries: boolean;
}): typeof fetch {
  const { useGETForQueries } = options;

  return async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    if (useGETForQueries) {
      const [newInput, newInit] = getCLGraphQLFetchParameters(input, init);
      return fetch(newInput, newInit);
    }
    return fetch(input, init);
  };
}

// For GET operations, returns the given URI rewritten with parameters, or a
// parse error.
// https://github.com/apollographql/apollo-link/blob/a204a87e9e8c2ff9d4f797e1b17b843078aa5622/packages/apollo-link-http/src/httpLink.ts#L198
function rewriteURIForGET(chosenURI: string, request: GraphQLRequest): string {
  // Implement the standard HTTP GET serialization, plus 'extensions'. Note
  // the extra level of JSON serialization!
  const queryParams: string[] = [];
  const addQueryParam = (key: string, value: string) => {
    queryParams.push(`${key}=${encodeURIComponent(value)}`);
  };

  if (request.query) {
    const processed = request.query.replace(/\s+/g, " ");
    addQueryParam("query", processed);
  }
  if (request.operationName) {
    addQueryParam("operationName", request.operationName);
  }
  if (request.variables) {
    const serializedVariables = JSON.stringify(request.variables);
    addQueryParam("variables", serializedVariables);
  }
  if (request.extensions) {
    const serializedExtensions = JSON.stringify(request.extensions);
    addQueryParam("extensions", serializedExtensions);
  }

  // Reconstruct the URI with added query params.
  // XXX This assumes that the URI is well-formed and that it doesn't
  //     already contain any of these query params. We could instead use the
  //     URL API and take a polyfill (whatwg-url@6) for older browsers that
  //     don't support URLSearchParams. Note that some browsers (and
  //     versions of whatwg-url) support URL but not URLSearchParams!
  let fragment = "",
    preFragment = chosenURI;
  const fragmentStart = chosenURI.indexOf("#");
  if (fragmentStart !== -1) {
    fragment = chosenURI.substr(fragmentStart);
    preFragment = chosenURI.substr(0, fragmentStart);
  }
  const queryParamsPrefix = preFragment.indexOf("?") === -1 ? "?" : "&";
  const newURI =
    preFragment + queryParamsPrefix + queryParams.join("&") + fragment;
  return newURI;
}

function isString(maybeString: any): maybeString is string {
  return typeof maybeString === "string";
}

function isMutation(query: string): boolean {
  const normalizedQuery = query.trim();
  const regex = new RegExp("^mutation");
  const matched = regex.exec(normalizedQuery);
  return !!matched;
}
