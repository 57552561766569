import React from "react";
import { IonIcon } from "@ionic/react";
import classnames from "classnames";
import styles from "./PaymentInfo.module.scss";
import commonStyles from "./styles.module.scss";
import { Money, formatMoney } from "../../models/Price";
import { MessageID } from "../../i18n/translations/type";
import { LocalizedText } from "../../i18n/Localization";

type PaymentInfoExtraItemMoneyType = "+" | "-";

export type PaymentInfoExtraItem = (
  | {
      titleType: "titleID";
      titleID: MessageID;
    }
  | {
      titleType: "title";
      title: string;
    }) & {
  type: PaymentInfoExtraItemMoneyType;
  value: Money;
};

interface PaymentInfoProps {
  subtotal: Money;
  clubPointsRequired: number;
  extraClubpointsUsed: number;
  totalClubPointsUsed: number;
  clubpointsConversionCurrency: string;
  clubCurrencyAmount: number | null;
  clubpointsEarned: number | null;
  extraItems: PaymentInfoExtraItem[];
  total: Money;
  totalShippingFee: Money;
}

const PaymentInfo: React.FC<PaymentInfoProps> = props => {
  const {
    subtotal,
    clubPointsRequired,
    extraClubpointsUsed,
    totalClubPointsUsed,
    clubpointsConversionCurrency,
    clubCurrencyAmount,
    clubpointsEarned,
    extraItems,
    total,
  } = props;
  return (
    <div
      className={classnames(
        commonStyles.orderDetailSectionContainer,
        styles.paymentInfoContainer
      )}
    >
      <PaymentInfoItem className={styles.paymentInfoItem}>
        <LocalizedText messageID="order_detail.payment_info.subtotal" />
        {`+ ${formatMoney(subtotal)}`}
      </PaymentInfoItem>
      {extraItems.map((item, index) => (
        <PaymentInfoItem key={index} className={styles.paymentInfoItem}>
          <>
            {item.titleType === "titleID" && (
              <LocalizedText messageID={item.titleID} />
            )}
            {item.titleType === "title" && item.title}
          </>
          {`${item.type} ${formatMoney(item.value)}`}
        </PaymentInfoItem>
      ))}
      {clubPointsRequired > 0 ? (
        <PaymentInfoItem className={styles.paymentInfoItem}>
          <div className={styles.itemLeftChild}>
            <LocalizedText messageID="order_detail.payment_info.club_points_required" />
            &nbsp;(
            <IonIcon
              className={styles.clubpointsIconHighlight}
              name="club-point"
            />
            <span className={styles.clubpointsHighlight}>
              {clubPointsRequired}
            </span>
            )
          </div>
          <div className={styles.itemRightChild}></div>
        </PaymentInfoItem>
      ) : null}
      {extraClubpointsUsed > 0 ? (
        <PaymentInfoItem className={styles.paymentInfoItem}>
          <div className={styles.itemLeftChild}>
            <LocalizedText
              messageID={
                clubPointsRequired > 0
                  ? "order_detail.payment_info.extra_club_points_used"
                  : "order_detail.payment_info.club_points_used"
              }
            />
            &nbsp;(
            <IonIcon
              className={styles.clubpointsIconHighlight}
              name="club-point"
            />
            <span className={styles.clubpointsHighlight}>
              {extraClubpointsUsed}
            </span>
            )
          </div>
          <div className={styles.itemRightChild}>
            {clubCurrencyAmount
              ? `- ${formatMoney({
                  currency: clubpointsConversionCurrency,
                  value: clubCurrencyAmount,
                })}`
              : null}
          </div>
        </PaymentInfoItem>
      ) : null}
      {clubpointsEarned != null && (
        <PaymentInfoItem className={styles.paymentInfoItem}>
          <LocalizedText messageID="order_detail.club_point_earned" />
          <>
            <IonIcon
              className={styles.clubpointsIconHighlight}
              name="club-point"
            />
            {clubpointsEarned}
          </>
        </PaymentInfoItem>
      )}
      <PaymentInfoItem
        className={classnames(
          styles.paymentInfoItem,
          styles.grandTotalContainer
        )}
      >
        <LocalizedText messageID="order_detail.payment_info.amount_payable" />
        <div className={styles.grandTotalPrice}>
          {totalClubPointsUsed > 0 && (
            <>
              <IonIcon
                className={styles.clubpointsIconHighlight}
                name="club-point"
              />
              {totalClubPointsUsed}
              {totalClubPointsUsed > 0 && total.value > 0 ? (
                <>&nbsp;+&nbsp;</>
              ) : null}
            </>
          )}
          {total.value > 0 ? formatMoney(total) : null}
        </div>
      </PaymentInfoItem>
    </div>
  );
};

interface PaymentInfoItemProps {
  className?: string;
  children: [React.ReactNode, React.ReactNode];
}

const PaymentInfoItem: React.FC<PaymentInfoItemProps> = props => {
  const {
    className,
    children: [leftChild, rightChild],
  } = props;
  return (
    <div className={classnames(className, styles.itemContainer)}>
      <div className={styles.itemLeftChild}>{leftChild}</div>
      <div className={styles.itemRightChild}>{rightChild}</div>
    </div>
  );
};

export default PaymentInfo;
