import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import { getStoreViewCodeForLocale, Locale } from "../../i18n/locale";
import { MoneyGraphQLAttributes } from "../../models/Price";
import {
  keyGraphQLAttributes,
  ModelKeys,
  ProductCustomizableOptionCheckboxGraphQLAttributes,
  ProductCustomizableOptionFileGraphQLAttributes,
  ProductCustomizableOptionRadioGraphQLAttributes,
  ProductCustomizableOptionTextFieldGraphQLAttributes,
  ProductCustomizationOptionDateGraphQLAttributes,
  ProductCustomizationOptionDropDownGraphQLAttributes,
  ProductCustomizationOptionInterfaceGraphQLAttributes,
  ProductCustomizationOptionMultipleSelectGraphQLAttributes,
  ProductCustomizationOptionTextAreaGraphQLAttributes,
  ProductImageGraphQLAttributes,
  ProductConfigurableOptionGraphQLAttributes,
} from "../../models/product";
import { filterNullOrUndefined } from "../../utils/array";
import { Product, SimpleProduct, ProductCustomizableOptions } from "./models";

const productGraphQLAttributes = `
enableAgeDeclaration: enable_age_declaration
enableClubProtection: enable_club_protection
enableDisclaimer: enable_disclaimer
image {
  ${ProductImageGraphQLAttributes}
}
isDisclaimerRequired: is_disclaimer_required
minClubPoint: min_clubpoints
name
priceRange: price_range {
  minimumPrice: minimum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
  maximumPrice: maximum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
}
recurringConfiguration: recurring_configuration {
  isRecurringEnable: is_recurring_enable
  isSubscriptionOnly: is_subscription_only
  billingCycle: billing_cycle
  isEnableTrial: is_enable_trial
  isFreeShipping: is_free_shipping
  startDate: start_date
  endDate: end_date
}
stockStatus: stock_status
type: type_id
`;

const productBaseGraphQLAttributes = `
${keyGraphQLAttributes}
${productGraphQLAttributes}
... on ConfigurableProduct {
  configurableOptions: configurable_options {
    ${ProductConfigurableOptionGraphQLAttributes}
  }
  variants {
    product {
      ${keyGraphQLAttributes}
      ${productGraphQLAttributes}
    }
    attributes {
      label
      value: value_index
      code
    }
  }
}
`;

const productCustomizableOptionsGraphQLAttributes = `
${keyGraphQLAttributes}
... on CustomizableProductInterface {
  customizableOptions: options {
    ${ProductCustomizationOptionInterfaceGraphQLAttributes}
    ... on CustomizableAreaOption {
      ${ProductCustomizationOptionTextAreaGraphQLAttributes}
    }
    ... on CustomizableDateOption {
      ${ProductCustomizationOptionDateGraphQLAttributes}
    }
    ... on CustomizableDropDownOption {
      ${ProductCustomizationOptionDropDownGraphQLAttributes}
    }
    ... on CustomizableMultipleOption {
      ${ProductCustomizationOptionMultipleSelectGraphQLAttributes}
    }
    ... on CustomizableFieldOption {
      ${ProductCustomizableOptionTextFieldGraphQLAttributes}
    }
    ... on CustomizableFileOption {
      ${ProductCustomizableOptionFileGraphQLAttributes}
    }
    ... on CustomizableRadioOption {
      ${ProductCustomizableOptionRadioGraphQLAttributes}
    }
    ... on CustomizableCheckboxOption {
      ${ProductCustomizableOptionCheckboxGraphQLAttributes}
    }
  }
}
`;

export async function fetchNormalProduct(
  client: ApolloClient<any>,
  sku: string,
  locale: Locale,
  // Do not use cache version of this api
  // because there are still objects returned even
  // some fields are missing (unexpected undefined)
  // and default partialRefetch (false) is not working
  fetchPolicy: Exclude<FetchPolicy, "cache-only" | "cache-first">
): Promise<Product> {
  const query = async <P extends ModelKeys>(
    graphQLAttributes: string
  ): Promise<P> => {
    const result = await client.query<{
      products:
        | {
            items: P[];
          }
        | undefined;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query QueryProductBySKU($sku: String!) {
          products(filter: { sku: { eq: $sku } }) {
            items {
              ${graphQLAttributes}
            }
          }
        }
      `,
      variables: {
        sku,
      },
      fetchPolicy,
    });
    if (result.data.products && result.data.products.items) {
      const items = filterNullOrUndefined(result.data.products.items);
      const selectedProducts = items.filter(product => product.sku === sku);
      if (selectedProducts.length > 0) {
        return selectedProducts[0];
      }
    }
    throw new Error("Product not found");
  };

  const [base, customizableOptions] = await Promise.all([
    query<SimpleProduct>(productBaseGraphQLAttributes),
    query<ProductCustomizableOptions>(
      productCustomizableOptionsGraphQLAttributes
    ),
  ]);

  return { ...base, ...customizableOptions };
}

export async function fetchCampaignProduct(
  client: ApolloClient<any>,
  campaignId: number,
  sku: string,
  locale: Locale,
  // Do not use cache version of this api
  // because there are still objects returned even
  // some fields are missing (unexpected undefined)
  // and default partialRefetch (false) is not working
  fetchPolicy: Exclude<FetchPolicy, "cache-only" | "cache-first">
): Promise<Product> {
  const query = async <P extends ModelKeys>(
    graphQLAttributes: string
  ): Promise<P> => {
    const result = await client.query<
      {
        campaign: {
          campaignProducts: {
            items: (P | null)[] | null;
          } | null;
        } | null;
      },
      { campaignId: number; page: number; pageSize: number }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query CampaignProducts(
          $campaignId: Int!
          $page: Int!
          $pageSize: Int!
        ) {
          campaign(campaign_id: $campaignId) {
            campaignProducts: campaign_products(
              currentPage: $page
              pageSize: $pageSize
            ) {
              items {
                ${graphQLAttributes}
              }
            }
          }
        }
      `,
      variables: {
        campaignId,
        page: 1,
        pageSize: 100,
      },
      fetchPolicy,
    });
    if (
      result.data.campaign &&
      result.data.campaign.campaignProducts &&
      result.data.campaign.campaignProducts.items
    ) {
      const items = filterNullOrUndefined(
        result.data.campaign.campaignProducts.items
      );
      const selectedProducts = items.filter(product => product.sku === sku);
      if (selectedProducts.length > 0) {
        return selectedProducts[0];
      }
    }
    throw new Error("Product not found");
  };

  const [baseData, customizableOptions] = await Promise.all([
    query<SimpleProduct>(productBaseGraphQLAttributes),
    query<ProductCustomizableOptions>(
      productCustomizableOptionsGraphQLAttributes
    ),
  ]);

  return { ...baseData, ...customizableOptions };
}
