import React, { useCallback, useState, useContext, useMemo } from "react";
import CLContent from "../CLContent";
import cn from "classnames";

import styles from "./styles.module.scss";
import {
  useCustomer,
  useUpdateMyCustomerEmailRequest,
} from "../../repository/AuthRepository";
import { IonButton } from "@ionic/react";
import { NavBar, NavBarBackButton } from "../NavBar";
import { LocalizedText } from "../../i18n/Localization";
import { OurNavContext } from "../../our-navigation";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import PasswordInputField from "../PasswordInputField";
import { validateEmail } from "../../utils/validation";
import { LoadingModalContext } from "../LoadingModalProvider";
import Input from "../Form/Input";
import {
  appEventEmitter,
  AppEventOnSubmitChangeEmailSuccess,
} from "../../utils/SimpleEventEmitter";

const ChangeEmailPage: React.FC = () => {
  const { show: showLoading, hide: hideLoading } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { goBack } = useContext(OurNavContext);
  const customer = useCustomer();
  const [isLoading, setIsLoading] = useState(false);
  const setLoading = useCallback(
    (loading: boolean) => {
      if (loading) {
        showLoading();
      } else {
        hideLoading();
      }
      setIsLoading(loading);
    },
    [setIsLoading, hideLoading, showLoading]
  );
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [reEnterNewEmail, setReEnterNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const isCurrentEmailValid = useMemo(() => {
    return customer && currentEmail !== "" && currentEmail === customer.email;
  }, [customer, currentEmail]);
  const isNewEmailValid = useMemo(() => {
    return (
      customer &&
      newEmail !== "" &&
      newEmail !== customer.email &&
      validateEmail(newEmail)
    );
  }, [customer, newEmail]);
  const isReEnterEmailValid = useMemo(() => {
    return reEnterNewEmail !== "" && reEnterNewEmail === newEmail;
  }, [reEnterNewEmail, newEmail]);
  const isSubmitButtonDisabled = useMemo(() => {
    return (
      isLoading ||
      !isCurrentEmailValid ||
      !isNewEmailValid ||
      !isReEnterEmailValid ||
      password === ""
    );
  }, [
    isLoading,
    isCurrentEmailValid,
    isNewEmailValid,
    isReEnterEmailValid,
    password,
  ]);

  const onCurrentEmailInputChange = useCallback(
    (value: string) => {
      setCurrentEmail(value);
    },
    [setCurrentEmail]
  );
  const onNewEmailInputChange = useCallback(
    (value: string) => {
      setNewEmail(value);
    },
    [setNewEmail]
  );
  const onReEnterNewEmailInputChange = useCallback(
    (value: string) => {
      setReEnterNewEmail(value);
    },
    [setReEnterNewEmail]
  );
  const onPasswordInputChange = useCallback(
    (value: string) => {
      setPassword(value);
    },
    [setPassword]
  );

  const updateMyCustomerEmailRequest = useUpdateMyCustomerEmailRequest();
  const onSubmitButtonClick = useCallback(async () => {
    setLoading(true);
    try {
      await updateMyCustomerEmailRequest(newEmail, password);
      goBack();
      appEventEmitter.publish(AppEventOnSubmitChangeEmailSuccess);
    } catch (e) {
      const errorMessage = e.message;
      presentLocalizedAlert({
        messageId: errorMessage ? undefined : "alert.error.message",
        message: errorMessage ? errorMessage : undefined,
        buttons: [{ textMessageID: "try_again" }],
      });
    } finally {
      setLoading(false);
    }
  }, [
    newEmail,
    password,
    updateMyCustomerEmailRequest,
    setLoading,
    goBack,
    presentLocalizedAlert,
  ]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="page.change_email.change_email" />
        }
        headerRight={
          <IonButton
            className={styles.submitButton}
            disabled={isSubmitButtonDisabled}
            onClick={onSubmitButtonClick}
          >
            <LocalizedText messageID="page.change_email.submit" />
          </IonButton>
        }
      />
      <CLContent>
        <div className={styles.content}>
          <div className={styles.basicInfoContainer}>
            <Input
              className={cn(
                styles.input,
                currentEmail !== "" && !isCurrentEmailValid && styles.error
              )}
              value={currentEmail}
              onChange={onCurrentEmailInputChange}
              titleMessageID="page.change_email.current_email"
              type="email"
            />
            <Input
              className={cn(
                styles.input,
                newEmail !== "" && !isNewEmailValid && styles.error
              )}
              value={newEmail}
              onChange={onNewEmailInputChange}
              titleMessageID="page.change_email.new_email"
              type="email"
            />
            <Input
              className={cn(
                styles.input,
                reEnterNewEmail !== "" && !isReEnterEmailValid && styles.error
              )}
              value={reEnterNewEmail}
              onChange={onReEnterNewEmailInputChange}
              titleMessageID="page.change_email.re_enter_email"
              type="email"
            />
            <PasswordInputField
              className={styles.input}
              value={password}
              onChange={onPasswordInputChange}
              titleMessageID="page.change_email.password"
            />
          </div>
        </div>
      </CLContent>
    </>
  );
};

export default ChangeEmailPage;
