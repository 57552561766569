import React from "react";
import { TheClubSSOConfirmationStatus } from "../../useCase/AuthUseCase";
import CLContent from "../CLContent";
import CLModal from "../CLModal";
import ForShoppingLoginLinkedWithTheClubContent from "./ForShoppingLoginLinkedWithTheClubContent";
import ForShoppingLoginNotLinkedWithTheClubContent from "./ForShoppingLoginNotLinkedWithTheClubContent";
import ForTheClubLoginContent from "./ForTheClubLoginContent";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  theClubSSOConfirmationStatus: Exclude<
    TheClubSSOConfirmationStatus,
    { type: "NoActionRequired" }
  > | null;

  onConfirmClick: (marketingMaterials: boolean) => void;
  onTheClubUserLinkWithAnotherAccountClick: (
    marketingMaterials: boolean
  ) => void;
  onClubShoppingUserLinkWithAnotherAccountClick: (
    method: LoginSignUpMethod,
    marketingMaterials: boolean
  ) => void;
  onClubShoppingUserLinkWithTheClubClick: (method: LoginSignUpMethod) => void;
  onClubShoppingUserSwitchAccountClick: (method: LoginSignUpMethod) => void;
}

const TheClubSSOConfirmationModals: React.FC<Props> = props => {
  const {
    isOpen,
    theClubSSOConfirmationStatus,
    onConfirmClick,
    onTheClubUserLinkWithAnotherAccountClick,
    onClubShoppingUserLinkWithAnotherAccountClick,
    onClubShoppingUserLinkWithTheClubClick,
    onClubShoppingUserSwitchAccountClick,
  } = props;

  return (
    <CLModal
      isOpen={isOpen}
      onRequestDismiss={noop}
      backdropDismiss={false}
      allowAndroidBack={false}
      className={styles.modal}
    >
      <CLContent className={styles.content} scrollY={true}>
        {theClubSSOConfirmationStatus ==
        null ? null : theClubSSOConfirmationStatus.type ===
          "SimpleSSOConfirmationRequired" ? (
          <ForTheClubLoginContent
            email={theClubSSOConfirmationStatus.email}
            onConfirmClick={onConfirmClick}
            onLinkAnotherAccountClick={onTheClubUserLinkWithAnotherAccountClick}
          />
        ) : theClubSSOConfirmationStatus.type ===
          "SimpleSSOConfirmationRequiredWithTheClubInfo" ? (
          <ForShoppingLoginLinkedWithTheClubContent
            method={theClubSSOConfirmationStatus.method}
            originalEmail={theClubSSOConfirmationStatus.originalEmail}
            email={theClubSSOConfirmationStatus.email}
            clubMemberID={theClubSSOConfirmationStatus.membershipID}
            onConfirmClick={onConfirmClick}
            onLinkAnotherAccountClick={
              onClubShoppingUserLinkWithAnotherAccountClick
            }
          />
        ) : (
          <ForShoppingLoginNotLinkedWithTheClubContent
            method={theClubSSOConfirmationStatus.method}
            onLinkWithTheClubClick={onClubShoppingUserLinkWithTheClubClick}
            onSwitchAccountClick={onClubShoppingUserSwitchAccountClick}
          />
        )}
      </CLContent>
    </CLModal>
  );
};

export default TheClubSSOConfirmationModals;

function noop() {}
