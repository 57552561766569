import { Cart, CartItem, RecurringOption } from "../../models/cart";
import { LuckyDraw, isLuckyDrawSku } from "../../models/LuckyDraw";
import { MerchantPreview } from "../../models/Merchant";
import {
  CLClubPoint,
  ModelKeys as ProductModelKeys,
  PriceRange,
  ProductConfigurableAttributeOption,
  ProductConfigurableOption,
  ProductDeliveryMethod,
  ProductEstimatedDeliveryDate,
  ProductImage,
  ProductStockStatus,
  ProductType,
  RemoteProductEstimatedDeliveryDate,
  transformRemoteEstimatedDeliveryDate,
} from "../../models/product";
import { Override } from "../../utils/type";

export type PartialMerchant = Pick<MerchantPreview, "name">;

export type Product = ProductModelKeys & {
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;

  merchant: [PartialMerchant | null];

  stockStatus: ProductStockStatus;

  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodLabel: string | null;
  deliveryMethodBlockIdentifier: string | null;

  configurableOptions?: ProductConfigurableOption[] | null;
  variants?: ProductVariant[] | null;
} & ProductEstimatedDeliveryDate;

export interface ProductVariant {
  product: ConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}

export type ConfiguredProduct = ProductModelKeys & {
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  clubPoint: number;
  minClubPoint: number;
  priceRange: PriceRange | null;

  merchant: [PartialMerchant | null];

  deliveryMethod: ProductDeliveryMethod | null;
  deliveryMethodLabel: string | null;

  stockStatus: ProductStockStatus;
} & ProductEstimatedDeliveryDate;

export interface PartialCartItem
  extends Override<
    CartItem,
    {
      product: Product;
    }
  > {
  isRecurring: boolean;
  recurringOptions: RecurringOption[] | null;
}

interface Coupon {
  code: string;
}

export interface PartialCart
  extends Override<
    Cart,
    {
      items: PartialCartItem[];
    }
  > {
  appliedCoupon?: Coupon | null;
  appliedCoupons?: Coupon[] | null;
}

export function getAppliedCoupon(
  cart: Pick<PartialCart, "appliedCoupon" | "appliedCoupons">
) {
  const { appliedCoupon, appliedCoupons } = cart;
  if (appliedCoupon != null) {
    return appliedCoupon.code;
  }
  if (appliedCoupons != null && appliedCoupons.length) {
    return appliedCoupons[0].code;
  }
  return null;
}

export type LuckyDrawCartItem = Override<
  PartialCartItem,
  { product: LuckyDraw<Product> }
>;

export function isLuckyDrawCartItem(
  item: PartialCartItem
): item is LuckyDrawCartItem {
  return isLuckyDrawSku(item.product.sku);
}

// Remote override

export type RemoteProduct = Override<
  Product,
  RemoteProductEstimatedDeliveryDate & {
    variants?: RemoteProductVariant[] | null;
  }
>;

export type RemoteProductVariant = Override<
  ProductVariant,
  {
    product: RemoteConfiguredProduct;
  }
>;

export type RemoteConfiguredProduct = Override<
  ConfiguredProduct,
  RemoteProductEstimatedDeliveryDate
>;

export type RemotePartialCartItem = Override<
  PartialCartItem,
  {
    product: RemoteProduct;
  }
>;

export type RemotePartialCart = Override<
  PartialCart,
  {
    items: RemotePartialCartItem[];
  }
>;

export function transformRemotePartialCartItemToCartItem(
  remote: RemotePartialCartItem
): PartialCartItem {
  const { product } = remote;
  return {
    ...remote,
    product: {
      ...product,
      estimatedDeliveryDate: product.estimatedDeliveryDate
        ? transformRemoteEstimatedDeliveryDate(product.estimatedDeliveryDate)
        : null,
      variants: product.variants
        ? product.variants.map(v => ({
            ...v,
            product: {
              ...v.product,
              estimatedDeliveryDate: v.product.estimatedDeliveryDate
                ? transformRemoteEstimatedDeliveryDate(
                    v.product.estimatedDeliveryDate
                  )
                : null,
            },
          }))
        : null,
    },
  };
}

export function transformRemotePartialCartToPartialCart(
  remote: RemotePartialCart
): PartialCart {
  return {
    ...remote,
    items: remote.items.map(i => transformRemotePartialCartItemToCartItem(i)),
  };
}
