import * as yup from "yup";

export interface ServerStatus {
  maintenance: boolean;
}

export const ServerStatusSchema = yup
  .object<ServerStatus>({
    maintenance: yup.boolean().required(),
  })
  .camelCase();
