import React from "react";

import orderedEvoucherGif from "../../resources/ordered-evoucher.gif";
import styles from "./EVoucherAccessory.module.scss";

export const EVoucherAccessory: React.FC = () => {
  return (
    <div className={styles.evoucherAccessory}>
      <img src={orderedEvoucherGif} />
    </div>
  );
};
