import { addPerformanceRecord } from "./PerformanceRecordStore";
import { Session } from "./PerformanceRecordStore/sessions";

export async function profileAsyncAction<T>(
  session: Session,
  event: string,
  action: () => Promise<T>
): Promise<T> {
  const startTime: number = performance.now();

  const result = await action();

  const endTime: number = performance.now();

  addPerformanceRecord(session, event, startTime, endTime);

  return result;
}

export function profiledAsyncActionFn<T, Args extends unknown[]>(
  session: Session,
  event: string,
  action: (...args: Args) => Promise<T>
): typeof action {
  return (...args: Args) =>
    profileAsyncAction(session, event, () => action(...args));
}

export function profileSyncAction<T>(
  session: Session,
  event: string,
  action: () => T
): T {
  const startTime: number = performance.now();

  const result = action();

  const endTime: number = performance.now();

  addPerformanceRecord(session, event, startTime, endTime);

  return result;
}
