import React, { useCallback } from "react";
import {
  appEventEmitter,
  AppEventOnClickViewMore,
} from "../utils/SimpleEventEmitter";

export function useOnClickViewMoreButton(url: string | null | undefined) {
  return useCallback(
    (e?: React.MouseEvent<unknown>) => {
      if (!url) {
        return;
      }
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      appEventEmitter.publish(AppEventOnClickViewMore(url));
    },
    [url]
  );
}
