import React, { useMemo, useContext } from "react";
import classnames from "classnames";
import styles from "./OrderDetails.module.scss";
import commonStyles from "./styles.module.scss";
import { MessageID } from "../../i18n/translations/type";
import { LocalizedText } from "../../i18n/Localization";
import {
  OtoStore,
  useFormatOtoStore,
  getOtoStoreTypeMessageID,
  ShippingAddress,
  formatAddress,
  BillingAddress,
  isAddressEmpty,
  INSTALMENT_PAYMENT_METHOD,
} from "../../models/Order";
import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";

interface OrderDetailsProps {
  deliveryMethod: string;
  deliveryTime: string | null;
  billingAddress: BillingAddress[] | null;
  shippingAddress: ShippingAddress[] | null;
  pickupAddress: OtoStore | null;
  paymentMethod: string;
}

const OrderDetails: React.FC<OrderDetailsProps> = props => {
  const {
    deliveryMethod,
    deliveryTime,
    shippingAddress,
    pickupAddress,
    billingAddress,
    paymentMethod,
  } = props;

  const { countryByID } = useContext(CountryRegionDistrictContext);

  const formatOtoStore = useFormatOtoStore();
  const addressHeader = useMemo<MessageID>(() => {
    if (pickupAddress != null) {
      return getOtoStoreTypeMessageID(pickupAddress) || "order_detail.address";
    }
    return "order_detail.address";
  }, [pickupAddress]);
  const addressStr = useMemo(() => {
    if (pickupAddress != null) {
      return formatOtoStore(pickupAddress);
    }

    return shippingAddress == null || shippingAddress.length === 0
      ? "-"
      : formatAddress(countryByID, shippingAddress[0]);
  }, [pickupAddress, shippingAddress, formatOtoStore, countryByID]);
  const billingAddressStr = useMemo(() => {
    return billingAddress == null ||
      billingAddress.length === 0 ||
      isAddressEmpty(billingAddress[0])
      ? "-"
      : formatAddress(countryByID, billingAddress[0]);
  }, [billingAddress, countryByID]);
  const items: OrderDetailsTableItem[] = useMemo(() => {
    if (pickupAddress != null) {
      return [
        { titleID: addressHeader, value: addressStr },
        {
          titleID: "order_detail.billing_address",
          value: (
            <>
              {billingAddressStr}
              {billingAddress != null &&
                billingAddress.length > 0 &&
                billingAddress[0].telephone != null && (
                  <>
                    <br />
                    T:{" "}
                    <a href={`tel:${billingAddress[0].telephone}`}>
                      {billingAddress[0].telephone}
                    </a>
                  </>
                )}
            </>
          ),
        },
        {
          titleID: "order_detail.payment_method",
          value: (
            <>
              <p>{paymentMethod}</p>
              {paymentMethod === INSTALMENT_PAYMENT_METHOD ? (
                <p className={styles.orderDetailsTablePaymentMethodNotes}>
                  <LocalizedText messageID="order_detail.payment_method.instalment.notes" />
                </p>
              ) : null}
            </>
          ),
        },
      ];
    }
    return [
      {
        titleID: "order_detail.delivery_method",
        value: deliveryMethod === "" ? "-" : deliveryMethod,
      },
      {
        titleID: "order_detail.delivery_time",
        value: deliveryTime || "-",
      },
      {
        titleID: addressHeader,
        value: (
          <>
            {addressStr}
            {shippingAddress != null &&
              shippingAddress.length > 0 &&
              shippingAddress[0].telephone != null && (
                <>
                  <br />
                  T:{" "}
                  <a href={`tel:${shippingAddress[0].telephone}`}>
                    {shippingAddress[0].telephone}
                  </a>
                </>
              )}
          </>
        ),
      },
      {
        titleID: "order_detail.billing_address",
        value: (
          <>
            {billingAddressStr}
            {billingAddress != null &&
              billingAddress.length > 0 &&
              billingAddress[0].telephone != null && (
                <>
                  <br />
                  T:{" "}
                  <a href={`tel:${billingAddress[0].telephone}`}>
                    {billingAddress[0].telephone}
                  </a>
                </>
              )}
          </>
        ),
      },
      {
        titleID: "order_detail.payment_method",
        value: (
          <>
            <p>{paymentMethod}</p>
            {paymentMethod === INSTALMENT_PAYMENT_METHOD ? (
              <p className={styles.orderDetailsTablePaymentMethodNotes}>
                <LocalizedText messageID="order_detail.payment_method.instalment.notes" />
              </p>
            ) : null}
          </>
        ),
      },
    ];
  }, [
    pickupAddress,
    billingAddress,
    shippingAddress,
    deliveryMethod,
    deliveryTime,
    addressHeader,
    addressStr,
    paymentMethod,
    billingAddressStr,
  ]);
  return (
    <div
      className={classnames(
        styles.infoContainer,
        commonStyles.orderDetailSectionContainer
      )}
    >
      <OrderDetailsTable items={items} />
    </div>
  );
};

interface OrderDetailsTableItem {
  titleID: MessageID;
  value: string | React.ReactNode;
}

interface OrderDetailsTableProps {
  items: OrderDetailsTableItem[];
}

const OrderDetailsTable: React.FC<OrderDetailsTableProps> = props => {
  const { items } = props;
  return (
    <table className={styles.infoTable}>
      <tbody>
        {items.map((item, index) => (
          <tr key={index} className={styles.infoTableItem}>
            <td className={classnames(styles.infoTableCell, styles.title)}>
              <LocalizedText messageID={item.titleID} />
            </td>
            <td className={classnames(styles.infoTableCell, styles.content)}>
              {item.value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OrderDetails;
