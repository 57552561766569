import React, { useMemo } from "react";
import cn from "classnames";
import numeral from "numeral";
import { IonIcon } from "@ionic/react";

import { addMoneyAmount, formatMoney } from "../../models/Price";
import {
  calculateTotalDiscount,
  DiscountType,
  ProductSaleBundleProduct,
} from "../../models/ProductSaleBundle";

import { getListedMoney } from "../ProductDetailPage/ProductPriceViewUtils";

import styles from "./ProductPriceView.module.scss";

interface Props {
  productOfMinValue: Pick<
    ProductSaleBundleProduct,
    "priceRange" | "manufacturerSuggestedRetailPrice" | "minClubPoint"
  >;
  productOfMaxValue: Pick<
    ProductSaleBundleProduct,
    "priceRange" | "manufacturerSuggestedRetailPrice" | "minClubPoint"
  >;
  discountType: DiscountType;
  discountAmount: number;
}

const ProductPriceView: React.FC<Props> = React.memo(props => {
  const {
    productOfMinValue,
    productOfMaxValue,
    discountType,
    discountAmount,
  } = props;
  const listedMoney = useMemo(
    () => getListedMoney(productOfMinValue, productOfMaxValue),
    [productOfMinValue, productOfMaxValue]
  );

  return (
    <div className={styles.productPriceView}>
      {listedMoney ? (
        <span
          className={cn(styles.productPriceText, styles.productOriginalPrice, {
            [styles.productPriceCrossed]: discountAmount,
          })}
        >
          {listedMoney.type === "single" ? (
            <span className={styles.priceRow}>
              {formatMoney(listedMoney.money)}
            </span>
          ) : listedMoney.type === "singleMinCP" ? (
            <span className={styles.priceRow}>
              <IonIcon name="club-point" className={styles.clubPointIcon} />
              {numeral(listedMoney.minClubPoint).format("0,0")} +{" "}
              {formatMoney(listedMoney.money)}
            </span>
          ) : listedMoney.type === "range" ? (
            <span className={styles.priceRow}>
              {formatMoney(listedMoney.minMoney)}
            </span>
          ) : listedMoney.type === "cpOnly" ? (
            <span className={styles.priceRow}>
              <IonIcon name="club-point" className={styles.clubPointIcon} />
              {numeral(listedMoney.clubPoint).format("0,0")}
            </span>
          ) : null}
        </span>
      ) : null}
      {discountAmount && listedMoney ? (
        <span className={cn(styles.productPriceText, styles.withSpecialPrice)}>
          {listedMoney.type === "single" ? (
            <span className={styles.priceRow}>
              {formatMoney(
                addMoneyAmount(
                  -calculateTotalDiscount(discountType, [
                    { qty: 1, price: listedMoney.money.value, discountAmount },
                  ]),
                  listedMoney.money
                )
              )}
            </span>
          ) : listedMoney.type === "singleMinCP" ? (
            <span className={styles.priceRow}>
              <IonIcon name="club-point" className={styles.clubPointIcon} />
              {numeral(listedMoney.minClubPoint).format("0,0")} +{" "}
              {formatMoney(
                addMoneyAmount(
                  -calculateTotalDiscount(discountType, [
                    { qty: 1, price: listedMoney.money.value, discountAmount },
                  ]),
                  listedMoney.money
                )
              )}
            </span>
          ) : listedMoney.type === "range" ? (
            <span className={styles.priceRow}>
              {formatMoney(
                addMoneyAmount(
                  -calculateTotalDiscount(discountType, [
                    {
                      qty: 1,
                      price: listedMoney.minMoney.value,
                      discountAmount,
                    },
                  ]),
                  listedMoney.minMoney
                )
              )}
            </span>
          ) : listedMoney.type === "cpOnly" ? (
            <span className={styles.priceRow}>
              <IonIcon name="club-point" className={styles.clubPointIcon} />
              {numeral(listedMoney.clubPoint).format("0,0")}
            </span>
          ) : null}
        </span>
      ) : null}
    </div>
  );
});

export default ProductPriceView;
