import {
  InAppBrowser,
  InAppBrowserOptions,
  InAppBrowserEvent,
} from "@ionic-native/in-app-browser";
import { Plugins } from "@capacitor/core";
import { Locale } from "../i18n/locale";

import Config from "../Config";

import { isAndroid } from "./Platform";
import { redirectUri } from "./TheClubLogin";

const { Device } = Plugins;

const useBeforeLoad = isAndroid();

function getInAppBrowserOptions(
  closebuttoncaption: string
): InAppBrowserOptions {
  return {
    zoom: "no",
    location: "no",
    clearsessioncache: "yes",
    beforeload: useBeforeLoad ? "yes" : undefined,
    clearcache: "yes",
    footer: "yes",
    closebuttoncaption,
  };
}

function getLocale(locale: Locale) {
  switch (locale) {
    case Locale.en:
      return "en";
    case Locale.zhHant:
      return "zh";
    default:
      return null;
  }
}

function createSignupUrl(locale: Locale) {
  if (!Config.THE_CLUB_UAT_CONFIG.SITE_URL) {
    return null;
  }
  const l = getLocale(locale);
  if (!l) {
    return null;
  }
  return `${Config.THE_CLUB_UAT_CONFIG.SITE_URL}${l}/signup.html`;
}

function theClubSignupWebPopup(url: string): Promise<void> {
  return new Promise(resolve => {
    let interval: number | undefined;
    const clearInterval = () => {
      if (interval != null) {
        window.clearInterval(interval);
        interval = undefined;
      }
    };
    const height = 820;
    const width = 510;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const externalWindow = window.open(
      url,
      "The Club",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!externalWindow || !externalWindow.window) {
      throw Error("cannot-open-window");
    }

    externalWindow.opener = null;
    const trackBrowserClose = () => {
      // Clear interval if externalWindow is closed
      if (
        !externalWindow ||
        !externalWindow.window ||
        externalWindow.closed ||
        externalWindow.window.closed
      ) {
        clearInterval();
        resolve();
      }
    };
    interval = window.setInterval(trackBrowserClose, 500);
  });
}

export function presentTheClubSignupBrowser(
  locale: Locale,
  closebuttoncaption: string
): Promise<void> {
  return new Promise(resolve => {
    const signupUrl = createSignupUrl(locale);
    if (!signupUrl) {
      resolve();
      return;
    }
    Device.getInfo().then(deviceInfo => {
      if (deviceInfo.platform !== "web") {
        const browser = InAppBrowser.create(
          signupUrl,
          "_blank",
          getInAppBrowserOptions(closebuttoncaption)
        );
        const loadstart = browser.on("loadstart");
        if (loadstart) {
          const loadstartListener = loadstart.subscribe(
            (event: InAppBrowserEvent) => {
              const eventCallback = encodeURI(event.url);
              //Check the redirect uri
              if (eventCallback.includes(Config.THE_CLUB_UAT_CONFIG.BACK_URI)) {
                loadstartListener.unsubscribe();
                browser.close();
                resolve();
              }
              if (eventCallback.includes(redirectUri)) {
                loadstartListener.unsubscribe();
                browser.close();
                resolve();
              }
            }
          );
        }

        if (useBeforeLoad) {
          const beforeload = browser.on("beforeload");
          if (beforeload) {
            const beforeloadListener = beforeload.subscribe(
              async (event: InAppBrowserEvent) => {
                const eventCallback = encodeURI(event.url);
                //Check the redirect uri
                if (
                  eventCallback.includes(Config.THE_CLUB_UAT_CONFIG.BACK_URI)
                ) {
                  beforeloadListener.unsubscribe();
                  browser.close();
                  resolve();
                  return;
                }
                if (eventCallback.includes(redirectUri)) {
                  beforeloadListener.unsubscribe();
                  browser.close();
                  resolve();
                  return;
                }
                browser._loadAfterBeforeload(event.url);
              }
            );
          }
        }

        const exit = browser.on("exit");
        if (exit) {
          const exitListener = exit.subscribe(() => {
            exitListener.unsubscribe();
            resolve();
          });
        }
      } else {
        resolve(theClubSignupWebPopup(signupUrl));
      }
    });
  });
}
