import React, { useCallback, useContext, useMemo } from "react";
import cn from "classnames";

import { LocalizedText, useIntl } from "../../i18n/Localization";
import { FormError } from "../../utils/type";

import { Address, AddressKey, getUpdatedAddress } from "../../models/Customer";

import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";

import Input from "./Input";
import Select from "./Select";

import styles from "./AddressForm.module.scss";
import { useAddressFormOptions } from "./AddressFormHooks";

interface Props {
  address: Address;
  onChange: (address: Address) => void;
  formError?: FormError<Address>;
  onNonDeliveryAreaLinkClick?: () => void;
}

const AddressForm: React.FC<Props> = props => {
  const {
    address,
    onChange,
    formError = {},
    onNonDeliveryAreaLinkClick,
  } = props;
  const { translate } = useIntl();

  const { countries, regionsByCountryID, districtsByRegionID } = useContext(
    CountryRegionDistrictContext
  );

  const {
    countryOptions,
    regionOptions,
    districtOptions,
  } = useAddressFormOptions(
    address,
    countries,
    regionsByCountryID,
    districtsByRegionID
  );

  const handleChange = useCallback(
    (field: AddressKey, value: string) => {
      onChange(getUpdatedAddress(address, field, value));
    },
    [onChange, address]
  );

  const handleFirstNameChange = useMemo(
    () => handleChange.bind(null, "firstName"),
    [handleChange]
  );
  const handleLastNameChange = useMemo(
    () => handleChange.bind(null, "lastName"),
    [handleChange]
  );
  const handleCompanyChange = useMemo(
    () => handleChange.bind(null, "company"),
    [handleChange]
  );
  const handleTelephoneChange = useMemo(
    () => handleChange.bind(null, "telephone"),
    [handleChange]
  );

  const handleRegionChange = useMemo(() => handleChange.bind(null, "region"), [
    handleChange,
  ]);
  const handleAreaChange = useMemo(() => handleChange.bind(null, "area"), [
    handleChange,
  ]);
  const handleDistrictChange = useMemo(
    () => handleChange.bind(null, "district"),
    [handleChange]
  );
  const handleStreetChange = useMemo(() => handleChange.bind(null, "street"), [
    handleChange,
  ]);
  const handleBuildingChange = useMemo(
    () => handleChange.bind(null, "building"),
    [handleChange]
  );
  const handleBlockChange = useMemo(() => handleChange.bind(null, "block"), [
    handleChange,
  ]);
  const handleFlatOrFloorChange = useMemo(
    () => handleChange.bind(null, "flatOrFloor"),
    [handleChange]
  );
  const handleRoomNumberChange = useMemo(
    () => handleChange.bind(null, "roomNumber"),
    [handleChange]
  );

  const showNonDeliveryAreaLink = onNonDeliveryAreaLinkClick != null;

  const handleNonDeliveryAreaClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (onNonDeliveryAreaLinkClick) {
        onNonDeliveryAreaLinkClick();
      }
    },
    [onNonDeliveryAreaLinkClick]
  );

  return (
    <div>
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.firstName,
        })}
        titleMessageID="address_form.first_name"
        value={address.firstName}
        onChange={handleFirstNameChange}
        errorMessage={
          formError.firstName &&
          translate("field.error_message", {
            field: translate("address_form.first_name"),
          })
        }
        required={true}
      />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.lastName,
        })}
        titleMessageID="address_form.last_name"
        value={address.lastName}
        onChange={handleLastNameChange}
        errorMessage={
          formError.lastName &&
          translate("field.error_message", {
            field: translate("address_form.last_name"),
          })
        }
        required={true}
      />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.company,
        })}
        titleMessageID="address_form.company"
        value={address.company}
        onChange={handleCompanyChange}
        errorMessage={
          formError.company &&
          translate("field.error_message", {
            field: translate("address_form.company"),
          })
        }
      />
      <div className={styles.fieldSpacer} />
      {showNonDeliveryAreaLink ? (
        <button onClick={handleNonDeliveryAreaClick} className={styles.link}>
          <LocalizedText messageID="address_form.non_delivery_area" />
        </button>
      ) : null}
      <Select
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.region,
        })}
        titleMessageID="address_form.region"
        placeholderID="address_form.region.placeholder"
        value={address.region}
        onChange={handleRegionChange}
        options={countryOptions}
        errorMessage={
          formError.region &&
          translate("field.error_message", {
            field: translate("address_form.region"),
          })
        }
        required={true}
      />
      <Select
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.area,
        })}
        titleMessageID="address_form.area"
        placeholderID="address_form.area.placeholder"
        value={address.area}
        onChange={handleAreaChange}
        options={regionOptions}
        errorMessage={
          formError.area &&
          translate("field.error_message", {
            field: translate("address_form.area"),
          })
        }
        required={true}
      />
      <Select
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.district,
        })}
        titleMessageID="address_form.district"
        placeholderID="address_form.district.placeholder"
        value={address.district}
        onChange={handleDistrictChange}
        options={districtOptions}
        errorMessage={
          formError.district &&
          translate("field.error_message", {
            field: translate("address_form.district"),
          })
        }
        required={true}
      />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.street,
        })}
        titleMessageID="address_form.street"
        placeholderId="address_form.street.example"
        value={address.street || ""}
        onChange={handleStreetChange}
        errorMessage={
          formError.street &&
          translate("field.error_message", {
            field: translate("address_form.street"),
          })
        }
        required={true}
      />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.building,
        })}
        titleMessageID="address_form.building"
        placeholderId="address_form.building.example"
        value={address.building || ""}
        onChange={handleBuildingChange}
        errorMessage={
          formError.building &&
          translate("field.error_message", {
            field: translate("address_form.building"),
          })
        }
      />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.block,
        })}
        titleMessageID="address_form.block"
        placeholderId="address_form.block.example"
        value={address.block || ""}
        onChange={handleBlockChange}
        errorMessage={
          formError.block &&
          translate("field.error_message", {
            field: translate("address_form.block"),
          })
        }
      />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.flatOrFloor,
        })}
        titleMessageID="address_form.flat_or_floor"
        placeholderId="address_form.flat_or_floor.example"
        value={address.flatOrFloor || ""}
        onChange={handleFlatOrFloorChange}
        errorMessage={
          formError.flatOrFloor &&
          translate("field.error_message", {
            field: translate("address_form.flat_or_floor"),
          })
        }
      />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.roomNumber,
        })}
        titleMessageID="address_form.room_number"
        placeholderId="address_form.room_number.example"
        value={address.roomNumber || ""}
        onChange={handleRoomNumberChange}
        errorMessage={
          formError.roomNumber &&
          translate("field.error_message", {
            field: translate("address_form.room_number"),
          })
        }
      />
      <div className={styles.fieldSpacer} />
      <Input
        className={styles.input}
        fieldClassName={cn(styles.inputField, {
          [styles.errorField]: formError.telephone,
        })}
        titleMessageID="address_form.telephone"
        type="tel"
        maxLength={8}
        value={address.telephone}
        onChange={handleTelephoneChange}
        errorMessage={
          formError.telephone &&
          translate("field.error_message", {
            field: translate("address_form.telephone"),
          })
        }
        required={true}
      />
    </div>
  );
};

export default AddressForm;
