import {
  useContext,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";

import { PaginationInfo } from "./State";
import ArticleContext from "../contexts/ArticleContext";
import { useFetchResources, useFetchResources_v2 } from "./Hooks";
import ConfigContext from "../contexts/ConfigContext";

import {
  fetchArticleList,
  restAPIClient,
  fetchAllArticleCategories,
  fetchArticleByID,
  fetchAuthorByID,
  fetchAllArticleTagsByIDs,
} from "../api/RESTful";

import {
  ArticlePreview,
  ArticleCategory,
  Article,
  ArticleFilter,
  ArticleAuthor,
  ArticleTag,
} from "../models/Article";

import { useIntl } from "../i18n/Localization";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { getAllArticleCategories, storeAllArticleCategories } from "../storage";

export function useFetchArticleList(query?: {
  type: ArticleFilter;
  id: string;
}): {
  requestState: ResourcesRequestState<unknown | null>;
  paginationInfo: PaginationInfo<ArticlePreview> | null;
  fetchNext: () => void;
  refresh: () => void;
} {
  const { waitForConfigs } = useContext(ConfigContext);
  const { locale } = useIntl();

  const isFetching = useRef(false);
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    ArticlePreview
  > | null>(null);
  const paginationInfoRef = useRef(paginationInfo);
  paginationInfoRef.current = paginationInfo;
  const [forceFetchKey, setForceFetchKey] = useState(0);

  const queryType = query != null ? query.type : null;
  const queryId = query != null ? query.id : null;
  const query_ = useMemo(
    () =>
      queryType != null && queryId != null
        ? {
            type: queryType,
            id: queryId,
          }
        : null,
    [queryType, queryId]
  );
  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () => Promise.resolve(null),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () => {
        return waitForConfigs(locale).then(({ storeConfig }) => {
          return fetchArticleList(restAPIClient, query_, page, storeConfig);
        });
      },
      didFetchFromRemote: result => {
        isFetching.current = false;
        if (result) {
          const { articles, currentPage, lastPage } = result;
          setPaginationInfo(p => {
            const items: ArticlePreview[] =
              page === 1 || p == null ? [] : p.items;
            items.push(...articles);
            return {
              items,
              currentPage,
              hasMore: currentPage < lastPage,
            };
          });
        }
      },
    },
    [forceFetchKey, page]
  );

  const fetchNext = useCallback(() => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return;
    }
    if (isFetching.current) {
      return;
    }
    isFetching.current = true;
    if (_paginationInfo == null) {
      startRequesting();
    } else if (_paginationInfo.hasMore) {
      setPage(_paginationInfo.currentPage + 1);
    }
  }, [startRequesting]);

  const refresh = useCallback(() => {
    setPage(1);
    setForceFetchKey(k => k + 1);
  }, []);

  return {
    requestState,
    paginationInfo,
    fetchNext,
    refresh,
  };
}

export function useFetchPopularArticleList(): {
  requestState: ResourcesRequestState<{
    articles: ArticlePreview[];
  }>;
  fetch: () => void;
  refresh: () => void;
} {
  const { waitForConfigs } = useContext(ConfigContext);
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    {
      articles: ArticlePreview[];
    },
    () => Promise<{
      articles: ArticlePreview[];
    }>
  >({
    remoteResourcesProvider: () => {
      return waitForConfigs(locale).then(({ appConfig, storeConfig }) => {
        return fetchArticleList(
          restAPIClient,
          {
            type: "category",
            id: String(appConfig.popularArticleCategory),
          },
          1,
          storeConfig
        );
      });
    },
  });

  return {
    requestState,
    fetch,
    refresh,
  };
}

export function useFetchAllArticleCategories(): {
  requestState: ResourcesRequestState<ArticleCategory[] | null>;
  fetch: () => void;
  retry: () => void;
} {
  const { categoryById, updateArticleCategories } = useContext(ArticleContext);
  const { waitForConfigs } = useContext(ConfigContext);
  const { locale } = useIntl();

  const { requestState, startRequesting, retry } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => {
        return Object.values(categoryById) as ArticleCategory[];
      },
      localCacheProvider: () => getAllArticleCategories(locale),
      didFetchFromLocalCache: categories => {
        if (categories) {
          updateArticleCategories(categories);
        }
      },
      remoteResourcesProvider: () => {
        return waitForConfigs(locale).then(({ storeConfig }) => {
          return fetchAllArticleCategories(restAPIClient, storeConfig);
        });
      },
      didFetchFromRemote: categories => {
        if (categories) {
          storeAllArticleCategories(categories, locale);
          updateArticleCategories(categories);
        }
      },
    },
    []
  );

  const fetch = useCallback(() => {
    startRequesting();
  }, [startRequesting]);

  return {
    requestState,
    fetch,
    retry,
  };
}

export function useFetchArticleByID(
  articleID: string
): { requestState: ResourcesRequestState<Article | null>; retry: () => void } {
  const { articleById, updateArticle } = useContext(ArticleContext);
  const { waitForConfigs } = useContext(ConfigContext);

  const { locale } = useIntl();

  const articleInMemory = articleById[articleID] || null;
  const { requestState, startRequesting, retry } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => articleInMemory,
      localCacheProvider: () => Promise.resolve(null),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () => {
        return waitForConfigs(locale).then(({ storeConfig }) => {
          return fetchArticleByID(restAPIClient, articleID, storeConfig);
        });
      },
      didFetchFromRemote: article => {
        if (article) {
          updateArticle(article);
        }
      },
    },
    [articleID, locale]
  );

  useEffect(() => {
    startRequesting();
  }, [startRequesting]);

  return { requestState, retry };
}

export function useFetchAuthorByID(): [
  ResourcesRequestState<ArticleAuthor | null>,
  (authorId: string) => Promise<ArticleAuthor | null>
] {
  const { authorById, updateArticleAuthor } = useContext(ArticleContext);

  const [requestState, { call }] = useFetchResources_v2<
    ArticleAuthor | null,
    (authorId: string) => Promise<ArticleAuthor | null>
  >({
    memoryCacheProvider: (authorId: string) =>
      Promise.resolve(authorById[authorId] || null),
    remoteResourcesProvider: async (authorId: string) => {
      const author = await fetchAuthorByID(restAPIClient, authorId);
      if (author != null) {
        updateArticleAuthor(authorId, author);
      }
      return author;
    },
  });

  return [requestState, call];
}

export function useFetchAllArticleTagsByIDs(): [
  ResourcesRequestState<ArticleTag[] | null>,
  (tagIds: string[]) => Promise<ArticleTag[] | null>
] {
  const { tagById, UpdateArticleTags } = useContext(ArticleContext);

  const [requestState, { call }] = useFetchResources_v2<
    ArticleTag[] | null,
    (tagIds: string[]) => Promise<ArticleTag[] | null>
  >({
    memoryCacheProvider: (tagIds: string[]) => {
      const tags = tagIds.map(tagId => tagById[tagId] || null);
      if (tags.some(x => x == null)) return Promise.resolve(null);
      return Promise.resolve(tags as ArticleTag[]);
    },
    remoteResourcesProvider: async (tagIds: string[]) => {
      const tags = await fetchAllArticleTagsByIDs(restAPIClient, tagIds);
      if (tags != null) {
        UpdateArticleTags(tags);
      }
      return tags;
    },
  });
  return [requestState, call];
}
