import { useApolloClient } from "@apollo/client";
import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import ProductDetailPage from ".";
import {
  fetchCampaignProductBySKU,
  fetchCampaignProductRelatedProductsBySKU,
  getCampaignProductBySKU,
  getCampaignProductRelatedProductsBySKU,
} from "../../api/Campaign";
import { ProfileSessionProvider } from "../../contexts/ProfileSessionContext";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";
import { useIntl } from "../../i18n/Localization";
import {
  isCampaignProductSoldOut,
  shouldCampaignProductShowPrice,
} from "../../models/product";
import { ProductOverview } from "../../models/ProductOverview";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { useMemoryCampaignProductDetail } from "../../repository/ProductRepository";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { ProductDetailPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { usePurchaseProductFormStateReducer } from "./PurchaseProductModal/PurchaseProductFormStateHook";
import { PageResource } from "./type";

interface RouteParams {
  campaignId: string;
  sku: string;
}

function usePageResource(
  campaignId: number,
  sku: string
): {
  requestState: ResourcesRequestState<PageResource | null>;
  startRequesting: () => Promise<PageResource | null>;
  refresh: () => Promise<PageResource | null>;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    PageResource | null,
    () => Promise<PageResource | null>
  >({
    localCacheProvider: async () => {
      const product = getCampaignProductBySKU(client, campaignId, sku);
      if (product) {
        return {
          product,
        };
      }
      return null;
    },
    remoteResourcesProvider: async () => {
      const product = await fetchCampaignProductBySKU(
        client,
        campaignId,
        sku,
        locale,
        "network-only"
      );
      if (product) {
        return {
          product,
        };
      }
      return null;
    },
  });

  return {
    requestState,
    startRequesting: fetch,
    refresh,
  };
}

function useRelatedProductsResource(
  campaignId: number,
  sku: string
): {
  requestState: ResourcesRequestState<ProductOverview[]>;
  startRequesting: () => Promise<ProductOverview[]>;
  refresh: () => Promise<ProductOverview[]>;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    ProductOverview[],
    () => Promise<ProductOverview[]>
  >({
    localCacheProvider: async () =>
      getCampaignProductRelatedProductsBySKU(client, campaignId, sku),
    remoteResourcesProvider: () =>
      fetchCampaignProductRelatedProductsBySKU(
        client,
        campaignId,
        sku,
        locale,
        "network-only"
      ),
  });

  return {
    requestState,
    startRequesting: fetch,
    refresh,
  };
}

const CampaignProductDetailPageImpl: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { sku, campaignId: _campaignId } = match.params;
  const campaignId = useMemo(() => parseInt(_campaignId, 10), [_campaignId]);

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const productDetailInMemory = useMemoryCampaignProductDetail(campaignId, sku);

  const {
    requestState: productResourcesState,
    startRequesting: startRequestingProduct,
    refresh: refreshProduct,
  } = usePageResource(campaignId, sku);

  const {
    requestState: relatedProductsResourcesState,
    startRequesting: startRequestingRelatedProducts,
    refresh: refreshRelatedProducts,
  } = useRelatedProductsResource(campaignId, sku);

  const purchaseProductFormStateReducer = usePurchaseProductFormStateReducer();
  const configProductFormState = purchaseProductFormStateReducer.formState;
  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = React.useCallback(() => {
    presentAddToCartModal(sku, configProductFormState, {
      campaignId,
      onAddToCartFinalResult: result => {
        if (
          result &&
          result.type === "failed" &&
          result.reason === "OUT_OF_STOCK"
        ) {
          refreshProduct();
        }
      },
    });
  }, [
    campaignId,
    configProductFormState,
    presentAddToCartModal,
    refreshProduct,
    sku,
  ]);

  return (
    <ProductDetailPage
      sku={sku}
      productDetailInMemory={productDetailInMemory}
      pageResourceState={productResourcesState}
      startRequestingPage={startRequestingProduct}
      refreshPage={refreshProduct}
      relatedProductsResouceState={relatedProductsResourcesState}
      startRequestingRelatedProducts={startRequestingRelatedProducts}
      refreshRelatedProducts={refreshRelatedProducts}
      ionLifeCycleContext={ionLifeCycleContext}
      purchaseProductFormStateReducer={purchaseProductFormStateReducer}
      onAddToCartClick={openPurchaseProductModal}
      allowShare={false}
      isSoldOut={isCampaignProductSoldOut}
      shouldShowPrice={shouldCampaignProductShowPrice}
    />
  );
};

const CampaignProductDetailPage: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { sku } = match.params;
  return (
    <ProfileSessionProvider profileSession={ProductDetailPageSession(sku)}>
      <CampaignProductDetailPageImpl />
    </ProfileSessionProvider>
  );
};

export default CampaignProductDetailPage;
