import React, { useMemo } from "react";
import { IonIcon } from "@ionic/react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { Money, formatMoney } from "../../models/Price";
import { MessageID } from "../../i18n/translations/type";
import { CartDiscountTypes } from "../../models/cart";
import { Customer, isCustomerLinkedToTheClub } from "../../models/Customer";

type OrderSummaryExtraItemMoneyType = "+" | "-";

export interface OrderSummaryExtraItem {
  titleID: MessageID;
  type: OrderSummaryExtraItemMoneyType;
  value: Money;
}

export function getMoneyTypeFromMoney(
  money: Money
): OrderSummaryExtraItemMoneyType {
  const { value } = money;
  return value < 0 ? "-" : "+";
}

interface Props {
  className?: string;
  customer: Customer | null;
  itemsCount: number;
  subtotal: Money;
  clubPointRequired: number;
  clubpointsRequiredItems:
    | {
        name: string;
        value: number;
      }[]
    | null;
  extraClubpointsUsed: number;
  clubpointsConversionCurrency: string;
  clubpointsConversionRate: number;
  extraItems: OrderSummaryExtraItem[];
  total: Money;
  discount: CartDiscountTypes;
  discountAmount: Money | null;
}

const OrderSummary: React.FC<Props> = props => {
  const {
    className,
    customer,
    subtotal,
    clubPointRequired,
    clubpointsRequiredItems,
    extraClubpointsUsed,
    clubpointsConversionCurrency,
    clubpointsConversionRate,
    extraItems,
    total,
    discount,
    discountAmount,
  } = props;

  /**
   * try best to format the discount amount, because the amount string is
   * returned from the server and not match the style of other amount string
   */
  const formattedDiscount = useMemo(() => {
    if (discount == null) {
      return null;
    }

    if (discount.type === "discountBreakdown") {
      if (discount.discountBreakdown.length === 0) {
        return null;
      }
      return discount.discountBreakdown.map(d => ({
        ruleName: d.ruleName,
        ruleAmount:
          d.ruleAmount[0] === "-"
            ? `- ${d.ruleAmount.substr(1)}`
            : d.ruleAmount,
      }));
    }

    if (discount.type === "discounts") {
      if (discount.discounts.length === 0) {
        return null;
      }
      return discount.discounts.map(d => ({
        ruleName: d.label,
        ruleAmount: `- ${formatMoney(d.amount)}`,
      }));
    }

    return null;
  }, [discount]);

  const shouldShowClubpointUsed = useMemo(() => {
    if (customer && isCustomerLinkedToTheClub(customer)) {
      return true;
    }
    if (clubpointsRequiredItems && clubpointsRequiredItems.length > 0) {
      return true;
    }
    return false;
  }, [customer, clubpointsRequiredItems]);

  return (
    <div className={classnames(className, styles.orderSummaryContainer)}>
      <div className={styles.title}>
        <LocalizedText messageID="order_summary" />
      </div>
      <OrderSummaryItem className={styles.row}>
        <LocalizedText messageID="order_summary.subtotal" />
        {`+ ${formatMoney(subtotal)}`}
      </OrderSummaryItem>
      {(discountAmount != null && discountAmount.value !== 0) ||
      formattedDiscount != null ? (
        <div className={styles.discountContainer}>
          <div className={styles.discountRulesContainer}>
            <div className={styles.discountRuleContainer}>
              <div className={styles.discountRuleName}>
                <LocalizedText messageID="order_summary.extra_item.discount" />
              </div>
              {formattedDiscount == null &&
              discountAmount != null &&
              discountAmount.value !== 0 ? (
                <div className={styles.discountRuleAmount}>
                  - {formatMoney(discountAmount)}
                </div>
              ) : null}
            </div>
            {formattedDiscount != null ? (
              <>
                {formattedDiscount.map((d, index) => (
                  <div key={index} className={styles.discountRuleContainer}>
                    <div className={styles.discountRuleName}>
                      - {d.ruleName}
                    </div>
                    <div className={styles.discountRuleAmount}>
                      {d.ruleAmount}
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
      ) : null}
      {extraItems.map((item, index) => (
        <OrderSummaryItem key={index} className={styles.row}>
          <LocalizedText messageID={item.titleID} />
          {`${item.type} ${formatMoney(item.value)}`}
        </OrderSummaryItem>
      ))}
      {shouldShowClubpointUsed && (
        <OrderSummaryClubpointsList
          className={styles.row}
          clubPointRequired={clubPointRequired}
          clubpointsRequiredItems={clubpointsRequiredItems}
          extraClubpointsUsed={extraClubpointsUsed}
          clubpointsConversionCurrency={clubpointsConversionCurrency}
          clubpointsConversionRate={clubpointsConversionRate}
        />
      )}
      <OrderSummaryItem className={styles.row}>
        <LocalizedText messageID="order_summary.total" />
        <div className={styles.orderSummaryTotal}>{formatMoney(total)}</div>
      </OrderSummaryItem>
    </div>
  );
};

interface OrderSummaryItemProps {
  className?: string;
  children: [React.ReactNode, React.ReactNode];
}

const OrderSummaryItem: React.FC<OrderSummaryItemProps> = props => {
  const {
    className,
    children: [leftChild, rightChild],
  } = props;
  return (
    <div className={classnames(className, styles.itemContainer)}>
      <div className={styles.itemLeftChild}>{leftChild}</div>
      <div className={styles.itemRightChild}>{rightChild}</div>
    </div>
  );
};

interface OrderSummaryClubpointsListProps {
  className?: string;
  clubPointRequired: number;
  clubpointsRequiredItems:
    | {
        name: string;
        value: number;
      }[]
    | null;
  extraClubpointsUsed: number;
  clubpointsConversionCurrency: string;
  clubpointsConversionRate: number;
}

const OrderSummaryClubpointsList: React.FC<
  OrderSummaryClubpointsListProps
> = props => {
  const {
    className,
    clubPointRequired,
    extraClubpointsUsed,
    clubpointsConversionCurrency,
    clubpointsConversionRate,
  } = props;

  return (
    <div className={classnames(className, styles.clubPointsListContainer)}>
      {clubPointRequired > 0 ? (
        <div
          className={classnames(
            styles.itemContainer,
            styles.clubPointsListItemContainer
          )}
        >
          <div className={styles.itemLeftChild}>
            <LocalizedText messageID="order_summary.clubpoints_required" />
            &nbsp;(
            <IonIcon
              className={styles.clubpointsIconHighlight}
              name="club-point"
            />
            <span className={styles.clubpointsHighlight}>
              {clubPointRequired}
            </span>
            )
          </div>
        </div>
      ) : null}
      {extraClubpointsUsed > 0 ? (
        <div
          className={classnames(
            styles.itemContainer,
            styles.clubPointsListItemContainer
          )}
        >
          <div className={styles.itemLeftChild}>
            {clubPointRequired > 0 ? (
              <LocalizedText messageID="order_summary.extra_clubpoints_used" />
            ) : (
              <LocalizedText messageID="order_summary.clubpoints_used" />
            )}
            &nbsp;(
            <IonIcon
              className={styles.clubpointsIconHighlight}
              name="club-point"
            />
            <span className={styles.clubpointsHighlight}>
              {extraClubpointsUsed}
            </span>
            )
          </div>
          {/*
          TODO (Steven-Chan):
          format the price string
          */}
          <div className={styles.itemRightChild}>
            {`- ${formatMoney({
              currency: clubpointsConversionCurrency,
              value: extraClubpointsUsed / clubpointsConversionRate,
            })}`}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OrderSummary;
