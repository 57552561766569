import React, { useCallback, useMemo } from "react";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";
import { FormError, IndexMap } from "../../utils/type";
import { actionEvent } from "../../utils/GTM";
import O2oStoreDisplay from "../O2oStore";

import { SelfPickup } from "./Context";
import { O2oStore } from "./models";
import Input from "./Input";
import Select from "./Select";
import ValueField from "../Form/ValueField";
import { LocationState as CheckoutSelectO2oStorePageLocationState } from "../CheckoutSelectO2oStorePage";

import styles from "./SelfPickupForm.module.scss";

export interface Option {
  label: string;
  value: string;
}

export interface PickupData {
  regions: Option[];
  mapping: IndexMap<
    string,
    (Option & { o2oStoreTypes: (Option & { o2oStores: O2oStore[] })[] })[]
  >;
}

interface Props {
  selfPickup: SelfPickup;
  onChange: (selfPickup: SelfPickup) => void;
  onPickupSpotChange: (pickupSpot: string) => void;
  onTelephoneBlur: () => void;
  data: PickupData | null;
  navigateToSelectO2oStorePage: (
    params: CheckoutSelectO2oStorePageLocationState
  ) => void;
  formError: FormError<{ mobile: unknown }>;
}

const SelfPickupForm: React.FC<Props> = props => {
  const {
    selfPickup,
    onChange,
    onPickupSpotChange,
    onTelephoneBlur,
    data,
    navigateToSelectO2oStorePage,
    formError,
  } = props;

  const handleTelephoneChange = useCallback(
    (telephone: string) => {
      onChange({ ...selfPickup, telephone });
    },
    [selfPickup, onChange]
  );

  const handleRegionClick = useCallback(() => {
    actionEvent(
      "Checkout Page",
      "Click",
      "Delivery Info_Self Pick-up Points_Region"
    );
  }, []);

  const handleRegionChange = useCallback(
    (value: string) => {
      onChange({
        ...selfPickup,
        region: value,
        district: "",
        storeType: "",
        pickupSpot: "",
      });
    },
    [selfPickup, onChange]
  );

  const handleDistrictClick = useCallback(() => {
    actionEvent(
      "Checkout Page",
      "Click",
      "Delivery Info_Self Pick-up Points_District"
    );
  }, []);

  const handleDistrictChange = useCallback(
    (value: string) => {
      onChange({
        ...selfPickup,
        district: value,
        storeType: "all",
        pickupSpot: "",
      });
    },
    [onChange, selfPickup]
  );

  const handleStoreTypeClick = useCallback(() => {
    actionEvent(
      "Checkout Page",
      "Click",
      "Delivery Info_Self Pick-up Points_Store Type"
    );
  }, []);

  const handleStoreTypeChange = useCallback(
    (value: string) => {
      onChange({ ...selfPickup, storeType: value, pickupSpot: "" });
    },
    [onChange, selfPickup]
  );

  const handlePickupSpotChange = useCallback(
    (o2oStore: O2oStore) => {
      const { code } = o2oStore;
      onChange({ ...selfPickup, pickupSpot: code });
      onPickupSpotChange(code);
    },
    [onChange, onPickupSpotChange, selfPickup]
  );

  const districtOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    if (selfPickup.region) {
      return data.mapping[selfPickup.region] || [];
    }
    return [];
  }, [selfPickup, data]);

  const storeTypeOptions = useMemo(() => {
    const district = districtOptions.filter(
      x => x.value === selfPickup.district
    )[0];
    if (!district) {
      return [];
    }
    return district.o2oStoreTypes;
  }, [selfPickup, districtOptions]);

  const o2oStores = useMemo(() => {
    const storeType = storeTypeOptions.filter(
      x => x.value === selfPickup.storeType
    )[0];
    if (!storeType) {
      return [];
    }
    return storeType.o2oStores;
  }, [selfPickup, storeTypeOptions]);

  const selectedO2oStore = useMemo(() => {
    if (!selfPickup.pickupSpot) {
      return null;
    }
    const store = o2oStores.filter(x => x.code === selfPickup.pickupSpot)[0];
    if (!store) {
      return null;
    }
    return store;
  }, [selfPickup, o2oStores]);

  const handleClickPickupSpot = useCallback(() => {
    actionEvent(
      "Checkout Page",
      "Click",
      "Delivery Info_Self Pick-up Points_Pickup Spot"
    );
    navigateToSelectO2oStorePage({
      options: o2oStores,
      selectedValue: selfPickup.pickupSpot,
      onSelect: handlePickupSpotChange,
    });
  }, [
    navigateToSelectO2oStorePage,
    o2oStores,
    handlePickupSpotChange,
    selfPickup,
  ]);

  return (
    <div>
      <div className={styles.formField}>
        <Input
          className={styles.input}
          fieldClassName={cn(styles.inputField, {
            [styles.errorField]: formError.mobile,
          })}
          titleMessageID="address_form.telephone"
          value={selfPickup.telephone}
          onChange={handleTelephoneChange}
          onBlur={onTelephoneBlur}
          required={true}
          type="tel"
          maxLength={8}
          errorMessage={formError.mobile}
        />
        <div className={styles.telephonePurposeText}>
          <LocalizedText messageID="address_form.telephone.purpose" />
        </div>
      </div>
      <div className={styles.formField}>
        <Select
          titleMessageID="self_pickup_form.region"
          placeholderID="select.placeholder"
          value={selfPickup.region || ""}
          onClick={handleRegionClick}
          onChange={handleRegionChange}
          options={data ? data.regions : []}
          required={true}
        />
      </div>
      {districtOptions.length > 0 ? (
        <div className={styles.formField}>
          <Select
            titleMessageID="self_pickup_form.district"
            placeholderID="select.placeholder"
            value={selfPickup.district || ""}
            onClick={handleDistrictClick}
            onChange={handleDistrictChange}
            options={districtOptions}
            required={true}
          />
        </div>
      ) : null}
      {storeTypeOptions.length > 0 ? (
        <div className={styles.formField}>
          <Select
            titleMessageID="self_pickup_form.store_type"
            placeholderID="select.placeholder"
            value={selfPickup.storeType || ""}
            onClick={handleStoreTypeClick}
            onChange={handleStoreTypeChange}
            options={storeTypeOptions}
            required={true}
          />
        </div>
      ) : null}
      {o2oStores.length > 0 ? (
        <div className={styles.formField}>
          <div onClick={handleClickPickupSpot}>
            <ValueField
              titleMessageID="self_pickup_form.pickup_spot"
              placeholderID="select.placeholder"
              disabled={o2oStores.length === 0}
              required={true}
              value={
                selectedO2oStore ? (
                  <O2oStoreDisplay o2oStore={selectedO2oStore} />
                ) : null
              }
              arrow="right"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelfPickupForm;
