import React, {
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useState,
} from "react";
import { IonIcon } from "@ionic/react";
import cn from "classnames";

import {
  RootTab,
  getPathForCheckoutFillingInfo,
  navigateToCheckoutOrderedPage,
  getPathForShoppingCart,
  getPathForMPOSPayPayment,
  getPathForAccountTab,
  getPathForEditProfile,
  getPathForChangeEmail,
} from "../../navigation/routes";
import {
  useCustomer,
  useLinkSocialAccountRequest,
  useRefreshCustomer,
  INVALID_MEMBERSHIP_RESPONSE,
  useHandleSessionExpired,
} from "../../repository/AuthRepository";
import { useAppConfig } from "../../repository/ConfigRepository";
import {
  isCustomerLinkedToTheClub,
  isCustomerTheClubAccountVerified,
  ClubMemberCustomer,
  getCustomerClubPoints,
} from "../../models/Customer";
import { getClubPointConversionRate } from "../../models/AppConfig";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import {
  AsiaPayError,
  AsiaPayParameters,
  presentAsiaPayBrowser,
} from "../../utils/AsiaPay";
import { getPayMethod, presentAsiaPay } from "../../utils/AlipayHK";
import {
  OppPaymentError,
  presentOppPaymentBrowser,
} from "../../utils/OppPayment";
import {
  TheClubOTPError,
  presentTheClubOTPBrowser,
} from "../../utils/TheClubOTP";
import { useResetShoppingCartClubPointToBeUsed } from "../../utils/ShoppingCart";
import { actionEvent, pageView, purchaseEvent } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { timeout } from "../../utils/promise";
import { isLocalError } from "../../utils/Error";
import presentApplePay, {
  canMakeApplePayPayment,
  getTotalLineItemLabel,
} from "../../utils/ApplePay";
import { profiledAsyncActionFn } from "../../utils/performance";
import { CheckoutSession } from "../../utils/PerformanceRecordStore/sessions";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";

import { withProviders } from "../Provider";
import { CartIDContext } from "../CartIDProvider";
import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import CheckoutSteps from "../CheckoutSteps";
import ClubpointWidget from "../ClubpointWidget";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import NotEnoughtClubPointBanner from "../NotEnoughtClubPointBanner";
import ErrorView from "../ErrorView";

import OrderSummary, {
  OrderSummaryExtraItem,
  getMoneyTypeFromMoney,
} from "../OrderSummary";
import SeparatorWithTitle from "../SeparatorWithTitle";
import { PrimaryButton } from "../Button";
import {
  useCartResource,
  getAvailablePaymentMethods,
  usePaymentMethodMissing,
  useGooglePayAvailable,
} from "../Checkout/hook";
import {
  PaymentType,
  getAppliedCoupon,
  PartialCart,
  isCartAddressEmpty,
} from "../Checkout/models";
import VerifyTheClubModal from "../VerifyTheClubModal/lazy";

import Section from "./Section";
import AddressForm from "./AddressForm";
import SelfPickupForm from "./SelfPickupForm";
import { FormField } from "./FormField";
import { PartialOrder } from "./models";

import styles from "./styles.module.scss";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { useLoginWithTheClub } from "../../useCase/AuthUseCase";
import {
  getDiscountBreakdown,
  CartDiscountTypes,
  getMinClubPointUsed,
} from "../../models/cart";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";
import { hasDeliveryInfoForCart } from "../Checkout/FillingInfo";
import { OppCardRow } from "../Checkout/OppCardSelectList";
import { Context } from "../Checkout/Context";
import {
  OurNavContext,
  PresentationContext,
  ParentNavContext,
} from "../../our-navigation";
import VerifyTheClubAccountBanner from "../Checkout/VerifyTheClubAccountBanner";
import { useDebounceByCompletion } from "../../hook/utils";
import { useGraphQLFn } from "../../hook/graphql";
import { parseGraphQLError } from "../../api/GraphQL";

import { fetchOrder } from "./api";

import Config from "../../Config";

import visaImg from "../../resources/logo-visa.svg";
import masterImg from "../../resources/logo-master.svg";
import tapAndGoImg from "../../resources/logo-tap-and-go.svg";
import chinapayImg from "../../resources/logo-chinapay.svg";
import applepayImg from "../../resources/logo-applepay.svg";
import alipayHKImg from "../../resources/logo-alipayhk.svg";
import googlePayImg from "../../resources/logo-googlepay.svg";
import instalmentImg from "../../resources/logo-instalment.svg";

function renderSelectedPaymentMethod(paymentMethod: PaymentType) {
  switch (paymentMethod) {
    case "checkmo": {
      return <span>Checkmo</span>;
    }
    case "free": {
      return (
        <span>
          <LocalizedText messageID="checkout.filling_info.payment_option.free" />
        </span>
      );
    }
    case "asiapay": {
      return (
        <div className={styles.paymentIcons}>
          <img className={styles.paymentIcon} alt="visa" src={visaImg} />
          <img className={styles.paymentIcon} alt="master" src={masterImg} />
          <img
            className={styles.paymentIcon}
            alt="chinapay"
            src={chinapayImg}
          />
        </div>
      );
    }
    case "visa": {
      return (
        <div className={styles.paymentIcons}>
          <img className={styles.paymentIcon} alt="visa" src={visaImg} />
        </div>
      );
    }
    case "master": {
      return (
        <div className={styles.paymentIcons}>
          <img className={styles.paymentIcon} alt="master" src={masterImg} />
        </div>
      );
    }
    case "chinapay": {
      return (
        <div className={styles.paymentIcons}>
          <img
            className={styles.paymentIcon}
            alt="chinapay"
            src={chinapayImg}
          />
        </div>
      );
    }
    case "mpospay": {
      return <span>mPosPay</span>;
    }
    case "opppayment": {
      return (
        <div className={styles.paymentIcons}>
          <img className={styles.paymentIcon} alt="visa" src={visaImg} />
          <img className={styles.paymentIcon} alt="master" src={masterImg} />
          <img
            className={styles.paymentIcon}
            alt="Tap and Go"
            src={tapAndGoImg}
          />
        </div>
      );
    }
    case "applepay": {
      return (
        <div className={styles.paymentIcons}>
          <img
            className={styles.paymentIcon}
            alt="applepay"
            src={applepayImg}
          />
        </div>
      );
    }
    case "alipayhk": {
      return (
        <div className={styles.paymentIcons}>
          <img
            className={styles.paymentIcon}
            alt="alipayhk"
            src={alipayHKImg}
          />
        </div>
      );
    }
    case "googlepay": {
      return (
        <div className={styles.paymentIcons}>
          <img
            className={styles.paymentIcon}
            alt="alipayhk"
            src={googlePayImg}
          />
        </div>
      );
    }
    case "installment": {
      return (
        <div className={styles.paymentIcons}>
          <img
            className={cn(styles.paymentIcon, styles.instalmentIcon)}
            alt="instalment"
            src={instalmentImg}
          />
          <span className={styles["paymentOptionTitle--iconBefore"]}>
            <LocalizedText messageID="checkout.payment_method.instalment" />
          </span>
        </div>
      );
    }
    default: {
      return <span>Unknown Method</span>;
    }
  }
}

function graphQLFieldsToAsiaPayParameters(
  fields: string[],
  values: string[]
): AsiaPayParameters {
  const res: AsiaPayParameters = {};
  for (let i = 0; i < fields.length; i++) {
    res[fields[i]] = values[i];
  }
  return res;
}

function viewEnter() {
  pageView({ page: "Confirm Purchasing Info" });
}

const CheckoutConfirmationPage: React.FC = () => {
  useEffect(
    () =>
      addPerformanceRecord(CheckoutSession(), "Checkout Confirmation mount"),
    []
  );

  const { isOnline } = useContext(NetworkStatusContext);

  const { translate } = useIntl();
  const cartResource = useCartResource();

  const { fetchCart, cart, isFetchCartLoading, fetchCartError } = cartResource;

  const [applePayAvailable, setApplePayAvailable] = useState<boolean | null>(
    null
  );
  const googlePayAvailable = useGooglePayAvailable();

  useEffect(() => {
    (async () => {
      setApplePayAvailable(await canMakeApplePayPayment());
    })();
  }, []);

  useEffect(() => {
    profiledAsyncActionFn(
      CheckoutSession(),
      "Checkout Confirmation fetch cart",
      fetchCart
    )().catch(() => {});
  }, [fetchCart]);

  const viewState = useMemo<
    SimpleViewState<
      {
        cart: PartialCart;
        applePayAvailable: boolean;
        googlePayAvailable: boolean;
      },
      Error
    >
  >(() => {
    if (isFetchCartLoading) {
      return SimpleViewStateLoading;
    }
    if (fetchCartError) {
      return SimpleViewStateError(fetchCartError);
    }
    if (cart && applePayAvailable != null && googlePayAvailable != null) {
      return SimpleViewStateDisplay({
        cart,
        applePayAvailable,
        googlePayAvailable,
      });
    }
    return SimpleViewStateInitial;
  }, [
    isFetchCartLoading,
    fetchCartError,
    cart,
    applePayAvailable,
    googlePayAvailable,
  ]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="checkout.filling_info.title" />}
      />
      <CLContent className={styles.ionContent}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "display"}
        >
          {viewState.type === "loading" ? (
            <FullContentLoadingView />
          ) : viewState.type === "error" ? (
            <div className={styles.errorView}>
              <ErrorView
                errorMessage={
                  viewState.error.message
                    ? viewState.error.message
                    : translate("error.unknown")
                }
              />
            </div>
          ) : viewState.type === "display" ? (
            <DisplayView
              cart={viewState.data.cart}
              cartResource={cartResource}
              applePayAvailable={viewState.data.applePayAvailable}
              googlePayAvailable={viewState.data.googlePayAvailable}
            />
          ) : null}
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

interface DisplayProps {
  cart: PartialCart;
  applePayAvailable: boolean;
  googlePayAvailable: boolean;
  cartResource: ReturnType<typeof useCartResource>;
}

/* eslint-disable complexity */
const DisplayView: React.FC<DisplayProps> = props => {
  useEffect(
    () =>
      addPerformanceRecord(
        CheckoutSession(),
        "Checkout Confirmation content shown"
      ),
    []
  );

  const { cart, cartResource, applePayAvailable, googlePayAvailable } = props;
  const { navigate, replace, goBack } = useContext(OurNavContext);
  const { reorder } = useContext(CartIDContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const appConfig = useAppConfig();
  const presentationContext = useContext(PresentationContext);
  const parentNavContext = useContext(ParentNavContext);
  const { translate } = useIntl();

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const {
    fetchCartWithoutLoading,
    resetRequestStates,
    setClubPoint,
    setClubPointRequesting,
    setClubPointError,
    setPaymentMethodError,
    placeOrder,
    queryTheClubOTPParameters,
    confirmOrderBurntPoint,
    mPosPayPrepareOrderForQrCodeScan,
    queryAsiapayClientPostParameters,
    queryOppClientPostParameters,
    authorizeApplePayPaymentData,
  } = cartResource;

  const { state } = useContext(Context);
  const { oppCardOption } = state;

  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );

  const availablePaymentMethods = useMemo(
    () =>
      getAvailablePaymentMethods(cart, applePayAvailable, googlePayAvailable),
    [cart, applePayAvailable, googlePayAvailable]
  );

  const handlePaymentMethodMissing = useCallback(() => {
    presentLocalizedAlert({
      messageId: "checkout.payment_method_missing.message",
      buttons: [
        {
          textMessageID: "alert.button.ok",
        },
      ],
    });
    goBack(undefined, { shouldRefresh: true });
  }, [presentLocalizedAlert, goBack]);

  usePaymentMethodMissing(
    availablePaymentMethods,
    cart.selectedPaymentMethod ? cart.selectedPaymentMethod.code : null,
    handlePaymentMethodMissing
  );

  const hasDeliveryInfo = React.useMemo(() => hasDeliveryInfoForCart(cart), [
    cart,
  ]);

  const customer = useCustomer();
  const linkedWithTheClub = React.useMemo<[true, ClubMemberCustomer] | [false]>(
    () =>
      customer == null
        ? [false]
        : isCustomerLinkedToTheClub(customer)
        ? [true, customer]
        : [false],
    [customer]
  );

  const displaySpendClubpointInfo = React.useMemo(() => {
    return linkedWithTheClub[0];
  }, [linkedWithTheClub]);

  const minClubpointUsed = useMemo(() => getMinClubPointUsed(cart), [cart]);
  const customerClubPoint = linkedWithTheClub[0]
    ? getCustomerClubPoints(linkedWithTheClub[1])
    : 0;
  const clubpointConversionRate = useMemo(
    () => getClubPointConversionRate(appConfig),
    [appConfig]
  );
  const maxClubpointUsed = React.useMemo(() => {
    const cartMaxClubPoints =
      Math.floor(cart.prices.grandTotal.value) * clubpointConversionRate +
      cart.clubPointToBeUsed; // add this becoz the grand total has minus discount by club point
    if (!linkedWithTheClub[0]) {
      return cartMaxClubPoints;
    }
    return Math.max(
      Math.min(
        Math.floor(customerClubPoint / clubpointConversionRate) *
          clubpointConversionRate,
        cartMaxClubPoints
      ),
      minClubpointUsed
    );
  }, [
    customerClubPoint,
    cart,
    minClubpointUsed,
    linkedWithTheClub,
    clubpointConversionRate,
  ]);
  const [extraClubpointUsed, setExtraClubpointUsed] = useState<number>(0);
  const notEnoughtClubPoint = customer
    ? extraClubpointUsed + minClubpointUsed > customerClubPoint
    : false;

  const { clubPointToBeUsed } = cart;

  const [
    debouncedSetClubPointOnCart,
    setClubPointOnCartInProgressRef,
  ] = useDebounceByCompletion(setClubPoint);

  useEffect(() => {
    if (!setClubPointOnCartInProgressRef.current) {
      setExtraClubpointUsed((clubPointToBeUsed || 0) - minClubpointUsed);
    }
  }, [clubPointToBeUsed, minClubpointUsed, setClubPointOnCartInProgressRef]);

  const onClubpointUsedChange = useCallback(
    (value: number) => {
      setExtraClubpointUsed(value - minClubpointUsed);
      debouncedSetClubPointOnCart(value);
    },
    [setExtraClubpointUsed, minClubpointUsed, debouncedSetClubPointOnCart]
  );

  useResetShoppingCartClubPointToBeUsed(
    setExtraClubpointUsed,
    setClubPoint,
    customer,
    minClubpointUsed,
    clubPointToBeUsed
  );

  const handleEditDeliveryInfoClick = useCallback(() => {
    actionEvent("Confirm Purchasing Info Page", "Click", "Delivery Info Edit");
    navigate(`${getPathForCheckoutFillingInfo("delivery-info")}`);
  }, [navigate]);

  const handleEditDeliveryTimeClick = useCallback(() => {
    actionEvent("Confirm Purchasing Info Page", "Click", "Delivery Time Edit");
    navigate(`${getPathForCheckoutFillingInfo("delivery-time")}`);
  }, [navigate]);

  const handleEditBillingInfoClick = useCallback(() => {
    actionEvent("Confirm Purchasing Info Page", "Click", "Billing Info Edit");
    navigate(`${getPathForCheckoutFillingInfo("billing-info")}`);
  }, [navigate]);

  const handleEditPaymentMethodClick = useCallback(() => {
    actionEvent("Confirm Purchasing Info Page", "Click", "Payment Info Edit");
    navigate(`${getPathForCheckoutFillingInfo("payment-option")}`);
  }, [navigate]);

  const handleEditPromotionClick = useCallback(() => {
    actionEvent("Confirm Purchasing Info Page", "Click", "Promotion Edit");
    navigate(`${getPathForCheckoutFillingInfo("promotion")}`);
  }, [navigate]);

  const dismissAndGoToChangeEmail = useCallback(() => {
    presentationContext.dismiss(async () => {
      parentNavContext.navigate(getPathForAccountTab());
      await timeout(500);
      parentNavContext.navigate(getPathForEditProfile(RootTab.account));
      await timeout(1000);
      parentNavContext.navigate(getPathForChangeEmail(RootTab.account));
    });
  }, [presentationContext, parentNavContext]);

  const handleEditEmailClick = useCallback(() => {
    if (Config.ENABLE_SET_EMAIL_ADDRESS_ON_CART) {
      navigate(`${getPathForCheckoutFillingInfo("email-address")}`);
      return;
    }
    presentLocalizedAlert({
      headerId: "checkout.email.change.alert.title",
      messageId: "checkout.email.change.alert.message",
      buttons: [
        {
          textMessageID: "alert.button.ok",
          handler: () => dismissAndGoToChangeEmail(),
        },
        {
          textMessageID: "cancel",
        },
      ],
    });
  }, [presentLocalizedAlert, dismissAndGoToChangeEmail, navigate]);

  const [verifyTheClubOpened, setVerifyTheClubOpened] = useState(false);

  useEffect(() => {
    if (customer && isCustomerTheClubAccountVerified(customer)) {
      setVerifyTheClubOpened(false);
    }
  }, [customer]);

  const clubpointsRequiredItems = React.useMemo(() => {
    return cart.items
      .filter(item => item.product.minClubPoint)
      .map(item => ({
        name: item.product.name,
        value: item.product.minClubPoint * item.quantity,
      }));
  }, [cart]);

  const orderSummaryExtraItems = React.useMemo<OrderSummaryExtraItem[]>(() => {
    const result: OrderSummaryExtraItem[] = [];
    result.push({
      titleID: "order_summary.extra_item.delivery_fee",
      type: getMoneyTypeFromMoney(cart.prices.shippingAmount),
      value: cart.prices.shippingAmount,
    });
    if (
      Config.ENABLE_SUBSCRIPTION &&
      cart.prices.initialSubscriptionFee &&
      cart.prices.initialSubscriptionFee.value
    ) {
      result.push({
        titleID: "order_summary.extra_item.initial_subscription_fee",
        type: getMoneyTypeFromMoney(cart.prices.initialSubscriptionFee),
        value: cart.prices.initialSubscriptionFee,
      });
    }
    return result;
  }, [cart]);

  const [isLinkingToTheClub, setIsLinkingToTheClub] = useState(false);
  const loginWithTheClub = useLoginWithTheClub();
  const linkSocialAccount = useLinkSocialAccountRequest();
  const onLinkButtonClick = useCallback(async () => {
    actionEvent("Confirm Purchasing Info Page", "Click", "Link With The Club");
    try {
      setIsLinkingToTheClub(true);
      const ssoResponse = await loginWithTheClub();
      const linkResult = await linkSocialAccount(
        ssoResponse.token,
        ssoResponse.provider
      );
      if (!linkResult.success) {
        presentLocalizedAlert({
          headerId: linkResult.errorMessage
            ? "page.edit_profile.link_account.error.email_registered"
            : "page.edit_profile.connect_account_error",
          messageId: linkResult.errorMessage
            ? undefined
            : "alert.error.message",
          message: linkResult.errorMessage
            ? linkResult.errorMessage
            : undefined,
          buttons: [{ textMessageID: "try_again" }],
        });
      }
    } catch (e) {
      if (e.message === INVALID_MEMBERSHIP_RESPONSE) {
        presentLocalizedAlert({
          headerId: "page.edit_profile.link_account.error.email_registered",
          messageId:
            "page.edit_profile.link_account.error.email_registered.message",
        });
      } else {
        presentLocalizedAlert({
          headerId: "page.edit_profile.connect_account_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      }
    } finally {
      setIsLinkingToTheClub(false);
    }
  }, [
    loginWithTheClub,
    setIsLinkingToTheClub,
    linkSocialAccount,
    presentLocalizedAlert,
  ]);

  let sectionCount = 1;

  const onVerifyClick = useCallback(() => {
    setVerifyTheClubOpened(true);
  }, []);
  const closeVerifyTheClubModal = useCallback(
    () => setVerifyTheClubOpened(false),
    []
  );

  const selectedDeliveryTimeSlot = useMemo(() => {
    const s = cart.availableDeliveryTimeSlots.filter(
      x => x.value === cart.selectedDeliveryTimeSlot
    );
    if (s.length > 0) {
      return s[0];
    }
    return null;
  }, [cart]);

  const shouldVerifyTheClub = useMemo(() => {
    if (!customer || !isCustomerLinkedToTheClub(customer)) {
      return false;
    }
    if (
      !isCustomerTheClubAccountVerified(customer) &&
      minClubpointUsed + extraClubpointUsed > 0
    ) {
      return true;
    }
    return false;
  }, [customer, minClubpointUsed, extraClubpointUsed]);

  const reorderAndBackToShoppingCart = useCallback(
    async (orderId: string) => {
      try {
        await reorder(orderId);
      } catch (e) {
        const errorMessage = parseGraphQLError(e);
        presentLocalizedAlert({
          messageId: errorMessage ? undefined : "alert.error.message",
          message: errorMessage ? errorMessage : undefined,
          buttons: [{ textMessageID: "alert.button.ok" }],
        });
      } finally {
        replace(getPathForShoppingCart());
      }
    },
    [reorder, replace, presentLocalizedAlert]
  );

  const fetchOrder_ = useHandleSessionExpired(useGraphQLFn(fetchOrder));
  const { refreshCustomer } = useRefreshCustomer();

  const navigateToOrderedPage = useCallback(
    (order: PartialOrder) => {
      navigateToCheckoutOrderedPage(navigate, { order });
    },
    [navigate]
  );

  const handleNextClick = useCallback(async () => {
    actionEvent("Confirm Purchasing Info Page", "Click", "Next");
    if (shouldVerifyTheClub) {
      setVerifyTheClubOpened(true);
      return;
    }
    if (setClubPointRequesting) {
      return;
    }

    const profiledFetchOrder = profiledAsyncActionFn(
      CheckoutSession(),
      "- Do Checkout fetchOrder API",
      fetchOrder_
    );

    const profiledReorderAndBackToShoppingCart = profiledAsyncActionFn(
      CheckoutSession(),
      "- Do Checkout reorder API",
      reorderAndBackToShoppingCart
    );

    showLoadingModal();
    addPerformanceRecord(CheckoutSession(), "Checkout Confirmation next click");
    try {
      const _cart = await fetchCartWithoutLoading();
      if (!_cart.selectedPaymentMethod || !_cart.selectedPaymentMethod.code) {
        throw new Error("No selected payment method");
      }
      const { clubPointToBeUsed: _clubPointToBeUsed } = _cart;
      resetRequestStates();
      const orderId = await profiledAsyncActionFn(
        CheckoutSession(),
        "- Do Checkout placeOrder API",
        placeOrder
      )();
      const order = await profiledFetchOrder(orderId);
      if (_clubPointToBeUsed > 0) {
        const res = await profiledAsyncActionFn(
          CheckoutSession(),
          "- Do Checkout queryTheClubOTPParameters API",
          queryTheClubOTPParameters
        )(orderId);
        const { action, fields } = res;
        try {
          await presentTheClubOTPBrowser(action, fields);
        } catch (e) {
          if (e.message === TheClubOTPError.CANCELLED) {
            presentLocalizedAlert({
              messageId: "the_club_otp.cancelled",
              buttons: [{ textMessageID: "alert.button.ok" }],
            });
            await profiledReorderAndBackToShoppingCart(orderId);
          }
          return;
        }
        try {
          await profiledAsyncActionFn(
            CheckoutSession(),
            "- Do Checkout confirmOrderBurntPoint API",
            confirmOrderBurntPoint
          )(orderId);
        } catch (e) {
          presentLocalizedAlert({
            messageId: isLocalError(e) ? e.messageID : undefined,
            message: !isLocalError(e) ? e.message : undefined,
            messageArgs: isLocalError(e) ? e.messageArgs : undefined,
            buttons: [{ textMessageID: "alert.button.ok" }],
          });
          await profiledReorderAndBackToShoppingCart(orderId);
          return;
        }
        await refreshCustomer();
      }
      switch (_cart.selectedPaymentMethod.code) {
        case "checkmo": {
          purchaseEvent(order);
          navigateToOrderedPage(order);
          return;
        }
        case "free": {
          purchaseEvent(order);
          navigateToOrderedPage(order);
          return;
        }
        case "asiapay":
        case "visa":
        case "master":
        case "chinapay":
        case "installment": {
          const res = await profiledAsyncActionFn(
            CheckoutSession(),
            "- Do Checkout queryAsiapayClientPostParameters API",
            queryAsiapayClientPostParameters
          )(orderId);
          const { action, fields, values } = res;
          try {
            await presentAsiaPayBrowser(
              action,
              graphQLFieldsToAsiaPayParameters(fields, values)
            );
            purchaseEvent(order);
            navigateToOrderedPage(order);
          } catch (e) {
            if (e.message === AsiaPayError.CANCELLED) {
              presentLocalizedAlert({
                messageId: "asia_pay.cancelled",
                buttons: [{ textMessageID: "alert.button.ok" }],
              });
              await profiledReorderAndBackToShoppingCart(orderId);
            } else if (e.message === AsiaPayError.FAILED) {
              presentLocalizedAlert({
                messageId: "asia_pay.failed",
                buttons: [{ textMessageID: "alert.button.ok" }],
              });
              await profiledReorderAndBackToShoppingCart(orderId);
            }
          }
          return;
        }
        case "googlepay":
        case "alipayhk": {
          const res = await profiledAsyncActionFn(
            CheckoutSession(),
            "- Do Checkout queryAsiapayClientPostParameters API",
            queryAsiapayClientPostParameters
          )(orderId);
          const { fields, values } = res;
          try {
            await presentAsiaPay(
              graphQLFieldsToAsiaPayParameters(fields, values),
              getPayMethod(_cart.selectedPaymentMethod.code)
            );
            purchaseEvent(order);
            navigateToOrderedPage(order);
          } catch (e) {
            const { message, code } = e;
            presentLocalizedAlert({
              message: message
                ? `${message}${code ? `(${code})` : ""}`
                : undefined,
              messageId: message
                ? undefined
                : "checkout.payment_failed.message",
              messageArgs: {
                code: code ? `(${code})` : "",
              },
              buttons: [{ textMessageID: "alert.button.ok" }],
            });
            await profiledReorderAndBackToShoppingCart(orderId);
          }
          return;
        }
        case "mpospay": {
          await profiledAsyncActionFn(
            CheckoutSession(),
            "Do Checkout mPosPayPrepareOrderForQrCodeScan API",
            mPosPayPrepareOrderForQrCodeScan
          )(orderId);
          replace(getPathForMPOSPayPayment(orderId));
          return;
        }
        case "opppayment": {
          const { action } = await profiledAsyncActionFn(
            CheckoutSession(),
            "Do Checkout queryOppClientPostParameters API",
            queryOppClientPostParameters
          )(orderId, oppCardOption);
          try {
            await presentOppPaymentBrowser(action, translate);
            purchaseEvent(order);
            navigateToOrderedPage(order);
          } catch (e) {
            if (e.message === OppPaymentError.CANCELLED) {
              presentLocalizedAlert({
                messageId: "opp_payment.cancelled",
                buttons: [{ textMessageID: "alert.button.ok" }],
              });
              await profiledReorderAndBackToShoppingCart(orderId);
            } else if (e.message === OppPaymentError.FAILED) {
              presentLocalizedAlert({
                messageId: "opp_payment.failed",
                buttons: [{ textMessageID: "alert.button.ok" }],
              });
              await profiledReorderAndBackToShoppingCart(orderId);
            }
          }
          return;
        }
        case "applepay": {
          try {
            const label = getTotalLineItemLabel();
            await presentApplePay(
              [
                {
                  label,
                  amount: order.grandTotal,
                },
              ],
              order.currencyCode,
              async paymentData => {
                await profiledAsyncActionFn(
                  CheckoutSession(),
                  "Do Checkout authorizeApplePayPaymentData API",

                  authorizeApplePayPaymentData
                )(order.entityID, paymentData);
              }
            );
            purchaseEvent(order);
            navigateToOrderedPage(order);
          } catch (e) {
            presentLocalizedAlert({
              message: e,
              buttons: [{ textMessageID: "alert.button.ok" }],
            });
            await profiledReorderAndBackToShoppingCart(orderId);
          }
          return;
        }
        default: {
          alert(
            `DEV: Payment method (${_cart.selectedPaymentMethod.code}) not supported`
          );
        }
      }
    } catch (e) {
      const errorMessage = parseGraphQLError(e);
      presentLocalizedAlert({
        messageId: errorMessage ? undefined : "alert.error.message",
        message: errorMessage ? errorMessage : undefined,
        buttons: [{ textMessageID: "alert.button.ok" }],
      });
    } finally {
      hideLoadingModal();
    }
  }, [
    translate,
    fetchCartWithoutLoading,
    fetchOrder_,
    resetRequestStates,
    placeOrder,
    queryTheClubOTPParameters,
    confirmOrderBurntPoint,
    queryAsiapayClientPostParameters,
    oppCardOption,
    queryOppClientPostParameters,
    authorizeApplePayPaymentData,
    navigateToOrderedPage,
    replace,
    showLoadingModal,
    hideLoadingModal,
    presentLocalizedAlert,
    reorderAndBackToShoppingCart,
    mPosPayPrepareOrderForQrCodeScan,
    shouldVerifyTheClub,
    setClubPointRequesting,
    refreshCustomer,
  ]);

  const discount = useMemo<CartDiscountTypes>(
    () => getDiscountBreakdown(cart.prices),
    [cart]
  );

  return (
    <>
      {notEnoughtClubPoint && <NotEnoughtClubPointBanner />}
      <div className={styles.content}>
        <div className={styles.checkoutStepsWrapper}>
          <CheckoutSteps currentStep="confirmation" />
        </div>
        <div className={styles.block}>
          {hasDeliveryInfo && (
            <>
              <Section
                num={sectionCount++}
                titleID="checkout.delivery_info.title"
                onEditClick={handleEditDeliveryInfoClick}
              >
                {state.deliveryInfoType === "address" &&
                cart.shippingAddresses.length ? (
                  <AddressForm cartAddress={cart.shippingAddresses[0]} />
                ) : null}
                {state.deliveryInfoType === "o2o-store" && (
                  <>
                    {customer && (
                      <SelfPickupForm
                        selfPickup={state.selfPickup}
                        o2oStores={cart.availableO2oStores}
                        customer={customer}
                      />
                    )}
                  </>
                )}
              </Section>
              {state.deliveryInfoType === "address" &&
                selectedDeliveryTimeSlot && (
                  <Section
                    num={sectionCount++}
                    titleID="checkout.delivery_time.title"
                    onEditClick={handleEditDeliveryTimeClick}
                  >
                    <FormField title={selectedDeliveryTimeSlot.label}>
                      {selectedDeliveryTimeSlot.valueText}
                    </FormField>
                  </Section>
                )}
            </>
          )}
          {cart.billingAddress && !isCartAddressEmpty(cart.billingAddress) ? (
            <Section
              num={sectionCount++}
              titleID="checkout.billing_info.title"
              onEditClick={handleEditBillingInfoClick}
            >
              <AddressForm cartAddress={cart.billingAddress} />
            </Section>
          ) : null}
          <Section
            num={sectionCount++}
            titleID="checkout.payment_info.title"
            onEditClick={handleEditPaymentMethodClick}
          >
            <FormField titleID="checkout.payment_method.title">
              <div className={styles.paymentInfo}>
                {(() => {
                  if (!cart.selectedPaymentMethod) {
                    return <>-</>;
                  }
                  if (cart.selectedPaymentMethod.code === "opppayment") {
                    if (state.oppCardOption) {
                      if (state.oppCardOption.type === "existing") {
                        return (
                          <OppCardRow oppCard={state.oppCardOption.oppCard} />
                        );
                      }
                      return (
                        <>
                          {renderSelectedPaymentMethod(
                            cart.selectedPaymentMethod.code
                          )}
                          <div>
                            <LocalizedText messageID="checkout.filling_info.payment_option.opp.new_card" />
                          </div>
                        </>
                      );
                    }
                  }
                  return renderSelectedPaymentMethod(
                    cart.selectedPaymentMethod.code
                  );
                })()}
              </div>
            </FormField>
            {setPaymentMethodError ? (
              <p className={styles.errorMessage}>{setPaymentMethodError}</p>
            ) : null}
          </Section>
          <Section
            num={sectionCount++}
            titleID="checkout.promotion.title"
            onEditClick={handleEditPromotionClick}
          >
            <FormField titleID="checkout.promotion.title">
              {getAppliedCoupon(cart) || "-"}
            </FormField>
          </Section>
          {Config.ENABLE_SET_EMAIL_ADDRESS_ON_CART ? (
            <Section
              num={sectionCount++}
              titleID="checkout.email.title"
              onEditClick={handleEditEmailClick}
            >
              <FormField titleID="checkout.email.message">
                {state.emailAddress}
              </FormField>
            </Section>
          ) : customer ? (
            <Section
              num={sectionCount++}
              titleID="checkout.email.title"
              onEditClick={handleEditEmailClick}
            >
              <FormField titleID="checkout.email.message">
                {customer.email}
              </FormField>
            </Section>
          ) : null}
        </div>
        {displaySpendClubpointInfo && (
          <div className={styles.clubpointWidget}>
            <ClubpointWidget
              className={styles.clubpointWidget}
              minValue={minClubpointUsed}
              maxValue={maxClubpointUsed}
              currentValue={minClubpointUsed + extraClubpointUsed}
              valuePerStep={clubpointConversionRate}
              grandTotal={cart.prices.grandTotal}
              onChange={onClubpointUsedChange}
              disabled={minClubpointUsed >= maxClubpointUsed}
            />
            {setClubPointError ? (
              <p className={styles.errorMessage}>{setClubPointError}</p>
            ) : null}
            {shouldVerifyTheClub ? (
              <VerifyTheClubAccountBanner onVerifyClick={onVerifyClick} />
            ) : null}
          </div>
        )}
        <OrderSummary
          className={styles.orderSummary}
          customer={customer}
          itemsCount={cart.items.length}
          subtotal={cart.prices.subtotalExcludingTax}
          // TODO (Steven-Chan):
          // display required club points and  amount of extra club points used
          clubPointRequired={minClubpointUsed}
          clubpointsRequiredItems={
            displaySpendClubpointInfo ? clubpointsRequiredItems : null
          }
          extraClubpointsUsed={extraClubpointUsed}
          clubpointsConversionCurrency="HKD"
          clubpointsConversionRate={getClubPointConversionRate(appConfig)}
          extraItems={orderSummaryExtraItems}
          total={cart.prices.grandTotal}
          discount={discount}
          discountAmount={cart.prices.discountAmount}
        />
        <PrimaryButton
          className={styles.nextButton}
          onClick={handleNextClick}
          disabled={notEnoughtClubPoint || setClubPointRequesting}
        >
          <LocalizedText messageID="next" />
        </PrimaryButton>
        <div className={styles.noChangeMessage}>
          <LocalizedText messageID="shopping_cart.no_changes_are_allowed" />
        </div>
        {Config.ENABLE_THE_CLUB_SSO || linkedWithTheClub[0] ? (
          <>
            <div className={styles.earnClubpointsContainer}>
              <div className={styles.clubpointsIconHighlight} />
              <div className={styles.earnClubpoints}>
                <LocalizedText
                  messageID="shopping_cart.complete_order_to_earn_clubpoints"
                  messageArgs={{
                    clubpointsAmount: cart.clubpointsToBeEarned,
                    clubpointsHighlight: styles.clubpointsHighlight,
                  }}
                />
              </div>
            </div>
            <div className={styles.transferClubpoints}>
              <LocalizedText messageID="shopping_cart.points_will_be_transferred" />
            </div>
          </>
        ) : !linkedWithTheClub[0] ? (
          <>
            <SeparatorWithTitle className={styles.separator} titleID="or" />
            <div className={styles.theClubEarnPoint}>
              <div className={styles.theClubEarnPointMessage}>
                <LocalizedText
                  messageID="shopping_cart.link_to_earn_point"
                  messageArgs={{
                    clubpointsAmount: cart.clubpointsToBeEarned,
                    theClubIconClassName: styles.theClubEarnPointMessageIcon,
                  }}
                  components={{ IonIcon }}
                />
              </div>
            </div>
            <div
              className={cn(
                styles.theClubButton,
                isLinkingToTheClub && styles.disabled
              )}
              onClick={onLinkButtonClick}
            >
              <LocalizedText messageID="shopping_cart.link_with" />
              <div className={styles.theClub} />
            </div>
          </>
        ) : null}
      </div>
      <VerifyTheClubModal
        isModalOpen={verifyTheClubOpened}
        onRequestDismiss={closeVerifyTheClubModal}
      />
    </>
  );
};
/* eslint-enable complexity */

export default withProviders(CheckoutConfirmationPage, LoadingModalProvider);
