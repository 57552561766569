import React, { useRef, useCallback, useMemo, useContext } from "react";
import { actionSheetController, ActionSheetButton } from "@ionic/core";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { FullContentLoadingView } from "../LoadingView";
import { useFetchCustomerAddresses } from "../../repository/CustomerRepository";
import {
  getResources,
  getRequestStateError,
} from "../../models/ResourcesRequestState";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import usePullToRefresh from "../../utils/pullToRefresh";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { Address, transformRemoteAddress } from "../../models/Customer";
import { PrimaryButton } from "../Button";
import DeliveryInfoItem from "./DeliveryInfoItem";
import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";
import { PresentationContext } from "../../our-navigation";
import {
  getPathForAddNewAddress,
  getPathForEditAddress,
} from "../../navigation/routes";
import { useAddressActions } from "./api";
import { LoadingModalContext } from "../LoadingModalProvider";
import { parseGraphQLError } from "../../api/GraphQL";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";
import { FullContentErrorView } from "../ErrorView";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import styles from "./styles.module.scss";

const MyDeliveryInfoPage: React.FC = () => {
  const presentationContext = useContext(PresentationContext);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const { isOnline } = useContext(NetworkStatusContext);

  const { districtByDistrictName } = useContext(CountryRegionDistrictContext);

  const {
    deleteAddress,
    setDefaultShipping,
    setDefaultBilling,
  } = useAddressActions();

  const { translate } = useIntl();
  const { show: showLoading, hide: hideLoading } = useContext(
    LoadingModalContext
  );

  const { requestState: addressesState, retry } = useFetchCustomerAddresses();
  const { handleRefresh } = usePullToRefresh(addressesState, retry);

  const onAddAddress = useCallback(() => {
    presentationContext.present(getPathForAddNewAddress(), undefined, retry);
  }, [presentationContext, retry]);

  const onEditAddress = useCallback(
    (address: Address) => {
      if (address.id == null) {
        // TODO (Steven-Chan):
        // handle unexpected address without id
        onAddAddress();
        return;
      }

      presentationContext.present(
        getPathForEditAddress(address.id),
        {
          address,
        },
        retry
      );
    },
    [presentationContext, onAddAddress, retry]
  );

  const onDeleteAddress = useCallback(
    async (address: Address) => {
      if (address.id == null) {
        alert(translate("error.unknown"));
        return;
      }

      showLoading();
      try {
        const result = await deleteAddress(address.id);
        retry();
        if (!result) {
          alert(translate("my_delivery.unable_delete"));
        }
      } catch (e) {
        alert(parseGraphQLError(e) || translate("error.unknown"));
      } finally {
        hideLoading();
      }
    },
    [translate, deleteAddress, showLoading, hideLoading, retry]
  );

  const onSetDefaultBilling = useCallback(
    async (address: Address) => {
      if (address.id == null) {
        alert(translate("error.unknown"));
        return;
      }

      showLoading();
      try {
        const result = await setDefaultBilling(address.id);
        retry();
        if (!result) {
          alert(translate("my_delivery.unable_set_as_default_shipping"));
        }
      } catch (e) {
        alert(parseGraphQLError(e) || translate("error.unknown"));
      } finally {
        hideLoading();
      }
    },
    [translate, setDefaultBilling, showLoading, hideLoading, retry]
  );

  const onSetDefaultShipping = useCallback(
    async (address: Address) => {
      if (address.id == null) {
        alert(translate("error.unknown"));
        return;
      }

      showLoading();
      try {
        const result = await setDefaultShipping(address.id);
        retry();
        if (!result) {
          alert(translate("my_delivery.unable_set_as_default_shipping"));
        }
      } catch (e) {
        alert(parseGraphQLError(e) || translate("error.unknown"));
      } finally {
        hideLoading();
      }
    },
    [translate, setDefaultShipping, showLoading, hideLoading, retry]
  );

  const viewState = useMemo<SimpleViewState<Address[], Error>>(() => {
    const resources = getResources(addressesState);
    if (resources != null) {
      const { addresses: addresses_ } = resources;
      const addresses = addresses_.map(address =>
        transformRemoteAddress(address, districtByDistrictName)
      );
      return SimpleViewStateDisplay(addresses);
    }
    const error = getRequestStateError(addressesState);
    if (error) {
      return SimpleViewStateError(error);
    }
    return SimpleViewStateLoading;
  }, [addressesState, districtByDistrictName]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="page.my_delivery.title" />}
      />
      <CLContent ref={contentRef} className={styles.ionContent}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "display" && viewState.data.length > 0}
          onRetry={retry}
        >
          {viewState.type === "loading" && <FullContentLoadingView />}
          {viewState.type === "display" && (
            // Only show pull to refresh when data is ready
            <>
              <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                <IonRefresherContent />
              </IonRefresher>
              <MyDeliveryInfo
                addresses={viewState.data}
                onAddAddress={onAddAddress}
                onEditAddress={onEditAddress}
                onDeleteAddress={onDeleteAddress}
                onSetDefaultBilling={onSetDefaultBilling}
                onSetDefaultShipping={onSetDefaultShipping}
              />
              <TabBarSpacePlaceholder />
            </>
          )}
          {viewState.type === "error" && (
            <FullContentErrorView
              errorMessage={
                viewState.error.message
                  ? viewState.error.message
                  : translate("error.unknown")
              }
              onClickRetry={retry}
            />
          )}
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};
interface MyDeliveryInfoProps {
  addresses: Address[];
  onAddAddress: () => void;
  onEditAddress: (address: Address) => void;
  onSetDefaultBilling: (address: Address) => void;
  onSetDefaultShipping: (address: Address) => void;
  onDeleteAddress: (address: Address) => void;
}

const MyDeliveryInfo: React.FC<MyDeliveryInfoProps> = props => {
  const {
    addresses,
    onAddAddress,
    onEditAddress,
    onDeleteAddress,
    onSetDefaultBilling,
    onSetDefaultShipping,
  } = props;
  const { translate } = useIntl();
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const onRequestDeleteAddress = useCallback(
    (address: Address) => {
      if (address.id === null) {
        presentLocalizedAlert({
          headerId: "error.unknown",
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
        return;
      }
      presentLocalizedAlert({
        headerId: "my_delivery.delete.ask",
        buttons: [
          {
            textMessageID: "alert.button.ok",
            handler: () => onDeleteAddress(address),
          },
          {
            textMessageID: "cancel",
            role: "cancel",
          },
        ],
      });
    },
    [presentLocalizedAlert, onDeleteAddress]
  );

  const getActionSheetButtons = useCallback(
    (address: Address) => {
      const buttons: ActionSheetButton[] = [
        {
          text: translate("my_delivery.edit"),
          handler: () => {
            onEditAddress(address);
          },
        },
      ];

      if (!address.isDefaultBilling) {
        buttons.push({
          text: translate("my_delivery.set_as_default_billing"),
          handler: () => {
            onSetDefaultBilling(address);
          },
        });
      }

      if (!address.isDefaultShipping) {
        buttons.push({
          text: translate("my_delivery.set_as_default_shipping"),
          handler: () => {
            onSetDefaultShipping(address);
          },
        });
      }

      if (!address.isDefaultBilling && !address.isDefaultShipping) {
        buttons.push({
          text: translate("my_delivery.delete"),
          role: "destructive",
          handler: () => {
            onRequestDeleteAddress(address);
          },
        });
      }

      return buttons;
    },
    [
      onEditAddress,
      onSetDefaultBilling,
      onSetDefaultShipping,
      onRequestDeleteAddress,
      translate,
    ]
  );

  const onClickSetting = useCallback(
    async (index: number) => {
      const actionSheet = await actionSheetController.create({
        buttons: [
          ...getActionSheetButtons(addresses[index]),
          {
            text: translate("cancel"),
            role: "cancel",
            handler: () => {
              actionSheet.dismiss();
            },
          },
        ],
      });
      await actionSheet.present();
    },
    [addresses, translate, getActionSheetButtons]
  );

  if (addresses.length === 0) {
    return <EmptyDeliveryInfo onAddAddress={onAddAddress} />;
  }

  return (
    <div className={styles.myDeliveryContainer}>
      <div className={styles.addButton} onClick={onAddAddress}>
        <LocalizedText messageID="my_delivery.add_new_address" />
      </div>
      {addresses.map((address, index) => (
        <DeliveryInfoItem
          key={index}
          className={styles.deliveryInfoItem}
          index={index}
          address={address}
          onClickSetting={onClickSetting}
        />
      ))}
    </div>
  );
};

const EmptyDeliveryInfo: React.FC<{ onAddAddress: () => void }> = props => {
  const { onAddAddress } = props;
  return (
    <div className={styles.emptyContainer}>
      <div className={styles.emptyIcon} />
      <div className={styles.emptyDescription}>
        <LocalizedText messageID="my_delivery.no_stored" />
      </div>
      <PrimaryButton className={styles.emptyAddButton} onClick={onAddAddress}>
        <LocalizedText messageID="my_delivery.add_new_address" />
      </PrimaryButton>
    </div>
  );
};

export default MyDeliveryInfoPage;
