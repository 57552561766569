import React, { useRef, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { useImageStyle } from "../../utils/imageStyle";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import parse from "../../utils/HTMLReactParser";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import { getResources } from "../../models/ResourcesRequestState";
import { Merchant } from "../../models/Merchant";

import { useFetchMerchantDetails } from "../../repository/MerchantRepository";

import { LocalizedText } from "../../i18n/Localization";

import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";

import styles from "./styles.module.scss";

import merchantLogoPlaceholderImg from "../../resources/merchant-placeholder.svg";

type Props = RouteComponentProps<{ id: string }>;

const MerchantDetailsPage: React.FC<Props> = props => {
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(ionContentRef, ionLifeCycleContext);

  const { match } = props;
  const merchantID = match.params.id;

  const { requestState } = useFetchMerchantDetails(merchantID);
  const merchant = getResources(requestState);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="merchant_details.title" />}
      />
      <CLContent className={styles.ionContent} ref={ionContentRef}>
        {merchant ? (
          <ResolvedMerchantDetails merchant={merchant} />
        ) : (
          <FullContentLoadingView />
        )}
      </CLContent>
    </>
  );
};

interface ResolvedMerchantDetailsProps {
  merchant: Merchant;
}

const ResolvedMerchantDetails: React.FC<
  ResolvedMerchantDetailsProps
> = props => {
  const { merchant } = props;
  const {
    about,
    returnPolicy,
    shippingPolicy,
    openingHours,
    contactNumber,
  } = merchant;

  const logoStyle = useImageStyle(merchantLogoPlaceholderImg, merchant.logo);

  const returnPolicyContainerRef = useRef<HTMLDivElement>(null);
  const returnPolicyHTMLString = useExtractScriptsAndStyles(
    returnPolicyContainerRef,
    returnPolicy || ""
  );
  const returnPolicyReactElement = useMemo(
    () => parse(returnPolicyHTMLString),
    [returnPolicyHTMLString]
  );
  useRegisterPublishDeepLinkOnHTMLContent(returnPolicyContainerRef, [
    returnPolicy,
  ]);

  const shippingPolicyContainerRef = useRef<HTMLDivElement>(null);
  const shippingPolicyHTMLString = useExtractScriptsAndStyles(
    shippingPolicyContainerRef,
    shippingPolicy || ""
  );
  const shippingPolicyReactElement = useMemo(
    () => parse(shippingPolicyHTMLString),
    [shippingPolicyHTMLString]
  );
  useRegisterPublishDeepLinkOnHTMLContent(shippingPolicyContainerRef, [
    shippingPolicy,
  ]);

  const aboutContainerRef = useRef<HTMLDivElement>(null);
  const aboutHTMLString = useExtractScriptsAndStyles(
    aboutContainerRef,
    about || ""
  );
  const aboutReactElement = useMemo(() => parse(aboutHTMLString), [
    aboutHTMLString,
  ]);
  useRegisterPublishDeepLinkOnHTMLContent(aboutContainerRef, [about]);

  const contactNumberContainerRef = useRef<HTMLDivElement>(null);
  const contactNumberHTMLString = useExtractScriptsAndStyles(
    contactNumberContainerRef,
    contactNumber || ""
  );
  const contactNumberReactElement = useMemo(
    () => parse(contactNumberHTMLString),
    [contactNumberHTMLString]
  );
  useRegisterPublishDeepLinkOnHTMLContent(contactNumberContainerRef, [
    contactNumber,
  ]);

  const openingHoursContainerRef = useRef<HTMLDivElement>(null);
  const openingHoursHTMLString = useExtractScriptsAndStyles(
    openingHoursContainerRef,
    openingHours || ""
  );
  const openingHoursReactElement = useMemo(
    () => parse(openingHoursHTMLString),
    [openingHoursHTMLString]
  );
  useRegisterPublishDeepLinkOnHTMLContent(openingHoursContainerRef, [
    openingHours,
  ]);

  return (
    <>
      <div className={styles.logo} style={logoStyle} />
      <h1 className={styles.name}>{merchant.name}</h1>
      <section className={styles.section}>
        <h2 className={styles.sectionHeader}>
          <LocalizedText messageID="merchant_details.about" />
        </h2>
        {about ? (
          <div className={styles.sectionContent} ref={aboutContainerRef}>
            {aboutReactElement}
          </div>
        ) : null}
        {contactNumber ? (
          <div className={styles.aboutDetailsRow}>
            <div className={styles.aboutDetailsTitle}>
              <LocalizedText messageID="merchant_details.about.contact_number" />
            </div>
            <div
              className={styles.aboutDetailsValue}
              ref={contactNumberContainerRef}
            >
              {contactNumberReactElement}
            </div>
          </div>
        ) : null}
        {openingHours ? (
          <div className={styles.aboutDetailsRow}>
            <div className={styles.aboutDetailsTitle}>
              <LocalizedText messageID="merchant_details.about.opening_hours" />
            </div>
            <div
              className={styles.aboutDetailsValue}
              ref={openingHoursContainerRef}
            >
              {openingHoursReactElement}
            </div>
          </div>
        ) : null}
      </section>
      {returnPolicy && (
        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>
            <LocalizedText messageID="merchant_details.return_policy" />
          </h2>
          <div className={styles.sectionContent} ref={returnPolicyContainerRef}>
            {returnPolicyReactElement}
          </div>
        </section>
      )}
      {shippingPolicy && (
        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>
            <LocalizedText messageID="merchant_details.shipping_policy" />
          </h2>
          <div
            className={styles.sectionContent}
            ref={shippingPolicyContainerRef}
          >
            {shippingPolicyReactElement}
          </div>
        </section>
      )}
      <TabBarSpacePlaceholder />
    </>
  );
};

export default MerchantDetailsPage;
