import React, { useMemo } from "react";
import { IFramePageComponent } from ".";
import { RouteComponentProps } from "react-router-dom";
import { useAppConfig } from "../../repository/ConfigRepository";
import { useIntl } from "../../i18n/Localization";

type Props = RouteComponentProps<any>;

const FooterCMSLinkPage: React.FC<Props> = props => {
  const { match } = props;
  const { groupText, text } = match.params;
  const { translate } = useIntl();

  const appConfig = useAppConfig();
  const [url, errorMessage] = useMemo(() => {
    if (appConfig == null) {
      return [null, null];
    }

    const matchedGroups = appConfig.footerCMSLinks.filter(
      l => l.text === groupText
    );
    if (matchedGroups.length === 0) {
      return [null, translate("footer_cms_link.page_not_found")];
    }

    const matchedLinks = matchedGroups[0].links.filter(l => l.text === text);
    if (matchedLinks.length === 0) {
      return [null, translate("footer_cms_link.page_not_found")];
    }

    return [matchedLinks[0].url, null];
  }, [appConfig, groupText, text, translate]);

  return (
    <IFramePageComponent
      title={text}
      src={url}
      errorMessage={errorMessage}
      includeTabBarSpace={false}
    />
  );
};

export default FooterCMSLinkPage;
