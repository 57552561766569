import React, { useState, useEffect } from "react";

import ErroView from "../ErrorView";
import { LocalizedText, useIntl } from "../../i18n/Localization";

import styles from "./styles.module.scss";

const NoInternetConnectionBanner: React.FC = () => {
  return (
    <div className={styles.bannerBackground}>
      <p className={styles.bannerText}>
        <LocalizedText messageID="no_internet_connection" />
      </p>
    </div>
  );
};

const NoInternetConnectionRetryView: React.FC<{
  onRetry?: () => void;
}> = props => {
  const { onRetry } = props;
  const { translate } = useIntl();
  return (
    <div className={styles.wholePageContainer}>
      <ErroView
        titleMessageID="no_internet_connection"
        errorMessage={translate("no_internet_description")}
        onClickRetry={onRetry}
      />
    </div>
  );
};

interface Props {
  isOnline: boolean;
  hasData: boolean;
  onRetry?: () => void;
}

const NoInternetConnectionView: React.FC<Props> = props => {
  const { isOnline, hasData, onRetry } = props;

  const [prevIsOnline, setPrevIsOnline] = useState(isOnline);

  useEffect(() => {
    if (isOnline && !prevIsOnline && onRetry) {
      onRetry();
    }
    return () => {
      setPrevIsOnline(isOnline);
    };
  }, [prevIsOnline, isOnline, onRetry]);

  if (hasData) {
    return (
      <>
        {!isOnline && <NoInternetConnectionBanner />}
        {props.children}
      </>
    );
  } else if (!isOnline) {
    return (
      <div className={styles.container}>
        <NoInternetConnectionRetryView onRetry={onRetry} />
      </div>
    );
  }
  return <>{props.children}</>;
};

export default NoInternetConnectionView;
