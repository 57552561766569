import { useCallback } from "react";

import { useIntl } from "../i18n/Localization";
import { getStoreViewCodeForLocale } from "../i18n/locale";

export function useAddStoreToUrl(
  key: string = "___store"
): (url: string) => string {
  const { locale } = useIntl();

  return useCallback(
    (url: string) => {
      const urlObj = new URL(url);
      const params = urlObj.searchParams;
      params.append(key, getStoreViewCodeForLocale(locale));
      return urlObj.href;
    },
    [locale, key]
  );
}

export function useAddInAppToUrl(
  key: string = "in_app",
  value: "0" | "1" = "1"
): (url: string) => string {
  return useCallback(
    (url: string) => {
      const urlObj = new URL(url);
      const params = urlObj.searchParams;
      params.append(key, value);
      return urlObj.href;
    },
    [key, value]
  );
}

export function useAddAppBannerToUrl(
  key: string = "app_banner",
  value: "0" | "1" = "0"
): (url: string) => string {
  return useCallback(
    (url: string) => {
      const urlObj = new URL(url);
      const params = urlObj.searchParams;
      params.append(key, value);
      return urlObj.href;
    },
    [key, value]
  );
}
