import React, { useEffect, useRef } from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
  itemHeight: number;
  itemCount: number;
  margin: number;

  scrollElement: HTMLElement | null;
  renderItem: (index: number) => React.ReactNode;

  className?: string;
  itemClassName?: string;
}

interface Range {
  min: number;
  max: number;
}

const WindowedList: React.FC<Props> = props => {
  const {
    itemHeight,
    itemCount,
    margin,
    scrollElement,
    renderItem,
    className,
    itemClassName,
  } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [itemIndex, setItemIndex] = React.useState<Range>({ min: 0, max: 0 });
  const itemIndexRef = useRef(itemIndex);
  itemIndexRef.current = itemIndex;

  useEffect(() => {
    const listener = () => {
      if (scrollElement === null || containerRef.current == null) {
        return;
      }

      const scrollRect = scrollElement.getBoundingClientRect();
      const gridRect = containerRef.current.getBoundingClientRect();

      const viewHeight = scrollRect.height + scrollRect.top;

      const topIndex = Math.max(Math.floor(-gridRect.top / itemHeight), 0);
      const bottomIndex = Math.max(
        Math.floor((viewHeight - gridRect.top) / itemHeight),
        topIndex
      );

      if (
        topIndex !== itemIndexRef.current.min ||
        bottomIndex !== itemIndexRef.current.max
      ) {
        setItemIndex({
          min: topIndex,
          max: bottomIndex,
        });
      }
    };

    if (scrollElement) {
      scrollElement.addEventListener("scroll", listener);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", listener);
      }
    };
  }, [scrollElement, itemHeight]);

  const startIndex = Math.max(itemIndex.min - margin, 0);
  const endIndex = Math.min(itemIndex.max + margin, itemCount);
  const items = [];

  for (let i = startIndex; i <= endIndex; i++) {
    items.push(
      <div
        key={`item-${i}`}
        className={classnames(styles.item, itemClassName)}
        style={{ top: i * itemHeight }}
      >
        {renderItem(i)}
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={classnames(styles.container, className)}
      style={{ height: `${itemHeight * itemCount}px` }}
    >
      {items}
    </div>
  );
};

export default React.memo(WindowedList);
