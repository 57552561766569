type ProductLabelPosition =
  | "top-left"
  | "top-center"
  | "top-right"
  | "middle-left"
  | "middle-center"
  | "middle-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";

export interface ProductLabel {
  image: string | null;
  labelId: number | null;
  name: string | null;
  position: ProductLabelPosition;
  productId: number;
  size: string | null;
  style: string | null;
  txt: string | null;
}

export type ProductLabelMode = "category" | "product";

export const ProductLabelGraphQLAttributes = `
image
labelId: label_id
name
position
productId: product_id
size
style
txt
`;
