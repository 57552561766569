export function isEmpty(str: string): boolean {
  return str.trim().length === 0;
}

export function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

// Since the encoded % (%25) will be automatically decoded to % again
// By react router. Decoding a %-decoded url
// will raise exception for malformatted url.
// Try to use a hard to guess string used to replace % to prevent
// auto decoding back to %.
const encodedPercent = "_-cl-_-peRCEnt-_";

export function clEncodePercent(str: string): string {
  return str.replace(/%/g, encodedPercent);
}

export function clDecodePercent(str: string): string {
  return str.replace(new RegExp(encodedPercent, "g"), "%");
}
