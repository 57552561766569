import { Plugins } from "@capacitor/core";
import { isHybrid } from "../utils/Platform";

export async function registeriBeacon(
  uuid: string,
  major?: number,
  minor?: number,
  identifier?: string
) {
  if (isHybrid()) {
    Plugins.LocationPlugin.registerBeaconRegion({
      uuid,
      major,
      minor,
      identifier,
    });
  }
}

export function listenRegionEnter(
  callback: (info: BeaconRegion) => void
): () => void {
  if (isHybrid()) {
    const listener = Plugins.LocationPlugin.addListener(
      "enterRegion",
      callback
    );
    return () => {
      try {
        listener.remove();
      } catch {}
    };
  }
  return () => {};
}

export function listenRegionLeave(
  callback: (info: BeaconRegion) => void
): () => void {
  if (isHybrid()) {
    const listener = Plugins.LocationPlugin.addListener("exitRegion", callback);
    return () => {
      try {
        listener.remove();
      } catch {}
    };
  }
  return () => {};
}
