import { IonButton, IonIcon } from "@ionic/react";
import React, { useCallback } from "react";
import { LocalizedText } from "../../i18n/Localization";
import styles from "./NavBar.module.scss";

interface Props {
  disabled: boolean;
  onBackClick: () => void;
}

const NavBar: React.FC<Props> = props => {
  const { onBackClick, disabled } = props;

  const handleBackClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onBackClick();
    },
    [onBackClick]
  );

  return (
    <div className={styles.container}>
      <div className={styles.secondary}>
        <IonButton
          className={styles.button}
          onClick={handleBackClick}
          disabled={disabled}
        >
          <IonIcon name="arrow-back" className={styles.backIcon} />
        </IonButton>
      </div>
      <div className={styles.title}>
        <LocalizedText messageID="the_club_sso.club_shopping_sign_in.title" />
      </div>
    </div>
  );
};

export default NavBar;
