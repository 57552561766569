import React, { useCallback } from "react";
import LocalizedText from "../../i18n/LocalizedText";
import img from "../../resources/img-consent-shopping.jpg";
import { PrimaryButton } from "../Button";
import styles from "./ForShoppingLoginNotLinkedWithTheClubContent.module.scss";

interface Props {
  method: LoginSignUpMethod;
  onLinkWithTheClubClick: (method: LoginSignUpMethod) => void;
  onSwitchAccountClick: (method: LoginSignUpMethod) => void;
}

const ForShoppingLoginNotLinkedWithTheClubContent: React.FC<Props> = props => {
  const { method, onLinkWithTheClubClick, onSwitchAccountClick } = props;

  const handleLinkWithTheClubClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onLinkWithTheClubClick(method);
    },
    [onLinkWithTheClubClick, method]
  );

  const handleSwitchAccountClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onSwitchAccountClick(method);
    },
    [onSwitchAccountClick, method]
  );

  return (
    <div>
      <img src={img} />
      <div className={styles.content}>
        <h2 className={styles.title}>
          <LocalizedText messageID="the_club_sso.confirmation.for_the_club_login.title" />
        </h2>
        <p className={styles.secondTitle}>
          <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_not_linked_with_the_club.second_title" />
        </p>
        <PrimaryButton
          className={styles.submitButton}
          onClick={handleLinkWithTheClubClick}
          block={true}
        >
          <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_not_linked_with_the_club.link_with_the_club_button_text" />
        </PrimaryButton>
        <p className={styles.pairText}>
          <LocalizedText
            messageID="the_club_sso.confirmation.for_shopping_login_not_linked_with_the_club.pair_text"
            messageArgs={{
              onButtonClick: handleSwitchAccountClick,
              buttonClassName: styles.switchAccountButton,
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default ForShoppingLoginNotLinkedWithTheClubContent;
