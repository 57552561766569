import React from "react";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./styles.module.scss";

const NotEnoughtCLubPointBanner: React.FC = () => {
  return (
    <div className={styles.bannerBackground}>
      <p className={styles.bannerText}>
        <LocalizedText messageID="not_enought_club_point" />
      </p>
    </div>
  );
};

export default NotEnoughtCLubPointBanner;
