import { useState, useEffect, RefObject } from "react";

export function useIonContentScrollEl(
  ionContentRef: RefObject<HTMLIonContentElement>
): HTMLElement | null {
  const [scrollEl, setScrollEl] = useState<HTMLElement | null>(null);
  useEffect(() => {
    if (ionContentRef.current) {
      ionContentRef.current.getScrollElement().then(el => setScrollEl(el));
    }
  }, [ionContentRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
  return scrollEl;
}
