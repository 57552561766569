import React, { useCallback, useMemo } from "react";
import Config from "../../Config";
import { LocalizedText } from "../../i18n/Localization";
import {
  getQRCodes,
  isItemInProtection,
  orderItemDisplayName,
} from "../../models/Order";
import ClubProtectBanner from "./ClubProtectBanner";
import styles from "./Item.module.scss";
import { PartialOrderItem } from "./model";
import PriceDisplay from "./PriceDisplay";
import PromoCodeUrl from "./PromoCodeUrl";
import RedemptionLetterButton from "./RedemptionLetterButton";
import RedemptionLetterUrl from "./RedemptionLetterUrl";

interface Props {
  currencyCode: string;
  item: PartialOrderItem;
  onClickReorder: (item: PartialOrderItem) => void;
  onClickReview: (item: PartialOrderItem) => void;
  onClickRedeem: (item: PartialOrderItem) => void;
  onQRCodeLinkClicked: (url: string) => void;
  onClickViewInsurance: (item: PartialOrderItem) => void;
  onClickRedemptionLetterUrl: (url: string) => void;
}

const Item: React.FC<Props> = props => {
  const {
    currencyCode,
    item,
    onClickReorder,
    onClickReview,
    onClickRedeem,
    onQRCodeLinkClicked,
    onClickViewInsurance,
    onClickRedemptionLetterUrl,
  } = props;

  const isInProtection = useMemo(() => isItemInProtection(item), [item]);
  const priceClubpoint = item.requiredClubpoints;
  const price = useMemo(() => ({ currency: currencyCode, value: item.price }), [
    currencyCode,
    item.price,
  ]);
  const subtotal = useMemo(
    () => ({ currency: currencyCode, value: item.subtotal }),
    [currencyCode, item.subtotal]
  );

  const promoCodeUrls = useMemo(() => {
    const res: string[] = [];
    if (!item.promoCodeUrl) {
      return res;
    }
    for (const { code } of item.promoCodeUrl) {
      if (code) {
        res.push(code);
      }
    }
    return res;
  }, [item]);
  const qrCodes = useMemo(() => getQRCodes(item), [item]);

  const onClickViewInsuranceItem = useCallback(() => {
    onClickViewInsurance(item);
  }, [onClickViewInsurance, item]);

  const onClickRedeemItem = useCallback(() => {
    if (qrCodes.length > 0) {
      onClickRedeem(item);
    }
  }, [onClickRedeem, item, qrCodes]);

  const onClickReviewItem = useCallback(() => {
    onClickReview(item);
  }, [onClickReview, item]);

  const onClickReorderItem = useCallback(() => {
    onClickReorder(item);
  }, [onClickReorder, item]);

  return (
    <>
      <div className={styles.itemProductInfoContainer}>
        <div className={styles.itemProductName}>
          {orderItemDisplayName(item)}
        </div>
        <div className={styles.itemProductSku}>
          {item.simpleSku || item.sku}
        </div>
        {item.selectedOptions && item.selectedOptions.length ? (
          <div className={styles.itemSelectedOptions}>
            {item.selectedOptions.map((itemOption, i) => (
              <p key={i} className={styles.itemSelectedOption}>
                {itemOption.label}
                {itemOption.value ? <>: ${itemOption.value}</> : null}
              </p>
            ))}
          </div>
        ) : null}
        {promoCodeUrls.length > 0 ? (
          <div className={styles.promoCodeUrlSection}>
            {promoCodeUrls.map((promoCodeUrl, i) => (
              <PromoCodeUrl
                index={i}
                promoCodeUrl={promoCodeUrl}
                onLinkClicked={onQRCodeLinkClicked}
                key={i}
              />
            ))}
          </div>
        ) : null}
      </div>
      {item.clubProtectionEnable ? (
        <div className={styles.clubProtectBanner}>
          <ClubProtectBanner />
        </div>
      ) : null}
      {item.redemptionLetters ? (
        <div className={styles.redemptionLettersContainer}>
          {Config.ENABLE_PRE_ORDER_AND_EDD ? (
            item.redemptionLetters.map((redemptionLetter, i) => {
              const { url } = redemptionLetter;
              return url ? (
                <div className={styles.redemptionButton}>
                  <RedemptionLetterButton
                    url={url}
                    key={i}
                    onLinkClicked={onClickRedemptionLetterUrl}
                  />
                </div>
              ) : null;
            })
          ) : (
            <>
              <p className={styles.redemptionLettersHeader}>
                <LocalizedText messageID="order_detail.redemption_letters.header" />
              </p>
              {item.redemptionLetters.map((redemptionLetter, i) => {
                const { name, url } = redemptionLetter;
                return name && url ? (
                  <RedemptionLetterUrl
                    name={name}
                    url={url}
                    key={i}
                    onLinkClicked={onClickRedemptionLetterUrl}
                  />
                ) : null;
              })}
            </>
          )}
        </div>
      ) : null}
      <div className={styles.itemInfoList}>
        <div className={styles.priceQuantityRow}>
          <div className={styles.priceQuantityRow__priceColumn}>
            <p className={styles.totalPrice}>
              <PriceDisplay
                clubPoints={priceClubpoint * item.qtyOrdered}
                price={subtotal}
              />
            </p>
            {item.qtyOrdered > 1 && item.subtotal > 0 ? (
              <p className={styles.itemPrice}>
                <PriceDisplay clubPoints={priceClubpoint} price={price} />
              </p>
            ) : null}
          </div>
          <div className={styles.priceQuantityRow__quantityColumn}>
            <p className={styles.itemQuantity}>
              <LocalizedText
                messageID="order_detail.quantity"
                messageArgs={{ quantity: item.qtyOrdered }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className={styles.itemActionContainer}>
        {isInProtection ? (
          <button
            className={styles.itemAction}
            onClick={onClickViewInsuranceItem}
          >
            <LocalizedText messageID="order_detail.view_insurance" />
          </button>
        ) : null}
        {qrCodes.length > 0 ? (
          <button className={styles.itemAction} onClick={onClickRedeemItem}>
            <LocalizedText messageID="order_detail.redeem" />
          </button>
        ) : null}
        <button className={styles.itemAction} onClick={onClickReviewItem}>
          <LocalizedText messageID="order_detail.review" />
        </button>
        <button className={styles.itemAction} onClick={onClickReorderItem}>
          <LocalizedText messageID="order_detail.reorder" />
        </button>
      </div>
    </>
  );
};

export default Item;
