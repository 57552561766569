import React, {
  useMemo,
  useEffect,
  useCallback,
  useRef,
  useContext,
  useState,
} from "react";
import {
  useRequestVirtualWaitingRoomToken,
  useCheckVirtualWaitingRoomPosition,
  useFetchVirtualWaitingRoomConfig,
} from "../../repository/VirtualWaitingRoomRepository";

import {
  ClientMsg,
  CheckinResponse,
  RequestVirtualWaitingRoomTokenResponse,
  virtualWaitingRoomIdRegExp,
  CheckinResponseSuccess,
  CheckinResponseFailed,
} from "../../models/VirtualWaitingRoom";
import {
  isRequestLoading,
  getResources,
  getRequestStateError,
  isRequestError,
} from "../../models/ResourcesRequestState";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";

import { NetworkStatusContext } from "../NetworkStatusProvider";

import Config from "../../Config";

import WaitingView from "./WaitingView";
import FailedView from "./FailedView";

import useRetryWhenOnline from "../../hook/useRetryWhenOnline";

interface UnknownViewState {
  type: "unknown";
}

type LoadingConfigViewState = SimpleViewState<
  | {
      type: "enabled";
      appid: string;
      queue: string;
    }
  | { type: "disabled" }
>;

type LoadingInitialVirtualWaitingRoomTokenViewState = SimpleViewState<
  | { type: "success"; tokenid: string }
  | { type: "failed"; clientMsg?: ClientMsg | null }
>;

type LoadingInitialVirtualWaitingRoomPositionViewState = SimpleViewState<
  CheckinResponseSuccess | CheckinResponseFailed
>;

type LoopWaitingViewState =
  | {
      type: "waiting";
      waitingPos: number | undefined;
      waitingTotal: number | undefined;
      clientMsg?: ClientMsg | null;
    }
  | { type: "failed"; clientMsg?: ClientMsg | null }
  | { type: "active" };

type PipelineViewState =
  | { type: "initial" }
  | { type: "loading" }
  | { type: "active" }
  | { type: "error" }
  | LoopWaitingViewState;

const CLVirtualWaitingRoom: React.FC = props => {
  const { isOnline } = useContext(NetworkStatusContext);

  const [loadingConfigViewState, setLoadingConfigPipelineState] = useState<
    UnknownViewState | LoadingConfigViewState
  >({ type: "unknown" });

  const [
    loadingInitialVirtualWaitingRoomTokenViewState,
    setLoadingInitialVirtualWaitingRoomTokenPipelineState,
  ] = useState<
    UnknownViewState | LoadingInitialVirtualWaitingRoomTokenViewState
  >({
    type: "unknown",
  });

  const [loopWaitingViewState, setLoopWaitingPipelineState] = useState<
    UnknownViewState | LoopWaitingViewState
  >({ type: "unknown" });

  const [
    loadingInitialVirtualWaitingRoomPositionViewState,
    setLoadingInitialVirtualWaitingRoomPositionViewState,
  ] = useState<
    UnknownViewState | LoadingInitialVirtualWaitingRoomPositionViewState
  >({ type: "unknown" });

  const viewState = useMemo<{ type: "enabled" } | { type: "disabled" }>(() => {
    if (Config.VIRTUAL_WAITING_ROOM && Config.VIRTUAL_WAITING_ROOM.ENDPOINT) {
      return { type: "enabled" };
    }
    return { type: "disabled" };
  }, []);

  const pipelineViewState = useMemo<PipelineViewState>(() => {
    if (viewState.type === "disabled") {
      return { type: "active" };
    }
    // loading config
    if (
      loadingConfigViewState.type === "unknown" ||
      loadingConfigViewState.type === "initial" ||
      loadingConfigViewState.type === "loading"
    ) {
      return { type: "loading" };
    }
    if (loadingConfigViewState.type === "error") {
      return { type: "error" };
    }
    if (loadingConfigViewState.data.type === "disabled") {
      return { type: "active" };
    }

    // loading initial virtual waiting room token
    if (
      loadingInitialVirtualWaitingRoomTokenViewState.type === "unknown" ||
      loadingInitialVirtualWaitingRoomTokenViewState.type === "initial" ||
      loadingInitialVirtualWaitingRoomTokenViewState.type === "loading"
    ) {
      return { type: "loading" };
    }
    if (loadingInitialVirtualWaitingRoomTokenViewState.type === "error") {
      return { type: "error" };
    }
    if (loadingInitialVirtualWaitingRoomTokenViewState.data.type === "failed") {
      return loadingInitialVirtualWaitingRoomTokenViewState.data;
    }

    // loading initial virtual waiting room token
    if (
      loadingInitialVirtualWaitingRoomPositionViewState.type === "unknown" ||
      loadingInitialVirtualWaitingRoomPositionViewState.type === "initial" ||
      loadingInitialVirtualWaitingRoomPositionViewState.type === "loading"
    ) {
      return { type: "loading" };
    }
    if (loadingInitialVirtualWaitingRoomPositionViewState.type === "error") {
      return { type: "error" };
    }
    if (!loadingInitialVirtualWaitingRoomPositionViewState.data.success) {
      return {
        type: "failed",
        clientMsg:
          loadingInitialVirtualWaitingRoomPositionViewState.data.clientMsg,
      };
    }

    // loop waiting
    if (loopWaitingViewState.type !== "unknown") {
      return loopWaitingViewState;
    }

    return { type: "initial" };
  }, [
    viewState,
    loadingConfigViewState,
    loadingInitialVirtualWaitingRoomTokenViewState,
    loadingInitialVirtualWaitingRoomPositionViewState,
    loopWaitingViewState,
  ]);

  return (
    <>
      {pipelineViewState.type === "initial" ||
      pipelineViewState.type === "loading" ||
      pipelineViewState.type === "active" ||
      pipelineViewState.type === "error" ? (
        <>{props.children}</>
      ) : pipelineViewState.type === "waiting" ? (
        <>
          <WaitingView
            waitingPos={pipelineViewState.waitingPos}
            waitingTotal={pipelineViewState.waitingTotal}
            clientMsg={pipelineViewState.clientMsg}
          />
        </>
      ) : pipelineViewState.type === "failed" ? (
        <>
          <FailedView clientMsg={pipelineViewState.clientMsg} />
        </>
      ) : null}
      {viewState.type === "enabled" ? (
        <LoadingConfigView
          isOnline={isOnline}
          onLoadingConfigViewStateChange={setLoadingConfigPipelineState}
          onLoadingInitialVirtualWaitingRoomTokenViewStateChange={
            setLoadingInitialVirtualWaitingRoomTokenPipelineState
          }
          onLoadingInitialVirtualWaitingRoomPositionViewStateChange={
            setLoadingInitialVirtualWaitingRoomPositionViewState
          }
          onVirtualWaitingRoomViewStateChange={setLoopWaitingPipelineState}
        />
      ) : null}
    </>
  );
};

export default CLVirtualWaitingRoom;

interface LoadingConfigViewProp {
  isOnline: boolean;
  onLoadingConfigViewStateChange: (viewState: LoadingConfigViewState) => void;
  onLoadingInitialVirtualWaitingRoomTokenViewStateChange: (
    viewState: LoadingInitialVirtualWaitingRoomTokenViewState
  ) => void;
  onLoadingInitialVirtualWaitingRoomPositionViewStateChange: (
    viewState: LoadingInitialVirtualWaitingRoomPositionViewState
  ) => void;
  onVirtualWaitingRoomViewStateChange: (
    viewState: LoopWaitingViewState
  ) => void;
}

const LoadingConfigView: React.FC<LoadingConfigViewProp> = props => {
  const { onLoadingConfigViewStateChange } = props;

  const [
    fetchVirtualWaitingRoomConfigRequestState,
    fetchVirtualWaitingRoomConfig,
  ] = useFetchVirtualWaitingRoomConfig();

  const virtualWaitingRoomConfig = useMemo(
    () => getResources(fetchVirtualWaitingRoomConfigRequestState),
    [fetchVirtualWaitingRoomConfigRequestState]
  );
  const fetchVirtualWaitingRoomConfigIsLoading = useMemo(
    () => isRequestLoading(fetchVirtualWaitingRoomConfigRequestState),
    [fetchVirtualWaitingRoomConfigRequestState]
  );
  const fetchVirtualWaitingRoomConfigError = useMemo(
    () => getRequestStateError(fetchVirtualWaitingRoomConfigRequestState),
    [fetchVirtualWaitingRoomConfigRequestState]
  );

  const fetchVirtualWaitingRoomConfigRef = useRef(
    fetchVirtualWaitingRoomConfig
  );
  fetchVirtualWaitingRoomConfigRef.current = fetchVirtualWaitingRoomConfig;

  useEffect(() => {
    fetchVirtualWaitingRoomConfigRef.current().catch(() => {});
  }, []);

  const viewState = useMemo<LoadingConfigViewState>(() => {
    if (fetchVirtualWaitingRoomConfigIsLoading) {
      return SimpleViewStateLoading;
    }
    if (fetchVirtualWaitingRoomConfigError) {
      return SimpleViewStateError(fetchVirtualWaitingRoomConfigError);
    }
    if (virtualWaitingRoomConfig) {
      const {
        enableVirtualWaitingRoom,
        virtualWaitingRoomId,
      } = virtualWaitingRoomConfig;
      if (
        Config.VIRTUAL_WAITING_ROOM &&
        enableVirtualWaitingRoom &&
        virtualWaitingRoomId
      ) {
        const matched = virtualWaitingRoomIdRegExp.exec(virtualWaitingRoomId);
        if (matched) {
          const [, appid, queue] = matched;
          return SimpleViewStateDisplay({
            type: "enabled",
            appid,
            queue,
          });
        }
      }
      return SimpleViewStateDisplay({ type: "disabled" });
    }
    return SimpleViewStateInitial;
  }, [
    fetchVirtualWaitingRoomConfigError,
    fetchVirtualWaitingRoomConfigIsLoading,
    virtualWaitingRoomConfig,
  ]);

  useEffect(() => {
    onLoadingConfigViewStateChange(viewState);
  }, [onLoadingConfigViewStateChange, viewState]);

  return viewState.type === "display" && viewState.data.type === "enabled" ? (
    <RetryLoadInitialVirtualWaitingRoomConfigOnOnlineView
      appid={viewState.data.appid}
      queue={viewState.data.queue}
      {...props}
    />
  ) : null;
};

interface RetryLoadInitialVirtualWaitingRoomConfigOnOnlineViewProps {
  isOnline: boolean;
  appid: string;
  queue: string;
  onLoadingInitialVirtualWaitingRoomTokenViewStateChange: (
    viewState: LoadingInitialVirtualWaitingRoomTokenViewState
  ) => void;
  onLoadingInitialVirtualWaitingRoomPositionViewStateChange: (
    viewState: LoadingInitialVirtualWaitingRoomPositionViewState
  ) => void;
  onVirtualWaitingRoomViewStateChange: (
    viewState: LoopWaitingViewState
  ) => void;
}

const RetryLoadInitialVirtualWaitingRoomConfigOnOnlineView: React.FC<
  RetryLoadInitialVirtualWaitingRoomConfigOnOnlineViewProps
> = props => {
  const { isOnline } = props;

  const [appid, setAppid] = useState(props.appid);
  const [queue, setQueue] = useState(props.queue);

  const [
    fetchVirtualWaitingRoomConfigRequestState,
    _fetchVirtualWaitingRoomConfig,
  ] = useFetchVirtualWaitingRoomConfig();

  const fetchVirtualWaitingRoomConfig = useCallback(() => {
    _fetchVirtualWaitingRoomConfig().catch(() => {});
  }, [_fetchVirtualWaitingRoomConfig]);

  useRetryWhenOnline(isOnline, fetchVirtualWaitingRoomConfig);

  const virtualWaitingRoomConfig = useMemo(
    () => getResources(fetchVirtualWaitingRoomConfigRequestState),
    [fetchVirtualWaitingRoomConfigRequestState]
  );

  useEffect(() => {
    if (
      virtualWaitingRoomConfig &&
      virtualWaitingRoomConfig.enableVirtualWaitingRoom &&
      virtualWaitingRoomConfig.virtualWaitingRoomId
    ) {
      const matched = virtualWaitingRoomIdRegExp.exec(
        virtualWaitingRoomConfig.virtualWaitingRoomId
      );
      if (matched) {
        const [, _appid, _queue] = matched;
        setAppid(_appid);
        setQueue(_queue);
      }
    }
  }, [virtualWaitingRoomConfig]);

  return (
    <LoadingInitialVirtualWaitingRoomToken
      {...props}
      isOnline={isOnline}
      appid={appid}
      queue={queue}
    />
  );
};

interface LoadingInitialVirtualWaitingRoomTokenViewProps {
  isOnline: boolean;
  appid: string;
  queue: string;
  onLoadingInitialVirtualWaitingRoomTokenViewStateChange: (
    viewState: LoadingInitialVirtualWaitingRoomTokenViewState
  ) => void;
  onLoadingInitialVirtualWaitingRoomPositionViewStateChange: (
    viewState: LoadingInitialVirtualWaitingRoomPositionViewState
  ) => void;
  onVirtualWaitingRoomViewStateChange: (
    viewState: LoopWaitingViewState
  ) => void;
}

const LoadingInitialVirtualWaitingRoomToken: React.FC<
  LoadingInitialVirtualWaitingRoomTokenViewProps
> = props => {
  const { onLoadingInitialVirtualWaitingRoomTokenViewStateChange } = props;
  const [appid] = useState(props.appid);
  const [queue] = useState(props.queue);

  const [
    requestVirtualWaitingRoomTokenRequestState,
    requestVirtualWaitingRoomToken,
  ] = useRequestVirtualWaitingRoomToken(appid, queue);

  const isLoading = isRequestLoading(
    requestVirtualWaitingRoomTokenRequestState
  );
  const error = getRequestStateError(
    requestVirtualWaitingRoomTokenRequestState
  );
  const virtualWaitingRoomToken = getResources(
    requestVirtualWaitingRoomTokenRequestState
  );

  const requestVirtualWaitingRoomTokenRef = useRef(
    requestVirtualWaitingRoomToken
  );
  requestVirtualWaitingRoomTokenRef.current = requestVirtualWaitingRoomToken;

  useEffect(() => {
    requestVirtualWaitingRoomTokenRef.current().catch(() => {});
  }, []);

  // Loop get token when error

  const loopRequestTokenRequest = useCallback(() => {
    requestVirtualWaitingRoomToken().catch(() => {});
  }, [requestVirtualWaitingRoomToken]);

  const loopRequestTokenRequestRef = useRef(loopRequestTokenRequest);
  loopRequestTokenRequestRef.current = loopRequestTokenRequest;

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (isRequestError(requestVirtualWaitingRoomTokenRequestState)) {
      timeout = setTimeout(() => {
        loopRequestTokenRequestRef.current();
      }, 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [requestVirtualWaitingRoomTokenRequestState]);

  const viewState = useMemo<
    LoadingInitialVirtualWaitingRoomTokenViewState
  >(() => {
    if (isLoading) {
      return SimpleViewStateLoading;
    }
    if (error) {
      return SimpleViewStateError(error);
    }
    if (virtualWaitingRoomToken) {
      if (virtualWaitingRoomToken.success) {
        return SimpleViewStateDisplay({
          type: "success",
          tokenid: virtualWaitingRoomToken.tokenid,
        });
      }
      return SimpleViewStateDisplay({
        type: "failed",
        clientMsg: virtualWaitingRoomToken.clientMsg,
      });
    }
    return SimpleViewStateInitial;
  }, [isLoading, error, virtualWaitingRoomToken]);

  useEffect(() => {
    onLoadingInitialVirtualWaitingRoomTokenViewStateChange(viewState);
  }, [onLoadingInitialVirtualWaitingRoomTokenViewStateChange, viewState]);

  return viewState.type === "display" && viewState.data.type === "success" ? (
    <LoadingInitialVirtualWaitingRoomPositionView
      tokenid={viewState.data.tokenid}
      {...props}
    />
  ) : null;
};

interface LoadingInitialVirtualWaitingRoomPositionViewProps {
  isOnline: boolean;
  appid: string;
  queue: string;
  tokenid: string;
  onLoadingInitialVirtualWaitingRoomPositionViewStateChange: (
    viewState: LoadingInitialVirtualWaitingRoomPositionViewState
  ) => void;
  onVirtualWaitingRoomViewStateChange: (
    viewState: LoopWaitingViewState
  ) => void;
}

const LoadingInitialVirtualWaitingRoomPositionView: React.FC<
  LoadingInitialVirtualWaitingRoomPositionViewProps
> = props => {
  const { onLoadingInitialVirtualWaitingRoomPositionViewStateChange } = props;
  const [appid] = useState(props.appid);
  const [queue] = useState(props.queue);
  const [tokenid] = useState(props.tokenid);

  const [
    checkVirtualWaitingRoomPositionRequestState,
    checkVirtualWaitingRoomPosition,
  ] = useCheckVirtualWaitingRoomPosition(appid, queue);

  const isLoading = isRequestLoading(
    checkVirtualWaitingRoomPositionRequestState
  );
  const error = getRequestStateError(
    checkVirtualWaitingRoomPositionRequestState
  );
  const virtualWaitingRoomPosition = getResources(
    checkVirtualWaitingRoomPositionRequestState
  );

  const checkVirtualWaitingRoomPositionRef = useRef(
    checkVirtualWaitingRoomPosition
  );

  useEffect(() => {
    checkVirtualWaitingRoomPositionRef.current(tokenid).catch(() => {});
  }, [tokenid]);

  const viewState = useMemo<
    SimpleViewState<CheckinResponseSuccess | CheckinResponseFailed>
  >(() => {
    if (isLoading) {
      return SimpleViewStateLoading;
    }
    if (error) {
      return SimpleViewStateError(error);
    }
    if (virtualWaitingRoomPosition) {
      if (virtualWaitingRoomPosition.success) {
        return SimpleViewStateDisplay(virtualWaitingRoomPosition);
      }
      return SimpleViewStateDisplay(virtualWaitingRoomPosition);
    }
    return SimpleViewStateInitial;
  }, [isLoading, error, virtualWaitingRoomPosition]);

  useEffect(() => {
    onLoadingInitialVirtualWaitingRoomPositionViewStateChange(viewState);
  }, [onLoadingInitialVirtualWaitingRoomPositionViewStateChange, viewState]);

  return viewState.type === "display" && viewState.data.success ? (
    <LoopRequestView checkinResponse={viewState.data} {...props} />
  ) : null;
};

interface LoopRequestViewProps {
  isOnline: boolean;
  appid: string;
  queue: string;
  tokenid: string;
  checkinResponse: CheckinResponseSuccess;
  onVirtualWaitingRoomViewStateChange: (
    viewState: LoopWaitingViewState
  ) => void;
}

const LoopRequestView: React.FC<LoopRequestViewProps> = props => {
  const { onVirtualWaitingRoomViewStateChange } = props;
  const [appid] = useState(props.appid);
  const [queue] = useState(props.queue);
  const [tokenid, setTokenId] = useState<string | null>(props.tokenid);
  const [checkinResponse, setCheckinResponse] = useState<CheckinResponse>(
    props.checkinResponse
  );
  const [lastRequestTime, setLastRequestTime] = useState(new Date());

  // Loop checkin request when in ttl
  // Loop request token when not in ttl

  const [
    requestVirtualWaitingRoomTokenRequestState,
    requestVirtualWaitingRoomToken,
  ] = useRequestVirtualWaitingRoomToken(appid, queue);

  const [
    checkVirtualWaitingRoomPositionRequestState,
    checkVirtualWaitingRoomPosition,
  ] = useCheckVirtualWaitingRoomPosition(appid, queue);

  const loopRequestTokenRequest = useCallback(async () => {
    const t = await requestVirtualWaitingRoomToken();
    if (t.success) {
      setTokenId(t.tokenid);
    }
  }, [requestVirtualWaitingRoomToken]);

  const loopRequestTokenRequestRef = useRef(loopRequestTokenRequest);
  loopRequestTokenRequestRef.current = loopRequestTokenRequest;

  const loopCheckVirtualWaitingRoomPositionWithTTL = useCallback(
    async (ttl: number) => {
      if (!tokenid) {
        return;
      }
      const now = new Date();
      if (now.getTime() - lastRequestTime.getTime() > ttl) {
        // Loop checkin request when in ttl
        let requestVirtualWaitingRoomTokenResponse: RequestVirtualWaitingRoomTokenResponse | null = null;
        try {
          requestVirtualWaitingRoomTokenResponse = await requestVirtualWaitingRoomToken();
        } catch {
          setTokenId(null);
          return;
        }
        if (
          requestVirtualWaitingRoomTokenResponse &&
          requestVirtualWaitingRoomTokenResponse.success
        ) {
          try {
            const c = await checkVirtualWaitingRoomPosition(
              requestVirtualWaitingRoomTokenResponse.tokenid
            );
            setCheckinResponse(c);
            setTokenId(requestVirtualWaitingRoomTokenResponse.tokenid);
            setLastRequestTime(now);
          } catch {
            setTokenId(null);
          }
        }
      } else {
        // Loop checkin request when in ttl
        const c = await checkVirtualWaitingRoomPosition(tokenid);
        setCheckinResponse(c);
        setLastRequestTime(now);
      }
    },
    [
      tokenid,
      lastRequestTime,
      requestVirtualWaitingRoomToken,
      checkVirtualWaitingRoomPosition,
    ]
  );

  const loopCheckVirtualWaitingRoomPositionWithTTLRef = useRef(
    loopCheckVirtualWaitingRoomPositionWithTTL
  );
  loopCheckVirtualWaitingRoomPositionWithTTLRef.current = loopCheckVirtualWaitingRoomPositionWithTTL;

  const loopCheckVirtualWaitingRoomPosition = useCallback(async () => {
    if (!tokenid) {
      return;
    }
    const c = await checkVirtualWaitingRoomPosition(tokenid);
    setCheckinResponse(c);
    setLastRequestTime(new Date());
  }, [tokenid, checkVirtualWaitingRoomPosition]);

  const loopCheckVirtualWaitingRoomPositionRef = useRef(
    loopCheckVirtualWaitingRoomPosition
  );
  loopCheckVirtualWaitingRoomPositionRef.current = loopCheckVirtualWaitingRoomPosition;

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (!tokenid) {
      if (isRequestError(requestVirtualWaitingRoomTokenRequestState)) {
        timeout = setTimeout(() => {
          loopRequestTokenRequestRef.current();
        }, 5000);
      } else {
        const requestVirtualWaitingRoomTokenResponse = getResources(
          requestVirtualWaitingRoomTokenRequestState
        );
        if (requestVirtualWaitingRoomTokenResponse) {
          if (!requestVirtualWaitingRoomTokenResponse.success) {
            timeout = setTimeout(() => {
              loopRequestTokenRequestRef.current();
            }, 5000);
          }
        }
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [tokenid, requestVirtualWaitingRoomTokenRequestState]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (isRequestError(checkVirtualWaitingRoomPositionRequestState)) {
      timeout = setTimeout(() => {
        loopCheckVirtualWaitingRoomPositionRef.current();
      }, 5000);
    } else if (tokenid && checkinResponse.success) {
      const { ttl, nextPollingDelay } = checkinResponse;
      const t = nextPollingDelay >= ttl ? ttl / 2 : nextPollingDelay;
      timeout = setTimeout(() => {
        loopCheckVirtualWaitingRoomPositionWithTTLRef.current(ttl);
      }, t);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [tokenid, checkinResponse, checkVirtualWaitingRoomPositionRequestState]);

  const viewState = useMemo<LoopWaitingViewState>(() => {
    const tokenRequest = getResources(
      requestVirtualWaitingRoomTokenRequestState
    );
    if (tokenRequest && !tokenRequest.success) {
      return { type: "failed", clientMsg: tokenRequest.clientMsg };
    }
    if (checkinResponse.success) {
      if (checkinResponse.isActive) {
        return { type: "active" };
      }
      const { waitingPos, waitingTotal, clientMsg } = checkinResponse;
      return { type: "waiting", waitingPos, waitingTotal, clientMsg };
    }
    return { type: "failed", clientMsg: checkinResponse.clientMsg };
  }, [checkinResponse, requestVirtualWaitingRoomTokenRequestState]);

  useEffect(() => {
    onVirtualWaitingRoomViewStateChange(viewState);
  }, [onVirtualWaitingRoomViewStateChange, viewState]);

  return null;
};
