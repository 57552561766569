import React, { ComponentType } from "react";
import ReactModal from "react-modal";
import styles from "./styles.module.scss";
import { LoadingView } from "../LoadingView";
import ModalContainerWithBackdrop from "../ModalContainerWithBackdrop";

const noop = () => {};

type WithAsyncAction = <T>(action: () => Promise<T>) => Promise<T>;

interface LoadingModalContext {
  show: () => void;
  hide: () => void;
  withLoadingModalAsync: WithAsyncAction;
}

export const LoadingModalContext = React.createContext<LoadingModalContext>({
  show: () => {},
  hide: () => {},
  withLoadingModalAsync: async <T,>(action: () => Promise<T>) => action(),
});

const LoadingModalProvider: React.FC = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const show = React.useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const hide = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const withLoadingModalAsync = React.useCallback(
    async <T,>(action: () => Promise<T>) => {
      show();
      try {
        return await action();
      } finally {
        hide();
      }
    },
    [show, hide]
  );

  const contextValue = React.useMemo(
    () => ({
      show,
      hide,
      withLoadingModalAsync,
    }),
    [show, hide, withLoadingModalAsync]
  );

  return (
    <>
      <LoadingModalContext.Provider value={contextValue}>
        {props.children}
      </LoadingModalContext.Provider>
      <ReactModal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={noop}
        ariaHideApp={false}
      >
        <ModalContainerWithBackdrop className={styles.modalBody} align="center">
          <div className={styles.loadingContainer}>
            <LoadingView />
          </div>
        </ModalContainerWithBackdrop>
      </ReactModal>
    </>
  );
};

export default LoadingModalProvider;

export interface LoadingModalContextProps {
  loadingModalContext: LoadingModalContext;
}

export function withLoadingModal<P>(
  Component: ComponentType<P & LoadingModalContextProps>
): ComponentType<P> {
  const Wrapped: React.FC<P> = props => {
    return (
      <LoadingModalProvider>
        <LoadingModalContext.Consumer>
          {context => <Component {...props} loadingModalContext={context} />}
        </LoadingModalContext.Consumer>
      </LoadingModalProvider>
    );
  };

  return Wrapped;
}
