import React, { ComponentType, useContext, useMemo } from "react";
import { Session } from "../utils/PerformanceRecordStore/sessions";

interface ProfileSessionContext {
  rootProfileSession: Session;
  parentProfileSession: Session | null;
  profileSessionPath: Session[];
  profileSession: Session;
}

export const ProfileSessionContext = React.createContext<ProfileSessionContext | null>(
  null
);

interface Props {
  profileSession: Session;
}

export const ProfileSessionProvider: React.FC<Props> = props => {
  const { profileSession } = props;
  const parentProfileSessionContext = useContext(ProfileSessionContext);

  const parentProfileSession = useMemo(
    () =>
      parentProfileSessionContext
        ? parentProfileSessionContext.profileSession
        : null,
    [parentProfileSessionContext]
  );

  const rootProfileSession = useMemo(
    () =>
      parentProfileSessionContext
        ? parentProfileSessionContext.rootProfileSession
        : profileSession,
    [parentProfileSessionContext, profileSession]
  );

  const profileSessionPath = useMemo(
    () =>
      parentProfileSessionContext
        ? [...parentProfileSessionContext.profileSessionPath, profileSession]
        : [profileSession],
    [parentProfileSessionContext, profileSession]
  );

  const value = useMemo(
    () => ({
      profileSession,
      parentProfileSession,
      rootProfileSession,
      profileSessionPath,
    }),
    [
      profileSession,
      parentProfileSession,
      rootProfileSession,
      profileSessionPath,
    ]
  );

  return (
    <ProfileSessionContext.Provider value={value}>
      {props.children}
    </ProfileSessionContext.Provider>
  );
};

export function withProfileSession<P>(
  sessionGenerator: (props: P) => Session,
  Component: ComponentType<P>
) {
  const Wrapped: React.FC<P> = props => {
    const session = useMemo(() => sessionGenerator(props), [props]);
    return (
      <ProfileSessionProvider profileSession={session}>
        <Component {...props} />
      </ProfileSessionProvider>
    );
  };
  return Wrapped;
}
