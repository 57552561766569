import React, { useRef, useMemo, useCallback, useContext } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { LocalizedText } from "../../i18n/Localization";

import {
  SimpleViewState,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
  SimpleViewStateDisplay,
  SimpleViewStateError,
} from "../../models/SimpleViewState";
import {
  getResources,
  isRequestLoading,
  isRequestError,
} from "../../models/ResourcesRequestState";
import { getQRCodes, OrderQRCode } from "../../models/Order";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { FullContentLoadingView } from "../LoadingView";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import QRCodeView from "../QRCodeView";

import {
  useInMemoryPartialOrder,
  useOrderResource,
} from "../OrderDetailPage/api";
import { PartialOrderItem } from "../OrderDetailPage/model";

import styles from "./styles.module.scss";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { isUrl } from "../../utils/Url";

type Props = RouteComponentProps<{ id: string; productID: string }>;

const OrderDetailsQRCodesRedemptionPage: React.FC<Props> = props => {
  const { match } = props;
  const { id: orderID, productID } = match.params;

  const contentRef = useRef<HTMLIonContentElement>(null);
  const { isOnline } = useContext(NetworkStatusContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const partialOrderInMemory = useInMemoryPartialOrder(orderID);

  const [fetchOrderRequestState, fetchOrder, refresh] = useOrderResource();

  const partialOrder = useMemo(() => {
    return getResources(fetchOrderRequestState) || partialOrderInMemory;
  }, [fetchOrderRequestState, partialOrderInMemory]);

  const onViewDidEnter = useCallback(() => {
    if (!partialOrderInMemory) {
      fetchOrder(orderID).catch(() => {});
    }
  }, [partialOrderInMemory, fetchOrder, orderID]);
  ionLifeCycleContext.onIonViewDidEnter(onViewDidEnter);

  const viewState = useMemo<SimpleViewState<PartialOrderItem>>(() => {
    if (isRequestError(fetchOrderRequestState)) {
      return SimpleViewStateError(fetchOrderRequestState.error);
    }
    if (partialOrder) {
      for (const partialOrderItem of partialOrder.items) {
        if (`${partialOrderItem.productID}` === productID) {
          return SimpleViewStateDisplay(partialOrderItem);
        }
      }
      return SimpleViewStateError(new Error("Not Found"));
    }
    if (isRequestLoading(fetchOrderRequestState)) {
      return SimpleViewStateLoading;
    }
    return SimpleViewStateInitial;
  }, [partialOrder, fetchOrderRequestState, productID]);

  const onRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh(orderID);
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh, orderID]
  );

  const dismissAndOpenLinkInExternalBrowser = useCallback((url: string) => {
    window.open(url, "_system");
  }, []);

  const onLinkClicked = useCallback(
    (url: string) => {
      presentLocalizedAlert({
        headerId: "open_link_in_external_browser.header",
        messageId: "open_link_in_external_browser.message",
        messageArgs: {
          url,
        },
        buttons: [
          {
            textMessageID: "alert.button.ok",
            handler: () => dismissAndOpenLinkInExternalBrowser(url),
          },
          {
            textMessageID: "cancel",
          },
        ],
      });
    },
    [presentLocalizedAlert, dismissAndOpenLinkInExternalBrowser]
  );

  const retry = useCallback(() => {
    refresh(orderID).catch(() => {});
  }, [refresh, orderID]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="order_details_qr_codes_redemption.title" />
        }
      />
      <CLContent ref={contentRef} className={styles.ionContent}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={partialOrder != null}
          onRetry={retry}
        >
          {viewState.type === "initial" || viewState.type === "loading" ? (
            <FullContentLoadingView />
          ) : (
            <Reloadable
              viewState={viewState}
              onRefresh={onRefresh}
              onLinkClicked={onLinkClicked}
            />
          )}
          <TabBarSpacePlaceholder />
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default OrderDetailsQRCodesRedemptionPage;

interface ReloadableProps {
  onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
  onLinkClicked: (url: string) => void;
  viewState: Extract<
    SimpleViewState<PartialOrderItem>,
    { type: "error" } | { type: "display" }
  >;
}

const Reloadable: React.FC<ReloadableProps> = props => {
  const { onRefresh, viewState, onLinkClicked } = props;

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {viewState.type === "error" ? (
        <ErrorView error={viewState.error} />
      ) : null}
      {viewState.type === "display" ? (
        <DisplayView
          partialOrderItem={viewState.data}
          onLinkClicked={onLinkClicked}
        />
      ) : null}
    </>
  );
};

interface DisplayViewProps {
  partialOrderItem: PartialOrderItem;
  onLinkClicked: (url: string) => void;
}

const DisplayView: React.FC<DisplayViewProps> = props => {
  const { partialOrderItem, onLinkClicked } = props;

  const qrCodes = useMemo(() => getQRCodes(partialOrderItem), [
    partialOrderItem,
  ]);

  return (
    <div>
      {qrCodes.length === 0 ? (
        <div className={styles.emptyMessage}>
          <LocalizedText messageID="order_details_qr_codes_redemption.empty" />
        </div>
      ) : (
        qrCodes.map((qrCode, i) => (
          <QRCodeContainer
            qrCode={qrCode}
            onLinkClicked={onLinkClicked}
            key={i}
          />
        ))
      )}
    </div>
  );
};

interface ErrorProps {
  error: Error | null;
}

const ErrorView: React.FC<ErrorProps> = props => {
  const { error } = props;

  return (
    <div className={styles.errorMessage}>
      {error != null ? error.message : null}
    </div>
  );
};

type QRCodeType = { type: "text"; text: string } | { type: "url"; url: string };

interface QRCodeContainerProps {
  qrCode: OrderQRCode;
  onLinkClicked: (url: string) => void;
}

const QRCodeContainer: React.FC<QRCodeContainerProps> = props => {
  const { qrCode, onLinkClicked } = props;

  const qrCodeType = useMemo<QRCodeType>(() => {
    if (isUrl(qrCode.code)) {
      return { type: "url", url: qrCode.code };
    }
    return { type: "text", text: qrCode.code };
  }, [qrCode]);

  const handleLinkClicked = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (qrCodeType.type === "url") {
        onLinkClicked(qrCodeType.url);
      }
    },
    [onLinkClicked, qrCodeType]
  );

  return (
    <div className={styles.qrCodeContainer}>
      <div className={styles.qrCode}>
        <QRCodeView code={qrCode.code} />
        {qrCodeType.type === "text" ? (
          <p className={styles.theCode}>{qrCodeType.text}</p>
        ) : (
          <p className={styles.theLink}>
            <a href={qrCodeType.url} onClick={handleLinkClicked}>
              {qrCodeType.url}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
