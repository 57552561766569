import React, { useRef, useEffect, useMemo } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import { setStylesToElementWithSelector } from "../../utils/ionicPatch";
import { RangeChangeEventDetail } from "@ionic/core";
import CLRange from "../CLRange";

interface Props {
  className?: string;
  minValue: number;
  maxValue: number;
  currentValue: number;
  valuePerStep: number;
  disabled?: boolean;
  onChange: (value: number) => void;
}

const ClubpointSlider: React.FC<Props> = props => {
  const {
    className,
    minValue,
    maxValue: _maxValue,
    currentValue,
    valuePerStep,
    disabled: _disabled = false,
    onChange,
  } = props;

  const maxValue = useMemo(
    () => Math.floor(_maxValue / valuePerStep) * valuePerStep,
    [_maxValue, valuePerStep]
  );

  const disabled = useMemo(() => {
    return _disabled || minValue === maxValue;
  }, [_disabled, minValue, maxValue]);

  const ref = useRef<HTMLIonRangeElement>(null);
  useEffect(() => {
    (async () => {
      if (ref.current == null) {
        return;
      }

      await setStylesToElementWithSelector(ref.current, ".range-knob", {
        "border-width": "var(--knob-border-width)",
        "border-style": "var(--knob-border-style)",
        "border-color": "var(--knob-border-color)",
        left: "calc(50% - var(--knob-border-width) - var(--knob-size) / 2)",
        top: "calc(50% - var(--knob-border-width) - var(--knob-size) / 2)",
      });
    })();
  }, [ref]);
  const onRangeChange = React.useCallback(
    (event: CustomEvent<RangeChangeEventDetail>) => {
      onChange(event.detail.value as number);
    },
    [onChange]
  );
  return (
    <div className={cn(className, styles.sliderContainer)}>
      <div className={cn(styles.sliderLabelValue, styles.start)}>
        {minValue}
      </div>
      <CLRange
        ref={ref}
        className={styles.ionSlider}
        snaps={true}
        ticks={false}
        debounce={1000 / 60}
        min={minValue}
        max={maxValue}
        step={valuePerStep}
        value={currentValue}
        onIonChange={onRangeChange}
        disabled={disabled}
      />
      <div className={cn(styles.sliderLabelValue, styles.end)}>{maxValue}</div>
    </div>
  );
};

export default ClubpointSlider;
