import React from "react";
// eslint-disable-next-line
import { IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from "@ionic/react";

import { ClientMsg } from "../../models/VirtualWaitingRoom";

import CLContent from "../CLContent";

import styles from "./FailedView.module.scss";

import virtualWaitingRoomGif from "../../resources/virtual-waiting-room.gif";

interface FailedViewProps {
  clientMsg?: ClientMsg | null;
}

const FailedView: React.FC<FailedViewProps> = props => {
  const { clientMsg } = props;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonTitle>
            <div className={styles.logo} />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <CLContent className={styles.content}>
        <div className={styles.failedView}>
          <IonImg src={virtualWaitingRoomGif} alt="" />
          <div className={styles.body}>
            {clientMsg &&
            (clientMsg.default || clientMsg.zh || clientMsg.en) ? (
              <div className={styles.clientMsg}>
                {clientMsg.zh ? (
                  <div className={styles.clientMsgP}>{clientMsg.zh}</div>
                ) : null}
                {clientMsg.en ? (
                  <div className={styles.clientMsgP}>{clientMsg.en}</div>
                ) : null}
                {clientMsg.default && (!clientMsg.en && !clientMsg.zh) ? (
                  <div className={styles.clientMsgP}>{clientMsg.default}</div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </CLContent>
    </IonPage>
  );
};

export default FailedView;
