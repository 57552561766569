export type ResourcesSource = "remote" | "local-cache" | "memory";

interface ResourcesRequestStateInitial {
  type: "initial";
}
export function ResourcesRequestStateInitial(): ResourcesRequestStateInitial {
  return {
    type: "initial",
  };
}

interface ResourcesRequestStateLoading {
  type: "loading";
}
export function ResourcesRequestStateLoading(): ResourcesRequestStateLoading {
  return {
    type: "loading",
  };
}

interface ResourcesRequestStateLoadingWithResources<T> {
  type: "loading-with-resources";
  source: ResourcesSource;
  resources: T;
}
export function ResourcesRequestStateLoadingWithResources<T>(
  source: ResourcesSource,
  resources: T
): ResourcesRequestStateLoadingWithResources<T> {
  return {
    type: "loading-with-resources",
    source,
    resources,
  };
}

interface ResourcesRequestStateLoaded<T> {
  type: "loaded";
  source: ResourcesSource;
  resources: T;
}
export function ResourcesRequestStateLoaded<T>(
  source: ResourcesSource,
  resources: T
): ResourcesRequestStateLoaded<T> {
  return {
    type: "loaded",
    source,
    resources,
  };
}

interface ResourcesRequestStateError {
  type: "error";
  error: Error;
}
export function ResourcesRequestStateError(
  error: Error
): ResourcesRequestStateError {
  return {
    type: "error",
    error,
  };
}

interface ResourcesRequestStateErrorWithResources<T> {
  type: "error-with-resources";
  error: Error;
  source: ResourcesSource;
  resources: T;
}
export function ResourcesRequestStateErrorWithResources<T>(
  error: Error,
  source: ResourcesSource,
  resources: T
): ResourcesRequestStateErrorWithResources<T> {
  return {
    type: "error-with-resources",
    error,
    source,
    resources,
  };
}

export type ResourcesRequestState<T> =
  | ResourcesRequestStateInitial
  | ResourcesRequestStateLoading
  | ResourcesRequestStateLoadingWithResources<T>
  | ResourcesRequestStateLoaded<T>
  | ResourcesRequestStateError
  | ResourcesRequestStateErrorWithResources<T>;

export function isRequestLoading<T>(
  state: ResourcesRequestState<T>
): state is
  | ResourcesRequestStateLoading
  | ResourcesRequestStateLoadingWithResources<T> {
  return state.type === "loading" || state.type === "loading-with-resources";
}

export function isRequestError<T>(
  state: ResourcesRequestState<T>
): state is
  | ResourcesRequestStateError
  | ResourcesRequestStateErrorWithResources<T> {
  return state.type === "error" || state.type === "error-with-resources";
}

export function getRequestStateError<T>(
  state: ResourcesRequestState<T>
): Error | null {
  if (state.type === "error" || state.type === "error-with-resources") {
    return state.error;
  }
  return null;
}

export function getResources<T>(state: ResourcesRequestState<T>): T | null {
  if (
    state.type === "loaded" ||
    state.type === "loading-with-resources" ||
    state.type === "error-with-resources"
  ) {
    return state.resources;
  }
  return null;
}
