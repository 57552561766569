import React, { useEffect, useMemo } from "react";

import Config from "../../Config";

import {
  useRecentlySearches,
  useHotSearches,
  useSearchSuggestion,
} from "../../repository/SearchRepository";

import {
  getRequestStateError,
  getResources,
} from "../../models/ResourcesRequestState";

import { SearchTerm, SearchAutoSuggestion } from "../../models/Search";

import { isEmpty } from "../../utils/String";

import { LoadingView } from "../LoadingView";

import RecentlySearchesList from "./RecentlySearchesList";
import PopularSearchesList from "./PopularSearchesList";
import ProductSearchesList from "./ProductSearchesList";
import HotSearchesList from "./HotSearchesList";
import FilteredListingSearchesList from "./FilteredListingSearchesList";
import { SearchSuggestionListItemItem } from "./SearchSuggestionList";
import SearchEmptyView from "./SearchEmptyView";

import styles from "./SearchSuggestionPage.module.scss";

type DisplayMode =
  | DisplayModeLoading
  | DisplayModeHotAndRecentSearches
  | DisplayModePopularAndFilterSearches
  | DisplayModeError;

interface DisplayModeLoading {
  type: "loading";
}

function DisplayModeLoading(): DisplayModeLoading {
  return {
    type: "loading",
  };
}

interface DisplayModeHotAndRecentSearches {
  type: "hot-recent";
  recentlySearches: SearchTerm[];
  hotSearches: SearchAutoSuggestion[];
}

function DisplayModeHotAndRecentSearches(
  recentlySearches: SearchTerm[],
  hotSearches: SearchAutoSuggestion[]
): DisplayModeHotAndRecentSearches {
  return {
    type: "hot-recent",
    recentlySearches,
    hotSearches,
  };
}

interface DisplayModePopularAndFilterSearches {
  type: "popular-filter";
  popularSearches: SearchAutoSuggestion[];
  products: { items: { sku: string; name: string }[]; totalCount: number };
  filteredListing: { items: SearchAutoSuggestion[]; searchTerm: string };
  brand: SearchAutoSuggestion[];
}

function DisplayModePopularAndFilterSearches(
  popularSearches: SearchAutoSuggestion[],
  products: { items: { sku: string; name: string }[]; totalCount: number },
  filteredListing: { items: SearchAutoSuggestion[]; searchTerm: string },
  brand: SearchAutoSuggestion[]
): DisplayModePopularAndFilterSearches {
  return {
    type: "popular-filter",
    popularSearches,
    products,
    filteredListing,
    brand,
  };
}

interface DisplayModeError {
  type: "error";
  error: Error;
}

function DisplayModeError(error: Error): DisplayModeError {
  return {
    type: "error",
    error,
  };
}

interface Props {
  searchTerm: string;
  isLoading: boolean;
  searchSuggestionRequestState: ReturnType<typeof useSearchSuggestion>;
  onClickSearchTerm: (searchTerm: SearchTerm) => void;
  onClickSearchSuggestion: (searchSuggestion: SearchAutoSuggestion) => void;
  onClickProductSearchesListItem: (item: SearchSuggestionListItemItem) => void;
  onClickFilteredListItem: (item: SearchSuggestionListItemItem) => void;
  onClickBrandItem: (item: SearchSuggestionListItemItem) => void;
  onClickBackToStorefrontButton: (event: React.MouseEvent<unknown>) => void;
}

const SearchSuggestion: React.FC<Props> = props => {
  const {
    searchTerm,
    isLoading,
    searchSuggestionRequestState,
    onClickSearchTerm,
    onClickSearchSuggestion,
    onClickProductSearchesListItem,
    onClickFilteredListItem,
    onClickBrandItem,
    onClickBackToStorefrontButton,
  } = props;

  const [recentlySearches, loadRecentlySearches] = useRecentlySearches();
  useEffect(() => loadRecentlySearches(), [loadRecentlySearches]);
  const hotSearchesRequestState = useHotSearches();

  const displayMode = useMemo<DisplayMode>(() => {
    if (isLoading) {
      return DisplayModeLoading();
    }

    const error = getRequestStateError(searchSuggestionRequestState);
    if (error) {
      return DisplayModeError(error);
    }

    if (isEmpty(searchTerm)) {
      if (recentlySearches) {
        return DisplayModeHotAndRecentSearches(
          recentlySearches,
          getResources(hotSearchesRequestState) || []
        );
      }
      return DisplayModeLoading();
    }

    const searchSuggestion = getResources(searchSuggestionRequestState);
    if (searchSuggestion) {
      return DisplayModePopularAndFilterSearches(
        searchSuggestion.popularSearches,
        searchSuggestion.products,
        searchSuggestion.filteredListing,
        searchSuggestion.brand
      );
    }

    return DisplayModeLoading();
  }, [
    isLoading,
    searchTerm,
    recentlySearches,
    hotSearchesRequestState,
    searchSuggestionRequestState,
  ]);

  return (
    <>
      {displayMode.type === "loading" && (
        <div className={styles.loadingContainer}>
          <LoadingView />
        </div>
      )}
      {displayMode.type === "hot-recent" && (
        <>
          <HotSearchesList
            hotSearches={displayMode.hotSearches}
            onClickItem={onClickSearchSuggestion}
          />
          <RecentlySearchesList
            recentlySearches={displayMode.recentlySearches}
            onClickItem={onClickSearchTerm}
          />
        </>
      )}
      {displayMode.type === "popular-filter" && (
        <>
          <PopularSearchesList
            popularSearches={displayMode.popularSearches}
            onClickItem={onClickSearchSuggestion}
          />
          {Config.ENABLE_BRAND ? (
            <FilteredListingSearchesList
              searchTerm={displayMode.filteredListing.searchTerm}
              items={displayMode.brand}
              onClickItem={onClickBrandItem}
              titleID="page.search_suggestion.list.brand_list.title"
            />
          ) : null}
          <ProductSearchesList
            products={displayMode.products.items}
            totalCount={displayMode.products.totalCount}
            onClickItem={onClickProductSearchesListItem}
          />
          <FilteredListingSearchesList
            searchTerm={displayMode.filteredListing.searchTerm}
            items={displayMode.filteredListing.items}
            onClickItem={onClickFilteredListItem}
            titleID="page.search_suggestion.list.filtered_listing.title"
          />
        </>
      )}
      {displayMode.type === "error" ? (
        <div className={styles.emptyContainer}>
          <SearchEmptyView
            searchTerm={searchTerm}
            onClickBackToStorefrontButton={onClickBackToStorefrontButton}
          />
        </div>
      ) : null}
    </>
  );
};

export default SearchSuggestion;
