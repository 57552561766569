import { ApolloClient, ApolloQueryResult, gql } from "@apollo/client";
import { PartialOrder } from "./models";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";

const orderGraphQL = `
entityID: entity_id
incrementID: increment_id
currencyCode: currency_code
grandTotal: grand_total
subtotal
discountDescription: discount_description
discountAmount: discount_amount
shippingAmount: shipping_amount
shippingAddress: shipping_address {
  name
  street
  city
  region
  countryId: country_id
  telephone
  company
}
pickupAddress: pickup_address {
  block
  building
  flat
  floor
  lockerCode: locker_code
  lockerProvider: locker_provider
  storeType: store_type
  street
}
items {
  name
  sku
  simpleName: simple_name
  simpleSku: simple_sku
  subtotal: row_total
  qtyOrdered: qty_ordered
  shippingLabel: union_shipping_label
}
`;

const orderEstimatedDeliveryDateGraphQL = `
entityID: entity_id
estimatedDeliveryDate: estimated_delivery_date {
  shippingType: shipping_type
  estimatedDeliveryDate: estimated_delivery_date
}
`;

export async function fetchOrder(
  client: ApolloClient<any>,
  locale: Locale,
  id: string
): Promise<PartialOrder> {
  const query = <T>(
    graphQLAttributes: string
  ): Promise<ApolloQueryResult<{ salesOrder: T }>> => {
    return client.query<{ salesOrder: T }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryOrder($incrementID: String!) {
        salesOrder(increment_id: $incrementID) {
          ${graphQLAttributes}
        }
      }
    `,
      variables: {
        incrementID: id,
      },
      fetchPolicy: "network-only",
    });
  };

  const [baseResult, orderEstimatedDeliveryDateResult] = await Promise.all([
    query<Omit<PartialOrder, "estimatedDeliveryDate">>(orderGraphQL),
    query<Pick<PartialOrder, "estimatedDeliveryDate">>(
      orderEstimatedDeliveryDateGraphQL
    ).catch(() => ({
      data: { salesOrder: { estimatedDeliveryDate: [] } },
    })),
  ]);

  const res = {
    ...baseResult.data.salesOrder,
    ...orderEstimatedDeliveryDateResult.data.salesOrder,
  };
  return res;
}
