export interface PageInfo {
  currentPage: number;
  pageSize: number;
  totalPages: number;
}

export const PageInfoGraphQLAttributes = `
  currentPage: current_page
  pageSize: page_size
  totalPages: total_pages
`;
