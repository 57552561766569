import React, {
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

import { OurNavContext } from "../../our-navigation";
import { CustomerProductReview } from "../../models/ProductReview";
import {
  isRequestLoading,
  isRequestError,
  getResources,
} from "../../models/ResourcesRequestState";
import { useFetchCustomerProductReviews } from "../../repository/ProductReviewRepository";
import { LocalizedText } from "../../i18n/Localization";
import {
  useCurrentTab,
  getPathForProductDetailPage,
  getPathForHomePage,
} from "../../navigation/routes";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";

import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { NavBar, NavBarBackButton } from "../NavBar";
import CustomerProductReviewCell from "../ProductReviews/CustomerProductReviewCell";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import { PrimaryButton } from "../Button";

import ProductLinkView from "./ProductLinkView";

import styles from "./styles.module.scss";

interface ViewStateLoading {
  type: "loading";
}
export const ViewStateLoading: ViewStateLoading = {
  type: "loading",
};

interface ViewStateError {
  type: "error";
}
function ViewStateError(): ViewStateError {
  return {
    type: "error",
  };
}

interface ViewStateEmpty {
  type: "empty";
}
function ViewStateEmpty(): ViewStateEmpty {
  return {
    type: "empty",
  };
}

interface ViewStateDisplay {
  type: "display";
  productReviews: CustomerProductReview[];
}
function ViewStateDisplay(
  productReviews: CustomerProductReview[]
): ViewStateDisplay {
  return {
    type: "display",
    productReviews,
  };
}

type ViewState =
  | ViewStateLoading
  | ViewStateError
  | ViewStateEmpty
  | ViewStateDisplay;

const CustomerProductReviewPage: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const { isOnline } = useContext(NetworkStatusContext);
  const { navigate } = useContext(OurNavContext);

  const currentTab = useCurrentTab();

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const [
    fetchProductReviewsRequestState,
    fetchProductReviews,
    refreshProductReviews,
  ] = useFetchCustomerProductReviews();

  useEffect(() => {
    fetchProductReviews().catch(() => {});
  }, [fetchProductReviews]);

  const viewState = useMemo<ViewState>(() => {
    const productReviews = getResources(fetchProductReviewsRequestState);
    if (!productReviews) {
      if (isRequestLoading(fetchProductReviewsRequestState)) {
        return ViewStateLoading;
      }
      if (isRequestError(fetchProductReviewsRequestState)) {
        return ViewStateError();
      }
      return ViewStateEmpty();
    }
    if (productReviews.length === 0) {
      return ViewStateEmpty();
    }
    return ViewStateDisplay(productReviews);
  }, [fetchProductReviewsRequestState]);

  const retry = useCallback(() => {
    refreshProductReviews().catch(() => {});
  }, [refreshProductReviews]);

  const onRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refreshProductReviews();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refreshProductReviews]
  );

  const handleProductLinkClick = useCallback(
    (sku: string) => {
      navigate(getPathForProductDetailPage(currentTab, sku));
    },
    [navigate, currentTab]
  );

  const handleGoShoppingClick = useCallback(() => {
    navigate(getPathForHomePage());
  }, [navigate]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="page.customer_product_reviews.navbar.title" />
        }
      />
      <CLContent ref={contentRef} className={styles.content}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "empty"}
          onRetry={retry}
        >
          {viewState.type === "loading" && <FullContentLoadingView />}
          {viewState.type === "error" ||
          viewState.type === "empty" ||
          viewState.type === "display" ? (
            <List
              viewState={viewState}
              onRefresh={onRefresh}
              onProductLinkClick={handleProductLinkClick}
              onGoShoppingClick={handleGoShoppingClick}
            />
          ) : null}
        </NoInternetConnectionView>
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

const ErrorView: React.FC = () => {
  return (
    <div className={styles.errorView}>
      <p className={styles.errorViewMessage}>
        <LocalizedText messageID="error.unknown" />
      </p>
    </div>
  );
};

interface EmptyViewProps {
  onGoShoppingClick: () => void;
}
const EmptyView: React.FC<EmptyViewProps> = props => {
  const { onGoShoppingClick } = props;

  const handleGoShoppingClick = useCallback(() => {
    onGoShoppingClick();
  }, [onGoShoppingClick]);

  return (
    <div className={styles.emptyView}>
      <div className={styles.emptyViewWrapper}>
        <p className={styles.emptyViewMessage}>
          <LocalizedText messageID="page.product_detail.product_reviews.no_reviews" />
        </p>
        <PrimaryButton
          className={styles.letsGoShoppingButton}
          onClick={handleGoShoppingClick}
        >
          <LocalizedText messageID="page.customer_product_reviews.lets_go_shopping" />
        </PrimaryButton>
      </div>
    </div>
  );
};

interface ListProps {
  viewState: ViewStateDisplay | ViewStateEmpty | ViewStateError;
  onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
  onProductLinkClick: (sku: string) => void;
  onGoShoppingClick: () => void;
}

const List: React.FC<ListProps> = props => {
  const { viewState, onRefresh, onProductLinkClick, onGoShoppingClick } = props;

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {viewState.type === "empty" && (
        <EmptyView onGoShoppingClick={onGoShoppingClick} />
      )}
      {viewState.type === "error" && <ErrorView />}
      {viewState.type === "display" && (
        <div className={styles.listView}>
          {viewState.productReviews.map((productReview, i) => {
            return (
              <div className={styles.listItem} key={i}>
                {productReview.product != null ? (
                  <ProductLinkView
                    sku={productReview.product.sku}
                    productName={productReview.product.name}
                    merchantName={productReview.product.vendorName || undefined}
                    onClick={onProductLinkClick}
                  />
                ) : null}
                <div className={styles.listItemContent}>
                  <CustomerProductReviewCell productReview={productReview} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CustomerProductReviewPage;
