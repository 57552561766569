import React, { useRef, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import MainTabPageNavBar from "../MainTabPageNavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

import styles from "./styles.module.scss";
import HTMLBasedCMSPage from "../HTMLBasedCMSPage";
import { HTML_BASED_CMS_PAGE_TYPE } from "../../models/cmsBlock";
import { useAppConfig } from "../../repository/ConfigRepository";

const AllCategoriesPage: React.FC<RouteComponentProps> = () => {
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const appConfig = useAppConfig();
  const pageType: HTML_BASED_CMS_PAGE_TYPE | null = useMemo(() => {
    return appConfig && appConfig.allCategoryCMSPageID
      ? {
          type: "cmsPage",
          identifier: appConfig.allCategoryCMSPageID,
        }
      : null;
  }, [appConfig]);

  return (
    <>
      <MainTabPageNavBar />
      <CLContent ref={contentRef} className={styles.ionContent}>
        {!pageType && <FullContentLoadingView />}
        {pageType && (
          <HTMLBasedCMSPage pageType={pageType} allowRefresh={false} />
        )}
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

export default AllCategoriesPage;
