import React, { useMemo } from "react";
import SearchSuggestionList, {
  SearchSuggestionListItemItem,
} from "./SearchSuggestionList";
import { stringLiteral } from "../../utils/type";

interface Props {
  products: { sku: string; name: string }[];
  totalCount: number;
  onClickItem: (item: SearchSuggestionListItemItem) => void;
}

const ProductSearchesList: React.FC<Props> = props => {
  const { products, totalCount, onClickItem } = props;
  const listItems = useMemo(
    () => ({
      type: stringLiteral("products"),
      products,
    }),
    [products]
  );
  return (
    <SearchSuggestionList
      displayMode="row"
      headerTitleID="page.search_suggestion.list.product_searches.title"
      items={listItems}
      totalCount={totalCount}
      onClickItem={onClickItem}
    />
  );
};

export default ProductSearchesList;
