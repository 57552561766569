import { IonIcon } from "@ionic/react";
import classnames from "classnames";
import numeral from "numeral";
import React, { useCallback, useMemo } from "react";
import { LocalizedText } from "../../../i18n/Localization";
import {
  ClubMemberCustomer,
  ClubTier,
  Customer,
  getCustomerClubPoints,
  getCustomerClubTier,
  isCustomerLinkedToTheClub,
} from "../../../models/Customer";
import styles from "./styles.module.scss";

interface Props {
  customer: Customer;
  onClick: () => void;
  onLinkWithTheClubClick: () => void;
}

const TheClubBanner: React.FC<Props> = props => {
  const { customer, onClick, onLinkWithTheClubClick } = props;
  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );
  return isCustomerLinkedToTheClub(customer) ? (
    <button className={styles.theClubSection} onClick={handleClick}>
      <TheClubSection customer={customer} />
    </button>
  ) : (
    <div className={styles.theClubSection}>
      <LinkTheClubSection onLinkWithTheClubClick={onLinkWithTheClubClick} />
    </div>
  );
};

export default TheClubBanner;

interface LinkTheClubSectionProps {
  onLinkWithTheClubClick: () => void;
}

const LinkTheClubSection: React.FC<LinkTheClubSectionProps> = props => {
  const { onLinkWithTheClubClick } = props;
  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onLinkWithTheClubClick();
    },
    [onLinkWithTheClubClick]
  );
  return (
    <button className={styles.theClubCard} onClick={handleClick}>
      <div className={styles.withArrow}>
        <div className={styles.theClubDesc}>
          <LocalizedText messageID="page.home.link_the_club_section.link_text" />
        </div>
      </div>
    </button>
  );
};

interface TheClubSectionProps {
  customer: ClubMemberCustomer;
}

const TheClubSection: React.FC<TheClubSectionProps> = props => {
  const { customer } = props;

  const tierClass = useMemo(() => {
    const tierClassMapping: { [key in ClubTier]: string } = {
      blue: "blueCard",
      silver: "silverCard",
      gold: "goldCard",
      platinum: "platinumCard",
      black: "blackCard",
    };
    return tierClassMapping[getCustomerClubTier(customer)];
  }, [customer]);

  return (
    <div className={classnames(styles.clubPointCard, styles[tierClass])}>
      <div className={styles.clubPointCardUpperSection}>
        <div className={styles.clubPoint}>
          <h6 className={styles.clubPointTitle}>
            <LocalizedText messageID="page.home.the_club_section.club_points_earned.label" />
          </h6>
          <p className={styles.clubPointValue}>
            <IonIcon icon="club-point" className={styles.clubPointIcon} />{" "}
            {numeral(getCustomerClubPoints(customer)).format("0,0")}
          </p>
        </div>
        <div className={styles.tier}>
          <p className={styles.tierText}>
            <LocalizedText
              messageID="page.home.the_club_section.club_tier"
              messageArgs={{ tier: getCustomerClubTier(customer) }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
