import React, { useCallback, useContext } from "react";
import cn from "classnames";

import { LocalizedText, useIntl } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";
import { isiOS, isiPad } from "../../utils/Platform";

import { CLContentContext } from "../CLContent";

import styles from "./Select.module.scss";

interface SelectOption {
  value: string | number;
  label: string;
}

interface Props {
  className?: string;
  fieldClassName?: string;
  titleMessageID?: MessageID;
  placeholderID?: MessageID;
  options: SelectOption[];
  value: string | number | null;
  onClick?: () => void;
  onChange: (value: string) => void;
  isError?: boolean;
  errorMessage?: string;
  required?: boolean;
}

const Select: React.FC<Props> = props => {
  const {
    className,
    fieldClassName,
    titleMessageID,
    value: _value,
    onClick,
    onChange,
    options,
    placeholderID,
    isError,
    errorMessage,
    required,
  } = props;
  const { translate } = useIntl();

  const clContentContext = useContext(CLContentContext);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  const handleFocus = useCallback(
    el => {
      if (el.target && clContentContext && isiOS() && !isiPad()) {
        clContentContext.scrollToElement(el.target, 100);
      }
    },
    [clContentContext]
  );

  const shouldDisabled = options.length === 0;

  return (
    <div className={className}>
      <label
        className={cn(fieldClassName, styles.select, {
          [styles.selectDisabled]: shouldDisabled,
        })}
      >
        {titleMessageID && (
          <div className={styles.titleColumn}>
            <span className={cn({ [styles.required]: required })}>
              <LocalizedText messageID={titleMessageID} />
            </span>
          </div>
        )}
        <div className={styles.inputColumn}>
          <select
            value={_value || ""}
            onClick={onClick}
            onChange={handleChange}
            className={cn(styles.selectBox, {
              [styles.haveTitle]: !!titleMessageID,
              [styles.selectBoxError]: !!isError,
              [styles.placeholder]: !_value,
            })}
            disabled={shouldDisabled}
            onFocus={handleFocus}
          >
            <option value="">
              {placeholderID
                ? translate(placeholderID) +
                  (required && !titleMessageID ? " *" : "")
                : required && !titleMessageID
                ? `(${translate("required")})`
                : null}
            </option>
            {options.map(({ label, value }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        {isError && <div className={styles.errorIcon} />}
      </label>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default Select;
