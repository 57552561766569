import React, { useEffect, useContext, useState } from "react";
import { ApolloProvider } from "@apollo/react-components";
import { IonApp } from "@ionic/react";
import { ApolloProvider as ApolloClientProvider } from "@apollo/react-hooks";
import { Plugins } from "@capacitor/core";
import { addIcons } from "ionicons";
import { arrowUp, arrowForward, share } from "ionicons/icons";
import * as Sentry from "sentry-cordova";

import { LocalizationProvider } from "./i18n/Localization";
import ErrorBoundary from "./components/ErrorBoundary";

import { useLocale, Locale } from "./i18n/locale";
import AppNavigator from "./components/navigation/AppNavigator";
import DeviceOrientation from "./components/DeviceOrientation";

import { withProviders } from "./components/Provider";
import RepositoryProvider from "./repository/RepositoryProvider";
import {
  NetworkStatusProvider,
  NetworkStatusContext,
} from "./components/NetworkStatusProvider";
import { useAccessToken } from "./api/TokenStore";
import { ContentScrollProvider } from "./components/ContentScrollProvider";
import { BackAndroidHandlerProvider } from "./components/BackAndroidHandler";
import {
  LocalizedAlertProvider,
  LocalizedAlertContext,
} from "./components/LocalizedAlertProvider";
import QRScannerProvider from "./components/QRScanner/lazy";
import { OurRouterOutletProvider } from "./our-navigation/OurRouterOutlet";
import { Provider as CheckoutProvider } from "./components/Checkout/Context";
import { apolloClient, cachePersistor } from "./api/GraphQL";
import {
  CartIDProvider,
  useCartIDFromStorage,
} from "./components/CartIDProvider";
import WishlistProvider from "./components/WishlistProvider";
import { ShoppingCartItemCountProvider } from "./components/ShoppingCartItemCountProvider";
import { PushNotificationProvider } from "./components/PushNotificationProvider";
import { GoogleAdsProvider } from "./components/GoogleAds";
import MaintenanceModeBlocker from "./components/MaintenanceModeBlocker";
import GoogleAdsInterstitial from "./components/GoogleAdsInterstitial";
import CLVirtualWaitingRoom from "./components/VirtualWaitingRoom";
import AppUIPlaceholder from "./components/AppUIPlaceholder";
import SubscribeProductRestockAlertProvider from "./components/SubscribeProductRestockAlert/Provider.lazy";

import { CountryRegionDistrictContextProvider } from "./contexts/CountryRegionDistrictContext";
import { ProductAttributeFilterContextProvider } from "./contexts/ProductAttributeFilterContext";
import { ProductAttributeSortContextProvider } from "./contexts/ProductAttributeSortContext";
import { ConfigContextProvider } from "./contexts/ConfigContext";
import { CategoryContextProvider } from "./contexts/CategoryContext";
import { ArticleContextProvider } from "./contexts/ArticleContext";
import { BingoListContextProvider } from "./contexts/BingoListContext";

import {
  useFetchStoreConfig,
  useAutoFetchAppConfig,
  useStateMemoryConfigs,
} from "./repository/ConfigRepository";

import Config from "./Config";
import RerenderBlocker from "./components/RerenderBlocker";
import { useKeepUpdatingRef } from "./hook/utils";
import { isiOS, isAndroid, isDesktop, isHybrid } from "./utils/Platform";
import { gtm } from "./utils/GTM";
import { profileAsyncAction } from "./utils/performance";
import { InitializeHomePageSession } from "./utils/PerformanceRecordStore/sessions";

import clubPointIcon from "./resources/ic-club-points.svg";
import clShareIcon from "./resources/share.svg";
import AppUpdateObserver from "./components/AppUpdateObserver";
import AdobeExperiencePlatform from "./components/AdobeExperiencePlatform";
import useRetryWhenOnline from "./hook/useRetryWhenOnline";
import clMessageIcon from "./resources/ic-message.svg";
import clHomeIcon from "./resources/ic-home.svg";
import clLiveChatIcon from "./resources/ic-livechat.svg";
import clSubscribeRestockIcon from "./resources/ic-product-subscribe-restock.svg";
import clSubscribeRestockNoticedIcon from "./resources/ic-product-subscribe-restock-noticed.svg";
import shoppingCartIcon from "./resources/shopping-cart-icon.svg";
import plusIcon from "./resources/plus.svg";

if (isiOS() && Config.IOS_GTM_ID) {
  gtm(Config.IOS_GTM_ID);
} else if (isAndroid() && Config.ANDROID_GTM_ID) {
  gtm(Config.ANDROID_GTM_ID);
} else if (isDesktop() && Config.WEB_GTM_ID) {
  gtm(Config.WEB_GTM_ID);
}

// https://github.com/ionic-team/ionic/issues/19078#issuecomment-520769707
addIcons({
  "ios-arrow-forward": arrowForward.ios,
  "md-arrow-forward": arrowForward.md,
  "ios-arrow-up": arrowUp.ios,
  "md-arrow-up": arrowUp.md,
  "ios-cl-share": clShareIcon,
  "md-cl-share": share.md,
  "ios-club-point": clubPointIcon,
  "md-club-point": clubPointIcon,
  "ios-cl-message": clMessageIcon,
  "md-cl-message": clMessageIcon,
  "ios-cl-home": clHomeIcon,
  "md-cl-home": clHomeIcon,
  "ios-cl-livechat": clLiveChatIcon,
  "md-cl-livechat": clLiveChatIcon,
  "ios-cl-subscribe-restock": clSubscribeRestockIcon,
  "md-cl-subscribe-restock": clSubscribeRestockIcon,
  "ios-cl-subscribe-restock-noticed": clSubscribeRestockNoticedIcon,
  "md-cl-subscribe-restock-noticed": clSubscribeRestockNoticedIcon,
  "ios-shopping-cart": shoppingCartIcon,
  "md-shopping-cart": shoppingCartIcon,
  "ios-plus": plusIcon,
  "md-plus": plusIcon,
});

const FetchConfigs: React.FC = props => {
  const { isOnline } = useContext(NetworkStatusContext);
  const configs = useStateMemoryConfigs();
  const configsRef = useKeepUpdatingRef(configs);
  const [, fetchStoreConfig] = useFetchStoreConfig();
  const [, fetchAppConfig] = useAutoFetchAppConfig();

  useRetryWhenOnline(isOnline, fetchStoreConfig);
  useRetryWhenOnline(isOnline, fetchAppConfig);

  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  useEffect(() => {
    const fetchConfigs = () =>
      Promise.all([fetchStoreConfig(), fetchAppConfig()]).catch(() => {
        if (configsRef.current != null) {
          return;
        }
        presentLocalizedAlert({
          headerId: "alert.fetch_config.error.title",
          buttons: [
            {
              textMessageID: "alert.fetch_config.error.retry",
              handler: () => {
                fetchConfigs();
              },
            },
          ],
        });
      });
    fetchConfigs();
  }, [configsRef, fetchStoreConfig, fetchAppConfig, presentLocalizedAlert]);

  return <>{props.children}</>;
};

const App: React.FC<{
  cartID: string | null;
  locale: Locale;
}> = props => {
  useEffect(() => {
    if (isHybrid() && isiOS()) {
      Plugins.Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  }, []);

  useEffect(() => {
    Plugins.SplashScreen.hide();
  }, []);

  const [cacheRestored, setCacheRestored] = useState(false);

  useEffect(() => {
    profileAsyncAction(InitializeHomePageSession(), "Cache Restore", () =>
      cachePersistor.restore().catch(e => {
        console.warn(e);
      })
    ).finally(() => setCacheRestored(true));
  }, []);

  if (!cacheRestored) {
    return <AppUIPlaceholder />;
  }

  return (
    <OurRouterOutletProvider>
      <AppUIPlaceholder />
      <NetworkStatusProvider>
        <LocalizationProvider locale={props.locale}>
          <ApolloProvider client={apolloClient}>
            <ApolloClientProvider client={apolloClient}>
              <DeviceOrientation>
                <LocalizedAlertProvider>
                  <GoogleAdsInterstitial>
                    <MaintenanceModeBlocker>
                      <CLVirtualWaitingRoom>
                        <PushNotificationProvider>
                          <RepositoryProvider>
                            {isHybrid() &&
                            Config.ADOBE_EXPERIENCE_PLATFORM_APP_ID ? (
                              <AdobeExperiencePlatform
                                appId={Config.ADOBE_EXPERIENCE_PLATFORM_APP_ID}
                              />
                            ) : null}
                            <AppUpdateObserver>
                              <ContentScrollProvider>
                                <BackAndroidHandlerProvider>
                                  <QRScannerProvider>
                                    <ShoppingCartItemCountProvider>
                                      <CheckoutProvider>
                                        <CartIDProvider cartID={props.cartID}>
                                          <FetchConfigs>
                                            <WishlistProvider>
                                              <SubscribeProductRestockAlertProvider>
                                                <GoogleAdsProvider>
                                                  <RerenderBlocker>
                                                    <AppNavigator />
                                                  </RerenderBlocker>
                                                </GoogleAdsProvider>
                                              </SubscribeProductRestockAlertProvider>
                                            </WishlistProvider>
                                          </FetchConfigs>
                                        </CartIDProvider>
                                      </CheckoutProvider>
                                    </ShoppingCartItemCountProvider>
                                  </QRScannerProvider>
                                </BackAndroidHandlerProvider>
                              </ContentScrollProvider>
                            </AppUpdateObserver>
                          </RepositoryProvider>
                        </PushNotificationProvider>
                      </CLVirtualWaitingRoom>
                    </MaintenanceModeBlocker>
                  </GoogleAdsInterstitial>
                </LocalizedAlertProvider>
              </DeviceOrientation>
            </ApolloClientProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </NetworkStatusProvider>
    </OurRouterOutletProvider>
  );
};

const ProvidedApp = withProviders(
  App,
  CountryRegionDistrictContextProvider,
  ProductAttributeFilterContextProvider,
  ProductAttributeSortContextProvider,
  ConfigContextProvider,
  CategoryContextProvider,
  ArticleContextProvider,
  BingoListContextProvider
);

const PrepareApp: React.FunctionComponent = () => {
  const locale = useLocale();
  const getAccessTokenFinished = useAccessToken();
  const { cartID, getCartIDFinished } = useCartIDFromStorage();

  return (
    <IonApp>
      {locale != null && getAccessTokenFinished && getCartIDFinished ? (
        <ProvidedApp locale={locale} cartID={cartID} />
      ) : (
        <AppInitIssueReporter />
      )}
    </IonApp>
  );
};

const AppInitIssueReporter: React.FC = () => {
  const locale = useLocale();
  const getAccessTokenFinished = useAccessToken();
  const { getCartIDFinished } = useCartIDFromStorage();

  const reportTimer = React.useRef<any>(null);

  React.useEffect(() => {
    reportTimer.current = setTimeout(() => {
      reportTimer.current = null;
      Sentry.captureEvent({
        message: "Stuck at SplashScreen",
        extra: {
          isLocaleReady: locale != null,
          getAccessTokenFinished,
          getCartIDFinished,
        },
      });
    }, 15000);

    return () => {
      if (reportTimer.current) {
        clearTimeout(reportTimer.current);
        reportTimer.current = null;
      }
    };
  }, [locale, getAccessTokenFinished, getCartIDFinished]);

  return null;
};

// ErrorBoundary must be the top level component
export default function AppWithErrorBoundary() {
  useEffect(() => {
    if (!(isiOS() || isAndroid()) && Config.GOOGLE_CLIENT_ID) {
      // Only initialize it in web
      require("@codetrix-studio/capacitor-google-auth");
    }
  }, []);
  return (
    <ErrorBoundary>
      <PrepareApp />
      <meta name="google-signin-client_id" content={Config.GOOGLE_CLIENT_ID} />
    </ErrorBoundary>
  );
}
