import { useCallback } from "react";

import { MessageID } from "../i18n/translations/type";
import { filterNullOrUndefined } from "../utils/array";
import { IndexMap, NumericBoolean } from "../utils/type";

import { Country, CountryID } from "./CountryRegion";

export const INSTALMENT_PAYMENT_METHOD = "Credit Card Instalment";

export type OrderStatus =
  | "canceled"
  | "closed"
  | "complete"
  | "pending"
  | "pending_clubpoint"
  | "pending_payment"
  | "processing";
export interface Order {
  entityID: number;
  incrementID: string;
  customerName: string;
  currencyCode: string;
  grandTotal: number;
  subtotal: number;
  initialSubscriptionFee: number | null;
  discountDescription: string | null;
  discountAmount: number;
  shippingAmount: number;
  totalPaid: number;
  isGuestCustomer: boolean;
  createdAt: string;
  updatedAt: string;
  orderStatus: OrderStatus;
  paymentMethod: string;
  shippingMethod: string;
  deliveryTimeSlot: string;
  shippingAddress: ShippingAddress[];
  billingAddress: BillingAddress[];
  items: OrderItem[];
  clubMemberId: string | null;
  clubpointEarned: number;
  clubpointUsed: number;
  clubCurrencyAmount: number | null;
  pickupAddress: OtoStore;
  fulfillment: FulfillmentCode | null;
  shipmentStatusList: (ShipmentStatus | null)[] | null;
  estimatedDeliveryDate?: OrderEstimatedDeliveryDate[] | null;
}

export interface OtoStore {
  block: string;
  building: string;
  flat: string;
  floor: string;
  lockerCode: string;
  lockerProvider: string;
  storeType: string;
  street: string;
}

export type ShippingAddress = Partial<Address>;
export type BillingAddress = Partial<Address>;

interface Address {
  name: string;
  street: string;
  city: string;
  region: string;
  countryId: CountryID;
  telephone: string;
  company: string;
}

export interface OrderItemAttributeInfo {
  label: string;
  value: string;
  optionID: number;
  optionValue: string;
}

interface RedemptionLetterOutput {
  name: string | null;
  url: string | null;
}

export interface ItemOption {
  label: string;
  value: string;
}

export type OrderItemShippingLabel =
  | "Self pick-up and e-voucher"
  | "自取貨品及電子優惠劵"
  | ClubShoppingShippingLabel
  | ConsolidateShippingLabel
  | "Merchant Delivery"
  | "商戶派送";

type ConsolidateShippingLabel = "3-5 days Consolidated Delivery" | "3-5 天集運";

type ClubShoppingShippingLabel =
  | "CLUB SHOPPING Delivery"
  | "CLUB SHOPPING 派送";

export function isConsolidation(
  shippingLabel: OrderItemShippingLabel
): shippingLabel is ConsolidateShippingLabel {
  return (
    shippingLabel === "3-5 days Consolidated Delivery" ||
    shippingLabel === "3-5 天集運"
  );
}

export function isClubShoppingDelivery(
  shippingLabel: OrderItemShippingLabel
): shippingLabel is ClubShoppingShippingLabel {
  return (
    shippingLabel === "CLUB SHOPPING Delivery" ||
    shippingLabel === "CLUB SHOPPING 派送"
  );
}

interface OrderItemAttrs {
  name: string;
  sku: string;
  requiredClubpoints: number;
  price: number;
  subtotal: number;
  qtyOrdered: number;
  productID: number;
  productType: string;
  isVirtual: boolean;
  shippingLabel: OrderItemShippingLabel;
  estimatedDeliveryDate?: string | null;
  shippingTypeID: number;
  attributesInfo: OrderItemAttributeInfo[] | null;
  merchant: OrderItemMerchant | null;
  qrCodeGen: MaybeOrderQRCode | null;
  qrCodeImport: MaybeOrderQRCode[] | null;
  promoCodeUrl: MaybeOrderQRCode[] | null;

  clubProtectionEnable?: NumericBoolean;
  protectStartDate?: Date | null;
  protectEndDate?: Date | null;

  redemptionLetters?: RedemptionLetterOutput[] | null;
  selectedOptions?: ItemOption[];
}

export interface SimpleOrderItem extends OrderItemAttrs {
  productType: "simple";
  simpleName: null;
  simpleSku: null;
}

export interface VirtualOrderItem extends OrderItemAttrs {
  productType: "virtual";
  simpleName: null;
  simpleSku: null;
}

export interface ConfigurableOrderItem extends OrderItemAttrs {
  productType: "configurable";
  simpleName: string;
  simpleSku: string;
}

export function getOrderItemSku(orderItem: {
  simpleSku: string | null;
  sku: string;
}): string {
  return orderItem.simpleSku || orderItem.sku;
}

export function orderItemDisplayName(item: OrderItem) {
  return item.simpleName || item.name;
}

export type OrderItem =
  | SimpleOrderItem
  | VirtualOrderItem
  | ConfigurableOrderItem;

export interface OrderItemMerchant {
  entityID: number;
  name: string;
}

export interface FulfillmentCode {
  fulfillCode: string | null;
}

export interface ShipmentStatus {
  incrementIds?: string[] | null;
  stages?: (ShipmentStage | null)[] | null;
  trackNumber?: string | null;
  trackTitle?: string | null;
  productIds?: number[] | null;
}

export interface RemoteShipmentStage {
  isProcessing: boolean;
  label: string | null;
  timestamp: number | null;
}

export interface ShipmentStage {
  isProcessing: boolean;
  label: string | null;
  timestamp: Date | null;
}

interface MaybeOrderQRCode {
  code: string | null;
}

export interface OrderQRCode {
  type: "gen" | "import";
  code: string;
}

// Also has merchant type but in value "Merchant %s" (e.g. "Merchant HKT")
export type ShippingType = "EVoucher" | "Warehouse" | "Consolidate";

export interface OrderEstimatedDeliveryDate {
  estimatedDeliveryDate: string; // The date is already
  shippingType: ShippingType;
}

export function getEstimatedDeliveryDateOfType<
  O extends Pick<Order, "estimatedDeliveryDate">
>(shippingType: ShippingType, order: O) {
  const { estimatedDeliveryDate } = order;
  if (estimatedDeliveryDate) {
    const selected = estimatedDeliveryDate.filter(
      e => e.shippingType === shippingType
    );
    if (selected.length > 0) {
      return selected[0];
    }
  }
  return null;
}

export function getEstimatedDeliveryDateOfMerchant<
  O extends Pick<Order, "estimatedDeliveryDate">
>(order: O) {
  const { estimatedDeliveryDate } = order;
  if (estimatedDeliveryDate) {
    const selected = estimatedDeliveryDate.filter(
      e =>
        // Regard other types as merchant
        (["EVoucher", "Warehouse", "Consolidate"] as ShippingType[]).indexOf(
          e.shippingType
        ) === -1
    );
    if (selected.length > 0) {
      return selected[0];
    }
  }
  return null;
}

export function formatAddress(
  countryByID: IndexMap<CountryID, Country>,
  address: ShippingAddress | BillingAddress,
  delimiter: string = "\n"
) {
  const country = address.countryId ? countryByID[address.countryId] : null;
  const countryName = country ? country.name : null;

  const result = [
    address.name,
    address.company,
    address.street,
    [address.city, address.region].filter(x => x).join(", "),
    countryName,
  ]
    .filter(x => x)
    .join(delimiter);

  return result;
}

const storeTypeMapping: { [key: string]: MessageID } = {
  o2o_store: "store_type.o2o_store",
  elocker: "store_type.elocker",
};

const lockerTypeMapping: { [key: string]: string } = {
  "hkt-shop": "HKT Shop",
  "csl-shop": "CSL Shop",
  "1010-center": "1010 Shop",
  "4px": "4PX Locker",
  alfred: "Alfred e-locker",
};

export function getOtoStoreTypeMessageID(otoStore: OtoStore): MessageID {
  return storeTypeMapping[otoStore.storeType];
}

export function useFormatOtoStore() {
  return useCallback((address: OtoStore, delimiter: string = "\n") => {
    const displayLockerType =
      lockerTypeMapping[address.lockerProvider] == null
        ? address.lockerProvider
        : lockerTypeMapping[address.lockerProvider];

    const result = [
      displayLockerType,
      address.lockerCode,
      address.street,
      address.building,
      address.block,
      address.floor,
      address.flat,
    ]
      .filter(x => x)
      .join(delimiter);

    return result;
  }, []);
}

export function getLastShipmentStageFromShipmentStatusList(
  shipmentStatusList: (ShipmentStatus | null)[] | null
): ShipmentStage | null {
  if (!shipmentStatusList || shipmentStatusList.length === 0) {
    return null;
  }
  const [shipmentStatus] = shipmentStatusList;
  if (!shipmentStatus) {
    return null;
  }
  const { stages } = shipmentStatus;
  return stages ? getLastShipmentStage(stages) : null;
}

export function getLastShipmentStage(
  stages: (ShipmentStage | null)[]
): ShipmentStage | null {
  if (!stages || stages.length === 0) {
    return null;
  }
  let lastTimestampStage: ShipmentStage | null = null;
  let lastProcessingStage: ShipmentStage | null = null;
  for (let i = stages.length - 1; i >= 0; i--) {
    const stage = stages[i];
    if (stage) {
      const { isProcessing, timestamp } = stage;
      if (isProcessing && !lastProcessingStage) {
        lastProcessingStage = stage;
      }
      if (timestamp && !lastTimestampStage) {
        lastTimestampStage = stage;
      }
      if (lastTimestampStage && lastProcessingStage) {
        break;
      }
    }
  }
  return lastProcessingStage || lastTimestampStage;
}

export function hasQRCode(item: OrderItem): boolean {
  const { qrCodeGen, qrCodeImport } = item;
  if (qrCodeGen && qrCodeGen.code != null) {
    return true;
  }
  if (qrCodeImport) {
    for (const qrCode of qrCodeImport) {
      if (qrCode && qrCode.code != null) {
        return true;
      }
    }
  }
  return false;
}

export function isShipmentStatusCompleted(shipmentStatus: ShipmentStatus) {
  const stages = shipmentStatus.stages
    ? filterNullOrUndefined(shipmentStatus.stages)
    : [];
  const lastStage = stages.length > 0 ? stages[stages.length - 1] : null;
  if (!lastStage) {
    return false;
  }
  return lastStage.isProcessing;
}

export function getQRCodes(item: OrderItem): OrderQRCode[] {
  const { qrCodeGen, qrCodeImport } = item;
  const res: OrderQRCode[] = [];
  if (qrCodeGen && qrCodeGen.code != null) {
    res.push({ type: "gen", code: qrCodeGen.code });
  }
  if (qrCodeImport) {
    for (const qrCode of qrCodeImport) {
      if (qrCode && qrCode.code != null) {
        res.push({ type: "import", code: qrCode.code });
      }
    }
  }
  return res;
}

export function isItemInProtection<
  Item extends Pick<
    OrderItemAttrs,
    "clubProtectionEnable" | "protectStartDate" | "protectEndDate"
  >
>(item: Item): boolean {
  if (
    !item.clubProtectionEnable ||
    !item.protectStartDate ||
    !item.protectEndDate
  ) {
    return false;
  }
  const now = new Date();
  return (
    item.protectStartDate.getTime() <= now.getTime() &&
    now.getTime() <= item.protectEndDate.getTime()
  );
}

export function isAddressEmpty(address: Partial<Address>): boolean {
  const { name, street, city, region, countryId, telephone, company } = address;

  const areSomeFieldsNotEmpty =
    (name && name.trim()) ||
    (street && street.trim()) ||
    (city && city.trim()) ||
    (region && region.trim()) ||
    (countryId && countryId.trim()) ||
    (telephone && telephone.trim()) ||
    (company && company.trim());

  return !areSomeFieldsNotEmpty;
}

interface ShippingGroups<
  C extends {
    shippingLabel: OrderItemShippingLabel;
    merchant: OrderItemMerchant | null;
  }
> {
  eVoucher: C[];
  consolidate: C[];
  merchant: C[][];
  unknown: C[];
}

export function groupOrderItemsByShippingLabel<
  C extends {
    shippingLabel: OrderItemShippingLabel;
    merchant: OrderItemMerchant | null;
  }
>(items: C[]): ShippingGroups<C> {
  const eVoucher: C[] = [];
  const consolidate: C[] = [];
  const unknown: C[] = [];

  const merchantDeliveryList: (C & {
    merchant: OrderItemMerchant;
  })[] = [];
  const unknownMerchantDeliveryList: (C & {
    merchant: null;
  })[] = [];

  for (const item of items) {
    const { shippingLabel } = item;
    if (
      shippingLabel === "Self pick-up and e-voucher" ||
      shippingLabel === "自取貨品及電子優惠劵"
    ) {
      eVoucher.push(item);
    } else if (
      shippingLabel === "CLUB SHOPPING Delivery" ||
      shippingLabel === "CLUB SHOPPING 派送"
    ) {
      consolidate.push(item);
    } else if (
      shippingLabel === "Merchant Delivery" ||
      shippingLabel === "商戶派送"
    ) {
      if (item.merchant != null) {
        merchantDeliveryList.push({
          ...item,
          merchant: item.merchant,
        });
      } else {
        unknownMerchantDeliveryList.push({
          ...item,
          merchant: null,
        });
      }
    } else if (
      shippingLabel === "3-5 days Consolidated Delivery" ||
      shippingLabel === "3-5 天集運"
    ) {
      consolidate.push(item);
    } else {
      unknown.push(item);
    }
  }

  const merchantKeys: string[] = [];
  const merchantKeyExists: IndexMap<string, boolean> = {};
  const merchantKeyItems: IndexMap<string, C[]> = {};

  for (const merchantDelivery of merchantDeliveryList) {
    const {
      merchant: { name },
    } = merchantDelivery;
    if (!merchantKeyExists[name]) {
      merchantKeyExists[name] = true;
      merchantKeys.push(name);
    }
    merchantKeyItems[name] = [
      ...(merchantKeyItems[name] || []),
      merchantDelivery,
    ];
  }

  const merchant: C[][] = [
    ...filterNullOrUndefined(merchantKeys.map(k => merchantKeyItems[k])),
    unknownMerchantDeliveryList,
  ];

  return {
    eVoucher,
    consolidate,
    merchant,
    unknown,
  };
}

export function getEffectiveShippingLabel<
  C extends {
    shippingLabel: OrderItemShippingLabel;
  }
>(items: C[]) {
  let clubShoppingShippingLabel: ClubShoppingShippingLabel | null = null;

  for (const item of items) {
    if (isConsolidation(item.shippingLabel)) {
      return item.shippingLabel;
    }
    if (isClubShoppingDelivery(item.shippingLabel)) {
      clubShoppingShippingLabel = item.shippingLabel;
    }
  }
  return clubShoppingShippingLabel;
}

export function getEstimatedDeliveryDateForConsolidateAndWarehouse(
  orderEstimatedDeliveryDate: OrderEstimatedDeliveryDate[]
) {
  const selected = orderEstimatedDeliveryDate.filter(
    o => o.shippingType === "Consolidate" || o.shippingType === "Warehouse"
  );
  if (selected.length > 0) {
    return selected[0].estimatedDeliveryDate;
  }
  return null;
}
