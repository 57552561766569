import React, { useCallback } from "react";
import { LocalizedText } from "../../i18n/Localization";
import bannerImg from "../../resources/img-the-club-sso-welcome-banner.png";
import CLImage from "../CLImage";
import styles from "./styles.module.scss";

interface Props {
  isLoading: boolean;
  onTheClubButtonClick: () => void;
  onSignInWithClubShoppingButtonClick: () => void;
}

const TheClubSSOWelcomeView: React.FC<Props> = props => {
  const {
    onTheClubButtonClick,
    onSignInWithClubShoppingButtonClick,
    isLoading,
  } = props;

  const handleTheClubButtonClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onTheClubButtonClick();
    },
    [onTheClubButtonClick]
  );

  const handleSignInWithClubShoppingButtonClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onSignInWithClubShoppingButtonClick();
    },
    [onSignInWithClubShoppingButtonClick]
  );

  return (
    <div className={styles.container}>
      <CLImage className={styles.bannerImage} src={bannerImg} />
      <section className={styles.theClubButtonSection}>
        <h2 className={styles.signInAndRegistrationText}>
          <LocalizedText messageID="the_club_sso.welcome.sign_in_and_registration" />
        </h2>
        <button
          onClick={handleTheClubButtonClick}
          className={styles.theClubButton}
          disabled={isLoading}
        />
      </section>
      <section className={styles.signInWithClubShoppingSection}>
        <p className={styles.notYetPairedClubAccountText}>
          <LocalizedText messageID="the_club_sso.welcome.not_yet_paired_club_account" />
        </p>
        <button
          onClick={handleSignInWithClubShoppingButtonClick}
          className={styles.signInWithClubShoppingButton}
          disabled={isLoading}
        >
          <LocalizedText messageID="the_club_sso.welcome.sign_in_with_club_shopping_account" />
        </button>
      </section>
    </div>
  );
};

export default TheClubSSOWelcomeView;
