import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from "react";
import { toastController, actionSheetController } from "@ionic/core";
import {
  Plugins,
  CameraResultType,
  CameraSource,
  CameraPhoto,
} from "@capacitor/core";
import cn from "classnames";

import CLContent from "../CLContent";
import styles from "./styles.module.scss";
import {
  useCustomer,
  useUpdateMyCustomerInfoRequest,
  useLinkSocialAccountRequest,
  useUnlinkSocialAccountRequest,
  useGetMyCustomerRequest,
  useResendChangeEmailConfirmation,
  useCancelChangeEmailRequest,
  INVALID_MEMBERSHIP_RESPONSE,
} from "../../repository/AuthRepository";
import { IonButton } from "@ionic/react";
import { NavBar, NavBarBackButton } from "../NavBar";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import Input from "../Form/Input";
import ProfilePictureContainer from "./ProfilePictureContainer";
import Checkbox from "../Checkbox";
import LinkSocialAccountWidget from "./LinkSocialAccountWidget";
import ChangeEmailWidget from "./ChangeEmailWidget";
import { OurNavContext } from "../../our-navigation";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import {
  getCustomerLinkedSSOEmail,
  getCustomerProfilePicUrl,
  Customer,
  isCustomerLinkedToTheClub,
} from "../../models/Customer";
import {
  useLoginWithGoogle,
  SSOResponse,
  useLoginWithFacebook,
  useLoginWithTheClub,
} from "../../useCase/AuthUseCase";
import { OAuthProvider } from "../../api/RESTful";
import { resizeImage } from "../../utils/ResizeHelper";
import Config from "../../Config";
import {
  getPathForChangeEmail,
  RootTab,
  getPathForAccountTab,
  getPathForHomePage,
} from "../../navigation/routes";
import { LoadingModalContext } from "../LoadingModalProvider";
import { mapNullable } from "../../utils/type";
import { MessageID } from "../../i18n/translations/type";
import { VerificationEmailSentModal } from "../ChangeEmailModals";
import { appEventEmitter } from "../../utils/SimpleEventEmitter";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { isiOS } from "../../utils/Platform";
import { crop } from "../../CLPlugins/ImageCropper";
import {
  Permission,
  check,
  request,
  requestable,
  openSettings,
} from "../../CLPlugins/Permission";
import { RouteComponentProps } from "react-router-dom";

const { Camera, Filesystem } = Plugins;

function useShowToast() {
  const { translate } = useIntl();
  return useCallback(
    async (messageID: MessageID) => {
      const toast = await toastController.create({
        cssClass: styles.toast,
        message: translate(messageID),
        position: "bottom",
        duration: 2000,
      });
      toast.present();
    },
    [translate]
  );
}

function viewEnter() {
  pageView({ page: "Customer info" });
}

type Props = RouteComponentProps;

export const EditProfilePage: React.FC<Props> = props => {
  const { history } = props;
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { navigate, goBack } = useContext(OurNavContext);
  const { translate } = useIntl();

  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const _customer = useCustomer();
  const initialCustomer = useRef(_customer);

  const [isLoading, setIsLoading] = useState(false);

  const [isProfilePicUpdated, setIsProfilePicUpdated] = useState(false);

  const [firstName, setFirstName] = useState(
    mapNullable(initialCustomer.current, c => c.firstname) || ""
  );
  const [lastName, setLastName] = useState(
    mapNullable(initialCustomer.current, c => c.lastname) || ""
  );
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(
    mapNullable(initialCustomer.current, getCustomerProfilePicUrl)
  );
  const [isSubscribed, setIsSubscribed] = useState(
    mapNullable(initialCustomer.current, c => c.isSubscribed) || false
  );
  const [facebookAccountEmail, setIsFacebookAccountEmail] = useState<
    string | null
  >(
    mapNullable(initialCustomer.current, c =>
      getCustomerLinkedSSOEmail(c, "facebook")
    )
  );
  const [theClubAccountEmail, setTheClubAccountEmail] = useState<string | null>(
    mapNullable(initialCustomer.current, c =>
      getCustomerLinkedSSOEmail(c, "the-club")
    )
  );
  const [googleAccountEmail, setGoogleAccountEmail] = useState<string | null>(
    mapNullable(initialCustomer.current, c =>
      getCustomerLinkedSSOEmail(c, "google")
    )
  );

  const clubMemberID = useMemo(
    () =>
      _customer && isCustomerLinkedToTheClub(_customer)
        ? _customer.clubMember[0].clubMemberID
        : null,
    [_customer]
  );

  const { show: showLoading, hide: hideLoading } = useContext(
    LoadingModalContext
  );
  const setLoading = useCallback(
    (loading: boolean) => {
      if (loading) {
        showLoading();
      } else {
        hideLoading();
      }
      setIsLoading(loading);
    },
    [setIsLoading, hideLoading, showLoading]
  );
  const fetchCustomer = useGetMyCustomerRequest();
  const updateSSOEmailsWithCustomer = useCallback(
    (customer: Customer) => {
      setIsFacebookAccountEmail(
        getCustomerLinkedSSOEmail(customer, "facebook")
      );
      setTheClubAccountEmail(getCustomerLinkedSSOEmail(customer, "the-club"));
      setGoogleAccountEmail(getCustomerLinkedSSOEmail(customer, "google"));
    },
    [setIsFacebookAccountEmail, setTheClubAccountEmail, setGoogleAccountEmail]
  );
  useEffect(() => {
    if (initialCustomer.current != null) {
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const customer = await fetchCustomer();
        if (!customer) {
          throw new Error("no-customer");
        }
        setFirstName(customer.firstname);
        setLastName(customer.lastname);
        setIsSubscribed(customer.isSubscribed);
        updateSSOEmailsWithCustomer(customer);
        setProfilePicUrl(getCustomerProfilePicUrl(customer));
      } catch {
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: "page.edit_profile.alert.cannot_fetch_customer",
          buttons: [
            {
              textMessageID: "alert.button.ok",
              handler: () => {
                goBack();
              },
            },
          ],
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [
    setLoading,
    fetchCustomer,
    goBack,
    presentLocalizedAlert,
    updateSSOEmailsWithCustomer,
  ]);

  const onFirstNameInputChange = useCallback(
    (value: string) => {
      setFirstName(value);
    },
    [setFirstName]
  );
  const firstNameValid = useMemo(() => firstName && firstName.trim(), [
    firstName,
  ]);
  const onLastNameInputChange = useCallback(
    (value: string) => {
      setLastName(value);
    },
    [setLastName]
  );
  const lastNameValid = useMemo(() => lastName && lastName.trim(), [lastName]);
  const onIsSubscribedChange = useCallback(
    (value: boolean) => {
      setIsSubscribed(value);
    },
    [setIsSubscribed]
  );

  const presentFromPhotoBlockedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.from_photo_blocked",
      buttons: [
        { textMessageID: "alert.button.ok" },
        {
          textMessageID: "go_to_settings",
          handler: () => {
            openSettings();
          },
        },
      ],
    });
  }, [presentLocalizedAlert]);

  const presentFromPhotoDeniedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.from_photo_denied",
      buttons: [{ textMessageID: "alert.button.ok" }],
    });
  }, [presentLocalizedAlert]);

  const presentTakePictureBlockedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.take_picture_blocked",
      buttons: [
        { textMessageID: "alert.button.ok" },
        {
          textMessageID: "go_to_settings",
          handler: () => {
            openSettings();
          },
        },
      ],
    });
  }, [presentLocalizedAlert]);

  const presentTakePictureDeniedAlert = useCallback(() => {
    presentLocalizedAlert({
      headerId:
        "page.edit_profile.update_profile_picture.error.take_picture_denied",
      buttons: [{ textMessageID: "alert.button.ok" }],
    });
  }, [presentLocalizedAlert]);

  const getPhoto = useCallback((): Promise<CameraPhoto> => {
    return new Promise(resolve => {
      actionSheetController
        .create({
          buttons: [
            {
              text: translate(
                "page.edit_profile.action_sheet.camera.from_photos"
              ),
              handler: () => {
                (async () => {
                  let { result: permissionResult } = await check({
                    permission: Permission.PHOTO_LIBRARY,
                  });
                  if (permissionResult === "blocked") {
                    presentFromPhotoBlockedAlert();
                    return;
                  }
                  if (requestable(permissionResult)) {
                    const { result: requestResult } = await request({
                      permission: Permission.PHOTO_LIBRARY,
                    });
                    permissionResult = requestResult;
                  }
                  if (permissionResult !== "granted") {
                    presentFromPhotoDeniedAlert();
                    return;
                  }
                  try {
                    await Camera.getPhoto({
                      quality: 100,
                      resultType: CameraResultType.Uri,
                      source: CameraSource.Photos,
                    }).then(resolve);
                  } catch {}
                })();
              },
            },
            {
              text: translate(
                "page.edit_profile.action_sheet.camera.take_picture"
              ),
              handler: () => {
                (async () => {
                  let { result: permissionResult } = await check({
                    permission: Permission.CAMERA,
                  });
                  if (permissionResult === "blocked") {
                    presentTakePictureBlockedAlert();
                    return;
                  }
                  if (requestable(permissionResult)) {
                    const { result: requestResult } = await request({
                      permission: Permission.CAMERA,
                    });
                    permissionResult = requestResult;
                  }
                  if (permissionResult !== "granted") {
                    presentTakePictureDeniedAlert();
                    return;
                  }
                  try {
                    await Camera.getPhoto({
                      quality: 100,
                      resultType: CameraResultType.Uri,
                      source: CameraSource.Camera,
                    }).then(resolve);
                  } catch {}
                })();
              },
            },
            {
              text: translate("page.edit_profile.action_sheet.camera.cancel"),
              role: "cancel",
            },
          ],
        })
        .then(actionSheet => actionSheet.present());
    });
  }, [
    translate,
    presentFromPhotoBlockedAlert,
    presentFromPhotoDeniedAlert,
    presentTakePictureBlockedAlert,
    presentTakePictureDeniedAlert,
  ]);

  const onPickImageClick = useCallback(async () => {
    try {
      const image = await getPhoto();
      const imageUrl = image.path;
      if (imageUrl) {
        const croppedUrl = await crop(imageUrl);
        const data = await Filesystem.readFile({
          path: croppedUrl,
        });
        const resizedData = await resizeImage(
          "data:image/jpeg;base64," + data.data,
          Config.IMAGE_HEIGHT,
          Config.IMAGE_WIDTH
        );
        setProfilePicUrl(resizedData);
        setIsProfilePicUpdated(true);
      }
    } catch (e) {
      if (
        e.message !== "User cancelled photos app" &&
        e.message !== "User cancelled"
      ) {
        presentLocalizedAlert({
          headerId: "page.edit_profile.update_profile_picture_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      }
    }
  }, [
    setProfilePicUrl,
    presentLocalizedAlert,
    setIsProfilePicUpdated,
    getPhoto,
  ]);

  const linkSocialAccount = useLinkSocialAccountRequest();
  const connectSocialAccount = useCallback(
    async (ssoProvider: () => Promise<SSOResponse>) => {
      try {
        setLoading(true);
        const ssoResponse = await ssoProvider();
        const linkResult = await linkSocialAccount(
          ssoResponse.token,
          ssoResponse.provider
        );
        if (linkResult.success !== true) {
          presentLocalizedAlert({
            headerId: linkResult.errorMessage
              ? "page.edit_profile.link_account.error.email_registered"
              : "page.edit_profile.connect_account_error",
            messageId: linkResult.errorMessage
              ? undefined
              : "alert.error.message",
            message: linkResult.errorMessage
              ? linkResult.errorMessage
              : undefined,
            buttons: [{ textMessageID: "try_again" }],
          });
        } else {
          if (linkResult.customer) {
            updateSSOEmailsWithCustomer(linkResult.customer);
          }
        }
      } catch (e) {
        if (e.message === INVALID_MEMBERSHIP_RESPONSE) {
          presentLocalizedAlert({
            headerId: "page.edit_profile.link_account.error.email_registered",
            messageId:
              "page.edit_profile.link_account.error.email_registered.message",
            buttons: [{ textMessageID: "try_again" }],
          });
        } else {
          presentLocalizedAlert({
            headerId: "page.edit_profile.connect_account_error",
            buttons: [{ textMessageID: "try_again" }],
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      linkSocialAccount,
      presentLocalizedAlert,
      setLoading,
      updateSSOEmailsWithCustomer,
    ]
  );

  const unlinkSocialAccount = useUnlinkSocialAccountRequest();
  const disconnectSocialAccount = useCallback(
    async (provider: OAuthProvider) => {
      try {
        setLoading(true);
        const unlinkResult = await unlinkSocialAccount(provider);
        if (unlinkResult !== true) {
          presentLocalizedAlert({
            headerId: "page.edit_profile.disconnect_account_error",
            buttons: [{ textMessageID: "try_again" }],
          });
        } else {
          switch (provider) {
            case "facebook":
              setIsFacebookAccountEmail(null);
              break;
            case "google":
              setGoogleAccountEmail(null);
              break;
            case "the-club":
              setTheClubAccountEmail(null);
              break;
            default:
              break;
          }
        }
      } catch {
        presentLocalizedAlert({
          headerId: "page.edit_profile.disconnect_account_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      } finally {
        setLoading(false);
      }
    },
    [
      unlinkSocialAccount,
      presentLocalizedAlert,
      setLoading,
      setIsFacebookAccountEmail,
      setGoogleAccountEmail,
      setTheClubAccountEmail,
    ]
  );
  const showDisconnectSocialAccountAlert = useCallback(
    (provider: OAuthProvider) => {
      const providerString =
        provider === "facebook"
          ? "Facebook"
          : provider === "google"
          ? "Google"
          : provider === "the-club"
          ? "The Club"
          : "";
      presentLocalizedAlert({
        messageId: "page.edit_profile.disconnect_account_confirm",
        messageArgs: {
          provider: providerString,
        },
        buttons: [
          {
            textMessageID: "page.edit_profile.disconnect_account_confirm.yes",
            handler: () => disconnectSocialAccount(provider),
          },
          {
            textMessageID: "page.edit_profile.disconnect_account_confirm.no",
          },
        ],
      });
    },
    [disconnectSocialAccount, presentLocalizedAlert]
  );

  const loginWithFacebook = useLoginWithFacebook();
  const onLinkFacebookClick = useCallback(() => {
    if (!facebookAccountEmail) {
      connectSocialAccount(loginWithFacebook);
    } else {
      showDisconnectSocialAccountAlert("facebook");
    }
  }, [
    facebookAccountEmail,
    connectSocialAccount,
    loginWithFacebook,
    showDisconnectSocialAccountAlert,
  ]);
  const loginWithTheClub = useLoginWithTheClub();
  const onLinkTheClubClick = useCallback(() => {
    if (!theClubAccountEmail) {
      connectSocialAccount(loginWithTheClub);
    } else {
      showDisconnectSocialAccountAlert("the-club");
    }
  }, [
    theClubAccountEmail,
    connectSocialAccount,
    loginWithTheClub,
    showDisconnectSocialAccountAlert,
  ]);
  const loginWithGoogle = useLoginWithGoogle();
  const onLinkGoogleClick = useCallback(async () => {
    if (!googleAccountEmail) {
      connectSocialAccount(loginWithGoogle);
    } else {
      showDisconnectSocialAccountAlert("google");
    }
  }, [
    googleAccountEmail,
    loginWithGoogle,
    connectSocialAccount,
    showDisconnectSocialAccountAlert,
  ]);

  const updateProfileLock = useRef(false);
  const updateMyCustomerInfoRequest = useUpdateMyCustomerInfoRequest();
  const onDoneButtonClick = useCallback(async () => {
    if (updateProfileLock.current) {
      return;
    }
    updateProfileLock.current = true;
    setLoading(true);
    try {
      await updateMyCustomerInfoRequest(
        firstName,
        lastName,
        isSubscribed,
        profilePicUrl && isProfilePicUpdated ? profilePicUrl : undefined
      );
      goBack();
      // We dont need to unlock updateProfileLock
      // because we are going to go back
    } catch {
      updateProfileLock.current = false;
      presentLocalizedAlert({
        headerId: "page.edit_profile.update_profile_error",
        buttons: [{ textMessageID: "try_again" }],
      });
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    goBack,
    firstName,
    lastName,
    isSubscribed,
    profilePicUrl,
    updateMyCustomerInfoRequest,
    presentLocalizedAlert,
    isProfilePicUpdated,
  ]);

  const [
    isVerificationEmailSentModalOpen,
    setIsVerificationEmailSentModalOpen,
  ] = useState(false);
  const closeVerificationEmailSentModal = useCallback(() => {
    setIsVerificationEmailSentModalOpen(false);
  }, []);

  useEffect(() => {
    const sub = appEventEmitter.subscribe(e => {
      if (e.type === "OnSubmitChangeEmailSuccess") {
        setIsVerificationEmailSentModalOpen(true);
      }
    });
    return () => {
      sub.remove();
    };
  }, []);
  const onChangeEmailClick = useCallback(() => {
    actionEvent("Customer Info", "Click", "My Email");
    navigate(getPathForChangeEmail(RootTab.account));
  }, [navigate]);
  const onClickGotoShopButton = useCallback(() => {
    setIsVerificationEmailSentModalOpen(false);
    // FIXME: A super hacky way to back to top and then go to home page
    // I cannot find another way to do so... so sad
    setTimeout(() => {
      goBack(getPathForAccountTab());
      setTimeout(() => {
        history.replace(getPathForHomePage());
      }, 500);
    }, 250);
  }, [goBack, history]);

  const showToast = useShowToast();
  const resendChangeEmailConfirmation = useResendChangeEmailConfirmation();
  const onClickResendChangeEmailConfirmationButton = useCallback(async () => {
    setLoading(true);
    try {
      await resendChangeEmailConfirmation();
      showToast(
        "page.change_email.verify_your_email.toast.verification_email_sent"
      );
    } finally {
      setLoading(false);
    }
  }, [showToast, setLoading, resendChangeEmailConfirmation]);
  const cancelChangeEmailRequest = useCancelChangeEmailRequest();
  const onClickCancelChangeEmailButton = useCallback(async () => {
    setLoading(true);
    try {
      await cancelChangeEmailRequest();
      showToast("page.change_email.verify_your_email.toast.cancelled");
    } finally {
      setLoading(false);
    }
  }, [showToast, setLoading, cancelChangeEmailRequest]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="page.edit_profile.title" />}
        headerRight={
          <IonButton
            className={styles.doneButton}
            disabled={isLoading || !firstNameValid || !lastNameValid}
            onClick={onDoneButtonClick}
          >
            <LocalizedText messageID="page.edit_profile.done" />
          </IonButton>
        }
      />
      <CLContent>
        <div className={styles.content}>
          <div className={styles.basicInfoContainer}>
            <ProfilePictureContainer
              className={styles.profilePicture}
              profilePicUrl={profilePicUrl}
              onPickImageClick={onPickImageClick}
            />
            <Input
              className={cn(
                styles.input,
                !isLoading && !firstNameValid && styles.error
              )}
              titleMessageID="first_name"
              value={firstName}
              onChange={onFirstNameInputChange}
              type="text"
            />
            <Input
              className={cn(
                styles.input,
                !isLoading && !lastNameValid && styles.error
              )}
              titleMessageID="last_name"
              value={lastName}
              onChange={onLastNameInputChange}
              type="text"
            />
            <div className={styles.checkBoxInput}>
              <Checkbox
                checked={isSubscribed}
                onCheckedChange={onIsSubscribedChange}
              >
                <LocalizedText messageID="page.edit_profile.subscribe_newsletter" />
              </Checkbox>
            </div>
          </div>
          <div className={styles.linkedAccountTitle}>
            <LocalizedText messageID="page.edit_profile.linked_account" />
          </div>
          <div className={styles.socialContainer}>
            <LinkSocialAccountWidget
              type="club"
              email={theClubAccountEmail}
              onToggleClick={onLinkTheClubClick}
              disabled={isLoading}
              additionalInfo={clubMemberID ? `ID: ${clubMemberID}` : null}
            />
            {!(Config.HIDE_OAUTH_FOR_IOS && isiOS()) && (
              <LinkSocialAccountWidget
                type="facebook"
                email={facebookAccountEmail}
                onToggleClick={onLinkFacebookClick}
                disabled={isLoading}
              />
            )}
            {!(Config.HIDE_OAUTH_FOR_IOS && isiOS()) && (
              <LinkSocialAccountWidget
                type="google"
                email={googleAccountEmail}
                onToggleClick={onLinkGoogleClick}
                disabled={isLoading}
              />
            )}
            {_customer && (
              <ChangeEmailWidget
                customer={_customer}
                onChangeEmailClick={onChangeEmailClick}
                onClickResendConfirmationButton={
                  onClickResendChangeEmailConfirmationButton
                }
                onClickCancelThisChangeButton={onClickCancelChangeEmailButton}
              />
            )}
          </div>
        </div>
        <VerificationEmailSentModal
          isOpen={isVerificationEmailSentModalOpen}
          onRequestDismiss={closeVerificationEmailSentModal}
          onClickGotoShopButton={onClickGotoShopButton}
        />
      </CLContent>
    </>
  );
};

export default EditProfilePage;
