export type Nothing = null | undefined;
export type Just<T> = NonNullable<T>;
export type Maybe<T> = Just<T> | Nothing;

export function isJust<T>(obj: Maybe<T>): obj is Just<T> {
  return obj != null;
}

export function throwIfNothing<T>(error: Error, obj: Maybe<T>): Just<T> {
  if (isJust(obj)) {
    return obj;
  }
  throw error;
}
