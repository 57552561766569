import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import { getStoreViewCodeForLocale, Locale } from "../i18n/locale";
import {
  getIndexedProduct,
  keyGraphQLAttributes,
  ModelKeys,
  ProductEnableAgeDeclaration,
  ProductEnableAgeDeclarationGraphQLAttributes,
  VariantProductEnableAgeDeclaration,
  VariantProductEnableAgeDeclarationGraphQLAttributes,
} from "../models/product";
import {
  assembleProduct,
  ProductBaseGraphQLAttributes,
  ProductDetailsAdditionalGraphQLAttributes,
  RemoteProductAdditional,
  RemoteProductBase,
} from "../models/ProductDetails";
import {
  extractItemProducts,
  getProductSaleBundleItemsGraphQLAttributes,
  getProductSaleBundleMainProductGraphQLAttributes,
  ProductSaleBundle,
  ProductSaleBundleItem,
  ProductSaleBundleItemBase,
  ProductSaleBundleItemBaseGraphQLAttributes,
  ProductSaleBundleProduct,
} from "../models/ProductSaleBundle";
import { filterNullOrUndefined } from "../utils/array";
import { parseGraphQLError } from "./GraphQL";

export async function fetchProductSaleBundles(
  client: ApolloClient<unknown>,
  productId: number,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductSaleBundle<ProductSaleBundleProduct> | null> {
  try {
    const query = <T>(graphQLAttributes: string) => {
      return client.query<{
        amMostviewedBundlePacks: T;
      }>({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`query FetchProductSaleBundles(
  $productId: Int!
) {
  amMostviewedBundlePacks(
    productId: $productId
  ) {
    ${graphQLAttributes}
  }
}`,
        variables: {
          productId,
        },
        fetchPolicy,
      });
    };

    const bundleItemsResult = await query<{
      items: ProductSaleBundleItemBase[] | null;
    }>(ProductSaleBundleItemBaseGraphQLAttributes);

    // Return null if no bundle packs found
    if (
      bundleItemsResult.data.amMostviewedBundlePacks.items == null ||
      bundleItemsResult.data.amMostviewedBundlePacks.items.length === 0
    ) {
      return null;
    }

    const [
      mainProductBaseResult,
      mainProductAdditionalResult,
      mainProductEnableAgeDeclarationResult,
      mainProductVariantProductEnableAgeDeclarationResult,
    ] = await Promise.all([
      query<{
        mainProduct: RemoteProductBase;
      }>(
        getProductSaleBundleMainProductGraphQLAttributes(
          ProductBaseGraphQLAttributes
        )
      ),
      query<{
        mainProduct: RemoteProductAdditional;
      }>(
        getProductSaleBundleMainProductGraphQLAttributes(
          ProductDetailsAdditionalGraphQLAttributes
        )
      ).catch(() => ({
        data: { amMostviewedBundlePacks: { mainProduct: null } },
      })),
      query<{
        mainProduct: ProductEnableAgeDeclaration;
      }>(
        getProductSaleBundleMainProductGraphQLAttributes(
          ProductEnableAgeDeclarationGraphQLAttributes
        )
      ).catch(() => ({
        data: { amMostviewedBundlePacks: { mainProduct: null } },
      })),
      query<{
        mainProduct: VariantProductEnableAgeDeclaration;
      }>(
        getProductSaleBundleMainProductGraphQLAttributes(
          VariantProductEnableAgeDeclarationGraphQLAttributes
        )
      ).catch(() => ({
        data: { amMostviewedBundlePacks: { mainProduct: null } },
      })),
    ]);

    const [
      itemProductBaseResult,
      itemProductAdditionalResult,
      itemProductEnableAgeDeclarationResult,
      itemProductVariantProductEnableAgeDeclarationResult,
    ] = await Promise.all([
      query<{
        items: ProductSaleBundleItem<RemoteProductBase>[] | null;
      }>(
        getProductSaleBundleItemsGraphQLAttributes(ProductBaseGraphQLAttributes)
      ),
      query<{
        items: ProductSaleBundleItem<RemoteProductAdditional>[] | null;
      }>(
        getProductSaleBundleItemsGraphQLAttributes(
          ProductDetailsAdditionalGraphQLAttributes
        )
      ).catch(() => ({
        data: { amMostviewedBundlePacks: { items: null } },
      })),
      query<{
        items: ProductSaleBundleItem<ProductEnableAgeDeclaration>[] | null;
      }>(
        getProductSaleBundleItemsGraphQLAttributes(
          ProductEnableAgeDeclarationGraphQLAttributes
        )
      ).catch(() => ({
        data: { amMostviewedBundlePacks: { items: null } },
      })),
      query<{
        items:
          | ProductSaleBundleItem<VariantProductEnableAgeDeclaration>[]
          | null;
      }>(
        getProductSaleBundleItemsGraphQLAttributes(
          VariantProductEnableAgeDeclarationGraphQLAttributes
        )
      ).catch(() => ({
        data: { amMostviewedBundlePacks: { items: null } },
      })),
    ]);

    const mainProduct =
      mainProductBaseResult.data.amMostviewedBundlePacks.mainProduct &&
      mainProductAdditionalResult.data.amMostviewedBundlePacks.mainProduct
        ? assembleProduct(
            mainProductBaseResult.data.amMostviewedBundlePacks.mainProduct,
            mainProductAdditionalResult.data.amMostviewedBundlePacks
              .mainProduct,
            null,
            mainProductEnableAgeDeclarationResult.data.amMostviewedBundlePacks
              .mainProduct,
            null,
            mainProductVariantProductEnableAgeDeclarationResult.data
              .amMostviewedBundlePacks.mainProduct,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          )
        : null;
    if (mainProduct == null) {
      return null;
    }

    const itemAdditionalProductMap = itemProductAdditionalResult.data
      .amMostviewedBundlePacks.items
      ? getIndexedProduct(
          extractItemProducts(
            itemProductAdditionalResult.data.amMostviewedBundlePacks.items
          )
        )
      : null;
    const itemProductEnableAgeDeclarationMap = itemProductEnableAgeDeclarationResult
      .data.amMostviewedBundlePacks.items
      ? getIndexedProduct(
          extractItemProducts(
            itemProductEnableAgeDeclarationResult.data.amMostviewedBundlePacks
              .items
          )
        )
      : {};
    const itemProductVariantProdutEnableAgeDeclarationMap = itemProductVariantProductEnableAgeDeclarationResult
      .data.amMostviewedBundlePacks.items
      ? getIndexedProduct(
          extractItemProducts(
            itemProductVariantProductEnableAgeDeclarationResult.data
              .amMostviewedBundlePacks.items
          )
        )
      : {};

    const items = itemProductBaseResult.data.amMostviewedBundlePacks.items
      ? itemProductBaseResult.data.amMostviewedBundlePacks.items.map(item => ({
          ...item,
          items: filterNullOrUndefined(
            item.items.map(i => {
              const additional =
                itemAdditionalProductMap &&
                itemAdditionalProductMap[i.product.sku];
              if (additional) {
                return {
                  ...i,
                  product: assembleProduct(
                    i.product,
                    additional,
                    null,
                    itemProductEnableAgeDeclarationMap[i.product.sku] || null,
                    null,
                    itemProductVariantProdutEnableAgeDeclarationMap[
                      i.product.sku
                    ] || null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  ),
                };
              }
              return null;
            })
          ),
        }))
      : null;

    if (!items) {
      return null;
    }

    return {
      mainProduct,
      items,
    };
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}

export function getProductSaleBundlesForProductSKUOnly(
  client: ApolloClient<unknown>,
  productIds: number[]
): ProductSaleBundle<ModelKeys>[] {
  const query = (productId: number) => {
    const result = client.readQuery<{
      amMostviewedBundlePacks: ProductSaleBundle<ModelKeys>;
    }>({
      query: gql`
        query FetchProductSaleBundles($productId: Int!) {
          amMostviewedBundlePacks(productId: $productId) {
            ${getProductSaleBundleMainProductGraphQLAttributes(
              keyGraphQLAttributes
            )}
            ${getProductSaleBundleItemsGraphQLAttributes(keyGraphQLAttributes)}
          }
        }
      `,
      variables: {
        productId,
      },
    });
    if (result) {
      return result.amMostviewedBundlePacks;
    }
    return null;
  };

  return filterNullOrUndefined(productIds.map(query));
}

export async function fetchProductSaleBundlesForProductSKUOnly(
  client: ApolloClient<unknown>,
  productIds: number[],
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ProductSaleBundle<ModelKeys>[]> {
  const query = async (productId: number) => {
    try {
      const result = await client.query<{
        amMostviewedBundlePacks: ProductSaleBundle<ModelKeys>;
      }>({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`
          query FetchProductSaleBundles($productId: Int!) {
            amMostviewedBundlePacks(productId: $productId) {
              ${getProductSaleBundleMainProductGraphQLAttributes(
                keyGraphQLAttributes
              )}
              ${getProductSaleBundleItemsGraphQLAttributes(
                keyGraphQLAttributes
              )}
            }
          }
        `,
        variables: {
          productId,
        },
        fetchPolicy,
      });
      return result.data.amMostviewedBundlePacks;
    } catch {
      return null;
    }
  };

  const results = filterNullOrUndefined(
    await Promise.all(productIds.map(query))
  );
  return results;
}
