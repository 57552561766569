import { useApolloClient } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  CategoryTree,
  getCategoryCMSPageId,
  hasProducts,
} from "../../models/category";
import { ResolvedHTMLBasedCMSPageContent } from "../../models/cmsBlock";
import { ProductOverview } from "../../models/ProductOverview";
import {
  defaultProductFilterInfo,
  FilterAttribute,
  getFilterAttributeMapsFromFilters,
  makeGraphQLFilterFromParamsMap,
  ProductFilterInfo,
  RangeFilterAttribute,
  SortOrder,
  removeSingleLevelAndMultiLevelFilterAttribute,
  removeRangeFilterAttribute,
  SortFields,
} from "../../models/filter";

import {
  getAggregation,
  getProductOverviewsFromCategoryListByCategoryId,
  getSortFields,
} from "../../api/GraphQL";
import { PaginationInfo } from "../../repository/State";
import ProductAttributeFilterContext from "../../contexts/ProductAttributeFilterContext";
import { getCategoryPageContent } from "../../repository/CMSPageContentRepository";
import { useGetValidSortFields } from "../../repository/ProductAttributeFilter";
import { convertAggregationToFilter } from "../../utils/Filter";
import {
  getResources,
  isRequestLoading,
  ResourcesRequestState,
} from "../../models/ResourcesRequestState";
import {
  useSingleCategoryPageProductListResources,
  useSingleCategoryPageResource,
} from "./repository";
import { ProductLabel } from "../../models/ProductLabel";
import { ModelKeys } from "../../models/product";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { useIsHTMLStringVisible } from "../../utils/IsHTMLStringVisible";
import { profileSyncAction } from "../../utils/performance";
import { CategoryPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { useKeepUpdatingRef } from "../../hook/utils";

interface ViewModel {
  hasDescription: boolean;

  singleLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute;
  };
  multiLevelLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute[];
  };
  rangeFilterAttributeMap: {
    [attributeCode in string]: RangeFilterAttribute;
  };
  sortFields: SortFields | null;
  categoryPageContent: ResolvedHTMLBasedCMSPageContent | null;

  isFullPageLoading: boolean;

  // Filters
  productFilterInfo: ProductFilterInfo;

  applyProductFilterInfo: (productFilterInfo: ProductFilterInfo) => void;

  clearFilter: (filter: FilterAttribute) => void;
  clearRangeFilter: (filter: RangeFilterAttribute) => void;
  clearPredefinedCPFilter: () => void;

  clearAllFilter: () => void;

  isProductListLoading: boolean;
  isProductListLoadingMore: boolean;

  // Products
  productOverviews: ProductOverview[];
  productLabelsByProductIdMap: { [key in number]: ProductLabel[] };
  bundleByProductIdMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
  fetchProductOverviewsByCategoryIdRequestState: ResourcesRequestState<unknown>;
  fetchNextProductOverviews: () => Promise<unknown>;

  // Operations
  refresh: () => Promise<void>;
}

function useViewModel(
  categoryTree: CategoryTree,
  queryParams: { [key in string]: string }
): ViewModel {
  const client = useApolloClient();
  const {
    productAttributeFilterFields,
    productAttributeFilterInputMap,
  } = useContext(ProductAttributeFilterContext);

  const hasDescription = useIsHTMLStringVisible(categoryTree.description || "");

  const categoryId = categoryTree.id;

  const fixedFilter = useMemo(
    () =>
      makeGraphQLFilterFromParamsMap(
        { category_id: `${categoryId}` },
        productAttributeFilterFields,
        productAttributeFilterInputMap
      ),
    [categoryId, productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const queryFilter = useMemo(
    () =>
      makeGraphQLFilterFromParamsMap(
        queryParams,
        productAttributeFilterFields,
        productAttributeFilterInputMap
      ),
    [queryParams, productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const filter = useMemo(() => ({ ...fixedFilter, ...queryFilter }), [
    fixedFilter,
    queryFilter,
  ]);

  const aggregations = useMemo(
    () =>
      profileSyncAction(
        CategoryPageSession(categoryId),
        "Load filters from cache",
        () => getAggregation(client, "", filter)
      ),
    [categoryId, client, filter]
  );

  const filterTypes = useMemo(
    () => (aggregations ? aggregations.map(convertAggregationToFilter) : []),
    [aggregations]
  );

  const _filterAttributeMapsFromFiters = useMemo(
    () =>
      getFilterAttributeMapsFromFilters(
        filterTypes,
        queryParams,
        productAttributeFilterInputMap
      ),
    [filterTypes, queryParams, productAttributeFilterInputMap]
  );

  const [
    {
      singleLevelFilterAttributeMap,
      multiLevelLevelFilterAttributeMap,
      rangeFilterAttributeMap,
    },
    setFilterAttributeMap,
  ] = useState(_filterAttributeMapsFromFiters);

  const getValidSortFields = useGetValidSortFields();

  const _sortFields = useMemo(
    () =>
      getValidSortFields(
        profileSyncAction(
          CategoryPageSession(categoryId),
          "Load sort fields from cache",
          () => getSortFields(client, "", filter)
        )
      ),
    [getValidSortFields, categoryId, client, filter]
  );

  const [sortFields, setSortFields] = useState(_sortFields);

  const categoryCMSPageId = getCategoryCMSPageId(categoryTree);

  const _categoryPageContent = useMemo(
    () =>
      categoryCMSPageId
        ? profileSyncAction(
            CategoryPageSession(categoryId),
            "Load CMS Page from Cache",
            () => getCategoryPageContent(client, categoryCMSPageId)
          )
        : null,
    [categoryCMSPageId, categoryId, client]
  );

  const [
    categoryPageContent,
    setCategoryPageContent,
  ] = useState<ResolvedHTMLBasedCMSPageContent | null>(_categoryPageContent);

  const [, fetchSingleCategoryPageResources] = useSingleCategoryPageResource(
    categoryTree,
    queryParams
  );

  const [
    fetchProductOverviewsByCategoryIdRequestState,
    fetchNextProductOverviewsByCategoryId,
    refreshProductOverviewsByCategoryId,
  ] = useSingleCategoryPageProductListResources(categoryTree);

  const [isProductListLoadingMore, setIsProductListLoadingMore] = useState(
    false
  );

  const defaultSortField = sortFields ? sortFields.defaultSortField : null;

  const [productFilterInfo, setProductFilterInfo] = useState<ProductFilterInfo>(
    {
      ...defaultProductFilterInfo({
        sortField: defaultSortField,
        sortOrder: SortOrder.ascending,
      }),
      attributeMap: singleLevelFilterAttributeMap,
      multiLevelAttributeMap: multiLevelLevelFilterAttributeMap,
      rangeAttributeMap: rangeFilterAttributeMap,
    }
  );

  const productFilterInfoRef = useKeepUpdatingRef(productFilterInfo);

  const applyProductFilterInfo = useCallback(
    async (_productFilterInfo: ProductFilterInfo) => {
      const newProductFilterInfo = {
        ...productFilterInfo,
        ..._productFilterInfo,
      };
      setProductFilterInfo(newProductFilterInfo);
      await refreshProductOverviewsByCategoryId(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshProductOverviewsByCategoryId]
  );

  const clearFilter = useCallback(
    async (appliedFilter: FilterAttribute) => {
      const newProductFilterInfo = removeSingleLevelAndMultiLevelFilterAttribute(
        productFilterInfo,
        appliedFilter
      );
      setProductFilterInfo(newProductFilterInfo);
      await refreshProductOverviewsByCategoryId(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshProductOverviewsByCategoryId]
  );

  const clearRangeFilter = useCallback(
    async (appliedFilter: RangeFilterAttribute) => {
      const newProductFilterInfo = removeRangeFilterAttribute(
        productFilterInfo,
        appliedFilter
      );
      setProductFilterInfo(newProductFilterInfo);
      await refreshProductOverviewsByCategoryId(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshProductOverviewsByCategoryId]
  );
  const clearPredefinedCPFilter = useCallback(async () => {
    const newProductFilterInfo = {
      ...productFilterInfo,
      predefinedCPFilter: null,
    };
    setProductFilterInfo(newProductFilterInfo);
    await refreshProductOverviewsByCategoryId(1, newProductFilterInfo);
  }, [productFilterInfo, refreshProductOverviewsByCategoryId]);

  const clearAllFilter = useCallback(async () => {
    const newProductFilterInfo = defaultProductFilterInfo(
      productFilterInfo.sortAttribute
    );
    setProductFilterInfo(newProductFilterInfo);
    await refreshProductOverviewsByCategoryId(1, newProductFilterInfo);
  }, [productFilterInfo, refreshProductOverviewsByCategoryId]);

  const productOverviewRequestResponse = useMemo(
    () =>
      profileSyncAction(
        CategoryPageSession(categoryId),
        "Load Product Overviews from cache",
        () =>
          getProductOverviewsFromCategoryListByCategoryId(
            client,
            categoryId,
            1,
            productFilterInfo,
            productAttributeFilterInputMap
          )
      ),
    [categoryId, client, productFilterInfo, productAttributeFilterInputMap]
  );

  const [
    productOverviewsPaginationInfo,
    setProductOverviewsPaginationInfo,
  ] = useState<PaginationInfo<ProductOverview>>({
    currentPage: 1,
    items: productOverviewRequestResponse
      ? productOverviewRequestResponse.productOverviews
      : [],
    hasMore: false,
  });

  const productOverviews = productOverviewsPaginationInfo.items;
  const [
    productLabelsByProductIdMap,
    setProductLabelsByProductIdMap,
  ] = useState<{ [key in number]: ProductLabel[] }>({});
  const [bundleByProductIdMap, setBundleByProductIdMap] = useState<
    { [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys> }
  >({});

  const isProductListLoading = useMemo(
    () =>
      productOverviews.length === 0 &&
      (fetchProductOverviewsByCategoryIdRequestState.type === "initial" ||
        isRequestLoading(fetchProductOverviewsByCategoryIdRequestState)),
    [productOverviews, fetchProductOverviewsByCategoryIdRequestState]
  );

  useEffect(() => {
    const resources = getResources(
      fetchProductOverviewsByCategoryIdRequestState
    );
    if (
      !isRequestLoading(fetchProductOverviewsByCategoryIdRequestState) &&
      resources
    ) {
      const {
        currentPage: page,
        pageSize,
        productIdLabelMap,
        productIdBundleMap,
      } = resources;
      setProductOverviewsPaginationInfo(prev => {
        return {
          currentPage: resources.currentPage,
          items:
            page > prev.currentPage
              ? [...prev.items, ...resources.productOverviews]
              : prev.items
                  .slice(0, (page - 1) * pageSize)
                  .concat(resources.productOverviews),
          hasMore: resources.hasMore,
        };
      });
      setProductLabelsByProductIdMap(prev => ({
        ...prev,
        ...productIdLabelMap,
      }));
      setBundleByProductIdMap(prev => ({
        ...prev,
        ...productIdBundleMap,
      }));
    }
  }, [fetchProductOverviewsByCategoryIdRequestState]);

  const fetchNext = useCallback(async () => {
    if (
      !productOverviewsPaginationInfo.hasMore ||
      isRequestLoading(fetchProductOverviewsByCategoryIdRequestState)
    ) {
      return;
    }
    const currentPage = productOverviewsPaginationInfo.currentPage + 1;
    setIsProductListLoadingMore(true);
    await fetchNextProductOverviewsByCategoryId(currentPage, productFilterInfo);
    setIsProductListLoadingMore(false);
  }, [
    productOverviewsPaginationInfo,
    fetchProductOverviewsByCategoryIdRequestState,
    fetchNextProductOverviewsByCategoryId,
    productFilterInfo,
  ]);

  // Operations

  const refresh = useCallback(async () => {
    const singleCategoryPageResources = await fetchSingleCategoryPageResources();
    setFilterAttributeMap({
      singleLevelFilterAttributeMap:
        singleCategoryPageResources.singleLevelFilterAttributeMap,
      multiLevelLevelFilterAttributeMap:
        singleCategoryPageResources.multiLevelLevelFilterAttributeMap,
      rangeFilterAttributeMap:
        singleCategoryPageResources.rangeFilterAttributeMap,
    });

    const newSortFields = getValidSortFields(
      singleCategoryPageResources.sortFields
    );
    setSortFields(newSortFields);
    setCategoryPageContent(
      singleCategoryPageResources.resolvedCategoryPageContent
    );

    const shouldChangeSortAttribute = !newSortFields
      ? false
      : newSortFields.sortFieldOptions.find(
          s => s.value === productFilterInfoRef.current.sortAttribute.sortField
        ) != null
      ? false
      : true;

    const newSortField =
      shouldChangeSortAttribute && newSortFields
        ? newSortFields.defaultSortField
        : productFilterInfoRef.current.sortAttribute.sortField;

    const _productFilterInfo: ProductFilterInfo = {
      ...productFilterInfoRef.current,
      sortAttribute: shouldChangeSortAttribute
        ? {
            sortField: newSortField,
            sortOrder: SortOrder.ascending,
          }
        : productFilterInfoRef.current.sortAttribute,
    };

    await refreshProductOverviewsByCategoryId(1, _productFilterInfo);
    setProductFilterInfo(_productFilterInfo);
  }, [
    getValidSortFields,
    fetchSingleCategoryPageResources,
    refreshProductOverviewsByCategoryId,
    productFilterInfoRef,
  ]);

  const isFullPageLoading = useMemo(() => {
    if (categoryPageContent || hasDescription) {
      return false;
    }
    if (
      hasProducts(categoryTree) &&
      productOverviewsPaginationInfo.items.length === 0 &&
      !getResources(fetchProductOverviewsByCategoryIdRequestState) &&
      (fetchProductOverviewsByCategoryIdRequestState.type === "initial" ||
        isRequestLoading(fetchProductOverviewsByCategoryIdRequestState))
    ) {
      return true;
    }
    return false;
  }, [
    hasDescription,
    categoryPageContent,
    categoryTree,
    productOverviewsPaginationInfo,
    fetchProductOverviewsByCategoryIdRequestState,
  ]);

  return {
    hasDescription,

    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
    sortFields,
    categoryPageContent,

    productFilterInfo,
    applyProductFilterInfo,
    clearFilter,
    clearRangeFilter,
    clearPredefinedCPFilter,
    clearAllFilter,

    productOverviews,
    productLabelsByProductIdMap,
    bundleByProductIdMap,
    fetchProductOverviewsByCategoryIdRequestState: fetchProductOverviewsByCategoryIdRequestState,
    fetchNextProductOverviews: fetchNext,
    isProductListLoading,
    isProductListLoadingMore,

    isFullPageLoading,
    refresh,
  };
}

export default useViewModel;
