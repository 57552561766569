export type SimpleViewState<T, ErrorType = any> =
  | SimpleViewStateInitial
  | SimpleViewStateLoading
  | SimpleViewStateDisplay<T>
  | SimpleViewStateError<ErrorType>;

interface SimpleViewStateInitial {
  type: "initial";
}
export const SimpleViewStateInitial: SimpleViewStateInitial = {
  type: "initial",
};

interface SimpleViewStateLoading {
  type: "loading";
}
export const SimpleViewStateLoading: SimpleViewStateLoading = {
  type: "loading",
};

interface SimpleViewStateDisplay<T> {
  type: "display";
  data: T;
}
export function SimpleViewStateDisplay<T>(data: T): SimpleViewStateDisplay<T> {
  return {
    type: "display",
    data,
  };
}

interface SimpleViewStateError<ErrorType> {
  type: "error";
  error: ErrorType;
}
export function SimpleViewStateError<ErrorType = any>(
  error: ErrorType
): SimpleViewStateError<ErrorType> {
  return {
    type: "error",
    error,
  };
}
