import React, { useCallback, useMemo, useRef } from "react";

import { ThirdPartyProduct } from "../../models/product";
import { ProductOverview } from "../../models/ProductOverview";
import parse from "../../utils/HTMLReactParser";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";
import { LocalizedText } from "../../i18n/Localization";
import {
  appEventEmitter,
  AppEventOnClickThirdPartyProductButtonUrl,
} from "../../utils/SimpleEventEmitter";

import { BlackButton } from "../Button";

import ProductPriceView from "./ProductPriceView";

import styles from "./ThirdPartyProductContent.module.scss";

type ViewState = ViewStateButtonUrl | ViewStateInfoMessage | ViewStateEmpty;

interface ViewStateButtonUrl {
  type: "buttonUrl";
  buttonUrl: string;
}

interface ViewStateInfoMessage {
  type: "infoMessage";
  infoMessage: string;
}

interface ViewStateEmpty {
  type: "empty";
}

interface Props {
  product: ThirdPartyProduct<ProductOverview>;
}

export const ThirdPartyProductContent: React.FC<Props> = props => {
  const { product } = props;
  const { showPrice } = product;

  const viewState = useMemo<ViewState>(() => {
    const { displayType, buttonUrl, infoMessage } = product;
    if (displayType === "BUTTON_WITH_LINK" && buttonUrl) {
      return { type: "buttonUrl", buttonUrl };
    }
    if (displayType === "INFO_MESSAGE" && infoMessage) {
      return { type: "infoMessage", infoMessage };
    }
    return { type: "empty" };
  }, [product]);

  return (
    <>
      {showPrice ? (
        <div className={styles.productPriceRow}>
          <ProductPriceView
            productOfMinValue={product}
            productOfMaxValue={product}
          />
        </div>
      ) : null}
      {viewState.type === "buttonUrl" ? (
        <Button url={viewState.buttonUrl} />
      ) : viewState.type === "infoMessage" ? (
        <InfoMessage message={viewState.infoMessage} />
      ) : null}
    </>
  );
};

interface ButtonProps {
  url: string;
}

const Button: React.FC<ButtonProps> = props => {
  const { url } = props;

  const handleUrl = useCallback(() => {
    appEventEmitter.publish(AppEventOnClickThirdPartyProductButtonUrl(url));
  }, [url]);

  return (
    <BlackButton onClick={handleUrl} className={styles.button}>
      <LocalizedText messageID="page.product_detail.button.third_party_url" />
    </BlackButton>
  );
};

interface InfoMessageProps {
  message: string;
}

const InfoMessage: React.FC<InfoMessageProps> = props => {
  const { message } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const htmlString = useFillSrcUrlScheme(
    useExtractScriptsAndStyles(containerRef, message)
  );
  useRegisterPublishDeepLinkOnHTMLContent(containerRef, [htmlString]);
  const reactElement = useMemo(() => parse(htmlString), [htmlString]);

  return (
    <div className={styles.infoMessage} ref={containerRef}>
      {reactElement}
    </div>
  );
};

export default ThirdPartyProductContent;
