import React, { useCallback, useContext, useState } from "react";
import { IonFooter, IonToolbar } from "@ionic/react";

import CLContent from "../CLContent";
import Input from "../Form/Input";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import { NavBar, NavBarCrossButton } from "../NavBar";
import { PrimaryButton } from "../Button";

import { useResetPassword } from "../../repository/AuthRepository";

import { PresentationContext } from "../../our-navigation";

import { validateEmail } from "../../utils/validation";

import styles from "./styles.module.scss";
import { withProviders } from "../Provider";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

const ForgotPasswordPage: React.FC = () => {
  const [emailValue, setEmailValue] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);

  const { dismiss } = useContext(PresentationContext);
  const onClickNavBarCrossButton = useCallback(() => {
    dismiss();
  }, [dismiss]);

  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const resetPassword = useResetPassword();
  const onClickSubmitButton = useCallback(async () => {
    const _isEmailValid = validateEmail(emailValue);
    setIsEmailValid(_isEmailValid);
    if (!_isEmailValid) {
      return;
    }
    showLoadingModal();
    try {
      await resetPassword(emailValue);
      presentLocalizedAlert({
        headerId: "page.forgot_password.request_success",
        buttons: [
          {
            textMessageID: "alert.button.ok",
            handler: () => {
              dismiss();
            },
          },
        ],
      });
    } catch {
      presentLocalizedAlert({
        headerId: "page.forgot_password.error.fail_reset_password",
        buttons: [
          {
            textMessageID: "alert.button.ok",
          },
        ],
      });
    } finally {
      hideLoadingModal();
    }
  }, [
    emailValue,
    resetPassword,
    dismiss,
    presentLocalizedAlert,
    showLoadingModal,
    hideLoadingModal,
  ]);

  const { translate } = useIntl();

  return (
    <>
      <NavBar
        headerLeft={<NavBarCrossButton onClick={onClickNavBarCrossButton} />}
        headerTitle={<LocalizedText messageID="page.forgot_password.title" />}
      />
      <CLContent className={styles.ionContent}>
        <p className={styles.instruction}>
          <LocalizedText messageID="page.forgot_password.instruction" />
        </p>
        <Input
          className={styles.emailInput}
          type="email"
          placeholderId="page.forgot_password.input.email.label"
          value={emailValue}
          errorMessage={
            isEmailValid === false
              ? translate("page.forgot_password.error.invalid_email_format")
              : undefined
          }
          onChange={setEmailValue}
        />
      </CLContent>
      <IonFooter className={styles.ionFooter}>
        <IonToolbar className={styles.ionFooterToolbar}>
          <div className={styles.submitButtonContainer}>
            <PrimaryButton
              className={styles.submitButton}
              onClick={onClickSubmitButton}
            >
              <LocalizedText messageID="page.forgot_password.button.submit" />
            </PrimaryButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default withProviders(ForgotPasswordPage, LoadingModalProvider);
