import React, { useMemo, useCallback, useContext, useRef } from "react";
import cn from "classnames";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonRefresher,
  IonRefresherContent,
  IonList,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from "@ionic/react";

import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { LocalizedText } from "../../i18n/Localization";

import {
  isRequestLoading,
  isRequestError,
  getResources,
} from "../../models/ResourcesRequestState";
import { OppCard } from "../../models/OppCard";
import {
  useGetOppCards,
  useDeleteOppCard,
} from "../../repository/OppCardRepository";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { FullContentLoadingView } from "../LoadingView";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { LoadingModalContext } from "../LoadingModalProvider";

import styles from "./styles.module.scss";

import logoVisa from "../../resources/logo-visa.svg";
import logoMaster from "../../resources/logo-master.svg";

interface ViewStateInitial {
  type: "initial";
}
function ViewStateInitial(): ViewStateInitial {
  return {
    type: "initial",
  };
}

interface ViewStateLoading {
  type: "loading";
}
function ViewStateLoading(): ViewStateLoading {
  return {
    type: "loading",
  };
}

interface ViewStateError {
  type: "error";
  error: any;
}
function ViewStateError(error: any): ViewStateError {
  return {
    type: "error",
    error,
  };
}

interface ViewStateEmpty {
  type: "empty";
}
function ViewStateEmpty(): ViewStateEmpty {
  return {
    type: "empty",
  };
}

interface ViewStateDisplay {
  type: "display";
  oppCards: OppCard[];
}
function ViewStateDisplay(oppCards: OppCard[]): ViewStateDisplay {
  return {
    type: "display",
    oppCards,
  };
}

type ViewState =
  | ViewStateInitial
  | ViewStateLoading
  | ViewStateError
  | ViewStateEmpty
  | ViewStateDisplay;

const MyCardsPage: React.FC = () => {
  const { isOnline } = useContext(NetworkStatusContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { show: showLoading, hide: hideLoading } = useContext(
    LoadingModalContext
  );

  const ionLifeCycleContext = useCLIonLifeCycleContext();

  const [requestState, fetch, refresh] = useGetOppCards();

  const [, _deleteOppCard] = useDeleteOppCard();

  const deleteOppCard = useCallback(
    async (oppCard: OppCard) => {
      showLoading();
      try {
        await _deleteOppCard(oppCard.cardId);
        await fetch();
      } catch (e) {
        presentLocalizedAlert({
          message: e && e.message ? e.message : undefined,
          messageId: e && e.message ? undefined : "error.unknown",
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
      } finally {
        hideLoading();
      }
    },
    [showLoading, _deleteOppCard, hideLoading, presentLocalizedAlert, fetch]
  );

  const viewDidEnterOnceRef = useRef(false);
  const onViewDidEnter = useCallback(() => {
    if (!viewDidEnterOnceRef.current) {
      fetch().catch(() => {});
      viewDidEnterOnceRef.current = true;
    }
  }, [fetch]);
  ionLifeCycleContext.onIonViewDidEnter(onViewDidEnter);

  const oppCards = getResources(requestState);

  const viewState = useMemo<ViewState>(() => {
    if (!oppCards) {
      if (isRequestLoading(requestState)) {
        return ViewStateLoading();
      }
      if (isRequestError(requestState)) {
        return ViewStateError(requestState.error);
      }
      return ViewStateInitial();
    }
    if (oppCards.length > 0) {
      return ViewStateDisplay(oppCards);
    }
    return ViewStateEmpty();
  }, [requestState, oppCards]);

  const onRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh]
  );

  const retry = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);

  const handleDelete = useCallback(
    (oppCard: OppCard) => {
      presentLocalizedAlert({
        messageId: "my_cards.remove_card.dialog.message",
        messageArgs: {
          numberQuoted: oppCard.number ? `(${oppCard.number})` : "",
        },
        buttons: [
          {
            textMessageID: "my_cards.remove_card.dialog.button.remove",
            handler: () => {
              deleteOppCard(oppCard);
            },
          },
          { textMessageID: "cancel" },
        ],
      });
    },
    [presentLocalizedAlert, deleteOppCard]
  );

  return (
    <div className={cn("ion-page", styles.container)}>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="page.my_cards.title" />}
      />
      <CLContent className={styles.content}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={oppCards ? oppCards.length > 0 : false}
          onRetry={retry}
        >
          {viewState.type === "initial" || viewState.type === "loading" ? (
            <FullContentLoadingView />
          ) : (
            <List
              onRefresh={onRefresh}
              onDelete={handleDelete}
              viewState={viewState}
            />
          )}
        </NoInternetConnectionView>
      </CLContent>
    </div>
  );
};

export default MyCardsPage;

interface ListProps {
  onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
  onDelete: (oppCard: OppCard) => void;
  viewState: ViewStateDisplay | ViewStateEmpty | ViewStateError;
}

const List: React.FC<ListProps> = props => {
  const { onRefresh, onDelete, viewState } = props;

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {viewState.type === "empty" && <EmptyView />}
      {viewState.type === "error" && <ErrorView />}
      {viewState.type === "display" && (
        <>
          <div>
            <IonList className={styles.list}>
              {viewState.oppCards.map((oppCard, i) => (
                <Item key={i} oppCard={oppCard} onDelete={onDelete} />
              ))}
            </IonList>
          </div>
        </>
      )}
    </>
  );
};

interface ItemProps {
  oppCard: OppCard;
  onDelete: (oppCard: OppCard) => void;
}
const Item: React.FC<ItemProps> = props => {
  const { oppCard, onDelete } = props;

  const handleDeleteClick = useCallback(() => {
    onDelete(oppCard);
  }, [oppCard, onDelete]);

  const brandImg = useMemo<React.ReactNode | null>(() => {
    switch (oppCard.brand) {
      case "VISA":
        return <img className={styles.brandIcon} alt="visa" src={logoVisa} />;
      case "MASTERCARD":
        return (
          <img className={styles.brandIcon} alt="master" src={logoMaster} />
        );
      default:
        return null;
    }
  }, [oppCard]);

  return (
    <IonItemSliding>
      <IonItem>
        <div className={styles.itemBody}>
          {brandImg ? <div className={styles.brand}>{brandImg}</div> : null}
          <div className={styles.number}>{oppCard.number}</div>
        </div>
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption onClick={handleDeleteClick} color="danger">
          <LocalizedText messageID="my_cards.delete" />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

const EmptyView: React.FC = () => {
  return (
    <div className={styles.emptyMessage}>
      <LocalizedText messageID="my_cards.empty" />
    </div>
  );
};

const ErrorView: React.FC = () => {
  return (
    <div className={styles.errorMessage}>
      <LocalizedText messageID="error.unknown" />
    </div>
  );
};
