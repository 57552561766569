import React from "react";
import orderedDeliveryGif from "../../resources/ordered-delivery.gif";
import styles from "./DeliveryAccessory.module.scss";

const NUM_ROUTE_SECTION = 99;

export const DeliveryAccessory: React.FC = () => {
  return (
    <div className={styles.deliveryAccessory}>
      <div className={styles.deliveryGif}>
        <img src={orderedDeliveryGif} />
      </div>
      <div className={styles.route}>
        {new Array(NUM_ROUTE_SECTION).fill(0).map((_, i) => (
          <div className={styles.routeSection} key={i} />
        ))}
      </div>
      <div className={styles.destination} />
    </div>
  );
};
