import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonPage,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";

import { restAPIClient, fetchMaintenanceStatus } from "../../api/RESTful";
import useRefreshOnAppActive from "../../utils/RefreshOnAppActive";
import {
  networkEventEmitter,
  NetworkEventResumedFromMaintenance,
} from "../../utils/SimpleEventEmitter";

import { NavBar } from "../NavBar";
import CLContent from "../CLContent";

import styles from "./styles.module.scss";

import maintenanceGif from "../../resources/maintenance.gif";

interface ViewStateInitial {
  type: "initial";
}
const ViewStateInitial: ViewStateInitial = {
  type: "initial",
};

interface ViewStateLoading {
  type: "loading";
}
const ViewStateLoading: ViewStateLoading = {
  type: "loading",
};

interface ViewStateIsMaintenanceMode {
  type: "maintenanceMode";
}
const ViewStateIsMaintenanceMode: ViewStateIsMaintenanceMode = {
  type: "maintenanceMode",
};

interface ViewStateIsNotMaintenanceMode {
  type: "notMaintenanceMode";
}
const ViewStateIsNotMaintenanceMode: ViewStateIsNotMaintenanceMode = {
  type: "notMaintenanceMode",
};

type ViewState =
  | ViewStateInitial
  | ViewStateLoading
  | ViewStateIsMaintenanceMode
  | ViewStateIsNotMaintenanceMode;

interface Props {
  onMaintenanceModeEnter?: () => void;
}

const MaintenanceModeBlocker: React.FC<Props> = props => {
  const { onMaintenanceModeEnter } = props;

  const [isInitial, setIsInitial] = useState(true);
  const [maintenanceMode, setMaintenanceMode] = useState<boolean | null>(null);
  const prevMaintenanceModeRef = useRef(maintenanceMode);
  const [isLoading, setIsLoading] = useState(false);

  const request = useCallback(async () => {
    const res = await fetchMaintenanceStatus(restAPIClient);
    setMaintenanceMode(res);
  }, []);

  useRefreshOnAppActive(request);

  useEffect(() => {
    const { remove } = networkEventEmitter.subscribe(e => {
      if (e.type === "Maintenance") {
        setMaintenanceMode(true);
      }
    });
    return () => {
      remove();
    };
  }, []);

  useEffect(() => {
    if (!maintenanceMode && prevMaintenanceModeRef.current) {
      networkEventEmitter.publish(NetworkEventResumedFromMaintenance());
    }
    prevMaintenanceModeRef.current = maintenanceMode;
  }, [maintenanceMode]);

  useEffect(() => {
    if (isInitial) {
      (async () => {
        try {
          setIsInitial(false);
          setIsLoading(true);
          request();
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [request, isInitial]);

  const viewState = useMemo<ViewState>(() => {
    if (isInitial) {
      return ViewStateInitial;
    }
    if (isLoading) {
      return ViewStateLoading;
    }
    if (maintenanceMode === false) {
      return ViewStateIsNotMaintenanceMode;
    }
    if (maintenanceMode === true) {
      return ViewStateIsMaintenanceMode;
    }
    return ViewStateInitial;
  }, [isInitial, isLoading, maintenanceMode]);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await request();
      } finally {
        e.detail.complete();
      }
    },
    [request]
  );

  useEffect(() => {
    if (
      viewState.type === "maintenanceMode" &&
      onMaintenanceModeEnter != null
    ) {
      onMaintenanceModeEnter();
    }
  }, [viewState, onMaintenanceModeEnter]);

  return (
    <>
      {viewState.type === "maintenanceMode" ? (
        <MaintenanceView onRefresh={handleRefresh} />
      ) : (
        props.children
      )}
    </>
  );
};

interface MaintenanceViewProps {
  onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
}

const MaintenanceView: React.FC<MaintenanceViewProps> = props => {
  const { onRefresh } = props;

  return (
    <IonPage>
      <NavBar headerTitle="Club Shopping" />
      <CLContent className={styles.content}>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <div className={styles.header}>
          <IonImg src={maintenanceGif} alt="" />
        </div>
        <div className={styles.body}>
          <section className={styles.langSection}>
            <p className={styles.langP}>
              為提升購物體驗，Club
              Like團隊正在努力進行系統升級。我們將盡快完成，請稍後回來。
            </p>
          </section>
          <section className={styles.langSection}>
            <p className={styles.langP}>
              To provide a better shopping experience, the Club Shopping Team is
              currently upgrading the platform. We should be back online
              shortly, please try again later.
            </p>
          </section>
        </div>
      </CLContent>
    </IonPage>
  );
};

export default MaintenanceModeBlocker;
