import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import classnames from "classnames";

import LocalizedText from "../../i18n/LocalizedText";
import {
  Brand,
  groupBrands,
  groupByFirstLetterOrOthers,
  OTHER_BRANDS,
  sortBrandKeys,
} from "../../models/Brand";
import {
  ProfileSessionContext,
  withProfileSession,
} from "../../contexts/ProfileSessionContext";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { BrandSearchPageSession } from "../../utils/PerformanceRecordStore/sessions";
import {
  addPerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";
import { OurNavContext } from "../../our-navigation";
import {
  appEventEmitter,
  AppEventLinkToBrand,
} from "../../utils/SimpleEventEmitter";
import { useEffectOnce } from "../../hook/useEffectOnce";

import SearchInput from "./SearchInput";
import { NavBar } from "../NavBar";
import CLContent from "../CLContent";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import { FullContentLoadingView } from "../LoadingView";
import { FullContentErrorView } from "../ErrorView";
import { FullContentEmptyView } from "../EmptyView";
import RerenderLogger from "../Performance/RerenderLogger";

import List from "./List";
import styles from "./Search.module.scss";
import useViewModel from "./SearchViewModel";

const Search: React.FC = () => {
  const ionLifeCycleContext = useCLIonLifeCycleContext();

  const { brands, refresh, isFullPageLoading, error } = useViewModel();

  const { goBack } = useContext(OurNavContext);

  const didEnterOnce = useRef(false);
  const viewDidEnter = useCallback(() => {
    if (didEnterOnce.current) {
      return;
    }
    didEnterOnce.current = true;
    if (!brands) {
      refresh();
    }
  }, [brands, refresh]);

  ionLifeCycleContext.onIonViewDidEnter(viewDidEnter);

  const recordDidEnter = useCallback(
    () =>
      addPerformanceRecord(
        BrandSearchPageSession(),
        "Brand Search Page did enter"
      ),
    []
  );
  ionLifeCycleContext.onIonViewDidEnter(recordDidEnter);

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);

  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const onSearchTermChange = useCallback((search: string) => {
    setCurrentSearchTerm(search);
  }, []);

  const onClearTextClick = useCallback(() => {
    setCurrentSearchTerm("");
  }, []);

  const onSearchSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleCancelClick = useCallback(() => {
    goBack();
  }, [goBack]);

  const handleBrandClick = useCallback((brand: Brand) => {
    if (brand.url) {
      appEventEmitter.publish(AppEventLinkToBrand(brand.url));
    }
  }, []);

  const handleRefresh = useCallback(() => {
    return refresh().catch(() => {});
  }, [refresh]);

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          BrandSearchPageSession(),
          "Brand Search Page mounted",
          startTime
        );
        return;
      }
      addPerformanceRecord(
        BrandSearchPageSession(),
        RERENDER_EVENT,
        startTime,
        startTime + actualDuration
      );
    },
    []
  );

  return (
    <RerenderLogger id="Brand Search Page" onRender={handleRender}>
      <div className={classnames("ion-page", styles.container)}>
        <NavBar
          toolbarClassName={styles.toolbar}
          custom={
            <>
              <form
                className={styles.searchInputContainer}
                action="/"
                onSubmit={onSearchSubmit}
              >
                <SearchInput
                  value={currentSearchTerm}
                  onTextChange={onSearchTermChange}
                  onClearTextClick={onClearTextClick}
                  ref={inputRef}
                />
              </form>
              <IonButtons slot="primary">
                <IonButton
                  className={styles.navBarCancelButton}
                  mode="ios"
                  onClick={handleCancelClick}
                >
                  <div className={styles.navBarCancelButtonText}>
                    <LocalizedText messageID="page.brand_index.search.navbar.button.cancel" />
                  </div>
                </IonButton>
              </IonButtons>
            </>
          }
        />
        <CLContent ref={contentRef}>
          {isFullPageLoading ? (
            <FullContentLoadingView />
          ) : brands ? (
            <Display
              brands={brands}
              currentSearchTerm={currentSearchTerm}
              onRefresh={handleRefresh}
              onBrandClick={handleBrandClick}
            />
          ) : error ? (
            <FullContentErrorView
              errorMessage={error.message}
              onClickRetry={refresh}
            />
          ) : null}
        </CLContent>
      </div>
    </RerenderLogger>
  );
};

export default withProfileSession(BrandSearchPageSession, Search);

interface DisplayProps {
  brands: Brand[];
  currentSearchTerm: string;
  onRefresh: () => Promise<unknown>;
  onBrandClick: (brand: Brand) => void;
}

const Display: React.FC<DisplayProps> = props => {
  const { brands: _brands, currentSearchTerm, onRefresh, onBrandClick } = props;

  const brands = useMemo(() => {
    const processedSearchTerm = currentSearchTerm.trim();
    if (!processedSearchTerm) {
      return _brands;
    }
    return _brands.filter(b =>
      new RegExp(processedSearchTerm, "i").exec(b.label)
    );
  }, [_brands, currentSearchTerm]);

  const [groupedBrands, otherBrands] = useMemo(
    () =>
      groupBrands(brands, (brand: Brand) => groupByFirstLetterOrOthers(brand)),
    [brands]
  );

  const keys = useMemo(() => Object.keys(groupedBrands).sort(sortBrandKeys), [
    groupedBrands,
  ]);

  const allKeys = useMemo(
    () => (otherBrands.length > 0 ? [...keys, OTHER_BRANDS] : keys),
    [keys, otherBrands]
  );

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      await onRefresh();
      e.detail.complete();
    },
    [onRefresh]
  );

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, _actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          BrandSearchPageSession(),
          "Brand List mounted",
          startTime
        );
      }
    },
    []
  );

  const profileSessionContext = useContext(ProfileSessionContext);
  useEffectOnce(() => {
    if (profileSessionContext)
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Contents (BrandList) Shown"
      );
  });

  return (
    <RerenderLogger id="Brand Search Page List" onRender={handleRender}>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {brands.length > 0 ? (
        <List
          keys={allKeys}
          groupedBrands={groupedBrands}
          otherBrands={otherBrands}
          onBrandClick={onBrandClick}
        />
      ) : (
        <FullContentEmptyView
          messageID="search.result_empty.title"
          messageArgs={{
            SEARCH_TERM_HIGHLIGHT_CLASSNAME: styles.searchTermHighlight,
            SEARCH_TERM: currentSearchTerm,
          }}
        />
      )}
      <TabBarSpacePlaceholder />
    </RerenderLogger>
  );
};
