import {
  ApolloClient,
  FetchPolicy,
  useApolloClient,
} from "@apollo/react-hooks";
import { useCallback, useContext, useState } from "react";
import { useIntl } from "../../i18n/Localization";
import {
  FilterAttribute,
  FilterInputField,
  getFilterAttributeMapsFromFilters,
  makeGraphQLFilterFromParamsMap,
  ProductFilterInfo,
  RangeFilterAttribute,
  SortFields,
} from "../../models/filter";
import { ProductOverview } from "../../models/ProductOverview";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { PaginationInfo } from "../../repository/State";
import { fetchBrandTopCmsContent } from "../../repository/CMSPageContentRepository";
import ProductAttributeFilterContext from "../../contexts/ProductAttributeFilterContext";
import { fetchBrand, fetchProductOverviews } from "./api";
import { Brand } from "./models";
import { Locale } from "../../i18n/locale";
import { IndexMap } from "../../utils/type";
import { profileAsyncAction } from "../../utils/performance";
import { SingleBrandPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { fetchAggregation, fetchSortFields } from "../../api/GraphQL";
import { fetchProductLabelsByProductIds } from "../../api/ProductLabel";
import { convertAggregationToFilter } from "../../utils/Filter";
import { ProductLabel } from "../../models/ProductLabel";
import { ResolvedHTMLBasedCMSPageContent } from "../../models/cmsBlock";
import { ModelKeys } from "../../models/product";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { fetchProductSaleBundlesForProductSKUOnly } from "../../api/ProductSaleBundle";
import Config from "../../Config";

export function useFetchProductsPaginated(
  slug: string,
  brandId: number,
  productFilterInfo: ProductFilterInfo
): [
  ResourcesRequestState<ProductOverview[] | null>,
  () => Promise<ProductOverview[] | null>,
  () => Promise<ProductOverview[] | null>,
  PaginationInfo<ProductOverview> | null
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { productAttributeFilterInputMap } = useContext(
    ProductAttributeFilterContext
  );

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    ProductOverview
  > | null>(null);

  const [
    requestState,
    { call: _fetch, refresh: _refresh },
  ] = useFetchResources_v2<
    ProductOverview[] | null,
    (page: number) => Promise<ProductOverview[] | null>
  >({
    remoteResourcesProvider: async (page: number) => {
      const res = await profileAsyncAction(
        SingleBrandPageSession(slug),
        "Load Product List from Network",
        () =>
          fetchProductOverviews(
            client,
            brandId,
            page,
            productFilterInfo,
            productAttributeFilterInputMap,
            locale,
            "network-only"
          )
      );
      if (!res) {
        return null;
      }
      const { productOverviews, hasMore } = res;
      setPaginationInfo(prev => {
        if (prev == null) {
          return { items: productOverviews, hasMore, currentPage: page };
        }
        if (page === 1) {
          return { items: productOverviews, hasMore, currentPage: page };
        }
        return {
          items: [...prev.items, ...productOverviews],
          hasMore,
          currentPage: page,
        };
      });
      return productOverviews;
    },
  });

  const fetchNext = useCallback(async () => {
    if (!paginationInfo) {
      return _fetch(1);
    }
    if (paginationInfo.hasMore) {
      return _fetch(paginationInfo.currentPage + 1);
    }
    return null;
  }, [_fetch, paginationInfo]);

  const refresh = useCallback(async () => {
    return _refresh(1);
  }, [_refresh]);

  return [requestState, fetchNext, refresh, paginationInfo];
}

export function useFetchBrand(
  name: string
): [ResourcesRequestState<Brand>, () => Promise<Brand>, () => Promise<Brand>] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Brand,
    () => Promise<Brand>
  >({
    localCacheProvider: () =>
      profileAsyncAction(
        SingleBrandPageSession(name),
        "Load Brand from Cache",
        () => fetchBrand(client, name, locale, "cache-only")
      ),
    remoteResourcesProvider: () =>
      profileAsyncAction(
        SingleBrandPageSession(name),
        "Load Brand from Network",
        () => fetchBrand(client, name, locale, "network-only")
      ),
  });

  return [requestState, fetch, refresh];
}

export interface SingleBrandPageResource {
  brand: Brand;
  topCmsContent: ResolvedHTMLBasedCMSPageContent | null;
  singleLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute;
  };
  multiLevelLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute[];
  };
  rangeFilterAttributeMap: {
    [attributeCode in string]: RangeFilterAttribute;
  };
  sortFields: SortFields | null;
}

async function fetchSingleBrandPageResources(
  client: ApolloClient<any>,
  locale: Locale,
  productAttributeFilterFields: string[],
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  slug: string,
  queryParams: { [key in string]: string },
  fetchPolicy: FetchPolicy
): Promise<SingleBrandPageResource> {
  const brand = await fetchBrand(client, slug, locale, fetchPolicy);

  const fixedFilter = makeGraphQLFilterFromParamsMap(
    { brand: `${brand.id}` },
    productAttributeFilterFields,
    productAttributeFilterInputMap
  );
  const queryFilter = makeGraphQLFilterFromParamsMap(
    queryParams,
    productAttributeFilterFields,
    productAttributeFilterInputMap
  );
  const filter = { ...fixedFilter, ...queryFilter };
  const aggregations = await fetchAggregation(
    client,
    "",
    filter,
    locale,
    fetchPolicy
  );
  const filterTypes = aggregations
    ? aggregations.map(convertAggregationToFilter)
    : [];
  const {
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
  } = getFilterAttributeMapsFromFilters(
    filterTypes,
    queryParams,
    productAttributeFilterInputMap
  );

  const sortFields = await fetchSortFields(
    client,
    "",
    filter,
    locale,
    fetchPolicy
  );

  const topCmsId = brand.topCmsId;
  const topCmsContent = topCmsId
    ? await fetchBrandTopCmsContent(client, topCmsId, locale, fetchPolicy)
    : null;

  return {
    brand,
    topCmsContent,
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
    sortFields,
  };
}

export function useSingleBrandPageResource(
  slug: string,
  queryParams: { [key in string]: string }
): [
  ResourcesRequestState<SingleBrandPageResource>,
  () => Promise<SingleBrandPageResource>,
  () => Promise<SingleBrandPageResource>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const {
    productAttributeFilterFields,
    productAttributeFilterInputMap,
  } = useContext(ProductAttributeFilterContext);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    SingleBrandPageResource,
    () => Promise<SingleBrandPageResource>
  >({
    localCacheProvider: async () =>
      fetchSingleBrandPageResources(
        client,
        locale,
        productAttributeFilterFields,
        productAttributeFilterInputMap,
        slug,
        queryParams,
        "cache-only"
      ),
    remoteResourcesProvider: async () =>
      fetchSingleBrandPageResources(
        client,
        locale,
        productAttributeFilterFields,
        productAttributeFilterInputMap,
        slug,
        queryParams,
        "network-only"
      ),
  });

  return [requestState, fetch, refresh];
}

interface SingleBrandPageProductListResources {
  productOverviews: ProductOverview[];
  hasMore: boolean;
  currentPage: number;
  pageSize: number;

  productIdLabelMap: {
    [key in number]: ProductLabel[];
  };
  productIdBundleMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
}

export function useSingleBrandPageProductListResources(
  brandId: number | null
): [
  ResourcesRequestState<SingleBrandPageProductListResources | null>,
  (
    page: number,
    productFilterinfo: ProductFilterInfo
  ) => Promise<SingleBrandPageProductListResources | null>,
  (
    page: number,
    productFilterinfo: ProductFilterInfo
  ) => Promise<SingleBrandPageProductListResources | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { productAttributeFilterInputMap } = useContext(
    ProductAttributeFilterContext
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    SingleBrandPageProductListResources | null,
    (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => Promise<SingleBrandPageProductListResources | null>
  >({
    localCacheProvider: async (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => {
      if (!brandId) {
        return null;
      }
      const productOverviews = await fetchProductOverviews(
        client,
        brandId,
        page,
        productFilterInfo,
        productAttributeFilterInputMap,
        locale,
        "cache-only"
      );
      if (!productOverviews) {
        return null;
      }
      const productIdLabelMap: { [key in number]: ProductLabel[] } = {};
      const productIdBundleMap: {
        [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
      } = {};
      if (productOverviews.productOverviews.length > 0) {
        const productIds = productOverviews.productOverviews.map(
          productOverview => productOverview.id
        );
        const [productLabelsByProductIds, bundles] = await Promise.all([
          fetchProductLabelsByProductIds(
            client,
            productIds,
            "category",
            locale,
            "cache-only"
          ).catch(() => []),
          Config.ENABLE_BUNDLE_SALE &&
          Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
            ? fetchProductSaleBundlesForProductSKUOnly(
                client,
                productIds,
                locale,
                "cache-only"
              ).catch(() => [])
            : Promise.resolve([]),
        ]);
        for (const productLabelsByProductId of productLabelsByProductIds) {
          productIdLabelMap[productLabelsByProductId.productId] =
            productLabelsByProductId.productLabels;
        }

        for (const bundle of bundles) {
          if (bundle.mainProduct) {
            productIdBundleMap[bundle.mainProduct.id] = bundle;
          }
        }
      }
      return {
        ...productOverviews,
        currentPage: page,
        productIdLabelMap,
        productIdBundleMap,
      };
    },
    remoteResourcesProvider: async (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => {
      if (!brandId) {
        return null;
      }
      const productOverviews = await fetchProductOverviews(
        client,
        brandId,
        page,
        productFilterInfo,
        productAttributeFilterInputMap,
        locale,
        "network-only"
      );
      if (!productOverviews) {
        return null;
      }
      const productIdLabelMap: { [key in number]: ProductLabel[] } = {};
      const productIdBundleMap: {
        [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
      } = {};
      if (productOverviews.productOverviews.length > 0) {
        const productIds = productOverviews.productOverviews.map(
          productOverview => productOverview.id
        );
        const [productLabelsByProductIds, bundles] = await Promise.all([
          fetchProductLabelsByProductIds(
            client,
            productIds,
            "category",
            locale,
            "network-only"
          ).catch(() => []),
          Config.ENABLE_BUNDLE_SALE &&
          Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
            ? fetchProductSaleBundlesForProductSKUOnly(
                client,
                productIds,
                locale,
                "network-only"
              ).catch(() => [])
            : Promise.resolve([]),
        ]);
        for (const productLabelsByProductId of productLabelsByProductIds) {
          productIdLabelMap[productLabelsByProductId.productId] =
            productLabelsByProductId.productLabels;
        }

        for (const bundle of bundles) {
          if (bundle.mainProduct) {
            productIdBundleMap[bundle.mainProduct.id] = bundle;
          }
        }
      }
      return {
        ...productOverviews,
        currentPage: page,
        productIdLabelMap,
        productIdBundleMap,
      };
    },
  });

  return [requestState, fetch, refresh];
}
