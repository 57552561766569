import React from "react";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./SaleBadge.module.scss";

const SaleBadge: React.FC = () => {
  return (
    <div className={styles.badge}>
      <LocalizedText messageID="product.sale" />
    </div>
  );
};

export default SaleBadge;
