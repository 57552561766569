import {
  InAppBrowser,
  InAppBrowserObject,
  InAppBrowserOptions,
} from "@ionic-native/in-app-browser";
import { useMemo } from "react";
import Config from "../Config";
import { Locale } from "../i18n/locale";
import { useIntl } from "../i18n/Localization";
import { useGetTheClubOneTimeAuthenticatedUrl } from "../useCase/TheClubSSOUseCase";
import { isAndroid, isHybrid } from "./Platform";

const options: InAppBrowserOptions = {
  zoom: "no",
  location: "no",
  clearsessioncache: "yes",
  clearcache: "yes",
  // FIXME(kenchan): On android the page load of redirection cannot be executed normally.
  //                 Use before load event to force browser to load as workaround.
  beforeload: isAndroid() ? "yes" : undefined,
};

export function usePresentTheClubMemberProfileBrowser() {
  const getTheClubOneTimeAuthenticatedUrl = useGetTheClubOneTimeAuthenticatedUrl();
  const { locale } = useIntl();
  return useMemo(() => {
    if (!Config.ENABLE_THE_CLUB_SSO_MVP1) {
      return null;
    }
    const {
      THE_CLUB_EDIT_SHOULD_GO_TO_THE_CLUB_MEMBER_PROFILE,
      THE_CLUB_MEMBER_PROFILE_URL_EN,
      THE_CLUB_MEMBER_PROFILE_URL_ZH,
    } = Config.ENABLE_THE_CLUB_SSO_MVP1;
    const url =
      locale === Locale.en
        ? THE_CLUB_MEMBER_PROFILE_URL_EN
        : locale === Locale.zhHant
        ? THE_CLUB_MEMBER_PROFILE_URL_ZH
        : null;
    if (!THE_CLUB_EDIT_SHOULD_GO_TO_THE_CLUB_MEMBER_PROFILE || !url) {
      return null;
    }
    return async () => {
      const presentUrl = await getTheClubOneTimeAuthenticatedUrl(url);
      return presentBrowser(presentUrl);
    };
  }, [locale, getTheClubOneTimeAuthenticatedUrl]);
}

function presentBrowser(url: string) {
  if (isHybrid()) {
    return inAppBrowser(url);
  }
  return webBrowser(url);
}

export type Subscription = ReturnType<
  ReturnType<InAppBrowserObject["on"]>["subscribe"]
>;

function inAppBrowser(url: string) {
  return new Promise<void>(resolve => {
    const browser = InAppBrowser.create(url, "_blank", options);

    // FIXME(kenchan): On android the page load of redirection cannot be executed normally.
    //                 Use before load event to force browser to load as workaround.
    const beforeload = browser.on("beforeload");
    let beforeloadListener: Subscription | null = null;
    if (beforeload) {
      beforeloadListener = beforeload.subscribe(e => {
        const { url: _url } = e;
        browser._loadAfterBeforeload(_url);
      });
    }

    const exit = browser.on("exit");
    if (exit) {
      const exitListener = exit.subscribe(() => {
        exitListener.unsubscribe();
        if (beforeloadListener) {
          beforeloadListener.unsubscribe();
        }
        resolve();
      });
    }
  });
}

function webBrowser(url: string) {
  return new Promise<void>((resolve, reject) => {
    let interval: number | undefined;
    const clearInterval = () => {
      if (interval != null) {
        window.clearInterval(interval);
        interval = undefined;
      }
    };
    const height = 820;
    const width = 510;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const externalWindow = window.open(
      url,
      "The Club",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!externalWindow || !externalWindow.window) {
      reject(new Error("cannot-open-window"));
      return;
    }

    externalWindow.opener = null;
    const checkClosed = () => {
      if (
        !externalWindow ||
        !externalWindow.window ||
        externalWindow.closed ||
        externalWindow.window.closed
      ) {
        // Clear interval if externalWindow is closed
        clearInterval();
        resolve();
      }
    };

    interval = window.setInterval(checkClosed, 500);
  });
}
