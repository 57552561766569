import classnames from "classnames";
import React, { useCallback, useMemo } from "react";
import { LocalizedText } from "../../i18n/Localization";
import { LuckyDraw } from "../../models/LuckyDraw";
import { orderItemDisplayName } from "../../models/Order";
import { formatMoney, Money } from "../../models/Price";
import { PartialOrderItem } from "./model";
import styles from "./PurchasedItemList.module.scss";
import commonStyles from "./styles.module.scss";

interface LuckyDrawItemListProps {
  currencyCode: string;
  items: LuckyDraw<PartialOrderItem>[];
  onClickRedemptionLetterUrl: (url: string) => void;
}

const LuckyDrawItemList: React.FC<LuckyDrawItemListProps> = props => {
  const { currencyCode, items, onClickRedemptionLetterUrl } = props;

  return (
    <div>
      {items.map(item => (
        <LuckyDrawItemInfo
          key={item.sku}
          className={styles.itemInfo}
          currencyCode={currencyCode}
          item={item}
          onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
        />
      ))}
    </div>
  );
};

interface PurchasedItemInfoProps {
  className?: string;
  currencyCode: string;
  item: LuckyDraw<PartialOrderItem>;
  onClickRedemptionLetterUrl: (url: string) => void;
}

const LuckyDrawItemInfo: React.FC<PurchasedItemInfoProps> = props => {
  const { className, currencyCode, item, onClickRedemptionLetterUrl } = props;

  const priceClubpoint = item.requiredClubpoints;
  const price = useMemo(() => ({ currency: currencyCode, value: item.price }), [
    currencyCode,
    item.price,
  ]);
  const subtotal = useMemo(
    () => ({ currency: currencyCode, value: item.subtotal }),
    [currencyCode, item.subtotal]
  );

  return (
    <div
      className={classnames(
        className,
        styles.itemInfoContainer,
        commonStyles.orderDetailSectionContainer
      )}
    >
      <div className={styles.itemProductInfoContainer}>
        <div className={styles.itemProductName}>
          {orderItemDisplayName(item)}
        </div>
        <div className={styles.itemProductSku}>
          {item.simpleSku || item.sku}
        </div>
        {item.selectedOptions && item.selectedOptions.length ? (
          <div className={styles.itemSelectedOptions}>
            {item.selectedOptions.map((itemOption, i) => (
              <p key={i} className={styles.itemSelectedOption}>
                {itemOption.label}
                {itemOption.value ? <>: ${itemOption.value}</> : null}
              </p>
            ))}
          </div>
        ) : null}
      </div>
      {item.redemptionLetters ? (
        <div className={styles.redemptionLettersContainer}>
          <p className={styles.redemptionLettersHeader}>
            <LocalizedText messageID="order_detail.redemption_letters.header" />
          </p>
          {item.redemptionLetters.map((redemptionLetter, i) => {
            const { name, url } = redemptionLetter;
            return name && url ? (
              <RedemptionLetterUrl
                name={name}
                url={url}
                key={i}
                onLinkClicked={onClickRedemptionLetterUrl}
              />
            ) : null;
          })}
        </div>
      ) : null}
      <div className={styles.itemInfoList}>
        <div className={styles.priceQuantityRow}>
          <div className={styles.priceQuantityRow__priceColumn}>
            <p className={styles.totalPrice}>
              <PriceDisplay
                clubPoints={priceClubpoint * item.qtyOrdered}
                price={subtotal}
              />
            </p>
            {item.qtyOrdered > 1 && item.subtotal > 0 ? (
              <p className={styles.itemPrice}>
                <PriceDisplay clubPoints={priceClubpoint} price={price} />
              </p>
            ) : null}
          </div>
          <div className={styles.priceQuantityRow__quantityColumn}>
            <p className={styles.itemQuantity}>
              <LocalizedText
                messageID="order_detail.quantity"
                messageArgs={{ quantity: item.qtyOrdered }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PriceDisplayProps {
  clubPoints: number;
  price: Money;
}

const PriceDisplay: React.FC<PriceDisplayProps> = props => {
  const { clubPoints, price } = props;

  return (
    <div className={styles.price}>
      {clubPoints > 0 && (
        <div className={styles.priceRow}>
          <div className={styles.clubpointsIcon} />
          <div className={styles.infoItemContentItem}>{clubPoints}</div>
          {price.value > 0 ? (
            <div className={styles.infoItemContentItem}>+</div>
          ) : null}
        </div>
      )}
      {price.value > 0 ? (
        <div className={styles.priceRow}>{formatMoney(price)}</div>
      ) : null}
      {clubPoints === 0 && price.value === 0 ? (
        <div className={styles.priceRow}>{formatMoney(price)}</div>
      ) : null}
    </div>
  );
};

interface RedemptionLetterUrlProps {
  name: string;
  url: string;
  onLinkClicked: (url: string) => void;
}

const RedemptionLetterUrl: React.FC<RedemptionLetterUrlProps> = props => {
  const { name, url, onLinkClicked } = props;

  const handleLinkClicked = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onLinkClicked(url);
    },
    [onLinkClicked, url]
  );

  return (
    <p className={styles.redemptionLetterUrl}>
      <button
        className={styles.redemptionLetterLink}
        onClick={handleLinkClicked}
      >
        <LocalizedText messageID="order_detail.redemption_letters.prefix" />{" "}
        {name}
      </button>
    </p>
  );
};

export default LuckyDrawItemList;
