import { useApolloClient } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import {
  getCampaign,
  getCampaignProducts,
  getFixedProducts,
} from "../../api/Campaign";
import { Campaign } from "../../models/Campaign";
import { ProductLabel } from "../../models/ProductLabel";
import { ProductOverview } from "../../models/ProductOverview";
import {
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import {
  useFetchCampaign,
  useFetchCampaignProduct as useFetchCampaignProductOverviews,
  useFetchFixedProduct as useFetchFixedProductOverviews,
} from "../../repository/CampaignRepository";
import { useFetchProductLabelsByProductId } from "../../repository/ProductLabelRepository";

interface ViewData {
  campaign: Campaign | null;

  // Campaign Products
  campaignProductOverviews: ProductOverview[];

  // Fixed Products
  fixedProductOverviews: ProductOverview[];

  productLabelsByProductIdMap: { [key in number]: ProductLabel[] };
}

type ViewModel = ViewData & {
  isFullPageLoading: boolean;
  fetchCampaignError: Error | null;

  refresh: () => Promise<unknown>;
};

export function useViewModel(campaignId: number): ViewModel {
  const initialViewData = useInitialViewData(campaignId);

  const [campaign, setCampaign] = useState(initialViewData.campaign);

  const [fetchCampaignRequestState, , refreshCampaign] = useFetchCampaign(
    campaignId
  );

  const isFullPageLoading = useMemo(
    () => !campaign && isRequestLoading(fetchCampaignRequestState),
    [campaign, fetchCampaignRequestState]
  );

  const [
    productLabelsByProductIdMap,
    setProductLabelsByProductIdMap,
  ] = useState<{ [key in number]: ProductLabel[] }>({});

  const [campaignProductOverviews, setCampaignProductOverviews] = useState(
    initialViewData.campaignProductOverviews
  );

  const [
    ,
    ,
    refreshCampaignProductOverviews,
  ] = useFetchCampaignProductOverviews(campaignId);

  const [fixedProductOverviews, setFixedProductOverviews] = useState(
    initialViewData.fixedProductOverviews
  );

  const [, , refreshFixedProductOverviews] = useFetchFixedProductOverviews(
    campaignId
  );

  const [, fetchProductLabelsByProductId] = useFetchProductLabelsByProductId();

  const refresh = useCallback(async () => {
    try {
      const _campaign = await refreshCampaign();
      setCampaign(_campaign);
    } catch {}
    try {
      const [
        _campaignProductOverviews,
        _fixedProductOverviews,
      ] = await Promise.all([
        refreshCampaignProductOverviews(),
        refreshFixedProductOverviews(),
      ]);
      const _productLabels = await (async () => {
        try {
          const productIds = [
            ..._campaignProductOverviews.map(p => p.id),
            ..._fixedProductOverviews.map(p => p.id),
          ];
          return await fetchProductLabelsByProductId(productIds, "category");
        } catch {
          return {};
        }
      })();
      setCampaignProductOverviews(_campaignProductOverviews);
      setFixedProductOverviews(_fixedProductOverviews);
      setProductLabelsByProductIdMap(prev => ({
        ...prev,
        ..._productLabels,
      }));
    } catch {}
  }, [
    refreshCampaign,
    refreshCampaignProductOverviews,
    refreshFixedProductOverviews,
    fetchProductLabelsByProductId,
  ]);

  return {
    campaign,
    isFullPageLoading,
    fetchCampaignError: getRequestStateError(fetchCampaignRequestState),
    campaignProductOverviews,
    fixedProductOverviews,
    productLabelsByProductIdMap,
    refresh,
  };
}

function useInitialViewData(campaignId: number): ViewData {
  const client = useApolloClient();

  const _campaign = useMemo(() => getCampaign(client, campaignId), [
    client,
    campaignId,
  ]);

  const [campaign] = useState(_campaign);

  const _campaignProductOverviews = useMemo(
    () => getCampaignProducts(client, campaignId),
    [client, campaignId]
  );

  const [campaignProductOverviews] = useState(
    _campaignProductOverviews ? _campaignProductOverviews : []
  );

  const _fixedProductOverviews = useMemo(
    () => getFixedProducts(client, campaignId),
    [client, campaignId]
  );

  const [fixedProductOverviews] = useState(
    _fixedProductOverviews ? _fixedProductOverviews : []
  );

  const viewData = useMemo<ViewData>(() => {
    if (!campaign) {
      return {
        campaign,
        campaignProductOverviews: [],
        fixedProductOverviews: [],
        productLabelsByProductIdMap: {},
      };
    }
    return {
      campaign,
      campaignProductOverviews,
      fixedProductOverviews,
      productLabelsByProductIdMap: {},
    };
  }, [campaign, campaignProductOverviews, fixedProductOverviews]);

  return viewData;
}
