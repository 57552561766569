(function() {
  if (
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  ) {
    window.nativelySupportInteractionObserver = true;
  } else {
    window.nativelySupportInteractionObserver = false;
  }
})();

// FIXME: polyfill does not work, just take it out until we find the reason
// import "intersection-observer";
