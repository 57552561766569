import React, { useMemo } from "react";

import styles from "./styles.module.scss";

const MAX_NUMBER_OF_STARS = 5;
const MAX_SCORE = 100;

const scorePerStar = MAX_SCORE / MAX_NUMBER_OF_STARS;

interface Props {
  overallReviewScore: number;
  size: "normal" | "small";
  className?: string;
}
const ProductRatingScore: React.FC<Props> = props => {
  const { overallReviewScore, size, className } = props;
  const starFilledClassName = useMemo(() => {
    switch (size) {
      case "small":
        return styles.productRatingStarFilledSmall;
      default:
        return styles.productRatingStarFilled;
    }
  }, [size]);
  const starHalfFilledClassName = useMemo(() => {
    switch (size) {
      case "small":
        return styles.productRatingStarHalfFilledSmall;
      default:
        return styles.productRatingStarHalfFilled;
    }
  }, [size]);
  const starClassName = useMemo(() => {
    switch (size) {
      case "small":
        return styles.productRatingStarSmall;
      default:
        return styles.productRatingStar;
    }
  }, [size]);
  const stars: React.ReactNode[] = [];
  for (let i = 0; i < MAX_NUMBER_OF_STARS; ++i) {
    if (overallReviewScore >= (i + 1) * scorePerStar) {
      stars.push(<span key={i} className={starFilledClassName} />);
    } else if (overallReviewScore >= (i + 0.5) * scorePerStar) {
      stars.push(<span key={i} className={starHalfFilledClassName} />);
    } else {
      stars.push(<span key={i} className={starClassName} />);
    }
  }
  return <div className={className}>{stars}</div>;
};

export default React.memo(ProductRatingScore);
