import React, { useCallback, useState } from "react";
import LocalizedText from "../../i18n/LocalizedText";
import theClubIcon from "../../resources/the-club-icon-blue.svg";
import { PrimaryButton } from "../Button";
import Checkbox from "../Checkbox";
import styles from "./ForShoppingLoginLinkedWithTheClubContent.module.scss";

interface Props {
  method: LoginSignUpMethod;
  originalEmail: string;
  email: string;
  clubMemberID: string;
  onConfirmClick: (marketingMaterials: boolean) => void;
  onLinkAnotherAccountClick: (
    method: LoginSignUpMethod,
    marketingMaterials: boolean
  ) => void;
}

const ForShoppingLoginLinkedWithTheClubContent: React.FC<Props> = props => {
  const {
    method,
    originalEmail,
    email,
    clubMemberID,
    onConfirmClick,
    onLinkAnotherAccountClick,
  } = props;

  const [
    subscribeMarketingMaterials,
    setSubscribeMarketingMaterials,
  ] = useState(true);

  const handleConfirmClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onConfirmClick(subscribeMarketingMaterials);
    },
    [onConfirmClick, subscribeMarketingMaterials]
  );

  const handleLinkAnotherAccountClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onLinkAnotherAccountClick(method, subscribeMarketingMaterials);
    },
    [onLinkAnotherAccountClick, method, subscribeMarketingMaterials]
  );

  return (
    <div>
      <div className={styles.content}>
        <h2 className={styles.title}>
          <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.title" />
        </h2>
        <h3 className={styles.secondTitle}>
          <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.second_title" />
        </h3>
        <p className={styles.text}>
          <LocalizedText
            messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.text"
            messageArgs={{ email: originalEmail }}
          />
        </p>
        <div className={styles.info}>
          <img src={theClubIcon} className={styles.theClubIcon} />
          <div className={styles.infoRow}>
            <div className={styles.infoName}>
              <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.email_address" />
            </div>
            <div className={styles.infoValue}>{email}</div>
          </div>
          <div className={styles.infoRow}>
            <div className={styles.infoName}>
              <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.club_member_id" />
            </div>
            <div className={styles.infoValue}>{clubMemberID}</div>
          </div>
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            checked={subscribeMarketingMaterials}
            onCheckedChange={setSubscribeMarketingMaterials}
          >
            <p className={styles.checkboxText}>
              <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.checkbox_text" />
            </p>
          </Checkbox>
        </div>
        <PrimaryButton
          className={styles.submitButton}
          onClick={handleConfirmClick}
          block={true}
        >
          <LocalizedText messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.confirm" />
        </PrimaryButton>
        <p className={styles.pairText}>
          <LocalizedText
            messageID="the_club_sso.confirmation.for_shopping_login_linked_with_the_club.pair_text"
            messageArgs={{
              onButtonClick: handleLinkAnotherAccountClick,
              buttonClassName: styles.linkAnotherAccountButton,
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default ForShoppingLoginLinkedWithTheClubContent;
