import React from "react";
import LocalizedText from "../../i18n/LocalizedText";

import styles from "./ClubProtectBanner.module.scss";

const ClubProtectBanner: React.FC = () => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>
        <span className={styles.mainText}>
          <LocalizedText messageID="order_detail.item.club_protect.in_protection" />
        </span>
      </p>
    </div>
  );
};

export default ClubProtectBanner;
