import { useApolloClient } from "@apollo/client";
import { SHA256 } from "crypto-js";
import randomBytes from "randombytes";
import { useMemo } from "react";
import * as Sentry from "sentry-cordova";
import { fetchTheClubOneTimeToken } from "../api/TheClubSSO";
import Config from "../Config";
import { isCustomerLinkedToTheClub } from "../models/Customer";
import { useCustomer } from "../repository/AuthRepository";
import { isEmpty } from "../utils/String";

const theClubSSOOneTimeTokenApplyUrls = Config.THE_CLUB_SSO_ONE_TIME_TOKEN_APPLY_HREFS
  ? Config.THE_CLUB_SSO_ONE_TIME_TOKEN_APPLY_HREFS.filter(url => !isEmpty(url))
  : [];

const theClubSSOOneTimeTokenApplyUrlsRegex =
  theClubSSOOneTimeTokenApplyUrls.length > 0
    ? new RegExp(
        theClubSSOOneTimeTokenApplyUrls.map(str => `^${str}`).join("|")
      )
    : null;

export function useGetTheClubOneTimeAuthenticatedUrl() {
  const client = useApolloClient();
  const customer = useCustomer();
  return useMemo(() => {
    return async (url: string) => {
      const oneTimeTokenUrl = Config.THE_CLUB_SSO_ONE_TIME_TOKEN_URL;
      if (!oneTimeTokenUrl) {
        return url;
      }
      if (!customer || !isCustomerLinkedToTheClub(customer)) {
        return url;
      }
      if (
        !theClubSSOOneTimeTokenApplyUrlsRegex ||
        !theClubSSOOneTimeTokenApplyUrlsRegex.exec(url)
      ) {
        return url;
      }
      const codeVerifier = getVerifier();
      const challenge = getChallenge(codeVerifier);
      try {
        const token = await fetchTheClubOneTimeToken(client, challenge);
        return `${oneTimeTokenUrl}?codeVerifier=${codeVerifier}&token=${token}&redirectUri=${encodeURIComponent(
          url
        )}`;
      } catch (e) {
        Sentry.captureException(e);
        return url;
      }
    };
  }, [customer, client]);
}

// https://3.basecamp.com/3096882/buckets/12323301/messages/3998392159
function base64URLEncode(str: string) {
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

function getVerifier() {
  return base64URLEncode(randomBytes(32).toString("base64"));
}

function sha256(buffer: string) {
  return SHA256(buffer).toString();
}

function getChallenge(verifier: string) {
  return sha256(verifier);
}
