import React, { useMemo, useRef } from "react";
import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import parse from "../../utils/HTMLReactParser";

const HTMLView: React.FC<{
  htmlContent: string;
  onDeepLinkPublish?: (url: string) => void;
}> = props => {
  const { htmlContent, onDeepLinkPublish } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const html = useFillSrcUrlScheme(
    useExtractScriptsAndStyles(containerRef, htmlContent)
  );

  const reactElement = useMemo(() => parse(html), [html]);

  useRegisterPublishDeepLinkOnHTMLContent(
    containerRef,
    [html],
    onDeepLinkPublish
  );

  return <div ref={containerRef}>{reactElement}</div>;
};

export default HTMLView;
