import React, { useCallback, useMemo } from "react";
import { ModelKeys, ProductImage } from "../../models/product";
import { ConfiguredProduct } from "../../models/ProductDetails";
import { ProductSaleBundleProduct } from "../../models/ProductSaleBundle";
import placeholderUrl from "../../resources/img-product-default.png";
import styles from "./ProductImageList.module.scss";

function getProductImage<
  T extends { thumbnail: ProductImage | null; image: ProductImage | null }
>(product: T): string {
  return product.thumbnail && !product.thumbnail.disabled
    ? product.thumbnail.url
    : product.image && !product.image.disabled
    ? product.image.url
    : placeholderUrl;
}

interface ProductState {
  product: ProductSaleBundleProduct | ConfiguredProduct;
  disabled: boolean;
}

interface Props {
  items: ProductState[];
  onClickProduct: (product: ModelKeys) => void;
}

const ProductImageList: React.FC<Props> = props => {
  const { items, onClickProduct } = props;

  return (
    <div>
      <ul className={styles.list}>
        {items.map(item => (
          <Item
            product={item.product}
            disabled={item.disabled}
            key={item.product.sku}
            onClick={onClickProduct}
          />
        ))}
      </ul>
    </div>
  );
};

export default ProductImageList;

interface ItemProps {
  product: ProductSaleBundleProduct | ConfiguredProduct;
  disabled: boolean;
  onClick: (product: ModelKeys) => void;
}

const Item: React.FC<ItemProps> = props => {
  const { product, disabled, onClick } = props;

  const imgStyle = useMemo(
    () => ({
      backgroundImage: `url(${getProductImage(product)})`,
    }),
    [product]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(product);
    },
    [onClick, product]
  );

  return (
    <li className={styles.item}>
      <button className={styles.imgContainer} onClick={handleClick}>
        <div className={styles.img} style={imgStyle} />
        {disabled ? <div className={styles.overlay} /> : null}
      </button>
    </li>
  );
};
