import React from "react";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./RebateMessage.module.scss";

interface Props {
  rebateAmount: number;
  className?: string;
}

const RebateMessage: React.FC<Props> = props => {
  const { rebateAmount, className } = props;

  return (
    <div className={cn(styles.rebateMessage, className)}>
      <span className={styles.rebateAmount}>{rebateAmount}</span>%
      <LocalizedText messageID="rebate_circle.rebate" />
    </div>
  );
};

export default RebateMessage;
