import {
  ProductStockStatus,
  sortConfigurableOptions,
  ProductConfigurableAttributeOption,
  ModelKeys,
  WithVariantConfigurationAttributes,
} from "./product";

import { findFirst } from "../utils/CollectionUtils";
import { IndexMap } from "../utils/type";

export function buildConfigurationOptionsDependencyMap<
  P extends { sku: string },
  V extends {
    attributes: ProductConfigurableAttributeOption[];
    product: P;
  }
>(variants: V[], orderedAttributeCodes: string[]): object {
  const map = {} as any;
  for (const variant of variants) {
    const attributes: ProductConfigurableAttributeOption[] = [];
    for (const attributeCode of orderedAttributeCodes) {
      const attribute = variant.attributes.find(_attribute => {
        return _attribute.code === attributeCode;
      });
      if (attribute) {
        attributes.push(attribute);
      }
    }
    let currentNode = map;
    for (let i = 0; i < attributes.length; ++i) {
      const attribute = attributes[i];
      const node = currentNode[attribute.value];
      if (node) {
        currentNode = node;
      } else {
        const _node =
          i + 1 === variant.attributes.length ? variant.product.sku : {};
        currentNode[attribute.value] = _node;
        currentNode = _node;
      }
    }
  }
  return map;
}

export function resolveConfiguredProduct<P extends ModelKeys>(
  product: WithVariantConfigurationAttributes<P>,
  configurationOptionValue: IndexMap<string, number | null>
): P | null {
  const { configurableOptions, variants } = product;
  if (variants == null) {
    return null;
  }
  if (configurableOptions == null || configurableOptions.length <= 0) {
    return null;
  }
  const orderedConfigurableOptions = sortConfigurableOptions(
    configurableOptions
  );
  const orderedAttributeCodes = orderedConfigurableOptions.map(
    configurableOption => configurableOption.attributeCode
  );
  let dependencyMap = buildConfigurationOptionsDependencyMap(
    variants,
    orderedAttributeCodes
  ) as any;
  for (const configurableOption of orderedConfigurableOptions) {
    const selectedValue =
      configurationOptionValue[configurableOption.id] != null
        ? configurationOptionValue[configurableOption.id]
        : null;
    const selectedItem = findFirst(
      v => v.value === selectedValue,
      configurableOption.values
    );
    dependencyMap =
      selectedItem != null ? dependencyMap[selectedItem.value] : {};
  }
  if (typeof dependencyMap === "string") {
    const sku = dependencyMap;
    const variant = findFirst(v => v.product.sku === sku, variants);
    return variant != null ? variant.product : null;
  }
  return null;
}

export function resolveConfiguredProductBySelectedConfiguration<
  VariantProductType,
  VariantType extends {
    attributes: ProductConfigurableAttributeOption[];
    product: VariantProductType;
  }
>(
  variants: VariantType[],
  configuredOptionValues: number[]
): VariantProductType | null {
  for (const variant of variants) {
    if (variant.attributes.length !== configuredOptionValues.length) {
      return null;
    }
    const attributeValueMap = variant.attributes.reduce<
      { [key in string]: number }
    >((prev, attribute) => {
      prev[`${attribute.value}`] = attribute.value;
      return prev;
    }, {});
    const configuredOptionValueMap = configuredOptionValues.reduce<
      { [key in string]: number }
    >((prev, optionValue) => {
      prev[`${optionValue}`] = optionValue;
      return prev;
    }, {});
    const keys = Object.keys(attributeValueMap);
    let sameOption = true;
    for (const key of keys) {
      const attributeValue = attributeValueMap[key];
      if (!attributeValue) {
        sameOption = false;
        break;
      }
      const configuredOptionValue = configuredOptionValueMap[key];
      if (!configuredOptionValue) {
        sameOption = false;
        break;
      }
      sameOption = sameOption && configuredOptionValue === attributeValue;
    }
    if (sameOption) {
      return variant.product;
    }
  }
  return null;
}

// FIXME:
// Sorry for the complicated and no typed function
// Basically this function returns stock status for options
// The shape of the returned data is like
/*
  {
    stockStatus: "IN_STOCK",
    [option1]: {
      stockStatus: "IN_STOCK",
      [subOption1]: {
        stockStatus: "IN_STOCK",
      },
      [subOption2]: {
        stockStatus: "OUT_OF_STOCK",
      },
    }
  }
 */
export function buildConfigurationOptionsStockStatusMap<
  P extends { stockStatus: ProductStockStatus },
  V extends {
    attributes: ProductConfigurableAttributeOption[];
    product: P;
  }
>(variants: V[], orderedAttributeCodes: string[]): object {
  const map = {} as any;
  for (const variant of variants) {
    const attributes: ProductConfigurableAttributeOption[] = [];
    for (const attributeCode of orderedAttributeCodes) {
      const attribute = variant.attributes.find(_attribute => {
        return _attribute.code === attributeCode;
      });
      if (attribute) {
        attributes.push(attribute);
      }
    }
    let currentNode = map;
    for (let i = 0; i < attributes.length; ++i) {
      const attribute = attributes[i];
      const node = currentNode[attribute.value];
      if (node) {
        currentNode = node;
      } else {
        const _node =
          i + 1 === attributes.length ? variant.product.stockStatus : {};
        currentNode[attribute.value] = _node;
        currentNode = _node;
      }
    }
  }

  function build(node: any): any {
    if (typeof node === "string") {
      return {
        stockStatus: node as ProductStockStatus,
      };
    }
    const subNodes: { [key: string]: { stockStatus: ProductStockStatus } } = {};
    for (const key of Object.keys(node)) {
      const childNode = node[key];
      subNodes[key] = build(childNode);
    }
    const resultNode = {
      stockStatus:
        Object.values(subNodes).findIndex(n => n.stockStatus === "IN_STOCK") ===
        -1
          ? "OUT_OF_STOCK"
          : "IN_STOCK",
      ...subNodes,
    };
    return resultNode;
  }

  return build(map);
}
