import React, { useCallback } from "react";
import { IonIcon } from "@ionic/react";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./ProductLinkView.module.scss";

interface Props {
  sku: string;
  productName: string;
  merchantName?: string;
  onClick?: (sku: string) => void;
}

const ProductLinkView: React.FC<Props> = props => {
  const { sku, productName, merchantName, onClick } = props;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(sku);
    }
  }, [sku, onClick]);

  return (
    <div className={styles.productLink} onClick={handleClick}>
      <div className={styles.row}>
        <div className={styles.titleColumn}>
          <LocalizedText messageID="customer_product_review.product_link.product_name" />
        </div>
        <div className={styles.valueColumn}>{productName}</div>
      </div>
      {merchantName ? (
        <div className={styles.row}>
          <div className={styles.titleColumn}>
            <LocalizedText messageID="customer_product_review.product_link.merchant_name" />
          </div>
          <div className={styles.valueColumn}>{merchantName}</div>
        </div>
      ) : null}
      {onClick ? (
        <IonIcon className={styles.linkArrow} name="ios-arrow-forward" />
      ) : null}
    </div>
  );
};

export default ProductLinkView;
