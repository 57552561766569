import produce from "immer";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DataByLocale, Locale } from "../i18n/locale";
import { AppConfig, LiveEvent } from "../models/AppConfig";
import { EntityUrlRedirect } from "../models/EntityUrl";
import { StoreConfig } from "../models/StoreConfig";

type NeedUpdate =
  | false
  | { type: "force"; forceUpdateLink: string }
  | { type: "recommend"; recommendUpdateLink: string };

interface ContextState {
  appConfig: DataByLocale<AppConfig> | null;
  liveEvent: DataByLocale<LiveEvent> | null;
  urlRedirect: DataByLocale<EntityUrlRedirect[]> | null;
  storeConfig: DataByLocale<StoreConfig> | null;
  needUpdate: NeedUpdate;
}

const initialContextState: ContextState = {
  appConfig: null,
  liveEvent: null,
  urlRedirect: null,
  storeConfig: null,
  needUpdate: false,
};

interface ContextAction {
  updateAppConfigs: (
    appConfigs: DataByLocale<AppConfig>,
    urlRedirects: DataByLocale<EntityUrlRedirect[]>
  ) => void;
  updateStoreConfigs: (storeConfigs: DataByLocale<StoreConfig>) => void;
  updateLiveEvents: (liveEvents: DataByLocale<LiveEvent>) => void;
  waitForConfigs: (
    locale: Locale
  ) => Promise<{
    storeConfig: StoreConfig;
    appConfig: AppConfig;
  }>;

  checkAppNeedUpdate: (needUpdate: NeedUpdate) => void;
}

const initialContextAction: ContextAction = {
  updateAppConfigs: () => {},
  updateStoreConfigs: () => {},
  updateLiveEvents: () => {},
  waitForConfigs: () => Promise.reject(new Error("Context not initialized")),
  checkAppNeedUpdate: () => {},
};

type Context = ContextState & ContextAction;

const initialContextValue: Context = {
  ...initialContextState,
  ...initialContextAction,
};

const ConfigContext = React.createContext<Context>(initialContextValue);

export default ConfigContext;

export const ConfigContextProvider: React.FC = props => {
  const [state, setState] = useState<ContextState>(initialContextState);
  const configLoader = useRef(new ConfigLoader());

  useEffect(() => {
    if (state.appConfig != null && state.storeConfig != null) {
      configLoader.current.setConfigs({
        appConfig: state.appConfig,
        storeConfig: state.storeConfig,
      });
    }
  }, [state.appConfig, state.storeConfig]);

  const updateAppConfigs = useCallback(
    (
      appConfigs: DataByLocale<AppConfig>,
      urlRedirects: DataByLocale<EntityUrlRedirect[]>
    ) => {
      setState(prevState =>
        produce(prevState, draft => {
          draft.appConfig = appConfigs;
          draft.urlRedirect = urlRedirects;
        })
      );
    },
    []
  );

  const updateStoreConfigs = useCallback(
    (storeConfigs: DataByLocale<StoreConfig>) => {
      setState(prevState =>
        produce(prevState, draft => {
          draft.storeConfig = storeConfigs;
        })
      );
    },
    []
  );

  const updateLiveEvents = useCallback(
    (liveEvents: DataByLocale<LiveEvent>) => {
      setState(prevState =>
        produce(prevState, draft => {
          draft.liveEvent = liveEvents;
        })
      );
    },
    []
  );

  const checkAppNeedUpdate = useCallback((needUpdate: NeedUpdate) => {
    setState(prevState =>
      produce(prevState, draft => {
        draft.needUpdate = needUpdate;
      })
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      updateAppConfigs,
      updateStoreConfigs,
      updateLiveEvents,
      checkAppNeedUpdate,
      waitForConfigs: configLoader.current.waitForConfigs,
    }),
    [
      state,
      updateAppConfigs,
      updateStoreConfigs,
      updateLiveEvents,
      checkAppNeedUpdate,
    ]
  );

  return (
    <ConfigContext.Provider value={contextValue}>
      {props.children}
    </ConfigContext.Provider>
  );
};

class ConfigLoader {
  private configs: {
    appConfig: DataByLocale<AppConfig>;
    storeConfig: DataByLocale<StoreConfig>;
  } | null = null;

  private subscribers: ((configs: {
    appConfig: DataByLocale<AppConfig>;
    storeConfig: DataByLocale<StoreConfig>;
  }) => void)[] = [];

  private resolveConfigsForLocale = (
    locale: Locale,
    configs: {
      appConfig: DataByLocale<AppConfig>;
      storeConfig: DataByLocale<StoreConfig>;
    }
  ): {
    appConfig: AppConfig;
    storeConfig: StoreConfig;
  } => {
    return {
      appConfig: configs.appConfig[locale],
      storeConfig: configs.storeConfig[locale],
    };
  };

  private subscribeForConfigsLoaded = (
    subscriber: (configs: {
      appConfig: DataByLocale<AppConfig>;
      storeConfig: DataByLocale<StoreConfig>;
    }) => void
  ) => {
    this.subscribers.push(subscriber);
  };

  setConfigs = (configs: {
    appConfig: DataByLocale<AppConfig>;
    storeConfig: DataByLocale<StoreConfig>;
  }) => {
    this.configs = configs;
    const subscribers = this.subscribers;
    this.subscribers = [];
    subscribers.forEach(sub => sub(configs));
  };

  waitForConfigs = (
    locale: Locale
  ): Promise<{
    storeConfig: StoreConfig;
    appConfig: AppConfig;
  }> => {
    return new Promise(resolve => {
      if (this.configs != null) {
        resolve(this.resolveConfigsForLocale(locale, this.configs));
      } else {
        this.subscribeForConfigsLoaded(configs =>
          resolve(this.resolveConfigsForLocale(locale, configs))
        );
      }
    });
  };
}
