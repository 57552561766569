import React, { useCallback } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

interface Props {
  onClick: () => void;
  className?: string;
}

const BackToTopButton: React.FC<Props> = props => {
  const { onClick, className } = props;

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      onClick();
    },
    [onClick]
  );

  return (
    <div className={cn(styles.button, className)} onClick={handleClick}>
      <div className={styles.icon} />
    </div>
  );
};

export default BackToTopButton;
