import React from "react";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./ValueField.module.scss";

interface Props {
  className?: string;
  fieldClassName?: string;
  disabled?: boolean;
  titleMessageID?: MessageID;
  placeholderID?: MessageID;
  value?: React.ReactNode;
  isError?: boolean;
  errorMessage?: string;
  required?: boolean;
  arrow?: "bottom" | "right";
}

const ValueField: React.FC<Props> = props => {
  const {
    className,
    fieldClassName,
    titleMessageID,
    placeholderID,
    value,
    disabled,
    isError,
    errorMessage,
    required,
    arrow,
  } = props;

  return (
    <div className={className}>
      <label
        className={cn(fieldClassName, styles.valueField, {
          [styles.valueFieldDisabled]: disabled,
          [styles.valueFieldArrowRight]: arrow === "right",
          [styles.valueFieldArrowBottom]: arrow === "bottom",
        })}
      >
        {titleMessageID && (
          <div className={styles.titleColumn}>
            <span className={cn({ [styles.required]: required })}>
              <LocalizedText messageID={titleMessageID} />
            </span>
          </div>
        )}
        <div
          className={cn(styles.valueColumn, {
            [styles.placeholder]: !value,
          })}
        >
          {value ? (
            value
          ) : placeholderID ? (
            <LocalizedText messageID={placeholderID} />
          ) : null}
        </div>
        {isError && <div className={styles.errorIcon} />}
      </label>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default ValueField;
