import { camelizeKeys } from "humps";

import Config from "../Config";
import { filterNullOrUndefined } from "../utils/array";
import { ModelKeys } from "./product";

import { ProductLabel } from "./ProductLabel";
import { ProductOverview } from "./ProductOverview";
import { ProductSaleBundle } from "./ProductSaleBundle";

export interface CMS_PAGE_TYPE {
  type: "home";
}

export type HTML_BASED_CMS_PAGE_TYPE =
  | {
      type: "cmsPage";
      identifier: number;
    }
  | { type: "category"; identifier: string }
  | { type: "merchant"; identifier: string }
  | { type: "cmsBlock"; identifier: string };

// TODO:
// Refactor the data structure when implement home page content
export interface CMSPageContent {
  items: CMSBlock[];
}
export function CMSPageContent(items: CMSBlock[]): CMSPageContent {
  return {
    items,
  };
}

export interface ResolvedCMSPageContent {
  items: ResolvedCMSBlock[];
}

export interface HTMLBasedCMSPageContent {
  waitingToFillHTML: string;
  matchedCMSBlocks: MatchedCMSBlock[];
}

export interface ResolvedHTMLBasedCMSPageContent {
  waitingToFillHTML: string;
  resolvedMatchedCMSBlocks: ResolvedMatchedCMSBlock[];
}

/**
 * Carousel
 */
export interface CarouselItem {
  desktopImageUrl: string | null;
  mobileImageUrl: string | null;
  linkUrl: string | null;
}

export type CMSCarousel = {
  type: "banner_rotator";
} & Partial<{
  items: CarouselItem[] | null;
}>;

/**
 * HTML
 */

export type CMSStaticBlock = {
  type: "static_block";
} & Partial<{
  items:
    | Partial<{
        identifier: string | null;
      }>[]
    | null;
}>;

type MaybeValidIdentifier = string | undefined | null;

type ValidIdentifier = Exclude<string, "0">;

function isValidIdentifier(
  identifier: MaybeValidIdentifier
): identifier is ValidIdentifier {
  return !!identifier && identifier !== "0";
}

export function makeCMSStaticBlock(
  identifiers: MaybeValidIdentifier[]
): CMSStaticBlock | null {
  const validIdentifiers = identifiers.filter(isValidIdentifier);
  if (validIdentifiers.length === 0) {
    return null;
  }
  return {
    type: "static_block",
    items: validIdentifiers.map(identifier => ({ identifier })),
  };
}

export function isCMSStaticBlock(
  cmsBlock: CMSBlock
): cmsBlock is CMSStaticBlock {
  return cmsBlock.type === "static_block";
}

export interface CMSStaticBlockContent {
  identifier: string;
  title: string;
  contentForApp: string | null;
}

export const CMSStaticBlockContentGraphQLAttributes = `
  identifier
  title
  contentForApp: content_for_app
`;

/**
 * Horizontal Product List
 */

export type CMSHorizontalProductList = Partial<{
  title: string | null;
  widgetTemplate:
    | "special_item"
    | "top_seller"
    | "hot_product_list_ajax"
    | ""
    | null;
  viewMoreUrl: string | null;
  backgroundImageUrl: string | null;
  description: string | null;
  items: Partial<{ sku: string | null }>[] | null;
}>;

export type ResolvedCMSHorizontalProductList = Partial<{
  title: string | null;
  widgetTemplate:
    | "special_item"
    | "top_seller"
    | "hot_product_list_ajax"
    | ""
    | null;
  viewMoreUrl: string | null;
  backgroundImageUrl: string | null;
  description: string | null;
}> & {
  productOverviews: ProductOverview[];
  productLabelsByProductId: { [productId in number]: ProductLabel[] };
  bundleByProductId: {
    [productId in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
};

export interface CMSTopSellerHorizontalProductList
  extends CMSHorizontalProductList {
  type: "top_sellers";
}

export interface CMSProductsRecommendationProductList
  extends CMSHorizontalProductList {
  type: "products_recommendation";
}

export interface CMSPopularProductsProductList
  extends CMSHorizontalProductList {
  type: "popular_products";
}

/**
 * CMS Blog
 */
export type Blog = Partial<{
  postId: string | null;
  name: string | null;
  shortContent: string | null;
  url: string | null;
  featureImageUrl: string | null;
}>;

export type CMSBlogBlock = {
  type: "recent_blog";
} & Partial<{
  title: string | null;
  items: Blog[] | null;
  viewMoreUrl: string | null;
}>;

/**
 * Category bricks
 */
export type CMSCateogryBrick = {
  id: string;
} & Partial<{
  name: string | null;
  categoryThumbnail: string | null;
  backgroundColor1: string | null;
  backgroundColor2: string | null;
}>;

export type CMSCateogryBricksBlock = {
  type: "category_bricks";
} & Partial<{
  items: CMSCateogryBrick[] | null;
}>;

/**
 * Export
 */

export type CMSBlock =
  | CMSCarousel
  | CMSStaticBlock
  | CMSTopSellerHorizontalProductList
  | CMSProductsRecommendationProductList
  | CMSPopularProductsProductList
  | CMSBlogBlock
  | CMSCateogryBricksBlock;

export interface MatchedCMSBlock {
  cmsBlocks: CMSBlock[];
  matchId: string;
}

function isCMSBlock(json: object): json is CMSBlock {
  if (
    [
      "banner_rotator",
      "static_block",
      "top_sellers",
      "products_recommendation",
      "popular_products",
      "recent_blog",
      "category_bricks",
    ].indexOf((json as any).type) > -1
  ) {
    return true;
  }
  return false;
}

export function parseCMSBlockFromJSON(json: any): CMSBlock | null {
  if (json == null || typeof json !== "object") {
    return null;
  }
  const camelized = camelizeKeys(json);
  if (!isCMSBlock(camelized)) {
    return null;
  }
  if (
    camelized.type === "products_recommendation" ||
    camelized.type === "top_sellers" ||
    camelized.type === "popular_products"
  ) {
    return {
      ...camelized,
      items:
        Config.MAX_HORIZONTAL_LIST_ITEMS &&
        Config.MAX_HORIZONTAL_LIST_ITEMS > 0 &&
        camelized.items
          ? filterNullOrUndefined(camelized.items).slice(
              0,
              Config.MAX_HORIZONTAL_LIST_ITEMS
            )
          : camelized.items,
    };
  }
  return camelized;
}

export function parseCMSPageContentFromJson(json: any): CMSPageContent | null {
  const { items } = json;
  if (!Array.isArray(items)) {
    return null;
  }
  const cmsBlocks: CMSBlock[] = [];
  for (const item of items) {
    const cmsBlock = parseCMSBlockFromJSON(item);
    if (cmsBlock != null) {
      cmsBlocks.push(cmsBlock);
    }
  }
  return {
    items: cmsBlocks,
  };
}

export type ResolvedCMSBlock =
  | ResolvedCMSCarousel
  | ResolvedCMSStaticBlock
  | ResolvedCMSTopSellerHorizontalProductList
  | ResolvedCMSProductsRecommendationProductList
  | ResolvedCMSPopularProductsProductList
  | ResolvedCMSBlogBlock
  | ResolvedCMSCategoryBricksBlock;

export type ResolvedCMSCarousel = CMSCarousel;

export interface ResolvedCMSStaticBlock {
  type: "static_block";
  items: CMSStaticBlockContent[];
}

export interface ResolvedCMSTopSellerHorizontalProductList
  extends ResolvedCMSHorizontalProductList {
  type: "top_sellers";
}

export interface ResolvedCMSProductsRecommendationProductList
  extends ResolvedCMSHorizontalProductList {
  type: "products_recommendation";
}

export interface ResolvedCMSPopularProductsProductList
  extends ResolvedCMSHorizontalProductList {
  type: "popular_products";
}

export type ResolvedCMSBlogBlock = CMSBlogBlock;

export type ResolvedCMSCategoryBricksBlock = CMSCateogryBricksBlock;

export interface ResolvedMatchedCMSBlock {
  resolvedCMSBlocks: ResolvedCMSBlock[];
  matchId: string;
}
