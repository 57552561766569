import produce from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { SortInputField } from "../models/filter";

interface ContextState {
  productAttributeSortInputFields: SortInputField[];
  productAttributeSortFields: string[];
}

const initialContextState: ContextState = {
  productAttributeSortInputFields: [],
  productAttributeSortFields: [],
};

type Context = ContextState & {
  updateProductAttributeSortFields: (
    productAttributeFilterSortFields: SortInputField[]
  ) => void;
};

const initialContextValue: Context = {
  ...initialContextState,
  updateProductAttributeSortFields: () => {},
};

const ProductAttributeSortContext = React.createContext<Context>(
  initialContextValue
);

export const ProductAttributeSortContextProvider: React.FC = props => {
  const [state, setState] = useState(initialContextState);

  const updateProductAttributeSortFields = useCallback(
    (productAttributeFilterSortFields: SortInputField[]) => {
      setState(prevState =>
        produce(prevState, draft => {
          draft.productAttributeSortInputFields = productAttributeFilterSortFields;
          draft.productAttributeSortFields = productAttributeFilterSortFields.map(
            ({ name }) => name
          );
        })
      );
    },
    []
  );

  const contextValue = useMemo(
    () => ({ ...state, updateProductAttributeSortFields }),
    [state, updateProductAttributeSortFields]
  );

  return (
    <ProductAttributeSortContext.Provider value={contextValue}>
      {props.children}
    </ProductAttributeSortContext.Provider>
  );
};

export default ProductAttributeSortContext;
