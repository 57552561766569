import React, { useMemo } from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
  offset?: number;
  className?: string;
}

const FloatingButtons: React.FC<Props> = React.memo(props => {
  const { children, offset, className } = props;

  const transformed = React.Children.map(children, (child, i) => {
    return (
      <div className={styles.button} key={i}>
        {child}
      </div>
    );
  });

  const referenceStyle = useMemo<React.CSSProperties>(
    () => ({
      bottom: `${offset || 0}px`,
    }),
    [offset]
  );

  return (
    <div
      className={classnames(styles.reference, className)}
      style={referenceStyle}
    >
      <div className={styles.container}>{transformed}</div>
    </div>
  );
});

export default FloatingButtons;
