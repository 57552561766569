import React, { useCallback, useMemo, useState } from "react";

interface ContextState {
  bingoListCount: number | null;
}

const initialContextState: ContextState = {
  bingoListCount: null,
};

interface ContextAction {
  updateBingoListCount: (bingoListCount: number) => void;
}

const initialContextAction: ContextAction = {
  updateBingoListCount: () => {},
};

type Context = ContextState & ContextAction;

const initialContextValue: Context = {
  ...initialContextState,
  ...initialContextAction,
};

const BingoListContext = React.createContext<Context>(initialContextValue);

export default BingoListContext;

export const BingoListContextProvider: React.FC = props => {
  const [bingoListCount, setBingoListCount] = useState<number | null>(null);

  const updateBingoListCount = useCallback(c => {
    setBingoListCount(c);
  }, []);

  const contextValue = useMemo<Context>(
    () => ({
      bingoListCount,
      updateBingoListCount,
    }),
    [bingoListCount, updateBingoListCount]
  );

  return (
    <BingoListContext.Provider value={contextValue}>
      {props.children}
    </BingoListContext.Provider>
  );
};
