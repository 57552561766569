import React, { useMemo } from "react";

import { LocalizedText } from "../../i18n/Localization";
import { O2oStore } from "../Checkout/models";

import styles from "./styles.module.scss";

import logo4px from "../../resources/o2o-logo-4px.png";
import logoAlfred from "../../resources/o2o-logo-alfred.png";
import logoClublike from "../../resources/o2o-logo-clublike.png";

const storeTypeLogoMapping: { [key in O2oStore["storeType"]]: string } = {
  "o2o-store": logoClublike,
  elocker: logoClublike,
  "o2o-fulfillment": logoClublike,
};

function getLockerProviderMapping(o2oStore: O2oStore) {
  switch (o2oStore.lockerProvider) {
    case "hkt-shop":
      return logoClublike;
    case "csl-shop":
      return logoClublike;
    case "1010-center":
      return logoClublike;
    case "4px":
      return logo4px;
    case "alfred":
      return logoAlfred;
    default:
      return null;
  }
}

interface Props {
  o2oStore: O2oStore;
}

const O2oStoreDisplay: React.FC<Props> = props => {
  const { o2oStore } = props;

  const icon = useMemo(() => {
    if (o2oStore.storeType === "elocker") {
      return getLockerProviderMapping(o2oStore);
    }
    return storeTypeLogoMapping[o2oStore.storeType];
  }, [o2oStore]);

  const address = useMemo(() => {
    return [
      o2oStore.street,
      o2oStore.building,
      o2oStore.block,
      o2oStore.floor,
      o2oStore.flat,
    ]
      .filter(x => !!x && !!x.trim())
      .join(", ");
  }, [o2oStore]);

  return (
    <div className={styles.o2oStore}>
      <p className={styles.name}>
        {icon ? <img src={icon} alt="" className={styles.icon} /> : null}
        {o2oStore.name}
      </p>
      <p className={styles.address}>{address}</p>
      {o2oStore.openingHours ? (
        <p className={styles.openingHours}>
          <LocalizedText messageID="self_pickup_form.opening_hours" />:{" "}
          {o2oStore.openingHours}
        </p>
      ) : null}
    </div>
  );
};

export default O2oStoreDisplay;
