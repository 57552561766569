import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import { getStoreViewCodeForLocale, Locale } from "../i18n/locale";
import {
  ClubMemberCampaign,
  getAvailableCount,
  RemoteClubMemberCampaign,
  RemoteClubMemberCampaignForCount,
  transformRemoteCampaignMemberToCampaignMember,
  transformRemoteClubMemberCampaign,
} from "../models/ClubMemberCampaign";
import { filterNullOrUndefined } from "../utils/array";

const clubMemberCampaignGraphQLAttributes = `
clubMemberCampaign {
  campaigns {
    id: campaign_id
    banner
    end
    expired
    title
    image
  }
  campaignMembers {
    campaignId: campaign_id
    quota
    usedQuota: used_quota
  }
  campaignOrderItems {
    campaignId: campaign_id
    createdAt: created_at
  }
}
`;

export function getClubMemberCampaign(
  client: ApolloClient<unknown>
): ClubMemberCampaign | null {
  const result = client.readQuery<{
    clubMemberCampaign: RemoteClubMemberCampaign;
  }>({
    query: gql`
      query BingoList {
        ${clubMemberCampaignGraphQLAttributes}
      }
    `,
  });
  if (result) {
    return transformRemoteClubMemberCampaign(result.clubMemberCampaign);
  }
  return null;
}

export async function fetchClubMemberCampaign(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<ClubMemberCampaign> {
  const result = await client.query<{
    clubMemberCampaign: RemoteClubMemberCampaign;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query ClubMemberCampaign {
        ${clubMemberCampaignGraphQLAttributes}
      }
    `,
    fetchPolicy,
  });

  return transformRemoteClubMemberCampaign(result.data.clubMemberCampaign);
}

const clubMemberCampaignCountGraphQLAttributes = `
clubMemberCampaign {
  campaigns {
    id: campaign_id
    expired
  }
  campaignMembers {
    campaignId: campaign_id
    quota
    usedQuota: used_quota
  }
}
`;

export function getClubMemberCampaignCount(
  client: ApolloClient<unknown>
): number | null {
  const result = client.readQuery<{
    clubMemberCampaign: RemoteClubMemberCampaignForCount;
  }>({
    query: gql`
      query BingoList {
        ${clubMemberCampaignCountGraphQLAttributes}
      }
    `,
  });
  if (result) {
    const campaigns = result.clubMemberCampaign.campaigns
      ? filterNullOrUndefined(result.clubMemberCampaign.campaigns)
      : [];
    const campaignMembers = result.clubMemberCampaign.campaignMembers
      ? filterNullOrUndefined(result.clubMemberCampaign.campaignMembers).map(
          transformRemoteCampaignMemberToCampaignMember
        )
      : [];
    return getAvailableCount(campaigns, campaignMembers);
  }
  return null;
}

export async function fetchClubMemberCampaignCount(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<number> {
  const result = await client.query<{
    clubMemberCampaign: RemoteClubMemberCampaignForCount;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query BingoListCount {
        ${clubMemberCampaignCountGraphQLAttributes}
      }
    `,
    fetchPolicy,
  });
  const campaigns = result.data.clubMemberCampaign.campaigns
    ? filterNullOrUndefined(result.data.clubMemberCampaign.campaigns)
    : [];
  const campaignMembers = result.data.clubMemberCampaign.campaignMembers
    ? filterNullOrUndefined(result.data.clubMemberCampaign.campaignMembers).map(
        transformRemoteCampaignMemberToCampaignMember
      )
    : [];
  return getAvailableCount(campaigns, campaignMembers);
}
