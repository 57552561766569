import * as yup from "yup";
import Config from "../Config";
import { Locale } from "../i18n/locale";

export type LuckyDraw<T> = T & {
  __context_lucky_draw: "lucky_draw";
};

interface LuckyDrawConfig {
  productSkus: string[];
  optOutMsgsEn: string[];
  optOutMsgsZhHant: string[];
  shoppingCartProductUrl: string | null;
}

const LuckyDrawConfigSchema = yup.array().of(
  yup
    .object<LuckyDrawConfig>({
      productSkus: yup.array().of(yup.string()),
      optOutMsgsEn: yup.array().of(yup.string()),
      optOutMsgsZhHant: yup.array().of(yup.string()),
      shoppingCartProductUrl: yup.string(),
    })
    .camelCase()
);

const luckyDrawConfigs: LuckyDrawConfig[] | null = (luckyDraws => {
  try {
    return LuckyDrawConfigSchema.validateSync(luckyDraws);
  } catch {
    return null;
  }
})(Config.LUCKY_DRAW);

function findLuckyDrawConfig(sku: string) {
  if (!luckyDrawConfigs) {
    return null;
  }
  const selectedLuckyDrawConfigs = luckyDrawConfigs.filter(
    ({ productSkus }) => productSkus.indexOf(sku) > -1
  );
  if (selectedLuckyDrawConfigs.length === 0) {
    return null;
  }
  return selectedLuckyDrawConfigs[0];
}

export function isLuckyDrawSku(sku: string) {
  const luckyDrawConfig = findLuckyDrawConfig(sku);
  return !!luckyDrawConfig;
}

export function isOptOutMessage(sku: string, msg: string, locale: Locale) {
  const luckyDrawConfig = findLuckyDrawConfig(sku);
  if (!luckyDrawConfig) {
    return false;
  }
  switch (locale) {
    case Locale.en:
      return luckyDrawConfig.optOutMsgsEn.indexOf(msg) > -1;
    case Locale.zhHant:
      return luckyDrawConfig.optOutMsgsZhHant.indexOf(msg) > -1;
  }
}

export function getShoppingCartProductUrl(sku: string) {
  const luckyDrawConfig = findLuckyDrawConfig(sku);
  if (!luckyDrawConfig) {
    return null;
  }
  return luckyDrawConfig.shoppingCartProductUrl;
}
