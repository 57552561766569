import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";
import { Plugins } from "@capacitor/core";
import { IonButton, IonButtons } from "@ionic/react";
import { useHistory } from "react-router-dom";

import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import CLContent from "../CLContent";
import { withProviders } from "../Provider";
import { LocalizedText } from "../../i18n/Localization";
import { NavBar } from "../NavBar";
import SearchSuggestion from "./SearchSuggestion";
import SearchInput from "./SearchInput";
import { SearchSuggestionListItemItem } from "./SearchSuggestionList";

import {
  isRequestLoading,
  getResources,
  getRequestStateError,
} from "../../models/ResourcesRequestState";
import { SearchTerm, SearchAutoSuggestion } from "../../models/Search";
import {
  useAddRecentSearchTerm,
  useSearchSuggestion,
} from "../../repository/SearchRepository";

import {
  getPathForProductDetailPage,
  useCurrentTab,
  getPathForSearch,
  getPathForTab,
} from "../../navigation/routes";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import { OurNavContext } from "../../our-navigation";

import { isEmpty } from "../../utils/String";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { useDebounce } from "../../hook/utils";
import { isHybrid } from "../../utils/Platform";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { SearchPageSession } from "../../utils/PerformanceRecordStore/sessions";

import styles from "./SearchSuggestionPage.module.scss";
import {
  appEventEmitter,
  AppEventLinkToBrand,
  AppEventOnClickViewMore,
} from "../../utils/SimpleEventEmitter";

const SearchSuggestionPage: React.FC = () => {
  const currentTab = useCurrentTab();
  const history = useHistory();
  const { navigate, goBack } = useContext(OurNavContext);
  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchSuggestionIsLoading, setSearchSuggestionIsLoading] = useState(
    false
  );

  const handleSearchTermChange = useCallback((_searchTerm: string) => {
    setSearchTerm(_searchTerm);
    setSearchSuggestionIsLoading(!isEmpty(_searchTerm));
  }, []);

  const debouncedSearchTerm = useDebounce(searchTerm, 250);
  const searchSuggestionRequestState = useSearchSuggestion(debouncedSearchTerm);

  const prevSearchSuggestionRequestStateIsLoading = useRef(
    isRequestLoading(searchSuggestionRequestState)
  );

  useEffect(() => {
    const requestStateIsLoading = isRequestLoading(
      searchSuggestionRequestState
    );

    if (
      prevSearchSuggestionRequestStateIsLoading.current &&
      !requestStateIsLoading
    ) {
      setSearchSuggestionIsLoading(false);
    }

    prevSearchSuggestionRequestStateIsLoading.current = requestStateIsLoading;
  }, [searchSuggestionRequestState]);

  const onClearTextClick = useCallback(() => {
    setSearchTerm("");
  }, []);

  const viewDidEnter = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  ionLifeCycleContext.onIonViewDidEnter(viewDidEnter);

  const onClickCancelButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      goBack();
    },
    [goBack]
  );

  const addRecentSearchTerm = useAddRecentSearchTerm();

  const applySearch = useCallback(
    (_searchTerm: SearchTerm) => {
      if (isEmpty(_searchTerm)) {
        return;
      }
      if (isHybrid()) {
        Plugins.Keyboard.hide();
      }
      addPerformanceRecord(SearchPageSession(_searchTerm), "Submit search");
      navigate(getPathForSearch(currentTab, _searchTerm));
      addRecentSearchTerm(_searchTerm);
    },
    [navigate, currentTab, addRecentSearchTerm]
  );

  const handlePopularSearchOnSearchSubmit = useCallback(
    (popularSearches: SearchAutoSuggestion[]) => {
      if (popularSearches.length === 0 || popularSearches.length >= 2) {
        applySearch(searchTerm);
        return;
      }
      const [theOnlyPopularSearch] = popularSearches;
      const { redirectUrl } = theOnlyPopularSearch;
      if (redirectUrl) {
        appEventEmitter.publish(AppEventOnClickViewMore(redirectUrl));
        return;
      }
      applySearch(searchTerm);
    },
    [applySearch, searchTerm]
  );

  const searchSubmitActionPerformedRef = useRef(false);
  const searchSubmitAction = useCallback(() => {
    if (isEmpty(searchTerm)) {
      return;
    }
    if (searchSubmitActionPerformedRef.current === true) {
      return;
    }
    if (
      searchSuggestionIsLoading ||
      isRequestLoading(searchSuggestionRequestState)
    ) {
      showLoadingModal();
      searchSubmitActionPerformedRef.current = true;
      return;
    }
    const searchSuggestionError = getRequestStateError(
      searchSuggestionRequestState
    );
    if (searchSuggestionError) {
      return;
    }
    const searchSuggestion = getResources(searchSuggestionRequestState);
    if (searchSuggestion) {
      const { popularSearches } = searchSuggestion;
      handlePopularSearchOnSearchSubmit(popularSearches);
    }
    applySearch(searchTerm);
  }, [
    searchSuggestionIsLoading,
    searchSuggestionRequestState,
    showLoadingModal,
    handlePopularSearchOnSearchSubmit,
    applySearch,
    searchTerm,
  ]);

  useEffect(() => {
    if (searchSubmitActionPerformedRef.current === false) {
      return;
    }
    if (isRequestLoading(searchSuggestionRequestState)) {
      return;
    }
    hideLoadingModal();
    const searchSuggestionError = getRequestStateError(
      searchSuggestionRequestState
    );
    if (searchSuggestionError) {
      searchSubmitActionPerformedRef.current = false;
      return;
    }
    const searchSuggestion = getResources(searchSuggestionRequestState);
    if (searchSuggestion) {
      searchSubmitActionPerformedRef.current = false;
      const { popularSearches } = searchSuggestion;
      handlePopularSearchOnSearchSubmit(popularSearches);
      return;
    }
    applySearch(searchTerm);
  }, [
    searchSuggestionRequestState,
    searchTerm,
    hideLoadingModal,
    applySearch,
    handlePopularSearchOnSearchSubmit,
  ]);

  const onClickSearchSuggestion = useCallback(
    (searchSuggestion: SearchAutoSuggestion) => {
      if (searchSuggestion.redirectUrl == null) {
        applySearch(searchSuggestion.name);
      } else {
        appEventEmitter.publish(
          AppEventOnClickViewMore(searchSuggestion.redirectUrl)
        );
      }
    },
    [applySearch]
  );

  const onClickProductSearchesListItem = useCallback(
    (item: SearchSuggestionListItemItem) => {
      if (item.type === "product") {
        navigate(getPathForProductDetailPage(currentTab, item.product.sku));
      } else if (item.type === "total-count") {
        applySearch(searchTerm);
      }
    },
    [currentTab, navigate, applySearch, searchTerm]
  );

  const onClickFilteredListItem = useCallback(
    (item: SearchSuggestionListItemItem) => {
      if (item.type === "filtered-listing") {
        const { searchSuggestion } = item;
        if (searchSuggestion.url != null) {
          appEventEmitter.publish(
            AppEventOnClickViewMore(searchSuggestion.url)
          );
        } else if (searchSuggestion.redirectUrl != null) {
          appEventEmitter.publish(
            AppEventOnClickViewMore(searchSuggestion.redirectUrl)
          );
        } else {
          applySearch(searchSuggestion.name);
        }
      }
    },
    [applySearch]
  );

  const onClickBrandItem = useCallback((item: SearchSuggestionListItemItem) => {
    if (item.type === "filtered-listing") {
      appEventEmitter.publish(AppEventLinkToBrand(item.searchSuggestion.url));
    }
  }, []);

  const onSearchSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      searchSubmitAction();
    },
    [searchSubmitAction]
  );

  const onClickBackToStorefrontButton = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      history.replace(getPathForTab(currentTab));
    },
    [history, currentTab]
  );

  return (
    <>
      <NavBar
        toolbarClassName={styles.navBarToolBar}
        custom={
          <>
            <form action="/" onSubmit={onSearchSubmit}>
              <SearchInput
                value={searchTerm}
                onTextChange={handleSearchTermChange}
                onClearTextClick={onClearTextClick}
                ref={inputRef}
              />
            </form>
            <IonButtons slot="primary">
              <IonButton
                className={styles.navBarCancelButton}
                mode="ios"
                onClick={onClickCancelButton}
              >
                <div className={styles.navBarCancelButtonText}>
                  <LocalizedText messageID="page.search_suggestion.navbar.button.cancel" />
                </div>
              </IonButton>
            </IonButtons>
          </>
        }
      />
      <CLContent>
        <SearchSuggestion
          isLoading={searchSuggestionIsLoading}
          searchTerm={searchTerm}
          searchSuggestionRequestState={searchSuggestionRequestState}
          onClickSearchTerm={applySearch}
          onClickSearchSuggestion={onClickSearchSuggestion}
          onClickProductSearchesListItem={onClickProductSearchesListItem}
          onClickFilteredListItem={onClickFilteredListItem}
          onClickBrandItem={onClickBrandItem}
          onClickBackToStorefrontButton={onClickBackToStorefrontButton}
        />
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

export default withProviders(SearchSuggestionPage, LoadingModalProvider);
