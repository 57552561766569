import React, { useCallback, useContext, useMemo } from "react";

import HorizontalProductList from "../../HorizontalProductList";
import CMSBlog from "../CMSBlog";
import CMSCarouselView from "../CMSCarousel";
import { ResolvedCMSHtmlView } from "../CMSHtml";

import { LocalizedText } from "../../../i18n/Localization";
import {
  ResolvedCMSBlock,
  ResolvedCMSHorizontalProductList,
} from "../../../models/cmsBlock";
import { ProductOverview } from "../../../models/ProductOverview";
import { HorizontalProductListData } from "../../../models/horizontalProductList";

import ViewMoreButton from "./ViewMoreButton.lazy";
import CMSCategoryBricksBlock from "../CMSCategoryBricksBlock";
import { RootTab } from "../../../navigation/routes";
import { useOnClickViewMoreButton } from "../../../hook/viewMore";
import { useEffectOnce } from "../../../hook/useEffectOnce";
import { isEmpty } from "../../../utils/String";
import { addPerformanceRecord } from "../../../utils/PerformanceRecordStore";
import { ProfileSessionContext } from "../../../contexts/ProfileSessionContext";
import HorizontalPaginatedProductList from "../../HorizontalPaginatedProductList";
import RerenderLogger from "../../Performance/RerenderLogger";

import styles from "./styles.module.scss";

export interface Props {
  cmsBlocks: ResolvedCMSBlock[];
  currentTab: RootTab;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
  onClickProductBlock?: (productOverview: ProductOverview) => void;
  forceRerenderKey?: number;
}

const CMSBlockList: React.FC<Props> = React.memo((props: Props) => {
  const {
    cmsBlocks,
    onAddToCart,
    hrefForProduct,
    currentTab,
    onClickLikeButton,
    onClickProductBlock,
    forceRerenderKey,
  } = props;

  const profileSessionContext = useContext(ProfileSessionContext);

  const profileSession = useMemo(
    () =>
      profileSessionContext ? profileSessionContext.rootProfileSession : null,
    [profileSessionContext]
  );

  useEffectOnce(() => {
    if (profileSession)
      addPerformanceRecord(profileSession, "Contents (CMSBlockList) Shown");
  });

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, _actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount" && profileSession) {
        addPerformanceRecord(
          profileSession,
          "Contents (CMSBlockList) mounted",
          startTime
        );
      }
    },
    [profileSession]
  );

  return (
    <RerenderLogger id="CMSBlockList" onRender={handleRender}>
      {cmsBlocks.map((cmsBlock, i) => {
        const key = `${cmsBlock.type}-${i}${
          forceRerenderKey != null ? `-${forceRerenderKey}` : ""
        }`;
        switch (cmsBlock.type) {
          case "banner_rotator":
            return <CMSCarouselView key={key} cmsCarousel={cmsBlock} />;
          case "static_block":
            return (
              <ResolvedCMSHtmlView
                key={key}
                cmsStaticBlockContents={cmsBlock.items}
              />
            );
          case "top_sellers":
          case "products_recommendation":
          case "popular_products":
            if (cmsBlock.productOverviews.length <= 0) {
              return null;
            }
            if (cmsBlock.widgetTemplate === "hot_product_list_ajax") {
              return (
                <HotProductList
                  key={key}
                  resolvedCmsBlock={cmsBlock}
                  hrefForProduct={hrefForProduct}
                  onAddToCart={onAddToCart}
                  onClickLikeButton={onClickLikeButton}
                />
              );
            }
            return (
              <CMSHorizontalProductListView
                key={key}
                resolvedCmsBlock={cmsBlock}
                hrefForProduct={hrefForProduct}
                onAddToCart={onAddToCart}
                onClickLikeButton={onClickLikeButton}
                onClickProductBlock={onClickProductBlock}
              />
            );
          case "recent_blog":
            return (
              <CMSBlog
                key={key}
                cmsBlogBlock={cmsBlock}
                currentTab={currentTab}
              />
            );
          case "category_bricks":
            return (
              <CMSCategoryBricksBlock
                key={key}
                currentTab={currentTab}
                cmsBlock={cmsBlock}
              />
            );
          default:
            return null;
        }
      })}
    </RerenderLogger>
  );
});

export default CMSBlockList;

const CMSHorizontalProductListView: React.FC<{
  resolvedCmsBlock: ResolvedCMSHorizontalProductList;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
  onClickProductBlock?: (productOverview: ProductOverview) => void;
}> = React.memo(props => {
  const {
    resolvedCmsBlock,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
    onClickProductBlock,
  } = props;
  const productListData = useMemo<HorizontalProductListData>(() => {
    return HorizontalProductListData(
      resolvedCmsBlock.title || "",
      resolvedCmsBlock.productOverviews,
      resolvedCmsBlock.productLabelsByProductId,
      resolvedCmsBlock.backgroundImageUrl || "",
      null,
      resolvedCmsBlock.viewMoreUrl || "",
      resolvedCmsBlock.bundleByProductId
    );
  }, [resolvedCmsBlock]);

  const onClick = useOnClickViewMoreButton(resolvedCmsBlock.viewMoreUrl);

  return (
    <HorizontalProductList
      horizontalProductListData={productListData}
      onAddToCart={onAddToCart}
      hrefForProduct={hrefForProduct}
      detailButton={
        resolvedCmsBlock.viewMoreUrl != null &&
        !isEmpty(resolvedCmsBlock.viewMoreUrl) && (
          <ViewMoreButton onClick={onClick} />
        )
      }
      onClickLikeButton={onClickLikeButton}
      onClickProductBlock={onClickProductBlock}
    />
  );
});

const HotProductList: React.FC<{
  resolvedCmsBlock: ResolvedCMSHorizontalProductList;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
}> = React.memo(props => {
  const {
    resolvedCmsBlock,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
  } = props;
  const productListData = useMemo<HorizontalProductListData>(() => {
    return HorizontalProductListData(
      resolvedCmsBlock.title || "",
      resolvedCmsBlock.productOverviews,
      resolvedCmsBlock.productLabelsByProductId,
      resolvedCmsBlock.backgroundImageUrl || "",
      null,
      resolvedCmsBlock.viewMoreUrl || "",
      resolvedCmsBlock.bundleByProductId
    );
  }, [resolvedCmsBlock]);

  const onClick = useOnClickViewMoreButton(resolvedCmsBlock.viewMoreUrl);

  return (
    <div className={styles.horizontalPaginatedProductList}>
      <HorizontalPaginatedProductList
        horizontalProductListData={productListData}
        onAddToCart={onAddToCart}
        hrefForProduct={hrefForProduct}
        detailButton={
          resolvedCmsBlock.viewMoreUrl != null &&
          !isEmpty(resolvedCmsBlock.viewMoreUrl) && (
            <button
              onClick={onClick}
              className={styles.hotProductListViewMoreButton}
            >
              <LocalizedText messageID="hot_product_list.view_more" /> &gt;
            </button>
          )
        }
        onClickLikeButton={onClickLikeButton}
        itemPerPage={3}
      />
    </div>
  );
});
