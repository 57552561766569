import { useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { resolveUrl } from "../api/GraphQL";
import { EntityUrl } from "../models/EntityUrl";
import { useIntl } from "../i18n/Localization";

export function useResolveUrl(): (
  urlString: string
) => Promise<EntityUrl | null> {
  const client = useApolloClient();
  const { locale } = useIntl();
  return useCallback(
    async (urlString: string) => {
      return resolveUrl(client, locale, urlString);
    },
    [client, locale]
  );
}
