import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

type ModalAlign = "top" | "center" | "bottom";

function modalAlignToStyles(align: ModalAlign) {
  const mappings = {
    justifyContent: {
      top: "flex-start",
      center: "center",
      bottom: "flex-end",
    },
  };
  return {
    justifyContent: mappings.justifyContent[align],
  };
}

interface ModalContainerWithBackdropProps {
  className?: string;
  align: ModalAlign;
  padding?: string;
  onClickBackdrop?: () => void;
}

const ModalContainerWithBackdrop: React.FC<
  ModalContainerWithBackdropProps
> = props => {
  const { className, align, padding, onClickBackdrop, children } = props;
  return (
    <div className={styles.fullScreen} style={modalAlignToStyles(align)}>
      {/*
          backdropDismiss of IonModal not work in this modal because of the
          view hierarchy to achieve the design.
          So, I added a hack element to pretent that
          */}
      <div
        className={styles.backgroundTouchableDismiss}
        onClick={onClickBackdrop}
      />
      <div
        className={classnames(styles.modalContainer, className)}
        style={padding == null ? {} : { maxHeight: `calc(100% - ${padding})` }}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalContainerWithBackdrop;

export const BottomModalWithBackdrop: React.FC<
  Omit<ModalContainerWithBackdropProps, "align" | "padding">
> = props => {
  return (
    <ModalContainerWithBackdrop align="bottom" padding="85px" {...props} />
  );
};
