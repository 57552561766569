import React from "react";
import ReactDOM from "react-dom";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import * as Sentry from "sentry-cordova";
import "@fortawesome/fontawesome-free/css/all.css";
import "whatwg-fetch";
import "intl";
import "./utils/InteractionObserverPolyfill";
import * as serviceWorker from "./serviceWorker";
import { registerWebPlugin, Plugins } from "@capacitor/core";
import { FacebookLogin } from "@capacitor-community/facebook-login";

import "react-image-gallery/styles/css/image-gallery-no-icon.css";
import "./styles/globals.scss";

import App from "./App";
import { getInfo } from "./CLPlugins/DeviceInfo";

import * as Platform from "./utils/Platform";
import Config from "./Config";

const { Device } = Plugins;

registerWebPlugin(FacebookLogin as any);

// Load the SDK asynchronously
Device.getInfo().then(deviceInfo => {
  if (deviceInfo.platform === "web") {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: Config.FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v4.0",
      });
    };
    (function(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      (js as any).src = "https://connect.facebook.net/en_US/sdk.js";
      (fjs as any).parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
});

const knownUnhandledExceptions = [
  // https://sentry.io/organizations/hkt-limited/issues/1513969390/events/ea33df6bc3b349ed84b715ee10e8f317/?project=2455700
  "This account isn't confirmed. Verify and try again.",
  // https://sentry.io/organizations/hkt-limited/issues/1513969390/events/ef39bcd5fdfc43ad97b84eb317625dcb/?project=2455700
  "Current user does not have an active cart.",
  // https://sentry.io/organizations/hkt-limited/issues/1705124225/?project=2455700&query=is%3Aunresolved
  "The current user cannot perform operations on cart",
  // https://sentry.io/organizations/hkt-limited/issues/1513969390/events/56a68ea295e34aeb9b8afe17b696ee5c/?project=2455700
  "The account is locked.",
  // https://sentry.io/organizations/hkt-limited/issues/1513969390/events/1ccc44e078a3486c9456840276810388/?project=2455700
  "Error time slot value",

  // Network errors
  "The Internet connection appears to be offline",
  "The network connection was lost",
  "The request timed out",
  "Failed to fetch",
  "軟體導致連線失敗",
  "網路連線中斷",
  "連線已斷開",
  "要求逾時",
];

const knownUnhandledExceptionsRegex = new RegExp(
  knownUnhandledExceptions.map(e => `(.*)${e}(.*)`).join("|")
);

function isKnownUnhandledExceptions(error: Error | string): boolean {
  const message = typeof error === "string" ? error : error.message;
  if (message) {
    return !!knownUnhandledExceptionsRegex.exec(message);
  }
  return false;
}

async function initSentry() {
  window.Sentry = Sentry;
  const { appVersion } = await getInfo();
  Sentry.init({
    release: `${appVersion}`,
    dsn: Platform.select({
      ios: Config.SENTRY_DSN_IOS,
      android: Config.SENTRY_DSN_ANDROID,
      default: undefined,
    }),
    beforeSend: (event, hint) => {
      if (hint && hint.originalException) {
        const { originalException } = hint;
        if (isKnownUnhandledExceptions(originalException)) {
          console.warn("Known exception. Pending handle.", originalException);
          return null;
        }
      }
      if (Config.SHOW_ALERT_ON_SENTRY_ERROR) {
        alert("SHOW_ALERT_ON_SENTRY_ERROR: " + JSON.stringify(event.exception));
      }
      return event;
    },
    attachStacktrace: true,
  });

  // Catch syntax error
  const documentErrorScript = document.createElement("script");
  documentErrorScript.textContent = `window.onerror = function (e) {
  window.Sentry.withScope(function (scope) {
    scope.setLevel("warning");
    window.Sentry.captureException(e);
  });
}`;
  document.head.appendChild(documentErrorScript);
}

initSentry();

console.log("Run app with", Config);

ReactDOM.render(<App />, document.getElementById("root"));

defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Event capture for web view crash
function captureWebViewWebContentProcessDidTerminate() {
  try {
    if (Config.INFO_ENABLE_CAPTURE_WEB_VIEW_WEB_CONTENT_PROCESS_DID_TERMINATE) {
      window.Sentry.captureMessage("webViewWebContentProcessDidTerminate");
    }
  } catch {
    console.log("Cannot capture webViewWebContentProcessDidTerminate");
  }
}

window.captureWebViewWebContentProcessDidTerminate = captureWebViewWebContentProcessDidTerminate;
