import {
  InAppBrowser,
  InAppBrowserOptions,
  InAppBrowserEvent,
  InAppBrowserObject,
} from "@ionic-native/in-app-browser";

import Config from "../Config";
import { MessageID } from "../i18n/translations/type";

import { isAndroid } from "./Platform";

export enum OppPaymentError {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

const successResultUrlsRegex =
  Config.OPPPAYMENT_REDIRECT_SUCCESS_RESULT_URLS &&
  Config.OPPPAYMENT_REDIRECT_SUCCESS_RESULT_URLS.length > 0
    ? new RegExp(Config.OPPPAYMENT_REDIRECT_SUCCESS_RESULT_URLS.join("|"))
    : null;
const successUrlsRegex =
  Config.OPPPAYMENT_REDIRECT_SUCCESS_URLS &&
  Config.OPPPAYMENT_REDIRECT_SUCCESS_URLS.length > 0
    ? new RegExp(Config.OPPPAYMENT_REDIRECT_SUCCESS_URLS.join("|"))
    : null;
const failedUrlsRegex =
  Config.OPPPAYMENT_REDIRECT_FAILED_URLS &&
  Config.OPPPAYMENT_REDIRECT_FAILED_URLS.length > 0
    ? new RegExp(Config.OPPPAYMENT_REDIRECT_FAILED_URLS.join("|"))
    : null;
const cancelledUrlRegex =
  Config.OPPPAYMENT_REDIRECT_CANCELLED_URLS &&
  Config.OPPPAYMENT_REDIRECT_CANCELLED_URLS.length > 0
    ? new RegExp(Config.OPPPAYMENT_REDIRECT_CANCELLED_URLS.join("|"))
    : null;

function getInAppBrowserOptions(
  translate: (id: MessageID) => string
): InAppBrowserOptions {
  return {
    location: "no",
    clearcache: "yes",
    clearsessioncache: "yes",
    beforeload: isAndroid() ? "yes" : undefined,

    hidenavigationbuttons: "yes",
    closebuttoncaption: translate("cancel"),

    // Android
    footer: "yes",
    zoom: "no",
    hardwareback: "no",

    // iOS
    cleardata: "yes",
    toolbar: "yes",
  };
}

async function closeBrowser(browser: InAppBrowserObject) {
  // Stop onbeforeunload when closing browser programmatically
  await browser.executeScript({
    code: "window.onbeforeunload = undefined;",
  });
  browser.close();
}

export function presentOppPaymentBrowser(
  action: string,
  translate: (id: MessageID) => string
): Promise<null> {
  return new Promise((resolve, reject) => {
    let successResultShown = false;
    const browser = InAppBrowser.create(
      action,
      "_blank",
      getInAppBrowserOptions(translate)
    );

    const loadstart = browser.on("loadstart");
    if (loadstart) {
      const loadstartListener = loadstart.subscribe(
        async (event: InAppBrowserEvent) => {
          const eventCallback = encodeURI(event.url);
          if (
            successResultUrlsRegex &&
            successResultUrlsRegex.exec(eventCallback)
          ) {
            successResultShown = true;
          }
          if (successUrlsRegex && successUrlsRegex.exec(eventCallback)) {
            loadstartListener.unsubscribe();
            await closeBrowser(browser);
            resolve();
          }
          if (failedUrlsRegex && failedUrlsRegex.exec(eventCallback)) {
            loadstartListener.unsubscribe();
            await closeBrowser(browser);
            reject(new Error(OppPaymentError.FAILED));
          }
          if (cancelledUrlRegex && cancelledUrlRegex.exec(eventCallback)) {
            loadstartListener.unsubscribe();
            await closeBrowser(browser);
            reject(new Error(OppPaymentError.CANCELLED));
          }
        }
      );
    }

    if (isAndroid()) {
      const beforeload = browser.on("beforeload");
      if (beforeload) {
        const beforeloadListener = beforeload.subscribe(
          async (event: InAppBrowserEvent) => {
            const { url } = event;
            const eventCallback = encodeURI(url);
            if (
              successResultUrlsRegex &&
              successResultUrlsRegex.exec(eventCallback)
            ) {
              successResultShown = true;
            }
            if (successUrlsRegex && successUrlsRegex.exec(eventCallback)) {
              beforeloadListener.unsubscribe();
              await closeBrowser(browser);
              resolve();
              return;
            }
            if (failedUrlsRegex && failedUrlsRegex.exec(eventCallback)) {
              beforeloadListener.unsubscribe();
              await closeBrowser(browser);
              reject(new Error(OppPaymentError.FAILED));
              return;
            }
            if (cancelledUrlRegex && cancelledUrlRegex.exec(eventCallback)) {
              beforeloadListener.unsubscribe();
              await closeBrowser(browser);
              reject(new Error(OppPaymentError.CANCELLED));
              return;
            }
            browser._loadAfterBeforeload(url);
          }
        );
      }
    }

    const exit = browser.on("exit");
    if (exit) {
      const exitListener = exit.subscribe(() => {
        exitListener.unsubscribe();
        if (successResultShown) {
          resolve();
        } else {
          reject(new Error(OppPaymentError.CANCELLED));
        }
      });
    }
  });
}
