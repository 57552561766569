import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import cn from "classnames";
// eslint-disable-next-line no-restricted-imports
import { IonModal } from "@ionic/react";

import styles from "./styles.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { PrimaryButton } from "../Button";
import LocalizedInput from "../LocalizedInput";
import { validateEmail } from "../../utils/validation";
import { useUpdateCustomerEmailOnLogin } from "../../repository/AuthRepository";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

interface TheClubEmailModalProps {
  isModalOpen: boolean;
  initialEmail: string;
  onRequestDismiss: () => void;
  onEmailVerified: () => void;
}

const VerifyEmailModal: React.FC<TheClubEmailModalProps> = (
  props: TheClubEmailModalProps
) => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const {
    isModalOpen,
    initialEmail,
    onRequestDismiss,
    onEmailVerified,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmailValue] = useState("");

  useEffect(() => {
    setEmailValue(initialEmail);
  }, [initialEmail]);

  const isEmailValid = useMemo(() => validateEmail(email), [email]);
  const onEmailInputChange = useCallback(
    (value: string) => {
      setEmailValue(value);
    },
    [setEmailValue]
  );

  const updateCustomerEmailOnLogin = useUpdateCustomerEmailOnLogin();
  const onVerifyButtonClick = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await updateCustomerEmailOnLogin(email);
      if (!result.success) {
        presentLocalizedAlert({
          headerId: "modal.the_club_email.cannot_verify_email",
          messageId: result.reject_reason ? undefined : "alert.error.message",
          message: result.reject_reason ? result.reject_reason : undefined,
          buttons: [{ textMessageID: "try_again" }],
        });
        return;
      }
      onEmailVerified();
    } catch {
      presentLocalizedAlert({
        headerId: "modal.the_club_email.cannot_verify_email",
        buttons: [{ textMessageID: "try_again" }],
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    updateCustomerEmailOnLogin,
    email,
    setIsLoading,
    onEmailVerified,
    presentLocalizedAlert,
  ]);

  return (
    <IonModal
      className={styles.modal}
      isOpen={isModalOpen}
      onDidDismiss={onRequestDismiss}
      backdropDismiss={false}
    >
      <div className={styles.modalBody}>
        <div className={styles.title}>
          <LocalizedText messageID="modal.the_club_email.verify_your_email" />
        </div>
        <div>
          <div className={styles.emailTitle}>
            <LocalizedText messageID="email_address" />
          </div>
          <LocalizedInput
            className={cn(
              styles.input,
              email !== "" && !isEmailValid && styles.error
            )}
            type="email"
            placeholderId={"email"}
            value={email}
            onChange={onEmailInputChange}
          />
        </div>
        <PrimaryButton
          onClick={onVerifyButtonClick}
          className={styles.verifyButton}
          loading={isLoading}
          disabled={email === "" || !isEmailValid || isLoading}
        >
          <LocalizedText messageID="modal.the_club_email.verify" />
        </PrimaryButton>
      </div>
    </IonModal>
  );
};

export default VerifyEmailModal;
