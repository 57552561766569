import {
  InAppBrowser,
  InAppBrowserEvent,
  InAppBrowserOptions,
} from "@ionic-native/in-app-browser";
import { presentBrowser } from "../hook/useOpenUrlWithBrowser";
import { filterNullOrUndefined } from "./array";
import { executeHandleAnchorTargetBlank, Subscription } from "./InAppBrowser";
import { isAndroid } from "./Platform";
import { timeout } from "./promise";
import { IndexMap } from "./type";

export enum AsiaPayError {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
}

export type AsiaPayParameters = IndexMap<string, string>;

const options: InAppBrowserOptions = {
  location: "no",
  clearcache: "yes",
  clearsessioncache: "yes",
  beforeload: isAndroid() ? "yes" : undefined,

  // Android
  footer: "no",
  zoom: "no",
  hardwareback: "no",

  // iOS
  cleardata: "yes",
  toolbar: "no",
};

function getUrl(action: string, asiaPayParameters: AsiaPayParameters) {
  const inputFields = filterNullOrUndefined(
    Object.keys(asiaPayParameters).map(k => {
      const v = asiaPayParameters[k];
      return v ? { name: k, value: v } : null;
    })
  )
    .map(
      ({ name, value }) =>
        `<input type="hidden" name="${name}" value="${value}" />`
    )
    .join("");

  const content = `
<html>
<body>
<form method="post" action="${action}" id="form">
${inputFields}
</form>
<script>
  var form = document.getElementById('form');
  form.submit();
</script>
</body>
</html>
`;

  return `data:text/html;base64,${btoa(content)}`;
}

function getTargetUrl(urlString: string) {
  const url = new URL(urlString);
  const urlRedirectRaw = url.searchParams.get("urlRedirect");
  if (urlRedirectRaw) {
    // The string is double encoded
    // e.g. `https%253A%252F%252Ftest.thisismrchan.com%252Fasiapay%252FhtmlRedirect%252Fresponsecancel%252F%253FRef%253Dtest3000000084`
    return decodeURIComponent(decodeURI(urlRedirectRaw));
  }
  return urlString;
}

export function presentAsiaPayBrowser(
  action: string,
  asiaPayParameters: AsiaPayParameters
): Promise<void> {
  const { successUrl, cancelUrl, failUrl } = asiaPayParameters;
  return new Promise((resolve, reject) => {
    if (!successUrl || !cancelUrl || !failUrl) {
      reject(new Error(AsiaPayError.FAILED));
      return;
    }
    const browser = InAppBrowser.create(
      getUrl(action, asiaPayParameters),
      "_blank",
      options
    );

    const loadstart = browser.on("loadstart");
    let loadstartListener: Subscription | null = null;
    if (loadstart) {
      loadstartListener = loadstart.subscribe((event: InAppBrowserEvent) => {
        const eventCallback = encodeURI(event.url);
        if (eventCallback.indexOf(successUrl) > -1) {
          handleSuccess();
        } else if (eventCallback.indexOf(cancelUrl) > -1) {
          handleFailed(new Error(AsiaPayError.CANCELLED));
        } else if (eventCallback.indexOf(failUrl) > -1) {
          handleFailed(new Error(AsiaPayError.FAILED));
        }
      });
    }

    let beforeloadListener: Subscription | null = null;
    if (isAndroid()) {
      // Android will skip "asiapay/htmlRedirect/responsesuccess/" and other callback urls on loadstart which is key of closing browser.
      // Use before load to capture
      const beforeload = browser.on("beforeload");
      if (beforeload) {
        beforeloadListener = beforeload.subscribe(
          async (event: InAppBrowserEvent) => {
            const { url } = event;
            const targetUrl = getTargetUrl(url);
            if (targetUrl.indexOf(successUrl) > -1) {
              browser._loadAfterBeforeload(url);
              await timeout(1000);
              handleSuccess();
              return;
            } else if (targetUrl.indexOf(cancelUrl) > -1) {
              browser._loadAfterBeforeload(url);
              await timeout(1000);
              handleFailed(new Error(AsiaPayError.CANCELLED));
              return;
            } else if (targetUrl.indexOf(failUrl) > -1) {
              browser._loadAfterBeforeload(url);
              await timeout(1000);
              handleFailed(new Error(AsiaPayError.FAILED));
              return;
            }
            browser._loadAfterBeforeload(url);
          }
        );
      }
    }

    const exit = browser.on("exit");
    let exitListener: Subscription | null = null;
    if (exit) {
      exitListener = exit.subscribe(() => {
        handleFailed(new Error(AsiaPayError.CANCELLED));
      });
    }

    const unsubscribeHandleAnchorTargetBlank = executeHandleAnchorTargetBlank(
      url => {
        presentBrowser(url);
      },
      browser
    );

    const handleSuccess = () => {
      unsubscribeObservers();
      browser.close();
      resolve();
    };

    const handleFailed = (reason: Error) => {
      unsubscribeObservers();
      browser.close();
      reject(reason);
    };

    const unsubscribeObservers = () => {
      if (loadstartListener) loadstartListener.unsubscribe();
      if (beforeloadListener) beforeloadListener.unsubscribe();
      if (exitListener) exitListener.unsubscribe();
      unsubscribeHandleAnchorTargetBlank();
    };
  });
}
