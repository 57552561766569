import React, { useEffect, useCallback, useState } from "react";
import { IonLifeCycleContextInterface } from "@ionic/react";

import useContentScrollDirection, {
  ScrollDirection,
} from "../hook/contentScrollDirection";
import { useKeepUpdatingRef } from "../hook/utils";

export function useScrollToHideTabBar(
  contentRef: React.RefObject<HTMLIonContentElement>,
  ionLifeCycleContext: IonLifeCycleContextInterface
): boolean {
  const [isTabBarHidden, setIsTabBarHidden] = useState(false);
  const isTabBarHiddenRef = useKeepUpdatingRef(isTabBarHidden);
  const onScroll = useCallback(
    (direction: ScrollDirection) => {
      if (direction === "up") {
        if (isTabBarHiddenRef.current === true) {
          setIsTabBarHidden(false);
        }
      } else {
        if (isTabBarHiddenRef.current === false) {
          setIsTabBarHidden(true);
        }
      }
    },
    [isTabBarHiddenRef]
  );

  const onViewLeave = useCallback(() => {
    setIsTabBarHidden(false);
  }, []);

  useContentScrollDirection(
    contentRef,
    onScroll,
    onViewLeave,
    ionLifeCycleContext
  );

  useEffect(() => {
    const ionTabBar = document.querySelector("ion-tab-bar");
    if (!ionTabBar) {
      return undefined;
    }
    if (isTabBarHidden) {
      ionTabBar.classList.add("tab-bar-hide");
    } else {
      ionTabBar.classList.remove("tab-bar-hide");
    }
    return undefined;
  }, [isTabBarHidden]);

  return isTabBarHidden;
}

export default useScrollToHideTabBar;
