import React, { useMemo, useState } from "react";

interface Context {
  count: number;
  setCount: (newCount: number) => void;
}

export const ShoppingCartItemCountContext = React.createContext<Context>(
  null as any
);

export const ShoppingCartItemCountProvider: React.FC = props => {
  const [count, setCount] = useState(0);

  const contextValue = useMemo(
    () => ({
      count,
      setCount,
    }),
    [count]
  );

  return (
    <ShoppingCartItemCountContext.Provider value={contextValue}>
      {props.children}
    </ShoppingCartItemCountContext.Provider>
  );
};
