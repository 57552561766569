import { useCallback, useContext, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { Plugins } from "@capacitor/core";

import { useIntl } from "../i18n/Localization";
import {
  OS,
  Platform,
  Token,
  Isdn,
  PNSResponse,
  NotificationEnableState,
} from "../models/OPNSPushNotification";

import {
  registerDeviceToken,
  deleteDeviceToken,
  changeDeviceToken,
  messageMarkRead,
  parseGraphQLError,
  setOrderNotification,
  setPromotionNotification,
} from "../api/GraphQL";

import { CLError, RemoteError, LocalError } from "../utils/Error";

import { PushNotificationContext } from "../components/PushNotificationProvider";

const { AdobeExperiencePlatform: thePlugin } = Plugins;

export function useRegisterDeviceToken(): (
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
) => Promise<PNSResponse> {
  const client = useApolloClient();
  const { locale } = useIntl();
  return useCallback(
    async (os: OS, platform: Platform, token: Token, isdn: Isdn) => {
      console.info(
        `[ClubLike-OPNS] Attempt to registered device token ${token} for ${os}, ${platform}`
      );
      const response = await registerDeviceToken(
        client,
        locale,
        os,
        platform,
        token,
        isdn
      );
      console.info(
        `[ClubLike-OPNS] Registered device token ${token} for ${os}, ${platform}`
      );
      return response;
    },
    [client, locale]
  );
}

export function useRegisterDeviceTokenACS(): (token: Token) => Promise<void> {
  return useCallback(
    (token: string) => thePlugin.setPushIdentifier({ deviceToken: token }),
    []
  );
}

export function useDeleteDeviceToken(): (
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
) => Promise<PNSResponse> {
  const client = useApolloClient();
  const { locale } = useIntl();
  return useCallback(
    async (os: OS, platform: Platform, token: Token, isdn: Isdn) => {
      console.info(
        `[ClubLike-OPNS] Attempt to deleted device token ${token} for ${os}, ${platform}`
      );
      const response = await deleteDeviceToken(
        client,
        locale,
        os,
        platform,
        token,
        isdn
      );
      console.info(
        `[ClubLike-OPNS] Deleted device token ${token} for ${os}, ${platform}`
      );
      return response;
    },
    [client, locale]
  );
}

export function useDeleteDeviceTokenACS(): () => Promise<void> {
  return useCallback(
    () => thePlugin.setPushIdentifier({ deviceToken: null }),
    []
  );
}

export function useChangeDeviceToken(): (
  os: OS,
  platform: Platform,
  oldToken: Token,
  newToken: Token,
  isdn: Isdn
) => Promise<PNSResponse> {
  const client = useApolloClient();
  const { locale } = useIntl();
  return useCallback(
    async (
      os: OS,
      platform: Platform,
      oldToken: Token,
      newToken: Token,
      isdn: Isdn
    ) => {
      console.info(
        `[ClubLike-OPNS] Attemp to changed device token from ${oldToken} to ${newToken} for ${os}, ${platform}`
      );
      const response = await changeDeviceToken(
        client,
        locale,
        os,
        platform,
        oldToken,
        newToken,
        isdn
      );
      console.info(
        `[ClubLike-OPNS] Changed device token from ${oldToken} to ${newToken} for ${os}, ${platform}`
      );
      return response;
    },
    [client, locale]
  );
}

export function useChangeDeviceTokenACS(): (token: string) => Promise<void> {
  return useCallback(
    (token: string) => thePlugin.setPushIdentifier({ deviceToken: token }),
    []
  );
}

// TODO: make token not nullable #1971
export function useMessageMarkRead(
  token: Token | null,
  isdn: Isdn
): (messageId: string) => Promise<void> {
  const client = useApolloClient();
  const { locale } = useIntl();

  return useCallback(
    async (messageId: string) => {
      if (!token) {
        return;
      }
      await messageMarkRead(client, locale, token, isdn, messageId);
    },
    [client, locale, token, isdn]
  );
}

function useSetPromotionNotification(): (
  isEnabled: NotificationEnableState
) => Promise<PNSResponse> {
  const client = useApolloClient();
  const { locale } = useIntl();

  return useCallback(
    async (isEnabled: NotificationEnableState) => {
      return setPromotionNotification(client, locale, isEnabled);
    },
    [client, locale]
  );
}

export function useTogglePromotionNotification(): [
  NotificationEnableState,
  () => Promise<void>,
  boolean,
  CLError | null
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<CLError | null>(null);

  const {
    promotionNotification,
    setPromotionNotification: _setPromotionNotification,
  } = useContext(PushNotificationContext);

  const setRemotePromotionNotification = useSetPromotionNotification();

  const request = useCallback(async () => {
    const nextValue =
      promotionNotification === NotificationEnableState.Yes
        ? NotificationEnableState.No
        : NotificationEnableState.Yes;
    _setPromotionNotification(nextValue);
    setLoading(true);
    setError(null);
    try {
      await setRemotePromotionNotification(nextValue);
    } catch (e) {
      const errorMessage = parseGraphQLError(e);
      if (errorMessage) {
        setError(RemoteError(errorMessage));
      } else {
        setError(LocalError("error.unknown"));
      }
      _setPromotionNotification(promotionNotification);
    } finally {
      setLoading(false);
    }
  }, [
    promotionNotification,
    _setPromotionNotification,
    setRemotePromotionNotification,
  ]);

  return [promotionNotification, request, loading, error];
}

function useSetOrderNotification(): (
  isEnabled: NotificationEnableState
) => Promise<PNSResponse> {
  const client = useApolloClient();
  const { locale } = useIntl();

  return useCallback(
    async (isEnabled: NotificationEnableState) => {
      return setOrderNotification(client, locale, isEnabled);
    },
    [client, locale]
  );
}

export function useToggleOrderNotification(): [
  NotificationEnableState,
  () => Promise<void>,
  boolean,
  CLError | null
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<CLError | null>(null);

  const {
    orderNotification,
    setOrderNotification: _setOrderNotification,
  } = useContext(PushNotificationContext);

  const setRemoteOrderNotification = useSetOrderNotification();

  const request = useCallback(async () => {
    const nextValue =
      orderNotification === NotificationEnableState.Yes
        ? NotificationEnableState.No
        : NotificationEnableState.Yes;
    _setOrderNotification(nextValue);
    setLoading(true);
    setError(null);
    try {
      await setRemoteOrderNotification(nextValue);
    } catch (e) {
      const errorMessage = parseGraphQLError(e);
      if (errorMessage) {
        setError(RemoteError(errorMessage));
      } else {
        setError(LocalError("error.unknown"));
      }
      _setOrderNotification(orderNotification);
    } finally {
      setLoading(false);
    }
  }, [orderNotification, _setOrderNotification, setRemoteOrderNotification]);

  return [orderNotification, request, loading, error];
}
