import { useApolloClient } from "@apollo/client";
import { useIntl } from "../../i18n/Localization";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { fetchCampaignProduct, fetchNormalProduct } from "./api";
import { Product } from "./models";

export function useFetchNormalProduct(): [
  ResourcesRequestState<Product>,
  (sku: string) => Promise<Product>,
  (sku: string) => Promise<Product>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Product,
    (sku: string) => Promise<Product>
  >({
    remoteResourcesProvider: async (sku: string) => {
      const product = await fetchNormalProduct(
        client,
        sku,
        locale,
        "network-only"
      );
      return product;
    },
  });
  return [requestState, fetch, refresh];
}

export function useFetchCampaignProduct(): [
  ResourcesRequestState<Product>,
  (campaignId: number, sku: string) => Promise<Product>,
  (campaignId: number, sku: string) => Promise<Product>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Product,
    (campaignId: number, sku: string) => Promise<Product>
  >({
    remoteResourcesProvider: async (campaignId: number, sku: string) => {
      const product = await fetchCampaignProduct(
        client,
        campaignId,
        sku,
        locale,
        "network-only"
      );
      return product;
    },
  });
  return [requestState, fetch, refresh];
}
