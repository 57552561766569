import { IonIcon } from "@ionic/react";
import React from "react";
import { LocalizedText } from "../../i18n/Localization";
import { PrimaryButton } from "../Button";
import styles from "./styles.module.scss";

interface Props {
  disabled: boolean;
  onClick: () => any;
}

const PreOrderButton: React.FC<Props> = props => {
  return (
    <PrimaryButton
      onClick={props.onClick}
      disabled={props.disabled}
      className={styles.preOrderButton}
    >
      <IonIcon name="plus" className={styles.preOrderIcon} />
      <span className={styles.preOrderButtonText}>
        <LocalizedText messageID="product.pre_order" />
      </span>
    </PrimaryButton>
  );
};

export default PreOrderButton;
