import { useCallback, useEffect, useRef, useState } from "react";

export function useSticky(
  scrollEl: HTMLElement | null,
  stickyEl: HTMLDivElement | null
) {
  const holder = useRef<HTMLDivElement | null>(null);

  const height = stickyEl ? stickyEl.getBoundingClientRect().height : 0;

  useEffect(() => {
    if (stickyEl && stickyEl.parentNode) {
      holder.current = document.createElement("div");
      holder.current.style.height = `${height}px`;
      stickyEl.parentNode.insertBefore(holder.current, stickyEl.nextSibling);
      stickyEl.style.marginBottom = `-${height}px`;
      return () => {
        if (holder.current && holder.current.parentNode) {
          holder.current.parentNode.removeChild(holder.current);
        }
        holder.current = null;
        stickyEl.style.marginBottom = null as any;
      };
    }
    return undefined;
  }, [stickyEl, height]);

  const [isSticky, setIsSticky] = useState<boolean>(false);

  const handleScroll = useCallback(
    (e: Event) => {
      if (e.currentTarget && stickyEl && holder.current) {
        const _scrollEl = e.currentTarget as HTMLElement;

        // The scroll element will be assigned a
        // `transform` style which will make its
        // fixed children behaves like absolute
        // (following scrolling of the scroll element)
        _scrollEl.style.transform = null as any;

        const { scrollTop } = _scrollEl;
        const { offsetTop } = holder.current;
        if (scrollTop >= offsetTop) {
          // Try with `sticky` first to get the latest browser support
          // iOS
          stickyEl.style.position = "-webkit-sticky";
          stickyEl.style.top = "0px";
          stickyEl.style.left = "0px";
          stickyEl.style.width = "100%";
          stickyEl.style.zIndex = "10";

          const computedStyle = getComputedStyle(stickyEl);
          if (computedStyle["position"] !== "-webkit-sticky") {
            // Standard Sticky
            stickyEl.style.position = "sticky";

            // Fall back to use `fixed` if the browser does not support `sticky`
            const _computedStyle = getComputedStyle(stickyEl);
            if (_computedStyle["position"] !== "sticky") {
              const { top } = _scrollEl.getBoundingClientRect();
              stickyEl.style.position = "fixed";
              stickyEl.style.top = `${top}px`;
            }
          }

          setIsSticky(true);
        } else {
          stickyEl.style.position = null as any;
          stickyEl.style.top = null as any;
          stickyEl.style.left = null as any;
          stickyEl.style.width = null as any;
          stickyEl.style.zIndex = null as any;
          setIsSticky(false);
        }
      }
    },
    [stickyEl]
  );

  useEffect(() => {
    if (scrollEl) {
      scrollEl.addEventListener("scroll", handleScroll);
      return () => {
        scrollEl.removeEventListener("scroll", handleScroll);
      };
    }
    return undefined;
  }, [scrollEl, handleScroll]);

  return isSticky;
}
