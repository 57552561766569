import React, { useMemo, useState, useCallback } from "react";
import { IonSlides, IonSlide, IonImg } from "@ionic/react";

import Product360Badge from "../Product360Badge";
import ProductLabels from "../ProductLabel";

import { MediaContent, sortMediaContents } from "../../models/Media";
import {
  constructMediaUrlForProduct,
  Product360Image,
} from "../../models/product";
import { ProductLabel } from "../../models/ProductLabel";
import { StoreConfig } from "../../models/StoreConfig";

import styles from "./productImageCarouselStyles.module.scss";
import { useImageStyle } from "../../utils/imageStyle";
import { ProductData, getProductSKU, getProductImageUrl } from "./type";

import placeholderImg from "../../resources/img-product-default.png";

interface Magic360Content {
  type: "360";
  image: Product360Image;
}

interface Props {
  storeConfig: StoreConfig;
  productData: ProductData;
  productLabels?: ProductLabel[];
  onClickImage: () => any;
}

function is360Content(
  content: Magic360Content | MediaContent
): content is Magic360Content {
  return (content as any).type === "360";
}

const ProductImageCarousel: React.FC<Props> = React.memo(props => {
  const { productData, storeConfig, onClickImage, productLabels } = props;

  const sku = getProductSKU(productData);
  const productImageUrl = getProductImageUrl(productData);
  const contents = useMemo<(Magic360Content | MediaContent)[] | null>(() => {
    if (productData.type !== "full-product") {
      return null;
    }
    const {
      product: { magic360Images, mediaContents, image },
    } = productData;
    const contents_: (Magic360Content | MediaContent)[] = [];
    if (magic360Images.length > 0) {
      contents_.push({
        type: "360",
        image: magic360Images[0],
      });
    }

    contents_.push(
      ...sortMediaContents(
        mediaContents
          .filter(m => !m.disabled)
          .map(m => ({
            ...m,
            filePath: constructMediaUrlForProduct(storeConfig, m),
          }))
      )
    );

    if (contents_.length) {
      return contents_;
    }

    if (image) {
      return [
        {
          mediaType: "image",
          id: 0,
          label: image.label,
          position: 0,
          filePath: image.url,
          disabled: false,
          videoContent: null,
        },
      ];
    }

    return [
      {
        mediaType: "image",
        id: 0,
        label: "",
        position: 0,
        filePath: placeholderImg,
        disabled: false,
        videoContent: null,
      },
    ];
  }, [productData, storeConfig]);

  const ionSlidesKey = useMemo(() => {
    return contents
      ? contents.map(c => (is360Content(c) ? c.image.id : c.id)).join("-")
      : undefined;
  }, [contents]);

  const withoutMediaContentsStyles = useImageStyle(
    placeholderImg,
    productImageUrl
  );

  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);

  const handleImageDidLoad = useCallback(() => {
    setTimeout(() => {
      setIsFirstImageLoaded(true);
    }, 100);
  }, []);

  const ionSlidesOptions = useMemo(
    () => ({
      zoom: false,
    }),
    []
  );

  return (
    <div key={sku} className={styles.slidesContainer}>
      {contents != null && contents.length > 0 && (
        <IonSlides
          mode="ios"
          pager={true}
          options={ionSlidesOptions}
          key={ionSlidesKey}
        >
          {contents.map((content, i) => (
            <ImageSlide
              key={
                is360Content(content)
                  ? `360-image-${content.image.url}`
                  : content.filePath
              }
              content={content}
              onClickImage={onClickImage}
              productLabels={i === 0 ? productLabels : undefined}
              onIonImgDidLoad={i === 0 ? handleImageDidLoad : undefined}
            />
          ))}
        </IonSlides>
      )}
      {!isFirstImageLoaded && (
        <div
          className={styles.productImagePlaceholder}
          style={withoutMediaContentsStyles}
        ></div>
      )}
    </div>
  );
});

interface ImageSlideProps {
  content: MediaContent | Magic360Content;
  productLabels?: ProductLabel[];
  onClickImage: () => any;
  onIonImgDidLoad?: () => void;
}
const ImageSlide: React.FC<ImageSlideProps> = props => {
  const { content, onClickImage, productLabels, onIonImgDidLoad } = props;

  const [isImageLoadError, setIsImageLoadError] = useState(false);

  const imgSrc = useMemo(
    () =>
      isImageLoadError
        ? placeholderImg
        : is360Content(content)
        ? content.image.url
        : content.filePath,
    [isImageLoadError, content]
  );

  const handleImageDidLoad = useCallback(() => {
    if (onIonImgDidLoad) {
      onIonImgDidLoad();
    }
  }, [onIonImgDidLoad]);

  const handleError = useCallback(() => {
    setIsImageLoadError(true);
  }, []);

  return (
    <IonSlide>
      <div className={styles.productImageContainer} onClick={onClickImage}>
        <IonImg
          className={styles.productImage}
          alt={is360Content(content) ? "360" : content.label}
          src={imgSrc}
          onIonImgDidLoad={handleImageDidLoad}
          onIonError={handleError}
        />
        {is360Content(content) && (
          <Product360Badge className={styles.product360Badge} />
        )}
        {productLabels && productLabels.length >= 1 && (
          <ProductLabels
            productLabels={productLabels}
            productLabelMode="product"
          />
        )}
      </div>
    </IonSlide>
  );
};

export default ProductImageCarousel;
