import { useEffect, useState } from "react";
import { Globalization } from "@ionic-native/globalization";

import { getDisplayLanguage } from "../storage";

import Config from "../Config";

export enum Locale {
  en = "en",
  zhHant = "zh-hant",
}

export type DataByLocale<T> = { [key in Locale]: T };

export async function getLocale(): Promise<Locale> {
  const locale = await getDisplayLanguage();
  if (locale != null) {
    return locale;
  }
  const preferredLanguage = await Globalization.getPreferredLanguage()
    .then(x => x.value)
    .catch(_ => navigator.language)
    .then(x => x.toLowerCase());
  if (preferredLanguage.startsWith("zh-")) {
    return Locale.zhHant;
  }
  return Locale.en;
}

export function getStoreViewCodeForLocale(locale: Locale): string {
  switch (locale) {
    case Locale.en:
      return Config.STORE_VIEW_CODE.EN_US;
    case Locale.zhHant:
      return Config.STORE_VIEW_CODE.ZH_HANT_HK;
    default:
      return Config.STORE_VIEW_CODE.ZH_HANT_HK;
  }
}

export function useLocale(): Locale | null {
  const [locale, setLocale] = useState<Locale | null>(null);

  useEffect(() => {
    getLocale().then(_locale => {
      setLocale(_locale);
    });
  }, []);

  return locale;
}

export function stringToLocale(value: string): Locale | null {
  switch (value) {
    case Locale.en:
      return Locale.en;
    case Locale.zhHant:
      return Locale.zhHant;
    default:
      return null;
  }
}

export function getLocaleDisplayText(locale: Locale): string {
  switch (locale) {
    case Locale.en:
      return "English";
    case Locale.zhHant:
      return "中文（繁體）";
    default:
      return "";
  }
}
