import { useEffect, useCallback, useRef, useMemo } from "react";
import { IonLifeCycleContextInterface } from "@ionic/react";
import { useKeepUpdatingRef } from "./utils";

export default function<T>(
  content: T | null,
  ionLifeCycleContext: IonLifeCycleContextInterface,
  viewDidEnterFn: () => void
) {
  const hasContent = useMemo(() => !!content, [content]);
  const viewDidEnterFnRef = useKeepUpdatingRef(viewDidEnterFn);

  const didEnter = useRef(false);

  // View enter but content not available will try to run fn
  // after content avaialble
  useEffect(() => {
    if (hasContent && !didEnter.current) {
      viewDidEnterFnRef.current();
      didEnter.current = true;
    }
  }, [hasContent, viewDidEnterFnRef]);

  // View enter and content available
  const viewEnter = useCallback(() => {
    if (hasContent && !didEnter.current) {
      viewDidEnterFnRef.current();
      didEnter.current = true;
    }
  }, [hasContent, viewDidEnterFnRef]);

  const viewLeave = useCallback(() => {
    didEnter.current = false;
  }, []);

  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  ionLifeCycleContext.onIonViewWillLeave(viewLeave);
}
