import React, { useCallback } from "react";

import styles from "./Pager.module.scss";

interface Props {
  currentPage: number;
  totalPages: number;
  onNextClick: () => void;
  onPrevClick: () => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
}

const Pager: React.FC<Props> = props => {
  const {
    currentPage,
    totalPages,
    onNextClick,
    onPrevClick,
    nextDisabled,
    prevDisabled,
  } = props;

  const handleNextClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onNextClick();
    },
    [onNextClick]
  );

  const handlePrevClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onPrevClick();
    },
    [onPrevClick]
  );

  return (
    <div className={styles.pager}>
      <div className={styles.prevButtonContainer}>
        <button
          className={styles.prevButton}
          disabled={prevDisabled}
          onClick={handlePrevClick}
        >
          &lt;
        </button>
      </div>
      <span className={styles.page}>
        {currentPage}/{totalPages}
      </span>
      <div className={styles.nextButtonContainer}>
        <button
          className={styles.nextButton}
          disabled={nextDisabled}
          onClick={handleNextClick}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Pager;
