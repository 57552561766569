import React from "react";
import { Context as MFContext, Values } from "@oursky/react-messageformat";

import { Locale } from "./locale";
import { MessageID } from "./translations/type";

import DeferredComponent from "../utils/DeferredComponent";

export type MessageArgs = Values;

interface LocaleContext {
  locale: Locale | null;

  // return true if locale did change
  changeLocaleWithLoadingAndDestroyHistory: (
    locale: Locale
  ) => Promise<boolean>;
}

export const LocaleContext = React.createContext<LocaleContext>(null as any);

export const LocalizationProvider = DeferredComponent(() =>
  import("./Provider")
);
export const LocalizedText = DeferredComponent(() => import("./LocalizedText"));
export const LocalizedDateTime = DeferredComponent(() =>
  import("./LocalizedDateTime")
);

export type TranslateFn = (
  messageID: MessageID,
  messageArgs?: MessageArgs
) => string;

interface Intl {
  locale: Locale;
  translate: TranslateFn;
}

export function useIntl(): Intl {
  const { renderToString } = React.useContext(MFContext);
  const { locale } = React.useContext(LocaleContext);

  return {
    locale: locale || Locale.en,
    translate: React.useCallback(
      (messageID: MessageID, messageArgs?: MessageArgs) => {
        return renderToString(messageID, messageArgs);
      },
      [renderToString]
    ),
  };
}
