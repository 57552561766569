import React, {
  useMemo,
  useCallback,
  useRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { RouteComponentProps } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import QRCode from "qrcode";

import {
  getPathForOrderDetailsQRCodesRedemptionPage,
  useCurrentTab,
  getPathForClubProtectClaimApplication,
} from "../../navigation/routes";
import { OurNavContext, PresentationContext } from "../../our-navigation";
import { TokenStore } from "../../api/TokenStore";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import PurhcasedItemList from "./PurchasedItemList";
import LuckyDrawItemList from "./LuckyDrawItemList";
import OrderDetails from "./OrderDetails";
import PaymentInfo, { PaymentInfoExtraItem } from "./PaymentInfo";
import { FullContentLoadingView } from "../LoadingView";
import { FullContentErrorView } from "../ErrorView";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { AddProductReviewModalContext } from "../AddProductReviewModalProvider";

import { useOrderResource } from "./api";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { isAndroid } from "../../utils/Platform";

import { isLuckyDrawItem, PartialOrder, PartialOrderItem } from "./model";
import {
  getResources,
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import {
  SimpleViewStateError,
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateLoading,
  SimpleViewStateInitial,
} from "../../models/SimpleViewState";
import { OrderStatus, ShipmentStatus } from "../../models/Order";
import { LuckyDraw } from "../../models/LuckyDraw";
import useOpenUrlWithBrowser from "../../hook/useOpenUrlWithBrowser";
import { usePresentAddToCartModalWithOrderItem } from "../../hook/usePresentAddToCartModal";

import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import ShipmentStatusModal from "./ShipmentStatusModal.lazy";
import InsuranceModal from "./InsuranceModal.lazy";
import PurchasedItemGroupList from "./PurchasedItemGroupList";

import Config from "../../Config";

import styles from "./styles.module.scss";

function viewEnter() {
  pageView({ page: "Order Details" });
}

type Props = RouteComponentProps<
  { id: string },
  {},
  { incrementID: string; date: Date }
>;

const OrderDetailPage: React.FC<Props> = props => {
  const { match, location } = props;
  const orderID = match.params.id;
  const currentTab = useCurrentTab();

  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const { isOnline } = useContext(NetworkStatusContext);
  const { navigate } = useContext(OurNavContext);
  const { present } = useContext(PresentationContext);

  const openUrlWithBrowser = useOpenUrlWithBrowser();

  const presentAddToCartModalWithOrderItem = usePresentAddToCartModalWithOrderItem();
  const { show: showAddProductReviewModal } = useContext(
    AddProductReviewModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const { translate } = useIntl();
  const [orderState, fetchOrder, refreshOrder] = useOrderResource();
  useEffect(() => {
    fetchOrder(orderID).catch(() => {});
  }, [orderID, fetchOrder]);
  const order = getResources(orderState);
  const retry = useCallback(() => {
    fetchOrder(orderID).catch(() => {});
  }, [orderID, fetchOrder]);
  const refresh = useCallback(() => {
    refreshOrder(orderID).catch(() => {});
  }, [orderID, refreshOrder]);

  const onClickReorder = useCallback(
    (item: PartialOrderItem) => {
      actionEvent("Purchased Items", "Click", "Reorder");
      presentAddToCartModalWithOrderItem(item);
    },
    [presentAddToCartModalWithOrderItem]
  );

  const [shipmentStatusModalOpened, setShipmentStatusModalOpened] = useState(
    false
  );
  const [shipmentStatusModalData, setShipmentStatusModalData] = useState<{
    shipmentStatus: ShipmentStatus;
  } | null>(null);
  const handleViewDeliveryClick = useCallback(
    (shipmentStatus: ShipmentStatus) => {
      setShipmentStatusModalOpened(true);
      setShipmentStatusModalData({ shipmentStatus });
    },
    []
  );
  const onClickReview = useCallback(
    (item: PartialOrderItem) => {
      showAddProductReviewModal(item.productID);
    },
    [showAddProductReviewModal]
  );
  const onShipmentStatusModalRequestDismiss = useCallback(() => {
    setShipmentStatusModalOpened(false);
  }, []);

  const onClickRedeem = useCallback(
    (item: PartialOrderItem) => {
      navigate(
        getPathForOrderDetailsQRCodesRedemptionPage(
          currentTab,
          orderID,
          item.productID
        )
      );
    },
    [navigate, currentTab, orderID]
  );

  const viewState = useMemo<SimpleViewState<PartialOrder>>(() => {
    if (isRequestLoading(orderState)) {
      return SimpleViewStateLoading;
    }
    const error = getRequestStateError(orderState);
    if (error) {
      return SimpleViewStateError(
        typeof error === "string" ? error : translate("error.unknown")
      );
    }
    const _order = getResources(orderState);
    if (_order) {
      return SimpleViewStateDisplay(_order);
    }
    return SimpleViewStateInitial;
  }, [orderState, translate]);

  const generalInfo = useMemo<{ id: string; date: string } | null>(() => {
    if (
      viewState.type === "initial" ||
      viewState.type === "loading" ||
      viewState.type === "error"
    ) {
      if (location.state && location.state.incrementID && location.state.date) {
        return {
          id: location.state.incrementID,
          date: moment
            .utc(location.state.date)
            .utcOffset(8)
            .format("DD-MM-YYYY"),
        };
      }
      return null;
    }
    return {
      id: viewState.data.incrementID,
      date: moment
        .utc(viewState.data.createdAt)
        .utcOffset(8)
        .format("DD-MM-YYYY"),
    };
  }, [viewState, location.state]);

  // Refresh
  const [refreshEventDetail, setRefreshEventDetail] = useState<CustomEvent<
    RefresherEventDetail
  > | null>(null);
  const onRefresh = useCallback(
    (e: CustomEvent<RefresherEventDetail>) => {
      refresh();
      setRefreshEventDetail(e);
    },
    [refresh]
  );
  useEffect(() => {
    if (refreshEventDetail && !isRequestLoading(orderState)) {
      refreshEventDetail.detail.complete();
      setRefreshEventDetail(null);
    }
  }, [orderState, refreshEventDetail]);

  const dismissAndOpenLinkInExternalBrowser = useCallback((url: string) => {
    window.open(url, "_system");
  }, []);

  const handleQRCodeLinkClicked = useCallback(
    (url: string) => {
      presentLocalizedAlert({
        headerId: "open_link_in_external_browser.header",
        messageId: "open_link_in_external_browser.message",
        messageArgs: {
          url,
        },
        buttons: [
          {
            textMessageID: "alert.button.ok",
            handler: () => dismissAndOpenLinkInExternalBrowser(url),
          },
          {
            textMessageID: "cancel",
          },
        ],
      });
    },
    [presentLocalizedAlert, dismissAndOpenLinkInExternalBrowser]
  );

  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
  const [
    insuranceModalItem,
    setInsuranceModalItem,
  ] = useState<PartialOrderItem | null>(null);
  const handleClickViewInsurance = useCallback((item: PartialOrderItem) => {
    setIsInsuranceModalOpen(true);
    setInsuranceModalItem(item);
  }, []);
  const handleDismissInsuranceModal = useCallback(() => {
    setIsInsuranceModalOpen(false);
  }, []);

  const handleClaimApplicationClick = useCallback(() => {
    setIsInsuranceModalOpen(false);
    if (insuranceModalItem) {
      present(getPathForClubProtectClaimApplication());
    }
  }, [present, insuranceModalItem]);

  const handleRedemptionLetterUrlClick = useCallback(
    (url: string) => {
      const docUrl = `${url}?token=${TokenStore.accessToken}`;
      // Prevent direct downloading the file without any visual notification on android
      const urlToOpen = isAndroid()
        ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURI(
            docUrl
          )}`
        : docUrl;
      openUrlWithBrowser(urlToOpen);
    },
    [openUrlWithBrowser]
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="order_detail.title" />}
      />
      <CLContent ref={contentRef} className={styles.content}>
        {isOnline ? (
          <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
            <IonRefresherContent />
          </IonRefresher>
        ) : null}
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "display"}
          onRetry={retry}
        >
          <div
            className={classnames({
              [styles.loadingContent]: order == null,
            })}
          >
            {generalInfo ? (
              <GeneralInfo
                orderID={generalInfo.id}
                date={generalInfo.date}
                orderStatus={
                  viewState.type === "display"
                    ? viewState.data.orderStatus
                    : null
                }
              />
            ) : null}
            {viewState.type === "initial" || viewState.type === "loading" ? (
              <FullContentLoadingView />
            ) : viewState.type === "display" ? (
              <OrderDetail
                order={viewState.data}
                onClickReorder={onClickReorder}
                onClickViewDelivery={handleViewDeliveryClick}
                onClickReview={onClickReview}
                onClickRedeem={onClickRedeem}
                onQRCodeLinkClicked={handleQRCodeLinkClicked}
                onClickViewInsurance={handleClickViewInsurance}
                onClickRedemptionLetterUrl={handleRedemptionLetterUrlClick}
              />
            ) : viewState.type === "error" ? (
              <FullContentErrorView
                errorMessage={viewState.error}
                onClickRetry={retry}
              />
            ) : null}
          </div>
          <TabBarSpacePlaceholder />
        </NoInternetConnectionView>
      </CLContent>
      <ShipmentStatusModal
        isOpen={shipmentStatusModalOpened}
        shipmentStatusModalData={shipmentStatusModalData}
        onRequestDismiss={onShipmentStatusModalRequestDismiss}
      />
      <InsuranceModal
        isOpen={isInsuranceModalOpen}
        onRequestDismiss={handleDismissInsuranceModal}
        item={insuranceModalItem}
        onClaimApplicationClick={handleClaimApplicationClick}
      />
    </>
  );
};

interface OrderDetailProps {
  order: PartialOrder;
  onClickReorder: (item: PartialOrderItem) => void;
  onClickViewDelivery: (ShipmentStatus: ShipmentStatus) => void;
  onClickReview: (item: PartialOrderItem) => void;
  onClickRedeem: (item: PartialOrderItem) => void;
  onQRCodeLinkClicked: (url: string) => void;
  onClickViewInsurance: (item: PartialOrderItem) => void;
  onClickRedemptionLetterUrl: (url: string) => void;
}

const OrderDetail: React.FC<OrderDetailProps> = props => {
  const {
    order,
    onClickReorder,
    onClickViewDelivery,
    onClickReview,
    onClickRedeem,
    onQRCodeLinkClicked,
    onClickViewInsurance,
    onClickRedemptionLetterUrl,
  } = props;
  const { translate } = useIntl();

  const [luckyDrawItems, nonLuckyDrawItems] = useMemo<
    [LuckyDraw<PartialOrderItem>[], PartialOrderItem[]]
  >(() => {
    const _luckyDrawItems: LuckyDraw<PartialOrderItem>[] = [];
    const _nonLuckyDrawItems: PartialOrderItem[] = [];
    for (const item of order.items) {
      if (isLuckyDrawItem(item)) {
        _luckyDrawItems.push(item);
      } else {
        _nonLuckyDrawItems.push(item);
      }
    }
    return [_luckyDrawItems, _nonLuckyDrawItems];
  }, [order]);

  const shippingFee = useMemo(
    () => ({ currency: order.currencyCode, value: order.shippingAmount }),
    [order.currencyCode, order.shippingAmount]
  );
  const discount = useMemo(
    () => ({ currency: order.currencyCode, value: order.discountAmount }),
    [order.currencyCode, order.discountAmount]
  );
  const subtotal = useMemo(
    () => ({ currency: order.currencyCode, value: order.subtotal }),
    [order.currencyCode, order.subtotal]
  );
  const total = useMemo(
    () => ({ currency: order.currencyCode, value: order.grandTotal }),
    [order.currencyCode, order.grandTotal]
  );
  const clubpointEarned = useMemo(() => {
    if (order.clubMemberId) {
      return order.clubpointEarned;
    }
    return null;
  }, [order]);
  const initialSubscriptionFee = useMemo(
    () => ({
      currency: order.currencyCode,
      value: order.initialSubscriptionFee || 0,
    }),
    [order]
  );
  const clubPointsRequired = useMemo(() => {
    const { items: _items } = order;
    let res = 0;
    for (const item of _items) {
      res += item.requiredClubpoints * item.qtyOrdered;
    }
    return res;
  }, [order]);
  const extraClubpointsUsed = useMemo(
    () => order.clubpointUsed - clubPointsRequired,
    [order, clubPointsRequired]
  );

  const paymentInfoExtraItems: PaymentInfoExtraItem[] = useMemo(() => {
    const extraItems: PaymentInfoExtraItem[] = [];
    if (discount.value) {
      extraItems.push({
        titleType: "title",
        title: `${translate("order_detail.payment_info.discount")}`,
        type: "-",
        value: discount,
      });
    }
    extraItems.push({
      titleType: "titleID",
      titleID: "order_detail.payment_info.shipping",
      type: "+",
      value: shippingFee,
    });
    if (initialSubscriptionFee.value) {
      extraItems.push({
        titleType: "titleID",
        titleID: "order_detail.payment_info.initial_subscription_fee",
        type: "+",
        value: initialSubscriptionFee,
      });
    }
    return extraItems;
  }, [discount, shippingFee, initialSubscriptionFee, translate]);

  const [fulfillQRCode, setFulfillQRCode] = useState<string | null>(null);

  useEffect(() => {
    if (!order.fulfillment || !order.fulfillment.fulfillCode) {
      return;
    }
    QRCode.toDataURL(
      order.fulfillment.fulfillCode,
      {
        margin: 1,
        scale: 16,
      },
      (error, url) => {
        if (error) {
          console.error(error);
          return;
        }
        if (url) {
          setFulfillQRCode(url);
        }
      }
    );
    return () => {
      setFulfillQRCode(null);
    };
  }, [order]);

  const shipmentStatusList = useMemo(() => {
    const res: ShipmentStatus[] = [];
    if (order.shipmentStatusList != null) {
      for (const shipmentStatus of order.shipmentStatusList) {
        if (shipmentStatus) {
          res.push(shipmentStatus);
        }
      }
    }
    return res;
  }, [order]);

  const handleViewDeliveryClick = useCallback(
    (shipmentStatus: ShipmentStatus) => {
      onClickViewDelivery(shipmentStatus);
    },
    [onClickViewDelivery]
  );

  return (
    <div className={styles.orderDetailContainer}>
      <div className={styles.orderDetailSectionTitle}>
        <LocalizedText messageID="order_detail.purchased_items" />
      </div>
      <div className={styles.orderDetailRow}>
        <div className={styles.orderDetailItemCount}>
          <LocalizedText
            messageID="order_detail.purchased_items.count"
            messageArgs={{ count: order.items.length }}
          />
        </div>
      </div>
      {Config.ENABLE_PRE_ORDER_AND_EDD ? (
        <PurchasedItemGroupList
          currencyCode={order.currencyCode}
          estimatedDeliveryDate={order.estimatedDeliveryDate}
          items={nonLuckyDrawItems}
          onClickReorder={onClickReorder}
          onClickViewDelivery={handleViewDeliveryClick}
          onClickReview={onClickReview}
          onClickRedeem={onClickRedeem}
          shipmentStatusList={shipmentStatusList}
          onQRCodeLinkClicked={onQRCodeLinkClicked}
          onClickViewInsurance={onClickViewInsurance}
          onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
        />
      ) : (
        <PurhcasedItemList
          currencyCode={order.currencyCode}
          items={nonLuckyDrawItems}
          onClickReorder={onClickReorder}
          onClickViewDelivery={handleViewDeliveryClick}
          onClickReview={onClickReview}
          onClickRedeem={onClickRedeem}
          shipmentStatusList={shipmentStatusList}
          onQRCodeLinkClicked={onQRCodeLinkClicked}
          onClickViewInsurance={onClickViewInsurance}
          onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
        />
      )}
      {luckyDrawItems.length > 0 ? (
        <div className={styles.luckyDrawItemListContainer}>
          <LuckyDrawItemList
            currencyCode={order.currencyCode}
            items={luckyDrawItems}
            onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
          />
        </div>
      ) : null}
      <div className={styles.orderDetailSectionTitle}>
        <LocalizedText messageID="order_detail.payment_information" />
      </div>
      <PaymentInfo
        subtotal={subtotal}
        clubPointsRequired={clubPointsRequired}
        extraClubpointsUsed={extraClubpointsUsed}
        totalClubPointsUsed={order.clubpointUsed}
        clubpointsConversionCurrency="HKD"
        clubCurrencyAmount={order.clubCurrencyAmount}
        clubpointsEarned={clubpointEarned}
        extraItems={paymentInfoExtraItems}
        total={total}
        totalShippingFee={shippingFee}
      />
      <div className={styles.orderDetailSectionTitle}>
        <LocalizedText messageID="order_detail.order_details" />
      </div>
      <OrderDetails
        deliveryMethod={order.shippingMethod}
        deliveryTime={order.deliveryTimeSlot}
        shippingAddress={order.shippingAddress}
        pickupAddress={order.pickupAddress}
        billingAddress={order.billingAddress}
        paymentMethod={order.paymentMethod}
      />
      {fulfillQRCode && order.fulfillment && order.fulfillment.fulfillCode ? (
        order.orderStatus === "complete" ? null : (
          <>
            <div className={styles.orderDetailSectionTitle}>
              <LocalizedText messageID="order_detail.fulfillment_code" />
            </div>
            <div
              className={classnames(
                styles.orderDetailSectionContainer,
                styles.fulfillment
              )}
            >
              <img alt={order.fulfillment.fulfillCode} src={fulfillQRCode} />
              <p className={styles.fulfillCode}>
                {order.fulfillment.fulfillCode}
              </p>
            </div>
          </>
        )
      ) : null}
    </div>
  );
};

interface GeneralInfoProps {
  orderID: string | null;
  date: string | null;
  orderStatus: OrderStatus | null;
}

const GeneralInfo: React.FC<GeneralInfoProps> = props => {
  const { orderID, date, orderStatus } = props;
  return (
    <div className={styles.generalInfoContainer}>
      <div className={styles.generalInfoRow}>
        <div className={styles.generalInfoLeft}>
          <div className={styles.generalInfoOrderIDTitle}>
            <LocalizedText messageID="order_detail.order_id" />
          </div>
          <div className={styles.generalInfoOrderStatus}>
            {orderStatus == null ? null : (
              <LocalizedText
                messageID={(() => {
                  switch (orderStatus) {
                    case "canceled":
                      return "order_detail.status.canceled";
                    case "closed":
                      return "order_detail.status.closed";
                    case "complete":
                      return "order_detail.status.complete";
                    case "pending":
                      return "order_detail.status.pending";
                    case "pending_clubpoint":
                      return "order_detail.status.pending_clubpoint";
                    case "pending_payment":
                      return "order_detail.status.pending_payment";
                    case "processing":
                      return "order_detail.status.processing";
                    default:
                      return "order_detail.title";
                  }
                })()}
              />
            )}
          </div>
        </div>
        <div className={styles.generalInfoRight}>
          <div className={styles.generalInfoOrderID}>
            {`#${orderID}` || "-"}
          </div>
          <div className={styles.generalInfoDate}>{date ? date : "-"}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailPage;
