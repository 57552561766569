import { useContext, useMemo, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import CategoryContext from "../contexts/CategoryContext";
import { useFetchResources_v2 } from "./Hooks";
import {
  Category,
  makeAllCategory,
  CategoryTree,
  RemoteCategoryTree,
  computeChildenCategories,
  filterDisabledCategories,
} from "../models/category";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import {
  fetchCategoryList,
  fetchCategoryDescriptionFromCategoryListByCategoryId,
  getCategoryList,
  getCategoryDescriptionFromCategoryListByCategoryId,
  storeCategoryList,
} from "../api/GraphQL";

import { useIntl } from "../i18n/Localization";
import { IndexMap } from "../utils/type";
import { Session } from "../utils/PerformanceRecordStore/sessions";
import { profileAsyncAction, profileSyncAction } from "../utils/performance";

export function useFetchCategory(
  categoryId: number,
  profileSession: Session
): ResourcesRequestState<RemoteCategoryTree | null> {
  const client = useApolloClient();
  const { updateCategoryTree } = useContext(CategoryContext);
  const { locale } = useIntl();

  const [requestState, { call: fetch }] = useFetchResources_v2<
    RemoteCategoryTree | null,
    () => Promise<RemoteCategoryTree | null>
  >({
    localCacheProvider: async () => {
      const rawRemoteCategoryTree = profileSyncAction(
        profileSession,
        "Load Category List from Cache",
        () => getCategoryList(client, categoryId)
      );
      if (rawRemoteCategoryTree) {
        const remoteCategoryTree = filterDisabledCategories(
          rawRemoteCategoryTree
        );
        if (remoteCategoryTree) {
          updateCategoryTree(remoteCategoryTree, locale);
          return remoteCategoryTree;
        }
      }
      return null;
    },
    remoteResourcesProvider: async () => {
      const rawRemoteCategoryTree = await profileAsyncAction(
        profileSession,
        "Load Category List from Network",
        // We are not caching the result of category list by apollo itself.
        // It prevents massive field recomputation of recursive category tree.
        // We will store the response directly (storeCategoryList) with
        // custom query and get it (getCategoryList) in future launches.
        () => fetchCategoryList(client, categoryId, locale, "no-cache")
      );
      if (rawRemoteCategoryTree) {
        storeCategoryList(client, categoryId, rawRemoteCategoryTree);
        const remoteCategoryTree = filterDisabledCategories(
          rawRemoteCategoryTree
        );
        if (remoteCategoryTree) {
          updateCategoryTree(remoteCategoryTree, locale);
          return remoteCategoryTree;
        }
      }
      return null;
    },
  });

  useEffect(() => {
    fetch().catch(() => {});
  }, [fetch, locale]);

  return requestState;
}

export function useFetchCategoryDescription(
  categoryId: number
): [
  ResourcesRequestState<string | null>,
  () => Promise<string | null>,
  () => Promise<string | null>
] {
  const client = useApolloClient();
  const { updateCategoryDescription } = useContext(CategoryContext);
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    string | null,
    () => Promise<string | null>
  >({
    memoryCacheProvider: async () =>
      getCategoryDescriptionFromCategoryListByCategoryId(client, categoryId),
    localCacheProvider: async () => {
      const description = getCategoryDescriptionFromCategoryListByCategoryId(
        client,
        categoryId
      );
      updateCategoryDescription(categoryId, description, locale);
      return description;
    },
    remoteResourcesProvider: async () => {
      const description = await (async () => {
        return fetchCategoryDescriptionFromCategoryListByCategoryId(
          client,
          categoryId,
          locale,
          "network-only"
        );
      })();
      updateCategoryDescription(categoryId, description, locale);
      return description;
    },
  });

  return [requestState, fetch, refresh];
}

export function useCategoryTreeMap() {
  const { categoryTreeMap } = useContext(CategoryContext);
  const { locale } = useIntl();
  return categoryTreeMap[locale] || {};
}

export function useAllCategory(): Category {
  const { locale } = useIntl();
  const allCategory = useMemo(() => makeAllCategory(locale), [locale]);
  return allCategory;
}

export function useChildrenCategories(
  categoryTree: CategoryTree | null,
  categoryTreeMap: IndexMap<string, CategoryTree>
) {
  return useMemo(() => {
    if (!categoryTree || !categoryTreeMap) {
      return [];
    }
    return computeChildenCategories(categoryTree, categoryTreeMap);
  }, [categoryTree, categoryTreeMap]);
}

export function useMainCategoryCount(categoryId: number) {
  const categoryTreeMap = useCategoryTreeMap();

  const categoryTree = useMemo(() => {
    const defaultCategoryTree = categoryTreeMap[categoryId];
    if (!defaultCategoryTree) {
      return null;
    }
    return defaultCategoryTree;
  }, [categoryId, categoryTreeMap]);

  const childrenCategories = useChildrenCategories(
    categoryTree,
    categoryTreeMap
  );

  return childrenCategories.length;
}
