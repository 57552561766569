import React from "react";
import classnames from "classnames";

import styles from "./styles.module.scss";

interface Props {
  displayText: string;
  className?: string;
  textClassName?: string;
  onClick?: () => void;
  isSelected: boolean;
}

const SelectingCategoryRow: React.FC<Props> = props => {
  const { className, textClassName, displayText, onClick, isSelected } = props;
  const textStyle = isSelected
    ? styles.selectedTextColor
    : styles.deselectTextColor;
  return (
    <div
      className={classnames(styles.container, styles.bottomBorder, className)}
      onClick={onClick}
    >
      <p className={classnames(styles.text, textStyle, textClassName)}>
        {displayText}
      </p>
      {isSelected && <div className={styles.tickIcon} />}
    </div>
  );
};

export default SelectingCategoryRow;
