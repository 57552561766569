import React, {
  useEffect,
  useRef,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";
import { Plugins, PluginListenerHandle } from "@capacitor/core";
import { useHistory, useLocation } from "react-router-dom";

import {
  useActivateCustomer,
  useVerifyChangeEmail,
} from "../repository/AuthRepository";
import { useResolveUrl } from "../repository/DeepLinkRepository";
import { useFetchProductSKUByUrlKey } from "../repository/ProductRepository";
import {
  getPathForTab,
  RootTab,
  getPathForSearch,
  getPathForProductDetailPage,
  getPathForEditProfile,
  getPathForMyOrders,
  getPathForMyDelivery,
  getPathForSingleCategoryPage,
  getPathForSelectInterestCategoryPage,
  getPathForCMSLanding,
  activeTabAsRootTab,
  getPathForSingleMerchant,
  getPathForHomePage,
  getPathForArticleDetail,
  getPathForOrderDetail,
  getCurrentTabForPath,
  getPathForSingleBrandPage,
  getPathForBingoList,
  getPathForCampaignDetailsPage,
  getPathForCampaignProductDetailsPage,
} from "../navigation/routes";
import {
  makeClublikeDeepLinkRouter,
  DeepLinkRouteResult,
  CLDeepLinkRouteKey,
} from "../utils/DeepLinkRouter";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";
import { LoginSignupModalContext } from "./LoginSignupModalProvider";
import {
  LoadingModalContextProps,
  withLoadingModal,
} from "./LoadingModalProvider";
import { TokenStore } from "../api/TokenStore";
import { EntityUrl } from "../models/EntityUrl";
import { isCustomerInterestSet } from "../models/Customer";
import { AppConfig } from "../models/AppConfig";
import { useAppConfig } from "../repository/ConfigRepository";
import { appEventEmitter } from "../utils/SimpleEventEmitter";
import { isUrl } from "../utils/Url";
import { isiOS, isAndroid, isHybrid } from "../utils/Platform";
import { addPerformanceRecord } from "../utils/PerformanceRecordStore";
import { SingleBrandPageSession } from "../utils/PerformanceRecordStore/sessions";
import { OurNavContext } from "../our-navigation";
import { getUrlKey } from "../models/DeepLink";
import { YourEmailUpdatedModal } from "./ChangeEmailModals";
import useOpenUrlWithBrowser from "../hook/useOpenUrlWithBrowser";
import Config from "../Config";

const { App, FirebaseDynamicLinksPlugin } = Plugins;

function tryCompleteUrl(url: string): string {
  const regex = new RegExp("(.+)://(.+)");
  if (regex.exec(url)) {
    // Normal case. Do nothing.
    return url;
  }
  if (url.startsWith("//")) {
    // Possibly protocol inheritance
    return `https:${url}`;
  }
  if (url.startsWith("/")) {
    // Possibly path only
    return `${Config.SITE_URL}${url}`;
  }
  // Begin with non http and slash
  return `${Config.SITE_URL}/${url}`;
}

const excludedURLs = [
  // When firebase is enabled,
  //
  // ```
  // FirebaseApp.configure()
  // ```
  //
  // [appScheme]://google/link/?dismiss=1&is_weak_match=1 will be
  // emitted through App urlOpen event. We ignore this type of url. It only happens on ios.
  "google/link",
];

const excludedURLsRegEx =
  excludedURLs.length > 0
    ? new RegExp(excludedURLs.map(str => `(.*)${str}(.*)`).join("|"))
    : null;

function isExcludedUrl(url: string): boolean {
  if (excludedURLsRegEx) {
    return !!excludedURLsRegEx.exec(url);
  }
  return false;
}

type Props = {
  appConfig: AppConfig;
} & LoadingModalContextProps;

const DeepLinkHandler: React.FC<Props> = props => {
  const {
    appConfig,
    loadingModalContext: {
      show: showLoadingView,
      hide: hideLoadingView,
      withLoadingModalAsync,
    },
  } = props;
  const history = useHistory();
  const location = useLocation();
  const {
    navigate,
    goBack,
    replace,
    getActiveTab,
    waitForTransition,
  } = useContext(OurNavContext);
  const deepLinkRouter = useRef(makeClublikeDeepLinkRouter(appConfig));
  useEffect(() => {
    deepLinkRouter.current = makeClublikeDeepLinkRouter(appConfig);
  }, [appConfig]);
  const resolveUrl = useResolveUrl();

  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const activateCustomer = useActivateCustomer();

  const rootTab = useMemo(
    () => getCurrentTabForPath(location.pathname) || RootTab.home,
    [location.pathname]
  );

  const findCurrentTab = useCallback(() => {
    const activeTab = getActiveTab();
    if (activeTab == null) {
      return null;
    }
    return activeTabAsRootTab(activeTab);
  }, [getActiveTab]);

  const [
    isYourEmailUpdatedModalOpen,
    setIsYourEmailUpdatedModalOpen,
  ] = useState(false);
  const closeVerificationEmailSentModal = useCallback(() => {
    setIsYourEmailUpdatedModalOpen(false);
  }, []);
  const onClickGotoShopButton = useCallback(() => {
    setIsYourEmailUpdatedModalOpen(false);
    // FIXME: A super hacky way to back to top and then go to home page
    // I cannot find another way to do so... so sad
    setTimeout(() => {
      console.log(findCurrentTab());
      goBack(findCurrentTab() || RootTab.home);
      setTimeout(() => {
        history.replace(getPathForHomePage());
      }, 500);
    }, 250);
  }, [findCurrentTab, goBack, history]);

  const openUrlWithBrowser = useOpenUrlWithBrowser();
  const fetchProductSKUByUrlKey = useFetchProductSKUByUrlKey();

  const handleEntityUrl = useCallback(
    async (entityUrl: EntityUrl, originalUrl: string) => {
      const url = new URL(originalUrl);
      const { id } = entityUrl;
      switch (entityUrl.type) {
        case "PRODUCT": {
          const urlKey = getUrlKey(originalUrl);
          try {
            showLoadingView();
            const productSKU = await fetchProductSKUByUrlKey(urlKey);
            if (productSKU) {
              navigate(
                getPathForProductDetailPage(
                  findCurrentTab() || RootTab.home,
                  productSKU
                ) + url.search
              );
            }
          } finally {
            hideLoadingView();
          }
          break;
        }
        case "CATEGORY": {
          navigate(
            getPathForSingleCategoryPage(
              findCurrentTab() || RootTab.category,
              id
            ) + url.search
          );
          break;
        }
        case "CMS_PAGE": {
          navigate(
            getPathForCMSLanding(findCurrentTab() || RootTab.home, String(id)) +
              url.search
          );
          break;
        }
        case "POST": {
          navigate(
            getPathForArticleDetail(
              findCurrentTab() || RootTab.home,
              String(id)
            )
          );
          break;
        }
        default: {
          withLoadingModalAsync(() => openUrlWithBrowser(originalUrl));
        }
      }
    },
    [
      navigate,
      showLoadingView,
      hideLoadingView,
      openUrlWithBrowser,
      findCurrentTab,
      fetchProductSKUByUrlKey,
      withLoadingModalAsync,
    ]
  );

  const verifyChangeEmail = useVerifyChangeEmail();
  /* eslint-disable complexity */
  const handleDeepLinkRoute = useCallback(
    async (
      deepLinkRoute: DeepLinkRouteResult<CLDeepLinkRouteKey>,
      originalUrl: string
    ) => {
      const url = new URL(originalUrl);
      switch (deepLinkRoute.routeKey) {
        case "home": {
          replace(getPathForTab(RootTab.home) + url.search);
          break;
        }
        case "product": {
          try {
            showLoadingView();
            const { urlKey } = deepLinkRoute.param;
            const productSKU = await fetchProductSKUByUrlKey(urlKey);
            if (productSKU) {
              navigate(
                getPathForProductDetailPage(
                  findCurrentTab() || RootTab.home,
                  productSKU
                ) + url.search
              );
            }
          } finally {
            hideLoadingView();
          }
          break;
        }
        case "search": {
          let searchTerm = deepLinkRoute.param.searchTerm;
          if (searchTerm == null) {
            searchTerm = deepLinkRoute.queryParam.searchTerm;
          }
          if (searchTerm != null) {
            navigate(
              getPathForSearch(
                findCurrentTab() || RootTab.home,
                decodeURIComponent(searchTerm)
              ) + url.search
            );
          }
          break;
        }
        case "my-account": {
          replace(getPathForTab(RootTab.account) + url.search);
          break;
        }
        case "likes-tab": {
          replace(getPathForTab(RootTab.likes) + url.search);
          break;
        }
        case "edit-profile": {
          const gotoEditProfile = () => {
            navigate(getPathForEditProfile(RootTab.account) + url.search);
          };
          if (TokenStore.accessToken) {
            gotoEditProfile();
          } else {
            presentLoginModal(gotoEditProfile);
          }
          break;
        }
        case "my-order": {
          const gotoMyOrdersPage = () => {
            navigate(getPathForMyOrders(RootTab.account) + url.search);
          };
          if (TokenStore.accessToken) {
            gotoMyOrdersPage();
          } else {
            presentLoginModal(gotoMyOrdersPage);
          }
          break;
        }
        case "delivery-info": {
          const gotoDeliveryInfoPage = () => {
            navigate(getPathForMyDelivery(RootTab.account) + url.search);
          };
          if (TokenStore.accessToken) {
            gotoDeliveryInfoPage();
          } else {
            presentLoginModal(gotoDeliveryInfoPage);
          }
          break;
        }
        case "email-verification": {
          const {
            id: customerId,
            key: confirmationKey,
          } = deepLinkRoute.queryParam;
          if (customerId == null || confirmationKey == null) {
            return;
          }
          try {
            showLoadingView();
            const customer = await activateCustomer(
              customerId,
              confirmationKey
            );
            if (!customer) {
              throw new Error("cannot-get-customer");
            }
            if (!isCustomerInterestSet(customer)) {
              replace(
                getPathForSelectInterestCategoryPage(RootTab.home) + url.search
              );
            }
          } catch (e) {
            console.debug(e);
            presentLocalizedAlert({
              headerId: "alert.error.title",
              messageId: "alert.deeplink.email_verification.fail",
              buttons: [
                {
                  textMessageID: "alert.button.ok",
                },
              ],
            });
          } finally {
            hideLoadingView();
          }
          break;
        }
        case "merchant": {
          navigate(
            getPathForSingleMerchant(
              findCurrentTab() || RootTab.category,
              deepLinkRoute.param.venderId
            ) + url.search
          );
          break;
        }
        case "change-email-verification": {
          const {
            id: customerId,
            key: confirmationKey,
          } = deepLinkRoute.queryParam;
          if (customerId == null || confirmationKey == null) {
            return;
          }
          try {
            showLoadingView();
            await verifyChangeEmail(Number(customerId), confirmationKey);
            setIsYourEmailUpdatedModalOpen(true);
          } catch (e) {
            presentLocalizedAlert({
              headerId: "alert.error.title",
              messageId:
                typeof e === "string"
                  ? "alert.error.message"
                  : "alert.deeplink.change_email_verification.fail",
              buttons: [
                {
                  textMessageID: "alert.button.ok",
                },
              ],
            });
          } finally {
            hideLoadingView();
          }
          break;
        }
        case "order-details": {
          const { orderId } = deepLinkRoute.param;
          navigate(getPathForOrderDetail(rootTab, orderId));
          break;
        }
        case "brand": {
          const { slug } = deepLinkRoute.param;
          addPerformanceRecord(
            SingleBrandPageSession(slug),
            "Navigating to Brand Product List Page"
          );
          navigate(getPathForSingleBrandPage(rootTab, slug));
          break;
        }
        case "exclusive-rewards": {
          const gotoBingoListPage = () => {
            navigate(getPathForBingoList(rootTab));
          };
          if (TokenStore.accessToken) {
            gotoBingoListPage();
          } else {
            presentLoginModal(gotoBingoListPage);
          }
          break;
        }
        case "campaign": {
          const { campaignId: _campaignId } = deepLinkRoute.param;
          const campaignId = parseInt(_campaignId, 10);
          const gotoCampaignDetailsPage = () => {
            navigate(getPathForCampaignDetailsPage(rootTab, campaignId));
          };
          if (TokenStore.accessToken) {
            gotoCampaignDetailsPage();
          } else {
            presentLoginModal(gotoCampaignDetailsPage);
          }
          break;
        }
        case "campaign-product": {
          const { campaignId: _campaignId, sku } = deepLinkRoute.param;
          const campaignId = parseInt(_campaignId, 10);
          const gotoCampaignProductPage = () => {
            navigate(
              getPathForCampaignProductDetailsPage(rootTab, campaignId, sku)
            );
          };
          if (TokenStore.accessToken) {
            gotoCampaignProductPage();
          } else {
            presentLoginModal(gotoCampaignProductPage);
          }
          break;
        }
        default: {
          withLoadingModalAsync(() => openUrlWithBrowser(originalUrl));
        }
      }
    },
    [
      navigate,
      replace,
      presentLoginModal,
      showLoadingView,
      hideLoadingView,
      presentLocalizedAlert,
      activateCustomer,
      openUrlWithBrowser,
      findCurrentTab,
      fetchProductSKUByUrlKey,
      verifyChangeEmail,
      rootTab,
      withLoadingModalAsync,
    ]
  );
  /* eslint-enable complexity */

  const onAppUrlOpen = useCallback(
    async (url: string) => {
      url = tryCompleteUrl(url);
      if (isExcludedUrl(url)) {
        return;
      }
      if (!isUrl(url)) {
        presentLocalizedAlert({
          headerId: "alert.error.title",
          messageId: "alert.deeplink.invalid_url",
          messageArgs: {
            URL: url,
          },
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
        return;
      }

      if (!isUrlRecognizable(url)) {
        // Still open in app browser when it is not recognizable
        withLoadingModalAsync(() => openUrlWithBrowser(url));
        return;
      }

      const deepLinkRoute = deepLinkRouter.current.match(url);
      if (deepLinkRoute != null) {
        handleDeepLinkRoute(deepLinkRoute, url);
        return;
      }

      let entityUrl: EntityUrl | null = null;
      try {
        showLoadingView();
        entityUrl = await resolveUrl(url);
      } finally {
        hideLoadingView();
      }

      if (entityUrl != null) {
        handleEntityUrl(entityUrl, url);
        return;
      }

      openUrlWithBrowser(url);
    },
    [
      resolveUrl,
      handleDeepLinkRoute,
      handleEntityUrl,
      openUrlWithBrowser,
      showLoadingView,
      hideLoadingView,
      presentLocalizedAlert,
      withLoadingModalAsync,
    ]
  );

  useEffect(() => {
    const onClickViewMoreSub = appEventEmitter.subscribe(e => {
      if (e.type === "OnClickViewMore") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "OnQRScanSuccess") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "OnClickPromotionBanner") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "PushNotificationGoTo") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "ClickThirdPartyProductButtonUrl") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "LinkToExternalRedemptionOrder") {
        onAppUrlOpen(e.url);
      }
      if (e.type === "LinkToBrand") {
        onAppUrlOpen(e.url);
      }
    });
    return () => {
      onClickViewMoreSub.remove();
    };
  }, [onAppUrlOpen]);

  useEffect(() => {
    const appUrlOpenSub = App.addListener("appUrlOpen", async (e: any) => {
      if (e && e.url) {
        if (!isUrlRecognizable(e.url)) {
          // Do not handle url from appUrlOpen if it is not recognized
          return;
        }
        // Wait router transition complete when app init
        await waitForTransition();
        onAppUrlOpen(e.url);
      }
    });
    return () => {
      appUrlOpenSub.remove();
    };
  }, [waitForTransition, onAppUrlOpen]);

  useEffect(() => {
    let dynamicLinksOpenSub: PluginListenerHandle | null = null;
    if (isHybrid() && (isiOS() || isAndroid())) {
      dynamicLinksOpenSub = FirebaseDynamicLinksPlugin.addListener(
        "dynamicLinkOpened",
        async (e: any) => {
          if (e && e.url) {
            // Wait router transition complete when app init
            if (!isUrlRecognizable(e.url)) {
              // Do not handle url from dynamic link if it is not recognized
              return;
            }
            await waitForTransition();
            onAppUrlOpen(e.url);
          }
        }
      );
    }
    return () => {
      if (dynamicLinksOpenSub) {
        dynamicLinksOpenSub.remove();
      }
    };
  }, [waitForTransition, onAppUrlOpen]);

  return (
    <>
      <YourEmailUpdatedModal
        isOpen={isYourEmailUpdatedModalOpen}
        onRequestDismiss={closeVerificationEmailSentModal}
        onClickGotoShopButton={onClickGotoShopButton}
      />
    </>
  );
};

function withAppConfig<P>(
  Component: React.ComponentType<P & { appConfig: AppConfig }>
): React.ComponentType<P> {
  const WithAppConfigComponent: React.FC<P> = props => {
    const appConfig = useAppConfig();
    if (appConfig == null) {
      return null;
    }
    return <Component {...props} appConfig={appConfig} />;
  };
  return WithAppConfigComponent;
}

export default React.memo(withAppConfig<{}>(withLoadingModal(DeepLinkHandler)));

function isUrlRecognizable(url: string): boolean {
  const appSiteUrlRegex = new RegExp(
    Config.DEEPLINK_URLS && Config.DEEPLINK_URLS.length > 0
      ? Config.DEEPLINK_URLS.map(u => `https://${u}(/.*)`)
          .concat(`${Config.SITE_URL}(/.*)`)
          .join("|")
      : `${Config.SITE_URL}(/.*)`
  );
  const appSiteUrlMatched = appSiteUrlRegex.exec(url);
  return !!appSiteUrlMatched;
}
