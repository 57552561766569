import React, { Suspense, lazy } from "react";

export default function<T, RefType>(
  importPromise: () => Promise<{ default: React.ComponentType<T> }>
) {
  const Lazied = lazy<React.ComponentType<T>>(importPromise);

  const Wrapped = React.forwardRef<
    RefType,
    React.ComponentProps<typeof Lazied>
  >((props, ref) => {
    return (
      <Suspense fallback={<></>}>
        <Lazied {...props} ref={ref} />
      </Suspense>
    );
  });

  return Wrapped;
}
