import React from "react";

import { LocalizedText } from "../../i18n/Localization";

import { Customer } from "../../models/Customer";

import styles from "./ChangeEmailWidget.module.scss";

interface ChangeEmailWidgetProps {
  customer: Customer;
  onChangeEmailClick: () => void;
  onClickResendConfirmationButton: () => void;
  onClickCancelThisChangeButton: () => void;
}

const ChangeEmailWidget: React.FC<ChangeEmailWidgetProps> = React.memo(
  props => {
    const {
      customer,
      onChangeEmailClick,
      onClickResendConfirmationButton,
      onClickCancelThisChangeButton,
    } = props;
    const { email, newEmail } = customer;
    return (
      <div className={styles.widgetContainer}>
        <div className={styles.emailInfo}>
          <div className={styles.title}>
            <LocalizedText messageID="page.edit_profile.my_email" />
          </div>
          <div>{email}</div>
          {newEmail == null && (
            <div className={styles.verified}>
              <LocalizedText messageID="page.edit_profile.verified_account" />
            </div>
          )}
          {newEmail != null && (
            <div className={styles.verifyYourEmailDescription}>
              <p>
                <LocalizedText
                  messageID="page.change_email.verify_your_email.description"
                  messageArgs={{
                    NEW_EMAIL: newEmail,
                  }}
                />
              </p>
              <div className={styles.actionButtonsContainer}>
                <button
                  className={styles.verifyEmailActionButton}
                  onClick={onClickResendConfirmationButton}
                >
                  <LocalizedText messageID="page.change_email.verify_your_email.resend_confirmation" />
                </button>
                <span className={styles.dot}>・</span>
                <button
                  className={styles.verifyEmailActionButton}
                  onClick={onClickCancelThisChangeButton}
                >
                  <LocalizedText messageID="page.change_email.verify_your_email.cancel_this_change" />
                </button>
              </div>
            </div>
          )}
        </div>
        {newEmail == null && (
          <div className={styles.changeEmailButtonContainer}>
            <div className={styles.changeButton} onClick={onChangeEmailClick}>
              <LocalizedText messageID="page.edit_profile.change" />
            </div>
          </div>
        )}
      </div>
    );
  }
);
ChangeEmailWidget.displayName = "ChangeEmailWidget";

export default ChangeEmailWidget;
