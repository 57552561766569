import { produce } from "immer";
import numeral from "numeral";

export interface Money {
  currency: string;
  value: number;
}

export function Money(currency: string, value: number): Money {
  return {
    currency,
    value,
  };
}

export const MoneyGraphQLAttributes = `
  currency
  value
`;

function getCurrencySymbol(currency: string): string {
  const CURRENCY_SYMBOL_MAP = {
    BAM: "KM",
    XAF: "FCFA",
    BIF: "FBu",
    RWF: "RF",
    SZL: "E",
    ILS: "₪",
    NZD: "$",
    AUD: "$",
    INR: "₹",
    TZS: "TSh",
    TWD: "$",
    CNY: "¥",
    CHF: "CHF",
    THB: "THB",
    EUR: "€",
    TRY: "₺",
    XOF: "CFA",
    NGN: "₦",
    SCR: "SR",
    HNL: "L",
    XCD: "$",
    KZT: "₸",
    ETB: "Br",
    MOP: "MOP$",
    SSP: "£",
    KHR: "៛",
    MMK: "K",
    MZN: "MTn",
    GHS: "GH₵",
    USD: "$",
    GBP: "£",
    MKD: "ден",
    BMD: "$",
    MGA: "Ar",
    SGD: "$",
    CAD: "$",
    LRD: "$",
    HKD: "HK$",
    TOP: "T$",
    UGX: "USh",
    ZAR: "R",
    MAD: "MAD",
    ALL: "ALL",
    VUV: "VT",
    CDF: "FC",
    KES: "Ksh",
    DKK: "kr.",
    TND: "DT",
    PAB: "B/.",
    PLN: "zł",
    BRL: "R$",
    GNF: "FG",
    NOK: "NOK",
    RSD: "RSD",
    BOB: "Bs",
    AFN: "؋",
    BSD: "$",
    ZMW: "K",
    HTG: "G",
    GTQ: "Q",
    UYU: "$",
    BND: "$",
    PEN: "S/.",
    CZK: "CZK",
    BTN: "Nu.",
    BWP: "P",
    NAD: "$",
    PHP: "₱",
    IRR: "ریال",
    UZS: "soʻm",
    XPF: "FCFP",
    BZD: "$",
    DJF: "Fdj",
    STD: "Db",
    SDG: "ج.س.",
    IQD: "IQD",
    SRD: "$",
    DOP: "RD$",
    MUR: "Rs",
    CLP: "$",
    MVR: "MVR",
    RUB: "₽",
    KGS: "сом",
    MWK: "MK",
    ISK: "ISK",
    COP: "$",
    AZN: "₼",
    MYR: "RM",
    SYP: "LS",
    JMD: "$",
    MDL: "L",
    KRW: "₩",
    NIO: "C$",
    ANG: "NAf.",
    CRC: "₡",
    JPY: "¥",
    SBD: "$",
    PKR: "Rs",
    SOS: "S",
    WST: "WS$",
    CUP: "$",
    AOA: "Kz",
    RON: "RON",
    SEK: "kr",
    KYD: "$",
    GIP: "£",
    PYG: "Gs.",
    LKR: "රු.",
    DZD: "DA",
    ARS: "$",
    SHP: "£",
    ERN: "Nfk",
    GMD: "D",
    HRK: "HRK",
    FJD: "$",
    BYR: "р.",
    FKP: "£",
    SLL: "Le",
    LAK: "₭",
    NPR: "नेरू",
    HUF: "Ft",
    KPW: "KPW",
    VEF: "Bs.",
    TJS: "сом",
    UAH: "₴",
    TTD: "$",
    MXN: "$",
    AWG: "Afl.",
    BBD: "$",
    PGK: "K",
    BDT: "৳",
    IDR: "Rp",
    GYD: "$",
    VND: "₫",
    TMT: "TMT",
    BGN: "лв.",
    MNT: "₮",
  } as any;
  return CURRENCY_SYMBOL_MAP[currency] || "$";
}

function getShortCurrencySymbol(currency: string): string {
  const CURRENCY_SYMBOL_MAP = {
    HKD: "$",
  } as any;
  return CURRENCY_SYMBOL_MAP[currency] || "$";
}

type MoneyFormatOption = Partial<{
  withCurrency: boolean;
  withValue: boolean;
  useShortSymbol: boolean;
}>;

const defaultMoneyFormatOption = {
  withCurrency: true,
  withValue: true,
  useShortSymbol: false,
};

export function multiplyMoneyQuantity(money: Money, quantity: number): Money {
  return {
    ...money,
    value: money.value * quantity,
  };
}

export function formatMoney(money: Money, opt?: MoneyFormatOption): string {
  opt =
    opt == null
      ? defaultMoneyFormatOption
      : { ...defaultMoneyFormatOption, ...opt };
  return `${
    opt.withCurrency
      ? opt.useShortSymbol
        ? getShortCurrencySymbol(money.currency)
        : getCurrencySymbol(money.currency)
      : ""
  }${opt.withValue ? numeral(Math.abs(money.value)).format("0,0.0") : ""}`;
}

export function formatPriceValue(
  price: number,
  currency: string = "HKD"
): string {
  return formatMoney(Money(currency, price));
}

export function addMoneyAmount(amount: number, money: Money): Money {
  return produce(money, draft => {
    draft.value += amount;
  });
}

export function areMoneyEqual(moneyA: Money, moneyB: Money): boolean {
  return moneyA.currency === moneyB.currency && moneyA.value === moneyB.value;
}

export const ZERO_MONEY: Money = {
  currency: "HKD",
  value: 0,
};
