import * as yup from "yup";
import { useCallback, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import {
  ProductReview,
  CustomerProductReview,
  RatingCode,
  RatingVote,
} from "../models/ProductReview";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import {
  fetchProductReviews,
  fetchCustomerProductReviews,
  fetchRatingCodes,
  addProductReview,
  getProductReviews,
} from "../api/GraphQL";
import { TranslateFn, useIntl } from "../i18n/Localization";

import { useCustomer } from "./AuthRepository";
import { useFetchResources_v2 } from "./Hooks";
import { FormError } from "../utils/type";
import Config from "../Config";

export function useInMemoryProductReviews(sku: string): ProductReview[] {
  const client = useApolloClient();

  return useMemo(() => {
    return getProductReviews(client, sku);
  }, [client, sku]);
}

export function useFetchProductReviews(
  sku: string
): [
  ResourcesRequestState<ProductReview[]>,
  () => Promise<ProductReview[]>,
  () => Promise<ProductReview[]>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    ProductReview[],
    () => Promise<ProductReview[]>
  >({
    localCacheProvider: () =>
      fetchProductReviews(client, sku, locale, "cache-only"),
    remoteResourcesProvider: () =>
      fetchProductReviews(client, sku, locale, "network-only"),
  });

  return [requestState, fetch, refresh];
}

export function useFetchCustomerProductReviews(): [
  ResourcesRequestState<CustomerProductReview[]>,
  () => Promise<CustomerProductReview[]>,
  () => Promise<CustomerProductReview[]>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    CustomerProductReview[],
    () => Promise<CustomerProductReview[]>
  >({
    remoteResourcesProvider: async () => {
      const productReviews = await fetchCustomerProductReviews(
        client,
        locale,
        "network-only"
      );
      return productReviews;
    },
  });

  return [requestState, fetch, refresh];
}

export function useFetchRatingCodes(): [
  ResourcesRequestState<RatingCode[]>,
  () => void
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch }] = useFetchResources_v2<
    RatingCode[],
    () => Promise<RatingCode[]>
  >({
    remoteResourcesProvider: async () => {
      const ratingCodes = await fetchRatingCodes(
        client,
        locale,
        "network-only"
      );
      return ratingCodes;
    },
  });

  return [requestState, fetch];
}

export function useAddProductReview(): [
  ResourcesRequestState<number>,
  (
    ratingVote: RatingVote[],
    productId: number,
    detail: string
  ) => Promise<number>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const customer = useCustomer();

  const [requestState, { call }] = useFetchResources_v2<
    number,
    (
      ratingVote: RatingVote[],
      productId: number,
      detail: string
    ) => Promise<number>
  >({
    remoteResourcesProvider: async (
      ratingVote: RatingVote[],
      productId: number,
      detail: string
    ) => {
      const detailId = await addProductReview(
        client,
        ratingVote,
        productId,
        customer ? `${customer.firstname} ${customer.lastname}` : "",
        detail,
        locale
      );
      return detailId;
    },
  });

  return [requestState, call];
}

const addProductReviewSchema = (translate: TranslateFn) =>
  yup.object({
    ratingVote: yup
      .array()
      .of(
        yup.object({
          ratingId: yup.number().required(),
          optionId: yup.number().required(),
        })
      )
      .min(1, translate("add_product_review.validate.rating_star.missing")),
    name: yup
      .string()
      .required(translate("add_product_review.validate.required.missing"))
      .min(1, translate("add_product_review.validate.required.missing")),
    detail: yup
      .string()
      .required(translate("add_product_review.validate.required.missing"))
      .min(1, translate("add_product_review.validate.required.missing")),
  });

type AddProductReviewFormError = FormError<{
  name?: string;
  detail?: string;
  ratingVote?: RatingVote[];
}>;

function parseYupErrorToAddProductReviewFormError(
  e: yup.ValidationError
): AddProductReviewFormError {
  const formError: AddProductReviewFormError = {};
  for (const { path, message } of e.inner) {
    if (path === "name" || path === "detail" || path === "ratingVote") {
      formError[path] = message;
    }
  }
  return formError;
}

export function useAddProductReviewWithName(): [
  ResourcesRequestState<number>,
  (
    ratingVote: RatingVote[],
    productId: number,
    name: string,
    detail: string
  ) => Promise<number>,
  AddProductReviewFormError | null,
  () => void
] {
  const client = useApolloClient();
  const { locale, translate } = useIntl();
  const customer = useCustomer();
  const [
    validationError,
    setValidationError,
  ] = useState<AddProductReviewFormError | null>(null);

  const resetValidationError = useCallback(() => setValidationError(null), []);

  const [requestState, { call }] = useFetchResources_v2<
    number,
    (
      ratingVote: RatingVote[],
      productId: number,
      name: string,
      detail: string
    ) => Promise<number>
  >({
    remoteResourcesProvider: async (
      _ratingVote: RatingVote[],
      productId: number,
      _name: string,
      _detail: string
    ) => {
      setValidationError(null);
      let name: string = _name;
      const detail: string = _detail;
      const ratingVote: RatingVote[] = _ratingVote;
      if (Config.ENABLE_SET_NAME_ON_RODUCT_REVIEW) {
        try {
          await addProductReviewSchema(translate).validate(
            {
              name: _name,
              detail: _detail,
              ratingVote: _ratingVote,
            },
            { abortEarly: false }
          );
        } catch (e) {
          if (yup.ValidationError.isError(e)) {
            setValidationError(parseYupErrorToAddProductReviewFormError(e));
          }
          throw e;
        }
      } else {
        if (customer && !name) {
          name = `${customer.firstname} ${customer.lastname}`;
        }
      }
      const detailId = await addProductReview(
        client,
        ratingVote,
        productId,
        name,
        detail,
        locale
      );
      return detailId;
    },
  });

  return [requestState, call, validationError, resetValidationError];
}
