import React from "react";

import styles from "./DeliveryMethodBanner.EDD.module.scss";

interface DeliveryMethodBannerProps {
  name: string;
  estimatedDeliveryDate: React.ReactNode;
}

const DeliveryMethodBanner: React.FC<DeliveryMethodBannerProps> = props => {
  const { name, estimatedDeliveryDate } = props;
  return (
    <div className={styles.deliveryMethodBanner}>
      <div className={styles.deliveryMethodBanner__name}>{name}</div>
      <div className={styles.deliveryMethodBanner__estimatedDeliveryDate}>
        {estimatedDeliveryDate}
      </div>
    </div>
  );
};

export default DeliveryMethodBanner;
