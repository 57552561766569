import React, {
  useReducer,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from "react";

import {
  ResourcesSource,
  ResourcesRequestState,
  ResourcesRequestStateLoading,
  ResourcesRequestStateLoadingWithResources,
  ResourcesRequestStateLoaded,
  ResourcesRequestStateErrorWithResources,
  ResourcesRequestStateError,
  ResourcesRequestStateInitial,
  isRequestError,
} from "../models/ResourcesRequestState";
import { parseGraphQLError } from "../api/GraphQL";
import { LocalizedAlertContext } from "../components/LocalizedAlertProvider";

interface WillRequestResourcesAction {
  type: "WillRequestAction";
}

interface DidRequestResourcesAction<T> {
  type: "DidRequestResourcesAction";
  source: ResourcesSource;
  resources: T;
}

interface DidRejectRequestResourcesAction {
  type: "DidRejectRequestResourcesAction";
  error: Error;
}

interface StopRequestAction {
  type: "StopRequestAction";
}

type ResourcesRequestAction<T> =
  | WillRequestResourcesAction
  | DidRequestResourcesAction<T>
  | DidRejectRequestResourcesAction
  | StopRequestAction;

function resourcesRequestStateReducer<T>(
  state: ResourcesRequestState<T>,
  action: ResourcesRequestAction<T>
): ResourcesRequestState<T> {
  switch (action.type) {
    case "WillRequestAction": {
      switch (state.type) {
        case "initial":
        case "error":
          return ResourcesRequestStateLoading();
        case "loaded":
        case "error-with-resources": {
          return ResourcesRequestStateLoadingWithResources(
            state.source,
            state.resources
          );
        }
        default:
          return state;
      }
    }
    case "DidRequestResourcesAction": {
      const { source, resources } = action;
      return ResourcesRequestStateLoaded(source, resources);
    }
    case "DidRejectRequestResourcesAction": {
      switch (state.type) {
        case "loading-with-resources":
        case "error-with-resources":
        case "loaded":
          return ResourcesRequestStateErrorWithResources(
            action.error,
            state.source,
            state.resources
          );
        default:
          return ResourcesRequestStateError(action.error);
      }
    }
    case "StopRequestAction": {
      return ResourcesRequestStateInitial();
    }
    default:
      return state;
  }
}

export function useResourcesRequestState<T>() {
  const [state, dispatch] = useReducer(
    resourcesRequestStateReducer,
    ResourcesRequestStateInitial()
  ) as [ResourcesRequestState<T>, React.Dispatch<ResourcesRequestAction<T>>];
  const willRequest = useCallback(
    () => dispatch({ type: "WillRequestAction" }),
    [dispatch]
  );
  const didRequest = useCallback(
    (source: ResourcesSource, resources: T) =>
      dispatch({ type: "DidRequestResourcesAction", source, resources }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const didRejectRequest = useCallback(
    (error: Error) =>
      dispatch({ type: "DidRejectRequestResourcesAction", error }),
    [dispatch]
  );
  const stopRequest = useCallback(
    () => dispatch({ type: "StopRequestAction" }),
    [dispatch]
  );
  return useMemo(
    () => ({
      state,
      willRequest,
      didRequest,
      didRejectRequest,
      stopRequest,
    }),
    [state, willRequest, didRequest, didRejectRequest, stopRequest]
  );
}

const messagesShouldSkipShowingAlert = [
  "Store reset while query was in flight",
];
const messagesShouldSkipShowingAlertRegex =
  messagesShouldSkipShowingAlert.length > 0
    ? new RegExp(messagesShouldSkipShowingAlert.join("|"))
    : null;

export function usePresentLocalizedAlertForRequestStateError<T>(
  requestState: ResourcesRequestState<T>
) {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  useEffect(() => {
    if (isRequestError(requestState)) {
      const { error } = requestState;
      const errorMessage = parseGraphQLError(error) || error.message;
      if (
        messagesShouldSkipShowingAlertRegex &&
        messagesShouldSkipShowingAlertRegex.exec(errorMessage)
      ) {
        return;
      }
      presentLocalizedAlert({
        message: errorMessage ? errorMessage : undefined,
        messageId: errorMessage ? undefined : "error.unknown",
        buttons: [
          {
            textMessageID: "alert.button.ok",
          },
        ],
      });
    }
  }, [requestState, presentLocalizedAlert]);
}
