import React, { useCallback } from "react";
import classnames from "classnames";

import LocalizedInput from "../LocalizedInput";

import { isEmpty } from "../../utils/String";

import styles from "./SearchInput.module.scss";
import LocalizedText from "../../i18n/LocalizedText";

interface Props {
  value: string;
  disabled?: boolean;
  onTextChange: (value: string) => void;
  onClearTextClick: () => void;
}

const WrappedSearchInput = (
  props: Props,
  ref?: React.Ref<HTMLInputElement>
) => {
  const { value, disabled, onTextChange, onClearTextClick } = props;
  const onClickClearButton = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClearTextClick();
    },
    [onClearTextClick]
  );
  return (
    <div
      className={classnames(styles.inputContainer, disabled && styles.disabled)}
    >
      <LocalizedInput
        className={styles.input}
        type="search"
        placeholderId="page.brand_index.search.navbar.search_input.placeholder"
        value={value}
        onChange={onTextChange}
        ref={ref}
      />
      {!isEmpty(value) && !disabled && (
        <div className={styles.clearButton} onClick={onClickClearButton} />
      )}
    </div>
  );
};

export default React.forwardRef(WrappedSearchInput);

interface DisplayOnlySearchInputProps {
  onClick: () => void;
}

export const DisplayOnlySearchInput: React.FC<
  DisplayOnlySearchInputProps
> = props => {
  const { onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <button className={styles.inputDisplayOnlyContainer} onClick={handleClick}>
      <div className={styles.inputDisplayOnly}>
        <LocalizedText messageID="page.brand_index.search.navbar.search_input.placeholder" />
      </div>
    </button>
  );
};
