import React, { useReducer, useMemo } from "react";
import { produce } from "immer";

import { IndexMap } from "../utils/type";

import { Customer, RemoteAddress } from "../models/Customer";
import { WishlistItem } from "../models/Wishlist";
import {
  CustomerSubscription,
  CustomerSubscriptionId,
} from "../models/CustomerSubscription";

import { PartialOrder as OrderDetailsPartialOrder } from "../components/OrderDetailPage/model";

export interface PaginationInfo<T> {
  items: T[];
  currentPage: number;
  hasMore: boolean;
}

export interface RepositoryState {
  product: {
    likedProductSKU: IndexMap<string, boolean>;
    wishlistItemByProductSKU: IndexMap<string, WishlistItem>;
  };
  customer: Customer | null;
  isLogoutBySectionExpired: boolean;
  customerAddresses: RemoteAddress[];
  defaultBilling: number | null;
  defaultShipping: number | null;
  customerSubscriptions: CustomerSubscription[] | null;
  customerSubscriptionMap: IndexMap<
    CustomerSubscriptionId,
    CustomerSubscription
  >;
  orderDetailsPartialOrderMap: IndexMap<string, OrderDetailsPartialOrder>;
}

interface RepositoryContextValue {
  state: RepositoryState;
  dispatch: React.Dispatch<RepositoryAction>;
}

export type RepositoryAction =
  | {
      type: "UpdateCustomer";
      customer: Customer;
    }
  | {
      type: "SectionExpired";
    }
  | {
      type: "Logout";
    }
  | {
      type: "UpdateCustomerAddresses";
      addresses: RemoteAddress[];
      defaultShipping: number | null;
      defaultBilling: number | null;
    }
  | {
      type: "AddCustomerAddress";
      address: RemoteAddress;
    }
  | {
      type: "UpdateCustomerAddress";
      address: RemoteAddress;
    }
  | {
      type: "SetCustomerAddressDefaultShipping";
      addressID: number;
    }
  | {
      type: "SetCustomerAddressDefaultBilling";
      addressID: number;
    }
  | {
      type: "RemoveCustomerAddress";
      result: boolean;
      addressID: number;
    }
  | {
      type: "UpdateWishlistItems";
      items: WishlistItem[];
    }
  | {
      type: "AddProductToWishlist";
      sku: string;
    }
  | {
      type: "RemoveProductFromWishlist";
      sku: string;
    }
  | {
      type: "ClearProduct";
    }
  | {
      type: "UpdateCustomerSubscriptions";
      customerSubscriptions: CustomerSubscription[];
    }
  | {
      type: "UpdateCustomerSubscription";
      customerSubscription: CustomerSubscription;
    }
  | {
      type: "UpdateOrderDetailsSalesOrder";
      order: OrderDetailsPartialOrder;
    };

export const RepositoryContext = React.createContext<RepositoryContextValue>(
  null as any
);

const initialState: RepositoryState = {
  product: {
    likedProductSKU: {},
    wishlistItemByProductSKU: {},
  },
  customer: null,
  isLogoutBySectionExpired: false,
  customerAddresses: [],
  defaultShipping: null,
  defaultBilling: null,
  customerSubscriptions: null,
  customerSubscriptionMap: {},
  orderDetailsPartialOrderMap: {},
};

/* eslint-disable complexity */
function repositoryStateReducer(
  state: RepositoryState,
  action: RepositoryAction
): RepositoryState {
  return produce(state, draft => {
    switch (action.type) {
      case "UpdateWishlistItems": {
        draft.product.likedProductSKU = {};
        draft.product.wishlistItemByProductSKU = {};
        action.items.forEach(wishListItem => {
          const { product } = wishListItem;
          draft.product.likedProductSKU[product.sku] = true;
          draft.product.wishlistItemByProductSKU[product.sku] = wishListItem;
        });
        break;
      }
      case "AddProductToWishlist": {
        draft.product.likedProductSKU[action.sku] = true;
        break;
      }
      case "RemoveProductFromWishlist": {
        draft.product.likedProductSKU[action.sku] = undefined;
        draft.product.wishlistItemByProductSKU[action.sku] = undefined;
        break;
      }
      case "UpdateCustomer": {
        draft.customer = action.customer;
        draft.isLogoutBySectionExpired = false;
        break;
      }
      case "SectionExpired": {
        draft.customer = null;
        draft.customerAddresses = [];
        draft.product.likedProductSKU = {};
        draft.isLogoutBySectionExpired = true;
        draft.customerSubscriptions = null;
        draft.customerSubscriptionMap = {};
        draft.orderDetailsPartialOrderMap = {};
        break;
      }
      case "Logout": {
        draft.customer = null;
        draft.customerAddresses = [];
        draft.product.likedProductSKU = {};
        draft.isLogoutBySectionExpired = false;
        draft.customerSubscriptions = null;
        draft.customerSubscriptionMap = {};
        draft.orderDetailsPartialOrderMap = {};
        break;
      }
      case "UpdateCustomerAddresses": {
        draft.customerAddresses = action.addresses;
        draft.defaultShipping = action.defaultShipping;
        draft.defaultBilling = action.defaultBilling;
        break;
      }
      case "AddCustomerAddress": {
        draft.customerAddresses = [...draft.customerAddresses, action.address];
        if (action.address.defaultShipping) {
          draft.defaultShipping = action.address.id;
        }
        if (action.address.defaultBilling) {
          draft.defaultBilling = action.address.id;
        }
        break;
      }
      case "UpdateCustomerAddress": {
        draft.customerAddresses = draft.customerAddresses.reduce(
          (acc, address, index) => {
            if (address.id !== action.address.id) {
              return acc;
            }

            acc[index] = address;
            return acc;
          },
          draft.customerAddresses
        );
        if (action.address.defaultShipping) {
          draft.defaultShipping = action.address.id;
        }
        if (action.address.defaultBilling) {
          draft.defaultBilling = action.address.id;
        }
        break;
      }
      case "SetCustomerAddressDefaultBilling": {
        draft.customerAddresses = draft.customerAddresses.map(address => {
          if (address.id !== action.addressID) {
            address.defaultBilling = false;
            return address;
          }
          address.defaultBilling = true;
          return address;
        });
        break;
      }
      case "SetCustomerAddressDefaultShipping": {
        draft.customerAddresses = draft.customerAddresses.map(address => {
          if (address.id !== action.addressID) {
            address.defaultShipping = false;
            return address;
          }
          address.defaultShipping = true;
          return address;
        });
        break;
      }
      case "RemoveCustomerAddress": {
        if (!action.result) {
          break;
        }

        draft.customerAddresses = draft.customerAddresses.filter(
          address => address.id !== action.addressID
        );
        break;
      }
      case "ClearProduct": {
        draft.product = initialState.product;
        break;
      }
      case "UpdateCustomerSubscriptions": {
        const { customerSubscriptions } = action;
        draft.customerSubscriptions = customerSubscriptions;
        for (const customerSubscription of customerSubscriptions) {
          if (
            customerSubscription.subscription &&
            customerSubscription.subscription.subscriptionId
          ) {
            draft.customerSubscriptionMap[
              customerSubscription.subscription.subscriptionId
            ] = customerSubscription;
          }
        }
        break;
      }
      case "UpdateCustomerSubscription": {
        const { customerSubscription } = action;
        draft.customerSubscriptions = draft.customerSubscriptions
          ? draft.customerSubscriptions.map(c => {
              if (
                c.subscription &&
                c.subscription.subscriptionId &&
                customerSubscription.subscription &&
                customerSubscription.subscription.subscriptionId &&
                c.subscription.subscriptionId ===
                  customerSubscription.subscription.subscriptionId
              ) {
                return customerSubscription;
              }
              return c;
            })
          : null;
        if (
          customerSubscription.subscription &&
          customerSubscription.subscription.subscriptionId
        ) {
          draft.customerSubscriptionMap[
            customerSubscription.subscription.subscriptionId
          ] = customerSubscription;
        }
        break;
      }
      case "UpdateOrderDetailsSalesOrder": {
        const { order } = action;
        draft.orderDetailsPartialOrderMap[`${order.entityID}`] = order;
        break;
      }
      default:
        break;
    }
  });
}
/* eslint-enable complexity */

export function useRepositoryStateReducer() {
  const [state, dispatch] = useReducer(repositoryStateReducer, initialState);

  return useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );
}
