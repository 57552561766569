import React, { useCallback } from "react";
import cn from "classnames";

import { Address } from "../../models/Customer";

import AddressCard from "../AddressCard";

import styles from "./SelectAddressCard.module.scss";

interface Props {
  selected: boolean;
  title: string;
  address: Address;
  onClick: (address: Address) => void;
}

const SelectAddressCard: React.FC<Props> = props => {
  const { selected, title, address, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(address);
  }, [onClick, address]);

  return (
    <div
      className={cn(styles.container, {
        [styles.containerActive]: selected,
      })}
      onClick={handleClick}
    >
      <div className={styles.header}>
        <div className={styles.headerLeft}>{title}</div>
        <div className={styles.headerRight}>
          <div
            className={cn(styles.indicator, {
              [styles.indicatorActive]: selected,
            })}
          />
        </div>
      </div>
      <AddressCard address={address} />
    </div>
  );
};

export default SelectAddressCard;
