import React, { useCallback, useContext, useMemo, useRef } from "react";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";
import { isiOS, isAndroid } from "../../utils/Platform";
import { useRefToRefObject } from "../../utils/Ref";
import LocalizedInput from "../LocalizedInput";
import { CLContentContext } from "../CLContent";

import styles from "./Input.module.scss";

interface Props {
  className?: string;
  fieldClassName?: string;
  type?: string;
  titleMessageID?: MessageID;
  titleWidth?: number;
  placeholderId?: MessageID;
  value: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  showClearButton?: boolean;
  disabled?: boolean;
  autoCorrect?: "on" | "off";
  autoCapitalize?: "on" | "off";
  autoComplete?: "on" | "off";
  nextFieldRef?: React.RefObject<HTMLInputElement>;
  required?: boolean;
  maxLength?: number;
}

const WrappedInput = (props: Props, ref?: React.Ref<HTMLInputElement>) => {
  const {
    className,
    fieldClassName,
    type = "text",
    titleMessageID,
    titleWidth = 104,
    value,
    onChange,
    onBlur,
    onKeyPress,
    placeholderId,
    showClearButton = false,
    isSuccess,
    isError,
    errorMessage,
    disabled,
    autoCorrect,
    autoCapitalize,
    autoComplete,
    nextFieldRef,
    required,
    maxLength,
  } = props;

  const inputRef = useRefToRefObject(ref);

  const clearClickedRef = useRef(false);

  const handleClearMouseDown = useCallback(() => {
    clearClickedRef.current = true;
  }, []);

  const handleClearMouseUp = useCallback(() => {
    clearClickedRef.current = false;
  }, []);

  const handleClearClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (onChange) {
        onChange("");
      }
      // Focus the input again
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [onChange, inputRef]
  );

  const clContentContext = useContext(CLContentContext);

  const handleFocus = useCallback(
    async el => {
      if ((isiOS() || isAndroid()) && clContentContext && el.target) {
        const { target } = el;
        // Force rerender the element by update the style to update cursor position
        const orgTransform = target.style.transform;
        target.style.transform = "translateZ(0)";
        await clContentContext.scrollToElement(target, 100);
        target.style.transform = orgTransform;
      }
    },
    [clContentContext]
  );

  const handleBlur = useCallback(() => {
    // Prevent calling blur when clear button is in interaction
    // The input will be focused again after clear and
    // can be blurred again
    if (clearClickedRef.current) {
      return;
    }
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const titleColumnStyle = useMemo(
    () => ({
      width: `${titleWidth}px`,
    }),
    [titleWidth]
  );

  return (
    <div className={className}>
      <label
        className={cn(fieldClassName, styles.input, {
          [styles.inputError]: isError,
        })}
      >
        {titleMessageID && (
          <div className={styles.titleColumn} style={titleColumnStyle}>
            <span className={cn({ [styles.required]: required })}>
              <LocalizedText messageID={titleMessageID} />
            </span>
          </div>
        )}
        <div className={styles.inputColumn}>
          <LocalizedInput
            type={type}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyPress={onKeyPress}
            placeholderId={placeholderId}
            className={styles.inputBox}
            disabled={disabled}
            autoCorrect={autoCorrect}
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            nextFieldRef={nextFieldRef}
            ref={inputRef}
            maxLength={maxLength}
          />
        </div>
        {isSuccess && <div className={styles.successIcon} />}
        {isError && <div className={styles.errorIcon} />}
        {showClearButton && !!value && (
          <button
            className={styles.clear}
            onMouseDown={handleClearMouseDown}
            onMouseUp={handleClearMouseUp}
            onMouseLeave={handleClearMouseUp}
            onMouseOut={handleClearMouseUp}
            onClick={handleClearClick}
            type="button"
          />
        )}
      </label>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default React.forwardRef(WrappedInput);
