import { useState, useCallback } from "react";
import { ScrollDetail } from "@ionic/core";

export function useIsContentScrolledToTop() {
  const [isContentScrolledToTop, setIsContentScrolledToTop] = useState<boolean>(
    true
  );

  const handleIonScroll = useCallback((e: CustomEvent<ScrollDetail>) => {
    const { scrollTop } = e.detail;
    if (scrollTop === 0) {
      setIsContentScrolledToTop(true);
    } else {
      setIsContentScrolledToTop(false);
    }
  }, []);

  return { isContentScrolledToTop, handleIonScroll };
}
