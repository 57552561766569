export interface MediaContent {
  id: number;
  label: string;
  mediaType: "image" | "video";
  position: number;
  filePath: string;
  disabled: boolean;
  videoContent: {
    videoTitle: string;
    url: string;
  } | null;
}

export const MediaContentGraphQLAttributes = `
  id
  label
  mediaType: media_type
  position
  filePath: file
  disabled
  videoContent: video_content {
    title: video_title
    url: video_url
  }
`;

export function sortMediaContents(
  mediaContents: MediaContent[]
): MediaContent[] {
  return mediaContents.slice().sort((a, b) => a.position - b.position);
}
