import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";

import ConfigContext from "../../contexts/ConfigContext";
import { withProfileSession } from "../../contexts/ProfileSessionContext";
import { useCategoryTreeMap } from "../../repository/CategoryRepository";
import { parseQueryParameters } from "../../utils/Url";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { pageView } from "../../utils/GTM";
import { useIsContentScrolledToTop } from "../../utils/IonContent";
import { RedemptionPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { useCurrentTab } from "../../navigation/routes";
import { useIntl } from "../../i18n/Localization";
import useViewEnterForAsyncContent from "../../hook/useViewEnterForAsyncContent";

import CLContent from "../CLContent";
import { SingleCategory } from "../SingleCategoryPage";
import MainTabPageNavBar from "../MainTabPageNavBar";

const RedemptionPage: React.FC = () => {
  const location = useLocation<{ forceRerender?: boolean; search: string }>();

  const currentTab = useCurrentTab();

  const { locale } = useIntl();
  const ionLifeCycleContext = useCLIonLifeCycleContext();

  const { appConfig } = useContext(ConfigContext);

  const [search, setSearch] = useState(location.search);

  const categoryId = useMemo<number | null>(() => {
    if (appConfig == null) {
      return null;
    }
    const appConfigLocalized = appConfig[locale];
    if (appConfigLocalized == null) {
      return null;
    }
    const { redemptionTabCategoryId } = appConfigLocalized;
    if (redemptionTabCategoryId == null || redemptionTabCategoryId === "0") {
      return null;
    }
    const categoryIdNumber = parseInt(redemptionTabCategoryId, 10);
    if (isNaN(categoryIdNumber)) {
      return null;
    }
    return categoryIdNumber;
  }, [appConfig, locale]);

  const [shouldForceRerender, setShouldForceRerender] = useState(false);
  const [forceGenerateQueryParams, setForceGenerateQueryParams] = useState(0);

  useEffect(() => {
    if (location.state && location.state.forceRerender) {
      setShouldForceRerender(true);
    }
  }, [location]);

  useEffect(() => {
    if (shouldForceRerender) {
      setShouldForceRerender(false);
      setSearch(location.search);
      setForceGenerateQueryParams(k => k + 1);
    }
  }, [shouldForceRerender, location]);

  const queryParams = useMemo(() => {
    const res = parseQueryParameters(search);
    const cat = res.cat;
    if (cat != null) {
      res.category_id = cat;
    }
    return res;
  }, [search, forceGenerateQueryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const categoryTreeMap = useCategoryTreeMap();
  const categoryTree = categoryId ? categoryTreeMap[categoryId] : null;

  const [viewDidEnter, setViewDidEnter] = useState(false);

  const handleViewDidEnter = useCallback(() => {
    if (!categoryTree) {
      return;
    }
    pageView({ page: "Redemption", categoryName: categoryTree.name });
    setViewDidEnter(true);
  }, [categoryTree]);

  useViewEnterForAsyncContent(
    categoryTree,
    ionLifeCycleContext,
    handleViewDidEnter
  );

  const {
    isContentScrolledToTop,
    handleIonScroll,
  } = useIsContentScrolledToTop();

  const contentRef = useRef<HTMLIonContentElement>(null);

  return (
    <>
      <MainTabPageNavBar title={categoryTree && categoryTree.name} />
      <CLContent ref={contentRef} onIonScroll={handleIonScroll}>
        {categoryId == null ? null : (
          <SingleCategory
            categoryId={categoryId}
            viewDidEnter={viewDidEnter}
            queryParams={queryParams}
            currentTab={currentTab}
            ionLifeCycleContext={ionLifeCycleContext}
            noProductsBackAction="CLEAR_FILTER"
            contentRef={contentRef}
            isContentScrolledToTop={isContentScrolledToTop}
          />
        )}
      </CLContent>
    </>
  );
};

export default withProfileSession(RedemptionPageSession, RedemptionPage);
