import React from "react";
import {
  Components,
  FormattedMessage,
  Values,
} from "@oursky/react-messageformat";

import { MessageID } from "./translations/type";

export type MessageArgs = Values;

interface Props {
  messageID: MessageID;
  messageArgs?: MessageArgs;
  components?: Components;
}
const LocalizedText: React.FunctionComponent<Props> = React.memo(props => {
  const { messageID, messageArgs, components } = props;
  return (
    <FormattedMessage
      id={messageID}
      values={messageArgs}
      components={components}
    />
  );
});
LocalizedText.displayName = "LocalizedText";

export default LocalizedText;
