import { useMemo } from "react";

export default function isHTMLStringVisible(htmlString: string) {
  const x = document.createElement("div");
  x.innerHTML = htmlString;
  const hasIframe = x.getElementsByTagName("iframe").length > 0;
  const hasImg = x.getElementsByTagName("img").length > 0;
  if (x.innerText.trim() || hasIframe || hasImg) {
    return true;
  }
  return false;
}

export function useIsHTMLStringVisible(htmlString: string) {
  return useMemo(() => isHTMLStringVisible(htmlString), [htmlString]);
}
