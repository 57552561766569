import { ApolloClient, Context, gql } from "@apollo/client";
import isString from "lodash/isString";
import { Maybe } from "../types/Maybe";
import { alias } from "../utils/GraphQL";

type OperationInput = {
  [key in string]: string | string[] | OperationInput | OperationInput[];
};

/**
 * In the form of graphql request
 * ${alias}: ${operation}(${operationInput}) { ${initFields | lastFields} }
 */
export interface MutationDefinition {
  input: { [key in string]: string };
  variables: { [key in string]: unknown };
  alias?: Maybe<string>;
  operation: string;
  operationInput: OperationInput;
  initFields: string;
  lastFields: string;
}

// Specify the expected return type of last mutation T
export async function runMutations<T>(
  client: ApolloClient<any>,
  context: Context,
  operationName: string,
  ...definitions: MutationDefinition[]
) {
  if (definitions.length === 0) {
    throw new Error("No mutations are going to be run");
  }
  const variables = definitions.reduce<{ [key in string]: unknown }>(
    (prev, curr) => ({ ...prev, ...curr.variables }),
    {}
  );
  const lastDefinition = definitions[definitions.length - 1];
  const lastName = lastDefinition.alias || lastDefinition.operation;
  const result = await client.mutate<{ [key in string]: T }, typeof variables>({
    context,
    mutation: gql(getGraphQLRequest(operationName, definitions)),
    variables,
  });

  return result.data ? result.data[lastName] : null;
}

function makeMutation(
  operation: string,
  operationInput: string,
  fields: string
) {
  return `${operation}(${operationInput}) { ${fields} }`;
}

export function operationInputToString(operationInput: OperationInput): string {
  return Object.keys(operationInput)
    .map(k => {
      const v = operationInput[k];
      if (Array.isArray(v)) {
        const _v: string[] = [];
        for (const x of v) {
          if (isString(x)) {
            _v.push(x);
          } else {
            _v.push(`{ ${operationInputToString(x)} }`);
          }
        }
        return `${k}: [${_v.join(", ")}]`;
      }
      if (isString(v)) {
        return `${k}: ${v}`;
      }
      return `${k}: { ${operationInputToString(v)} }`;
    })
    .join(", ");
}

function getGraphQLRequest(
  operationName: string,
  definitions: MutationDefinition[]
) {
  if (definitions.length === 0) {
    throw new Error("No mutations are going to be run");
  }
  const input = definitions.reduce<{ [key in string]: string }>(
    (prev, curr) => ({ ...prev, ...curr.input }),
    {}
  );
  return `mutation ${operationName}(
  ${Object.keys(input)
    .map(k => `$${k}:${input[k]}`)
    .join("\n")}
) {
  ${definitions
    .map((b, i) =>
      alias(
        makeMutation(
          b.operation,
          operationInputToString(b.operationInput),
          i === definitions.length - 1 ? b.lastFields : b.initFields
        ),
        b.alias
      )
    )
    .join("\n")}
}`;
}
