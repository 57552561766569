import { useMemo, useState } from "react";

export function useModalState(
  initialIsOpen: boolean = false
): [boolean, () => void, () => void] {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const present = useMemo(() => setIsOpen.bind(null, true), [setIsOpen]);
  const dismiss = useMemo(() => setIsOpen.bind(null, false), [setIsOpen]);
  return [isOpen, present, dismiss];
}
