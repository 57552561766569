import { useApolloClient } from "@apollo/client";
import {
  fetchProductSaleBundles,
  fetchProductSaleBundlesForProductSKUOnly,
} from "../../api/ProductSaleBundle";
import Config from "../../Config";
import { useIntl } from "../../i18n/Localization";
import { ModelKeys } from "../../models/product";
import { Product } from "../../models/ProductDetails";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { fromMaybe, mapNullable } from "../../utils/type";

export interface BundleResource {
  bundle: ProductSaleBundle<Product> | null;
  relatedProductBundleProductIdMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
}

type RequestBundleFn = (
  mainProductId: number,
  relatedProductIds: number[]
) => Promise<BundleResource>;

function useBundleResource(): {
  requestState: ResourcesRequestState<BundleResource>;
  startRequesting: RequestBundleFn;
  refresh: RequestBundleFn;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    BundleResource,
    RequestBundleFn
  >({
    localCacheProvider: async (mainProductId, relatedProductIds) => {
      const [bundle, relatedProductBundles] = Config.ENABLE_BUNDLE_SALE
        ? await Promise.all<
            ProductSaleBundle<Product> | null,
            ProductSaleBundle<ModelKeys>[]
          >([
            fetchProductSaleBundles(
              client,
              mainProductId,
              locale,
              "cache-only"
            ).catch(() => null),
            fetchProductSaleBundlesForProductSKUOnly(
              client,
              relatedProductIds,
              locale,
              "cache-only"
            ).catch(() => []),
          ])
        : [null, []];
      return {
        bundle,
        relatedProductBundleProductIdMap: relatedProductBundles.reduce(
          (prev, curr) =>
            fromMaybe(
              prev,
              mapNullable(curr.mainProduct, p => ({ ...prev, [p.id]: curr }))
            ),
          {}
        ),
      };
    },
    remoteResourcesProvider: async (mainProductId, relatedProductIds) => {
      const [bundle, relatedProductBundles] = Config.ENABLE_BUNDLE_SALE
        ? await Promise.all<
            ProductSaleBundle<Product> | null,
            ProductSaleBundle<ModelKeys>[]
          >([
            fetchProductSaleBundles(
              client,
              mainProductId,
              locale,
              "network-only"
            ).catch(() => null),
            fetchProductSaleBundlesForProductSKUOnly(
              client,
              relatedProductIds,
              locale,
              "network-only"
            ).catch(() => []),
          ])
        : [null, []];
      return {
        bundle,
        relatedProductBundleProductIdMap: relatedProductBundles.reduce(
          (prev, curr) =>
            fromMaybe(
              prev,
              mapNullable(curr.mainProduct, p => ({ ...prev, [p.id]: curr }))
            ),
          {}
        ),
      };
    },
  });

  return {
    requestState,
    startRequesting: fetch,
    refresh,
  };
}

export default useBundleResource;
