import React, { useCallback } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

interface CheckboxProps {
  className?: string;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = React.memo(props => {
  const { className, checked, onCheckedChange, children, disabled } = props;
  const onCheckboxClick = useCallback(() => {
    if (disabled) {
      return;
    }
    onCheckedChange(!checked);
  }, [checked, onCheckedChange, disabled]);
  return (
    <div
      className={cn(styles.container, { [styles.disabled]: disabled })}
      onClick={onCheckboxClick}
    >
      <div className={styles.indicatorColumn}>
        <div
          className={cn(styles.checkbox, className, checked && styles.checked)}
        />
      </div>
      {children && <div className={styles.descriptionColumn}>{children}</div>}
    </div>
  );
});

// Cannot use React.memo here because compiler error
// Property 'children' does not exist on type 'IntrinsicAttributes & CheckboxProps'
export default Checkbox;
