import { useApolloClient } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getAggregation, getSortFields } from "../../api/GraphQL";
import ProductAttributeFilterContext from "../../contexts/ProductAttributeFilterContext";
import { useKeepUpdatingRef } from "../../hook/utils";
import { ResolvedHTMLBasedCMSPageContent } from "../../models/cmsBlock";
import {
  defaultProductFilterInfo,
  FilterAttribute,
  getFilterAttributeMapsFromFilters,
  makeGraphQLFilterFromParamsMap,
  ProductFilterInfo,
  RangeFilterAttribute,
  removeRangeFilterAttribute,
  removeSingleLevelAndMultiLevelFilterAttribute,
  SortOrder,
} from "../../models/filter";
import { ModelKeys } from "../../models/product";
import { ProductLabel } from "../../models/ProductLabel";
import { ProductOverview } from "../../models/ProductOverview";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import {
  getResources,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { getBrandTopCmsContent } from "../../repository/CMSPageContentRepository";
import { useGetValidSortFields } from "../../repository/ProductAttributeFilter";
import { PaginationInfo } from "../../repository/State";
import { convertAggregationToFilter } from "../../utils/Filter";
import { profileAsyncAction, profileSyncAction } from "../../utils/performance";
import { SingleBrandPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { getBrand, getProductOverviews } from "./api";
import { Brand } from "./models";
import {
  useSingleBrandPageProductListResources,
  useSingleBrandPageResource,
} from "./repository";

function useViewModel(slug: string, queryParams: { [key in string]: string }) {
  const client = useApolloClient();
  const {
    productAttributeFilterFields,
    productAttributeFilterInputMap,
  } = useContext(ProductAttributeFilterContext);

  const _brand = useMemo(
    () =>
      profileSyncAction(
        SingleBrandPageSession(slug),
        "Load Brand from Cache",
        () => getBrand(client, slug)
      ),
    [client, slug]
  );

  const [brand, setBrand] = useState<Brand | null>(_brand);

  const fixedFilter = useMemo(
    () =>
      brand
        ? makeGraphQLFilterFromParamsMap(
            { brand: `${brand.id}` },
            productAttributeFilterFields,
            productAttributeFilterInputMap
          )
        : null,
    [brand, productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const queryFilter = useMemo(
    () =>
      makeGraphQLFilterFromParamsMap(
        queryParams,
        productAttributeFilterFields,
        productAttributeFilterInputMap
      ),
    [queryParams, productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const filter = useMemo(() => ({ ...fixedFilter, ...queryFilter }), [
    fixedFilter,
    queryFilter,
  ]);

  const aggregations = useMemo(
    () =>
      profileSyncAction(
        SingleBrandPageSession(slug),
        "Load Filters from Cache",
        () => getAggregation(client, "", filter)
      ),
    [slug, client, filter]
  );

  const filterTypes = useMemo(
    () => (aggregations ? aggregations.map(convertAggregationToFilter) : []),
    [aggregations]
  );

  const _filterAttributeMapsFromFiters = useMemo(
    () =>
      getFilterAttributeMapsFromFilters(
        filterTypes,
        queryParams,
        productAttributeFilterInputMap
      ),
    [filterTypes, queryParams, productAttributeFilterInputMap]
  );

  const [
    {
      singleLevelFilterAttributeMap,
      multiLevelLevelFilterAttributeMap,
      rangeFilterAttributeMap,
    },
    setFilterAttributeMap,
  ] = useState(_filterAttributeMapsFromFiters);

  const getValidSortFields = useGetValidSortFields();

  const _sortFields = useMemo(
    () =>
      getValidSortFields(
        profileSyncAction(
          SingleBrandPageSession(slug),
          "Load Sort Fields from Cache",
          () => getSortFields(client, "", filter)
        )
      ),
    [getValidSortFields, client, filter, slug]
  );

  const [sortFields, setSortFields] = useState(_sortFields);

  const sortRows = useMemo(() => {
    if (sortFields) {
      return sortFields.sortFieldOptions;
    }
    return [];
  }, [sortFields]);

  const defaultSortField = sortFields ? sortFields.defaultSortField : null;

  const topCmsId = brand ? brand.topCmsId : null;

  const _topCmsContent = useMemo(
    () => (topCmsId ? getBrandTopCmsContent(client, topCmsId) : null),
    [topCmsId, client]
  );

  const [
    topCmsContent,
    setTopCmsContent,
  ] = useState<ResolvedHTMLBasedCMSPageContent | null>(_topCmsContent);

  const [, fetchBrandPageResource] = useSingleBrandPageResource(
    slug,
    queryParams
  );

  const [
    brandProductOverviewsRequestState,
    searchNextProductOverviews,
    refreshSearchProductOverviews,
  ] = useSingleBrandPageProductListResources(
    brand ? parseInt(brand.id, 10) : null
  );

  const [isProductListLoadingMore, setIsProductListLoadingMore] = useState(
    false
  );

  const [productFilterInfo, setProductFilterInfo] = useState<ProductFilterInfo>(
    {
      ...defaultProductFilterInfo({
        sortField: defaultSortField,
        sortOrder: SortOrder.ascending,
      }),
      attributeMap: singleLevelFilterAttributeMap,
      multiLevelAttributeMap: multiLevelLevelFilterAttributeMap,
      rangeAttributeMap: rangeFilterAttributeMap,
    }
  );

  const productFilterInfoRef = useKeepUpdatingRef(productFilterInfo);

  const applyProductFilterInfo = useCallback(
    async (_productFilterInfo: ProductFilterInfo) => {
      const newProductFilterInfo = {
        ...productFilterInfo,
        ..._productFilterInfo,
      };
      setProductFilterInfo(newProductFilterInfo);
      await refreshSearchProductOverviews(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshSearchProductOverviews]
  );

  const clearFilter = useCallback(
    async (appliedFilter: FilterAttribute) => {
      const newProductFilterInfo = removeSingleLevelAndMultiLevelFilterAttribute(
        productFilterInfo,
        appliedFilter
      );
      setProductFilterInfo(newProductFilterInfo);
      await refreshSearchProductOverviews(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshSearchProductOverviews]
  );

  const clearRangeFilter = useCallback(
    async (appliedFilter: RangeFilterAttribute) => {
      const newProductFilterInfo = removeRangeFilterAttribute(
        productFilterInfo,
        appliedFilter
      );
      setProductFilterInfo(newProductFilterInfo);
      await refreshSearchProductOverviews(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshSearchProductOverviews]
  );

  const clearPredefinedCPFilter = useCallback(async () => {
    const newProductFilterInfo = {
      ...productFilterInfo,
      predefinedCPFilter: null,
    };
    setProductFilterInfo(newProductFilterInfo);
    await refreshSearchProductOverviews(1, newProductFilterInfo);
  }, [productFilterInfo, refreshSearchProductOverviews]);

  const clearAllFilter = useCallback(async () => {
    const newProductFilterInfo = defaultProductFilterInfo(
      productFilterInfo.sortAttribute
    );
    setProductFilterInfo(newProductFilterInfo);
    await refreshSearchProductOverviews(1, newProductFilterInfo);
  }, [productFilterInfo, refreshSearchProductOverviews]);

  const productOverviewRequestResponse = useMemo(() => {
    if (!brand) {
      return null;
    }
    return profileSyncAction(
      SingleBrandPageSession(slug),
      "Load Product List from Cache",
      () =>
        getProductOverviews(
          client,
          parseInt(brand.id, 10),
          1,
          productFilterInfo,
          productAttributeFilterInputMap
        )
    );
  }, [slug, brand, client, productFilterInfo, productAttributeFilterInputMap]);

  const [
    productOverviewsPaginationInfo,
    setProductOverviewsPaginationInfo,
  ] = useState<PaginationInfo<ProductOverview>>({
    currentPage: 1,
    items: productOverviewRequestResponse
      ? productOverviewRequestResponse.productOverviews
      : [],
    hasMore: false,
  });

  const productOverviews = productOverviewsPaginationInfo.items;
  const [
    productLabelsByProductIdMap,
    setProductLabelsByProductIdMap,
  ] = useState<{ [key in number]: ProductLabel[] }>({});
  const [bundleByProductIdMap, setBundleByProductIdMap] = useState<
    { [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys> }
  >({});

  useEffect(() => {
    const resources = getResources(brandProductOverviewsRequestState);
    if (!isRequestLoading(brandProductOverviewsRequestState) && resources) {
      const {
        currentPage: page,
        pageSize,
        productIdLabelMap,
        productIdBundleMap,
      } = resources;
      setProductOverviewsPaginationInfo(prev => {
        return {
          currentPage: resources.currentPage,
          items:
            page > prev.currentPage
              ? [...prev.items, ...resources.productOverviews]
              : prev.items
                  .slice(0, (page - 1) * pageSize)
                  .concat(resources.productOverviews),
          hasMore: resources.hasMore,
        };
      });
      setProductLabelsByProductIdMap(prev => ({
        ...prev,
        ...productIdLabelMap,
      }));
      setBundleByProductIdMap(prev => ({
        ...prev,
        ...productIdBundleMap,
      }));
    }
  }, [brandProductOverviewsRequestState]);

  const fetchNext = useCallback(async () => {
    if (
      !productOverviewsPaginationInfo.hasMore ||
      isRequestLoading(brandProductOverviewsRequestState)
    ) {
      return;
    }
    const currentPage = productOverviewsPaginationInfo.currentPage + 1;
    setIsProductListLoadingMore(true);
    await profileAsyncAction(
      SingleBrandPageSession(slug),
      "Load Product List next page from Network",
      () => searchNextProductOverviews(currentPage, productFilterInfo)
    );
    setIsProductListLoadingMore(false);
  }, [
    slug,
    productOverviewsPaginationInfo,
    brandProductOverviewsRequestState,
    searchNextProductOverviews,
    productFilterInfo,
  ]);

  const refresh = useCallback(async () => {
    const brandPageResources = await fetchBrandPageResource();
    setTopCmsContent(brandPageResources.topCmsContent);
    setFilterAttributeMap({
      singleLevelFilterAttributeMap:
        brandPageResources.singleLevelFilterAttributeMap,
      multiLevelLevelFilterAttributeMap:
        brandPageResources.multiLevelLevelFilterAttributeMap,
      rangeFilterAttributeMap: brandPageResources.rangeFilterAttributeMap,
    });

    const newSortFields = getValidSortFields(brandPageResources.sortFields);
    setSortFields(newSortFields);

    const shouldChangeSortAttribute = newSortFields
      ? newSortFields.sortFieldOptions.find(
          s => s.value === productFilterInfoRef.current.sortAttribute.sortField
        ) != null
        ? false
        : true
      : true;

    const newSortField =
      shouldChangeSortAttribute &&
      newSortFields &&
      newSortFields.defaultSortField
        ? newSortFields.defaultSortField
        : productFilterInfoRef.current.sortAttribute.sortField;

    const _productFilterInfo: ProductFilterInfo = {
      ...productFilterInfoRef.current,
      sortAttribute: shouldChangeSortAttribute
        ? {
            sortField: newSortField,
            sortOrder: SortOrder.ascending,
          }
        : productFilterInfoRef.current.sortAttribute,
    };

    setBrand(brandPageResources.brand);
    setProductFilterInfo(_productFilterInfo);
    await profileAsyncAction(
      SingleBrandPageSession(slug),
      "Load Product List from Network",
      () => refreshSearchProductOverviews(1, _productFilterInfo)
    );
  }, [
    slug,
    fetchBrandPageResource,
    getValidSortFields,
    refreshSearchProductOverviews,
    productFilterInfoRef,
  ]);

  const isFullPageLoading = useMemo(() => {
    if (topCmsContent) {
      return false;
    }
    if (
      productOverviewsPaginationInfo.items.length === 0 &&
      !getResources(brandProductOverviewsRequestState) &&
      (brandProductOverviewsRequestState.type === "initial" ||
        isRequestLoading(brandProductOverviewsRequestState))
    ) {
      return true;
    }
    return false;
  }, [
    topCmsContent,
    productOverviewsPaginationInfo,
    brandProductOverviewsRequestState,
  ]);

  return {
    brand,
    topCmsContent,
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
    sortFields: {
      defaultSortField: defaultSortField,
      sortFieldOptions: sortRows,
    },

    productFilterInfo,
    applyProductFilterInfo,
    clearFilter,
    clearRangeFilter,
    clearPredefinedCPFilter,
    clearAllFilter,

    isFullPageLoading,

    productOverviews,
    productLabelsByProductIdMap,
    bundleByProductIdMap,
    brandProductOverviewsRequestState,
    fetchNextProductOverviews: fetchNext,
    isProductListLoadingMore,

    refresh,
  };
}

export default useViewModel;
