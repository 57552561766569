export type IndexMap<K extends string | number | symbol, T> = {
  [P in K]: T | undefined;
};

export function indexMapLength<K extends string | number | symbol, T>(
  indexMap: IndexMap<K, T>
): number {
  return Object.entries(indexMap).filter(([_, v]) => v != null).length;
}

// Later win when the key function returns the same value
// for different objects
export function indexMapBy<T, K extends string | number | symbol>(
  fn: (t: T) => K,
  ts: T[]
): IndexMap<K, T> {
  const res: IndexMap<K, T> = {} as IndexMap<K, T>;
  for (const t of ts) {
    const k = fn(t);
    res[k] = t;
  }
  return res;
}

export function mapNullable<T, U>(
  maybeValue: T | null | undefined,
  map: (value: T) => U
): U | null {
  if (maybeValue == null) {
    return null;
  }
  return map(maybeValue);
}

export function fromMaybe<T>(
  defaultValue: T,
  maybeValue: T | null | undefined
) {
  if (maybeValue != null) {
    return maybeValue;
  }
  return defaultValue;
}

export function apply<T>(
  target: NonNullable<T> | null | undefined,
  op: (target: NonNullable<T>) => void
) {
  if (target) {
    op(target);
  }
}

export function stringLiteral<T extends string>(str: T): T {
  return str;
}

export type Override<T, V> = keyof V extends keyof T
  ? Omit<T, keyof V> & V
  : never;

export type FormError<T extends IndexMap<string, any>> = Partial<
  IndexMap<keyof T, string>
>;

export enum NumericBoolean {
  YES = 1,
  NO = 0,
}
