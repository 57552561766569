import React, { useMemo } from "react";
import { IonIcon } from "@ionic/react";
import numeral from "numeral";
import styles from "./styles.module.scss";
import cn from "classnames";

import {
  multiplyMoneyQuantity,
  formatMoney,
  Money,
  addMoneyAmount,
} from "../../models/Price";
import { PriceRange, ProductType } from "../../models/product";
import { LocalizedText } from "../../i18n/Localization";

import {
  getCrossedMoney,
  getListedMoney,
  getPriceRangeTextMessageID,
  getManufacturerSuggestedRetailPriceMoney,
} from "./utils";

interface Product {
  priceRange: PriceRange | null;
  type: ProductType;
  minClubPoint: number;
  manufacturerSuggestedRetailPrice?: string | null;
  variants?: { product: ConfiguredProduct }[] | null;
}

interface ConfiguredProduct {
  priceRange: PriceRange | null;
  type: ProductType;
  minClubPoint: number;
  manufacturerSuggestedRetailPrice?: string | null;
}

interface ProductBlockPriceViewProps {
  productOfMinValue: Product;
  productOfMaxValue?: Product;
  extraPrice?: number;
  quantity?: number;
  hideDeletedPrice?: boolean;
}

const ProductBlockPriceView: React.FC<ProductBlockPriceViewProps> = React.memo(
  props => {
    const {
      productOfMinValue,
      productOfMaxValue,
      hideDeletedPrice,
      extraPrice,
      quantity,
    } = props;

    const manufacturerSuggestedRetailPriceMoney = useMemo(
      () => getManufacturerSuggestedRetailPriceMoney(productOfMinValue),
      [productOfMinValue]
    );
    const crossedMoney = useMemo(
      () =>
        getCrossedMoney(
          productOfMinValue,
          productOfMaxValue || productOfMinValue
        ),
      [productOfMinValue, productOfMaxValue]
    );
    const priceRangeTextMessageID = useMemo(
      () =>
        getPriceRangeTextMessageID(
          productOfMinValue,
          productOfMaxValue || productOfMinValue
        ),
      [productOfMinValue, productOfMaxValue]
    );
    const listedMoney = useMemo(
      () =>
        getListedMoney(
          productOfMinValue,
          productOfMaxValue || productOfMinValue
        ),
      [productOfMinValue, productOfMaxValue]
    );

    const isSpecialPrice = useMemo(
      () => !!crossedMoney || manufacturerSuggestedRetailPriceMoney,
      [crossedMoney, manufacturerSuggestedRetailPriceMoney]
    );

    return (
      <>
        {manufacturerSuggestedRetailPriceMoney ? (
          <p className={styles.manufacturerSuggestedRetailPrice}>
            {formatMoney(manufacturerSuggestedRetailPriceMoney)}
          </p>
        ) : null}
        {crossedMoney && !hideDeletedPrice ? (
          <p className={styles.deletedPrice}>
            <s className={styles.productOriginalPrice}>
              {formatMoney(crossedMoney)}
            </s>
          </p>
        ) : null}
        {priceRangeTextMessageID ? (
          <p className={styles.asLowAs}>
            <LocalizedText messageID={priceRangeTextMessageID} />
          </p>
        ) : null}
        <div
          className={cn(styles.productPriceText, styles.listedPrice, {
            [styles.specialPrice]: isSpecialPrice,
          })}
        >
          {listedMoney ? (
            <>
              {listedMoney.type === "single" ? (
                <>
                  {formatMoney(
                    multiplyMoneyQuantity(
                      addMoneyAmount(extraPrice || 0, listedMoney.money),
                      quantity == null ? 1 : quantity
                    )
                  )}
                </>
              ) : listedMoney.type === "singleMinCP" ? (
                <>
                  <IonIcon name="club-point" className={styles.clubPointIcon} />
                  {numeral(
                    listedMoney.minClubPoint * (quantity == null ? 1 : quantity)
                  ).format("0,0")}{" "}
                  +{" "}
                  {formatMoney(
                    multiplyMoneyQuantity(
                      addMoneyAmount(extraPrice || 0, listedMoney.money),
                      quantity == null ? 1 : quantity
                    )
                  )}
                </>
              ) : listedMoney.type === "cpOnly" ? (
                <>
                  <IonIcon name="club-point" className={styles.clubPointIcon} />
                  {numeral(
                    listedMoney.clubPoint * (quantity == null ? 1 : quantity)
                  ).format("0,0")}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </>
    );
  }
);

ProductBlockPriceView.displayName = "ProductBlockPriceView";

export default ProductBlockPriceView;

interface ProductPriceViewProps {
  product: Product;
  extraPrice: string;
}

export const ProductPriceViewInMessageFormat: React.FC<
  ProductPriceViewProps
> = props => {
  const { product, extraPrice } = props;
  const { minClubPoint } = product;

  const minMoney = useMemo(() => {
    function addExtraMoney(money: Money): Money {
      if (extraPrice && !isNaN(parseFloat(extraPrice))) {
        return addMoneyAmount(parseFloat(extraPrice), money);
      }
      return money;
    }
    const { priceRange } = product;
    if (priceRange == null) {
      return null;
    }
    return addExtraMoney(priceRange.minimumPrice.finalPrice);
  }, [product, extraPrice]);

  return (
    <span>
      {minClubPoint ? (
        <>
          <IonIcon name="club-point" className={styles.clubPointIcon} />
          {numeral(minClubPoint).format("0,0")}
        </>
      ) : null}
      {minClubPoint && minMoney && minMoney.value > 0 ? <> + </> : null}
      {minMoney != null && minMoney.value >= 0 ? (
        <span>{formatMoney(minMoney)}</span>
      ) : null}
    </span>
  );
};
