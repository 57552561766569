import React from "react";
import cn from "classnames";

import CLLink from "../navigation/CLLink";
import { LocalizedText } from "../../i18n/Localization";

import styles from "./styles.module.scss";

interface Props {
  href: string;
  className?: string;
  onClick?: () => void;
}

const BrandIndexButton: React.FC<Props> = React.memo(props => {
  const { href, className, onClick } = props;
  return (
    <CLLink to={href} onClick={onClick} className={cn(styles.link, className)}>
      <div className={styles.button}>
        <div className={styles.icon} />
        <div className={styles.text}>
          <LocalizedText messageID="page.brand_index.title" />
        </div>
      </div>
    </CLLink>
  );
});

export default BrandIndexButton;
