import React, { useState, useMemo, useCallback, useEffect } from "react";

import {
  Token,
  ReadState,
  Message,
  NotificationEnableState,
} from "../../models/OPNSPushNotification";

import * as Storage from "../../storage";

export interface PushNotificationContext {
  enablePushNotification: boolean;
  toggleEnablePushNotification: () => void;
  enablePushNotificationInitialized: boolean;
  deviceToken: Token | null;
  setDeviceToken: (token: Token | null) => void;
  updateMessages: (messages: Message[]) => void;
  getMessagesByIds: (messageIds: string[]) => Message[];
  messageMarkRead: (messageId: string) => void;
  pushNotificationMessagesPageLastRead: Date | null;
  pushNotificationMessagesPageLastReadInitialized: boolean;
  unreadMessageCount: number;
  setUnreadMessageCount: (count: number) => void;
  addUnreadMessageCount: () => void;
  promotionNotification: NotificationEnableState;
  setPromotionNotification: (value: NotificationEnableState) => void;
  orderNotification: NotificationEnableState;
  setOrderNotification: (value: NotificationEnableState) => void;
}

export const PushNotificationContext = React.createContext<
  PushNotificationContext
>(null as any);

export const PushNotificationProvider: React.FC = props => {
  const [
    enablePushNotificationInitialized,
    setEnablePushNotificationInitialized,
  ] = useState(false);
  const [enablePushNotification, setEnablePushNotification] = useState(false);
  const [deviceToken, setDeviceToken] = useState<Token | null>(null);

  useEffect(() => {
    (async () => {
      const enabled = (await Storage.hasEnablePushNotificationEntry())
        ? await Storage.getEnablePushNotification()
        : true;
      setEnablePushNotification(enabled);
      setEnablePushNotificationInitialized(true);
    })();
  }, []);

  const [messageMap, setMessageMap] = useState<{ [key in string]: Message }>(
    {}
  );

  const [
    pushNotificationMessagesPageLastRead,
    setPushNotificationMessagesPageLastRead,
  ] = useState<Date | null>(null);
  const [
    pushNotificationMessagesPageLastReadInitialized,
    setPushNotificationMessagesPageLastReadInitialized,
  ] = useState(false);

  useEffect(() => {
    (async () => {
      const p = await Storage.getPushNotificationMessagesPageLastRead();
      setPushNotificationMessagesPageLastRead(p);
      setPushNotificationMessagesPageLastReadInitialized(true);
    })();
  }, []);

  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const toggleEnablePushNotification = useCallback(async () => {
    if (enablePushNotification) {
      setEnablePushNotification(false);
      Storage.setEnablePushNotification(false);
    } else {
      setEnablePushNotification(true);
      Storage.setEnablePushNotification(true);
    }
  }, [enablePushNotification]);

  const updateMessages = useCallback((messages: Message[]) => {
    setMessageMap(m => {
      const newItemMap: { [key in string]: Message } = {};
      for (const message of messages) {
        newItemMap[message.id] = message;
      }
      return {
        ...m,
        ...newItemMap,
      };
    });
  }, []);

  const messageMarkRead = useCallback(
    (messageId: string) => {
      const message = messageMap[messageId];
      setMessageMap(m => ({
        ...m,
        [messageId]: {
          ...message,
          read: ReadState.Read,
        },
      }));
      setUnreadMessageCount(k => Math.max(k - 1, 0));
    },
    [messageMap]
  );

  const getMessagesByIds = useCallback(
    (messageIds: string[]): Message[] => {
      const messages: Message[] = [];
      for (const messageId of messageIds) {
        const message = messageMap[messageId];
        if (message) {
          messages.push(message);
        }
      }
      return messages;
    },
    [messageMap]
  );

  const addUnreadMessageCount = useCallback(
    () => setUnreadMessageCount(k => k + 1),
    []
  );

  const [promotionNotification, setPromotionNotification] = useState(
    NotificationEnableState.Yes
  );

  const [orderNotification, setOrderNotification] = useState(
    NotificationEnableState.Yes
  );

  const value = useMemo(
    () => ({
      enablePushNotification,
      enablePushNotificationInitialized,
      toggleEnablePushNotification,
      deviceToken,
      setDeviceToken,
      updateMessages,
      getMessagesByIds,
      messageMarkRead,
      pushNotificationMessagesPageLastRead,
      pushNotificationMessagesPageLastReadInitialized,
      unreadMessageCount,
      setUnreadMessageCount,
      addUnreadMessageCount,
      promotionNotification,
      setPromotionNotification,
      orderNotification,
      setOrderNotification,
    }),
    [
      enablePushNotification,
      enablePushNotificationInitialized,
      toggleEnablePushNotification,
      deviceToken,
      updateMessages,
      getMessagesByIds,
      messageMarkRead,
      pushNotificationMessagesPageLastRead,
      pushNotificationMessagesPageLastReadInitialized,
      unreadMessageCount,
      setUnreadMessageCount,
      addUnreadMessageCount,
      promotionNotification,
      setPromotionNotification,
      orderNotification,
      setOrderNotification,
    ]
  );

  return (
    <PushNotificationContext.Provider value={value}>
      {props.children}
    </PushNotificationContext.Provider>
  );
};
