import { getPlatforms as ionicGetPlatformats, Platforms } from "@ionic/core";

const getPlatforms = () => ionicGetPlatformats(window);

function isPlatform(platform: Platforms): boolean {
  return getPlatforms().indexOf(platform) > -1;
}

export function isHybrid(): boolean {
  return isPlatform("hybrid");
}

export function isiOS(): boolean {
  return isPlatform("ios");
}

export function isAndroid(): boolean {
  return isPlatform("android");
}

export function isiPad(): boolean {
  return isPlatform("ipad");
}

export function isMobile(): boolean {
  return isPlatform("mobile");
}

export function isTablet(): boolean {
  return isPlatform("tablet");
}

export function isDesktop(): boolean {
  return isPlatform("desktop");
}

export function isCapacitor(): boolean {
  return isPlatform("capacitor");
}

export function select<T>(
  specifics: { [platform in "ios" | "android" | "default"]: T }
): T {
  if (isiOS()) {
    return specifics["ios"];
  } else if (isAndroid()) {
    return specifics["android"];
  }
  return specifics["default"];
}
