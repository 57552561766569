import React, { useMemo, useRef } from "react";
import { LocalizedText } from "../../i18n/Localization";

import { ConfiguredProduct } from "../../models/ProductDetails";

import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";

import { useIsHTMLStringVisible } from "../../utils/IsHTMLStringVisible";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import isValidHTMLTag from "../../utils/IsValidHTMLTag";
import parse from "../../utils/HTMLReactParser";

import CLImage from "../CLImage";

import styles from "./styles.module.scss";

interface Props {
  configuredProduct: ConfiguredProduct;
}

const ProductDescription: React.FC<Props> = props => {
  const { configuredProduct } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const html = useFillSrcUrlScheme(
    useExtractScriptsAndStyles(
      containerRef,
      configuredProduct.shortDescription.html
    )
  );

  useRegisterPublishDeepLinkOnHTMLContent(containerRef, [html]);

  const hasDescription = useIsHTMLStringVisible(html);

  const productDescription = useMemo(() => {
    return parse(html, {
      replace: domNode => {
        if (
          domNode.type === "tag" &&
          domNode.name &&
          !isValidHTMLTag(domNode.name)
        ) {
          return <></>;
        }
        if (domNode.type === "tag" && domNode.name === "img") {
          const { attribs } = domNode;
          return <CLImage htmlAttributes={attribs} />;
        }
        return undefined;
      },
    });
  }, [html]);

  if (!hasDescription) {
    return null;
  }

  return (
    <div className={styles.productDescriptionContainer} ref={containerRef}>
      <div className={styles.productDescriptionTitle}>
        <LocalizedText messageID="page.product_detail.product_description.title" />
        <div className={styles.productDescriptionContent}>
          {productDescription}
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
