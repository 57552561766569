import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useState,
  RefObject,
} from "react";
import { IonIcon } from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import cn from "classnames";
import { History, Location } from "history";

import CLContent from "../CLContent";
import { NavBar, NavBarBackButton } from "../NavBar";
import Checkbox from "../Checkbox";
import { withProviders } from "../Provider";
import CheckoutSteps from "../CheckoutSteps";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import NotEnoughtClubPointBanner from "../NotEnoughtClubPointBanner";
import { LocationState as CheckoutSelectO2oStorePageLocationState } from "../CheckoutSelectO2oStorePage";

import { useAppConfig } from "../../repository/ConfigRepository";
import { useFetchCustomerAddresses } from "../../repository/CustomerRepository";
import { useGetOppCards } from "../../repository/OppCardRepository";
import {
  getResources,
  isRequestLoading,
  isRequestError,
} from "../../models/ResourcesRequestState";
import { getClubPointConversionRate } from "../../models/AppConfig";
import {
  Customer,
  Address,
  isSameAddress,
  isCustomerLinkedToTheClub,
  isCustomerTheClubAccountVerified,
  transformRemoteAddress,
  RemoteAddress,
  ClubMemberCustomer,
  getCustomerClubPoints,
  emptyAddress,
  getCustomerLinkedSSOEmail,
} from "../../models/Customer";
import {
  useCustomer,
  useLinkSocialAccountRequest,
  INVALID_MEMBERSHIP_RESPONSE,
} from "../../repository/AuthRepository";
import {
  Country,
  CountryCode,
  Region,
  RegionCode,
  District,
  DistrictName,
} from "../../models/CountryRegion";

import {
  Context,
  SelfPickup,
  BillingInfoOption,
  DeliveryAddress,
  BillingInfo,
  State as FillingInfoState,
  OppCardOption,
} from "./Context";
import {
  useCartResource,
  usePaymentMethodMissing,
  getAvailablePaymentMethods,
  useGooglePayAvailable,
  transformAddressToAddressInput,
  transformO2oStoreToAddressInput,
} from "./hook";
import {
  PartialCart,
  PaymentType,
  DeliveryEndpointType,
  DeliveryTimeSlotValue,
  O2oStore,
  CartAddress,
  getAppliedCoupon,
  DeliveryEndpoint,
  areAllItemsVirtual,
  findO2oStore,
  getDefaultShippingMethod,
} from "./models";
import Block from "./Block";
import SelectBlock from "./SelectBlock";
import AddressForm from "./AddressForm";
import SelfPickupForm, { PickupData } from "./SelfPickupForm";
import SelectSavedAddressButton from "./SelectSavedAddressButton";
import Input from "./Input";
import {
  Provider as SelectSavedAddressProvider,
  Context as SelectSavedAddressContext,
} from "./SelectSavedAddress";
import VerifyTheClubAccountBanner from "./VerifyTheClubAccountBanner";
import { OppCardSelectListView } from "./OppCardSelectList";
import OrderSummary, {
  OrderSummaryExtraItem,
  getMoneyTypeFromMoney,
} from "../OrderSummary";
import SeparatorWithTitle from "../SeparatorWithTitle";
import { PrimaryButton } from "../Button";
import { LoadingView } from "../LoadingView";
import ClubpointWidget from "../ClubpointWidget";
import ErrorView from "../ErrorView";

import {
  RootTab,
  getPathForCheckoutConfirmation,
  getPathForAccountTab,
  getPathForEditProfile,
  getPathForChangeEmail,
  getPathForCheckoutSelectO2oStore,
} from "../../navigation/routes";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";
import { IndexMap } from "../../utils/type";
import { isEmpty } from "../../utils/Object";
import { useResetShoppingCartClubPointToBeUsed } from "../../utils/ShoppingCart";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { isRemoteError, CLError } from "../../utils/Error";
import { timeout } from "../../utils/promise";
import { canMakeApplePayPayment } from "../../utils/ApplePay";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { CheckoutSession } from "../../utils/PerformanceRecordStore/sessions";
import { profiledAsyncActionFn } from "../../utils/performance";
import LoadingModalProvider, {
  LoadingModalContextProps,
  withLoadingModal,
} from "../LoadingModalProvider";
import VerifyTheClubModal from "../VerifyTheClubModal/lazy";
import LocalErrorMessage from "../LocalErrorMessage";

import styles from "./FillingInfo.module.scss";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { useLoginWithTheClub } from "../../useCase/AuthUseCase";
import {
  getDiscountBreakdown,
  CartDiscountTypes,
  getMinClubPointUsed,
} from "../../models/cart";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";
import {
  OurNavContext,
  PresentationContext,
  ParentNavContext,
} from "../../our-navigation";
import { useDebounceByCompletion, useKeepUpdatingRef } from "../../hook/utils";
import useOpenUrlWithBrowser from "../../hook/useOpenUrlWithBrowser";
import { useAddInAppToUrl, useAddStoreToUrl } from "../../hook/Url";
import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";

import Config from "../../Config";

import logoVisaImg from "../../resources/logo-visa.svg";
import logoMasterImg from "../../resources/logo-master.svg";
import logoTapAndGo from "../../resources/logo-tap-and-go.svg";
import logoChinaPayImg from "../../resources/logo-chinapay.svg";
import logoApplePayImg from "../../resources/logo-applepay.svg";
import logoAlipayHKImg from "../../resources/logo-alipayhk.svg";
import logoGooglePayImg from "../../resources/logo-googlepay.svg";
import logoInstalmentImg from "../../resources/logo-instalment.svg";
import {
  MutationDefinitionWithCartId,
  OperationKey,
  SetBillingAddressMutationDefinition,
  SetDeliveryEndpointMutationDefinition,
  SetDeliveryTimeSlotMutationDefinition,
  SetEmailAddressMutationDefinition,
  SetPaymentMethodMutationDefinition,
  SetShippingAddressesMutationDefinition,
  SetShippingMethodMutationDefinition,
} from "./mutation";

type ScrollAnchor =
  | "delivery-info"
  | "delivery-info-o2o"
  | "delivery-info-address"
  | "delivery-time"
  | "billing-info"
  | "payment-option"
  | "promotion"
  | "email-address";

function stringIsScrollAnchor(str: string): str is ScrollAnchor {
  return (
    str === "delivery-info" ||
    str === "delivery-info-o2o" ||
    str === "delivery-time" ||
    str === "billing-info" ||
    str === "payment-option" ||
    str === "promotion" ||
    str === "email-address"
  );
}

function isAddressFilled(address: Address) {
  const {
    firstName,
    lastName,
    company,
    telephone,
    roomNumber,
    flatOrFloor,
    block,
    building,
    street,
    district,
    area,
    region,
  } = address;
  return (
    firstName ||
    lastName ||
    company ||
    telephone ||
    roomNumber ||
    flatOrFloor ||
    block ||
    building ||
    street ||
    district ||
    area ||
    region
  );
}

function transformCartAddressToAddress(
  cartAddress: CartAddress,
  countryByCountryCode: IndexMap<CountryCode, Country>,
  regionByRegionCode: IndexMap<RegionCode, Region>,
  districtByDistrictName: IndexMap<DistrictName, District>
): Address {
  const country = cartAddress.country.code
    ? countryByCountryCode[cartAddress.country.code]
    : null;
  const region = cartAddress.region.code
    ? regionByRegionCode[cartAddress.region.code]
    : null;
  const district = cartAddress.city
    ? districtByDistrictName[cartAddress.city]
    : null;
  const [street, building, block, flatOrFloor, roomNumber] = cartAddress.street;
  return {
    firstName: cartAddress.firstname || "",
    lastName: cartAddress.lastname || "",
    company: cartAddress.company || "",
    telephone: cartAddress.telephone || "",
    roomNumber: roomNumber || "",
    flatOrFloor: flatOrFloor || "",
    block: block || "",
    building: building || "",
    street: street || "",
    district: district ? district.id : null,
    area: region ? region.id : null,
    region: country ? country.id : null,
    isDefaultBilling: false,
    isDefaultShipping: false,
  };
}

function transformO2oStoresToSelfPickupData(
  o2oStores: O2oStore[],
  translate: (text: MessageID) => string
): PickupData {
  const regions: PickupData["regions"] = [];
  const mapping: PickupData["mapping"] = {};

  for (const o2oStore of o2oStores) {
    const { region } = o2oStore;
    const regionExists =
      regions.filter(r => r.value === `${region.id}`).length > 0;
    if (!regionExists) {
      regions.push({
        label: region.name,
        value: `${region.id}`,
      });
    }

    let cityMapped = mapping[`${region.id}`];
    if (!cityMapped) {
      cityMapped = [];
      mapping[`${region.id}`] = cityMapped;
    }
    let city = cityMapped.filter(c => c.value === o2oStore.city)[0];
    if (!city) {
      city = {
        value: o2oStore.city,
        label: o2oStore.city,
        o2oStoreTypes: [
          {
            value: "all",
            label: translate("self_pickup_form.store_type.all"),
            o2oStores: [o2oStore],
          },
        ],
      };
      cityMapped.push(city);
    }

    let allStoreType = city.o2oStoreTypes.filter(x => x.value === "all")[0];
    if (!allStoreType) {
      allStoreType = {
        value: "all",
        label: translate("self_pickup_form.store_type.all"),
        o2oStores: [o2oStore],
      };
      city.o2oStoreTypes.push(allStoreType);
    }

    let storeType = city.o2oStoreTypes.filter(
      x => x.value === o2oStore.storeType
    )[0];
    if (!storeType) {
      storeType = {
        value: o2oStore.storeType,
        label: (() => {
          switch (o2oStore.storeType) {
            case "o2o-store":
              return translate("self_pickup_form.store_type.o2o_store");
            case "elocker":
              return translate("self_pickup_form.store_type.elocker");
            case "o2o-fulfillment":
              return translate("self_pickup_form.store_type.o2o_fulfillment");
            default:
              return `${translate("self_pickup_form.store_type.unknown")} (${
                o2oStore.storeType
              })`;
          }
        })(),
        o2oStores: [],
      };
      city.o2oStoreTypes.push(storeType);
    }

    const storeInAll = allStoreType.o2oStores.filter(
      x => x.code === o2oStore.code
    )[0];
    if (!storeInAll) {
      allStoreType.o2oStores.push(o2oStore);
    }

    const store = storeType.o2oStores.filter(x => x.code === o2oStore.code)[0];
    if (!store) {
      storeType.o2oStores.push(o2oStore);
    }
  }

  return {
    regions,
    mapping,
  };
}

export function hasDeliveryInfoForCart(cart: PartialCart | null): boolean {
  if (cart === null || areAllItemsVirtual(cart)) {
    return false;
  }

  const endpointTypes = cart.availableDeliveryEndpoints.map(x => x.type);
  if (
    endpointTypes.indexOf("address") > -1 ||
    endpointTypes.indexOf("o2o-store") > -1
  ) {
    return true;
  }

  return false;
}

function prefillCartForm(
  customer: Customer | null,
  countryByCountryCode: IndexMap<CountryCode, Country>,
  regionByRegionCode: IndexMap<RegionCode, Region>,
  districtByDistrictName: IndexMap<DistrictName, District>,
  fillingInfoState: FillingInfoState,
  cart: PartialCart,
  applePayAvailable: boolean,
  googlePayAvailable: boolean,
  updateDeliveryAddress: (deliveryAddress: DeliveryAddress) => void,
  setDeliveryTime: (deliveryTimeSlot: DeliveryTimeSlotValue) => void,
  updateCustomBillingInfo: (billingAddress: BillingInfo) => void,
  setPaymentType: (paymentType: PaymentType) => void,
  updatePromotionCode: (code: string) => void,
  defaultShipping: number | null,
  defaultBilling: number | null,
  addresses: Address[],
  prefillShipping: boolean = false
) {
  // Try prefill delivery address and delivery time slot if we have address option.
  if (
    prefillShipping &&
    cart.availableDeliveryEndpoints.filter(x => x.type === "address").length > 0
  ) {
    const cartShippingAddress = cart.shippingAddresses[0];
    if (cartShippingAddress) {
      const transformedShippingAddress = transformCartAddressToAddress(
        cartShippingAddress,
        countryByCountryCode,
        regionByRegionCode,
        districtByDistrictName
      );
      if (isAddressFilled(transformedShippingAddress)) {
        // Try prefill delivery address if there are something in shipping address.
        updateDeliveryAddress({
          ...transformedShippingAddress,
        });
      } else if (defaultShipping) {
        // If found not filling anything in shopping cart shipping address,
        // prefill with default shipping
        const defaultShippingAddress = addresses.filter(
          address => address.id === defaultShipping
        )[0];
        if (!defaultShippingAddress) {
          console.warn(
            "Have default shipping address but cannot find that address"
          );
        } else {
          updateDeliveryAddress({
            ...defaultShippingAddress,
          });
        }
      } else {
        if (customer) {
          updateDeliveryAddress({
            ...emptyAddress,
            firstName: customer.firstname,
            lastName: customer.lastname,
          });
        }
      }
    }
    if (cart.selectedDeliveryTimeSlot) {
      setDeliveryTime(cart.selectedDeliveryTimeSlot);
    }
  }

  // Try prefill billing address
  if (cart.billingAddress) {
    const transformedBilling = transformCartAddressToAddress(
      cart.billingAddress,
      countryByCountryCode,
      regionByRegionCode,
      districtByDistrictName
    );
    if (isAddressFilled(transformedBilling)) {
      // Try prefill billing address if there are something in billing address.
      updateCustomBillingInfo({
        ...transformedBilling,
      });
    } else if (defaultBilling) {
      // If found not filling anything in shopping cart billing address,
      // prefill with default billing
      const defaultBillingAddress = addresses.filter(
        address => address.id === defaultBilling
      )[0];
      if (defaultBillingAddress) {
        updateCustomBillingInfo({
          ...defaultBillingAddress,
        });
      }
    } else {
      if (customer) {
        updateCustomBillingInfo({
          ...emptyAddress,
          firstName: customer.firstname,
          lastName: customer.lastname,
        });
      }
    }
  }

  // Try prefill selected payment method
  if (cart.selectedPaymentMethod) {
    setPaymentType(cart.selectedPaymentMethod.code);
  } else {
    if (!fillingInfoState.paymentOption) {
      const availablePaymentMethods = getAvailablePaymentMethods(
        cart,
        applePayAvailable,
        googlePayAvailable
      );
      if (availablePaymentMethods.length > 0) {
        const [availablePaymentMethod] = availablePaymentMethods;
        setPaymentType(availablePaymentMethod.code);
      }
    }
  }

  // Try prefill coupon code
  const appliedCoupon = getAppliedCoupon(cart);
  if (appliedCoupon) {
    updatePromotionCode(appliedCoupon);
  }
}

function getPosition(element: HTMLElement) {
  return {
    x: element.offsetLeft,
    y: element.offsetTop,
  };
}

function haveAddressInAddressBook(address: Address, addresses: Address[]) {
  for (const addr of addresses) {
    if (isSameAddress(addr, address)) {
      return true;
    }
  }
  return false;
}

const FillingInfoLoading: React.FC = () => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingView />
    </div>
  );
};

type FillingInfoReadyProps = {
  cart: PartialCart;
  contentRef: React.RefObject<HTMLIonContentElement>;
  customerAddressesRequest: {
    addresses: RemoteAddress[];
    defaultBilling: number | null;
    defaultShipping: number | null;
  };
  history: History;
  location: Location;
  cartResource: ReturnType<typeof useCartResource>;
  applePayAvailable: boolean;
  googlePayAvailable: boolean;
} & LoadingModalContextProps;

/* eslint-disable complexity */
const FillingInfoReadyImpl: React.FC<FillingInfoReadyProps> = props => {
  useEffect(
    () => addPerformanceRecord(CheckoutSession(), "Filling Info content shown"),
    []
  );

  const {
    cart,
    customerAddressesRequest,
    location,
    cartResource,
    contentRef,
    applePayAvailable,
    googlePayAvailable,
    loadingModalContext,
  } = props;
  const { hash } = location;
  const { navigate } = useContext(OurNavContext);
  const { isOnline } = useContext(NetworkStatusContext);
  const appConfig = useAppConfig();
  const customer = useCustomer();

  const {
    countryByCountryCode,
    regionByRegionCode,
    districtByDistrictName,
    countryByID,
    regionByID,
    districtByID,
  } = useContext(CountryRegionDistrictContext);

  const {
    show: showLoadingModal,
    hide: hideLoadingModal,
    withLoadingModalAsync,
  } = loadingModalContext;

  const presentationContext = useContext(PresentationContext);
  const parentNavContext = useContext(ParentNavContext);

  const {
    resetRequestStates,
    updateDeliveryEndpoint,
    updateDeliveryEndpointError,
    validateSelfPickup,
    updateDeliveryEndpointForO2oStore,
    updateDeliveryEndpointForO2oStoreError,
    updateDeliveryEndpointForO2oStoreFormError,
    updateDeliveryTimeSlot,
    updateDeliveryTimeSlotError,
    applyCoupon,
    applyCouponError,
    shippingAddressFormError,
    validateShippingAddress,
    setShippingAddress,
    setShippingAddressError,
    billingAddressFormError,
    resetBillingAddress,
    validateBillingAddress,
    setBillingAddress,
    setBillingAddressError,
    setPaymentMethod,
    setPaymentMethodError,
    setClubPoint,
    setClubPointRequesting,
    setClubPointError,
    validateEmailAddress,
    setEmailAddress,
    setEmailAddressError,
    runCartMutations,
  } = cartResource;

  const { translate } = useIntl();

  const addresses = useMemo(() => {
    return customerAddressesRequest.addresses.map(address =>
      transformRemoteAddress(address, districtByDistrictName)
    );
  }, [districtByDistrictName, customerAddressesRequest]);

  const {
    state,
    startCheckout,
    selectDeliveryInfoType,
    updateDeliveryAddress,
    setDeliveryAddressSaveInAddressBook,
    updateSelfPickup,
    setShouldSubmitBillingInfo,
    selectBillingInfoOption,
    updateCustomBillingInfo,
    setCustomBillingInfoSaveInAddressBook,
    setDeliveryTime,
    setPaymentType,
    updatePromotionCode,
    setOppCardOption,
    updateEmailAddress,
  } = useContext(Context);

  useEffect(() => {
    if (customer && Config.ENABLE_SET_EMAIL_ADDRESS_ON_CART) {
      const emailAddress =
        getCustomerLinkedSSOEmail(customer, "the-club") || customer.email;
      updateEmailAddress(emailAddress);
    }
  }, [customer, updateEmailAddress]);

  const handleSetDeliveryAddressSaveInAddressBook = useCallback(
    (saveInAddressBook: boolean) => {
      if (saveInAddressBook) {
        actionEvent(
          "Checkout Page",
          "Click",
          "Delivery Info_Saved Address For Future"
        );
      }
      setDeliveryAddressSaveInAddressBook(saveInAddressBook);
    },
    [setDeliveryAddressSaveInAddressBook]
  );

  const handleSetCustomBillingInfoSaveInAddressBook = useCallback(
    (saveInAddressBook: boolean) => {
      if (saveInAddressBook) {
        actionEvent(
          "Checkout Page",
          "Click",
          "Billing Info_Save Address For Future"
        );
      }
      setCustomBillingInfoSaveInAddressBook(saveInAddressBook);
    },
    [setCustomBillingInfoSaveInAddressBook]
  );

  useEffect(() => {
    // Reset checkout context state
    startCheckout();

    const { defaultShipping, defaultBilling } = customerAddressesRequest;
    prefillCartForm(
      customer,
      countryByCountryCode,
      regionByRegionCode,
      districtByDistrictName,
      state,
      cart,
      applePayAvailable,
      googlePayAvailable,
      updateDeliveryAddress,
      setDeliveryTime,
      updateCustomBillingInfo,
      setPaymentType,
      updatePromotionCode,
      defaultShipping,
      defaultBilling,
      addresses
    );

    // Because we have no selected delivery endpoints on cart,
    // we just assume shipping address option is selected
    // and ask server to choose it when checkout init
    if (
      cart.availableDeliveryEndpoints.filter(x => x.type === "address").length >
      0
    ) {
      updateDeliveryEndpoint("address");
    }
    // Only run on did mount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const availablePaymentMethods = useMemo(
    () =>
      getAvailablePaymentMethods(cart, applePayAvailable, googlePayAvailable),
    [cart, applePayAvailable, googlePayAvailable]
  );

  const handlePaymentMethodMissing = useCallback(() => {
    if (availablePaymentMethods.length > 0) {
      const paymentMethod = availablePaymentMethods[0];
      setPaymentType(paymentMethod.code);
      setPaymentMethod(paymentMethod.code).catch(() => {});
    }
  }, [availablePaymentMethods, setPaymentMethod, setPaymentType]);

  usePaymentMethodMissing(
    availablePaymentMethods,
    state.paymentOption,
    handlePaymentMethodMissing
  );

  const selectSavedAddressContext = useContext(SelectSavedAddressContext);

  const handleSelectDeliveryInfoType = useCallback(
    async (deliveryInfoType: DeliveryEndpointType) => {
      selectDeliveryInfoType(deliveryInfoType);
      if (deliveryInfoType === "address") {
        actionEvent("Checkout Page", "Click", "Delivery Info_Delivery Address");
        await updateDeliveryEndpoint(deliveryInfoType);
      } else if (
        deliveryInfoType === "o2o-store" &&
        state.selfPickup.pickupSpot
      ) {
        actionEvent(
          "Checkout Page",
          "Click",
          "Delivery Info_Self Pick-up Points"
        );
        try {
          await updateDeliveryEndpointForO2oStore(
            deliveryInfoType,
            cart.availableO2oStores,
            state.selfPickup
          );
        } catch {}
      }
    },
    [
      state,
      selectDeliveryInfoType,
      updateDeliveryEndpoint,
      cart,
      updateDeliveryEndpointForO2oStore,
    ]
  );

  const handleSelectDeliveryAddress = useCallback(
    (address: Address) => {
      updateDeliveryAddress({
        ...state.deliveryAddress,
        ...address,
      });
    },
    [updateDeliveryAddress, state]
  );

  const handleChangeDeliveryAddress = useCallback(
    (address: Address) => {
      updateDeliveryAddress({
        ...state.deliveryAddress,
        ...address,
      });
    },
    [updateDeliveryAddress, state]
  );

  const navigateToSelectO2oStorePage = useCallback(
    (params: CheckoutSelectO2oStorePageLocationState) => {
      navigate(getPathForCheckoutSelectO2oStore(), params);
    },
    [navigate]
  );

  const selfPickupData = useMemo(() => {
    if (cart) {
      return transformO2oStoresToSelfPickupData(
        cart.availableO2oStores,
        translate
      );
    }
    return null;
  }, [cart, translate]);

  const handleChangeSelfPickup = useCallback(
    (selfPickup: SelfPickup) => {
      updateSelfPickup(selfPickup);
    },
    [updateSelfPickup]
  );

  const handleSelfPickupTelephoneBlur = useCallback(async () => {
    const { selfPickup } = state;

    if (
      state.deliveryInfoType === "o2o-store" &&
      selfPickup.telephone &&
      selfPickup.pickupSpot
    ) {
      try {
        await updateDeliveryEndpointForO2oStore(
          state.deliveryInfoType,
          cart.availableO2oStores,
          state.selfPickup
        );
      } catch {}
    }
  }, [state, cart, updateDeliveryEndpointForO2oStore]);

  const handleSelfPickupPickupSpotChange = useCallback(
    async (pickupSpot: string) => {
      const { selfPickup } = state;
      if (
        state.deliveryInfoType === "o2o-store" &&
        pickupSpot &&
        selfPickup.telephone
      ) {
        try {
          await updateDeliveryEndpointForO2oStore(
            state.deliveryInfoType,
            cart.availableO2oStores,
            { ...selfPickup, pickupSpot }
          );
        } catch {}
      }
    },
    [state, cart, updateDeliveryEndpointForO2oStore]
  );

  const handlePresentSelectSavedAddressModalForDeliveryAddress = useCallback(() => {
    actionEvent(
      "Checkout Page",
      "Click",
      "Delivery Info_Select a Saved Address"
    );
    selectSavedAddressContext.present(addresses, handleSelectDeliveryAddress);
  }, [selectSavedAddressContext, handleSelectDeliveryAddress, addresses]);

  const handleSelectBillingInfoOption = useCallback(
    (billingInfoOption: BillingInfoOption) => {
      if (billingInfoOption === "sameAsDeliveryAddress") {
        actionEvent(
          "Checkout Page",
          "Click",
          "Billing Info_Same As Delivery Address"
        );
      } else if (billingInfoOption === "custom") {
        actionEvent(
          "Checkout Page",
          "Click",
          "Billing Info_Different from Delivery Address"
        );
      }
      selectBillingInfoOption(billingInfoOption);
    },
    [selectBillingInfoOption]
  );

  const handleSelectBillingInfo = useCallback(
    (address: Address) => {
      updateCustomBillingInfo({
        ...state.customBillingInfo,
        ...address,
      });
    },
    [updateCustomBillingInfo, state]
  );

  const handleChangeCustomBillingInfo = useCallback(
    (address: Address) => {
      updateCustomBillingInfo({
        ...state.customBillingInfo,
        ...address,
      });
    },
    [updateCustomBillingInfo, state]
  );

  const handlePresentSelectSavedAddressModalForBillingInfo = useCallback(() => {
    selectSavedAddressContext.present(addresses, handleSelectBillingInfo);
  }, [selectSavedAddressContext, handleSelectBillingInfo, addresses]);

  const handleSelectPaymentType = useCallback(
    paymentType => {
      actionEvent("Checkout Page", "Click", `Payment Option_${paymentType}`);
      setPaymentType(paymentType);
    },
    [setPaymentType]
  );

  const handleDeliveryTimeChange = useCallback(
    (timeOption: DeliveryTimeSlotValue) => {
      setDeliveryTime(timeOption);
    },
    [setDeliveryTime]
  );

  const [isApplyCouponLoading, setIsApplyCouponLoading] = useState(false);
  const handlePromotionCodeBlur = useCallback(async () => {
    const {
      promotion: { code },
    } = state;
    showLoadingModal();
    setIsApplyCouponLoading(true);
    await applyCoupon(code);
    hideLoadingModal();
    setIsApplyCouponLoading(false);
  }, [state, applyCoupon, showLoadingModal, hideLoadingModal]);

  const handlePromotionCodeKeyPress = useCallback(async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === "Enter" && e.currentTarget) {
      e.currentTarget.blur();
    }
  }, []);

  const hasDeliveryInfo = React.useMemo(() => hasDeliveryInfoForCart(cart), [
    cart,
  ]);

  const [verifyTheClubOpened, setVerifyTheClubOpened] = useState(false);

  useEffect(() => {
    if (customer && isCustomerTheClubAccountVerified(customer)) {
      setVerifyTheClubOpened(false);
    }
  }, [customer]);

  const linkedWithTheClub = React.useMemo<[true, ClubMemberCustomer] | [false]>(
    () =>
      customer == null
        ? [false]
        : isCustomerLinkedToTheClub(customer)
        ? [true, customer]
        : [false],
    [customer]
  );

  const displaySpendClubpointInfo = React.useMemo(() => {
    return linkedWithTheClub[0];
  }, [linkedWithTheClub]);

  const minClubpointUsed = useMemo(() => getMinClubPointUsed(cart), [cart]);
  const customerClubPoint = linkedWithTheClub[0]
    ? getCustomerClubPoints(linkedWithTheClub[1])
    : 0;
  const clubpointConversionRate = useMemo(
    () => getClubPointConversionRate(appConfig),
    [appConfig]
  );
  const maxClubpointUsed = React.useMemo(() => {
    const cartMaxClubPoints =
      Math.floor(cart.prices.grandTotal.value) * clubpointConversionRate +
      cart.clubPointToBeUsed; // add this becoz the grand total has minus discount by club point
    if (!linkedWithTheClub[0]) {
      return cartMaxClubPoints;
    }
    return Math.max(
      Math.min(
        Math.floor(customerClubPoint / clubpointConversionRate) *
          clubpointConversionRate,
        cartMaxClubPoints
      ),
      minClubpointUsed
    );
  }, [
    customerClubPoint,
    cart,
    minClubpointUsed,
    linkedWithTheClub,
    clubpointConversionRate,
  ]);
  const [extraClubpointUsed, setExtraClubpointUsed] = useState<number>(0);
  const notEnoughtClubPoint = customer
    ? extraClubpointUsed + minClubpointUsed > customerClubPoint
    : false;

  const { clubPointToBeUsed } = cart;

  const [
    debouncedSetClubPointOnCart,
    setClubPointOnCartInProgressRef,
  ] = useDebounceByCompletion(setClubPoint);

  useEffect(() => {
    if (!setClubPointOnCartInProgressRef.current) {
      setExtraClubpointUsed((clubPointToBeUsed || 0) - minClubpointUsed);
    }
  }, [clubPointToBeUsed, minClubpointUsed, setClubPointOnCartInProgressRef]);

  const onClubpointUsedChange = useCallback(
    (value: number) => {
      setExtraClubpointUsed(value - minClubpointUsed);
      debouncedSetClubPointOnCart(value);
    },
    [setExtraClubpointUsed, minClubpointUsed, debouncedSetClubPointOnCart]
  );

  useResetShoppingCartClubPointToBeUsed(
    setExtraClubpointUsed,
    setClubPoint,
    customer,
    minClubpointUsed,
    clubPointToBeUsed
  );

  const deliveryInfoAnchorRef = useRef<HTMLDivElement>(null);
  const deliveryTimeAnchorRef = useRef<HTMLDivElement>(null);
  const billingInfoAnchorRef = useRef<HTMLDivElement>(null);
  const paymentOptionAnchorRef = useRef<HTMLDivElement>(null);
  const promotionAnchorRef = useRef<HTMLDivElement>(null);
  const deliveryInfoO2oAnchorRef = useRef<HTMLDivElement>(null);
  const deliveryInfoAddressAnchorRef = useRef<HTMLDivElement>(null);
  const emailAddressAnchorRef = useRef<HTMLDivElement>(null);

  const anchorMap: {
    [name in ScrollAnchor]: RefObject<HTMLDivElement>;
  } = useMemo(
    () => ({
      "delivery-info": deliveryInfoAnchorRef,
      "delivery-info-o2o": deliveryInfoO2oAnchorRef,
      "delivery-info-address": deliveryInfoAddressAnchorRef,
      "delivery-time": deliveryTimeAnchorRef,
      "billing-info": billingInfoAnchorRef,
      "payment-option": paymentOptionAnchorRef,
      promotion: promotionAnchorRef,
      "email-address": emailAddressAnchorRef,
    }),
    []
  );

  const scrollToAnchor = useCallback(
    function(anchor: ScrollAnchor, duration?: number) {
      const ref = anchorMap[anchor];
      if (contentRef.current && ref && ref.current) {
        contentRef.current.scrollToPoint(
          0,
          getPosition(ref.current).y,
          duration
        );
      }
    },
    [contentRef, anchorMap]
  );

  useEffect(() => {
    const regex = new RegExp("#?(.*)");
    if (hash) {
      const matched = regex.exec(hash);
      if (matched && matched.length >= 2) {
        const str = matched[1];
        if (stringIsScrollAnchor(str)) {
          scrollToAnchor(str, 1000);
        }
      }
    }
  }, [hash, scrollToAnchor]);

  useEffect(() => {
    if (
      updateDeliveryEndpointError ||
      !isEmpty(shippingAddressFormError) ||
      setShippingAddressError
    ) {
      scrollToAnchor("delivery-info", 1000);
    } else if (
      updateDeliveryEndpointForO2oStoreError ||
      !isEmpty(updateDeliveryEndpointForO2oStoreFormError)
    ) {
      scrollToAnchor("delivery-info-o2o", 1000);
    } else if (updateDeliveryTimeSlotError) {
      scrollToAnchor("delivery-time", 1000);
    } else if (!isEmpty(billingAddressFormError) || setBillingAddressError) {
      scrollToAnchor("billing-info", 1000);
    } else if (setPaymentMethodError) {
      scrollToAnchor("payment-option", 1000);
    } else if (applyCouponError) {
      scrollToAnchor("promotion", 1000);
    }
  }, [
    updateDeliveryEndpointError,
    updateDeliveryTimeSlotError,
    updateDeliveryEndpointForO2oStoreError,
    updateDeliveryEndpointForO2oStoreFormError,
    applyCouponError,
    shippingAddressFormError,
    setShippingAddressError,
    billingAddressFormError,
    setBillingAddressError,
    setPaymentMethodError,
    scrollToAnchor,
  ]);

  const clubpointsRequiredItems = React.useMemo(() => {
    return cart.items
      .filter(item => item.product.minClubPoint)
      .map(item => ({
        name: item.product.name,
        value: item.product.minClubPoint * item.quantity,
      }));
  }, [cart]);

  const orderSummaryExtraItems = React.useMemo<OrderSummaryExtraItem[]>(() => {
    const result: OrderSummaryExtraItem[] = [];
    if (cart) {
      result.push({
        titleID: "order_summary.extra_item.delivery_fee",
        type: getMoneyTypeFromMoney(cart.prices.shippingAmount),
        value: cart.prices.shippingAmount,
      });
      if (
        Config.ENABLE_SUBSCRIPTION &&
        cart.prices.initialSubscriptionFee &&
        cart.prices.initialSubscriptionFee.value
      ) {
        result.push({
          titleID: "order_summary.extra_item.initial_subscription_fee",
          type: getMoneyTypeFromMoney(cart.prices.initialSubscriptionFee),
          value: cart.prices.initialSubscriptionFee,
        });
      }
    }
    return result;
  }, [cart]);

  const [isLinkingToTheClub, setIsLinkingToTheClub] = useState(false);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const loginWithTheClub = useLoginWithTheClub();
  const linkSocialAccount = useLinkSocialAccountRequest();
  const onLinkButtonClick = useCallback(async () => {
    actionEvent("Checkout Page", "Click", "Link With The Club");
    try {
      setIsLinkingToTheClub(true);
      const ssoResponse = await loginWithTheClub();
      const linkResult = await linkSocialAccount(
        ssoResponse.token,
        ssoResponse.provider
      );
      if (!linkResult.success) {
        presentLocalizedAlert({
          headerId: linkResult.errorMessage
            ? "page.edit_profile.link_account.error.email_registered"
            : "page.edit_profile.connect_account_error",
          messageId: linkResult.errorMessage
            ? undefined
            : "alert.error.message",
          message: linkResult.errorMessage
            ? linkResult.errorMessage
            : undefined,
          buttons: [{ textMessageID: "try_again" }],
        });
      }
    } catch (e) {
      if (e.message === INVALID_MEMBERSHIP_RESPONSE) {
        presentLocalizedAlert({
          headerId: "page.edit_profile.link_account.error.email_registered",
          messageId:
            "page.edit_profile.link_account.error.email_registered.message",
          buttons: [{ textMessageID: "try_again" }],
        });
      } else {
        presentLocalizedAlert({
          headerId: "page.edit_profile.connect_account_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      }
    } finally {
      setIsLinkingToTheClub(false);
    }
  }, [
    loginWithTheClub,
    setIsLinkingToTheClub,
    linkSocialAccount,
    presentLocalizedAlert,
  ]);

  const dismissAndGoToChangeEmail = useCallback(() => {
    presentationContext.dismiss(async () => {
      parentNavContext.navigate(getPathForAccountTab());
      await timeout(500);
      parentNavContext.navigate(getPathForEditProfile(RootTab.account));
      await timeout(1000);
      parentNavContext.navigate(getPathForChangeEmail(RootTab.account));
    });
  }, [presentationContext, parentNavContext]);

  const handleEmailClick = useCallback(() => {
    presentLocalizedAlert({
      headerId: "checkout.email.change.alert.title",
      messageId: "checkout.email.change.alert.message",
      buttons: [
        {
          textMessageID: "alert.button.ok",
          handler: () => dismissAndGoToChangeEmail(),
        },
        {
          textMessageID: "cancel",
        },
      ],
    });
  }, [presentLocalizedAlert, dismissAndGoToChangeEmail]);

  const onVerifyClick = useCallback(() => {
    setVerifyTheClubOpened(true);
  }, []);
  const closeVerifyTheClubModal = useCallback(
    () => setVerifyTheClubOpened(false),
    []
  );

  const deliveryInfoTypeRef = useRef(state.deliveryInfoType);
  useEffect(() => {
    if (
      deliveryInfoTypeRef.current !== state.deliveryInfoType &&
      state.deliveryInfoType === "o2o-store"
    ) {
      scrollToAnchor("delivery-info-o2o");
    }

    if (
      deliveryInfoTypeRef.current !== state.deliveryInfoType &&
      state.deliveryInfoType === "address"
    ) {
      scrollToAnchor("delivery-info-address");
    }

    deliveryInfoTypeRef.current = state.deliveryInfoType;
  }, [state.deliveryInfoType, scrollToAnchor]);

  const availableDeliveryEndpoints = useMemo<DeliveryEndpoint[]>(() => {
    if (areAllItemsVirtual(cart)) {
      return [];
    }
    return [...cart.availableDeliveryEndpoints].sort(a =>
      a.type === "o2o-store" ? -1 : 0
    );
  }, [cart]);

  const shouldVerifyTheClub = useMemo(() => {
    if (!customer || !isCustomerLinkedToTheClub(customer)) {
      return false;
    }
    if (
      !isCustomerTheClubAccountVerified(customer) &&
      minClubpointUsed + extraClubpointUsed > 0
    ) {
      return true;
    }
    return false;
  }, [customer, minClubpointUsed, extraClubpointUsed]);

  const shouldAutoSaveDeliveryAddress = useMemo(() => addresses.length === 0, [
    addresses,
  ]);

  const [getOppCardsRequestState, getOppCards] = useGetOppCards();

  const oppCards = useMemo(() => {
    if (isRequestLoading(getOppCardsRequestState)) {
      return [];
    }
    const _oppCards = getResources(getOppCardsRequestState);
    return _oppCards || [];
  }, [getOppCardsRequestState]);

  const getOppCardsError = useMemo<string | null>(() => {
    if (isRequestLoading(getOppCardsRequestState)) {
      return null;
    }
    if (isRequestError(getOppCardsRequestState)) {
      if (
        getOppCardsRequestState.error &&
        getOppCardsRequestState.error.message
      ) {
        return getOppCardsRequestState.error.message;
      }
      return translate("error.unknown");
    }
    return null;
  }, [getOppCardsRequestState, translate]);

  useEffect(() => {
    if (state.paymentOption === "opppayment") {
      if (getOppCardsRequestState.type === "initial") {
        getOppCards().catch(() => {});
      }
    }
  }, [state.paymentOption, getOppCardsRequestState, getOppCards]);

  const handleOppCardOptionChange = useCallback(
    (oppCardOption: OppCardOption) => {
      setOppCardOption(oppCardOption);
    },
    [setOppCardOption]
  );

  const canNext = useMemo(() => {
    return !notEnoughtClubPoint && !setClubPointRequesting;
  }, [notEnoughtClubPoint, setClubPointRequesting]);

  const presentAlert = useCallback(
    (messageId: MessageID) => {
      presentLocalizedAlert({
        messageId,
        buttons: [{ textMessageID: "alert.button.ok" }],
      });
    },
    [presentLocalizedAlert]
  );

  const submit = useCallback(async () => {
    if (!canNext) {
      return;
    }
    actionEvent("Checkout Page", "Click", "Next");
    if (shouldVerifyTheClub) {
      setVerifyTheClubOpened(true);
      return;
    }
    if (availableDeliveryEndpoints.length > 0 && !state.deliveryInfoType) {
      presentAlert("checkout.delivery_info.missing");
      return;
    }
    if (state.shouldSubmitBillingInfo && !state.billingInfoOption) {
      presentAlert("checkout.billing_info.missing");
      return;
    }
    if (!state.paymentOption) {
      presentAlert("checkout.filling_info.payment_option.missing");
      return;
    }
    if (setClubPointRequesting) {
      return;
    }
    addPerformanceRecord(CheckoutSession(), "Filling Info next click");
    showLoadingModal();
    resetRequestStates();
    try {
      if (hasDeliveryInfo) {
        if (state.deliveryInfoType === "address") {
          await profiledAsyncActionFn(
            CheckoutSession(),
            "- Filling Info updateDeliveryTimeSlot API",
            updateDeliveryTimeSlot
          )(state.deliveryTime.timeOption);
          await profiledAsyncActionFn(
            CheckoutSession(),
            "- Filling Info setShippingAddress API",
            setShippingAddress
          )(
            {
              ...state.deliveryAddress,
            },
            shouldAutoSaveDeliveryAddress
              ? true
              : haveAddressInAddressBook(
                  state.deliveryAddress,
                  state.billingInfoOption === "custom" &&
                    state.customBillingInfoSaveInAddressBook
                    ? [...addresses, state.customBillingInfo]
                    : addresses
                )
              ? false
              : state.deliveryAddressSaveInAddressBook
          );
        } else if (state.deliveryInfoType === "o2o-store") {
          await profiledAsyncActionFn(
            CheckoutSession(),
            "- Filling Info updateDeliveryEndpointForO2oStore API",
            updateDeliveryEndpointForO2oStore
          )(state.deliveryInfoType, cart.availableO2oStores, state.selfPickup);
        }
      }
      if (state.shouldSubmitBillingInfo) {
        if (
          hasDeliveryInfo &&
          state.billingInfoOption === "sameAsDeliveryAddress"
        ) {
          await profiledAsyncActionFn(
            CheckoutSession(),
            "- Filling Info setBillingAddress API",
            setBillingAddress
          )(
            {
              ...state.deliveryAddress,
            },
            false
          );
        } else if (state.billingInfoOption === "custom") {
          await profiledAsyncActionFn(
            CheckoutSession(),
            "- Filling Info setBillingAddress API",
            setBillingAddress
          )(
            state.customBillingInfo,
            haveAddressInAddressBook(
              state.customBillingInfo,
              state.deliveryInfoType === "address" &&
                (shouldAutoSaveDeliveryAddress ||
                  state.deliveryAddressSaveInAddressBook)
                ? [...addresses, state.deliveryAddress]
                : addresses
            )
              ? false
              : state.customBillingInfoSaveInAddressBook
          );
        }
      } else {
        await profiledAsyncActionFn(
          CheckoutSession(),
          "- Filling Info resetBillingAddress API",
          resetBillingAddress
        )();
      }
      await profiledAsyncActionFn(
        CheckoutSession(),
        "- Filling Info setPaymentMethod API",
        setPaymentMethod
      )(state.paymentOption);
      if (Config.ENABLE_SET_EMAIL_ADDRESS_ON_CART) {
        await profiledAsyncActionFn(
          CheckoutSession(),
          "- FIlling Info setEmailAddress API",
          setEmailAddress
        )(state.emailAddress || "");
      }
      navigate(getPathForCheckoutConfirmation());
    } catch (e) {
      console.warn(e);
    } finally {
      hideLoadingModal();
    }
  }, [
    canNext,
    presentAlert,
    cart,
    addresses,
    state,
    resetRequestStates,
    updateDeliveryEndpointForO2oStore,
    updateDeliveryTimeSlot,
    setShippingAddress,
    resetBillingAddress,
    setBillingAddress,
    setPaymentMethod,
    setEmailAddress,
    showLoadingModal,
    hideLoadingModal,
    navigate,
    hasDeliveryInfo,
    shouldVerifyTheClub,
    shouldAutoSaveDeliveryAddress,
    setClubPointRequesting,
    availableDeliveryEndpoints,
  ]);

  const submitWithCartMutations = useCallback(async () => {
    if (!customer) {
      return;
    }
    if (!canNext) {
      return;
    }
    actionEvent("Checkout Page", "Click", "Next");
    if (shouldVerifyTheClub) {
      setVerifyTheClubOpened(true);
      return;
    }
    if (availableDeliveryEndpoints.length > 0 && !state.deliveryInfoType) {
      presentAlert("checkout.delivery_info.missing");
      return;
    }
    if (state.shouldSubmitBillingInfo && !state.billingInfoOption) {
      presentAlert("checkout.billing_info.missing");
      return;
    }
    if (!state.paymentOption) {
      presentAlert("checkout.filling_info.payment_option.missing");
      return;
    }
    if (setClubPointRequesting) {
      return;
    }
    addPerformanceRecord(CheckoutSession(), "Filling Info next click");
    resetRequestStates();
    let setShippingMethodOperation: OperationKey | null = null;
    let hasFormValidationError: boolean = false;
    const mutationOperationsForPerformanceLogging: string[] = [];
    const mutations: MutationDefinitionWithCartId[] = [];
    if (hasDeliveryInfo) {
      if (state.deliveryInfoType === "address") {
        mutations.push(
          SetDeliveryEndpointMutationDefinition(
            state.deliveryInfoType,
            {},
            OperationKey.address_setDeliveryEndpoint
          )
        );
        mutationOperationsForPerformanceLogging.push("setDeliveryEndpoint");
        mutations.push(
          SetDeliveryTimeSlotMutationDefinition(
            state.deliveryTime.timeOption,
            OperationKey.address_setDeliveryTimeSlot
          )
        );
        mutationOperationsForPerformanceLogging.push("setDeliveryTimeSlot");
        hasFormValidationError = validateShippingAddress(state.deliveryAddress)
          ? true
          : hasFormValidationError;
        mutations.push(
          SetShippingAddressesMutationDefinition(
            {
              ...transformAddressToAddressInput(
                state.deliveryAddress,
                countryByID,
                regionByID,
                districtByID
              ),
              saveInAddressBook: shouldAutoSaveDeliveryAddress
                ? true
                : haveAddressInAddressBook(
                    state.deliveryAddress,
                    state.billingInfoOption === "custom" &&
                      state.customBillingInfoSaveInAddressBook
                      ? [...addresses, state.customBillingInfo]
                      : addresses
                  )
                ? false
                : state.deliveryAddressSaveInAddressBook,
            },
            OperationKey.address_setShippingAddress
          )
        );
        mutationOperationsForPerformanceLogging.push("setShippingAddresses");
        setShippingMethodOperation = OperationKey.address_setShippingMethod;
      } else if (state.deliveryInfoType === "o2o-store") {
        const o2oStore = findO2oStore(
          cart.availableO2oStores,
          state.selfPickup.region,
          state.selfPickup.district,
          state.selfPickup.storeType,
          state.selfPickup.pickupSpot
        );
        if (!o2oStore) {
          return;
        }
        hasFormValidationError = validateSelfPickup(state.selfPickup)
          ? true
          : hasFormValidationError;
        mutations.push(
          SetShippingAddressesMutationDefinition(
            {
              ...transformO2oStoreToAddressInput(
                customer.firstname,
                customer.lastname,
                state.selfPickup.telephone,
                o2oStore
              ),
              saveInAddressBook: false,
            },
            OperationKey.o2o_setShippingAddress
          )
        );
        mutationOperationsForPerformanceLogging.push("setShippingAddresses");
        setShippingMethodOperation = OperationKey.o2o_setShippingMethod;
        mutations.push(
          SetDeliveryTimeSlotMutationDefinition(
            null,
            OperationKey.o2o_setDeliveryTimeSlot
          )
        );
        mutationOperationsForPerformanceLogging.push("setDeliveryTimeSlot");
        mutations.push(
          SetDeliveryEndpointMutationDefinition(
            state.deliveryInfoType,
            {
              o2oStoreCode: state.selfPickup.pickupSpot,
            },
            OperationKey.o2o_setDeliveryEndpoint
          )
        );
        mutationOperationsForPerformanceLogging.push("setDeliveryEndpoint");
      }
    }
    if (state.shouldSubmitBillingInfo) {
      if (
        hasDeliveryInfo &&
        state.billingInfoOption === "sameAsDeliveryAddress"
      ) {
        mutations.push(
          SetBillingAddressMutationDefinition(
            {
              ...transformAddressToAddressInput(
                state.deliveryAddress,
                countryByID,
                regionByID,
                districtByID
              ),
              saveInAddressBook: false,
            },
            OperationKey.setBillingAddress
          )
        );
        mutationOperationsForPerformanceLogging.push("setBillingAddress");
      } else if (state.billingInfoOption === "custom") {
        hasFormValidationError = validateBillingAddress(state.customBillingInfo)
          ? true
          : hasFormValidationError;
        mutations.push(
          SetBillingAddressMutationDefinition(
            {
              ...transformAddressToAddressInput(
                state.customBillingInfo,
                countryByID,
                regionByID,
                districtByID
              ),
              saveInAddressBook: haveAddressInAddressBook(
                state.customBillingInfo,
                state.deliveryInfoType === "address" &&
                  (shouldAutoSaveDeliveryAddress ||
                    state.deliveryAddressSaveInAddressBook)
                  ? [...addresses, state.deliveryAddress]
                  : addresses
              )
                ? false
                : state.customBillingInfoSaveInAddressBook,
            },
            OperationKey.setBillingAddress
          )
        );
        mutationOperationsForPerformanceLogging.push("setBillingAddress");
      }
    } else {
      mutations.push(
        SetBillingAddressMutationDefinition(
          null,
          OperationKey.setBillingAddress
        )
      );
      mutationOperationsForPerformanceLogging.push("setBillingAddress");
    }
    mutations.push(
      SetPaymentMethodMutationDefinition(
        state.paymentOption,
        OperationKey.setPaymentMethod
      )
    );
    mutationOperationsForPerformanceLogging.push("setPaymentMethod");
    if (Config.ENABLE_SET_EMAIL_ADDRESS_ON_CART) {
      if (!state.emailAddress) {
        hasFormValidationError = true;
      } else {
        hasFormValidationError = validateEmailAddress(state.emailAddress)
          ? true
          : hasFormValidationError;
      }
    }
    if (hasFormValidationError) {
      return;
    }
    showLoadingModal();
    try {
      const _cart = await profiledAsyncActionFn(
        CheckoutSession(),
        `- Run Cart Mutations (${mutationOperationsForPerformanceLogging.join(
          ", "
        )})`,
        runCartMutations
      )(mutations);
      if (_cart && setShippingMethodOperation) {
        const defaultShippingMethod = getDefaultShippingMethod(_cart.cart);
        if (defaultShippingMethod) {
          await profiledAsyncActionFn(
            CheckoutSession(),
            "- Set shipping method on cart",
            runCartMutations
          )([
            SetShippingMethodMutationDefinition(
              defaultShippingMethod,
              setShippingMethodOperation
            ),
          ]);
        }
      }
      if (Config.ENABLE_SET_EMAIL_ADDRESS_ON_CART && state.emailAddress) {
        await profiledAsyncActionFn(
          CheckoutSession(),
          "- Set email address on cart",
          runCartMutations
        )([
          SetEmailAddressMutationDefinition(
            state.emailAddress,
            OperationKey.setEmailAddress
          ),
        ]);
      }
      navigate(getPathForCheckoutConfirmation());
    } catch (e) {
      console.warn({ e });
    } finally {
      hideLoadingModal();
    }
  }, [
    customer,
    canNext,
    presentAlert,
    cart,
    addresses,
    state,
    resetRequestStates,
    showLoadingModal,
    hideLoadingModal,
    navigate,
    hasDeliveryInfo,
    shouldVerifyTheClub,
    shouldAutoSaveDeliveryAddress,
    setClubPointRequesting,
    availableDeliveryEndpoints,
    runCartMutations,
    countryByID,
    regionByID,
    districtByID,
    validateSelfPickup,
    validateShippingAddress,
    validateBillingAddress,
    validateEmailAddress,
  ]);

  const handleNextClick = useCallback(() => {
    if (Config.DEVELOPMENT_ENABLE_AGGREGATED_CART_MUTATIONS) {
      submitWithCartMutations();
    } else {
      submit();
    }
  }, [submitWithCartMutations, submit]);

  const discount = useMemo<CartDiscountTypes>(
    () =>
      cart
        ? getDiscountBreakdown(cart.prices)
        : { type: "discounts", discounts: [] },
    [cart]
  );

  const openUrlWithBrowser = useOpenUrlWithBrowser();
  const addStoreToUrl = useAddStoreToUrl();
  const addInAppToUrl = useAddInAppToUrl();
  const handleNonDeliveryAreaClick = useCallback(() => {
    const nonDeliveryAreaLink = Config.NON_DELIVERY_AREA_LINK;
    if (nonDeliveryAreaLink) {
      withLoadingModalAsync(() =>
        openUrlWithBrowser(addInAppToUrl(addStoreToUrl(nonDeliveryAreaLink)))
      );
    }
  }, [withLoadingModalAsync, openUrlWithBrowser, addInAppToUrl, addStoreToUrl]);

  return (
    <>
      <NoInternetConnectionView isOnline={isOnline} hasData={true}>
        {notEnoughtClubPoint && <NotEnoughtClubPointBanner />}
        <div>
          <div className={styles.checkoutStepsWrapper}>
            <CheckoutSteps currentStep="fillingInfo" />
          </div>
          {hasDeliveryInfo && (
            <Block
              titleMessageID="checkout.delivery_info.title"
              ref={deliveryInfoAnchorRef}
            >
              {availableDeliveryEndpoints.map(deliveryEndpoint => {
                switch (deliveryEndpoint.type) {
                  case "address": {
                    return (
                      <div
                        ref={deliveryInfoAddressAnchorRef}
                        key={deliveryEndpoint.type}
                        className={styles.selectBlockAnchor}
                      >
                        <SelectBlock
                          header={
                            <div className={styles.optionHeader}>
                              <LocalizedText messageID="checkout.delivery_info.delivery_address.text" />
                            </div>
                          }
                          selected={
                            state.deliveryInfoType === deliveryEndpoint.type
                          }
                          collapsed={
                            state.deliveryInfoType !== deliveryEndpoint.type
                          }
                          value={deliveryEndpoint.type}
                          onSelect={handleSelectDeliveryInfoType}
                        >
                          {addresses.length ? (
                            <SelectSavedAddressButton
                              onClick={
                                handlePresentSelectSavedAddressModalForDeliveryAddress
                              }
                            />
                          ) : null}
                          <div
                            className={styles.selectSavedAddressButtonSeparator}
                          />
                          <AddressForm
                            address={state.deliveryAddress}
                            onChange={handleChangeDeliveryAddress}
                            formError={shippingAddressFormError}
                            onNonDeliveryAreaLinkClick={
                              Config.NON_DELIVERY_AREA_LINK
                                ? handleNonDeliveryAreaClick
                                : undefined
                            }
                          />
                          {!shouldAutoSaveDeliveryAddress ? (
                            <>
                              <div className={styles.fieldSeparator} />
                              <Checkbox
                                checked={
                                  state.deliveryAddressSaveInAddressBook ||
                                  false
                                }
                                onCheckedChange={
                                  handleSetDeliveryAddressSaveInAddressBook
                                }
                              >
                                <div className={styles.checkboxText}>
                                  <LocalizedText messageID="checkout.filling_info.save_address.text" />
                                </div>
                              </Checkbox>
                            </>
                          ) : null}
                          {setShippingAddressError ? (
                            <p className={styles.errorMessage}>
                              {setShippingAddressError}
                            </p>
                          ) : null}
                        </SelectBlock>
                      </div>
                    );
                  }
                  case "o2o-store": {
                    if (
                      !selfPickupData ||
                      selfPickupData.regions.length === 0
                    ) {
                      return null;
                    }
                    return (
                      <div
                        ref={deliveryInfoO2oAnchorRef}
                        key={deliveryEndpoint.type}
                        className={styles.selectBlockAnchor}
                      >
                        <SelectBlock
                          header={
                            <div className={styles.optionHeader}>
                              <LocalizedText messageID="checkout.delivery_info.self_pickup.text" />
                            </div>
                          }
                          selected={
                            state.deliveryInfoType === deliveryEndpoint.type
                          }
                          collapsed={
                            state.deliveryInfoType !== deliveryEndpoint.type
                          }
                          value={deliveryEndpoint.type}
                          onSelect={handleSelectDeliveryInfoType}
                        >
                          <SelfPickupForm
                            navigateToSelectO2oStorePage={
                              navigateToSelectO2oStorePage
                            }
                            selfPickup={state.selfPickup}
                            onChange={handleChangeSelfPickup}
                            onPickupSpotChange={
                              handleSelfPickupPickupSpotChange
                            }
                            onTelephoneBlur={handleSelfPickupTelephoneBlur}
                            data={selfPickupData}
                            formError={
                              updateDeliveryEndpointForO2oStoreFormError
                            }
                          />
                          {updateDeliveryEndpointError ? (
                            <p className={styles.errorMessage}>
                              {updateDeliveryEndpointError}
                            </p>
                          ) : null}
                          {updateDeliveryEndpointForO2oStoreError ? (
                            <p className={styles.errorMessage}>
                              <CLErrorMessage
                                clError={updateDeliveryEndpointForO2oStoreError}
                              />
                            </p>
                          ) : null}
                        </SelectBlock>
                      </div>
                    );
                  }
                  default: {
                    return <div key={deliveryEndpoint.type}>Unknown</div>;
                  }
                }
              })}
            </Block>
          )}
          {state.deliveryInfoType === "address" &&
            cart.availableDeliveryTimeSlots.length &&
            hasDeliveryInfo && (
              <Block
                titleMessageID="checkout.delivery_time.title"
                ref={deliveryTimeAnchorRef}
              >
                {cart.availableDeliveryTimeSlots.map(
                  availableDeliveryTimeSlot => {
                    const {
                      label,
                      value,
                      valueText,
                    } = availableDeliveryTimeSlot;
                    return (
                      <SelectBlock
                        key={availableDeliveryTimeSlot.value}
                        header={
                          <>
                            <div className={styles.optionHeader}>{label}</div>
                            <div className={styles.deliveryTimeSlotDescription}>
                              {valueText}
                            </div>
                          </>
                        }
                        selected={state.deliveryTime.timeOption === value}
                        value={value}
                        onSelect={handleDeliveryTimeChange}
                        indicatorAlignment="center"
                      />
                    );
                  }
                )}
                {updateDeliveryTimeSlotError ? (
                  <p className={styles.errorMessage}>
                    <CLErrorMessage clError={updateDeliveryTimeSlotError} />
                  </p>
                ) : null}
              </Block>
            )}
          <Block ref={billingInfoAnchorRef}>
            <Checkbox
              checked={state.shouldSubmitBillingInfo}
              onCheckedChange={setShouldSubmitBillingInfo}
            >
              <span className={styles.checkboxText}>
                <LocalizedText messageID="checkout.billing_info.select_submit" />
              </span>
            </Checkbox>
            {state.shouldSubmitBillingInfo ? (
              <>
                <div className={styles.fieldSeparator} />
                {hasDeliveryInfo && (
                  <SelectBlock
                    header={
                      <div className={styles.optionHeader}>
                        <LocalizedText messageID="checkout.billing_info.same_as_delivery_address.text" />
                      </div>
                    }
                    selected={
                      state.billingInfoOption === "sameAsDeliveryAddress"
                    }
                    value="sameAsDeliveryAddress"
                    disabled={state.deliveryInfoType === "o2o-store"}
                    onSelect={handleSelectBillingInfoOption}
                  />
                )}
                <SelectBlock
                  header={
                    <div className={styles.optionHeader}>
                      <LocalizedText
                        messageID={
                          hasDeliveryInfo
                            ? "checkout.billing_info.custom_address.text"
                            : "checkout.billing_info.input_billing_address.text"
                        }
                      />
                    </div>
                  }
                  selected={state.billingInfoOption === "custom"}
                  collapsed={state.billingInfoOption !== "custom"}
                  value="custom"
                  onSelect={handleSelectBillingInfoOption}
                >
                  {addresses && addresses.length ? (
                    <SelectSavedAddressButton
                      onClick={
                        handlePresentSelectSavedAddressModalForBillingInfo
                      }
                    />
                  ) : null}
                  <div className={styles.selectSavedAddressButtonSeparator} />
                  <AddressForm
                    address={state.customBillingInfo}
                    onChange={handleChangeCustomBillingInfo}
                    formError={billingAddressFormError}
                  />
                  <div className={styles.fieldSeparator} />
                  <Checkbox
                    checked={state.customBillingInfoSaveInAddressBook || false}
                    onCheckedChange={
                      handleSetCustomBillingInfoSaveInAddressBook
                    }
                  >
                    <div className={styles.checkboxText}>
                      <LocalizedText messageID="checkout.filling_info.save_address.text" />
                    </div>
                  </Checkbox>
                  {setBillingAddressError ? (
                    <p className={styles.errorMessage}>
                      {setBillingAddressError}
                    </p>
                  ) : null}
                </SelectBlock>
              </>
            ) : null}
          </Block>
          <Block
            titleMessageID="checkout.payment_option.title"
            ref={paymentOptionAnchorRef}
          >
            {availablePaymentMethods.map(paymentMethod => {
              switch (paymentMethod.code) {
                case "checkmo": {
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <span className={styles.paymentOptionTitle}>
                            Checkmo
                          </span>
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                }
                case "free": {
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentOptionTitle}>
                          <LocalizedText messageID="checkout.filling_info.payment_option.free" />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                }
                case "asiapay": {
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="visa"
                            src={logoVisaImg}
                          />
                          <img
                            className={styles.paymentIcon}
                            alt="master"
                            src={logoMasterImg}
                          />
                          <img
                            className={styles.paymentIcon}
                            alt="chinapay"
                            src={logoChinaPayImg}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                }
                case "visa": {
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="visa"
                            src={logoVisaImg}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                }
                case "master": {
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="master"
                            src={logoMasterImg}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                }
                case "chinapay": {
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="chinapay"
                            src={logoChinaPayImg}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                }
                case "mpospay":
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <div className={styles.paymentOptionTitle}>
                            mPosPay
                          </div>
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                case "opppayment":
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="visa"
                            src={logoVisaImg}
                          />
                          <img
                            className={styles.paymentIcon}
                            alt="master"
                            src={logoMasterImg}
                          />
                          <img
                            className={styles.paymentIcon}
                            alt="Tap and Go"
                            src={logoTapAndGo}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      collapsed={state.paymentOption !== paymentMethod.code}
                      indicatorAlignment="center"
                      bottomAccessory={
                        <p className={styles.selectBlockBottomAccessaryMessage}>
                          <LocalizedText messageID="checkout.filling_info.payment_option.opp.not_accept_tap_and_go_unionpay" />
                        </p>
                      }
                    >
                      <OppCardSelectListView
                        oppCards={oppCards}
                        selectedOption={state.oppCardOption}
                        onChange={handleOppCardOptionChange}
                      />
                      {isRequestLoading(getOppCardsRequestState) ? (
                        <LoadingView />
                      ) : getOppCardsError ? (
                        <div className={styles.errorMessage}>
                          {getOppCardsError}
                        </div>
                      ) : null}
                    </SelectBlock>
                  );
                case "applepay":
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="applepay"
                            src={logoApplePayImg}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      collapsed={state.paymentOption !== paymentMethod.code}
                      indicatorAlignment="center"
                    />
                  );
                case "alipayhk":
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="alipayhk"
                            src={logoAlipayHKImg}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      collapsed={state.paymentOption !== paymentMethod.code}
                      indicatorAlignment="center"
                    />
                  );
                case "googlepay":
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={styles.paymentIcon}
                            alt="googlepay"
                            src={logoGooglePayImg}
                          />
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                    />
                  );
                case "installment":
                  return (
                    <SelectBlock
                      key={paymentMethod.code}
                      header={
                        <div className={styles.paymentIcons}>
                          <img
                            className={cn(
                              styles.paymentIcon,
                              styles.installmentIcon
                            )}
                            src={logoInstalmentImg}
                          />
                          <span
                            className={cn(
                              styles.paymentOptionTitle,
                              styles["paymentOptionTitle--iconBefore"]
                            )}
                          >
                            <LocalizedText messageID="checkout.payment_method.instalment" />
                          </span>
                        </div>
                      }
                      selected={state.paymentOption === paymentMethod.code}
                      value={paymentMethod.code}
                      onSelect={handleSelectPaymentType}
                      indicatorAlignment="center"
                      bottomAccessory={
                        <p className={styles.selectBlockBottomAccessaryMessage}>
                          {Config.SHOPPING_CART_FILLING_INFO_INSTALMENT_MESSAGE_VERSION ===
                          2 ? (
                            <LocalizedText messageID="checkout.payment_method.instalment.message.v2" />
                          ) : (
                            <LocalizedText messageID="checkout.payment_method.instalment.message" />
                          )}
                          <br />
                          <b>
                            <LocalizedText messageID="checkout.payment_method.instalment.message.2" />
                          </b>
                        </p>
                      }
                    />
                  );
                default: {
                  return null;
                }
              }
            })}
            {setPaymentMethodError ? (
              <p className={styles.errorMessage}>{setPaymentMethodError}</p>
            ) : null}
          </Block>
          <Block
            titleMessageID="checkout.promotion.title"
            ref={promotionAnchorRef}
          >
            <Input
              className={styles.input}
              placeholderId="promotion.code.title"
              value={state.promotion.code || ""}
              onChange={updatePromotionCode}
              onBlur={handlePromotionCodeBlur}
              onKeyPress={handlePromotionCodeKeyPress}
              isSuccess={
                isApplyCouponLoading ? false : !!getAppliedCoupon(cart)
              }
              isError={!!applyCouponError}
              showClearButton={true}
            />
            {applyCouponError ? (
              <p className={styles.errorMessage}>
                <CLErrorMessage clError={applyCouponError} />
              </p>
            ) : null}
          </Block>
          {displaySpendClubpointInfo && (
            <div className={styles.clubpointWidget}>
              <ClubpointWidget
                minValue={minClubpointUsed}
                maxValue={maxClubpointUsed}
                currentValue={minClubpointUsed + extraClubpointUsed}
                valuePerStep={clubpointConversionRate}
                grandTotal={cart.prices.grandTotal}
                onChange={onClubpointUsedChange}
                disabled={minClubpointUsed >= maxClubpointUsed}
              />
              {setClubPointError ? (
                <div className={styles.messageContainer}>
                  <p className={styles.errorMessage}>{setClubPointError}</p>
                </div>
              ) : null}
              {shouldVerifyTheClub ? (
                <VerifyTheClubAccountBanner onVerifyClick={onVerifyClick} />
              ) : null}
            </div>
          )}
          {Config.ENABLE_SET_EMAIL_ADDRESS_ON_CART ? (
            <Block
              titleMessageID="checkout.billing_email.title"
              ref={emailAddressAnchorRef}
            >
              <p className={styles.emailMessage}>
                <LocalizedText messageID="checkout.billing_email.message" />
              </p>
              <Input
                className={styles.input}
                value={state.emailAddress || ""}
                onChange={updateEmailAddress}
              />
              {setEmailAddressError ? (
                <p className={styles.errorMessage}>
                  <CLErrorMessage clError={setEmailAddressError} />
                </p>
              ) : null}
            </Block>
          ) : customer ? (
            <Block titleMessageID="checkout.email.title">
              <p className={styles.emailMessage}>
                <LocalizedText messageID="checkout.email.message" />
              </p>
              <div onClick={handleEmailClick}>
                <Input
                  className={styles.input}
                  value={customer.email}
                  disabled={true}
                />
              </div>
            </Block>
          ) : null}
          <OrderSummary
            className={styles.orderSummary}
            customer={customer}
            itemsCount={cart.items.length}
            subtotal={cart.prices.subtotalExcludingTax}
            // TODO (Steven-Chan):
            // display required club points and  amount of extra club points used
            clubPointRequired={minClubpointUsed}
            clubpointsRequiredItems={
              displaySpendClubpointInfo ? clubpointsRequiredItems : null
            }
            extraClubpointsUsed={extraClubpointUsed}
            clubpointsConversionCurrency="HKD"
            clubpointsConversionRate={getClubPointConversionRate(appConfig)}
            extraItems={orderSummaryExtraItems}
            total={cart.prices.grandTotal}
            discount={discount}
            discountAmount={cart.prices.discountAmount}
          />
          <PrimaryButton
            className={styles.nextButton}
            onClick={handleNextClick}
            disabled={!canNext}
          >
            <LocalizedText messageID="next" />
          </PrimaryButton>
          <div className={styles.noChangeMessage}>
            <LocalizedText messageID="shopping_cart.no_changes_are_allowed" />
          </div>
          {Config.ENABLE_THE_CLUB_SSO || linkedWithTheClub[0] ? (
            <>
              <div className={styles.earnClubpointsContainer}>
                <IonIcon
                  className={styles.clubpointsIconHighlight}
                  name="club-point"
                />
                <div className={styles.earnClubpoints}>
                  <LocalizedText
                    messageID="shopping_cart.complete_order_to_earn_clubpoints"
                    messageArgs={{
                      clubpointsAmount: cart.clubpointsToBeEarned,
                      clubpointsHighlight: styles.clubpointsHighlight,
                    }}
                  />
                </div>
              </div>
              <div className={styles.transferClubpoints}>
                <LocalizedText messageID="shopping_cart.points_will_be_transferred" />
              </div>
            </>
          ) : !linkedWithTheClub[0] ? (
            <>
              <SeparatorWithTitle className={styles.separator} titleID="or" />
              <div className={styles.theClubEarnPoint}>
                <div className={styles.theClubEarnPointMessage}>
                  <LocalizedText
                    messageID="shopping_cart.link_to_earn_point"
                    components={{ IonIcon }}
                    messageArgs={{
                      clubpointsAmount: cart.clubpointsToBeEarned,
                      theClubIconClassName: styles.theClubEarnPointMessageIcon,
                    }}
                  />
                </div>
              </div>
              <div
                className={cn(
                  styles.theClubButton,
                  isLinkingToTheClub && styles.disabled
                )}
                onClick={onLinkButtonClick}
              >
                <LocalizedText messageID="shopping_cart.link_with" />
                <div className={styles.theClub} />
              </div>
            </>
          ) : null}
        </div>
      </NoInternetConnectionView>
      <VerifyTheClubModal
        isModalOpen={verifyTheClubOpened}
        onRequestDismiss={closeVerifyTheClubModal}
      />
    </>
  );
};
/* eslint-enable complexity */

const FillingInfoReady = withLoadingModal(FillingInfoReadyImpl);

function viewEnter() {
  pageView({ page: "Checkout" });
}

type Props = RouteComponentProps<{}, {}, { shouldRefresh: boolean }>;

const FillingInfo: React.FC<Props> = props => {
  useEffect(
    () => addPerformanceRecord(CheckoutSession(), "Filling Info mount"),
    []
  );

  const { history, location } = props;

  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const { translate } = useIntl();

  const {
    customerAddresses,
    isLoading: customerAddressesRequestIsLoading,
    error: customerAddressesRequestError,
  } = useFetchCustomerAddresses();

  const { countries, districts } = useContext(CountryRegionDistrictContext);

  const cartResource = useCartResource();
  const {
    fetchCart,
    isFetchCartLoading,
    fetchCartError,
    retryFetchCart,
  } = cartResource;

  const fetchCartRef = useKeepUpdatingRef(fetchCart);
  const retryFetchCartRef = useKeepUpdatingRef(retryFetchCart);

  useEffect(() => {
    profiledAsyncActionFn(
      CheckoutSession(),
      "Filling Info fetch cart",
      fetchCartRef.current
    )().catch(() => {});
  }, [fetchCartRef]);

  const handleRetryClick = useCallback(() => {
    retryFetchCartRef.current().catch(() => {});
  }, [retryFetchCartRef]);

  const googlePayAvailable = useGooglePayAvailable();

  const viewState = useMemo<
    SimpleViewState<
      {
        cart: PartialCart;
        customerAddressesRequest: {
          addresses: RemoteAddress[];
          defaultShipping: number | null;
          defaultBilling: number | null;
        };
        googlePayAvailable: boolean;
      },
      Error
    >
  >(() => {
    if (
      customerAddressesRequestIsLoading ||
      isFetchCartLoading ||
      countries.length === 0 ||
      districts.length === 0
    ) {
      return SimpleViewStateLoading;
    }
    if (customerAddressesRequestError) {
      return SimpleViewStateError(customerAddressesRequestError);
    }
    if (fetchCartError) {
      return SimpleViewStateError(fetchCartError);
    }
    if (cartResource.cart && customerAddresses && googlePayAvailable != null) {
      return SimpleViewStateDisplay({
        cart: cartResource.cart,
        customerAddressesRequest: {
          addresses: customerAddresses.addresses,
          defaultShipping: customerAddresses.defaultShipping,
          defaultBilling: customerAddresses.defaultBilling,
        },
        googlePayAvailable,
      });
    }
    return SimpleViewStateInitial;
  }, [
    customerAddresses,
    customerAddressesRequestIsLoading,
    customerAddressesRequestError,
    cartResource,
    isFetchCartLoading,
    fetchCartError,
    countries,
    districts,
    googlePayAvailable,
  ]);

  const [applePayAvailable, setApplePayAvailable] = useState(false);

  useEffect(() => {
    (async () => {
      if (await canMakeApplePayPayment()) {
        setApplePayAvailable(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (location.state.shouldRefresh) {
      retryFetchCartRef.current().catch(() => {});
    }
  }, [location.state.shouldRefresh, retryFetchCartRef]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="checkout.filling_info.title" />}
      />
      <CLContent ref={ionContentRef} className={styles.content}>
        {viewState.type === "loading" ? (
          <FillingInfoLoading />
        ) : viewState.type === "error" ? (
          <div className={styles.errorView}>
            <ErrorView
              errorMessage={
                viewState.error.message
                  ? viewState.error.message
                  : translate("error.unknown")
              }
              onClickRetry={handleRetryClick}
            />
          </div>
        ) : viewState.type === "display" ? (
          <FillingInfoReady
            contentRef={ionContentRef}
            history={history}
            location={location}
            cart={viewState.data.cart}
            cartResource={cartResource}
            customerAddressesRequest={viewState.data.customerAddressesRequest}
            applePayAvailable={applePayAvailable}
            googlePayAvailable={viewState.data.googlePayAvailable}
          />
        ) : null}
      </CLContent>
    </>
  );
};

export default withProviders(
  FillingInfo,
  SelectSavedAddressProvider,
  LoadingModalProvider
);

interface CLErrorMessageProps {
  clError: CLError;
}

const CLErrorMessage: React.FC<CLErrorMessageProps> = props => {
  const { clError } = props;
  return isRemoteError(clError) ? (
    <span>{clError.message}</span>
  ) : (
    <LocalErrorMessage localError={clError} />
  );
};
