import React from "react";

import { MerchantPreview } from "../../models/Merchant";
import { useImageStyle } from "../../utils/imageStyle";

import styles from "./Header.module.scss";

import placholderImg from "../../resources/merchant-placeholder.svg";

interface Props {
  merchantPreview: MerchantPreview;
}

const Header: React.FC<Props> = React.memo(props => {
  const { merchantPreview } = props;
  const { logo, name } = merchantPreview;

  const logoStyle = useImageStyle(placholderImg, logo);

  return (
    <div className={styles.container}>
      <div className={styles.logo} style={logoStyle} />
      <div className={styles.body}>
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.arrow}></div>
    </div>
  );
});

export default Header;
