import {
  ApplePay,
  IMerchantCapabilities,
  IOrderItem,
  IPaymentResponse,
  ISupportedNetworks,
} from "@ionic-native/apple-pay";

import Config from "../Config";
import { isHybrid, isiOS } from "./Platform";

interface AppApplePayParameters {
  totalLineItemLabel: string;
  merchantId: string;
  countryCode: string;
  supportedNetworks: ISupportedNetworks[];
  merchantCapabilities: IMerchantCapabilities[];
}

function isSupportedNetwork(s: string): s is ISupportedNetworks {
  return s === "visa" || s === "amex" || s === "discover" || s === "masterCard";
}

function isMerchantCapabilities(s: string): s is IMerchantCapabilities {
  return s === "3ds" || s === "credit" || s === "debit" || s === "emv";
}

const appApplePayParameters: AppApplePayParameters | null = (() => {
  const { APPLE_PAY } = Config;
  if (!APPLE_PAY) {
    return null;
  }
  const {
    MERCHANT_ID,
    COUNTRY_CODE,
    MERCHANT_CAPABILITIES,
    SUPPORTED_NETWORKS,
    TOTAL_LINE_ITEM_LABEL,
  } = APPLE_PAY;
  if (
    !MERCHANT_ID ||
    !COUNTRY_CODE ||
    !MERCHANT_CAPABILITIES ||
    !SUPPORTED_NETWORKS ||
    !TOTAL_LINE_ITEM_LABEL
  ) {
    return null;
  }
  const merchantCapabilities: IMerchantCapabilities[] = [];
  for (const c of MERCHANT_CAPABILITIES) {
    if (isMerchantCapabilities(c)) {
      merchantCapabilities.push(c);
    } else {
      return null;
    }
  }

  const supportedNetworks: ISupportedNetworks[] = [];
  for (const c of SUPPORTED_NETWORKS) {
    if (isSupportedNetwork(c)) {
      supportedNetworks.push(c);
    } else {
      return null;
    }
  }

  return {
    merchantId: MERCHANT_ID,
    countryCode: COUNTRY_CODE,
    supportedNetworks,
    merchantCapabilities,
    totalLineItemLabel: TOTAL_LINE_ITEM_LABEL,
  };
})();

export function getTotalLineItemLabel(): string {
  if (!appApplePayParameters) {
    throw new Error("Apple Pay is not configured");
  }
  return appApplePayParameters.totalLineItemLabel;
}

export async function canMakeApplePayPayment() {
  if (isiOS() && isHybrid() && appApplePayParameters != null) {
    try {
      await ApplePay.canMakePayments();
      return true;
    } catch {
      return false;
    }
  }
  return false;
}

enum PKPaymentMethodType {
  unknown = "0",
  debit = "1",
  credit = "2",
  prepaid = "3",
  store = "4",
}

function getPKPaymentMethodType(paymentMethodCard: string) {
  if (paymentMethodCard === "unknown") {
    return PKPaymentMethodType.unknown;
  }
  if (paymentMethodCard === "debit") {
    return PKPaymentMethodType.debit;
  }
  if (paymentMethodCard === "credit") {
    return PKPaymentMethodType.credit;
  }
  if (paymentMethodCard === "prepaid") {
    return PKPaymentMethodType.prepaid;
  }
  if (paymentMethodCard === "store") {
    return PKPaymentMethodType.store;
  }
  throw new Error(`Unknown payment method card ${paymentMethodCard}`);
}

interface PKPayment {
  token: {
    paymentMethod: {
      displayName?: string;
      network?: string;
      type?: PKPaymentMethodType;
    };
    transactionIdentifier: string;
    paymentData: {
      header: {
        publicKeyHash: string;
        ephemeralPublicKey: string;
        transactionId: string;
      };
      signature: string;
      version: string;
      data: string;
    };
  };
}

function convertIPaymentResponseToPKPayment(
  paymentResponse: IPaymentResponse
): PKPayment {
  const paymentData = JSON.parse(atob(paymentResponse.paymentData));
  return {
    token: {
      paymentMethod: {
        displayName: paymentResponse.paymentMethodDisplayName,
        network: paymentResponse.paymentMethodNetwork,
        type: paymentResponse.paymentMethodTypeCard
          ? getPKPaymentMethodType(paymentResponse.paymentMethodTypeCard)
          : undefined,
      },
      transactionIdentifier: paymentResponse.transactionIdentifier,
      paymentData,
    },
  };
}

async function presentApplePay(
  items: IOrderItem[],
  currencyCode: string,
  authorizeApplePayToken: (paymentData: string) => Promise<unknown>
) {
  if (!appApplePayParameters) {
    throw new Error("Apple Pay is not configured");
  }

  await ApplePay.canMakePayments();

  const paymentResponse = await ApplePay.makePaymentRequest({
    items,
    supportedNetworks: appApplePayParameters.supportedNetworks,
    merchantCapabilities: appApplePayParameters.merchantCapabilities,
    merchantIdentifier: appApplePayParameters.merchantId,
    currencyCode,
    countryCode: appApplePayParameters.countryCode,
    billingAddressRequirement: "none",
    shippingAddressRequirement: "none",
  });

  const pkPayment = convertIPaymentResponseToPKPayment(paymentResponse);

  try {
    await authorizeApplePayToken(btoa(JSON.stringify(pkPayment)));
    await ApplePay.completeLastTransaction("success");
  } catch (e) {
    await ApplePay.completeLastTransaction("failure");
    throw e;
  }
}

export default presentApplePay;
