export interface OppCard {
  cardId: string;
  brand: "VISA" | "MASTERCARD" | null;
  expiryMonth: string | null;
  expiryYear: string | null;
  number: string | null;
  token: string;
}

export const OppCardGraphQLAttributes = `
cardId: card_id
brand
expiryMonth: expiry_month
expiryYear: expiry_year
number
token
`;
