import React, { useMemo } from "react";
import { LocalizedText } from "../../i18n/Localization";
import cn from "classnames";

import styles from "./ProfilePictureContainer.module.scss";

interface ProfilePictureContainerProps {
  className?: string;
  profilePicUrl: string | null;
  onPickImageClick: () => void;
  showEditText?: boolean;
}

const ProfilePictureContainer: React.FC<
  ProfilePictureContainerProps
> = props => {
  const {
    className,
    profilePicUrl,
    onPickImageClick,
    showEditText = true,
  } = props;
  const style = useMemo(() => profilePicUrl, [profilePicUrl])
    ? { backgroundImage: `url(${profilePicUrl})`, backgroundSize: "cover" }
    : undefined;
  return (
    <div className={cn(styles.container, className)} onClick={onPickImageClick}>
      <div className={styles.image} style={style}>
        <div className={styles.editButton} />
      </div>
      {showEditText ? (
        <div className={styles.editText}>
          <LocalizedText messageID="page.edit_profile.change_profile_picture" />
        </div>
      ) : null}
    </div>
  );
};

export default ProfilePictureContainer;
