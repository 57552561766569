import { ScrollDetail } from "@ionic/core";
// eslint-disable-next-line no-restricted-imports
import { IonContent, IonSlide, IonSlides } from "@ionic/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import styles from "./styles.module.scss";

interface Props {
  onIsScrolledToTopChange: (isScrolledToTop: boolean) => void;
  currentIndex?: number;
  onIndexChange: (index: number) => void;
  // Provide this key to update slides implementation on children changed
  updatePagesKey?: string | number;
  contentClassName?: string;
}

const SwipeablePages: React.FC<Props> = props => {
  const {
    children,
    currentIndex = 0,
    onIndexChange,
    onIsScrolledToTopChange,
    updatePagesKey,
    contentClassName,
  } = props;

  const ionSlidesRef = useRef<HTMLIonSlidesElement>(null);

  const ionContentRefs = useRef<React.RefObject<HTMLIonContentElement>[]>([]);

  useEffect(() => {
    if (ionSlidesRef.current) {
      const ionSlides = ionSlidesRef.current;
      ionSlides.componentOnReady().then(() => ionSlides.slideTo(currentIndex));
    }
  }, [currentIndex]);

  const childrenCount = React.Children.count(children);

  useEffect(() => {
    const refs: React.RefObject<HTMLIonContentElement>[] = [];
    for (let i = 0; i < childrenCount; i++) {
      refs.push(React.createRef<HTMLIonContentElement>());
    }
    ionContentRefs.current = refs;
  }, [childrenCount]);

  const handlePageScroll = useCallback(
    (scrollTop: number) => {
      onIsScrolledToTopChange(scrollTop <= 0);
    },
    [onIsScrolledToTopChange]
  );

  const handleSlideTransitionEnd = useCallback(async () => {
    if (!ionSlidesRef.current) {
      return;
    }
    onIndexChange(await ionSlidesRef.current.getActiveIndex());
    const ionContentRef =
      ionContentRefs.current[await ionSlidesRef.current.getActiveIndex()];
    if (ionContentRef && ionContentRef.current) {
      const scrollEl = await ionContentRef.current.getScrollElement();
      const { scrollTop } = scrollEl;
      onIsScrolledToTopChange(scrollTop === 0);
    }
  }, [onIndexChange, onIsScrolledToTopChange]);

  const ionSlidesOptions = useMemo(
    () => ({
      edgeSwipeDetection: true,
      threshold: 15,
    }),
    []
  );

  const handleStatusBarTap = useCallback(async () => {
    if (!ionSlidesRef.current) {
      return;
    }
    const ionSlides = ionSlidesRef.current;
    const activeIndex = await ionSlides.getActiveIndex();
    const ionContentRef = ionContentRefs.current[activeIndex];
    if (!ionContentRef || !ionContentRef.current) {
      return;
    }
    await ionContentRef.current.scrollToPoint(undefined, 0, 200);
  }, []);

  useEffect(() => {
    window.addEventListener("statusTap", handleStatusBarTap);
    return () => {
      window.removeEventListener("statusTap", handleStatusBarTap);
    };
  }, [handleStatusBarTap]);

  return (
    <IonSlides
      className={styles.slides}
      onIonSlideTransitionEnd={handleSlideTransitionEnd}
      ref={ionSlidesRef}
      key={updatePagesKey}
      options={ionSlidesOptions}
    >
      {React.Children.map(children, (page, i) => (
        <Page
          key={i}
          page={page}
          onScroll={handlePageScroll}
          ref={ionContentRefs.current[i]}
          contentClassName={contentClassName}
        />
      ))}
    </IonSlides>
  );
};

export default SwipeablePages;

interface PageProps {
  page: React.ReactNode;
  onScroll: (scrollTop: number) => void;
  contentClassName?: string;
}

const PageImpl: React.ForwardRefRenderFunction<
  HTMLIonContentElement,
  PageProps
> = (props, ref) => {
  const { page, onScroll, contentClassName } = props;

  const handleScroll = useCallback(
    (e: CustomEvent<ScrollDetail>) => {
      onScroll(e.detail.currentY);
    },
    [onScroll]
  );

  return (
    <IonSlide className={styles.slide}>
      <IonContent
        onIonScroll={handleScroll}
        scrollEvents={true}
        ref={ref}
        className={contentClassName}
      >
        {page}
      </IonContent>
    </IonSlide>
  );
};

const Page = React.forwardRef<HTMLIonContentElement, PageProps>(PageImpl);
