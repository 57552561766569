import React, { useRef, useContext, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useCategoryTreeMap } from "../../repository/CategoryRepository";
import { PresentationContext } from "../../our-navigation";
import { getPathForShoppingCart } from "../../navigation/routes";

import ShoppingCartButton from "../ShoppingCartButton";
import CategoryTreeList from "../CategoryTreeList";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

import CLContent from "../CLContent";
import { NavBar, NavBarBackButton } from "../NavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

type Props = RouteComponentProps<{ categoryId: string }>;

const SubCategoryListPage: React.FC<Props> = (props: Props) => {
  const { isOnline } = useContext(NetworkStatusContext);
  const { present } = useContext(PresentationContext);

  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const categoryTreeMap = useCategoryTreeMap();
  const { match } = props;
  const { categoryId } = match.params;

  const categoryTree = categoryTreeMap[categoryId];

  const onClickShoppingCart = useCallback(() => {
    present(getPathForShoppingCart());
  }, [present]);

  const handleExpandableItemClick = useCallback(
    async (el: HTMLIonItemElement) => {
      if (!contentRef.current) {
        return;
      }
      const { scrollTop } = await contentRef.current.getScrollElement();
      const { top: contentTop } = contentRef.current.getBoundingClientRect();
      const { top: elTop } = el.getBoundingClientRect();
      contentRef.current.scrollToPoint(
        undefined,
        scrollTop + elTop - contentTop,
        500
      );
    },
    []
  );

  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={categoryTree && categoryTree.name}
        headerRight={
          <ShoppingCartButton
            onClick={onClickShoppingCart}
            count={shoppingCartItemCount}
          />
        }
      />
      <CLContent ref={contentRef}>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          {categoryTree && (
            <CategoryTreeList
              categoryTree={categoryTree}
              categoryTreeMap={categoryTreeMap}
              onExpandableItemClick={handleExpandableItemClick}
            />
          )}
          <TabBarSpacePlaceholder />
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default SubCategoryListPage;
