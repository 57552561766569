import { useMemo, useEffect, RefObject, useState } from "react";

export default function useExtractScriptsAndStyles(
  containerRef: RefObject<HTMLElement>,
  htmlContent: string
) {
  const { scriptStrings: _scriptStrings, styleStrings, html } = useMemo<{
    scriptStrings: string[];
    styleStrings: string[];
    html: string;
  }>(() => {
    const _html = document.createElement("div");
    _html.innerHTML = htmlContent;

    const styles = _html.getElementsByTagName("style");
    const _styleStrings = [];
    for (let i = styles.length - 1; i >= 0; i--) {
      _styleStrings.push(styles[i].innerText);
      const { parentNode } = styles[i];
      if (parentNode) {
        parentNode.removeChild(styles[i]);
      }
    }
    _styleStrings.reverse();

    const scripts = _html.getElementsByTagName("script");
    const scriptStrings = [];
    for (let i = scripts.length - 1; i >= 0; i--) {
      scriptStrings.push(scripts[i].innerText);
      const { parentNode } = scripts[i];
      if (parentNode) {
        parentNode.removeChild(scripts[i]);
      }
    }
    scriptStrings.reverse();

    return {
      scriptStrings,
      styleStrings: _styleStrings,
      html: _html.innerHTML,
    };
  }, [htmlContent]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const styles = styleStrings.map(s => {
      const d = document.createElement("style");
      d.textContent = s;
      return d;
    });

    for (let i = 0; i < styles.length; i++) {
      styles[i].dataset.ref = "cl-html";
      container.appendChild(styles[i]);
    }

    return () => {
      for (let i = 0; i < styles.length; i++) {
        const { parentNode } = styles[i];
        if (parentNode) {
          parentNode.removeChild(styles[i]);
        }
      }
    };
  }, [containerRef, styleStrings]);

  // Store initial scripts string and do not change in each rerender
  // to prevent side effects in scripts to be executed more than once
  const [scriptStrings] = useState(_scriptStrings);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const scripts = scriptStrings.map(s => {
      const d = document.createElement("script");
      d.textContent = `try {
${s}
} catch (e) {
  console.error('[CL] Error in HTML Content', e);
  window.Sentry.withScope(function (scope) {
    scope.setLevel("warning");
    scope.setExtras({
      pathname: atob("${btoa(`${window.location.pathname}`)}"),
      script: unescape(decodeURIComponent(atob("${btoa(
        encodeURIComponent(escape(`${s}`))
      )}")))
    });
    var alteredError = new Error("[CMS Scripts] " + e.message);
    alteredError.stack = e.stack;
    window.Sentry.captureException(alteredError);
  });
}`;
      return d;
    });

    for (let i = 0; i < scripts.length; i++) {
      scripts[i].dataset.ref = "cl-html";
      container.appendChild(scripts[i]);
    }

    return () => {
      for (let i = 0; i < scripts.length; i++) {
        const { parentNode } = scripts[i];
        if (parentNode) {
          parentNode.removeChild(scripts[i]);
        }
      }
    };
  }, [containerRef, scriptStrings]);

  return html;
}
