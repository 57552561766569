import React from "react";
import { LocalError } from "../utils/Error";
import { LocalizedText } from "../i18n/Localization";

interface Props {
  localError: LocalError;
}

const LocalErrorMessage: React.FC<Props> = props => {
  const { localError } = props;
  return (
    <LocalizedText
      messageID={localError.messageID}
      messageArgs={localError.messageArgs}
    />
  );
};

export default LocalErrorMessage;
