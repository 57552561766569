import { useContext, useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { useIntl } from "../i18n/Localization";
import { useFetchResources } from "./Hooks";

import {
  District,
  CountryRegions,
  transformRemoteCountries,
  transformRemoteDistricts,
} from "../models/CountryRegion";

import { fetchCountries, fetchDistricts } from "../api/GraphQL";
import CountryRegionDistrictContext from "../contexts/CountryRegionDistrictContext";

export function useFetchCountryRegion() {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { countries, regionsByCountryID, updateCountryRegions } = useContext(
    CountryRegionDistrictContext
  );

  const inState = useMemo(
    () => ({
      countries,
      regionsByCountryID,
    }),
    [countries, regionsByCountryID]
  );

  const { requestState, startRequesting } = useFetchResources<CountryRegions>(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => inState,
      localCacheProvider: () =>
        fetchCountries(client, locale, "cache-only").then(remoteCountries => {
          if (remoteCountries) {
            return transformRemoteCountries(remoteCountries);
          }
          return null;
        }),
      didFetchFromLocalCache: countryRegions => {
        if (countryRegions) {
          updateCountryRegions(countryRegions);
        }
      },
      remoteResourcesProvider: () =>
        fetchCountries(client, locale, "network-only").then(remoteCountries => {
          if (remoteCountries) {
            return transformRemoteCountries(remoteCountries);
          }
          return null;
        }),
      didFetchFromRemote: countryRegions => {
        if (countryRegions) {
          updateCountryRegions(countryRegions);
        }
      },
    },
    [locale]
  );

  useEffect(() => {
    startRequesting();
  }, [startRequesting]);

  return { requestState };
}

export function useFetchDistricts() {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { districts, updateDistricts } = useContext(
    CountryRegionDistrictContext
  );

  const { requestState, startRequesting } = useFetchResources<District[]>(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => districts,
      localCacheProvider: () =>
        fetchDistricts(client, locale, "cache-only").then(remoteDistricts => {
          if (remoteDistricts) {
            return transformRemoteDistricts(remoteDistricts);
          }
          return null;
        }),
      didFetchFromLocalCache: _districts => {
        if (_districts) {
          updateDistricts(_districts);
        }
      },
      remoteResourcesProvider: () =>
        fetchDistricts(client, locale, "network-only").then(remoteDistricts => {
          if (remoteDistricts) {
            return transformRemoteDistricts(remoteDistricts);
          }
          return null;
        }),
      didFetchFromRemote: _districts => {
        if (_districts) {
          updateDistricts(_districts);
        }
      },
    },
    [locale]
  );

  useEffect(() => {
    startRequesting();
  }, [startRequesting]);

  return { requestState };
}
