import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { MessageID } from "../../i18n/translations/type";
import { LocalizedText } from "../../i18n/Localization";

interface Props {
  className: string;
  titleID: MessageID;
}

const SeparatorWithTitle: React.FC<Props> = props => {
  return (
    <div className={classnames(styles.container, props.className)}>
      <div className={styles.separator} />
      <div className={styles.title}>
        <LocalizedText messageID={props.titleID} />
      </div>
      <div className={styles.separator} />
    </div>
  );
};

export default SeparatorWithTitle;
