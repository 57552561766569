import React, { useMemo } from "react";

import { CarouselItem } from "../../../models/cmsBlock";
import { useOnClickViewMoreButton } from "../../../hook/viewMore";

import { isMobile } from "../../../utils/Platform";

import styles from "./ImageCarousel.module.scss";

interface Props {
  item: CarouselItem;
  hidden?: boolean;
}

const ImageCarousel: React.FC<Props> = (props: Props) => {
  const {
    item: { desktopImageUrl, mobileImageUrl, linkUrl },
    hidden = false,
  } = props;

  const style = useMemo(
    () => ({
      backgroundImage: hidden
        ? ""
        : `url(${isMobile() ? mobileImageUrl : desktopImageUrl})`,
    }),
    [desktopImageUrl, mobileImageUrl, hidden]
  );

  const onClick = useOnClickViewMoreButton(linkUrl);

  return <div className={styles.image} style={style} onClick={onClick} />;
};

export default ImageCarousel;
