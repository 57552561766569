import React from "react";

import { LocalizedText } from "../../i18n/Localization";
import { PrimaryButton } from "../Button";

import { MessageID } from "../../i18n/translations/type";

import styles from "./styles.module.scss";

interface Props {
  titleMessageID?: MessageID;
  errorMessage?: string;
  onClickRetry?: () => void;
}
const ErrorView: React.FC<Props> = React.memo(props => {
  const { titleMessageID, errorMessage, onClickRetry } = props;
  return (
    <div>
      <div className={styles.warningImage} />
      {titleMessageID != null && (
        <p className={styles.titleText}>
          <LocalizedText messageID={titleMessageID} />
        </p>
      )}
      {errorMessage != null && (
        <p className={styles.errorMessage}>{errorMessage}</p>
      )}
      {onClickRetry && (
        <PrimaryButton className={styles.retryButton} onClick={onClickRetry}>
          <p className={styles.buttonText}>
            <LocalizedText messageID="tap_to_retry" />
          </p>
        </PrimaryButton>
      )}
    </div>
  );
});
ErrorView.displayName = "ErrorView";

export default ErrorView;

export const FullContentErrorView: React.FC<Props> = React.memo(props => {
  return (
    <div className={styles.fullContentErrorViewContainer}>
      <ErrorView {...props} />
    </div>
  );
});
