import React, { useCallback, useMemo, useEffect, useRef } from "react";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

import {
  useFetchMerchantDirectories,
  useGetMerchantPreviewsByIDs,
} from "../../repository/MerchantRepository";
import { MerchantPreview, isMerchantVisible } from "../../models/Merchant";
import { isRequestLoading } from "../../models/ResourcesRequestState";

import CLLink from "../navigation/CLLink";
import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import { LoadingView, FullContentLoadingView } from "../LoadingView";
import MerchantBlock from "../MerchantBlock";
import CLInfiniteScroll from "../CLInfiniteScroll";

import {
  RootTab,
  getPathForSingleMerchant,
  useCurrentTab,
} from "../../navigation/routes";
import { LocalizedText } from "../../i18n/Localization";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import usePullToRefresh from "../../utils/pullToRefresh";
import { useIonContentScrollEl } from "../../utils/ionContentScrollEl";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { usePresentLocalizedAlertForRequestStateError } from "../../hook/ResourcesRequestHook";

import styles from "./styles.module.scss";

interface MerchantBlockButtonProps {
  currentTab: RootTab;
  merchantPreview: MerchantPreview;
  onClick: (merchantPreview: MerchantPreview) => void;
  className?: string;
}

const MerchantBlockButton: React.FC<MerchantBlockButtonProps> = props => {
  const { currentTab, onClick, merchantPreview, className } = props;
  const handleClick = useCallback(() => {
    onClick(merchantPreview);
  }, [onClick, merchantPreview]);
  return (
    <CLLink
      to={getPathForSingleMerchant(currentTab, merchantPreview.id)}
      className={className}
      onClick={handleClick}
    >
      <MerchantBlock merchant={merchantPreview} className={styles.merchant} />
    </CLLink>
  );
};

function viewEnter() {
  pageView({ page: "Merchant Directories" });
}

const MerchantDirectoriesPage: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);
  const currentTab = useCurrentTab();

  const {
    requestState: fetchMerchantDirectoriesRequestState,
    fetchPageOne,
    fetchNext,
    paginationInfo,
  } = useFetchMerchantDirectories();

  usePresentLocalizedAlertForRequestStateError(
    fetchMerchantDirectoriesRequestState
  );

  useEffect(() => {
    fetchPageOne();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const merchantPreviews = useGetMerchantPreviewsByIDs(
    paginationInfo ? paginationInfo.items : []
  );

  const loading = useMemo(
    () =>
      fetchMerchantDirectoriesRequestState.type === "loading" ||
      fetchMerchantDirectoriesRequestState.type === "loading-with-resources",
    [fetchMerchantDirectoriesRequestState]
  );

  const isLoadMoreLoading = useMemo(() => {
    if (
      paginationInfo != null &&
      paginationInfo.hasMore &&
      isRequestLoading(fetchMerchantDirectoriesRequestState)
    ) {
      return true;
    }
    return false;
  }, [paginationInfo, fetchMerchantDirectoriesRequestState]);

  const { handleRefresh } = usePullToRefresh(
    fetchMerchantDirectoriesRequestState,
    fetchPageOne
  );

  const handleLoadMore = useCallback(() => {
    if (
      !isLoadMoreLoading &&
      (paginationInfo ? paginationInfo.hasMore : false)
    ) {
      fetchNext();
    }
  }, [fetchNext, isLoadMoreLoading, paginationInfo]);

  const scrollEl = useIonContentScrollEl(contentRef);

  const handleMerchantClick = useCallback(
    (merchantPreview: MerchantPreview) => {
      actionEvent("Merchant Directories", "Click", merchantPreview.name);
    },
    []
  );

  const visibleMerchantPreviews = useMemo<MerchantPreview[]>(() => {
    const res: MerchantPreview[] = [];
    for (const merchantPreview of merchantPreviews) {
      if (isMerchantVisible(merchantPreview)) {
        res.push(merchantPreview);
      }
    }
    return res;
  }, [merchantPreviews]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <div className={styles.navTitle}>
            <LocalizedText messageID="merchant_directories.title" />
          </div>
        }
      />
      <CLContent ref={contentRef} className={styles.ionContent}>
        {loading && (!merchantPreviews || merchantPreviews.length === 0) ? (
          <FullContentLoadingView />
        ) : (
          <>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <div className={styles.listContainer}>
              {visibleMerchantPreviews.map(merchantPreview => {
                return (
                  <MerchantBlockButton
                    currentTab={currentTab}
                    merchantPreview={merchantPreview}
                    onClick={handleMerchantClick}
                    className={styles.listItem}
                    key={merchantPreview.id}
                  />
                );
              })}
            </div>
            <CLInfiniteScroll
              onLoadMore={handleLoadMore}
              scrollEl={scrollEl}
              bottomOffset="-800px"
            />
            {isLoadMoreLoading && (
              <div className={styles.infiniteLoadingContainer}>
                <LoadingView />
              </div>
            )}
          </>
        )}
      </CLContent>
    </>
  );
};

export default MerchantDirectoriesPage;
