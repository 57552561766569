import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";

import { Address } from "../../models/Customer";

import CLModal from "../CLModal/lazy";
import ModalHeader from "../ModalHeader";
import CLContent from "../CLContent";
import SelectAddressCard from "./SelectAddressCard";

import { useIntl } from "../../i18n/Localization";

import styles from "./SelectSavedAddress.module.scss";

type OnSelectCallback = (address: Address) => void;

interface SelectSavedAddressModalProps {
  addresses: Address[];
  isModalOpen: boolean;
  onSelect: OnSelectCallback | null;
  onDismiss: () => void;
}

const SelectSavedAddressModal: React.FC<
  SelectSavedAddressModalProps
> = props => {
  const { addresses, isModalOpen, onDismiss, onSelect } = props;

  const { translate } = useIntl();

  const [selected, setSelected] = useState<Address | null>(null);

  useEffect(() => {
    if (isModalOpen) {
      setSelected(null);
    }
  }, [isModalOpen]);

  const handleSelectAddress = useCallback((address: Address) => {
    setSelected(address);
  }, []);

  const handleDone = useCallback(() => {
    if (onSelect && selected) {
      onSelect(selected);
    }
  }, [onSelect, selected]);

  return (
    <>
      <CLModal isOpen={isModalOpen} onRequestDismiss={onDismiss}>
        <ModalHeader
          messageID="select_saved_address.title"
          onModalDismiss={onDismiss}
          hasDismissButton={true}
          disabled={false}
          hasDoneButton={true}
          onModalDone={handleDone}
          doneDisabled={!selected}
        />
        <CLContent scrollY={true} className={styles.ionContent}>
          <div className={styles.content}>
            {addresses.map((address, i) => (
              <div className={styles.card} key={address.id}>
                <SelectAddressCard
                  title={translate("address_card.title.index", {
                    index: i + 1,
                  })}
                  address={address}
                  selected={selected ? selected.id === address.id : false}
                  onClick={handleSelectAddress}
                />
              </div>
            ))}
          </div>
        </CLContent>
      </CLModal>
    </>
  );
};

interface Context {
  present: (addresses: Address[], fn: (address: Address) => void) => void;
}

export const Context = React.createContext<Context>(null as any);

export const Provider: React.FC = props => {
  const { children } = props;

  const fnRef = useRef<((address: Address) => void) | null>(null);
  const addressesRef = useRef<Address[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const present = useCallback(
    (addresses: Address[], fn: (address: Address) => void) => {
      fnRef.current = fn;
      setIsModalOpen(true);
      addressesRef.current = addresses;
    },
    [fnRef, setIsModalOpen, addressesRef]
  );

  const handleSelect = useCallback(
    (address: Address) => {
      if (fnRef.current) {
        fnRef.current(address);
      }
      setIsModalOpen(false);
    },
    [fnRef, setIsModalOpen]
  );

  const handleDismiss = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const contextValue = useMemo(
    () => ({
      present,
    }),
    [present]
  );

  return (
    <>
      <Context.Provider value={contextValue}>{children}</Context.Provider>
      <SelectSavedAddressModal
        addresses={addressesRef.current || []}
        isModalOpen={isModalOpen}
        onSelect={handleSelect}
        onDismiss={handleDismiss}
      />
    </>
  );
};
