import { useCallback, useContext } from "react";
import { addProductToCart, setClubPointOnCart } from "../api/GraphQL";
import { ModelKeys } from "../models/product";
import {
  ReeService,
  SubscribeEnum,
  SimpleProductCartItemInput,
  ConfigurableProductCartItemInput,
  CustomizableOptionInput,
  ClubProtectionInput,
  AgeDeclarationInput,
} from "../models/cart";
import { CartIDContext } from "../components/CartIDProvider";

export function useAddProductToCart() {
  const { callCartAPIGracefully } = useContext(CartIDContext);
  return useCallback(
    async (
      product: ModelKeys,
      configuredProduct: ModelKeys | null,
      quantity: number,
      subscribe: SubscribeEnum,
      campaignId?: number,
      customizableOptions?: CustomizableOptionInput[],
      options?: {
        ree?: {
          agreement?: boolean;
          service?: ReeService;
        };
        clubprotection?: ClubProtectionInput;
        ageDeclaration?: AgeDeclarationInput;
      }
    ) => {
      const item:
        | SimpleProductCartItemInput
        | ConfigurableProductCartItemInput =
        configuredProduct == null
          ? {
              data: {
                sku: product.sku,
                quantity,
                subscribe,
              },
            }
          : {
              parentSku: product.sku,
              variantSku: configuredProduct.sku,
              data: {
                sku: configuredProduct.sku,
                quantity,
                subscribe,
              },
            };

      item.data.campaign_id = campaignId;

      if (customizableOptions && customizableOptions.length) {
        item.customizableOptions = customizableOptions;
      }

      if (options) {
        if (options.ree) {
          const { agreement, service } = options.ree;
          item.data.disclaimer = {
            agreement: agreement ? 1 : 0,
            service,
          };
        }
        if (options.clubprotection) {
          item.data.clubprotection = options.clubprotection;
        }
        if (options.ageDeclaration) {
          item.data.age_declaration = options.ageDeclaration;
        }
      }

      return callCartAPIGracefully(addProductToCart, item);
    },
    [callCartAPIGracefully]
  );
}

export function useSetClubPointOnCart() {
  const { callCartAPIGracefully } = useContext(CartIDContext);
  return useCallback(
    async (clubPoint: number) => {
      return callCartAPIGracefully(setClubPointOnCart, clubPoint);
    },
    [callCartAPIGracefully]
  );
}
