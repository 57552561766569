import { IonSlide, IonSlides } from "@ionic/react";
import classnames from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ProfileSessionContext } from "../../contexts/ProfileSessionContext";
import { useEffectOnce } from "../../hook/useEffectOnce";
import { BrandSlider, BrandSliderItem } from "../../models/Brand";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import styles from "./Slider.module.scss";

interface DisplayableBrandSliderItem {
  img: string;
  alt: string | null;
  position: number;
  id: string;
  brandSliderItem: BrandSliderItem;
}

interface Props {
  brandSlider: BrandSlider;
  onSliderItemClick: (item: BrandSliderItem) => void;
}

const Slider: React.FC<Props> = props => {
  const { brandSlider, onSliderItemClick } = props;

  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const titleViewState = useMemo<{
    sliderHeaderStyles: React.CSSProperties | undefined;
    sliderTitle: string;
    sliderTitleStyles: React.CSSProperties | undefined;
  } | null>(() => {
    if (!brandSlider.sliderTitle) {
      return null;
    }
    const { sliderTitle, sliderHeaderColor, sliderTitleColor } = brandSlider;
    return {
      sliderTitle,
      sliderHeaderStyles: sliderHeaderColor
        ? { backgroundColor: sliderHeaderColor }
        : undefined,
      sliderTitleStyles: sliderTitleColor
        ? { color: sliderTitleColor }
        : undefined,
    };
  }, [brandSlider]);

  const sliderViewState = useMemo<{
    items: DisplayableBrandSliderItem[];
  } | null>(() => {
    if (!brandSlider.items) {
      return null;
    }
    const items: DisplayableBrandSliderItem[] = [];
    for (const item of brandSlider.items) {
      if (item.img && item.position != null && item.id != null) {
        const { img, alt, position, id } = item;
        items.push({ img, alt, position, id, brandSliderItem: item });
      }
    }
    items.sort((a, b) =>
      a.position < b.position ? -1 : a.position === b.position ? 0 : 1
    );

    return { items };
  }, [brandSlider]);

  const autoplayState = useMemo<{
    autoplay: boolean;
    autoplayDelay: number;
  } | null>(() => {
    const { autoplay, autoplayDelay } = brandSlider;
    if (autoplayDelay != null) {
      return { autoplay: autoplay || false, autoplayDelay };
    }
    return null;
  }, [brandSlider]);

  const getSlidesPerViewForWindowSize = useCallback(
    (size: number) => {
      if (brandSlider.itemsNumber) {
        if (size >= 768) {
          return brandSlider.itemsNumber;
        }
      }
      return 1;
    },
    [brandSlider]
  );

  const [_slidesPerView, setSlidesPerView] = useState<number>(
    getSlidesPerViewForWindowSize(document.documentElement.clientWidth)
  );

  useEffect(() => {
    const onResize = () => {
      setSlidesPerView(
        getSlidesPerViewForWindowSize(document.documentElement.clientWidth)
      );
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [getSlidesPerViewForWindowSize]);

  const ionSlidesOptions = useMemo(
    () => ({
      zoom: false,
      slidesPerView: _slidesPerView,
      loop: true,
      autoplay:
        autoplayState && autoplayState.autoplay
          ? {
              delay: autoplayState.autoplayDelay,
            }
          : undefined,
    }),
    [_slidesPerView, autoplayState]
  );

  const ionSlidesKey = useMemo(() => {
    const { slidesPerView, autoplay } = ionSlidesOptions;
    return `${slidesPerView}-${autoplay ? autoplay.delay : "n-autoplay"}`;
  }, [ionSlidesOptions]);

  const handleSlidesNextButtonClick = useCallback(
    async (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (slidesRef.current == null) {
        return;
      }
      await slidesRef.current.slideNext(500);
    },
    []
  );

  const handleSlidesPrevButtonClick = useCallback(
    async (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (slidesRef.current == null) {
        return;
      }
      await slidesRef.current.slidePrev(500);
    },
    []
  );

  const profileSessionContext = useContext(ProfileSessionContext);

  useEffectOnce(() => {
    if (profileSessionContext)
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Contents (BrandSlider) Shown"
      );
  });

  return (
    <div className={styles.slider}>
      {titleViewState ? (
        <div
          className={styles.header}
          style={titleViewState.sliderHeaderStyles}
        >
          <h2 className={styles.title} style={titleViewState.sliderTitleStyles}>
            {titleViewState.sliderTitle}
          </h2>
        </div>
      ) : null}
      {sliderViewState ? (
        <div className={styles.body}>
          {brandSlider && brandSlider.buttonsShow ? (
            <button
              className={classnames(
                styles.navButton,
                styles["navButton--prev"]
              )}
              onClick={handleSlidesPrevButtonClick}
            />
          ) : null}
          <div className={styles.theSlider}>
            <IonSlides
              options={ionSlidesOptions}
              mode="ios"
              ref={slidesRef}
              key={ionSlidesKey}
            >
              {sliderViewState.items.map((item, index) => (
                <IonSlide key={index}>
                  <SliderItem item={item} onClick={onSliderItemClick} />
                </IonSlide>
              ))}
            </IonSlides>
          </div>
          {brandSlider && brandSlider.buttonsShow ? (
            <button
              className={classnames(
                styles.navButton,
                styles["navButton--next"]
              )}
              onClick={handleSlidesNextButtonClick}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Slider;

interface SliderItemProps {
  item: DisplayableBrandSliderItem;
  onClick: (item: BrandSliderItem) => void;
}

const SliderItem: React.FC<SliderItemProps> = props => {
  const { item, onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(item.brandSliderItem);
    },
    [onClick, item]
  );
  return (
    <button onClick={handleClick}>
      <img src={item.img} alt={item.alt || undefined} />
    </button>
  );
};
