import React from "react";
import cn from "classnames";

import { RecurringConfiguration } from "../../models/product";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import RecurringDate from "../RecurringDate";

import styles from "./SubscriptionInfo.module.scss";

import trueIcon from "../../resources/ic-subscription-info-true.svg";
import falseIcon from "../../resources/ic-subscription-info-false.svg";

interface Props {
  recurringConfiguration: RecurringConfiguration;
}

const SubscriptionInfo: React.FC<Props> = props => {
  const { recurringConfiguration } = props;
  const {
    isRecurringEnable,
    billingCycle,
    isEnableTrial,
    isFreeShipping,
    startDate,
    endDate,
  } = recurringConfiguration;

  return isRecurringEnable ? (
    <div className={styles.subscriptionInfo}>
      <Row titleID="page.product_detail.subscription_info.billing_cycle">
        {billingCycle}
      </Row>
      <Row titleID="page.product_detail.subscription_info.free_trials">
        {isEnableTrial ? (
          <img className={styles.booleanIcon} src={trueIcon} alt="" />
        ) : (
          <img className={styles.booleanIcon} src={falseIcon} alt="" />
        )}
      </Row>
      <Row titleID="page.product_detail.subscription_info.free_shipping">
        {isFreeShipping ? (
          <img className={styles.booleanIcon} src={trueIcon} alt="" />
        ) : (
          <img className={styles.booleanIcon} src={falseIcon} alt="" />
        )}
      </Row>
      {startDate ? (
        <Row titleID="page.product_detail.subscription_info.start_date">
          <RecurringDate recurringDate={startDate} />
        </Row>
      ) : null}
      {endDate ? (
        <Row titleID="page.product_detail.subscription_info.end_date">
          <RecurringDate recurringDate={endDate} />
        </Row>
      ) : null}
    </div>
  ) : null;
};

export default SubscriptionInfo;

interface RowProps {
  titleID: MessageID;
  children: React.ReactNode;
}

const Row: React.FC<RowProps> = props => {
  const { titleID, children } = props;
  return (
    <div className={styles.row}>
      <div className={cn(styles.rowField, styles.fieldTitle)}>
        <LocalizedText messageID={titleID} />
      </div>
      <div className={cn(styles.rowField, styles.fieldValue)}>{children}</div>
    </div>
  );
};
