import React, { useCallback, useContext, useEffect, useState } from "react";
import TheClubSSOConfirmationModals from ".";
import { useKeepUpdatingRef } from "../../hook/utils";
import { useIntl } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";
import { Customer, isCustomerLinkedToTheClub } from "../../models/Customer";
import {
  useCustomer,
  useGetMyCustomer,
  useLinkSocialAccountRequest,
  useLogin,
  useLoginWithOAuthRequest,
  useLogout,
  useUnlinkSocialAccountRequest,
  useUpdateCustomerSsoConfirmation,
} from "../../repository/AuthRepository";
import {
  SSOResponse,
  TheClubSSOConfirmationStatus,
  useGetTheClubSSOConfirmationStatus,
  useLoginWithTheClub,
} from "../../useCase/AuthUseCase";
import {
  clubAcctConsentClickEvent,
  clubAcctConsentImpressionEvent,
  pairedAcctConsentClickEvent,
  pairedAcctConsentImpressionEvent,
  pairUpAnotherAcctClubClickEvent,
  pairUpAnotherAcctPairedEvent,
  pairUpAnotherAcctUnpairedEvent,
  singleSignOnFailEvent,
  unpairAcctConsentClickEvent,
  unpairAcctConsentImpressionEvent,
} from "../../utils/GTM";
import { isEmpty } from "../../utils/String";
import { LoadingModalContext } from "../LoadingModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import SocialSignupTermsAndConditionsModal from "../SocialSignupTermsAndConditionsModal";

export type TheClubSSOCOnfirmationStatusResult = Exclude<
  TheClubSSOConfirmationStatus,
  { type: "NoActionRequired" }
> | null;

interface Props {
  theClubSSOConfirmationModalShown: boolean;
  showTheClubSSOConfirmationModal: () => void;
  hideTheClubSSOConfirmationModal: () => void;

  theClubSSOConfirmationStatusResult: TheClubSSOCOnfirmationStatusResult | null;
  setTheClubSSOConfirmationStatusResult: (
    result: TheClubSSOCOnfirmationStatusResult | null
  ) => void;

  onLoginSuccess: (customer: Customer) => void;
}

const TheClubSSOConfirmationFlow: React.FC<Props> = props => {
  const {
    theClubSSOConfirmationModalShown,
    showTheClubSSOConfirmationModal,
    hideTheClubSSOConfirmationModal,
    theClubSSOConfirmationStatusResult,
    setTheClubSSOConfirmationStatusResult,
    onLoginSuccess,
  } = props;

  useGTMConsentImpressionEvents(theClubSSOConfirmationStatusResult);
  const gtmConsentClickEvent = useGTMConsentClickEvents(
    theClubSSOConfirmationStatusResult
  );

  const {
    presentLocalizedAlert,
    presentLocalizedAlertOnAsyncError,
  } = useContext(LocalizedAlertContext);
  const { withLoadingModalAsync } = useContext(LoadingModalContext);

  const { translate } = useIntl();
  const loginWithTheClub = useLoginWithTheClub();
  const getTheClubSSOConfirmationStatus = useGetTheClubSSOConfirmationStatus();
  const updateCustomerSsoConfirmation = useUpdateCustomerSsoConfirmation();
  const getMyCustomer = useGetMyCustomer();
  const currentCustomer = useCustomer();
  const currentCustomerRef = useKeepUpdatingRef(currentCustomer);
  const logout = useLogout();
  const unlinkSocialAccount = useUnlinkSocialAccountRequest();
  const linkSocialAccount = useLinkSocialAccountRequest();

  const presentLoginFailMessageAlert = useCallback(
    (message: string) => {
      presentLocalizedAlert({
        headerId: "login.login_failed",
        message,
        buttons: [{ textMessageID: "try_again" }],
      });
    },
    [presentLocalizedAlert]
  );

  const presentLoginFailAlert = useCallback(
    (errorMessageId: MessageID) => {
      presentLoginFailMessageAlert(translate(errorMessageId));
    },
    [presentLoginFailMessageAlert, translate]
  );

  const login = useLogin();
  const loginWithOAuth = useLoginWithOAuthRequest();

  const [ssoLoginResult, setSSOLoginResult] = useState<SSOResponse | undefined>(
    undefined
  );
  const [
    socialSignupTermsModalIsOpen,
    setSocialSignupTermsModalIsOpen,
  ] = useState(false);
  const handleSSO = useCallback(
    async (
      loginProvider: () => Promise<SSOResponse>,
      allowSignup: boolean,
      method: LoginSignUpMethod
    ) => {
      try {
        const result = await loginProvider();
        setSSOLoginResult(result);
        return await login(() => loginWithOAuth(result.token, result.provider));
      } catch (e) {
        if (!(e instanceof Error) || isEmpty(e.message)) {
          presentLoginFailAlert("login.login_with_oauth_failed_text");
          return;
        }
        if (e.message === "cancelled") {
          return null;
        }
        if (e.message === "Customer not exist.") {
          if (allowSignup) {
            setSocialSignupTermsModalIsOpen(true);
          } else {
            if (method) {
              singleSignOnFailEvent(method, "acct_inexist");
            }
            presentLoginFailAlert(
              "the_club_sso.club_shopping_sign_in.oauth_signup_failed"
            );
          }
          return null;
        }
        if (method) {
          singleSignOnFailEvent(method, "invalid_login");
        }
        presentLoginFailMessageAlert(e.message);
        return null;
      }
    },
    [login, presentLoginFailAlert, loginWithOAuth, presentLoginFailMessageAlert]
  );

  const isHandlingSSOConfirmation = useCallback(
    async (customer: Customer, method: LoginSignUpMethod) => {
      const theClubSSOConfirmationStatus = await getTheClubSSOConfirmationStatus(
        customer,
        method
      );
      if (theClubSSOConfirmationStatus.type !== "NoActionRequired") {
        showTheClubSSOConfirmationModal();
        setTheClubSSOConfirmationStatusResult(theClubSSOConfirmationStatus);
        return true;
      }
      return false;
    },
    [
      getTheClubSSOConfirmationStatus,
      showTheClubSSOConfirmationModal,
      setTheClubSSOConfirmationStatusResult,
    ]
  );

  const onSocialSignupTermsModalRequestDismiss = useCallback(() => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
  }, []);

  const handleSocialSignupTermsModalSuccess = useCallback(async () => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
    const _customer = await getMyCustomer.startRequesting().catch(() => null);
    if (_customer) {
      presentLocalizedAlertOnAsyncError(async () => {
        const isMember = isCustomerLinkedToTheClub(_customer);
        if (
          !(await isHandlingSSOConfirmation(
            _customer,
            isMember ? "The Club" : "Email"
          ))
        ) {
          onLoginSuccess(_customer);
        }
      });
    }
  }, [
    getMyCustomer,
    presentLocalizedAlertOnAsyncError,
    isHandlingSSOConfirmation,
    onLoginSuccess,
  ]);

  const unlinkAndLinkWithTheClub = useCallback(async () => {
    try {
      await unlinkSocialAccount("the-club");
      const ssoResponse = await loginWithTheClub();
      const linkResult = await linkSocialAccount(
        ssoResponse.token,
        ssoResponse.provider
      );
      if (linkResult.errorMessage) {
        throw new Error(linkResult.errorMessage);
      }
      return linkResult.customer || null;
    } catch (e) {
      if (e.message !== "cancelled") {
        throw e;
      }
    }
    return null;
  }, [unlinkSocialAccount, loginWithTheClub, linkSocialAccount]);

  const justLinkWithTheClub = useCallback(async () => {
    try {
      const ssoResponse = await loginWithTheClub();
      const linkResult = await linkSocialAccount(
        ssoResponse.token,
        ssoResponse.provider
      );
      if (linkResult.errorMessage) {
        throw new Error(linkResult.errorMessage);
      }
      return linkResult.customer || null;
    } catch (e) {
      if (e.message !== "cancelled") {
        throw e;
      }
    }
    return null;
  }, [loginWithTheClub, linkSocialAccount]);

  const handleTheClubSSOConfirm = useCallback(
    async (marketingMaterials: boolean) => {
      gtmConsentClickEvent(marketingMaterials);
      presentLocalizedAlertOnAsyncError(async () => {
        await withLoadingModalAsync(() =>
          updateCustomerSsoConfirmation(marketingMaterials)
        );
        hideTheClubSSOConfirmationModal();
        const customer = await withLoadingModalAsync(
          getMyCustomer.startRequesting
        );
        if (customer) {
          onLoginSuccess(customer);
        }
      });
    },
    [
      gtmConsentClickEvent,
      hideTheClubSSOConfirmationModal,
      updateCustomerSsoConfirmation,
      getMyCustomer,
      onLoginSuccess,
      presentLocalizedAlertOnAsyncError,
      withLoadingModalAsync,
    ]
  );

  const handleTheClubUserLinkWithAnotherAccount = useCallback(
    async (marketingMaterials: boolean) => {
      pairUpAnotherAcctClubClickEvent(
        "The Club",
        getOptInStatus(marketingMaterials)
      );
      //  Logout -> Present the club login -> show confirmation again if necessary
      presentLocalizedAlertOnAsyncError(async () => {
        await withLoadingModalAsync(logout);
        hideTheClubSSOConfirmationModal();
        const customer = await withLoadingModalAsync(() =>
          handleSSO(loginWithTheClub, true, "The Club")
        );
        if (
          customer &&
          !(await withLoadingModalAsync(() =>
            isHandlingSSOConfirmation(customer, "The Club")
          ))
        ) {
          onLoginSuccess(customer);
        }
      });
    },
    [
      hideTheClubSSOConfirmationModal,
      logout,
      handleSSO,
      loginWithTheClub,
      isHandlingSSOConfirmation,
      onLoginSuccess,
      presentLocalizedAlertOnAsyncError,
      withLoadingModalAsync,
    ]
  );

  const handleClubShoppingUserLinkWithAnotherAccount = useCallback(
    async (method: LoginSignUpMethod, marketingMaterials: boolean) => {
      pairUpAnotherAcctPairedEvent(method, getOptInStatus(marketingMaterials));
      // Unpair the club account -> Present the club login -> pair account -> show confirmation again if necessary
      presentLocalizedAlertOnAsyncError(async () => {
        const customer =
          (await withLoadingModalAsync(unlinkAndLinkWithTheClub)) ||
          currentCustomerRef.current;
        hideTheClubSSOConfirmationModal();
        if (
          customer &&
          !(await withLoadingModalAsync(() =>
            isHandlingSSOConfirmation(customer, method)
          ))
        ) {
          onLoginSuccess(customer);
        }
      });
    },
    [
      hideTheClubSSOConfirmationModal,
      unlinkAndLinkWithTheClub,
      currentCustomerRef,
      isHandlingSSOConfirmation,
      onLoginSuccess,
      presentLocalizedAlertOnAsyncError,
      withLoadingModalAsync,
    ]
  );

  const handleClubShoppingUserLinkWithClubClub = useCallback(
    async (method: LoginSignUpMethod) => {
      unpairAcctConsentClickEvent(method);
      // Present the club login -> pair account -> show confirmation again if necessary
      presentLocalizedAlertOnAsyncError(async () => {
        const customer =
          (await withLoadingModalAsync(justLinkWithTheClub)) ||
          currentCustomerRef.current;
        hideTheClubSSOConfirmationModal();
        if (
          customer &&
          !(await withLoadingModalAsync(() =>
            isHandlingSSOConfirmation(customer, method)
          ))
        ) {
          onLoginSuccess(customer);
        }
      });
    },
    [
      hideTheClubSSOConfirmationModal,
      justLinkWithTheClub,
      currentCustomerRef,
      isHandlingSSOConfirmation,
      onLoginSuccess,
      presentLocalizedAlertOnAsyncError,
      withLoadingModalAsync,
    ]
  );

  const handleClubShoppingUserSwitchAccount = useCallback(
    async (method: LoginSignUpMethod) => {
      // Logout -> Show club shopping login
      pairUpAnotherAcctUnpairedEvent(method);
      await withLoadingModalAsync(logout);
      hideTheClubSSOConfirmationModal();
    },
    [withLoadingModalAsync, logout, hideTheClubSSOConfirmationModal]
  );

  return (
    <>
      <TheClubSSOConfirmationModals
        isOpen={theClubSSOConfirmationModalShown}
        theClubSSOConfirmationStatus={theClubSSOConfirmationStatusResult}
        onConfirmClick={handleTheClubSSOConfirm}
        onTheClubUserLinkWithAnotherAccountClick={
          handleTheClubUserLinkWithAnotherAccount
        }
        onClubShoppingUserLinkWithAnotherAccountClick={
          handleClubShoppingUserLinkWithAnotherAccount
        }
        onClubShoppingUserLinkWithTheClubClick={
          handleClubShoppingUserLinkWithClubClub
        }
        onClubShoppingUserSwitchAccountClick={
          handleClubShoppingUserSwitchAccount
        }
      />
      <SocialSignupTermsAndConditionsModal
        isModalOpen={socialSignupTermsModalIsOpen}
        onRequestDismiss={onSocialSignupTermsModalRequestDismiss}
        onSuccess={handleSocialSignupTermsModalSuccess}
        provider={ssoLoginResult ? ssoLoginResult.provider : undefined}
        token={ssoLoginResult ? ssoLoginResult.token : undefined}
      />
    </>
  );
};

export default TheClubSSOConfirmationFlow;

function useGTMConsentImpressionEvents(
  theClubSSOConfirmationStatusResult: TheClubSSOCOnfirmationStatusResult | null
) {
  useEffect(() => {
    if (!theClubSSOConfirmationStatusResult) {
      return;
    }
    switch (theClubSSOConfirmationStatusResult.type) {
      case "SimpleSSOConfirmationRequired":
        clubAcctConsentImpressionEvent("The Club", "true");
        break;
      case "SimpleSSOConfirmationRequiredWithTheClubInfo":
        pairedAcctConsentImpressionEvent(
          theClubSSOConfirmationStatusResult.method,
          "true"
        );
        break;
      case "PairWithTheClubRequired":
        unpairAcctConsentImpressionEvent(
          theClubSSOConfirmationStatusResult.method
        );
    }
  }, [theClubSSOConfirmationStatusResult]);
}

function useGTMConsentClickEvents(
  theClubSSOConfirmationStatusResult: TheClubSSOCOnfirmationStatusResult | null
) {
  return useCallback(
    (marketingMaterials: boolean) => {
      if (!theClubSSOConfirmationStatusResult) {
        return;
      }
      const optInStatus: OptInStatus = getOptInStatus(marketingMaterials);
      switch (theClubSSOConfirmationStatusResult.type) {
        case "SimpleSSOConfirmationRequired":
          clubAcctConsentClickEvent("The Club", optInStatus);
          break;
        case "SimpleSSOConfirmationRequiredWithTheClubInfo":
          pairedAcctConsentClickEvent(
            theClubSSOConfirmationStatusResult.method,
            optInStatus
          );
          break;
        default:
          break;
      }
    },
    [theClubSSOConfirmationStatusResult]
  );
}

function getOptInStatus(marketingMaterials: boolean): OptInStatus {
  return marketingMaterials ? "true" : "false";
}
