import {
  ApolloClient,
  gql,
  FetchPolicy,
  ApolloQueryResult,
  useApolloClient,
} from "@apollo/client";
import produce from "immer";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";
import { useIntl } from "../../i18n/Localization";
import { MoneyGraphQLAttributes } from "../../models/Price";
import {
  keyGraphQLAttributes as productKeyGraphQLAttributes,
  ModelKeys,
  ProductConfigurableOptionGraphQLAttributes,
  ProductImageGraphQLAttributes,
} from "../../models/product";
import {
  SelectedConfigurableOptionGraphQL,
  SelectedCustomizableOptionGraphQL,
  CartDiscountBreakdownGraphQLAttributes,
  CartDiscountsGraphQLAttributes,
  extractProductIdsFromCart,
  classifyBundleOfCartItems,
  MaybeBundledCartItem,
  BundledCartItem,
  NonBundledCartItem,
  InstalmentMsg,
  CartInstallmentGraphQLAttributes,
  CartItemPriceGraphQLAttributes,
} from "../../models/cart";
import {
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { GraphQLFn, parseGraphQLError } from "../../api/GraphQL";
import { fetchProductSaleBundlesForProductSKUOnly } from "../../api/ProductSaleBundle";
import {
  LuckyDrawCartItem,
  PartialCart,
  PartialCartItem,
  RemotePartialCart,
  transformRemotePartialCartToPartialCart,
} from "./models";
import { CartIDContext, CartIDInjectingFn } from "../CartIDProvider";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";
import Config from "../../Config";
import { CLError, LocalError, RemoteError } from "../../utils/Error";
import { indexMapBy } from "../../utils/type";
import { profileAsyncAction } from "../../utils/performance";
import { CheckoutSession } from "../../utils/PerformanceRecordStore/sessions";

type APICartType = RemotePartialCart;
interface CartMutationResponseType {
  cart: APICartType;
}

const ProductVariantGrapQLAttributes = `
product {
  ${productKeyGraphQLAttributes}
  name
  type: type_id
  thumbnail {
    ${ProductImageGraphQLAttributes}
  }
  extraClubpoints: extra_clubpoints
  clClubPoint: cl_clubpoints
  clubPoint: clubpoints
  minClubPoint: min_clubpoints
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
    maximumPrice: maximum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
  }
  merchant {
    name: store_name
  }
  deliveryMethod: delivery_method
  deliveryMethodLabel: delivery_method_label
  stockStatus: stock_status
  estimatedDeliveryDate: estimated_delivery_date
}
attributes {
  label
  value: value_index
  code
}
`;

const ProductGraphQLAttribute = `
${productKeyGraphQLAttributes}
name
type: type_id
thumbnail {
  ${ProductImageGraphQLAttributes}
}
clubPoint: clubpoints
minClubPoint: min_clubpoints
extraClubpoints: extra_clubpoints
clClubPoint: cl_clubpoints
priceRange: price_range {
  minimumPrice: minimum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
  maximumPrice: maximum_price {
    regularPrice: regular_price {
      ${MoneyGraphQLAttributes}
    }
    finalPrice: final_price {
      ${MoneyGraphQLAttributes}
    }
  }
}
merchant {
  name: store_name
}
stockStatus: stock_status
deliveryMethod: delivery_method
deliveryMethodLabel: delivery_method_label
deliveryMethodBlockIdentifier: delivery_method_block_identifier
estimatedDeliveryDate: estimated_delivery_date
...on ConfigurableProduct {
  configurableOptions: configurable_options {
    ${ProductConfigurableOptionGraphQLAttributes}
  }
  variants {
    ${ProductVariantGrapQLAttributes}
  }
}
`;

function getCartGraphQL(
  appliedCouponField: "appliedCoupon" | "appliedCoupons",
  discountField: "discounts" | "discountBreakdown"
) {
  return `
id
items {
  id
  product {
    ${ProductGraphQLAttribute}
  }
  ...on VirtualCartItem {
    isFreeGift: is_free_gift
    customizableOptionsForVirtualCartItem: customizable_options {
      ${SelectedCustomizableOptionGraphQL}
    }
  }
  ...on SimpleCartItem {
    isFreeGift: is_free_gift
    customizableOptionsForSimpleCartItem: customizable_options {
      ${SelectedCustomizableOptionGraphQL}
    }
  }
  ...on ConfigurableCartItem {
    configurableOptions: configurable_options {
      ${SelectedConfigurableOptionGraphQL}
    }
    customizableOptionsForConfigurableCartItem: customizable_options {
      ${SelectedCustomizableOptionGraphQL}
    }
  }
  quantity
  isRecurring: is_recurring
  recurringOptions: recurring_options {
    label
    value
  }
}
prices {
  grandTotal: grand_total { ${MoneyGraphQLAttributes} }
  subtotalExcludingTax: subtotal_excluding_tax { ${MoneyGraphQLAttributes} }
  subtotalIncludingTax: subtotal_including_tax { ${MoneyGraphQLAttributes} }
  subtotalWithDiscountExcludingTax: subtotal_with_discount_excluding_tax { ${MoneyGraphQLAttributes} }
  discountAmount: discount_amount { ${MoneyGraphQLAttributes} }
  initialSubscriptionFee: initial_subscription_fee { ${MoneyGraphQLAttributes} }
${
  discountField === "discounts"
    ? `
  discounts { ${CartDiscountsGraphQLAttributes} }
`
    : discountField === "discountBreakdown"
    ? `
  discountBreakdown: discount_breakdown { ${CartDiscountBreakdownGraphQLAttributes} }
`
    : ""
}
  shippingAmount: shipping_amount { ${MoneyGraphQLAttributes} }
}
clubPointToBeEarned: clubpoints_to_be_earned
clubPointToBeUsed: clubpoints_to_be_used
clubPointRequired: clubpoints_required
${
  appliedCouponField === "appliedCoupons"
    ? `
appliedCoupons: applied_coupons {
  code
}
`
    : appliedCouponField === "appliedCoupon"
    ? `
appliedCoupon: applied_coupon {
  code
}
`
    : ""
}
`;
}

function queryCartWithGraphQLAttributes<T>(
  client: ApolloClient<unknown>,
  locale: Locale,
  id: string,
  fetchPolicy: FetchPolicy,
  graphQLAttributes: string
) {
  return client.query<{ cart: T }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryCart($cart_id: String!) {
        cart(cart_id: $cart_id) {
          ${graphQLAttributes}
        }
      }
    `,
    variables: {
      cart_id: id,
    },
    fetchPolicy,
  });
}

const CartItemPricesGraphQLAttributes = `
id
items {
  id
  ${CartItemPriceGraphQLAttributes}
}`;

export interface CartItemPricesType {
  items: Pick<PartialCartItem, "id" | "prices">[];
}

// --------------------------------------------------------------
// FEATURE_MERGE: shippingGroup presents in Cart.items
//   ==> getCartGraphQL
const CartItemShippingGroupGraphQLAttributes = `
id
items {
  id
  shippingGroup: shipping_group
}
`;

export interface CartItemShippingGroupType {
  items: Pick<PartialCartItem, "id" | "shippingGroup">[];
}

function queryCartItemShippingGroup(
  client: ApolloClient<unknown>,
  locale: Locale,
  id: string,
  fetchPolicy: FetchPolicy
) {
  return queryCartWithGraphQLAttributes<CartItemShippingGroupType>(
    client,
    locale,
    id,
    fetchPolicy,
    CartItemShippingGroupGraphQLAttributes
  ).catch(() => ({ data: { cart: { items: [] } } }));
}

function additionalQueries(
  client: ApolloClient<unknown>,
  locale: Locale,
  id: string,
  fetchPolicy: FetchPolicy
): [
  Promise<Pick<ApolloQueryResult<{ cart: CartItemShippingGroupType }>, "data">>,
  Promise<
    Pick<
      ApolloQueryResult<{ cart: { instalmentMsg: InstalmentMsg | null } }>,
      "data"
    >
  >,
  Promise<Pick<ApolloQueryResult<{ cart: CartItemPricesType }>, "data">>
] {
  return [
    queryCartItemShippingGroup(client, locale, id, fetchPolicy),
    queryCartWithGraphQLAttributes<{
      instalmentMsg: InstalmentMsg | null;
    }>(client, locale, id, fetchPolicy, CartInstallmentGraphQLAttributes).catch(
      () => ({ data: { cart: { instalmentMsg: null } } })
    ),
    queryCartWithGraphQLAttributes<CartItemPricesType>(
      client,
      locale,
      id,
      fetchPolicy,
      CartItemPricesGraphQLAttributes
    ).catch(() => ({ data: { cart: { items: [] } } })),
  ];
}

function patchCart(
  cart: PartialCart,
  cartItemShippingGroupType: CartItemShippingGroupType,
  cartItemPricesType: CartItemPricesType,
  instalmentMsgType: { instalmentMsg: InstalmentMsg | null }
) {
  const cartItemShippingGroupTypeIdMap: {
    [key in string]: {
      id: string;
      shippingGroup?: PartialCartItem["shippingGroup"];
    };
  } = {};
  for (const i of cartItemShippingGroupType.items) {
    cartItemShippingGroupTypeIdMap[i.id] = i;
  }

  const cartItemPricesTypeIdMap = indexMapBy(
    i => i.id,
    cartItemPricesType.items
  );

  return produce(cart, draft => {
    for (const item of draft.items) {
      if (cartItemShippingGroupTypeIdMap[item.id]) {
        item.shippingGroup =
          cartItemShippingGroupTypeIdMap[item.id].shippingGroup;
      }
      const cartItemPrices = cartItemPricesTypeIdMap[item.id];
      if (cartItemPrices) {
        item.prices = cartItemPrices.prices;
      }
    }
    draft.instalmentMsg = instalmentMsgType.instalmentMsg;
  });
}
// END FEATURE_MERGE is_free_shipping presents in Cart.items.product
// -----------------------------------------------------------------

async function fetchCartAPI(
  client: ApolloClient<any>,
  locale: Locale,
  id: string,
  fetchPolicy: FetchPolicy
): Promise<PartialCart> {
  try {
    const [
      result,
      isFreeShippingData,
      instalmentMsgResult,
      cartItemPricesResult,
    ] = await Promise.all([
      queryCartWithGraphQLAttributes<APICartType>(
        client,
        locale,
        id,
        fetchPolicy,
        getCartGraphQL("appliedCoupons", "discounts")
      ),
      ...additionalQueries(client, locale, id, fetchPolicy),
    ]);
    const cart = patchCart(
      transformRemotePartialCartToPartialCart(result.data.cart),
      isFreeShippingData.data.cart,
      cartItemPricesResult.data.cart,
      instalmentMsgResult.data.cart
    );
    return {
      ...cart,
      instalmentMsg: instalmentMsgResult
        ? instalmentMsgResult.data.cart.instalmentMsg
        : undefined,
    };
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function updateCartItemQuantityAPI(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  itemId: number,
  quantity: number
): Promise<PartialCart> {
  const result = await client.mutate<{
    updateCartItems: CartMutationResponseType;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation UpdateCartItemQuantity($cart_id: String!, $cart_item_id: Int!, $quantity: Float!) {
        updateCartItems(
          input: {
            cart_id: $cart_id,
            cart_items: [{
              cart_item_id: $cart_item_id
              quantity: $quantity
            }]
          }
        ) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
    variables: {
      cart_id: cartId,
      cart_item_id: itemId,
      quantity,
    },
    fetchPolicy: "no-cache",
  });

  if (!result.data) {
    throw new Error();
  }

  const [
    isFreeShippingData,
    instalmentMsgData,
    cartItemPricesData,
  ] = await Promise.all(additionalQueries(client, locale, cartId, "no-cache"));

  const cart = patchCart(
    transformRemotePartialCartToPartialCart(result.data.updateCartItems.cart),
    isFreeShippingData.data.cart,
    cartItemPricesData.data.cart,
    instalmentMsgData.data.cart
  );
  return {
    ...cart,
    instalmentMsg: instalmentMsgData
      ? instalmentMsgData.data.cart.instalmentMsg
      : undefined,
  };
}

async function removeCartItemAPI(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  itemId: number
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      removeItemFromCart: CartMutationResponseType;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation RemoveCartItem($cart_id: String!, $cart_item_id: Int!) {
        removeItemFromCart(
          input: {
            cart_id: $cart_id,
            cart_item_id: $cart_item_id
          }
        ) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cart_id: cartId,
        cart_item_id: itemId,
      },
      fetchPolicy: "no-cache",
    });

    if (!result.data) {
      throw new Error();
    }

    const [
      isFreeShippingData,
      instalmentMsgData,
      cartItemPricesData,
    ] = await Promise.all(
      additionalQueries(client, locale, cartId, "no-cache")
    );

    const cart = patchCart(
      transformRemotePartialCartToPartialCart(
        result.data.removeItemFromCart.cart
      ),
      isFreeShippingData.data.cart,
      cartItemPricesData.data.cart,
      instalmentMsgData.data.cart
    );
    return cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function removeCouponFromCartAPI(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      removeCouponFromCart: CartMutationResponseType;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation RemoveCouponFromCart($cartId: String!) {
        removeCouponFromCart(input: { cart_id: $cartId }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    const [
      isFreeShippingData,
      instalmentMsgData,
      cartItemPricesData,
    ] = await Promise.all(
      additionalQueries(client, locale, cartId, "no-cache")
    );

    const cart = patchCart(
      transformRemotePartialCartToPartialCart(
        result.data.removeCouponFromCart.cart
      ),
      isFreeShippingData.data.cart,
      cartItemPricesData.data.cart,
      instalmentMsgData.data.cart
    );
    return cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function applyCouponToCartAPI(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  couponCode: string
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      applyCouponToCart: CartMutationResponseType;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation ApplyCouponToCart($cartId: String!, $couponCode: String!) {
        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
        couponCode,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    const [
      isFreeShippingData,
      instalmentMsgData,
      cartItemPricesData,
    ] = await Promise.all(
      additionalQueries(client, locale, cartId, "no-cache")
    );

    const cart = patchCart(
      transformRemotePartialCartToPartialCart(
        result.data.applyCouponToCart.cart
      ),
      isFreeShippingData.data.cart,
      cartItemPricesData.data.cart,
      instalmentMsgData.data.cart
    );
    return cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function setClubPointOnCartAPI(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  clubpoint: number
): Promise<PartialCart> {
  try {
    const result = await client.mutate<{
      setClubPointOnCart: CartMutationResponseType;
    }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation SetClubPointOnCart($cartId: String!, $clubpoint: Int!) {
        setClubPointOnCart(input: { cart_id: $cartId, clubpoints: $clubpoint }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
        clubpoint,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    const [
      isFreeShippingData,
      instalmentMsgData,
      cartItemPricesData,
    ] = await Promise.all(
      additionalQueries(client, locale, cartId, "no-cache")
    );

    const cart = patchCart(
      transformRemotePartialCartToPartialCart(
        result.data.setClubPointOnCart.cart
      ),
      isFreeShippingData.data.cart,
      cartItemPricesData.data.cart,
      instalmentMsgData.data.cart
    );
    return cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

async function setOptOutLuckyDrawAPI(
  client: ApolloClient<any>,
  locale: Locale,
  cartId: string,
  item: LuckyDrawCartItem,
  optOut: boolean
) {
  try {
    const result = await client.mutate<
      {
        setOptOutLuckyDraw: CartMutationResponseType;
      },
      {
        cartId: string;
        itemId: string;
        optOut: boolean;
      }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
      mutation SetOptOutLuckyDraw($cartId: String!, $itemId: String!, $optOut: Boolean) {
        setOptOutLuckyDraw(input: {
          cart_id: $cartId
          item_id: $itemId
          opt_out: $optOut
        }) {
          cart {
          ${getCartGraphQL("appliedCoupons", "discounts")}
          }
        }
      }
    `,
      variables: {
        cartId,
        itemId: item.id,
        optOut,
      },
    });

    if (!result.data) {
      throw new Error();
    }

    const [
      isFreeShippingData,
      instalmentMsgData,
      cartItemPricesData,
    ] = await Promise.all(
      additionalQueries(client, locale, cartId, "no-cache")
    );

    const cart = patchCart(
      transformRemotePartialCartToPartialCart(
        result.data.setOptOutLuckyDraw.cart
      ),
      isFreeShippingData.data.cart,
      cartItemPricesData.data.cart,
      instalmentMsgData.data.cart
    );
    return cart;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

interface CartStateFunctions {
  setCart: (v: PartialCart) => void;
  setActionInProgress: (v: boolean) => void;
  setActionError: (v: any) => void;
}

async function applyCartAction<T extends GraphQLFn<PartialCart>>(
  fn: T,
  gracefully: boolean,
  callCartAPI: CartIDInjectingFn,
  callCartAPIGracefully: CartIDInjectingFn,
  stateFns: CartStateFunctions,
  ...args: any
) {
  stateFns.setActionInProgress(true);
  try {
    const cart = await (gracefully
      ? callCartAPIGracefully(fn, ...args)
      : callCartAPI(fn, ...args));
    stateFns.setCart(cart);
    return cart;
  } catch (e) {
    if (gracefully) {
      stateFns.setActionError(e);
      return null;
    }
    throw e;
  } finally {
    stateFns.setActionInProgress(false);
  }
}

export function useCartResource() {
  const [cart, setCart] = useState<PartialCart | null>(null);
  const [
    productSaleBundlesForProductSKU,
    setProductSaleBundlesForProductSKU,
  ] = useState<ProductSaleBundle<ModelKeys>[]>([]);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [setClubPointInProgress, setSetClubPointInProgress] = useState(false);
  const [actionError, setActionError] = useState<Error | null>(null);

  const [
    applyCouponToCartError,
    setApplyCouponToCartError,
  ] = useState<CLError | null>(null);

  const maybeBundledCartItems = useMemo<
    MaybeBundledCartItem<PartialCartItem>[]
  >(() => {
    if (!cart) {
      return [];
    }
    const [classifiedItems, unbundledItems] = classifyBundleOfCartItems<
      PartialCartItem
    >(cart.items, productSaleBundlesForProductSKU);
    const res: MaybeBundledCartItem<PartialCartItem>[] = [];
    for (const classifiedItem of classifiedItems) {
      const { bundle, bundleItem, items } = classifiedItem;
      for (const item of items) {
        res.push(BundledCartItem(bundle, bundleItem, item));
      }
    }
    for (const item of unbundledItems) {
      res.push(NonBundledCartItem(item));
    }
    return res;
  }, [cart, productSaleBundlesForProductSKU]);

  const { cartID, callCartAPI, callCartAPIGracefully } = useContext(
    CartIDContext
  );

  const { setCount: setShoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  const fetchCart = fetchCartAPI;
  const removeCartItem = removeCartItemAPI;
  const updateCartItemQuantity = updateCartItemQuantityAPI;
  const removeCouponFromCart = removeCouponFromCartAPI;
  const applyCouponToCart_ = applyCouponToCartAPI;
  const setClubPointOnCart_ = setClubPointOnCartAPI;
  const setOptOutLuckyDrawOnCart_ = setOptOutLuckyDrawAPI;

  useEffect(() => {
    if (!cart) {
      setShoppingCartItemCount(0);
      return;
    }
    const { items } = cart;
    let count = 0;
    for (const item of items) {
      count += item.quantity;
    }
    setShoppingCartItemCount(count);
  }, [cart, setShoppingCartItemCount]);

  const stateFns = useMemo(
    () => ({
      setCart,
      setActionInProgress,
      setActionError,
    }),
    [setCart, setActionInProgress, setActionError]
  );

  const setClubPointStateFns = useMemo(
    () => ({
      setCart,
      setActionInProgress: setSetClubPointInProgress,
      setActionError,
    }),
    [setCart, setActionError]
  );

  const apolloClient = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    PartialCart | null,
    () => Promise<PartialCart | null>
  >({
    memoryCacheProvider: () => Promise.resolve(cart),
    remoteResourcesProvider: async () => {
      const _cart = await callCartAPIGracefully(fetchCart, "network-only");
      const productIds = extractProductIdsFromCart(_cart);
      if (Config.ENABLE_BUNDLE_SALE) {
        const productSaleBundles = await fetchProductSaleBundlesForProductSKUOnly(
          apolloClient,
          productIds,
          locale,
          "network-only"
        ).catch(() => []);
        setProductSaleBundlesForProductSKU(productSaleBundles);
      }
      setCart(_cart);
      return _cart;
    },
  });

  const fetchCartIsLoading = useMemo(() => isRequestLoading(requestState), [
    requestState,
  ]);
  const fetchCartError = useMemo(() => getRequestStateError(requestState), [
    requestState,
  ]);
  const refreshCart = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);

  useEffect(() => {
    profileAsyncAction(CheckoutSession(), "Fetch shopping cart", () =>
      fetch().catch(() => {})
    );
  }, [fetch, cartID]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeCartItemWithCartId = useCallback(
    async (itemId: number) => {
      return applyCartAction(
        removeCartItem,
        true,
        callCartAPI,
        callCartAPIGracefully,
        stateFns,
        itemId
      );
    },
    [callCartAPI, callCartAPIGracefully, removeCartItem, stateFns]
  );

  const updateCartItemQuantityWithCartId = useCallback(
    async (itemId: number, quantity: number) => {
      return applyCartAction(
        updateCartItemQuantity,
        true,
        callCartAPI,
        callCartAPIGracefully,
        stateFns,
        itemId,
        quantity
      );
    },
    [callCartAPI, callCartAPIGracefully, updateCartItemQuantity, stateFns]
  );

  const applyCouponToCart = useCallback(
    async (couponCode: string) => {
      setApplyCouponToCartError(null);
      try {
        let _cart = await callCartAPI(removeCouponFromCart);
        if (couponCode) {
          _cart = await callCartAPI(applyCouponToCart_, couponCode);
        }
        setCart(_cart);
      } catch (e) {
        if (!e.message) {
          setApplyCouponToCartError(LocalError("error.unknown"));
        } else {
          if (
            e.message ===
            "The coupon code isn't valid. Verify the code and try again."
          ) {
            setApplyCouponToCartError(
              LocalError("promotion.code.error.invalid")
            );
          } else {
            setApplyCouponToCartError(RemoteError(e.message));
          }
        }
      }
    },
    [callCartAPI, removeCouponFromCart, applyCouponToCart_]
  );

  const setClubPointOnCart = useCallback(
    async (clubPoint: number) => {
      return applyCartAction(
        setClubPointOnCart_,
        true,
        callCartAPI,
        callCartAPIGracefully,
        setClubPointStateFns,
        clubPoint
      );
    },
    [
      callCartAPI,
      callCartAPIGracefully,
      setClubPointOnCart_,
      setClubPointStateFns,
    ]
  );

  const setOptOutLuckyDrawOnCart = useCallback(
    async (item: LuckyDrawCartItem, optOut: boolean) => {
      return applyCartAction(
        setOptOutLuckyDrawOnCart_,
        false,
        callCartAPI,
        callCartAPIGracefully,
        stateFns,
        item,
        optOut
      );
    },
    [callCartAPI, callCartAPIGracefully, setOptOutLuckyDrawOnCart_, stateFns]
  );

  return useMemo(
    () => ({
      cart,
      maybeBundledCartItems,
      fetchCartIsLoading,
      fetchCartError,
      refreshCart,
      actionInProgress,
      setClubPointInProgress,
      actionError,
      removeCartItem: removeCartItemWithCartId,
      updateCartItemQuantity: updateCartItemQuantityWithCartId,
      applyCouponToCart,
      applyCouponToCartError,
      setClubPointOnCart,
      setOptOutLuckyDrawOnCart,
    }),
    [
      cart,
      maybeBundledCartItems,
      fetchCartIsLoading,
      fetchCartError,
      refreshCart,
      actionInProgress,
      setClubPointInProgress,
      actionError,
      removeCartItemWithCartId,
      updateCartItemQuantityWithCartId,
      applyCouponToCart,
      applyCouponToCartError,
      setClubPointOnCart,
      setOptOutLuckyDrawOnCart,
    ]
  );
}
