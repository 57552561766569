export const waitShadowRoot = (element: Element): Promise<ShadowRoot> => {
  return new Promise<ShadowRoot>((resolve, _reject) => {
    setTimeout(() => {
      if (element !== null && element.shadowRoot !== null) {
        if (element.shadowRoot.childNodes.length === 0) {
          new MutationObserver((mutationList, observer) => {
            if (element !== null && element.shadowRoot !== null) {
              for (const mutation of mutationList) {
                if (mutation.type === "childList") {
                  resolve(element.shadowRoot);
                  observer.disconnect();
                  break;
                }
              }
            }
          }).observe(element.shadowRoot, {
            childList: true,
            attributes: false,
            subtree: false,
          });
        } else {
          resolve(element.shadowRoot);
        }
      }
    }, 0);
  });
};

export const getScrollElement = async (element: HTMLIonContentElement) => {
  const shadowRoot = await waitShadowRoot(element);
  return shadowRoot.querySelector(".inner-scroll") as HTMLElement;
};

export const setStylesToElementWithSelector = async (
  element: Element,
  selector: string,
  styles: { [prop: string]: string }
) => {
  const shadowRoot = await waitShadowRoot(element);
  const el = shadowRoot.querySelector(selector) as HTMLElement;
  Object.entries(styles).forEach(([k, v]) => el.style.setProperty(k, v));
};
