import { useApolloClient } from "@apollo/react-hooks";

import { useIntl } from "../../i18n/Localization";
import { Brand, BrandSlider } from "../../models/Brand";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { profileAsyncAction } from "../../utils/performance";
import {
  BrandIndexPageSession,
  Session,
} from "../../utils/PerformanceRecordStore/sessions";

import { fetchBrandList, fetchBrandSlider } from "./api";

export function useFetchBrandSlider(): [
  ResourcesRequestState<BrandSlider | null>,
  () => Promise<BrandSlider | null>,
  () => Promise<BrandSlider | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    BrandSlider | null,
    () => Promise<BrandSlider | null>
  >({
    localCacheProvider: async () =>
      profileAsyncAction(
        BrandIndexPageSession(),
        "Load Brand Slider from Cache",
        () => fetchBrandSlider(client, locale, "cache-only")
      ),
    remoteResourcesProvider: async () =>
      profileAsyncAction(
        BrandIndexPageSession(),
        "Load Brand Slider from Network",
        () => fetchBrandSlider(client, locale, "network-only")
      ),
  });

  return [requestState, fetch, refresh];
}

export function useFetchBrandList(
  profileSession: Session
): [
  ResourcesRequestState<Brand[] | null>,
  () => Promise<Brand[] | null>,
  () => Promise<Brand[] | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Brand[] | null,
    () => Promise<Brand[] | null>
  >({
    localCacheProvider: () =>
      profileAsyncAction(profileSession, "Load Brand List from Cache", () =>
        fetchBrandList(client, locale, "cache-only")
      ),
    remoteResourcesProvider: () =>
      profileAsyncAction(profileSession, "Load Brand List from Network", () =>
        fetchBrandList(client, locale, "network-only")
      ),
  });

  return [requestState, fetch, refresh];
}
