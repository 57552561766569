import React, {
  RefObject,
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent } from "@ionic/react";

import { ProfileSessionContext } from "../../contexts/ProfileSessionContext";

import { useIonContentScrollEl } from "../../utils/ionContentScrollEl";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import CLInfiniteScroll from "../CLInfiniteScroll";
import { LoadingView } from "../LoadingView";

import ProductBlock from "../ProductBlock";
import WindowedList from "../WindowedList";

import { ProductOverview } from "../../models/ProductOverview";
import { ProductLabel } from "../../models/ProductLabel";
import { ModelKeys } from "../../models/product";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";

import { useEffectOnce } from "../../hook/useEffectOnce";

import styles from "./styles.module.scss";

interface Props {
  ionContentRef: RefObject<HTMLIonContentElement>;

  productOverviews: ProductOverview[];
  isLoadMoreLoading: boolean;

  productLabelsByProductId?: { [key in number]: ProductLabel[] };
  bundleByProductId?: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };

  header?: React.ReactNode;
  footer?: React.ReactNode;
  hrefForProduct: (productOverview: ProductOverview) => string;
  onProductOverviewClick?: (productOverview: ProductOverview) => void;
  onAddToCart: (productOverview: ProductOverview) => void;
  onClickLikeButton: (productOverview: ProductOverview) => void;

  onRefresh?: (e: CustomEvent<RefresherEventDetail>) => void;
  onEndReach?: () => void;
}

function getNumberOfItemsPerRow() {
  if (window.innerWidth < 640) {
    return 2;
  } else if (window.innerWidth < 1024) {
    return 3;
  }
  return 4;
}

const productBlockHeight = 335;

function useNumItemsPerRow() {
  const [numItemsPerRow, setNumItemsPerRow] = useState(
    getNumberOfItemsPerRow()
  );

  const numItemsPerRowRef = useRef(numItemsPerRow);

  useEffect(() => {
    const listener = () => {
      const currentNumItemsPerRow = getNumberOfItemsPerRow();
      if (currentNumItemsPerRow !== numItemsPerRowRef.current) {
        numItemsPerRowRef.current = currentNumItemsPerRow;
        setNumItemsPerRow(currentNumItemsPerRow);
      }
    };

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return numItemsPerRow;
}

const ProductList: React.FC<Props> = props => {
  const {
    ionContentRef,

    productOverviews,
    isLoadMoreLoading,

    productLabelsByProductId,
    bundleByProductId,

    header,
    footer,
    hrefForProduct,
    onProductOverviewClick,
    onAddToCart,
    onClickLikeButton,

    onRefresh,
    onEndReach,
  } = props;

  const scrollElement = useIonContentScrollEl(ionContentRef);

  const numItemsPerRow = useNumItemsPerRow();

  const itemCount = Math.ceil(productOverviews.length / numItemsPerRow);

  const handleLoadMore = useCallback(() => {
    if (onEndReach && !isLoadMoreLoading) {
      onEndReach();
    }
  }, [onEndReach, isLoadMoreLoading]);

  const renderProductsInRow = useCallback(
    (rowIndex: number) => {
      const cells = [];
      for (let i = 0; i < numItemsPerRow; i++) {
        const item = productOverviews[rowIndex * numItemsPerRow + i];
        if (item) {
          const bundle = bundleByProductId ? bundleByProductId[item.id] : null;
          const hasBundle = bundle
            ? bundle.items
              ? bundle.items.length > 0
              : false
            : false;
          cells.push(
            <div key={item.sku} className={styles.productContainer}>
              <ProductBlock
                showOnlyInView={true}
                hasBundle={hasBundle}
                product={item}
                height={productBlockHeight}
                productLabels={
                  productLabelsByProductId
                    ? productLabelsByProductId[item.id]
                    : undefined
                }
                hrefForProduct={hrefForProduct}
                onClick={onProductOverviewClick}
                onAddToCart={onAddToCart}
                className={styles.product}
                onClickLikeButton={onClickLikeButton}
              />
            </div>
          );
        }
      }

      return cells;
    },
    [
      productOverviews,
      productLabelsByProductId,
      bundleByProductId,
      hrefForProduct,
      onAddToCart,
      onClickLikeButton,
      onProductOverviewClick,
      numItemsPerRow,
    ]
  );

  return (
    <>
      {onRefresh && (
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent />
        </IonRefresher>
      )}
      <div className={styles.productList}>
        {header !== undefined && header}
        <WindowedList
          itemHeight={productBlockHeight + 23}
          itemCount={itemCount}
          margin={4}
          scrollElement={scrollElement}
          renderItem={renderProductsInRow}
          itemClassName={styles.container}
        />
        {footer !== undefined && footer}
        {onEndReach && (
          <CLInfiniteScroll
            scrollEl={scrollElement}
            onLoadMore={handleLoadMore}
            bottomOffset="-800px"
          />
        )}
        {isLoadMoreLoading && (
          <div className={styles.loadingContainer}>
            <LoadingView />
          </div>
        )}
      </div>
      {productOverviews.length > 0 ? (
        <FirstProductShown productOverview={productOverviews[0]} />
      ) : null}
    </>
  );
};

export default React.memo(ProductList);

interface FirstProductShownProps {
  productOverview: ProductOverview;
}

const FirstProductShown: React.FC<FirstProductShownProps> = () => {
  const profileSessionContext = useContext(ProfileSessionContext);

  useEffectOnce(() => {
    if (profileSessionContext) {
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Products Shown"
      );
    }
  });

  return null;
};
