import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { RefresherEventDetail } from "@ionic/core";

import {
  FilterAttribute,
  hasFilters,
  makeGraphQLFilterValue,
  ProductFilterInfo,
  RangeFilterAttribute,
} from "../../models/filter";
import { ProductOverview } from "../../models/ProductOverview";
import { ProfileSessionProvider } from "../../contexts/ProfileSessionContext";
import { parseQueryParameters } from "../../utils/Url";
import {
  getPathForProductDetailPage,
  getPathForShoppingCart,
  presentFilterModal,
  useCurrentTab,
} from "../../navigation/routes";
import { PresentationContext } from "../../our-navigation";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import {
  addPerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";
import { SingleBrandPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";

import CLContent from "../CLContent";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import NoInternetConnectionView from "../NoInternetConnectionView";
import ProductList from "../ProductList";
import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import { WishlistContext } from "../WishlistProvider";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { FullContentLoadingView } from "../LoadingView";
import { withProviders } from "../Provider";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { NavBar, NavBarBackButton } from "../NavBar";
import ShoppingCartButton from "../ShoppingCartButton";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";
import {
  TabBarSpacePlaceholder,
  TarBarHeightContext,
} from "../navigation/TabBar";
import FloatingButtons from "../FloatingButtons";
import FilterButton from "../FilterButton";
import AppliedFiltersList from "../AppliedFiltersList";
import RerenderLogger from "../Performance/RerenderLogger";

import styles from "./styles.module.scss";
import { IonItemDivider } from "@ionic/react";
import ProductListEmptyView from "./ProductListEmptyView";
import useViewModel from "./viewModel";
import HTMLBasedCMSBlocks from "../CMSBlocks/HTMLBasedCMSBlocks";

const SingleBrandPageImpl: React.FC = () => {
  const match = useRouteMatch<{ slug: string }>();
  const location = useLocation();
  const currentTab = useCurrentTab();

  const ionLifeCycleContext = useCLIonLifeCycleContext();

  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const isTabBarHidden = useScrollToHideTabBar(
    ionContentRef,
    ionLifeCycleContext
  );
  const tabBarHeight = useContext(TarBarHeightContext);

  const [search] = useState(location.search);

  const [{ slug }] = useState(match.params);

  const recordDidEnter = useCallback(
    () =>
      addPerformanceRecord(
        SingleBrandPageSession(slug),
        "Brand Product List Page did enter"
      ),
    [slug]
  );
  ionLifeCycleContext.onIonViewDidEnter(recordDidEnter);

  const { present } = useContext(PresentationContext);
  const onClickShoppingCart = useCallback(() => {
    present(getPathForShoppingCart());
  }, [present]);

  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  const queryParams = useMemo(() => {
    const res = parseQueryParameters(search);
    const cat = res.cat;
    if (cat != null) {
      res.category_id = cat;
    }
    return res;
  }, [search]);

  const { isOnline } = useContext(NetworkStatusContext);

  const {
    brand,
    topCmsContent,

    sortFields,

    productFilterInfo,
    applyProductFilterInfo,
    clearFilter,
    clearRangeFilter,
    clearPredefinedCPFilter,
    clearAllFilter,

    isFullPageLoading,

    productOverviews,
    productLabelsByProductIdMap,
    bundleByProductIdMap,
    fetchNextProductOverviews,
    isProductListLoadingMore,

    refresh: refreshViewModel,
  } = useViewModel(slug, queryParams);

  const viewDidEnter = useCallback(() => {
    refreshViewModel();
  }, [refreshViewModel]);

  ionLifeCycleContext.onIonViewDidEnter(viewDidEnter);

  const { toggleProductFromWishlist } = useContext(WishlistContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      toggleProductFromWishlist(productOverview.sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );

  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(currentTab, productOverview.sku);
    },
    [currentTab]
  );

  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = React.useCallback(
    (product: ProductOverview) => {
      presentAddToCartModal(product.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );

  const handleRetryOnNoInternetConnection = useCallback(() => {
    refreshViewModel();
  }, [refreshViewModel]);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      await refreshViewModel();
      e.detail.complete();
    },
    [refreshViewModel]
  );

  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );

  const scrollToTop = useCallback(() => {
    const ionContent = ionContentRef.current;
    if (ionContent) {
      ionContent.scrollToPoint(undefined, 0);
    }
  }, []);

  const onApplyFilters = useCallback(
    async (_productFilterInfo: ProductFilterInfo) => {
      scrollToTop();
      showLoadingModal();
      try {
        await applyProductFilterInfo(_productFilterInfo);
      } finally {
        hideLoadingModal();
      }
    },
    [showLoadingModal, hideLoadingModal, applyProductFilterInfo, scrollToTop]
  );

  const onClickClearAllButton = useCallback(async () => {
    scrollToTop();
    showLoadingModal();
    try {
      await clearAllFilter();
    } finally {
      hideLoadingModal();
    }
  }, [showLoadingModal, hideLoadingModal, clearAllFilter, scrollToTop]);

  const onClickClearFilterButton = useCallback(
    async (appliedFilter: FilterAttribute) => {
      scrollToTop();
      showLoadingModal();
      try {
        await clearFilter(appliedFilter);
      } finally {
        hideLoadingModal();
      }
    },
    [showLoadingModal, hideLoadingModal, clearFilter, scrollToTop]
  );

  const onClickClearRangeFilterButton = useCallback(
    async (appliedRangeFilter: RangeFilterAttribute) => {
      scrollToTop();
      showLoadingModal();
      try {
        await clearRangeFilter(appliedRangeFilter);
      } finally {
        hideLoadingModal();
      }
    },
    [showLoadingModal, hideLoadingModal, clearRangeFilter, scrollToTop]
  );

  const onClickClearPredefinedCPFilterButton = useCallback(async () => {
    scrollToTop();
    showLoadingModal();
    try {
      await clearPredefinedCPFilter();
    } finally {
      hideLoadingModal();
    }
  }, [
    showLoadingModal,
    hideLoadingModal,
    clearPredefinedCPFilter,
    scrollToTop,
  ]);

  const handleClickFilter = useCallback(() => {
    const brandIdFilter = brand
      ? makeGraphQLFilterValue(brand.id, "FilterEqualTypeInput")
      : null;
    presentFilterModal(present, {
      initialProductFilterInfo: productFilterInfo,
      onApplyFilters,
      sortRows: sortFields.sortFieldOptions,
      initialGraphQLFilter: brandIdFilter ? { brand: brandIdFilter } : {},
      excludedFilterAttributeCodes: ["brand"],
    });
  }, [present, brand, productFilterInfo, onApplyFilters, sortFields]);

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          SingleBrandPageSession(slug),
          "Brand Product List Page mounted",
          startTime
        );
        return;
      }
      addPerformanceRecord(
        SingleBrandPageSession(slug),
        RERENDER_EVENT,
        startTime,
        startTime + actualDuration
      );
    },
    [slug]
  );

  const handleListRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, _actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          SingleBrandPageSession(slug),
          "Product List mounted",
          startTime
        );
      }
    },
    [slug]
  );

  return (
    <RerenderLogger id="Single Brand Page" onRender={handleRender}>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={brand ? brand.label : null}
        headerRight={
          <ShoppingCartButton
            onClick={onClickShoppingCart}
            count={shoppingCartItemCount}
          />
        }
      />
      <CLContent ref={ionContentRef} className={styles.ionContent}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={productOverviews && productOverviews.length > 0}
          onRetry={handleRetryOnNoInternetConnection}
        >
          {hasFilters(productFilterInfo) ? (
            <IonItemDivider
              sticky={true}
              className={styles.appliedFiltersListContainer}
            >
              <AppliedFiltersList
                productFilterInfo={productFilterInfo}
                onClickClearAllButton={onClickClearAllButton}
                onClickClearButton={onClickClearFilterButton}
                onClickClearRangeButton={onClickClearRangeFilterButton}
                onClickClearPredefinedCPFilterButton={
                  onClickClearPredefinedCPFilterButton
                }
              />
            </IonItemDivider>
          ) : null}
          <RerenderLogger
            id="Brand Details Page List"
            onRender={handleListRender}
          >
            <ProductList
              productOverviews={productOverviews}
              productLabelsByProductId={productLabelsByProductIdMap}
              bundleByProductId={bundleByProductIdMap}
              header={
                isFullPageLoading ? (
                  <FullContentLoadingView />
                ) : (
                  <>
                    {topCmsContent ? (
                      <HTMLBasedCMSBlocks
                        waitingToFillHTML={topCmsContent.waitingToFillHTML}
                        resolvedMatchedCMSBlocks={
                          topCmsContent.resolvedMatchedCMSBlocks
                        }
                        currentTab={currentTab}
                        onAddToCart={openPurchaseProductModal}
                        hrefForProduct={hrefForProduct}
                        onClickLikeButton={onClickLikeButton}
                      />
                    ) : null}
                    {productOverviews.length === 0 ? (
                      <ProductListEmptyView messageID="product_list.no_product" />
                    ) : (
                      undefined
                    )}
                  </>
                )
              }
              isLoadMoreLoading={isProductListLoadingMore}
              hrefForProduct={hrefForProduct}
              onAddToCart={openPurchaseProductModal}
              onClickLikeButton={onClickLikeButton}
              ionContentRef={ionContentRef}
              onRefresh={handleRefresh}
              onEndReach={
                productOverviews.length === 0
                  ? undefined
                  : fetchNextProductOverviews
              }
            />
          </RerenderLogger>
          <TabBarSpacePlaceholder />
        </NoInternetConnectionView>
        <FloatingButtons
          offset={isTabBarHidden ? 0 : tabBarHeight}
          className={styles.floatingButtons}
        >
          {productOverviews.length > 0 && (
            <FilterButton onClick={handleClickFilter} />
          )}
        </FloatingButtons>
      </CLContent>
    </RerenderLogger>
  );
};

const SingleBrandPage: React.FC = () => {
  const match = useRouteMatch<{ slug: string }>();
  const { slug } = match.params;

  return (
    <ProfileSessionProvider profileSession={SingleBrandPageSession(slug)}>
      <SingleBrandPageImpl />
    </ProfileSessionProvider>
  );
};

export default withProviders(SingleBrandPage, LoadingModalProvider);
