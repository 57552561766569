import { ApolloClient, gql } from "@apollo/client";
import { getStoreViewCodeForLocale, Locale } from "../i18n/locale";
import { parseGraphQLError } from "./GraphQL";

export async function isCustomerSsoConfirmed(client: ApolloClient<unknown>) {
  try {
    const result = await client.query<{ isCustomerSsoConfirmed: boolean }>({
      query: gql`
        query IsCustomerSsoConfirmed {
          isCustomerSsoConfirmed
        }
      `,
      fetchPolicy: "network-only",
    });
    return result.data.isCustomerSsoConfirmed;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

export async function updateCustomerSsoConfirmation(
  client: ApolloClient<unknown>,
  locale: Locale,
  marketingMaterials: boolean
) {
  try {
    const result = await client.mutate<
      {
        updateCustomerSsoConfirmation: {
          success: boolean;
          rejectReason: string | null;
        };
      },
      {
        marketingMaterials: boolean;
      }
    >({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation UpdateCustomerSsoConfirmation($marketingMaterials: Boolean!) {
          updateCustomerSsoConfirmation(
            marketing_materials: $marketingMaterials
          ) {
            success
            rejectReason: reject_reason
          }
        }
      `,
      variables: {
        marketingMaterials,
      },
    });

    if (result.data) {
      if (result.data.updateCustomerSsoConfirmation.success) {
        return true;
      }
      if (result.data.updateCustomerSsoConfirmation.rejectReason) {
        throw new Error(result.data.updateCustomerSsoConfirmation.rejectReason);
      }
    }
    throw new Error("Unknown Error");
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}
