import React, { useMemo, useRef, useCallback } from "react";

import { LocalizedText } from "../../i18n/Localization";

import { ConfiguredProduct } from "../../models/ProductDetails";

import { useIsHTMLStringVisible } from "../../utils/IsHTMLStringVisible";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import isValidHTMLTag from "../../utils/IsValidHTMLTag";
import parse from "../../utils/HTMLReactParser";

import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";

import CLImage from "../CLImage";

import styles from "./styles.module.scss";

interface Props {
  configuredProduct: ConfiguredProduct;
  onClickMedia: () => void;
}

const ProductDetailDescription: React.FC<Props> = React.memo(
  function ProductDetailDescription(props) {
    const { configuredProduct, onClickMedia } = props;
    const contentContainerRef = useRef<HTMLParagraphElement>(null);

    const hasProductDescription = useIsHTMLStringVisible(
      configuredProduct.longDescription.html
    );

    const longDescription = useFillSrcUrlScheme(
      useExtractScriptsAndStyles(
        contentContainerRef,
        configuredProduct.longDescription.html
      )
    );

    const onClickMedia_ = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClickMedia();
      },
      [onClickMedia]
    );

    const longDescriptionReactNode = useMemo(() => {
      return parse(longDescription, {
        replace: domNode => {
          if (
            domNode.type === "tag" &&
            domNode.name &&
            !isValidHTMLTag(domNode.name)
          ) {
            return <></>;
          }
          if (domNode.type === "tag" && domNode.name === "img") {
            const { attribs } = domNode;
            return <CLImage htmlAttributes={attribs} onClick={onClickMedia_} />;
          }
          return undefined;
        },
      });
    }, [longDescription, onClickMedia_]);

    useRegisterPublishDeepLinkOnHTMLContent(contentContainerRef, [
      longDescription,
    ]);

    if (!hasProductDescription) {
      return null;
    }

    return (
      <div className={styles.productDetailDescriptionContainer}>
        <h2 className={styles.productDetailDescriptionTitle}>
          <LocalizedText messageID="page.product_detail.detail_description.title" />
        </h2>
        <div
          ref={contentContainerRef}
          className={styles.productDescriptionContent}
        >
          {longDescriptionReactNode}
        </div>
      </div>
    );
  }
);

export default ProductDetailDescription;
