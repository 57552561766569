import React, { useMemo, useContext, useCallback, useRef } from "react";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { RouteComponentProps } from "react-router-dom";
import formatDate from "date-fns/format";
import cn from "classnames";

import {
  getResources,
  isRequestError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import {
  SimpleViewStateInitial,
  SimpleViewStateError,
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";
import {
  CustomerSubscription,
  CustomerSubscriptionId,
} from "../../models/CustomerSubscription";
import {
  useGetCustomerSubscriptionFromMemory,
  useGetCustomerSubscription,
  useCancelSubscription,
} from "../../repository/CustomerSubscriptionRepository";

import { LocalizedText } from "../../i18n/Localization";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import { withProviders } from "../Provider";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { AddProductReviewModalContext } from "../AddProductReviewModalProvider";

import Item from "./Item";

import styles from "./styles.module.scss";

type Props = RouteComponentProps<{ id: CustomerSubscriptionId }>;

const SubscriptionDetailsPage: React.FC<Props> = props => {
  const { match } = props;
  const subscriptionId = match.params.id;

  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const { isOnline } = useContext(NetworkStatusContext);
  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { show: showAddProductReviewModal } = useContext(
    AddProductReviewModalContext
  );

  const getCustomerSubscriptionFromMemory = useGetCustomerSubscriptionFromMemory(
    subscriptionId
  );

  const customerSubscriptionFromMemory = useMemo(
    () => getCustomerSubscriptionFromMemory(),
    [getCustomerSubscriptionFromMemory]
  );

  const [requestState, fetch, refresh] = useGetCustomerSubscription(
    subscriptionId
  );

  const customerSubscription = getResources(requestState);

  const viewDidEnter = useCallback(() => {
    if (!customerSubscriptionFromMemory) {
      fetch().catch(() => {});
    }
  }, [customerSubscriptionFromMemory, fetch]);
  ionLifeCycleContext.onIonViewDidEnter(viewDidEnter);

  const customerSubscriptionResolved = useMemo(() => {
    return customerSubscriptionFromMemory || customerSubscription;
  }, [customerSubscriptionFromMemory, customerSubscription]);

  const viewState = useMemo<SimpleViewState<CustomerSubscription>>(() => {
    if (!customerSubscriptionResolved) {
      if (isRequestLoading(requestState)) {
        return SimpleViewStateLoading;
      }
      if (isRequestError(requestState)) {
        return SimpleViewStateError(requestState.error);
      }
      return SimpleViewStateInitial;
    }
    return SimpleViewStateDisplay(customerSubscriptionResolved);
  }, [requestState, customerSubscriptionResolved]);

  const retry = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh]
  );

  const [, _cancelSubscription] = useCancelSubscription();

  const cancelSubscription = useCallback(
    async (paymentMethod: string, _subscriptionId: CustomerSubscriptionId) => {
      showLoadingModal();
      try {
        await _cancelSubscription(paymentMethod, _subscriptionId);
        await refresh();
      } catch (e) {
        presentLocalizedAlert({
          messageId: e.message ? undefined : "error.unknown",
          message: e.message ? e.message : undefined,
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
      } finally {
        hideLoadingModal();
      }
    },
    [
      _cancelSubscription,
      refresh,
      showLoadingModal,
      hideLoadingModal,
      presentLocalizedAlert,
    ]
  );

  const handleReviewClick = useCallback(
    (productId: number) => {
      showAddProductReviewModal(productId);
    },
    [showAddProductReviewModal]
  );

  const handleCancelClick = useCallback(
    (_customerSubscription: CustomerSubscription) => {
      const { subscription } = _customerSubscription;
      if (!subscription) {
        return;
      }
      const { paymentMethod, subscriptionId: _subscriptionId } = subscription;
      if (!paymentMethod || !_subscriptionId) {
        return;
      }
      presentLocalizedAlert({
        messageId: "subscription_details.cancel.dialog.message",
        buttons: [
          {
            textMessageID: "subscription_details.cancel.dialog.button.confirm",
            handler: () => {
              cancelSubscription(paymentMethod, _subscriptionId);
            },
          },
          { textMessageID: "subscription_details.cancel.dialog.button.cancel" },
        ],
      });
    },
    [presentLocalizedAlert, cancelSubscription]
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="page.subscription_details.title" />
        }
      />
      <CLContent ref={contentRef} className={styles.content}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "display"}
          onRetry={retry}
        >
          {viewState.type === "initial" || viewState.type === "loading" ? (
            <FullContentLoadingView />
          ) : null}
          {viewState.type === "error" || viewState.type === "display" ? (
            <ReloadableView
              viewState={viewState}
              onRefresh={handleRefresh}
              onReviewClick={handleReviewClick}
              onCancelClick={handleCancelClick}
            />
          ) : null}
        </NoInternetConnectionView>
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

export default withProviders(SubscriptionDetailsPage, LoadingModalProvider);

interface ReloadableViewProps {
  viewState: Extract<
    SimpleViewState<CustomerSubscription>,
    { type: "error" } | { type: "display" }
  >;
  onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
  onReviewClick: (productId: number) => void;
  onCancelClick: (customerSubscription: CustomerSubscription) => void;
}

const ReloadableView: React.FC<ReloadableViewProps> = props => {
  const { viewState, onRefresh, onReviewClick, onCancelClick } = props;
  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {viewState.type === "error" ? <ErrorView /> : null}
      {viewState.type === "display" ? (
        <DisplayView
          customerSubscription={viewState.data}
          onReviewClick={onReviewClick}
          onCancelClick={onCancelClick}
        />
      ) : null}
    </>
  );
};

const ErrorView: React.FC = () => {
  return (
    <div className={styles.errorMessage}>
      <LocalizedText messageID="error.unknown" />
    </div>
  );
};

interface DisplayViewProps {
  customerSubscription: CustomerSubscription;
  onReviewClick: (productId: number) => void;
  onCancelClick: (customerSubscription: CustomerSubscription) => void;
}

const DisplayView: React.FC<DisplayViewProps> = props => {
  const { customerSubscription, onReviewClick, onCancelClick } = props;
  const { subscription, startDate } = customerSubscription;
  return (
    <>
      <div className={styles.generalInfoContainer}>
        <div className={styles.generalInfoRow}>
          <div className={styles.generalInfoTitle}>
            <LocalizedText messageID="subscription_details.subscription_id" />
          </div>
          <div
            className={cn(
              styles.generalInfoValue,
              styles.generalInfoSubscriptionId
            )}
          >
            {(subscription && subscription.subscriptionId) || "-"}
          </div>
        </div>
        <div className={styles.generalInfoRow}>
          <div className={styles.generalInfoValue}>
            {(startDate && formatDate(startDate, "DD-MM-YYYY")) || "-"}
          </div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.sectionTitle}>
          <LocalizedText messageID="subscription_details.subscribed_item" />
        </div>
        <Item
          customerSubscription={customerSubscription}
          onReviewClick={onReviewClick}
          onCancelClick={onCancelClick}
        />
      </div>
    </>
  );
};
