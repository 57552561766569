import React, { useCallback, useMemo, useState, useEffect } from "react";

import { useKeepUpdatingRef } from "../../hook/utils";

interface GoogleAdsContext {
  enableAds: () => void;
  adsEnabled: boolean;
}

export const GoogleAdsContext = React.createContext<GoogleAdsContext>(
  null as any
);

export const GoogleAdsProvider: React.FC = props => {
  const [adsInitializing, setAdsIntializing] = useState(false);
  const [adsInitializationIsError, setAdsInitializationIsError] = useState(
    false
  );
  const [adsEnabled, setAdsEnabled] = useState(false);

  const adsInitializingRef = useKeepUpdatingRef(adsInitializing);
  const adsInitializationIsErrorRef = useKeepUpdatingRef(
    adsInitializationIsError
  );
  const adsEnabledRef = useKeepUpdatingRef(adsEnabled);

  const enableAds = useCallback(() => {
    const _adsInitializing = adsInitializingRef.current;
    const _adsInitializationIsError = adsInitializationIsErrorRef.current;
    const _adsEnabled = adsEnabledRef.current;

    if (_adsInitializing || _adsEnabled || _adsInitializationIsError) {
      return;
    }

    setAdsIntializing(true);
  }, [adsInitializingRef, adsInitializationIsErrorRef, adsEnabledRef]);

  useEffect(() => {
    if (adsInitializing) {
      const script = document.createElement("script");
      script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script.onload = () => {
        setAdsIntializing(false);
        setAdsEnabled(true);
      };
      script.onerror = () => {
        setAdsIntializing(false);
        setAdsInitializationIsError(true);
      };
      document.head.appendChild(script);
    }
  }, [adsInitializing]);

  const value = useMemo(
    () => ({
      enableAds,
      adsEnabled,
    }),
    [enableAds, adsEnabled]
  );

  return (
    <GoogleAdsContext.Provider value={value}>
      {props.children}
    </GoogleAdsContext.Provider>
  );
};
