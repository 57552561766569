import Config from "../../Config";

import { InitializeHomePageSession, Session } from "./sessions";

export const RERENDER_EVENT = "re-render";

export interface PerformanceRecord {
  session: Session;
  event: string;
  startTime: number;
  endTime?: number;
}

const performanceRecords: PerformanceRecord[] = Config.INFO_ENABLE_PERFORMANCE_LOGGING
  ? [
      {
        session: InitializeHomePageSession(),
        event: "Launch App",
        startTime: performance.now(),
      },
    ]
  : [];

export default performanceRecords;

export function addPerformanceRecord(
  session: Session,
  event: string,
  _startTime?: number,
  endTime?: number
) {
  if (!Config.INFO_ENABLE_PERFORMANCE_LOGGING) {
    return;
  }
  const startTime = _startTime == null ? performance.now() : _startTime;
  performanceRecords.push({
    session,
    event,
    startTime,
    endTime,
  });
}
