import React, { useCallback, useContext } from "react";
import { Setting, SettingDisplayInfo } from "./model";
import { LocalizedText } from "../../i18n/Localization";
import { AppConfig } from "../../models/AppConfig";
import BingoListContext from "../../contexts/BingoListContext";

import styles from "./styles.module.scss";

import ordersIcon from "../../resources/setting-orders-icon.svg";
import bingoListIcon from "../../resources/setting-bingo-list-icon.svg";
import subscriptionsIcon from "../../resources/setting-subscriptions-icon.svg";
import reviewsIcon from "../../resources/setting-reviews-icon.svg";
import redemptionIcon from "../../resources/setting-redemption-icon.svg";
import rewardOrderIcon from "../../resources/setting-reward-order-icon.svg";
import deliveryIcon from "../../resources/setting-delivery-icon.svg";
import myCardsIcon from "../../resources/setting-my-cards-icon.svg";
import languageIcon from "../../resources/setting-language-icon.svg";
import notificationIcon from "../../resources/setting-notification-icon.svg";
import resetPasswordIcon from "../../resources/setting-reset-password-icon.svg";
import aboutUsIcon from "../../resources/setting-about-us-icon.svg";
import logoutIcon from "../../resources/setting-logout-icon.svg";
import helpCenterIcon from "../../resources/setting-help-center-icon.svg";

export function useSettingDisplayInfo(appConfig: AppConfig | null) {
  const bingoListContext = useContext(BingoListContext);
  const getSettingDisplayInfo = useCallback(
    (setting: Setting): SettingDisplayInfo[] => {
      switch (setting) {
        case Setting.orders:
          return [
            {
              title: <LocalizedText messageID="page.settings.orders" />,
              iconPath: ordersIcon,
              showRightArrow: true,
            },
          ];
        case Setting.bingoList:
          return [
            {
              title: <LocalizedText messageID="page.settings.bingo_list" />,
              iconPath: bingoListIcon,
              showRightArrow: true,
              count: bingoListContext.bingoListCount || undefined,
            },
          ];
        case Setting.subscriptions:
          return [
            {
              title: <LocalizedText messageID="page.settings.subscriptions" />,
              iconPath: subscriptionsIcon,
              showRightArrow: true,
            },
          ];
        case Setting.productReviews:
          return [
            {
              title: <LocalizedText messageID="page.settings.reviews" />,
              iconPath: reviewsIcon,
              showRightArrow: true,
            },
          ];
        case Setting.redemption:
          return [
            {
              title: <LocalizedText messageID="page.settings.redemption" />,
              iconPath: redemptionIcon,
              showRightArrow: true,
            },
          ];
        case Setting.externalRedemptionOrder:
          return [
            {
              title: (
                <LocalizedText
                  messageID="page.settings.redemption_order"
                  messageArgs={{
                    redemptionDateStyle: styles.redemptionDateStyle,
                  }}
                />
              ),
              iconPath: rewardOrderIcon,
              showRightArrow: true,
            },
          ];
        case Setting.addressBook:
          return [
            {
              title: <LocalizedText messageID="page.settings.address_book" />,
              iconPath: deliveryIcon,
              showRightArrow: true,
            },
          ];
        case Setting.myCards:
          return [
            {
              title: <LocalizedText messageID="page.settings.my_cards" />,
              iconPath: myCardsIcon,
              showRightArrow: true,
            },
          ];
        case Setting.displayLanguage:
          return [
            {
              title: (
                <LocalizedText messageID="page.settings.display_language" />
              ),
              iconPath: languageIcon,
              showRightArrow: true,
            },
          ];
        case Setting.notifications:
          return [
            {
              title: <LocalizedText messageID="page.settings.notifications" />,
              iconPath: notificationIcon,
              showRightArrow: true,
            },
          ];
        case Setting.changePassword:
          return [
            {
              title: (
                <LocalizedText messageID="page.settings.change_password" />
              ),
              iconPath: resetPasswordIcon,
              showRightArrow: true,
            },
          ];
        case Setting.footerCMSLinks:
          if (appConfig == null) {
            return [];
          }
          return appConfig.footerCMSLinks.map(link => ({
            title: link.text,
            iconPath: link.iconPath || aboutUsIcon,

            showRightArrow: true,
          }));
        case Setting.logout:
          return [
            {
              title: <LocalizedText messageID="page.settings.logout" />,
              iconPath: logoutIcon,
              showRightArrow: false,
            },
          ];
        case Setting.performanceRecords:
          return [
            {
              title: <span>Performance Records</span>,
              iconPath: require("../../resources/setting-about-us-icon.svg"),
              showRightArrow: true,
            },
          ];
        default:
          return [
            {
              title: <LocalizedText messageID="page.settings.help_center" />,
              iconPath: helpCenterIcon,
              showRightArrow: true,
            },
          ];
      }
    },
    [appConfig, bingoListContext.bingoListCount]
  );

  return {
    getSettingDisplayInfo,
  };
}
