import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import classnames from "classnames";

import {
  Brand,
  groupBrands,
  groupByFirstLetterOrOthers,
  sortBrandKeys,
  OTHER_BRANDS,
} from "../../models/Brand";

import {
  ProfileSessionContext,
  withProfileSession,
} from "../../contexts/ProfileSessionContext";

import { LocalizedText, useIntl } from "../../i18n/Localization";
import { OurNavContext, PresentationContext } from "../../our-navigation";
import {
  activeTabAsRootTab,
  getPathForBrandsSearchPage,
  getPathForShoppingCart,
  RootTab,
} from "../../navigation/routes";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import {
  appEventEmitter,
  AppEventLinkToBrand,
} from "../../utils/SimpleEventEmitter";
import {
  addPerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";
import {
  BrandIndexPageSession,
  BrandSearchPageSession,
} from "../../utils/PerformanceRecordStore/sessions";
import { useEffectOnce } from "../../hook/useEffectOnce";

import { NavBar, NavBarBackButton } from "../NavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import ShoppingCartButton from "../ShoppingCartButton";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import { FullContentErrorView } from "../ErrorView";

import Slider from "./Slider";
import List from "./List";
import RerenderLogger from "../Performance/RerenderLogger";

import styles from "./styles.module.scss";
import { DisplayOnlySearchInput } from "./SearchInput";
import useViewModel from "./viewModel";

const BrandIndexPage: React.FC = () => {
  const {
    brands,
    brandSlider,
    refresh,
    isFullPageLoading,
    error,
  } = useViewModel();

  const contentRef = useRef<HTMLIonContentElement>(null);

  const ionLifeCycleContext = useCLIonLifeCycleContext();

  const { navigate, getActiveTab } = useContext(OurNavContext);

  const didEnterOnceRef = useRef(false);
  const initialRequests = useCallback(() => {
    if (didEnterOnceRef.current) {
      return;
    }
    didEnterOnceRef.current = true;
    refresh();
  }, [refresh]);
  ionLifeCycleContext.onIonViewDidEnter(initialRequests);

  const recordDidEnter = useCallback(
    () =>
      addPerformanceRecord(
        BrandIndexPageSession(),
        "Brand Index Page did enter"
      ),
    []
  );
  ionLifeCycleContext.onIonViewDidEnter(recordDidEnter);

  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      await refresh();
      e.detail.complete();
    },
    [refresh]
  );

  const { present } = useContext(PresentationContext);
  const onClickShoppingCartButton = useCallback(() => {
    present(getPathForShoppingCart());
  }, [present]);

  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  const activeRootTab = useMemo(() => {
    const activeTab = getActiveTab();
    return activeTab
      ? activeTabAsRootTab(activeTab) || RootTab.home
      : RootTab.home;
  }, [getActiveTab]);

  const handleSearchClick = useCallback(() => {
    addPerformanceRecord(BrandSearchPageSession(), "Search clicked");
    navigate(getPathForBrandsSearchPage(activeRootTab));
  }, [activeRootTab, navigate]);

  const onBrandClick = useCallback(
    <T extends { url: string | null }>(item: T) => {
      if (item.url) {
        appEventEmitter.publish(AppEventLinkToBrand(item.url));
      }
    },
    []
  );

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          BrandIndexPageSession(),
          "Brand Index Page mounted",
          startTime
        );
        return;
      }
      addPerformanceRecord(
        BrandIndexPageSession(),
        RERENDER_EVENT,
        startTime,
        startTime + actualDuration
      );
    },
    []
  );

  return (
    <RerenderLogger id="Brand Index Page" onRender={handleRender}>
      <NavBar
        headerLeft={
          <>
            <NavBarBackButton />
          </>
        }
        headerTitle={<LocalizedText messageID="page.brand_index.title" />}
        headerRight={
          <>
            <ShoppingCartButton
              onClick={onClickShoppingCartButton}
              count={shoppingCartItemCount}
            />
          </>
        }
      />
      <CLContent ref={contentRef}>
        {isFullPageLoading ? (
          <>
            <FullContentLoadingView />
          </>
        ) : brands != null || brandSlider != null ? (
          <>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            {brandSlider ? (
              <>
                <Slider
                  brandSlider={brandSlider}
                  onSliderItemClick={onBrandClick}
                />
              </>
            ) : null}
            {brands ? (
              <>
                <BrandList
                  brands={brands}
                  selectedKey={selectedKey}
                  setSelectedKey={setSelectedKey}
                  onSearchClick={handleSearchClick}
                  onBrandClick={onBrandClick}
                />
              </>
            ) : null}
          </>
        ) : error ? (
          <>
            <FullContentErrorView
              errorMessage={error.message}
              onClickRetry={refresh}
            />
          </>
        ) : null}
        <TabBarSpacePlaceholder />
      </CLContent>
    </RerenderLogger>
  );
};

export default withProfileSession(BrandIndexPageSession, BrandIndexPage);

interface BrandListProps {
  brands: Brand[];
  selectedKey: string | null;
  setSelectedKey: (key: string | null) => void;
  onSearchClick: () => void;
  onBrandClick: (brand: Brand) => void;
}

const BrandList: React.FC<BrandListProps> = props => {
  const {
    brands,
    selectedKey,
    setSelectedKey,
    onSearchClick,
    onBrandClick,
  } = props;
  const { translate } = useIntl();

  const [groupedBrands, otherBrands] = useMemo(
    () =>
      groupBrands(brands, (brand: Brand) => groupByFirstLetterOrOthers(brand)),
    [brands]
  );

  const keys = useMemo(() => Object.keys(groupedBrands).sort(sortBrandKeys), [
    groupedBrands,
  ]);

  const handleKeyAllSelected = useCallback(() => {
    setSelectedKey(null);
  }, [setSelectedKey]);

  const handleKeySelected = useCallback(
    (key: string) => {
      setSelectedKey(key);
    },
    [setSelectedKey]
  );

  const handleOtherSelected = useCallback(() => {
    setSelectedKey(OTHER_BRANDS);
  }, [setSelectedKey]);

  const displayKeys = useMemo(() => {
    if (selectedKey != null) {
      return [selectedKey];
    }
    if (selectedKey === OTHER_BRANDS) {
      return [OTHER_BRANDS];
    }
    return otherBrands.length > 0 ? [...keys, OTHER_BRANDS] : keys;
  }, [keys, selectedKey, otherBrands]);

  const profileSessionContext = useContext(ProfileSessionContext);

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, _actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        if (profileSessionContext)
          addPerformanceRecord(
            profileSessionContext.rootProfileSession,
            "Brand List mounted",
            startTime
          );
      }
    },
    [profileSessionContext]
  );

  useEffectOnce(() => {
    if (profileSessionContext)
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Contents (BrandList) Shown"
      );
  });

  return (
    <RerenderLogger id="Brand Index Page List" onRender={handleRender}>
      <ul className={styles.brandKeys}>
        <li className={styles.brandKeyItem}>
          <BrandKey
            label={translate("page.brand_index.filter.all")}
            onClick={handleKeyAllSelected}
            selected={selectedKey == null}
          />
        </li>
        {keys.map(key => {
          return (
            <li className={styles.brandKeyItem} key={key}>
              <BrandKey
                label={key}
                onClick={handleKeySelected}
                selected={selectedKey === key}
              />
            </li>
          );
        })}
        {otherBrands.length > 0 ? (
          <li className={styles.brandKeyItem}>
            <BrandKey
              label={translate("page.brand_index.filter.others")}
              onClick={handleOtherSelected}
              selected={selectedKey === OTHER_BRANDS}
            />
          </li>
        ) : null}
      </ul>
      <div className={styles.searchInputContainer}>
        <DisplayOnlySearchInput onClick={onSearchClick} />
      </div>
      <List
        keys={displayKeys}
        groupedBrands={groupedBrands}
        otherBrands={otherBrands}
        onBrandClick={onBrandClick}
      />
    </RerenderLogger>
  );
};

interface BrandKeyProps {
  label: string;
  onClick: (key: string) => void;
  selected: boolean;
}

const BrandKey: React.FC<BrandKeyProps> = props => {
  const { label, onClick, selected } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(label);
    },
    [onClick, label]
  );

  return (
    <button
      className={classnames(styles.brandKey, {
        [styles["brandKey--selected"]]: selected,
      })}
      onClick={handleClick}
    >
      {label}
    </button>
  );
};
