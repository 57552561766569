import { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { getRecentlySearches, storeRecentlySearches } from "../storage";

import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { SearchTerm, SearchAutoSuggestion } from "../models/Search";

import { useFetchResources } from "./Hooks";

import { fetchSearchSuggestion, fetchHotSearches } from "../api/GraphQL";
import { useIntl } from "../i18n/Localization";

import { isEmpty } from "../utils/String";

import Config from "../Config";

export function useAddRecentSearchTerm(): (
  searchTerm: SearchTerm
) => Promise<void> {
  return useCallback(async (searchTerm: SearchTerm) => {
    const currentSearches = await getRecentlySearches();
    const recentSearches = [
      searchTerm,
      ...currentSearches.filter(s => s !== searchTerm),
    ].slice(0, Config.MAX_RECENTLY_SEARCHES_TO_STORE);
    await storeRecentlySearches(recentSearches);
  }, []);
}

export function useRecentlySearches(): [SearchTerm[] | null, () => void] {
  const [recentlySearches, setRecentlySearches] = useState<SearchTerm[] | null>(
    null
  );

  const load = useCallback(() => {
    getRecentlySearches().then(setRecentlySearches);
  }, []);

  return useMemo(() => [recentlySearches, load], [recentlySearches, load]);
}

export function useHotSearches(): ResourcesRequestState<
  SearchAutoSuggestion[] | null
> {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () => Promise.resolve(null),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () => fetchHotSearches(client, locale),
      didFetchFromRemote: () => {},
    },
    [locale]
  );
  useEffect(() => {
    startRequesting();
  }, [startRequesting]);
  return requestState;
}

export function useSearchSuggestion(
  searchTerm: SearchTerm
): ResourcesRequestState<{
  searchTerm: SearchTerm;
  popularSearches: SearchAutoSuggestion[];
  brand: SearchAutoSuggestion[];
  products: { items: { sku: string; name: string }[]; totalCount: number };
  filteredListing: { items: SearchAutoSuggestion[]; searchTerm: string };
} | null> {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { requestState, startRequesting, stopRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () => Promise.resolve(null),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () => {
        if (isEmpty(searchTerm)) {
          return Promise.resolve(null);
        }
        return fetchSearchSuggestion(client, searchTerm, locale).then(
          result => ({
            searchTerm,
            popularSearches: result.popularSearches,
            brand: result.brand,
            products: {
              items: result.products.items,
              totalCount: result.products.totalCount,
            },
            filteredListing: {
              items: result.filteredListing,
              searchTerm,
            },
          })
        );
      },
      didFetchFromRemote: () => {},
    },
    [searchTerm]
  );

  const isSearchTermEmpty = useMemo(() => isEmpty(searchTerm), [searchTerm]);

  useEffect(() => {
    if (isSearchTermEmpty) {
      stopRequesting();
    } else {
      startRequesting();
    }
  }, [isSearchTermEmpty, startRequesting, stopRequesting]);

  return requestState;
}
