import {
  ProductType,
  ProductImage,
  CLClubPoint,
  PriceRange,
  Product360Image,
  ProductStockStatus,
  ThirdPartyProductDisplayType,
  ProductConfigurableAttributeOption,
  Product360ImageGraphQLAttributes,
  ProductImageGraphQLAttributes,
  keyGraphQLAttributes,
  ModelKeys,
  ProductThirdPartyProductShowPriceType,
  ProductIsPreOrder,
  WithVariantConfigurationAttributes,
} from "./product";
import { MediaContent, MediaContentGraphQLAttributes } from "./Media";
import { MerchantPreview, MerchantPreviewGraphQLAttributes } from "./Merchant";
import { MoneyGraphQLAttributes } from "./Price";

export type ProductOverview = WithVariantConfigurationAttributes<
  ConfiguredProductOverview,
  {
    displayType?: ThirdPartyProductDisplayType | null;
    buttonUrl?: string | null;
    infoMessage?: string | null;
  } & ProductThirdPartyProductShowPriceType
>;

export interface ProductOverviewVariant {
  product: ConfiguredProductOverview;
  attributes: ProductConfigurableAttributeOption[];
}

export type ConfiguredProductOverview = ModelKeys & {
  name: string;
  type: ProductType;
  thumbnail: ProductImage | null;
  image: ProductImage | null;
  clubPoint: number;
  minClubPoint: number;
  extraClubpoints: number | null;
  clClubPoint?: CLClubPoint | null;
  priceRange: PriceRange | null;
  specialFromDateStr: string | null;
  specialToDateStr: string | null;
  newFromDateStr: string | null;
  newToDateStr: string | null;

  mediaContents: MediaContent[];
  magic360Images: Product360Image[];
  merchant: [MerchantPreview | null];

  rating: number;

  stockStatus: ProductStockStatus;

  manufacturerSuggestedRetailPrice?: string | null;

  enableClubProtection?: boolean | null;

  isFreeShipping: boolean | null;
} & ProductIsPreOrder;

export const ProductOverviewVariantGrapQLAttributes = `
  product {
    ${keyGraphQLAttributes}
    name
    type: type_id
    thumbnail {
      ${ProductImageGraphQLAttributes}
    }
    image {
      ${ProductImageGraphQLAttributes}
    }
    clubPoint: clubpoints
    minClubPoint: min_clubpoints
    extraClubpoints: extra_clubpoints
    clClubPoint: cl_clubpoints
    priceRange: price_range {
      minimumPrice: minimum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
      maximumPrice: maximum_price {
        regularPrice: regular_price {
          ${MoneyGraphQLAttributes}
        }
        finalPrice: final_price {
          ${MoneyGraphQLAttributes}
        }
      }
    }
    specialFromDateStr: special_from_date
    specialToDateStr: special_to_date
    newFromDateStr: new_from_date
    newToDateStr: new_to_date
    mediaContents: media_gallery_entries {
      ${MediaContentGraphQLAttributes}
    }
    magic360Images: magic360_image {
      ${Product360ImageGraphQLAttributes}
    }
    merchant {
      ${MerchantPreviewGraphQLAttributes}
    }
    rating
    stockStatus: stock_status
    manufacturerSuggestedRetailPrice: pro_msrp

    enableClubProtection: enable_club_protection

    isFreeShipping: is_free_shipping
  }
  attributes {
    label
    value: value_index
    code
  }
`;

export const ProductOverviewGraphQLAttributes = `
  ${keyGraphQLAttributes}
  name
  type: type_id
  thumbnail {
    ${ProductImageGraphQLAttributes}
  }
  image {
    ${ProductImageGraphQLAttributes}
  }
  clubPoint: clubpoints
  minClubPoint: min_clubpoints
  extraClubpoints: extra_clubpoints
  clClubPoint: cl_clubpoints
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
    maximumPrice: maximum_price {
      regularPrice: regular_price {
        ${MoneyGraphQLAttributes}
      }
      finalPrice: final_price {
        ${MoneyGraphQLAttributes}
      }
    }
  }
  specialFromDateStr: special_from_date
  specialToDateStr: special_to_date
  newFromDateStr: new_from_date
  newToDateStr: new_to_date
  mediaContents: media_gallery_entries {
    ${MediaContentGraphQLAttributes}
  }
  magic360Images: magic360_image {
    ${Product360ImageGraphQLAttributes}
  }
  merchant {
    ${MerchantPreviewGraphQLAttributes}
  }
  rating
  stockStatus: stock_status
  manufacturerSuggestedRetailPrice: pro_msrp

  isFreeShipping: is_free_shipping

  ... on ConfigurableProduct {
    variants {
      ${ProductOverviewVariantGrapQLAttributes}
    }
  }
  ... on ThirdPartyProduct {
    displayType: display_type
    buttonUrl: button_url
    infoMessage: info_message
  }
  enableClubProtection: enable_club_protection
`;
