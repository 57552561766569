import React, { useMemo } from "react";

import { CustomerProductReview } from "../../models/ProductReview";
import { LocalizedDateTime } from "../../i18n/Localization";
import { Locale } from "../../i18n/locale";

import ProductRatingScore from "../ProductDetailPage/ProductRatingScore.lazy";

import styles from "./CustomerProductReviewCell.module.scss";

interface Props {
  productReview: CustomerProductReview;
}

const dateFormat = {
  [Locale.en]: "Published on D MMM YYYY",
  [Locale.zhHant]: "發表於D/M/YYYY",
};

const ProductReviewCell: React.FC<Props> = React.memo(props => {
  const { productReview } = props;
  const { createdAt, detail, nickname, rating } = productReview;

  const theRating = useMemo(() => {
    if (rating && rating.length > 0) {
      return rating[0];
    }
    return null;
  }, [rating]);

  return (
    <div className={styles.container}>
      <div className={styles.reviewHeader}>
        {nickname ? <p className={styles.reviewerName}>{nickname}</p> : null}
        {createdAt ? (
          <div className={styles.postDate}>
            <LocalizedDateTime datetime={createdAt} format={dateFormat} />
          </div>
        ) : null}
      </div>
      <div className={styles.rating}>
        <ProductRatingScore
          size="small"
          overallReviewScore={theRating ? theRating.percent : 0}
        />
      </div>
      {detail ? <p className={styles.detail}>{detail}</p> : null}
    </div>
  );
});

export default ProductReviewCell;
