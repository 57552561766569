import moment from "moment-timezone";

import {
  Order,
  OrderItem,
  SimpleOrderItem,
  ConfigurableOrderItem,
  VirtualOrderItem,
  ShipmentStatus,
  RemoteShipmentStage,
  ItemOption,
  getOrderItemSku,
  OrderEstimatedDeliveryDate,
} from "../../models/Order";
import { LuckyDraw, isLuckyDrawSku } from "../../models/LuckyDraw";
import { Override } from "../../utils/type";
import { filterNullOrUndefined } from "../../utils/array";

import Config from "../../Config";

export type PartialOrderItem = OrderItem;
export type PartialOrder = Override<Order, { items: PartialOrderItem[] }>;

export interface PartialOrderItemSelectedOptions {
  sku: PartialOrderItem["sku"];
  simpleSku: PartialOrderItem["simpleSku"];
  selectedOptions: PartialOrderItem["selectedOptions"];
}

export interface PartialOrderShipmentStatusListProductIds {
  shipmentStatusList: {
    productIds?: number[] | null;
  }[];
}

export interface PartialOrderEstimatedDeliveryDate {
  estimatedDeliveryDate?: OrderEstimatedDeliveryDate[] | null;
}

export type RemotePartialOrder = Override<
  PartialOrder,
  {
    shipmentStatusList:
      | (Override<
          ShipmentStatus,
          { stages?: (RemoteShipmentStage | null)[] | null }
        > | null)[]
      | null;
    items: RemotePartialOrderItem[];
  }
>;

interface RemoteOrderItemAttrs {
  protectStartDate?: string | null;
  protectEndDate?: string | null;
  selectedOptions?: (ItemOption | null)[];
}

type RemoteSimpleOrderItem = Override<SimpleOrderItem, RemoteOrderItemAttrs>;

type RemoteConfigurableOrderItem = Override<
  ConfigurableOrderItem,
  RemoteOrderItemAttrs
>;

type RemoteVirtualOrderItem = Override<VirtualOrderItem, RemoteOrderItemAttrs>;

export type RemotePartialOrderItem =
  | RemoteSimpleOrderItem
  | RemoteConfigurableOrderItem
  | RemoteVirtualOrderItem;

export function transformRemotePartialOrderToPartialOrder(
  order: RemotePartialOrder
): PartialOrder {
  return {
    ...order,
    shipmentStatusList: order.shipmentStatusList
      ? order.shipmentStatusList.map(shipmentStatus => {
          return shipmentStatus
            ? {
                ...shipmentStatus,
                stages: shipmentStatus.stages
                  ? shipmentStatus.stages.map(stage => {
                      return stage
                        ? {
                            ...stage,
                            timestamp: stage.timestamp
                              ? moment(stage.timestamp * 1000).toDate()
                              : null,
                          }
                        : null;
                    })
                  : null,
              }
            : null;
        })
      : null,
    items: order.items.map(item => {
      return {
        ...item,
        // 2121-09-23 17:09:00
        protectEndDate: item.protectEndDate
          ? moment(
              item.protectEndDate,
              "YYYY-MM-DD hh:mm:ss",
              Config.TIMEZONE
            ).toDate()
          : null,
        protectStartDate: item.protectStartDate
          ? moment(
              item.protectStartDate,
              "YYYY-MM-DD hh:mm:ss",
              Config.TIMEZONE
            ).toDate()
          : null,
        selectedOptions: item.selectedOptions
          ? filterNullOrUndefined(item.selectedOptions)
          : [],
      };
    }),
  };
}

export function isLuckyDrawItem(
  item: PartialOrderItem
): item is LuckyDraw<PartialOrderItem> {
  return isLuckyDrawSku(getOrderItemSku(item));
}
