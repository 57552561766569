import React from "react";
import cn from "classnames";

import { IndexMap } from "../../utils/type";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./styles.module.scss";

export type Step = "fillingInfo" | "confirmation" | "payment" | "ordered";

const stepMessageIDMap: IndexMap<Step, MessageID> = {
  fillingInfo: "checkout.header.step.filling_info",
  confirmation: "checkout.header.step.confirmation",
  payment: "checkout.header.step.payment",
  ordered: "checkout.header.step.ordered",
};

const steps: Step[] = ["fillingInfo", "confirmation", "payment", "ordered"];

function isStepPast(currentStep: Step, step: Step) {
  const currentIndex = steps.indexOf(currentStep);
  const index = steps.indexOf(step);
  return index < currentIndex;
}

interface StepPointProps {
  type: "past" | "current" | "future";
}

const StepPoint: React.FC<StepPointProps> = props => {
  const { type, children } = props;

  return (
    <div className={styles.stepPoint}>
      <div className={cn(styles.thePoint, styles[type])} />
      <div className={styles.text}>{children}</div>
    </div>
  );
};

interface Props {
  currentStep: Step;
}

const Header: React.FC<Props> = props => {
  const { currentStep } = props;

  return (
    <div className={styles.container}>
      {steps.map(step => {
        const messageID = stepMessageIDMap[step];
        return (
          <StepPoint
            key={step}
            type={
              isStepPast(currentStep, step)
                ? "past"
                : currentStep === step
                ? "current"
                : "future"
            }
          >
            {messageID && <LocalizedText messageID={messageID} />}
          </StepPoint>
        );
      })}
    </div>
  );
};

export default Header;
