import { useRef, useState, useCallback, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { Plugins } from "@capacitor/core";

import {
  OS,
  Token,
  Isdn,
  Message,
  ReadState,
} from "../../models/OPNSPushNotification";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useKeepUpdatingRef } from "../../hook/utils";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { PaginationInfo } from "../../repository/State";
import { useIntl } from "../../i18n/Localization";
import { InboxMessage } from "../../CLPlugins/InboxMessage/models";

import "../../CLPlugins/InboxMessage";

import { getMessages, messagesMarkRead } from "./api";

const { InboxMessagePlugin } = Plugins;

export function useFetchPushNotificationMessages(
  os: OS,
  token: Token,
  isdn: Isdn
): {
  requestState: ResourcesRequestState<Message[]>;
  paginationInfo: PaginationInfo<Message> | null;
  fetchNext: () => void;
  refresh: () => void;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const isFetchingRef = useRef(false);
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    Message
  > | null>(null);
  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);
  const [forceFetchKey, setForceFetchKey] = useState(0);

  const [requestState, { call: fetch }] = useFetchResources_v2<
    Message[],
    () => Promise<Message[]>
  >({
    remoteResourcesProvider: async () => {
      isFetchingRef.current = true;
      try {
        const { msgs, total } = await getMessages(
          client,
          locale,
          os,
          token,
          isdn,
          page,
          "network-only"
        );
        setPaginationInfo(p => {
          const items: Message[] = page === 1 || p == null ? [] : p.items;
          items.push(...msgs);
          return {
            items,
            currentPage: page,
            hasMore: items.length < total,
          };
        });
        return msgs;
      } finally {
        isFetchingRef.current = false;
      }
    },
  });

  const fetchNext = useCallback(() => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return;
    }
    if (isFetchingRef.current) {
      return;
    }
    if (_paginationInfo == null) {
      setForceFetchKey(p => p + 1);
    } else {
      setPage(p => p + 1);
    }
  }, [paginationInfoRef]);

  const refresh = useCallback(() => {
    setPage(1);
    setForceFetchKey(k => k + 1);
  }, []);

  useEffect(() => {
    fetch().catch(() => {});
  }, [page, forceFetchKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPage(1);
    setForceFetchKey(k => k + 1);
  }, [token, isdn]);

  return {
    requestState,
    paginationInfo,
    fetchNext,
    refresh,
  };
}

export function useMessagesMarkRead(
  token: Token,
  isdn: Isdn
): (messageIds: string[]) => Promise<void> {
  const client = useApolloClient();
  const { locale } = useIntl();

  return useCallback(
    async (messageIds: string[]) => {
      await messagesMarkRead(client, locale, token, isdn, messageIds);
    },
    [client, locale, token, isdn]
  );
}

function noop() {}

export function useFetchPushNotificationMessagesV2(): {
  requestState: ResourcesRequestState<Message[]>;
  paginationInfo: PaginationInfo<Message> | null;
  fetchNext: () => void;
  refresh: () => void;
} {
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    Message
  > | null>(null);

  const [requestState, { call: fetch }] = useFetchResources_v2<
    Message[],
    () => Promise<Message[]>
  >({
    remoteResourcesProvider: async () => {
      const messages = (await InboxMessagePlugin.loadMessages().catch(
        () => []
      )).messages.map(transformInboxMessageToMessage);
      setPaginationInfo({
        items: messages,
        currentPage: 1,
        hasMore: false,
      });
      return messages;
    },
  });

  useEffect(() => {
    fetch().catch(noop);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    requestState,
    paginationInfo,
    fetchNext: noop,
    refresh: noop,
  };
}

export function useMessagesMarkReadV2(): (
  messageIds: string[]
) => Promise<void> {
  return useCallback(async (messageIds: string[]) => {
    for (const id of messageIds) {
      await InboxMessagePlugin.markAsRead({ id }).catch(console.error);
    }
  }, []);
}

function transformInboxMessageToMessage(inboxMessage: InboxMessage): Message {
  return {
    id: inboxMessage.id,
    title: inboxMessage.title,
    message: inboxMessage.message,
    pushStartDate: new Date(inboxMessage.receivedAt),
    read: inboxMessage.read ? ReadState.Read : ReadState.New,
    extraData: inboxMessage.deeplinkURL
      ? {
          type: "goTo",
          goTo: inboxMessage.deeplinkURL,
        }
      : null,
    type: null,
  };
}
