import React, { useCallback } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

interface Props {
  checked: boolean;
  disabled?: boolean;
  onCheck: (checked: boolean) => void;
}

const Toggle: React.FC<Props> = props => {
  const { checked, onCheck, disabled } = props;
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked: _checked } = e.target;
      onCheck(_checked);
    },
    [onCheck]
  );
  return (
    <span>
      <label
        className={cn(styles.label, {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
        })}
        data-on="Yes"
        data-off="No"
      >
        <input
          checked={checked}
          className={styles.toggle}
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          value={checked ? "true" : "false"}
        />
      </label>
    </span>
  );
};

export default Toggle;
