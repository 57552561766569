import React, { useMemo } from "react";
import SearchSuggestionList, {
  SearchSuggestionListItemItem,
} from "./SearchSuggestionList";
import { stringLiteral } from "../../utils/type";
import { SearchAutoSuggestion } from "../../models/Search";
import { MessageID } from "../../i18n/translations/type";

interface Props {
  searchTerm: string;
  items: SearchAutoSuggestion[];
  onClickItem: (item: SearchSuggestionListItemItem) => void;
  titleID: MessageID;
}
const FilteredListingSearchesList: React.FC<Props> = props => {
  const { searchTerm, items, onClickItem, titleID } = props;
  const listItems = useMemo(
    () => ({
      type: stringLiteral("filtered-listing"),
      searchTerm,
      searchSuggestions: items,
    }),
    [searchTerm, items]
  );
  return (
    <SearchSuggestionList
      displayMode="row"
      headerTitleID={titleID}
      items={listItems}
      onClickItem={onClickItem}
    />
  );
};

export default FilteredListingSearchesList;
