import cn from "classnames";
import { format } from "date-fns";
import React, { CSSProperties, useCallback, useMemo } from "react";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import {
  CampaignForClubMember,
  CampaignOrderItem,
  UnavailableCampaign,
} from "../../models/ClubMemberCampaign";
import styles from "./List.module.scss";

interface AvailableListProps {
  bingoList: CampaignForClubMember[];
  onBingoListCampaignClick: (bingoListCampaign: CampaignForClubMember) => void;
}

export const AvailableList: React.FC<AvailableListProps> = props => {
  const { bingoList, onBingoListCampaignClick } = props;

  return (
    <ul className={styles.list}>
      {bingoList.map((bingoListCampaign, i) => (
        <AvailableItem
          key={i}
          bingoListCampaign={bingoListCampaign}
          onClick={onBingoListCampaignClick}
        />
      ))}
    </ul>
  );
};

interface AvailableItem {
  bingoListCampaign: CampaignForClubMember;
  onClick: (bingoListCampaign: CampaignForClubMember) => void;
}

const AvailableItem: React.FC<AvailableItem> = props => {
  const { bingoListCampaign: campaign, onClick } = props;

  return (
    <li className={styles.item}>
      <CampaignItem
        campaign={campaign}
        onClick={onClick}
        disabled={false}
        dimmed={false}
      />
    </li>
  );
};

type UnavailableCampaign_ = UnavailableCampaign<
  CampaignForClubMember,
  CampaignOrderItem
>;

interface UnavailableListProps {
  unavailableBingoList: UnavailableCampaign_[];
}

export const UnavailableList: React.FC<UnavailableListProps> = props => {
  const { unavailableBingoList } = props;

  return (
    <ul className={styles.list}>
      {unavailableBingoList.map(unavailableCampaign => {
        switch (unavailableCampaign.type) {
          case "expired":
            return <ExpiredItem expiredCampaign={unavailableCampaign} />;
          case "redeemed":
            return <RedeemedItem redeemedCampaign={unavailableCampaign} />;
          default:
            return null;
        }
      })}
    </ul>
  );
};

interface ExpiredItemProps {
  expiredCampaign: Extract<UnavailableCampaign_, { type: "expired" }>;
}

const ExpiredItem: React.FC<ExpiredItemProps> = props => {
  const { expiredCampaign } = props;
  const { translate } = useIntl();

  const { campaign } = expiredCampaign;
  const { end } = campaign;

  return (
    <li className={styles.item}>
      <CampaignItem
        campaign={campaign}
        disabled={true}
        dimmed={true}
        message={
          end
            ? translate("bingo_list.item.expired_message", {
                dateStr: format(
                  end,
                  translate("bingo_list.item.expired_message.date_format")
                ),
              })
            : ""
        }
      />
    </li>
  );
};

interface RedeemedItemProps {
  redeemedCampaign: Extract<UnavailableCampaign_, { type: "redeemed" }>;
}

const RedeemedItem: React.FC<RedeemedItemProps> = props => {
  const { redeemedCampaign } = props;
  const { translate } = useIntl();

  const { campaign, orderedItem } = redeemedCampaign;
  const { createdAt } = orderedItem;

  return (
    <li className={styles.item}>
      <CampaignItem
        campaign={campaign}
        disabled={true}
        dimmed={true}
        message={
          createdAt
            ? translate("bingo_list.item.redeemed_message", {
                dateStr: format(
                  createdAt,
                  translate("bingo_list.item.redeemed_message.date_format")
                ),
              })
            : ""
        }
      />
    </li>
  );
};

interface CampaignItemProps {
  campaign: CampaignForClubMember;
  disabled: boolean;
  dimmed: boolean;
  onClick?: (bingoListCampaign: CampaignForClubMember) => void;
  message?: string;
}

const CampaignItem: React.FC<CampaignItemProps> = props => {
  const { campaign, disabled, dimmed, onClick, message } = props;
  const { translate } = useIntl();

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("kenchannnn handleClick");
      if (onClick) {
        onClick(campaign);
      }
    },
    [onClick, campaign]
  );

  const imageStyle = useMemo<CSSProperties>(
    () => ({
      backgroundImage: campaign.image ? `url(${campaign.image})` : undefined,
    }),
    [campaign]
  );

  return (
    <div className={styles.campaignItem}>
      <button
        onClick={handleClick}
        disabled={disabled}
        className={styles.itemContainer}
      >
        <div className={styles.bannerContainer}>
          <div
            style={imageStyle}
            className={cn(styles.image, { [styles.dimmed]: dimmed })}
          />
          {message ? (
            <div className={styles.campaignItemMessageContainer}>
              <p className={styles.campaignItemMessage}>{message}</p>
            </div>
          ) : null}
        </div>
        <div className={cn(styles.infoContainer, { [styles.dimmed]: dimmed })}>
          <p className={styles.campaignTitle}>{campaign.title}</p>
          {campaign.end ? (
            <p className={styles.campaignEndDateMessage}>
              <LocalizedText
                messageID="bingo_list.item.expire_message"
                messageArgs={{
                  dateStr: format(
                    campaign.end,
                    translate("bingo_list.item.expire_message.date_format")
                  ),
                }}
              />
            </p>
          ) : null}
        </div>
      </button>
    </div>
  );
};
