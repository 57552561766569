import React, { useMemo } from "react";

import CLLink from "../../navigation/CLLink";

import {
  CMSCateogryBricksBlock,
  CMSCateogryBrick,
} from "../../../models/cmsBlock";

import { getBackgroundColor } from "../../../models/category";
import {
  RootTab,
  getPathForSingleCategoryPage,
} from "../../../navigation/routes";

import styles from "./styles.module.scss";
import { useUrlRedirectConfig } from "../../../repository/ConfigRepository";
import findMatchedRedirectMapping from "../../../utils/findMatchedRedirectMapping";

interface Props {
  currentTab: RootTab;
  cmsBlock: CMSCateogryBricksBlock;
}

const CMSCategoryBricksBlock: React.FC<Props> = props => {
  const { cmsBlock, currentTab } = props;
  const items = useMemo(() => (cmsBlock.items ? cmsBlock.items : []), [
    cmsBlock,
  ]);
  return (
    <div className={styles.container}>
      <div className={styles.categoryList}>
        {items.map(categoryBrick => (
          <div key={categoryBrick.id} className={styles.categoryListItem}>
            <CMSCategoryBrickItem
              categoryBrick={categoryBrick}
              currentTab={currentTab}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CMSCategoryBricksBlock;

const CMSCategoryBrickItem: React.FC<{
  categoryBrick: CMSCateogryBrick;
  currentTab: RootTab;
}> = props => {
  const { categoryBrick, currentTab } = props;
  const urlRedirectConfig = useUrlRedirectConfig();
  const backgroundColor = getBackgroundColor(
    {
      backgroundColorFrom: categoryBrick.backgroundColor1 || null,
      backgroundColorTo: categoryBrick.backgroundColor2 || null,
    },
    "bottom"
  );
  const thumbnailStyles = useMemo<React.CSSProperties>(() => {
    const backgroundImages = [];
    if (categoryBrick.categoryThumbnail) {
      backgroundImages.push(`url(${categoryBrick.categoryThumbnail})`);
    }
    if (backgroundColor != null) {
      backgroundImages.push(backgroundColor);
    }
    if (backgroundImages.length > 0) {
      return {
        backgroundImage: backgroundImages.join(","),
      };
    }
    return {
      backgroundColor: "white",
    };
  }, [backgroundColor, categoryBrick.categoryThumbnail]);
  const toLink = useMemo(() => {
    const matched = findMatchedRedirectMapping(
      urlRedirectConfig,
      "CATEGORY",
      categoryBrick.id
    );
    if (matched != null) {
      return matched.targetPath;
    }

    return getPathForSingleCategoryPage(currentTab, categoryBrick.id);
  }, [categoryBrick.id, urlRedirectConfig, currentTab]);
  return (
    <CLLink className={styles.categoryBrick} to={toLink}>
      <div style={thumbnailStyles} className={styles.categoryThumbnail} />
      <div className={styles.categoryName}>{categoryBrick.name}</div>
    </CLLink>
  );
};
