import React, { useState, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";

import CLContent from "../CLContent";
import { NavBar, NavBarBackButton } from "../NavBar";
import { O2oStore } from "../Checkout/models";
import O2oStoreDisplay from "../O2oStore";

import styles from "./styles.module.scss";

export interface LocationState {
  options: O2oStore[];
  selectedValue: string;
  onSelect: (o2oStore: O2oStore) => void;
}

type Props = RouteComponentProps<{}, {}, LocationState>;

const CheckoutSelectO2oStorePage: React.FC<Props> = props => {
  const {
    location: { state },
  } = props;

  const [{ options, onSelect }] = useState<{
    options: O2oStore[];
    onSelect: (o2oStore: O2oStore) => void;
  }>({
    options: state.options,
    onSelect: state.onSelect,
  });

  const [selectedValue, setSelectedValue] = useState<string>(
    state.selectedValue
  );

  const handleClickO2oStoreItem = useCallback(
    (o2oStore: O2oStore) => {
      setSelectedValue(o2oStore.code);
      onSelect(o2oStore);
    },
    [onSelect]
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="checkout.select_o2o_store.title" />
        }
      />
      <CLContent className={styles.ionContent}>
        <div className={styles.content}>
          {options.map(o2oStore => {
            return (
              <O2oStoreItem
                o2oStore={o2oStore}
                selected={o2oStore.code === selectedValue}
                key={o2oStore.code}
                onClick={handleClickO2oStoreItem}
              />
            );
          })}
        </div>
      </CLContent>
    </>
  );
};

export default CheckoutSelectO2oStorePage;

interface ItemProps {
  o2oStore: O2oStore;
  selected: boolean;
  onClick: (o2oStore: O2oStore) => void;
}

const O2oStoreItem: React.FC<ItemProps> = props => {
  const { o2oStore, selected, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(o2oStore);
  }, [onClick, o2oStore]);

  return (
    <div
      className={cn(styles.o2oStore, {
        [styles.o2oStoreSelected]: selected,
      })}
      onClick={handleClick}
    >
      <div className={styles.o2oStoreBody}>
        <O2oStoreDisplay o2oStore={o2oStore} />
      </div>
      <div
        className={cn(styles.o2oStoreOption, {
          [styles.o2oStoreOptionActive]: selected,
        })}
      />
    </div>
  );
};
