import { useContext, useCallback, useMemo, useRef } from "react";
import {
  // eslint-disable-next-line no-restricted-imports
  IonLifeCycleContext,
  IonLifeCycleContextInterface,
} from "@ionic/react";

type LifeCycleCallback = Function;

class CallbackManager<T extends Function> {
  private callbacks: T[] = [];

  register = (callback: T) => {
    const isExist = this.callbacks.findIndex(cb => cb === callback) !== -1;
    if (!isExist) {
      this.callbacks.push(callback);
    }
  };

  unregisterAll = () => {
    this.callbacks = [];
  };

  invokeCallbacks = () => {
    this.callbacks.forEach(cb => cb());
  };
}

class IonLifeCycleCallbackManager {
  private onIonViewWillEnterCallbackManager = new CallbackManager<
    LifeCycleCallback
  >();
  private onIonViewDidEnterCallbackManager = new CallbackManager<
    LifeCycleCallback
  >();
  private onIonViewWillLeaveCallbackManager = new CallbackManager<
    LifeCycleCallback
  >();
  private onIonViewDidLeaveCallbackManager = new CallbackManager<
    LifeCycleCallback
  >();

  registerOnIonViewWillEnterCallback = (callback: LifeCycleCallback) => {
    this.onIonViewWillEnterCallbackManager.register(callback);
  };
  registerOnIonViewDidEnterCallback = (callback: LifeCycleCallback) => {
    this.onIonViewDidEnterCallbackManager.register(callback);
  };
  registerOnIonViewWillLeaveCallback = (callback: LifeCycleCallback) => {
    this.onIonViewWillLeaveCallbackManager.register(callback);
  };
  registerOnIonViewDidLeaveCallback = (callback: LifeCycleCallback) => {
    this.onIonViewDidLeaveCallbackManager.register(callback);
  };

  invokeOnIonViewWillEnterCallbacks = () => {
    this.onIonViewWillEnterCallbackManager.invokeCallbacks();
  };
  invokeOnIonViewDidEnterCallbacks = () => {
    this.onIonViewDidEnterCallbackManager.invokeCallbacks();
  };
  invokeOnIonViewWillLeaveCallbacks = () => {
    this.onIonViewWillLeaveCallbackManager.invokeCallbacks();
  };
  invokeOnIonViewDidLeaveCallbacks = () => {
    this.onIonViewDidLeaveCallbackManager.invokeCallbacks();
  };

  unregisterAll = () => {
    [
      this.onIonViewWillEnterCallbackManager,
      this.onIonViewDidEnterCallbackManager,
      this.onIonViewWillLeaveCallbackManager,
      this.onIonViewDidLeaveCallbackManager,
    ].forEach(m => m.unregisterAll());
  };
}

function useCLIonLifeCycleContext(): IonLifeCycleContextInterface {
  const ionLifeCycleContext = useContext(IonLifeCycleContext);
  const ionLifeCycleCallbackManager = useRef(new IonLifeCycleCallbackManager());

  const {
    ionViewWillEnter,
    ionViewDidEnter,
    ionViewWillLeave,
    ionViewDidLeave,
  } = ionLifeCycleContext;

  ionLifeCycleCallbackManager.current.unregisterAll();

  const onIonViewWillEnter = useCallback(
    (callback: LifeCycleCallback | undefined) => {
      if (callback == null) {
        return;
      }
      ionLifeCycleCallbackManager.current.registerOnIonViewWillEnterCallback(
        callback
      );
    },
    []
  );
  const onIonViewDidEnter = useCallback(
    (callback: LifeCycleCallback | undefined) => {
      if (callback == null) {
        return;
      }
      ionLifeCycleCallbackManager.current.registerOnIonViewDidEnterCallback(
        callback
      );
    },
    []
  );
  const onIonViewWillLeave = useCallback(
    (callback: LifeCycleCallback | undefined) => {
      if (callback == null) {
        return;
      }
      ionLifeCycleCallbackManager.current.registerOnIonViewWillLeaveCallback(
        callback
      );
    },
    []
  );
  const onIonViewDidLeave = useCallback(
    (callback: LifeCycleCallback | undefined) => {
      if (callback == null) {
        return;
      }
      ionLifeCycleCallbackManager.current.registerOnIonViewDidLeaveCallback(
        callback
      );
    },
    []
  );

  ionLifeCycleContext.onIonViewWillEnter(
    ionLifeCycleCallbackManager.current.invokeOnIonViewWillEnterCallbacks
  );
  ionLifeCycleContext.onIonViewDidEnter(
    ionLifeCycleCallbackManager.current.invokeOnIonViewDidEnterCallbacks
  );
  ionLifeCycleContext.onIonViewWillLeave(
    ionLifeCycleCallbackManager.current.invokeOnIonViewWillLeaveCallbacks
  );
  ionLifeCycleContext.onIonViewDidLeave(
    ionLifeCycleCallbackManager.current.invokeOnIonViewDidLeaveCallbacks
  );

  return useMemo(
    () => ({
      onIonViewWillEnter,
      onIonViewDidEnter,
      onIonViewWillLeave,
      onIonViewDidLeave,

      ionViewWillEnter,
      ionViewDidEnter,
      ionViewWillLeave,
      ionViewDidLeave,
    }),
    [
      onIonViewWillEnter,
      onIonViewDidEnter,
      onIonViewWillLeave,
      onIonViewDidLeave,

      ionViewWillEnter,
      ionViewDidEnter,
      ionViewWillLeave,
      ionViewDidLeave,
    ]
  );
}

export default useCLIonLifeCycleContext;
