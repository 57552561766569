import React, { useCallback, useContext, useMemo } from "react";
import numeral from "numeral";

import { LocalizedText } from "../../i18n/Localization";

import {
  FilterAttribute,
  RangeFilterAttribute,
  ProductFilterInfo,
  getApplicableProductFilterInfo,
  getFilterLabel,
} from "../../models/filter";
import ProductAttributeFilterContext from "../../contexts/ProductAttributeFilterContext";

import styles from "./styles.module.scss";

interface Props {
  productFilterInfo: ProductFilterInfo;
  onClickClearAllButton: () => void;
  onClickClearButton: (appliedFilter: FilterAttribute) => void;
  onClickClearRangeButton: (appliedRangeFilter: RangeFilterAttribute) => void;
  onClickClearPredefinedCPFilterButton: () => void;
}

const AppliedFiltersList: React.FC<Props> = React.memo(props => {
  const {
    productFilterInfo,
    onClickClearAllButton,
    onClickClearButton,
    onClickClearRangeButton,
    onClickClearPredefinedCPFilterButton,
  } = props;

  const applicableProductFilterInfo = useMemo(
    () => getApplicableProductFilterInfo(productFilterInfo),
    [productFilterInfo]
  );
  const appliedFilters = useMemo(
    () =>
      Object.values(applicableProductFilterInfo.attributeMap).concat(
        Object.values(applicableProductFilterInfo.multiLevelAttributeMap)
      ),
    [applicableProductFilterInfo]
  );
  const appliedRangeFilters = useMemo(
    () => Object.values(applicableProductFilterInfo.rangeAttributeMap),
    [applicableProductFilterInfo]
  );

  const onClickClearAllButton_ = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickClearAllButton();
    },
    [onClickClearAllButton]
  );

  const handlePredefinedCPFilterClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickClearPredefinedCPFilterButton();
    },
    [onClickClearPredefinedCPFilterButton]
  );

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {productFilterInfo.predefinedCPFilter ? (
          <div className={styles.listItem}>
            <div
              className={styles.appliedFilterItem}
              onClick={handlePredefinedCPFilterClick}
            >
              <LocalizedText
                messageID={productFilterInfo.predefinedCPFilter.messageID}
              />
              <span className={styles.clearFilterButton} />
            </div>
          </div>
        ) : null}
        {appliedFilters.map(appliedFilter => (
          <div
            key={`${appliedFilter.attributeCode}-${appliedFilter.attributeValue}`}
            className={styles.listItem}
          >
            <AppliedFiltersListItem
              appliedFilter={appliedFilter}
              onClickClearButton={onClickClearButton}
            />
          </div>
        ))}
        {appliedRangeFilters.map(appliedRangeFilter => (
          <div
            key={`${appliedRangeFilter.attributeCode}-${appliedRangeFilter.min}-${appliedRangeFilter.max}`}
            className={styles.listItem}
          >
            <AppliedRangeFiltersListItem
              appliedRangeFilter={appliedRangeFilter}
              onClickClearRangeButton={onClickClearRangeButton}
            />
          </div>
        ))}
      </div>
      <button
        className={styles.clearAllButton}
        onClick={onClickClearAllButton_}
      >
        <LocalizedText messageID="filter.clear_all" />
      </button>
    </div>
  );
});

export default AppliedFiltersList;

const AppliedFiltersListItem: React.FC<{
  appliedFilter: FilterAttribute;
  onClickClearButton: (appliedFilter: FilterAttribute) => void;
}> = React.memo(props => {
  const { appliedFilter, onClickClearButton } = props;
  const { productAttributeFilterInputMap } = useContext(
    ProductAttributeFilterContext
  );
  const onClickClearButton_ = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickClearButton(appliedFilter);
    },
    [appliedFilter, onClickClearButton]
  );
  const title = useMemo(
    () => getFilterLabel(appliedFilter, productAttributeFilterInputMap),
    [appliedFilter, productAttributeFilterInputMap]
  );
  return (
    <div className={styles.appliedFilterItem} onClick={onClickClearButton_}>
      {title}
      <span className={styles.clearFilterButton} />
    </div>
  );
});

const AppliedRangeFiltersListItem: React.FC<{
  appliedRangeFilter: RangeFilterAttribute;
  onClickClearRangeButton: (appliedRangeFilter: RangeFilterAttribute) => void;
}> = React.memo(props => {
  const { appliedRangeFilter, onClickClearRangeButton } = props;
  const onClickClearRangeButton_ = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickClearRangeButton(appliedRangeFilter);
    },
    [appliedRangeFilter, onClickClearRangeButton]
  );
  return (
    <div
      className={styles.appliedFilterItem}
      onClick={onClickClearRangeButton_}
    >
      <LocalizedText
        messageID="filter.applied_filters.range_format"
        messageArgs={{
          title: appliedRangeFilter.title,
          min:
            appliedRangeFilter.min != null
              ? numeral(appliedRangeFilter.min).format("0,0")
              : "*",
          max:
            appliedRangeFilter.max != null
              ? numeral(appliedRangeFilter.max).format("0,0")
              : "*",
        }}
      />
      <span className={styles.clearFilterButton} />
    </div>
  );
});
