import React from "react";
// eslint-disable-next-line no-restricted-imports
import { IonHeader, IonToolbar, IonTitle } from "@ionic/react";

import { LoadingView } from "../LoadingView";

import styles from "./styles.module.scss";

const AppUIPlaceholder: React.FC = () => {
  return (
    <>
      <NavBar />
      <FullContentLoadingView />
    </>
  );
};

export default AppUIPlaceholder;

const NavBar: React.FC = () => {
  return (
    <>
      <IonHeader mode="ios" className={styles.header}>
        <IonToolbar mode="ios">
          <IonTitle>
            <div className={styles.logo} />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
    </>
  );
};

export const FullContentLoadingView: React.FC = () => {
  return (
    <div className={styles.fullContentLoadingViewContainer}>
      <LoadingView />
    </div>
  );
};
