import cn from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Config from "../../Config";
import { LocalizedText } from "../../i18n/Localization";
import { loginFormImpressionEvent } from "../../utils/GTM";
import { isiOS } from "../../utils/Platform";
import { validateEmail } from "../../utils/validation";
import { PrimaryButton } from "../Button";
import Input from "../Form/Input";
import PasswordInputField from "../PasswordInputField";
import NavBar from "./NavBar";
import SocialLoginButtons from "./SocialLoginButtons";
import styles from "./styles.module.scss";

interface Props {
  isLoading: boolean;
  onBack: () => void;
  onFacebookLoginClick: () => void;
  onGoogleLoginClick: () => void;
  onForgotPasswordClick: () => void;
  onLoginFormSubmit: (email: string, password: string) => void;
  onCreateAccountButtonClick: () => void;
}

const TheClubSSOClubShoppingSignInView: React.FC<Props> = props => {
  const {
    isLoading,
    onBack,
    onFacebookLoginClick,
    onGoogleLoginClick,
    onForgotPasswordClick,
    onLoginFormSubmit,
    onCreateAccountButtonClick,
  } = props;

  useEffect(() => {
    loginFormImpressionEvent();
  }, []);

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const isEmailValid = validateEmail(email);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const handleForgotPasswordClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onForgotPasswordClick();
    },
    [onForgotPasswordClick]
  );

  const handleLoginFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  const handleLoginButtonClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onLoginFormSubmit(email, password);
    },
    [onLoginFormSubmit, email, password]
  );

  const handleCreateAccountButtonClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onCreateAccountButtonClick();
    },
    [onCreateAccountButtonClick]
  );

  const showSSO = useMemo(() => !isiOS(), []);

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div className={styles.navBar}>
          <NavBar onBackClick={onBack} disabled={isLoading} />
        </div>
        {Config.ENABLE_THE_CLUB_SSO_MVP1 &&
        Config.ENABLE_THE_CLUB_SSO_MVP1
          .THE_CLUB_SSO_CONFIRMATION_DIALOGS ? null : (
          <p className={styles.terminationMessage}>
            <LocalizedText messageID="the_club_sso.club_shopping_sign_in.termination" />
          </p>
        )}
        {showSSO ? (
          <div className={styles.socialLoginButtons}>
            <SocialLoginButtons
              onGoogleLoginClick={onGoogleLoginClick}
              onFacebookLoginClick={onFacebookLoginClick}
            />
          </div>
        ) : null}
        <form
          onSubmit={handleLoginFormSubmit}
          className={cn(styles.form, styles.flex1)}
        >
          <div className={styles.emailLoginContainer}>
            {showSSO ? (
              <div className={styles.loginTypeTitle}>
                <LocalizedText messageID="login.or_login_with_email" />
              </div>
            ) : null}
            <Input
              className={cn(
                styles.emailInput,
                styles.input,
                email !== "" && !isEmailValid && styles.error
              )}
              type="email"
              placeholderId="email"
              value={email}
              onChange={setEmail}
              ref={emailInputRef}
              nextFieldRef={passwordInputRef}
            />
            <PasswordInputField
              className={styles.passwordInput}
              placeholderID="password"
              value={password}
              onChange={setPassword}
              ref={passwordInputRef}
            />
            <div
              className={styles.forgotPassword}
              onClick={handleForgotPasswordClick}
            >
              <LocalizedText messageID="login.forgot_password" />
            </div>
          </div>
          <PrimaryButton
            loading={isLoading}
            className={styles.loginButton}
            onClick={handleLoginButtonClick}
            disabled={
              password === "" || email === "" || !isEmailValid || isLoading
            }
          >
            <LocalizedText messageID="login" />
          </PrimaryButton>
        </form>
      </div>
      <div className={styles.signupContainer}>
        <div className={styles.signupText}>
          <LocalizedText
            messageID="login.do_not_have_an_account"
            messageArgs={{
              textClassName: styles.createAccountText,
              createAccountClassName: styles.createAccountButton,
              onCreateAccountButtonClick: handleCreateAccountButtonClick,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TheClubSSOClubShoppingSignInView;
