import React, {
  useRef,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { Plugins } from "@capacitor/core";
import { IndexMap } from "../utils/type";
import { useKeepUpdatingRef } from "../hook/utils";
import { LocalizedAlertContext } from "./LocalizedAlertProvider";

const { App } = Plugins;

interface Subscription {
  remove: () => void;
}

interface BackAndroidContextValue {
  addHandler: (handler: () => boolean) => Subscription;
}

const BackAndroidContext = React.createContext<BackAndroidContextValue>(
  null as any
);

export const BackAndroidHandlerProvider: React.FC = props => {
  const nextID = useRef<number>(0);
  const handlers = useRef<IndexMap<string, () => boolean>>({});

  useEffect(() => {
    const sub = App.addListener("backButton", () => {
      const ids = Object.keys(handlers.current)
        .map(Number)
        .sort((a, b) => b - a);
      for (const id of ids) {
        const handler = handlers.current[id];
        if (handler) {
          const handled = handler();
          if (handled) {
            break;
          }
        }
      }
    });
    return () => {
      sub.remove();
    };
  }, []);

  const addHandler = useCallback(
    (handler: () => boolean): Subscription => {
      const id = nextID.current;
      nextID.current += 1;
      handlers.current[id] = handler;
      return {
        remove: () => {
          delete handlers.current[id];
        },
      };
    },
    [handlers]
  );
  const contextValue = useMemo<BackAndroidContextValue>(
    () => ({
      addHandler,
    }),
    [addHandler]
  );
  return (
    <BackAndroidContext.Provider value={contextValue}>
      {props.children}
    </BackAndroidContext.Provider>
  );
};

const BackAndroidHandler: React.FC<{
  onBackPress: () => boolean;
}> = props => {
  const { onBackPress } = props;
  const { addHandler } = useContext(BackAndroidContext);
  useEffect(() => {
    const subscription = addHandler(onBackPress);
    return () => {
      subscription.remove();
    };
  }, [onBackPress, addHandler]);
  return null;
};

export default BackAndroidHandler;

export const BackAndroidExitApp: React.FC<{
  pageIsActive: boolean;
}> = props => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const { pageIsActive } = props;
  const pageIsActiveRef = useKeepUpdatingRef(pageIsActive);
  const isAlertShowing = useRef(false);
  const onBackExitApp = useCallback(() => {
    if (pageIsActiveRef.current === true) {
      if (isAlertShowing.current === false) {
        presentLocalizedAlert({
          messageId: "alert.confirm_exit_app",
          buttons: [
            {
              textMessageID: "alert.confirm_exit_app.yes",
              handler: () => {
                App.exitApp();
                isAlertShowing.current = false;
              },
            },
            {
              textMessageID: "alert.confirm_exit_app.no",
              handler: () => {
                isAlertShowing.current = false;
              },
            },
          ],
          backdropDismiss: false,
          keyboardClose: false,
        });
        isAlertShowing.current = true;
      }
      return true;
    }
    return false;
  }, [pageIsActiveRef, presentLocalizedAlert]);
  return <BackAndroidHandler onBackPress={onBackExitApp} />;
};
