import React, { useEffect } from "react";
import { useKeepUpdatingRef } from "../hook/utils";

interface Props {
  onMount: () => void;
}

const MountNotifier: React.FC<Props> = props => {
  const { onMount, children } = props;

  const onMountRef = useKeepUpdatingRef(onMount);

  useEffect(() => {
    onMountRef.current();
  }, [onMountRef]);

  return <>{children}</>;
};

export default MountNotifier;
