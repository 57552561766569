import React, { useCallback } from "react";
import cn from "classnames";

import styles from "./SelectBlock.module.scss";

interface Props<T> {
  header: React.ReactNode;
  value: T;
  selected: boolean;
  collapsed?: boolean;
  disabled?: boolean;
  onSelect?: (value: T) => void;
  hideIndicator?: boolean;
  indicatorAlignment?: "top" | "center";
  bottomAccessory?: React.ReactNode;
}

const SelectBlock = <T extends string>(
  props: Props<T> & { children?: React.ReactNode }
) => {
  const {
    header,
    value,
    selected,
    collapsed,
    onSelect,
    children,
    disabled,
    hideIndicator,
    indicatorAlignment = "top",
    bottomAccessory,
  } = props;

  const handleClick = useCallback(() => {
    if (disabled || !onSelect) {
      return;
    }
    onSelect(value);
  }, [onSelect, disabled, value]);

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.selectBlock, {
          [styles.selectBlockActive]: selected,
          [styles.disabled]: disabled,
        })}
      >
        <div className={styles.header} onClick={handleClick}>
          <div className={styles.headerLeft}>{header}</div>
          <div className={styles.headerRight}>
            {hideIndicator ? null : (
              <div
                className={cn(styles.indicator, {
                  [styles.indicatorActive]: selected,
                  [styles["indicator--alignTop"]]: indicatorAlignment === "top",
                  [styles["indicator--alignCenter"]]:
                    indicatorAlignment === "center",
                })}
              />
            )}
          </div>
        </div>
        {!collapsed && children && (
          <div className={styles.body}>{children}</div>
        )}
      </div>
      {bottomAccessory}
    </div>
  );
};

export default SelectBlock;
