import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from "@capacitor/core";

import * as Storage from "../../storage";
import {
  OS,
  Platform,
  Token,
  Isdn,
  getOS,
  dummyIsdn,
  getPlatform,
  getLinkFromPushNotification,
  getIdFromPushNotification,
} from "../../models/OPNSPushNotification";
import { getPathForPushNotificationMessages } from "../../navigation/routes";
import { PresentationContext } from "../../our-navigation";
import {
  appEventEmitter,
  AppEventPushNotificationGoTo,
} from "../../utils/SimpleEventEmitter";

import { PushNotificationContext } from "../PushNotificationProvider";
import {
  LocalizedAlertContext,
  LocalizedAlertProvider,
  LocalizedAlertButton,
} from "../LocalizedAlertProvider";
import { withProviders } from "../Provider";

import {
  useRegisterDeviceToken,
  useChangeDeviceToken,
  useDeleteDeviceToken,
  useMessageMarkRead,
} from "../../repository/PushNotificationRepository";

interface SyncStateInitializing {
  type: "initializing";
}
const SyncStateInitializing: SyncStateInitializing = {
  type: "initializing",
};

interface SyncStateAborted {
  type: "aborted";
}
const SyncStateAborted: SyncStateAborted = {
  type: "aborted",
};

interface SyncStateNoChange {
  type: "noChange";
  token: Token | null;
}
function SyncStateNoChange(token: Token | null): SyncStateNoChange {
  return {
    type: "noChange",
    token,
  };
}

interface SyncStateShouldRegister {
  type: "shouldRegister";
  os: OS;
  platform: Platform;
  token: Token;
  isdn: Isdn;
}
function SyncStateShouldRegister(
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
): SyncStateShouldRegister {
  return {
    type: "shouldRegister",
    os,
    platform,
    token,
    isdn,
  };
}

interface SyncStateShouldChange {
  type: "shouldChange";
  os: OS;
  platform: Platform;
  oldToken: Token;
  token: Token;
  isdn: Isdn;
}
function SyncStateShouldChange(
  os: OS,
  platform: Platform,
  oldToken: Token,
  token: Token,
  isdn: Isdn
): SyncStateShouldChange {
  return {
    type: "shouldChange",
    os,
    platform,
    oldToken,
    token,
    isdn,
  };
}

interface SyncStateShouldDelete {
  type: "shouldDelete";
  os: OS;
  platform: Platform;
  token: Token;
  isdn: Isdn;
}
function SyncStateShouldDelete(
  os: OS,
  platform: Platform,
  token: Token,
  isdn: Isdn
): SyncStateShouldDelete {
  return {
    type: "shouldDelete",
    os,
    platform,
    token,
    isdn,
  };
}

type SyncState =
  | SyncStateInitializing
  | SyncStateAborted
  | SyncStateNoChange
  | SyncStateShouldRegister
  | SyncStateShouldChange
  | SyncStateShouldDelete;

const { PushNotifications } = Plugins;

const PushNotificationReceiver: React.FC = () => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const presentationContext = useContext(PresentationContext);

  const {
    enablePushNotification,
    enablePushNotificationInitialized,
    deviceToken,
    setDeviceToken,
    addUnreadMessageCount,
    messageMarkRead: contextMessageMarkRead,
  } = useContext(PushNotificationContext);

  const messageMarkRead = useMessageMarkRead(deviceToken, dummyIsdn);

  const remoteDeviceTokenRef = useRef<Token | null>(null);
  const [
    remoteDeviceTokenInitialized,
    setRemoteDeviceTokenInitialized,
  ] = useState(false);

  const pendingDeviceTokenRef = useRef<Token | null>(null);
  const [
    pendingDeviceTokenInitialized,
    setPendingDeviceTokenInitialized,
  ] = useState(false);

  const syncState = useMemo<SyncState>(() => {
    if (
      !remoteDeviceTokenInitialized ||
      !pendingDeviceTokenInitialized ||
      !enablePushNotificationInitialized
    ) {
      return SyncStateInitializing;
    }
    const os = getOS();
    const platform = getPlatform();
    if (!os || !platform) {
      return SyncStateAborted;
    }
    const remoteDeviceToken = remoteDeviceTokenRef.current;
    const pendingDeviceToken = pendingDeviceTokenRef.current;
    if (enablePushNotification) {
      if (remoteDeviceToken) {
        if (pendingDeviceToken) {
          if (remoteDeviceToken === pendingDeviceToken) {
            return SyncStateNoChange(remoteDeviceToken);
          }
          return SyncStateShouldChange(
            os,
            platform,
            remoteDeviceToken,
            pendingDeviceToken,
            dummyIsdn
          );
        }
        return SyncStateShouldDelete(
          os,
          platform,
          remoteDeviceToken,
          dummyIsdn
        );
      }
      if (pendingDeviceToken) {
        return SyncStateShouldRegister(
          os,
          platform,
          pendingDeviceToken,
          dummyIsdn
        );
      }
      return SyncStateNoChange(null);
    }
    if (remoteDeviceToken) {
      return SyncStateShouldDelete(os, platform, remoteDeviceToken, dummyIsdn);
    }
    return SyncStateNoChange(null);
  }, [
    remoteDeviceTokenInitialized,
    pendingDeviceTokenInitialized,
    enablePushNotificationInitialized,
    enablePushNotification,
  ]);

  const registerDeviceToken = useRegisterDeviceToken();
  const changeDeviceToken = useChangeDeviceToken();
  const deleteDeviceToken = useDeleteDeviceToken();

  const [granted, setGranted] = useState(false);

  useEffect(() => {
    (async () => {
      const _deviceToken = await Storage.getPNDeviceToken();
      remoteDeviceTokenRef.current = _deviceToken;
      setRemoteDeviceTokenInitialized(true);
    })();
  }, []);

  const handlePushNotificationReceived = useCallback(
    (notification: PushNotification) => {
      addUnreadMessageCount();
      const buttons: LocalizedAlertButton[] = [
        {
          textMessageID: "alert.button.ok",
        },
      ];

      const link = getLinkFromPushNotification(notification);
      const id = getIdFromPushNotification(notification);

      if (link) {
        buttons.push({
          textMessageID: "push_notification_alert.go_to",
          handler: () => {
            appEventEmitter.publish(AppEventPushNotificationGoTo(link));
            if (id) {
              messageMarkRead(id);
              contextMessageMarkRead(id);
            }
          },
        });
      }

      presentLocalizedAlert({
        header: notification.title,
        message: notification.body,
        buttons: buttons,
      });
    },
    [
      presentLocalizedAlert,
      addUnreadMessageCount,
      messageMarkRead,
      contextMessageMarkRead,
    ]
  );

  const handlePushNotificationActionPerformed = useCallback(
    (notification: PushNotificationActionPerformed) => {
      const link = getLinkFromPushNotification(notification.notification);
      const id = getIdFromPushNotification(notification.notification);
      if (link) {
        appEventEmitter.publish(AppEventPushNotificationGoTo(link));
        if (id) {
          messageMarkRead(id);
          contextMessageMarkRead(id);
        }
        return;
      }
      presentationContext.present(getPathForPushNotificationMessages());
    },
    [presentationContext, messageMarkRead, contextMessageMarkRead]
  );

  useEffect(() => {
    (async () => {
      const result = await PushNotifications.requestPermission();
      setGranted(result.granted);
    })();
  }, []);

  const [
    registrationEventRegistered,
    setRegistrationEventRegistered,
  ] = useState(false);

  useEffect(() => {
    if (!granted) {
      return;
    }

    const registerationListener = PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        pendingDeviceTokenRef.current = token.value;
        setPendingDeviceTokenInitialized(true);
      }
    );

    const registerationErrorListener = PushNotifications.addListener(
      "registrationError",
      (error: any) => {
        console.info("[DEV] Error on registration:", error);
      }
    );

    setRegistrationEventRegistered(true);

    return () => {
      registerationListener.remove();
      registerationErrorListener.remove();
    };
  }, [granted]);

  useEffect(() => {
    if (registrationEventRegistered) {
      PushNotifications.register();
    }
  }, [registrationEventRegistered]);

  useEffect(() => {
    const pushNotifcationReceivedListener = PushNotifications.addListener(
      "pushNotificationReceived",
      handlePushNotificationReceived
    );

    return () => {
      if (
        // listener and listener.remove may not be available in ios mode of chrome
        pushNotifcationReceivedListener &&
        pushNotifcationReceivedListener.remove
      ) {
        pushNotifcationReceivedListener.remove();
      }
    };
  }, [handlePushNotificationReceived]);

  useEffect(() => {
    const pushNotificationActionPerformedListener = PushNotifications.addListener(
      "pushNotificationActionPerformed",
      handlePushNotificationActionPerformed
    );

    return () => {
      if (
        // listener and listener.remove may not be available in ios mode of chrome
        pushNotificationActionPerformedListener &&
        pushNotificationActionPerformedListener.remove
      ) {
        pushNotificationActionPerformedListener.remove();
      }
    };
  }, [handlePushNotificationActionPerformed]);

  useEffect(() => {
    if (pendingDeviceTokenInitialized) {
      setDeviceToken(pendingDeviceTokenRef.current);
    }
  }, [pendingDeviceTokenInitialized, setDeviceToken]);

  useEffect(() => {
    (async () => {
      if (syncState.type === "shouldRegister") {
        const { os, platform, token, isdn } = syncState;
        await registerDeviceToken(os, platform, token, isdn);
        Storage.setPNDeviceToken(token);
        remoteDeviceTokenRef.current = token;
      } else if (syncState.type === "shouldChange") {
        const { os, platform, oldToken, token, isdn } = syncState;
        await changeDeviceToken(os, platform, oldToken, token, isdn);
        Storage.setPNDeviceToken(token);
        remoteDeviceTokenRef.current = token;
      } else if (syncState.type === "shouldDelete") {
        const { os, platform, token, isdn } = syncState;
        await deleteDeviceToken(os, platform, token, isdn);
        Storage.deletePNDeviceToken();
        remoteDeviceTokenRef.current = null;
      }
    })();
  }, [
    syncState,
    setDeviceToken,
    registerDeviceToken,
    changeDeviceToken,
    deleteDeviceToken,
  ]);

  return null;
};

export default withProviders(PushNotificationReceiver, LocalizedAlertProvider);
