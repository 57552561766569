import React, { useRef, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import HTMLBasedCMSPage from "../HTMLBasedCMSPage";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { HTML_BASED_CMS_PAGE_TYPE } from "../../models/cmsBlock";

type Props = RouteComponentProps<{ id: string }>;

const CMSLandingPage: React.FC<Props> = props => {
  const { match } = props;
  const {
    params: { id },
  } = match;

  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const cmsPageType = useMemo<HTML_BASED_CMS_PAGE_TYPE>(
    () => ({
      type: "cmsPage",
      identifier: Number(id),
    }),
    [id]
  );

  return (
    <>
      <NavBar headerLeft={<NavBarBackButton />} />
      <CLContent ref={contentRef}>
        <HTMLBasedCMSPage pageType={cmsPageType} allowRefresh={false} />
        <TabBarSpacePlaceholder />
      </CLContent>
    </>
  );
};

export default CMSLandingPage;
