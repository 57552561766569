import { useApolloClient } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import {
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { profileAsyncAction, profileSyncAction } from "../../utils/performance";
import { BrandIndexPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { getBrands, getBrandSlider } from "./api";
import { useFetchBrandList, useFetchBrandSlider } from "./repository";

function useViewModel() {
  const client = useApolloClient();

  const _brands = useMemo(
    () =>
      profileSyncAction(
        BrandIndexPageSession(),
        "Load Brand List from Cache",
        () => getBrands(client)
      ),
    [client]
  );
  const _brandSlider = useMemo(
    () =>
      profileSyncAction(
        BrandIndexPageSession(),
        "Load Brand Slider from Cache",
        () => getBrandSlider(client)
      ),
    [client]
  );

  const [brands, setBrands] = useState(_brands);
  const [brandSlider, setBrandSlider] = useState(_brandSlider);

  const [fetchBrandsRequestState, , refreshBrands] = useFetchBrandList(
    BrandIndexPageSession()
  );

  const [, , refreshBrandSlider] = useFetchBrandSlider();

  const isFullPageLoading = useMemo(
    () =>
      !brands &&
      (isRequestLoading(fetchBrandsRequestState) ||
        fetchBrandsRequestState.type === "initial"),
    [brands, fetchBrandsRequestState]
  );

  const error = useMemo(() => getRequestStateError(fetchBrandsRequestState), [
    fetchBrandsRequestState,
  ]);

  const refresh = useCallback(async () => {
    const [__brands, __brandSlider] = await profileAsyncAction(
      BrandIndexPageSession(),
      "Load Brand List and Slider from Network",
      () => Promise.all([refreshBrands(), refreshBrandSlider()])
    );
    setBrands(__brands);
    setBrandSlider(__brandSlider);
  }, [refreshBrands, refreshBrandSlider]);

  return {
    brands,
    brandSlider,
    refresh,

    isFullPageLoading,
    error,
  };
}

export default useViewModel;
