import React, { useCallback, useContext, useEffect } from "react";

import { FullContentLoadingView } from "../LoadingView";

import {
  getPathForProductDetailPage,
  useCurrentTab,
} from "../../navigation/routes";

import { HTML_BASED_CMS_PAGE_TYPE } from "../../models/cmsBlock";
import {
  getResources,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { ProductOverview } from "../../models/ProductOverview";

import { useFetchHTMLBasedCMSPageContent } from "../../repository/CMSPageContentRepository";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { WishlistContext } from "../WishlistProvider";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import usePullToRefresh from "../../utils/pullToRefresh";
import HTMLBasedCMSBlocks from "../CMSBlocks/HTMLBasedCMSBlocks";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { ProductDetailPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";

export interface OwnProps {
  pageType: HTML_BASED_CMS_PAGE_TYPE;
  allowRefresh: boolean;
}

type Props = OwnProps;

const HTMLBasedCMSPage: React.FC<Props> = props => {
  const { pageType, allowRefresh } = props;
  const currentTab = useCurrentTab();
  const { isOnline } = useContext(NetworkStatusContext);
  const [
    htmlBasedCMSPageContentResource,
    fetchHTMLBasedCMSPageContent,
    refreshHTMLBasedCMSPageContent,
  ] = useFetchHTMLBasedCMSPageContent();

  const htmlBasedCMSPageContent = getResources(htmlBasedCMSPageContentResource);

  useEffect(() => {
    fetchHTMLBasedCMSPageContent(pageType).catch(() => {});
  }, [pageType, fetchHTMLBasedCMSPageContent]);

  const onRetry = useCallback(() => {
    fetchHTMLBasedCMSPageContent(pageType).catch(() => {});
  }, [pageType, fetchHTMLBasedCMSPageContent]);

  const refresh = useCallback(() => {
    refreshHTMLBasedCMSPageContent(pageType).catch(() => {});
  }, [pageType, refreshHTMLBasedCMSPageContent]);

  const { handleRefresh: onRefresh } = usePullToRefresh(
    htmlBasedCMSPageContentResource,
    refresh
  );

  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(currentTab, productOverview.sku);
    },
    [currentTab]
  );

  const onClickProductBlock = useCallback(
    (productOverview: ProductOverview) => {
      addPerformanceRecord(
        ProductDetailPageSession(productOverview.sku),
        "Click on product block"
      );
    },
    []
  );

  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = React.useCallback(
    (product: ProductOverview) => {
      presentAddToCartModal(product.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );

  const { toggleProductFromWishlist } = useContext(WishlistContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      toggleProductFromWishlist(productOverview.sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );

  const allowRefreshHTMLBasedCMSPage =
    allowRefresh && htmlBasedCMSPageContent != null;

  return (
    <>
      {allowRefreshHTMLBasedCMSPage && (
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent />
        </IonRefresher>
      )}
      <NoInternetConnectionView
        isOnline={isOnline}
        onRetry={onRetry}
        hasData={
          htmlBasedCMSPageContent != null ||
          isRequestLoading(htmlBasedCMSPageContentResource)
        }
      >
        {htmlBasedCMSPageContent == null ? (
          <FullContentLoadingView />
        ) : (
          <HTMLBasedCMSBlocks
            waitingToFillHTML={htmlBasedCMSPageContent.waitingToFillHTML}
            resolvedMatchedCMSBlocks={
              htmlBasedCMSPageContent.resolvedMatchedCMSBlocks
            }
            currentTab={currentTab}
            hrefForProduct={hrefForProduct}
            onAddToCart={openPurchaseProductModal}
            onClickLikeButton={onClickLikeButton}
            onClickProductBlock={onClickProductBlock}
          />
        )}
      </NoInternetConnectionView>
    </>
  );
};

export default HTMLBasedCMSPage;
