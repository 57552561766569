import { useCallback, useContext, useEffect, useMemo } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { useIntl } from "../i18n/Localization";
import {
  getRequestStateError,
  getResources,
  isRequestLoading,
} from "../models/ResourcesRequestState";
import { RemoteAddress } from "../models/Customer";

import { useFetchResources_v2 } from "./Hooks";
import { RepositoryContext } from "./State";

import { fetchCustomerAddresses } from "../api/GraphQL";

export function useFetchCustomerAddresses() {
  const client = useApolloClient();
  const { locale } = useIntl();
  const {
    state: { customerAddresses, defaultShipping, defaultBilling },
    dispatch,
  } = useContext(RepositoryContext);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    {
      addresses: RemoteAddress[];
      defaultShipping: number | null;
      defaultBilling: number | null;
    } | null,
    () => Promise<{
      addresses: RemoteAddress[];
      defaultShipping: number | null;
      defaultBilling: number | null;
    } | null>
  >({
    memoryCacheProvider: () =>
      Promise.resolve({
        addresses: customerAddresses,
        defaultShipping,
        defaultBilling,
      }),
    localCacheProvider: async () => {
      const res = await fetchCustomerAddresses(client, locale, "cache-only");
      if (res) {
        const {
          addresses,
          defaultShipping: _defaultShipping,
          defaultBilling: _defaultBilling,
        } = res;
        dispatch({
          type: "UpdateCustomerAddresses",
          addresses,
          defaultShipping: _defaultShipping,
          defaultBilling: _defaultBilling,
        });
      }
      return res;
    },
    remoteResourcesProvider: async () => {
      const res = await fetchCustomerAddresses(client, locale, "network-only");
      if (res) {
        const {
          addresses,
          defaultShipping: _defaultShipping,
          defaultBilling: _defaultBilling,
        } = res;
        dispatch({
          type: "UpdateCustomerAddresses",
          addresses,
          defaultShipping: _defaultShipping,
          defaultBilling: _defaultBilling,
        });
      }
      return res;
    },
  });

  useEffect(() => {
    fetch().catch(() => {});
  }, [fetch]);

  const retry = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);

  const resources = useMemo(() => getResources(requestState), [requestState]);
  const isLoading = useMemo(() => isRequestLoading(requestState), [
    requestState,
  ]);
  const error = useMemo(() => getRequestStateError(requestState), [
    requestState,
  ]);

  return {
    requestState,
    customerAddresses: resources,
    isLoading,
    error,
    retry,
  };
}
