import { useEffect, useMemo, useState, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { useIntl } from "../i18n/Localization";

import { useFetchResources } from "./Hooks";
import { PaginationInfo } from "./State";
import { MerchantPreview, MerchantID } from "../models/Merchant";

import {
  fetchMerchantDirectory,
  fetchMerchantPreview,
  fetchMerchant,
  getMerchantPreview,
} from "../api/GraphQL";

export function useFetchMerchantDirectories() {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    MerchantID
  > | null>(null);

  const [page, setPage] = useState<number>(
    paginationInfo ? paginationInfo.currentPage : 1
  );
  const [forceFetchKey, setForceFetchKey] = useState(0);

  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () =>
        fetchMerchantDirectory(client, page, locale, "cache-only"),
      didFetchFromLocalCache: result => {
        if (result) {
          const { merchantPreviews, hasMore, pageSize } = result;
          setPaginationInfo(p => {
            const ids = merchantPreviews.map(({ id }) => id);
            if (p === null || page === 1) {
              return {
                items: ids,
                currentPage: page,
                hasMore,
              };
            }
            let items = p.items;
            if (page <= p.currentPage) {
              items = items
                .slice(0, (page - 1) * pageSize)
                .concat(ids)
                .concat(items.slice(page * pageSize));
              return {
                items,
                currentPage: p.currentPage,
                hasMore,
              };
            }
            items = [...items, ...ids];
            return {
              items,
              currentPage: page,
              hasMore,
            };
          });
        }
      },
      remoteResourcesProvider: () =>
        fetchMerchantDirectory(client, page, locale, "network-only"),
      didFetchFromRemote: result => {
        if (result) {
          const { merchantPreviews, hasMore, pageSize } = result;
          setPaginationInfo(p => {
            const ids = merchantPreviews.map(({ id }) => id);
            if (p === null || page === 1) {
              return {
                items: ids,
                currentPage: page,
                hasMore,
              };
            }
            let items = p.items;
            if (page <= p.currentPage) {
              items = items
                .slice(0, (page - 1) * pageSize)
                .concat(ids)
                .concat(items.slice(page * pageSize));
              return {
                items,
                currentPage: p.currentPage,
                hasMore,
              };
            }
            items = [...items, ...ids];
            return {
              items,
              currentPage: page,
              hasMore,
            };
          });
        }
      },
    },
    [locale, page, forceFetchKey]
  );

  useEffect(() => {
    if (page) {
      startRequesting();
    }
  }, [page, startRequesting]);

  const fetchNext = useCallback(() => {
    if (paginationInfo) {
      setPage(paginationInfo.currentPage + 1);
      setForceFetchKey(k => k + 1);
    }
  }, [paginationInfo, setPage]);

  const fetchPageOne = useCallback(() => {
    if (paginationInfo && paginationInfo.currentPage === 1) {
      startRequesting();
    } else {
      setPage(1);
      setForceFetchKey(k => k + 1);
    }
  }, [paginationInfo, startRequesting, setPage]);

  return { requestState, fetchNext, fetchPageOne, paginationInfo };
}

export function useFetchMerchantPreview(merchantID: MerchantID) {
  const client = useApolloClient();
  const { locale } = useIntl();

  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () =>
        fetchMerchantPreview(client, merchantID, locale, "cache-only"),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () =>
        fetchMerchantPreview(client, merchantID, locale, "network-only"),
      didFetchFromRemote: () => {},
    },
    [locale]
  );

  useEffect(() => {
    startRequesting();
  }, [startRequesting]);

  return { requestState };
}

export function useGetMerchantPreviewsByIDs(
  merchantIDs: MerchantID[]
): MerchantPreview[] {
  const client = useApolloClient();
  const merchantPreviews: MerchantPreview[] = useMemo(() => {
    const res: MerchantPreview[] = [];
    for (const merchantID of merchantIDs) {
      const merchantPreview = getMerchantPreview(client, merchantID);
      if (merchantPreview) {
        res.push(merchantPreview);
      }
    }
    return res;
  }, [client, merchantIDs]);
  return merchantPreviews;
}

export function useFetchMerchantDetails(merchantID: MerchantID) {
  const client = useApolloClient();
  const { locale } = useIntl();

  const { requestState, startRequesting } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => null,
      localCacheProvider: () =>
        fetchMerchant(client, merchantID, locale, "cache-only"),
      didFetchFromLocalCache: () => {},
      remoteResourcesProvider: () =>
        fetchMerchant(client, merchantID, locale, "network-only"),
      didFetchFromRemote: () => {},
    },
    [locale]
  );

  useEffect(() => {
    startRequesting();
  }, [startRequesting]);

  return { requestState };
}
