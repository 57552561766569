import { ProductOverview } from "./ProductOverview";

export interface WishlistItem {
  id: number;
  product: ProductOverview;
}

export function WishlistItemGraphQLAttribtues(productGraphQLAttribute: string) {
  return `
  id
  product {
    ${productGraphQLAttribute}
  }
`;
}
