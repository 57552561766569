import React, { useContext, useEffect } from "react";
import cn from "classnames";

import { NotificationEnableState } from "../../models/OPNSPushNotification";

import CLContent from "../CLContent";
import { NavBar, NavBarBackButton } from "../NavBar";
import { LocalizedText } from "../../i18n/Localization";
import Toggle from "../Toggle";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { PushNotificationContext } from "../PushNotificationProvider";
import { LoadingModalContext } from "../LoadingModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import { MessageID } from "../../i18n/translations/type";
import { useCustomer } from "../../repository/AuthRepository";
import {
  useTogglePromotionNotification,
  useToggleOrderNotification,
} from "../../repository/PushNotificationRepository";
import { isRemoteError, isLocalError } from "../../utils/Error";

import styles from "./styles.module.scss";
import { MessageArgs } from "../../i18n/LocalizedText";

const NotificationSettingsPage: React.FC = () => {
  const { isOnline } = useContext(NetworkStatusContext);
  const { show: showLoading, hide: hideLoading } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const customer = useCustomer();

  const { enablePushNotification, toggleEnablePushNotification } = useContext(
    PushNotificationContext
  );

  const [
    promotionNotification,
    togglePromotionNotification,
    setRemotePromotionNotificationLoading,
    setRemotePromotionNotificationError,
  ] = useTogglePromotionNotification();

  const [
    orderNotification,
    toggleOrderNotification,
    setRemoteOrderNotificationLoading,
    setRemoteOrderNotificationError,
  ] = useToggleOrderNotification();

  useEffect(() => {
    if (setRemotePromotionNotificationLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [setRemotePromotionNotificationLoading, showLoading, hideLoading]);

  useEffect(() => {
    if (setRemoteOrderNotificationLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [setRemoteOrderNotificationLoading, showLoading, hideLoading]);

  useEffect(() => {
    if (setRemotePromotionNotificationError) {
      if (isRemoteError(setRemotePromotionNotificationError)) {
        const { message } = setRemotePromotionNotificationError;
        presentLocalizedAlert({
          message,
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
      } else if (isLocalError(setRemotePromotionNotificationError)) {
        const { messageID, messageArgs } = setRemotePromotionNotificationError;
        presentLocalizedAlert({
          messageId: messageID,
          messageArgs: messageArgs,
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
      }
    }
  }, [presentLocalizedAlert, setRemotePromotionNotificationError]);

  useEffect(() => {
    if (setRemoteOrderNotificationError) {
      if (isRemoteError(setRemoteOrderNotificationError)) {
        const { message } = setRemoteOrderNotificationError;
        presentLocalizedAlert({
          message,
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
      } else if (isLocalError(setRemoteOrderNotificationError)) {
        const { messageID, messageArgs } = setRemoteOrderNotificationError;
        presentLocalizedAlert({
          messageId: messageID,
          messageArgs: messageArgs,
          buttons: [
            {
              textMessageID: "alert.button.ok",
            },
          ],
        });
      }
    }
  }, [presentLocalizedAlert, setRemoteOrderNotificationError]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="page.notification_settings.title" />
        }
      />
      <CLContent className={styles.ionContent}>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          <SettingRow
            titleMessageID="page.notification_settings.enable_notification"
            checked={enablePushNotification}
            onCheck={toggleEnablePushNotification}
            className={styles.enableNotificationRow}
            titleClassName={styles.enableNotification}
          />
          {customer != null && enablePushNotification ? (
            <>
              <SettingRow
                titleMessageID="page.notification_settings.promotion_notification"
                checked={promotionNotification === NotificationEnableState.Yes}
                disabled={!enablePushNotification}
                onCheck={togglePromotionNotification}
              />
              <SettingRow
                titleMessageID="page.notification_settings.order_notification"
                checked={orderNotification === NotificationEnableState.Yes}
                disabled={!enablePushNotification}
                onCheck={toggleOrderNotification}
              />
            </>
          ) : null}
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default NotificationSettingsPage;

const SettingRow: React.FC<{
  titleMessageID: MessageID;
  titleMessageArgs?: MessageArgs;
  checked: boolean;
  onCheck: (checked: boolean) => void;
  className?: string;
  titleClassName?: string;
  disabled?: boolean;
}> = props => {
  const {
    titleMessageID,
    titleMessageArgs,
    checked,
    onCheck,
    className,
    titleClassName,
    disabled,
  } = props;
  return (
    <div className={cn(styles.settingRow, className)}>
      <div className={cn(styles.settingName, titleClassName)}>
        <LocalizedText
          messageID={titleMessageID}
          messageArgs={titleMessageArgs}
        />
      </div>
      <Toggle checked={checked} onCheck={onCheck} disabled={disabled} />
    </div>
  );
};
