import {
  CountryID,
  CountryCode,
  RegionID,
  RegionCode,
  DistrictName,
} from "../../models/CountryRegion";
import { CartPrices, CartItem } from "../../models/cart";
import {
  ProductDeliveryMethod,
  ProductType,
  ProductConfigurableOption,
  ProductConfigurableAttributeOption,
} from "../../models/product";
import { resolveConfiguredProductBySelectedConfiguration } from "../../models/ProductConfigurationDependency";
import { Override } from "../../utils/type";

export interface ShippingMethod {
  methodCode: string;
  carrierCode: string;
}

export interface CartAddress {
  firstname: string | null;
  lastname: string | null;
  company: string | null;
  telephone: string | null;
  city: DistrictName | null;
  street: string[];
  region: {
    code: RegionCode | null;
  };
  country: {
    code: CountryCode | null;
  };
}

export interface ShippingCartAddress extends CartAddress {
  availableShippingMethods: ShippingMethod[];
}

export interface AddressInput {
  city: DistrictName;
  company: string;
  countryCode: CountryCode;
  firstname: string;
  lastname: string;
  region: RegionCode;
  street: string[];
  telephone: string;
}

export type CartAddressInput = AddressInput & {
  saveInAddressBook: boolean;
};

export type DeliveryEndpointType = "address" | "o2o-store";

export interface DeliveryEndpoint {
  type: DeliveryEndpointType;
}

export interface DeliveryEndpointParameters {
  o2oStoreCode?: string;
}

export type DeliveryTimeSlotValue = string;

export interface DeliveryTimeSlot {
  label: string;
  value: DeliveryTimeSlotValue;
  valueText: string;
}

export type PaymentType =
  | "checkmo"
  | "asiapay"
  | "visa"
  | "master"
  | "chinapay"
  | "mpospay"
  | "opppayment"
  | "free"
  | "applepay"
  | "alipayhk"
  | "googlepay"
  | "installment";

export interface PaymentMethod {
  code: PaymentType;
}

type O2oStoreType = "o2o-store" | "elocker" | "o2o-fulfillment";
type LockerProvider =
  | "hkt-shop"
  | "csl-shop"
  | "1010-center"
  | "4px"
  | "alfred";
type UnknownLockerProvider = string;

export interface O2oStore {
  country: {
    id: CountryID;
    code: CountryCode;
  };
  region: {
    code: string;
    id: RegionID;
    name: string;
  };
  city: string;
  storeType: O2oStoreType;
  lockerProvider: LockerProvider | UnknownLockerProvider;
  name: string;
  street: string;
  building: string;
  block: string;
  floor: string;
  flat: string;
  code: string;
  openingHours: string | null;
}

export function findO2oStore(
  o2oStores: O2oStore[],
  region: string,
  city: string,
  storeType: string,
  code: string
): O2oStore | null {
  const selectedStores = o2oStores.filter(
    x =>
      `${x.region.id}` === region &&
      x.city === city &&
      (x.storeType === storeType || storeType === "all") &&
      x.code === code
  );
  if (selectedStores.length > 0) {
    return selectedStores[0];
  }
  return null;
}

export interface Coupon {
  code: string;
}

export interface Product {
  id: number;
  type: ProductType;
  deliveryMethod: ProductDeliveryMethod | null;
  minClubPoint: number;
  name: string;
  configurableOptions?: ProductConfigurableOption[] | null;
  variants?: ProductVariant[] | null;
}

export interface ProductVariant {
  product: ConfiguredProduct;
  attributes: ProductConfigurableAttributeOption[];
}

export interface ConfiguredProduct {
  id: number;
  entityId: number;
  name: string;
  deliveryMethod: ProductDeliveryMethod | null;
  type: ProductType;
  minClubPoint: number;
}

export interface PartialCart {
  items: Override<
    Pick<CartItem, "id" | "product" | "quantity" | "configurableOptions">,
    {
      product: Product;
    }
  >[];
  clubpointsToBeEarned: number;
  clubPointToBeUsed: number;
  clubPointRequired?: number | null;
  prices: CartPrices;
  shippingAddresses: ShippingCartAddress[];
  billingAddress: CartAddress | null;
  availableDeliveryEndpoints: DeliveryEndpoint[];
  availableDeliveryTimeSlots: DeliveryTimeSlot[];
  availablePaymentMethods: PaymentMethod[];
  availableO2oStores: O2oStore[];
  selectedDeliveryTimeSlot: DeliveryTimeSlotValue;
  selectedPaymentMethod: PaymentMethod | null;
  appliedCoupon?: Coupon | null;
  appliedCoupons?: Coupon[] | null;
}

export function getAppliedCoupon(
  cart: Pick<PartialCart, "appliedCoupon" | "appliedCoupons">
) {
  const { appliedCoupon, appliedCoupons } = cart;
  if (appliedCoupon != null) {
    return appliedCoupon.code;
  }
  if (appliedCoupons != null && appliedCoupons.length) {
    return appliedCoupons[0].code;
  }
  return null;
}

export function areAllItemsVirtual(cart: PartialCart): boolean {
  for (const item of cart.items) {
    const { configurableOptions } = item;
    let product: Product | ConfiguredProduct = item.product;
    const { variants } = item.product;
    if (
      item.product.type === "configurable" &&
      configurableOptions &&
      variants
    ) {
      const configuredProduct = resolveConfiguredProductBySelectedConfiguration<
        ConfiguredProduct,
        ProductVariant
      >(variants, configurableOptions.map(x => x.valueId));
      if (configuredProduct) {
        product = configuredProduct;
      }
    }
    if (product.type !== "virtual") {
      return false;
    }
  }
  return true;
}

export function isCartAddressEmpty(address: CartAddress): boolean {
  const {
    firstname,
    lastname,
    company,
    telephone,
    city,
    street,
    region,
    country,
  } = address;
  const areSimpleFieldsNotEmpty =
    (firstname && firstname.trim()) ||
    (lastname && lastname.trim()) ||
    (company && company.trim() && telephone && telephone.trim()) ||
    (city && city.trim()) ||
    (region.code && region.code.trim()) ||
    (country.code && country.code.trim());
  let isStreetEmpty = true;
  for (const line of street) {
    if (line && line.trim()) {
      isStreetEmpty = false;
    }
  }

  return !areSimpleFieldsNotEmpty && isStreetEmpty;
}

export function getDefaultShippingMethod(cart: PartialCart) {
  if (
    cart.shippingAddresses.length &&
    cart.shippingAddresses[0].availableShippingMethods.length
  ) {
    return cart.shippingAddresses[0].availableShippingMethods[0];
  }
  return null;
}
