import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useContext,
  useState,
} from "react";
import { IonButtons, IonButton } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Plugins } from "@capacitor/core";
import cn from "classnames";

import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { WishlistContext } from "../WishlistProvider";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";

import AppliedFiltersList from "../AppliedFiltersList";
import CLContent from "../CLContent";
import { NavBar, NavBarBackButton } from "../NavBar";
import { FullContentLoadingView } from "../LoadingView";
import ProductList from "../ProductList";
import SearchEmptyView from "./SearchEmptyView";
import SearchInput from "./SearchInput";
import SearchSuggestion from "./SearchSuggestion";
import { SearchSuggestionListItemItem } from "./SearchSuggestionList";
import {
  TabBarSpacePlaceholder,
  TarBarHeightContext,
} from "../navigation/TabBar";
import FloatingButtons from "../FloatingButtons";
import FilterButton from "../FilterButton";
import BackToTopButton from "../BackToTopButton";

import { ProductOverview } from "../../models/ProductOverview";
import {
  ProductFilterInfo,
  hasFilters,
  FilterAttribute,
  RangeFilterAttribute,
} from "../../models/filter";
import { SearchTerm, SearchAutoSuggestion } from "../../models/Search";
import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import {
  isRequestLoading,
  getResources,
  getRequestStateError,
} from "../../models/ResourcesRequestState";

import {
  useAddRecentSearchTerm,
  useSearchSuggestion,
} from "../../repository/SearchRepository";

import { ProfileSessionProvider } from "../../contexts/ProfileSessionContext";

import {
  useCurrentTab,
  getPathForTab,
  getPathForProductDetailPage,
  getPathForSearch,
  presentFilterModal,
} from "../../navigation/routes";

import { withProviders } from "../Provider";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useHandleBackToTopClick from "../../utils/handleBackToTopClick";
import { useIsContentScrolledToTop } from "../../utils/IonContent";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import {
  appEventEmitter,
  AppEventLinkToBrand,
  AppEventOnClickViewMore,
} from "../../utils/SimpleEventEmitter";
import { clDecodePercent, isEmpty } from "../../utils/String";
import { useDebounce } from "../../hook/utils";
import { usePresentLocalizedAlertForRequestStateError } from "../../hook/ResourcesRequestHook";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";

import { LocalizedText } from "../../i18n/Localization";

import { OurNavContext, PresentationContext } from "../../our-navigation";
import { isHybrid } from "../../utils/Platform";
import {
  addPerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";
import { SearchPageSession } from "../../utils/PerformanceRecordStore/sessions";

import RerenderLogger from "../Performance/RerenderLogger";

import useViewModel from "./SearchPageViewModel";

import styles from "./SearchPage.module.scss";
import floatingButtonStyles from "../FloatingButtons/FloatingButton.module.scss";
import { parseQueryParameters } from "../../utils/Url";
import { useViewDidEnterOnce } from "../../utils/View";

type Mode = "product" | "search-suggestion";

const SearchPageImpl: React.FC = () => {
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isTabBarHidden = useScrollToHideTabBar(
    ionContentRef,
    ionLifeCycleContext
  );
  const tabBarHeight = useContext(TarBarHeightContext);
  const { navigate } = useContext(OurNavContext);

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ searchTerm: string }>();
  const [search] = useState(location.search);
  const { searchTerm: __searchTerm } = match.params;
  const searchTerm = useMemo(
    () => clDecodePercent(decodeURIComponent(__searchTerm)),
    [__searchTerm]
  );
  const currentTab = useCurrentTab();

  const [searchSuggestionIsLoading, setSearchSuggestionIsLoading] = useState(
    false
  );

  const queryParams = useMemo(() => {
    const res = parseQueryParameters(search);
    const cat = res.cat;
    if (cat != null) {
      res.category_id = cat;
    }
    return res;
  }, [search]);

  const viewModel = useViewModel(searchTerm, queryParams);

  const {
    sortFields,
    productFilterInfo,
    applyProductFilterInfo,
    clearFilter,
    clearRangeFilter,
    clearPredefinedCPFilter,
    clearAllFilter,

    isFullPageLoading,

    searchProductOverviewsRequestState,
    fetchNextProductOverviews,
    refresh: refreshViewModel,
    productLabelsByProductIdMap: productLabelsByProductId,
    bundleByProductIdMap,
    productOverviews,
    isProductListLoadingMore,
  } = viewModel;

  useViewDidEnterOnce(ionLifeCycleContext, refreshViewModel);

  const { sortFieldOptions: sortRows } = sortFields;

  const [mode, setMode] = useState<Mode>("product");
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);

  const debouncedSearchTerm = useDebounce(currentSearchTerm, 250);
  const searchSuggestionRequestState = useSearchSuggestion(debouncedSearchTerm);

  const onSearchTermChange = useCallback((value: string) => {
    setCurrentSearchTerm(value);
    setSearchSuggestionIsLoading(!isEmpty(value));
  }, []);

  const prevSearchSuggestionRequestStateIsLoading = useRef(
    isRequestLoading(searchSuggestionRequestState)
  );

  useEffect(() => {
    const requestStateIsLoading = isRequestLoading(
      searchSuggestionRequestState
    );

    if (
      prevSearchSuggestionRequestStateIsLoading.current &&
      !requestStateIsLoading
    ) {
      setSearchSuggestionIsLoading(false);
    }

    prevSearchSuggestionRequestStateIsLoading.current = requestStateIsLoading;
  }, [searchSuggestionRequestState]);

  const onClearTextClick = useCallback(() => {
    setMode("search-suggestion");
    setCurrentSearchTerm("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const fetchNext = useCallback(async () => {
    fetchNextProductOverviews();
  }, [fetchNextProductOverviews]);

  const refresh = useCallback(async () => {
    await refreshViewModel();
  }, [refreshViewModel]);

  usePresentLocalizedAlertForRequestStateError(
    searchProductOverviewsRequestState
  );

  const addRecentSearchTerm = useAddRecentSearchTerm();

  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(currentTab, productOverview.sku);
    },
    [currentTab]
  );
  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = useCallback(
    (product: ProductOverview) => {
      presentAddToCartModal(product.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );

  const { toggleProductFromWishlist } = useContext(WishlistContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      toggleProductFromWishlist(productOverview.sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );

  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );
  const onProductListRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh]
  );

  const onProductListEndReach = useCallback(async () => {
    try {
      await fetchNext();
    } catch {}
  }, [fetchNext]);

  const scrollToTop = useCallback(() => {
    const ionContent = ionContentRef.current;
    if (ionContent) {
      ionContent.scrollToPoint(undefined, 0);
    }
  }, []);

  const onClickClearAllButton = useCallback(async () => {
    scrollToTop();
    showLoadingModal();
    try {
      await clearAllFilter();
    } finally {
      hideLoadingModal();
    }
  }, [scrollToTop, clearAllFilter, showLoadingModal, hideLoadingModal]);

  const onClickClearFilterButton = useCallback(
    async (appliedFilter: FilterAttribute) => {
      scrollToTop();
      showLoadingModal();
      try {
        await clearFilter(appliedFilter);
      } finally {
        hideLoadingModal();
      }
    },
    [scrollToTop, clearFilter, showLoadingModal, hideLoadingModal]
  );

  const onClickClearRangeFilterButton = useCallback(
    async (appliedRangeFilter: RangeFilterAttribute) => {
      scrollToTop();
      showLoadingModal();
      try {
        await clearRangeFilter(appliedRangeFilter);
      } finally {
        hideLoadingModal();
      }
    },
    [clearRangeFilter, scrollToTop, showLoadingModal, hideLoadingModal]
  );

  const onClickClearPredefinedCPFilterButton = useCallback(async () => {
    scrollToTop();
    showLoadingModal();
    try {
      await clearPredefinedCPFilter();
    } finally {
      hideLoadingModal();
    }
  }, [
    clearPredefinedCPFilter,
    scrollToTop,
    showLoadingModal,
    hideLoadingModal,
  ]);

  const onApplyFilters = useCallback(
    async (_productFilterInfo: ProductFilterInfo) => {
      scrollToTop();
      showLoadingModal();
      try {
        await applyProductFilterInfo(_productFilterInfo);
      } finally {
        hideLoadingModal();
      }
    },
    [scrollToTop, applyProductFilterInfo, showLoadingModal, hideLoadingModal]
  );

  const { present } = useContext(PresentationContext);
  const handleFilterClick = useCallback(() => {
    if (productFilterInfo) {
      presentFilterModal(present, {
        initialProductFilterInfo: productFilterInfo,
        onApplyFilters,
        sortRows,
        searchTerm,
        initialGraphQLFilter: {},
      });
    }
  }, [present, productFilterInfo, onApplyFilters, searchTerm, sortRows]);

  const handleBackToTopClick = useHandleBackToTopClick(ionContentRef, 200);

  const onClickBackToStorefrontButton = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      history.replace(getPathForTab(currentTab));
    },
    [history, currentTab]
  );

  const {
    isContentScrolledToTop,
    handleIonScroll,
  } = useIsContentScrolledToTop();

  const onSearchInputFocus = useCallback(() => {
    setMode("search-suggestion");
  }, []);

  const onClickCancelButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setMode("product");
      setCurrentSearchTerm(searchTerm);
    },
    [searchTerm]
  );

  const applySearch = useCallback(
    (_searchTerm: SearchTerm) => {
      if (isEmpty(_searchTerm)) {
        return;
      }
      if (isHybrid()) {
        Plugins.Keyboard.hide();
      }
      if (_searchTerm === searchTerm) {
        setMode("product");
        return;
      }
      addPerformanceRecord(SearchPageSession(_searchTerm), "Submit search");
      navigate(getPathForSearch(currentTab, _searchTerm));
      addRecentSearchTerm(_searchTerm);
    },
    [navigate, currentTab, addRecentSearchTerm, searchTerm]
  );

  const handlePopularSearchOnSearchSubmit = useCallback(
    (popularSearches: SearchAutoSuggestion[]) => {
      if (popularSearches.length === 0 || popularSearches.length >= 2) {
        applySearch(currentSearchTerm);
        return;
      }
      const [theOnlyPopularSearch] = popularSearches;
      const { redirectUrl } = theOnlyPopularSearch;
      if (redirectUrl) {
        appEventEmitter.publish(AppEventOnClickViewMore(redirectUrl));
        return;
      }
      applySearch(currentSearchTerm);
    },
    [applySearch, currentSearchTerm]
  );

  const searchSubmitActionPerformedRef = useRef(false);
  const searchSubmitAction = useCallback(() => {
    if (isEmpty(currentSearchTerm)) {
      return;
    }
    if (currentSearchTerm === searchTerm) {
      setMode("product");
      return;
    }
    if (searchSubmitActionPerformedRef.current === true) {
      return;
    }
    if (
      searchSuggestionIsLoading ||
      isRequestLoading(searchSuggestionRequestState)
    ) {
      showLoadingModal();
      searchSubmitActionPerformedRef.current = true;
      return;
    }
    const searchSuggestionError = getRequestStateError(
      searchSuggestionRequestState
    );
    if (searchSuggestionError) {
      return;
    }
    const searchSuggestion = getResources(searchSuggestionRequestState);
    if (searchSuggestion) {
      const { popularSearches } = searchSuggestion;
      handlePopularSearchOnSearchSubmit(popularSearches);
    }
    applySearch(currentSearchTerm);
  }, [
    searchSuggestionIsLoading,
    searchSuggestionRequestState,
    showLoadingModal,
    handlePopularSearchOnSearchSubmit,
    applySearch,
    currentSearchTerm,
    searchTerm,
  ]);

  useEffect(() => {
    if (searchSubmitActionPerformedRef.current === false) {
      return;
    }
    if (isRequestLoading(searchSuggestionRequestState)) {
      return;
    }
    hideLoadingModal();
    if (currentSearchTerm === searchTerm) {
      setMode("product");
      return;
    }
    const searchSuggestionError = getRequestStateError(
      searchSuggestionRequestState
    );
    if (searchSuggestionError) {
      searchSubmitActionPerformedRef.current = false;
      return;
    }
    const searchSuggestion = getResources(searchSuggestionRequestState);
    if (searchSuggestion) {
      searchSubmitActionPerformedRef.current = false;
      const { popularSearches } = searchSuggestion;
      handlePopularSearchOnSearchSubmit(popularSearches);
      return;
    }
    applySearch(currentSearchTerm);
  }, [
    searchSuggestionRequestState,
    searchTerm,
    currentSearchTerm,
    hideLoadingModal,
    applySearch,
    handlePopularSearchOnSearchSubmit,
  ]);

  const onSearchSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      searchSubmitAction();
    },
    [searchSubmitAction]
  );

  const onClickSearchSuggestion = useCallback(
    (searchSuggestion: SearchAutoSuggestion) => {
      if (searchSuggestion.redirectUrl == null) {
        applySearch(searchSuggestion.name);
      } else {
        appEventEmitter.publish(
          AppEventOnClickViewMore(searchSuggestion.redirectUrl)
        );
      }
    },
    [applySearch]
  );

  const onClickProductSearchesListItem = useCallback(
    (item: SearchSuggestionListItemItem) => {
      if (item.type === "product") {
        navigate(getPathForProductDetailPage(currentTab, item.product.sku));
      } else if (item.type === "total-count") {
        applySearch(currentSearchTerm);
      }
    },
    [currentTab, navigate, applySearch, currentSearchTerm]
  );

  const onClickFilteredListItem = useCallback(
    (item: SearchSuggestionListItemItem) => {
      if (item.type === "filtered-listing") {
        const { searchSuggestion } = item;
        if (searchSuggestion.url != null) {
          appEventEmitter.publish(
            AppEventOnClickViewMore(searchSuggestion.url)
          );
        } else if (searchSuggestion.redirectUrl != null) {
          appEventEmitter.publish(
            AppEventOnClickViewMore(searchSuggestion.redirectUrl)
          );
        } else {
          applySearch(searchSuggestion.name);
        }
      }
    },
    [applySearch]
  );

  const onClickBrandItem = useCallback((item: SearchSuggestionListItemItem) => {
    if (item.type === "filtered-listing") {
      appEventEmitter.publish(AppEventLinkToBrand(item.searchSuggestion.url));
    }
  }, []);

  const handleRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          SearchPageSession(searchTerm),
          "Search Result Page Mount",
          startTime
        );
        return;
      }
      addPerformanceRecord(
        SearchPageSession(searchTerm),
        RERENDER_EVENT,
        startTime,
        startTime + actualDuration
      );
    },
    [searchTerm]
  );

  const handleProductListRender: React.ProfilerOnRenderCallback = useCallback(
    (_id, phrase, _actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          SearchPageSession(searchTerm),
          "Product List Mount",
          startTime
        );
      }
    },
    [searchTerm]
  );

  return (
    <div className={cn("ion-page", styles.container)}>
      <RerenderLogger id="Search Page" onRender={handleRender}>
        <NavBar
          headerLeft={<NavBarBackButton />}
          custom={
            <>
              <form
                className={styles.searchInputContainer}
                action="/"
                onSubmit={onSearchSubmit}
              >
                <SearchInput
                  value={currentSearchTerm}
                  onTextChange={onSearchTermChange}
                  onClearTextClick={onClearTextClick}
                  onFocus={onSearchInputFocus}
                  ref={inputRef}
                />
              </form>
              {mode === "search-suggestion" && (
                <IonButtons slot="primary">
                  <IonButton
                    className={styles.navBarCancelButton}
                    mode="ios"
                    onClick={onClickCancelButton}
                  >
                    <div className={styles.navBarCancelButtonText}>
                      <LocalizedText messageID="page.search_suggestion.navbar.button.cancel" />
                    </div>
                  </IonButton>
                </IonButtons>
              )}
            </>
          }
        />
        {mode === "product" &&
          productFilterInfo != null &&
          hasFilters(productFilterInfo) && (
            <div className={styles.appliedFiltersListContainer}>
              <AppliedFiltersList
                productFilterInfo={productFilterInfo}
                onClickClearAllButton={onClickClearAllButton}
                onClickClearButton={onClickClearFilterButton}
                onClickClearRangeButton={onClickClearRangeFilterButton}
                onClickClearPredefinedCPFilterButton={
                  onClickClearPredefinedCPFilterButton
                }
              />
            </div>
          )}
        <CLContent
          ref={ionContentRef}
          className={styles.content}
          onIonScroll={handleIonScroll}
        >
          {mode === "product" ? (
            <>
              {isFullPageLoading ? (
                <FullContentLoadingView />
              ) : (
                <RerenderLogger
                  id="Search Page Product List"
                  onRender={handleProductListRender}
                >
                  <ProductList
                    ionContentRef={ionContentRef}
                    productOverviews={productOverviews}
                    productLabelsByProductId={productLabelsByProductId}
                    bundleByProductId={bundleByProductIdMap}
                    isLoadMoreLoading={isProductListLoadingMore}
                    hrefForProduct={hrefForProduct}
                    onAddToCart={openPurchaseProductModal}
                    onClickLikeButton={onClickLikeButton}
                    onRefresh={onProductListRefresh}
                    onEndReach={onProductListEndReach}
                    footer={
                      productOverviews.length === 0 ? (
                        <div className={styles.emptyContainer}>
                          <SearchEmptyView
                            searchTerm={currentSearchTerm}
                            onClickBackToStorefrontButton={
                              onClickBackToStorefrontButton
                            }
                          />
                        </div>
                      ) : null
                    }
                  />
                </RerenderLogger>
              )}
              <TabBarSpacePlaceholder />
            </>
          ) : mode === "search-suggestion" ? (
            <SearchSuggestion
              isLoading={searchSuggestionIsLoading}
              searchTerm={currentSearchTerm}
              searchSuggestionRequestState={searchSuggestionRequestState}
              onClickSearchTerm={applySearch}
              onClickSearchSuggestion={onClickSearchSuggestion}
              onClickProductSearchesListItem={onClickProductSearchesListItem}
              onClickFilteredListItem={onClickFilteredListItem}
              onClickBrandItem={onClickBrandItem}
              onClickBackToStorefrontButton={onClickBackToStorefrontButton}
            />
          ) : null}
        </CLContent>
        {mode === "product" && productOverviews.length !== 0 ? (
          <FloatingButtons offset={isTabBarHidden ? 0 : tabBarHeight}>
            <FilterButton onClick={handleFilterClick} />
            <BackToTopButton
              onClick={handleBackToTopClick}
              className={cn(floatingButtonStyles.hidable, {
                [floatingButtonStyles.hidden]: isContentScrolledToTop,
              })}
            />
          </FloatingButtons>
        ) : null}
      </RerenderLogger>
    </div>
  );
};

const SearchPage: React.FC = () => {
  const match = useRouteMatch<{ searchTerm: string }>();
  const [searchTerm] = useState(match.params.searchTerm);

  return (
    <ProfileSessionProvider profileSession={SearchPageSession(searchTerm)}>
      <SearchPageImpl />
    </ProfileSessionProvider>
  );
};

export default withProviders(SearchPage, LoadingModalProvider);
