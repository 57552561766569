import React, { useState } from "react";
// eslint-disable-next-line
import { IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from "@ionic/react";

import { ClientMsg } from "../../models/VirtualWaitingRoom";

import CLContent from "../CLContent";

import styles from "./WaitingView.module.scss";

import virtualWaitingRoomGif from "../../resources/virtual-waiting-room.gif";

interface WaitingViewProps {
  clientMsg?: ClientMsg | null;
  waitingPos: number | undefined;
  waitingTotal: number | undefined;
}

const WaitingView: React.FC<WaitingViewProps> = props => {
  const { clientMsg, waitingPos } = props;

  const [waitingTotal] = useState(props.waitingTotal);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonTitle>
            <div className={styles.logo} />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <CLContent className={styles.content}>
        <div className={styles.waitingView}>
          <IonImg src={virtualWaitingRoomGif} alt="" />
          <div className={styles.body}>
            {waitingPos != null && waitingTotal != null ? (
              <div className={styles.waitingPosition}>
                <div className={styles.waitingPositionTextWrap}>
                  <div className={styles.waitingPositionText}>
                    <div className={styles.waitingPositionTextZhHant}>
                      在你前面的用戶
                    </div>
                    <div className={styles.waitingPositionTextEn}>
                      Visitors waiting before you
                    </div>
                  </div>
                  <div className={styles.waitingPositionNumber}>
                    {waitingPos}
                  </div>
                </div>
                <div className={styles.waitingPositionBar}>
                  <div
                    className={styles.waitingPositionCurrentBar}
                    style={{
                      width: `${((waitingTotal - waitingPos) / waitingTotal) *
                        100}%`,
                    }}
                  />
                </div>
              </div>
            ) : null}
            {clientMsg &&
            (clientMsg.default || clientMsg.zh || clientMsg.en) ? (
              <div className={styles.clientMsg}>
                {clientMsg.zh ? (
                  <div className={styles.clientMsgP}>{clientMsg.zh}</div>
                ) : null}
                {clientMsg.en ? (
                  <div className={styles.clientMsgP}>{clientMsg.en}</div>
                ) : null}
                {clientMsg.default && (!clientMsg.en && !clientMsg.zh) ? (
                  <div className={styles.clientMsgP}>{clientMsg.default}</div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </CLContent>
    </IonPage>
  );
};

export default WaitingView;
