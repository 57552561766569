import { useApolloClient } from "@apollo/react-hooks";

import {
  CheckinResponse,
  RequestVirtualWaitingRoomTokenResponse,
  ServerVirtualWaitingRoomConfig,
} from "../models/VirtualWaitingRoom";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import {
  virtualWaitingRoomAPIClient,
  requestVirtualWaitingRoomToken,
  checkVirtualWaitingRoomPosition,
} from "../api/RESTful";
import { fetchVirtualWaitingRoomConfig } from "../api/GraphQL";

import { useFetchResources_v2 } from "./Hooks";

export function useFetchVirtualWaitingRoomConfig(): [
  ResourcesRequestState<ServerVirtualWaitingRoomConfig>,
  () => Promise<ServerVirtualWaitingRoomConfig>
] {
  const client = useApolloClient();
  const [requestState, { call: fetch }] = useFetchResources_v2<
    ServerVirtualWaitingRoomConfig,
    () => Promise<ServerVirtualWaitingRoomConfig>
  >({
    remoteResourcesProvider: async () => {
      const config = await fetchVirtualWaitingRoomConfig(client);
      if (!config) {
        throw new Error("Virtual waiting room is not supported");
      }
      return config;
    },
  });

  return [requestState, fetch];
}

export function useRequestVirtualWaitingRoomToken(
  appid: string,
  queue: string
): [
  ResourcesRequestState<RequestVirtualWaitingRoomTokenResponse>,
  () => Promise<RequestVirtualWaitingRoomTokenResponse>
] {
  const [requestState, { call: fetch }] = useFetchResources_v2<
    RequestVirtualWaitingRoomTokenResponse,
    () => Promise<RequestVirtualWaitingRoomTokenResponse>
  >({
    remoteResourcesProvider: async () => {
      if (virtualWaitingRoomAPIClient) {
        return requestVirtualWaitingRoomToken(
          virtualWaitingRoomAPIClient,
          appid,
          queue
        );
      }
      throw new Error("Virtual waiting room is not supported");
    },
  });

  return [requestState, fetch];
}

export function useCheckVirtualWaitingRoomPosition(
  appid: string,
  queue: string
): [
  ResourcesRequestState<CheckinResponse>,
  (tokenid: string) => Promise<CheckinResponse>
] {
  const [requestState, { call: fetch }] = useFetchResources_v2<
    CheckinResponse,
    (tokenid: string) => Promise<CheckinResponse>
  >({
    remoteResourcesProvider: async (tokenid: string) => {
      if (virtualWaitingRoomAPIClient) {
        return checkVirtualWaitingRoomPosition(
          virtualWaitingRoomAPIClient,
          appid,
          queue,
          tokenid
        );
      }
      throw new Error("Virtual waiting room is not supported");
    },
  });

  return [requestState, fetch];
}
