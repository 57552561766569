import React, { useCallback } from "react";
import { useIntl } from "../../i18n/Localization";
import cn from "classnames";

import styles from "./styles.module.scss";
import { MessageID } from "../../i18n/translations/type";
import { useRefToRefObject } from "../../utils/Ref";

interface LocalizedInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  className?: string;
  value: string;
  placeholderId?: MessageID;
  onChange?: (value: string) => void;
  nextFieldRef?: React.RefObject<HTMLInputElement>;
}

const WrappedLocalizedInput = (
  props: LocalizedInputProps,
  ref?: React.Ref<HTMLInputElement>
) => {
  const refObject = useRefToRefObject(ref);
  const {
    className,
    value,
    placeholderId,
    onChange,
    nextFieldRef,
    onBlur,
    ...otherInputProps
  } = props;
  const { onKeyPress } = otherInputProps;
  const { translate } = useIntl();
  const onInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (onChange == null) {
        return;
      }

      onChange(e.currentTarget.value);
    },
    [onChange]
  );
  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (nextFieldRef && nextFieldRef.current) {
          e.preventDefault();
          e.stopPropagation();
          nextFieldRef.current.focus();
        } else if (refObject.current) {
          refObject.current.blur();
        }
      }
      if (onKeyPress) {
        onKeyPress(e);
      }
    },
    [nextFieldRef, refObject, onKeyPress]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      // FIXME(kenchan): (Issue #2231) Close keyboard in iOS (13.3)
      // by keyboard down button will make the input focus again
      // before focusing on other elements. Just blur 1 more time to fix
      // auto focus.
      e.currentTarget.blur();
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <input
      {...otherInputProps}
      className={cn(styles.input, className)}
      placeholder={placeholderId ? translate(placeholderId) : undefined}
      value={value}
      onChange={onInputChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      ref={ref}
    />
  );
};

export default React.forwardRef(WrappedLocalizedInput);
