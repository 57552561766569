import * as yup from "yup";
import { FormError, IndexMap } from "../utils/type";
import { CustomerAddressSchema } from "../utils/CustomerAddressValidator";
import { isEmpty } from "../utils/String";

import {
  District,
  DistrictID,
  Region,
  RegionID,
  Country,
  CountryID,
  DistrictName,
} from "./CountryRegion";
import { OAuthProvider } from "../api/RESTful";

export enum EmailVerificationPromptStatus {
  notRequired = 0,
  required = 1,
  proceeding = 2,
}

export type ClubTier = "blue" | "silver" | "gold" | "platinum" | "black";

export interface Customer {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  newEmail: string | null;
  attributes: {
    profilePicUrl: string | null;
  }[];
  interest: {
    hasInterestsSet: boolean;
  }[];
  isSubscribed: boolean;
  clubMember: [
    {
      clubMemberID: string | null;
      clubPoint: number;
      hasTheClubVerified: boolean;
      clubTier: ClubTier;
    }
  ];
  linkage: {
    socialAccounts: SocialAccount[];
  }[];
  emailVerificationPrompt: EmailVerificationPromptStatus;
}

export interface ClubMemberCustomer extends Customer {
  clubMember: [
    {
      clubMemberID: string;
      clubPoint: number;
      hasTheClubVerified: boolean;
      clubTier: ClubTier;
    }
  ];
}

export interface SocialAccount {
  label: string;
  connected: boolean;
  socialId: string;
}

export interface Address {
  id?: number;
  firstName: string;
  lastName: string;
  company: string;
  telephone: string;
  roomNumber: string | null;
  flatOrFloor: string | null;
  block: string | null;
  building: string | null;
  street: string | null;
  district: DistrictID | null;
  area: RegionID | null;
  region: CountryID | null;
  isDefaultBilling: boolean;
  isDefaultShipping: boolean;
}

export type AddressKey = keyof Address;

export const emptyAddress = {
  firstName: "",
  lastName: "",
  company: "",
  telephone: "",
  roomNumber: "",
  flatOrFloor: "",
  block: "",
  building: "",
  street: "",
  district: null,
  area: null,
  region: null,
  isDefaultBilling: false,
  isDefaultShipping: false,
};

export function validateAddress(address: Address): FormError<Address> | null {
  let formError: FormError<Address> | null = null;

  try {
    CustomerAddressSchema.validateSync(address, { abortEarly: false });
  } catch (e) {
    if (yup.ValidationError.isError(e)) {
      const { inner } = e;
      formError = inner.reduce(
        (acc: any, error: any) => ({
          ...acc,
          [error.path]: error.message,
        }),
        formError || {}
      );
    }
  }

  if (isEmpty(address.street || "")) {
    formError = formError || {};
    formError.street = "missing";
  }

  return formError;
}

// getUpdatedAddress handles the dependencies between region, area and district
export function getUpdatedAddress<K extends AddressKey>(
  address: Address,
  field: K,
  value: Address[K]
) {
  const newAddress = {
    ...address,
    [field]: value,
  };

  if (address[field] !== newAddress[field]) {
    if (field === "region") {
      newAddress.area = null;
      newAddress.district = null;
    } else if (field === "area") {
      newAddress.district = null;
    }
  }

  return newAddress;
}

export function isSameAddress(lhs: Address, rhs: Address): boolean {
  function isSameStr(str1: string | null, str2: string | null) {
    const processedStr1 = str1 && str1.length > 0 ? str1.trim() : null;
    const processedStr2 = str2 && str2.length > 0 ? str2.trim() : null;
    if (!processedStr1 && !processedStr2) {
      return true;
    }
    if (!processedStr1 || !processedStr2) {
      return false;
    }
    return processedStr1.toLowerCase() === processedStr2.toLowerCase();
  }

  return (
    isSameStr(lhs.firstName, rhs.firstName) &&
    isSameStr(lhs.lastName, rhs.lastName) &&
    isSameStr(lhs.company, rhs.company) &&
    isSameStr(lhs.telephone, rhs.telephone) &&
    isSameStr(lhs.roomNumber, rhs.roomNumber) &&
    isSameStr(lhs.flatOrFloor, rhs.flatOrFloor) &&
    isSameStr(lhs.block, rhs.block) &&
    isSameStr(lhs.building, rhs.building) &&
    isSameStr(lhs.street, rhs.street) &&
    lhs.district === rhs.district &&
    lhs.area === rhs.area &&
    lhs.region === rhs.region
  );
}

export function getCustomerFullName(customer: Customer): string {
  return customer.firstname + " " + customer.lastname;
}

export function isCustomerLinkedToTheClub(
  customer: Customer
): customer is ClubMemberCustomer {
  return customer.clubMember.filter(m => m.clubMemberID != null).length > 0;
}

export function isCustomerTheClubAccountVerified(customer: Customer): boolean {
  return customer.clubMember[0] && customer.clubMember[0].hasTheClubVerified;
}

export function getCustomerClubPoints(customer: ClubMemberCustomer): number {
  return customer.clubMember[0].clubPoint;
}

export function getCustomerClubTier(customer: ClubMemberCustomer): ClubTier {
  return customer.clubMember[0].clubTier;
}

export const CustomerGraphQLAttributes = `
  id
  firstname
  lastname
  email
  newEmail: new_email
  isSubscribed: is_subscribed
  attributes {
    profilePicUrl: profile_picture
  }
  interest {
    hasInterestsSet: has_interests_set
  }
  clubMember: club_member {
    clubMemberID: club_member_id
    clubPoint: clubpoints
    hasTheClubVerified: has_the_club_verified
    clubTier: club_tier
  }
  linkage {
    socialAccounts: social_accounts {
      label
      connected
      socialId: social_id
    }
  }
  emailVerificationPrompt: email_verification_prompt
`;

export function isCustomerInterestSet(customer: Customer): boolean {
  return (
    customer.interest != null &&
    customer.interest.length > 0 &&
    // Display the prompt for interest only when "has_interests_set" is true
    !customer.interest[0].hasInterestsSet
  );
}

export function isCustomerRequireEmailVerificationPrompt(
  customer: Customer
): boolean {
  return (
    customer.emailVerificationPrompt === EmailVerificationPromptStatus.required
  );
}

export function getCustomerLinkedSSOEmail(
  customer: Customer,
  provider: OAuthProvider
): string | null {
  if (
    !customer.linkage ||
    customer.linkage.length === 0 ||
    !customer.linkage[0] ||
    !customer.linkage[0].socialAccounts ||
    customer.linkage[0].socialAccounts.length === 0
  ) {
    return null;
  }
  const socialAccounts = customer.linkage[0].socialAccounts;
  let account: SocialAccount | null = null;
  switch (provider) {
    case "facebook":
      account = socialAccounts.filter(
        _account => _account.label === "Facebook"
      )[0];
      if (account == null) {
        return null;
      }
      return account.socialId;
    case "google":
      account = socialAccounts.filter(
        _account => _account.label === "Google"
      )[0];
      if (account == null) {
        return null;
      }
      return account.socialId;
    case "the-club":
      account = socialAccounts.filter(
        _account => _account.label === "The Club"
      )[0];
      if (account == null) {
        return null;
      }
      return account.socialId;
    default:
      return null;
  }
}

export function getCustomerProfilePicUrl(customer: Customer): string | null {
  if (customer.attributes == null || customer.attributes.length === 0) {
    return null;
  }
  return customer.attributes[0].profilePicUrl;
}

export function getCustomerClubMemberId(customer: Customer): string | null {
  if (isCustomerLinkedToTheClub(customer)) {
    return customer.clubMember[0].clubMemberID;
  }
  return null;
}

export function formatContactName(address: Address) {
  const { firstName, lastName } = address;
  return [firstName, lastName].filter(x => x).join(" ");
}

export function formatAddress(
  address: Address,
  countryByID: IndexMap<CountryID, Country>,
  regionByID: IndexMap<RegionID, Region>,
  districtByID: IndexMap<DistrictID, District>
) {
  const { roomNumber, flatOrFloor, block, building, street } = address;
  const d = address.district ? districtByID[address.district] : null;
  const district = d ? d.name : null;
  const a = address.area ? regionByID[address.area] : null;
  const area = a ? a.name : null;
  const r = address.region ? countryByID[address.region] : null;
  const region = r ? r.name : null;

  const lines = [
    [street, building, block, flatOrFloor, roomNumber]
      .filter(x => x)
      .join(", "),
    [district, area, region].filter(x => x).join(", "),
  ];

  return lines.join("\n");
}

export interface RemoteAddress {
  id: number;
  firstName: string;
  lastName: string;
  company: string;
  telephone: string;
  countryId: string | null;
  city: string | null;
  regionId: number | null;
  street: string[];
  defaultBilling: boolean;
  defaultShipping: boolean;
}

export const CustomerAddressGraphQLAttributes = `
  id
  firstName: firstname
  lastName: lastname
  company
  telephone
  street
  city
  countryId: country_id
  regionId: region_id
  defaultBilling: default_billing
  defaultShipping: default_shipping
`;

export const RemoteAddressSchema: yup.Schema<RemoteAddress> = yup.object({
  id: yup.number(),
  firstName: yup.string().transform(currentValue => currentValue || ""),
  lastName: yup.string().transform(currentValue => currentValue || ""),
  company: yup.string().transform(currentValue => currentValue || ""),
  telephone: yup.string().transform(currentValue => currentValue || ""),
  street: yup.array(yup.string()),
  city: yup.string().nullable(),
  countryId: yup.string().nullable(),
  regionId: yup.number().nullable(),
  defaultBilling: yup.boolean(),
  defaultShipping: yup.boolean(),
});

export function transformRemoteAddress(
  remoteAddress: RemoteAddress,
  districtByDistrictName: IndexMap<DistrictName, District>
): Address {
  const {
    id,
    firstName,
    lastName,
    company,
    telephone,
    street: [street, building, block, flatOrFloor, roomNumber],
    city,
    countryId,
    regionId,
    defaultBilling,
    defaultShipping,
  } = remoteAddress;

  const district = city ? districtByDistrictName[city] : null;

  return {
    id,
    firstName: firstName || "",
    lastName: lastName || "",
    company: company || "",
    telephone: telephone || "",
    roomNumber: roomNumber || "",
    flatOrFloor: flatOrFloor || "",
    building: building || "",
    street: street || "",
    block: block || "",
    district: district ? district.id : null,
    area: regionId,
    region: countryId,
    isDefaultBilling: defaultBilling,
    isDefaultShipping: defaultShipping,
  };
}

export function canCustomerSetClubPointOnCart(
  customer: Customer,
  minClubPoint: number
): boolean {
  return (
    isCustomerLinkedToTheClub(customer) &&
    getCustomerClubPoints(customer) >= minClubPoint
  );
}
