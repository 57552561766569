import React, { useCallback } from "react";
import { LocalizedText } from "../../i18n/Localization";
import styles from "./RedemptionLetterUrl.module.scss";

interface RedemptionLetterUrlProps {
  name: string;
  url: string;
  onLinkClicked: (url: string) => void;
}

const RedemptionLetterUrl: React.FC<RedemptionLetterUrlProps> = props => {
  const { name, url, onLinkClicked } = props;

  const handleLinkClicked = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onLinkClicked(url);
    },
    [onLinkClicked, url]
  );

  return (
    <p className={styles.redemptionLetterUrl}>
      <button
        className={styles.redemptionLetterLink}
        onClick={handleLinkClicked}
      >
        <LocalizedText messageID="order_detail.redemption_letters.prefix" />{" "}
        {name}
      </button>
    </p>
  );
};

export default RedemptionLetterUrl;
