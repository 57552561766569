import React, { useContext, useCallback, useMemo } from "react";
import styles from "./TryClubpointsButton.module.scss";
import { LocalizedText } from "../../i18n/Localization";
import { withProviders } from "../Provider";
import {
  TryClubpointModalProvider,
  TryClubpointModalContext,
} from "../TryClubpointModalProvider";
import { getClubPointConversionRate } from "../../models/AppConfig";
import { ProductOverview } from "../../models/ProductOverview";
import { useAppConfig } from "../../repository/ConfigRepository";

interface Props {
  className?: string;
  productOverview: ProductOverview;
}

const TryClubpointsButton: React.FC<Props> = props => {
  const { className, productOverview } = props;
  const tryClubpointModalContext = useContext(TryClubpointModalContext);
  const appConfig = useAppConfig();
  const productMoney = useMemo(
    () =>
      productOverview.priceRange
        ? productOverview.priceRange.maximumPrice.finalPrice
        : null,
    [productOverview]
  );
  const clubpointConversionRate = useMemo(
    () => getClubPointConversionRate(appConfig),
    [appConfig]
  );
  const { minClubPoint } = productOverview;
  const onClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (productMoney != null) {
        tryClubpointModalContext.present({
          minValue: minClubPoint || 0,
          valuePerStep: clubpointConversionRate,
          clubpointsConversionRate: clubpointConversionRate,
          basePrice: productMoney,
        });
      }
    },
    [
      tryClubpointModalContext,
      minClubPoint,
      productMoney,
      clubpointConversionRate,
    ]
  );
  return (
    <div className={className}>
      <div className={styles.title}>
        <LocalizedText messageID="page.product_detail.clubpoints_calculator" />
      </div>
      <div className={styles.buttonContainer} onClick={onClick}>
        <div className={styles.icon} />
        <div className={styles.tryNow}>
          <LocalizedText messageID="page.product_detail.button.try_now" />
        </div>
      </div>
    </div>
  );
};

export default withProviders(TryClubpointsButton, TryClubpointModalProvider);
