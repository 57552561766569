import {
  MatchedCMSBlock,
  CMSBlock,
  parseCMSBlockFromJSON,
  HTMLBasedCMSPageContent,
} from "../models/cmsBlock";
import { v4 } from "uuid";

export function extractCMSBlocksFromContentForApp(
  contentForApp: string
): CMSBlock[] {
  const div = document.createElement("div");
  div.innerHTML = contentForApp;
  const scriptTags = div.querySelectorAll(
    'script[type="text/x-clublike-widget"]'
  );
  const cmsBlocks: CMSBlock[] = [];
  scriptTags.forEach(tag => {
    const jsonStringData = tag.getAttribute("data-config");
    if (jsonStringData != null) {
      try {
        const json = JSON.parse(jsonStringData);
        const cmsBlock = parseCMSBlockFromJSON(json);
        if (cmsBlock != null) {
          cmsBlocks.push(cmsBlock);
        }
      } catch {}
    }
  });
  return cmsBlocks;
}

export function extractCMSBlocksFromContentForAppWithWaitingToFillHTML(
  contentForApp: string
): HTMLBasedCMSPageContent {
  const div = document.createElement("div");
  div.innerHTML = contentForApp;
  const scriptTags = div.querySelectorAll(
    'script[type="text/x-clublike-widget"]'
  );
  const matchedCMSBlocks: MatchedCMSBlock[] = [];
  scriptTags.forEach(tag => {
    const jsonStringData = tag.getAttribute("data-config");
    if (jsonStringData != null) {
      try {
        const json = JSON.parse(jsonStringData);
        const cmsBlock = parseCMSBlockFromJSON(json);
        if (cmsBlock != null) {
          const id = v4();
          matchedCMSBlocks.push({ matchId: id, cmsBlocks: [cmsBlock] });
          const holeEl = document.createElement("hole");
          holeEl.setAttribute("id", id);
          (tag.parentNode ? tag.parentNode : div).insertBefore(holeEl, tag);
        }
      } catch {}
    }
  });
  const waitingToFillHTML = div.innerHTML;
  return { matchedCMSBlocks, waitingToFillHTML };
}
