import React, { useEffect, useContext, useCallback } from "react";

import {
  Permission,
  check,
  request,
  requestable,
} from "../CLPlugins/Permission";
import {
  registeriBeacon,
  listenRegionEnter,
  listenRegionLeave,
} from "../CLPlugins/Location";
import { isiOS, isAndroid } from "../utils/Platform";

import { LocalizedAlertContext } from "./LocalizedAlertProvider";

import Config from "../Config";

async function registerBeacon(regionInfo: BeaconRegion) {
  const { uuid, major, minor, identifier } = regionInfo;
  await registeriBeacon(uuid, major, minor, identifier);
}

async function registerBeacons(beaconInfos: BeaconRegion[]) {
  try {
    await Promise.all(beaconInfos.map(registerBeacon));
  } catch (e) {
    console.warn("[DEV] " + e.message);
  }
}

const LocationService: React.FC = () => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const presentRejectedDialog = useCallback(() => {
    presentLocalizedAlert({
      headerId: "location.denied",
      buttons: [
        {
          textMessageID: "alert.button.ok",
        },
      ],
    });
  }, [presentLocalizedAlert]);

  const presentEnterRegionAlert = useCallback(
    (info: BeaconRegion) => {
      presentLocalizedAlert({
        message: `[DEV] Region [${info.uuid}, ${info.major}, ${info.minor}] entered`,
        buttons: [
          {
            textMessageID: "alert.button.ok",
          },
        ],
      });
    },
    [presentLocalizedAlert]
  );

  const presentLeaveRegionAlert = useCallback(
    (info: BeaconRegion) => {
      presentLocalizedAlert({
        message: `[DEV] Region [${info.uuid}, ${info.major}, ${info.minor}] exited`,
        buttons: [
          {
            textMessageID: "alert.button.ok",
          },
        ],
      });
    },
    [presentLocalizedAlert]
  );

  useEffect(() => {
    (async () => {
      if (Config.BEACON_REGIONS.length === 0) {
        return;
      }
      if (isAndroid() || isiOS()) {
        if (isiOS()) {
          const { result: checkResult } = await check({
            permission: Permission.IOS_LOCATION_WHEN_IN_USE,
          });
          if (requestable(checkResult)) {
            await request({
              permission: Permission.IOS_LOCATION_ALWAYS,
            });
          }
        } else if (isAndroid()) {
          const { result: permissionStatus } = await check({
            permission: Permission.ANDROID_ACCESS_FINE_LOCATION,
          });
          if (requestable(permissionStatus)) {
            await request({
              permission: Permission.ANDROID_ACCESS_FINE_LOCATION,
            });
          }
        }
        await registerBeacons(Config.BEACON_REGIONS);
      }
    })();
  }, [presentRejectedDialog]);

  useEffect(() => {
    const removeListener = listenRegionEnter(info => {
      presentEnterRegionAlert(info);
    });
    return () => {
      removeListener();
    };
  }, [presentEnterRegionAlert]);

  useEffect(() => {
    const removeListener = listenRegionLeave(info => {
      presentLeaveRegionAlert(info);
    });
    return () => {
      removeListener();
    };
  }, [presentLeaveRegionAlert]);

  return null;
};

export default LocationService;
