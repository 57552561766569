import React, { useMemo } from "react";
import cn from "classnames";

import ProductBlock from "../ProductBlock";

import { ProductOverview } from "../../models/ProductOverview";
import { HorizontalProductListData } from "../../models/horizontalProductList";

import styles from "./styles.module.scss";

import useInViewPolyfill from "../../hook/useInViewPolyfill";
import { ProductLabel } from "../../models/ProductLabel";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { ModelKeys } from "../../models/product";

const PRODUCTLIST_CELL_WIDTH = 140;
const PRODUCTLIST_CELL_HEIGHT = 335;

interface Props {
  horizontalProductListData: HorizontalProductListData;
  detailButton?: React.ReactNode;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
  onClickProductBlock?: (productOverview: ProductOverview) => void;
}

const HorizontalProductList: React.FC<Props> = (props: Props) => {
  const {
    horizontalProductListData: {
      title,
      backgroundUrl,
      productOverviews,
      productLabelsByProductId,
      bundleByProductId,
    },
    detailButton,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
    onClickProductBlock,
  } = props;

  const backgroundStyle = useMemo(
    () =>
      backgroundUrl
        ? {
            backgroundImage: `url(${backgroundUrl})`,
          }
        : undefined,
    [backgroundUrl]
  );

  const [ref, inView] = useInViewPolyfill();

  const productListStyle = useMemo(
    () => ({
      height: PRODUCTLIST_CELL_HEIGHT + 40,
    }),
    []
  );

  return productOverviews.length === 0 ? null : (
    <div className={styles.container}>
      {/*
        Cannot apply position:relative in top level,
        because the static block use float
      */}
      <div className={styles.relativeContainer}>
        <div ref={ref} className={styles.visibleArea} />
        <div className={styles.header} style={backgroundStyle}>
          <>
            <p
              className={cn(styles.title, {
                [styles.hasBackground]: backgroundStyle !== undefined,
              })}
            >
              {title}
            </p>
            {detailButton && (
              <div className={styles.detailButton}>{detailButton}</div>
            )}
          </>
        </div>
        <div className={styles.products} style={productListStyle}>
          {inView ? (
            <ResolvedHorizontalProductListProducts
              productOverviews={productOverviews}
              productLabelsByProductId={productLabelsByProductId}
              bundleByProductId={bundleByProductId}
              onAddToCart={onAddToCart}
              hrefForProduct={hrefForProduct}
              onClickLikeButton={onClickLikeButton}
              onClickProductBlock={onClickProductBlock}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

interface ResolvedHorizontalProductListProductsProps {
  productOverviews: ProductOverview[];
  productLabelsByProductId: {
    [x: number]: ProductLabel[];
  } | null;
  bundleByProductId: {
    [x in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
  onClickProductBlock?: (productOverview: ProductOverview) => void;
}

export const ResolvedHorizontalProductListProducts: React.FC<
  ResolvedHorizontalProductListProductsProps
> = props => {
  const {
    productOverviews,
    productLabelsByProductId,
    bundleByProductId,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
    onClickProductBlock,
  } = props;

  return (
    <>
      {productOverviews.map(productOverview => {
        const bundle = bundleByProductId[productOverview.id];
        const hasBundle =
          bundle && bundle.items != null ? bundle.items.length > 0 : false;
        return (
          <div key={productOverview.sku} className={styles.productWrapper}>
            <ProductBlock
              showOnlyInView={false}
              product={productOverview}
              productLabels={
                productLabelsByProductId
                  ? productLabelsByProductId[productOverview.id]
                  : undefined
              }
              hasBundle={hasBundle}
              width={PRODUCTLIST_CELL_WIDTH}
              height={PRODUCTLIST_CELL_HEIGHT}
              hrefForProduct={hrefForProduct}
              onAddToCart={onAddToCart}
              onClickLikeButton={onClickLikeButton}
              onClick={onClickProductBlock}
            />
          </div>
        );
      })}
    </>
  );
};

export default React.memo(HorizontalProductList);
