import React, { useContext, useMemo } from "react";

import { Customer, Address, formatAddress } from "../../models/Customer";
import { DistrictName, District } from "../../models/CountryRegion";
import { IndexMap } from "../../utils/type";

import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";

import { SelfPickup } from "../Checkout/Context";
import { O2oStore } from "../Checkout/models";

import { FormFieldRow, FormField } from "./FormField";

function transformO2oStoreTOAddress(
  customer: Customer,
  o2oStore: O2oStore,
  telephone: string,
  districtByDistrictName: IndexMap<DistrictName, District>
): Address {
  const {
    country: { id: countryId },
    region: { id: regionId },
    city,
    street,
    building,
    block,
    floor,
    flat,
  } = o2oStore;

  const district = districtByDistrictName[city];

  return {
    firstName: customer.firstname,
    lastName: customer.lastname,
    company: "",
    telephone,
    roomNumber: flat,
    flatOrFloor: floor,
    block,
    building,
    street,
    district: district ? district.id : null,
    area: regionId,
    region: countryId,
    isDefaultBilling: false,
    isDefaultShipping: false,
  };
}

interface Props {
  selfPickup: SelfPickup;
  o2oStores: O2oStore[];
  customer: Customer;
}

const SelfPickupForm: React.FC<Props> = props => {
  const { selfPickup, o2oStores, customer } = props;
  const { telephone } = selfPickup;

  const {
    districtByDistrictName,
    countryByID,
    regionByID,
    districtByID,
  } = useContext(CountryRegionDistrictContext);

  const o2oStore = useMemo(
    () =>
      o2oStores.filter(
        x =>
          `${x.region.id}` === selfPickup.region &&
          x.city === selfPickup.district &&
          (x.storeType === selfPickup.storeType ||
            selfPickup.storeType === "all") &&
          x.code === selfPickup.pickupSpot
      )[0],
    [o2oStores, selfPickup]
  );

  const address = useMemo(
    () =>
      o2oStore
        ? transformO2oStoreTOAddress(
            customer,
            o2oStore,
            telephone,
            districtByDistrictName
          )
        : null,
    [customer, o2oStore, telephone, districtByDistrictName]
  );

  if (!address) {
    return null;
  }

  return (
    <div>
      {address.lastName || address.firstName ? (
        <FormFieldRow>
          {address.lastName ? (
            <FormField titleID="address_form.last_name">
              {address.lastName}
            </FormField>
          ) : null}
          {address.firstName ? (
            <FormField titleID="address_form.first_name">
              {address.firstName}
            </FormField>
          ) : null}
        </FormFieldRow>
      ) : null}
      {address.company ? (
        <FormFieldRow>
          <FormField titleID="address_form.company">
            {address.company}
          </FormField>
        </FormFieldRow>
      ) : null}
      {address.telephone ? (
        <FormFieldRow>
          <FormField titleID="address_form.telephone">
            {address.telephone}
          </FormField>
        </FormFieldRow>
      ) : null}
      <FormFieldRow>
        <FormField titleID="address_form.address">
          {formatAddress(address, countryByID, regionByID, districtByID)}
        </FormField>
      </FormFieldRow>
      <FormFieldRow>
        <FormField titleID="self_pickup_form.pickup_spot">
          {o2oStore.name}
        </FormField>
      </FormFieldRow>
    </div>
  );
};

export default SelfPickupForm;
