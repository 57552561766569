export function getFirst<T>(collection: T[]): T | null {
  return collection.length > 0 ? collection[0] : null;
}

export function findFirst<T>(
  filter: (item: T) => boolean,
  collection: T[]
): T | null {
  const first = collection.filter(filter)[0];
  if (first == null) {
    return null;
  }
  return first;
}
