import { RefresherEventDetail, SegmentChangeEventDetail } from "@ionic/core";
import {
  IonLifeCycleContextInterface,
  IonPage,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import {
  CampaignForClubMember,
  getAvailableCampaigns,
  UnavailableCampaign,
  getUnavailableCampaigns,
  CampaignOrderItem,
} from "../../models/ClubMemberCampaign";
import {
  getRequestStateError,
  getResources,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";
import {
  getPathForCampaignDetailsPage,
  useCurrentTab,
} from "../../navigation/routes";
import { OurNavContext } from "../../our-navigation";
import {
  useFetchBingoList,
  useGetBingoList,
} from "../../repository/BingoListRepository";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { selectBingoListEvent, viewBingoListEvent } from "../../utils/GTM";
import CLContent from "../CLContent";
import CLIonRefresher from "../CLIonRefresher";
import { FullContentErrorView } from "../ErrorView";
import { FullContentLoadingView } from "../LoadingView";
import { NavBar, NavBarBackButton } from "../NavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import SwipeablePages from "../SwipeablePages";
import EmptyView from "./EmptyView";
import { AvailableList, UnavailableList } from "./List";

import styles from "./styles.module.scss";

type ViewState = SimpleViewState<
  {
    availableCampaigns: CampaignForClubMember[];
    unavailableCampaigns: UnavailableCampaign<
      CampaignForClubMember,
      CampaignOrderItem
    >[];
  },
  Error
>;

const BingoListPage: React.FC = () => {
  const bingoListInMemory = useGetBingoList();
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const { translate } = useIntl();
  const { navigate } = useContext(OurNavContext);
  const currentTab = useCurrentTab();

  const [requestState, fetch, refresh] = useFetchBingoList();

  const viewState = useMemo<ViewState>(() => {
    const error = getRequestStateError(requestState);
    if (error) {
      return SimpleViewStateError(error);
    }
    const resources = getResources(requestState);
    if (resources) {
      return SimpleViewStateDisplay({
        availableCampaigns: getAvailableCampaigns(
          resources.campaigns,
          resources.campaignMembers
        ),
        unavailableCampaigns: getUnavailableCampaigns(
          resources.campaigns,
          resources.campaignMembers,
          resources.campaignOrderItems
        ),
      });
    }
    if (bingoListInMemory) {
      return SimpleViewStateDisplay({
        availableCampaigns: getAvailableCampaigns(
          bingoListInMemory.campaigns,
          bingoListInMemory.campaignMembers
        ),
        unavailableCampaigns: getUnavailableCampaigns(
          bingoListInMemory.campaigns,
          bingoListInMemory.campaignMembers,
          bingoListInMemory.campaignOrderItems
        ),
      });
    }
    if (isRequestLoading(requestState)) {
      return SimpleViewStateLoading;
    }
    return SimpleViewStateInitial;
  }, [bingoListInMemory, requestState]);

  useLogBingoListGTMEvent(ionLifeCycleContext, viewState);

  const handleViewEnter = useCallback(() => {
    fetch().catch(() => {});
  }, [fetch]);

  ionLifeCycleContext.onIonViewDidEnter(handleViewEnter);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh]
  );

  const handleBingoListCampaignClick = useCallback(
    (campaign: CampaignForClubMember) => {
      if (campaign.id && campaign.title) {
        selectBingoListEvent(campaign.id, campaign.title);
      }
      if (campaign.id) {
        const id = parseInt(campaign.id, 10);
        navigate(getPathForCampaignDetailsPage(currentTab, id));
      }
    },
    [navigate, currentTab]
  );

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentSegment, setCurrentSegment] = useState<string | undefined>(
    "available"
  );

  const handleSwipeablePagesIndexChange = useCallback((index: number) => {
    setCurrentPageIndex(index);
    if (index === 0) {
      setCurrentSegment("available");
    }
    if (index === 1) {
      setCurrentSegment("unavailable");
    }
  }, []);

  const handleSegmentChange = useCallback(
    (e: CustomEvent<SegmentChangeEventDetail>) => {
      setCurrentSegment(e.detail.value);
      if (e.detail.value === "available") {
        setCurrentPageIndex(0);
      }
      if (e.detail.value === "unavailable") {
        setCurrentPageIndex(1);
      }
    },
    []
  );

  const [
    slideContentIsScrolledToTop,
    setSlideContentIsScrolledToTop,
  ] = useState(true);
  const disableRefresh = useMemo(
    () =>
      viewState.type === "initial" ||
      viewState.type === "loading" ||
      !slideContentIsScrolledToTop,
    [viewState, slideContentIsScrolledToTop]
  );

  return (
    <IonPage className={styles.page}>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="page.bingo_list.title" />}
      />
      <IonSegment
        onIonChange={handleSegmentChange}
        mode="md"
        className={styles.segment}
        value={currentSegment}
      >
        <IonSegmentButton
          value="available"
          mode="md"
          className={styles.segmentButton}
        >
          <p>
            <LocalizedText messageID="page.bingo_list.tab.available.text" />
            {viewState.type === "display" &&
            viewState.data.availableCampaigns.length > 0 ? (
              <span className={styles.availableBadge}>
                {viewState.data.availableCampaigns.length > 9
                  ? "9+"
                  : viewState.data.availableCampaigns.length}
              </span>
            ) : null}
          </p>
        </IonSegmentButton>
        <IonSegmentButton
          value="unavailable"
          mode="md"
          className={styles.segmentButton}
        >
          <LocalizedText messageID="page.bingo_list.tab.unavailable.text" />
        </IonSegmentButton>
      </IonSegment>
      <CLContent className={styles.content} scrollY={false}>
        <CLIonRefresher
          slot="fixed"
          onIonRefresh={handleRefresh}
          disabled={disableRefresh}
        >
          <IonRefresherContent />
        </CLIonRefresher>
        {viewState.type === "initial" || viewState.type === "loading" ? (
          <FullContentLoadingView />
        ) : viewState.type === "error" ? (
          <FullContentErrorView errorMessage={viewState.error.message} />
        ) : viewState.type === "display" ? (
          <div className={styles.contentWrapper}>
            <SwipeablePages
              onIsScrolledToTopChange={setSlideContentIsScrolledToTop}
              onIndexChange={handleSwipeablePagesIndexChange}
              currentIndex={currentPageIndex}
              contentClassName={styles.pageContent}
            >
              <div>
                {viewState.data.availableCampaigns.length > 0 ? (
                  <>
                    <AvailableList
                      bingoList={viewState.data.availableCampaigns}
                      onBingoListCampaignClick={handleBingoListCampaignClick}
                    />
                    <TabBarSpacePlaceholder />
                  </>
                ) : (
                  <EmptyView
                    message={translate(
                      "page.bingo_list.tab.available.empty.message"
                    )}
                  />
                )}
              </div>
              <div>
                {viewState.data.unavailableCampaigns.length > 0 ? (
                  <>
                    <UnavailableList
                      unavailableBingoList={viewState.data.unavailableCampaigns}
                    />
                    <TabBarSpacePlaceholder />
                  </>
                ) : (
                  <EmptyView
                    message={translate(
                      "page.bingo_list.tab.unavailable.empty.message"
                    )}
                  />
                )}
              </div>
            </SwipeablePages>
          </div>
        ) : null}
      </CLContent>
    </IonPage>
  );
};

export default BingoListPage;

function useLogBingoListGTMEvent(
  ionLifeCycleContext: IonLifeCycleContextInterface,
  viewState: ViewState
) {
  const didLog = useRef(false);

  const loggableItems = useMemo<{ id: string; title: string }[]>(() => {
    if (
      viewState.type === "display" &&
      viewState.data.availableCampaigns.length > 0
    ) {
      const items: { id: string; title: string }[] = [];
      for (const campaign of viewState.data.availableCampaigns) {
        if (campaign.id && campaign.title) {
          items.push({ id: campaign.id, title: campaign.title });
        }
      }
      return items;
    }
    return [];
  }, [viewState]);

  const doLogIfNotLogged = useCallback(() => {
    if (!didLog.current && loggableItems.length > 0) {
      for (const { id, title } of loggableItems) {
        viewBingoListEvent(id, title);
      }
      didLog.current = true;
    }
  }, [loggableItems]);

  const didEnter = useCallback(() => {
    doLogIfNotLogged();
  }, [doLogIfNotLogged]);
  ionLifeCycleContext.onIonViewDidEnter(didEnter);

  const didLeave = useCallback(() => {
    didLog.current = false;
  }, []);
  ionLifeCycleContext.onIonViewDidLeave(didLeave);

  useEffect(() => {
    doLogIfNotLogged();
  }, [doLogIfNotLogged]);
}
