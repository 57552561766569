import { Plugins } from "@capacitor/core";
import React, { useEffect, useRef } from "react";
import { useKeepUpdatingRef } from "../hook/utils";

const { AdobeExperiencePlatform: thePlugin } = Plugins;

interface Props {
  appId: string;
}

const AdobeExperiencePlatform: React.FC<Props> = props => {
  const { appId } = props;
  const appIdRef = useKeepUpdatingRef(appId);
  const initializedRef = useRef(false);

  useEffect(() => {
    thePlugin
      .initialize({ appId: appIdRef.current })
      .then(() => {
        initializedRef.current = true;
      })
      .catch(console.error);
  }, [appIdRef]);

  useEffect(() => {
    // Only update app id when initialization is completed
    if (initializedRef.current) {
      thePlugin.configureWithAppId({ appId });
    }
  }, [appId]);

  useEffect(() => {
    thePlugin.lifecycleStart().catch(console.error);
    const handler = Plugins.App.addListener(
      "appStateChange",
      ({ isActive }) => {
        if (isActive) {
          thePlugin.lifecycleStart().catch(console.error);
        } else {
          thePlugin.lifecyclePause().catch(console.error);
        }
      }
    );
    return () => {
      handler.remove();
    };
  }, []);

  return <>{props.children}</>;
};

export default AdobeExperiencePlatform;
