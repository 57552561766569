import { useLocation, useRouteMatch } from "react-router-dom";
import { MerchantID } from "../models/Merchant";
import { ArticleFilter } from "../models/Article";
import { CustomerSubscriptionId } from "../models/CustomerSubscription";
import { PresentationContextValue } from "../our-navigation";
import { RouteLocationState as FilterModalRouteLocationState } from "../components/FilterModal";
import { clEncodePercent } from "../utils/String";
import { PartialOrder } from "../components/CheckoutConfirmationPage/models";

export enum RootTab {
  allCategories = "all-categories",
  category = "category",
  articles = "articles",
  home = "home",
  likes = "likes",
  account = "account",
  redemption = "redemption",
}

export const AllTabs: RootTab[] = [
  RootTab.allCategories,
  RootTab.category,
  RootTab.articles,
  RootTab.home,
  RootTab.likes,
  RootTab.account,
  RootTab.redemption,
];

export function activeTabAsRootTab(activeTab: string): RootTab | null {
  return AllTabs.find(v => v === activeTab) || null;
}

export function getRouteDefinitionForTab(tab: RootTab): string {
  switch (tab) {
    case RootTab.allCategories:
      return getRouteDefinitionForAllCategoriesPage();
    case RootTab.category:
      return getRouteDefinitionForCategoryTab();
    case RootTab.articles:
      return getRouteDefinitionForArticlesTab();
    case RootTab.home:
      return getRouteDefinitionForHomePage();
    case RootTab.likes:
      return getRouteDefinitionForLikesTab();
    case RootTab.account:
      return getRouteDefinitionForAccountTab();
    case RootTab.redemption:
      return getRouteDefinitionForRedemptionTab();
    default:
      throw new Error("Unreachable");
  }
}

export function getPathForTab(tab: RootTab): string {
  switch (tab) {
    case RootTab.allCategories:
      return getPathForAllCategoriesPage();
    case RootTab.category:
      return getPathForCategoryTab();
    case RootTab.articles:
      return getPathForArticleTab();
    case RootTab.home:
      return getPathForHomePage();
    case RootTab.likes:
      return getPathForLikesTab();
    case RootTab.account:
      return getPathForAccountTab();
    case RootTab.redemption:
      return getPathForRedemptionTab();
    default:
      throw new Error("Unreachable");
  }
}

export function useCheckIsScreenActive(): boolean {
  const match = useRouteMatch();
  const location = useLocation();
  return match.url === location.pathname;
}

export function useCurrentTab(): RootTab {
  const match = useRouteMatch<{ tab: RootTab }>();
  return match.params.tab;
}

export function getCurrentTabForPath(path: string): RootTab | null {
  if (new RegExp(`^/${RootTab.home}`).exec(path)) {
    return RootTab.home;
  }
  if (new RegExp(`^/${RootTab.allCategories}`).exec(path)) {
    return RootTab.allCategories;
  }
  if (new RegExp(`^/${RootTab.category}`).exec(path)) {
    return RootTab.category;
  }
  if (new RegExp(`^/${RootTab.articles}`).exec(path)) {
    return RootTab.articles;
  }
  if (new RegExp(`^/${RootTab.likes}`).exec(path)) {
    return RootTab.likes;
  }
  if (new RegExp(`^/${RootTab.account}`).exec(path)) {
    return RootTab.account;
  }
  if (new RegExp(`^/${RootTab.redemption}`).exec(path)) {
    return RootTab.redemption;
  }
  return null;
}

export function getRouteDefinitionForCategoryTab() {
  return `/:tab(${RootTab.category})`;
}

export function getPathForCategoryTab() {
  return `/${RootTab.category}`;
}

export function getRouteDefinitionForArticlesTab() {
  return `/:tab(${RootTab.articles})`;
}

export function getPathForArticleTab() {
  return `/${RootTab.articles}`;
}

export function getRouteDefinitionForHomePage() {
  return `/:tab(${RootTab.home})`;
}

export function getPathForHomePage() {
  return `/${RootTab.home}`;
}

export function getRouteDefinitionForLikesTab() {
  return `/:tab(${RootTab.likes})`;
}

export function getPathForLikesTab() {
  return `/${RootTab.likes}`;
}

export function getRouteDefinitionForAccountTab() {
  return `/:tab(${RootTab.account})`;
}

export function getPathForAccountTab() {
  return `/${RootTab.account}`;
}

export function getRouteDefinitionForRedemptionTab() {
  return `/:tab(${RootTab.redemption})`;
}

export function getPathForRedemptionTab() {
  return `/${RootTab.redemption}`;
}

export function getRouteDefinitionForDisplayLanguagePage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/display-language`;
}

export function getPathForDisplayLanguagePage(tab: RootTab) {
  return `${getPathForTab(tab)}/display-language`;
}

export function getRouteDefinitionForAllCategoriesPage() {
  return `${getRouteDefinitionForCategoryTab()}/categories`;
}

export function getPathForAllCategoriesPage() {
  return `${getPathForCategoryTab()}/categories`;
}

export function getRouteDefinitionForSingleCategoryPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/category/:categoryId`;
}

export function getPathForSingleCategoryPage(
  tab: RootTab,
  categoryId: number | string
) {
  return `${getPathForTab(tab)}/category/${categoryId}`;
}

export function getRouteDefinitionForSubCategoryListPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/sub-category-list/:categoryId`;
}

export function getPathForSubCategoryListPage(
  tab: RootTab,
  categoryId: number
) {
  return `${getPathForTab(tab)}/sub-category-list/${categoryId}`;
}

export function getRouteDefinitionForProductDetailPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/product/:sku`;
}
export function getPathForProductDetailPage(tab: RootTab, sku: string) {
  return `${getPathForTab(tab)}/product/${sku}`;
}

export function getRouteDefinitionForSelectInterestCategoryPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/select-categories`;
}

export function getPathForSelectInterestCategoryPage(tab: RootTab) {
  return `${getPathForTab(tab)}/select-categories`;
}

export function getRouteDefinitionForSignupClubPointPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/signup-clubpoint`;
}

export function getPathForSignupClubPointPage(tab: RootTab) {
  return `${getPathForTab(tab)}/signup-clubpoint`;
}

export function getRouteDefinitionForForgotPassword() {
  return `/forgot-password`;
}

export function getPathForForgotPassword() {
  return `/forgot-password`;
}

export function getRouteDefinitionForFilterModal() {
  return "/filter";
}

export function presentFilterModal(
  present: PresentationContextValue["present"],
  state: FilterModalRouteLocationState
) {
  present("/filter", state);
}

export function getPathForShoppingCart() {
  return "/shopping-cart";
}

export function getRouteDefinitionForCheckoutFillingInfo() {
  return "/cart/checkout";
}

export function getPathForCheckoutFillingInfo(
  section?:
    | "delivery-info"
    | "delivery-time"
    | "billing-info"
    | "payment-option"
    | "promotion"
    | "email-address"
) {
  return `/cart/checkout${section ? `#${section}` : ""}`;
}

export function getRouteDefinitionForCheckoutSelectO2oStore() {
  return "/cart/select-o2o-store";
}

export function getPathForCheckoutSelectO2oStore() {
  return "/cart/select-o2o-store";
}

export function getRouteDefinitionForCheckoutConfirmation() {
  return "/cart/confirmation";
}

export function getPathForCheckoutConfirmation() {
  return `/cart/confirmation`;
}

export function getRouteDefinitionForCheckoutOrdered() {
  return "/cart/ordered";
}

export interface CheckoutOrderedLocationState {
  order: PartialOrder;
}

function getPathForCheckoutOrdered() {
  return "/cart/ordered";
}

export function navigateToCheckoutOrderedPage(
  navigate: (path: string, state: CheckoutOrderedLocationState) => void,
  state: CheckoutOrderedLocationState
) {
  navigate(getPathForCheckoutOrdered(), state);
}

export function getRouteDefinitionForMPOSPayPayment() {
  return "/cart/mpospay-payment/:orderID";
}

export function getPathForMPOSPayPayment(orderID: string) {
  return `/cart/mpospay-payment/${orderID}`;
}

export function getRouteDefinitionForMerchantDirectories(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/merchants`;
}

export function getPathForMerchantDirectories(tab: RootTab) {
  return `${getPathForTab(tab)}/merchants`;
}

export function getPathForEditProfile(tab: RootTab) {
  return `${getPathForTab(tab)}/edit-profile`;
}

export function getRouteDefinitionForEditProfile(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/edit-profile`;
}

export function getPathForAccountInformation(tab: RootTab) {
  return `${getPathForTab(tab)}/account-information`;
}

export function getRouteDefinitionForAccountInformation(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/account-information`;
}

export function getPathForChangeEmail(tab: RootTab) {
  return `${getPathForEditProfile(tab)}/change-email`;
}

export function getRouteDefinitionForChangeEmail(tab: RootTab) {
  return `${getRouteDefinitionForEditProfile(tab)}/change-email`;
}

export function getPathForChangePassword(tab: RootTab) {
  return `${getPathForTab(tab)}/change-password`;
}

export function getRouteDefinitionForChangePassword(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/change-password`;
}

export function getRouteDefinitionForMyOrders(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/my-orders`;
}

export function getPathForMyOrders(tab: RootTab) {
  return `${getPathForTab(tab)}/my-orders`;
}

export function getRouteDefinitionForOrderDetail(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/order-detail/:id`;
}

export function getPathForOrderDetail(tab: RootTab, orderID: string) {
  return `${getPathForTab(tab)}/order-detail/${orderID}`;
}

export function getRouteDefinitionForOrderDetailsQRCodesRedemptionPage(
  tab: RootTab
) {
  return `${getRouteDefinitionForTab(
    tab
  )}/order-detail/:id/:productID/qr-codes`;
}

export function getPathForOrderDetailsQRCodesRedemptionPage(
  tab: RootTab,
  orderID: string,
  productID: number
) {
  return `${getPathForTab(tab)}/order-detail/${orderID}/${productID}/qr-codes`;
}

export function getRouteDefinitionForSingleMerchant(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/merchant/:id`;
}

export function getPathForSingleMerchant(tab: RootTab, merchantID: MerchantID) {
  return `${getPathForTab(tab)}/merchant/${merchantID}`;
}

export function getRouteDefinitionForMerchantDetails(tab: RootTab) {
  return `${getRouteDefinitionForSingleMerchant(tab)}/details`;
}

export function getPathForMerchantDetails(
  tab: RootTab,
  merchantID: MerchantID
) {
  return `${getPathForSingleMerchant(tab, merchantID)}/details`;
}

export function getRouteDefinitionForSearchSuggestion(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/search-suggestion`;
}

export function getPathForSearchSuggestion(tab: RootTab) {
  return `${getPathForTab(tab)}/search-suggestion`;
}

export function getRouteDefinitionForSearch(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/search/:searchTerm`;
}

export function getPathForSearch(tab: RootTab, searchTerm: string) {
  // Should be encoded. Search term will be broken if `/` exists.
  return `${getPathForTab(tab)}/search/${encodeURIComponent(
    clEncodePercent(searchTerm)
  )}`;
}

export function getRouteDefinitionForMyDelivery(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/my-delivery`;
}

export function getPathForMyDelivery(tab: RootTab) {
  return `${getPathForTab(tab)}/my-delivery`;
}

export function getRouteDefinitionForAddressForm() {
  return `/my-delivery/:id`;
}

export function getPathForAddNewAddress() {
  return `/my-delivery/new`;
}

export function getPathForEditAddress(addressID: number) {
  return `/my-delivery/${addressID}`;
}

export function getRouteDefinitionForArticleDetail(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/article/:id`;
}

export function getPathForArticleDetail(tab: RootTab, articleID: string) {
  return `${getPathForTab(tab)}/article/${articleID}`;
}

export function getRouteDefinitionForFilteredArticleListPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/articles/:type/:id`;
}

export function getPathForFilteredArticleListPage(
  tab: RootTab,
  type: ArticleFilter,
  id: string
) {
  return `${getPathForTab(tab)}/articles/${type}/${id}`;
}

export function getRouteDefinitionForCMSLanding(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/cms/:id`;
}

export function getPathForCMSLanding(tab: RootTab, id: string) {
  return `${getPathForTab(tab)}/cms/${id}`;
}

export function getRouteDefinitionForNotificationSettings(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/notification-settings`;
}

export function getPathForNotificationSettings(tab: RootTab) {
  return `${getPathForTab(tab)}/notification-settings`;
}

export function getRouteDefinitionForFooterCMSLinkGroup(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/footer-cms-link-group/:text`;
}

export function getPathForFooterCMSLinkGroup(tab: RootTab, text: string) {
  return `${getPathForTab(tab)}/footer-cms-link-group/${text}`;
}

export function getRouteDefinitionForFooterCMSLink(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/footer-cms-link/:groupText/:text`;
}

export function getPathForFooterCMSLink(
  tab: RootTab,
  groupText: string,
  text: string
) {
  return `${getPathForTab(tab)}/footer-cms-link/${groupText}/${text}`;
}

export function getRouteDefinitionForPushNotificationMessages() {
  return `/push-notification-messages`;
}

export function getPathForPushNotificationMessages() {
  return `/push-notification-messages`;
}

export function getRouteDefinitionForCustomerProductReview(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/customer-product-review`;
}

export function getPathForCustomerProductReview(tab: RootTab) {
  return `${getPathForTab(tab)}/customer-product-review`;
}

export function getRouteDefinitionForProductReviews() {
  return `/product-reviews/:sku`;
}

export function getPathForProductReviews(sku: string) {
  return `/product-reviews/${sku}`;
}

export function getRouteDefinitionForMyCards(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/my-cards`;
}

export function getPathForMyCards(tab: RootTab) {
  return `${getPathForTab(tab)}/my-cards`;
}

export function getRouteDefinitionForMySubscriptions(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/my-subscriptions`;
}

export function getPathForMySubscriptions(tab: RootTab) {
  return `${getPathForTab(tab)}/my-subscriptions`;
}

export function getRouteDefinitionForSubscriptionDetails(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/subscription-details/:id`;
}

export function getPathForSubscriptionDetails(
  tab: RootTab,
  id: CustomerSubscriptionId
) {
  return `${getPathForTab(tab)}/subscription-details/${id}`;
}

export function getRouteDefinitionForClubProtectClaimApplication() {
  return `/club-protect-claim-application`;
}

export function getPathForClubProtectClaimApplication() {
  return `/club-protect-claim-application`;
}

export function getRouteDefinitionForBrandIndexPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/brands`;
}

export function getPathForBrandIndexPage(tab: RootTab) {
  return `${getPathForTab(tab)}/brands`;
}

export function getRouteDefinitionForBrandsSearchPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/brands_search`;
}

export function getPathForBrandsSearchPage(tab: RootTab) {
  return `${getPathForTab(tab)}/brands_search`;
}

export function getRouteDefinitionForSingleBrandPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/brand/:slug`;
}

export function getPathForSingleBrandPage(tab: RootTab, slug: string) {
  return `${getPathForTab(tab)}/brand/${slug}`;
}

export function getRouteDefinitionForPerformanceRecords(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/performance-records`;
}

export function getPathForPerformanceRecords(tab: RootTab) {
  return `${getPathForTab(tab)}/performance-records`;
}

export function getRouteDefinitionForBingoList(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/bingo-list`;
}

export function getPathForBingoList(tab: RootTab) {
  return `${getPathForTab(tab)}/bingo-list`;
}

export function getRouteDefinitionForCampaignDetailsPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/campaign-details/:id`;
}

export function getPathForCampaignDetailsPage(tab: RootTab, id: number) {
  return `${getPathForTab(tab)}/campaign-details/${id}`;
}

export function getRouteDefinitionForCampaignProductDetailsPage(tab: RootTab) {
  return `${getRouteDefinitionForTab(
    tab
  )}/campaign-details/:campaignId/product/:sku`;
}

export function getPathForCampaignProductDetailsPage(
  tab: RootTab,
  campaignId: number,
  sku: string
) {
  return `${getPathForTab(tab)}/campaign-details/${campaignId}/product/${sku}`;
}

export function getRouteDefinitionForIFramePage(tab: RootTab) {
  return `${getRouteDefinitionForTab(tab)}/iframe-page/:urlEncoded`;
}

export function getPathForIFramePage(tab: RootTab, url: string) {
  return `${getPathForTab(tab)}/iframe-page/${encodeIFramePageParamUrl(url)}`;
}

function encodeIFramePageParamUrl(url: string) {
  return encodeURIComponent(btoa(url));
}

export function decodeIFramePageParamUrl(urlEncoded: string) {
  return atob(decodeURIComponent(urlEncoded));
}
