import React from "react";
import { LocalizedText } from "../../i18n/Localization";

import styles from "./styles.module.scss";

const SoldOutButton: React.FC = () => {
  return (
    <div className={styles.soldOutButton}>
      <span className={styles.soldOutButtonText}>
        <LocalizedText messageID="product.sold_out" />
      </span>
    </div>
  );
};

export default SoldOutButton;
