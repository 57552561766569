import { StoreConfig } from "./StoreConfig";

import { Locale } from "../i18n/locale";
import { IndexMap, Override } from "../utils/type";
import { filterNullOrUndefined } from "../utils/array";

import allCategoryImg from "../resources/all-categories.svg";

export type CategoryID = number;

type DisplayMode = "PRODUCTS" | "PAGE" | "PRODUCTS_AND_PAGE";

function isDisplayMode(displayMode: string): displayMode is DisplayMode {
  return (
    displayMode === "PRODUCTS" ||
    displayMode === "PAGE" ||
    displayMode === "PRODUCTS_AND_PAGE"
  );
}

export interface Category {
  id: CategoryID;
  name: string;
  iconUrl: string | null;
  thumbnailUrl: string | null;
  backgroundColor1: string | null;
  backgroundColor2: string | null;
  includeInMenu: boolean;
  position: number;
  displayMode: DisplayMode;
  landingPage: number | null;
  description?: string | null;
}

export interface CategoryTree extends Category {
  childrenIds: CategoryID[];
}

interface CategoryWithCMSPage extends Category {
  landingPage: number;
}

export type RemoteCategoryTree = Partial<{
  id: CategoryID | null;
  name: string | null;
  iconUrl: string | null;
  thumbnailUrl: string | null;
  backgroundColor1: string | null;
  backgroundColor2: string | null;
  includeInMenu: number | null;
  position: number | null;
  displayMode: string | null;
  landingPage: number | null;
  children: RemoteCategoryTree[];
}>;

export interface DisabledRemoteCategoryTree {
  name: null;
  position: null;
}

export type RawRemoteCategoryTree =
  | Override<RemoteCategoryTree, { children?: RawRemoteCategoryTree[] }>
  | DisabledRemoteCategoryTree;

export const CategoryGraphQLAttributes = `
  id
  name
  iconUrl: category_thumbnail
  thumbnailUrl: category_app_menu_image
  includeInMenu: include_in_menu
  position
  backgroundColor1: background_color_1
  backgroundColor2: background_color_2
  displayMode: display_mode
  landingPage: landing_page
`;

export const CategoryTreeGraphQLAttributes = `
  ${CategoryGraphQLAttributes}
  children {
    ${CategoryGraphQLAttributes}
    children {
      ${CategoryGraphQLAttributes}
      children {
        ${CategoryGraphQLAttributes}
      }
    }
  }
`;

function isCategoryDisabled(
  categoryTree: RawRemoteCategoryTree
): categoryTree is DisabledRemoteCategoryTree {
  return !categoryTree.position || !categoryTree.name;
}

export function filterDisabledCategories(
  categoryTree: RawRemoteCategoryTree
): RemoteCategoryTree | null {
  if (isCategoryDisabled(categoryTree)) {
    return null;
  }
  if (!categoryTree.children || !categoryTree.children.length) {
    return categoryTree as any;
  }
  const children: RemoteCategoryTree[] = [];
  for (const c of categoryTree.children) {
    const processed = filterDisabledCategories(c);
    if (processed) {
      children.push(processed);
    }
  }
  return { ...categoryTree, children };
}

export function makeAllCategory(locale: Locale): Category {
  switch (locale) {
    case Locale.en:
      return {
        id: 0,
        name: "All Categories",
        iconUrl: allCategoryImg,
        backgroundColor1: null,
        backgroundColor2: null,
        thumbnailUrl: null,
        includeInMenu: true,
        position: 0,
        displayMode: "PAGE",
        landingPage: null,
        description: null,
      };
    case Locale.zhHant:
      return {
        id: 0,
        name: "所有類別",
        iconUrl: allCategoryImg,
        backgroundColor1: null,
        backgroundColor2: null,
        thumbnailUrl: null,
        includeInMenu: true,
        position: 0,
        displayMode: "PAGE",
        landingPage: null,
        description: null,
      };
    default:
      return {
        id: 0,
        name: "All Categories",
        iconUrl: allCategoryImg,
        backgroundColor1: null,
        backgroundColor2: null,
        thumbnailUrl: null,
        includeInMenu: true,
        position: 0,
        displayMode: "PAGE",
        landingPage: null,
        description: null,
      };
  }
}

export function transformRemoteCategoryTree(
  r: RemoteCategoryTree
): CategoryTree | null {
  const {
    id,
    name,
    iconUrl,
    thumbnailUrl,
    backgroundColor1,
    backgroundColor2,
    includeInMenu,
    position,
    displayMode,
    landingPage,
  } = r;
  if (!id || !name || !position) {
    return null;
  }
  const _displayMode = displayMode || "PRODUCTS";
  if (!isDisplayMode(_displayMode)) {
    return null;
  }

  const _includeInMenu = !!includeInMenu;

  return {
    id,
    name,
    iconUrl: iconUrl || null,
    thumbnailUrl: thumbnailUrl || null,
    backgroundColor1: backgroundColor1 || null,
    backgroundColor2: backgroundColor2 || null,
    includeInMenu: _includeInMenu,
    position,
    displayMode: _displayMode,
    landingPage: landingPage || null,
    description: null,
    childrenIds: r.children
      ? filterNullOrUndefined(r.children.map(c => c.id))
      : [],
  };
}

export function flattenCategoryTree(
  remoteCategoryTree: RemoteCategoryTree
): CategoryTree[] {
  const transformed = transformRemoteCategoryTree(remoteCategoryTree);
  let flattened: CategoryTree[] = transformed ? [transformed] : [];

  if (remoteCategoryTree.children) {
    remoteCategoryTree.children.forEach(l2tree => {
      const flattenedl2tree = flattenCategoryTree(l2tree);
      flattened = flattened.concat(flattenedl2tree);
    });
  }

  return flattened;
}

export function computeChildenCategories(
  categoryTree: CategoryTree,
  categoryTreeMap: IndexMap<string, CategoryTree>
) {
  const { childrenIds } = categoryTree;
  const res: CategoryTree[] = [];
  for (let i = 0; i < childrenIds.length; i++) {
    const childrenId = childrenIds[i];
    const children = categoryTreeMap[childrenId];
    if (children) {
      res.push(children);
    }
  }
  return res
    .filter(c => c.includeInMenu)
    .slice()
    .sort((a, b) => a.position - b.position);
}

export function filterCategoryTreeNodes(
  categoryTree: CategoryTree,
  categoryTreeMap: IndexMap<string, CategoryTree>,
  categoryIDs: CategoryID[]
): CategoryTree | null {
  if (categoryTree.childrenIds.length === 0) {
    return categoryIDs.indexOf(categoryTree.id) > -1 ? categoryTree : null;
  }

  const children: (CategoryTree | null)[] = categoryTree.childrenIds.map(
    childrenId => {
      const c = categoryTreeMap[childrenId];
      if (c) {
        return filterCategoryTreeNodes(c, categoryTreeMap, categoryIDs);
      }
      return null;
    }
  );
  const childrenExists: CategoryTree[] = [];
  for (const child of children) {
    if (child != null) {
      childrenExists.push(child);
    }
  }

  if (childrenExists.length === 0) {
    return categoryIDs.indexOf(categoryTree.id) > -1
      ? { ...categoryTree, childrenIds: [] }
      : null;
  }

  return { ...categoryTree, childrenIds: childrenExists.map(c => c.id) };
}

export function constructMediaUrlForCategory(
  storeConfig: StoreConfig,
  filePath: string
): string {
  return `${storeConfig.baseMediaUrl}catalog/category/${filePath}`;
}

export function getBackgroundColor(
  {
    backgroundColorFrom,
    backgroundColorTo,
  }: {
    backgroundColorFrom: string | null;
    backgroundColorTo: string | null;
  },
  toPosition: "top" | "right" | "bottom" | "left" = "right"
): string | undefined {
  if (backgroundColorFrom && backgroundColorTo) {
    return `linear-gradient(to ${toPosition}, #${backgroundColorFrom}, #${backgroundColorTo})`;
  } else if (backgroundColorFrom) {
    return `#${backgroundColorFrom}`;
  } else if (backgroundColorTo) {
    return `#${backgroundColorTo}`;
  }
  return undefined;
}

export function hasProducts(categoryTree: CategoryTree) {
  return (
    categoryTree.displayMode === "PRODUCTS" ||
    categoryTree.displayMode === "PRODUCTS_AND_PAGE"
  );
}

export function hasPage(category: Category): category is CategoryWithCMSPage {
  return (
    (category.displayMode === "PAGE" ||
      category.displayMode === "PRODUCTS_AND_PAGE") &&
    category.landingPage != null
  );
}

export function getCategoryCMSPageId(category: Category): string | null {
  if (hasPage(category)) {
    return String(category.landingPage);
  }
  return null;
}

export function getCategoryPath(
  categoryTreeMap: IndexMap<string, CategoryTree>,
  root: CategoryTree,
  id: string
): CategoryTree[] {
  if (`${root.id}` === id) {
    return [root];
  } else if (!root.childrenIds || !root.childrenIds.length) {
    return [];
  }
  for (const childrenId of root.childrenIds) {
    const child = categoryTreeMap[childrenId];
    if (child) {
      const subPath = getCategoryPath(categoryTreeMap, child, id);
      if (subPath.length > 0) {
        return [root, ...subPath];
      }
    }
  }
  return [];
}
