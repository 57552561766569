import { useCallback, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { ProductLabel, ProductLabelMode } from "../models/ProductLabel";
import {
  ResourcesRequestState,
  getResources,
} from "../models/ResourcesRequestState";

import { useIntl } from "../i18n/Localization";
import {
  fetchProductLabelsByProductIds,
  getProductLabelsByProductIds,
} from "../api/ProductLabel";
import { Session } from "../utils/PerformanceRecordStore/sessions";
import { profileAsyncAction, profileSyncAction } from "../utils/performance";

import { useFetchResources_v2 } from "./Hooks";

export function useGetProductLabelsByProductId(
  profileSession?: Session
): (
  productIds: number[],
  mode: ProductLabelMode
) => { [key in number]: ProductLabel[] } {
  const client = useApolloClient();
  return useCallback(
    (productIds: number[], mode: ProductLabelMode) => {
      const action = () => {
        const productLabelsByProductIdList = getProductLabelsByProductIds(
          client,
          productIds,
          mode
        );
        const res: { [key in number]: ProductLabel[] } = {};
        productLabelsByProductIdList.forEach(({ productId, productLabels }) => {
          res[productId] = productLabels;
        });
        return res;
      };
      return profileSession
        ? profileSyncAction(
            profileSession,
            "Load product labels from cache",
            action
          )
        : action();
    },
    [client, profileSession]
  );
}

export function useFetchProductLabelsByProductId(
  profileSession?: Session
): [
  ResourcesRequestState<{ [key in number]: ProductLabel[] } | null>,
  (
    productIds: number[],
    mode: ProductLabelMode
  ) => Promise<{ [key in number]: ProductLabel[] }>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch }] = useFetchResources_v2<
    {
      [key in number]: ProductLabel[];
    },
    (
      productIds: number[],
      mode: ProductLabelMode
    ) => Promise<
      {
        [key in number]: ProductLabel[];
      }
    >
  >({
    localCacheProvider: async (
      productIds: number[],
      mode: ProductLabelMode
    ) => {
      const action = async () => {
        const productLabelsByProductIdList = await fetchProductLabelsByProductIds(
          client,
          productIds,
          mode,
          locale,
          "cache-only"
        );
        const res: { [key in number]: ProductLabel[] } = {};
        productLabelsByProductIdList.forEach(({ productId, productLabels }) => {
          res[productId] = productLabels;
        });
        return res;
      };
      return profileSession
        ? profileAsyncAction(
            profileSession,
            "Load product labels from cache",
            action
          )
        : action();
    },
    remoteResourcesProvider: async (
      productIds: number[],
      mode: ProductLabelMode
    ) => {
      const action = async () => {
        const productLabelsByProductIdList = await fetchProductLabelsByProductIds(
          client,
          productIds,
          mode,
          locale,
          "network-only"
        );
        const res: { [key in number]: ProductLabel[] } = {};
        productLabelsByProductIdList.forEach(({ productId, productLabels }) => {
          res[productId] = productLabels;
        });
        return res;
      };
      return profileSession
        ? profileAsyncAction(
            profileSession,
            "Load product labels from network",
            action
          )
        : action();
    },
  });

  return [requestState, fetch];
}

export function useFetchProductLabelsByProductIdPaginated(
  profileSession?: Session
): [
  { [key in number]: ProductLabel[] },
  (productIds: number[]) => void,
  () => void
] {
  const [productLabelsByProductId, setProductLabelsByProductId] = useState<
    { [key in number]: ProductLabel[] }
  >({});

  const [
    productLabelRequestState,
    requestProductLabelsByProductId,
  ] = useFetchProductLabelsByProductId(profileSession);

  const fetch = useCallback(
    (productIds: number[]) => {
      const productIdsNotExists = productIds.filter(
        productId => !productLabelsByProductId[productId]
      );
      if (productIdsNotExists.length > 0) {
        requestProductLabelsByProductId(productIdsNotExists, "category").catch(
          () => {}
        );
      }
    },
    [productLabelsByProductId, requestProductLabelsByProductId]
  );

  const clear = useCallback(() => {
    setProductLabelsByProductId({});
  }, []);

  const productLabelsByProductIdCurrentPage = getResources(
    productLabelRequestState
  );

  useEffect(() => {
    if (productLabelsByProductIdCurrentPage) {
      setProductLabelsByProductId(prev => ({
        ...prev,
        ...productLabelsByProductIdCurrentPage,
      }));
    }
  }, [productLabelsByProductIdCurrentPage]);

  return [productLabelsByProductId, fetch, clear];
}
