import React, { useCallback } from "react";
import formatDate from "date-fns/format";

import { CustomerSubscription } from "../../models/CustomerSubscription";
import { formatMoney } from "../../models/Price";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./Item.module.scss";

interface Props {
  customerSubscription: CustomerSubscription;
  onReviewClick: (productId: number) => void;
  onCancelClick: (customerSubscription: CustomerSubscription) => void;
}

/* eslint-disable complexity */
const Item: React.FC<Props> = props => {
  const { customerSubscription, onReviewClick, onCancelClick } = props;
  const {
    subscriptionName,
    subscription,
    startDate,
    endDate,
    trialStartDate,
    trialEndDate,
    lastBilling,
    lastBillingAmount,
    nextBilling,
    nextBillingAmount,
    status,
    isActive,
  } = customerSubscription;

  const handleReviewClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (subscription && subscription.productId) {
        onReviewClick(subscription.productId);
      }
    },
    [subscription, onReviewClick]
  );

  const handleCancelSubscription = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onCancelClick(customerSubscription);
    },
    [onCancelClick, customerSubscription]
  );

  return (
    <div className={styles.item}>
      <div className={styles.itemInfo}>
        <div className={styles.infoRow}>
          <InfoItem titleID="subscription_details.subscription_name">
            {subscriptionName || "-"}
          </InfoItem>
        </div>
        <div className={styles.infoRow}>
          <InfoItem titleID="subscription_details.quantity">
            {(subscription && subscription.quantity) || "-"}
          </InfoItem>
          <InfoItem titleID="subscription_details.status">
            {status || "-"}
          </InfoItem>
        </div>
        <div className={styles.infoRow}>
          <InfoItem titleID="subscription_details.start_date">
            {(startDate && formatDate(startDate, "DD/MM/YYYY hh:mm A")) || "-"}
          </InfoItem>
          <InfoItem titleID="subscription_details.end_date">
            {(endDate && formatDate(endDate, "DD/MM/YYYY hh:mm A")) || "-"}
          </InfoItem>
        </div>
        <div className={styles.infoRow}>
          <InfoItem titleID="subscription_details.trial_start_date">
            {(trialStartDate &&
              formatDate(trialStartDate, "DD/MM/YYYY hh:mm A")) ||
              "-"}
          </InfoItem>
          <InfoItem titleID="subscription_details.trial_end_date">
            {(trialEndDate && formatDate(trialEndDate, "DD/MM/YYYY hh:mm A")) ||
              "-"}
          </InfoItem>
        </div>
        <div className={styles.infoRow}>
          <InfoItem titleID="subscription_details.last_billing_date">
            {(lastBilling && formatDate(lastBilling, "DD/MM/YYYY hh:mm A")) ||
              "-"}
          </InfoItem>
          <InfoItem titleID="subscription_details.last_billing_amount">
            {(lastBillingAmount && formatMoney(lastBillingAmount)) || "-"}
          </InfoItem>
        </div>
        <div className={styles.infoRow}>
          <InfoItem titleID="subscription_details.next_billing_date">
            {(nextBilling && formatDate(nextBilling, "DD/MM/YYYY hh:mm A")) ||
              "-"}
          </InfoItem>
          <InfoItem titleID="subscription_details.next_billing_amount">
            {(nextBillingAmount && formatMoney(nextBillingAmount)) || "-"}
          </InfoItem>
        </div>
      </div>
      <div className={styles.itemActions}>
        {subscription && subscription.productId ? (
          <div className={styles.itemAction} onClick={handleReviewClick}>
            <LocalizedText messageID="subscription_details.review" />
          </div>
        ) : null}
        {isActive ? (
          <div className={styles.itemAction} onClick={handleCancelSubscription}>
            <LocalizedText messageID="subscription_details.cancel_subscription" />
          </div>
        ) : null}
      </div>
    </div>
  );
};
/* eslint-enable complexity */

export default Item;

interface InfoItemProps {
  titleID: MessageID;
  children: React.ReactNode;
}

const InfoItem: React.FC<InfoItemProps> = props => {
  const { titleID, children } = props;

  return (
    <div className={styles.infoItem}>
      <div className={styles.infoTitle}>
        <LocalizedText messageID={titleID} />
      </div>
      <div className={styles.infoValue}>{children}</div>
    </div>
  );
};
