import { useCallback, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import {
  OS,
  Token,
  Isdn,
  NotificationEnableState,
} from "../../models/OPNSPushNotification";
import { useIntl } from "../../i18n/Localization";
import { CLError, RemoteError, LocalError } from "../../utils/Error";
import { parseGraphQLError } from "../../api/GraphQL";

import {
  getUnreadMessageCount,
  getOrderNotification,
  getPromotionNotification,
} from "./api";

export function useGetUnreadMessageCount(): (
  os: OS,
  token: Token,
  isdn: Isdn,
  readFromDate: Date | null
) => Promise<number> {
  const client = useApolloClient();
  const { locale } = useIntl();

  return useCallback(
    async (os: OS, token: Token, isdn: Isdn, readFromDate: Date | null) => {
      return getUnreadMessageCount(
        client,
        locale,
        os,
        token,
        isdn,
        readFromDate,
        "network-only"
      );
    },
    [client, locale]
  );
}

export function useGetOrderNotification(): [
  () => Promise<NotificationEnableState | undefined>,
  CLError | null
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  // TODO(kenchan): Use resources request state
  const [error, setError] = useState<CLError | null>(null);

  const request = useCallback(async () => {
    try {
      return await getOrderNotification(client, locale);
    } catch (e) {
      const errorMessage = parseGraphQLError(e);
      if (errorMessage) {
        setError(RemoteError(errorMessage));
      } else {
        setError(LocalError("error.unknown"));
      }
      return undefined;
    }
  }, [client, locale]);

  return [request, error];
}

export function useGetPromotionNotification(): [
  () => Promise<NotificationEnableState | undefined>,
  CLError | null
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  // TODO(kenchan): Use resources request state
  const [error, setError] = useState<CLError | null>(null);

  const request = useCallback(async () => {
    try {
      return await getPromotionNotification(client, locale);
    } catch (e) {
      const errorMessage = parseGraphQLError(e);
      if (errorMessage) {
        setError(RemoteError(errorMessage));
      } else {
        setError(LocalError("error.unknown"));
      }
      return undefined;
    }
  }, [client, locale]);

  return [request, error];
}
