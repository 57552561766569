import React, { useEffect } from "react";
import { appEventEmitter } from "../utils/SimpleEventEmitter";
import { RootTab } from "../navigation/routes";
import { useKeepUpdatingRef } from "./utils";

export function useScrollToTopWhenClickTabBar(
  targetTab: RootTab,
  isScreenActive: boolean,
  contentRef: React.RefObject<HTMLIonContentElement>
) {
  const isScreenActiveRef = useKeepUpdatingRef(isScreenActive);
  useEffect(() => {
    const sub = appEventEmitter.subscribe(e => {
      if (
        e.type === "OnClickTabBar" &&
        e.tab === targetTab &&
        isScreenActiveRef.current
      ) {
        if (contentRef.current) {
          contentRef.current.scrollToTop(250);
        }
      }
    });
    return () => {
      sub.remove();
    };
  }, [contentRef, targetTab, isScreenActiveRef]);
}
