import { Plugins } from "@capacitor/core";
import {
  InAppBrowser,
  InAppBrowserEvent,
  InAppBrowserOptions,
} from "@ionic-native/in-app-browser";
import qs from "qs";
import { v4 } from "uuid";
import Config from "../Config";
import { Locale } from "../i18n/locale";
import { handleDeepLink, handleDynamicLink } from "./InAppBrowser";
import { isAndroid } from "./Platform";
import { appEventEmitter, AppEventOnClickViewMore } from "./SimpleEventEmitter";

const { Device } = Plugins;

const useBeforeLoad = isAndroid();

const options: InAppBrowserOptions = {
  zoom: "no",
  location: "no",
  clearsessioncache: "yes",
  beforeload: useBeforeLoad ? "yes" : undefined,
  clearcache: "yes",
};

function getKcLocale(locale: Locale) {
  switch (locale) {
    case Locale.en:
      return "en-US";
    case Locale.zhHant:
      return "zh-HK";
    default:
      return "";
  }
}

interface TheClubLoginUTMButtonPairUpAcct {
  utm_medium: "buapp";
  utm_source: "clubshopping";
  utm_content: "button_pair_up_acct";
}
export const TheClubLoginUTMButtonPairUpAcct: TheClubLoginUTMButtonPairUpAcct = {
  utm_medium: "buapp",
  utm_source: "clubshopping",
  utm_content: "button_pair_up_acct",
};

export type TheClubLoginUTM = TheClubLoginUTMButtonPairUpAcct;

const { protocol, host } = window.location;
export const redirectUri = `${
  protocol === "http:" || protocol === "https:" ? protocol : "https:"
}//${host}`;

function createLoginUrl(locale: Locale, utm?: TheClubLoginUTM) {
  const state = v4();
  const nonce = v4();
  const kcLocale = getKcLocale(locale);
  const queryString = qs.stringify({
    client_id: Config.THE_CLUB_UAT_CONFIG.CLIENT_ID,
    state,
    nonce,
    redirect_uri: redirectUri,
    response_type: "token",
    login: "true",
    scope: "openid",
    kc_idp_hint: Config.THE_CLUB_UAT_CONFIG.KC_IDP_HINT,
    back_uri: Config.THE_CLUB_UAT_CONFIG.BACK_URI,
    kc_locale: kcLocale,
    ...utm,
  });
  return (
    Config.THE_CLUB_UAT_CONFIG.AUTH_SERVER_URL +
    "realms/" +
    encodeURIComponent(Config.THE_CLUB_UAT_CONFIG.REALM) +
    "/protocol/openid-connect/auth" +
    "?" +
    queryString
  );
}

function parseUrlParamsToObject(url: any) {
  const hashes = url.slice(url.indexOf("?") + 1).split("&");
  return hashes.reduce((params: any, hash: any) => {
    const [key, val] = hash.split("=");
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
}

interface LoginTheClubResponse {
  token?: string;
  cancelled: boolean;
}

function loginWithTheClubWebPopup(
  loginUrl: string
): Promise<LoginTheClubResponse> {
  return new Promise(resolve => {
    let interval: number | undefined;
    let done = false;
    const clearInterval = () => {
      if (interval != null) {
        window.clearInterval(interval);
        interval = undefined;
      }
    };
    const height = 820;
    const width = 510;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const externalWindow = window.open(
      loginUrl,
      "The Club",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!externalWindow || !externalWindow.window) {
      throw Error("cannot-open-window");
    }

    externalWindow.opener = null;
    const checkCode = () => {
      try {
        // Clear interval if externalWindow is closed
        if (
          !externalWindow ||
          !externalWindow.window ||
          externalWindow.closed ||
          externalWindow.window.closed
        ) {
          clearInterval();
          if (!done) {
            resolve({ cancelled: true });
          }
          return;
        }
        const params = parseUrlParamsToObject(externalWindow.location.href);
        const token = params["access_token"] || undefined;
        if (!token) {
          return;
        }
        done = true;
        externalWindow.close();
        clearInterval();
        resolve({
          token,
          cancelled: false,
        });
      } catch (e) {
        console.debug(e);
      }
    };
    interval = window.setInterval(checkCode, 500);
  });
}

export function presentLoginWithTheClubBrowser(
  locale: Locale,
  utmContent?: TheClubLoginUTM
): Promise<LoginTheClubResponse> {
  return new Promise(resolve => {
    const loginUrl = createLoginUrl(locale, utmContent);
    Device.getInfo().then(deviceInfo => {
      if (deviceInfo.platform !== "web") {
        const browser = InAppBrowser.create(loginUrl, "_blank", options);
        const loadstart = browser.on("loadstart");
        if (loadstart) {
          const loadstartListener = loadstart.subscribe(
            (event: InAppBrowserEvent) => {
              const eventCallback = encodeURI(event.url);
              //Check the redirect uri
              if (eventCallback.includes(Config.THE_CLUB_UAT_CONFIG.BACK_URI)) {
                loadstartListener.unsubscribe();
                browser.close();
                resolve({ cancelled: true });
              }
              if (eventCallback.includes(redirectUri)) {
                loadstartListener.unsubscribe();
                browser.close();
                const parameters = parseUrlParamsToObject(event.url);
                resolve({
                  cancelled: false,
                  token: parameters.access_token,
                });
              }
            }
          );
        }

        if (useBeforeLoad) {
          const beforeload = browser.on("beforeload");
          if (beforeload) {
            const beforeloadListener = beforeload.subscribe(
              async (event: InAppBrowserEvent) => {
                const eventCallback = encodeURI(event.url);
                //Check the redirect uri
                if (
                  eventCallback.includes(Config.THE_CLUB_UAT_CONFIG.BACK_URI)
                ) {
                  beforeloadListener.unsubscribe();
                  browser.close();
                  resolve({ cancelled: true });
                  return;
                }
                if (eventCallback.includes(redirectUri)) {
                  beforeloadListener.unsubscribe();
                  browser.close();
                  const parameters = parseUrlParamsToObject(event.url);
                  resolve({
                    cancelled: false,
                    token: parameters.access_token,
                  });
                  return;
                }
                browser._loadAfterBeforeload(event.url);
              }
            );
          }
        }

        const exit = browser.on("exit");
        if (exit) {
          const exitListener = exit.subscribe(() => {
            exitListener.unsubscribe();
            resolve({ cancelled: true });
          });
        }

        const unsubscribeHandleDynamicLink = handleDynamicLink(url => {
          // Close browser and propagate the dynamic link target to app
          appEventEmitter.publish(AppEventOnClickViewMore(url));
          unsubscribeHandleDynamicLink();
          browser.close();
        }, browser);

        const unsubscribeHandleDeepLink = handleDeepLink(() => {
          // Simply close the browser when in app browser navigate to deeplink site
          unsubscribeHandleDeepLink();
          browser.close();
        }, browser);
      } else {
        resolve(loginWithTheClubWebPopup(loginUrl));
      }
    });
  });
}
