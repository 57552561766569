import { AlertButton, alertController, OverlayEventDetail } from "@ionic/core";
import isError from "lodash/isError";
import React, { useCallback, useMemo } from "react";
import { MessageArgs, useIntl } from "../i18n/Localization";
import { MessageID } from "../i18n/translations/type";

export interface LocalizedAlertButton extends Omit<AlertButton, "text"> {
  textMessageID: MessageID;
}

interface LocalizedAlertOptions {
  header?: string;
  headerId?: MessageID;
  subHeaderId?: MessageID;
  message?: string;
  messageId?: MessageID;
  messageArgs?: MessageArgs;
  buttons?: LocalizedAlertButton[];
  backdropDismiss?: boolean;
  keyboardClose?: boolean;
  onDidDismiss?: (detail: OverlayEventDetail<any>) => any;
}

interface LocalizedAlertContext {
  presentLocalizedAlert: (options: LocalizedAlertOptions) => void;
  presentLocalizedAlertOnAsyncError: <T>(
    fn: () => Promise<T>
  ) => Promise<T | null>;
}

export const LocalizedAlertContext = React.createContext<LocalizedAlertContext>(
  {
    presentLocalizedAlert: (_: LocalizedAlertOptions) => {},
    presentLocalizedAlertOnAsyncError: <T,>(fn: () => Promise<T>) => fn(),
  }
);

export const LocalizedAlertProvider: React.FC = props => {
  const { translate } = useIntl();
  const presentLocalizedAlert = useCallback(
    (options: LocalizedAlertOptions) => {
      const {
        headerId,
        subHeaderId,
        message: rawMessage,
        messageId,
        messageArgs,
        buttons,
        backdropDismiss,
        keyboardClose,
        onDidDismiss,
      } = options;
      const header = headerId
        ? translate(headerId)
        : options.header
        ? options.header
        : undefined;
      const subHeader = subHeaderId ? translate(subHeaderId) : undefined;
      const message = rawMessage
        ? rawMessage
        : messageId
        ? translate(messageId, messageArgs)
        : undefined;
      const localizedButtons = buttons
        ? buttons.map(button => ({
            text: translate(button.textMessageID),
            ...button,
          }))
        : undefined;
      alertController
        .create({
          header,
          subHeader,
          message,
          buttons: localizedButtons,
          backdropDismiss,
          keyboardClose,
        })
        .then(
          alert => {
            if (onDidDismiss) {
              alert.onDidDismiss().then(onDidDismiss);
            }
            alert.present();
          },
          _ => {}
        );
    },
    [translate]
  );

  const presentLocalizedAlertOnAsyncError = useCallback(
    <T,>(fn: () => Promise<T>): Promise<T | null> => {
      return fn().catch(e => {
        if (isError(e)) {
          presentLocalizedAlert({
            message: e.message,
            buttons: [{ textMessageID: "alert.button.ok" }],
          });
        }
        return null;
      });
    },
    [presentLocalizedAlert]
  );

  const contextValue = useMemo(
    () => ({
      presentLocalizedAlert,
      presentLocalizedAlertOnAsyncError,
    }),
    [presentLocalizedAlert, presentLocalizedAlertOnAsyncError]
  );
  return (
    <LocalizedAlertContext.Provider value={contextValue}>
      {props.children}
    </LocalizedAlertContext.Provider>
  );
};
