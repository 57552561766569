import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { IonTabButton, IonLabel, IonTabBar } from "@ionic/react";
import cn from "classnames";

import { LottieElement } from "../Lottie";
import DocumentLocationAwareLottie from "../DocumentLocationAwareLottie.lazy";

import {
  CategoryListPopoverProps,
  withCategoryListPopover,
} from "../CategoryListPopover";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { BingoListNotificationPopover } from "../BingoListNotificationPopover";

import { getCustomerProfilePicUrl } from "../../models/Customer";
import { LocalizedText, useIntl } from "../../i18n/Localization";

import {
  getPathForAllCategoriesPage,
  getPathForCategoryTab,
  getPathForArticleTab,
  getPathForHomePage,
  getPathForLikesTab,
  getPathForAccountTab,
  getPathForRedemptionTab,
  activeTabAsRootTab,
  RootTab,
} from "../../navigation/routes";

import { OurNavContext } from "../../our-navigation";

import { isTablet, isDesktop } from "../../utils/Platform";

import styles from "./AppNavigator.module.scss";
import { apply } from "../../utils/type";
import { useIsLoggedIn, useCustomer } from "../../repository/AuthRepository";
import ConfigContext from "../../contexts/ConfigContext";
import {
  appEventEmitter,
  AppEventOnClickTabBar,
} from "../../utils/SimpleEventEmitter";

import Config from "../../Config";

function shouldShowCategoryAsPopover() {
  return isTablet() || isDesktop();
}

const RootTabAnimation = {
  [RootTab.category]: require("../../resources/animation/tab-active-category.json"),
  [RootTab.articles]: require("../../resources/animation/tab-active-discovery.json"),
  [RootTab.home]: require("../../resources/animation/tab-active-home.json"),
  [RootTab.likes]: require("../../resources/animation/tab-active-likes.json"),
  [RootTab.account]: require("../../resources/animation/tab-active-account.json"),
  [RootTab.redemption]: require("../../resources/animation/tab-active-redemption.json"),
};

type SecondTabState = SecondTabStateDiscovery | SecondTabStateRedemption;

interface SecondTabStateDiscovery {
  type: "discovery";
}

interface SecondTabStateRedemption {
  type: "redemption";
}

type Props = CategoryListPopoverProps;

const TabBar: React.FC<Props> = props => {
  const { openCategoryListPopover } = props;
  const { navigate, replace, getActiveTab, getActivePathForTab } = useContext(
    OurNavContext
  );
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const activeTabLottieRef = useRef<LottieElement>(null);
  const { liveEvent, appConfig } = useContext(ConfigContext);
  const { locale } = useIntl();

  const customer = useCustomer();

  const [
    accountTabButton,
    setAccountTabButton,
  ] = useState<HTMLIonTabButtonElement | null>(null);
  const accountTabButtonRef: React.RefCallback<
    HTMLIonTabButtonElement
  > = useCallback((el: HTMLIonTabButtonElement | null) => {
    setAccountTabButton(el);
  }, []);

  // NOTE:(jasonkit)
  // IonTabButton's href attribute did not change after render even if its
  // driving value is changed. Do due with this, we don't set href and use
  // onClick to do navigation.
  // As there is a <a> under <ion-tab-button>, url change will take effect
  // which will push an entry to browser history. To make back button
  // work properly for tab switching, here we will use replace instead of
  // push for navigation.
  const onTarBarPress = useCallback(
    (e: React.MouseEvent<HTMLIonTabButtonElement>) => {
      const tab =
        (e.currentTarget && e.currentTarget.getAttribute("tab")) || "";
      const activeTab = getActiveTab();
      const path = (activeTab !== tab && getActivePathForTab(tab)) || `/${tab}`;
      replace(path);
      const activeRootTab = activeTabAsRootTab(tab);
      if (activeRootTab != null) {
        if (activeTabLottieRef.current) {
          activeTabLottieRef.current.play();
        }
        appEventEmitter.publish(AppEventOnClickTabBar(activeRootTab));
      }
    },
    [replace, getActiveTab, getActivePathForTab]
  );

  const renderCategoryTab = useCallback((isActive: boolean) => {
    return (
      <>
        {isActive ? (
          <DocumentLocationAwareLottie
            ref={activeTabLottieRef}
            className={styles.tabAnimation}
            play={true}
            loop={false}
            autoplay={true}
            animationData={RootTabAnimation[RootTab.category]}
          />
        ) : (
          <div className={styles.category} />
        )}
        <IonLabel className={isActive ? styles.isActiveTab : undefined}>
          <LocalizedText messageID="tab.category" />
        </IonLabel>
      </>
    );
  }, []);

  const handleCategoryClick = useCallback(
    (e: MouseEvent) => {
      if (shouldShowCategoryAsPopover()) {
        openCategoryListPopover(e);
      } else {
        onTarBarPress(e as any);
      }
    },
    [openCategoryListPopover, onTarBarPress]
  );

  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikesTab = useCallback(
    (e: React.MouseEvent<HTMLIonTabButtonElement>) => {
      if (isLoggedIn) {
        onTarBarPress(e);
      } else {
        e.preventDefault();
        e.stopPropagation();
        presentLoginModal(() => {
          navigate(getPathForLikesTab());
        });
      }
    },
    [isLoggedIn, onTarBarPress, presentLoginModal, navigate]
  );

  const renderArticleTab = useCallback(
    (isActive: boolean) => {
      const live = liveEvent && liveEvent[locale].enable;
      return (
        <>
          {isActive ? (
            <DocumentLocationAwareLottie
              ref={activeTabLottieRef}
              className={cn(styles.tabAnimation, { [styles.live]: live })}
              play={true}
              loop={false}
              autoplay={true}
              animationData={RootTabAnimation[RootTab.articles]}
            />
          ) : (
            <div className={cn(styles.article, { [styles.live]: live })} />
          )}
          <IonLabel className={isActive ? styles.isActiveTab : undefined}>
            <LocalizedText messageID="tab.articles" />
          </IonLabel>
        </>
      );
    },
    [liveEvent, locale]
  );

  const renderRedemptionTab = useCallback((isActive: boolean) => {
    return (
      <>
        {isActive ? (
          <DocumentLocationAwareLottie
            ref={activeTabLottieRef}
            className={styles.tabAnimation}
            play={true}
            loop={false}
            autoplay={true}
            animationData={RootTabAnimation[RootTab.redemption]}
          />
        ) : (
          <div className={styles.redemption} />
        )}
        <IonLabel className={cn({ [styles.isActiveTab]: isActive })}>
          <LocalizedText messageID="tab.rewards" />
        </IonLabel>
      </>
    );
  }, []);

  const renderHomeTab = useCallback((isActive: boolean) => {
    return (
      <>
        {isActive ? (
          <DocumentLocationAwareLottie
            ref={activeTabLottieRef}
            className={styles.tabAnimation}
            play={true}
            loop={false}
            autoplay={true}
            animationData={RootTabAnimation[RootTab.home]}
          />
        ) : (
          <div className={styles.home} />
        )}
        <IonLabel className={isActive ? styles.isActiveTab : undefined}>
          <LocalizedText messageID="tab.home" />
        </IonLabel>
      </>
    );
  }, []);

  const renderLikesTab = useCallback((isActive: boolean) => {
    return (
      <>
        {isActive ? (
          <DocumentLocationAwareLottie
            ref={activeTabLottieRef}
            className={styles.tabAnimation}
            play={true}
            loop={false}
            autoplay={true}
            animationData={RootTabAnimation[RootTab.likes]}
          />
        ) : (
          <div className={styles.likes} />
        )}
        <IonLabel className={isActive ? styles.isActiveTab : undefined}>
          <LocalizedText messageID="tab.likes" />
        </IonLabel>
      </>
    );
  }, []);

  const renderAccountTab = useCallback(
    (isActive: boolean) => {
      const profilePicUrl = customer
        ? getCustomerProfilePicUrl(customer)
        : null;
      return (
        <>
          {isLoggedIn ? (
            <div
              className={cn(styles.profileImageWrapper, {
                [styles.isActive]: isActive,
              })}
            >
              {profilePicUrl ? (
                <div
                  className={styles.profileImage}
                  style={{ backgroundImage: `url(${profilePicUrl})` }}
                />
              ) : (
                <div className={styles.profileImagePlaceholder} />
              )}
            </div>
          ) : isActive ? (
            <DocumentLocationAwareLottie
              ref={activeTabLottieRef}
              className={styles.tabAnimation}
              play={true}
              loop={false}
              autoplay={true}
              animationData={RootTabAnimation[RootTab.account]}
            />
          ) : (
            <div className={styles.account} />
          )}
          <IonLabel className={isActive ? styles.isActiveTab : undefined}>
            <span className={styles.customerName}>
              {customer ? (
                `${customer.firstname} ${customer.lastname}`
              ) : (
                <LocalizedText messageID="tab.account" />
              )}
            </span>
          </IonLabel>
          <BingoListNotificationPopover targetElement={accountTabButton} />
        </>
      );
    },
    [customer, isLoggedIn, accountTabButton]
  );

  const renderTab = useCallback(
    (
      path: string,
      renderContent: (isActive: boolean) => React.ReactNode,
      onClick: (e: any) => void,
      ref?: React.Ref<HTMLIonTabButtonElement>
    ) => {
      const tabName = path.replace(/\//, "");
      const isActive = location.pathname.startsWith(path);
      return (
        <IonTabButton
          tab={tabName}
          onClick={onClick}
          ref={ref}
          className={styles.tabButton}
        >
          {renderContent(isActive)}
        </IonTabButton>
      );
    },
    [location]
  );

  const secondTabState = useMemo<SecondTabState>(() => {
    if (appConfig == null) {
      return { type: "discovery" };
    }
    const appConfigLocalized = appConfig[locale];
    if (appConfigLocalized == null) {
      return { type: "discovery" };
    }
    const { redemptionTabCategoryId } = appConfigLocalized;
    if (
      Config.ENABLE_REDEMPTION_TAB &&
      redemptionTabCategoryId != null &&
      redemptionTabCategoryId !== "0"
    ) {
      return { type: "redemption" };
    }
    return { type: "discovery" };
  }, [appConfig, locale]);

  // Force IonTabBar to re-render to re-register
  // tab bar entries
  const ionTabBarKey = useMemo(() => {
    return secondTabState.type;
  }, [secondTabState]);

  return (
    <IonTabBar slot="bottom" key={ionTabBarKey}>
      {renderTab(
        shouldShowCategoryAsPopover()
          ? getPathForAllCategoriesPage()
          : getPathForCategoryTab(),
        renderCategoryTab,
        handleCategoryClick
      )}
      {secondTabState.type === "discovery"
        ? renderTab(getPathForArticleTab(), renderArticleTab, onTarBarPress)
        : renderTab(
            getPathForRedemptionTab(),
            renderRedemptionTab,
            onTarBarPress
          )}
      {renderTab(getPathForHomePage(), renderHomeTab, onTarBarPress)}
      {renderTab(getPathForLikesTab(), renderLikesTab, onClickLikesTab)}
      {renderTab(
        getPathForAccountTab(),
        renderAccountTab,
        onTarBarPress,
        accountTabButtonRef
      )}
    </IonTabBar>
  );
};

export default React.memo(withCategoryListPopover<{}>(TabBar));

export const TarBarHeightContext = createContext(0);
export const TarBarHeightProvider: React.FC = props => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    // Assume tabbar height won't change after initial layout
    const tryToGetAndSetTabBarHeight = () => {
      let h = 0;
      apply(document.querySelector("ion-tab-bar"), ionTabBar => {
        h = ionTabBar.getBoundingClientRect().height;
      });
      if (h > 0) {
        setHeight(h);
      }
      return h;
    };
    if (tryToGetAndSetTabBarHeight() > 0) {
      return;
    }
    const token = setInterval(() => {
      const tabBarHeight = tryToGetAndSetTabBarHeight();
      if (tabBarHeight) {
        clearInterval(token);
      }
    }, 100);
    return () => {
      clearInterval(token);
    };
  }, []);
  return (
    <TarBarHeightContext.Provider value={height}>
      {props.children}
    </TarBarHeightContext.Provider>
  );
};

export const TabBarSpacePlaceholder: React.FC = () => {
  const tabBarHeight = useContext(TarBarHeightContext);
  const style = useMemo<React.CSSProperties>(
    () => ({
      paddingBottom: tabBarHeight,
      width: "100%",
    }),
    [tabBarHeight]
  );
  return <div style={style} />;
};
