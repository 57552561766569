import React, { useContext } from "react";

import { OurNavContextState, OurNavContext } from "./index";

type ParentNavContext = OurNavContextState;

export const ParentNavContext = React.createContext<ParentNavContext>(
  null as any
);

export const ParentNavContextProvider: React.FC = props => {
  const navContext = useContext(OurNavContext);

  return (
    <ParentNavContext.Provider value={navContext}>
      {props.children}
    </ParentNavContext.Provider>
  );
};
