import React, {
  useMemo,
  useEffect,
  useCallback,
  useRef,
  useContext,
  useState,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import cn from "classnames";

import { Props as ArticleListProps } from "./ArticleList";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import { NavBar, NavBarBackButton } from "../NavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import {
  ArticleFilter,
  ArticlePreview,
  getAuthorDisplayName,
} from "../../models/Article";

import { PaginationInfo } from "../../repository/State";
import {
  useFetchArticleList,
  useFetchAuthorByID,
  useFetchAllArticleTagsByIDs,
  useFetchAllArticleCategories,
} from "../../repository/ArticleRepository";

import { getPathForArticleDetail, RootTab } from "../../navigation/routes";

import usePullToRefresh from "../../utils/pullToRefresh";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

import styles from "./FilteredArticleListPage.module.scss";
import { OurNavContext } from "../../our-navigation";
import { getResources } from "../../models/ResourcesRequestState";

type ViewState = ViewStateLoading | ViewStateDisplay;

interface ViewStateLoading {
  type: "loading";
}
function ViewStateLoading(): ViewStateLoading {
  return {
    type: "loading",
  };
}

interface ViewStateDisplay {
  type: "display";
  ArticleListComponent: React.ComponentType<ArticleListProps>;
  articleListPaginationInfo: PaginationInfo<ArticlePreview>;
}
function ViewStateDisplay(
  ArticleListComponent: React.ComponentType<ArticleListProps>,
  articleListPaginationInfo: PaginationInfo<ArticlePreview>
): ViewStateDisplay {
  return {
    type: "display",
    ArticleListComponent,
    articleListPaginationInfo,
  };
}

type Props = RouteComponentProps<{
  type: ArticleFilter;
  id: string;
}>;

const FilteredArticleListPage: React.FC<Props> = props => {
  const [
    ArticleListComponent,
    setArticleListComponent,
  ] = useState<React.ComponentType<ArticleListProps> | null>(null);
  useEffect(() => {
    import("./ArticleList").then(({ default: ArticleList }) => {
      setArticleListComponent(() => ArticleList);
    });
  }, []);

  const { match } = props;
  const {
    params: { type, id },
  } = match;
  const { navigate } = useContext(OurNavContext);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const { isOnline } = useContext(NetworkStatusContext);

  const {
    requestState,
    paginationInfo,
    fetchNext,
    refresh,
  } = useFetchArticleList({
    type,
    id,
  });
  useEffect(() => {
    fetchNext();
  }, [fetchNext]);

  const { handleRefresh: onRefreshArticlieList } = usePullToRefresh(
    requestState,
    refresh
  );

  const retry = useCallback(() => {
    refresh();
  }, [refresh]);

  const onClickArticleCell = useCallback(
    (article: ArticlePreview) => {
      navigate(getPathForArticleDetail(RootTab.articles, article.postId));
    },
    [navigate]
  );

  const viewState = useMemo<ViewState>(() => {
    if (!paginationInfo || ArticleListComponent == null) {
      return ViewStateLoading();
    }
    return ViewStateDisplay(ArticleListComponent, paginationInfo);
  }, [ArticleListComponent, paginationInfo]);

  const [authorResource, fetchAuthor] = useFetchAuthorByID();
  const author = getResources(authorResource);
  const [tagResource, fetchAllTags] = useFetchAllArticleTagsByIDs();
  const tags = getResources(tagResource);
  const {
    requestState: fetchAllArticleCategoriesRequestState,
    fetch: fetchAllArticleCategories,
  } = useFetchAllArticleCategories();
  const allArticleCategories = getResources(
    fetchAllArticleCategoriesRequestState
  );

  useEffect(() => {
    switch (type) {
      case "author":
        if (author == null) {
          fetchAuthor(id);
        }
        break;
      case "tag":
        if (tags == null) {
          fetchAllTags([id]);
        }
        break;
      case "category":
        if (allArticleCategories == null) {
          fetchAllArticleCategories();
        }
        break;
      default:
        break;
    }
  }, [
    type,
    id,
    author,
    fetchAuthor,
    tags,
    fetchAllTags,
    allArticleCategories,
    fetchAllArticleCategories,
  ]);

  const title = useMemo(() => {
    switch (type) {
      case "author":
        return author ? getAuthorDisplayName(author) : "";
      case "tag":
        return tags ? tags[0].title : "";
      case "category":
        if (allArticleCategories == null) return "";
        for (const articleCategory of allArticleCategories) {
          if (articleCategory.categoryId === id) {
            return articleCategory.title;
          }
        }
        return id;
      default:
        return id;
    }
  }, [type, id, author, tags, allArticleCategories]);

  return (
    <div className={cn("ion-page", styles.container)}>
      <NavBar headerLeft={<NavBarBackButton />} headerTitle={title} />
      <CLContent ref={contentRef} className={styles.content}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "display"}
          onRetry={retry}
        >
          {viewState.type === "loading" && <FullContentLoadingView />}
          {viewState.type === "display" && (
            <viewState.ArticleListComponent
              ionContentRef={contentRef}
              listContainerClassName={styles.articleListContainer}
              paginationInfo={viewState.articleListPaginationInfo}
              onRefresh={onRefreshArticlieList}
              onEndReach={fetchNext}
              onClickArticleCell={onClickArticleCell}
            />
          )}
        </NoInternetConnectionView>
      </CLContent>
      <TabBarSpacePlaceholder />
    </div>
  );
};

export default FilteredArticleListPage;
