import React, { useEffect } from "react";
import { isiPad, isTablet } from "../utils/Platform";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

function useLockOrientationForMobile() {
  useEffect(() => {
    if (isiPad() || isTablet()) {
      ScreenOrientation.unlock();
    } else {
      ScreenOrientation.lock(
        ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
      ).catch(() => {
        if (process.env.NODE_ENV === "development") {
          console.log("Cannot lock orientation");
        }
      });
    }
  }, []);
}

const DeviceOrientation: React.FunctionComponent = props => {
  useLockOrientationForMobile();
  return <>{props.children}</>;
};

export default DeviceOrientation;
