import React from "react";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./styles.module.scss";

interface Props {
  rebateAmount: number;
  className?: string;
}

const RebateCircle: React.FC<Props> = props => {
  const { rebateAmount, className } = props;

  return (
    <div className={cn(styles.rebateCircle, className)}>
      <p className={styles.amountRow}>
        <span className={styles.rebateAmount}>{rebateAmount}</span>%
      </p>
      <p>
        <LocalizedText messageID="rebate_circle.rebate" />
      </p>
    </div>
  );
};

export default RebateCircle;
