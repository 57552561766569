import { ApolloClient, gql, FetchPolicy } from "@apollo/client";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";
import { CustomerOrders } from "../../models/CustomerOrders";
import { useFetchResources } from "../../repository/Hooks";
import { useHandleSessionExpired } from "../../repository/AuthRepository";
import { useGraphQLFn } from "../../hook/graphql";
import { useRef, useEffect } from "react";
import { parseGraphQLError } from "../../api/GraphQL";

const customerOrdersGraphQLAttributes = `
items {
  id
  status
  createdAt: created_at
  grandTotal: grand_total
  incrementID: increment_id
  clubpointsUsed: clubpoints_used
  hasClubProtectionItem: has_club_protection_item
}
`;

export async function fetchCustomerOrders(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<CustomerOrders> {
  try {
    const result = await client.query<{ customerOrders: CustomerOrders }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryCustomerOrders {
        customerOrders {
          ${customerOrdersGraphQLAttributes}
        }
      }
    `,
      fetchPolicy,
    });
    return result.data.customerOrders;
  } catch (e) {
    throw parseGraphQLError(e);
  }
}

export function useCustomerOrdersResource() {
  const cachedCustomerOrders = useRef<CustomerOrders | null>(null);
  const fetchCustomerOrders_ = useHandleSessionExpired(
    useGraphQLFn(fetchCustomerOrders)
  );

  const {
    requestState,
    startRequesting,
    stopRequesting,
    retry,
  } = useFetchResources(
    {
      needStoreConfig: true,
      memoryCacheProvider: () => cachedCustomerOrders.current,
      localCacheProvider: () => fetchCustomerOrders_("cache-only"),
      didFetchFromLocalCache: customerOrders =>
        (cachedCustomerOrders.current = customerOrders),
      remoteResourcesProvider: () => fetchCustomerOrders_("network-only"),
      didFetchFromRemote: customerOrders =>
        (cachedCustomerOrders.current = customerOrders),
    },
    [fetchCustomerOrders_]
  );

  useEffect(() => {
    startRequesting();
    return () => {
      stopRequesting();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { customerOrdersState: requestState, retry };
}
