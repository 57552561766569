import { MutationDefinition } from "../../api/GraphQLMutation";
import { Maybe } from "../../types/Maybe";
import { getCartGraphQL } from "./api";
import {
  CartAddressInput,
  DeliveryEndpointParameters,
  DeliveryEndpointType,
  DeliveryTimeSlotValue,
  ShippingMethod,
} from "./models";

export enum OperationKey {
  address_setDeliveryEndpoint = "address_setDeliveryEndpoint",
  address_setDeliveryTimeSlot = "address_setDeliveryTimeSlot",
  address_setShippingAddress = "address_setShippingAddress",
  address_setShippingMethod = "address_setShippingMethod",
  o2o_setShippingAddress = "o2o_setShippingAddress",
  o2o_setDeliveryTimeSlot = "o2o_setDeliveryTimeSlot",
  o2o_setDeliveryEndpoint = "o2o_setDeliveryEndpoint",
  o2o_setShippingMethod = "o2o_setShippingMethod",
  setBillingAddress = "setBillingAddress",
  setPaymentMethod = "setPaymentMethod",
  setEmailAddress = "setEmailAddress",
}

export type MutationDefinitionWithCartId = (
  cartId: string
) => MutationDefinition;

export function withCartId<
  M extends MutationDefinition & {
    operationInput: {
      input?: { [key in string]: unknown };
    };
  }
>(mutationDefinition: M): MutationDefinitionWithCartId {
  return (cartId: string) => ({
    ...mutationDefinition,
    input: { ...mutationDefinition.input, cartId: "String!" },
    variables: { ...mutationDefinition.variables, cartId },
    operationInput: {
      ...mutationDefinition.operationInput,
      input: { ...mutationDefinition.operationInput.input, cart_id: "$cartId" },
    },
  });
}

const initFields = `
cart {
  id
}
`;

const lastFields = `
cart {
  ${getCartGraphQL("appliedCoupons", "discounts")}
}
`;

export function SetDeliveryTimeSlotMutationDefinition(
  deliveryTimeSlot: DeliveryTimeSlotValue | null,
  alias?: Maybe<string>
): MutationDefinitionWithCartId {
  return withCartId({
    input: { deliveryTimeSlot: "String" },
    variables: { deliveryTimeSlot: deliveryTimeSlot },
    alias,
    operation: "setDeliveryTimeSlotOnCart",
    operationInput: {
      input: {
        delivery_time_slot: "$deliveryTimeSlot",
      },
    },
    initFields,
    lastFields,
  });
}

export function SetShippingAddressesMutationDefinition(
  shippingAddress: CartAddressInput,
  alias?: Maybe<string>
): MutationDefinitionWithCartId {
  return withCartId({
    input: { shippingAddress: "CartAddressInput!" },
    variables: {
      shippingAddress: {
        city: shippingAddress.city,
        company: shippingAddress.company,
        country_code: shippingAddress.countryCode,
        firstname: shippingAddress.firstname,
        lastname: shippingAddress.lastname,
        region: shippingAddress.region,
        save_in_address_book: shippingAddress.saveInAddressBook,
        street: shippingAddress.street,
        telephone: shippingAddress.telephone,
      },
    },
    alias,
    operation: "setShippingAddressesOnCart",
    operationInput: {
      input: {
        shipping_addresses: "[{ address: $shippingAddress }]",
      },
    },
    initFields,
    lastFields,
  });
}

export function SetBillingAddressMutationDefinition(
  billingAddress: CartAddressInput | null,
  alias?: Maybe<string>
): MutationDefinitionWithCartId {
  return withCartId({
    input: { billingAddress: "CartAddressInput" },
    variables: {
      billingAddress: billingAddress
        ? {
            city: billingAddress.city,
            company: billingAddress.company,
            country_code: billingAddress.countryCode,
            firstname: billingAddress.firstname,
            lastname: billingAddress.lastname,
            region: billingAddress.region,
            save_in_address_book: billingAddress.saveInAddressBook,
            street: billingAddress.street,
            telephone: billingAddress.telephone,
          }
        : null,
    },
    alias,
    operation: "setBillingAddressOnCart",
    operationInput: {
      input: {
        billing_address: { address: "$billingAddress" },
      },
    },
    initFields,
    lastFields,
  });
}

export function SetPaymentMethodMutationDefinition(
  paymentMethodCode: string,
  alias?: Maybe<string>
): MutationDefinitionWithCartId {
  return withCartId({
    input: { paymentMethodCode: "String!" },
    variables: { paymentMethodCode },
    alias,
    operation: "setPaymentMethodOnCart",
    operationInput: {
      input: {
        payment_method: { code: "$paymentMethodCode" },
      },
    },
    initFields,
    lastFields,
  });
}

export function SetDeliveryEndpointMutationDefinition(
  deliveryEndpointType: DeliveryEndpointType,
  deliveryEndpointParameters: DeliveryEndpointParameters,
  alias?: Maybe<string>
): MutationDefinitionWithCartId {
  return withCartId({
    input: { deliveryEndpointType: "String!", o2oStoreCode: "String" },
    variables: {
      deliveryEndpointType,
      o2oStoreCode: deliveryEndpointParameters
        ? deliveryEndpointParameters.o2oStoreCode
        : null,
    },
    alias,
    operation: "setDeliveryEndpointOnCart",
    operationInput: {
      input: {
        delivery_endpoint_type: "$deliveryEndpointType",
        o2o_store_code: "$o2oStoreCode",
      },
    },
    initFields,
    lastFields,
  });
}

export function SetShippingMethodMutationDefinition(
  shippingMethod: ShippingMethod,
  alias?: Maybe<string>
): MutationDefinitionWithCartId {
  return withCartId({
    input: { shippingMethod: "ShippingMethodInput!" },
    variables: {
      shippingMethod: {
        carrier_code: shippingMethod.carrierCode,
        method_code: shippingMethod.methodCode,
      },
    },
    alias,
    operation: "setShippingMethodsOnCart",
    operationInput: {
      input: {
        shipping_methods: ["$shippingMethod"],
      },
    },
    initFields,
    lastFields,
  });
}

export function SetEmailAddressMutationDefinition(
  emailAddress: string,
  alias?: Maybe<string>
): MutationDefinitionWithCartId {
  return withCartId({
    input: { emailAddress: "String!" },
    variables: {
      emailAddress,
    },
    alias,
    operation: "setEmailAddressOnCart",
    operationInput: {
      input: {
        email_address: "$emailAddress",
      },
    },
    initFields,
    lastFields,
  });
}
