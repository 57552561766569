import {
  Plugins,
  PushNotification,
  PushNotificationActionPerformed,
  PushNotificationToken,
} from "@capacitor/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CollectMessageInfoAction } from "../../CLPlugins/AdobeExperiencePlatform";
import {
  getLinkFromPushNotification,
  Token,
} from "../../models/OPNSPushNotification";
import { isAdobeExperienceNotification } from "../../models/PushNotification";
import { getPathForPushNotificationMessages } from "../../navigation/routes";
import { PresentationContext } from "../../our-navigation";
import {
  useChangeDeviceTokenACS,
  useDeleteDeviceTokenACS,
  useRegisterDeviceTokenACS,
} from "../../repository/PushNotificationRepository";
import {
  appEventEmitter,
  AppEventPushNotificationGoTo,
} from "../../utils/SimpleEventEmitter";
import {
  LocalizedAlertButton,
  LocalizedAlertContext,
  LocalizedAlertProvider,
} from "../LocalizedAlertProvider";
import { withProviders } from "../Provider";
import { PushNotificationContext } from "../PushNotificationProvider";

interface SyncStateInitializing {
  type: "initializing";
}
const SyncStateInitializing: SyncStateInitializing = {
  type: "initializing",
};

interface SyncStateShouldRegister {
  type: "shouldRegister";
  token: Token;
}
function SyncStateShouldRegister(token: Token): SyncStateShouldRegister {
  return {
    type: "shouldRegister",
    token,
  };
}

interface SyncStateShouldDelete {
  type: "shouldDelete";
}
function SyncStateShouldDelete(): SyncStateShouldDelete {
  return {
    type: "shouldDelete",
  };
}

type SyncState =
  | SyncStateInitializing
  | SyncStateShouldRegister
  | SyncStateShouldDelete;

const {
  PushNotifications,
  BackgroundFCMPlugin,
  AdobeExperiencePlatform,
} = Plugins;

const ACSPushNotificationReceiver: React.FC = () => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const presentationContext = useContext(PresentationContext);

  const {
    enablePushNotification,
    enablePushNotificationInitialized,
    setDeviceToken,
    addUnreadMessageCount,
  } = useContext(PushNotificationContext);

  const pendingDeviceTokenRef = useRef<Token | null>(null);
  const [
    pendingDeviceTokenInitialized,
    setPendingDeviceTokenInitialized,
  ] = useState(false);

  const syncState = useMemo<SyncState>(() => {
    if (!pendingDeviceTokenInitialized || !enablePushNotificationInitialized) {
      return SyncStateInitializing;
    }
    const pendingDeviceToken = pendingDeviceTokenRef.current;
    if (enablePushNotification) {
      if (pendingDeviceToken) {
        return SyncStateShouldRegister(pendingDeviceToken);
      }
    }
    return SyncStateShouldDelete();
  }, [
    pendingDeviceTokenInitialized,
    enablePushNotificationInitialized,
    enablePushNotification,
  ]);

  const registerDeviceToken = useRegisterDeviceTokenACS();
  const changeDeviceToken = useChangeDeviceTokenACS();
  const deleteDeviceToken = useDeleteDeviceTokenACS();

  const [granted, setGranted] = useState(false);

  const handlePushNotificationReceived = useCallback(
    (notification: PushNotification) => {
      const buttons: LocalizedAlertButton[] = [
        {
          textMessageID: "alert.button.ok",
        },
      ];

      const link = getLinkFromPushNotification(notification);

      if (link) {
        buttons.push({
          textMessageID: "push_notification_alert.go_to",
          handler: () => {
            appEventEmitter.publish(AppEventPushNotificationGoTo(link));
          },
        });
      }

      const { title, body } = notification;

      if (title || body) {
        addUnreadMessageCount();
        presentLocalizedAlert({
          header: title,
          message: body,
          buttons: buttons,
        });
      }
    },
    [presentLocalizedAlert, addUnreadMessageCount]
  );

  const handlePushNotificationActionPerformed = useCallback(
    async (notification: PushNotificationActionPerformed) => {
      const link = getLinkFromPushNotification(notification.notification);
      // Collect message action to aes
      if (isAdobeExperienceNotification(notification.notification)) {
        await AdobeExperiencePlatform.collectMessageInfo({
          deliveryId: notification.notification.data._dId,
          broadlogId: notification.notification.data._mId,
          action: CollectMessageInfoAction.CLICK,
        });
        await AdobeExperiencePlatform.collectMessageInfo({
          deliveryId: notification.notification.data._dId,
          broadlogId: notification.notification.data._mId,
          action: CollectMessageInfoAction.OPEN_APP,
        });
      }
      if (link) {
        appEventEmitter.publish(AppEventPushNotificationGoTo(link));
        return;
      }
      presentationContext.present(getPathForPushNotificationMessages());
    },
    [presentationContext]
  );

  useEffect(() => {
    (async () => {
      const result = await PushNotifications.requestPermission();
      setGranted(result.granted);
    })();
  }, []);

  const [
    registrationEventRegistered,
    setRegistrationEventRegistered,
  ] = useState(false);

  useEffect(() => {
    if (!granted) {
      return;
    }

    const registerationListener = PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        pendingDeviceTokenRef.current = token.value;
        setPendingDeviceTokenInitialized(true);
      }
    );

    const registerationErrorListener = PushNotifications.addListener(
      "registrationError",
      (error: any) => {
        console.info("[DEV] Error on registration:", error);
      }
    );

    setRegistrationEventRegistered(true);

    return () => {
      registerationListener.remove();
      registerationErrorListener.remove();
    };
  }, [granted]);

  useEffect(() => {
    if (registrationEventRegistered) {
      PushNotifications.register();
    }
  }, [registrationEventRegistered]);

  // Capacitor pushNotificationReceived
  useEffect(() => {
    const pushNotifcationReceivedListener = PushNotifications.addListener(
      "pushNotificationReceived",
      handlePushNotificationReceived
    );

    return () => {
      if (
        // listener and listener.remove may not be available in ios mode of chrome
        pushNotifcationReceivedListener &&
        pushNotifcationReceivedListener.remove
      ) {
        pushNotifcationReceivedListener.remove();
      }
    };
  }, [handlePushNotificationReceived]);

  // FCM pushNotificationReceived
  useEffect(() => {
    if (BackgroundFCMPlugin && BackgroundFCMPlugin.addListener) {
      const pushNotifcationReceivedListener = BackgroundFCMPlugin.addListener(
        "pushNotificationReceived",
        handlePushNotificationReceived
      );

      return () => {
        if (
          // listener and listener.remove may not be available in ios mode of chrome
          pushNotifcationReceivedListener &&
          pushNotifcationReceivedListener.remove
        ) {
          pushNotifcationReceivedListener.remove();
        }
      };
    }
    return undefined;
  }, [handlePushNotificationReceived]);

  // Capacitor pushNotificationActionPerformed
  useEffect(() => {
    const pushNotificationActionPerformedListener = PushNotifications.addListener(
      "pushNotificationActionPerformed",
      handlePushNotificationActionPerformed
    );

    return () => {
      if (
        // listener and listener.remove may not be available in ios mode of chrome
        pushNotificationActionPerformedListener &&
        pushNotificationActionPerformedListener.remove
      ) {
        pushNotificationActionPerformedListener.remove();
      }
    };
  }, [handlePushNotificationActionPerformed]);

  // FCM pushNotificationActionPerformed
  useEffect(() => {
    if (BackgroundFCMPlugin && BackgroundFCMPlugin.addListener) {
      const pushNotificationActionPerformedListener = BackgroundFCMPlugin.addListener(
        "pushNotificationActionPerformed",
        handlePushNotificationActionPerformed
      );

      return () => {
        if (
          // listener and listener.remove may not be available in ios mode of chrome
          pushNotificationActionPerformedListener &&
          pushNotificationActionPerformedListener.remove
        ) {
          pushNotificationActionPerformedListener.remove();
        }
      };
    }
    return undefined;
  }, [handlePushNotificationActionPerformed]);

  useEffect(() => {
    if (pendingDeviceTokenInitialized) {
      setDeviceToken(pendingDeviceTokenRef.current);
    }
  }, [pendingDeviceTokenInitialized, setDeviceToken]);

  useEffect(() => {
    (async () => {
      if (syncState.type === "shouldRegister") {
        const { token } = syncState;
        await registerDeviceToken(token);
      } else if (syncState.type === "shouldDelete") {
        await deleteDeviceToken();
      }
    })();
  }, [
    syncState,
    setDeviceToken,
    registerDeviceToken,
    changeDeviceToken,
    deleteDeviceToken,
  ]);

  return null;
};

export default withProviders(
  ACSPushNotificationReceiver,
  LocalizedAlertProvider
);
