import React, {
  useCallback,
  useState,
  useRef,
  ImgHTMLAttributes,
  useEffect,
} from "react";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  maxReloadTimes?: number;
  retryTimeout?: number;
  htmlAttributes?: { [s: string]: string };
}

const CLImage: React.FC<Props> = props => {
  const {
    htmlAttributes,
    maxReloadTimes,
    retryTimeout = 5000,
    ...imgProps
  } = props;
  const { onError, alt } = imgProps;

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current && htmlAttributes) {
      const attrKeys = Object.keys(htmlAttributes);
      for (const key of attrKeys) {
        imgRef.current.setAttribute(key, htmlAttributes[key]);
      }
    }
  }, [htmlAttributes]);

  const reloadTimesRef = useRef(0);
  const [loadTimesKey, setLoadTimesKey] = useState(0);

  const handleError = useCallback(
    e => {
      if (maxReloadTimes != null && reloadTimesRef.current >= maxReloadTimes) {
        console.error(
          `Image load failed. Already loaded ${maxReloadTimes} times.`
        );
        if (onError) {
          onError(e);
        }
        return;
      }
      console.info(`Retry download image in ${retryTimeout}ms...`);
      setTimeout(() => {
        reloadTimesRef.current += 1;
        setLoadTimesKey(reloadTimesRef.current);
      }, retryTimeout);
    },
    [onError, maxReloadTimes, retryTimeout]
  );
  return (
    <img
      {...imgProps}
      ref={imgRef}
      key={loadTimesKey}
      onError={handleError}
      alt={alt}
      data-react-component="CLImage"
    />
  );
};

export default CLImage;
