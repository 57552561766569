import * as yup from "yup";
import moment from "moment";

import { Override } from "../utils/type";

export interface ProductReview {
  createdAt: Date | null;
  customerProfilePicture: string | null;
  detail: string | null;
  nickname: string | null;
  rating: ReviewRating[] | null;
}

export type RemoteProductReview = Override<
  ProductReview,
  {
    createdAt: string | null;
  }
>;

export interface ReviewRating {
  percent: number;
  ratingCode: string | null;
  ratingId: number;
  value: number;
}

export interface CustomerProductReview {
  createdAt: Date | null;
  detail: string | null;
  nickname: string | null;
  rating: ReviewRating[] | null;
  product: CustomerProductReviewProduct | null;
}

export interface CustomerProductReviewProduct {
  sku: string;
  name: string;
  vendorName: string | null;
}

export type RemoteCustomerProductReview = Override<
  CustomerProductReview,
  { createdAt: string | null }
>;

export const ReviewRatingGraphQLAttributes = `
percent
ratingCode: rating_code
ratingId: rating_id
value
`;

export const ProductReviewGraphQLAttributes = `
createdAt: created_at
customerProfilePicture: customer_profile_picture
detail
nickname
rating {
  ${ReviewRatingGraphQLAttributes}
}
`;

export const CustomerProductReviewGraphqlAttributes = `
createdAt: created_at
detail
nickname
product {
  sku
  name
  vendorName: vendor_name
}
rating {
  ${ReviewRatingGraphQLAttributes}
}
`;

export const ProductReviewSchema = yup.array(
  yup.object<ProductReview>({
    createdAt: yup.mixed().transform(p => {
      if (!p) {
        return null;
      }
      return moment(p).toDate();
    }),
    customerProfilePicture: yup.string().nullable(),
    detail: yup.string().nullable(),
    nickname: yup.string().nullable(),
    rating: yup.array(
      yup.object<ReviewRating>({
        percent: yup.number(),
        ratingCode: yup.string().nullable(),
        ratingId: yup.number(),
        value: yup.number(),
      })
    ),
  })
);

export const CustomerProductReviewSchema = yup.object<CustomerProductReview>({
  createdAt: yup.mixed().transform(p => {
    if (!p) {
      return null;
    }
    return moment(p).toDate();
  }),
  detail: yup.string().nullable(),
  nickname: yup.string().nullable(),
  product: yup
    .object<CustomerProductReviewProduct>({
      sku: yup.string(),
      name: yup.string(),
      vendorName: yup.string().nullable(),
    })
    .nullable(),
  rating: yup.array(
    yup.object<ReviewRating>({
      percent: yup.number(),
      ratingCode: yup.string().nullable(),
      ratingId: yup.number(),
      value: yup.number(),
    })
  ),
});

export const CustomerProductReviewsSchema = yup.array(
  CustomerProductReviewSchema
);

export interface RatingCode {
  options: RatingOption[] | null;
  ratingCode: string | null;
  ratingId: number | null;
}

export interface RatingOption {
  optionId: number | null;
  percent: number | null;
  value: number | null;
}

export interface RatingVote {
  ratingId: number;
  optionId: number;
}

export const RatingOptionGraphQLAttributes = `
optionId: option_id
percent
value
`;

export const RatingCodeGraphQLAttributes = `
options {
  ${RatingOptionGraphQLAttributes}
}
ratingCode: rating_code
ratingId: rating_id
`;
