import React, { useContext, useCallback, useMemo, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { IonRange } from "@ionic/react";
import { RangeChangeEventDetail } from "@ionic/core";
import debounce from "lodash.debounce";
import { ContentScrollProviderContext } from "./ContentScrollProvider";

const CLRange = React.forwardRef<
  HTMLIonRangeElement,
  React.ComponentProps<typeof IonRange>
>((props, ref) => {
  const { updateContentYScrollEnabled, isContentYScrollEnabled } = useContext(
    ContentScrollProviderContext
  );

  const { onIonFocus, onIonChange } = props;

  const prevContentYScrollEnabled = useRef(isContentYScrollEnabled);

  const resumeContentYScrollable = useMemo(
    () =>
      debounce(
        () => updateContentYScrollEnabled(prevContentYScrollEnabled.current),
        200
      ),
    [updateContentYScrollEnabled]
  );

  const handleFocus = useCallback(
    (event: CustomEvent<void>) => {
      if (onIonFocus) {
        onIonFocus(event);
      }
      prevContentYScrollEnabled.current = isContentYScrollEnabled;
    },
    [onIonFocus, isContentYScrollEnabled]
  );

  const handleChange = useCallback(
    (event: CustomEvent<RangeChangeEventDetail>) => {
      if (onIonChange) {
        onIonChange(event);
      }
      updateContentYScrollEnabled(false);
      resumeContentYScrollable();
    },
    [onIonChange, updateContentYScrollEnabled, resumeContentYScrollable]
  );

  return (
    <IonRange
      {...props}
      onIonFocus={handleFocus}
      onIonChange={handleChange}
      ref={ref}
    />
  );
});

export default CLRange;
