import React, { useMemo } from "react";
import moment from "moment-timezone";

import { RecurringDateType } from "../models/product";

import Config from "../Config";

interface Props {
  recurringDate: RecurringDateType;
}

const RecurringDate: React.FC<Props> = props => {
  const { recurringDate } = props;

  const display = useMemo(() => {
    if (recurringDate.type === "unknown") {
      return recurringDate.message;
    }
    return moment(recurringDate.date)
      .tz(Config.TIMEZONE)
      .format("YYYY-MM-DD");
  }, [recurringDate]);

  return <>{display}</>;
};

export default RecurringDate;
