import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";

import {
  getAggregation,
  getSearchedProductOverviews,
  getSortFields,
} from "../../api/GraphQL";
import { TranslateFn, useIntl } from "../../i18n/Localization";
import { PaginationInfo } from "../../repository/State";
import ProductAttributeFilterContext from "../../contexts/ProductAttributeFilterContext";
import { useGetValidSortFields } from "../../repository/ProductAttributeFilter";
import { ProductOverview } from "../../models/ProductOverview";
import { ProductLabel } from "../../models/ProductLabel";
import {
  defaultProductFilterInfo,
  FilterAttribute,
  getFilterAttributeMapsFromFilters,
  makeGraphQLFilterFromParamsMap,
  ProductFilterInfo,
  RangeFilterAttribute,
  removeRangeFilterAttribute,
  removeSingleLevelAndMultiLevelFilterAttribute,
  SortFieldOption,
  SortFields,
  SortOrder,
} from "../../models/filter";
import {
  getResources,
  isRequestLoading,
  ResourcesRequestState,
} from "../../models/ResourcesRequestState";
import { ModelKeys } from "../../models/product";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { convertAggregationToFilter } from "../../utils/Filter";
import { profileSyncAction } from "../../utils/performance";
import { SearchPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { useKeepUpdatingRef } from "../../hook/utils";

import {
  useSearchPageProductListResource,
  useSearchPageResource,
} from "./SearchPageRepository";

interface ViewModel {
  singleLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute;
  };
  multiLevelLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute[];
  };
  rangeFilterAttributeMap: {
    [attributeCode in string]: RangeFilterAttribute;
  };
  sortFields: SortFields;

  productFilterInfo: ProductFilterInfo;
  applyProductFilterInfo: (
    productFilterInfo: ProductFilterInfo
  ) => Promise<unknown>;

  clearFilter: (filter: FilterAttribute) => Promise<unknown>;
  clearRangeFilter: (filter: RangeFilterAttribute) => Promise<unknown>;
  clearPredefinedCPFilter: () => Promise<unknown>;

  clearAllFilter: () => Promise<unknown>;

  isFullPageLoading: boolean;

  // Products
  productOverviews: ProductOverview[];
  productLabelsByProductIdMap: { [key in number]: ProductLabel[] };
  bundleByProductIdMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
  searchProductOverviewsRequestState: ResourcesRequestState<unknown>;
  fetchNextProductOverviews: () => Promise<unknown>;
  isProductListLoadingMore: boolean;

  // Operations
  refresh: () => Promise<void>;
}

function getSortField(sortField: string) {
  return sortField === "position" ? "relevance" : sortField;
}

function getDefaultSortOrder(sortField: string | null) {
  return sortField === "relevance" ? SortOrder.descending : SortOrder.ascending;
}

function transformSortFieldOption(
  sortFieldOption: SortFieldOption,
  translate: TranslateFn
): SortFieldOption {
  const { value } = sortFieldOption;
  if (value === "position") {
    return {
      value: "relevance",
      label: translate("filter.sort.relevance"),
    };
  }
  return sortFieldOption;
}

function transformSortFields(
  sortFields: SortFields | null,
  translate: TranslateFn
): SortFields | null {
  if (sortFields == null) {
    return null;
  }

  return {
    defaultSortField: sortFields.defaultSortField
      ? getSortField(sortFields.defaultSortField)
      : null,
    sortFieldOptions: sortFields.sortFieldOptions.map(sortFieldOption =>
      transformSortFieldOption(sortFieldOption, translate)
    ),
  };
}

function useViewModel(
  search: string,
  queryParams: { [key in string]: string }
): ViewModel {
  const client = useApolloClient();
  const { translate } = useIntl();
  const {
    productAttributeFilterFields,
    productAttributeFilterInputMap,
  } = useContext(ProductAttributeFilterContext);

  const fixedFilter = useMemo(
    () =>
      makeGraphQLFilterFromParamsMap(
        {},
        productAttributeFilterFields,
        productAttributeFilterInputMap
      ),
    [productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const queryFilter = useMemo(
    () =>
      makeGraphQLFilterFromParamsMap(
        queryParams,
        productAttributeFilterFields,
        productAttributeFilterInputMap
      ),
    [queryParams, productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const filter = useMemo(() => ({ ...fixedFilter, ...queryFilter }), [
    fixedFilter,
    queryFilter,
  ]);

  const aggregations = useMemo(
    () =>
      profileSyncAction(
        SearchPageSession(search),
        "Load filters from cache",
        () => getAggregation(client, "", filter)
      ),
    [search, client, filter]
  );

  const filterTypes = useMemo(
    () => (aggregations ? aggregations.map(convertAggregationToFilter) : []),
    [aggregations]
  );

  const _filterAttributeMapsFromFiters = useMemo(
    () =>
      getFilterAttributeMapsFromFilters(
        filterTypes,
        queryParams,
        productAttributeFilterInputMap
      ),
    [filterTypes, queryParams, productAttributeFilterInputMap]
  );

  const [
    {
      singleLevelFilterAttributeMap,
      multiLevelLevelFilterAttributeMap,
      rangeFilterAttributeMap,
    },
    setFilterAttributeMap,
  ] = useState(_filterAttributeMapsFromFiters);

  const getValidSortFields = useGetValidSortFields();

  const _sortFields = useMemo(
    () =>
      getValidSortFields(
        transformSortFields(
          profileSyncAction(
            SearchPageSession(search),
            "Load sort fields from cache",
            () => getSortFields(client, search, {})
          ),
          translate
        )
      ),
    [getValidSortFields, client, search, translate]
  );

  const [sortFields, setSortFields] = useState(_sortFields);

  const sortRows = useMemo(() => {
    if (sortFields) {
      return sortFields.sortFieldOptions;
    }
    return [];
  }, [sortFields]);

  const defaultSortField = sortFields ? sortFields.defaultSortField : null;

  const [, fetchSearchPageResources] = useSearchPageResource(
    search,
    queryParams
  );

  const [
    searchProductOverviewsRequestState,
    searchNextProductOverviews,
    refreshSearchProductOverviews,
  ] = useSearchPageProductListResource(search);

  const [isProductListLoadingMore, setIsProductListLoadingMore] = useState(
    false
  );

  const [productFilterInfo, setProductFilterInfo] = useState<ProductFilterInfo>(
    {
      ...defaultProductFilterInfo({
        sortField: defaultSortField,
        sortOrder: getDefaultSortOrder(defaultSortField),
      }),
    }
  );

  const productFilterInfoRef = useKeepUpdatingRef(productFilterInfo);

  const applyProductFilterInfo = useCallback(
    async (_productFilterInfo: ProductFilterInfo) => {
      const newProductFilterInfo = {
        ...productFilterInfo,
        ..._productFilterInfo,
      };
      setProductFilterInfo(newProductFilterInfo);
      await refreshSearchProductOverviews(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshSearchProductOverviews]
  );

  const clearFilter = useCallback(
    async (appliedFilter: FilterAttribute) => {
      const newProductFilterInfo = removeSingleLevelAndMultiLevelFilterAttribute(
        productFilterInfo,
        appliedFilter
      );
      setProductFilterInfo(newProductFilterInfo);
      await refreshSearchProductOverviews(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshSearchProductOverviews]
  );

  const clearRangeFilter = useCallback(
    async (appliedFilter: RangeFilterAttribute) => {
      const newProductFilterInfo = removeRangeFilterAttribute(
        productFilterInfo,
        appliedFilter
      );
      setProductFilterInfo(newProductFilterInfo);
      await refreshSearchProductOverviews(1, newProductFilterInfo);
    },
    [productFilterInfo, refreshSearchProductOverviews]
  );

  const clearPredefinedCPFilter = useCallback(async () => {
    const newProductFilterInfo = {
      ...productFilterInfo,
      predefinedCPFilter: null,
    };
    setProductFilterInfo(newProductFilterInfo);
    await refreshSearchProductOverviews(1, newProductFilterInfo);
  }, [productFilterInfo, refreshSearchProductOverviews]);

  const clearAllFilter = useCallback(async () => {
    const newProductFilterInfo = defaultProductFilterInfo(
      productFilterInfo.sortAttribute
    );
    setProductFilterInfo(newProductFilterInfo);
    await refreshSearchProductOverviews(1, newProductFilterInfo);
  }, [productFilterInfo, refreshSearchProductOverviews]);

  const productOverviewRequestResponse = useMemo(
    () =>
      profileSyncAction(
        SearchPageSession(search),
        "Load Product Overviews from cache",
        () =>
          getSearchedProductOverviews(
            client,
            search,
            productFilterInfo,
            productAttributeFilterInputMap,
            1
          )
      ),
    [client, search, productFilterInfo, productAttributeFilterInputMap]
  );

  const [
    productOverviewsPaginationInfo,
    setProductOverviewsPaginationInfo,
  ] = useState<PaginationInfo<ProductOverview>>({
    currentPage: 1,
    items: productOverviewRequestResponse
      ? productOverviewRequestResponse.productOverviews
      : [],
    hasMore: false,
  });

  const productOverviews = productOverviewsPaginationInfo.items;
  const [
    productLabelsByProductIdMap,
    setProductLabelsByProductIdMap,
  ] = useState<{ [key in number]: ProductLabel[] }>({});
  const [bundleByProductIdMap, setBundleByProductIdMap] = useState<
    { [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys> }
  >({});

  useEffect(() => {
    const resources = getResources(searchProductOverviewsRequestState);
    if (!isRequestLoading(searchProductOverviewsRequestState) && resources) {
      const {
        currentPage: page,
        pageSize,
        productIdLabelMap,
        productIdBundleMap,
      } = resources;
      setProductOverviewsPaginationInfo(prev => {
        return {
          currentPage: resources.currentPage,
          items:
            page > prev.currentPage
              ? [...prev.items, ...resources.productOverviews]
              : prev.items
                  .slice(0, (page - 1) * pageSize)
                  .concat(resources.productOverviews),
          hasMore: resources.hasMore,
        };
      });
      setProductLabelsByProductIdMap(prev => ({
        ...prev,
        ...productIdLabelMap,
      }));
      setBundleByProductIdMap(prev => ({
        ...prev,
        ...productIdBundleMap,
      }));
    }
  }, [searchProductOverviewsRequestState]);

  const fetchNext = useCallback(async () => {
    if (
      !productOverviewsPaginationInfo.hasMore ||
      isRequestLoading(searchProductOverviewsRequestState)
    ) {
      return;
    }
    const currentPage = productOverviewsPaginationInfo.currentPage + 1;
    setIsProductListLoadingMore(true);
    await searchNextProductOverviews(currentPage, productFilterInfo);
    setIsProductListLoadingMore(false);
  }, [
    productOverviewsPaginationInfo,
    searchProductOverviewsRequestState,
    searchNextProductOverviews,
    productFilterInfo,
  ]);

  const refresh = useCallback(async () => {
    const searchPageResource = await fetchSearchPageResources();
    setFilterAttributeMap({
      singleLevelFilterAttributeMap:
        searchPageResource.singleLevelFilterAttributeMap,
      multiLevelLevelFilterAttributeMap:
        searchPageResource.multiLevelLevelFilterAttributeMap,
      rangeFilterAttributeMap: searchPageResource.rangeFilterAttributeMap,
    });

    const newSortFields = getValidSortFields(
      transformSortFields(searchPageResource.sortFields, translate)
    );
    setSortFields(newSortFields);

    const shouldChangeSortAttribute = newSortFields
      ? newSortFields.sortFieldOptions.find(
          s => s.value === productFilterInfoRef.current.sortAttribute.sortField
        ) != null
        ? false
        : true
      : true;

    const newSortField =
      shouldChangeSortAttribute &&
      newSortFields &&
      newSortFields.defaultSortField
        ? newSortFields.defaultSortField
        : productFilterInfoRef.current.sortAttribute.sortField;

    const _productFilterInfo: ProductFilterInfo = {
      ...productFilterInfoRef.current,
      sortAttribute: shouldChangeSortAttribute
        ? {
            sortField: newSortField,
            sortOrder: getDefaultSortOrder(newSortField),
          }
        : productFilterInfoRef.current.sortAttribute,
    };

    setProductFilterInfo(_productFilterInfo);
    await refreshSearchProductOverviews(1, _productFilterInfo);
  }, [
    fetchSearchPageResources,
    getValidSortFields,
    translate,
    refreshSearchProductOverviews,
    productFilterInfoRef,
  ]);

  const isFullPageLoading = useMemo(() => {
    if (
      productOverviewsPaginationInfo.items.length === 0 &&
      !getResources(searchProductOverviewsRequestState) &&
      (searchProductOverviewsRequestState.type === "initial" ||
        isRequestLoading(searchProductOverviewsRequestState))
    ) {
      return true;
    }
    return false;
  }, [productOverviewsPaginationInfo, searchProductOverviewsRequestState]);

  return {
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
    sortFields: {
      defaultSortField: defaultSortField,
      sortFieldOptions: sortRows,
    },

    productFilterInfo,
    applyProductFilterInfo,
    clearFilter,
    clearRangeFilter,
    clearPredefinedCPFilter,
    clearAllFilter,

    isFullPageLoading,

    productOverviews,
    productLabelsByProductIdMap,
    bundleByProductIdMap,
    searchProductOverviewsRequestState,
    fetchNextProductOverviews: fetchNext,
    isProductListLoadingMore,

    refresh,
  };
}

export default useViewModel;
