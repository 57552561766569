export type SemverComparator = "<" | "<=" | ">" | ">=" | "=";

export function semverCmp(
  cmp: SemverComparator,
  v1: string,
  v2: string
): boolean {
  const v1v = v1.split(".");
  const v2v = v2.split(".");

  const splitLength = Math.max(v1v.length, v2v.length);
  for (let i = 0; i < splitLength; i++) {
    const v1value = i > v1v.length - 1 ? 0 : parseInt(v1v[i], 10);
    const v2value = i > v2v.length - 1 ? 0 : parseInt(v2v[i], 10);
    switch (cmp) {
      case "<": {
        if (i !== splitLength - 1 ? v1value > v2value : v1value >= v2value) {
          return false;
        }
        break;
      }
      case "<=": {
        if (v1value > v2value) {
          return false;
        }
        break;
      }
      case ">": {
        if (i !== splitLength - 1 ? v1value < v2value : v1value <= v2value) {
          return false;
        }
        break;
      }
      case ">=": {
        if (v1value < v2value) {
          return false;
        }
        break;
      }
      case "=": {
        if (v1value !== v2value) {
          return false;
        }
        break;
      }
      default:
        return false;
    }
  }

  return true;
}
