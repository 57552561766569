import { Plugins } from "@capacitor/core";
import { Locale } from "../../i18n/locale";
import { V2Locale } from "./";

const { MigrateToV2Plugin } = Plugins;

export function setToken(token: string | null) {
  return MigrateToV2Plugin.setToken({ token });
}

export function setLocale(locale: Locale | null) {
  const v2Locale: V2Locale =
    locale === Locale.en ? V2Locale.en : V2Locale.zhHant;
  return MigrateToV2Plugin.setLocale({ locale: v2Locale });
}
