import React, { useMemo } from "react";
import cn from "classnames";
import { Decimal } from "decimal.js";

import SaleBadge from "./SaleBadge";
import NewBadge from "./NewBadge";
import ExtraClubPointBadge from "./ExtraClubPointBadge";
import ClubPointRedemptionBadge from "./ClubPointRedemptionBadge";

import {
  isProductSale,
  isProductNew,
  isProductOrItsVariantsHasMinClubPoint,
} from "../../models/product";
import { ProductOverview } from "../../models/ProductOverview";

import Config from "../../Config";

import styles from "./styles.module.scss";
import FreeDeliveryBadge from "./FreeDeliveryBadge";
import BundleBadge from "./BundleBadge";

type ViewState = ViewStateRebate | ViewStateRedemption | ViewStateNormal;

interface ViewStateRedemption {
  type: "redemption";
}

interface ViewStateRebate {
  type: "rebate";
  hasMinClubPoint: boolean;
}

interface ViewStateNormal {
  type: "normal";
  extraClubpoints: number;
  isNew: boolean;
  isSale: boolean;
}

interface Props {
  productOverview: ProductOverview;
  hasBundle?: boolean;
  maxNumberOfBadgesToRender?: number;
  containerClassName?: string;
  showFreeShippingBadge?: boolean;
}

const ProductBadges: React.FC<Props> = React.memo(props => {
  const {
    productOverview,
    hasBundle,
    maxNumberOfBadgesToRender,
    containerClassName,
    showFreeShippingBadge,
  } = props;

  const viewState = useMemo<ViewState>(() => {
    const { extraClubpoints, clClubPoint } = productOverview;
    const hasMinClubPoint = isProductOrItsVariantsHasMinClubPoint(
      productOverview
    );
    const clClubPointDecimal = clClubPoint
      ? new Decimal(clClubPoint)
      : new Decimal(0);
    const extraClubPointDecimal = extraClubpoints
      ? new Decimal(extraClubpoints)
      : new Decimal(0);
    const additionalClubpoints =
      extraClubPointDecimal.toNumber() + clClubPointDecimal.toNumber();
    if (additionalClubpoints > 0) {
      return { type: "rebate", hasMinClubPoint };
    }
    if (hasMinClubPoint) {
      return { type: "redemption" };
    }
    return {
      type: "normal",
      extraClubpoints: additionalClubpoints,
      isNew:
        Config.ENABLE_SALE_AND_NEW_PRODUCT_TAG && isProductNew(productOverview)
          ? true
          : false,
      isSale:
        Config.ENABLE_SALE_AND_NEW_PRODUCT_TAG && isProductSale(productOverview)
          ? true
          : false,
    };
  }, [productOverview]);

  const badges = useMemo(() => {
    const elements: React.ReactNodeArray =
      viewState.type === "rebate"
        ? [
            viewState.hasMinClubPoint ? (
              <ClubPointRedemptionBadge key="redemption" />
            ) : null,
          ]
        : viewState.type === "redemption"
        ? [<ClubPointRedemptionBadge key="redemption" />]
        : viewState.type === "normal"
        ? [
            viewState.isSale ? <SaleBadge key="sale" /> : null,
            viewState.extraClubpoints > 1 ? (
              <ExtraClubPointBadge
                point={viewState.extraClubpoints}
                key="bonus"
              />
            ) : null,
            viewState.isNew ? <NewBadge key="new" /> : null,
          ]
        : [];
    return elements.filter(e => !!e);
  }, [viewState]);

  const additionalBadges = useMemo(() => {
    const elements: React.ReactNodeArray = [];
    if (showFreeShippingBadge && productOverview.isFreeShipping) {
      elements.push(<FreeDeliveryBadge />);
    }
    if (hasBundle) {
      elements.push(<BundleBadge />);
    }
    return elements.filter(e => !!e);
  }, [productOverview, showFreeShippingBadge, hasBundle]);

  const allBadges = useMemo(() => {
    const res = [...badges, ...additionalBadges];
    if (maxNumberOfBadgesToRender == null) {
      return res;
    }
    return res.slice(0, maxNumberOfBadgesToRender);
  }, [badges, additionalBadges, maxNumberOfBadgesToRender]);

  if (allBadges.length === 0) {
    return null;
  }

  return (
    <div className={cn(styles.badgesContainer, containerClassName)}>
      {allBadges}
    </div>
  );
});

export default ProductBadges;
