import React, { useMemo, useCallback, useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { IonContent } from "@ionic/react";
import { useRefToRefObject } from "../../utils/Ref";
import { timeout } from "../../utils/promise";
import { ContentScrollProviderContext } from "../ContentScrollProvider";

interface CLContentContext {
  scrollToElement: (el: HTMLElement, offset?: number) => void;
}

export const CLContentContext = React.createContext<CLContentContext>(
  null as any
);

interface CLContentProviderProps {
  ionContentRef: React.RefObject<HTMLIonContentElement>;
}

export const CLContentProvider: React.FC<CLContentProviderProps> = props => {
  const { ionContentRef } = props;

  const {
    updateContentYScrollEnabled,
    isContentYScrollEnabled,
  } = React.useContext(ContentScrollProviderContext);

  const scrollToElement = useCallback(
    async (el: HTMLElement, offset?: number) => {
      if (!ionContentRef.current) {
        return;
      }
      const { top: elTop } = el.getBoundingClientRect();
      const scrollEl = await ionContentRef.current.getScrollElement();
      const { scrollTop } = scrollEl;
      const { top: scrollElTop } = scrollEl.getBoundingClientRect();
      return ionContentRef.current.scrollToPoint(
        undefined,
        elTop + scrollTop - scrollElTop - (offset || 0),
        200
      );
    },
    [ionContentRef]
  );

  const onStatusBarTap = useCallback(async () => {
    if (!ionContentRef.current) {
      return;
    }
    const prevIsContentYScrollEnabled = isContentYScrollEnabled;
    updateContentYScrollEnabled(false);
    await timeout(100);
    await ionContentRef.current.scrollToPoint(undefined, 0, 200);
    await timeout(100);
    updateContentYScrollEnabled(prevIsContentYScrollEnabled);
  }, [isContentYScrollEnabled, ionContentRef, updateContentYScrollEnabled]);

  useEffect(() => {
    window.addEventListener("statusTap", onStatusBarTap);
    return () => {
      window.removeEventListener("statusTap", onStatusBarTap);
    };
  }, [onStatusBarTap]);

  const contextValue = useMemo(
    () => ({
      scrollToElement,
    }),
    [scrollToElement]
  );

  return (
    <CLContentContext.Provider value={contextValue}>
      {props.children}
    </CLContentContext.Provider>
  );
};

const WrappedIonContent = (
  props: React.ComponentProps<typeof IonContent>,
  ref?: React.Ref<HTMLIonContentElement>
) => {
  const { isContentYScrollEnabled } = React.useContext(
    ContentScrollProviderContext
  );

  const refObject = useRefToRefObject(ref);

  return (
    <CLContentProvider ionContentRef={refObject}>
      <IonContent
        scrollY={isContentYScrollEnabled}
        {...props}
        ref={refObject}
      />
    </CLContentProvider>
  );
};

const CLContent = React.forwardRef(WrappedIonContent);

export default CLContent;
