import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useRef,
} from "react";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonFooter,
  IonToolbar,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonLifeCycleContextInterface,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";

import {
  NavBar,
  NavBarBackButton,
  NavBarHomeButton,
  NavBarLiveChatButton,
} from "../NavBar";

import AddToCartButton from "./AddToCartButton.lazy";
import PreOrderButton from "./PreOrderButton";
import CLContent from "../CLContent";
import { FullContentLoadingView } from "../LoadingView";
import ShoppingCartButton from "../ShoppingCartButton";
import { BlackButton } from "../Button";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import {
  getPathForHomePage,
  RootTab,
  useCurrentTab,
  getPathForShoppingCart,
  getPathForTab,
} from "../../navigation/routes";

import {
  useIsProductLiked,
  useCachedProductOverview,
} from "../../repository/ProductRepository";
import {
  useInMemoryProductReviews,
  useFetchProductReviews,
} from "../../repository/ProductReviewRepository";
import {
  useStoreConfig,
  useAppConfig,
} from "../../repository/ConfigRepository";
import { useIsLoggedIn } from "../../repository/AuthRepository";

import {
  ThirdPartyProduct,
  isThirdPartyProduct,
  ModelKeys,
  IsSoldOutFunction,
  ShouldProductShowPriceFunction,
} from "../../models/product";
import { Product, ConfiguredProduct } from "../../models/ProductDetails";
import { ProductOverview } from "../../models/ProductOverview";
import {
  ResourcesRequestState,
  getResources,
  isRequestError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import { StoreConfig } from "../../models/StoreConfig";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";

import Config from "../../Config";

import styles from "./styles.module.scss";

import {
  LocalizedAlertContext,
  LocalizedAlertButton,
} from "../LocalizedAlertProvider";
import { PurchaseProductFormStateReducer } from "./PurchaseProductModal/PurchaseProductFormStateHook";
import { WishlistContext } from "../WishlistProvider";
import { PresentationContext, OurNavContext } from "../../our-navigation";
import { LocalizedText } from "../../i18n/Localization";

import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";
import { timeout } from "../../utils/promise";
import SoldOutButton from "./SoldOutButton";
import { pageView } from "../../utils/GTM";
import {
  appEventEmitter,
  AppEventOnClickThirdPartyProductButtonUrl,
} from "../../utils/SimpleEventEmitter";
import useViewEnterForAsyncContent from "../../hook/useViewEnterForAsyncContent";
import useOpenUrlWithBrowser from "../../hook/useOpenUrlWithBrowser";
import {
  useAddStoreToUrl,
  useAddInAppToUrl,
  useAddAppBannerToUrl,
} from "../../hook/Url";
import { parseGraphQLError } from "../../api/GraphQL";

import ProductDetailPageContent from "./ProductDetailPageContent";
import SubscribeRestockAlertButton from "./SubscribeRestockAlertButton.lazy";
import { useRequestSubscribeToRestockAlert } from "../SubscribeProductRestockAlert/repository";
import { useIsProductSubscribedToRestockAlert } from "../SubscribeProductRestockAlert/context";
import RerenderLogger from "../Performance/RerenderLogger";
import { ProductDetailPageSession } from "../../utils/PerformanceRecordStore/sessions";
import {
  addPerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";
import { profiledAsyncActionFn } from "../../utils/performance";
import { useViewDidEnterOnce } from "../../utils/View";
import { mapNullable } from "../../utils/type";
import { PageResource } from "./type";
import {
  LoadingModalContextProps,
  withLoadingModal,
} from "../LoadingModalProvider";
import useBundleResource from "./BundleRepository";

interface ViewStateLoading {
  type: "loading";
}
const ViewStateLoading: ViewStateLoading = {
  type: "loading",
};

type ProductData =
  | { type: "productOverview"; productOverview: ProductOverview }
  | { type: "product"; product: Product };

function asProductOverview(productData: ProductData) {
  if (productData.type === "product") {
    return productData.product;
  }
  return productData.productOverview;
}

function asProduct(productData: ProductData) {
  if (productData.type === "product") {
    return productData.product;
  }
  return null;
}

interface ViewStateDisplay {
  type: "display";
  product: ProductData;
  relatedProducts: ProductOverview[];
  bundle: ProductSaleBundle<Product> | null;
  relatedProductBundleProductIdMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
  storeConfig: StoreConfig;
}
function ViewStateDisplay(
  product: ProductData,
  relatedProducts: ProductOverview[],
  bundle: ProductSaleBundle<Product> | null,
  relatedProductBundleProductIdMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  },
  storeConfig: StoreConfig
): ViewStateDisplay {
  return {
    type: "display",
    product,
    relatedProducts,
    bundle,
    relatedProductBundleProductIdMap,
    storeConfig,
  };
}

type ViewState = ViewStateLoading | ViewStateDisplay;

type Props = {
  sku: string;
  productDetailInMemory: Product | null;
  pageResourceState: ResourcesRequestState<PageResource | null>;
  startRequestingPage: () => Promise<PageResource | null>;
  refreshPage: () => Promise<PageResource | null>;
  relatedProductsResouceState: ResourcesRequestState<ProductOverview[]>;
  startRequestingRelatedProducts: () => Promise<ProductOverview[]>;
  refreshRelatedProducts: () => Promise<ProductOverview[]>;
  ionLifeCycleContext: IonLifeCycleContextInterface;
  purchaseProductFormStateReducer: PurchaseProductFormStateReducer;
  onAddToCartClick: () => void;
  allowShare: boolean;
  isSoldOut: IsSoldOutFunction;
  shouldShowPrice: ShouldProductShowPriceFunction;
} & LoadingModalContextProps;

const ProductDetailPage: React.FC<Props> = props => {
  const {
    sku,
    pageResourceState,
    startRequestingPage,
    refreshPage,
    relatedProductsResouceState,
    startRequestingRelatedProducts,
    refreshRelatedProducts,
    ionLifeCycleContext,
    productDetailInMemory,
    purchaseProductFormStateReducer,
    onAddToCartClick,
    allowShare,
    isSoldOut,
    shouldShowPrice,
    loadingModalContext: { withLoadingModalAsync },
  } = props;
  const {
    requestState: bundleResourceState,
    startRequesting: startRequestingBundle,
    refresh: refreshBundle,
  } = useBundleResource();
  const location = useLocation();
  const history = useHistory();
  const url = location.pathname;
  const currentTab = useCurrentTab();

  const { navigate } = useContext(OurNavContext);
  const { isOnline } = useContext(NetworkStatusContext);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const addStoreToUrl = useAddStoreToUrl();
  const addInAppToUrl = useAddInAppToUrl();
  const addAppBannerToUrl = useAddAppBannerToUrl();
  const openUrlWithBrowser = useOpenUrlWithBrowser();

  const storeConfig = useStoreConfig();

  const isLoggedIn = useIsLoggedIn();

  const cachedProductOverview = useCachedProductOverview(sku);
  const productOverview = cachedProductOverview;

  const product = useMemo(() => {
    const pageResource = getResources(pageResourceState);
    if (pageResource) {
      return pageResource.product;
    }
    return productDetailInMemory;
  }, [pageResourceState, productDetailInMemory]);
  const [
    configuredProduct,
    setConfiguredProduct,
  ] = useState<ConfiguredProduct | null>(null);

  const productReviewsInMemory = useInMemoryProductReviews(sku);
  const [
    fetchProductReviewsRequestState,
    fetchProductReviews,
    refreshProductReviews,
  ] = useFetchProductReviews(sku);

  const viewDidEnter = useCallback(() => {
    addPerformanceRecord(
      ProductDetailPageSession(sku),
      "Product Detail Page did enter"
    );
    profiledAsyncActionFn(
      ProductDetailPageSession(sku),
      "Load Product From Network",
      startRequestingPage
    )()
      .then(maybePage =>
        mapNullable(maybePage, page => {
          const mainProductId = page.product.id;
          // Async
          profiledAsyncActionFn(
            ProductDetailPageSession(sku),
            "Load Related Products From Network",
            startRequestingRelatedProducts
          )()
            .then(ps => ps.map(p => p.id))
            .catch(() => [])
            .then(relatedProductIds =>
              profiledAsyncActionFn(
                ProductDetailPageSession(sku),
                "Load Bundles From Network",
                startRequestingBundle
              )(mainProductId, relatedProductIds)
            );
          // Async
          fetchProductReviews().catch(() => {});
        })
      )
      .catch(() => {});
  }, [
    sku,
    startRequestingPage,
    startRequestingRelatedProducts,
    startRequestingBundle,
    fetchProductReviews,
  ]);
  useViewDidEnterOnce(ionLifeCycleContext, viewDidEnter);

  const viewState = useMemo<ViewState>(() => {
    const bundleResource = getResources(bundleResourceState);
    const relatedProducts = getResources(relatedProductsResouceState);
    if (storeConfig) {
      if (product) {
        return ViewStateDisplay(
          { type: "product", product: product },
          relatedProducts || [],
          bundleResource ? bundleResource.bundle : null,
          bundleResource ? bundleResource.relatedProductBundleProductIdMap : {},
          storeConfig
        );
      } else if (productOverview) {
        return ViewStateDisplay(
          { type: "productOverview", productOverview },
          relatedProducts || [],
          bundleResource ? bundleResource.bundle : null,
          bundleResource ? bundleResource.relatedProductBundleProductIdMap : {},
          storeConfig
        );
      }
    }
    return ViewStateLoading;
  }, [
    bundleResourceState,
    relatedProductsResouceState,
    productOverview,
    storeConfig,
    product,
  ]);

  const productReviews = useMemo(() => {
    const _productReviews = getResources(fetchProductReviewsRequestState);
    if (_productReviews) {
      return _productReviews;
    }
    return productReviewsInMemory || [];
  }, [fetchProductReviewsRequestState, productReviewsInMemory]);

  const viewEnter = useCallback(() => {
    if (!product) {
      return;
    }
    const { name, sku: _sku } = product;
    pageView({
      page: "Product Details",
      productName: name,
      productSku: _sku,
      productUrl: url,
    });
  }, [url, product]);

  useViewEnterForAsyncContent(product, ionLifeCycleContext, viewEnter);

  const isProductLiked = useIsProductLiked(sku);
  const isProductSoldOut = useMemo(() => {
    return viewState.type === "display"
      ? configuredProduct
        ? isSoldOut(configuredProduct)
        : isSoldOut(asProductOverview(viewState.product))
      : false;
  }, [viewState, configuredProduct, isSoldOut]);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const alertButtons = useMemo<LocalizedAlertButton[]>(() => {
    return [
      {
        textMessageID: "alert.button.ok",
        handler: () => {
          history.replace(getPathForHomePage());
        },
      },
    ];
  }, [history]);

  useEffect(() => {
    if (
      pageResourceState.type === "loaded" &&
      pageResourceState.resources == null
    ) {
      presentLocalizedAlert({
        headerId: "alert.error.title",
        messageId: "page.product_detail.alert.product_not_found.message",
        buttons: alertButtons,
      });
    }
    if (isRequestError(pageResourceState)) {
      const errorMessage = parseGraphQLError(pageResourceState.error);
      presentLocalizedAlert({
        headerId: "alert.error.title",
        message: errorMessage ? errorMessage : undefined,
        messageId: errorMessage ? undefined : "error.unknown",
        buttons: alertButtons,
      });
    }
  }, [pageResourceState, presentLocalizedAlert, alertButtons]);

  const scrollToTop = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.scrollToTop(250);
    }
  }, []);

  const { present } = useContext(PresentationContext);
  const onClickShoppingCartButton = useCallback(() => {
    present(getPathForShoppingCart());
  }, [present]);

  const { toggleProductFromWishlist } = useContext(WishlistContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (_sku: string) => {
      toggleProductFromWishlist(_sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );
  const onClickThisProductLikeButton = useCallback(() => {
    onClickLikeButton(sku);
  }, [sku, onClickLikeButton]);

  const { count: shoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );

  const [shareModalOpened, setShareModalOpened] = useState(false);
  const handleShareButtonClick = useCallback(() => {
    setShareModalOpened(true);
  }, []);

  const handleLiveChatClick = useCallback(() => {
    if (Config.LIVE_CHAT_URL) {
      const _url = addStoreToUrl(
        addAppBannerToUrl(addInAppToUrl(Config.LIVE_CHAT_URL))
      );
      withLoadingModalAsync(() => openUrlWithBrowser(_url));
    }
  }, [
    addStoreToUrl,
    addInAppToUrl,
    addAppBannerToUrl,
    openUrlWithBrowser,
    withLoadingModalAsync,
  ]);

  const handleShareModalDismiss = useCallback(() => {
    setShareModalOpened(false);
  }, []);

  const retry = useCallback(
    () =>
      refreshPage().then(maybePage =>
        mapNullable(maybePage, page => {
          const mainProductId = page.product.id;
          // Async
          refreshRelatedProducts()
            .then(ps => ps.map(p => p.id))
            .catch(() => [])
            .then(relatedProductIds =>
              refreshBundle(mainProductId, relatedProductIds)
            );
          // Async
          refreshProductReviews().catch(() => {});
        })
      ),
    [refreshPage, refreshRelatedProducts, refreshBundle, refreshProductReviews]
  );

  const handleHomeButtonClick = useCallback(async () => {
    navigate(getPathForTab(currentTab));
    if (currentTab !== RootTab.home) {
      await timeout(1000);
      navigate(getPathForTab(RootTab.home));
    }
  }, [currentTab, navigate]);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await retry();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [retry]
  );

  const isRestockAlertSubscribed = useIsProductSubscribedToRestockAlert(
    viewState.type === "display"
      ? configuredProduct
        ? configuredProduct.id
        : asProductOverview(viewState.product).id
      : null
  );
  const [
    subscribeToRestockAlertRequestState,
    subscribeToRestockAlert,
  ] = useRequestSubscribeToRestockAlert();

  const onClickSubscribeRestockAlert = useCallback(async () => {
    if (!isLoggedIn) {
      presentLoginModal();
      return;
    }
    if (viewState.type === "display") {
      try {
        const p = configuredProduct
          ? configuredProduct
          : asProductOverview(viewState.product);
        const subscribed = await subscribeToRestockAlert(p.id);
        presentLocalizedAlert({
          messageId: subscribed
            ? "page.product_detail.subscribe_restock.success"
            : "page.product_detail.subscribe_restock.failed",
          buttons: [{ textMessageID: "alert.button.ok" }],
        });
      } catch (e) {
        const { message } = e;
        presentLocalizedAlert({
          message: message ? message : undefined,
          messageId: message
            ? undefined
            : "page.product_detail.subscribe_restock.failed",
          buttons: [{ textMessageID: "alert.button.ok" }],
        });
      }
    }
  }, [
    isLoggedIn,
    subscribeToRestockAlert,
    viewState,
    configuredProduct,
    presentLocalizedAlert,
    presentLoginModal,
  ]);

  const onRender = useCallback(
    (_id, phrase, actualDuration, _baseDuration, startTime) => {
      if (phrase === "mount") {
        addPerformanceRecord(
          ProductDetailPageSession(sku),
          "Contents (ProductDetailPage) Mounted",
          startTime
        );
        return;
      }
      addPerformanceRecord(
        ProductDetailPageSession(sku),
        RERENDER_EVENT,
        startTime,
        startTime + actualDuration
      );
    },
    [sku]
  );

  return (
    <RerenderLogger onRender={onRender} id="Product Detail Page">
      <NavBar
        headerLeft={
          <>
            <NavBarBackButton />
            <NavBarHomeButton onClick={handleHomeButtonClick} />
          </>
        }
        headerTitle={
          <LocalizedText messageID="page.product_detail.navbar.title" />
        }
        headerRight={
          <>
            {Config.LIVE_CHAT_URL ? (
              <NavBarLiveChatButton onClick={handleLiveChatClick} />
            ) : null}
            <ShoppingCartButton
              onClick={onClickShoppingCartButton}
              count={shoppingCartItemCount}
            />
          </>
        }
      />
      <CLContent ref={contentRef}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={productOverview !== null}
          onRetry={retry}
        >
          {viewState.type === "display" ? (
            <>
              <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                <IonRefresherContent />
              </IonRefresher>
              <ProductDetailPageContent
                ionLifeCycleContext={ionLifeCycleContext}
                isLoggedIn={isLoggedIn}
                storeConfig={viewState.storeConfig}
                currentTab={currentTab}
                purchaseProductFormStateReducer={
                  purchaseProductFormStateReducer
                }
                productOverview={asProductOverview(viewState.product)}
                product={asProduct(viewState.product)}
                bundle={viewState.bundle}
                relatedProductBundleProductIdMap={
                  viewState.relatedProductBundleProductIdMap
                }
                configuredProduct={configuredProduct}
                setConfiguredProduct={setConfiguredProduct}
                scrollToTop={scrollToTop}
                onClickHorizontalProductListLikeButton={onClickLikeButton}
                shareModalOpened={shareModalOpened}
                onRequestShareModalDismiss={handleShareModalDismiss}
                relatedProducts={viewState.relatedProducts}
                productReviews={productReviews}
                isSoldOut={isSoldOut}
                shouldShowPrice={shouldShowPrice}
              />
            </>
          ) : (
            <FullContentLoadingView />
          )}
        </NoInternetConnectionView>
      </CLContent>
      <IonFooter className={styles.ionFooter}>
        <IonToolbar className={styles.ionFooterToolbar}>
          {product && isThirdPartyProduct(product) ? (
            <ThirdPartyProductBottomActionButtons
              product={product}
              isProductLiked={isProductLiked}
              onClickLikeButton={onClickThisProductLikeButton}
              onClickShare={allowShare ? handleShareButtonClick : undefined}
            />
          ) : (
            <BottomActionButtons
              isPreOrder={product ? product.isPreOrder || false : false}
              isProductSoldOut={isProductSoldOut}
              isProductLiked={isProductLiked}
              isRestockAlertSubscribed={isRestockAlertSubscribed}
              isRestockAlertSubscribeRequesting={isRequestLoading(
                subscribeToRestockAlertRequestState
              )}
              disabled={viewState.type !== "display"}
              onClickLikeButton={onClickThisProductLikeButton}
              onClickAddToCart={onAddToCartClick}
              onClickShare={allowShare ? handleShareButtonClick : undefined}
              onClickSubscribeRestockAlert={onClickSubscribeRestockAlert}
            />
          )}
        </IonToolbar>
      </IonFooter>
    </RerenderLogger>
  );
};

type BottomButtonState =
  | BottomButtonPreOrder
  | BottomButtonAddToCart
  | BottomButtonSubscribeRestockAlert
  | BottomButtonSoldOut;

interface BottomButtonPreOrder {
  type: "preOrder";
}

interface BottomButtonAddToCart {
  type: "addToCart";
}

interface BottomButtonSubscribeRestockAlert {
  type: "subscribeRestockAlert";
  requesting: boolean;
  subscribed: boolean;
}

interface BottomButtonSoldOut {
  type: "soldOut";
}

interface BottomActionButtonsProps {
  isPreOrder: boolean;
  isProductSoldOut: boolean;
  isProductLiked: boolean;
  isRestockAlertSubscribed: boolean;
  isRestockAlertSubscribeRequesting: boolean;
  disabled: boolean;
  onClickLikeButton: () => void;
  onClickAddToCart: () => void;
  onClickShare?: () => void;
  onClickSubscribeRestockAlert: () => void;
}

const BottomActionButtons: React.FC<BottomActionButtonsProps> = props => {
  const {
    isPreOrder,
    isProductSoldOut,
    isProductLiked,
    isRestockAlertSubscribed,
    isRestockAlertSubscribeRequesting,
    disabled,
    onClickLikeButton,
    onClickAddToCart,
    onClickShare,
    onClickSubscribeRestockAlert,
  } = props;

  const appConfig = useAppConfig();

  const onClickLikeButton_ = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickLikeButton();
    },
    [onClickLikeButton]
  );

  const handleClickSubscribeToRestockAlert = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickSubscribeRestockAlert();
    },
    [onClickSubscribeRestockAlert]
  );

  const buttonState = useMemo<BottomButtonState>(() => {
    if (!isProductSoldOut) {
      if (isPreOrder) {
        return { type: "preOrder" };
      }
      return { type: "addToCart" };
    }
    if (appConfig && appConfig.isStockAlertAllowed) {
      return {
        type: "subscribeRestockAlert",
        requesting: isRestockAlertSubscribeRequesting,
        subscribed: isRestockAlertSubscribed,
      };
    }
    return { type: "soldOut" };
  }, [
    isPreOrder,
    appConfig,
    isProductSoldOut,
    isRestockAlertSubscribed,
    isRestockAlertSubscribeRequesting,
  ]);

  return (
    <div className={styles.bottomButtonsContainer}>
      <button
        className={cn(
          styles.likeButton,
          isProductLiked && styles.likeButtonLiked,
          disabled && styles.buttonDisable
        )}
        onClick={onClickLikeButton_}
      />
      {buttonState.type === "soldOut" ? (
        <SoldOutButton />
      ) : buttonState.type === "addToCart" ? (
        <AddToCartButton disabled={disabled} onClick={onClickAddToCart} />
      ) : buttonState.type === "subscribeRestockAlert" ? (
        <SubscribeRestockAlertButton
          subscribed={buttonState.subscribed}
          requesting={buttonState.requesting}
          onClick={handleClickSubscribeToRestockAlert}
        />
      ) : buttonState.type === "preOrder" ? (
        <PreOrderButton disabled={disabled} onClick={onClickAddToCart} />
      ) : null}
      {onClickShare ? (
        <button onClick={onClickShare} className={styles.shareButton}>
          <IonIcon name="cl-share" className={styles.shareIcon} />
        </button>
      ) : null}
    </div>
  );
};

interface ThirdPartyProductBottomActionButtonsProps {
  product: ThirdPartyProduct<ProductOverview>;
  isProductLiked: boolean;
  onClickLikeButton: () => void;
  onClickShare?: () => void;
}

const ThirdPartyProductBottomActionButtons: React.FC<
  ThirdPartyProductBottomActionButtonsProps
> = props => {
  const { product, isProductLiked, onClickLikeButton, onClickShare } = props;
  const { displayType, buttonUrl } = product;
  const onClickLikeButton_ = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickLikeButton();
    },
    [onClickLikeButton]
  );

  const handleUrl = useCallback(() => {
    if (buttonUrl) {
      appEventEmitter.publish(
        AppEventOnClickThirdPartyProductButtonUrl(buttonUrl)
      );
    }
  }, [buttonUrl]);

  return (
    <div className={styles.bottomButtonsContainer}>
      <button
        className={cn(
          styles.likeButton,
          isProductLiked && styles.likeButtonLiked
        )}
        onClick={onClickLikeButton_}
      />
      <BlackButton
        disabled={
          displayType === "BUTTON_WITH_LINK" && buttonUrl ? false : true
        }
        onClick={handleUrl}
        className={styles.thirdPartyProductButton}
      >
        <span className={styles.thirdPartyProductButtonText}>
          <LocalizedText messageID="page.product_detail.button.third_party_url" />
        </span>
      </BlackButton>
      {onClickShare ? (
        <button onClick={onClickShare} className={styles.shareButton}>
          <IonIcon name="cl-share" className={styles.shareIcon} />
        </button>
      ) : null}
    </div>
  );
};

export default withLoadingModal(ProductDetailPage);
