import React from "react";

import styles from "./styles.module.scss";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

export const LoadingView: React.FC = React.memo(() => (
  <div className={styles.container}>
    <div className={styles.loading}></div>
  </div>
));

export const FullContentLoadingView: React.FC = React.memo(() => {
  return (
    <div className={styles.fullContentLoadingViewContainer}>
      <LoadingView />
      <TabBarSpacePlaceholder />
    </div>
  );
});
