import React, { useMemo } from "react";

import HTMLBasedCMSPage from "../HTMLBasedCMSPage";

import { HTML_BASED_CMS_PAGE_TYPE } from "../../models/cmsBlock";

interface Props {
  cmsPageID: number;
  canonicalUrl: string | null;
  relativeUrl: string | null;
}

const Features: React.FC<Props> = props => {
  const { cmsPageID, canonicalUrl, relativeUrl } = props;
  const pageType = useMemo<HTML_BASED_CMS_PAGE_TYPE>(() => {
    if (canonicalUrl) {
      const regex = new RegExp("cms/page/view/page_id/(\\d+)");
      const matched = regex.exec(canonicalUrl);
      if (matched) {
        return {
          type: "merchant",
          identifier: matched[1],
        };
      }
    }
    if (relativeUrl) {
      const regex = new RegExp("cms/page/view/page_id/(\\d+)");
      const matched = regex.exec(relativeUrl);
      if (matched) {
        return {
          type: "merchant",
          identifier: matched[1],
        };
      }
    }
    return {
      type: "cmsPage",
      identifier: cmsPageID,
    };
  }, [cmsPageID, canonicalUrl, relativeUrl]);
  return (
    <div>
      {pageType.type === "merchant" || pageType.type === "cmsPage" ? (
        <HTMLBasedCMSPage pageType={pageType} allowRefresh={false} />
      ) : null}
    </div>
  );
};

export default Features;
