import React, { useMemo, useCallback } from "react";
import SearchSuggestionList, {
  SearchSuggestionListItemItem,
} from "./SearchSuggestionList";
import { SearchAutoSuggestion } from "../../models/Search";
import { stringLiteral } from "../../utils/type";

interface Props {
  hotSearches: SearchAutoSuggestion[];
  onClickItem: (searchTerm: SearchAutoSuggestion) => void;
}

const HotSearchesList: React.FC<Props> = props => {
  const { hotSearches, onClickItem } = props;
  const listItems = useMemo(
    () => ({
      type: stringLiteral("search-suggestion"),
      searchSuggestions: hotSearches,
    }),
    [hotSearches]
  );
  const onClickItem_ = useCallback(
    (item: SearchSuggestionListItemItem) => {
      if (item.type === "search-suggestion") {
        onClickItem(item.searchSuggestion);
      }
    },
    [onClickItem]
  );
  return (
    <SearchSuggestionList
      displayMode="tag"
      headerTitleID="page.search_suggestion.list.hot_searches.title"
      items={listItems}
      onClickItem={onClickItem_}
    />
  );
};

export default HotSearchesList;
