import React, { useMemo, useEffect, useState } from "react";

function useImage(imageUrl: string | null) {
  return useLazyLoadImage(imageUrl, true);
}

export function useImageStyle(placeholderUrl: string, imageUrl: string | null) {
  const isLoaded = useImage(imageUrl);
  return useMemo<React.CSSProperties>(() => {
    return {
      backgroundImage: `url(${
        imageUrl == null || !isLoaded ? placeholderUrl : imageUrl
      })`,
    };
  }, [placeholderUrl, imageUrl, isLoaded]);
}

function useLazyLoadImage(imageUrl: string | null, isReady: boolean) {
  const [isLoaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);
  useEffect(() => {
    if (imageUrl == null || !isReady) return;
    setLoaded(false);
    setError(false);
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setLoaded(true);
    };
    image.onerror = () => {
      setError(true);
    };
    return () => {
      image.onload = null;
    };
  }, [imageUrl, isReady]);
  return { isLoaded: imageUrl ? isLoaded : true, isError };
}

export function useLazyLoadImageStyle(
  placeholderUrl: string,
  imageUrl: string | null,
  isReady: boolean
): {
  isLoaded: boolean;
  showImageCover: boolean;
  style: React.CSSProperties;
} {
  const { isLoaded, isError } = useLazyLoadImage(imageUrl, isReady);

  const [showImageCover, setShowImageCover] = useState(true);

  // Show image cover for 1 frame to prevent full image shown immediately
  // if animation applied
  useEffect(() => {
    if (isReady && (isLoaded || isError)) {
      setTimeout(() => {
        setShowImageCover(false);
      });
    }
  }, [isReady, isLoaded, isError]);

  return useMemo<{
    isLoaded: boolean;
    showImageCover: boolean;
    style: React.CSSProperties;
  }>(
    () => ({
      isLoaded: isLoaded || isError,
      showImageCover,
      style: {
        backgroundImage: `url(${
          // e.g. off-screen
          !isReady
            ? undefined
            : // place holder for no image or not found image
            !imageUrl || isError
            ? placeholderUrl
            : // no image for loading image
            !isLoaded
            ? undefined
            : // finally the image
              imageUrl
        })`,
      },
    }),
    [placeholderUrl, imageUrl, isReady, isLoaded, isError, showImageCover]
  );
}
