export type Session = string;

export function CacheSession() {
  return "Cache Session";
}

export function InitializeHomePageSession(): Session {
  return "Initialize Home Page Session";
}

export function ProductDetailPageSession(sku: string) {
  return `Product Detail Page (sku: ${sku})`;
}

export function CategoryPageSession(id: number) {
  return `Category Page (id: ${id})`;
}

export function RedemptionPageSession() {
  return "Redemption Page";
}

export function SearchPageSession(search: string) {
  return `Search Page (search: ${search})`;
}

export function BrandIndexPageSession() {
  return "Brand Index Page";
}

export function BrandSearchPageSession() {
  return "Brand Search Page";
}

export function SingleBrandPageSession(slug: string) {
  return `Brand Details Page (slug: ${slug})`;
}

export function CheckoutSession(): Session {
  return "Checkout";
}
