import { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { isApolloError, useApolloClient } from "@apollo/react-hooks";
import isError from "lodash/isError";

import { IndexMap, FormError } from "../../utils/type";
import { useIntl } from "../../i18n/Localization";
import {
  Country,
  CountryID,
  Region,
  RegionID,
  District,
  DistrictID,
} from "../../models/CountryRegion";
import { Address, validateAddress } from "../../models/Customer";
import { RepositoryContext } from "../../repository/State";
import { CLError, RemoteError, LocalError } from "../../utils/Error";
import { isEmpty } from "../../utils/String";
import { validateEmail, validatePhoneNumber } from "../../utils/validation";
import { isHybrid } from "../../utils/Platform";
import { useKeepUpdatingRef } from "../../hook/utils";

import { CartIDContext } from "../CartIDProvider";
import { ShoppingCartItemCountContext } from "../ShoppingCartItemCountProvider";

import {
  DeliveryAddress,
  BillingInfo,
  SelfPickup,
  OppCardOption,
} from "./Context";
import {
  PartialCart,
  AddressInput,
  DeliveryEndpointType,
  DeliveryEndpointParameters,
  DeliveryTimeSlotValue,
  PaymentType,
  PaymentMethod,
  O2oStore,
  findO2oStore,
} from "./models";
import {
  fetchCart as fetchCartAPI,
  updateDeliveryEndpointOnCart as updateDeliveryEndpointOnCartAPI,
  updateDeliveryTimeSlotOnCart as updateDeliveryTimeSlotOnCartAPI,
  applyCouponToCart as applyCouponToCartAPI,
  removeCouponFromCart as removeCouponFromCartAPI,
  setShippingAddressOnCart as setShippingAddressOnCartAPI,
  setShippingMethodOnCart as setShippingMethodOnCartAPI,
  setBillingAddressOnCart as setBillingAddressOnCartAPI,
  setPaymentMethodOnCart as setPaymentMethodOnCartAPI,
  setClubPointOnCart as setClubPointOnCartAPI,
  setEmailAddressOnCart as setEmailAddressOnCartAPI,
  placeOrder,
  queryTheClubOTPParameters,
  confirmOrderBurntPoint,
  queryAsiapayClientPostParameters,
  queryOppClientPostParameters,
  mPosPayPrepareOrderForQrCodeScan,
  authorizeApplePayPaymentData,
  runCartMutations as runCartMutationsAPI,
} from "./api";
import { useFetchResources_v2 } from "../../repository/Hooks";
import {
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";
import Config from "../../Config";
import { isGooglePayReady } from "../../CLPlugins/AsiaPay";
import { MutationDefinitionWithCartId, OperationKey } from "./mutation";

export function transformAddressToAddressInput(
  address: Address,
  countryByID: IndexMap<CountryID, Country>,
  regionByID: IndexMap<RegionID, Region>,
  districtByID: IndexMap<DistrictID, District>
): AddressInput {
  const country = address.region ? countryByID[address.region] : null;
  const region = address.area ? regionByID[address.area] : null;
  const district = address.district ? districtByID[address.district] : null;
  return {
    city: district ? district.name : "",
    company: address.company,
    countryCode: country ? country.code : "",
    firstname: address.firstName,
    lastname: address.lastName,
    region: region ? region.code : "",
    street: [
      address.street || "",
      address.building || "",
      address.block || "",
      address.flatOrFloor || "",
      address.roomNumber || "",
    ],
    telephone: address.telephone,
  };
}

export function transformO2oStoreToAddressInput(
  firstname: string,
  lastname: string,
  telephone: string,
  o2oStore: O2oStore
): AddressInput {
  const {
    country: { code: countryCode },
    region: { code: regionCode },
    city,
    street,
    building,
    block,
    floor,
    flat,
  } = o2oStore;
  return {
    firstname: firstname,
    lastname: lastname,
    company: "",
    telephone,
    street: [
      street || "",
      building || "",
      block || "",
      floor || "",
      flat || "",
    ],
    city,
    region: regionCode,
    countryCode,
  };
}

export function useCartResource() {
  const [cart, setCart] = useState<PartialCart | null>(null);

  const { callCartAPI, callCartAPIGracefully } = useContext(CartIDContext);
  const {
    state: { customer },
  } = useContext(RepositoryContext);
  const { countryByID, regionByID, districtByID } = useContext(
    CountryRegionDistrictContext
  );
  const { setCount: setShoppingCartItemCount } = useContext(
    ShoppingCartItemCountContext
  );
  const client = useApolloClient();
  const { locale, translate } = useIntl();

  const [
    updateDeliveryEndpointError,
    setUpdateDeliveryEndpointError,
  ] = useState<string | null>(null);

  const [
    updateDeliveryEndpointForO2oStoreFormError,
    setUpdateDeliveryEndpointForO2oStoreFormError,
  ] = useState<FormError<{ mobile: unknown }>>({});

  const [
    updateDeliveryEndpointForO2oStoreError,
    setUpdateDeliveryEndpointForO2oStoreError,
  ] = useState<CLError | null>(null);

  const [
    updateDeliveryTimeSlotError,
    setUpdateDeliveryTimeSlotError,
  ] = useState<CLError | null>(null);

  const [applyCouponError, setApplyCouponError] = useState<CLError | null>(
    null
  );

  const [shippingAddressFormError, setShippingAddressFormError] = useState<
    FormError<Address>
  >({});
  const [setShippingAddressError, setSetShippingAddressError] = useState<
    string | null
  >(null);

  const [billingAddressFormError, setBillingAddressFormError] = useState<
    FormError<Address>
  >({});
  const [setBillingAddressError, setSetBillingAddressError] = useState<
    string | null
  >(null);

  const [setPaymentMethodError, setSetPaymentMethodError] = useState<
    string | null
  >(null);

  const [setClubPointError, setSetClubPointError] = useState<string | null>(
    null
  );
  const [setClubPointRequesting, setSetClubPointRequesting] = useState(false);

  const [
    setEmailAddressError,
    setSetEmailAddressError,
  ] = useState<CLError | null>(null);
  const [setEmailAddressRequesting, setSetEmailAddressRequesting] = useState(
    false
  );

  const [placeOrderError, setPlaceOrderError] = useState<string | null>(null);

  const [
    queryTheClubOTPParametersError,
    setQueryTheClubOTPParametersError,
  ] = useState<string | null>(null);

  const [
    confirmOrderBurntPointError,
    setConfirmOrderBurntPointError,
  ] = useState<CLError | null>(null);

  const [
    queryAsiapayClientPostParametersError,
    setQueryAsiapayClientPostParametersError,
  ] = useState<string | null>(null);

  const [
    queryOppClientPostParametersError,
    setQueryOppClientPostParametersError,
  ] = useState<CLError | null>(null);

  const _fetchCart = useCallback(() => callCartAPIGracefully(fetchCartAPI), [
    callCartAPIGracefully,
  ]);
  const setClubPointOnCart = setClubPointOnCartAPI;
  const setPaymentMethodOnCart = setPaymentMethodOnCartAPI;
  const setBillingAddressOnCart = setBillingAddressOnCartAPI;
  const setShippingMethodOnCart = setShippingMethodOnCartAPI;
  const applyCouponToCart = applyCouponToCartAPI;
  const removeCouponFromCart = removeCouponFromCartAPI;
  const updateDeliveryEndpointOnCart = updateDeliveryEndpointOnCartAPI;
  const updateDeliveryTimeSlotOnCart = updateDeliveryTimeSlotOnCartAPI;
  const setShippingAddressOnCart = setShippingAddressOnCartAPI;
  const setEmailAddressOnCart = setEmailAddressOnCartAPI;

  const [
    requestState,
    { call: fetchCart, refresh: retryFetchCart },
  ] = useFetchResources_v2<PartialCart, () => Promise<PartialCart>>({
    remoteResourcesProvider: async () => {
      const _cart = await _fetchCart();
      setCart(_cart);
      return _cart;
    },
  });

  const isFetchCartLoading = useMemo(() => isRequestLoading(requestState), [
    requestState,
  ]);
  const fetchCartError = useMemo(() => getRequestStateError(requestState), [
    requestState,
  ]);

  const resetRequestStates = useCallback(() => {
    setUpdateDeliveryEndpointError(null);
    setUpdateDeliveryTimeSlotError(null);
    setShippingAddressFormError({});
    setSetShippingAddressError(null);
    setBillingAddressFormError({});
    setSetBillingAddressError(null);
    setSetPaymentMethodError(null);
    setSetClubPointError(null);
    setSetEmailAddressError(null);
    setPlaceOrderError(null);

    setUpdateDeliveryEndpointForO2oStoreFormError({});
    setShippingAddressFormError({});
    setBillingAddressFormError({});
  }, []);

  const updateDeliveryEndpoint = useCallback(
    async (
      deliveryEndpointType: Exclude<DeliveryEndpointType, "o2o-store">,
      deliveryEndpointParameters?: DeliveryEndpointParameters
    ) => {
      setUpdateDeliveryEndpointError(null);
      try {
        const _cart = await callCartAPI(
          updateDeliveryEndpointOnCart,
          deliveryEndpointType,
          deliveryEndpointParameters
        );
        setCart(_cart);
      } catch (e) {
        setUpdateDeliveryEndpointError(e.message || "Unknown Error");
        throw e;
      }
    },
    [
      callCartAPI,
      updateDeliveryEndpointOnCart,
      setCart,
      setUpdateDeliveryEndpointError,
    ]
  );

  const validateSelfPickup = useCallback(
    (selfPickup: SelfPickup) => {
      setUpdateDeliveryEndpointForO2oStoreFormError({});
      const { telephone } = selfPickup;
      let formError: FormError<{ mobile: unknown }> | null = null;
      if (!telephone) {
        formError = formError || {};
        formError.mobile = translate(
          "update_delivery_endpoint_for_o2o_store.error.no_telephone"
        );
      }
      if (!validatePhoneNumber(telephone)) {
        formError = formError || {};
        formError.mobile = translate("field.error_message", {
          field: translate("address_form.telephone"),
        });
      }

      if (formError) {
        setUpdateDeliveryEndpointForO2oStoreFormError(formError);
      }

      return formError;
    },
    [translate]
  );

  const updateDeliveryEndpointForO2oStore = useCallback(
    async (
      deliveryEndpointType: Extract<DeliveryEndpointType, "o2o-store">,
      o2oStores: O2oStore[],
      selfPickup: SelfPickup
    ): Promise<void> => {
      setUpdateDeliveryEndpointForO2oStoreError(null);

      if (!customer) {
        return;
      }

      const formError = validateSelfPickup(selfPickup);

      if (formError) {
        throw formError;
      }

      const { telephone } = selfPickup;

      const o2oStore = findO2oStore(
        o2oStores,
        selfPickup.region,
        selfPickup.district,
        selfPickup.storeType,
        selfPickup.pickupSpot
      );
      if (!o2oStore) {
        const e = LocalError(
          "update_delivery_endpoint_for_o2o_store.error.no_o2o_store"
        );
        setUpdateDeliveryEndpointForO2oStoreError(e);
        throw e;
      }

      try {
        let _cart = await callCartAPI(setShippingAddressOnCart, {
          ...transformO2oStoreToAddressInput(
            customer.firstname,
            customer.lastname,
            telephone,
            o2oStore
          ),
          saveInAddressBook: false,
        });
        // reset delivery timeslot
        _cart = await callCartAPI(updateDeliveryTimeSlotOnCart, null);
        // do set delivery endpoint
        const deliveryEndpointParameters = {
          o2oStoreCode: selfPickup.pickupSpot,
        };
        _cart = await callCartAPI(
          updateDeliveryEndpointOnCart,
          deliveryEndpointType,
          deliveryEndpointParameters
        );
        if (
          _cart.shippingAddresses.length &&
          _cart.shippingAddresses[0].availableShippingMethods.length
        ) {
          // set shipping methods
          const shippingMethod =
            _cart.shippingAddresses[0].availableShippingMethods[0];
          _cart = await callCartAPI(setShippingMethodOnCart, shippingMethod);
        }
        // The shipping fee will not apply until we call another cart api
        _cart = await _fetchCart();
        setCart(_cart);
      } catch (e) {
        setUpdateDeliveryEndpointForO2oStoreError(
          RemoteError(e.message || "Unknown Error")
        );
      }
    },
    [
      validateSelfPickup,
      callCartAPI,
      updateDeliveryEndpointOnCart,
      setShippingAddressOnCart,
      setShippingMethodOnCart,
      updateDeliveryTimeSlotOnCart,
      customer,
      _fetchCart,
    ]
  );

  const updateDeliveryTimeSlot = useCallback(
    async (deliveryTimeSlot: DeliveryTimeSlotValue | null) => {
      setUpdateDeliveryTimeSlotError(null);
      if (!deliveryTimeSlot) {
        const e = LocalError("choose_delivery_time.error.missing");
        setUpdateDeliveryTimeSlotError(e);
        throw e;
      }
      try {
        const _cart = await callCartAPI(
          updateDeliveryTimeSlotOnCart,
          deliveryTimeSlot
        );
        setCart(_cart);
      } catch (e) {
        setUpdateDeliveryTimeSlotError(
          RemoteError(e.message || "Unknown Error")
        );
        throw e;
      }
    },
    [callCartAPI, updateDeliveryTimeSlotOnCart]
  );

  const applyCoupon = useCallback(
    async (couponCode: string) => {
      setApplyCouponError(null);
      try {
        let _cart = await callCartAPI(removeCouponFromCart);
        if (couponCode) {
          _cart = await callCartAPI(applyCouponToCart, couponCode);
        }
        setCart(_cart);
      } catch (e) {
        if (!e.message) {
          setApplyCouponError(LocalError("error.unknown"));
        } else {
          if (
            e.message ===
            "The coupon code isn't valid. Verify the code and try again."
          ) {
            setApplyCouponError(LocalError("promotion.code.error.invalid"));
          } else {
            setApplyCouponError(RemoteError(e.message));
          }
        }
      }
    },
    [
      callCartAPI,
      removeCouponFromCart,
      applyCouponToCart,
      setCart,
      setApplyCouponError,
    ]
  );

  const validateShippingAddress = useCallback((address: DeliveryAddress) => {
    setShippingAddressFormError({});

    const formError = validateAddress(address);

    if (formError) {
      setShippingAddressFormError(formError);
    }

    return formError;
  }, []);

  const setShippingAddress = useCallback(
    async (address: DeliveryAddress, saveInAddressBook: boolean) => {
      setShippingAddressFormError({});
      setSetShippingAddressError(null);

      const formError = validateShippingAddress(address);

      if (formError) {
        throw formError;
      }

      try {
        let _cart = await callCartAPI(setShippingAddressOnCart, {
          ...transformAddressToAddressInput(
            address,
            countryByID,
            regionByID,
            districtByID
          ),
          saveInAddressBook,
        });
        if (
          _cart.shippingAddresses.length &&
          _cart.shippingAddresses[0].availableShippingMethods.length
        ) {
          const shippingMethod =
            _cart.shippingAddresses[0].availableShippingMethods[0];
          _cart = await callCartAPI(setShippingMethodOnCart, shippingMethod);
        }
        setCart(_cart);
      } catch (e) {
        setSetShippingAddressError(e.message || "Unknown Error");
        throw e;
      }
    },
    [
      validateShippingAddress,
      callCartAPI,
      setShippingAddressOnCart,
      setShippingMethodOnCart,
      setCart,
      setSetShippingAddressError,
      countryByID,
      regionByID,
      districtByID,
    ]
  );

  const resetBillingAddress = useCallback(async () => {
    const _cart = await callCartAPI(setBillingAddressOnCart, null);
    setCart(_cart);
  }, [callCartAPI, setBillingAddressOnCart]);

  const validateBillingAddress = useCallback((address: BillingInfo) => {
    setBillingAddressFormError({});

    const formError = validateAddress(address);

    if (formError) {
      setBillingAddressFormError(formError);
    }

    return formError;
  }, []);

  const setBillingAddress = useCallback(
    async (address: BillingInfo, saveInAddressBook: boolean) => {
      setSetBillingAddressError(null);

      const formError = validateBillingAddress(address);

      if (formError) {
        throw formError;
      }

      try {
        const _cart = await callCartAPI(setBillingAddressOnCart, {
          ...transformAddressToAddressInput(
            address,
            countryByID,
            regionByID,
            districtByID
          ),
          saveInAddressBook,
        });
        setCart(_cart);
      } catch (e) {
        setSetBillingAddressError(e.message || "Unknown Error");
        throw e;
      }
    },
    [
      validateBillingAddress,
      callCartAPI,
      setBillingAddressOnCart,
      setCart,
      setSetBillingAddressError,
      countryByID,
      regionByID,
      districtByID,
    ]
  );

  const setPaymentMethod = useCallback(
    async (paymentMethodCode: PaymentType) => {
      setSetPaymentMethodError(null);
      try {
        const _cart = await callCartAPI(
          setPaymentMethodOnCart,
          paymentMethodCode
        );
        setCart(_cart);
      } catch (e) {
        setSetPaymentMethodError(e.message || "Unknown Error");
        throw e;
      }
    },
    [callCartAPI, setPaymentMethodOnCart, setCart, setSetPaymentMethodError]
  );

  const setClubPoint = useCallback(
    async (clubPoint: number) => {
      setSetClubPointError(null);
      try {
        setSetClubPointRequesting(true);
        const _cart = await callCartAPI(setClubPointOnCart, clubPoint);
        setCart(_cart);
      } catch (e) {
        setSetClubPointError(e.message || "Unknown Error");
        throw e;
      } finally {
        setSetClubPointRequesting(false);
      }
    },
    [callCartAPI, setClubPointOnCart, setCart, setSetClubPointError]
  );

  const validateEmailAddress = useCallback((emailAddress: string) => {
    if (isEmpty(emailAddress) && !validateEmail(emailAddress)) {
      const e = LocalError("checkout.billing_email.invalid");
      return e;
    }
    return null;
  }, []);

  const setEmailAddress = useCallback(
    async (emailAddress: string) => {
      setSetEmailAddressError(null);
      const validationError = validateEmailAddress(emailAddress);
      if (validationError) {
        setSetEmailAddressError(validationError);
        throw validationError;
      }
      try {
        setSetEmailAddressRequesting(true);
        const _cart = await callCartAPI(setEmailAddressOnCart, emailAddress);
        setCart(_cart);
      } catch (e) {
        if (isError(e)) {
          setSetEmailAddressError(RemoteError(e.message || "Unknown Error"));
        }
        throw e;
      } finally {
        setSetEmailAddressRequesting(false);
      }
    },
    [validateEmailAddress, callCartAPI, setEmailAddressOnCart]
  );

  const placeOrder_ = useCallback(async () => {
    setPlaceOrderError(null);
    try {
      const { orderId } = await callCartAPI(placeOrder);
      setShoppingCartItemCount(0);
      return orderId;
    } catch (e) {
      setPlaceOrderError(e.message || "Unknown Error");
      throw e;
    }
  }, [callCartAPI, setPlaceOrderError, setShoppingCartItemCount]);

  const queryTheClubOTPParameters_ = useCallback(
    async (orderId: string) => {
      try {
        const { action, fields } = await queryTheClubOTPParameters(
          client,
          locale,
          orderId
        );
        return { action, fields };
      } catch (e) {
        setQueryTheClubOTPParametersError(e.message || "Unknown Error");
        throw e;
      }
    },
    [client, locale]
  );

  const confirmOrderBurntPoint_ = useCallback(
    async (orderId: string) => {
      setConfirmOrderBurntPointError(null);
      let isConfirmed: boolean;
      try {
        isConfirmed = await confirmOrderBurntPoint(client, locale, orderId);
      } catch (e) {
        setConfirmOrderBurntPointError(
          RemoteError(e.message || "Unknown Error")
        );
        throw e;
      }
      if (!isConfirmed) {
        const e = LocalError("checkout.burn_point.alert.message");
        setConfirmOrderBurntPointError(e);
        throw e;
      }
    },
    [client, locale]
  );

  const queryAsiapayClientPostParameters_ = useCallback(
    async (orderId: string) => {
      try {
        const {
          action,
          fields,
          values,
        } = await queryAsiapayClientPostParameters(client, locale, orderId);
        return { action, fields, values };
      } catch (e) {
        setQueryAsiapayClientPostParametersError(e.message || "Unknown Error");
        throw e;
      }
    },
    [client, locale]
  );

  const queryOppClientPostParameters_ = useCallback(
    async (orderId: string, oppCardOption: OppCardOption | null) => {
      if (!oppCardOption) {
        const error = LocalError(
          "checkout.filling_info.payment_option.opp.missing_card"
        );
        setQueryOppClientPostParametersError(error);
        throw error;
      }
      try {
        const { action } = await queryOppClientPostParameters(
          client,
          locale,
          orderId,
          oppCardOption
        );
        return { action };
      } catch (e) {
        const error = RemoteError(e.message || "Unknown Error");
        setQueryOppClientPostParametersError(error);
        throw error;
      }
    },
    [client, locale]
  );

  const runCartMutations = useCallback(
    async (mutationDefinitions: MutationDefinitionWithCartId[]) =>
      // eslint-disable-next-line complexity
      callCartAPI(runCartMutationsAPI, ...mutationDefinitions).catch(e => {
        if (isApolloError(e)) {
          for (const graphQLError of e.graphQLErrors) {
            const { message } = graphQLError;
            const [fnName] = graphQLError.path || [];
            switch (fnName) {
              case OperationKey.address_setDeliveryEndpoint:
              case OperationKey.address_setShippingAddress:
              case OperationKey.address_setShippingMethod:
                setSetShippingAddressError(message || "Unknown Error");
                break;
              case OperationKey.address_setDeliveryTimeSlot:
                setUpdateDeliveryTimeSlotError(
                  RemoteError(message || "Unknown Error")
                );
                break;
              case OperationKey.o2o_setDeliveryEndpoint:
              case OperationKey.o2o_setDeliveryTimeSlot:
              case OperationKey.o2o_setShippingAddress:
              case OperationKey.o2o_setShippingMethod:
                setUpdateDeliveryEndpointForO2oStoreError(
                  RemoteError(message || "Unknown Error")
                );
                break;
              case OperationKey.setBillingAddress:
                setSetBillingAddressError(message || "Unknown Error");
                break;
              case OperationKey.setPaymentMethod:
                setSetPaymentMethodError(message || "Unknown Error");
                break;
              case OperationKey.setEmailAddress:
                setSetEmailAddressError(
                  RemoteError(message || "Unknown Error")
                );
                break;
              default:
                break;
            }
          }
        }
        throw e;
      }),
    [callCartAPI]
  );

  // This is for updating order status to pending payment ONLY for mpospay.
  //
  // The backend team said they may change the name to a better one later.
  const mPosPayPrepareOrderForQrCodeScan_ = useCallback(
    async (incrementID: string) => {
      return mPosPayPrepareOrderForQrCodeScan(client, locale, incrementID);
    },
    [client, locale]
  );

  const authorizeApplePayPaymentData_ = useCallback(
    async (orderId: number, paymentData: string) =>
      authorizeApplePayPaymentData(client, orderId, paymentData),
    [client]
  );

  return {
    cart,
    fetchCart,
    fetchCartWithoutLoading: _fetchCart,
    retryFetchCart,
    isFetchCartLoading,
    fetchCartError,
    resetRequestStates,
    updateDeliveryEndpoint,
    updateDeliveryEndpointError,
    validateSelfPickup,
    updateDeliveryEndpointForO2oStore,
    updateDeliveryEndpointForO2oStoreError,
    updateDeliveryEndpointForO2oStoreFormError,
    updateDeliveryTimeSlot,
    updateDeliveryTimeSlotError,
    applyCoupon,
    applyCouponError,
    shippingAddressFormError,
    validateShippingAddress,
    setShippingAddress,
    setShippingAddressError,
    billingAddressFormError,
    resetBillingAddress,
    validateBillingAddress,
    setBillingAddress,
    setBillingAddressError,
    setPaymentMethod,
    setPaymentMethodError,
    setClubPoint,
    setClubPointRequesting,
    setClubPointError,
    validateEmailAddress,
    setEmailAddress,
    setEmailAddressRequesting,
    setEmailAddressError,
    placeOrder: placeOrder_,
    placeOrderError,
    queryTheClubOTPParameters: queryTheClubOTPParameters_,
    queryTheClubOTPParametersError,
    confirmOrderBurntPoint: confirmOrderBurntPoint_,
    confirmOrderBurntPointError,
    queryAsiapayClientPostParameters: queryAsiapayClientPostParameters_,
    queryAsiapayClientPostParametersError,
    queryOppClientPostParameters: queryOppClientPostParameters_,
    queryOppClientPostParametersError,
    mPosPayPrepareOrderForQrCodeScan: mPosPayPrepareOrderForQrCodeScan_,
    authorizeApplePayPaymentData: authorizeApplePayPaymentData_,
    runCartMutations,
  };
}

export function getAvailablePaymentMethods(
  cart: PartialCart,
  applePayAvailable: boolean,
  googlePayAvailable: boolean
): PaymentMethod[] {
  let { availablePaymentMethods } = cart;
  if (cart.prices.grandTotal.value <= 0) {
    availablePaymentMethods = availablePaymentMethods.filter(
      (paymentMethod: PaymentMethod) => {
        return paymentMethod.code === "free";
      }
    );
  }
  if (!Config.ENABLE_ALIPAYHK) {
    availablePaymentMethods = availablePaymentMethods.filter(
      (paymentMethod: PaymentMethod) => {
        return paymentMethod.code !== "alipayhk";
      }
    );
  }
  if (
    availablePaymentMethods.map(m => m.code).indexOf("applepay") > -1 &&
    !applePayAvailable
  ) {
    availablePaymentMethods = availablePaymentMethods.filter(
      (paymentMethod: PaymentMethod) => {
        return paymentMethod.code !== "applepay";
      }
    );
  }
  if (!googlePayAvailable) {
    availablePaymentMethods = availablePaymentMethods.filter(
      (paymentMethod: PaymentMethod) => {
        return paymentMethod.code !== "googlepay";
      }
    );
  }
  return availablePaymentMethods;
}

export function usePaymentMethodMissing(
  availablePaymentMethods: PaymentMethod[],
  selectedPaymentMethod: PaymentType | null,
  onMissing: () => void
) {
  const onMissingRef = useKeepUpdatingRef(onMissing);

  useEffect(() => {
    if (selectedPaymentMethod) {
      const availablePaymentOptionCodes = availablePaymentMethods.map(
        x => x.code
      );
      if (availablePaymentOptionCodes.indexOf(selectedPaymentMethod) === -1) {
        onMissingRef.current();
      }
    }
  }, [availablePaymentMethods, selectedPaymentMethod, onMissingRef]);
}

export function useGooglePayAvailable() {
  const [googlePayAvailable, setGooglePayAvailable] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    (async () => {
      setGooglePayAvailable(isHybrid() ? await isGooglePayReady() : false);
    })();
  }, []);

  return googlePayAvailable;
}
