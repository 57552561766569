import { Plugins } from "@capacitor/core";
import { GooglePlus } from "@ionic-native/google-plus";
import { useCallback } from "react";
import { OAuthProvider } from "../api/RESTful";
import Config from "../Config";
import { useIntl } from "../i18n/Localization";
import {
  Customer,
  getCustomerLinkedSSOEmail,
  isCustomerLinkedToTheClub,
} from "../models/Customer";
import { useIsCustomerSsoConfirmed } from "../repository/AuthRepository";
import { presentAppleSignInPopup } from "../utils/AppleSignIn";
import {
  presentLoginWithTheClubBrowser,
  TheClubLoginUTM,
} from "../utils/TheClubLogin";
import { presentTheClubSignupBrowser } from "../utils/TheClubSignup";

const { GoogleAuth, Device } = Plugins;

export interface SSOResponse {
  token: string;
  provider: OAuthProvider;
}

export function useTheClubSignup(): () => Promise<void> {
  const { locale, translate } = useIntl();
  return useCallback(
    () => presentTheClubSignupBrowser(locale, translate("cancel")),
    [translate, locale]
  );
}

export function useLoginWithTheClub(): (
  utm?: TheClubLoginUTM
) => Promise<SSOResponse> {
  const { locale } = useIntl();
  return useCallback(
    async (utm?: TheClubLoginUTM) => {
      const result = await presentLoginWithTheClubBrowser(locale, utm);
      if (result.cancelled) {
        throw new Error("cancelled");
      }
      if (result.token == null) {
        throw new Error("cannot-get-token");
      }
      return {
        token: result.token,
        provider: "the-club",
      };
    },
    [locale]
  );
}

export function useLoginWithFacebook(): () => Promise<SSOResponse> {
  return useCallback(async () => {
    // FacebookLogin SDK may store accessToken of a different Facebook user and
    // will cause the login to fail
    try {
      const currentTokenResult = await Plugins.FacebookLogin.getCurrentAccessToken();
      if (currentTokenResult.accessToken) {
        await Plugins.FacebookLogin.logout();
      }
    } catch {}
    try {
      const result = await Plugins.FacebookLogin.login({
        permissions: ["email", "public_profile"],
      });
      const token = result && result.accessToken && result.accessToken.token;
      if (!token || typeof token !== "string") {
        throw new Error("cannot-get-token");
      }
      return {
        token,
        provider: "facebook",
      };
    } catch (e) {
      // WTF: When close the Facebook popup without login, Plugins.FacebookLogin will throw an object with an empty accessToken.token.
      if (e.accessToken && e.accessToken.token === null) {
        throw new Error("cancelled");
      }
      throw e;
    }
  }, []);
}

export function useLoginWithGoogle(): () => Promise<SSOResponse> {
  return useCallback(async () => {
    try {
      const deviceInfo = await Device.getInfo();
      if (deviceInfo.platform === "android") {
        const user = await GooglePlus.login({
          webClientId: Config.GOOGLE_CLIENT_ID,
        });
        const token = user.accessToken;
        if (!token || typeof token !== "string") {
          throw new Error("cannot-get-token");
        }
        return {
          token,
          provider: "google",
        };
      }

      const user: any = await GoogleAuth.signIn();
      const token =
        user && user.authentication && user.authentication.accessToken;
      if (!token || typeof token !== "string") {
        throw new Error("cannot-get-token");
      }
      return {
        token,
        provider: "google",
      };
    } catch (e) {
      if (
        e.error === "popup_closed_by_user" ||
        e.message === "The user canceled the sign-in flow."
      ) {
        throw new Error("cancelled");
      }
      throw e;
    }
  }, []);
}

export function useLoginWithAppleSignIn(): () => Promise<SSOResponse> {
  return useCallback(async () => {
    const result = await presentAppleSignInPopup();
    if (result.cancelled) {
      throw new Error("cancelled");
    }
    if (result.token == null) {
      throw new Error("cannot-get-token");
    }
    return {
      token: result.token,
      provider: "apple",
    };
  }, []);
}

interface SimpleSSOConfirmationRequired {
  type: "SimpleSSOConfirmationRequired";
  email: string;
}

interface SimpleSSOConfirmationRequiredWithTheClubInfo {
  type: "SimpleSSOConfirmationRequiredWithTheClubInfo";
  method: LoginSignUpMethod;
  originalEmail: string;
  email: string;
  membershipID: string;
}

interface PairWithTheClubRequired {
  type: "PairWithTheClubRequired";
  method: LoginSignUpMethod;
}

interface TheClubSSOConfirmationNoActionRequired {
  type: "NoActionRequired";
}

export type TheClubSSOConfirmationStatus =
  | SimpleSSOConfirmationRequired
  | SimpleSSOConfirmationRequiredWithTheClubInfo
  | PairWithTheClubRequired
  | TheClubSSOConfirmationNoActionRequired;

export function useGetTheClubSSOConfirmationStatus() {
  const isCustomerSsoConfirmed = useIsCustomerSsoConfirmed();

  return useCallback(
    async (
      customer: Customer,
      method: LoginSignUpMethod
    ): Promise<TheClubSSOConfirmationStatus> => {
      if (
        !Config.ENABLE_THE_CLUB_SSO_MVP1 ||
        !Config.ENABLE_THE_CLUB_SSO_MVP1.THE_CLUB_SSO_CONFIRMATION_DIALOGS
      ) {
        return {
          type: "NoActionRequired",
        };
      }
      if (method === "The Club" && !(await isCustomerSsoConfirmed())) {
        return {
          type: "SimpleSSOConfirmationRequired",
          email:
            getCustomerLinkedSSOEmail(customer, "the-club") || customer.email,
        };
      }
      if (
        !isCustomerLinkedToTheClub(customer) ||
        !customer.clubMember[0].hasTheClubVerified
      ) {
        return {
          type: "PairWithTheClubRequired",
          method,
        };
      }
      if (
        isCustomerLinkedToTheClub(customer) &&
        !(await isCustomerSsoConfirmed())
      ) {
        const [clubMember] = customer.clubMember;
        return {
          type: "SimpleSSOConfirmationRequiredWithTheClubInfo",
          method,
          originalEmail: customer.email,
          email:
            getCustomerLinkedSSOEmail(customer, "the-club") || customer.email,
          membershipID: clubMember.clubMemberID,
        };
      }
      return {
        type: "NoActionRequired",
      };
    },
    [isCustomerSsoConfirmed]
  );
}
