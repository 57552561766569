import { useContext, useCallback, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { RepositoryContext, PaginationInfo } from "./State";

import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { WishlistItem } from "../models/Wishlist";
import { useFetchResources_v2 } from "./Hooks";
import { useHandleSessionExpired } from "./AuthRepository";

import { fetchCustomerWishlist } from "../api/GraphQL";
import { useIntl } from "../i18n/Localization";
import {
  addProductToWishlist,
  restAPIClient,
  removeItemFromWishlist,
} from "../api/RESTful";
import { useKeepUpdatingRef } from "../hook/utils";

export function useMemoryProductLikedBySKUMap() {
  const { state } = useContext(RepositoryContext);
  return state.product.likedProductSKU;
}

export function useFetchWishlist(): {
  requestState: ResourcesRequestState<WishlistItem[] | null>;
  paginationInfo: PaginationInfo<WishlistItem> | null;
  fetchNext: () => Promise<WishlistItem[] | null>;
  refresh: () => Promise<WishlistItem[] | null>;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { dispatch } = useContext(RepositoryContext);

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    WishlistItem
  > | null>(null);

  const didFetch = useCallback(
    (wishlist: WishlistItem[]) => {
      dispatch({
        type: "UpdateWishlistItems",
        items: wishlist,
      });
      setPaginationInfo({
        items: wishlist,
        currentPage: 1,
        hasMore: false,
      });
    },
    [dispatch]
  );

  const fetchCustomerWishlist_ = useHandleSessionExpired(fetchCustomerWishlist);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    WishlistItem[] | null,
    () => Promise<WishlistItem[] | null>
  >({
    localCacheProvider: async () => {
      const wishlist = await fetchCustomerWishlist_(
        client,
        locale,
        "cache-only"
      );
      if (wishlist != null) {
        didFetch(wishlist);
      }
      return wishlist;
    },
    remoteResourcesProvider: async () => {
      const wishlist = await fetchCustomerWishlist_(
        client,
        locale,
        "network-only"
      );
      if (wishlist != null) {
        didFetch(wishlist);
      }
      return wishlist;
    },
  });

  const fetchNext = useCallback(() => fetch(), [fetch]);

  return {
    requestState,
    paginationInfo,
    fetchNext,
    refresh,
  };
}

export function useAddProductToWishlist(): (
  isLoggedIn: true,
  sku: string
) => Promise<boolean> {
  const { dispatch } = useContext(RepositoryContext);
  return useCallback(
    async (isLoggedIn: true, sku: string) => {
      const result = await addProductToWishlist(restAPIClient, isLoggedIn, sku);
      dispatch({
        type: "AddProductToWishlist",
        sku,
      });
      return result;
    },
    [dispatch]
  );
}

export function useRemoveItemFromWishlist(): (
  isLoggedIn: true,
  sku: string
) => Promise<boolean> {
  const client = useApolloClient();
  const {
    state: {
      product: { wishlistItemByProductSKU },
    },
    dispatch,
  } = useContext(RepositoryContext);
  const wishlistItemByProductSKURef = useKeepUpdatingRef(
    wishlistItemByProductSKU
  );
  const { locale } = useIntl();
  const localeRef = useKeepUpdatingRef(locale);

  const getOrFetchWishlistItemByProductSKU = useCallback(
    async (sku: string): Promise<WishlistItem | null> => {
      const wishlistItem = wishlistItemByProductSKURef.current[sku];
      if (wishlistItem != null) {
        return wishlistItem;
      }
      const wishlistItems = await fetchCustomerWishlist(
        client,
        localeRef.current,
        "network-only"
      );
      dispatch({
        type: "UpdateWishlistItems",
        items: wishlistItems,
      });
      return wishlistItems.filter(i => i.product.sku === sku)[0] || null;
    },
    [client, wishlistItemByProductSKURef, localeRef, dispatch]
  );

  return useCallback(
    async (isLoggedIn: true, sku: string) => {
      const wishlistItem = await getOrFetchWishlistItemByProductSKU(sku);
      if (wishlistItem == null) {
        return false;
      }
      const result = await removeItemFromWishlist(
        restAPIClient,
        isLoggedIn,
        wishlistItem
      );
      dispatch({
        type: "RemoveProductFromWishlist",
        sku,
      });
      return result;
    },
    [getOrFetchWishlistItemByProductSKU, dispatch]
  );
}
