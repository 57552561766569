import React, { useMemo } from "react";

interface ContentScrollProviderContext {
  isContentYScrollEnabled: boolean;
  updateContentYScrollEnabled: (isYScrollEnabled: boolean) => void;
}

export const ContentScrollProviderContext = React.createContext<
  ContentScrollProviderContext
>({
  isContentYScrollEnabled: true,
  updateContentYScrollEnabled: () => {},
});

export const ContentScrollProvider: React.FC = props => {
  const [isContentYScrollEnabled, setIsContentYScrollEnabled] = React.useState<
    boolean
  >(true);
  const value = useMemo(() => {
    return {
      isContentYScrollEnabled,
      updateContentYScrollEnabled: setIsContentYScrollEnabled,
    };
  }, [isContentYScrollEnabled, setIsContentYScrollEnabled]);
  return (
    <ContentScrollProviderContext.Provider value={value}>
      {props.children}
    </ContentScrollProviderContext.Provider>
  );
};
