import { useMemo } from "react";
import {
  Country,
  CountryID,
  Region,
  RegionID,
  District,
} from "../../models/CountryRegion";
import { IndexMap } from "../../utils/type";
import { Address } from "../../models/Customer";

export function useAddressFormOptions(
  address: Address,
  countries: Country[],
  regionsByCountryID: IndexMap<CountryID, Region[]>,
  districtsByRegionID: IndexMap<RegionID, District[]>
) {
  const countryOptions = useMemo(() => {
    return countries.map(country => ({
      value: country.id,
      label: country.name,
    }));
  }, [countries]);

  const regionOptions = useMemo(() => {
    if (!address.region) {
      return [];
    }
    const regions = regionsByCountryID[address.region];
    if (!regions) {
      return [];
    }
    return regions.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [address.region, regionsByCountryID]);

  const districtOptions = useMemo(() => {
    if (!address.area) {
      return [];
    }
    const districts = districtsByRegionID[address.area];
    if (!districts) {
      return [];
    }
    return districts.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [address.area, districtsByRegionID]);

  return {
    countryOptions,
    regionOptions,
    districtOptions,
  };
}
