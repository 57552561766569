import { useContext, useRef, useEffect } from "react";
import { LocalizedAlertContext } from "../components/LocalizedAlertProvider";
import { RepositoryContext } from "../repository/State";

export default function usePresentSessionExpiredAlert(
  onLoginAgain: () => void,
  onCancel: () => void
) {
  const {
    state: { isLogoutBySectionExpired },
  } = useContext(RepositoryContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);

  const isLogoutBySectionExpiredRef = useRef(isLogoutBySectionExpired);

  useEffect(() => {
    if (
      isLogoutBySectionExpired &&
      isLogoutBySectionExpiredRef.current !== isLogoutBySectionExpired
    ) {
      presentLocalizedAlert({
        headerId: "alert.session_expired",
        buttons: [
          {
            role: "cancel",
            textMessageID: "alert.session_expired.button.cancel",
            handler: () => {
              onCancel();
            },
          },
          {
            textMessageID: "alert.session_expired.button.login_again",
            handler: () => {
              onLoginAgain();
            },
          },
        ],
      });
    }
    isLogoutBySectionExpiredRef.current = isLogoutBySectionExpired;
  }, [isLogoutBySectionExpired, onLoginAgain, onCancel, presentLocalizedAlert]);
}
