import cn from "classnames";
import formatDate from "date-fns/format";
import React, { useCallback, useMemo } from "react";
import {
  getLastShipmentStage,
  ShipmentStage,
  ShipmentStatus,
} from "../../models/Order";
import styles from "./ShipmentStatusBar.module.scss";

interface Props {
  shipmentStatus: ShipmentStatus;
  onClick: (shipmentStatus: ShipmentStatus) => void;
}

const ShipmentStatusBar: React.FC<Props> = props => {
  const { shipmentStatus, onClick } = props;
  const stages = useMemo<ShipmentStage[]>(() => {
    if (shipmentStatus.stages == null) {
      return [];
    }
    const res: ShipmentStage[] = [];
    for (const stage of shipmentStatus.stages) {
      if (stage != null) {
        res.push(stage);
      }
    }
    return res;
  }, [shipmentStatus]);

  const latestShipmentStage = useMemo(() => {
    return getLastShipmentStage(stages);
  }, [stages]);

  const latestShipmentStageIndex = useMemo(() => {
    if (!latestShipmentStage) {
      return -1;
    }
    return stages.indexOf(latestShipmentStage);
  }, [latestShipmentStage, stages]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(shipmentStatus);
    },
    [onClick, shipmentStatus]
  );

  return (
    <div className={styles.shipmentStatusBar}>
      <button onClick={handleClick} className={styles.shipmentStatusBarButton}>
        <ul className={styles.shipmentStages}>
          {stages.map((stage, i) => (
            <li key={i} className={styles.shipmentStageContainer}>
              <ShipmentStageView
                shipmentStage={stage}
                index={i + 1}
                isPast={latestShipmentStageIndex >= i}
              />
              {i < stages.length - 1 ? (
                <div
                  className={cn(styles.nextIcon, {
                    [styles.isPast]: latestShipmentStageIndex >= i,
                  })}
                />
              ) : null}
            </li>
          ))}
        </ul>
      </button>
    </div>
  );
};

export default ShipmentStatusBar;

interface ShipmentStageViewProps {
  index: number;
  shipmentStage: ShipmentStage;
  isPast: boolean;
}

const ShipmentStageView: React.FC<ShipmentStageViewProps> = props => {
  const { index, shipmentStage, isPast } = props;
  const { label, timestamp } = shipmentStage;

  return (
    <div className={cn(styles.shipmentStage, { [styles.isPast]: isPast })}>
      <div className={styles.index}>{index}</div>
      {label ? <div className={styles.label}>{label}</div> : null}
      {timestamp ? (
        <div className={styles.timestamp}>
          {formatDate(timestamp, "DD/MM/YYYY")}
        </div>
      ) : null}
    </div>
  );
};
