import React from "react";
import { IonIcon } from "@ionic/react";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./ClubPointRedemptionBadge.module.scss";

const ClubPointRedemptionBadge: React.FC = () => {
  return (
    <div className={styles.badge}>
      <IonIcon className={styles.clubpointIcon} name="club-point" />{" "}
      <LocalizedText messageID="product.clubpoint_redemption" />
    </div>
  );
};

export default ClubPointRedemptionBadge;
