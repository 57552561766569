import { produce } from "immer";

import { IndexMap } from "../utils/type";
import { MessageID } from "../i18n/translations/type";

export interface FilterType {
  attributeCode: AttributeCode; // e.g. for Brand, the code should be brand
  title: string;
  filterAttributes: FilterAttribute[];
}

export interface RangeFilterType {
  attributeCode: AttributeCode; // e.g. for Brand, the code should be brand
  title: string;
  min: number;
  max: number;
}

export interface FilterAttribute {
  attributeCode: AttributeCode;
  attributeValue: string | number; // Unique value for this attribute
  attributeType: string;
  count: number;
  title: string;
}

export interface RangeFilterAttribute {
  attributeCode: AttributeCode;
  title: string;
  min?: number;
  max?: number;
}

export interface PredefinedFilter {
  messageID: MessageID;
  attributeCode: AttributeCode;
  graphQLFilterValue:
    | FilterEqualTypeInput
    | FilterRangeTypeInput
    | FilterMatchTypeInput;
}

export type AttributeCode = string;

export function hasFilters(productFilterInfo: ProductFilterInfo): boolean {
  const {
    attributeMap,
    multiLevelAttributeMap,
    rangeAttributeMap,
    predefinedCPFilter,
  } = productFilterInfo;
  return (
    Object.keys(attributeMap).length > 0 ||
    Object.keys(multiLevelAttributeMap).length > 0 ||
    Object.keys(rangeAttributeMap).length > 0 ||
    predefinedCPFilter != null
  );
}

export enum SortOrder {
  ascending = "ASC",
  descending = "DESC",
}

export type SortField = string;

export interface SortFields {
  defaultSortField: string | null;
  sortFieldOptions: SortFieldOption[];
}

export interface SortFieldOption {
  label: string;
  value: SortField;
}

export interface SortAttribute {
  sortField: SortField | null;
  sortOrder: SortOrder;
}

export interface ProductFilterInfo {
  attributeMap: {
    [attributeCode: string]: FilterAttribute;
  };
  multiLevelAttributeMap: {
    [attributeCode: string]: FilterAttribute[];
  };
  rangeAttributeMap: {
    [attributeCode: string]: RangeFilterAttribute;
  };
  predefinedCPFilter: PredefinedFilter | null;
  sortAttribute: SortAttribute;
}

export interface ApplicableProductFilterInfo {
  attributeMap: {
    [attributeCode: string]: FilterAttribute;
  };
  multiLevelAttributeMap: {
    [attributeCode: string]: FilterAttribute;
  };
  rangeAttributeMap: {
    [attributeCode: string]: RangeFilterAttribute;
  };
  predefinedCPFilter: PredefinedFilter | null;
  sortAttribute: SortAttribute;
}

export function getApplicableProductFilterInfo(
  productFilterInfo: ProductFilterInfo
): ApplicableProductFilterInfo {
  const {
    sortAttribute,
    rangeAttributeMap,
    predefinedCPFilter,
    attributeMap,
    multiLevelAttributeMap,
  } = productFilterInfo;
  const res: ApplicableProductFilterInfo = {
    attributeMap,
    multiLevelAttributeMap: {},
    rangeAttributeMap,
    sortAttribute,
    predefinedCPFilter,
  };
  const attributeCodes = Object.keys(multiLevelAttributeMap);
  for (const attributeCode of attributeCodes) {
    const attributeStack = multiLevelAttributeMap[attributeCode];
    if (attributeStack && attributeStack.length > 0) {
      res.multiLevelAttributeMap[attributeCode] =
        attributeStack[attributeStack.length - 1];
    }
  }
  return res;
}

export function removeSingleLevelAndMultiLevelFilters(
  productFilterInfo: ProductFilterInfo
): ProductFilterInfo {
  return produce(productFilterInfo, draft => {
    draft.attributeMap = {};
    draft.multiLevelAttributeMap = {};
  });
}

export function removeSingleLevelAndMultiLevelFilterAttribute(
  productFilterInfo: ProductFilterInfo,
  filterAttribute: FilterAttribute
): ProductFilterInfo {
  return produce(productFilterInfo, draft => {
    if (
      Object.keys(draft.attributeMap).indexOf(filterAttribute.attributeCode) >
      -1
    ) {
      delete draft.attributeMap[filterAttribute.attributeCode];
    }
    if (
      Object.keys(draft.multiLevelAttributeMap).indexOf(
        filterAttribute.attributeCode
      ) > -1
    ) {
      delete draft.multiLevelAttributeMap[filterAttribute.attributeCode];
    }
  });
}

export function removeRangeFilterAttribute(
  productFilterInfo: ProductFilterInfo,
  rangeFilterAttribute: RangeFilterAttribute
) {
  return produce(productFilterInfo, draft => {
    delete draft.rangeAttributeMap[rangeFilterAttribute.attributeCode];
  });
}

// Manipulate product filter info depends on the
// available filters state and the filter attribute to manipulate.
export function manipulateAttibuteMap(
  productFilterInfo: ProductFilterInfo,
  filterAttribute: FilterAttribute
) {
  const { attributeCode } = filterAttribute;

  return produce(productFilterInfo, draft => {
    const { attributeMap } = draft;

    const existingFilterAttributes = attributeMap[attributeCode];

    // Add new attribute when not selected at all
    if (existingFilterAttributes == null) {
      attributeMap[attributeCode] = filterAttribute;
    } else if (existingFilterAttributes != null) {
      if (
        existingFilterAttributes.attributeValue ===
        filterAttribute.attributeValue
      ) {
        delete attributeMap[attributeCode];
      } else {
        attributeMap[attributeCode] = filterAttribute;
      }
    }
  });
}
export function manipulateMultiLevelFilterAttribute(
  filter: FilterType,
  productFilterInfo: ProductFilterInfo,
  filterAttribute: FilterAttribute
) {
  const { attributeCode } = filterAttribute;

  return produce(productFilterInfo, draft => {
    const { multiLevelAttributeMap } = draft;
    const existingFilterAttributes = multiLevelAttributeMap[attributeCode];

    // Add new attribute when not selected at all
    if (existingFilterAttributes == null) {
      multiLevelAttributeMap[attributeCode] = [filterAttribute];
      return;
    }

    if (
      existingFilterAttributes != null &&
      existingFilterAttributes.length > 0
    ) {
      let selectedIndex = -1;
      for (let i = 0; i < existingFilterAttributes.length; i++) {
        const existingFilterAttribute = existingFilterAttributes[i];
        if (
          existingFilterAttribute.attributeValue ===
          filterAttribute.attributeValue
        ) {
          selectedIndex = i;
          break;
        }
      }

      // Attempt to remove attribute filter from stack if found
      if (selectedIndex > 0) {
        // Remove stacked attribute filters from intermediate matched value to the end
        multiLevelAttributeMap[attributeCode] = existingFilterAttributes.slice(
          0,
          selectedIndex
        );
        return;
      } else if (selectedIndex === 0) {
        // Remove all stacked attribute filters if the selected attribute filter is root
        delete draft.multiLevelAttributeMap[attributeCode];
        return;
      }

      // Replace top attribute filter if selected filter has original value
      const lastSelectedValue =
        existingFilterAttributes[existingFilterAttributes.length - 1];
      if (
        filter != null &&
        filter.filterAttributes.filter(
          f => f.attributeValue === lastSelectedValue.attributeValue
        ).length > 0 &&
        lastSelectedValue.attributeValue !== filterAttribute.attributeValue
      ) {
        multiLevelAttributeMap[attributeCode] = [
          ...existingFilterAttributes.slice(
            0,
            existingFilterAttributes.length - 1
          ),
          filterAttribute,
        ];
        return;
      }

      // Finally stack the filter attribute up
      multiLevelAttributeMap[attributeCode] = [
        ...existingFilterAttributes,
        filterAttribute,
      ];
    }
  });
}

export function getAttributeCodesMissingFromFilters(
  filters: FilterType[],
  productFilterInfo: ProductFilterInfo
): AttributeCode[] {
  const attributeCodesApplied = Object.keys(productFilterInfo.attributeMap);
  const attributeCodesMissingInFilters: AttributeCode[] = [];
  for (const attributeCode of attributeCodesApplied) {
    const _filters = filters.filter(f => f.attributeCode === attributeCode);
    if (_filters.length === 0) {
      attributeCodesMissingInFilters.push(attributeCode);
    }
  }
  return attributeCodesMissingInFilters;
}

export function getMultiLevelAttributeCodesMissingFromMultiLevelFilters(
  filters: FilterType[],
  productFilterInfo: ProductFilterInfo
): AttributeCode[] {
  const { multiLevelAttributeMap } = productFilterInfo;
  const attributeCodesApplied = Object.keys(multiLevelAttributeMap);
  const multiLevelAttributeCodesMissingInFilters: AttributeCode[] = [];
  for (const attributeCode of attributeCodesApplied) {
    const _filters = filters.filter(f => f.attributeCode === attributeCode);
    if (_filters.length === 0) {
      multiLevelAttributeCodesMissingInFilters.push(attributeCode);
      continue;
    }
  }
  return multiLevelAttributeCodesMissingInFilters;
}

export function getRangeAttributeCodeMissingFromRangeFilters(
  rangeFilters: RangeFilterType[],
  productFilterInfo: ProductFilterInfo
): AttributeCode[] {
  const rangeAttributeCodesApplied = Object.keys(
    productFilterInfo.rangeAttributeMap
  );
  const rangeAttributeCodesMissingInFilters: AttributeCode[] = [];
  for (const rangeAttributeCode of rangeAttributeCodesApplied) {
    const _rangeFilters = rangeFilters.filter(
      f => f.attributeCode === rangeAttributeCode
    );
    if (_rangeFilters.length === 0) {
      rangeAttributeCodesMissingInFilters.push(rangeAttributeCode);
    }
  }
  return rangeAttributeCodesMissingInFilters;
}

export function toggleSortOrder(sortAttribute: SortAttribute) {
  const { sortOrder, sortField } = sortAttribute;
  if (sortOrder === SortOrder.ascending) {
    return { sortField, sortOrder: SortOrder.descending };
  }
  return { sortField, sortOrder: SortOrder.ascending };
}

export function mapSortAttributeToGraphQLVariable(
  sortAttribute: SortAttribute
): any {
  const { sortField, sortOrder } = sortAttribute;
  if (sortField != null) {
    return { [sortField]: sortOrder };
  }
  return null;
}

export const defaultProductFilterInfo = (
  sortAttribute: SortAttribute
): ProductFilterInfo => ({
  attributeMap: {},
  multiLevelAttributeMap: {},
  rangeAttributeMap: {},
  sortAttribute,
  predefinedCPFilter: null,
});

export function isFilterAttributeEqual(
  lhs: FilterAttribute,
  rhs: FilterAttribute
) {
  return (
    lhs.attributeCode === rhs.attributeCode &&
    lhs.attributeValue === rhs.attributeValue
  );
}

export interface FilterEqualTypeInput {
  eq?: string;
  in?: string[];
}

export interface FilterRangeTypeInput {
  from?: string;
  to?: string;
}

export interface FilterMatchTypeInput {
  match: string;
}

export type GraphQLFilter = {
  [key in string]:
    | FilterEqualTypeInput
    | FilterRangeTypeInput
    | FilterMatchTypeInput;
};

function isRangeFilter(
  filterInput:
    | FilterEqualTypeInput
    | FilterRangeTypeInput
    | FilterMatchTypeInput
): filterInput is FilterRangeTypeInput {
  return (
    Object.prototype.hasOwnProperty.call(filterInput, "from") ||
    Object.prototype.hasOwnProperty.call(filterInput, "to")
  );
}

export function makeGraphQLFilter(
  productFilterInfo: ApplicableProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
): GraphQLFilter {
  const {
    attributeMap,
    multiLevelAttributeMap,
    rangeAttributeMap,
    predefinedCPFilter,
  } = productFilterInfo;
  const keysOfSingleLevelAttributeMap = Object.keys(attributeMap);
  const keysOfMultiLevelAttributeMap = Object.keys(multiLevelAttributeMap);
  const res: GraphQLFilter = {};

  if (predefinedCPFilter) {
    const { attributeCode, graphQLFilterValue } = predefinedCPFilter;
    res[attributeCode] = graphQLFilterValue;
  }

  for (const key of keysOfSingleLevelAttributeMap) {
    const attribute = attributeMap[key];
    const filterInputField = productAttributeFilterInputMap[key];
    if (attribute && filterInputField && filterInputField.type) {
      const graphQLFilterValue = makeGraphQLFilterValue(
        attribute.attributeValue,
        filterInputField.type.name
      );
      if (graphQLFilterValue) {
        res[key] = graphQLFilterValue;
      }
    }
  }

  for (const key of keysOfMultiLevelAttributeMap) {
    const attribute = multiLevelAttributeMap[key];
    const filterInputField = productAttributeFilterInputMap[key];
    if (attribute && filterInputField && filterInputField.type) {
      const graphQLFilterValue = makeGraphQLFilterValue(
        attribute.attributeValue,
        filterInputField.type.name
      );
      if (graphQLFilterValue) {
        res[key] = graphQLFilterValue;
      }
    }
  }

  const keysForRangeAttributes = Object.keys(rangeAttributeMap);
  for (const key of keysForRangeAttributes) {
    const attribute = rangeAttributeMap[key];
    if (attribute) {
      const { attributeCode, min, max } = attribute;
      res[attributeCode] = {
        from: min != null ? `${min}` : undefined,
        to: max != null ? `${max}` : undefined,
      };
    }
  }

  return correctRangeFilterValuesInGraphQLFilter(res);
}

function correctRangeFilterValuesInGraphQLFilter(
  graphQLFilter: GraphQLFilter
): GraphQLFilter {
  const attributeCodes = Object.keys(graphQLFilter);
  const res: GraphQLFilter = {};
  for (const attributeCode of attributeCodes) {
    const filterValue = graphQLFilter[attributeCode];
    if (isRangeFilter(filterValue)) {
      const { from, to } = filterValue;
      if (from === "0" && to === "0") {
        // Filtering range filter from 0 to 0 will exclude products
        // without 0 value of attributeCode. Just give it a small range
        // to include 0 value in range.
        res[attributeCode] = { ...filterValue, from: "0", to: "0.01" };
        continue;
      }
    }
    res[attributeCode] = filterValue;
  }
  return res;
}

export function filterInvalidFilterAttributes(
  filter: FilterType,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
): FilterType {
  const filterInputField = productAttributeFilterInputMap[filter.attributeCode];
  if (filterInputField != null) {
    if (
      filterInputField.type != null &&
      filterInputField.type.name === "FilterRangeTypeInput"
    ) {
      return {
        ...filter,
        filterAttributes: filter.filterAttributes.filter(
          filterAttribute =>
            !!new RegExp(
              "(.+)?-(.+)|(.+)-(.+)|(.+)-(.+)?|(.+)?_(.+)|(.+)_(.+)|(.+)_(.+)?"
            ).exec(`${filterAttribute.attributeValue}`) &&
            filterAttribute.attributeValue !== "0-0" &&
            filterAttribute.attributeValue !== "*_*"
        ),
      };
    }
  }
  return filter;
}

export function isFilterNotEmpty(
  filter: FilterType,
  productFilterInfo: ProductFilterInfo,
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
): boolean {
  const { attributeCode } = filter;
  const filterInputField = productAttributeFilterInputMap[attributeCode];
  if (
    filterInputField &&
    filterInputField.type &&
    filterInputField.type.name === "FilterRangeTypeInput"
  ) {
    // If the filter originally is a range filter and marked
    // in attribute map (without min-max), once it is selected,
    // the filter will not be regarded as empty even the filter
    // response is empty
    const isSelectedRangeFilterInAttributeMap =
      productFilterInfo.attributeMap[attributeCode] != null;
    if (isSelectedRangeFilterInAttributeMap) {
      return true;
    }
  }
  return filter.filterAttributes.length > 0;
}

export function getRangeFilterTypes(
  filters: FilterType[],
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
): RangeFilterType[] {
  const res: RangeFilterType[] = [];
  for (const filter of filters) {
    const { attributeCode, filterAttributes, title } = filter;
    const filterInputField = productAttributeFilterInputMap[attributeCode];
    if (filterInputField != null) {
      if (
        filterInputField.type != null &&
        filterInputField.type.name === "FilterRangeTypeInput"
      ) {
        const minMaxAttributes = filterAttributes.filter(
          filterAttribute => filterAttribute.title === "min-max"
        );
        if (minMaxAttributes.length > 0) {
          const minMaxAttribute = minMaxAttributes[0];
          const { attributeValue } = minMaxAttribute;
          const minMaxValueRegex = new RegExp("([0-9]+)-([0-9]+)");
          const matched = minMaxValueRegex.exec(`${attributeValue}`);
          if (matched) {
            const rangeMin = parseInt(matched[1], 10);
            const rangeMax = parseInt(matched[2], 10);
            if (
              !isNaN(rangeMin) &&
              !isNaN(rangeMax) &&
              (rangeMin > 0 || rangeMax > 0)
            ) {
              res.push({
                attributeCode,
                title,
                min: rangeMin,
                max: rangeMax,
              });
            }
          }
        }
      }
    }
  }
  return res;
}

export function filterExcludedByAttributeCodes(
  ...attributeCodes: AttributeCode[]
): (filterType: FilterType) => boolean {
  return (filterType: FilterType): boolean => {
    const { attributeCode } = filterType;
    if (attributeCodes.indexOf(attributeCode) > -1) {
      return false;
    }
    return true;
  };
}

// Mock data
export function makeMockFilters(): FilterType[] {
  return [
    {
      attributeCode: "brand",
      title: "Brand",
      filterAttributes: [
        {
          attributeCode: "brand",
          attributeValue: "1",
          attributeType: "text",
          count: 7,
          title: "Apple",
        },
        {
          attributeCode: "brand",
          attributeValue: "2",
          attributeType: "text",
          count: 85,
          title: "Samsung",
        },
      ],
    },
    {
      attributeCode: "internal_storage_rom",
      title: "Internal Storage",
      filterAttributes: [
        {
          attributeCode: "internal_storage_rom",
          attributeValue: "1",
          attributeType: "text",
          count: 4,
          title: "64GB",
        },
      ],
    },
  ];
}

export interface FilterInputField {
  name: string;
  type: {
    name:
      | "FilterEqualTypeInput"
      | "FilterMatchTypeInput"
      | "FilterRangeTypeInput";
  } | null;
}

export interface SortInputField {
  name: string;
}

export function isFilterRangeTypeInput(
  attributeFilterInput: FilterInputField | undefined | null
) {
  return (
    attributeFilterInput &&
    attributeFilterInput.type &&
    attributeFilterInput.type.name === "FilterRangeTypeInput"
  );
}

export function makeGraphQLFilterValue(
  value: string | number,
  type: "FilterEqualTypeInput" | "FilterMatchTypeInput" | "FilterRangeTypeInput"
): FilterEqualTypeInput | FilterRangeTypeInput | FilterMatchTypeInput | null {
  if (type === "FilterEqualTypeInput") {
    return { eq: `${value}` };
  }
  if (type === "FilterRangeTypeInput") {
    const regex = new RegExp("(.+)?_(.+)?");
    const matched = regex.exec(`${value}`);
    if (matched) {
      const [, begin, end] = matched;
      if ((begin === "*" || begin == null) && (end === "*" || end == null)) {
        return null;
      }
      return {
        from: begin === "*" || begin == null ? undefined : begin,
        to: end === "*" || end == null ? undefined : end,
      };
    }
  }
  if (type === "FilterMatchTypeInput") {
    return { match: `${value}` };
  }
  return null;
}

export function isFilterTypeRangeFilter(
  filter: FilterType,
  productAttributeFilterInputMap: IndexMap<AttributeCode, FilterInputField>
) {
  const { attributeCode, filterAttributes } = filter;
  const filterInputField = productAttributeFilterInputMap[attributeCode];
  if (filterInputField != null) {
    if (
      filterInputField.type != null &&
      filterInputField.type.name === "FilterRangeTypeInput"
    ) {
      const minMaxAttributes = filterAttributes.filter(
        filterAttribute => filterAttribute.title === "min-max"
      );
      if (minMaxAttributes.length > 0) {
        return true;
      }
    }
  }
  return false;
}

export function isFilterTypeMultiLevelFilter(
  filter: FilterType,
  productAttributeFilterInputMap: IndexMap<AttributeCode, FilterInputField>
) {
  const { attributeCode, filterAttributes } = filter;
  const filterInputField = productAttributeFilterInputMap[attributeCode];
  if (
    filterInputField != null &&
    filterInputField.type != null &&
    filterInputField.type.name === "FilterRangeTypeInput"
  ) {
    const minMaxAttributes = filterAttributes.filter(
      filterAttribute => filterAttribute.title === "min-max"
    );
    if (minMaxAttributes.length === 0) {
      return true;
    }
  }
  return false;
}

export function isFilterTypeSingleLevelFilter(
  filter: FilterType,
  productAttributeFilterInputMap: IndexMap<AttributeCode, FilterInputField>
) {
  const { attributeCode } = filter;
  const filterInputField = productAttributeFilterInputMap[attributeCode];
  if (
    filterInputField != null &&
    filterInputField.type != null &&
    filterInputField.type.name === "FilterEqualTypeInput"
  ) {
    return true;
  }
  return false;
}

export function convertRangeFilterLabelToValue(value: string) {
  return value.replace(/-/, "_");
}

export function getFilterLabel(
  filterAttribute: FilterAttribute,
  productAttributeFilterInputMap: IndexMap<AttributeCode, FilterInputField>
) {
  const { attributeCode, title } = filterAttribute;
  const filterInput = productAttributeFilterInputMap[attributeCode];
  if (filterInput && isFilterRangeTypeInput(filterInput)) {
    const regex = new RegExp("([0-9]+)?(-|_)([0-9]+)");
    const matched = regex.exec(title);
    if (matched) {
      const [, min, , max] = matched;
      return `${min == null ? "0" : min}-${max}`;
    }
  }
  return title;
}

export function makeGraphQLFilterFromParamsMap(
  params: { [key in string]: string },
  productAttributeFilterFields: string[],
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
) {
  const res: GraphQLFilter = {};
  for (const field of productAttributeFilterFields) {
    const value = params[field];
    const filterInputField = productAttributeFilterInputMap[field];
    if (value != null && filterInputField != null && filterInputField.type) {
      const graphQLFilterValue = makeGraphQLFilterValue(
        value,
        filterInputField.type.name
      );
      if (graphQLFilterValue != null) {
        res[field] = graphQLFilterValue;
      }
    }
  }
  return res;
}

export function getFilterAttributeMapsFromFilters(
  filterTypes: FilterType[],
  params: { [attributeCode in string]: string },
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>
) {
  const singleLevelFilterAttributeMap: {
    [attributeCode: string]: FilterAttribute;
  } = {};
  const multiLevelLevelFilterAttributeMap: {
    [attributeCode: string]: FilterAttribute[];
  } = {};
  const rangeFilterAttributeMap: {
    [attributeCode: string]: RangeFilterAttribute;
  } = {};
  for (const filterType of filterTypes) {
    const { attributeCode } = filterType;
    const value = params[attributeCode];
    if (value == null) {
      continue;
    }
    if (
      isFilterTypeSingleLevelFilter(filterType, productAttributeFilterInputMap)
    ) {
      const [filterAttribute] = filterType.filterAttributes.filter(
        _filterAttribute => _filterAttribute.attributeValue === value
      );
      if (filterAttribute) {
        singleLevelFilterAttributeMap[attributeCode] = filterAttribute;
      }
    } else if (
      isFilterTypeMultiLevelFilter(filterType, productAttributeFilterInputMap)
    ) {
      const attributeFilterInput =
        productAttributeFilterInputMap[attributeCode];
      const attributeValue = isFilterRangeTypeInput(attributeFilterInput)
        ? convertRangeFilterLabelToValue(value)
        : value;
      multiLevelLevelFilterAttributeMap[attributeCode] = [
        {
          attributeCode,
          attributeType: "text",
          attributeValue,
          title: value,
          count: 0,
        },
      ];
    } else if (
      isFilterTypeRangeFilter(filterType, productAttributeFilterInputMap)
    ) {
      const { title } = filterType;
      if (value != null) {
        const minMaxValueRegex = new RegExp("([0-9.]+)-([0-9.]+)");
        const matched = minMaxValueRegex.exec(`${value}`);
        if (matched) {
          const rangeMin = parseFloat(matched[1]);
          const rangeMax = parseFloat(matched[2]);
          if (!isNaN(rangeMin) && !isNaN(rangeMax)) {
            rangeFilterAttributeMap[attributeCode] = {
              attributeCode,
              title,
              min: rangeMin,
              max: rangeMax,
            };
          }
        }
      }
    }
  }
  return {
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
  };
}
