import React, { useEffect, useCallback } from "react";
import { Plugins } from "@capacitor/core";

import Config from "../Config";

interface ShouldShowInterstitialSkip {
  type: "skip";
}
const ShouldShowInterstitialSkip: ShouldShowInterstitialSkip = {
  type: "skip",
};

interface ShouldShowInterstitialShow {
  type: "show";
  adUnitId: string;
}
function ShouldShowInterstitialShow(
  adUnitId: string
): ShouldShowInterstitialShow {
  return {
    type: "show",
    adUnitId,
  };
}

type ShouldShowInterstitial =
  | ShouldShowInterstitialSkip
  | ShouldShowInterstitialShow;

const shouldShowInterstitial: ShouldShowInterstitial =
  Config.ADS && Config.ADS.INTERSTITIAL
    ? { type: "show", adUnitId: Config.ADS.INTERSTITIAL }
    : { type: "skip" };

const GoogleAdsInterstitial: React.FC = props => {
  const showInterstitial = useCallback(async (adUnitId: string) => {
    try {
      await Plugins.GoogleAdsPlugin.presentInterstitial({ adUnitId });
      const interstitialDidDismissScreenListener = Plugins.GoogleAdsPlugin.addListener(
        "interstitialDidDismissScreen",
        () => {
          interstitialDidDismissScreenListener.remove();
        }
      );
    } catch {}
  }, []);

  useEffect(() => {
    if (shouldShowInterstitial.type === "show") {
      showInterstitial(shouldShowInterstitial.adUnitId);
    }
  }, [showInterstitial]);

  return <>{props.children}</>;
};

export default GoogleAdsInterstitial;
