import { MessageArgs } from "../i18n/Localization";
import { MessageID } from "../i18n/translations/type";

interface RemoteError {
  type: "remote";
  message: string;
}
export function RemoteError(message: string): RemoteError {
  return {
    type: "remote",
    message,
  };
}
export function isRemoteError(error: any): error is RemoteError {
  if (
    typeof error === "object" &&
    error.type === "remote" &&
    error.message != null
  ) {
    return true;
  }
  return false;
}

export interface LocalError {
  type: "local";
  messageID: MessageID;
  messageArgs?: MessageArgs;
}
export function LocalError(
  messageID: MessageID,
  messageArgs?: MessageArgs
): LocalError {
  return {
    type: "local",
    messageID,
    messageArgs,
  };
}
export function isLocalError(error: any): error is LocalError {
  if (
    typeof error === "object" &&
    error.type === "local" &&
    error.messageID != null
  ) {
    return true;
  }
  return false;
}

export type CLError = RemoteError | LocalError;
