import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import { parseGraphQLError } from "../../api/GraphQL";
import { getStoreViewCodeForLocale, Locale } from "../../i18n/locale";
import {
  Brand,
  BrandSlider,
  RemoteBrandSlider,
  transformRemoteBrandSliderToBrandSlider,
} from "../../models/Brand";

const BrandSliderItemGraphQLAttributes = `
  alt
  img
  label
  position
  url
  id: brandId
`;

const BrandSliderGraphQLAttributes = `
  autoplay
  autoplayDelay: autoplay_delay
  buttonsShow: buttons_show
  imageWidth: image_width
  imageHeight: image_height
  sliderHeaderColor: slider_header_color
  sliderTitle: slider_title
  sliderTitleColor: slider_title_color
  items {
    ${BrandSliderItemGraphQLAttributes}
  }
  itemsNumber: items_number
`;

export function getBrands(client: ApolloClient<any>): Brand[] | null {
  const result = client.readQuery<{ ambrandlist: { items: Brand[] } }>({
    query: gql`
      query amBrandList {
        ambrandlist {
          items {
            ${BrandGraphQLAttributes}
          }
        }
      }
    `,
  });
  if (result) {
    return result.ambrandlist.items;
  }
  return null;
}

export function getBrandSlider(client: ApolloClient<any>): BrandSlider | null {
  const results = client.readQuery<{ ambrandslider: RemoteBrandSlider }>({
    query: gql`
        query amBrandSlider {
          ambrandslider {
            ${BrandSliderGraphQLAttributes}
          }
        }
      `,
  });

  if (results) {
    return transformRemoteBrandSliderToBrandSlider(results.ambrandslider);
  }

  return null;
}

export async function fetchBrandSlider(
  client: ApolloClient<any>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<BrandSlider | null> {
  try {
    const results = await client.query<{ ambrandslider: RemoteBrandSlider }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
        query amBrandSlider {
          ambrandslider {
            ${BrandSliderGraphQLAttributes}
          }
        }
      `,
      fetchPolicy,
    });

    if (results.data && results.data.ambrandslider) {
      return transformRemoteBrandSliderToBrandSlider(
        results.data.ambrandslider
      );
    }

    return null;
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}

const BrandGraphQLAttributes = `
label
url
id: brandId
`;

const BrandTopCmsIdGraphQLAttributes = `
id: brandId
topCmsId
`;

export async function fetchBrandList(
  client: ApolloClient<unknown>,
  locale: Locale,
  fetchPolicy: FetchPolicy
): Promise<Brand[] | null> {
  try {
    const query = async <T>(graphQLAttributes: string) => {
      return client.query<{ ambrandlist: { items: T[] } }>({
        context: {
          headers: {
            Store: getStoreViewCodeForLocale(locale),
          },
        },
        query: gql`
          query amBrandList {
            ambrandlist {
              items {
                ${graphQLAttributes}
              }
            }
          }
        `,
        fetchPolicy,
      });
    };

    const [baseBrandResults, brandTopCmsIdResults] = await Promise.all([
      query<Pick<Brand, "id" | "label" | "url">>(BrandGraphQLAttributes),
      query<Pick<Brand, "id" | "topCmsId">>(
        BrandTopCmsIdGraphQLAttributes
      ).catch(() => ({ data: { ambrandlist: { items: [] } } })),
    ]);

    const topCmsIdMap: { [key in string]: string | null } = {};
    for (const brandTopCmsId of brandTopCmsIdResults.data.ambrandlist.items) {
      const { id, topCmsId } = brandTopCmsId;
      if (id) {
        topCmsIdMap[id] = topCmsId || null;
      }
    }

    return baseBrandResults.data.ambrandlist.items.map(brand => ({
      ...brand,
      topCmsId: brand.id ? topCmsIdMap[brand.id] : null,
    }));
  } catch (e) {
    const graphQLError = parseGraphQLError(e);
    if (graphQLError) {
      throw new Error(graphQLError);
    }
    throw e;
  }
}
