import React, { useState, useEffect } from "react";
import QRCode from "qrcode";

interface Props {
  code: string;
}

const QRCodeView: React.FC<Props> = props => {
  const { code } = props;

  const [dataURL, setDataURL] = useState<string | null>(null);

  useEffect(() => {
    QRCode.toDataURL(code, { margin: 1, scale: 16 }, (error, url) => {
      if (error) {
        console.error(error);
        return;
      }
      if (url) {
        setDataURL(url);
      }
    });
    return () => {
      setDataURL(null);
    };
  }, [code]);

  return dataURL != null ? <img alt={code} src={dataURL} /> : <></>;
};

export default QRCodeView;
