import { useApolloClient } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import ProductDetailPage from ".";
import {
  fetchProduct,
  fetchProductRelatedProducts,
  getProduct,
  getProductRelatedProducts,
} from "../../api/GraphQL";
import { ProfileSessionProvider } from "../../contexts/ProfileSessionContext";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";
import { useIntl } from "../../i18n/Localization";
import {
  isNormalProductSoldOut,
  shouldNormalProductShowPrice,
} from "../../models/product";
import { ProductOverview } from "../../models/ProductOverview";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { useMemoryProductDetail } from "../../repository/ProductRepository";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { ProductDetailPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { usePurchaseProductFormStateReducer } from "./PurchaseProductModal/PurchaseProductFormStateHook";
import { PageResource } from "./type";

interface RouteParams {
  sku: string;
}

function usePageResource(
  sku: string
): {
  requestState: ResourcesRequestState<PageResource | null>;
  startRequesting: () => Promise<PageResource | null>;
  refresh: () => Promise<PageResource | null>;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    PageResource | null,
    () => Promise<PageResource | null>
  >({
    localCacheProvider: async () => {
      const product = getProduct(client, sku);
      if (product) {
        return {
          product,
        };
      }
      return null;
    },
    remoteResourcesProvider: async () => {
      const product = await fetchProduct(client, sku, locale, "network-only");
      if (product) {
        return {
          product,
        };
      }
      return null;
    },
  });

  return {
    requestState,
    startRequesting: fetch,
    refresh,
  };
}

function useRelatedProductsResource(
  sku: string
): {
  requestState: ResourcesRequestState<ProductOverview[]>;
  startRequesting: () => Promise<ProductOverview[]>;
  refresh: () => Promise<ProductOverview[]>;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    ProductOverview[],
    () => Promise<ProductOverview[]>
  >({
    localCacheProvider: async () => getProductRelatedProducts(client, sku),
    remoteResourcesProvider: () =>
      fetchProductRelatedProducts(client, sku, locale, "network-only"),
  });

  return {
    requestState,
    startRequesting: fetch,
    refresh,
  };
}

const NormalProductDetailPageImpl: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { sku } = match.params;

  const ionLifeCycleContext = useCLIonLifeCycleContext();
  const productDetailInMemory = useMemoryProductDetail(sku);

  const {
    requestState: productResourcesState,
    startRequesting: startRequestingProduct,
    refresh: refreshProduct,
  } = usePageResource(sku);

  const {
    requestState: relatedProductsResourcesState,
    startRequesting: startRequestingRelatedProducts,
    refresh: refreshRelatedProducts,
  } = useRelatedProductsResource(sku);

  const purchaseProductFormStateReducer = usePurchaseProductFormStateReducer();
  const configProductFormState = purchaseProductFormStateReducer.formState;
  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = React.useCallback(() => {
    presentAddToCartModal(sku, configProductFormState, {
      onAddToCartFinalResult: result => {
        if (
          result &&
          result.type === "failed" &&
          result.reason === "OUT_OF_STOCK"
        ) {
          refreshProduct();
        }
      },
    });
  }, [configProductFormState, presentAddToCartModal, refreshProduct, sku]);

  return (
    <ProductDetailPage
      sku={sku}
      productDetailInMemory={productDetailInMemory}
      pageResourceState={productResourcesState}
      startRequestingPage={startRequestingProduct}
      refreshPage={refreshProduct}
      relatedProductsResouceState={relatedProductsResourcesState}
      startRequestingRelatedProducts={startRequestingRelatedProducts}
      refreshRelatedProducts={refreshRelatedProducts}
      ionLifeCycleContext={ionLifeCycleContext}
      purchaseProductFormStateReducer={purchaseProductFormStateReducer}
      onAddToCartClick={openPurchaseProductModal}
      allowShare={true}
      isSoldOut={isNormalProductSoldOut}
      shouldShowPrice={shouldNormalProductShowPrice}
    />
  );
};

const NormalProductDetailPage: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { sku } = match.params;
  return (
    <ProfileSessionProvider profileSession={ProductDetailPageSession(sku)}>
      <NormalProductDetailPageImpl />
    </ProfileSessionProvider>
  );
};

export default NormalProductDetailPage;
