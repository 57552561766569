import { useState, useEffect } from "react";

export default function useRetryWhenOnline(
  isOnline: boolean,
  onRetry?: () => void
) {
  const [prevIsOnline, setPrevIsOnline] = useState(isOnline);

  useEffect(() => {
    if (isOnline && !prevIsOnline && onRetry) {
      onRetry();
    }
    return () => {
      setPrevIsOnline(isOnline);
    };
  }, [prevIsOnline, isOnline, onRetry]);
}
