import moment from "moment";
import React, { useCallback, useContext, useMemo } from "react";
import CountryRegionDistrictContext from "../../contexts/CountryRegionDistrictContext";
import { Locale } from "../../i18n/locale";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import {
  formatAddress,
  getEstimatedDeliveryDateOfMerchant,
  getEstimatedDeliveryDateOfType,
  OrderEstimatedDeliveryDate,
  useFormatOtoStore,
} from "../../models/Order";
import { PartialOrder } from "../CheckoutConfirmationPage/models";
import styles from "./CheckoutOrderedPageComponent.module.scss";
import { DeliveryAccessory } from "./DeliveryAccessory";
import { EVoucherAccessory } from "./EVoucherAccessory";
import { ShipmentBlock } from "./ShipmentBlock";

interface Props {
  order: PartialOrder;
  onMyOrderClick: () => void;
}

const CheckoutOrderedPageComponent: React.FC<Props> = props => {
  const { order, onMyOrderClick } = props;
  const { shippingAddress, pickupAddress } = order;

  const { countryByID } = useContext(CountryRegionDistrictContext);
  const formatOtoStore = useFormatOtoStore();
  const { locale } = useIntl();

  const estimatedDeliveryDateEvoucher = useMemo(
    () => getEstimatedDeliveryDateOfType("EVoucher", order),
    [order]
  );
  const estimatedDeliveryDateMerchant = useMemo(
    () => getEstimatedDeliveryDateOfMerchant(order),
    [order]
  );

  const estimatedDeliveryDateConsolidateOrWarehouse = useMemo<OrderEstimatedDeliveryDate | null>(() => {
    const estimatedDeliveryDateConsolidate = getEstimatedDeliveryDateOfType(
      "Consolidate",
      order
    );
    const estimatedDeliveryDateWarehouse = getEstimatedDeliveryDateOfType(
      "Warehouse",
      order
    );
    if (estimatedDeliveryDateConsolidate && estimatedDeliveryDateWarehouse) {
      const dateFormat = locale === Locale.en ? "MMM D, YYYY" : "YYYY年M月D日";
      const c = moment(
        estimatedDeliveryDateConsolidate.estimatedDeliveryDate,
        dateFormat
      );
      const w = moment(
        estimatedDeliveryDateWarehouse.estimatedDeliveryDate,
        dateFormat
      );
      return {
        shippingType: "Consolidate",
        estimatedDeliveryDate: c.isAfter(w)
          ? c.format(dateFormat)
          : w.format(dateFormat),
      };
    } else if (estimatedDeliveryDateConsolidate) {
      return estimatedDeliveryDateConsolidate;
    } else if (estimatedDeliveryDateWarehouse) {
      return estimatedDeliveryDateWarehouse;
    }
    return null;
  }, [locale, order]);

  const hasDelivery =
    estimatedDeliveryDateConsolidateOrWarehouse != null ||
    estimatedDeliveryDateMerchant != null;

  const isMixed = estimatedDeliveryDateEvoucher != null && hasDelivery;

  const addressStr = useMemo(() => {
    if (pickupAddress != null) {
      return formatOtoStore(pickupAddress);
    }

    return shippingAddress == null || shippingAddress.length === 0
      ? "-"
      : formatAddress(countryByID, shippingAddress[0]);
  }, [pickupAddress, shippingAddress, formatOtoStore, countryByID]);

  const handleMyOrderClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onMyOrderClick();
    },
    [onMyOrderClick]
  );

  return (
    <div>
      <h1 className={styles.handleTitle}>
        <LocalizedText messageID="checkout.ordered.handle_title" />
      </h1>
      <p className={styles.handleOrderId}>
        <LocalizedText messageID="checkout.ordered.handle_order_id" />{" "}
        <button onClick={handleMyOrderClick} className={styles.orderIdButton}>
          {order.incrementID}
        </button>
      </p>
      <div className={styles.description}>
        {isMixed ? (
          <LocalizedText messageID="checkout.ordered.handle_order_redemption_and_delivery" />
        ) : estimatedDeliveryDateEvoucher ? (
          <LocalizedText messageID="checkout.ordered.handle_order_redemption" />
        ) : (
          <LocalizedText messageID="checkout.ordered.handle_order_delivery" />
        )}
      </div>
      {estimatedDeliveryDateEvoucher ? (
        <div className={styles.shipmentBlock}>
          <ShipmentBlock
            title={<LocalizedText messageID="checkout.ordered.evoucher" />}
            fieldName={
              <LocalizedText messageID="checkout.ordered.evoucher.redemption_arrangement" />
            }
            value={
              <LocalizedText
                messageID="checkout.ordered.evoucher.my_order_message"
                messageArgs={{
                  onMyOrderClick,
                  myOrderMessageButtonClassName: styles.myOrderMessageButton,
                }}
              />
            }
            accessory={<EVoucherAccessory />}
          />
        </div>
      ) : null}
      {isMixed ? (
        <div className={styles.description}>
          <LocalizedText messageID="checkout.ordered.other_items" />
        </div>
      ) : null}
      {hasDelivery ? (
        <div className={styles.addressBox}>{addressStr}</div>
      ) : null}
      {estimatedDeliveryDateConsolidateOrWarehouse ? (
        <div className={styles.shipmentBlock}>
          <ShipmentBlock
            title={
              estimatedDeliveryDateConsolidateOrWarehouse.shippingType ===
              "Consolidate" ? (
                <LocalizedText messageID="checkout.ordered.delivery.consolidate" />
              ) : (
                <LocalizedText messageID="checkout.ordered.delivery.warehouse" />
              )
            }
            fieldName={
              <LocalizedText messageID="checkout.ordered.delivery.estimated_delivery_date_title" />
            }
            value={
              estimatedDeliveryDateConsolidateOrWarehouse.estimatedDeliveryDate
            }
            accessory={
              <div className={styles.deliveryAccessory}>
                <DeliveryAccessory />
              </div>
            }
          />
        </div>
      ) : null}
      {estimatedDeliveryDateMerchant ? (
        <div className={styles.shipmentBlock}>
          <ShipmentBlock
            title={
              <LocalizedText messageID="checkout.ordered.delivery.merchant" />
            }
            fieldName={
              <LocalizedText messageID="checkout.ordered.merchant.delivery_arrangement" />
            }
            value={
              <LocalizedText messageID="checkout.ordered.merchant.delivery_message" />
            }
            accessory={
              <div className={styles.deliveryAccessory}>
                <DeliveryAccessory />
              </div>
            }
          />
        </div>
      ) : null}
      <div className={styles.thankyou}>
        <LocalizedText messageID="checkout.ordered.thankyou" />
      </div>
    </div>
  );
};

export default CheckoutOrderedPageComponent;
