import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import { IonSlides, IonSlide } from "@ionic/react";

import { CMSCarousel } from "../../../models/cmsBlock";

import ImageCarousel from "./ImageCarousel";

import styles from "./styles.module.scss";
import { useKeepUpdatingRef } from "../../../hook/utils";
import useInViewPolyfill from "../../../hook/useInViewPolyfill";

interface Props {
  cmsCarousel: CMSCarousel;
}

const CMSCarouselView: React.FC<Props> = React.memo((props: Props) => {
  const {
    cmsCarousel: { items: maybeItems },
  } = props;

  const items = useMemo(() => maybeItems || [], [maybeItems]);

  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const numberOfItems = useKeepUpdatingRef(items.length);
  const autoScrollIntervalToken = useRef<NodeJS.Timeout | null>(null);
  const [ref, inView] = useInViewPolyfill();

  const startAutoScroll = useCallback(() => {
    if (autoScrollIntervalToken.current) {
      clearInterval(autoScrollIntervalToken.current);
    }

    const token = setInterval(async () => {
      if (slidesRef.current == null) {
        return;
      }
      const currentSlide = await slidesRef.current.getActiveIndex();
      if (slidesRef.current == null) {
        return;
      }

      slidesRef.current.slideTo(
        (currentSlide + 1) % numberOfItems.current,
        1000
      );
    }, 4000);
    autoScrollIntervalToken.current = token;
  }, [numberOfItems]);

  const stopAutoScroll = useCallback(() => {
    if (autoScrollIntervalToken.current != null) {
      clearInterval(autoScrollIntervalToken.current);
      autoScrollIntervalToken.current = null;
    }
  }, []);

  useEffect(() => {
    if (inView) {
      startAutoScroll();
    }
    return () => {
      stopAutoScroll();
    };
  }, [startAutoScroll, stopAutoScroll, inView]);

  useEffect(() => {
    const onResize = () => {
      setTimeout(() => {
        if (slidesRef.current != null) {
          slidesRef.current.update();
        }
      }, 1000);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const isStoppedAutoScrollDueToManualScroll = useRef(false);
  const isTouchContainerMoved = useRef(false);
  const onTouchContainerStart = useCallback(() => {
    stopAutoScroll();
  }, [stopAutoScroll]);
  const onTouchContainerMove = useCallback(() => {
    // vertical scroll will trigger onTouchContainerMove
    isTouchContainerMoved.current = true;
  }, []);
  const onTouchContainerCancelOrEnd = useCallback(() => {
    if (
      isTouchContainerMoved.current === true &&
      isStoppedAutoScrollDueToManualScroll.current === false
    ) {
      startAutoScroll();
      // reset flag
      isTouchContainerMoved.current = false;
    } else {
      isStoppedAutoScrollDueToManualScroll.current = true;
    }
  }, [startAutoScroll]);

  const [activeIndex, setActiveIndex] = useState(0);
  const onIonSlideTransitionEnd = useCallback(async () => {
    if (slidesRef.current == null) {
      return;
    }

    const _activeIndex = await slidesRef.current.getActiveIndex();
    setActiveIndex(_activeIndex);
  }, []);

  const itemVisibility = useMemo(() => {
    const result = new Array(items.length);
    for (let i = 0; i < items.length; i++) {
      if (activeIndex === 0 && i === items.length - 1) {
        result[i] = true;
        continue;
      }

      if (activeIndex === items.length - 1 && i === 0) {
        result[i] = true;
        continue;
      }

      result[i] =
        i === activeIndex - 1 || i === activeIndex || i === activeIndex + 1;
    }
    return result;
  }, [items, activeIndex]);

  const hasItems = items.length > 0;

  // Force update slides because the pagination bullets may not be initialized
  useEffect(() => {
    (async () => {
      if (hasItems && slidesRef.current) {
        const swiper = await slidesRef.current.getSwiper();
        swiper.update();
      }
    })();
  }, [hasItems]);

  const ionSlidesOptions = useMemo(
    () => ({
      zoom: false,
    }),
    []
  );

  if (!hasItems) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={styles.container}
      onTouchStart={onTouchContainerStart}
      onTouchMove={onTouchContainerMove}
      onTouchCancel={onTouchContainerCancelOrEnd}
      onTouchEnd={onTouchContainerCancelOrEnd}
    >
      {items.length > 1 && (
        <IonSlides
          ref={slidesRef}
          key={items.length}
          mode="ios"
          pager={true}
          className={styles.slides}
          onIonSlideTransitionEnd={onIonSlideTransitionEnd}
          options={ionSlidesOptions}
        >
          {items.map((item, i) => (
            <IonSlide key={i}>
              <ImageCarousel item={item} hidden={!itemVisibility[i]} />
            </IonSlide>
          ))}
        </IonSlides>
      )}
      {items.length === 1 && (
        <div className={styles.slides}>
          <ImageCarousel item={items[0]} />
        </div>
      )}
    </div>
  );
});

export default CMSCarouselView;
