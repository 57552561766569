import { useContext, useMemo, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { useIntl } from "../i18n/Localization";
import {
  ResourcesRequestState,
  getResources,
  isRequestLoading,
} from "../models/ResourcesRequestState";
import ProductAttributeFilterContext from "../contexts/ProductAttributeFilterContext";
import {
  FilterType,
  FilterAttribute,
  RangeFilterAttribute,
  GraphQLFilter,
  isFilterTypeSingleLevelFilter,
  isFilterTypeMultiLevelFilter,
  isFilterTypeRangeFilter,
  convertRangeFilterLabelToValue,
  isFilterRangeTypeInput,
  makeGraphQLFilterFromParamsMap,
  SortFields,
} from "../models/filter";
import { convertAggregationToFilter } from "../utils/Filter";
import { Session } from "../utils/PerformanceRecordStore/sessions";
import { profileAsyncAction } from "../utils/performance";

import { fetchAggregation, fetchSortFields } from "../api/GraphQL";

import { useFetchResources_v2 } from "./Hooks";

export function useFetchFilters(
  profileSession?: Session
): [
  ResourcesRequestState<FilterType[] | null>,
  (search: string, filter: GraphQLFilter) => Promise<FilterType[] | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch }] = useFetchResources_v2<
    FilterType[] | null,
    (search: string, filter: GraphQLFilter) => Promise<FilterType[] | null>
  >({
    localCacheProvider: async (search: string, filter: GraphQLFilter) => {
      const action = async () => {
        const aggregations = await fetchAggregation(
          client,
          search,
          filter,
          locale,
          "cache-only"
        );
        if (aggregations) {
          return aggregations.map(convertAggregationToFilter);
        }
        return null;
      };
      return profileSession
        ? profileAsyncAction(profileSession, "Load filters from cache", action)
        : action();
    },
    remoteResourcesProvider: async (search: string, filter: GraphQLFilter) => {
      const action = async () => {
        const aggregations = await fetchAggregation(
          client,
          search,
          filter,
          locale,
          "network-only"
        );
        if (aggregations) {
          return aggregations.map(convertAggregationToFilter);
        }
        return null;
      };
      return profileSession
        ? profileAsyncAction(
            profileSession,
            "Load filters from network",
            action
          )
        : action();
    },
  });

  return [requestState, fetch];
}

export function useInitializeFilterWithQueryParameters(
  search: string,
  fixedParams: { [key in string]: string },
  queryParams: { [key in string]: string },
  profileSession?: Session
): [
  {
    [attributeCode: string]: FilterAttribute;
  },
  {
    [attributeCode: string]: FilterAttribute[];
  },
  {
    [attributeCode: string]: RangeFilterAttribute;
  },
  boolean
] {
  const {
    productAttributeFilterFields,
    productAttributeFilterInputMap,
  } = useContext(ProductAttributeFilterContext);
  const [requestState, fetch] = useFetchFilters(profileSession);

  const fixedFilter = useMemo<GraphQLFilter>(
    () =>
      makeGraphQLFilterFromParamsMap(
        fixedParams,
        productAttributeFilterFields,
        productAttributeFilterInputMap
      ),
    [fixedParams, productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const queryFilter = useMemo<GraphQLFilter>(
    () =>
      makeGraphQLFilterFromParamsMap(
        queryParams,
        productAttributeFilterFields,
        productAttributeFilterInputMap
      ),
    [queryParams, productAttributeFilterFields, productAttributeFilterInputMap]
  );

  const filter = useMemo<GraphQLFilter>(
    () => ({ ...fixedFilter, ...queryFilter }),
    [fixedFilter, queryFilter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      return;
    }
    fetch(search, filter).catch(() => {});
  }, [fetch, search, filter]);

  const rangeFilterTypes = useMemo(() => {
    const filterTypes = getResources(requestState);
    if (!filterTypes) {
      return [];
    }
    return filterTypes.filter(filterType =>
      isFilterTypeRangeFilter(filterType, productAttributeFilterInputMap)
    );
  }, [requestState, productAttributeFilterInputMap]);

  const resolvedInitialFilterAttributeMap = useMemo<{
    [attributeCode: string]: FilterAttribute;
  }>(() => {
    const filterTypes = getResources(requestState);
    if (!filterTypes) {
      return {};
    }
    const res: { [attributeCode: string]: FilterAttribute } = {};
    for (const filterType of filterTypes) {
      const { attributeCode } = filterType;
      const value = queryParams[attributeCode];
      if (value == null) {
        continue;
      }
      if (
        isFilterTypeSingleLevelFilter(
          filterType,
          productAttributeFilterInputMap
        )
      ) {
        const [filterAttribute] = filterType.filterAttributes.filter(
          _filterAttribute => _filterAttribute.attributeValue === value
        );
        if (filterAttribute) {
          res[attributeCode] = filterAttribute;
        }
      }
    }
    return res;
  }, [requestState, queryParams, productAttributeFilterInputMap]);

  const resolvedInitialMultiLevelFilterAttributeMap = useMemo<{
    [attributeCode: string]: FilterAttribute[];
  }>(() => {
    const filterTypes = getResources(requestState);
    if (!filterTypes) {
      return {};
    }
    const res: { [attributeCode: string]: FilterAttribute[] } = {};
    for (const filterType of filterTypes) {
      const { attributeCode } = filterType;
      const value = queryParams[attributeCode];
      if (value == null) {
        continue;
      }
      if (
        isFilterTypeMultiLevelFilter(filterType, productAttributeFilterInputMap)
      ) {
        const attributeFilterInput =
          productAttributeFilterInputMap[attributeCode];
        const attributeValue = isFilterRangeTypeInput(attributeFilterInput)
          ? convertRangeFilterLabelToValue(value)
          : value;
        res[attributeCode] = [
          {
            attributeCode,
            attributeType: "text",
            attributeValue,
            title: value,
            count: 0,
          },
        ];
      }
    }
    return res;
  }, [productAttributeFilterInputMap, queryParams, requestState]);

  const resolvedInitialRangeFilterAttributeMap = useMemo<{
    [attributeCode: string]: RangeFilterAttribute;
  }>(() => {
    const filterTypes = getResources(requestState);
    if (!filterTypes) {
      return {};
    }
    const res: { [attributeCode: string]: RangeFilterAttribute } = {};
    for (const filterType of rangeFilterTypes) {
      const { attributeCode, title } = filterType;
      const value = queryParams[attributeCode];
      if (value != null) {
        const minMaxValueRegex = new RegExp("([0-9.]+)-([0-9.]+)");
        const matched = minMaxValueRegex.exec(`${value}`);
        if (matched) {
          const rangeMin = parseFloat(matched[1]);
          const rangeMax = parseFloat(matched[2]);
          if (!isNaN(rangeMin) && !isNaN(rangeMax)) {
            res[attributeCode] = {
              attributeCode,
              title,
              min: rangeMin,
              max: rangeMax,
            };
          }
        }
      }
    }
    return res;
  }, [requestState, queryParams, rangeFilterTypes]);

  const isLoading = useMemo(() => isRequestLoading(requestState), [
    requestState,
  ]);

  return [
    resolvedInitialFilterAttributeMap,
    resolvedInitialMultiLevelFilterAttributeMap,
    resolvedInitialRangeFilterAttributeMap,
    isLoading,
  ];
}

export function useFetchSortFields(
  profileSession?: Session
): [
  ResourcesRequestState<SortFields | null>,
  (search: string, filter: GraphQLFilter) => Promise<SortFields | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch }] = useFetchResources_v2<
    SortFields | null,
    (search: string, filter: GraphQLFilter) => Promise<SortFields | null>
  >({
    localCacheProvider: (search: string, filter: GraphQLFilter) => {
      const action = () =>
        fetchSortFields(client, search, filter, locale, "cache-only");
      return profileSession
        ? profileAsyncAction(
            profileSession,
            "Load sort fields from cache",
            action
          )
        : action();
    },
    remoteResourcesProvider: (search: string, filter: GraphQLFilter) => {
      const action = () =>
        fetchSortFields(client, search, filter, locale, "network-only");
      return profileSession
        ? profileAsyncAction(
            profileSession,
            "Load sort fields from network",
            action
          )
        : action();
    },
  });

  return [requestState, fetch];
}
