import React, { useMemo, useCallback } from "react";
import classnames from "classnames";

import CLLink from "../navigation/CLLink";

import styles from "./styles.module.scss";

enum ButtonType {
  PrimaryButton,
  PrimaryHollowButton,
  BlackButton,
  BlackHollowButton,
  ExpandButton,
  WhiteButton,
}

const buttonTypeClassNameMapping: { [key in ButtonType]: string } = {
  [ButtonType.PrimaryButton]: styles.primaryButton,
  [ButtonType.PrimaryHollowButton]: styles.primaryHollowButton,
  [ButtonType.BlackButton]: styles.blackButton,
  [ButtonType.BlackHollowButton]: styles.blackHollowButton,
  [ButtonType.ExpandButton]: styles.expandButton,
  [ButtonType.WhiteButton]: styles.whiteButton,
};

interface BaseButtonProps {
  buttonType: ButtonType;
  onClick?: (e: React.MouseEvent<unknown>) => any;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  link?: string;
  block?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const BaseButton: React.FC<BaseButtonProps> = (props: BaseButtonProps) => {
  const {
    children,
    onClick,
    className,
    style,
    link,
    block,
    buttonType,
    disabled,
    loading,
  } = props;

  const computedClassName = useMemo(() => {
    return classnames(buttonTypeClassNameMapping[buttonType], className, {
      [styles.block]: block,
      [styles.disabled]: disabled,
      [styles.loading]: loading,
    });
  }, [buttonType, className, block, disabled, loading]);

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      if (onClick && !disabled) {
        onClick(e);
      } else if (disabled) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [onClick, disabled]
  );

  if (link && !disabled && !loading) {
    return (
      <CLLink
        to={link}
        className={computedClassName}
        style={style}
        onClick={handleClick}
      >
        {children}
      </CLLink>
    );
  }

  return (
    <button className={computedClassName} style={style} onClick={handleClick}>
      {children}
    </button>
  );
};

type ButtonProps = Omit<BaseButtonProps, "buttonType">;

export const PrimaryButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  return <BaseButton buttonType={ButtonType.PrimaryButton} {...props} />;
};

export const PrimaryHollowButton: React.FC<ButtonProps> = (
  props: ButtonProps
) => {
  return <BaseButton buttonType={ButtonType.PrimaryHollowButton} {...props} />;
};

export const BlackButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  return <BaseButton buttonType={ButtonType.BlackButton} {...props} />;
};

export const BlackHollowButton: React.FC<ButtonProps> = (
  props: ButtonProps
) => {
  return <BaseButton buttonType={ButtonType.BlackHollowButton} {...props} />;
};

export const ExpandButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  return <BaseButton buttonType={ButtonType.ExpandButton} {...props} />;
};

export const WhiteButton: React.FC<ButtonProps> = React.memo(
  (props: ButtonProps) => {
    return <BaseButton buttonType={ButtonType.WhiteButton} {...props} />;
  }
);
