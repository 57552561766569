export function validateEmail(email: string) {
  const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  //from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  return emailRegExp.test(email.toLowerCase());
}

export function validatePassword(password: string) {
  // Minimum length is 8
  // Accepted characters from oct 41 (!) to oct 176(~)
  if (!/^[\41-\176]{8,}$/.test(password)) {
    return false;
  }
  //Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.
  let score = 0;
  if (/[a-z]/.test(password)) {
    score++;
  }
  if (/[A-Z]/.test(password)) {
    score++;
  }
  if (/\d/.test(password)) {
    score++;
  }
  if (/[\41-\57\72-\100\133-\140\173-\176]/.test(password)) {
    score++;
  }
  return score >= 3;
}

export function validatePhoneNumber(phoneNumber: string) {
  const phoneNumberRegex = /^[0-9]{8}$/;
  return !phoneNumber || phoneNumberRegex.test(phoneNumber);
}
