import React from "react";
import { LocalizedText } from "../../i18n/Localization";
import { PrimaryButton } from "../Button";
import { FullContentEmptyView } from "../EmptyView";
import styles from "./SearchEmptyView.module.scss";

interface Props {
  searchTerm: string;
  onClickBackToStorefrontButton: (e: React.MouseEvent<unknown>) => void;
}

const SearchEmptyView: React.FC<Props> = props => {
  const { searchTerm, onClickBackToStorefrontButton } = props;
  return (
    <FullContentEmptyView
      messageID="search.result_empty.title"
      messageArgs={{
        SEARCH_TERM_HIGHLIGHT_CLASSNAME: styles.searchTermHighlight,
        SEARCH_TERM: searchTerm,
      }}
    >
      <PrimaryButton
        className={styles.backToStorefrontButton}
        onClick={onClickBackToStorefrontButton}
      >
        <LocalizedText messageID="page.search.result_empty.back_to_storefront" />
      </PrimaryButton>
    </FullContentEmptyView>
  );
};

export default SearchEmptyView;
