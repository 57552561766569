import React from "react";
import { LocalizedText } from "../../i18n/Localization";

import styles from "./BundleBadge.module.scss";

const BundleBadge: React.FC = () => {
  return (
    <div className={styles.badge}>
      <LocalizedText messageID="product.badge.bundle" />
    </div>
  );
};

export default BundleBadge;
