import React, {
  useCallback,
  ChangeEvent,
  useState,
  useEffect,
  useRef,
} from "react";
import { IonIcon } from "@ionic/react";
import styles from "./styles.module.scss";
import { Money, formatMoney } from "../../models/Price";
import { LocalizedText } from "../../i18n/Localization";
import ClubpointSlider from "../ClubpointSlider";

interface Props {
  className?: string;
  minValue: number;
  maxValue: number;
  currentValue: number;
  valuePerStep: number;
  grandTotal: Money;
  disabled: boolean;
  onChange: (value: number) => void;
}

const ClubpointWidget: React.FC<Props> = props => {
  const {
    className,
    minValue,
    maxValue,
    currentValue,
    valuePerStep,
    grandTotal,
    disabled,
    onChange,
  } = props;

  // inputValue is a temporary value for user to enter the number freely
  const [inputValue, setInputValue] = useState(`${currentValue}`);
  const inputRef = useRef<HTMLInputElement>(null);
  // update inputValue for updated currentValue
  useEffect(() => {
    const isFocused =
      document.activeElement != null &&
      inputRef.current === document.activeElement;

    // do not interupt user input
    if (!isFocused) {
      setInputValue(`${currentValue}`);
    }
  }, [setInputValue, currentValue]);

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  const onInputBlur = useCallback(() => {
    const newValue = Number(inputValue);
    const roundedNewValue =
      Math.floor(
        Math.max(minValue, Math.min(maxValue, newValue)) / valuePerStep
      ) * valuePerStep;
    setInputValue(`${roundedNewValue}`);
    onChange(roundedNewValue);
  }, [onChange, minValue, maxValue, inputValue, valuePerStep]);

  const handleClubpointSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, []);

  return (
    <div className={className}>
      <div className={styles.container}>
        <div className={styles.sectionContainer}>
          <div className={styles.title}>
            <LocalizedText messageID="clubpoint_widget.use_your_clubpoint" />
          </div>
          <ClubpointSlider
            className={styles.slider}
            minValue={minValue}
            maxValue={maxValue}
            currentValue={currentValue}
            valuePerStep={valuePerStep}
            disabled={disabled}
            onChange={onChange}
          />
          <div className={styles.inputContainer}>
            <div className={styles.inputLabelContainer}>
              <LocalizedText messageID="clubpoint_widget.clubpoint_used" />
              <div className={styles.perBlockContainer}>
                <IonIcon
                  className={styles.clubpointIconGrey}
                  name="club-point"
                />
                <div className={styles.perBlock}>
                  <LocalizedText
                    messageID="clubpoint_widget.per_block"
                    messageArgs={{ perBlock: valuePerStep }}
                  />
                </div>
              </div>
            </div>
            <form
              className={styles.numberInputContainer}
              onSubmit={handleClubpointSubmit}
            >
              <IonIcon
                className={styles.clubpointIconHighlight}
                name="club-point"
              />
              <input
                className={styles.numberInput}
                ref={inputRef}
                type="number"
                value={inputValue}
                disabled={disabled}
                onChange={onInputChange}
                onBlur={onInputBlur}
                step="0.1"
                pattern="\d*"
                inputMode="decimal"
              />
            </form>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.sectionContainer}>
          <div className={styles.extraClubpointUsedContainer}>
            <div className={styles.extraClubpointUsedTitle}>
              <LocalizedText messageID="clubpoint_widget.total_amount" />
            </div>
            <div className={styles.extraClubpointUsedValue}>
              <span className={styles.extraClubpointsUsedText}>
                {formatMoney(grandTotal)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubpointWidget;
