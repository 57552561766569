import React from "react";

export function withProvider<P>(
  Component: React.ComponentType<P>,
  Provider: React.ComponentType<{}>
) {
  const WithProvider: React.FC<P> = props => {
    return (
      <Provider>
        <Component {...props} />
      </Provider>
    );
  };

  return WithProvider;
}

export function withProviders<P>(
  Component: React.ComponentType<P>,
  FirstProvider: React.ComponentType<{}>,
  ...Providers: React.ComponentType<{}>[]
) {
  let component = withProvider<P>(Component, FirstProvider);

  for (let i = 0; i < Providers.length; i++) {
    const Provider = Providers[i];
    component = withProvider<P>(component, Provider);
  }

  return component;
}
