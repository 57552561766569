import React, { useContext, useRef, useMemo, useCallback } from "react";
import { IonRefresher, IonRefresherContent, IonIcon } from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";

import { NavBar, NavBarBackButton } from "../NavBar";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import CLContent from "../CLContent";
import { MessageID } from "../../i18n/translations/type";
import { formatMoney } from "../../models/Price";
import { OurNavContext } from "../../our-navigation";
import {
  getPathForOrderDetail,
  useCurrentTab,
  getPathForHomePage,
} from "../../navigation/routes";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { useCustomerOrdersResource } from "./api";
import {
  getResources,
  getRequestStateError,
  isRequestError,
} from "../../models/ResourcesRequestState";
import { FullContentLoadingView } from "../LoadingView";
import { CustomerOrder, CustomerOrders } from "../../models/CustomerOrders";
import usePullToRefresh from "../../utils/pullToRefresh";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { PrimaryButton } from "../Button";
import moment from "moment";
import { FullContentErrorView } from "../ErrorView";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import ClubProtectBanner from "./ClubProtectBanner";

import styles from "./styles.module.scss";

function viewEnter() {
  pageView({ page: "My Order" });
}

type Props = RouteComponentProps;

const MyOrdersPage: React.FC<Props> = props => {
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  ionLifeCycleContext.onIonViewDidEnter(viewEnter);

  const { isOnline } = useContext(NetworkStatusContext);

  const { translate } = useIntl();
  const { customerOrdersState, retry } = useCustomerOrdersResource();
  const { handleRefresh } = usePullToRefresh(customerOrdersState, retry);

  const currentTab = useCurrentTab();
  const { navigate } = useContext(OurNavContext);
  const onOrderDetailsClick = React.useCallback(
    (customerOrder: CustomerOrder) => {
      actionEvent("My Order Detail", "Click", "Details");
      navigate(getPathForOrderDetail(currentTab, `${customerOrder.id}`), {
        incrementID: customerOrder.incrementID,
        date: customerOrder.createdAt,
      });
    },
    [navigate, currentTab]
  );
  const { history } = props;

  const viewState = useMemo<SimpleViewState<CustomerOrders>>(() => {
    const customerOrders = getResources(customerOrdersState);
    if (customerOrders != null) {
      return SimpleViewStateDisplay(customerOrders);
    }
    if (isRequestError(customerOrdersState)) {
      const error = getRequestStateError(customerOrdersState);
      return SimpleViewStateError(
        typeof error === "string" ? error : translate("error.unknown")
      );
    }
    return SimpleViewStateLoading;
  }, [customerOrdersState, translate]);

  const onGoShoppingClicked = useCallback(() => {
    history.replace(getPathForHomePage());
  }, [history]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<LocalizedText messageID="page.my_orders.title" />}
      />
      <CLContent ref={contentRef} className={styles.ionContent}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={
            viewState.type === "display" && viewState.data.items.length > 0
          }
          onRetry={retry}
        >
          {viewState.type === "loading" && <FullContentLoadingView />}
          {viewState.type === "display" && (
            // Only show pull to refresh when data is ready
            <>
              <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                <IonRefresherContent />
              </IonRefresher>
              <MyOrders
                customerOrders={viewState.data}
                onDetailsClick={onOrderDetailsClick}
                onGoShoppingClicked={onGoShoppingClicked}
              />
            </>
          )}
          {viewState.type === "error" && (
            <FullContentErrorView
              errorMessage={viewState.error}
              onClickRetry={retry}
            />
          )}
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

interface MyOrdersProps {
  customerOrders: CustomerOrders;
  onDetailsClick: (customerOrder: CustomerOrder) => void;
  onGoShoppingClicked: () => void;
}

const MyOrders: React.FC<MyOrdersProps> = props => {
  const { customerOrders, onDetailsClick, onGoShoppingClicked } = props;
  const reversedOrderItems = useMemo(
    () => customerOrders.items.slice().sort((a, b) => b.id - a.id),
    [customerOrders]
  );
  return (
    <>
      {reversedOrderItems.length === 0 ? (
        <EmptyPage onGoShoppingClicked={onGoShoppingClicked} />
      ) : (
        reversedOrderItems.map(item => (
          <OrderInfo
            key={item.id}
            customerOrder={item}
            onDetailsClick={onDetailsClick}
          />
        ))
      )}
    </>
  );
};

interface EmptyPageProps {
  onGoShoppingClicked: () => void;
}

const EmptyPage: React.FC<EmptyPageProps> = (props: EmptyPageProps) => {
  return (
    <div className={styles.emptyContainer}>
      <div className={styles.emptyIcon} />
      <div className={styles.emptyDescription}>
        <LocalizedText messageID="my_orders.no_orders_yet" />
      </div>
      <PrimaryButton
        className={styles.goShoppingButton}
        onClick={props.onGoShoppingClicked}
      >
        <LocalizedText messageID="my_orders.lets_go_shopping" />
      </PrimaryButton>
    </div>
  );
};

interface OrderInfoProps {
  customerOrder: CustomerOrder;
  onDetailsClick: (customerOrder: CustomerOrder) => void;
}

const OrderInfo: React.FC<OrderInfoProps> = props => {
  const { customerOrder, onDetailsClick } = props;

  const id = customerOrder.incrementID;
  const orderTotal = useMemo(
    () => ({ currency: "HKD", value: customerOrder.grandTotal }),
    [customerOrder]
  );
  const orderDate = useMemo(
    () =>
      moment
        .utc(customerOrder.createdAt)
        .utcOffset(8)
        .format("DD-MM-YYYY"),
    [customerOrder]
  );
  // TODO (Steven-Chan):
  // Wait for client update about clubpoint
  // const clubpointEarned = 777;
  const status = customerOrder.status;

  const onOrderDetailsClick = useCallback(() => {
    onDetailsClick(customerOrder);
  }, [onDetailsClick, customerOrder]);

  return (
    <div className={styles.orderInfoContainer}>
      <div className={styles.orderInfo}>
        <div className={styles.orderInfoColumn}>
          <OrderInfoItem titleID="my_orders.order_info.order_id">
            # {id}
          </OrderInfoItem>
          <OrderInfoItem titleID="my_orders.order_info.order_total">
            <div className={styles.orderTotal}>
              <div className={styles.orderTotalRow}>
                {customerOrder.clubpointsUsed ? (
                  <>
                    <div className={styles.orderInfoItemContentItem}>
                      <IonIcon
                        icon="club-point"
                        className={styles.clubpointsIcon}
                      />
                    </div>
                    <div className={styles.orderInfoItemContentItem}>
                      {customerOrder.clubpointsUsed}
                    </div>
                  </>
                ) : null}
                {customerOrder.clubpointsUsed && orderTotal.value ? (
                  <div className={styles.orderInfoItemContentItem}>+</div>
                ) : null}
                {orderTotal.value ? (
                  <div className={styles.orderInfoItemContentItem}>
                    {formatMoney(orderTotal)}
                  </div>
                ) : null}
              </div>
            </div>
          </OrderInfoItem>
        </div>
        <div className={styles.orderInfoColumn}>
          <OrderInfoItem titleID="my_orders.order_info.order_date">
            {orderDate}
          </OrderInfoItem>
          {/* <OrderInfoItem titleID="my_orders.order_info.club_point_earned">
            <div className={styles.clubpointsEarned}>
              <div className={styles.clubpointsIcon} />
              <div className={styles.orderInfoItemContentItem}>
                <LocalizedText
                  messageID="my_orders.order_info.club_point_earned_pts"
                  messageArgs={{ pts: clubpointEarned }}
                />
              </div>
            </div>
          </OrderInfoItem> */}
          <OrderInfoItem titleID="my_orders.status">
            <LocalizedText
              messageID={(() => {
                switch (status) {
                  case "canceled":
                    return "my_orders.status.canceled";
                  case "closed":
                    return "my_orders.status.closed";
                  case "complete":
                    return "my_orders.status.complete";
                  case "pending":
                    return "my_orders.status.pending";
                  case "pending_payment":
                    return "my_orders.status.pending_payment";
                  case "processing":
                    return "my_orders.status.processing";
                  case "pending_clubpoint":
                    return "my_orders.status.pending_clubpoint";
                  default:
                    return "my_orders.status";
                }
              })()}
            />
          </OrderInfoItem>
        </div>
      </div>
      {customerOrder.hasClubProtectionItem ? <ClubProtectBanner /> : null}
      <div
        className={styles.orderInfoDetailsButton}
        onClick={onOrderDetailsClick}
      >
        <LocalizedText messageID="my_orders.order_info_details" />
      </div>
    </div>
  );
};

interface OrderInfoItemProps {
  titleID: MessageID;
}

const OrderInfoItem: React.FC<OrderInfoItemProps> = props => {
  const { titleID } = props;
  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.orderInfoItemTitle}>
        <LocalizedText messageID={titleID} />
      </div>
      <div className={styles.orderInfoItemContent}>{props.children}</div>
    </div>
  );
};

export default MyOrdersPage;
