import { ApolloClient, gql } from "@apollo/client";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";
import { useContext, useCallback, useMemo } from "react";
import { RepositoryContext } from "../../repository/State";
import { useGraphQLFn } from "../../hook/graphql";

async function deleteCustomerAddress(
  client: ApolloClient<any>,
  locale: Locale,
  addressID: number
): Promise<boolean> {
  const result = await client.mutate<{
    deleteCustomerAddress: boolean;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation DeleteCustomerAddress($id: Int!) {
        deleteCustomerAddress(id: $id)
      }
    `,
    variables: {
      id: addressID,
    },
  });

  if (!result.data) {
    throw new Error();
  }

  return result.data.deleteCustomerAddress;
}

async function setCustomerAddressDefaultShipping(
  client: ApolloClient<any>,
  locale: Locale,
  addressID: number
): Promise<number> {
  const result = await client.mutate<{ updateCustomerAddress: { id: number } }>(
    {
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation SetCustomerAddressDefaultShipping($id: Int!) {
          updateCustomerAddress(id: $id, input: { default_shipping: true }) {
            id
          }
        }
      `,
      variables: {
        id: addressID,
      },
    }
  );

  if (!result.data) {
    throw new Error();
  }

  return result.data.updateCustomerAddress.id;
}

async function setCustomerAddressDefaultBilling(
  client: ApolloClient<any>,
  locale: Locale,
  addressID: number
): Promise<number> {
  const result = await client.mutate<{ updateCustomerAddress: { id: number } }>(
    {
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      mutation: gql`
        mutation SetCustomerAddressDefaultBilling($id: Int!) {
          updateCustomerAddress(id: $id, input: { default_billing: true }) {
            id
          }
        }
      `,
      variables: {
        id: addressID,
      },
    }
  );

  if (!result.data) {
    throw new Error();
  }

  return result.data.updateCustomerAddress.id;
}

export function useAddressActions() {
  const { dispatch } = useContext(RepositoryContext);

  const deleteCustomerAddress_ = useGraphQLFn(deleteCustomerAddress);
  const deleteAddress = useCallback(
    async (addressID: number) => {
      const result = await deleteCustomerAddress_(addressID);
      dispatch({
        type: "RemoveCustomerAddress",
        result,
        addressID,
      });
      return result;
    },
    [dispatch, deleteCustomerAddress_]
  );

  const setCustomerAddressDefaultShipping_ = useGraphQLFn(
    setCustomerAddressDefaultShipping
  );
  const setDefaultShipping = useCallback(
    async (addressID: number) => {
      const result = await setCustomerAddressDefaultShipping_(addressID);
      dispatch({
        type: "SetCustomerAddressDefaultBilling",
        addressID,
      });
      return result;
    },
    [dispatch, setCustomerAddressDefaultShipping_]
  );

  const setCustomerAddressDefaultBilling_ = useGraphQLFn(
    setCustomerAddressDefaultBilling
  );
  const setDefaultBilling = useCallback(
    async (addressID: number) => {
      const result = await setCustomerAddressDefaultBilling_(addressID);
      dispatch({
        type: "SetCustomerAddressDefaultBilling",
        addressID,
      });
      return result;
    },
    [dispatch, setCustomerAddressDefaultBilling_]
  );

  return useMemo(
    () => ({
      deleteAddress,
      setDefaultShipping,
      setDefaultBilling,
    }),
    [deleteAddress, setDefaultShipping, setDefaultBilling]
  );
}
