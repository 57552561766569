import React, { useCallback, useMemo, useState } from "react";
import produce from "immer";

import {
  Country,
  CountryCode,
  CountryID,
  CountryRegions,
  District,
  DistrictID,
  DistrictName,
  Region,
  RegionCode,
  RegionID,
} from "../models/CountryRegion";
import { IndexMap } from "../utils/type";

interface ContextState {
  countries: Country[];
  countryByID: IndexMap<CountryID, Country>;
  countryByCountryCode: IndexMap<CountryCode, Country>;
  regionsByCountryID: IndexMap<CountryID, Region[]>;
  regionByID: IndexMap<RegionID, Region>;
  regionByRegionCode: IndexMap<RegionCode, Region>;
  districts: District[];
  districtsByRegionID: IndexMap<RegionID, District[]>;
  districtByID: IndexMap<DistrictID, District>;
  districtByDistrictName: IndexMap<DistrictName, District>;
}

type Context = ContextState & {
  updateCountryRegions: (countryRegions: CountryRegions) => void;
  updateDistricts: (districts: District[]) => void;
};

const initialContextStateValue: ContextState = {
  countries: [],
  countryByID: {},
  countryByCountryCode: {},
  regionsByCountryID: {},
  regionByID: {},
  regionByRegionCode: {},
  districts: [],
  districtsByRegionID: {},
  districtByID: {},
  districtByDistrictName: {},
};

const initialContextValue: Context = {
  ...initialContextStateValue,
  updateCountryRegions: () => {},
  updateDistricts: () => {},
};

const CountryRegionDistrictContext = React.createContext<Context>(
  initialContextValue
);

export const CountryRegionDistrictContextProvider: React.FC = props => {
  const [state, setState] = useState<ContextState>(initialContextStateValue);

  const updateCountryRegions = useCallback((countryRegions: CountryRegions) => {
    setState(prevState => {
      return produce(prevState, draft => {
        const { countries, regionsByCountryID } = countryRegions;
        draft.countries = countries;
        draft.regionsByCountryID = regionsByCountryID;

        draft.countryByID = {};
        draft.regionByID = {};

        draft.countryByCountryCode = {};
        draft.regionByRegionCode = {};

        for (const country of countries) {
          const { id, code } = country;
          draft.countryByID[id] = country;
          draft.countryByCountryCode[code] = country;

          const regions = regionsByCountryID[id];
          if (regions) {
            for (const region of regions) {
              const { id: rid, code: rcode } = region;
              draft.regionByID[rid] = region;
              draft.regionByRegionCode[rcode] = region;
            }
          }
        }
      });
    });
  }, []);

  const updateDistricts = useCallback((districts: District[]) => {
    setState(prevState => {
      return produce(prevState, draft => {
        draft.districts = districts;

        draft.districtsByRegionID = {};
        draft.districtByID = {};
        draft.districtByDistrictName = {};

        for (const district of districts) {
          const { regionId, id, name } = district;
          if (!draft.districtsByRegionID[regionId]) {
            draft.districtsByRegionID[regionId] = [];
          }
          const mDistricts = draft.districtsByRegionID[regionId];
          if (mDistricts) {
            mDistricts.push(district);
          }

          draft.districtByID[id] = district;
          draft.districtByDistrictName[name] = district;
        }
      });
    });
  }, []);

  const contextValue = useMemo(
    () => ({ ...state, updateCountryRegions, updateDistricts }),
    [state, updateCountryRegions, updateDistricts]
  );

  return (
    <CountryRegionDistrictContext.Provider value={contextValue}>
      {props.children}
    </CountryRegionDistrictContext.Provider>
  );
};

export default CountryRegionDistrictContext;
