import { useApolloClient } from "@apollo/react-hooks";

import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { OppCard } from "../models/OppCard";
import { useIntl } from "../i18n/Localization";
import { getOppCards, deleteOppCard } from "../api/GraphQL";

import { useFetchResources_v2 } from "./Hooks";

export function useGetOppCards(): [
  ResourcesRequestState<OppCard[]>,
  () => Promise<OppCard[]>,
  () => Promise<OppCard[]>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    OppCard[],
    () => Promise<OppCard[]>
  >({
    localCacheProvider: () => getOppCards(client, locale, "cache-only"),
    remoteResourcesProvider: () => getOppCards(client, locale, "network-only"),
  });
  return [requestState, fetch, refresh];
}

export function useDeleteOppCard(): [
  ResourcesRequestState<boolean>,
  (cardId: string) => Promise<boolean>
] {
  const client = useApolloClient();
  const [requestState, { call }] = useFetchResources_v2<
    boolean,
    (cardId: string) => Promise<boolean>
  >({
    remoteResourcesProvider: (cardId: string) => deleteOppCard(client, cardId),
  });
  return [requestState, call];
}
