import React from "react";

import styles from "./styles.module.scss";
import { ProductData, getProductName } from "./type";

interface Props {
  productData: ProductData;
}

const ProductNameLabel: React.FC<Props> = React.memo(props => {
  const { productData } = props;
  const productName = getProductName(productData);
  return <h1 className={styles.productNameText}>{productName}</h1>;
});

export default ProductNameLabel;
