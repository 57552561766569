import { ApolloClient, FetchPolicy, useApolloClient } from "@apollo/client";
import { useContext } from "react";
import {
  fetchAggregation,
  fetchSortFields,
  searchProductOverviews,
} from "../../api/GraphQL";
import { fetchProductLabelsByProductIds } from "../../api/ProductLabel";
import { fetchProductSaleBundlesForProductSKUOnly } from "../../api/ProductSaleBundle";
import { Locale } from "../../i18n/locale";
import { useIntl } from "../../i18n/Localization";
import {
  FilterAttribute,
  FilterInputField,
  getFilterAttributeMapsFromFilters,
  makeGraphQLFilterFromParamsMap,
  ProductFilterInfo,
  RangeFilterAttribute,
  SortFieldOption,
} from "../../models/filter";
import { ProductLabel } from "../../models/ProductLabel";
import { ProductOverview } from "../../models/ProductOverview";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { SearchTerm } from "../../models/Search";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { ModelKeys } from "../../models/product";
import { useFetchResources_v2 } from "../../repository/Hooks";
import ProductAttributeFilterContext from "../../contexts/ProductAttributeFilterContext";
import { convertAggregationToFilter } from "../../utils/Filter";
import { profileAsyncAction } from "../../utils/performance";
import { SearchPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { IndexMap } from "../../utils/type";
import Config from "../../Config";

interface SearchPageProductListResources {
  productOverviews: ProductOverview[];
  hasMore: boolean;
  currentPage: number;
  pageSize: number;

  productIdLabelMap: {
    [key in number]: ProductLabel[];
  };
  productIdBundleMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
}

export function useSearchPageProductListResource(
  searchTerm: SearchTerm
): [
  ResourcesRequestState<SearchPageProductListResources | null>,
  (
    page: number,
    productFilterInfo: ProductFilterInfo
  ) => Promise<SearchPageProductListResources | null>,
  (
    page: number,
    productFilterInfo: ProductFilterInfo
  ) => Promise<SearchPageProductListResources | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { productAttributeFilterInputMap } = useContext(
    ProductAttributeFilterContext
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    SearchPageProductListResources | null,
    (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => Promise<SearchPageProductListResources | null>
  >({
    localCacheProvider: async (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => {
      const result = await profileAsyncAction(
        SearchPageSession(searchTerm),
        "Load product list from cache",
        () =>
          searchProductOverviews(
            client,
            searchTerm,
            productFilterInfo,
            productAttributeFilterInputMap,
            page,
            locale,
            "cache-only"
          )
      );
      if (!result) {
        return null;
      }
      const productIdLabelMap: { [key in number]: ProductLabel[] } = {};
      const productIdBundleMap: {
        [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
      } = {};
      if (result.productOverviews.length > 0) {
        const productIds = result.productOverviews.map(
          productOverview => productOverview.id
        );
        const [productLabelsByProductIds, bundles] = await Promise.all([
          await profileAsyncAction(
            SearchPageSession(searchTerm),
            "Load product labels from cache",
            () =>
              fetchProductLabelsByProductIds(
                client,
                productIds,
                "category",
                locale,
                "cache-only"
              ).catch(() => [])
          ),
          Config.ENABLE_BUNDLE_SALE &&
          Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
            ? fetchProductSaleBundlesForProductSKUOnly(
                client,
                productIds,
                locale,
                "cache-only"
              ).catch(() => [])
            : Promise.resolve([]),
        ]);
        for (const productLabelsByProductId of productLabelsByProductIds) {
          productIdLabelMap[productLabelsByProductId.productId] =
            productLabelsByProductId.productLabels;
        }

        for (const bundle of bundles) {
          if (bundle.mainProduct) {
            productIdBundleMap[bundle.mainProduct.id] = bundle;
          }
        }
      }
      return {
        ...result,
        currentPage: page,
        productIdLabelMap,
        productIdBundleMap,
      };
    },
    remoteResourcesProvider: async (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => {
      const result = await profileAsyncAction(
        SearchPageSession(searchTerm),
        "Load product list from network",
        () =>
          searchProductOverviews(
            client,
            searchTerm,
            productFilterInfo,
            productAttributeFilterInputMap,
            page,
            locale,
            "network-only"
          )
      );
      if (!result) {
        return null;
      }
      const productIdLabelMap: { [key in number]: ProductLabel[] } = {};
      const productIdBundleMap: {
        [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
      } = {};
      if (result.productOverviews.length > 0) {
        const productIds = result.productOverviews.map(
          productOverview => productOverview.id
        );
        const [productLabelsByProductIds, bundles] = await Promise.all([
          profileAsyncAction(
            SearchPageSession(searchTerm),
            "Load product label from network",
            () =>
              fetchProductLabelsByProductIds(
                client,
                productIds,
                "category",
                locale,
                "network-only"
              ).catch(() => [])
          ),
          Config.ENABLE_BUNDLE_SALE &&
          Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
            ? fetchProductSaleBundlesForProductSKUOnly(
                client,
                productIds,
                locale,
                "network-only"
              ).catch(() => [])
            : Promise.resolve([]),
        ]);
        for (const productLabelsByProductId of productLabelsByProductIds) {
          productIdLabelMap[productLabelsByProductId.productId] =
            productLabelsByProductId.productLabels;
        }

        for (const bundle of bundles) {
          if (bundle.mainProduct) {
            productIdBundleMap[bundle.mainProduct.id] = bundle;
          }
        }
      }
      return {
        ...result,
        currentPage: page,
        productIdLabelMap,
        productIdBundleMap,
      };
    },
  });

  return [requestState, fetch, refresh];
}

export interface SearchPageResource {
  singleLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute;
  };
  multiLevelLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute[];
  };
  rangeFilterAttributeMap: {
    [attributeCode in string]: RangeFilterAttribute;
  };
  sortFields: {
    defaultSortField: string | null;
    sortFieldOptions: SortFieldOption[];
  } | null;
}

async function fetchSearchPageResources(
  client: ApolloClient<any>,
  locale: Locale,
  productAttributeFilterFields: string[],
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  search: string,
  queryParams: { [key in string]: string },
  fetchPolicy: FetchPolicy
): Promise<SearchPageResource> {
  const queryFilter = makeGraphQLFilterFromParamsMap(
    queryParams,
    productAttributeFilterFields,
    productAttributeFilterInputMap
  );
  const filter = { ...queryFilter };
  const aggregations = await profileAsyncAction(
    SearchPageSession(search),
    `Load filters from ${fetchPolicy === "network-only" ? "network" : "cache"}`,
    () => fetchAggregation(client, search, filter, locale, fetchPolicy)
  );
  const filterTypes = aggregations
    ? aggregations.map(convertAggregationToFilter)
    : [];
  const {
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
  } = getFilterAttributeMapsFromFilters(
    filterTypes,
    queryParams,
    productAttributeFilterInputMap
  );

  const sortFields = await profileAsyncAction(
    SearchPageSession(search),
    `Load sort fields from ${
      fetchPolicy === "network-only" ? "network" : "cache"
    }`,
    () => fetchSortFields(client, search, filter, locale, fetchPolicy)
  );

  return {
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
    sortFields,
  };
}

export function useSearchPageResource(
  search: SearchTerm,
  queryParams: { [key in string]: string }
): [
  ResourcesRequestState<SearchPageResource>,
  () => Promise<SearchPageResource>,
  () => Promise<SearchPageResource>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const {
    productAttributeFilterFields,
    productAttributeFilterInputMap,
  } = useContext(ProductAttributeFilterContext);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    SearchPageResource,
    () => Promise<SearchPageResource>
  >({
    localCacheProvider: async () =>
      fetchSearchPageResources(
        client,
        locale,
        productAttributeFilterFields,
        productAttributeFilterInputMap,
        search,
        queryParams,
        "cache-only"
      ),
    remoteResourcesProvider: async () =>
      fetchSearchPageResources(
        client,
        locale,
        productAttributeFilterFields,
        productAttributeFilterInputMap,
        search,
        queryParams,
        "network-only"
      ),
  });

  return [requestState, fetch, refresh];
}
