import React, { useRef, useMemo } from "react";
import cn from "classnames";

import { LocalizedText } from "../../i18n/Localization";

import { ProductSpec } from "../../models/product";
import { ConfiguredProduct } from "../../models/ProductDetails";

import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";

import parse from "../../utils/HTMLReactParser";

import styles from "./styles.module.scss";

interface Props {
  configuredProduct: ConfiguredProduct;
}

const ProductSpecification: React.FC<Props> = props => {
  const { configuredProduct } = props;

  if (configuredProduct.specs.length === 0) {
    return null;
  }

  return (
    <div className={styles.productSpecsContainer}>
      <h2 className={styles.productSpecsTitle}>
        <LocalizedText messageID="page.product_detail.specification.title" />
      </h2>
      <div className={styles.productSpecsTable}>
        {configuredProduct.specs.map(spec => (
          <ProductSpecificationRow spec={spec} key={spec.label} />
        ))}
      </div>
    </div>
  );
};

interface ProductSpecificationRowProps {
  spec: ProductSpec;
}

const ProductSpecificationRow: React.FC<
  ProductSpecificationRowProps
> = props => {
  const { spec } = props;
  const { label, value } = spec;

  const containerRef = useRef<HTMLDivElement>(null);

  const html = useExtractScriptsAndStyles(containerRef, value);

  const specReactElement = useMemo(() => parse(html), [html]);

  return (
    <div className={styles.productSpecsTableRow} ref={containerRef}>
      <div className={cn(styles.productSpecsTableRowField, styles.specTitle)}>
        {label}
      </div>
      <div className={cn(styles.productSpecsTableRowField, styles.specValue)}>
        {specReactElement}
      </div>
    </div>
  );
};

export default ProductSpecification;
