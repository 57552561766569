import produce from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { Locale } from "../i18n/locale";
import {
  CategoryTree,
  flattenCategoryTree,
  RemoteCategoryTree,
} from "../models/category";
import { IndexMap } from "../utils/type";

interface ContextState {
  categoryTreeMap: { [key in Locale]: IndexMap<string, CategoryTree> };
}

const initialContextState: ContextState = {
  categoryTreeMap: {
    [Locale.en]: {},
    [Locale.zhHant]: {},
  },
};

interface ContextAction {
  updateCategoryTree: (
    remoteCategoryTree: RemoteCategoryTree,
    locale: Locale
  ) => void;
  updateCategoryDescription: (
    id: number,
    description: string | null,
    locale: Locale
  ) => void;
}

const initialContextAction: ContextAction = {
  updateCategoryTree: () => {},
  updateCategoryDescription: () => {},
};

type Context = ContextState & ContextAction;

const initialContextValue: Context = {
  ...initialContextState,
  ...initialContextAction,
};

const CategoryContext = React.createContext<Context>(initialContextValue);

export default CategoryContext;

export const CategoryContextProvider: React.FC = props => {
  const [state, setState] = useState<ContextState>(initialContextState);

  const updateCategoryTree = useCallback(
    (remoteCategoryTree: RemoteCategoryTree, locale: Locale) => {
      setState(prevState =>
        produce(prevState, draft => {
          const categoryTrees = flattenCategoryTree(remoteCategoryTree);
          for (const categoryTree of categoryTrees) {
            draft.categoryTreeMap[locale][categoryTree.id] = categoryTree;
          }
        })
      );
    },
    []
  );

  const updateCategoryDescription = useCallback(
    (id: number, description: string | null, locale: Locale) => {
      setState(prevState =>
        produce(prevState, draft => {
          const categoryTree = draft.categoryTreeMap[locale][id];
          if (categoryTree) {
            categoryTree.description = description;
          }
        })
      );
    },
    []
  );

  const contextValue = useMemo(
    () => ({ ...state, updateCategoryTree, updateCategoryDescription }),
    [state, updateCategoryTree, updateCategoryDescription]
  );

  return (
    <CategoryContext.Provider value={contextValue}>
      {props.children}
    </CategoryContext.Provider>
  );
};
