import { IonButton, IonIcon } from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Config from "../../Config";
import { useModalState } from "../../hook/modal";
import { useIntl } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";
import { Customer, isCustomerInterestSet } from "../../models/Customer";
import {
  getPathForForgotPassword,
  getPathForSelectInterestCategoryPage,
  RootTab,
} from "../../navigation/routes";
import { PresentationContext } from "../../our-navigation";
import {
  useGetMyCustomer,
  useLogin,
  useLoginWithEmailRequest,
  useLoginWithOAuthRequest,
} from "../../repository/AuthRepository";
import { setLoginSignupMethod } from "../../storage";
import { throwIfNothing } from "../../types/Maybe";
import {
  SSOResponse,
  useGetTheClubSSOConfirmationStatus,
  useLoginWithFacebook,
  useLoginWithGoogle,
  useLoginWithTheClub,
  useTheClubSignup,
} from "../../useCase/AuthUseCase";
import {
  actionEvent,
  clubShoppingLoginClickEvent,
  singleSignOnFailEvent,
  theClubLoginClickEvent,
} from "../../utils/GTM";
import { isEmpty } from "../../utils/String";
import CLContent from "../CLContent";
import CLModal from "../CLModal";
import { LoadingModalContext } from "../LoadingModalProvider";
import {
  LocalizedAlertButton,
  LocalizedAlertContext,
} from "../LocalizedAlertProvider";
import SocialSignupTermsAndConditionsModal from "../SocialSignupTermsAndConditionsModal";
import TheClubSSOClubShoppingSignInView from "../TheClubSSOClubShoppingSignInView";
import TheClubSSOConfirmationFlow, {
  TheClubSSOCOnfirmationStatusResult,
} from "../TheClubSSOConfirmationModals/TheClubSSOConfirmationFlow";
import TheClubSSOWelcomeView from "../TheClubSSOWelcomeView";
import styles from "./styles.module.scss";

const enableTheClubSSOMVP1ConfirmationDialogs =
  Config.ENABLE_THE_CLUB_SSO_MVP1 &&
  Config.ENABLE_THE_CLUB_SSO_MVP1.THE_CLUB_SSO_CONFIRMATION_DIALOGS;

interface Props {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
  onDidLogin: ((customer: Customer) => void) | null;
}

const TheClubPreferredLoginModal: React.FC<Props> = props => {
  const { isModalOpen, onRequestDismiss, onDidLogin } = props;

  const history = useHistory();
  const { startRequesting: getMyCustomer } = useGetMyCustomer();
  const {
    presentLocalizedAlert,
    presentLocalizedAlertOnAsyncError,
  } = useContext(LocalizedAlertContext);
  const { present } = useContext(PresentationContext);
  const { withLoadingModalAsync } = useContext(LoadingModalContext);
  const { translate } = useIntl();

  const [page, setPage] = useState<"welcome" | "signin">("welcome");
  const [ssoLoginResult, setSSOLoginResult] = useState<SSOResponse | undefined>(
    undefined
  );
  const [
    socialSignupTermsModalIsOpen,
    setSocialSignupTermsModalIsOpen,
  ] = useState(false);

  const methodRef = useRef<LoginSignUpMethod | null>(null);
  const [
    theClubSSOConfirmationModalShown,
    showTheClubSSOConfirmationModal,
    hideTheClubSSOConfirmationModal,
  ] = useModalState();
  const [
    theClubSSOConfirmationStatusResult,
    setTheClubSSOConfirmationStatusResult,
  ] = useState<TheClubSSOCOnfirmationStatusResult | null>(null);

  useEffect(() => {
    if (isModalOpen) {
      setPage("welcome");
    }
  }, [isModalOpen]);

  const onSignInWithClubShoppingButtonClick = useCallback(() => {
    setPage("signin");
    clubShoppingLoginClickEvent();
  }, []);

  const onBackFromSignIn = useCallback(() => {
    setPage("welcome");
  }, []);

  const onSocialSignupTermsModalRequestDismiss = useCallback(() => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
  }, [setSocialSignupTermsModalIsOpen]);

  const presentLoginFailMessageAlert = useCallback(
    (message: string) => {
      presentLocalizedAlert({
        headerId: "login.login_failed",
        message,
        buttons: [{ textMessageID: "try_again" }],
      });
    },
    [presentLocalizedAlert]
  );

  const presentLoginFailAlert = useCallback(
    (errorMessageId: MessageID) => {
      presentLoginFailMessageAlert(translate(errorMessageId));
    },
    [presentLoginFailMessageAlert, translate]
  );

  const getTheClubSSOConfirmationStatus = useGetTheClubSSOConfirmationStatus();

  const isHandlingSSOConfirmation = useCallback(
    async (customer: Customer) => {
      const method = methodRef.current;
      if (!method) {
        return false;
      }
      const theClubSSOConfirmationStatus = await getTheClubSSOConfirmationStatus(
        customer,
        method
      );
      if (theClubSSOConfirmationStatus.type !== "NoActionRequired") {
        showTheClubSSOConfirmationModal();
        setTheClubSSOConfirmationStatusResult(theClubSSOConfirmationStatus);
        return true;
      }
      return false;
    },
    [getTheClubSSOConfirmationStatus, showTheClubSSOConfirmationModal]
  );

  const handleSocialSignupTermsModalSuccess = useCallback(async () => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
    const customer = await withLoadingModalAsync(getMyCustomer);
    if (
      !customer ||
      !(await withLoadingModalAsync(() => isHandlingSSOConfirmation(customer)))
    ) {
      onRequestDismiss();
    }
  }, [
    withLoadingModalAsync,
    getMyCustomer,
    isHandlingSSOConfirmation,
    onRequestDismiss,
  ]);

  const handleLoginSuccess = useCallback(
    (customer: Customer) => {
      if (
        !enableTheClubSSOMVP1ConfirmationDialogs &&
        !isCustomerInterestSet(customer)
      ) {
        history.replace(getPathForSelectInterestCategoryPage(RootTab.home));
      }
      onRequestDismiss();
      if (onDidLogin) {
        onDidLogin(customer);
      }
    },
    [onRequestDismiss, history, onDidLogin]
  );

  const login = useLogin();

  const loginWithOAuth = useLoginWithOAuthRequest();
  const handleSSO = useCallback(
    async (loginProvider: () => Promise<SSOResponse>, allowSignup: boolean) => {
      const method = methodRef.current;
      try {
        const result = await loginProvider();
        setSSOLoginResult(result);
        return await login(() => loginWithOAuth(result.token, result.provider));
      } catch (e) {
        if (!(e instanceof Error) || isEmpty(e.message)) {
          presentLoginFailAlert("login.login_with_oauth_failed_text");
          return;
        }
        if (e.message === "cancelled") {
          return null;
        }
        if (e.message === "Customer not exist.") {
          if (allowSignup) {
            setSocialSignupTermsModalIsOpen(true);
          } else {
            if (method) {
              singleSignOnFailEvent(method, "acct_inexist");
            }
            presentLoginFailAlert(
              "the_club_sso.club_shopping_sign_in.oauth_signup_failed"
            );
          }
          return null;
        }
        if (method) {
          singleSignOnFailEvent(method, "invalid_login");
        }
        presentLoginFailMessageAlert(e.message);
        return null;
      }
    },
    [login, presentLoginFailAlert, loginWithOAuth, presentLoginFailMessageAlert]
  );

  const loginWithEmail = useLoginWithEmailRequest();

  const loginWithTheClub = useLoginWithTheClub();

  const handleTheClubLogin = useCallback(() => {
    methodRef.current = "The Club";
    setLoginSignupMethod("The Club");
    presentLocalizedAlertOnAsyncError(async () => {
      const customer = await withLoadingModalAsync(() =>
        handleSSO(loginWithTheClub, true)
      );
      if (
        customer &&
        !(await withLoadingModalAsync(() =>
          isHandlingSSOConfirmation(customer)
        ))
      ) {
        handleLoginSuccess(customer);
      }
    });
  }, [
    handleSSO,
    loginWithTheClub,
    isHandlingSSOConfirmation,
    handleLoginSuccess,
    presentLocalizedAlertOnAsyncError,
    withLoadingModalAsync,
  ]);

  const onTheClubButtonClick = useCallback(async () => {
    theClubLoginClickEvent();
    handleTheClubLogin();
  }, [handleTheClubLogin]);

  const presentLoginFailAlertWithTheClubRetry = useCallback(
    (errorMessageId: MessageID) => {
      const buttons: LocalizedAlertButton[] = [];
      if (enableTheClubSSOMVP1ConfirmationDialogs) {
        buttons.push({
          textMessageID: "login.login_failed.login_with_the_club_account",
          handler: () => {
            handleTheClubLogin();
          },
        });
      }
      buttons.push({ textMessageID: "try_again" });
      presentLocalizedAlert({
        headerId: "login.login_failed",
        messageId: errorMessageId,
        buttons,
      });
    },
    [presentLocalizedAlert, handleTheClubLogin]
  );

  const loginWithFacebook = useLoginWithFacebook();
  const handleFacebookLoginClick = useCallback(() => {
    methodRef.current = "Facebook";
    setLoginSignupMethod("Facebook");
    presentLocalizedAlertOnAsyncError(async () => {
      const customer = await withLoadingModalAsync(() =>
        handleSSO(loginWithFacebook, false)
      );
      if (
        customer &&
        !(await withLoadingModalAsync(() =>
          isHandlingSSOConfirmation(customer)
        ))
      ) {
        handleLoginSuccess(customer);
      }
    });
  }, [
    handleSSO,
    loginWithFacebook,
    isHandlingSSOConfirmation,
    handleLoginSuccess,
    presentLocalizedAlertOnAsyncError,
    withLoadingModalAsync,
  ]);

  const loginWithGoogle = useLoginWithGoogle();
  const handleGoogleLoginClick = useCallback(() => {
    methodRef.current = "Google";
    setLoginSignupMethod("Google");
    presentLocalizedAlertOnAsyncError(async () => {
      const customer = await withLoadingModalAsync(() =>
        handleSSO(loginWithGoogle, false)
      );
      if (
        customer &&
        !(await withLoadingModalAsync(() =>
          isHandlingSSOConfirmation(customer)
        ))
      ) {
        handleLoginSuccess(customer);
      }
    });
  }, [
    handleSSO,
    loginWithGoogle,
    isHandlingSSOConfirmation,
    handleLoginSuccess,
    presentLocalizedAlertOnAsyncError,
    withLoadingModalAsync,
  ]);

  const handleForgotPasswordClick = useCallback(() => {
    onRequestDismiss();
    present(getPathForForgotPassword());
  }, [present, onRequestDismiss]);
  const handleLoginFormSubmit = useCallback(
    async (email: string, password: string) => {
      methodRef.current = "Email";
      setLoginSignupMethod("Email");
      actionEvent("Login", "Click", "Submit-Btn");
      try {
        const customer = throwIfNothing(
          CANNOT_GET_CUSTOMER_ERROR,
          await withLoadingModalAsync(() =>
            login(() => loginWithEmail(email, password))
          )
        );
        if (
          !(await withLoadingModalAsync(() =>
            isHandlingSSOConfirmation(customer)
          ))
        ) {
          handleLoginSuccess(customer);
          actionEvent("Login", "Click", "Goal");
        }
      } catch {
        presentLoginFailAlertWithTheClubRetry("login.login_failed_text");
        singleSignOnFailEvent("Email", "invalid_login");
      }
    },
    [
      login,
      loginWithEmail,
      presentLoginFailAlertWithTheClubRetry,
      isHandlingSSOConfirmation,
      handleLoginSuccess,
      withLoadingModalAsync,
    ]
  );
  const showTheClubSignup = useTheClubSignup();
  const handleCreateAccountButtonClick = useCallback(() => {
    showTheClubSignup();
  }, [showTheClubSignup]);

  return (
    <>
      <CLModal
        className={styles.modal}
        isOpen={isModalOpen}
        onRequestDismiss={onRequestDismiss}
        backdropDismiss={true}
      >
        <CLContent className={styles.content}>
          <IonButton className={styles.closeButton} onClick={onRequestDismiss}>
            <IonIcon name="close" mode="md" className={styles.closeIcon} />
          </IonButton>
          <div className={styles.body}>
            {page === "welcome" ? (
              <div className={styles.page} key="welcome">
                <TheClubSSOWelcomeView
                  isLoading={false}
                  onTheClubButtonClick={onTheClubButtonClick}
                  onSignInWithClubShoppingButtonClick={
                    onSignInWithClubShoppingButtonClick
                  }
                />
              </div>
            ) : page === "signin" ? (
              <div className={styles.page} key="signin">
                <TheClubSSOClubShoppingSignInView
                  isLoading={false}
                  onBack={onBackFromSignIn}
                  onFacebookLoginClick={handleFacebookLoginClick}
                  onGoogleLoginClick={handleGoogleLoginClick}
                  onForgotPasswordClick={handleForgotPasswordClick}
                  onLoginFormSubmit={handleLoginFormSubmit}
                  onCreateAccountButtonClick={handleCreateAccountButtonClick}
                />
              </div>
            ) : null}
          </div>
        </CLContent>
      </CLModal>
      <SocialSignupTermsAndConditionsModal
        isModalOpen={socialSignupTermsModalIsOpen}
        onRequestDismiss={onSocialSignupTermsModalRequestDismiss}
        onSuccess={handleSocialSignupTermsModalSuccess}
        provider={ssoLoginResult ? ssoLoginResult.provider : undefined}
        token={ssoLoginResult ? ssoLoginResult.token : undefined}
      />
      <TheClubSSOConfirmationFlow
        theClubSSOConfirmationModalShown={theClubSSOConfirmationModalShown}
        showTheClubSSOConfirmationModal={showTheClubSSOConfirmationModal}
        hideTheClubSSOConfirmationModal={hideTheClubSSOConfirmationModal}
        theClubSSOConfirmationStatusResult={theClubSSOConfirmationStatusResult}
        setTheClubSSOConfirmationStatusResult={
          setTheClubSSOConfirmationStatusResult
        }
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

export default TheClubPreferredLoginModal;

const CANNOT_GET_CUSTOMER_ERROR = new Error("cannot-get-customer");
