import React, { useCallback, useContext, useState } from "react";
import { IonButton } from "@ionic/react";

import CLContent from "../CLContent";
import { LocalizedText } from "../../i18n/Localization";
import { NavBar, NavBarBackButton } from "../NavBar";
import PasswordInputField from "../PasswordInputField";

import { withProviders } from "../Provider";
import LoadingModalProvider, {
  LoadingModalContext,
} from "../LoadingModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import { useChangePassword } from "../../repository/AuthRepository";

import { OurNavContext, PresentationContext } from "../../our-navigation";
import { getPathForForgotPassword } from "../../navigation/routes";

import { validatePassword } from "../../utils/validation";

import styles from "./styles.module.scss";

const ChangePasswordPage: React.FC = () => {
  const { goBack } = useContext(OurNavContext);
  const { isOnline } = useContext(NetworkStatusContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean | null>(
    null
  );
  const [isReEnterPasswordValid, setIsReEnterPasswordValid] = useState<
    boolean | null
  >(null);

  const { show: showLoadingModal, hide: hideLoadingModal } = useContext(
    LoadingModalContext
  );
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const changePassword = useChangePassword();
  const onClickSubmitButton = useCallback(async () => {
    const _isNewPasswordValid = validatePassword(newPassword);
    const _isReEnterPasswordValid = newPassword === reEnterNewPassword;
    setIsNewPasswordValid(_isNewPasswordValid);
    setIsReEnterPasswordValid(_isReEnterPasswordValid);
    if (!_isNewPasswordValid || !_isReEnterPasswordValid) {
      return;
    }
    showLoadingModal();
    try {
      await changePassword(currentPassword, newPassword);
      presentLocalizedAlert({
        headerId: "page.change_password.change_password_success",
        buttons: [
          {
            textMessageID: "alert.button.ok",
            handler: () => goBack(),
          },
        ],
      });
    } catch (e) {
      const messageId =
        e.message === "invalid-current-password"
          ? "page.change_password.current_password.invalid"
          : undefined;
      presentLocalizedAlert({
        headerId: "page.change_password.error.fail_change_password",
        messageId,
        buttons: [
          {
            textMessageID: "alert.button.ok",
          },
        ],
      });
    } finally {
      hideLoadingModal();
    }
  }, [
    currentPassword,
    newPassword,
    reEnterNewPassword,
    setIsNewPasswordValid,
    setIsReEnterPasswordValid,
    presentLocalizedAlert,
    showLoadingModal,
    hideLoadingModal,
    goBack,
    changePassword,
  ]);

  const { present } = useContext(PresentationContext);
  const onClickForgotPassword = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      present(getPathForForgotPassword());
    },
    [present]
  );

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={
          <LocalizedText messageID="page.change_password.change_password" />
        }
        headerRight={
          <IonButton
            className={styles.doneButton}
            disabled={
              currentPassword === "" ||
              newPassword === "" ||
              reEnterNewPassword === ""
            }
            onClick={onClickSubmitButton}
          >
            <LocalizedText messageID="page.change_password.save" />
          </IonButton>
        }
      />
      <CLContent className={styles.ionContent}>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          <div className={styles.content}>
            <PasswordInputField
              className={styles.input}
              placeholderID="page.change_password.current_password"
              value={currentPassword}
              onChange={setCurrentPassword}
            />
            <PasswordInputField
              className={styles.input}
              placeholderID="page.change_password.new_password"
              value={newPassword}
              errorMessageID={
                isNewPasswordValid === false
                  ? "page.change_password.new_password.invalid"
                  : undefined
              }
              onChange={setNewPassword}
            />
            <div className={styles.passwordRequirementText}>
              <LocalizedText messageID="password.requirement" />
            </div>
            <PasswordInputField
              className={styles.input}
              placeholderID="page.change_password.re_enter_password"
              value={reEnterNewPassword}
              errorMessageID={
                isReEnterPasswordValid === false
                  ? "page.change_password.re_enter_password.invalid"
                  : undefined
              }
              onChange={setReEnterNewPassword}
            />
            <div
              className={styles.forgotPassword}
              onClick={onClickForgotPassword}
            >
              <LocalizedText messageID="page.change_password.forgot_password" />
            </div>
          </div>
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default withProviders(ChangePasswordPage, LoadingModalProvider);
