import { Plugins } from "@capacitor/core";

export enum Permission {
  IOS_LOCATION_ALWAYS = "LOCATION_ALWAYS",
  IOS_LOCATION_WHEN_IN_USE = "LOCATION_WHEN_IN_USE",

  ANDROID_ACCESS_FINE_LOCATION = "ACCESS_FINE_LOCATION",

  CAMERA = "CAMERA",
  PHOTO_LIBRARY = "PHOTO_LIBRARY",
}

export type PermissionResult = "unavailable" | "denied" | "blocked" | "granted";

export async function check(obj: {
  permission: Permission;
}): Promise<{ result: PermissionResult }> {
  return Plugins.PermissionPlugin.check(obj);
}

export async function request(obj: {
  permission: Permission;
}): Promise<{ result: PermissionResult }> {
  return Plugins.PermissionPlugin.request(obj);
}

export function requestable(result: PermissionResult) {
  return result === "denied";
}

export async function openSettings(): Promise<void> {
  return Plugins.PermissionPlugin.openSettings();
}
