import { useState, useCallback, useEffect, useRef } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { ResourcesRequestState } from "../models/ResourcesRequestState";

export default function usePullToRefresh<T>(
  resourceRequestState: ResourcesRequestState<T>,
  refreshFunction: () => void,
  didFinishRefreshing?: () => void
): {
  handleRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
} {
  const [
    refreshEventDetail,
    setRefreshEventDetail,
  ] = useState<RefresherEventDetail | null>(null);

  const handleRefresh = useCallback(
    (e: CustomEvent<RefresherEventDetail>) => {
      refreshFunction();
      setRefreshEventDetail(e.detail);
    },
    [refreshFunction, setRefreshEventDetail]
  );

  const didFinishRefreshingCallbackRef = useRef(didFinishRefreshing);
  didFinishRefreshingCallbackRef.current = didFinishRefreshing;

  useEffect(() => {
    if (
      ((resourceRequestState.type === "loaded" ||
        resourceRequestState.type === "error-with-resources") &&
        resourceRequestState.source === "remote") ||
      resourceRequestState.type === "error"
    ) {
      if (refreshEventDetail != null) {
        refreshEventDetail.complete();
      }
      setRefreshEventDetail(null);
      if (didFinishRefreshingCallbackRef.current) {
        didFinishRefreshingCallbackRef.current();
      }
    }
  }, [resourceRequestState, refreshEventDetail, setRefreshEventDetail]);

  return { handleRefresh };
}
