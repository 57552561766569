import { fetchPromotionBannersForProduct, restAPIClient } from "../api/RESTful";

import { Product } from "../models/ProductDetails";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { PromotionBanner } from "../models/PromotionBanner";

import { useFetchResources_v2 } from "./Hooks";
import { useIntl } from "../i18n/Localization";

export function useFetchPromotionBannersForProduct(): [
  ResourcesRequestState<PromotionBanner[] | null>,
  (product: Product) => Promise<PromotionBanner[] | null>,
  (product: Product) => Promise<PromotionBanner[] | null>
] {
  const { locale } = useIntl();
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    PromotionBanner[] | null,
    (product: Product) => Promise<PromotionBanner[] | null>
  >({
    remoteResourcesProvider: async (product: Product) => {
      const productId = product.entityId;
      const promotionBanners = await fetchPromotionBannersForProduct(
        restAPIClient,
        productId,
        locale
      );
      return promotionBanners;
    },
  });

  return [requestState, fetch, refresh];
}
