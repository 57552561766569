import { useCallback, useRef } from "react";
import { IonLifeCycleContextInterface } from "@ionic/react";

export function useViewDidEnterOnce(
  ionLifeCycleContext: IonLifeCycleContextInterface,
  fn: () => unknown
) {
  const viewDidEnterOnce = useRef(false);

  const viewDidEnter = useCallback(() => {
    if (viewDidEnterOnce.current) {
      return;
    }
    viewDidEnterOnce.current = true;
    fn();
  }, [fn]);

  ionLifeCycleContext.onIonViewDidEnter(viewDidEnter);
}
