import { ApolloClient, gql } from "@apollo/client";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";
import { useGraphQLFn } from "../../hook/graphql";
import { useEffect, useState, useRef, useCallback } from "react";

async function fetchOrder(
  client: ApolloClient<any>,
  locale: Locale,
  id: string
): Promise<{ orderStatus: string }> {
  const result = await client.query<{ salesOrder: { orderStatus: string } }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryOrder($incrementID: String!) {
        salesOrder(increment_id: $incrementID) {
          orderStatus: order_status
        }
      }
    `,
    variables: {
      incrementID: id,
    },
    fetchPolicy: "network-only",
  });
  return result.data.salesOrder;
}

export function usePollOrderStatus() {
  const fetchOrder_ = useGraphQLFn(fetchOrder);
  const isFetching = useRef(false);
  const [orderStatus, setOrderStatus] = useState<string | null>(null);
  const [pollingOrderID, setPollingOrderID] = useState<string | null>(null);
  const intervalID = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (pollingOrderID != null) {
      intervalID.current = setInterval(async () => {
        if (isFetching.current) {
          return;
        }

        isFetching.current = true;
        const { orderStatus: _orderStatus } = await fetchOrder_(pollingOrderID);
        isFetching.current = false;
        setOrderStatus(_orderStatus);
      }, 2500);
    }

    return () => {
      if (intervalID.current != null) {
        clearInterval(intervalID.current);
        intervalID.current = null;
      }

      isFetching.current = false;
      setOrderStatus(null);
    };
  }, [pollingOrderID, fetchOrder_]);

  const startPolling = useCallback((orderID: string) => {
    setPollingOrderID(orderID);
  }, []);

  const stopPolling = useCallback(() => {
    setPollingOrderID(null);
  }, []);

  return {
    startPolling,
    stopPolling,
    orderStatus,
  };
}
