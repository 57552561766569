import { WebPlugin } from "@capacitor/core";

import { AdobeExperiencePlatformPlugin } from "./definitions";

export class AdobeExperiencePlatformWeb extends WebPlugin
  implements AdobeExperiencePlatformPlugin {
  constructor() {
    super({
      name: "AdobeExperiencePlatform",
      platforms: ["web"],
    });
  }

  async initialize(): Promise<void> {
    throw new Error("Not Implemented");
  }

  async configureWithAppId(): Promise<void> {
    throw new Error("Not Implemented");
  }

  async setPushIdentifier(): Promise<void> {
    throw new Error("Not Implemented");
  }

  async lifecycleStart(): Promise<void> {
    throw new Error("Not Implemented");
  }

  async lifecyclePause(): Promise<void> {
    throw new Error("Not Implemented");
  }

  async collectMessageInfo(): Promise<void> {
    throw new Error("Not Implemented");
  }

  async collectPii(): Promise<void> {
    throw new Error("Not Implemented");
  }
}
