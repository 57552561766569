import React, { useCallback, useState, useContext } from "react";
import cn from "classnames";
// eslint-disable-next-line no-restricted-imports
import { IonModal, IonContent } from "@ionic/react";
import { useHistory } from "react-router-dom";

import { PrimaryButton } from "../Button";
import Checkbox from "../Checkbox";
import VerifyEmailModal from "../VerifyEmailModal";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import Config from "../../Config";
import { LocalizedText } from "../../i18n/Localization";
import { OAuthProvider } from "../../api/RESTful";
import { isCustomerRequireEmailVerificationPrompt } from "../../models/Customer";
import { useSignupWithOAuthRequest } from "../../repository/AuthRepository";
import {
  getPathForSelectInterestCategoryPage,
  RootTab,
} from "../../navigation/routes";
import { useAddStoreToUrl } from "../../hook/Url";

import styles from "./styles.module.scss";

const enableTheClubSSOMVP1ConfirmationDialogs =
  Config.ENABLE_THE_CLUB_SSO_MVP1 &&
  Config.ENABLE_THE_CLUB_SSO_MVP1.THE_CLUB_SSO_CONFIRMATION_DIALOGS;

interface SocialSignupTermsAndConditionsModalProps {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
  onSuccess: () => void;
  provider?: OAuthProvider;
  token?: string;
}

const SocialSignupTermsAndConditionsModal: React.FC<
  SocialSignupTermsAndConditionsModalProps
> = (props: SocialSignupTermsAndConditionsModalProps) => {
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribeToNewsletter, setIsSubscribeToNewsletter] = useState(false);
  const onIsSubscribeToNewsletterChange = useCallback(
    (value: boolean) => {
      setIsSubscribeToNewsletter(value);
    },
    [setIsSubscribeToNewsletter]
  );
  const [verifyEmailModalIsOpen, setVerifyEmailModalIsOpen] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const { isModalOpen, onRequestDismiss, onSuccess, token, provider } = props;
  const history = useHistory();

  const signupWithOAuth = useSignupWithOAuthRequest();
  const ssoSignup = useCallback(
    async (_token: string, _provider: OAuthProvider) => {
      try {
        setIsLoading(true);
        const customer = await signupWithOAuth(
          _token,
          _provider,
          isSubscribeToNewsletter
        );
        if (!customer) {
          presentLocalizedAlert({
            headerId: "signup.signup_failed",
            buttons: [{ textMessageID: "try_again" }],
          });
          return;
        }
        if (isCustomerRequireEmailVerificationPrompt(customer)) {
          setVerifyEmailModalIsOpen(true);
          setVerifyEmail(customer.email || "");
          return;
        }
        onSuccess();
        if (!enableTheClubSSOMVP1ConfirmationDialogs) {
          history.replace(getPathForSelectInterestCategoryPage(RootTab.home));
        }
      } catch (e) {
        const messageId =
          e.message === "email-already-in-use"
            ? "signup.social_signup_email_used_fail_text"
            : undefined;
        presentLocalizedAlert({
          headerId:
            e.message === "email-already-in-use"
              ? "signup.social_signup_email_used_fail_title"
              : "signup.signup_failed",
          messageId,
          buttons: [{ textMessageID: "try_again" }],
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      history,
      setIsLoading,
      signupWithOAuth,
      isSubscribeToNewsletter,
      presentLocalizedAlert,
      onSuccess,
    ]
  );

  const agreeOnClick = useCallback(() => {
    if (token && provider) {
      ssoSignup(token, provider);
    }
  }, [token, provider, ssoSignup]);

  const onVerifyEmailModalDismiss = useCallback(() => {
    setVerifyEmailModalIsOpen(false);
  }, []);

  const handleEmailVerified = useCallback(() => {
    setVerifyEmailModalIsOpen(false);
    onSuccess();
    if (!enableTheClubSSOMVP1ConfirmationDialogs) {
      history.replace(getPathForSelectInterestCategoryPage(RootTab.home));
    }
  }, [onSuccess, history]);

  return (
    <>
      <IonModal
        className={styles.modal}
        isOpen={isModalOpen}
        onDidDismiss={onRequestDismiss}
        backdropDismiss={!isLoading}
      >
        <IonContent className={styles.modalContent} scrollY={true}>
          <div
            onClick={onRequestDismiss}
            className={cn(styles.crossButton, isLoading && styles.disabled)}
          />
          <div className={styles.modalBody}>
            <div className={styles.image} />
            <div className={styles.title}>
              <LocalizedText messageID="modal.social_signup_terms.title" />
            </div>
            <div className={styles.inputs}>
              <div className={styles.checkBoxInput}>
                <Checkbox
                  checked={isSubscribeToNewsletter}
                  onCheckedChange={onIsSubscribeToNewsletterChange}
                >
                  <LocalizedText messageID="modal.social_signup_terms.subscribe_news" />
                </Checkbox>
              </div>
            </div>
            <div className={styles.termsAndConditionsDescription}>
              {Config.SIGN_UP_TERMS_AND_CONDITIONS_LINK &&
              Config.SIGN_UP_PRIVACY_POLICY_LINK ? (
                <AcceptTermsText
                  termsAndConditionsLink={
                    Config.SIGN_UP_TERMS_AND_CONDITIONS_LINK
                  }
                  privacyPolicyLink={Config.SIGN_UP_PRIVACY_POLICY_LINK}
                />
              ) : null}
            </div>
            <div className={styles.agreeButtonContainer}>
              <PrimaryButton
                onClick={agreeOnClick}
                className={styles.agreeButton}
                loading={isLoading}
              >
                <LocalizedText messageID="modal.social_signup_terms.agree" />
              </PrimaryButton>
            </div>
          </div>
        </IonContent>
      </IonModal>
      <VerifyEmailModal
        isModalOpen={verifyEmailModalIsOpen}
        initialEmail={verifyEmail}
        onRequestDismiss={onVerifyEmailModalDismiss}
        onEmailVerified={handleEmailVerified}
      />
    </>
  );
};

export default React.memo(SocialSignupTermsAndConditionsModal);

interface AcceptTermsTextProps {
  termsAndConditionsLink: string;
  privacyPolicyLink: string;
}

const AcceptTermsText: React.FC<AcceptTermsTextProps> = props => {
  const termsAndConditionsLink = useAddStoreToUrl()(
    props.termsAndConditionsLink
  );
  const privacyPolicyLink = useAddStoreToUrl()(props.privacyPolicyLink);

  const handleLinkClick = useCallback((e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
  }, []);

  return (
    <LocalizedText
      messageID="signup.accept_terms"
      messageArgs={{
        termsAndPrivacyPolicy: styles.termsAndPrivacyPolicy,
        handleLinkClick,
        termsAndConditionsLink,
        privacyPolicyLink,
      }}
    />
  );
};
