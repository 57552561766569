import { ConfiguredProduct, Product } from "../../models/ProductDetails";
import { ProductOverview } from "../../models/ProductOverview";

export type ProductData = ProductOverviewData | ConfiguredProductData;

interface ConfiguredProductData {
  type: "full-product";
  product: ConfiguredProduct;
}

export function ConfiguredProductData(
  product: ConfiguredProduct
): ConfiguredProductData {
  return {
    type: "full-product",
    product,
  };
}

interface ProductOverviewData {
  type: "product-overview";
  productOverview: ProductOverview;
}

export function ProductOverviewData(
  productOverview: ProductOverview
): ProductOverviewData {
  return {
    type: "product-overview",
    productOverview,
  };
}

export function asProductOverview(productData: ProductData): ProductOverview {
  return productData.type === "product-overview"
    ? productData.productOverview
    : productData.product;
}

function getAttribute<K extends keyof (ProductOverview | ConfiguredProduct)>(
  productData: ProductData,
  key: K
) {
  const productOverview =
    productData.type === "product-overview"
      ? productData.productOverview
      : productData.product;
  return productOverview[key];
}

export function getProductSKU(productData: ProductData): string {
  return getAttribute(productData, "sku");
}

export function getProductImageUrl(productData: ProductData): string | null {
  const image = getAttribute(productData, "image");
  return image ? image.url : null;
}

export function getProductName(productData: ProductData): string {
  return getAttribute(productData, "name");
}

export interface PageResource {
  product: Product;
}
