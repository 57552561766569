import React, { useMemo } from "react";
import classnames from "classnames";
import { format } from "date-fns";

import { LocalizedText, useIntl } from "../../i18n/Localization";
import { Product } from "../../models/ProductDetails";
import { isMerchantProductDeliveryMethod } from "../../models/product";
import { CMSStaticBlock } from "../../models/cmsBlock";
import CMSHtmlView from "../CMSBlocks/CMSHtml/lazy";

import styles from "./styles.module.scss";
import Config from "../../Config";

interface Props {
  className?: string;
  product: Pick<
    Product,
    | "deliveryMethodBlockIdentifier"
    | "deliveryMethodLabel"
    | "deliveryMethod"
    | "estimatedDeliveryDate"
  >;
  onCMSHtmlViewDeepLinkPublish?: (url: string) => void;
}

const ProductDeliveryMethodBlock: React.FC<Props> = React.memo(props => {
  const { className, product, onCMSHtmlViewDeepLinkPublish } = props;
  const { translate } = useIntl();

  const {
    deliveryMethodBlockIdentifier,
    deliveryMethodLabel,
    deliveryMethod,
  } = product;

  const cmsStaticBlock = useMemo<CMSStaticBlock | null>(
    () =>
      deliveryMethodBlockIdentifier
        ? {
            type: "static_block",
            items: [{ identifier: deliveryMethodBlockIdentifier }],
          }
        : null,
    [deliveryMethodBlockIdentifier]
  );

  const estimatedDeliveryDate = !deliveryMethod
    ? product.estimatedDeliveryDate || null
    : // Should not show estimated delivery date for evoucher and merchant product
    deliveryMethod === "evoucher" ||
      isMerchantProductDeliveryMethod(deliveryMethod)
    ? null
    : product.estimatedDeliveryDate || null;

  if (!deliveryMethodLabel && !estimatedDeliveryDate && !cmsStaticBlock) {
    return null;
  }

  return (
    <div
      className={classnames(
        className,
        styles.productDeliveryMethodBlockContainer
      )}
    >
      <h2 className={styles.productDeliveryMethodTitle}>
        <LocalizedText messageID="page.product_detail.delivery_method.title" />
      </h2>
      {Config.ENABLE_PRE_ORDER_AND_EDD && deliveryMethodLabel ? (
        <p className={styles.productDeliveryMethodLabel}>
          {deliveryMethodLabel}
        </p>
      ) : null}
      {Config.ENABLE_PRE_ORDER_AND_EDD && estimatedDeliveryDate ? (
        <p className={styles.estimatedDeliveryDate}>
          <LocalizedText
            messageID="page.product_detail.delivery_method.estimated_delivery_date"
            messageArgs={{
              dateStr: format(
                estimatedDeliveryDate,
                translate(
                  "page.product_detail.delivery_method.estimated_delivery_date.date_format"
                )
              ),
            }}
          />
        </p>
      ) : null}
      {cmsStaticBlock ? (
        <CMSHtmlView
          cmsStaticBlock={cmsStaticBlock}
          onDeepLinkPublish={onCMSHtmlViewDeepLinkPublish}
        />
      ) : null}
    </div>
  );
});

export default ProductDeliveryMethodBlock;
