import { Plugins } from "@capacitor/core";

export type PayType = "NORMAL_PAYMENT" | "HOLD_PAYMENT";
export type PayMethod = "VISA" | "MASTER" | "ALIPAYHK" | "GOOGLE";
export type PayChannel = "WEBVIEW" | "DIRECT" | "EASYPAYMENTFORM";

interface TransactionQueryResult {
  successcode?: "0" | "1" | "2" | null;
  errMsg?: string | null;
  ref: string;
  prc?: string | null;
  src?: string | null;
}

export class AsiaPayError extends Error {
  public prc?: string;
  public src?: string;
  constructor(message: string, prc?: string, src?: string) {
    super(message);
    this.prc = prc;
    this.src = src;
  }
}

const pluginErrorCodesRegex = new RegExp("(.*),(.*)");

export async function pay(
  payChannel: PayChannel,
  amount: string,
  currCode: string,
  payType: PayType,
  orderRef: string,
  payMethod: PayMethod,
  lang: string,
  merchantId: string,
  remark: string,
  payRef: string
) {
  try {
    return Plugins.AsiaPayPlugin.pay({
      payChannel,
      amount,
      currCode,
      payType,
      orderRef,
      payMethod,
      lang,
      merchantId,
      remark,
      payRef,
    });
  } catch (e) {
    const { code, message } = e;
    const matched = pluginErrorCodesRegex.exec(code);
    if (matched) {
      const [prc, src] = matched;
      throw new AsiaPayError(message, prc, src);
    }
    throw new AsiaPayError(message);
  }
}

export async function queryTransactionResult(): Promise<{
  orders: TransactionQueryResult[];
}> {
  return Plugins.AsiaPayPlugin.queryTransactionResult();
}

export async function isGooglePayReady(): Promise<boolean> {
  return (await Plugins.AsiaPayPlugin.isGooglePayReady()).result;
}
