import React, { useCallback } from "react";

import { LocalizedText } from "../../i18n/Localization";

import {
  ResourcesRequestState,
  getResources,
} from "../../models/ResourcesRequestState";
import { PromotionBanner } from "../../models/PromotionBanner";
import {
  appEventEmitter,
  AppEventOnClickPromotionBanner,
} from "../../utils/SimpleEventEmitter";

import styles from "./styles.module.scss";
import { LoadingView } from "../LoadingView";

interface Props {
  promotionBannersResourcesState: ResourcesRequestState<
    PromotionBanner[] | null
  >;
}

const PromotionBannersSection: React.FC<Props> = React.memo(props => {
  const { promotionBannersResourcesState } = props;
  const promotionBanners = getResources(promotionBannersResourcesState);
  const isLoading = promotionBannersResourcesState.type === "loading";
  if (
    (promotionBanners == null || promotionBanners.length === 0) &&
    !isLoading
  ) {
    return null;
  }
  return (
    <div className={styles.promotionBannersSection}>
      <div className={styles.promotionTitle}>
        <LocalizedText messageID="page.product_detail.promotions.title" />
      </div>
      <div className={styles.promotionListContainer}>
        {isLoading && (
          <div className={styles.loadingContainer}>
            <LoadingView />
          </div>
        )}
        {promotionBanners && (
          <div className={styles.promotionBannerList}>
            {promotionBanners.map(promotionBanner => (
              <PromotionBannerItem
                key={promotionBanner.entityId}
                promotionBanner={promotionBanner}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

const PromotionBannerItem: React.FC<{
  promotionBanner: PromotionBanner;
}> = React.memo(props => {
  const { promotionBanner } = props;

  const handleClick = useCallback(() => {
    if (promotionBanner.bannerLink) {
      appEventEmitter.publish(
        AppEventOnClickPromotionBanner(promotionBanner.bannerLink)
      );
    }
  }, [promotionBanner]);

  return (
    <div className={styles.promotionBannerListItem}>
      <div onClick={handleClick}>
        <img
          alt={promotionBanner.bannerAlt || undefined}
          src={promotionBanner.bannerImage}
        />
      </div>
    </div>
  );
});

export default PromotionBannersSection;
