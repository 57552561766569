import React from "react";
// eslint-disable-next-line no-restricted-imports
import { IonLifeCycleContext } from "@ionic/react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

interface InternalProps extends React.HTMLAttributes<HTMLElement> {
  forwardedRef?: React.Ref<HTMLElement>;
  hideTabBar?: boolean;
  onHideTabBar?: () => void;
  onShowTabBar?: () => void;
}

type ExternalProps = Props & {
  ref?: React.Ref<HTMLElement>;
  hideTabBar?: boolean;
  onHideTabBar?: () => void;
  onShowTabBar?: () => void;
};

interface StackViewState {
  ref: any;
}

class ViewInternal extends React.Component<InternalProps, StackViewState> {
  context!: React.ContextType<typeof IonLifeCycleContext>;

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      ref: null,
    };
  }

  componentDidMount() {
    const forwardedRef = this.props.forwardedRef as React.RefObject<
      HTMLElement
    > | null;
    this.setState({ ref: forwardedRef });
    if (forwardedRef && forwardedRef.current) {
      forwardedRef.current.addEventListener(
        "ionViewWillEnter",
        this.ionViewWillEnterHandler
      );
      forwardedRef.current.addEventListener(
        "ionViewDidEnter",
        this.ionViewDidEnterHandler
      );
      forwardedRef.current.addEventListener(
        "ionViewWillLeave",
        this.ionViewWillLeaveHandler
      );
      forwardedRef.current.addEventListener(
        "ionViewDidLeave",
        this.ionViewDidLeaveHandler
      );
    }
  }

  componentWillUnmount() {
    const forwardedRef = this.props.forwardedRef as React.RefObject<
      HTMLElement
    > | null;
    if (forwardedRef && forwardedRef.current) {
      forwardedRef.current.removeEventListener(
        "ionViewWillEnter",
        this.ionViewWillEnterHandler
      );
      forwardedRef.current.removeEventListener(
        "ionViewDidEnter",
        this.ionViewDidEnterHandler
      );
      forwardedRef.current.removeEventListener(
        "ionViewWillLeave",
        this.ionViewWillLeaveHandler
      );
      forwardedRef.current.removeEventListener(
        "ionViewDidLeave",
        this.ionViewDidLeaveHandler
      );
    }
  }

  ionViewWillEnterHandler = () => {
    const { hideTabBar = false, onHideTabBar, onShowTabBar } = this.props;
    if (hideTabBar && onHideTabBar) {
      onHideTabBar();
    } else if (!hideTabBar && onShowTabBar) {
      onShowTabBar();
    }
    this.context.ionViewWillEnter();
  };

  ionViewDidEnterHandler = () => {
    this.context.ionViewDidEnter();
  };

  ionViewWillLeaveHandler = () => {
    this.context.ionViewWillLeave();
  };

  ionViewDidLeaveHandler = () => {
    this.context.ionViewDidLeave();
  };

  render() {
    const {
      className,
      children,
      forwardedRef,
      hideTabBar,
      onHideTabBar,
      onShowTabBar,
      ...rest
    } = this.props;
    const { ref } = this.state;
    return (
      <div
        className={className ? `ion-page ${className}` : "ion-page"}
        ref={forwardedRef as any}
        {...rest}
      >
        {ref && children}
      </div>
    );
  }
}
ViewInternal.contextType = IonLifeCycleContext;

function forwardRef(props: InternalProps, ref: React.Ref<HTMLElement>) {
  return <ViewInternal forwardedRef={ref} {...props} />;
}
forwardRef.displayName = "OurView";

export const OurView = React.forwardRef<HTMLElement, ExternalProps>(forwardRef);
