import { useEffect, useState } from "react";
import { setToken } from "../CLPlugins/MigrateToV2/functions";
import {
  getAccessToken,
  setAccessTokenToStorage,
  clearAccessToken,
} from "../storage";

export const TokenStore: {
  accessToken: string | null;
} = {
  accessToken: null,
};

export async function setAccessToken(accessToken: string | null) {
  if (accessToken == null) {
    await clearAccessToken();
  } else {
    await setAccessTokenToStorage(accessToken);
  }

  await setToken(accessToken);

  TokenStore.accessToken = accessToken;
}

export function useAccessToken(): boolean {
  const [getAccessTokenFinished, setGetAccessTokenFinished] = useState(false);
  useEffect(() => {
    getAccessToken().then(async accessToken => {
      if (accessToken) {
        TokenStore.accessToken = accessToken;
      }
      await setToken(accessToken);
      setGetAccessTokenFinished(true);
    });
  }, []);
  return getAccessTokenFinished;
}
