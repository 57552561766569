import {
  InAppBrowser,
  InAppBrowserOptions,
  InAppBrowserEvent,
} from "@ionic-native/in-app-browser";

import { isAndroid } from "./Platform";

const useBeforeLoad = isAndroid();

export enum TheClubOTPError {
  CANCELLED = "CANCELLED",
}

export interface TheClubOTPParameters {
  additionalRemarks: string;
  callbackUri: string;
  cancelUri: string;
  channel: string;
  clientId: string;
  clubMemberId: string;
  clubPointAmount: string;
  idSignature: string;
  language: string;
  orderReferenceNo: string;
  packageCode: string;
  signature: string;
}

function getUrl(action: string, parameters: TheClubOTPParameters) {
  const {
    additionalRemarks,
    callbackUri,
    cancelUri,
    channel,
    clientId,
    clubMemberId,
    clubPointAmount,
    idSignature,
    language,
    orderReferenceNo,
    packageCode,
    signature,
  } = parameters;

  const content = `
<html>
<body>
<form method="post" action="${action}" id="form">
  <input type="hidden" name="additionalRemarks" value="${additionalRemarks}" />
  <input type="hidden" name="callbackUri" value="${callbackUri}" />
  <input type="hidden" name="cancelUri" value="${cancelUri}" />
  <input type="hidden" name="channel" value="${channel}" />
  <input type="hidden" name="clientId" value="${clientId}" />
  <input type="hidden" name="clubMemberId" value="${clubMemberId}" />
  <input type="hidden" name="clubPointAmount" value="${clubPointAmount}" />
  <input type="hidden" name="idSignature" value="${idSignature}" />
  <input type="hidden" name="language" value="${language}" />
  <input type="hidden" name="orderReferenceNo" value="${orderReferenceNo}" />
  <input type="hidden" name="packageCode" value="${packageCode}" />
  <input type="hidden" name="signature" value="${signature}" />
</form>
<script>
  var form = document.getElementById('form');
  form.submit();
</script>
</body>
</html>
`;

  return `data:text/html;base64,${btoa(content)}`;
}

const otpInputScript = `
$(".otp-number input").off("input");

$(".otp-number input").attr("maxlength", 6);

$(".otp-number input").on("input", function () {
  var currentVal = $(this).val();
  if (currentVal.length >= 1) {
    $(this).val(currentVal[0]);
    var nextEl = $(this).parent().next('td').children('input').eq(0);
    for (var i = 1; i < currentVal.length; i++) {
      if (nextEl.length) {
        nextEl.val(currentVal[i]);
      } else {
        break;
      }
      var nextEl = nextEl.parent().next('td').children('input').eq(0);
    }
    nextEl.focus();
  }

  clearCheckOTPResult();
  disableSubmitButtonWhenOTPlack();
  var otp = getOTP();
  if(otp.length == 6){
    //clear the error message
    clearAndHideOTPError();
    validateOTPAJAX();
  }
});
`;

const options: InAppBrowserOptions = {
  location: "no",
  clearcache: "yes",
  clearsessioncache: "yes",

  // Android
  footer: "no",
  zoom: "no",
  hardwareback: "no",
  beforeload: useBeforeLoad ? "yes" : undefined,

  // iOS
  cleardata: "yes",
  toolbar: "no",
};

export function presentTheClubOTPBrowser(
  action: string,
  theClubOtpParameters: TheClubOTPParameters
): Promise<null> {
  const { callbackUri, cancelUri } = theClubOtpParameters;
  return new Promise((resolve, reject) => {
    const browser = InAppBrowser.create(
      getUrl(action, theClubOtpParameters),
      "_blank",
      options
    );

    const loadstart = browser.on("loadstart");
    if (loadstart) {
      const loadstartListener = loadstart.subscribe(
        (event: InAppBrowserEvent) => {
          const eventCallback = encodeURI(event.url);
          if (eventCallback.indexOf(action) > -1) {
            setTimeout(() => {
              browser.executeScript({
                code: otpInputScript,
              });
            }, 3000);
          } else if (eventCallback.indexOf(callbackUri) > -1) {
            loadstartListener.unsubscribe();
            browser.close();
            resolve();
          } else if (eventCallback.indexOf(cancelUri) > -1) {
            loadstartListener.unsubscribe();
            browser.close();
            reject(new Error(TheClubOTPError.CANCELLED));
          }
        }
      );
    }

    if (useBeforeLoad) {
      const beforeload = browser.on("beforeload");
      if (beforeload) {
        const beforeloadListener = beforeload.subscribe(
          async (event: InAppBrowserEvent) => {
            const eventCallback = encodeURI(event.url);
            if (eventCallback.indexOf(action) > -1) {
              setTimeout(() => {
                browser.executeScript({
                  code: otpInputScript,
                });
              }, 3000);
            } else if (eventCallback.indexOf(callbackUri) > -1) {
              beforeloadListener.unsubscribe();
              browser.close();
              resolve();
              return;
            } else if (eventCallback.indexOf(cancelUri) > -1) {
              beforeloadListener.unsubscribe();
              browser.close();
              reject(new Error(TheClubOTPError.CANCELLED));
              return;
            }
            browser._loadAfterBeforeload(event.url);
          }
        );
      }
    }

    const exit = browser.on("exit");
    if (exit) {
      const exitListener = exit.subscribe(() => {
        exitListener.unsubscribe();
        reject(new Error(TheClubOTPError.CANCELLED));
      });
    }
  });
}
