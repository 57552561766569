import { useContext, useMemo } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { AppConfig, URLMappingRule, LiveEvent } from "../models/AppConfig";
import { StoreConfig } from "../models/StoreConfig";
import ConfigContext from "../contexts/ConfigContext";
import { fetchStoreConfig, fetchLiveEvent } from "../api/GraphQL";
import { fetchAppConfig } from "../api/AppConfig";
import { Locale, DataByLocale } from "../i18n/locale";
import { useIntl } from "../i18n/Localization";

import {
  getStoreConfig,
  setStoreConfig,
  getAppConfig,
  storeAppConfig,
  storeUrlRedirect,
  getUrlRedirect,
} from "../storage";
import { useFetchResources_v2 } from "./Hooks";
import { useResolveUrl } from "./DeepLinkRepository";
import { EntityUrlRedirect, EntityUrl } from "../models/EntityUrl";
import Config from "../Config";
import { getInfo } from "../CLPlugins/DeviceInfo";
import { isiOS, isAndroid } from "../utils/Platform";
import { semverCmp } from "../utils/semver";

export function useFetchStoreConfig(): [
  ResourcesRequestState<DataByLocale<StoreConfig>>,
  () => Promise<DataByLocale<StoreConfig>>
] {
  const { updateStoreConfigs } = useContext(ConfigContext);
  const client = useApolloClient();
  const [requestState, { call }] = useFetchResources_v2<
    DataByLocale<StoreConfig>,
    () => Promise<DataByLocale<StoreConfig>>
  >({
    localCacheProvider: () =>
      Promise.all([
        getStoreConfig(Locale.en),
        getStoreConfig(Locale.zhHant),
      ]).then(([enConfig, zhHantHKConfig]) => {
        if (enConfig && zhHantHKConfig) {
          const storeConfigs = {
            [Locale.en]: enConfig,
            [Locale.zhHant]: zhHantHKConfig,
          };
          updateStoreConfigs(storeConfigs);
          return storeConfigs;
        }
        return null;
      }),
    remoteResourcesProvider: async () => {
      const enConfig = await fetchStoreConfig(client, Locale.en);
      const zhHantHKConfig = await fetchStoreConfig(client, Locale.zhHant);
      if (enConfig == null || zhHantHKConfig == null) {
        throw Error("unknown");
      }
      const storeConfigs = {
        [Locale.en]: enConfig,
        [Locale.zhHant]: zhHantHKConfig,
      };
      setStoreConfig(enConfig, Locale.en);
      setStoreConfig(zhHantHKConfig, Locale.zhHant);
      updateStoreConfigs(storeConfigs);
      return storeConfigs;
    },
  });
  return [requestState, call];
}

export function useFetchLiveEvent(): [
  ResourcesRequestState<DataByLocale<LiveEvent>>,
  () => Promise<DataByLocale<LiveEvent>>,
  () => Promise<DataByLocale<LiveEvent>>
] {
  const client = useApolloClient();
  const { liveEvent, updateLiveEvents } = useContext(ConfigContext);

  const [requestState, { call, refresh }] = useFetchResources_v2<
    DataByLocale<LiveEvent>,
    () => Promise<DataByLocale<LiveEvent>>
  >({
    memoryCacheProvider: () => Promise.resolve(liveEvent),
    localCacheProvider: async () => {
      const enLiveEvent = await fetchLiveEvent(client, Locale.en, "cache-only");
      const zhHantHKLiveEvent = await fetchLiveEvent(
        client,
        Locale.zhHant,
        "cache-only"
      );
      if (enLiveEvent == null || zhHantHKLiveEvent == null) {
        throw Error("unknown");
      }
      const liveEvents = {
        [Locale.en]: enLiveEvent,
        [Locale.zhHant]: zhHantHKLiveEvent,
      };
      updateLiveEvents(liveEvents);
      return liveEvents;
    },
    remoteResourcesProvider: async () => {
      const enLiveEvent = await fetchLiveEvent(
        client,
        Locale.en,
        "network-only"
      );
      const zhHantHKLiveEvent = await fetchLiveEvent(
        client,
        Locale.zhHant,
        "network-only"
      );
      if (enLiveEvent == null || zhHantHKLiveEvent == null) {
        throw Error("unknown");
      }
      const liveEvents = {
        [Locale.en]: enLiveEvent,
        [Locale.zhHant]: zhHantHKLiveEvent,
      };
      updateLiveEvents(liveEvents);
      return liveEvents;
    },
  });
  return [requestState, call, refresh];
}

export function useStoreConfig(): StoreConfig | null {
  const { locale } = useIntl();
  const { storeConfig } = useContext(ConfigContext);
  return (storeConfig && storeConfig[locale]) || null;
}

async function resolveUrlRedirect(
  urlRedirect: URLMappingRule,
  resolveUrl: (urlString: string) => Promise<EntityUrl | null>
): Promise<EntityUrlRedirect | null> {
  const entityUrl = await resolveUrl(
    `${Config.SITE_URL}/${urlRedirect.requestPath}`
  );
  if (entityUrl == null) {
    return null;
  }

  return {
    ...entityUrl,
    targetPath: urlRedirect.targetPath,
  };
}

async function getEntityUrlRedirectFromAppConfig(
  appConfig: AppConfig,
  resolveUrl: (urlString: string) => Promise<EntityUrl | null>
): Promise<EntityUrlRedirect[]> {
  const resolvePromises: Promise<EntityUrlRedirect | null>[] = [];
  for (let i = 0; i < appConfig.permanentRedirectToExternal.length; i++) {
    const mapping = appConfig.permanentRedirectToExternal[i];
    resolvePromises.push(resolveUrlRedirect(mapping, resolveUrl));
  }

  const result = (await Promise.all(resolvePromises)).filter(
    r => r != null
  ) as EntityUrlRedirect[];
  return result;
}

export function useAutoFetchAppConfig(): [
  ResourcesRequestState<DataByLocale<AppConfig>>,
  () => Promise<DataByLocale<AppConfig>>
] {
  const client = useApolloClient();
  const resolveUrl = useResolveUrl();
  const { appConfig, updateAppConfigs, checkAppNeedUpdate } = useContext(
    ConfigContext
  );
  const [requestState, { call }] = useFetchResources_v2<
    DataByLocale<AppConfig>,
    () => Promise<DataByLocale<AppConfig>>
  >({
    memoryCacheProvider: () => Promise.resolve(appConfig),
    localCacheProvider: () =>
      Promise.all([
        getAppConfig(Locale.en),
        getAppConfig(Locale.zhHant),
        getUrlRedirect(Locale.en),
        getUrlRedirect(Locale.zhHant),
      ])
        .then(
          ([
            enAppConfig,
            zhHantHKAppConfig,
            enUrlRedirect,
            zhHantHKUrlRedirect,
          ]) => {
            if (
              enAppConfig == null ||
              zhHantHKAppConfig == null ||
              enUrlRedirect == null ||
              zhHantHKUrlRedirect == null
            ) {
              return null;
            }
            const appConfigs = {
              [Locale.en]: enAppConfig,
              [Locale.zhHant]: zhHantHKAppConfig,
            };
            const urlRedirects = {
              [Locale.en]: enUrlRedirect,
              [Locale.zhHant]: zhHantHKUrlRedirect,
            };
            updateAppConfigs(appConfigs, urlRedirects);
            return appConfigs;
          }
        )
        .catch(() => null),
    remoteResourcesProvider: async () => {
      const enAppConfig = await fetchAppConfig(
        client,
        Locale.en,
        "network-only"
      );
      const zhHantHKAppConfig = await fetchAppConfig(
        client,
        Locale.zhHant,
        "network-only"
      );
      if (enAppConfig == null || zhHantHKAppConfig == null) {
        throw Error("unknown");
      }
      const enUrlRedirect = await getEntityUrlRedirectFromAppConfig(
        enAppConfig,
        resolveUrl
      );
      const zhHantHKUrlRedirect = await getEntityUrlRedirectFromAppConfig(
        zhHantHKAppConfig,
        resolveUrl
      );
      const appConfigs = {
        [Locale.en]: enAppConfig,
        [Locale.zhHant]: zhHantHKAppConfig,
      };
      const urlRedirects = {
        [Locale.en]: enUrlRedirect,
        [Locale.zhHant]: zhHantHKUrlRedirect,
      };
      storeAppConfig(enAppConfig, Locale.en);
      storeAppConfig(zhHantHKAppConfig, Locale.zhHant);
      storeUrlRedirect(enUrlRedirect, Locale.en);
      storeUrlRedirect(zhHantHKUrlRedirect, Locale.zhHant);
      updateAppConfigs(appConfigs, urlRedirects);

      /**
       * check if app need to force update
       */
      const info = await getInfo();
      const iOSVersion =
        zhHantHKAppConfig.appSystemConfig.iOSVersion ||
        enAppConfig.appSystemConfig.iOSVersion;
      const iOSLink =
        zhHantHKAppConfig.appSystemConfig.iOSLink ||
        enAppConfig.appSystemConfig.iOSLink;
      const iOSRecommendVersion =
        zhHantHKAppConfig.appSystemConfig.iOSRecommendVersion ||
        enAppConfig.appSystemConfig.iOSRecommendVersion;
      const androidVersion =
        zhHantHKAppConfig.appSystemConfig.androidVersion ||
        enAppConfig.appSystemConfig.androidVersion;
      const androidLink =
        zhHantHKAppConfig.appSystemConfig.androidLink ||
        enAppConfig.appSystemConfig.androidLink;
      const androidRecommendVersion =
        zhHantHKAppConfig.appSystemConfig.androidRecommendVersion ||
        enAppConfig.appSystemConfig.androidRecommendVersion;

      if (info.appVersion != null) {
        let version: string | null = null;
        let recommendVersion: string | null = null;
        let updateLink: string | null = null;
        if (isiOS()) {
          version = iOSVersion;
          recommendVersion = iOSRecommendVersion;
          updateLink = iOSLink;
        } else if (isAndroid()) {
          version = androidVersion;
          recommendVersion = androidRecommendVersion;
          updateLink = androidLink;
        }

        checkAppNeedUpdate(
          updateLink === null
            ? false
            : version != null && semverCmp(">", version, info.appVersion)
            ? {
                type: "force",
                forceUpdateLink: updateLink,
              }
            : recommendVersion != null &&
              semverCmp(">", recommendVersion, info.appVersion)
            ? { type: "recommend", recommendUpdateLink: updateLink }
            : false
        );
      }

      return appConfigs;
    },
  });

  return [requestState, call];
}

export function useAppConfig(): AppConfig | null {
  const { locale } = useIntl();
  const { appConfig } = useContext(ConfigContext);
  return (appConfig && appConfig[locale]) || null;
}

export function useUrlRedirectConfig(): EntityUrlRedirect[] | null {
  const { locale } = useIntl();
  const { urlRedirect } = useContext(ConfigContext);
  return (urlRedirect && urlRedirect[locale]) || null;
}

export function useStateMemoryConfigs(): {
  appConfig: DataByLocale<AppConfig>;
  storeConfig: DataByLocale<StoreConfig>;
} | null {
  const { appConfig, storeConfig } = useContext(ConfigContext);
  return useMemo(() => {
    if (appConfig == null || storeConfig == null) {
      return null;
    }
    return {
      appConfig,
      storeConfig,
    };
  }, [appConfig, storeConfig]);
}
