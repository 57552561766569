import React, { useMemo } from "react";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import {
  getEffectiveShippingLabel,
  getEstimatedDeliveryDateForConsolidateAndWarehouse,
  groupOrderItemsByShippingLabel,
  isClubShoppingDelivery,
  isConsolidation,
  isShipmentStatusCompleted,
  OrderEstimatedDeliveryDate,
  ShipmentStage,
  ShipmentStatus,
} from "../../models/Order";
import { useAppConfig } from "../../repository/ConfigRepository";
import HTMLView from "../HTMLView";
import EDDDeliveryMethodBanner from "./DeliveryMethodBanner.EDD";
import ItemGroup from "./ItemGroup";
import { PartialOrderItem } from "./model";
import styles from "./PurchasedItemList.module.scss";

interface PurhcasedItemListProps {
  currencyCode: string;
  estimatedDeliveryDate?: OrderEstimatedDeliveryDate[] | null;
  items: PartialOrderItem[];
  onClickReorder: (item: PartialOrderItem) => void;
  onClickViewDelivery: (shipmentStatus: ShipmentStatus) => void;
  onClickReview: (item: PartialOrderItem) => void;
  onClickRedeem: (item: PartialOrderItem) => void;
  shipmentStatusList: ShipmentStatus[] | null;
  onQRCodeLinkClicked: (url: string) => void;
  onClickViewInsurance: (item: PartialOrderItem) => void;
  onClickRedemptionLetterUrl: (url: string) => void;
}

const PurchasedItemGroupList: React.FC<PurhcasedItemListProps> = props => {
  const {
    currencyCode,
    estimatedDeliveryDate,
    items,
    onClickReorder,
    onClickViewDelivery,
    onClickReview,
    onClickRedeem,
    shipmentStatusList,
    onQRCodeLinkClicked,
    onClickViewInsurance,
    onClickRedemptionLetterUrl,
  } = props;

  const appConfig = useAppConfig();

  const shipmentStatusByProductId = useMemo(() => {
    const res: { [key in number]: ShipmentStatus | null } = {};
    if (!shipmentStatusList) {
      return res;
    }
    for (const shipmentStatus of shipmentStatusList) {
      if (!shipmentStatus) {
        continue;
      }
      const { productIds } = shipmentStatus;
      if (!productIds || !shipmentStatus.stages) {
        continue;
      }
      for (const productId of productIds) {
        res[productId] = shipmentStatus;
      }
    }
    return res;
  }, [shipmentStatusList]);

  const { consolidate, eVoucher, merchant, unknown } = useMemo(
    () => groupOrderItemsByShippingLabel(items),
    [items]
  );

  const estimatedDeliveryDateForConsolidateAndWarehouse = useMemo(
    () =>
      estimatedDeliveryDate
        ? getEstimatedDeliveryDateForConsolidateAndWarehouse(
            estimatedDeliveryDate
          )
        : null,
    [estimatedDeliveryDate]
  );

  const estimatedDeliveryDateDisclaimer =
    estimatedDeliveryDateForConsolidateAndWarehouse &&
    appConfig &&
    appConfig.estimatedDeliveryDateDisclaimer
      ? appConfig.estimatedDeliveryDateDisclaimer
      : null;

  const merchantDeliveryShipmentStatusPlaceholder = useMerchantDeliveryShipmentStatusPlaceholder();
  const clubShoppingDeliveryShipmentStatusPlaceholder = useClubShoppingDeliveryShipmentStatusPlaceholder();
  const consolidateShipmentStatusPlaceholder = useConsolidateShipmentStatusPlaceholder();

  const effectiveConsolidateShippingLabel = useMemo(
    () => getEffectiveShippingLabel(consolidate),
    [consolidate]
  );

  const consolidateShipmentStatus =
    consolidate.length > 0
      ? shipmentStatusByProductId[consolidate[0].productID]
      : null;

  return (
    <div>
      {consolidate.length > 0 ? (
        <div className={styles.itemListByDelivery}>
          <ItemGroup
            className={styles.itemInfo}
            currencyCode={currencyCode}
            header={
              <EDDDeliveryMethodBanner
                name={effectiveConsolidateShippingLabel || ""}
                estimatedDeliveryDate={
                  estimatedDeliveryDateForConsolidateAndWarehouse &&
                  (!consolidateShipmentStatus ||
                    !isShipmentStatusCompleted(consolidateShipmentStatus)) ? (
                    <LocalizedText
                      messageID="order_detail.estimated_delivery_date"
                      messageArgs={{
                        estimatedDeliveryDate: estimatedDeliveryDateForConsolidateAndWarehouse,
                      }}
                    />
                  ) : (
                    ""
                  )
                }
              />
            }
            items={consolidate}
            onClickReorder={onClickReorder}
            onClickViewDelivery={onClickViewDelivery}
            onClickReview={onClickReview}
            onClickRedeem={onClickRedeem}
            shipmentStatus={
              consolidateShipmentStatus ||
              (effectiveConsolidateShippingLabel
                ? isConsolidation(effectiveConsolidateShippingLabel)
                  ? consolidateShipmentStatusPlaceholder
                  : isClubShoppingDelivery(effectiveConsolidateShippingLabel)
                  ? clubShoppingDeliveryShipmentStatusPlaceholder
                  : null
                : null)
            }
            onQRCodeLinkClicked={onQRCodeLinkClicked}
            onClickViewInsurance={onClickViewInsurance}
            onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
          />
          {estimatedDeliveryDateDisclaimer ? (
            <div className={styles.estimatedDeliveryDateDisclaimerSection}>
              <HTMLView htmlContent={estimatedDeliveryDateDisclaimer} />
            </div>
          ) : null}
        </div>
      ) : null}
      {merchant.length > 0
        ? merchant.map((m, i) => {
            if (m.length === 0) {
              return null;
            }
            const shipmentStatus = shipmentStatusByProductId[m[0].productID];
            return (
              <div className={styles.itemListByDelivery} key={i}>
                <ItemGroup
                  className={styles.itemInfo}
                  currencyCode={currencyCode}
                  header={
                    <EDDDeliveryMethodBanner
                      name={m[0].shippingLabel}
                      estimatedDeliveryDate={
                        !shipmentStatus ||
                        !isShipmentStatusCompleted(shipmentStatus) ? (
                          <LocalizedText messageID="order_detail.estimated_delivery_date.refer_to_product_detail_page" />
                        ) : null
                      }
                    />
                  }
                  items={m}
                  onClickReorder={onClickReorder}
                  onClickViewDelivery={onClickViewDelivery}
                  onClickReview={onClickReview}
                  onClickRedeem={onClickRedeem}
                  shipmentStatus={
                    shipmentStatus || merchantDeliveryShipmentStatusPlaceholder
                  }
                  onQRCodeLinkClicked={onQRCodeLinkClicked}
                  onClickViewInsurance={onClickViewInsurance}
                  onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
                />
              </div>
            );
          })
        : null}
      {eVoucher.length > 0 ? (
        <div className={styles.itemListByDelivery}>
          <ItemGroup
            className={styles.itemInfo}
            currencyCode={currencyCode}
            header={
              <EDDDeliveryMethodBanner
                name={eVoucher[0].shippingLabel}
                estimatedDeliveryDate=""
              />
            }
            items={eVoucher}
            onClickReorder={onClickReorder}
            onClickViewDelivery={onClickViewDelivery}
            onClickReview={onClickReview}
            onClickRedeem={onClickRedeem}
            shipmentStatus={shipmentStatusByProductId[eVoucher[0].productID]}
            onQRCodeLinkClicked={onQRCodeLinkClicked}
            onClickViewInsurance={onClickViewInsurance}
            onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
          />
        </div>
      ) : null}
      {unknown.map((item, i) => (
        <div key={i} className={styles.itemListByDelivery}>
          <ItemGroup
            className={styles.itemInfo}
            currencyCode={currencyCode}
            header={
              <EDDDeliveryMethodBanner
                name={item.shippingLabel}
                estimatedDeliveryDate=""
              />
            }
            items={unknown}
            onClickReorder={onClickReorder}
            onClickViewDelivery={onClickViewDelivery}
            onClickReview={onClickReview}
            onClickRedeem={onClickRedeem}
            shipmentStatus={shipmentStatusByProductId[item.productID]}
            onQRCodeLinkClicked={onQRCodeLinkClicked}
            onClickViewInsurance={onClickViewInsurance}
            onClickRedemptionLetterUrl={onClickRedemptionLetterUrl}
          />
        </div>
      ))}
    </div>
  );
};

export default PurchasedItemGroupList;

function useMerchantDeliveryShipmentStatusPlaceholder(): ShipmentStatus {
  const { translate } = useIntl();
  return useMemo(
    () => ({
      incrementIds: null,
      stages: [
        makeDummyStage(
          translate("order_detail.shipment_status.merchant.placeholder.1")
        ),
        makeDummyStage(
          translate("order_detail.shipment_status.merchant.placeholder.2")
        ),
        makeDummyStage(
          translate("order_detail.shipment_status.merchant.placeholder.3")
        ),
      ],
      trackNumber: null,
      trackTitle: null,
      productIds: [],
    }),
    [translate]
  );
}

function useClubShoppingDeliveryShipmentStatusPlaceholder(): ShipmentStatus {
  const { translate } = useIntl();
  return useMemo(
    () => ({
      incrementIds: null,
      stages: [
        makeDummyStage(
          translate(
            "order_detail.shipment_status.club_shopping_delivery.placeholder.1"
          )
        ),
        makeDummyStage(
          translate(
            "order_detail.shipment_status.club_shopping_delivery.placeholder.2"
          )
        ),
        makeDummyStage(
          translate(
            "order_detail.shipment_status.club_shopping_delivery.placeholder.3"
          )
        ),
      ],
      trackNumber: null,
      trackTitle: null,
      productIds: [],
    }),
    [translate]
  );
}

function useConsolidateShipmentStatusPlaceholder(): ShipmentStatus {
  const { translate } = useIntl();
  return useMemo(
    () => ({
      incrementIds: null,
      stages: [
        makeDummyStage(
          translate("order_detail.shipment_status.consolidate.placeholder.1")
        ),
        makeDummyStage(
          translate("order_detail.shipment_status.consolidate.placeholder.2")
        ),
        makeDummyStage(
          translate("order_detail.shipment_status.consolidate.placeholder.3")
        ),
        makeDummyStage(
          translate("order_detail.shipment_status.consolidate.placeholder.4")
        ),
      ],
      trackNumber: null,
      trackTitle: null,
      productIds: [],
    }),
    [translate]
  );
}

function makeDummyStage(label: string): ShipmentStage {
  return {
    isProcessing: false,
    label,
    timestamp: null,
  };
}
