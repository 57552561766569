import React from "react";
import "./styles.css";

export const OurTabs: React.FC = props => {
  return <div className={"ourTabs ion-page"}>{props.children}</div>;
};

export const HideTabBar: React.FC = props => {
  return <div className={"ion-page hideTabBar"}>{props.children}</div>;
};
