import React, { useCallback } from "react";
import classnames from "classnames";
import styles from "./DeliveryInfoItem.module.scss";
import { Address } from "../../models/Customer";
import AddressCard from "../AddressCard";
import { useIntl, LocalizedText } from "../../i18n/Localization";

interface Props {
  className?: string;
  index: number;
  address: Address;
  onClickSetting: (index: number) => void;
}

const DeliveryInfoItem: React.FC<Props> = props => {
  const { className, index, address, onClickSetting } = props;
  const { translate } = useIntl();

  const title = translate("address_card.title.index", {
    index: index + 1,
  });
  const onClickItemSetting = useCallback(() => {
    onClickSetting(index);
  }, [onClickSetting, index]);

  return (
    <div className={classnames(className, styles.container)}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {address.isDefaultShipping ? (
          <span className={styles.badge}>
            <LocalizedText messageID="my_delivery.shipping" />
          </span>
        ) : null}
        {address.isDefaultBilling ? (
          <span className={styles.badge}>
            <LocalizedText messageID="my_delivery.billing" />
          </span>
        ) : null}
        <div className={styles.button} />
        <div
          className={styles.settingButtonArea}
          onClick={onClickItemSetting}
        />
      </div>
      <AddressCard address={address} />
    </div>
  );
};

export default DeliveryInfoItem;
