import React, { useRef, useMemo, useCallback, useContext } from "react";
import { Redirect } from "react-router-dom";
import { RefresherEventDetail } from "@ionic/core";

import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { WishlistContext } from "../WishlistProvider";

import { BackAndroidExitApp } from "../BackAndroidHandler";
import MainTabPageNavBar from "../MainTabPageNavBar";
import CLContent from "../CLContent";
import ProductList from "../ProductList";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import WishlistEmptyView from "./WishlistEmptyView";
import { FullContentLoadingView } from "../LoadingView";
import { FullContentErrorView } from "../ErrorView";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import { ProductOverview } from "../../models/ProductOverview";
import {
  getRequestStateError,
  isRequestLoading,
} from "../../models/ResourcesRequestState";
import {
  SimpleViewState,
  SimpleViewStateDisplay,
  SimpleViewStateError,
  SimpleViewStateInitial,
  SimpleViewStateLoading,
} from "../../models/SimpleViewState";
import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";

import { useIsLoggedIn } from "../../repository/AuthRepository";
import { PaginationInfo } from "../../repository/State";
import { useFetchProductLabelsByProductIdPaginated } from "../../repository/ProductLabelRepository";

import {
  useCheckIsScreenActive,
  getPathForProductDetailPage,
  useCurrentTab,
  getPathForHomePage,
  RootTab,
} from "../../navigation/routes";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";

import { useScrollToTopWhenClickTabBar } from "../../hook/scrollToTopWhenClickTabBar";
import { usePresentLocalizedAlertForRequestStateError } from "../../hook/ResourcesRequestHook";
import { useKeepUpdatingRef } from "../../hook/utils";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";

import styles from "./styles.module.scss";

function viewEnter() {
  pageView({ page: "Likes" });
}

const LikesPage: React.FC = () => {
  const currentTab = useCurrentTab();
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);

  const { isOnline } = useContext(NetworkStatusContext);

  const isScreenActive = useCheckIsScreenActive();

  const isLoggedIn = useIsLoggedIn();

  const {
    requestState,
    paginationInfo,
    fetchNext: _fetchNext,
    refresh: _refresh,
    toggleProductFromWishlist,
  } = useContext(WishlistContext);

  const handleViewEnter = useCallback(() => {
    if (!paginationInfo || paginationInfo.items.length === 0) {
      _fetchNext().catch(() => {});
    }
  }, [paginationInfo, _fetchNext]);
  ionLifeCycleContext.onIonViewDidEnter(handleViewEnter);

  const [
    productLabelsByProductId,
    fetchProductLabelsByProductIds,
    clearProductLabelByProductId,
  ] = useFetchProductLabelsByProductIdPaginated();

  const fetchProductLabelsByProductIdsRef = useKeepUpdatingRef(
    fetchProductLabelsByProductIds
  );

  const fetchNext = useCallback(async () => {
    const wishlistItems = await _fetchNext();
    if (wishlistItems) {
      const productIds = wishlistItems.map(w => w.product.id);
      fetchProductLabelsByProductIds(productIds);
    }
    return wishlistItems;
  }, [_fetchNext, fetchProductLabelsByProductIds]);

  const refresh = useCallback(async () => {
    const wishlistItems = await _refresh();
    if (wishlistItems) {
      clearProductLabelByProductId();
      const productIds = wishlistItems.map(w => w.product.id);
      fetchProductLabelsByProductIdsRef.current(productIds);
    }
    return wishlistItems;
  }, [
    _refresh,
    fetchProductLabelsByProductIdsRef,
    clearProductLabelByProductId,
  ]);

  usePresentLocalizedAlertForRequestStateError(requestState);

  const isLoadMoreLoading = useMemo(() => {
    if (
      paginationInfo != null &&
      paginationInfo.hasMore &&
      isRequestLoading(requestState)
    ) {
      return true;
    }
    return false;
  }, [paginationInfo, requestState]);

  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(currentTab, productOverview.sku);
    },
    [currentTab]
  );
  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = useCallback(
    (product: ProductOverview) => {
      presentAddToCartModal(product.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );

  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      toggleProductFromWishlist(productOverview.sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );

  const viewState = useMemo<
    SimpleViewState<
      {
        paginationInfo: PaginationInfo<unknown>;
        productOverviews: ProductOverview[];
      },
      Error
    >
  >(() => {
    if (paginationInfo == null) {
      if (requestState.type === "loading") {
        return SimpleViewStateLoading;
      }
      const error = getRequestStateError(requestState);
      if (error) {
        return SimpleViewStateError(error);
      }
      return SimpleViewStateInitial;
    }
    if (paginationInfo.items.length === 0) {
      const error = getRequestStateError(requestState);
      if (error) {
        return SimpleViewStateError(error);
      }
    }
    const productOverviews = paginationInfo.items.map(i => i.product);
    return SimpleViewStateDisplay({ paginationInfo, productOverviews });
  }, [requestState, paginationInfo]);

  const onProductListRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await refresh();
      } catch {
      } finally {
        e.detail.complete();
      }
    },
    [refresh]
  );

  const onProductListEndReach = useCallback(() => {
    fetchNext().catch(() => {});
  }, [fetchNext]);

  const handleRetry = useCallback(() => {
    refresh().catch(() => {});
  }, [refresh]);

  useScrollToTopWhenClickTabBar(RootTab.likes, isScreenActive, contentRef);

  if (!isLoggedIn) {
    return <Redirect to={getPathForHomePage()} />;
  }

  return (
    <>
      <BackAndroidExitApp pageIsActive={isScreenActive} />
      <MainTabPageNavBar />
      <CLContent ref={contentRef}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={
            viewState.type === "display" &&
            viewState.data.productOverviews.length > 0
          }
          onRetry={handleRetry}
        >
          <>
            <ProductList
              ionContentRef={contentRef}
              header={
                viewState.type === "loading" || viewState.type === "initial" ? (
                  <>
                    <FullContentLoadingView />
                  </>
                ) : viewState.type === "display" &&
                  viewState.data.productOverviews.length === 0 ? (
                  <div className={styles.wishListEmptyViewContainer}>
                    <WishlistEmptyView playAnimation={isScreenActive} />
                  </div>
                ) : viewState.type === "error" ? (
                  <FullContentErrorView
                    errorMessage={viewState.error.message}
                  />
                ) : (
                  undefined
                )
              }
              productOverviews={
                viewState.type === "display"
                  ? viewState.data.productOverviews
                  : []
              }
              productLabelsByProductId={productLabelsByProductId}
              isLoadMoreLoading={isLoadMoreLoading}
              hrefForProduct={hrefForProduct}
              onAddToCart={openPurchaseProductModal}
              onClickLikeButton={onClickLikeButton}
              onRefresh={
                viewState.type !== "initial" && viewState.type !== "loading"
                  ? onProductListRefresh
                  : undefined
              }
              onEndReach={
                viewState.type === "display" &&
                viewState.data.productOverviews.length > 0
                  ? onProductListEndReach
                  : undefined
              }
            />
            <TabBarSpacePlaceholder />
          </>
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

export default LikesPage;
