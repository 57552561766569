import React from "react";

import styles from "./EmptyView.module.scss";

interface Props {
  message: string;
}

const EmptyView: React.FC<Props> = props => {
  const { message } = props;
  return (
    <div className={styles.emptyView}>
      <div className={styles.icon} />
      <p className={styles.message}>{message}</p>
    </div>
  );
};

export default EmptyView;
