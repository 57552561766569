import * as yup from "yup";

import { Address } from "../models/Customer";

export const CustomerAddressSchema: yup.Schema<Address> = yup.object<Address>({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  company: yup.string(),
  telephone: yup
    .string()
    .required()
    .length(8),
  roomNumber: yup.string().nullable(),
  flatOrFloor: yup.string().nullable(),
  block: yup.string().nullable(),
  building: yup.string().nullable(),
  street: yup.string().nullable(),
  district: yup.number().required(),
  area: yup.number().required(),
  region: yup.string().required(),
  isDefaultBilling: yup.boolean().required(),
  isDefaultShipping: yup.boolean().required(),
});
