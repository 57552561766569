import { Aggregation } from "../models/LayeredNavigation";
import { FilterType } from "../models/filter";

export const convertAggregationToFilter = (
  aggregation: Aggregation
): FilterType => {
  return {
    attributeCode: aggregation.attributeCode,
    title: aggregation.label || "",
    filterAttributes: aggregation.options.map(option => ({
      attributeCode: aggregation.attributeCode,
      attributeValue: option.value,
      attributeType: "text",
      count: option.count,
      title: option.label || option.value,
    })),
  };
};
