// https://stackoverflow.com/a/2450976
export function shuffle<T>(array: T[]): T[] {
  const clonedArray = array.slice();

  let currentIndex = array.length;
  let temporaryValue: T | null = null;
  let randomIndex: number = 0;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = clonedArray[currentIndex];
    clonedArray[currentIndex] = clonedArray[randomIndex];
    clonedArray[randomIndex] = temporaryValue;
  }

  return clonedArray;
}

export function filterNullOrUndefined<T>(
  maybeTs: (T | null | undefined)[]
): T[] {
  const ts: T[] = [];
  for (const maybeT of maybeTs) {
    if (maybeT != null) {
      ts.push(maybeT);
    }
  }
  return ts;
}

export function flatMap<T, U>(f: (t: T) => U[], xs: T[]): U[] {
  return xs.map(f).reduce<U[]>((prev, curr) => [...prev, ...curr], []);
}
