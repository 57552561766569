import React, { useMemo, useEffect, useRef } from "react";
import classnames from "classnames";

import { ProductLabel, ProductLabelMode } from "../../models/ProductLabel";

import parse from "../../utils/HTMLReactParser";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";

import styles from "./styles.module.scss";

interface Props {
  productLabels: ProductLabel[];
  productLabelMode: ProductLabelMode;
  className?: string;
}

const ProductLabels: React.FC<Props> = props => {
  const { productLabels, productLabelMode, className } = props;

  return (
    <div className={className}>
      {productLabels.map((productLabel, i) => (
        <ProductLabelDisplay
          key={i}
          productLabel={productLabel}
          productLabelMode={productLabelMode}
        />
      ))}
    </div>
  );
};

interface ProductLabelDisplayProps {
  productLabel: ProductLabel;
  productLabelMode: ProductLabelMode;
}

const ProductLabelDisplay: React.FC<ProductLabelDisplayProps> = props => {
  const { productLabel, productLabelMode } = props;

  const {
    image,
    name,
    position,
    size,
    style,
    labelId,
    txt,
    productId,
  } = productLabel;

  const alignSelfClassName = useMemo(() => {
    switch (position) {
      case "top-left":
      case "top-center":
      case "top-right":
        return styles.alignTop;
      case "middle-left":
      case "middle-center":
      case "middle-right":
        return styles.alignMiddle;
      case "bottom-left":
      case "bottom-center":
      case "bottom-right":
        return styles.alignBottom;
      default:
        return "";
    }
  }, [position]);

  const justifyContentClassName = useMemo(() => {
    switch (position) {
      case "top-left":
      case "middle-left":
      case "bottom-left":
        return styles.justifyLeft;
      case "top-center":
      case "middle-center":
      case "bottom-center":
        return styles.justifyCenter;
      case "top-right":
      case "middle-right":
      case "bottom-right":
        return styles.justifyRight;
      default:
        return "";
    }
  }, [position]);

  const sizeStyle = useMemo(() => {
    const res = {
      width: `${size}%`,
      height: `${size}%`,
    };
    return res;
  }, [size]);

  const textPositionClassName = useMemo(() => {
    switch (position) {
      case "top-left":
      case "middle-left":
      case "bottom-left":
        return styles.left;
      case "top-center":
      case "middle-center":
      case "bottom-center":
        return styles.center;
      case "top-right":
      case "middle-right":
      case "bottom-right":
        return styles.right;
      default:
        return "";
    }
  }, [position]);

  const customStyleSelector = `product-label-${productLabelMode}-${productId}-${labelId}`;

  useEffect(() => {
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet as CSSStyleSheet;
    if (styleSheet && style) {
      styleSheet.insertRule(`.${customStyleSelector} { ${style} }`);
    }
    return () => {
      document.head.removeChild(styleEl);
    };
  }, [style, customStyleSelector]);

  return (
    <>
      <div className={styles.container}>
        <div
          className={classnames(
            styles.productLabel,
            styles.flex,
            justifyContentClassName
          )}
        >
          <div
            className={classnames(
              styles.size,
              alignSelfClassName,
              styles.flex,
              justifyContentClassName,
              customStyleSelector
            )}
            style={sizeStyle}
          >
            <div className={classnames(styles.theLabel, alignSelfClassName)}>
              {image ? (
                <img
                  src={image}
                  alt={name || undefined}
                  className={classnames(styles.image)}
                />
              ) : null}
              {txt ? (
                <ProductLabelText
                  text={txt}
                  className={classnames(textPositionClassName, {
                    [styles.withImage]: !!image,
                  })}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductLabels);

interface ProductLabelTextProps {
  text: string;
  className: string;
}

const ProductLabelText: React.FC<ProductLabelTextProps> = React.memo(props => {
  const { text, className } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const html = useFillSrcUrlScheme(
    useExtractScriptsAndStyles(containerRef, text)
  );

  const reactElements = useMemo(() => parse(html), [html]);

  return (
    <div className={classnames(styles.text, className)} ref={containerRef}>
      {reactElements}
    </div>
  );
});
