import React, { useMemo, useCallback } from "react";
import classnames from "classnames";
import CLLink from "../navigation/CLLink";

import {
  Category,
  constructMediaUrlForCategory,
  getBackgroundColor,
} from "../../models/category";
import { StoreConfig } from "../../models/StoreConfig";

import styles from "./styles.module.scss";

export interface CategoryButtonProps {
  storeConfig?: StoreConfig;
  category: Category;
  leftLink?: string;
  rightLink?: string;
  onLeftAreaClick?: (category: Category) => any;
  onRightAreaClick?: (category: Category) => any;
}

type BackgroundStyle = React.CSSProperties;
type ThumbnailStyle = React.CSSProperties;
type IconStyle = React.CSSProperties;

const CategoryButton: React.FC<CategoryButtonProps> = React.memo(
  (props: CategoryButtonProps) => {
    const {
      storeConfig,
      category,
      leftLink,
      rightLink,
      onLeftAreaClick,
      onRightAreaClick,
    } = props;

    const {
      name,
      backgroundColor1,
      backgroundColor2,
      thumbnailUrl,
      iconUrl,
    } = category;

    const backgroundStyle = useMemo<BackgroundStyle>(
      () => ({
        background: getBackgroundColor({
          backgroundColorFrom: backgroundColor1,
          backgroundColorTo: backgroundColor2,
        }),
      }),
      [backgroundColor1, backgroundColor2]
    );

    const thumbnailStyle = useMemo<ThumbnailStyle>(
      () => ({
        backgroundImage: thumbnailUrl
          ? `url(${
              storeConfig
                ? constructMediaUrlForCategory(storeConfig, thumbnailUrl)
                : thumbnailUrl
            })`
          : undefined,
      }),
      [thumbnailUrl, storeConfig]
    );

    const iconStyle = useMemo<IconStyle>(
      () => ({
        backgroundImage: iconUrl
          ? `url(${
              storeConfig
                ? constructMediaUrlForCategory(storeConfig, iconUrl)
                : iconUrl
            })`
          : undefined,
      }),
      [iconUrl, storeConfig]
    );

    const handleLeftAreaClick = useCallback(() => {
      if (onLeftAreaClick) {
        onLeftAreaClick(category);
      }
    }, [onLeftAreaClick, category]);

    const handleRightAreaClick = useCallback(() => {
      if (onRightAreaClick) {
        onRightAreaClick(category);
      }
    }, [onRightAreaClick, category]);

    return (
      <div className={styles.button} style={backgroundStyle}>
        <div
          className={classnames(styles.icon, {
            [styles.noIcon]: !iconUrl,
          })}
          style={iconStyle}
        ></div>
        <div className={styles.categoryName}>{name}</div>
        <div className={styles.thumbnail} style={thumbnailStyle} />
        <div className={styles.clickArea}>
          {leftLink ? (
            <CLLink
              className={styles.leftClickArea}
              to={leftLink}
              onClick={handleLeftAreaClick}
            />
          ) : onLeftAreaClick ? (
            <div
              className={styles.leftClickArea}
              onClick={handleLeftAreaClick}
            />
          ) : null}
          {rightLink ? (
            <CLLink
              className={styles.rightClickArea}
              to={rightLink}
              onClick={handleRightAreaClick}
            />
          ) : onRightAreaClick ? (
            <div
              className={styles.rightClickArea}
              onClick={handleRightAreaClick}
            />
          ) : null}
        </div>
      </div>
    );
  }
);
CategoryButton.displayName = "CategoryButton";

export default CategoryButton;
