import { InViewHookResponse, useInView } from "react-intersection-observer";
import { useMemo } from "react";

const no_op = () => {};

const useInViewPolyfill =
  "IntersectionObserver" in window
    ? useInView
    : (): InViewHookResponse => {
        // dummy implementation of useInView
        return useMemo(() => [no_op, true, undefined], []);
      };

export default useInViewPolyfill;
