import React from "react";

import { LocalizedText } from "../../i18n/Localization";
import { MessageID } from "../../i18n/translations/type";

import styles from "./ProductListEmptyView.module.scss";

interface Props {
  messageID: MessageID;
}

const ProductListEmptyView: React.FC<Props> = React.memo(props => {
  const { messageID } = props;
  return (
    <div className={styles.container}>
      <div className={styles.image} />
      <p className={styles.message}>
        <LocalizedText messageID={messageID} />
      </p>
    </div>
  );
});

export default ProductListEmptyView;
