import React from "react";
import { formatMoney, Money } from "../../models/Price";
import styles from "./PriceDisplay.module.scss";

interface PriceDisplayProps {
  clubPoints: number;
  price: Money;
}

const PriceDisplay: React.FC<PriceDisplayProps> = props => {
  const { clubPoints, price } = props;

  return (
    <div className={styles.price}>
      {clubPoints > 0 && (
        <div className={styles.priceRow}>
          <div className={styles.clubpointsIcon} />
          <div className={styles.infoItemContentItem}>{clubPoints}</div>
          {price.value > 0 ? (
            <div className={styles.infoItemContentItem}>+</div>
          ) : null}
        </div>
      )}
      {price.value > 0 ? (
        <div className={styles.priceRow}>{formatMoney(price)}</div>
      ) : null}
      {clubPoints === 0 && price.value === 0 ? (
        <div className={styles.priceRow}>{formatMoney(price)}</div>
      ) : null}
    </div>
  );
};

export default PriceDisplay;
