import React, { useCallback, useMemo, useContext } from "react";
import styles from "./styles.module.scss";
import { FooterCMSLink } from "../../models/AppConfig";
import { CMSStaticBlock, makeCMSStaticBlock } from "../../models/cmsBlock";
import { NavBar, NavBarBackButton } from "../NavBar";
import CLContent from "../CLContent";
import { useRouteMatch } from "react-router-dom";
import { useAppConfig } from "../../repository/ConfigRepository";
import { FullContentLoadingView } from "../LoadingView";
import { OurNavContext } from "../../our-navigation";
import {
  getPathForFooterCMSLink,
  useCurrentTab,
} from "../../navigation/routes";
import { useIntl } from "../../i18n/Localization";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import CMSHtmlView from "../CMSBlocks/CMSHtml";

import useOpenUrlWithBrowser from "../../hook/useOpenUrlWithBrowser";
import {
  useAddStoreToUrl,
  useAddInAppToUrl,
  useAddAppBannerToUrl,
} from "../../hook/Url";

import Config from "../../Config";
import {
  LoadingModalContextProps,
  withLoadingModal,
} from "../LoadingModalProvider";

type Props = LoadingModalContextProps;

const FooterCMSLinkGroupPage: React.FC<Props> = props => {
  const {
    loadingModalContext: { withLoadingModalAsync },
  } = props;
  const { navigate } = useContext(OurNavContext);
  const tab = useCurrentTab();
  const { translate } = useIntl();
  const { isOnline } = useContext(NetworkStatusContext);

  const match = useRouteMatch<{ text: string }>();
  const text = match.params.text;

  const addStoreToUrl = useAddStoreToUrl();
  const addInAppToUrl = useAddInAppToUrl();
  const addAppBannerToUrl = useAddAppBannerToUrl();
  const openUrlWithBrowser = useOpenUrlWithBrowser();

  const appConfig = useAppConfig();
  const [links, footerCmsBlockId, errorMessage] = useMemo(() => {
    if (appConfig == null) {
      return [null, null];
    }

    const matchedLinks = appConfig.footerCMSLinks.filter(l => l.text === text);
    if (matchedLinks.length === 0) {
      return [null, translate("footer_cms_link.page_not_found")];
    }

    return [matchedLinks[0].links, matchedLinks[0].footerCmsBlockId, null];
  }, [appConfig, text, translate]);

  const onItemClick = useCallback(
    (link: FooterCMSLink) => {
      if (Config.FOOTER_CMS_LINKS_REGEX_SHOULD_OPEN_IN_APP_BROWSER) {
        const { url } = link;
        for (const regexString of Config.FOOTER_CMS_LINKS_REGEX_SHOULD_OPEN_IN_APP_BROWSER) {
          const regex = new RegExp(regexString);
          const matched = regex.exec(url);
          if (matched) {
            const url_ = addStoreToUrl(addAppBannerToUrl(addInAppToUrl(url)));
            withLoadingModalAsync(() => openUrlWithBrowser(url_));
            return;
          }
        }
      }
      navigate(getPathForFooterCMSLink(tab, text, link.text));
    },
    [
      navigate,
      tab,
      text,
      addStoreToUrl,
      addInAppToUrl,
      addAppBannerToUrl,
      openUrlWithBrowser,
      withLoadingModalAsync,
    ]
  );

  const cmsStaticBlock = useMemo<CMSStaticBlock | null>(
    () => makeCMSStaticBlock([footerCmsBlockId]),
    [footerCmsBlockId]
  );

  return (
    <>
      <NavBar headerLeft={<NavBarBackButton />} headerTitle={text} />
      <CLContent className={styles.ionContent}>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          {errorMessage != null ? (
            <div className={styles.errorContainer}>
              <div className={styles.errorIcon} />
              <div className={styles.errorMessage}> {errorMessage}</div>
            </div>
          ) : links == null ? (
            <FullContentLoadingView />
          ) : (
            links.map(link => (
              <Item
                key={`${link.text}${link.url}`}
                link={link}
                onClick={onItemClick}
              />
            ))
          )}
          {cmsStaticBlock ? (
            <div className={styles.footer}>
              <CMSHtmlView cmsStaticBlock={cmsStaticBlock} />
            </div>
          ) : null}
        </NoInternetConnectionView>
      </CLContent>
    </>
  );
};

const Item: React.FC<{
  link: FooterCMSLink;
  onClick: (link: FooterCMSLink) => void;
}> = props => {
  const { link, onClick } = props;
  const onClick_ = useCallback(() => {
    onClick(link);
  }, [onClick, link]);
  return (
    <div className={styles.row} onClick={onClick_}>
      {link.text}
      <div className={styles.rowArrow} />
    </div>
  );
};

export default withLoadingModal(FooterCMSLinkGroupPage);
