export interface AggregationOption {
  count: number;
  label: string | null;
  value: string;
}

export interface Aggregation {
  attributeCode: string;
  count: number;
  label: string | null;
  options: AggregationOption[];
}

export const AggregationGraphQLAttributes = `
aggregations {
  attributeCode: attribute_code
  count
  label
  options {
    count
    label
    value
  }
}
`;
