import React, { useState, useCallback, useContext, useEffect } from "react";
import { RefresherEventDetail } from "@ionic/core";

import CMSBlockList from "../CMSBlocks/CMSBlockList";
import { FullContentLoadingView } from "../LoadingView";

import {
  getPathForProductDetailPage,
  useCurrentTab,
} from "../../navigation/routes";

import { CMS_PAGE_TYPE } from "../../models/cmsBlock";
import { ProductOverview } from "../../models/ProductOverview";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { getFormInitialState } from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";
import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { WishlistContext } from "../WishlistProvider";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { ProductDetailPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { usePresentAddToCartModal } from "../../hook/usePresentAddToCartModal";

import useViewModel from "./viewModel";

export interface OwnProps {
  pageType: CMS_PAGE_TYPE;
  allowRefresh: boolean;
  didEnter: boolean;
  additionalRefreshRequest?: () => Promise<unknown>;
}

type Props = OwnProps;

const CMSPage: React.FC<Props> = props => {
  const { pageType, allowRefresh, didEnter, additionalRefreshRequest } = props;
  const currentTab = useCurrentTab();
  const { isOnline } = useContext(NetworkStatusContext);

  const {
    cmsPageContent,
    refresh: refreshViewModel,
    isFullPageLoading,
  } = useViewModel(pageType);

  const [forceRerenderKey, setForceRerenderKey] = useState(0);

  useEffect(() => {
    if (didEnter) {
      refreshViewModel();
    }
  }, [didEnter, refreshViewModel]);

  const onRetry = useCallback(() => {
    refreshViewModel();
  }, [refreshViewModel]);

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      try {
        await Promise.all([
          refreshViewModel(),
          Promise.resolve().then(additionalRefreshRequest),
        ]);
        setForceRerenderKey(k => k + 1);
      } finally {
        e.detail.complete();
      }
    },
    [refreshViewModel, additionalRefreshRequest]
  );

  const hrefForProduct = useCallback(
    (productOverview: ProductOverview) => {
      return getPathForProductDetailPage(currentTab, productOverview.sku);
    },
    [currentTab]
  );

  const presentAddToCartModal = usePresentAddToCartModal();
  const openPurchaseProductModal = React.useCallback(
    (product: ProductOverview) => {
      presentAddToCartModal(product.sku, getFormInitialState());
    },
    [presentAddToCartModal]
  );

  const { toggleProductFromWishlist } = useContext(WishlistContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const onClickLikeButton = useCallback(
    (productOverview: ProductOverview) => {
      toggleProductFromWishlist(productOverview.sku, () => presentLoginModal());
    },
    [toggleProductFromWishlist, presentLoginModal]
  );

  const onClickProductBlock = useCallback(
    (productOverview: ProductOverview) => {
      addPerformanceRecord(
        ProductDetailPageSession(productOverview.sku),
        "Click on product block"
      );
    },
    []
  );

  const allowRefreshCMSList = allowRefresh && cmsPageContent != null;

  return (
    <>
      {allowRefreshCMSList && (
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent />
        </IonRefresher>
      )}
      <NoInternetConnectionView
        isOnline={isOnline}
        onRetry={onRetry}
        hasData={isFullPageLoading || cmsPageContent != null}
      >
        {isFullPageLoading ? (
          <FullContentLoadingView />
        ) : cmsPageContent != null ? (
          <CMSBlockList
            cmsBlocks={cmsPageContent.items}
            currentTab={currentTab}
            forceRerenderKey={forceRerenderKey}
            hrefForProduct={hrefForProduct}
            onAddToCart={openPurchaseProductModal}
            onClickLikeButton={onClickLikeButton}
            onClickProductBlock={onClickProductBlock}
          />
        ) : null}
      </NoInternetConnectionView>
    </>
  );
};

export default CMSPage;
