import { PushNotification } from "@capacitor/core";

interface AdobeExperiencePlatformPushNotificationData {
  _dId: string;
  _mId: string;
  show_notification?: boolean | null;
  notification_title?: string | null;
  notification_body?: string | null;
  notification_foreground?: string | null;
  [otherField: string]: any;
}

interface AdobeExperiencePlatformPushNotification extends PushNotification {
  data: AdobeExperiencePlatformPushNotificationData;
}

export function isAdobeExperienceNotification(
  notification: PushNotification
): notification is AdobeExperiencePlatformPushNotification {
  const { data } = notification;
  return data && data._dId && data._mId;
}

export function convertToNotificationMessage(
  aepPushNotification: AdobeExperiencePlatformPushNotification
): PushNotification {
  const title = aepPushNotification.data.notification_title;
  const body = aepPushNotification.data.notification_body;
  return {
    ...aepPushNotification,
    title: title || undefined,
    body: body || undefined,
  };
}
