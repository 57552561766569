import React, {
  useMemo,
  useCallback,
  useRef,
  useContext,
  useState,
} from "react";
import { useRouteMatch } from "react-router-dom";
import formatDate from "date-fns/format";

import { NavBar, NavBarBackButton, NavBarShareButton } from "../NavBar";
import CLContent from "../CLContent";
import CLImage from "../CLImage";
import { FullContentLoadingView } from "../LoadingView";
import { LocalizedText, useIntl } from "../../i18n/Localization";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import ShareModal from "../ShareModal/lazy";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";

import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import {
  Article,
  ArticleCategory,
  ArticleTag,
  ArticleAuthor,
  ArticleFilter,
  getAuthorDisplayName,
} from "../../models/Article";
import { getResources } from "../../models/ResourcesRequestState";
import { socialShare, ShareChannel } from "../../utils/SocialShare";
import isValidHTMLTag from "../../utils/IsValidHTMLTag";

import { useFetchArticleByID } from "../../repository/ArticleRepository";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import { actionEvent, pageView } from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { useFillSrcUrlScheme } from "../../utils/FillSrcUrlScheme";
import parse from "../../utils/HTMLReactParser";

import styles from "./ArticleDetailPage.module.scss";
import {
  getPathForFilteredArticleListPage,
  useCurrentTab,
} from "../../navigation/routes";
import { OurNavContext } from "../../our-navigation";

import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";
import useViewEnterForAsyncContent from "../../hook/useViewEnterForAsyncContent";

type ViewState = ViewStateLoading | ViewStateDisplay;

interface ViewStateLoading {
  type: "loading";
}
function ViewStateLoading(): ViewStateLoading {
  return {
    type: "loading",
  };
}

interface ViewStateDisplay {
  type: "display";
  article: Article;
}
function ViewStateDisplay(article: Article): ViewStateDisplay {
  return {
    type: "display",
    article,
  };
}

const ArticleDetailPage: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const articleID = match.params.id;
  const currentTab = useCurrentTab();
  const { navigate } = useContext(OurNavContext);
  const { isOnline } = useContext(NetworkStatusContext);

  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  useScrollToHideTabBar(ionContentRef, ionLifeCycleContext);

  const {
    requestState: articleRequestState,
    retry: retryFetchArticle,
  } = useFetchArticleByID(articleID);
  const article = getResources(articleRequestState);

  const viewEnter = useCallback(() => {
    if (!article) {
      return;
    }
    const { title } = article;
    pageView({ page: "Article Page", articleTitle: title });
  }, [article]);

  useViewEnterForAsyncContent(article, ionLifeCycleContext, viewEnter);

  const gotoFilteredArticleListPage = useCallback(
    (type: ArticleFilter, id: string) => {
      navigate(getPathForFilteredArticleListPage(currentTab, type, id));
    },
    [navigate, currentTab]
  );
  const onClickCategory = useCallback(
    (category: ArticleCategory) => {
      gotoFilteredArticleListPage("category", category.categoryId);
    },
    [gotoFilteredArticleListPage]
  );
  const onClickTag = useCallback(
    (tag: ArticleTag) => {
      gotoFilteredArticleListPage("tag", tag.tagId);
    },
    [gotoFilteredArticleListPage]
  );
  const onClickAuthor = useCallback(
    (author: ArticleAuthor) => {
      if (author.authorId != null) {
        gotoFilteredArticleListPage("author", author.authorId);
      }
    },
    [gotoFilteredArticleListPage]
  );

  const viewState = useMemo<ViewState>(() => {
    if (article == null) {
      return ViewStateLoading();
    }
    return ViewStateDisplay(article);
  }, [article]);

  const [shareModalOpened, setShareModalOpened] = useState(false);
  const handleShareButtonClick = useCallback(() => {
    setShareModalOpened(true);
  }, []);

  const handleShareModalDismiss = useCallback(() => {
    setShareModalOpened(false);
  }, []);

  const { translate } = useIntl();
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const onClickShare = useCallback(
    async (shareChannel: ShareChannel) => {
      if (!article) {
        return;
      }
      try {
        const message = article.title;
        const shareMessengerString = translate(
          "page.article_detail.share.message",
          {
            ARTICLE_TITLE: article.title,
            ARTICLE_URL: article.postUrl,
          }
        );
        await socialShare(
          shareChannel,
          undefined,
          message,
          shareMessengerString,
          article.postUrl
        );
        actionEvent(
          "Article Page",
          "Click",
          `Share With Friends_${shareChannel}`
        );
      } catch (e) {
        if (e !== "cancelled") {
          presentLocalizedAlert({
            headerId: "page.article_detail.alert.share_error",
            buttons: [{ textMessageID: "alert.button.ok" }],
          });
        }
      }
    },
    [article, translate, presentLocalizedAlert]
  );

  const retry = useCallback(() => {
    retryFetchArticle();
  }, [retryFetchArticle]);

  return (
    <>
      <NavBar
        headerLeft={<NavBarBackButton />}
        headerTitle={<>{article != null ? article.title : null}</>}
        headerRight={<NavBarShareButton onClick={handleShareButtonClick} />}
      />
      <CLContent ref={ionContentRef}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={viewState.type === "display"}
          onRetry={retry}
        >
          {viewState.type === "loading" && <FullContentLoadingView />}
          {viewState.type === "display" && (
            <ArticleContent
              article={viewState.article}
              onClickCategory={onClickCategory}
              onClickTag={onClickTag}
              onClickAuthor={onClickAuthor}
            />
          )}
        </NoInternetConnectionView>
      </CLContent>
      <ShareModal
        onClickShare={onClickShare}
        isOpen={shareModalOpened}
        onRequestDismiss={handleShareModalDismiss}
      />
    </>
  );
};

export default ArticleDetailPage;

const ArticleContent: React.FC<{
  article: Article;

  onClickCategory: (category: ArticleCategory) => void;
  onClickAuthor: (author: ArticleAuthor) => void;
  onClickTag: (tag: ArticleTag) => void;
}> = React.memo(props => {
  const { article, onClickCategory, onClickTag, onClickAuthor } = props;
  const { author, tags, categories } = article;
  const containerRef = useRef<HTMLDivElement>(null);

  const html = useFillSrcUrlScheme(
    useExtractScriptsAndStyles(containerRef, article.filteredContent)
  );

  const articleContent = useMemo(() => {
    return parse(html, {
      replace: domNode => {
        if (
          domNode.type === "tag" &&
          domNode.name &&
          !isValidHTMLTag(domNode.name)
        ) {
          return <></>;
        }
        if (domNode.type === "tag" && domNode.name === "img") {
          const { attribs } = domNode;
          return <CLImage htmlAttributes={attribs} />;
        }
        return undefined;
      },
    });
  }, [html]);

  useRegisterPublishDeepLinkOnHTMLContent(containerRef, [html]);

  return (
    <>
      <h1 className={styles.articleTitle}>{article.title}</h1>
      <div className={styles.articleInfoContainer}>
        <div className={styles.articleInfo}>
          <LocalizedText
            messageID="page.article_detail.post_date"
            messageArgs={{
              PUBLISHED_DATE: formatDate(article.publishTime, "YYYY-MM-DD"),
            }}
          />
        </div>
        {categories.length > 0 && (
          <div className={styles.articleInfo}>
            <LocalizedText
              messageID="page.article_detail.categories"
              messageArgs={{
                CATEGORY_LIST_ELEMENTS: categories.map(category => (
                  <ArticleCategoryInfoAnchor
                    key={category.categoryId}
                    category={category}
                    onClickCategory={onClickCategory}
                  />
                )),
              }}
            />
          </div>
        )}
        {tags.length > 0 && (
          <div className={styles.articleInfo}>
            <LocalizedText
              messageID="page.article_detail.tags"
              messageArgs={{
                TAG_LIST_ELEMENTS: tags.map(tag => (
                  <ArticleTagInfoAnchor
                    key={tag.tagId}
                    tag={tag}
                    onClickTag={onClickTag}
                  />
                )),
              }}
            />
          </div>
        )}
        <div className={styles.articleInfo}>
          <LocalizedText
            messageID="page.article_detail.author"
            messageArgs={{
              AUTHOR_ELEMENT: (
                <ArticleAuthorInfoAnchor
                  author={author}
                  onClickAuthor={onClickAuthor}
                />
              ),
            }}
          />
        </div>
      </div>
      <div ref={containerRef} className={styles.contentContainer}>
        {articleContent}
      </div>
      <TabBarSpacePlaceholder />
    </>
  );
});

const ArticleCategoryInfoAnchor: React.FC<{
  category: ArticleCategory;
  onClickCategory: (category: ArticleCategory) => void;
}> = React.memo(props => {
  const { category, onClickCategory } = props;
  const onClick = useCallback(() => {
    onClickCategory(category);
  }, [category, onClickCategory]);
  return <ArticleInfoAnchor name={category.title} onClick={onClick} />;
});

const ArticleTagInfoAnchor: React.FC<{
  tag: ArticleTag;
  onClickTag: (tag: ArticleTag) => void;
}> = React.memo(props => {
  const { tag, onClickTag } = props;
  const onClick = useCallback(() => {
    onClickTag(tag);
  }, [tag, onClickTag]);
  return <ArticleInfoAnchor name={tag.title} onClick={onClick} />;
});

const ArticleAuthorInfoAnchor: React.FC<{
  author: ArticleAuthor;
  onClickAuthor: (author: ArticleAuthor) => void;
}> = React.memo(props => {
  const { author, onClickAuthor } = props;
  const onClick = useCallback(() => {
    onClickAuthor(author);
  }, [author, onClickAuthor]);

  const authorDisplayName = useMemo(() => getAuthorDisplayName(author), [
    author,
  ]);

  return authorDisplayName != null ? (
    <ArticleInfoAnchor name={authorDisplayName} onClick={onClick} />
  ) : (
    <>-</>
  );
});

const ArticleInfoAnchor: React.FC<{
  name: string;
  onClick: () => void;
}> = props => {
  const { name, onClick } = props;
  const onClick_ = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );
  return (
    <span className={styles.articleInfoAnchor} onClick={onClick_}>
      {name}
    </span>
  );
};
