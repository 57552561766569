import { useApolloClient } from "@apollo/react-hooks";

import { useFetchResources_v2 } from "../../repository/Hooks";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";

import { subscribeToRestockAlert } from "./api";
import Context from "./context";
import { useContext } from "react";

export function useRequestSubscribeToRestockAlert(): [
  ResourcesRequestState<boolean>,
  (productId: number) => Promise<boolean>
] {
  const client = useApolloClient();
  const context = useContext(Context);
  const [requestState, { call: fetch }] = useFetchResources_v2<
    boolean,
    (productId: number) => Promise<boolean>
  >({
    remoteResourcesProvider: async (productId: number) => {
      const result = await subscribeToRestockAlert(client, productId);
      if (result) {
        context.subscribe(productId);
      }
      return result;
    },
  });

  return [requestState, fetch];
}
