import React, { useMemo } from "react";
import cn from "classnames";

import { ClubPointJourneyPoint } from "../../models/ClubPoint";
import { LocalizedText } from "../../i18n/Localization";

import styles from "./styles.module.scss";

interface ClubPointJourneyRowProps {
  clubPointJourneyPoint: ClubPointJourneyPoint;
  active: boolean;
}

const ClubPointJourneyRow: React.FC<ClubPointJourneyRowProps> = props => {
  const { clubPointJourneyPoint, active } = props;

  const pointTextMessageArgs = useMemo(
    () => ({
      point: clubPointJourneyPoint.value,
    }),
    [clubPointJourneyPoint]
  );

  return (
    <div className={styles.row}>
      <div className={cn(styles.point, { [styles.active]: active })} />
      <div className={styles.name}>{clubPointJourneyPoint.name}</div>
      <div className={styles.value}>
        <LocalizedText
          messageID="club_point_journey.point"
          messageArgs={pointTextMessageArgs}
        />
      </div>
    </div>
  );
};

interface Props {
  clubPointJourneyPoints: ClubPointJourneyPoint[];
  currentPoint: number;
}

const ClubPointJourney: React.FC<Props> = props => {
  const { clubPointJourneyPoints, currentPoint } = props;

  const sortedClubPointJourneyPoints = useMemo(
    () => clubPointJourneyPoints.slice().sort((a, b) => a.value - b.value),
    [clubPointJourneyPoints]
  );

  return (
    <div>
      <div className={styles.title}>
        <LocalizedText messageID="club_point_journey.title" />
      </div>
      <div className={styles.container}>
        {sortedClubPointJourneyPoints.map((clubPointJourneyPoint, index) => {
          return (
            <ClubPointJourneyRow
              clubPointJourneyPoint={clubPointJourneyPoint}
              key={index}
              active={currentPoint <= clubPointJourneyPoint.value}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ClubPointJourney;
