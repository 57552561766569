declare module "@capacitor/core" {
  interface PluginRegistry {
    AdobeExperiencePlatform: AdobeExperiencePlatformPlugin;
  }
}

export enum CollectMessageInfoAction {
  OPEN_APP = "1",
  CLICK = "2",
}

export interface PiiAttributes {
  cusMembershipID: string;
  systemLanguage: string;
}

export interface AdobeExperiencePlatformPlugin {
  initialize(options: { appId: string }): Promise<void>;
  configureWithAppId(options: { appId: string }): Promise<void>;
  setPushIdentifier(options: { deviceToken: string | null }): Promise<void>;
  lifecycleStart(): Promise<void>;
  lifecyclePause(): Promise<void>;
  collectMessageInfo(options: {
    broadlogId: string;
    deliveryId: string;
    action: CollectMessageInfoAction;
  }): Promise<void>;
  collectPii(options: { attributes: PiiAttributes }): Promise<void>;
}
