import { ApolloClient, FetchPolicy, useApolloClient } from "@apollo/client";
import { useContext } from "react";
import {
  fetchAggregation,
  fetchProductOverviewsFromCategoryListByCategoryId,
  fetchSortFields,
} from "../../api/GraphQL";
import { fetchProductLabelsByProductIds } from "../../api/ProductLabel";
import { fetchProductSaleBundlesForProductSKUOnly } from "../../api/ProductSaleBundle";
import Config from "../../Config";
import ProductAttributeFilterContext from "../../contexts/ProductAttributeFilterContext";
import { Locale } from "../../i18n/locale";
import { useIntl } from "../../i18n/Localization";
import { CategoryTree, getCategoryCMSPageId } from "../../models/category";
import { ResolvedHTMLBasedCMSPageContent } from "../../models/cmsBlock";
import {
  FilterAttribute,
  FilterInputField,
  getFilterAttributeMapsFromFilters,
  makeGraphQLFilterFromParamsMap,
  ProductFilterInfo,
  RangeFilterAttribute,
  SortFields,
} from "../../models/filter";
import { ModelKeys } from "../../models/product";
import { ProductLabel } from "../../models/ProductLabel";
import { ProductOverview } from "../../models/ProductOverview";
import { ProductSaleBundle } from "../../models/ProductSaleBundle";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { fetchCategoryPageContent } from "../../repository/CMSPageContentRepository";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { convertAggregationToFilter } from "../../utils/Filter";
import { profileAsyncAction } from "../../utils/performance";
import { CategoryPageSession } from "../../utils/PerformanceRecordStore/sessions";
import { IndexMap } from "../../utils/type";

export interface SingleCategoryPageResource {
  singleLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute;
  };
  multiLevelLevelFilterAttributeMap: {
    [attributeCode in string]: FilterAttribute[];
  };
  rangeFilterAttributeMap: {
    [attributeCode in string]: RangeFilterAttribute;
  };
  sortFields: SortFields | null;
  resolvedCategoryPageContent: ResolvedHTMLBasedCMSPageContent | null;
}

async function fetchSingleCategoryPageResources(
  client: ApolloClient<any>,
  locale: Locale,
  productAttributeFilterFields: string[],
  productAttributeFilterInputMap: IndexMap<string, FilterInputField>,
  categoryTree: CategoryTree,
  queryParams: { [key in string]: string },
  fetchPolicy: FetchPolicy
): Promise<SingleCategoryPageResource> {
  const categoryId = categoryTree.id;
  const fixedFilter = makeGraphQLFilterFromParamsMap(
    { category_id: `${categoryId}` },
    productAttributeFilterFields,
    productAttributeFilterInputMap
  );
  const queryFilter = makeGraphQLFilterFromParamsMap(
    queryParams,
    productAttributeFilterFields,
    productAttributeFilterInputMap
  );
  const filter = { ...fixedFilter, ...queryFilter };
  const aggregations = await profileAsyncAction(
    CategoryPageSession(categoryTree.id),
    `Load filters from ${fetchPolicy === "cache-only" ? "Cache" : "Network"}`,
    () => fetchAggregation(client, "", filter, locale, fetchPolicy)
  );
  const filterTypes = aggregations
    ? aggregations.map(convertAggregationToFilter)
    : [];
  const {
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
  } = getFilterAttributeMapsFromFilters(
    filterTypes,
    queryParams,
    productAttributeFilterInputMap
  );

  const sortFields = await profileAsyncAction(
    CategoryPageSession(categoryTree.id),
    `Load sort fields from ${
      fetchPolicy === "cache-only" ? "Cache" : "Network"
    }`,
    () => fetchSortFields(client, "", filter, locale, fetchPolicy)
  );

  const categoryCMSPageId = getCategoryCMSPageId(categoryTree);
  const categoryPageContent = categoryCMSPageId
    ? await profileAsyncAction(
        CategoryPageSession(categoryTree.id),
        `Load Load CMS Page from ${
          fetchPolicy === "cache-only" ? "Cache" : "Network"
        }`,
        async () => {
          return fetchCategoryPageContent(
            client,
            categoryCMSPageId,
            locale,
            fetchPolicy
          );
        }
      )
    : null;

  return {
    singleLevelFilterAttributeMap,
    multiLevelLevelFilterAttributeMap,
    rangeFilterAttributeMap,
    sortFields,
    resolvedCategoryPageContent: categoryPageContent,
  };
}

export function useSingleCategoryPageResource(
  categoryTree: CategoryTree,
  queryParams: { [key in string]: string }
): [
  ResourcesRequestState<SingleCategoryPageResource>,
  () => Promise<SingleCategoryPageResource>,
  () => Promise<SingleCategoryPageResource>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const {
    productAttributeFilterFields,
    productAttributeFilterInputMap,
  } = useContext(ProductAttributeFilterContext);

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    SingleCategoryPageResource,
    () => Promise<SingleCategoryPageResource>
  >({
    localCacheProvider: async () =>
      fetchSingleCategoryPageResources(
        client,
        locale,
        productAttributeFilterFields,
        productAttributeFilterInputMap,
        categoryTree,
        queryParams,
        "cache-only"
      ),
    remoteResourcesProvider: async () =>
      fetchSingleCategoryPageResources(
        client,
        locale,
        productAttributeFilterFields,
        productAttributeFilterInputMap,
        categoryTree,
        queryParams,
        "network-only"
      ),
  });

  return [requestState, fetch, refresh];
}

interface SingleCategoryPageProductListResources {
  productOverviews: ProductOverview[];
  hasMore: boolean;
  currentPage: number;
  pageSize: number;

  productIdLabelMap: {
    [key in number]: ProductLabel[];
  };
  productIdBundleMap: {
    [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
  };
}

export function useSingleCategoryPageProductListResources(
  categoryTree: CategoryTree
): [
  ResourcesRequestState<SingleCategoryPageProductListResources | null>,
  (
    page: number,
    productFilterinfo: ProductFilterInfo
  ) => Promise<SingleCategoryPageProductListResources | null>,
  (
    page: number,
    productFilterinfo: ProductFilterInfo
  ) => Promise<SingleCategoryPageProductListResources | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { productAttributeFilterInputMap } = useContext(
    ProductAttributeFilterContext
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    SingleCategoryPageProductListResources | null,
    (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => Promise<SingleCategoryPageProductListResources | null>
  >({
    localCacheProvider: async (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => {
      const productOverviews = await profileAsyncAction(
        CategoryPageSession(categoryTree.id),
        "Load Product Overviews from Network",
        () =>
          fetchProductOverviewsFromCategoryListByCategoryId(
            client,
            categoryTree.id,
            page,
            productFilterInfo,
            productAttributeFilterInputMap,
            locale,
            "cache-only"
          )
      );
      if (!productOverviews) {
        return null;
      }
      const productIdLabelMap: { [key in number]: ProductLabel[] } = {};
      const productIdBundleMap: {
        [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
      } = {};
      if (productOverviews.productOverviews.length > 0) {
        const productIds = productOverviews.productOverviews.map(
          productOverview => productOverview.id
        );
        const [productLabelsByProductIds, bundles] = await Promise.all([
          profileAsyncAction(
            CategoryPageSession(categoryTree.id),
            "Load product labels from cache",
            () =>
              fetchProductLabelsByProductIds(
                client,
                productIds,
                "category",
                locale,
                "cache-only"
              ).catch(() => [])
          ),
          Config.ENABLE_BUNDLE_SALE &&
          Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
            ? fetchProductSaleBundlesForProductSKUOnly(
                client,
                productIds,
                locale,
                "cache-only"
              ).catch(() => [])
            : Promise.resolve([]),
        ]);
        for (const productLabelsByProductId of productLabelsByProductIds) {
          productIdLabelMap[productLabelsByProductId.productId] =
            productLabelsByProductId.productLabels;
        }

        for (const bundle of bundles) {
          if (bundle.mainProduct) {
            productIdBundleMap[bundle.mainProduct.id] = bundle;
          }
        }
      }
      return {
        ...productOverviews,
        currentPage: page,
        productIdLabelMap,
        productIdBundleMap,
      };
    },
    remoteResourcesProvider: async (
      page: number,
      productFilterInfo: ProductFilterInfo
    ) => {
      const productOverviews = await profileAsyncAction(
        CategoryPageSession(categoryTree.id),
        "Load Product Overviews from network",
        () =>
          fetchProductOverviewsFromCategoryListByCategoryId(
            client,
            categoryTree.id,
            page,
            productFilterInfo,
            productAttributeFilterInputMap,
            locale,
            "network-only"
          )
      );
      if (!productOverviews) {
        return null;
      }
      const productIdLabelMap: { [key in number]: ProductLabel[] } = {};
      const productIdBundleMap: {
        [key in ModelKeys["id"]]: ProductSaleBundle<ModelKeys>;
      } = {};
      if (productOverviews.productOverviews.length > 0) {
        const productIds = productOverviews.productOverviews.map(
          productOverview => productOverview.id
        );
        const [productLabelsByProductIds, bundles] = await Promise.all([
          profileAsyncAction(
            CategoryPageSession(categoryTree.id),
            "Load product labels from network",
            () =>
              fetchProductLabelsByProductIds(
                client,
                productIds,
                "category",
                locale,
                "network-only"
              ).catch(() => [])
          ),
          Config.ENABLE_BUNDLE_SALE &&
          Config.ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST
            ? fetchProductSaleBundlesForProductSKUOnly(
                client,
                productIds,
                locale,
                "cache-only"
              ).catch(() => [])
            : Promise.resolve([]),
        ]);
        for (const productLabelsByProductId of productLabelsByProductIds) {
          productIdLabelMap[productLabelsByProductId.productId] =
            productLabelsByProductId.productLabels;
        }

        for (const bundle of bundles) {
          if (bundle.mainProduct) {
            productIdBundleMap[bundle.mainProduct.id] = bundle;
          }
        }
      }
      return {
        ...productOverviews,
        currentPage: page,
        productIdLabelMap,
        productIdBundleMap,
      };
    },
  });

  return [requestState, fetch, refresh];
}
