import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
}

const Product360Badge: React.FC<Props> = props => {
  return <span className={cn(styles.badge, props.className)} />;
};

export default Product360Badge;
