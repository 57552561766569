import React, {
  useCallback,
  useMemo,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";
import { RefresherEventDetail } from "@ionic/core";
import { IonRefresher, IonRefresherContent, IonIcon } from "@ionic/react";
import formatDate from "date-fns/format";
import cn from "classnames";

import * as Storage from "../../storage";
import {
  Message,
  ReadState,
  getLinkFromOPNSMessage,
  doesMessageHaveFurtherAction,
  MessageType,
} from "../../models/OPNSPushNotification";
import {
  isRequestLoading,
  isRequestError,
  getResources,
} from "../../models/ResourcesRequestState";
import { PaginationInfo } from "../../repository/State";
import { PresentationContext } from "../../our-navigation";
import { useIonContentScrollEl } from "../../utils/ionContentScrollEl";
import {
  appEventEmitter,
  AppEventPushNotificationGoTo,
} from "../../utils/SimpleEventEmitter";
import Config from "../../Config";
import { LocalizedText } from "../../i18n/Localization";

import CLContent from "../CLContent";
import CLInfiniteScroll from "../CLInfiniteScroll";
import ModalHeader from "../ModalHeader";
import { LoadingView, FullContentLoadingView } from "../LoadingView";
import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { PushNotificationContext } from "../PushNotificationProvider";

import {
  useFetchPushNotificationMessagesV2,
  useMessagesMarkReadV2,
} from "./repository";

import styles from "./styles.module.scss";

const PushNotificationMessagesPage: React.FC = () => {
  return (
    <>
      {Config.USE_PUSH ? (
        <PushNotificationMessagesPageUsed />
      ) : (
        <PushNotificationMessagesPageNotUsed />
      )}
    </>
  );
};

export default PushNotificationMessagesPage;

const PushNotificationMessagesPageUsed: React.FC = () => {
  const pushNotificationContext = useContext(PushNotificationContext);

  return (
    <PushNotificationMessagesPageReady
      pushNotificationContext={pushNotificationContext}
    />
  );
};

interface ViewStateLoading {
  type: "loading";
}

const ViewStateLoading: ViewStateLoading = {
  type: "loading",
};

interface ViewStateDisplay {
  type: "display";
  paginationInfo: PaginationInfo<Message>;
}

function ViewStateDisplay(
  paginationInfo: PaginationInfo<Message>
): ViewStateDisplay {
  return {
    type: "display",
    paginationInfo,
  };
}

interface ViewStateEmpty {
  type: "empty";
}

const ViewStateEmpty: ViewStateEmpty = {
  type: "empty",
};

interface ViewStateError {
  type: "error";
}

const ViewStateError: ViewStateError = {
  type: "error",
};

type ViewState =
  | ViewStateLoading
  | ViewStateDisplay
  | ViewStateEmpty
  | ViewStateError;

interface PushNotificationMessagesPageReadyProps {
  pushNotificationContext: PushNotificationContext;
}

const PushNotificationMessagesPageReady: React.FC<
  PushNotificationMessagesPageReadyProps
> = props => {
  const {
    pushNotificationContext: {
      messageMarkRead: contextMessageMarkRead,
      updateMessages,
      getMessagesByIds,
      setUnreadMessageCount,
    },
  } = props;

  const { isOnline } = useContext(NetworkStatusContext);
  const presentationContext = useContext(PresentationContext);

  const onRequestDismiss = React.useCallback(() => {
    presentationContext.dismiss();
  }, [presentationContext]);

  const contentRef = useRef<HTMLIonContentElement>(null);

  const {
    requestState,
    paginationInfo,
    fetchNext,
    refresh,
  } = useFetchPushNotificationMessagesV2();

  useEffect(() => {
    const messages = getResources(requestState);
    if (messages) {
      updateMessages(messages);
    }
  }, [requestState, updateMessages]);

  useEffect(() => {
    setUnreadMessageCount(0);
    Storage.setPushNotificationMessagesPageLastRead(new Date());
  }, [setUnreadMessageCount]);

  const messagesMarkRead = useMessagesMarkReadV2();

  const viewState = useMemo<ViewState>(() => {
    if (paginationInfo == null || paginationInfo.items.length === 0) {
      if (isRequestLoading(requestState)) {
        return ViewStateLoading;
      } else if (isRequestError(requestState)) {
        return ViewStateError;
      }
      return ViewStateEmpty;
    }
    const messageIds = paginationInfo.items.map(m => m.id);
    const messages = getMessagesByIds(messageIds);
    return ViewStateDisplay({
      ...paginationInfo,
      items: messages,
    });
  }, [paginationInfo, getMessagesByIds, requestState]);

  const autoMarkMessagesRead = useCallback(
    async (messages: Message[]) => {
      const messagesShouldMarkReadAutomatically = messages.filter(
        message =>
          !doesMessageHaveFurtherAction(message) &&
          message.read === ReadState.New
      );
      const messageIds = messagesShouldMarkReadAutomatically.map(
        message => message.id
      );
      if (messageIds.length > 0) {
        for (const messageId of messageIds) {
          contextMessageMarkRead(messageId);
        }
        await messagesMarkRead(messageIds);
      }
    },
    [contextMessageMarkRead, messagesMarkRead]
  );

  useEffect(() => {
    if (viewState.type === "display") {
      autoMarkMessagesRead(viewState.paginationInfo.items);
    }
  }, [viewState, autoMarkMessagesRead]);

  const onClickMessage = useCallback(
    async (message: Message) => {
      const link = getLinkFromOPNSMessage(message);
      if (link) {
        presentationContext.dismiss(() => {
          appEventEmitter.publish(AppEventPushNotificationGoTo(link));
        });
        if (message.read === ReadState.New) {
          await messagesMarkRead([message.id]);
          contextMessageMarkRead(message.id);
        }
      }
    },
    [messagesMarkRead, contextMessageMarkRead, presentationContext]
  );

  const [refresherEventDetail, setRefresherEventDetail] = useState<CustomEvent<
    RefresherEventDetail
  > | null>(null);
  const onRefresh = useCallback(
    (e: CustomEvent<RefresherEventDetail>) => {
      refresh();
      setRefresherEventDetail(e);
    },
    [refresh]
  );

  useEffect(() => {
    if (refresherEventDetail != null && !isRequestLoading(requestState)) {
      refresherEventDetail.detail.complete();
      setRefresherEventDetail(null);
    }
  }, [refresherEventDetail, requestState]);

  const onEndReach = useCallback(() => {
    fetchNext();
  }, [fetchNext]);

  const retry = useCallback(() => {
    refresh();
  }, [refresh]);

  return (
    <div className={cn("ion-page", styles.container)}>
      <ModalHeader
        messageID="push_notification_messages.title"
        disabled={false}
        onModalDismiss={onRequestDismiss}
        hasDismissButton={true}
      />
      <CLContent ref={contentRef} className={styles.content}>
        <NoInternetConnectionView
          isOnline={isOnline}
          hasData={true}
          onRetry={retry}
        >
          {viewState.type === "loading" && <FullContentLoadingView />}
          {viewState.type === "display" ||
          viewState.type === "empty" ||
          viewState.type === "error" ? (
            <List
              ionContentRef={contentRef}
              onClickMessage={onClickMessage}
              onRefresh={onRefresh}
              onEndReach={onEndReach}
              viewState={viewState}
            />
          ) : null}
        </NoInternetConnectionView>
      </CLContent>
    </div>
  );
};

interface ListProps {
  ionContentRef: React.RefObject<HTMLIonContentElement>;
  onClickMessage: (message: Message, index: number) => void;
  onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
  onEndReach: () => void;
  viewState: ViewStateDisplay | ViewStateEmpty | ViewStateError;
}

const List: React.FC<ListProps> = props => {
  const {
    ionContentRef,
    onClickMessage,
    onRefresh,
    onEndReach,
    viewState,
  } = props;
  const scrollElement = useIonContentScrollEl(ionContentRef);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {viewState.type === "empty" && <EmptyView />}
      {viewState.type === "error" && <ErrorView />}
      {viewState.type === "display" && (
        <>
          <div>
            <div className={styles.list}>
              {viewState.paginationInfo.items.map((message, i) => (
                <ListItem
                  message={message}
                  key={message.id}
                  index={i}
                  onClickMessage={onClickMessage}
                />
              ))}
            </div>
          </div>
          <CLInfiniteScroll scrollEl={scrollElement} onLoadMore={onEndReach}>
            {viewState.paginationInfo.hasMore && (
              <div className={styles.loadingContainer}>
                <LoadingView />
              </div>
            )}
          </CLInfiniteScroll>
        </>
      )}
    </>
  );
};

interface ListItemProps {
  message: Message;
  index: number;
  onClickMessage: (message: Message, index: number) => void;
}

const ListItem: React.FC<ListItemProps> = props => {
  const { index, onClickMessage } = props;
  const { type, title, message, pushStartDate, read } = props.message;

  const displayType = useMemo<MessageType>(() => {
    if (!type) {
      return "promotion";
    }
    return type;
  }, [type]);

  const handleClick = useCallback(() => {
    onClickMessage(props.message, index);
  }, [props, index, onClickMessage]);

  const hasLink = useMemo(() => getLinkFromOPNSMessage(props.message) != null, [
    props.message,
  ]);

  return (
    <div
      onClick={handleClick}
      className={cn(styles.listItem, {
        [styles.unread]: read === ReadState.New,
      })}
    >
      <div
        className={cn(styles.messageTypeIcon, {
          [styles.messageTypeIcon__order]: displayType === "order",
          [styles.messageTypeIcon__promotion]: displayType === "promotion",
        })}
      />
      <div className={styles.theMessage}>
        <p className={styles.listItemTitle}>{title}</p>
        <p className={styles.listItemMessage}>{message}</p>
        {pushStartDate ? (
          <p className={styles.listItemDate}>
            {formatDate(pushStartDate, "YYYY-MM-DD")}
          </p>
        ) : null}
      </div>
      {hasLink ? (
        <IonIcon className={styles.linkArrow} name="ios-arrow-forward" />
      ) : null}
    </div>
  );
};

const PushNotificationMessagesPageNotUsed: React.FC = () => {
  const presentationContext = useContext(PresentationContext);

  const onRequestDismiss = React.useCallback(() => {
    presentationContext.dismiss();
  }, [presentationContext]);

  return (
    <div className={cn("ion-page", styles.container)}>
      <ModalHeader
        messageID="push_notification_messages.title"
        disabled={false}
        onModalDismiss={onRequestDismiss}
        hasDismissButton={true}
      />
      <CLContent className={styles.content}>
        <div className={styles.notUsedMessage}>
          Push Notification is not supported
        </div>
      </CLContent>
    </div>
  );
};

const EmptyView: React.FC = () => {
  return (
    <div className={styles.emptyMessage}>
      <LocalizedText messageID="push_notification_messages.empty" />
    </div>
  );
};

const ErrorView: React.FC = () => {
  return (
    <div className={styles.errorMessage}>
      <LocalizedText messageID="push_notification_messages.error" />
    </div>
  );
};
