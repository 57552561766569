import * as yup from "yup";

export interface StoreConfig {
  id: number;
  code: string;
  baseMediaUrl: string;
}

export const StoreConfigGraphQLAttributes = `
  id
  code
  baseMediaUrl: base_media_url
`;

export const StoreConfigSchema: yup.Schema<StoreConfig> = yup.object<
  StoreConfig
>({
  id: yup.number().required(),
  code: yup.string().required(),
  baseMediaUrl: yup.string().required(),
});
