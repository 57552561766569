import cn from "classnames";
import React, { useCallback } from "react";
import { LocalizedText } from "../../i18n/Localization";
import styles from "./RedemptionLetterButton.module.scss";

interface RedemptionLetterUrlProps {
  url: string;
  onLinkClicked: (url: string) => void;
}

const RedemptionLetterButton: React.FC<RedemptionLetterUrlProps> = props => {
  const { url, onLinkClicked } = props;

  const handleLinkClicked = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onLinkClicked(url);
    },
    [onLinkClicked, url]
  );

  return (
    <button
      className={styles.redemptionLetterButton}
      onClick={handleLinkClicked}
    >
      <i className={cn("fas fa-qrcode", styles.icon)}></i>
      <span className={styles.text}>
        <LocalizedText messageID="order_detail.redemption_letter_button.text" />
      </span>
    </button>
  );
};

export default RedemptionLetterButton;
