import { ApolloClient, FetchPolicy, gql } from "@apollo/client";
import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";
import {
  PartialOrder,
  RemotePartialOrder,
  transformRemotePartialOrderToPartialOrder,
  PartialOrderShipmentStatusListProductIds,
  PartialOrderItemSelectedOptions,
  PartialOrderEstimatedDeliveryDate,
} from "./model";
import { useContext } from "react";
import { useGraphQLFn } from "../../hook/graphql";
import { useFetchResources_v2 } from "../../repository/Hooks";
import { useHandleSessionExpired } from "../../repository/AuthRepository";
import { RepositoryContext } from "../../repository/State";
import { ResourcesRequestState } from "../../models/ResourcesRequestState";
import { getOrderItemSku } from "../../models/Order";
import { parseGraphQLError } from "../../api/GraphQL";
import { indexMapBy } from "../../utils/type";

const orderGraphQL = `
entityID: entity_id
incrementID: increment_id
customerName: customer_name
currencyCode: currency_code
grandTotal: grand_total
subtotal
discountDescription: discount_description
discountAmount: discount_amount
shippingAmount: shipping_amount
totalPaid: total_paid
isGuestCustomer: is_guest_customer
createdAt: created_at
updatedAt: updated_at
orderStatus: order_status
paymentMethod: payment_method
shippingMethod: shipping_method
deliveryTimeSlot: delivery_time_slot
clubMemberId: club_member_id
clubpointEarned: clubpoints_earned
clubpointUsed: clubpoints_used
clubCurrencyAmount: club_currency_amount
initialSubscriptionFee: initial_subscription_fee
shippingAddress: shipping_address {
  name
  street
  city
  region
  countryId: country_id
  telephone
  company
}
billingAddress: billing_address {
  name
  street
  city
  region
  countryId: country_id
  telephone
  company
}
pickupAddress: pickup_address {
  block
  building
  flat
  floor
  lockerCode: locker_code
  lockerProvider: locker_provider
  storeType: store_type
  street
}
items {
  productType: product_type
  name
  sku
  simpleName: simple_name
  simpleSku: simple_sku
  price
  requiredClubpoints: min_clubpoints
  subtotal: row_total
  qtyOrdered: qty_ordered
  productID: product_id
  productType: product_type
  isVirtual: is_virtual
  shippingLabel: union_shipping_label
  shippingTypeID: union_shipping_type_id
  attributesInfo: attributes_info {
    label
    value
    optionID: option_id
    optionValue: option_value
  }
  merchant {
    entityID: entity_id
    name: store_name
  }
  qrCodeGen: qr_code_gen {
    code
  }
  qrCodeImport: qr_code_import {
    code
  }
  promoCodeUrl: promo_code_url {
    code
  }
  redemptionLetters: redemption_letter {
    name
    url
  }

  clubProtectionEnable: club_protection_enable
  protectStartDate: protect_start_date
  protectEndDate: protect_end_date
}
fulfillment {
  fulfillCode: fulfill_code
}
shipmentStatusList: shipment_status {
  incrementIds: increment_ids
  stages: status {
    isProcessing: is_processing
    label
    timestamp
  }
  trackTitle: track_title
  trackNumber: track_number
}
`;

const orderShipmentStatusListProductIdGraphQL = `
entityID: entity_id
shipmentStatusList: shipment_status {
  productIds: product_ids
}
`;

const orderItemSelectedOptionsGraphQL = `
entityID: entity_id
items {
  sku
  simpleSku: simple_sku
  selectedOptions: selected_options {
    label
    value
  }
}
`;

const orderEstimatedDeliveryDateGraphQL = `
entityID: entity_id
estimatedDeliveryDate: estimated_delivery_date {
  shippingType: shipping_type
  estimatedDeliveryDate: estimated_delivery_date
}
`;

async function fetchOrder(
  client: ApolloClient<any>,
  locale: Locale,
  id: string,
  fetchPolicy: FetchPolicy
): Promise<PartialOrder> {
  async function query<T>(graphQL: string): Promise<T> {
    const result = await client.query<{ salesOrder: T }>({
      context: {
        headers: {
          Store: getStoreViewCodeForLocale(locale),
        },
      },
      query: gql`
      query QueryOrder($order_id: Int!) {
        salesOrder(id: $order_id) {
          ${graphQL}
        }
      }
    `,
      variables: {
        order_id: id,
      },
      fetchPolicy,
    });
    return result.data.salesOrder;
  }
  try {
    const [
      baseSalesOrder,
      statusListProductIdsResult,
      itemSelectedOptionsResult,
      estimatedDeliveryDateResult,
    ] = await Promise.all([
      query<RemotePartialOrder>(orderGraphQL),
      // FEATURE_MERGE: shipmentStatusList.productIds available in Salesorder interface => result
      query<PartialOrderShipmentStatusListProductIds>(
        orderShipmentStatusListProductIdGraphQL
      ).catch(() => ({ shipmentStatusList: [] })),
      query<{ items: PartialOrderItemSelectedOptions[] }>(
        orderItemSelectedOptionsGraphQL
      ).catch(() => ({ items: [] })),
      // FEATURE_MERGE: SalesOrder.estimatedDeliveryDate available in Salesorder interface => baseSalesOrder
      query<PartialOrderEstimatedDeliveryDate>(
        orderEstimatedDeliveryDateGraphQL
      ).catch(() => ({ estimatedDeliveryDate: [] })),
    ]);

    const skuMapPartialOrderItemSelectedOptions = indexMapBy(
      getOrderItemSku,
      itemSelectedOptionsResult.items
    );

    const items = baseSalesOrder.items.map(i => {
      const sku = getOrderItemSku(i);
      const itemSelectedOptions = skuMapPartialOrderItemSelectedOptions[sku];
      return {
        ...i,

        ...(itemSelectedOptions
          ? { selectedOptions: itemSelectedOptions.selectedOptions }
          : {}),
      };
    });

    const salesOrder: RemotePartialOrder = {
      ...baseSalesOrder,
      shipmentStatusList: baseSalesOrder.shipmentStatusList
        ? baseSalesOrder.shipmentStatusList.map((shipmentStatus, i) => {
            const shipmentStatusList =
              statusListProductIdsResult.shipmentStatusList[i];
            if (shipmentStatusList && shipmentStatusList.productIds) {
              const { productIds } = shipmentStatusList;
              return { ...shipmentStatus, productIds };
            }
            return shipmentStatus;
          })
        : null,
      estimatedDeliveryDate: estimatedDeliveryDateResult.estimatedDeliveryDate,
      items,
    };

    return transformRemotePartialOrderToPartialOrder(salesOrder);
  } catch (e) {
    throw parseGraphQLError(e);
  }
}

export function useOrderResource(): [
  ResourcesRequestState<PartialOrder>,
  (orderID: string) => Promise<PartialOrder>,
  (orderID: string) => Promise<PartialOrder>
] {
  const { state, dispatch } = useContext(RepositoryContext);
  const fetchOrder_ = useHandleSessionExpired(useGraphQLFn(fetchOrder));

  const [requestState, { call, refresh }] = useFetchResources_v2<
    PartialOrder,
    (orderID: string) => Promise<PartialOrder>
  >({
    memoryCacheProvider: async (orderID: string) => {
      const { orderDetailsPartialOrderMap } = state;
      return orderDetailsPartialOrderMap[orderID] || null;
    },
    localCacheProvider: async (orderID: string) => {
      const order = await fetchOrder_(orderID, "cache-only");
      dispatch({ type: "UpdateOrderDetailsSalesOrder", order });
      return order;
    },
    remoteResourcesProvider: async (orderID: string) => {
      const order = await fetchOrder_(orderID, "network-only");
      dispatch({ type: "UpdateOrderDetailsSalesOrder", order });
      return order;
    },
  });
  return [requestState, call, refresh];
}

export function useInMemoryPartialOrder(orderID: string): PartialOrder | null {
  const { state } = useContext(RepositoryContext);
  const { orderDetailsPartialOrderMap } = state;
  return orderDetailsPartialOrderMap[orderID] || null;
}
