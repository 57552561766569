import { ApolloClient, gql, FetchPolicy } from "@apollo/client";

import { Locale, getStoreViewCodeForLocale } from "../../i18n/locale";

import {
  OS,
  Token,
  Isdn,
  PNSResponse,
  Message,
  MessageGraphQLAttributes,
  ReadState,
  MessageSchema,
  transformRemoteMessageToMessage,
} from "../../models/OPNSPushNotification";

export async function getMessages(
  client: ApolloClient<any>,
  locale: Locale,
  os: OS,
  token: Token,
  isdn: Isdn,
  page: number,
  fetchPolicy: FetchPolicy
): Promise<{
  code: number;
  count: number;
  msgs: Message[];
  pageIndex: number;
  total: number;
}> {
  const result = await client.query<{
    queryMessage: {
      code: number;
      count: number;
      msgs:
        | {
            id: string;
            topic: string;
            message: string;
            pushStartDate: string;
            read: ReadState;
          }[]
        | null;
      pageIndex: number;
      total: number;
    };
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    query: gql`
      query QueryMessages(
        $token: String!,
        $messageType: String!,
        $page: Int!,
        $isdn: String!,
        $os: String!,
        $markRead: String!,
      ) {
        queryMessage(
          accessToken: $token,
          messageType: $messageType,
          pageIndex: $page,
          isdn: $isdn,
          os: $os,
          markRead: $markRead,
        ) {
          code
          count
          msgs {
            ${MessageGraphQLAttributes}
          }
          pageIndex
          total
        }
      }
    `,
    variables: {
      token,
      isdn,
      os,
      messageType: "INBOX",
      page,
      markRead: "N",
    },
    fetchPolicy,
  });

  if (result.data.queryMessage.msgs == null) {
    return {
      ...result.data.queryMessage,
      msgs: [],
    };
  }

  const messages = result.data.queryMessage.msgs
    .map(message => MessageSchema.validateSync(message))
    .map(transformRemoteMessageToMessage);

  return { ...result.data.queryMessage, msgs: messages };
}

export async function messagesMarkRead(
  client: ApolloClient<any>,
  locale: Locale,
  token: Token,
  isdn: Isdn,
  messageIds: string[]
): Promise<PNSResponse> {
  const result = await client.mutate<{
    messageMarkRead: PNSResponse;
  }>({
    context: {
      headers: {
        Store: getStoreViewCodeForLocale(locale),
      },
    },
    mutation: gql`
      mutation MessagesMarkRead(
        $token: String!
        $messageIds: [String!]!
        $isdn: String!
      ) {
        messageMarkRead(accessToken: $token, msgIds: $messageIds, isdn: $isdn) {
          code
          description
        }
      }
    `,
    variables: {
      token,
      messageIds,
      isdn,
    },
  });

  if (!result.data) {
    throw new Error();
  }

  return result.data.messageMarkRead;
}
