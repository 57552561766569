import React, { useMemo, useRef, useCallback, useContext } from "react";

import HorizontalProductList from "../HorizontalProductList";
import CMSBlog from "./CMSBlog/lazy";
import CMSCarouselView from "./CMSCarousel/lazy";
import CMSHtml from "./CMSHtml/lazy";

import { LocalizedText } from "../../i18n/Localization";
import {
  ResolvedMatchedCMSBlock,
  ResolvedCMSHorizontalProductList,
  ResolvedCMSBlock,
} from "../../models/cmsBlock";
import { ProductOverview } from "../../models/ProductOverview";
import { HorizontalProductListData } from "../../models/horizontalProductList";

import { ProfileSessionContext } from "../../contexts/ProfileSessionContext";

import ViewMoreButton from "./CMSBlockList/ViewMoreButton.lazy";
import CMSCategoryBricksBlock from "./CMSCategoryBricksBlock/lazy";
import { RootTab } from "../../navigation/routes";
import { useOnClickViewMoreButton } from "../../hook/viewMore";
import useExtractScriptsAndStyles from "../../hook/useExtractScriptsAndStyles";
import useRegisterPublishDeepLinkOnHTMLContent from "../../hook/useRegisterPublishDeepLinkOnHTMLContent";
import { useEffectOnce } from "../../hook/useEffectOnce";
import { isEmpty } from "../../utils/String";
import isValidHTMLTag from "../../utils/IsValidHTMLTag";
import parse from "../../utils/HTMLReactParser";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import HorizontalPaginatedProductList from "../HorizontalPaginatedProductList";

import styles from "./HTMLBasedCMSBlocks.module.scss";

interface Props {
  waitingToFillHTML: string;
  resolvedMatchedCMSBlocks: ResolvedMatchedCMSBlock[];
  currentTab: RootTab;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
  onClickProductBlock?: (productOverview: ProductOverview) => void;
}

const HTMLBasedCMSBlocks: React.FC<Props> = React.memo((props: Props) => {
  const {
    waitingToFillHTML,
    resolvedMatchedCMSBlocks,
    currentTab,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
    onClickProductBlock,
  } = props;

  const profileSessionContext = useContext(ProfileSessionContext);

  const findMatchedCMSBlock = useCallback(
    (matchedId: string): ResolvedCMSBlock[] | null => {
      const resolvedMatchedCMSBlock = resolvedMatchedCMSBlocks.filter(
        m => m.matchId === matchedId
      );
      if (resolvedMatchedCMSBlock.length > 0) {
        return resolvedMatchedCMSBlock[0].resolvedCMSBlocks;
      }
      return null;
    },
    [resolvedMatchedCMSBlocks]
  );

  const tryFill = useCallback(
    domNode => {
      if (
        domNode.type === "tag" &&
        domNode.name &&
        domNode.name !== "hole" &&
        !isValidHTMLTag(domNode.name)
      ) {
        return <></>;
      }
      if (domNode.type === "tag" && domNode.name === "hole") {
        const _cmsBlocks = findMatchedCMSBlock(domNode.attribs.id);
        if (_cmsBlocks) {
          return (
            <>
              {_cmsBlocks.map((cmsBlock, i) => {
                switch (cmsBlock.type) {
                  case "banner_rotator":
                    return <CMSCarouselView cmsCarousel={cmsBlock} key={i} />;
                  case "static_block":
                    return <CMSHtml cmsStaticBlock={cmsBlock} key={i} />;
                  case "top_sellers":
                  case "products_recommendation":
                  case "popular_products":
                    if (cmsBlock.productOverviews.length <= 0) {
                      return null;
                    }
                    if (cmsBlock.widgetTemplate === "hot_product_list_ajax") {
                      return (
                        <HotProductList
                          key={i}
                          resolvedCmsBlock={cmsBlock}
                          hrefForProduct={hrefForProduct}
                          onAddToCart={onAddToCart}
                          onClickLikeButton={onClickLikeButton}
                        />
                      );
                    }
                    return (
                      <CMSHorizontalProductListView
                        key={i}
                        resolvedCmsBlock={cmsBlock}
                        hrefForProduct={hrefForProduct}
                        onAddToCart={onAddToCart}
                        onClickLikeButton={onClickLikeButton}
                        onClickProductBlock={onClickProductBlock}
                      />
                    );
                  case "recent_blog":
                    return (
                      <CMSBlog
                        cmsBlogBlock={cmsBlock}
                        currentTab={currentTab}
                        key={i}
                      />
                    );
                  case "category_bricks":
                    return (
                      <CMSCategoryBricksBlock
                        key={i}
                        currentTab={currentTab}
                        cmsBlock={cmsBlock}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </>
          );
        }
      }
      return undefined;
    },
    [
      currentTab,
      findMatchedCMSBlock,
      hrefForProduct,
      onAddToCart,
      onClickLikeButton,
      onClickProductBlock,
    ]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const html = useExtractScriptsAndStyles(containerRef, waitingToFillHTML);
  useRegisterPublishDeepLinkOnHTMLContent(containerRef, [html]);

  const htmlReactNode = useMemo(() => {
    return parse(html, { replace: tryFill });
  }, [html, tryFill]);

  useEffectOnce(() => {
    if (profileSessionContext) {
      addPerformanceRecord(
        profileSessionContext.rootProfileSession,
        "Contents (CMSBlocks) Shown"
      );
    }
  });

  return <div ref={containerRef}>{htmlReactNode}</div>;
});

const CMSHorizontalProductListView: React.FC<{
  resolvedCmsBlock: ResolvedCMSHorizontalProductList;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
  onClickProductBlock?: (productOverview: ProductOverview) => void;
}> = React.memo(props => {
  const {
    resolvedCmsBlock,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
    onClickProductBlock,
  } = props;
  const productListData = useMemo<HorizontalProductListData>(() => {
    return HorizontalProductListData(
      resolvedCmsBlock.title || "",
      resolvedCmsBlock.productOverviews,
      resolvedCmsBlock.productLabelsByProductId,
      resolvedCmsBlock.backgroundImageUrl || "",
      null,
      resolvedCmsBlock.viewMoreUrl || "",
      resolvedCmsBlock.bundleByProductId
    );
  }, [resolvedCmsBlock]);

  const onClick = useOnClickViewMoreButton(resolvedCmsBlock.viewMoreUrl);

  return (
    <HorizontalProductList
      horizontalProductListData={productListData}
      onAddToCart={onAddToCart}
      hrefForProduct={hrefForProduct}
      detailButton={
        resolvedCmsBlock.viewMoreUrl != null &&
        !isEmpty(resolvedCmsBlock.viewMoreUrl) && (
          <ViewMoreButton onClick={onClick} />
        )
      }
      onClickLikeButton={onClickLikeButton}
      onClickProductBlock={onClickProductBlock}
    />
  );
});

export default HTMLBasedCMSBlocks;

const HotProductList: React.FC<{
  resolvedCmsBlock: ResolvedCMSHorizontalProductList;
  onAddToCart: (product: ProductOverview) => void;
  hrefForProduct: (product: ProductOverview) => string;
  onClickLikeButton: (productOverview: ProductOverview) => void;
}> = React.memo(props => {
  const {
    resolvedCmsBlock,
    onAddToCart,
    hrefForProduct,
    onClickLikeButton,
  } = props;
  const productListData = useMemo<HorizontalProductListData>(() => {
    return HorizontalProductListData(
      resolvedCmsBlock.title || "",
      resolvedCmsBlock.productOverviews,
      resolvedCmsBlock.productLabelsByProductId,
      resolvedCmsBlock.backgroundImageUrl || "",
      null,
      resolvedCmsBlock.viewMoreUrl || "",
      resolvedCmsBlock.bundleByProductId
    );
  }, [resolvedCmsBlock]);

  const onClick = useOnClickViewMoreButton(resolvedCmsBlock.viewMoreUrl);

  return (
    <div className={styles.horizontalPaginatedProductList}>
      <HorizontalPaginatedProductList
        horizontalProductListData={productListData}
        itemPerPage={3}
        onAddToCart={onAddToCart}
        hrefForProduct={hrefForProduct}
        detailButton={
          resolvedCmsBlock.viewMoreUrl != null &&
          !isEmpty(resolvedCmsBlock.viewMoreUrl) && (
            <button
              onClick={onClick}
              className={styles.hotProductListViewMoreButton}
            >
              <LocalizedText messageID="hot_product_list.view_more" /> &gt;
            </button>
          )
        }
        onClickLikeButton={onClickLikeButton}
      />
    </div>
  );
});
