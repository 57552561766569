import { useContext, useMemo, useState, useCallback } from "react";
import { gql, useApolloClient } from "@apollo/react-hooks";

import { useIntl } from "../i18n/Localization";
import { useKeepUpdatingRef } from "../hook/utils";

import { RepositoryContext, PaginationInfo } from "./State";

import {
  fetchProduct,
  fetchProductOverviewsBySKUs,
  fetchNormalProductOverviewsByMerchantId,
  fetchFeaturedProductOverviewsByMerchantId,
  fetchProductSKUByUrlKey,
  getProduct,
} from "../api/GraphQL";

import { useFetchResources_v2 } from "./Hooks";

import { Product } from "../models/ProductDetails";
import {
  ProductOverview,
  ProductOverviewGraphQLAttributes,
} from "../models/ProductOverview";
import {
  ResourcesRequestState,
  isRequestLoading,
} from "../models/ResourcesRequestState";
import { MerchantID, EntityID as MerchantEntityID } from "../models/Merchant";

import { ProductFilterInfo } from "../models/filter";
import { PageInfo } from "../models/PageInfo";
import { ProductThirdPartyProductShowPriceGraphQLAttributes } from "../models/product";
import { profileAsyncAction, profileSyncAction } from "../utils/performance";
import {
  ProductDetailPageSession,
  Session,
} from "../utils/PerformanceRecordStore/sessions";

import possibleTypes from "../api/possibleTypes.json";
import ProductAttributeFilterContext from "../contexts/ProductAttributeFilterContext";
import {
  fetchCampaignProductBySKU,
  getCampaignProductBySKU,
} from "../api/Campaign";

export function useCachedProductOverview(sku: string): ProductOverview | null {
  const client = useApolloClient();

  return useMemo(() => {
    return profileSyncAction(
      ProductDetailPageSession(sku),
      "Load Product Overview from Cache",
      () => {
        for (const productType of possibleTypes.ProductInterface) {
          const id = client.cache.identify({
            __typename: productType,
            sku,
          });
          if (id) {
            const productOverview = client.readFragment<ProductOverview>(
              {
                id,
                fragment: gql`
                  fragment X on ${productType} {
                    ${ProductOverviewGraphQLAttributes}
                    ${ProductThirdPartyProductShowPriceGraphQLAttributes}
                  }
                `,
              },
              true
            );
            if (productOverview) {
              return productOverview;
            }
          }
        }
        return null;
      }
    );
  }, [client, sku]);
}

export function useMemoryProductDetail(sku: string): Product | null {
  const client = useApolloClient();
  return useMemo(() => {
    return getProduct(client, sku);
  }, [client, sku]);
}

export function useMemoryCampaignProductDetail(
  campaignId: number,
  sku: string
): Product | null {
  const client = useApolloClient();
  return useMemo(() => {
    return getCampaignProductBySKU(client, campaignId, sku);
  }, [client, campaignId, sku]);
}

export function useFetchProductDetailBySKU(
  profileSession?: Session
): [
  ResourcesRequestState<Product | null>,
  (sku: string) => Promise<Product | null>,
  (sku: string) => Promise<Product | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Product | null,
    (sku: string) => Promise<Product | null>
  >({
    localCacheProvider: async (sku: string) => {
      const action = () => getProduct(client, sku);
      const product = profileSession
        ? profileSyncAction(
            profileSession,
            "Load Product Detail from Cache",
            action
          )
        : action();
      return product;
    },
    remoteResourcesProvider: async (sku: string) => {
      const action = () => fetchProduct(client, sku, locale, "network-only");
      const product = profileSession
        ? await profileAsyncAction(
            profileSession,
            "Load Product Detail from Network",
            action
          )
        : await action();
      return product;
    },
  });
  return [requestState, fetch, refresh];
}

export function useFetchCampaignProductDetailBySKU(
  profileSession?: Session
): [
  ResourcesRequestState<Product | null>,
  (campaignId: number, sku: string) => Promise<Product | null>,
  (campaignId: number, sku: string) => Promise<Product | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();
  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    Product | null,
    (campaignId: number, sku: string) => Promise<Product | null>
  >({
    localCacheProvider: async (campaignId: number, sku: string) => {
      // TODO (kenchan):
      // fetchCampaignProductBySKU with "cache-only" fetchPolicy will return incomplete product data
      // if product overview is fetch previously. `returnPartialData` default is false and does not
      // stop the query call returning partial product data. Use readquery implementation instead
      const action = () => getCampaignProductBySKU(client, campaignId, sku);
      const product = profileSession
        ? profileSyncAction(
            profileSession,
            "Load Product Detail from Cache",
            action
          )
        : await action();
      return product;
    },
    remoteResourcesProvider: async (campaignId: number, sku: string) => {
      const action = () =>
        fetchCampaignProductBySKU(
          client,
          campaignId,
          sku,
          locale,
          "network-only"
        );
      const product = profileSession
        ? await profileAsyncAction(
            profileSession,
            "Load Product Detail from Network",
            action
          )
        : await action();
      return product;
    },
  });
  return [requestState, fetch, refresh];
}

export function useFetchProductsBySKUs(): [
  ResourcesRequestState<ProductOverview[] | null>,
  (skus: string[]) => Promise<ProductOverview[] | null>
] {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [requestState, { call: fetch }] = useFetchResources_v2<
    ProductOverview[] | null,
    (skus: string[]) => Promise<ProductOverview[] | null>
  >({
    localCacheProvider: async (skus: string[]) => {
      const productOverviews = await fetchProductOverviewsBySKUs(
        client,
        skus,
        locale,
        "cache-only"
      );
      return productOverviews;
    },
    remoteResourcesProvider: async (skus: string[]) => {
      const productOverviews = await fetchProductOverviewsBySKUs(
        client,
        skus,
        locale,
        "network-only"
      );
      return productOverviews;
    },
  });

  return [requestState, fetch];
}

export function useFetchNormalProductOverviewsByMerchantId(
  entityId: MerchantEntityID,
  productFilterInfo: ProductFilterInfo
): {
  requestState: ResourcesRequestState<{
    productOverviews: ProductOverview[];
  } | null>;
  fetchNext: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  refresh: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  paginationInfo: PaginationInfo<ProductOverview> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { productAttributeFilterInputMap } = useContext(
    ProductAttributeFilterContext
  );

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    ProductOverview
  > | null>(null);
  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const didFetchProducts = useCallback(
    (productOverviews: ProductOverview[], pageInfo: PageInfo) => {
      setPaginationInfo(p => {
        const newPaginationInfo: PaginationInfo<ProductOverview> = p || {
          items: [],
          currentPage: 0,
          hasMore: true,
        };
        if (pageInfo.currentPage === 1) {
          newPaginationInfo.items = productOverviews;
        } else if (pageInfo.currentPage === newPaginationInfo.currentPage) {
          // Handle remote success after cache success, which may update the fetched list
          newPaginationInfo.items = newPaginationInfo.items
            .slice(0, -productOverviews.length)
            .concat(productOverviews);
        } else {
          newPaginationInfo.items = newPaginationInfo.items.concat(
            productOverviews
          );
        }
        newPaginationInfo.currentPage = pageInfo.currentPage;
        newPaginationInfo.hasMore = pageInfo.currentPage < pageInfo.totalPages;
        return newPaginationInfo;
      });
    },
    []
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    { productOverviews: ProductOverview[] } | null,
    (page: number) => Promise<{ productOverviews: ProductOverview[] } | null>
  >({
    localCacheProvider: async (page: number) => {
      const result = await fetchNormalProductOverviewsByMerchantId(
        client,
        entityId,
        productFilterInfo,
        productAttributeFilterInputMap,
        page,
        locale,
        "cache-only"
      );
      if (result) {
        didFetchProducts(result.productOverviews, result.pageInfo);
      }
      return result;
    },
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchNormalProductOverviewsByMerchantId(
        client,
        entityId,
        productFilterInfo,
        productAttributeFilterInputMap,
        page,
        locale,
        "network-only"
      );
      if (result) {
        didFetchProducts(result.productOverviews, result.pageInfo);
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };
}

export function useFetchFeaturedProductOverviewsByMerchantId(
  merchantId: MerchantID
): {
  requestState: ResourcesRequestState<{
    productOverviews: ProductOverview[];
  } | null>;
  fetchNext: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  refresh: () => Promise<{ productOverviews: ProductOverview[] } | null>;
  paginationInfo: PaginationInfo<ProductOverview> | null;
} {
  const client = useApolloClient();
  const { locale } = useIntl();

  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo<
    ProductOverview
  > | null>(null);
  const paginationInfoRef = useKeepUpdatingRef(paginationInfo);

  const didFetchProducts = useCallback(
    (productOverviews: ProductOverview[], pageInfo: PageInfo) => {
      setPaginationInfo(p => {
        const newPaginationInfo: PaginationInfo<ProductOverview> = p || {
          items: [],
          currentPage: 0,
          hasMore: true,
        };
        if (pageInfo.currentPage === 1) {
          newPaginationInfo.items = productOverviews;
        } else if (pageInfo.currentPage === newPaginationInfo.currentPage) {
          // Handle remote success after cache success, which may update the fetched list
          newPaginationInfo.items = newPaginationInfo.items
            .slice(0, -productOverviews.length)
            .concat(productOverviews);
        } else {
          newPaginationInfo.items = newPaginationInfo.items.concat(
            productOverviews
          );
        }
        newPaginationInfo.currentPage = pageInfo.currentPage;
        newPaginationInfo.hasMore = pageInfo.currentPage < pageInfo.totalPages;
        return newPaginationInfo;
      });
    },
    []
  );

  const [requestState, { call: fetch, refresh }] = useFetchResources_v2<
    { productOverviews: ProductOverview[] } | null,
    (page: number) => Promise<{ productOverviews: ProductOverview[] } | null>
  >({
    localCacheProvider: async (page: number) => {
      const result = await fetchFeaturedProductOverviewsByMerchantId(
        client,
        merchantId,
        page,
        locale,
        "cache-only"
      );
      if (result) {
        didFetchProducts(result.productOverviews, result.pageInfo);
      }
      return result;
    },
    remoteResourcesProvider: async (page: number) => {
      const result = await fetchFeaturedProductOverviewsByMerchantId(
        client,
        merchantId,
        page,
        locale,
        "network-only"
      );
      if (result) {
        didFetchProducts(result.productOverviews, result.pageInfo);
      }
      return result;
    },
  });

  const requestStateRef = useKeepUpdatingRef(requestState);

  const fetchNext = useCallback(async () => {
    const _paginationInfo = paginationInfoRef.current;
    if (_paginationInfo != null && !_paginationInfo.hasMore) {
      return null;
    }
    if (isRequestLoading(requestStateRef.current)) {
      return null;
    }
    if (_paginationInfo == null) {
      return fetch(1);
    }
    return fetch(_paginationInfo.currentPage + 1);
  }, [paginationInfoRef, requestStateRef, fetch]);

  const refresh_ = useCallback(() => refresh(1), [refresh]);

  return { requestState, fetchNext, refresh: refresh_, paginationInfo };
}

export function useIsProductLiked(sku: string): boolean {
  const {
    state: {
      product: { likedProductSKU },
    },
  } = useContext(RepositoryContext);
  return likedProductSKU[sku] || false;
}

export function useFetchProductSKUByUrlKey(): (
  urlKey: string
) => Promise<string | null> {
  const client = useApolloClient();
  return useCallback(
    (urlKey: string) => {
      return fetchProductSKUByUrlKey(client, urlKey);
    },
    [client]
  );
}
