import React, { useCallback, useMemo } from "react";

import {
  ProductConfigurableOptionValue,
  isProductConfigurableOptionColorOption,
  sortConfigurableOptions,
} from "../../models/product";
import {
  buildConfigurationOptionsDependencyMap,
  buildConfigurationOptionsStockStatusMap,
} from "../../models/ProductConfigurationDependency";

import { ProductSaleBundleProduct } from "../../models/ProductSaleBundle";
import { useIntl } from "../../i18n/Localization";
import { findFirst } from "../../utils/CollectionUtils";

import styles from "./ProductConfigurationForm.module.scss";
import {
  FormState,
  getFormInitialState,
} from "../ProductDetailPage/PurchaseProductModal/PurchaseProductFormStateHook";

interface Props {
  product: ProductSaleBundleProduct;
  formState: FormState | undefined;
  onClick: (product: ProductSaleBundleProduct) => void;
}

const ProductConfigurationForm: React.FC<Props> = React.memo(props => {
  const { product, formState: _formState, onClick } = props;
  const { translate } = useIntl();
  const { configurableOptions: _configurableOptions, variants } = product;

  const formState = useMemo(() => _formState || getFormInitialState(), [
    _formState,
  ]);

  const onClickConfiguraionOpionItem = useCallback(() => {
    onClick(product);
  }, [onClick, product]);

  const configurableOptions = useMemo(() => {
    if (!_configurableOptions) {
      return _configurableOptions;
    }
    return sortConfigurableOptions(_configurableOptions);
  }, [_configurableOptions]);

  const orderedAttributeCodes = useMemo(
    () =>
      configurableOptions
        ? configurableOptions.map(
            configurableOption => configurableOption.attributeCode
          )
        : [],
    [configurableOptions]
  );

  const configurableOptionsDependendcy = useMemo<any>(() => {
    if (variants == null) {
      return {};
    }
    return buildConfigurationOptionsDependencyMap(
      variants,
      orderedAttributeCodes
    );
  }, [variants, orderedAttributeCodes]);
  const optionsStockStatusDependency = useMemo<any>(() => {
    if (variants == null) {
      return {};
    }
    return buildConfigurationOptionsStockStatusMap(
      variants,
      orderedAttributeCodes
    );
  }, [variants, orderedAttributeCodes]);
  const configurableOptionItems = useMemo(() => {
    if (configurableOptions == null) {
      return null;
    }
    const items: {
      type: "picker" | "color-picker";
      key: number;
      title: string;
      disabled: boolean;
      items: ProductConfigurableOptionValue[];
      selectedItem: number | null;
      onClick: () => void;
    }[] = [];
    let dependencyMap = configurableOptionsDependendcy;
    let stockStatusDependencyMap = optionsStockStatusDependency;
    for (let i = 0; i < configurableOptions.length; ++i) {
      const configurableOption = configurableOptions[i];
      const possibleOptions = Object.keys(dependencyMap);
      const selectedValue =
        formState.configurationOptionValue[configurableOption.id] != null
          ? formState.configurationOptionValue[configurableOption.id]
          : null;
      const selectedItem = findFirst(
        v => v.value === selectedValue,
        configurableOption.values
      );
      items.push({
        type: isProductConfigurableOptionColorOption(configurableOption)
          ? "color-picker"
          : "picker",
        key: configurableOption.id,
        title: configurableOption.label,
        disabled: possibleOptions.length <= 0,
        items: configurableOption.values
          .filter(
            o => possibleOptions.findIndex(p => p === String(o.value)) !== -1
          )
          // eslint-disable-next-line no-loop-func
          .map(option => ({
            ...option,
            label: translate(
              stockStatusDependencyMap[option.value].stockStatus ===
                "OUT_OF_STOCK"
                ? "page.product_detail.purchase_product.form.item_picker.product_option.sold_out"
                : "page.product_detail.purchase_product.form.item_picker.product_option.in_stock",
              {
                OPTION: option.label,
              }
            ),
          })),
        selectedItem: selectedItem ? selectedItem.value : null,
        onClick: onClickConfiguraionOpionItem,
      });
      dependencyMap =
        selectedItem != null ? dependencyMap[selectedItem.value] : {};
      stockStatusDependencyMap =
        selectedItem != null
          ? stockStatusDependencyMap[selectedItem.value]
          : {};
    }
    return items;
  }, [
    configurableOptions,
    configurableOptionsDependendcy,
    optionsStockStatusDependency,
    formState,
    translate,
    onClickConfiguraionOpionItem,
  ]);
  return (
    <div className={styles.productConfigurationForm}>
      {configurableOptionItems &&
        configurableOptionItems.map(item => {
          switch (item.type) {
            case "picker":
              return (
                <div key={item.key} className={styles.optionContainer}>
                  <ItemPicker isRequired={true} {...item} />
                </div>
              );
            default:
              return null;
          }
        })}
    </div>
  );
});

export default ProductConfigurationForm;

interface PickerItem {
  value: number;
  label: string;
}

interface ItemPickerProps {
  title: React.ReactNode;
  isRequired?: boolean;
  disabled?: boolean;
  items: PickerItem[];
  selectedItem: number | null;
  onClick: () => void;
}

const ItemPicker: React.FC<ItemPickerProps> = props => {
  const { items, selectedItem: _selectedItem, onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  const selectedItem = useMemo(() => {
    const selected = items.filter(i => i.value === _selectedItem);
    if (selected.length > 0) {
      return selected[0];
    }
    return null;
  }, [items, _selectedItem]);

  return (
    <button onClick={handleClick} className={styles.optionButton}>
      <span className={styles.optionText}>
        {selectedItem ? selectedItem.label : "Please select"}
      </span>
    </button>
  );
};
