import React, { useState, useCallback, useMemo, useEffect } from "react";
import cn from "classnames";

import { LocalizedText, useIntl } from "../../i18n/Localization";
import { RatingCode, RatingOption } from "../../models/ProductReview";
import { useCustomer } from "../../repository/AuthRepository";

import CLModal from "../CLModal/lazy";
import CLContent from "../CLContent";
import { PrimaryButton, WhiteButton } from "../Button";

import styles from "./v2.module.scss";

interface Props {
  isModalOpen: boolean;
  onRequestDismiss: () => void;
  ratingCodes: RatingCode[];
  onSubmit: (
    selectedOptions: { [key in number]: RatingOption | null },
    name: string,
    detail: string
  ) => void;
  isSubmitDisabled: boolean;
  isSubmitLoading: boolean;
  ratingStarErrorMessage: string | null;
  nameErrorMessage: string | null;
  detailErrorMessage: string | null;
}

const AddProductReviewModal: React.FC<Props> = props => {
  const {
    isModalOpen,
    onRequestDismiss,
    ratingCodes,
    onSubmit,
    isSubmitDisabled,
    isSubmitLoading,
    ratingStarErrorMessage,
    nameErrorMessage,
    detailErrorMessage,
  } = props;

  const { translate } = useIntl();
  const customer = useCustomer();

  const [selectedOptions, setSelectedOptions] = useState<
    { [key in number]: RatingOption | null }
  >({});
  const [detail, setDetail] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (isModalOpen) {
      const n = customer ? customer.firstname : "";
      setDetail("");
      setName(n);
      setSelectedOptions({});
    }
  }, [isModalOpen, customer]);

  const handleDetailChange = useCallback(e => {
    const { value } = e.target;
    setDetail(value);
  }, []);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setName(value);
    },
    []
  );

  const handleSelectOption = useCallback(
    (ratingCode: RatingCode, ratingOption: RatingOption) => {
      if (isSubmitLoading) {
        return;
      }
      setSelectedOptions(prev => {
        if (ratingCode.ratingId == null) {
          return prev;
        }
        return { ...prev, [ratingCode.ratingId]: ratingOption };
      });
    },
    [isSubmitLoading]
  );

  const handleSubmitClick = useCallback(() => {
    onSubmit(selectedOptions, name, detail);
  }, [onSubmit, selectedOptions, name, detail]);

  const handleCancelClick = useCallback(() => {
    onRequestDismiss();
  }, [onRequestDismiss]);

  return (
    <CLModal
      className={styles.modal}
      isOpen={isModalOpen}
      onRequestDismiss={onRequestDismiss}
      backdropDismiss={false}
    >
      <CLContent className={styles.content} scrollY={true}>
        <div className={styles.modalBody}>
          <h1 className={styles.modalTitle}>
            <LocalizedText messageID="add_product_review_modal.title" />
          </h1>
          <div className={styles.ratingRows}>
            {ratingCodes.map((ratingCode, i) => {
              if (ratingCode.ratingId == null) {
                return null;
              }
              return (
                <RatingRow
                  ratingCode={ratingCode}
                  selectedOption={selectedOptions[ratingCode.ratingId] || null}
                  onSelectOption={handleSelectOption}
                  key={i}
                />
              );
            })}
            <p className={cn(styles.errorMessage, styles.textAlignCenter)}>
              {ratingStarErrorMessage}
            </p>
          </div>
          <div className={styles.fieldContainer}>
            <div className={cn(styles.inputContainer, styles.nameInputField)}>
              <div className={styles.nameInputFieldName}>
                <LocalizedText messageID="add_product_review_modal.name.field" />
              </div>
              <input
                value={name}
                onChange={handleNameChange}
                className={styles.nameInput}
              />
            </div>
            <p className={styles.errorMessage}>{nameErrorMessage}</p>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.inputContainer}>
              <textarea
                value={detail}
                onChange={handleDetailChange}
                className={styles.detailTextArea}
                disabled={isSubmitLoading}
                placeholder={translate(
                  "add_product_review_modal.detail.placeholder"
                )}
              />
            </div>
            <p className={styles.errorMessage}>{detailErrorMessage}</p>
          </div>
          <PrimaryButton
            onClick={handleSubmitClick}
            disabled={isSubmitDisabled}
            loading={isSubmitLoading}
            className={cn(styles.button, styles.submitButton)}
          >
            <LocalizedText messageID="submit" />
          </PrimaryButton>
          <WhiteButton
            onClick={handleCancelClick}
            disabled={isSubmitDisabled}
            className={cn(styles.button, styles.cancelButton)}
          >
            <LocalizedText messageID="cancel" />
          </WhiteButton>
        </div>
      </CLContent>
    </CLModal>
  );
};

interface RatingRowProps {
  ratingCode: RatingCode;
  selectedOption: RatingOption | null;
  onSelectOption: (ratingCode: RatingCode, ratingOption: RatingOption) => void;
}

const RatingRow: React.FC<RatingRowProps> = props => {
  const { ratingCode, selectedOption, onSelectOption } = props;

  const handleSelectOption = useCallback(
    (ratingOption: RatingOption) => {
      onSelectOption(ratingCode, ratingOption);
    },
    [onSelectOption, ratingCode]
  );

  return (
    <div className={styles.ratingRow}>
      {ratingCode.options ? (
        <RatingButtons
          ratingOptions={ratingCode.options}
          selectedOption={selectedOption}
          onSelectOption={handleSelectOption}
        />
      ) : null}
    </div>
  );
};

interface RatingButtonsProps {
  ratingOptions: RatingOption[];
  selectedOption: RatingOption | null;
  onSelectOption: (ratingOption: RatingOption) => void;
}

const RatingButtons: React.FC<RatingButtonsProps> = props => {
  const { ratingOptions, selectedOption, onSelectOption } = props;

  const selectedArray = useMemo<boolean[]>(() => {
    const idx =
      selectedOption == null ? -1 : ratingOptions.indexOf(selectedOption);
    const res: boolean[] = [];
    for (let i = 0; i < ratingOptions.length; i++) {
      res.push(i <= idx);
    }
    return res;
  }, [ratingOptions, selectedOption]);

  return (
    <div className={styles.ratingButtons}>
      {ratingOptions.map((ratingOption, i) => {
        return (
          <RatingButton
            key={i}
            ratingOption={ratingOption}
            selected={selectedArray[i]}
            onClick={onSelectOption}
          />
        );
      })}
    </div>
  );
};

interface RatingButtonProps {
  ratingOption: RatingOption;
  selected: boolean;
  onClick: (ratingOption: RatingOption) => void;
}

const RatingButton: React.FC<RatingButtonProps> = props => {
  const { ratingOption, selected, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(ratingOption);
  }, [ratingOption, onClick]);

  return (
    <span
      onClick={handleClick}
      className={cn(styles.ratingButton, {
        [styles.selected]: selected,
      })}
    ></span>
  );
};

export default AddProductReviewModal;
