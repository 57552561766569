import React, { useCallback, useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";

interface Props {
  onLoadMore: () => void;
  scrollEl: HTMLElement | null;
  bottomOffset?: string;
}

const CLInfiniteScroll: React.FC<Props> = React.memo(props => {
  const { onLoadMore, scrollEl, children, bottomOffset } = props;
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(k => k + 1);
  }, [scrollEl]);

  const handleEnter = useCallback(() => {
    onLoadMore();
  }, [onLoadMore]);

  const handleLeave = useCallback(() => {
    setKey(k => k + 1);
  }, []);

  return (
    <div>
      <Waypoint
        key={key}
        onEnter={handleEnter}
        onLeave={handleLeave}
        scrollableAncestor={scrollEl}
        bottomOffset={bottomOffset}
      />
      {children}
    </div>
  );
});

export default CLInfiniteScroll;
