import * as yup from "yup";
import moment from "moment";
import { PushNotification } from "@capacitor/core";

import Config from "../Config";
import { isiOS, isAndroid, isHybrid } from "../utils/Platform";
import { Override } from "../utils/type";

export type OS = "ios" | "android";
export type Platform = "GCM" | "FCM" | "APNS";
export type Token = string;
export type Isdn = string;

export interface PNSResponse {
  code: string;
  description: string;
}

export function getOS(): OS | null {
  if (!isHybrid()) {
    return null;
  }
  if (isiOS()) {
    return "ios";
  }
  if (isAndroid()) {
    return "android";
  }
  return null;
}

export function getPlatform(): Platform | null {
  if (!isHybrid()) {
    return null;
  }
  if (isiOS()) {
    return "APNS";
  }
  if (isAndroid()) {
    return Config.OPNS_ANDROID_PLATFORM || null;
  }
  return null;
}

export const dummyIsdn = "85290000000";

export enum ReadState {
  New = 0,
  Read = 1,
}

export interface MessageExtraDataLink {
  type: "goTo";
  goTo: string;
}

export type MessageExtraData = MessageExtraDataLink;

export type MessageType = "order" | "promotion";

export interface Message {
  id: string;
  title?: string | null;
  message: string;
  pushStartDate?: Date | null;
  read: ReadState;
  extraData: MessageExtraData | null;
  type: MessageType | null;
}

export interface RemoteMessage {
  id: string;
  title?: string | null;
  message: string;
  pushStartDate?: string | null;
  read: ReadState;
  extraData: string | null;
}

interface RemoteExtraData {
  goTo?: string;
  type?: MessageType;
}

function makeExtraDataAndType(
  data: RemoteExtraData | null
): { extraData: MessageExtraData | null; type: MessageType | null } {
  return {
    extraData:
      data && data.goTo != null ? { type: "goTo", goTo: data.goTo } : null,
    type: data && data.type ? data.type : null,
  };
}

function makeRemoteExtraData(
  extraData: MessageExtraData | null,
  type: MessageType | null
): RemoteExtraData {
  const res: RemoteExtraData = {};
  if (extraData && extraData.type === "goTo") {
    res.goTo = extraData.goTo;
  }
  if (type) {
    res.type = type;
  }
  return res;
}

export const MessageSchema = yup.object<RemoteMessage>({
  id: yup.string(),
  title: yup.string().nullable(),
  message: yup.string(),
  pushStartDate: yup.string().nullable(),
  read: yup.number(),
  extraData: yup.string().nullable(),
});

export function realizeRemoteExtraData(
  extraDataString: string | null
): RemoteExtraData | null {
  if (!extraDataString) {
    return null;
  }
  try {
    const obj = JSON.parse(extraDataString);
    if (obj && obj instanceof Object) {
      return obj;
    }
    return null;
  } catch {
    return null;
  }
}

export function transformRemoteMessageToMessage(
  remoteMessage: RemoteMessage
): Message {
  const { id, title, message, pushStartDate, read, extraData } = remoteMessage;
  return {
    id,
    title,
    message,
    pushStartDate: pushStartDate
      ? moment(pushStartDate, "YYYYMMDDHHmm").toDate()
      : null,
    read,
    ...makeExtraDataAndType(realizeRemoteExtraData(extraData)),
  };
}

export function transformMessageToRemoteMessage(msg: Message): RemoteMessage {
  const { id, title, message, pushStartDate, read, extraData, type } = msg;
  return {
    id,
    title,
    message,
    pushStartDate: pushStartDate
      ? moment(pushStartDate).format("YYYYMMDDHHmm")
      : null,
    read,
    extraData: JSON.stringify(makeRemoteExtraData(extraData, type)),
  };
}

export const MessageGraphQLAttributes = `
  id
  topic
  title
  message
  pushStartDate
  extraData
  read
`;

export function getLinkFromOPNSMessage(message: Message): string | null {
  const { extraData } = message;
  if (!extraData) {
    return null;
  }
  switch (extraData.type) {
    case "goTo":
      return extraData.goTo;
    default:
      return null;
  }
}

export function getLinkFromPushNotification(
  notification: PushNotification
): string | null {
  const { data } = notification;
  if (data.goTo) {
    return data.goTo;
  }
  return null;
}

export function getIdFromPushNotification(
  notification: PushNotification
): string | null {
  const { data } = notification;
  if (data.onepnsMsgId) {
    return data.onepnsMsgId;
  }
  return null;
}

export function doesMessageHaveFurtherAction(message: Message): boolean {
  const { extraData } = message;
  if (!extraData) {
    return false;
  }
  return extraData.type === "goTo";
}

export enum NotificationEnableState {
  No = 0,
  Yes = 1,
}

type DisplayablePushNotification =
  | Override<
      PushNotification,
      {
        title: string;
      }
    >
  | Override<
      PushNotification,
      {
        body: string;
      }
    >
  | Override<
      PushNotification,
      {
        title: string;
        body: string;
      }
    >;

export function isDisplayablePushNotification(
  p: PushNotification
): p is DisplayablePushNotification {
  return p.title != null || p.body != null;
}

export function transformDisplayablePushNotificationToMessage(
  pushNotification: DisplayablePushNotification
): Message {
  const { id, title, body, data } = pushNotification;
  const res: Message = {
    id,
    title,
    message: body || "",
    read: ReadState.New,
    ...makeExtraDataAndType(data),
  };
  return res;
}
