import { Ref, RefObject, useRef, useEffect, useMemo } from "react";

export function useRefToRefObject<T>(ref?: Ref<T>): RefObject<T> {
  const _refObject = useRef<T>(null);

  const refObject = useMemo(() => {
    if (!ref) {
      return _refObject;
    }
    if (typeof ref === "function") {
      return _refObject;
    }
    return ref;
  }, [ref]);

  useEffect(() => {
    if (ref && typeof ref === "function") {
      ref(_refObject.current);
    }
    return () => {
      if (ref && typeof ref === "function") {
        ref(null);
      }
    };
  });

  return refObject;
}
