import React from "react";

import { LocalizedText } from "../../i18n/Localization";

import styles from "./NewBadge.module.scss";

const NewBadge: React.FC = () => {
  return (
    <div className={styles.badge}>
      <LocalizedText messageID="product.new" />
    </div>
  );
};

export default NewBadge;
