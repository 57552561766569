import React, { useMemo } from "react";
import moment from "moment-timezone";

import { Product } from "../../models/ProductDetails";
import { LocalizedText } from "../../i18n/Localization";

import Config from "../../Config";

import styles from "./ClubTierQuotaMessage.module.scss";

interface Props {
  product: Pick<Product, "clubTierQuota" | "quota" | "memberQuota">;
}

const ClubTierQuotaMessage: React.FC<Props> = props => {
  const { product } = props;
  const { clubTierQuota, quota, memberQuota } = product;

  const effectiveTierConfig = useMemo<{ max: number; tier: string }[]>(() => {
    const res: { max: number; tier: string }[] = [];
    if (clubTierQuota == null) {
      return res;
    }
    for (const tierConfig of clubTierQuota.tierConfig) {
      const { max, tier, startTime, endTime } = tierConfig;
      if (max != null && max > 0) {
        const now = moment()
          .tz(Config.TIMEZONE)
          .toDate();
        if (startTime != null) {
          if (endTime != null) {
            if (now >= startTime && now <= endTime) {
              res.push({ max, tier });
            }
          } else {
            if (now >= startTime) {
              res.push({ max, tier });
            }
          }
        } else {
          if (endTime != null) {
            if (now <= endTime) {
              res.push({ max, tier });
            }
          } else {
            res.push({ max, tier });
          }
        }
      }
    }
    return res;
  }, [clubTierQuota]);

  return (
    <div className={styles.clubTierQuotaMessage}>
      {quota && quota.value != null && quota.value > 0 ? (
        <span className={styles.message}>
          <LocalizedText
            messageID={
              "page.product_detail.club_tier_quota_message.quota_message"
            }
            messageArgs={{
              max: quota.value,
            }}
          />
          <br />
        </span>
      ) : null}
      {memberQuota && memberQuota.value != null && memberQuota.value > 0 ? (
        <span className={styles.message}>
          <LocalizedText
            messageID="page.product_detail.club_tier_quota_message.member_quota_message"
            messageArgs={{
              max: memberQuota.value,
            }}
          />
          <br />
        </span>
      ) : null}
      {effectiveTierConfig.map(({ tier, max }) => (
        <span className={styles.message} key={tier}>
          <LocalizedText
            messageID="page.product_detail.club_tier_quota_message.club_tier_quota_message"
            messageArgs={{
              clubTier: tier.toUpperCase(),
              max,
            }}
          />
          <br />
        </span>
      ))}
    </div>
  );
};

export default ClubTierQuotaMessage;
