import React from "react";
// eslint-disable-next-line no-restricted-imports
import parse, { HTMLReactParserOptions } from "html-react-parser";

export default function parse_(
  html: string,
  options?: HTMLReactParserOptions
): ReturnType<typeof parse> {
  try {
    return parse(html, options);
  } catch (e) {
    console.warn("[html-react-parser]", "parse error", e, html);
    return React.createElement("div");
  }
}
