import { ApolloClient, gql } from "@apollo/client";

import { parseGraphQLError } from "../../api/GraphQL";

export async function subscribeToRestockAlert(
  client: ApolloClient<any>,
  productId: number
): Promise<boolean> {
  try {
    const result = await client.mutate<
      { addStockAlert: boolean },
      { productId: number }
    >({
      mutation: gql`
        mutation SubscribeToRestockAlert($productId: Int!) {
          addStockAlert(product_id: $productId)
        }
      `,
      variables: {
        productId,
      },
      fetchPolicy: "no-cache",
    });

    if (!result.data) {
      throw new Error();
    }

    return result.data.addStockAlert;
  } catch (e) {
    const errorMessage = parseGraphQLError(e);
    if (errorMessage) {
      throw new Error(errorMessage);
    }
    throw e;
  }
}
