import React, { useCallback } from "react";
import cn from "classnames";

import LocalizedInput from "../LocalizedInput";

import { isEmpty } from "../../utils/String";

import styles from "./SearchInput.module.scss";

interface Props {
  value: string;
  disabled?: boolean;
  onTextChange: (value: string) => void;
  onClearTextClick: () => void;
  onFocus?: () => void;
}

const WrappedSearchInput = (
  props: Props,
  ref?: React.Ref<HTMLInputElement>
) => {
  const { value, disabled, onTextChange, onFocus, onClearTextClick } = props;
  const onClickClearButton = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClearTextClick();
    },
    [onClearTextClick]
  );
  return (
    <div className={cn(styles.inputContainer, disabled && styles.disabled)}>
      <LocalizedInput
        className={styles.input}
        type="search"
        placeholderId="page.search_suggestion.navbar.search_input.placeholder"
        value={value}
        onChange={onTextChange}
        onFocus={onFocus}
        ref={ref}
      />
      {!isEmpty(value) && !disabled && (
        <div className={styles.clearButton} onClick={onClickClearButton} />
      )}
    </div>
  );
};

export default React.forwardRef(WrappedSearchInput);
