import React, { useCallback, useMemo } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import {
  Category,
  constructMediaUrlForCategory,
  getBackgroundColor,
} from "../../models/category";
import { StoreConfig } from "../../models/StoreConfig";

interface Props {
  storeConfig?: StoreConfig;
  isSelected: boolean;
  category: Category;
  onItemClick: (category: Category) => void;
}

type BackgroundStyle = React.CSSProperties;
type IconStyle = React.CSSProperties;

const CategorySelectItem: React.FC<Props> = (props: Props) => {
  const { isSelected, onItemClick, category, storeConfig } = props;

  const { name, backgroundColor1, backgroundColor2, iconUrl } = category;

  const backgroundStyle = useMemo<BackgroundStyle>(
    () => ({
      background: getBackgroundColor({
        backgroundColorFrom: backgroundColor1,
        backgroundColorTo: backgroundColor2,
      }),
    }),
    [backgroundColor1, backgroundColor2]
  );

  const iconStyle = useMemo<IconStyle>(
    () => ({
      backgroundImage: iconUrl
        ? `url(${
            storeConfig
              ? constructMediaUrlForCategory(storeConfig, iconUrl)
              : iconUrl
          })`
        : undefined,
    }),
    [iconUrl, storeConfig]
  );

  const onClick = useCallback(() => {
    onItemClick(category);
  }, [onItemClick, category]);

  return (
    <div className={styles.item} onClick={onClick}>
      <div className={styles.category} style={backgroundStyle}>
        <div
          className={cn(styles.icon, {
            [styles.noIcon]: !iconUrl,
          })}
          style={iconStyle}
        ></div>
        <div className={styles.text}>{name}</div>
      </div>
      <div
        className={cn(styles.selectIndicator, isSelected && styles.selected)}
      />
    </div>
  );
};

export default CategorySelectItem;
