export interface ClubPointJourneyPoint {
  value: number;
  name: string;
}

export function makeMockClubPointJourneyPoints(): ClubPointJourneyPoint[] {
  return [
    {
      value: 500,
      name: "Buffet dinner 1",
    },
    {
      value: 1000,
      name: "Buffet dinner 2",
    },
    {
      value: 1500,
      name: "Buffet dinner 3",
    },
    {
      value: 2000,
      name: "Buffet dinner 4",
    },
    {
      value: 2500,
      name: "Buffet dinner 5",
    },
    {
      value: 3000,
      name: "Trip to Taipei",
    },
  ];
}
