import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import classnames from "classnames";

import CMSPage from "../CMSPage";
import { BackAndroidExitApp } from "../BackAndroidHandler";
import CLContent from "../CLContent";
import MainTabPageNavBar from "../MainTabPageNavBar";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";
import {
  LoadingModalContextProps,
  withLoadingModal,
} from "../LoadingModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";
import { withProfileSession } from "../../contexts/ProfileSessionContext";

import { useCheckIsScreenActive, RootTab } from "../../navigation/routes";

import { useIntl } from "../../i18n/Localization";
import { DataByLocale, Locale } from "../../i18n/locale";
import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import {
  pageView,
  pairUpAcctClickEvent,
  pairUpAcctSuccessEvent,
} from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import { CMS_PAGE_TYPE } from "../../models/cmsBlock";
import {
  INVALID_MEMBERSHIP_RESPONSE,
  useCustomer,
  useLinkSocialAccountRequest,
  useRefreshCustomer,
} from "../../repository/AuthRepository";
import { useScrollToTopWhenClickTabBar } from "../../hook/scrollToTopWhenClickTabBar";
import {
  addPerformanceRecord,
  RERENDER_EVENT,
} from "../../utils/PerformanceRecordStore";
import { InitializeHomePageSession } from "../../utils/PerformanceRecordStore/sessions";
import { useViewDidEnterOnce } from "../../utils/View";
import { TheClubLoginUTMButtonPairUpAcct } from "../../utils/TheClubLogin";
import { usePresentTheClubOneTimeAuthenticatedBrowser } from "../../utils/TheClubOneTimeAuthenticatedBrowser";
import { useLoginWithTheClub } from "../../useCase/AuthUseCase";

import RerenderLogger from "../Performance/RerenderLogger";

import TheClubBanner from "./TheClubBanner";

import Config from "../../Config";

import styles from "./styles.module.scss";

const cmsPageType: CMS_PAGE_TYPE = { type: "home" };

function viewEnter() {
  pageView({ page: "Home" });
  addPerformanceRecord(InitializeHomePageSession(), "Home Page did enter");
}

const onRender: React.ProfilerOnRenderCallback = (
  _id,
  phrase,
  actualDuration,
  _baseDuration,
  startTime
) => {
  if (phrase === "mount") {
    addPerformanceRecord(
      InitializeHomePageSession(),
      "Home Page Mounted",
      startTime
    );
    return;
  }
  addPerformanceRecord(
    InitializeHomePageSession(),
    RERENDER_EVENT,
    startTime,
    startTime + actualDuration
  );
};

type Props = LoadingModalContextProps;

/* eslint-disable react/jsx-no-bind */
const HomePage: React.FC<Props> = props => {
  const {
    loadingModalContext: {
      show: showLoading,
      hide: hideLoading,
      withLoadingModalAsync,
    },
  } = props;

  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);
  const customer = useCustomer();
  const { refreshCustomer: _refreshCustomer } = useRefreshCustomer();

  const refreshCustomerIfExists = useMemo(
    () => (customer ? _refreshCustomer : undefined),
    [customer, _refreshCustomer]
  );
  ionLifeCycleContext.onIonViewDidEnter(refreshCustomerIfExists);

  const contentRef = useRef<HTMLIonContentElement>(null);
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);
  const isScreenActive = useCheckIsScreenActive();

  useScrollToTopWhenClickTabBar(RootTab.home, isScreenActive, contentRef);

  const [
    changedFromActiveToInactiveOnce,
    setChangedFromActiveToInactiveOnce,
  ] = useState(false);

  useEffect(() => {
    if (!isScreenActive && !changedFromActiveToInactiveOnce) {
      setChangedFromActiveToInactiveOnce(true);
    }
  }, [isScreenActive, changedFromActiveToInactiveOnce]);

  const [didEnter, setDidEnter] = useState(false);

  const didEnterOnce = useCallback(() => setDidEnter(true), []);

  useViewDidEnterOnce(ionLifeCycleContext, didEnterOnce);

  const presentTheClubOneTimeAuthenticatedBrowser = usePresentTheClubOneTimeAuthenticatedBrowser();
  const theClubClubPointHistoryUrl = useTheClubClubPointHistoryUrl();
  const handleTheClubBannerClick = useCallback(() => {
    if (theClubClubPointHistoryUrl) {
      withLoadingModalAsync(() =>
        presentTheClubOneTimeAuthenticatedBrowser(theClubClubPointHistoryUrl)
      );
    }
  }, [
    withLoadingModalAsync,
    presentTheClubOneTimeAuthenticatedBrowser,
    theClubClubPointHistoryUrl,
  ]);

  const loginWithTheClub = useLoginWithTheClub();
  const linkSocialAccount = useLinkSocialAccountRequest();
  const handleLinkWithTheClubClick = useCallback(async () => {
    pairUpAcctClickEvent();
    try {
      showLoading();
      const ssoResponse = await loginWithTheClub(
        TheClubLoginUTMButtonPairUpAcct
      );
      const linkResult = await linkSocialAccount(
        ssoResponse.token,
        ssoResponse.provider
      );
      if (linkResult.success !== true) {
        presentLocalizedAlert({
          headerId: linkResult.errorMessage
            ? "page.edit_profile.link_account.error.email_registered"
            : "page.edit_profile.connect_account_error",
          messageId: linkResult.errorMessage
            ? undefined
            : "alert.error.message",
          message: linkResult.errorMessage
            ? linkResult.errorMessage
            : undefined,
          buttons: [{ textMessageID: "try_again" }],
        });
      } else {
        pairUpAcctSuccessEvent();
      }
    } catch (e) {
      if (e.message === INVALID_MEMBERSHIP_RESPONSE) {
        presentLocalizedAlert({
          headerId: "page.edit_profile.link_account.error.email_registered",
          messageId:
            "page.edit_profile.link_account.error.email_registered.message",
          buttons: [{ textMessageID: "try_again" }],
        });
      } else {
        presentLocalizedAlert({
          headerId: "page.edit_profile.connect_account_error",
          buttons: [{ textMessageID: "try_again" }],
        });
      }
    } finally {
      await Promise.resolve().then(refreshCustomerIfExists);
      hideLoading();
    }
  }, [
    showLoading,
    hideLoading,
    loginWithTheClub,
    linkSocialAccount,
    presentLocalizedAlert,
    refreshCustomerIfExists,
  ]);

  const additionalRefreshRequest = useCallback(async () => {
    if (refreshCustomerIfExists) {
      await refreshCustomerIfExists();
    }
  }, [refreshCustomerIfExists]);

  return (
    <RerenderLogger id="Home Page" onRender={onRender}>
      <BackAndroidExitApp pageIsActive={isScreenActive} />
      <MainTabPageNavBar>
        {customer ? (
          <div className={styles.theClubSection}>
            <TheClubBanner
              customer={customer}
              onClick={handleTheClubBannerClick}
              onLinkWithTheClubClick={handleLinkWithTheClubClick}
            />
          </div>
        ) : null}
      </MainTabPageNavBar>
      <CLContent
        ref={contentRef}
        className={classnames(
          styles.content,
          !changedFromActiveToInactiveOnce ? "withStartupAnimation" : undefined
        )}
      >
        {didEnter ? (
          <CMSPage
            pageType={cmsPageType}
            allowRefresh={true}
            didEnter={didEnter}
            additionalRefreshRequest={additionalRefreshRequest}
          />
        ) : null}
        <TabBarSpacePlaceholder />
      </CLContent>
    </RerenderLogger>
  );
};

export default withProfileSession(
  InitializeHomePageSession,
  withLoadingModal(HomePage)
);

function useTheClubClubPointHistoryUrl() {
  const { locale } = useIntl();
  return useMemo(() => {
    const {
      THE_CLUB_CLUB_POINT_HISTORY_URL_EN,
      THE_CLUB_CLUB_POINT_HISTORY_URL_ZH,
    } = Config;
    const urlMap: DataByLocale<string | undefined> = {
      [Locale.zhHant]: THE_CLUB_CLUB_POINT_HISTORY_URL_ZH,
      [Locale.en]: THE_CLUB_CLUB_POINT_HISTORY_URL_EN,
    };
    return urlMap[locale] || null;
  }, [locale]);
}
