import React from "react";
import { Network } from "@capacitor/core";

interface NetworkStatusContext {
  isOnline: boolean;
}

export const NetworkStatusContext = React.createContext<NetworkStatusContext>({
  isOnline: false,
});

export const NetworkStatusProvider: React.FC = props => {
  const [isOnline, setIsOnline] = React.useState<boolean>(true);
  React.useEffect(() => {
    Network.getStatus().then(status => {
      setIsOnline(status.connected);
    });
    const handler = Network.addListener("networkStatusChange", status => {
      setIsOnline(status.connected);
    });
    return () => {
      handler.remove();
    };
  }, []);
  return (
    <NetworkStatusContext.Provider value={{ isOnline: isOnline }}>
      {props.children}
    </NetworkStatusContext.Provider>
  );
};
