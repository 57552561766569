const sha256 = require("hash.js/lib/hash/sha/256");

export function getVariableValueList(
  variables: { [x in string]: unknown }
): { variable: string; value: string }[] {
  const variableKeys = Object.keys(variables);
  if (variableKeys.length === 0) {
    return [];
  }
  const res: { variable: string; value: string }[] = [];
  for (const variableKey of variableKeys) {
    const variableValue = variables[variableKey];

    const isArray = Array.isArray(variableValue);
    let serializedVariableValue = JSON.stringify(variableValue);

    if (isArray) {
      serializedVariableValue = serializedVariableValue
        .replace(/^\[/g, "")
        .replace(/\]$/g, "");
    }

    // Remove quotes
    const unquotedVariableValue = serializedVariableValue.replace(/"/g, "");

    res.push({ variable: variableKey, value: unquotedVariableValue });
  }
  return res;
}

export interface HashQueryAdditionalInfo {
  responseCacheAge?: number;
}

export function hashQuery(
  query: string,
  additionalInfo?: HashQueryAdditionalInfo
): string {
  return sha256()
    .update(`${query}${additionalInfo ? JSON.stringify(additionalInfo) : ""}`)
    .digest("hex");
}
