import { WebPlugin } from "@capacitor/core";

import { MigrateToV2Plugin } from "./definitions";

export class MigrateToV2PluginWeb extends WebPlugin
  implements MigrateToV2Plugin {
  constructor() {
    super({
      name: "MigrateToV2Plugin",
      platforms: ["web"],
    });
  }

  async setToken(): Promise<void> {}

  async setLocale(): Promise<void> {}
}
