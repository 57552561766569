import React, { useCallback, useMemo } from "react";
import { LocalizedText } from "../../i18n/Localization";
import { isUrl } from "../../utils/Url";
import styles from "./PromoCodeUrl.module.scss";

type QRCodeType = { type: "text"; text: string } | { type: "url"; url: string };

interface PromoCodeUrlProps {
  index: number;
  promoCodeUrl: string;
  onLinkClicked: (url: string) => void;
}

const PromoCodeUrl: React.FC<PromoCodeUrlProps> = props => {
  const { index, promoCodeUrl, onLinkClicked } = props;

  const qrCodeType = useMemo<QRCodeType>(() => {
    if (isUrl(promoCodeUrl)) {
      return { type: "url", url: promoCodeUrl };
    }
    return { type: "text", text: promoCodeUrl };
  }, [promoCodeUrl]);

  const handleLinkClicked = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (qrCodeType.type === "url") {
        onLinkClicked(qrCodeType.url);
      }
    },
    [qrCodeType, onLinkClicked]
  );

  return qrCodeType.type === "text" ? (
    <p className={styles.promoCodeUrl}>
      <LocalizedText
        messageID="order_detail.promo_code_text"
        messageArgs={{ index: index + 1, promoCodeUrl: qrCodeType.text }}
      />
    </p>
  ) : (
    <p className={styles.promoCodeUrl}>
      <LocalizedText
        messageID="order_detail.promo_code_url"
        messageArgs={{
          index: index + 1,
          promoCodeUrl: qrCodeType.url,
          onLinkClicked: handleLinkClicked,
        }}
      />
    </p>
  );
};

export default PromoCodeUrl;
