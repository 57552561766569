import React, { useContext, useMemo } from "react";

interface Context {
  subscribedProductIdMap: {
    [key in number]: boolean;
  };
  subscribe: (productId: number) => void;
  reset: () => void;
}

const Context = React.createContext<Context>(null as any);

export default Context;

export function useIsProductSubscribedToRestockAlert(productId: number | null) {
  const { subscribedProductIdMap } = useContext(Context);

  const isSubscribed = useMemo(() => {
    if (productId == null) {
      return false;
    }
    return !!subscribedProductIdMap[productId];
  }, [productId, subscribedProductIdMap]);

  return isSubscribed;
}
